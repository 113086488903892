import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const GenerateSchema = yup.object().shape({});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getReportData = async (params = {}, keyBy) => {
  try {
    let res = await api.getReportData(params, keyBy);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchReportList = async (params = {}) => {
  try {
    let res = await api.fetchReportList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchSupplierProfilesListReport = async (params = {}, isControlTowerUser) => {
  try {
    let res = await api.fetchSupplierProfilesListReport(params, isControlTowerUser);
    return res;
  } catch (err) {
    throw err;
  }
};

export const platformChargesReportData = async (params = {}) => {
  try {
    let res = await api.platformChargesReportData(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getEmissionsSustainabilityReport = async (params = {}) => {
  try {
    let res = await api.getEmissionsSustainabilityReport(params);
    return res;
  } catch (err) {
    throw err;
  }
};
