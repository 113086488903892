import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transform';
import dayjs from 'dayjs';

const { baseUrl } = config;

export const GetNearByVendors = async (filters, Id) => {
  try {
    const bodyParams = reqTransform.GetNearByVendors(filters, Id);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/GetNearByVendors`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchList(resData1);
    console.log('response from loaction api', resData);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetNearByVendors');
    throw errors;
  }
};

export const FetchSellersAgainstCommodityReqOfferId = async (params) => {
  try {
    // const bodyParams = reqTransform.GetNearByVendors(filters, Id);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchSellersAgainstCommodityReqOfferId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchListPickupMap(resData1);
    console.log('response from loaction api', res, resData);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchSellersAgainstCommodityReqOfferId');
    throw errors;
  }
};
