import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { locationService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'farmerOnboarding',
  initialState: adapter.getInitialState({
    busy: false,
    bankList: [],
    bankDetails: [],
    bankVerificationDetails: {},
    panVerificationDetails: {},
    facilitators: [],
    farmerDetailsForFacilitator: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBankList: (state, { payload }) => ({ ...state, bankList: payload }),
    setBankDetails: (state, { payload }) => ({ ...state, bankDetails: payload }),
    setBankVerificationDetails: (state, { payload }) => ({ ...state, bankVerificationDetails: payload }),
    setPanVerificationDetails: (state, { payload }) => ({ ...state, panVerificationDetails: payload }),
    setFacilitators: (state, { payload }) => ({ ...state, facilitators: payload }),
    setFarmerDetailsForFacilitator: (state, { payload }) => ({ ...state, farmerDetailsForFacilitator: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const getBankNames = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.getBankNames(params);
    dispatch(slice.actions.setBankList(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getBankDetailsAgainstName = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.getBankDetailsAgainstName(params);
    dispatch(slice.actions.setBankDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const verifyBankAccount = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.verifyBankAccount(params);
    if (!resData?.is_any_error && !resData?.bank_verification_failure_reason) {
      notifications.show({ type: 'success', message: 'Bank details verified successfully' });
    }
    dispatch(slice.actions.setBankVerificationDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const kycVerification = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.kycVerification(params);
    // if (resData?.kyc_message.includes('verified')) {
    //   notifications.show({ type: 'success', message: 'PAN details verified successfully' });
    // }
    dispatch(slice.actions.setPanVerificationDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getFacilitators = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.getFacilitators(params);
    dispatch(slice.actions.setFacilitators(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

export const FetchPersonalDetailsForFacilitator = (id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await locationService.FetchPersonalDetailsForFacilitator(id);
    dispatch(slice.actions.setFarmerDetailsForFacilitator(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  getBankNames,
  getBankDetailsAgainstName,
  verifyBankAccount,
  kycVerification,
  getFacilitators,
  FetchPersonalDetailsForFacilitator,
};

const selectors = {
  ...adapter.getSelectors((state) => state.farmerLocations),
};

export { actions, selectors };

export default slice.reducer;
