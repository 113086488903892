import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Popover } from 'antd';
import SupplierPopover from 'common/ExtendedProfileCard/popover';
// import NotFound from 'common/Form/NoDataFound';
// import { getAnalytics, logEvent } from "firebase/analytics";
import RawMaterialCatchmentIcon from 'assets/images/icons/extendedProfile/raw-material-catchment-icon.svg';

const OverviewInfoCard = ({
  type,
  heading,
  productsCommodityFormCode,
  isRawMaterialProductsData,
  rawMaterialProductsData,
  rawMaterialProductsData1,
  NoSuppliersIcon,
  rawMaterialSuppliersCountData,
  rawMaterialProcurementRangeFrom,
  rawMaterialProcurementRangeTo,
  infraEquipmentsArray,
  isThereInfrastructureData,
  isThereLocationAccessData,
  infraEquipmentsData,
  healthSafetyArray,
  healthSafetyData,
  isThereHealthSafetyData,
  newSafetyEqipmentsCodeDataArray,
  newFireFightingEquipmentTypeEnumDataArray,
  qualityStandardData,
  qualityProcessArray,
  rightSideIcon,
  middleText,
  viewMore,
  onClick,
  closeIcon,
  rawData,
  locationAccessData,
  infrastructureEquipmentData,
  healthSafety,
  qualityProcess,
  isQualityProcessData,
  maintainedRecordsData,
  comingSoon,
  extendedProfilePersonId,
  extendedProfileId,
  extendedProfileAddressId,
  userId
}) => {

  let qualityStandardDataArray = qualityStandardData?.slice(0, 2); //Show only 2 data

  let newProfileDataArray = rawMaterialProductsData?.slice(0, 4); // show only 4 data

  let qualityProcessArraySort = qualityProcessArray?.slice(0, 2); // Show only 2 data

  let newInfrastructureDataArray = infraEquipmentsArray?.slice(0, 4); // Show only 4 data

  const cardClass = classnames(['overview-single', { 'bg-color': type === 'highlight' }]);

  let zIndexPopup = '111111';

  // Prepare ecommerce params
// const params1 = {
//   item_list_id: 'L001',
//   item_list_name: 'OverviewInfoCard',
//   items:
//   [
//     {item_id: '1',item_name:'Raw Material & Products', ...rawMaterialProductsData},
//     {item_id: '2',item_name:'Location, Access & People', ...locationAccessData},
//     {item_id: '3',item_name:'Infrastructure & Equipment', ...infrastructureEquipmentData},
//     {item_id: '4',item_name:'Health & Safety', ...qualityProcessArray},
//     {item_id: '5',item_name:'Quality & Processes', ...qualityProcessArraySort},
//     {item_id: '6',item_name:'Person_Id', ...personId}
// ]
// };

// Log event
// const analytics = getAnalytics();

// rawMaterialProductsData &&
// logEvent(analytics, 'view_item_list', params1);


  const ProductsContent = ({details}) => (
    <div>
      {details.commodityTypeCode} {details.commodityFormCode}
    </div>
  );

  const QualityProcessContent = ({details}) => (
    <div>
      {details.qualitySpecCode}
    </div>
  );

  const QualityTestingContent = ({text}) => (
    <div>
      <span>{text}</span>
    </div>
  );

  return (
    <div class="card extended__overview-card" style={{ boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 5%)', borderRadius: '5px' }}>
      <div className="card-body p-0">
        <div className="company-info d-flex align-items-center pb-10">
          <div className="icon-wrapper">{rightSideIcon}</div>
          <p className="heading position-relative fw-600 ml-10 m-0" style={{ color: 'var(--unnamed-color-1b62ab)', fontFamily: 'var(--unnamed-font-segoeUI)' }}>{heading}</p>
        </div>
        <div className="extended__middle-part flex-wrap ml-20">
          {rawData && (
            <div class="extended__middle-part flex-wrap ml-20">
              {productsCommodityFormCode.length || isRawMaterialProductsData ? (
                <>
                  <span>
                    {productsCommodityFormCode.length > 0 ? (
                      <>
                        <ul className="extended__ul" style={{ width: 'max-content' }}>
                          {_.map(productsCommodityFormCode, (details , i) => {
                            return (
                              <>
                                <li className="extended__li">
                                  <Popover
                                    placement="top"
                                    overlayStyle={{ zIndex: zIndexPopup }}
                                    content={<ProductsContent details={details} />}>
                                    <img className="cursor-pointer" src={details.post_image_URL} alt={details.post_image_URL} height="25" width="25" />
                                  </Popover>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <small className="d-flex mt-5 ml-5">
                          Products
                        </small>
                      </>
                    ) : ''}
                  </span>

                  {rawMaterialSuppliersCountData != 0 ? (
                    <span>
                      <ul className="extended__ul">
                        <li className="extended__li">
                          <small style={{ color: 'var(--unnamed-color-1b62ab)', display: 'grid', textAlign: 'center', marginTop: '-5px' }}>
                            <img src={NoSuppliersIcon} alt={NoSuppliersIcon} height="20" width="20" />
                            <h4 className="count mb-0">{rawMaterialSuppliersCountData}</h4>
                          </small>
                        </li>
                      </ul>
                      <small className="d-flex justify-content-center text-center mt-5">
                        Dedicated <br />
                        Suppliers
                      </small>
                    </span>
                  ) : ''}

                  {/* {(rawMaterialProcurementRangeFrom || rawMaterialProcurementRangeTo) && (
                    <span>
                      <small className="text-center">Raw Material Catchment (km)</small><br/>
                      <img src={RawMaterialCatchmentIcon} alt={RawMaterialCatchmentIcon} height="25" width="50" /><br />
                      <small>{rawMaterialProcurementRangeFrom} - {rawMaterialProcurementRangeTo} </small>
                    </span>
                  )} */}
                </>
              ) : (
                // <NotFound />
                'No Data Available'
              )}
            </div>
          )}

          {locationAccessData && (
            <>
              {isThereLocationAccessData ? _.map(newProfileDataArray, (details) => {
                return (
                  <div>
                    {rawMaterialProductsData1 ? (
                      <>
                        {details.ownRentedEnumCode && (
                          <div className="">
                            <ul className="extended__ul pl-0 mb-10" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', marginTop: '-5px' }}>
                              <li className="extended__li ml-0 mr-0">
                                <span className="count">
                                  {details.unclearIcon && (
                                    <SupplierPopover imageSrc55={details.unclearIcon} title_one={details.popoverText} />
                                    // <img src={details.unclearIcon} alt={details.unclearIcon}
                                    // style={{ height: '60px', width: '100%', objectFit: 'cover', borderRadius: '50px',}}/>
                                  )}
                                  {details.ownRentedIcon && (
                                    <SupplierPopover imageSrc80={details.ownRentedIcon} title_one={details.popoverText} />
                                    // <img src={details.ownRentedIcon} alt={details.ownRentedIcon}
                                    // style={{ height: '80px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/>
                                  )}
                                </span>
                              </li>
                            </ul>
                            <small className="d-flex justify-content-center text-center mt-5">{details.ownRentedtext}</small>
                          </div>
                        )}
                      </>
                    ) : ''}

                    {rawMaterialProductsData1 != null ? (
                      <>
                        {details.totalMenEmployed && (
                          <div className="">
                            <ul className="extended__ul">
                              <li className="extended__li">
                                <span className="count" style={{ lineHeight: '12px' }}>
                                  <SupplierPopover imageSrc={details.menWomenIconIcon} title_one={'Labour force Gender ratio'} />
                                  {/* <img src={details.menWomenIconIcon} alt="" height="20" width="20" /> */}
                                </span>
                              </li>
                            </ul>
                            <small className="d-flex justify-content-center text-center mt-5">{details.totalMenEmployed ? details.totalMenEmployed : 0 } {' : '}{details.totalWomenEmployed ? details.totalWomenEmployed : 0}</small>
                          </div>
                        )}
                      </>
                    ) : ''}

                    {rawMaterialProductsData1 != null ? (
                      <>
                        {details.icon && (
                          <div className=''>
                            <ul className="extended__ul">
                              <li className="extended__li">
                                <span className="count" style={{ lineHeight: '20px'}}>
                                  <img src={details.icon} alt="" height="20" width="20" />
                                </span>
                              </li>
                            </ul>
                            {details.text && (
                              <small className="d-flex justify-content-center text-center mt-5">{details.text}</small>
                            )}
                          </div>
                        )}
                      </>
                    ) : ''}

                    {rawMaterialProductsData1 != null ?(
                      <>
                        {details.facilityStartYear && (
                          <>
                            <ul className="extended__ul">
                              <li className="extended__li">
                                <small style={{ color: 'var(--unnamed-color-1b62ab)', display: 'grid', textAlign: 'center' }}>
                                  Since
                                  <span className="count">{details.facilityStartYear}</span>
                                </small>
                              </li>
                            </ul>
                            <small className="d-flex justify-content-center text-center mt-5" style={{ whiteSpace: 'pre-wrap',}}>{details.yearsInOperationText}</small>
                          </>
                        )}
                      </>
                    ) : ''}

                  </div>
                );
              }) : 'No Data Available'}
            </>
          )}

          {infrastructureEquipmentData && (
            <div className="d-flex justify-content-start w-100" style={{ gap: '15px', marginTop: '-5px' }}>
              {isThereInfrastructureData ? _.map(newInfrastructureDataArray, (details,i) => {
                return (
                  <>
                    {details.title ? (
                      <div>
                        <ul className="extended__ul pl-0 mb-15" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0">
                            <span className="count">
                              {details.dryingFacilityEnumCodeIcon && (
                                <img className="mt-10" src={details.dryingFacilityEnumCodeIcon} alt={details.dryingFacilityEnumCodeIcon}
                                  style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                              )}
                              {details.backupGeneratorIcon ? (
                                <img src={details.backupGeneratorIcon} alt={details.backupGeneratorIcon}
                                  style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                              ) : ''}
                              {details.generatorCapacityIcon && (
                                <>
                                  <img className="" src={details.generatorCapacityIcon} alt={details.generatorCapacityIcon}
                                    style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                                  <small className="d-flex justify-content-center align-items-end position-absolute ml-25 fw-700" style={{ top: '85px', gap: '5px' }}>{details.value}</small>
                                </>
                              )}
                              {details.popoverText ? (
                                <>
                                  {details.icon && (
                                    <>
                                      <SupplierPopover imageSrc80={details.icon} title_one={details.popoverText} style={{ marginTop: '-5px' }} />
                                    </>
                                  )}
                                </>
                              ) : (
                                <img src={details.icon} alt={details.icon}
                                  style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                              )}
                            </span>
                          </li>
                        </ul>
                        {details.MultiText && (<small className="d-flex justify-content-center text-center mt-5 ml-auto mr-auto" style={{ width: 'min-content' }}>{details.MultiText}</small>)}
                        <small className="d-flex justify-content-center text-center mt-10 ml-auto mr-auto" style={{ width: '70px' }}>{details.text}</small>
                      </div>
                    ) : ''}
                  </>
                );
              }) : 'No Data Available'}
            </div>
          )}

          {healthSafety && (
            <div className="d-flex justify-content-start w-100" style={{ gap: '15px' }}>
              {isThereHealthSafetyData ? _.map(healthSafetyArray, (details,i) => {
                return (
                  <>
                    <span>
                      {details.healthSafetyCertificationEnumCode ? (
                        <span>
                          {details.ohssIcon && (
                            <ul className="extended__ul p-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', height: '50px', width: '100%', marginTop: '-10px', padding: '0px' }}>
                              <li className="extended__li ml-0 mr-0">
                                <SupplierPopover imageSrc80={details.ohssIcon} title_one={details.popoverText} />
                                {/* <img src={details.ohssIcon} alt={details.ohssIcon}
                                  style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                              </li>
                            </ul>
                          )}
                          {details.otherIcon && (
                            <ul className="extended__ul p-0  p-0 ml-0 mr-0 d-flex justify-content-center" style={{ width: '50px',  }}>
                              <li className="extended__li ml-0 mr-0">
                              <Popover
                                placement="top"
                                overlayStyle={{ zIndex: zIndexPopup }}
                                content={'Health Certification'}
                                >
                                  <span className="count m-0 ml-5" style={{ position: 'relative', top: 15, right: 5 }}>
                                    {details.otherIcon}
                                  </span>
                                </Popover>
                              </li>
                            </ul>
                          )}
                          {details.ohssIcon ? (
                            <small className="d-flex justify-content-center text-center mt-20" style={{ whiteSpace: 'nowrap' }}>
                              {details.healthSafetyCertificationEnumCode}
                            </small>
                          ) : (
                            <small className="d-flex justify-content-center text-center mt-10" style={{ whiteSpace: 'nowrap' }}>
                              {details.healthSafetyCertificationEnumCode}
                            </small>
                          )}
                        </span>
                      ) : ''}
                      {details.lastSafetyTrainingOn ? (
                        <span>
                          <ul className="extended__ul p-0 h-auto" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                            padding: '0px' }}>
                            <li className="extended__li ml-0 mr-0">
                              <SupplierPopover imageSrc80={details.SafetyTrainingIcon} title_one={details.popoverText} />
                              {/* <img src={details.SafetyTrainingIcon} alt={details.SafetyTrainingIcon}
                                style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                            </li>
                          </ul>
                          <small className="d-flex justify-content-center text-center mt-0" style={{ whiteSpace: 'nowrap' }}>
                            {details.SafetyTrainingTitle}
                          </small>
                        </span>
                      ) : ''}
                      {details.weeklyCheck ? (
                        <span>
                          <ul className="extended__ul p-0 h-auto" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                            padding: '0px' }}>
                            <li className="extended__li ml-0 mr-0">
                              <SupplierPopover imageSrc80={details.WeeklyCheckIcon} title_one={details.popoverText} />
                              {/* <img src={details.WeeklyCheckIcon} alt={details.WeeklyCheckIcon}
                                style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                            </li>
                          </ul>
                          <small className="d-flex justify-content-center text-center mt-0" style={{ whiteSpace: 'nowrap' }}>
                            {details.WeeklyCheckTitle}
                          </small>
                        </span>
                      ) : ''}
                      {/* {details.icon ? (
                        <ul className="extended__ul p-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                          marginBottom: '10px', padding: '0px' }}>
                          <li className=" ml-0 mr-0">
                            {details.icon && (
                              <>
                                <img src={details.icon} alt={details.icon}
                                  style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                              </>
                            )}
                          </li>
                        </ul>
                      ) : ''}  */}
                      {details.incidentFreeDays ? (
                        <ul className="extended__ul p-0 ml-auto mr-auto w-auto justify-content-center">
                          <li className="extended__li">
                            <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__one)', fontWeight: '800', top: '25%', position: 'relative' }}>
                              {details.text}
                            </span>
                          </li>
                        </ul>
                      ) : ''}
                      {details.incidentFreeDays ? (<small className="d-flex justify-content-center mt-10 text-center" style={{ whiteSpace: 'pre-wrap', width: '60px' }}>{details.MultiText}</small>) : ''}
                      {/* {details.titleText ? (<small className="d-flex justify-content-center mt-10">{details.titleText}</small>) : ''} */}
                      {/* {details.title ? (
                        <small className="d-flex justify-content-center text-center mt-5" style={{ whiteSpace: 'nowrap' }}>
                          {details.title}<br/>
                          {details.subtitle}
                        </small>
                      ) : ''} */}
                    </span>
                  </>
                );
              }) : 'No Data Available'}
            </div>
          )}

          {qualityProcess && (
            <span className="d-flex" style={{ gap: '0px' }}>
              {qualityStandardDataArray.length > 0 && isQualityProcessData ? (
                <div className="d-flex" style={{ gap: '0px' }}>
                  <span>
                    {qualityStandardDataArray.length > 0 ? (
                      <div>
                        <ul className="extended__ul" style={{ height: '45px', marginTop: '-3px' }}>
                          {_.map(qualityStandardDataArray, (details) => {
                            return (
                              <>
                                <li className="extended__li">
                                  <Popover
                                    overlayStyle={{ zIndex: zIndexPopup }}
                                    placement="top"
                                    content={<QualityProcessContent details={details} />}>
                                    <img className="cursor-pointer" src={details.quality_spec_code_image_URL} alt="" height="20" width="20" />
                                  </Popover>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <small className="d-flex mt-5 ml-5">
                          Parameters<br />
                          Tested
                        </small>
                      </div>
                    ) : ''}
                  </span>

                  <span className="flex-wrap ml-15" style={{ display: 'flex', gap: '10px' }}>
                    {_.map(qualityProcessArraySort, (details) => {
                      return (
                        <span className="">
                          {details.icon ? (
                            <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', position: 'relative', bottom: 15 }}>
                              <li className="extended__li ml-0 mr-0">
                                <span className="count">
                                  <img src={details.icon} alt={details.icon}
                                  style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                                </span>
                              </li>
                            </ul>
                          ) : ''}

                          {details.qualityTestingFrequencyEnumCode ? (
                            <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', position: 'relative', bottom: 15 }}>
                              <li className="extended__li ml-0 mr-0">
                                <span className="count">
                                  <Popover
                                    overlayStyle={{ zIndex: zIndexPopup }}
                                    placement="top"
                                    content={<QualityTestingContent text={'Availability of test reports'} />} >
                                    <img className="cursor-pointer mb-10" src={details.testingFrequencyIcon} alt={details.testingFrequencyIcon}
                                    style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                                  </Popover>
                                </span>
                              </li>
                            </ul>
                          ) : ''}

                          {details.testReportSentToBuyerFrequencyEnumCode ? (
                            <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', position: 'relative', bottom: 15 }}>
                              <li className="extended__li ml-0 mr-0">
                                <span className="count">
                                  <Popover
                                    overlayStyle={{ zIndex: zIndexPopup }}
                                    placement="top"
                                    content={'Test Reports for Buyers'} >
                                    <img className="cursor-pointer mb-10" src={details.reportBuyerIcon} alt={details.reportBuyerIcon}
                                    style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                                  </Popover>
                                </span>
                              </li>
                            </ul>
                          ) : ''}

                          {details.text ? (
                            <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', position: 'relative', bottom: 15 }}>
                              <li className="extended__li">
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '800', top: '25%', position: 'relative' }}>
                                  {details.text}
                                </span>
                              </li>
                            </ul>
                          ) : ''}
                          {details.titleText ? (<small className="text-center" style={{ whiteSpace: 'pre-wrap', width: '60px' }}>{details.titleText}</small>) : ''}
                          {details.title ? (<small className="d-flex justify-content-center text-center">
                              {details.title}
                            </small>
                          ) : ''}
                        </span>
                      );
                    })}
                  </span>
                </div>
              ) : 'No Data Available'}
            </span>
          )}

          {comingSoon && (
            <ul className="extended__ul ml-auto mr-auto mt-auto position-absolute" style={{ top: '50%', left: '30%', height: '35px', transform: 'rotate(180deg)' }}>
              <li className="extended__li">
                <p className="fw-600"
                  style={{ marginTop: '-5px', transform: 'rotate(180deg)', color: 'var(--unnamed-color-1b62ab)', fontFamily: 'var(--unnamed-font-segoeUI)', fontSize: 'var(--fs-base__one)' }}>
                  {middleText}
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
      {rawData && (isRawMaterialProductsData && productsCommodityFormCode.length > 0) ? (
        <>
          {viewMore ? (
            <>
              <div className="position-absolute text-right" style={{ backgroundColor: '#fafafa',
                borderRadius: '5px 0px 5px 0px',
                width: 'fit-content',
                padding: '3px 10px 6px',
                bottom: '0px',
                right: '0px' }}>
                <small className="view__more fw-700" onClick={onClick}>View More</small>
              </div>
            </>
          ) : ''}
        </>
      ) : ''}
      {locationAccessData && (isThereLocationAccessData) ? (
        <>
          {viewMore ? (
            <>
              <div className="position-absolute text-right" style={{ backgroundColor: '#fafafa',
                borderRadius: '5px 0px 5px 0px',
                width: 'fit-content',
                padding: '3px 10px 6px',
                bottom: '0px',
                right: '0px' }}>
                <small className="view__more fw-700" onClick={onClick}>View More</small>
              </div>
            </>
          ) : ''}
        </>
      ) : ''}
      {infrastructureEquipmentData && (isThereInfrastructureData) ? (
        <>
          {viewMore ? (
            <>
              <div className="position-absolute text-right" style={{ backgroundColor: '#fafafa',
                borderRadius: '5px 0px 5px 0px',
                width: 'fit-content',
                padding: '3px 10px 6px',
                bottom: '0px',
                right: '0px' }}>
                <small className="view__more fw-700" onClick={onClick}>View More</small>
              </div>
            </>
          ) : ''}
        </>
      ) : ''}
      {healthSafety && isThereHealthSafetyData ? (
        <>
          {viewMore ? (
            <>
              <div className="position-absolute text-right" style={{ backgroundColor: '#fafafa',
                borderRadius: '5px 0px 5px 0px',
                width: 'fit-content',
                padding: '3px 10px 6px',
                bottom: '0px',
                right: '0px' }}>
                <small className="view__more fw-700" onClick={onClick}>View More</small>
              </div>
            </>
          ) : ''}
        </>
      ) : ''}
      {qualityProcess && (qualityStandardDataArray.length > 0 && isQualityProcessData) ? (
        <>
          {viewMore ? (
            <>
              <div className="position-absolute text-right" style={{ backgroundColor: '#fafafa',
                borderRadius: '5px 0px 5px 0px',
                width: 'fit-content',
                padding: '3px 10px 6px',
                bottom: '0px',
                right: '0px' }}>
                <small className="view__more fw-700" onClick={onClick}>View More</small>
              </div>
            </>
          ) : ''}
        </>
      ) : ''}

      {/* {viewMore && (
        <div className="text-right">
          <small className="view__more fw-700" onClick={onClick}>View More</small>
        </div>
      )} */}
    </div>
  );
}

export default OverviewInfoCard;
