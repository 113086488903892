import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transform';

const { baseUrl } = config;

export const fetchFollowPreferences = async (params) => {
  try {
    const bodyParams = reqTransform.fetchFollowPreferences_CT(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/FollowPreference/FetchFollowPreference`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFollowPreferences(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchFollowPreference');
    throw errors;
  }
};

export const fetchFollowPreferences_CT = async (params) => {
  try {
    const bodyParams = reqTransform.fetchFollowPreferences_CT(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/FollowPreference/FetchFollowPreference_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFollowPreferences(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchFollowPreference_CT');
    throw errors;
  }
};

export const fetchPersonCompanyData = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelSocialFeed/api/FollowPreference/FetchCompaniesAndPersons`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCompaniesAndPersons');
    throw errors;
  }
};

export const saveUpdateFollowPreference = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelSocialFeed/api/FollowPreference/SaveUpdateFollowPreference`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCompaniesAndPersons');
    throw errors;
  }
};

export const callFollowPreferenceApi = async (params, apiString, isQueryString = false) => {
  try {
    const url = `${baseUrl}/BioFuelSocialFeed/api/FollowPreference/${
      isQueryString ? apiString + '?' + params.split(' ').join('') : apiString
    }`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: isQueryString ? '' : JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', `${apiString}`);
    throw errors;
  }
};
