import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const QualitySchema = yup.object().shape({
  // vehicle_registration_id: yup.string().required('Registration No. is a required field'),
  // name_on_vehicle_registration: yup.string().required('Name of vehicle registration is a required field'),
  entity_id: yup.number().required().positive('Please select Company'),
  // vehicle_type_enum: yup.number().required().positive('select vehicle type'),
  // expiry_date: yup.string().required('Expiry Date is a required field'),
});

export const eKycDocumentValiadtion = yup.object().shape({
  vehicle_registration_id: yup.string().required('Registration No. is a required field'),
  name_on_vehicle_registration: yup.string().required('Name of vehicle registration is a required field'),
  // expiry_date: yup.string().required('Expiry Date is a required field'),
});
export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchQualityServiceListCt = async (params = {}) => {
  try {
    let res = await api.fetchQualityServiceListCt(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchDetails = async (params = {}) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchQualityServiceDetails = async (params = {}) => {
  try {
    let res = await api.fetchQualityServiceDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchSharedQualityReports = async (params = {}) => {
  try {
    let res = await api.FetchSharedQualityReports(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(VehicleSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (params) => {
  try {
    // await helpers.validateSchema(VehicleSchema, params);
    const res = await api.update(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const handleQualityParameterDestroy = async (id) => {
  try {
    let res = await api.handleQualityParameterDestroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLabData = async (params) => {
  try {
    await helpers.validateSchema(QualitySchema, params);
    const res = await api.fetchLabData(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchObservedLabData = async (params) => {
  try {
    await helpers.validateSchema(QualitySchema, params);
    const res = await api.fetchObservedLabData(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handleQualityUploadDestroy = async (id) => {
  try {
    let res = await api.handleQualityUploadDestroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFTKMachineDetails = async (params) => {
  try {
    let res = await api.fetchAllFTKMachineDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdateFTKMachine = async (params) => {
  try {
    let res = await api.saveAndUpdateFTKMachine(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteFTKMachine = async (params) => {
  try {
    let res = await api.deleteFTKMachine(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdateFTKMachineAllotment = async (params) => {
  try {
    let res = await api.saveAndUpdateFTKMachineAllotment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFTKMachineAllotment = async (params) => {
  try {
    let res = await api.fetchAllFTKMachineAllotment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdateFTKResult = async (params) => {
  try {
    let res = await api.saveAndUpdateFTKResult(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFTKResult = async (params) => {
  try {
    let res = await api.fetchAllFTKResult(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFTKDetailsCombine = async (params) => {
  try {
    let res = await api.fetchAllFTKDetailsCombine(params);
    return res;
  } catch (err) {
    throw err;
  }
};
