import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getDecryptedUrl } from 'lib/utils';

function AntTableEditFunction(props) {
  const { row, id, name, cancel, showFlag } = props;
  const loc = getDecryptedUrl(window.location.href);
  const userRoleData = useSelector((state) => state?.user?.profile?.person_role);
  const isRejectAllowed = userRoleData?.some(
    (role) => role.role_id === 20 || role.role_id === 21 || role.role_id === 23,
  );
  const menu = (row) => {
    return (
      <Menu>
        <Menu.Item>
          <span
            style={{ color: '#1b62ab' }}
            className="icon edit-icon"
            onClick={() => {
              row.setEditDeliveryId(row.transport_delivery_schedule_alloted_id);
            }}>
            Edit
          </span>
        </Menu.Item>
        {isRejectAllowed && loc.includes('controltower/deliveries')  && (
          <Menu.Item>
            <span
              style={{ color: 'var(--unnamed-color-f44336)' }}
              className="icon edit-icon"
              onClick={() => {
                row.handelRejectClick(row);
              }}>
              Reject
            </span>
          </Menu.Item>
        )}
      </Menu>
    );
  };
  return (
    <Dropdown overlay={menu(row)} placement="bottomCenter">
      {showFlag && row.is_vehicle_editable == 1 ? (
        <a className="ant-dropdown-link d-flex justify-content-center" onClick={(e) => e.preventDefault()}>
          Actions <DownOutlined className="mt-10 ml-5" />
        </a>
      ) : (
        <div></div>
      )}
    </Dropdown>
    // showFlag && row.is_vehicle_editable == 1 ? (
    //   <span
    //     style={{ color: 'var(--unnamed-color-1b62ab)', cursor: 'pointer' }}
    //     className="icon edit-icon"
    //     onClick={() => {
    //       row.setEditDeliveryId(row.transport_delivery_schedule_alloted_id);
    //     }}>
    //     Edit
    //   </span>
    // ) : (
    //   <div></div>
    // )
  );
}

export default AntTableEditFunction;
