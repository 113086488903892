import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import utils from 'lib/utils';
import CloseIcon from 'mdi-react/CloseIcon';

const FormikDatePickerField = (props) => {
  // const { fieldName, placeholder, formikBag } = props;
  // const { touched, errors } = formikBag;
  const { field, form, fieldName, defaultValue, placeholder, minDate, disabled, showYearDropdown = false, showMonthDropdown = false } = props;
  const { touched, errors } = form;
  console.log('field', field);
  const name = field.name || fieldName;

  const [date, setDate] = useState(props.defaultValue ? new Date(props.defaultValue) : null);

  const handleChange = (updatedDate) => {
    setDate(updatedDate);
    form.setFieldValue(name, updatedDate);
  };

  const clearDOB = () => {
    setDate('');
    form.setFieldValue(name, '');
  };

  useEffect(() => {
    if (field.value) {
      setDate(new Date(field.value));
    }
  }, [field.value]);

  const hasError = touched[name] && errors[name];
  console.log('haserror', errors);
  return (
    <div className="date-picker date-picker--single">
      <div className={`${hasError ? 'has-error' : ''} bfc-date-from-wrapper date-picker-start`}>
        <DatePicker
          selected={date}
          selectsStart
          // startDate={startDate}
          // endDate={endDate}
          // onSelect={handleChange}
          onChange={handleChange}
          dateFormat="dd-MM-yyyy"
          placeholderText={placeholder || 'select date'}
          dropDownMode="select"
          withPortal={isMobileOnly}
          disabled={disabled}
          onFocus={e => e.target.blur()}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
        />
        {touched[name] && errors[name] && <span className="form__form-group-error">{errors[name]}</span>}
        {date && !disabled ? (
          <CloseIcon
            className="datepicker-dob-close__icon w-auto"
            onClick={() => {
              clearDOB();
            }}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default FormikDatePickerField;
