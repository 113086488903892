/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = '/BioFuelDeliveries/api';

const FetchDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId = async (id: number) => {
  const params = {
    transport_delivery_schedule_id: id, //4267,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/DeliveryAssignment/FetchDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId?${qs}`;
  const details = await fetch.fetchList(url);
  return details;
};

const FetchDeliveryAssignmentAgainstTransportDealMasterId = async (id: number, pageSize: number) => {
  const params = {
    transport_deal_master_id: id,
    offset: 0,
    page_size: pageSize,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/DeliveryAssignment/FetchDeliveryAssignmentAgainstTransportDealMasterId?${qs}`;

  const deliveryAssignmentList = await fetch.fetchList(url);

  return deliveryAssignmentList;
};

const FetchDeliveryAssignmentAgainstCommodityDealMasterId = async (id: number, pageSize: number) => {
  const params = {
    commodity_Deal_Master_Id: id,
    offset: 0,
    page_size: pageSize,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/DeliveryAssignment/FetchDeliveryAssignmentAgainstCommodityDealMasterId?${qs}`;

  const deliveryAssignmentList = await fetch.fetchList(url);

  return deliveryAssignmentList;
};

const SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId = async (payload) => {
  const url = `${BASE_URL}/DeliveryAssignment/SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId`;

  const details = await fetch.create(url, payload);

  return details;
};
export default {
  FetchDeliveryAssignmentAgainstTransportDealMasterId,
  FetchDeliveryAssignmentAgainstCommodityDealMasterId,
  SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId,
  FetchDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId,
};
