import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import * as helpers from 'services/helpers';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const submitForApproval = async (affiliate_id) => {
  try {
    const bodyParams = reqTransform.submitForApproval(affiliate_id);
    const qs = utils.getQs(bodyParams);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SendKYCForApproval?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.submitForApproval(resData);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SendKYCForApproval');
    console.log('kyc-submitForApproval---->err-->', err);
    console.log('kyc-submitForApproval---->errors-->', errors);
    throw errors;
  }
};
