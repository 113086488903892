import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { qualityService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'qualityServices',
  initialState: adapter.getInitialState({
    busy: false,
    dirty: true,
    reportData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setDirty(state, action) {
      let dirty = action.payload;
      state.dirty = dirty;
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchList(params);
    if (params?.isCsv) {
      dispatch(slice.actions.setReportData(Object.values(resData)));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const fetchQualityServiceListCt = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchQualityServiceListCt(params);
    if (params?.isCsv) {
      dispatch(slice.actions.setReportData(Object.values(resData)));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const FetchSharedQualityReports = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.FetchSharedQualityReports(params);
    if (params?.isCsv) {
      dispatch(slice.actions.setReportData(Object.values(resData)));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const fetchAllFTKMachineDetails = (params) => async (dispatch) => {
  !params?.isCsv && dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchAllFTKMachineDetails(params);
    if (params?.isCsv) {
      dispatch(slice.actions.setReportData(Object.values(resData)));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateFTKMachine = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.saveAndUpdateFTKMachine(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteFTKMachine = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.deleteFTKMachine(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateFTKMachineAllotment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.saveAndUpdateFTKMachineAllotment(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllFTKMachineAllotment = (params) => async (dispatch) => {
  !params?.isCsv && dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchAllFTKMachineAllotment(params);
    if (params?.isCsv) {
      dispatch(slice.actions.setReportData(Object.values(resData)));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateFTKResult = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.saveAndUpdateFTKResult(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllFTKResult = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchAllFTKResult(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllFTKDetailsCombine = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await qualityService.fetchAllFTKDetailsCombine(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchQualityServiceListCt,
  FetchSharedQualityReports,
  fetchAllFTKMachineDetails,
  saveAndUpdateFTKMachine,
  deleteFTKMachine,
  saveAndUpdateFTKMachineAllotment,
  fetchAllFTKMachineAllotment,
  saveAndUpdateFTKResult,
  fetchAllFTKResult,
  fetchAllFTKDetailsCombine,
};

const selectors = {
  ...adapter.getSelectors((state) => state.qualityServices),
};

export { actions, selectors };

export default slice.reducer;
