import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const deliverySchema = yup.object().shape({});

export const fetchDealAllotments = async (params = {}) => {
  try {
    let res = await api.fetchDealAllotments(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDealAllotmentsELoc = async (params = {}) => {
  try {
    let res = await api.fetchDealAllotmentsELoc(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListByPersonId = async (params = {}) => {
  try {
    let res = await api.fetchListByPersonId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUnconfirmedDelieveries = async (params = {}) => {
  try {
    let res = await api.fetchUnconfirmedDelieveries(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchListByPersonIdReport = async (params = {}) => {
  try {
    let res = await api.fetchListByPersonIdReport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(deliverySchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateCT = async (id, params = {}) => {
  try {
    await helpers.validateSchema(deliverySchema, params);
    const res = await api.updateCT(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateUnusedDeliveryStatus = async (params, deliveryArray) => {
  try {
    let res = await api.updateUnusedDeliveryStatus(params, deliveryArray);
    return res;
  } catch (err) {
    throw err;
  }
};
