import _ from 'lodash';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import { rentalService } from 'services';
import { useParams } from 'react-router-dom';

export const handleVerifyEquipment = async (values, formikBag) => {
  //const { id } = getDecryptedRouteParams(useParams())
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Equipment Details',
      key: 'req-form',
    });
    const res = await rentalService.verifyEquipmentProfile(values);
    notifications.show({
      type: 'success',
      message: 'Verified Equipment Details',
      key: 'req-form',
    });

    redirect.web(`/control-tower/equipment-rental?page=1`);

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export default {
  handleVerifyEquipment,
};
