import dayjs from 'dayjs';
import _, { update } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory, useRouteMatch } from 'react-router-dom';
// import LogoLoader from 'common/LogoLoader';
import { actions as commodityIndentDetailsActions } from 'redux/commodityIndentDetails';
// import OverviewHeader from 'common/OverviewHeader';
import Header from '../network/components/header'
import { COMMODITY_TYPE, COMMODITY_FILTER } from 'constants/pageParams';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import useQuery from 'lib/hooks/useQuery';
import followHeaderIcon from 'assets/images/icons/followHeader.svg';
import preferredHeaderIcon from 'assets/images/icons/preferredHeader.svg';
import RatingsPopup from 'shared/components/ratingsPopup';
import ShieldIcon from 'common/Shield/shield';
import verifiedHeaderIcon from 'assets/images/icons/verifiedHeader.svg';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import CommodityField from 'common/TableComponents/commodityField';
import QuantityField from 'common/TableComponents/quantityField';
import InvoiceField from 'common/TableComponents/invoiceField';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import ClosingDateField from 'common/TableComponents/closingDateField';
import ClosingDateFieldPlan from 'common/TableComponents/closingDataFieldPlan';
import DeliveryDateField from 'common/TableComponents/deliveryDateField';
import PriceField from 'common/TableComponents/priceField';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import FilterUtils from 'lib/filterUtils';
import { Table, Tag, Popover, Menu, Dropdown, Tooltip, Skeleton } from 'antd';
import ProgressBar from 'common/SupplierProgressBar';
import AntPagination from 'common/AntTablePagination';
import utils, { decryptionInHex,  getDecryptedRouteParams, getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import AntTableActions from 'common/AntTableActions';
import InfoIcon from 'common/Icons/InfoIcon';
import OverviewNav from 'common/OverviewNav';
import { actions as indentEnabledCompanyActions } from 'redux/indentEnabledCompanies';

import 'antd/dist/antd.css';

function InvoiceListPage(props) {
  const dispatch = useDispatch();
  let { id, type, filter, place } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);
  const { url } = useRouteMatch();
  const history = useHistory();

  let {
    page,
    company_name,
    commodity_form_id,
    commodity_id,
    address_id,
    location_code,
    rating,
    is_followed_by_person,
    preferred_blocked_enum,
    req_offer_status_enum,
    commodity_req_offer_id,
    req_offer_response_status_enum,
    plan_name,
    auction_status,
    commodity_auction_master_id,
    commodity_action_response_detail_id,
    auction_response_phase_enum,
    tick_color_enum_location,
    auction_duration_enum,
    distance_range,
    commodity_standard_enum,
  } = useQuery();

  const commodity = useSelector((state) => state.commodityIndentDetails);
  const user = useSelector((state) => state.user);
  const reqOfferTypeEnum = _.get(commodity, `entities`) || {};
  // const reqOfferTypeEnumFilter = Object.values(reqOfferTypeEnum).filter(item => item.req_offer_type_enum);

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const csvLink = useRef();
  const tableRef = useRef();

  const [timestamp, setTimeStamp] = useState(Date.now());
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const setEmptyData = () => {
    setTimeStamp(Date.now());
  };
  const encodeBackUrl = utils.encodeBackUrl();
  const roles = useSelector((state) => state.rolesAndRights);

  const rolesList = roles.entities || {};
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);


  let myCommodityName = 'Buys#';
  let indentyCommodityName = 'Buys';
  let myBidOfferName = 'Offers#';
  if (type == 'offers') {
    myCommodityName = 'Sells#';
    indentyCommodityName = 'Sells';
    myBidOfferName = 'Bids#';
  }
  const loc = getDecryptedUrl(window.location.href);

  if(loc?.includes('/control-tower/seller-indent-deals')) {
    myCommodityName = 'Sells#';
  }


  const indent = true;
  const ctIndentCompany = true;
  let createLink = `/commodity/${loc?.includes('/control-tower/indent-deals') ? 'requirements' : 'offers'}/create?back=${encodeBackUrl}&indent=${indent}&ctIndentCompany=${ctIndentCompany}&isSmartBuy=${loc?.includes('/control-tower/indent-deals') ? true : false}`;

  const handleCreate = () => {
    dispatch(indentEnabledCompanyActions.setAll({}));
    history.push(getUrlWithEncryptedQueryParams(createLink));
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'commodity_req_offer_id':
        delete params['commodity_req_offer_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_req_offer_id'));
        break;
      case 'commodity_form_code':
        delete params['commodity_form_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_form_id'));
        break;
      case 'company_name':
        delete params['company_name'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('company_name'));
        break;
      case 'commodity_code':
        delete params['commodity_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_id'));
        break;
      case 'coarse_loaction':
      case 'locations':
        delete params['location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('location_code'));
        break;
      case 'req_offer_response_status_enum_code':
        delete params['req_offer_response_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('req_offer_response_status_enum'));
        break;
      case 'auction_response_phase_enum_code':
        delete params['auction_response_phase_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('auction_response_phase_enum'));
        break;
      case 'auction_duration_enum_code':
        delete params['auction_duration_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('auction_duration_enum'));
        break;
      case 'commodity_standard_enum_code':
        delete params['commodity_standard_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_standard_enum'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'commodity_req_offer_id':
        params = {
          ...params,
          commodity_req_offer_id: e,
        };
        break;
      case 'commodity_form_code':
        params = {
          ...params,
          commodity_form_id: e,
        };
        break;
      case 'company_name':
        params = {
          ...params,
          company_name: e,
        };
        break;
      case 'commodity_code':
        params = {
          ...params,
          commodity_id: e,
        };
        break;
      case 'coarse_loaction':
      case 'locations':
        params = {
          ...params,
          location_code: e,
        };
        break;
      case 'req_offer_response_status_enum_code':
        params = {
          ...params,
          req_offer_response_status_enum: e,
        };
        break;
      case 'auction_response_phase_enum_code':
        params = {
          ...params,
          auction_response_phase_enum: e,
        };
        break;
      case 'auction_duration_enum_code':
        params = {
          ...params,
          auction_duration_enum: e,
        };
        break;
      case 'commodity_standard_enum_code':
        params = {
          ...params,
          commodity_standard_enum: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const verifiedContent = <p style={{ lineHeight: '10px', color: 'var(--unnamed-color-000)' }}>Verified</p>;

  const followedContent = <p style={{ lineHeight: '10px', color: 'var(--unnamed-color-000)' }}>Follow</p>;

  const preferredContent = <p style={{ lineHeight: '10px', color: 'var(--unnamed-color-000)' }}>Preferred</p>;

  const auctionContent = <p style={{ lineHeight: '10px', color: 'var(--unnamed-color-000)' }}>Auctions</p>;

  const standardToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV upto 3400</p>
      <p className="p-0 m-0">Ash ~ 12% or more</p>
      <p className="p-0 m-0">Moist. ~ 12% or more</p>
    </div>
  );

  const premiumToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 3800 or more</p>
      <p className="p-0 m-0">Ash ~ 8% or less </p>
      <p className="p-0 m-0">Moist. ~ 8% or less</p>
    </div>
  );

  const superiorToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 3400 - 3800</p>
      <p className="p-0 m-0">Ash ~ 8% - 12%</p>
      <p className="p-0 m-0">Moist. ~ 8% - 12%</p>
    </div>
  );

  const warningToolTipCode = (
    <div>
      <p>Some quality specs missing or inconsistent.</p>
    </div>
  )

  const INVOICE_TABLE_HEADERS = [
    {
      title: `${myCommodityName}`,
      dataIndex: 'commodity_req_offer_id',
      width: '100px',
      align: 'center',
      fixed: true,
      sorter: (a, b) => a.commodity_req_offer_id - b.commodity_req_offer_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex align-items-center">
          <div className="commodity-form">
            <table>
              <tr>
                <td style={{ width: '50%', textAlign: 'end' }}>
                  <Link to={row.detailsPageLink} style={{ color: 'var(--unnamed-color-51c4c3)' }}>
                    {row.commodity_req_offer_id}
                  </Link>
                </td>
                <td style={{ width: '50%' }}>
                  {row?.is_published_by_control_tower === true ? (
                    <Popover placement="top" content={"Published by Control Tower"} trigger="hover">
                      <div className="bfc-table-list-components text-center"><InfoIcon size={14} className="ml-2" /></div>
                    </Popover>
                  ) : ''}
                </td>
              </tr>
            </table>
          </div>
        </div>
        // <div className="bfc-table-list-components text-center">
        //   <Link to={row.detailsPageLink} style={{ color: 'var(--unnamed-color-51c4c3)' }}>
        //     {row.commodity_req_offer_id}
        //   </Link>
        // </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'commodity_req_offer_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_req_offer_id}
          name={`${myCommodityName}`}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_req_offer_id'),
      filteredValue: commodity_req_offer_id !== undefined ? commodity_req_offer_id : null,
    },
    {
      title: `Company Name`,
      dataIndex: 'company_name',
      width: '170px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.company_name}
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'company_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={company_name}
          name={'Company'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'company_name'),
      filteredValue: company_name !== undefined ? company_name : null,
    },
    {
      title: 'Form',
      dataIndex: 'commodity_form_code',
      width: '100px',
      align: 'center',
      sorter: (a, b) => a.commodity_form_code.localeCompare(b.commodity_form_code),
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.commodity_form_code}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_form_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_form_id}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_form_id'),
      filteredValue: commodity_form_id !== undefined ? commodity_form_id : null,
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity_code',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <CommodityField commodity_residue_code={row.commodity_residue_code} commodity_code={row.commodity_code} />
      ),
      sorter: (a, b) => a.commodity_code.localeCompare(b.commodity_code),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_id}
          name={'Commodity'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_id'),
      filteredValue: commodity_id !== undefined ? commodity_id : null,
    },
    {
      title: 'Grade',
      dataIndex: 'commodity_standard_enum_code',
      key: 'commodity_standard_enum_code',
      width: '120px',
      sorter: (a, b) =>
        a.commodity_standard_enum_code &&
        a.commodity_standard_enum_code.localeCompare(b.commodity_standard_enum_code && b.commodity_standard_enum_code),
      ellipsis: true,
      align: 'center',

      render: (text, row) => (
        <span>
          {text == 'Standard' && (
            <div className='d-flex align-items-center justify-content-center grouping-tag'>
              <Tag color={'cyan'} key={text}>
                <Popover placement="top" content={standardToolTipCode} trigger="hover">
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Popover>
              </Tag>
              {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && <div className='warning-text'>
                <Popover placement="top" content={warningToolTipCode} trigger="hover">
                  <WarningFilled style={{ color: 'red' }} />
                </Popover>
              </div>}
            </div>
          )}
          {text == 'Premium' && (
            <div className='d-flex align-items-center justify-content-center grouping-tag'>
              <Tag color={'gold'} key={text}>
                <Popover placement="top" content={premiumToolTipCode} trigger="hover">
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Popover>
              </Tag>
              {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && <div className='warning-text'>
                <Popover placement="top" content={warningToolTipCode} trigger="hover">
                  <WarningFilled style={{ color: 'red' }} />
                </Popover>
              </div>}
            </div>
          )}

          {text == 'Superior' && (
            <div className='d-flex align-items-center justify-content-center grouping-tag'>
              <Tag color={'geekblue'} key={text}>
                <Popover placement="top" content={superiorToolTipCode} trigger="hover">
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Popover>
              </Tag>
              {row.commodity_standard_enum !== 0 && row.is_show_warning_on_commoditygroup == 1 && <div className='warning-text'>
                <Popover placement="top" content={warningToolTipCode} trigger="hover">
                  <WarningFilled style={{ color: 'red' }} />
                </Popover>
              </div>}
            </div>
          )}
          {text == 'Unclassified' && (
            <div className='d-flex align-items-center grouping-tag'>
              <Tag color={'magenta'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            </div>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'commodity_standard_enum_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_standard_enum}
          name={'Grade'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_standard_enum'),
      filteredValue: commodity_standard_enum !== undefined ? commodity_standard_enum : null,
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '80px',
      align: 'center',
      render: (text, row) => (
        <QuantityField
          qty={row.qty}
          periodicity_enum_code={row.periodicity_enum_code}
          qty_uom_code={row.qty_uom_code}
        />
      ),
    },
    {
      title: `Delivered Qty`,
      dataIndex: 'delivery_qty',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-right">
          {row.delivery_qty ? row.delivery_qty : 0}
        </div>
      ),
    },
    {
      title: loc?.includes('/control-tower/indent-deals') ? `Purchased Qty` : 'Sell Qty',
      dataIndex: 'purchased_qty',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-right">
          {row.purchased_qty ? row.purchased_qty : 0}
        </div>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'expected_price',
      width: '80px',
      align: 'center',
      render: (text, row) =>
        <PriceField expected_price={row.expected_price} qty_uom_code={row.price_uom_code} special_buy_sell_type_enum_code={row.special_buy_sell_type_enum == 5 && "Rfq"} />
    },
    {
      title: 'Delivery Dates',
      dataIndex: 'from_date',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <DeliveryDateField
          form_date={new Date(row.from_date).getFullYear() === 9999 ? 'N/A' : row.from_date}
          to_date={new Date(row.to_date).getFullYear() === 9999 ? 'N/A' : row.to_date}
        />
      ),
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'coarse_loaction',
    //   width: '100px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components text-center">
    //       <p>{row.coarse_loaction === null ? '-' : row.coarse_loaction}</p>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.coarse_loaction.localeCompare(b.coarse_loaction),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'coarse_loaction'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={location_code}
    //       name={'Location'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'location_code'),
    //   filteredValue: location_code !== undefined ? location_code : null,
    // },
    {
      title: (text) => (
        <Popover content={verifiedContent}>
          <img src={verifiedHeaderIcon} alt="" height={18} style={{ width: 'fit-content' }} />
        </Popover>
      ),
      dataIndex: 'tick_color_enum_location',
      width: '120px',
      align: 'center',
      render: (text, row) => {
        return (
          <>
            <ShieldIcon
              tickvalue={
                row.tick_color_enum_location == null || row.tick_color_enum_location == 0
                  ? 4
                  : row.tick_color_enum_location
              }
            />
            <div className="supplier-rating-table">
              {/* <ProgressBar value={row.extended_profile_location_ratings} id={row?.extended_profile_id} tickvalue={row.tick_color_enum_location} /> */}
              <ProgressBar value={row.extended_profile_location_ratings} id={row?.extended_profile_id} addressId={row?.address_id} tickvalue={row.tick_color_enum_location} />
            </div>
          </>
        );
      },
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'tick_color_enum_location'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={tick_color_enum_location}
          name={'Verified'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'tick_color_enum_location'),
      filteredValue: tick_color_enum_location !== undefined ? tick_color_enum_location : null,
    },
    {
      title: 'Status',
      dataIndex: 'req_offer_status_enum_code',
      width: '120px',
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Withdrawn' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.req_offer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Published' && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.req_offer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Completed' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.req_offer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.req_offer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Expired' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.req_offer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'req_offer_status_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={req_offer_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'req_offer_status_enum'),
      filteredValue: req_offer_status_enum !== undefined ? req_offer_status_enum : null,
    },
    {
      title: '',
      dataIndex: 'operation_id',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            (row.req_offer_status_enum_code != 'Draft' && row.req_offer_status_enum_code != 'Withdrawn') ? (
              <AntTableActions
                show={true}
                myCommodityName={row.reqOfferTypeEnumCode}
                row={row.convertLink}
                row2={row.createAuctionLink}
                attachDealLink={row?.attachDealLink}
                showFlag={true}
                deleteFlag={false}
              />
            ) : ''
          ),
          props: {},
        };
        if (CT_parent) {
          return obj;
        } else {
          return null;
        }
      },
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const busy = commodity.busy;
  let tableData = busy ? [] : Object.values(reqOfferTypeEnum || {});
  const reversed = tableData.reverse();

  tableData = tableData.map((d) => {
    let reqOfferTypeEnumCode = d.req_offer_type_enum_code?.toLowerCase() + 's';
    let convertLink = `/commodity/${reqOfferTypeEnumCode}/edit/${d.commodity_req_offer_id}?back=${encodeBackUrl}&convertToBuy=true&indent=false&isSmartBuy=${loc?.includes('/control-tower/indent-deals') ? 'true' : 'false'}`;
    let detailsPageLink = `/commodity/${reqOfferTypeEnumCode}/${d.commodity_req_offer_id}/overview?back=${encodeBackUrl}&indent=true&isSmartBuy=${loc?.includes('/control-tower/indent-deals') ? 'true' : 'false'}`;
    let createAuctionLink = `/commodity/${reqOfferTypeEnumCode}/auction/edit/${d.commodity_req_offer_id}?convertToBuy=true&indent=true&back=${encodeBackUrl}&isSmartBuy=${loc?.includes('/control-tower/indent-deals') ? 'true' : 'false'}`;
    let attachDealLink = `/control-tower/deals?${`${loc?.includes('/control-tower/indent-deals') ? 'consignee_id' : 'consignor_id'}=${user?.profile?.person?.company_id}`}&deal_status_enum=1&indent=true&indent_id=${d.commodity_req_offer_id}&type=${reqOfferTypeEnumCode}&back=${encodeBackUrl}&isSmartBuy=${loc?.includes('/control-tower/indent-deals') ? 'true' : 'false'}`;
    return {
      ...d,
      reqOfferTypeEnumCode: reqOfferTypeEnumCode,
      convertLink: getUrlWithEncryptedQueryParams(convertLink),
      detailsPageLink: getUrlWithEncryptedQueryParams(detailsPageLink),
      createAuctionLink: getUrlWithEncryptedQueryParams(createAuctionLink),
      attachDealLink: getUrlWithEncryptedQueryParams(attachDealLink),
    };
  });

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;
  tableData = _.orderBy(tableData, ['invoiceDateTime'], ['desc']);

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      company_name,
      commodity_form_id,
      commodity_id,
      address_id,
      location_code,
      rating,
      is_followed_by_person,
      preferred_blocked_enum,
      req_offer_status_enum,
      commodity_req_offer_id,
      req_offer_response_status_enum,
      plan_name,
      auction_status,
      commodity_auction_master_id,
      auction_response_phase_enum,
      commodity_action_response_detail_id,
      tick_color_enum_location,
      auction_duration_enum,
      distance_range,
      commodity_standard_enum,
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
      ...(loc?.includes('/control-tower/indent-deals') ? {req_offer_type_enum: 1} : {req_offer_type_enum: 2})
    };
    const params = {
      filters: filterParams,
      page_size: rowsPerPage,
      offset: (parseInt(page) - 1) * rowsPerPage,
    };

    dispatch(commodityIndentDetailsActions.fetchAllIndents(params));
  }, [
    type,
    filter,
    page,
    rowsPerPage,
    company_name,
    commodity_form_id,
    commodity_id,
    address_id,
    location_code,
    rating,
    is_followed_by_person,
    preferred_blocked_enum,
    req_offer_status_enum,
    commodity_req_offer_id,
    req_offer_response_status_enum,
    plan_name,
    auction_status,
    commodity_auction_master_id,
    auction_response_phase_enum,
    commodity_action_response_detail_id,
    tick_color_enum_location,
    auction_duration_enum,
    distance_range,
    commodity_standard_enum,
    loc
  ]);

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  let links = [
    { to: '/control-tower/indent-deals?page=1', title: 'Smart Buyer' },
    { to: '/control-tower/seller-indent-deals?page=1', title: 'Smart Seller' },
  ];

  return (
    <div className="">
      <div className="">
        <OverviewNav links={links} rightPanContent={
          CT_parent && <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleCreate()}>
            Create
          </button>
        } />
        {/* <Header
          heading={loc?.includes('/control-tower/indent-deals') ? `Smart Buyer` : 'Smart Seller'}
          rightPanContent={
            CT_parent && <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => handleCreate()}>
              Create
            </button>
          }
        /> */}
      </div>
      <div className="bfc-body">
        {/* {busy && (
          <div className="commodity-wrapper">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )} */}

        <div className="invoice-table">
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? INVOICE_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : INVOICE_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 550 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              clearFilters={clearFilters}
              rowsPerPage={rowsPerPage}
              setScrollToFirstRow={setScrollToFirstRow}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceListPage;
