/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = '/BioFuelTransportRequirementOffers/api';

export const HireTypeEnum = {
  DAY_BASIS: 1,
  KILOMETER_BASIS: 2,
};

export const TransportRequirementOfferSchema = yup.object().shape({
  transport_req_offer_id: yup.number().required().positive().integer(),
  number_of_vehicles: yup.number().required().positive(),
  start_date: yup.date().required(),
  end_date: yup.date().required(),
  per_day_rate: yup.number().when('hire_type_enum', {
    is: HireTypeEnum.DAY_BASIS,
    then: yup.number().positive().required(),
    otherwise: yup.number().default(0),
  }),
  per_km_rate: yup.number().when('hire_type_enum', {
    is: HireTypeEnum.KILOMETER_BASIS,
    then: yup.number().positive().required(),
    otherwise: yup.number().default(0),
  }),
  minimum_km_per_day: yup.number().when('hire_type_enum', {
    is: HireTypeEnum.KILOMETER_BASIS,
    then: yup.number().positive().required(),
    otherwise: yup.number().default(0),
  }),
  extra_run_rate_per_km: yup.number().when('hire_type_enum', {
    is: HireTypeEnum.KILOMETER_BASIS,
    then: yup.number().positive().required(),
    otherwise: yup.number().default(0),
  }),
  follow_flag: yup.bool(),
  rate_uom_id: yup.number().positive().required(),
  hire_type_enum: yup.number().positive().required(),
  rate_currency_id: yup.number().positive().required(),
  payment_term_id: yup.number().positive().required(),
  transport_company_id: yup.number().positive().required(),
});

export type TransportRequirementOfferType = yup.InferType<typeof TransportRequirementOfferSchema>;

const FetchTransportOfferDetailsAgainstId = async (id: number) => {
  const params = {
    transport_req_offer_response_id: id,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportOffer/FetchTransportOfferDetailsAgainstId?${qs}`;

  const details = await fetch.fetchOne(url);

  return details;
};

const FetchAllTransportOffersAgainstEntityId = async (pageSize: number) => {
  const params = {
    entity_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.user_type_enum || 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
    offset: 0,
    page_size: pageSize,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportOffer/FetchAllTransportOffersAgainstEntityId?${qs}`;

  const details = await fetch.fetchList(url);

  return details;
};

const SaveTransportOffer = async (payload) => {
  const params = {
    ...payload,
    person_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.user_type_enum,
    company_affiliate_enum: 2, //@TODO: hardcoded
  };

  const url = `${BASE_URL}/TransportOffer/SaveTransportOffer`;

  const response = await fetch.create(url, params);

  return response;
};

const WithdrawTransportOfferById = async (id: number) => {
  const params = {
    transport_Req_Offer_Response_Id: id,
    person_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportOffer/WithdrawTransportOfferById?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const FetchOffersAgainstTransportRequirementId = async (transportReqOfferId: number, pageSize: number) => {
  const params = {
    transport_req_offer_id: transportReqOfferId,
    offset: 0,
    page_size: pageSize,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/FetchOffersAgainstTransportRequirementId?${qs}`;

  const details = await fetch.fetchList(url);

  return details;
};

export default {
  FetchAllTransportOffersAgainstEntityId,
  FetchTransportOfferDetailsAgainstId,
  SaveTransportOffer,
  WithdrawTransportOfferById,
  FetchOffersAgainstTransportRequirementId,
};
