import _ from 'lodash';
import utils from 'lib/utils';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  updatePaymentDetails(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchDetails(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchDocument(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchJobCardDocument(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchBankDetails(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchCreditLimitList(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchChat(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  create(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  createCreditLimit(params) {
    let bodyParams = {
      ...params,
      credit_limit: parseFloat(params['credit_limit']),
    };
    return bodyParams;
  },
  createBank(params) {
    let bodyParams = {
      ...params,
      base_roi: parseFloat(params['base_roi']),
      base_amount_offered: parseFloat(params['base_amount_offered']),
    };
    return bodyParams;
  },
  createInvoiceRespond(params) {
    let bodyParams = {
      ...params,
      approved_roi: parseFloat(params['approved_roi']),
      disbursed_amount: parseFloat(params['disbursed_amount']),
    };
    return bodyParams;
  },
  createResponse(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchUploadList(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  sendMessage(params) {
    let qsParams = {
      ...params,
      bf_finance_request_proposal_id: parseInt(params['bf_finance_request_proposal_id']),
    };
    // return utils.getQs(qsParams);
    return qsParams;
  },

  fetchMessages(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchSupplierRatings(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(data, 'company_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id'),
    };
    return res;
  },
  fetchFinancePartnerDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id'),
    };
    return res;
  },
  fetchChat(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id'),
    };
    return res;
  },
  create(resData) {
    return resData;
  },
  createBank(resData) {
    return resData;
  },
  createResponse(resData) {
    return resData;
  },
  fetchUploadList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUploadDetails(details);
    });

    return _.keyBy(uploadData, 'attachment_id');
  },
  fetchUploadDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'attachment_id') || 0,
    };
    return res;
  },

  fetchMessages(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchMessagesDetails(details);
    });

    return _.keyBy(data, 'notification_master_id');
  },
  fetchMessagesDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'notification_master_id'),
    };
    return res;
  },
  fetchBankDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id'),
    };
    return res;
  },
  createInvoiceRespond(resData) {
    return resData;
  },

  fetchCreditLimitList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCreditLimitListDetails(details);
    });

    return _.keyBy(uploadData, 'bf_finance_request_proposal_id');
  },
  fetchCreditLimitListDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'bf_finance_request_proposal_id') || 0,
    };
    return res;
  },

  fetchCreditLimitCompanyList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCreditLimitCompanyListDetails(details);
    });

    return _.keyBy(uploadData, 'company_id');
  },
  fetchCreditLimitCompanyListDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id') || 0,
    };
    return res;
  },
  createCreditLimit(resData) {
    return resData;
  },
  fetchDocument(resData) {
    return resData;
  },
  fetchJobCardDocument(resData) {
    return resData;
  },
  updatePaymentDetails(resData) {
    return resData;
  },
  fetchSupplierRatings(resData, params) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.supplierDetails(details);
    });

    return _.keyBy(data, 'score_type');
  },
  supplierDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'score_type') || '',
    };
    return res;
  },
};
