import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as refDataActions } from 'redux/referenceData';
import { actions as filterOptionsActions } from 'redux/filterOptions';

import _ from 'lodash';

export default function FormikAOISelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialValue,
    entity,
    disabled,
    enumApiCall,
    qparams,
    extendedProfileQuality,
    isCompanyAdmin,
    ...rest
  } = props;


  const dispatch = useDispatch();
  // const referenceData = useSelector((state) => state.referenceData);
  // const filterOptions = useSelector((state) => state.filterOptions);
  // let entityData = _.get(filterOptions, 'data.InterestCommodities') || [];
  let entityData = useSelector((state) => state.filterOptions.data[entity] || []);

  const [value, setValue] = React.useState([]);
  let loading = false;

  let options = [];
  if (!_.isEmpty(entityData)) {
    options = _.map(entityData, (item, index) => {
      return {
        label: item?.text || '',
        value: {
          id: parseInt(item.value),
          ...item,
        },
      };
    });
  }

  // console.log('check filterOptions', entityData, options, initialValue, value);

  if (!entityData.length) {
    loading = true;
  }

  // console.log('v-------------entityData before------------------->', options, entityData, initialValue, value);

  useEffect(() => {
    dispatch(filterOptionsActions.getFilterData('InterestCommodities'));
  }, []);

  useEffect(() => {
    const initialSelected = options.filter((o) => initialValue.includes(o.value?.id));
    // console.log('check initialSelected', options, initialValue, initialSelected);
    setValue(initialSelected);
  }, [initialValue]);

  const transformOptions = options.filter((o) => !initialValue.includes(o.value?.id));
  // console.log('v-------------entityData after------------------->', isCompanyAdmin);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-multi-select ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        multiple
        size="small"
        disableClearable
        fullWidth
        limitTags={10}
        loading={loading}
        disabled={disabled}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        options={transformOptions}
        value={value}
        onChange={(event, newValue) => {
          // console.log('FormikAOISelect----newValue---->', newValue);
          const tranformValue = (newValue || []).map((item) => {
            // console.log(`newValue item`, item);
            const newObject = item?.label

            return newObject;
          })
          setValue(tranformValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
