import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  create(params) {
    const commonParams = {
      ...params,
      operation_enum: params?.isPublish ? 'update' : 'Add',
      auction_phase_enum: params?.isPublish ? 'Published' : 'Created',
      commodity_standard_enum: 'Premium',
    };
    let bodyParams;
    if (params?.isPublish) {
      bodyParams = {
        ...commonParams,
      };
    } else {
      bodyParams = {
        ...commonParams,
        commodity_auction_master_id: 0,
      };
    }
    return bodyParams;
  },
};

export const resTransform = {
  create(params) {
    return params;
  },
  fetchMultipartyAuctions(resData) {
    let commodityMultipartyAuctionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllMultipartyAuctionDetails(details);
    });

    return _.keyBy(commodityMultipartyAuctionData, 'commodity_auction_master_id');
  },
  fetchAllMultipartyAuctionDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_auction_master_id') || 0,
    };
    return data;
  },
  fetchAllMultipartyAuctionOverview(resData, id) {
    let data = {
      ...resData,
      id: id,
    };
    return data;
  },
};
