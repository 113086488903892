import _ from 'lodash';
import utils from 'lib/utils';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchDealsList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchFinanceList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchDetails(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  createConfirm(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchBankList(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  create(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  update(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  createInvoiceApply(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(data, 'bf_finance_request_proposal_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'bf_finance_request_proposal_id'),
    };
    return res;
  },
  fetchUserList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUserDetails(details);
    });
    return _.keyBy(data, 'bf_finance_request_proposal_id');
  },
  fetchUserDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'bf_finance_request_proposal_id'),
    };
    return res;
  },
  update(resData) {
    return resData;
  },
  create(resData) {
    return resData;
  },

  fetchFinanceList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchFinanceDetails(details);
    });
    return _.keyBy(data, 'invoice_master_id');
  },
  fetchFinanceDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'invoice_master_id'),
    };
    return res;
  },

  fetchProviderFinanceList(resData, isCsv) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchProviderFinanceDetails(details, isCsv);
    });
    return _.keyBy(data, 'bf_finance_invoice_application_id');
  },
  fetchProviderFinanceDetails(resData, isCsv) {
    let res;
    if (isCsv) {
      res = {
        ...resData,
        id: _.get(resData, 'bf_finance_invoice_application_id'),
        custom_invoices: _.get(resData, 'invoice_list')
          ?.map((item) => (item?.custom_invoice_number ? `${item?.custom_invoice_number}` : ''))
          ?.join(' , '),
        invoice_master_ids: _.get(resData, 'invoice_list')
          ?.map((item) => (item?.invoice_master_id ? `${item?.invoice_master_id}` : ''))
          ?.join(' | '),
      };
      delete res.invoice_list;
      delete res.invoices;
    } else {
      res = {
        ...resData,
        id: _.get(resData, 'bf_finance_invoice_application_id'),
      };
    }
    return res;
  },

  fetchBankList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchBankDetails(details);
    });
    return _.keyBy(data, 'to_company_id');
  },
  fetchBankDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'to_company_id'),
    };
    return res;
  },

  createInvoiceApply(resData) {
    return resData;
  },
  createConfirm(resData) {
    return resData;
  },
};
