import React from 'react';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import api from 'lib/api';
import * as handlers from './../handlers';

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [attempt, setAttempt] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const onResendClick = async (e) => {
    e.preventDefault();

    setLoading(true);

    let params = {
      ...props.params,
      isResend: true,
    };

    try {
      let res = await handlers.handleResendOTP(params);
      setLoading(false);
      setAttempt(attempt + 1);
      setSeconds(30);
    } catch (err) {
      console.log('onResendClick->err', err);
      // throw new SubmissionError(err)
      setLoading(false);
    }
  };

  const minTxt = minutes;
  const secTxt = seconds < 10 ? `0${seconds}` : seconds;
  const isCountdownVisible = minutes || seconds;

  const isResendDisabled = loading || isCountdownVisible || attempt >= 3;

  return (
    <div className="bfc-OTPtext">
      <p>
        <br />
        Didn’t get the code? {' '}
        {isResendDisabled ? (
          <span className="disabled" style={{ color: 'lightgrey' }}>Resend Verification Code in:</span>
        ) : (
          <span onClick={onResendClick} style={{ color: 'var(--unnamed-color-1b62ab)' }} className="cursor-pointer">Resend Verification Code</span>
        )}
        {' '}
        {loading ? (
          <span className="spinner-border spinner-border-sm bfc-clr-blue" role="status"></span>
        ) : (
          <span className={classnames('bfc-otp-timer', { invisible: !isCountdownVisible })}>
            ({minTxt}:{secTxt})
          </span>
        )}
      </p>
    </div>
  );
};

export default Timer;
