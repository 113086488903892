import React from 'react';
import Carousel from 'shared/components/carousel/CarouselSingle';

const BasicCarouselWithoutCaption = ({ images }) => (
  <Carousel>
    {images.map((img, indx) => (
      <div key={indx}>
        <img
          src={img.image_url}
          className="basic-carsousel-img"
          alt=""
        />
      </div>
    ))}
  </Carousel>
);

export default BasicCarouselWithoutCaption;
