import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as configurationAllVendorsActions } from 'redux/configurationAllVendors';
import _ from 'lodash';
import api from 'lib/api';
import { getDecryptedUrl } from 'lib/utils';

export default function VendorSelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    disabled,
    type,
    initialDisplayText,
    checkKYCStatus,
    placeholder,
    isStorage,
    ...rest
  } = props;

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const configurationAllVendors = useSelector((state) => state.configurationAllVendors);
  const loc = getDecryptedUrl(window.location.href);

  const vendorList = _.get(configurationAllVendors, `entities`) || {};
  let is_preferred = "0";
  if(type == 'preferred'){
    is_preferred= "1";
  }
  _.each(vendorList, (item, id) => {
    options.push({
      label: item.vendor_code + '' || '',
      value: {
        ...item,
        id: parseInt(item.vendor_id),
      },
    });
  });

  // if (!options.length) {
  //   loading = true;
  // }

  useEffect(() => {
    // if (searchValue !== '') {
      let params = {
        filters: {
          vendor_code: searchValue,
          ...(loc?.includes('smart-buyer-configuration') ? { Is_Indent_Enabled: '1' } : { Is_Seller_Indent_Enabled: '1' }),
        },
        offset: 0,
        page_size: 10,
      };
      dispatch(configurationAllVendorsActions.fetchAllVendorList(params));
    // }
  }, [searchValue]);

  // useEffect(() => {
  //   if (!loading) {
  //     let initiaEntityId = isStorage ? parseInt(initialDisplayText) : _.get(props, 'form.initialValues.entity_id') || 0;

  //     const initialSelected = options.filter((o) => initiaEntityId === isStorage ? o.value?.person_id : o.value?.id);

  //     setValue(initialSelected);
  //   }
  // }, [loading, isStorage, initialDisplayText]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const handleChange = async (e) => {
    const searchText = e.target.value;
    if (searchText.length >= 3) {
      setSearchValue(searchText);
    }
  };
  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`aoi-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={loading || disabled}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }} // filterOptions={(x) => x}
        // loading={true}
        options={options}
        // filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            placeholder={placeholder || ''}
            variant={variant || 'outlined'}
            label={label}
            onChange={(e) => handleChange(e)}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
