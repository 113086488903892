import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
import FormikCheckBox from 'shared/components/form/FormikCheckBox';
import { useSelector, useDispatch } from 'react-redux';
import LogoLoader from 'common/LogoLoader';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as handlers from './handlers';
import ReferenceDataSelect from '../components/ReferenceDataSelect';
import FormikAOISelect from './FormikAOISelect';
import FormikLocationSelect from './components/FormikLocationSelect';
import { Popconfirm } from 'antd';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const getInitialValues = (teamsDetails = {}, user) => {
  const personRole = _.get(teamsDetails, 'person_role') || [];
  const role_code1 = personRole.map((item) => item.role_id);
  const alloted_locations = _.get(teamsDetails, 'alloted_locations') || [];
  const mobileNumberCountryCode = _.get(user, 'profile.person.mobile_number_country_code') || 91;

  let companyAddressId = [];
  alloted_locations.map((item) => {
    companyAddressId.push(item.company_address_id);
  });
  let formFields = {
    team_member_id: _.get(teamsDetails, 'team_member_id') || 0,
    first_name: _.get(teamsDetails, 'first_name') || '',
    last_name: _.get(teamsDetails, 'last_name') || '',
    designation: _.get(teamsDetails, 'designation') || '',
    mobile_number: _.get(teamsDetails, 'mobile_number') || '',
    mobile_number_country_code:  _.get(teamsDetails, 'mobile_number_country_code') || mobileNumberCountryCode,
    email_id: _.get(teamsDetails, 'email_id') || '',

    entity_id: _.get(teamsDetails, 'entity_id') || 0,
    person_id: _.get(window, 'user.userid') || 0,
    is_maker: _.get(teamsDetails, 'is_maker') || false,
    is_checker: _.get(teamsDetails, 'is_checker') || false,
    role_ids: personRole.map((item) => item.role_id),
    company_address_ids: companyAddressId, //companyAddressId.map((item) => item.id),
  };
  // console.log('check role_code1', personRole, role_code1, formFields.role_ids);

  return formFields;
};

const TeamsForm = (props) => {
  const user = useSelector((state) => state.user);
  const userId = _.get(user, 'userid') || 0;
  const acl = utils.getACL(user);
  const team_member_id = props.team_member_id || 0;
  const id = props.id;
  const teams = useSelector((state) => state.teams);
  // const teamsDetails = _.get(teams, `entities[${team_member_id}]`) || {};
  const teamsDetails = _.get(teams, `team`)?.find(item => item.team_member_id === parseInt(team_member_id)) || {};

  // console.log('check teams=====>', teams, teamsDetails)
  const mobileNumber = _.get(teamsDetails, 'mobile_number', '')
  const mobileNumberCountryCode = _.get(teamsDetails, 'mobile_number_country_code') || 91;

  const [countryPhone, setCountryPhone] = useState('');
  const [isMobileReqOrUpdateErr, setIsMobileReqOrUpdateErr] = useState("")
  const dispatch = useDispatch();
  const history = useHistory();
  if (team_member_id && _.isEmpty(teamsDetails)) {
    return (
      <div className="bfc-modalform">
        <div className="bfc-modalform-header">
          <div className="icons-wrapper">
            <img src={Bidicon} alt="" />
            <span className="title">Team</span>
          </div>
          <div className="close-wrapper">
            <a href="" className="d-none">
              -
            </a>
            <Link to={getUrlWithEncryptedQueryParams(`/account/${id}/teams`)}>X</Link>
          </div>
        </div>
        <div className="modalform-body">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      </div>
    );
  }
  const cancel = (e) => {
    console.log(e);
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(teamsDetails, user)}
        onSubmit={(values, formik) => {
          team_member_id ? handlers.handleUpdate(values, formik, props?.setIsRerenderRequired, props?.isRerenderRequired, props?.page, setIsMobileReqOrUpdateErr) : handlers.handleCreate(values, formik, props?.setIsRerenderRequired, props?.isRerenderRequired, props?.page, setIsMobileReqOrUpdateErr)
        }}>
        {(formikBag) => {
          const { values, initialValues } = formikBag;

          return (
            <div>
              <div>
                <Form className="formik-form">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">First Name</span>
                            <span class="required">*</span>
                            <Field name="first_name" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Last Name</span>
                            <span class="required">*</span>
                            <Field name="last_name" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Designation</span>
                            <Field name="designation" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Mobile Number</span>
                            <span class="required">*</span>
                            {/* {!values?.email_id ? <span class="required">*</span> : ''} */}
                            <PhoneInput
                              name="mobile_number"
                              country={'in'}
                              value={mobileNumber ? mobileNumberCountryCode + mobileNumber : countryPhone}
                              preferredCountries={['in']}
                              onChange={(event, name) => {
                                setIsMobileReqOrUpdateErr('');
                                setCountryPhone(event);
                                const originalString = event;
                                const dialCodeLength = name.dialCode;
                                const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                                formikBag.setFieldValue('mobile_number_country_code', parseInt(name.dialCode) || 91);
                                formikBag.setFieldValue('mobile_number', stringWithoutFirstThree || '');
                                }
                              }
                              inputProps={{
                                name: 'mobile_number',
                                required: true,
                                autoFocus: false,
                              }}
                            />
                            {isMobileReqOrUpdateErr && <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "10px"
                            }}
                            >{isMobileReqOrUpdateErr}</div>}
                            {/* <Field name="mobile_number" component={FormikMaterialTextField} /> */}
                          </div>
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Email ID (for login)</span>
                            <span class="required">*</span>
                            {/* {!values?.mobile_number ? <span class="required">*</span> : ''} */}
                            <Field name="email_id" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Select Location(s)</span>
                            <span class="required">*</span>
                            <Field
                              name={`company_address_ids`}
                              component={FormikLocationSelect}
                              entity="hashTags"
                              initialValue={initialValues.company_address_ids}
                              cb={(selectedValue) => {
                                formikBag.setFieldValue(
                                  'company_address_ids',
                                  (selectedValue || []).map((v) => v.value.id),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Please select roles</span>
                            <Field
                              name={`role_ids`}
                              component={FormikAOISelect}
                              entity="roleTeam"
                              initialValue={initialValues.role_ids}
                              teamMemberId={team_member_id}
                              cb={(selectedValue) => {
                                formikBag.setFieldValue(
                                  'role_ids',
                                  (selectedValue || []).map((v) => v.value.id),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <br />
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    {team_member_id == userId ? (
                      <Popconfirm
                        title={`You may lose access to Profile if you change your Role.`}
                        onConfirm={formikBag.handleSubmit}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No">
                        <Button
                          color="primary"
                          type="button"
                          disabled={formikBag.isSubmitting}
                          // onClick={formikBag.handleSubmit}
                          className="next bfc-button-next">
                          {formikBag.isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          )}
                          {team_member_id ? 'Update' : 'Save'}
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        color="primary"
                        type="button"
                        disabled={formikBag.isSubmitting}
                        onClick={formikBag.handleSubmit}
                        className="next bfc-button-next">
                        {formikBag.isSubmitting && (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        )}
                        {team_member_id ? 'Update' : 'Save'}
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default TeamsForm;
