/// <reference types="node-fetch">
import crossFetch from 'cross-fetch';
import normalize from 'normalize-object';

import inMemoryJWT from 'services/auth/inMemoryJWT';
import notifications from 'lib/notifications';
import utils, { encryption, decryption } from 'lib/utils';
import config from 'config';
const { isApiEncryptionEnabled } = config;
import queryStringParser from 'query-string';

const API_URL = process.env.REACT_APP_BASE_URL;

export type ResponseCheck<T> = {
  data: T;
  errors: Object | null;
  meta: Object | null;
};

const openFetch = function (request: string, opts?: RequestInit): Promise<Response> {
  const controller = new AbortController();
  const signal = controller.signal;

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: inMemoryJWT.getToken(),
    },
    ...opts,
  };

  const reqString = requestOptions?.['body'];
  let urlWithEncryptedQs;
  if (isApiEncryptionEnabled === 'true') {
    const parsedUrl = new URL(`${request?.includes('http') ? '' : API_URL}${request}`);
    if (parsedUrl.search) {
      const queryParams = parsedUrl.search.split('?')[1];
      parsedUrl.search = '';
      const isAlreadyEncrypted = decryption(queryParams);
      urlWithEncryptedQs =
        isApiEncryptionEnabled === 'true'
          ? isAlreadyEncrypted
            ? request
            : `${parsedUrl?.pathname}?${utils.getQs(queryStringParser.parse(queryParams))}`
          : request;
    }
  }

  setTimeout(() => {
    controller.abort();
  }, 30 * 1000);
  const requestUrl = urlWithEncryptedQs
    ? `${urlWithEncryptedQs?.includes('http') ? '' : API_URL}${urlWithEncryptedQs}`
    : `${request?.includes('http') ? '' : API_URL}${request}`;
  return isApiEncryptionEnabled === 'true'
    ? crossFetch(
        requestUrl,
        requestOptions?.body
          ? { ...requestOptions, body: encryption(reqString), signal }
          : { ...requestOptions, signal },
      )
    : crossFetch(requestUrl, { ...requestOptions, signal });
};

async function doFetching<T>(urlFragment: string, opts: RequestInit = { method: 'GET' }): Promise<ResponseCheck<T>> {
  try {
    const res = await openFetch(urlFragment, opts);

    // if (res.headers?.map['content-type'] !== 'application/json; charset=utf-8') {
    //   notifications.show({
    //     type: 'error',
    //     message: `invalid reponse: content-type` + res.headers?.map['content-type'],
    //     key: 'fetch-errors',
    //   });
    // }

    // const rawResponse: any = await res.json();
    let rawResponse: any = isApiEncryptionEnabled === 'true' ? await utils.processApiRes(res) : await res.json();
    const response = normalize(rawResponse, 'snake');

    if (res.status >= 400) {
      const errorMessage = response.message || response.title;
      const displayError = utils.getErrMessage(response.data) + '. ' + errorMessage;
      notifications.show({
        type: 'error',
        message: displayError,
        key: 'fetch-errors-400',
      });
      throw { errors: response.data, message: errorMessage, stat: response.stat, errorCode: res.status };
    }

    // return { data: response.data, errors: null, meta: null };
    return { data: isApiEncryptionEnabled === 'true' ? response : response.data, errors: null, meta: null };
  } catch (networkError: any) {
    const errors = await utils.processApiErrors(networkError);
    throw errors;
  }
}

export const fetchList = async <T>(urlFragment: string): Promise<ResponseCheck<T[]>> => {
  const response = await doFetching<T[]>(urlFragment);
  return response;
};

export const fetchOne = async <T>(urlFragment: string): Promise<ResponseCheck<T>> => {
  const response = await doFetching<T>(urlFragment);
  return response;
};

export const create = async <T>(urlFragment: string, payload: T): Promise<ResponseCheck<T>> => {
  const opts = { body: JSON.stringify(payload), method: 'POST' };
  const response = await doFetching<T>(urlFragment, opts);
  return response;
};

export const update = async <T>(urlFragment: string, payload: T): Promise<ResponseCheck<T>> => {
  const opts = { body: JSON.stringify(payload), method: 'PUT' };
  const response = await doFetching<T>(urlFragment, opts);
  return response;
};

// export const destroy = async <T>(urlFragment: string): Promise<ResponseCheck<T>> => {
//   const opts = { method: 'DELETE' };
//   const response = await doFetching<T>(urlFragment, opts);
//   return response;
// };

export default { fetchList, fetchOne, create, update };
