import React from 'react';
import { Dialog, DialogContent, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LogoLoader from 'common/LogoLoader';
import BidIcon from 'assets/images/icons/icon-bid.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({
  open,
  setOpen,
  title,
  icon,
  size,
  busy,
  handelOnCloseModal,
  resetQsAndMaterial,
  children,
  transition,
  closeOnBlur,
  loaderStyle,
  isIconRequired = true,
  ...rest
}) => {
  const handelClose = () => {
    setOpen(false);
    resetQsAndMaterial && resetQsAndMaterial();
    handelOnCloseModal && handelOnCloseModal();
  };

  return (
    <Dialog maxWidth={size} open={open} onClose={closeOnBlur && handelClose} {...rest} TransitionComponent={Transition}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px 24px',
          boxShadow: '0px 0px 10px #00000033',
        }}>
        <div>
          {isIconRequired && (
            <img style={{ maxWidth: '100%', width: 'auto', marginRight: '15px' }} src={icon || BidIcon} alt="" />
          )}
          <span style={{ fontSize: 'var(--fs-base__zero)', fontWeight: 'bold', color: 'var(--unnamed-color-1b62ab)', }}>{title}</span>
        </div>
        <IconButton aria-label="close" onClick={handelClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div className="commodity-wrapper">
          {busy && (
            <div className="commodity-wrapper">
              <div className="panel__refresh" style={loaderStyle ? loaderStyle : { transform: 'translate(50%,50%)' }}>
                <LogoLoader />
              </div>
            </div>
          )}
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
