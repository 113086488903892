import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchDetails(id) {
    const qsParams = {
      Company_Address_Id: id,
      Extended_Profile_Id: 0,
    };

    return utils.getQs(qsParams);
  },
  fetchNotConnectedDetails(id) {
    const qsParams = {
      Company_Address_Id: id,
      Extended_Profile_Id: 0,
    };

    return utils.getQs(qsParams);
  },
  create(params = {}) {
    params['health_And_Safety_Certification_Valid_Until'] = params['health_And_Safety_Certification_Valid_Until']
      ? dayjs(params['health_And_Safety_Certification_Valid_Until']).format('YYYY-MM-DD')
      : null;

    params['inspection_suggested_from_datetime'] = params['inspection_suggested_from_datetime']
      ? dayjs(params['inspection_suggested_from_datetime']).format('YYYY-MM-DD')
      : null;

    params['inspection_suggested_to_datetime'] = params['inspection_suggested_to_datetime']
      ? dayjs(params['inspection_suggested_to_datetime']).format('YYYY-MM-DD')
      : null;

    params['last_Health_Checkup_On'] = params['last_Health_Checkup_On']
      ? dayjs(params['last_Health_Checkup_On']).format('YYYY-MM-DD')
      : null;

    params['last_Quality_Testing_On'] = params['last_Quality_Testing_On']
      ? dayjs(params['last_Quality_Testing_On']).format('YYYY-MM-DD')
      : null;

    params['last_Safety_Training_On'] = params['last_Safety_Training_On']
      ? dayjs(params['last_Safety_Training_On']).format('YYYY-MM-DD')
      : null;

    params['quality_Certification_Valid_Until'] = params['quality_Certification_Valid_Until']
      ? dayjs(params['quality_Certification_Valid_Until']).format('YYYY-MM-DD')
      : null;

    if (params['health_And_Safety_Certification_Enum'] !== 3) {
      params['health_And_Safety_Certification_Other'] = '';
    }

    if (params['quality_Certification_Enum'] !== 3) {
      params['quality_Certification_Other'] = '';
    }

    let storages = [];
    const rawMaterial = {
      // extended_Profile_Storage_Id: 0,
      storage_Space_Enum: 1,
      covered_Area: params['rawMaterialsCovered'],
      open_Area: params['rawMaterialsOpen'],
      remarks: params['rawMaterialsRemarks'],
    };

    const finishedGoods = {
      // extended_Profile_Storage_Id: 0,
      storage_Space_Enum: 2,
      covered_Area: params['finishedGoodsCovered'],
      open_Area: params['finishedGoodsOpen'],
      remarks: params['finishedGoodsRemarks'],
    };

    const equipment = {
      // extended_Profile_Equipment_Id: 0,
      // commodity_Id: 1,
      commodity_Form_Id: params['commodity_form_id'],
      commodity_Type_Id: params['commodity_type_id'],
      // commodity_Residue_Id: 1,
      machine_Name_Enum: params['machine_Name_Enum'],
      make_Of_Machine: params['make_Of_Machine'],
      capacity: params['capacity'],
      capacity_Uom_Id: 1,
      count: 1,
      is_Equipment: true,
    };
    const filteredEquipments = params['mainProducts'].filter((item) => item.commodity_form_id !== 0);
    const equipments = [...params['equipments'], ...filteredEquipments];
    // params['equipments'].push(equipment);
    storages.push(rawMaterial);
    storages.push(finishedGoods);

    let bodyParams = {
      ...params,
      storages,
      equipments,
      qualityStandardsAndProcesses: {
        quality_Spec_Ids: [...params['quality_Spec_Ids']],
      },
      operation_enum: 1,
    };
    return bodyParams;
  },
  update(params) {
    params['health_And_Safety_Certification_Valid_Until'] = params['health_And_Safety_Certification_Valid_Until']
      ? dayjs(params['health_And_Safety_Certification_Valid_Until']).format('YYYY-MM-DD')
      : null;

    params['inspection_suggested_from_datetime'] = params['inspection_suggested_from_datetime']
      ? dayjs(params['inspection_suggested_from_datetime']).format('YYYY-MM-DD')
      : null;

    params['inspection_suggested_to_datetime'] = params['inspection_suggested_to_datetime']
      ? dayjs(params['inspection_suggested_to_datetime']).format('YYYY-MM-DD')
      : null;

    params['last_Health_Checkup_On'] = params['last_Health_Checkup_On']
      ? dayjs(params['last_Health_Checkup_On']).format('YYYY-MM-DD')
      : null;

    params['last_Quality_Testing_On'] = params['last_Quality_Testing_On']
      ? dayjs(params['last_Quality_Testing_On']).format('YYYY-MM-DD')
      : null;

    params['last_Safety_Training_On'] = params['last_Safety_Training_On']
      ? dayjs(params['last_Safety_Training_On']).format('YYYY-MM-DD')
      : null;

    params['quality_Certification_Valid_Until'] = params['quality_Certification_Valid_Until']
      ? dayjs(params['quality_Certification_Valid_Until']).format('YYYY-MM-DD')
      : null;

    if (params['health_And_Safety_Certification_Enum'] !== 3) {
      params['health_And_Safety_Certification_Other'] = '';
    }

    if (params['quality_Certification_Enum'] !== 3) {
      params['quality_Certification_Other'] = '';
    }

    let storages = [];
    const rawMaterial = {
      extended_Profile_Storage_Id: params['rawMaterialsId'],
      storage_Space_Enum: 1,
      covered_Area: params['rawMaterialsCovered'],
      open_Area: params['rawMaterialsOpen'],
      remarks: params['rawMaterialsRemarks'],
      covered_Space_In_MT: params['rawMaterialsCoveredInMT'],
      open_Space_In_MT: params['rawMaterialsOpenInMT'],
      storage_Space_Availability_Enum: 1,
    };

    const rawMaterialUsable = {
      extended_Profile_Storage_Id: params['rawMaterialsIdUsable'],
      storage_Space_Enum: 1,
      covered_Area: params['rawMaterialsCoveredUsable'],
      open_Area: params['rawMaterialsOpenUsable'],
      remarks: params['rawMaterialsRemarksUsable'],
      covered_Space_In_MT: params['rawMaterialsCoveredUsableInMT'],
      open_Space_In_MT: params['rawMaterialsOpenUsableInMT'],
      storage_Space_Availability_Enum: 2,
    };

    const finishedGoods = {
      extended_Profile_Storage_Id: params['finishedGoodsId'],
      storage_Space_Enum: 2,
      covered_Area: params['finishedGoodsCovered'],
      open_Area: params['finishedGoodsOpen'],
      remarks: params['finishedGoodsRemarks'],
      covered_Space_In_MT: params['finishedGoodsCoveredInMT'],
      open_Space_In_MT: params['finishedGoodsOpenInMT'],
      storage_Space_Availability_Enum: 1,
    };
    const finishedGoodsUsable = {
      extended_Profile_Storage_Id: params['finishedGoodsIdUsable'],
      storage_Space_Enum: 2,
      covered_Area: params['finishedGoodsCoveredUsable'],
      open_Area: params['finishedGoodsOpenUsable'],
      remarks: params['finishedGoodsRemarksUsable'],
      covered_Space_In_MT: params['finishedGoodsCoveredUsableInMT'],
      open_Space_In_MT: params['finishedGoodsOpenUsableInMT'],
      storage_Space_Availability_Enum: 2,
    };

    const filteredEquipments = params['mainProducts'].filter((item) => item.commodity_form_id !== 0);
    const equipments = [...params['equipments'], ...filteredEquipments];

    const filteredRawMaterialAndProduct = params['rawMaterialAndProduct'].filter((item) => item.commodity_id !== 0);
    const rawMaterialAndProduct = [...filteredRawMaterialAndProduct];

    storages.push(rawMaterial);
    storages.push(rawMaterialUsable);
    storages.push(finishedGoods);
    storages.push(finishedGoodsUsable);

    let bodyParams = {
      ...params,
      storages,
      equipments,
      rawMaterialAndProduct,
      qualityStandardsAndProcesses: {
        quality_Spec_Ids: [...params['quality_Spec_Ids']],
      }, // latitude: 18.928051,
      // longitude: 72.82086,
      operation_enum: 3,
    };
    return bodyParams;
  },
  sumbitForInspection(id) {
    return id;
  },

  submitForAssessment(id, comments = '') {
    const qsParams = {
      extended_profile_id: id,
      additional_comments: comments,
    };

    return utils.getQs(qsParams);
  },

  fetchList(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  allotInspection(params = {}) {
    let bodyParams = {
      extended_profile_id: params['extended_profile_id'],
      Alloted_Person_Id: params['inspection_person_id'],
      inspection_date: params['inspection_date'] ? dayjs(params['inspection_date']).format('YYYY-MM-DD HH:mm') : '',
    };
    return utils.getQs(bodyParams);
  },
  updateInspectionStatus(params = {}) {
    let bodyParams = {
      extended_profile_inspection_id: params['extended_profile_inspection_id'],
      extended_profile_enum: params['inspection_enum'],
      inspection_additional_comments: params['inspection_additional_comments'],
    };
    return utils.getQs(bodyParams);
  },
  cancelInspection(id) {
    return id;
  },
  rollBackSubmission(id) {
    return id;
  },
};

export const resTransform = {
  fetchDetails(resData) {
    let data = [];
    let item = {
      id: _.get(resData, 'user.profile.company_address_id') || 0,
      ...(resData || {}),
    };
    data.push(item);

    return _.keyBy(data, 'id');
    // let data = resData;
    // if (!Array.isArray(data)) {
    //   data = [];
    // }

    // data = data.map((d) => {
    //   let item = {
    //     id: _.get(d, 'user.profile.company_address_id') || 0,
    //     ...d,
    //   };
    //   return item;
    // });

    // return _.keyBy(data, 'id');
  },
  fetchNotConnectedDetails(resData) {
    let data = [];
    let item = {
      id: _.get(resData, 'user.profile.company_address_id') || 0,
      ...(resData || {}),
    };
    data.push(item);

    return _.keyBy(data, 'id');
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  sumbitForInspection(resData) {
    return resData;
    // return res;
  },

  submitForAssessment(resData) {
    return resData;
    // return res;
  },

  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }
    data = data.map((d) => {
      let item = {
        id: _.get(d, 'company_address_id') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  allotInspection(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }
    data = data.map((d) => {
      let item = {
        id: _.get(d, 'company_address_id') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  updateInspectionStatus(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }
    data = data.map((d) => {
      let item = {
        id: _.get(d, 'company_address_id') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  cancelInspection(resData) {
    return resData;
    // return res;
  },
  rollBackSubmission(resData) {
    return resData;
    // return res;
  },
};
