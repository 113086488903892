import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import utils from 'lib/utils';

import './styles.scss';

function ClosingDateField({ closing_on, to_date }) {
  const dateTime = utils.formatDateTime(closing_on).split(" ");
  return (
    <div className="bfc-table-list-components d-inline-block">
      <div className="closing-date-field">
        {dateTime[0]}
        <br /> <span className="closing-time">{dateTime[1]} {dateTime[2]}</span>
      </div>
    </div>
  );
}

ClosingDateField.propTypes = {
  closing_on: PropTypes.string.isRequired,
  to_date: PropTypes.string.isRequired,
};

export default ClosingDateField;
