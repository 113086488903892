import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  create(params) {
    return params;
  },
  setSingleInvoiceDeals(params) {
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
  createSingleInvoice(params) {
    delete params['is_seller'];
    delete params['is_user_defined_invoice_number'];
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(configurationData, 'cashflow_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'cashflow_id'),
    };
    return res;
  },
  setSingleInvoiceDeals(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'commodity_deal_master_id') || 0,
    };
    return res;
  },
  create(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.createDetails(details);
    });
    return _.keyBy(configurationData, 'seller_company_id');
  },
  createDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'seller_company_id'),
    };
    return res;
  },
  createSingleInvoice(resData) {
    return resData;
  },
};
