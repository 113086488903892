import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { actions as uploadActions } from 'redux/uploadDoc';
import UploadDocument from './UploadDocument';
import { NavLink } from 'react-router-dom';
import FormWithBtn from 'common/Form/FormWithBtn';
import { kycService } from 'services';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import { actions as userActions } from 'redux/user';
import useQuery from 'lib/hooks/useQuery';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@material-ui/core';
import MUIToggleControl from 'common/MUIComponents/ToggleControl';
import { actions as dashboardActions } from 'redux/dashboard';

const getInitialValues = (upload = {}) => {
  let formFields = {
    entity_type_enum: _.get(upload, 'entity_type_enum') || '',
  };
  return formFields;
};

const Upload = (props) => {
  const id = props.id;
  const user = useSelector((state) => state.user);
  const { back } = useQuery();
  const [showApproved, setShowApproved] = useState(false);
  const [showCancelledCheque, setShowCancelledCheque] = useState(false);
  const [isRenderRequired, setIsRenderRequired] = useState(false);
  const [isGpa, setIsGpa] = useState(true);
  const { company_affiliate_enum } = props;
  const typeOfindividualEnum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const globalParticipationData = user?.profile?.gpa_entity_list
  let addressProof = [
    { title: 'Passport', id: 2 },
    { title: 'Voter ID', id: 3 },
    { title: 'Driving Licence', id: 16 },
    { title: 'Electricity Bill', id: 17 },
    { title: 'LIC Premium Receipt', id: 18 },
    { title: 'Bank Passbook', id: 19 },
  ];

  if (typeOfindividualEnum == 1) {
    //Farmer
    // addressProof.push({ title: 'Electricity Bill', id: 17 });
    // addressProof.push({ title: 'LIC Premium Receipt', id: 18 });
    addressProof.push({ title: '7/12 Extract', id: 20 });
    //addressProof.push({ title: 'Bank Passbook', id: 19 });
  }

  const kycStatusEnumCode = _.get(user, 'profile.person.kyc_status_enum_code');

  let kycStatusKey = false;
  if (kycStatusEnumCode == 'Approved' || kycStatusEnumCode == 'Pending') {
    kycStatusKey = true;
  }
  if (kycStatusEnumCode == 'Rejected' || kycStatusEnumCode == 'Not Submitted') {
    kycStatusKey = false;
  }

  const handleSubmitForApproval = async (values, formikBag) => {
    try {
      let resData = await kycService.submitForApproval(0);

      dispatch(userActions.fetchPersonalDetails());

      utils.displayMessage('Submitted For Approval', 'success');
      if (!_.isEmpty(resData) && ['Not Submitted', 'Rejected'].includes(kycStatusEnumCode)) {
        const params = {
          gpa_acceptance: [{
            person_company_affiliate_id: _.get(window, 'user.profile.person.person_id'),
            person_company_affiliate_enum: 1
          }],
        };
        const success = await dispatch(dashboardActions.saveGPAAcceptance(params));
        if (!_.isEmpty(success)) {
          dispatch(userActions.fetchPersonalDetails());
        }
      }
    } catch (err) {
      utils.displayErrors(err);
      console.log('handleSubmitForApproval--->err--->', err);
    }
  };

  const upload = useSelector((state) => state.uploadDoc);

  let uploadedFileNameFront,
    uploadedFileNameBack,
    uploadedFileNameCancelledCrossedCheque,
    attachmentIdFront,
    attachmentIdBack,
    uploadedEntityTypeEnum,
    uploadedEntityTypeEnumCode,
    attachmentPathFront,
    attachmentPathBack,
    uploadedEntityTypeEnumToPass,
    attachmentIdCancelledCheque,
    attachmentPathCancelledCheque;

  let kycStatusRejected = false;
  if (kycStatusEnumCode == 'Rejected' || kycStatusEnumCode == 'Not Submitted') {
    kycStatusRejected = true;
  }

  for (let i = 0; i < upload?.ids?.length; i++) {
    let id = upload.ids[i];
    const uploadList = upload.entities[id] || [];
    const frontBackEnumValue = uploadList.front_back_enum;

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 19 ||
      uploadList.entity_type_enum == 20
    ) {
      uploadedEntityTypeEnum = uploadList.entity_type_enum;
      uploadedEntityTypeEnumCode = uploadList.entity_type_enum_code;
    }
    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 19 ||
      uploadList.entity_type_enum == 20
    ) {
      frontBackEnumValue == 1
        ? (uploadedFileNameFront = uploadList.attachment_file_name)
        : (uploadedFileNameBack = uploadList.attachment_file_name);
    } else {
      if (uploadList.entity_type_enum == 15) {
        uploadedFileNameCancelledCrossedCheque = uploadList.attachment_file_name;
      }
    }

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 19 ||
      uploadList.entity_type_enum == 20
    ) {
      frontBackEnumValue == 1
        ? (attachmentIdFront = uploadList.attachment_id)
        : (attachmentIdBack = uploadList.attachment_id);
    } else {
      if (uploadList.entity_type_enum == 15) {
        attachmentIdCancelledCheque = uploadList.attachment_id;
      }
    }

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 19 ||
      uploadList.entity_type_enum == 20
    ) {
      frontBackEnumValue == 1
        ? (attachmentPathFront = uploadList.attachment_path)
        : (attachmentPathBack = uploadList.attachment_path);
    } else {
      if (uploadList.entity_type_enum == 15) {
        attachmentPathCancelledCheque = uploadList.attachment_path;
      }
    }
  }

  const dispatch = useDispatch();
  const entityIdToPass = _.get(window, 'user.profile.person.person_id');
  const [dropValues, setDropValues] = useState(0);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useEffect(() => {
    if (uploadedEntityTypeEnum) {
      setDropValues(uploadedEntityTypeEnum)
    } else {
      setDropValues(0)
    }
  }, [uploadedEntityTypeEnum, isOpenDialog])

  const onTagsChange = (event, dropValues) => {
    setDropValues(dropValues?.id);
    if (uploadedEntityTypeEnum && uploadedEntityTypeEnum !== dropValues.id) {
      setIsOpenDialog(true);
    }
  };

  useEffect(() => {
    const selectedAddressProof = addressProof.find(item => item.id === uploadedEntityTypeEnum)
    setDropValues(selectedAddressProof?.id);
  }, [uploadedEntityTypeEnum, upload])

  {
    uploadedEntityTypeEnum && kycStatusEnumCode != 'Rejected' && kycStatusEnumCode != 'Not Submitted'
      ? (uploadedEntityTypeEnumToPass = uploadedEntityTypeEnum)
      : (uploadedEntityTypeEnumToPass = dropValues);
  }

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  let prevLink = `/account/${id}/kyc`;
  let nextLink = `/account/${id}/subscription`;
  if (isAdmin) {
    prevLink = `/account/${id}/kyc?back=${back}`;
    nextLink = `/account/${id}/subscription?back=${back}`;
  }
  useEffect(() => {
    if (!_.isEmpty(upload)) {
      const flag = _.get(upload, 'approvalFlag') || false;
      const cancelledChequeFlag = _.get(upload, 'cancelledChequeFlag') || false;
      setShowApproved(flag);
      setShowCancelledCheque(cancelledChequeFlag);
    }
  }, [upload]);

  useEffect(() => {
    let params = {
      entity_id: idCompare,
      company_affiliate_enum: 1,
    };
    dispatch(uploadActions.fetchList(params));
    setIsRenderRequired(false);
  }, [isRenderRequired]);

  return (
    <div className="bfc-body bfc-form profile-height-body">
      <Formik initialValues={getInitialValues(upload)}>
        {(formikBag) => {
          // console.log('formikBag', formikBag);
          const { values } = formikBag;

          return (
            <Form className="form-container">
              <Card className='pb-2'>
                <CardBody className="main-cardbody">
                  <Card>
                    <CardBody>
                      <div className="row form__form-group">
                        <div className="col-md-6 col-lg-5 col-xl-4">
                          <span className="form-group-label">Your Address Proof</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Autocomplete
                              size="small"
                              name="entity_type_enum"
                              options={addressProof}
                              value={addressProof.find(item => item.id === uploadedEntityTypeEnum)}
                              getOptionLabel={(option) => option.title}
                              onChange={onTagsChange}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={uploadedEntityTypeEnumCode} />
                              )}
                              key={uploadedEntityTypeEnumCode || ''}
                              disableClearable
                              // disabled={
                              //   ((uploadedEntityTypeEnum == 2 || uploadedEntityTypeEnum == 3 ? true : false) ||
                              //     isAdmin ||
                              //     kycStatusKey) &&
                              //   !kycStatusRejected
                              // }
                            />
                          </div>
                          {dropValues === 19 && (
                            <div class="notes-block">
                              <p class="greenbg">Upload documents with Sign and Stamped</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row form__form-group">
                        <Row className="col-md-12">
                          <span className="form-group-label col-md-3">Upload Front Page
                          <span class="required">*</span></span>
                          <div className="form-group-field col-md-9">
                            <Field
                              name="attachment_file"
                              entity_id={entityIdToPass}
                              entity_type_enum={uploadedEntityTypeEnumToPass}
                              front_back_enum="1"
                              component={UploadDocument}
                              disabled={isAdmin || kycStatusKey}
                              fileName={uploadedFileNameFront}
                              attachmentId={attachmentIdFront}
                              attachmentPath={attachmentPathFront}
                              company_affiliate_enum={company_affiliate_enum}
                              isAuthorizedSignatory={true}
                              setIsRenderRequired={setIsRenderRequired}
                              kycStatusKey={kycStatusKey}
                              setDropValues={setDropValues}
                            />
                          </div>
                        </Row>
                      </div>

                      {dropValues !== 17 && <div className="row form__form-group">
                        <Row className="col-md-12">
                          <span className="form-group-label col-md-3">Upload Back Page
                            <span class="required">*</span></span>
                          <div className="form-group-field col-md-9">
                            <Field
                              name="attachment_file1"
                              entity_id={entityIdToPass}
                              entity_type_enum={uploadedEntityTypeEnumToPass}
                              front_back_enum="2"
                              component={UploadDocument}
                              disabled={isAdmin || kycStatusKey}
                              fileName={uploadedFileNameBack}
                              attachmentId={attachmentIdBack}
                              attachmentPath={attachmentPathBack}
                              company_affiliate_enum={company_affiliate_enum}
                              isAuthorizedSignatory={true}
                              setIsRenderRequired={setIsRenderRequired}
                              kycStatusKey={kycStatusKey}
                              setDropValues={setDropValues}
                            />
                          </div>
                        </Row>
                      </div>}

                      {showCancelledCheque && (
                        <div>
                          <div>
                            {' '}
                            <hr />
                          </div>
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">
                                Upload Crossed Cancelled Cheque<span class="required">*</span>
                              </span>

                              <div className="form-group-field col-md-9">
                                <Field
                                  name="crossed_cancelled"
                                  entity_id={entityIdToPass}
                                  entity_type_enum="15"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadedFileNameCancelledCrossedCheque}
                                  attachmentId={attachmentIdCancelledCheque}
                                  attachmentPath={attachmentPathCancelledCheque}
                                  disabled={isAdmin || kycStatusKey}
                                />
                              </div>
                            </Row>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
              <div className="d-flex justify-content-center">
                <MUIToggleControl disabled={['Approved', 'Pending'].includes(kycStatusEnumCode) && !globalParticipationData?.length} label={<>Agree To <a target="_blank" href="https://my.biofuelcircle.com/Participation_Agreement.htm">Global Participation Agreement</a></>} value={true} onChange={(e, checked, name) => setIsGpa(checked)} />
              </div>
              <div className="btn-wrapper justify-content-space-between">
                <div className="">
                  <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                    Prev
                  </NavLink>
                </div>
                {!isAdmin && isGpa && (
                  <div className="">
                    {['Rejected', 'Not Submitted'].includes(kycStatusEnumCode) && (
                      <FormWithBtn
                        message={
                          'Are you sure you want to submit KYC for approval? You will not be able to make any changes'
                        }
                        btnText={'Submit For Approval'}
                        handleSubmit={handleSubmitForApproval}
                        // disabled={!showApproved}
                        disabled={!showApproved ? true :  showCancelledCheque ? (!addressProof || !uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack || !uploadedFileNameCancelledCrossedCheque) : (!addressProof || !uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack)}
                      />
                    )}
                  </div>
                )}
                <div className="">
                  {isAdmin && (
                    <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} className="link">
                      Next
                    </NavLink>
                  )}
                </div>
              </div>

              <Dialog
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle className="pb-0" id="alert-dialog-title">
                  {'Want to change document type?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please delete the uploaded document/s for front page and back page to change address proof type.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsOpenDialog(false)} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Upload;
