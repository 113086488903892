import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { resolvePreset } from '@babel/core';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };

    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['entity_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id', 0),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    const user = _.get(window, 'user') || {};
    const acl = utils.getACL(user);
    let bodyParams = {};
    bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
    };
    return bodyParams;
  },

  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      team_member_id: id,
    };
    return bodyParams;
  },

  destroy(id) {
    const qsParams = {
      person_id: _.get(window, 'user.userid', 0),
      team_member_id: id,
    };
    return utils.getQs(qsParams);
  },

  fetchListPost(params = {}) {
    let bodyParams;
    const filterLength = Object.keys(params.filters).length;
    if (filterLength === 0) {
      bodyParams = {
        ...params,
        offset: params.offset || 0,
      };
    } else {
      bodyParams = {
        ...params,
        // offset: 0,
        offset: params.offset,
      };
    }

    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let teamsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(teamsData, 'team_member_id');
  },

  fetchDetails(resData) {
    return resData;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },

  fetchListPost(resData) {
    let teamsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(teamsData, 'commodity_deal_master_id');
  },
  fetchAddressList(resData) {
    let addressData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAddressDetails(details);
    });
    return _.keyBy(addressData, 'value_member');
  },

  fetchAddressDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'value_member'),
    };
    return res;
  },
};
