import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    return params;
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let perKmRate = null;
    if (params['per_km_rate'] !== '') {
      perKmRate = params['per_km_rate'];
    }
    let perMtRate = null;
    if (params['per_mt_rate'] !== '') {
      perMtRate = params['per_mt_rate'];
    }
    let perDayRate = null;
    if (params['per_day_rate'] !== '') {
      perDayRate = params['per_day_rate'];
    }
    let extraRunRate = null;
    if (params['extra_run_rate_per_km'] !== '') {
      extraRunRate = params['extra_run_rate_per_km'];
    }
    let minimumKm = null;
    if (params['minimum_km_per_day'] !== '') {
      minimumKm = params['minimum_km_per_day'];
    }
    let bodyParams = {
      ...params,
      per_km_rate: perKmRate,
      per_mt_rate: perMtRate,
      per_day_rate: perDayRate,
      extra_run_rate_per_km: extraRunRate,
      minimum_km_per_day: minimumKm,
      from_date: dayjs(params['from_date']).format('YYYY-MM-DD'),
      to_date: dayjs(params['to_date']).format('YYYY-MM-DD'),
      contract_date: dayjs(params['contract_date']).format('YYYY-MM-DD'),
      operation_Enum: 1,
      number_of_vehicles: +params['number_of_vehicles'],
    };

    return bodyParams;
  },
  update(id, params) {
    let perKmRate = null;
    if (params['per_km_rate'] !== '') {
      perKmRate = params['per_km_rate'];
    }
    let perMtRate = null;
    if (params['per_mt_rate'] !== '') {
      perMtRate = params['per_mt_rate'];
    }
    let perDayRate = null;
    if (params['per_day_rate'] !== '') {
      perDayRate = params['per_day_rate'];
    }
    let extraRunRate = null;
    if (params['extra_run_rate_per_km'] !== '') {
      extraRunRate = params['extra_run_rate_per_km'];
    }
    let minimumKm = null;
    if (params['minimum_km_per_day'] !== '') {
      minimumKm = params['minimum_km_per_day'];
    }
    let bodyParams = {
      ...params,
      per_km_rate: perKmRate,
      per_mt_rate: perMtRate,
      per_day_rate: perDayRate,
      extra_run_rate_per_km: extraRunRate,
      minimum_km_per_day: minimumKm,
      from_date: dayjs(params['from_date']).format('YYYY-MM-DD'),
      to_date: dayjs(params['to_date']).format('YYYY-MM-DD'),
      contract_date: dayjs(params['contract_date']).format('YYYY-MM-DD'),
      operation_Enum: params['operation_Enum'] || 3,
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  fetchFilterData(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchTransports(params = {}) {
    return params;
  },
  createTransportDeal(params = {}) {
    return params;
  },
};

export const resTransform = {
  fetchList(resData) {
    let deliveryContractData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(deliveryContractData, 'transport_deal_master_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_deal_master_id') || 0,
    };
    return data;
  },
  fetchPaymentDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'bf_payment_master_response.payment_master_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  fetchFilterData(resData) {
    let filterData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchFilterDataDetails(details);
    });
    return _.keyBy(filterData, 'id');
  },
  fetchFilterDataDetails(resData) {
    return resData;
  },
  fetchTransports(resData) {
    let filterData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchTransportsDetails(details);
    });
    return _.keyBy(filterData, 'transport_deal_master_id');
  },
  fetchTransportsDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_deal_master_id') || 0,
    };
    return data;
  },
  createTransportDeal(resData) {
    return resData;
  },

  fetchTransportDeliveryContract(resData) {
    let deliveryContractData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDeliveryContractDetails(details);
    });
    return _.keyBy(deliveryContractData, 'transport_delivery_schedule_alloted_id');
  },
  fetchDeliveryContractDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
    };
    return data;
  },
};
