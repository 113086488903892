import _ from 'lodash';
import utils from 'lib/utils';
import qs from 'query-string';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_CT_ELOC_Lite`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveries_CT_ELOC_Lite');
    throw errors;
  }
};
