export const REQ_OFFER_STATUS_ENUM = {
  DRAFT: 1,
  PUBLISHED: 2,
  REJECTED: 3,
  WITHDRAWN: 4,
};

export const REQ_OFFER_RESPONSE_STATUS_ENUM = {
  SUBMITTED: 1,
  SELECTED: 2,
  REJECTED: 3,
  WITHDRAWN: 4,
};

export const COMPANY_AFFILIATE_ENUM = {
  COMPANY: 1,
  AFFILIATE: 2,
};

export const PROFILE_TABS_ENUM = {
  PROFILE: 3,
  PERSONALDETAILS: 17,
  ORGANISATION: 18,
  TEAM: 19,
  TRANSPORT: 20,
  KYC: 21,
  UPLOAD: 22,
  FARMS_CROPS: 23,
  FINANCE: 99,
};

export const MARKET_DEALS_TABS_ENUM = {
  MARKETDEAL: 9,
  DEALOVERVIEW: 83,
  DEALDETAILS: 84,
  DELADELIVERYSCHEDULE: 85,
  DEALDELIVERIES: 86,
  DEALINVOICES: 87,
  DEALPAYMENTS: 88,
  DEAL_BALANCE_POSITION: 127,
};

export const CT_DEALS_TABS_ENUM = {
  CTDEAL: 108,
  DEALOVERVIEW: 109,
  DEALDETAILS: 110,
  DELADELIVERYSCHEDULE: 111,
  DEALDELIVERIES: 112,
  DEALINVOICES: 113,
  DEALPAYMENTS: 114,
  DEAL_BALANCE_POSITION: 128,
};

export const BO_MARKETING_TABS_ENUM = {
  MARKETING: 62,
  BO_CanPostOnBehalfOfCompany: 68,
  BO_CanChangeCompanyAboutInfo: 69,
  BO_CanChangeCompanyProfilePic: 70,
  BO_CanChangeCompanyCoverPic: 71,
};

export const TO_MARKETING_TABS_ENUM = {
  TO_Marketing: 72,
  TO_CanPostOnBehalfOfCompany: 78,
  TO_CanChangeCompanyAboutInfo: 79,
  TO_CanChangeCompanyProfilePic: 80,
  TO_CanChangeCompanyCoverPic: 81,
};

export const DEAL_REJECT_TIMEOUT = 30; // in minutes

export default {
  DEAL_REJECT_TIMEOUT,
  REQ_OFFER_STATUS_ENUM,
  REQ_OFFER_RESPONSE_STATUS_ENUM,
  COMPANY_AFFILIATE_ENUM,
  PROFILE_TABS_ENUM,
  BO_MARKETING_TABS_ENUM,
  TO_MARKETING_TABS_ENUM,
};
export const NOTIFICATION_CATEGORY_ENUM = {
  BUY_SELL: 3,
  SOCIAL_FEED: 2,
  KYC: 1,
  BUY_SELL_OPERATIONS: 4,
  DELIVERIES: 6,
  INVOICES: 7,
  AUCTION: 12,
  CREATE_POST: 11,
  SUBSCRIPTION: 17,
  EARLY_PAYMENTS: 19,
  // GEOFENCE:3,
};

export const NOTIFICATION_ENUM = {
  KYC_APPROVED: 1,
  KYC_REJECTED: 2,
  POST_LIKE: 3,
  SHARE_POST: 80,
  CREATE_COMMENT: 81,
  SOCIAL_CONNECT: 34,
  FOLLOW: 4,
  REPORT_FEED: 5,
  DECADE_SCHEDULE: 11,
  DAY_SCHEDULE: 12,
  DELIVERIES_PHASE_UPDATE: 13,
  DELIVERIES_UPDATE_QS: 14,
  DELIVERIES_TRIP_REMINDER: 15,
  REGISTRATION_SEND_OTP: 16,
  REGISTRATION_SUCCESSFULL: 17,
  INVOICE_DOCUMENT_GENERATION: 18,
  LOGIN: 19,
  KYC_APPLIED: 20,
  KYC_ADMIN_NOTIFICATION: 21,
  KYC_TEAM_ADMIN_ASSIGN: 22,
  KYC_TEAM_ROLE_ASSIGN: 23,
  SUBSCRIPTION_ENQUIRY: 24,
  FOLLOWERS_POST: 29,
  POST_CREATED_BY_FOLLOWING: 30,
  TAGGED_ENTITY: 31,
  AUCTION_INVITATION_EMAIL: 35,
  AUCTION_WITHDRAWN_EMAIL: 36,
  AUCTION_MESSAGE: 32,
  CHAT_MESSAGE: 33,
  LOGOUT: 37,
  BUY_CREATED: 25,
  SELL_CREATED: 26,
  BUY_EDITED: 27,
  SELL_EDITED: 28,
  BUY_WITHDRAWN: 43,
  SELL_WITHDRAWN: 44,
  DEAL_CREATED: 42,
  OFFER_RECIEVED: 7,
  OFFER_ACCEPTED: 8,
  OFFER_UPDATED: 60,
  OFFER_REJECTED: 9,
  OFFER_WITHDRAWN: 10,
  BID_ACCEPTED: 38,
  BID_REJECTED: 39,
  BID_WITHDRAWN: 40,
  BID_UPDATED: 61,
  BID_RECIEVED: 41,
  ESIGN_SELLER_INVOICES: 53,
  COUNTER_PROPOSAL_RECIEVED_BUY: 54,
  COUNTER_PROPOSAL_RECIEVED_SELL: 55,
  COUNTER_PROPOSAL_RESPONDED_BUY: 56,
  COUNTER_PROPOSAL_RESPONDED_SELL: 57,
  BUY_AUCTION_INVITATION_EMAIL: 58,
  SELL_AUCTION_INVITATION_EMAIL: 59,
  BUY_AUCTION_STARTED: 124,
  SELL_AUCTION_STARTED: 125,
  MULTIPARTY_AUCTION_STARTED: 121,
  RECORD_PAYMENT: 63,
  RECORD_PAYMENT_UPDATED: 64,
  SUBSCRIPTION_ENTERPRISE_PACK1_FIRST_PURCHASE: 84,
  SUBSCRIPTION_POST_PAID_SUBSCRIPTION_CONFIRMED: 85,
  SUBSCRIPTION_LOW_PRE_PAID_BALANCE: 87,
  SUBSCRIPTION_INSUFFICIENT_BALANCE_SECOND_PARTY_TO_ACCEPT_BID_OR_OFFER: 89,
  SUBSCRIPTION_NEW_PREPAID_PACK: 82,
  SUBSCRIPTION_BUSINESS_PACK: 83,
  SUBSCRIPTION_NETWORK_PLAN: 86,
  AUTO_OFFER_HIGHMATCH_SELL: 94,
  AUTO_OFFER_HIGHMATCH_BUY: 95,
  UPDATE_FOLLOWED_BUY_SELL: 6,
  APPLIED_EARLY_PAYMENT: 116,
  ENABLE_DISABLE_EARLY_PAYMENT_SCHEME: 117,
  // WITHIN_GEOFENCE: 26,
};

export const FINANCE_ENUM = {
  ALL: 0,
  APPLIED: 1,
  INPROCESS: 2,
  REJECTED: 3,
  APPROVED: 4,
  NEED_MORE_INFO: 6,
};
export const TYPE_OF_INDIVIDUAL_ENUM = {
  NBFC_BANKING_INSTITUTE: 7,
  BUSINESS_ORGANISATION: 4,
  FPO_USER: 9,
};
export const NETWORK_TABS_ENUM = {
  Network: 5,
  TO_NetworkSettlements: 16,
  TO_NetworkRides: 15,
  TO_NetworkDeals: 14,
  TO_NetworkOfferTransport: 13,
  TO_NetworkHireTransport: 12,
  TO_NetworkQualityServices: 102,
  NETWORK_BANKING_MODULE: 104,
  NETWORK_SUPPLIER_PROFILE_MODULE: 107,
  NETWORK_PLATFORM_PARTNER_LOGS: 151,
  PROCESSING_DEALS: 155,
  FARMER_ONBOARDING: 157,
  NETWORK_TRANSPORTRATE: 158,
  AGGREGATION_PLANNING: 162,
};

export const MARKET_TABS_ENUM = {
  MARKET_BANKING_MODULE: 103,
};

export const INVOICE_FINANCE_APPLICATION_ENUM = {
  APPLIED: 1,
  NEEDMOREINFO: 2,
  APPROVED: 3,
  REJECTED: 4,
  CONFIRMED: 5,
  REJECTEDBYSELLER: 6,
};

export const INVOICE_NUMBER_ENUM = {
  CUSTOM_INVOICE_NUMBER: 1,
  USER_DEFINED_INVOICE_NUMBER: 2,
};

export const ENTITY_TYPE_ENUM = {
  DELIVERY: 12,
  PAYMENT: 14,
};

export const DOCUMENT_MODULE_ENUM = {
  MANUAL_INVOICE: 45,
};

export const MAUNAL_INVOICE_TYPE_ENUM = {
  TAX_INVOICE: 1,
  BILL_OF_SUPPLY: 2,
};

export const INVOICE_CATEGORY_ENUM = {
  TRANSPORT: 1,
  PLATFORM: 2,
};

export const MANUAL_INVOICE_PENDING_APPROVED_ENUM = {
  DRAFT: 1,
  APPROVED: 2,
  REJECTED: 3,
  DELETE: 4,
};

export const RATING_TYPE_ENUM = {
  PARTICIPATION: 1,
  TRANSACTION: 2,
  QUALITY: 3,
  QUANTITY: 4,
  PAYMENTS: 5,
  ONTIME: 6,
};

export const NATURE_OF_BUSINESS_ENUM = {
  BRIQUETTES_PELLETS: 2,
  INDUSTRIAL_BIOWASTE: 3,
  BIOMASS_CONSUMER_1: 4,
  BIOMASS_CONSUMER_2: 5,
  BIOFUEL_CONSUMER: 6,
  BANK_NBFC: 7,
  INSURANCE_COMPANY: 8,
  TRANSPORT: 9,
  WAREHOUSE_STORAGE: 10,
  PLATFORM_PARTNER: 11,
  PLATFORM_SUPPORT: 12,
  AGRICULTURAL_PRODUCTS: 1,
};

export const SOCIAL_FEEDS_ROLES_ENUM = {
  MY_BIOFUEL_CIRCLE: 1,
  CAN_CREATE_COMMENT: 134,
  CAN_LIKE_POST: 135,
  CAN_LIKE_COMMENT: 136,
  CAN_SAVE_POST: 137,
  CAN_SHARE_POST: 138,
  CAN_CHANGE_COMPANY_ABOUT_INFO: 139,
  CAN_CHANGE_COMPANY_PROFILE_PIC: 140,
  CAN_CREATE_POST: 133,
  CAN_CHANGE_COMPANY_COVER_PIC: 141,
  CAN_POST_ON_BEHALF_OF_COMPANY: 142,
};

export const Reason_Type_Enum = {
  Buyer: 1,
  Seller: 2,
  Transporter: 3,
  Mutual: 4,
  ForceMajeure: 5,
};

export const Delivery_Schedule_Enum = {
  Deliveries: 1,
  Schedules: 2,
};

export const Operation_Enum = {
  Add: 1,
  Edit: 2,
  Update: 3,
  Delete: 4,
};
export const Activity_Enum = {
  Slashing: 1,
  Shredding: 5,
  Tedding: 4,
  Raking: 2,
  Baling: 3,
  Pickup: 6,
};
