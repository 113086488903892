import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useRouteMatch, Route } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
// import FormikDateTimePickerField from '../../shared/components/form/FormikDateTimePickerField';
import Collapse from './../components/Collapse';
import ReferenceDataSelect from './../components/ReferenceDataSelect';
import VerficationFormModal from './../components/VerficationFormModal';
// import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelect';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as userActions } from 'redux/user';
import { actions as transportActions } from 'redux/transports';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { PROFILE_TABS_ENUM } from 'constants/enums';
import useQuery from 'lib/hooks/useQuery';
import MUITextField from '@material-ui/core/TextField';
import FormikAOISelect from './../components/FormikAOISelect';
import ConfirmDeleteModal from './../components/ConfirmDeleteModal';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import TransportForm from './TransportForm';
import PopupForm from 'common/Form/PopupForm';

import { Table } from 'antd';
import AntTableActions from 'common/AntTableActions';
import OverviewNav from 'common/OverviewNav';
import Vehiclelocations from './VehicleLocations';
import {  Skeleton } from 'antd';
import LogoLoader from 'common/LogoLoader';

const Transport = (props) => {
  //optimize this
  const id = props.id;
  const { back } = useQuery();
  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  const transports = useSelector((state) => state.transports);
  const busy = transports?.busy || false;
  const transportsList = transports.entities || [];
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteTransportId, setDeleteTransportId] = useState(0);

  let tableData = Object.values(transportsList || {});
  const rolesAndRights = useSelector((state) => state.rolesAndRights);
  const rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  const showTransport = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TRANSPORT),
  );
  const readAccess = _.get(showTransport, `read_access`);

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  const acl = utils.getACL(user);
  const isCompanyUser = acl.isUserCompany;
  let nextLink = `/account/${id}/kyc`;
  let prevLink = `/account/${id}/farms`;
  if (isCompanyUser) {
    nextLink = `/account/${id}/kyc-b`;
    prevLink = `/account/${id}/teams`;
  }
  if (isAdmin) {
    const isCompany = _.get(kycUser, 'profile.person.user_type_enum');
    const isTransporter = _.get(kycUser, 'profile.person.is_transporter');
    if (isCompany == 1 || isTransporter) {
      nextLink = `/account/${id}/kyc-b?back=${back}`;
      prevLink = `/account/${id}/teams?back=${back}`;
    } else {
      nextLink = `/account/${id}/kyc?back=${back}`;
      prevLink = `/account/${id}/farms?back=${back}`;
    }
  }
  if (!isAdmin) {
    if (acl.isCompany || acl.isTransporter) {
      nextLink = `/account/${id}/kyc-b`;
      prevLink = `/account/${id}/teams`;
    }
  }

  tableData = tableData.map((d) => {
    return {
      ...d,
      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/transport/edit/${d['transport_detail_id']}`),
    };
  });

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDestroy(id);
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const TRANSPORT_TABLE_HEADER = [
    {
      title: 'Base Location',
      dataIndex: 'base_location_name',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicle_type_enum_text',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Goods Type',
      dataIndex: 'goods_type_enum_text',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Rated Capacity',
      dataIndex: 'vehicle_type_code',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Owned/Rented',
      dataIndex: 'owned_rented_enum_code',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value=='' || value==null ? '-': value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Number of Vehicles',
      dataIndex: 'number_of_vehicles',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },

    {
      title: 'Actions',
      dataIndex: 'address_text_',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.transport_detail_id}
              name={row.company_display_name}
              cancel={cancel}
              showFlag={!isAdmin && !readAccess}
            />
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
  ];

  useEffect(() => {
    // if (transports.dirty) {

    let params = {
      entity_id: id,
    };
    dispatch(transportActions.fetchList(params));

    // }
  }, [transports.dirty]);

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/transport/create`));
  const editMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/transport/edit/:id`));

  let transport_detail_id = null;
  if (createMatch) {
    transport_detail_id = 0;
  } else if (editMatch) {
    transport_detail_id = _.get(editMatch, 'params.id') || 0;
    transport_detail_id = decryptionInHex(transport_detail_id);
  }
  const { url, path } = useRouteMatch();
  const links = [
    { to: `/account/${id}/transport`, title: 'Vehicle' },
    { to: `/account/${id}/locations?page=1`, title: 'Route' },
  ];
  if (transports.dirty) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="bfc-body bfc-form profile-height-body">
      <OverviewNav links={links?.map(link => ({ ...link, to: getUrlWithEncryptedQueryParams(link?.to)}))} />
      <React.Fragment>
        <div className="form-container">
          <div className="farm-wrapper">
            <div className="farm-table">
              <div className="table-farms-wrapper">
                {!readAccess && (
                  <div className="addnew-row add-new-btn-box">
                    <Button
                      onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/transport/create`))}
                      type="button"
                      className="add-new-btn"
                      disabled={isAdmin}
                      size="sm">
                      <span className="icon">+</span>
                      <span className="text">Add Vehicle</span>
                    </Button>
                  </div>
                )}

                <Table
                  // columns={TRANSPORT_TABLE_HEADER}
                  // dataSource={tableData}
                  dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                  columns={busy ? TRANSPORT_TABLE_HEADER.map((column) => {
                    return {
                      ...column,
                      render: function renderPlaceholder() {
                        return (
                          <Skeleton
                            active
                            key={column.dataIndex}
                            title={true}
                            paragraph={false}
                          />
                        );
                      },
                    };
                }) : TRANSPORT_TABLE_HEADER}
                  bordered
                  size="small"
                  pagination={false}
                  style={{ whiteSpace: 'pre' }}
                />
              </div>
            </div>

            <div className="btn-wrapper justify-content-space-between">
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} className="link">
                  Prev
                </NavLink>
              </div>
              {!isCompanyUser && (
                <div className="">
                  <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} className="link">
                    Next
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
        {editMatch || createMatch ? (
          <div>
            <PopupForm title={'Transport Details'} onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/transport`))}>
              <TransportForm transport_detail_id={transport_detail_id} id={id} />
            </PopupForm>
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default Transport;
