import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchDetails = async (id = 0) => {
  try {
    const qs = reqTransform.fetchDetails(id);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/FetchExtendedProfile?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('FetchKYCIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchExtendedProfile');
    throw errors;
  }
};

export const fetchNotConnectExtendedProfileDetails = async (id = 0) => {
  try {
    const qs = reqTransform.fetchNotConnectedDetails(id);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/FetchNotConnectedExtendedProfile?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchNotConnectedDetails(resData);
  } catch (err) {
    console.log('FetchKYCIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchExtendedProfile');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/SaveAndUpdateExtendedProfile`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateExtendedProfile');
    throw errors;
  }
};

export const update = async (params = {}) => {
  try {
    let bodyParams = reqTransform.update(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/SaveAndUpdateExtendedProfile`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateExtendedProfile');
    throw errors;
  }
};

export const sumbitForInspection = async (id) => {
  try {
    const qs = reqTransform.sumbitForInspection(id);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/SubmitForInspection?extended_profile_id=${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.sumbitForInspection(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SubmitForInspection');
    throw errors;
  }
};

export const submitForAssessment = async (id, comments) => {
  try {
    const qs = reqTransform.submitForAssessment(id, comments);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/SubmitAssessment?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.submitForAssessment(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SubmitAssessment');
    throw errors;
  }
};

export const fetchList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/FetchExtendedProfileHistory`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchExtendedProfileHistory');
    throw errors;
  }
};

export const fetchCTList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/FetchAllExtendedProfileApplications`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllExtendedProfileApplications');
    throw errors;
  }
};

export const allotInspection = async (params) => {
  try {
    let bodyParams = reqTransform.allotInspection(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/AllotInspection?${bodyParams}`;

    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.allotInspection(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'AllotInspection');
    throw errors;
  }
};
export const updateInspectionStatus = async (params) => {
  try {
    let bodyParams = reqTransform.updateInspectionStatus(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/UpdateInspectionStatus?${bodyParams}`;

    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateInspectionStatus(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateInspectionStatus');
    throw errors;
  }
};
export const cancelInspection = async (id) => {
  try {
    const qs = reqTransform.cancelInspection(id);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/CancelInspection?extended_profile_inspection_id=${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.cancelInspection(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CancelInspection');
    throw errors;
  }
};
export const rollBackSubmission = async (id) => {
  try {
    const qs = reqTransform.rollBackSubmission(id);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/RollbackExtendedProfileSubmission?extended_profile_id=${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.rollBackSubmission(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RollbackExtendedProfileSubmission');
    throw errors;
  }
};
export const unassignInspector = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/UnassignInspector?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UnassignInspector');
    throw errors;
  }
};
