import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ControlTower/FetchKycHistoryDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchKycHistoryDetails-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchKycHistoryDetails');
    throw errors;
  }
};
