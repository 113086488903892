import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import api from 'lib/api';
import TransportOfferService from 'services/transportOffer';

const initialState = {
  data: {},
  order: [],
  busy: false,
  updatedAt: null,
};

const slice = createSlice({
  name: 'transportOffers',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    add(state, action) {
      let data = {};
      let order = [];
      for (const transportOffer of action.payload) {
        const key = transportOffer.transport_req_offer_response_id;
        data[key] = transportOffer;
        order.push(key);
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();
    },

    // update(state, action) {
    //   const { id, data, detailsUpdatedAt } = action.payload;
    //   state.data[id] = {
    //     // isDetailsLoaded : true,
    //     ...state.data[id],
    //     ...data,
    //     detailsUpdatedAt: detailsUpdatedAt,
    //   };
    //   // state['updatedAt'] = utils.uid();
    // },
    // delete(state, action) {
    //   // debugger;
    //   const post_id = action.payload;
    //   let order = _.get(state, 'order', []) || [];
    //   // const posts = state.data.filter(p => p.post_id !== post_id)
    //   delete state['data'][post_id];
    //   let filteredOrder = order.filter((id) => id !== post_id);
    //   state['order'] = filteredOrder;
    // },
  },
});

const getTransportOfferDetailsAgainstId = (id) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await TransportOfferService.FetchTransportOfferDetailsAgainstId(id);
      dispatch(slice.actions.add([response.data]));
    } catch (errorResponse) {
      console.log('getTransportOfferDetailsAgainstId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const getAllTransportOffersAgainstEntityId = (params) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let response = await TransportOfferService.FetchAllTransportOffersAgainstEntityId(params);
      dispatch(
        slice.actions.add({
          isAllListLoaded: true,
          updatedAt: Date.now(),
          response,
        }),
      );
    } catch (errorResponse) {
      console.log('getAllTransportOffersAgainstEntityId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const saveTransportOffer = (params) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let response = await TransportOfferService.SaveTransportOffer(params);
      dispatch(
        slice.actions.add({
          isMyListLoaded: true,
          updatedAt: Date.now(),
          response,
        }),
      );
    } catch (errorResponse) {
      console.log('SaveTransportOffer-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const withdrawTransportOfferById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let response = await TransportOfferService.WithdrawTransportOfferById(id);
    } catch (errorResponse) {
      console.log('WithdrawTransportOfferById-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};
const FetchOffersAgainstTransportRequirementId = (id, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let response = await TransportOfferService.FetchOffersAgainstTransportRequirementId(id, pageSize);
      dispatch(slice.actions.add(response.data));
    } catch (errorResponse) {
      console.log('FetchOffersAgainstTransportRequirementId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const actions = {
  ...slice.actions,
  getTransportOfferDetailsAgainstId,
  getAllTransportOffersAgainstEntityId,
  saveTransportOffer,
  withdrawTransportOfferById,
  FetchOffersAgainstTransportRequirementId,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
