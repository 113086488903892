import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as userActions } from 'redux/user';
import { actions as affiliateActions } from 'redux/affiliates';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import {  Skeleton } from 'antd';
import LogoLoader from 'common/LogoLoader';

import ConfirmDeleteModal from './../../components/ConfirmDeleteModal';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import AffiliateForm from './AffiliateForm';
import PopupForm from 'common/Form/PopupForm';

import { Table } from 'antd';
import AntTableActions from 'common/AntTableActions';
import { TYPE_OF_INDIVIDUAL_ENUM } from 'constants/enums';
import useQuery from 'lib/hooks/useQuery';

const Affiliate = (props) => {
  //optimize this
  let { page = 1, back, filter_company_name, filter_address_text, filter_address_type_enum, filter_gstin } = useQuery();
  const id = props.id;
  const isCompanyUser = props.isCompanyUser;
  const user = useSelector((state) => state.user);
  const typeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const affiliates = useSelector((state) => state.affiliates);
  const affiliateList = affiliates.entities || [];
  const busy = _.get(user, 'busy') || false;
  const affiliates_busy = _.get(affiliates, 'busy') || false;
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteAffiliateId, setDeleteAffiliateId] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let tableData = Object.values(affiliateList || {});

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  tableData = tableData.map((d) => {
    return {
      ...d,
      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/organisations/affiliate/edit/${d['company_affiliate_id']}`),
    };
  });

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDestroy(id);
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const AFFILIATE_TABLE_HEADER = [
    {
      title: 'Affiliated Business Name',
      dataIndex: 'affiliate_name',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Business Type',
      dataIndex: 'business_type_enum_code',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Relationship',
      dataIndex: 'affiliate_relation_enum_code',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'address_text_',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.company_affiliate_id}
              name={row.display_name}
              cancel={cancel}
              showFlag={!isAdmin && isCompanyUser}
            />
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
  ];

  useEffect(() => {
    // if (affiliates.dirty) {
    const params = {
      person_id: parseInt(id),
    };
    dispatch(affiliateActions.fetchList(params));
  }, [affiliates.dirty]);

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/affiliate/create`));
  const editMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/affiliate/edit/:id`));

  let affiliateId = null;
  if (createMatch) {
    affiliateId = 0;
  } else if (editMatch) {
    affiliateId = _.get(editMatch, 'params.id') || 0;
    affiliateId = decryptionInHex(affiliateId);
  }

  // if (affiliates.dirty) {
  //   return (
  //     <div
  //       className=""
  //       style={{
  //         position: 'relative',
  //         minHeight: '150px',
  //       }}>
  //       <div className="panel__refresh">
  //         <LogoLoader />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <React.Fragment>
      <div className="form-container">
        <div className="farm-wrapper">
          <div className="farm-table">
            <div className="table-farms-wrapper">
              {!isAdmin && isCompanyUser && typeOfIndividualEnum != TYPE_OF_INDIVIDUAL_ENUM.NBFC_BANKING_INSTITUTE && (
                <div className="addnew-row add-new-btn-box">
                  <Button
                    onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/organisations/affiliate/create`))}
                    type="button"
                    className="add-new-btn"
                    size="sm">
                    <span className="icon">+</span>
                    <span className="text">Add Affiliate</span>
                  </Button>
                </div>
              )}
              <Table
                rowKey="key"
                columns={AFFILIATE_TABLE_HEADER}
                dataSource={tableData}
                bordered
                size="small"
                pagination={false}
                style={{ whiteSpace: 'pre' }}
              />
            </div>
          </div>
        </div>
      </div>
      {editMatch || createMatch ? (
        <div>
          <PopupForm title={'Business Affiliation'} onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/organisations`))}>
            <AffiliateForm affiliateId={affiliateId} id={id} />
          </PopupForm>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Affiliate;
