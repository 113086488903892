import _ from 'lodash';
import utils from 'lib/utils';
import qs from 'query-string';
import config from 'config';
import { util } from 'prettier';

const { baseUrl } = config;

export const ShareQualityReport = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/ShareQualityReport`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const response = await utils.processApiRes(res);
    if (!res.ok) {
      return { stat: false, response };
    }
    return { stat: true, response };
  } catch (err) {
    console.error('Error in ShareQualityReport:', err);
    const errors = await utils.processApiErrors(err, '', 'Error in sharing Quality Report');
    throw errors;
  }
};

export const FetchDealAudit = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Audit/FetchDealAudit`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchDealAudit');
    throw errors;
  }
};

export const fetchDealAuditForDelivery = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Audit/FetchAuditAgainstEntityId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchDealAudit');
    throw errors;
  }
};
