import React, { useEffect, useState, useRef } from 'react';
import { buyService } from 'services';
import history from 'lib/history';
import _ from 'lodash'
import { Link, BrowserRouter } from 'react-router-dom';
import { message, Skeleton, Spin } from 'antd';

const MatchingPopUpData = (props) => {
  const { percentage, type, name, bidOfferText, values, setOpenMatchModal, fetchNewData } = props.data;
  const [count, setCount] = useState(0);
  const [busy, setBusy] = useState(false);
  const [res, setRes] = useState([]);

  let commodityIdsArray = res?.length != 0 ? res : []

  let commodityIdString = "";
  if (!_.isEmpty(commodityIdsArray)) {
    commodityIdsArray.map((value, index) => {
      if (index != commodityIdsArray.length - 1) {
        commodityIdString = commodityIdString + value + ",";
      }
      else {
        commodityIdString = commodityIdString + value
      }
    })
  }

  let newtype = type == 'requirements' ? 'offers' : type == 'offers' ? 'requirements' : null;

  const handleButtonClick = () => {
    history.push({
      pathname: `/commodity/${newtype}/all`,
      // state: { commodity_id: res },
      search: `?page=1&commodity_req_offer_id=${commodityIdString}`
    })
  }

  useEffect(() => {
    const unSubscribe = history.listen(() => message.destroy(props.messageKey))
    return unSubscribe;
  }, [props.messageKey])

  useEffect(() => {
    const getCount = async () => {
      let newValues = {
        ...values,
        req_offer_type_enum: type === 'requirements' ? 1 : 2
      }
      if (newValues['min_qty'] === "") {
        newValues['min_qty'] = 0;
      }
      if (newValues['qty'] === "") {
        newValues['qty'] = 0;
      }
      if (newValues['to_date'] === "") {
        newValues['to_date'] = null;
      }
      if (newValues['from_date'] === "") {
        newValues['from_date'] = null;
      }
      if (newValues['closing_on'] === "") {
        newValues['closing_on'] = null;
      }
      if (newValues['valid_until'] === "") {
        newValues['valid_until'] = null;
      }
      if (newValues['expected_price'] === "") {
        newValues['expected_price'] = 0;
      }
      setBusy(true);
      try {
        let resData = await buyService.fetchMatchingCount(newValues);
        const resDataCount = _.get(resData, 'count') || 0;
        setCount(resDataCount);
        setRes(resData.commodity_req_offer_ids)
        setBusy(false);
      } catch (err) {
        message.destroy(props.messageKey)
        setBusy(false);
      }
    }
    if (!_.isEmpty(values)) {
      props.loadingDataForFirstTime.current = 2;
      if (props.fetchNewData) {
        message.destroy(props.messageKeyRef.current);
      };
      getCount();
      const { additional_comments, ...valuesWithoutComments } = values;
      props.oldValues.current = valuesWithoutComments;
      props.messageKeyRef.current = props.messageKey;
    }
  }, []);

  return (
    <>
      {/* {!busy ?  */}
      <div className="matching__popup-modal">
        <p className="close-area" style={{ top: '-5px' }}>
          <button aria-label="Close" className="close" type="button">
            <span aria-hidden onClick={() => message.destroy(props.messageKey)}>×</span>
          </button>
        </p>
        <div class="middle-part d-flex bd-highlight">
          <div className="d-flex align-items-center mt-0">
            <button class="reset btn-gradient-2">
              {!busy
                ? count
                : <Spin className='mt-5'/>
              }
              {/* {count} */}
            </button>
          </div>
          <div class="flex-grow-1 mt-0" style={{ marginLeft: '-30px' }}>
            <p className="heading">Matching {' '} {name}</p>
            {!busy
              ?
                name === 'Sellers' ?
                  <p className="content mt-5">We found
                    <span className="point">{' '}{count} {' '} {name}{' '}</span> offering the same or similar goods.
                  </p>
                  :
                  <p className="content mt-5">We found
                    <span className="point">{' '}{count} {' '} {name}{' '}</span> looking for the same or similar goods.
                  </p>

              : <Skeleton className='matching-skeleton-subtitle' active title={true} paragraph={false} />
            }
          </div>
          <div class="ltr-support d-flex align-items-end mt-0">
            <BrowserRouter>
              <Link to={{
                pathname: `/commodity/${newtype}/all`,
                search: `?page=1&commodity_req_offer_id=${commodityIdString}`
              }}
                target="_blank"
              >
                <button
                  type="button"
                  className="btn btn-secondary btn-sm view-all-btn"
                  // onClick={() => handleButtonClick()}
                  disabled={busy ? true : count > 0 ? false : true}
                >
                  View All
                </button>
              </Link>
            </BrowserRouter>
          </div>
        </div>
      </div>
    </>
  )
};


export default MatchingPopUpData ;

