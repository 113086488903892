import React from 'react';
import PaginationComponent from 'react-reactstrap-pagination';

import DataPaginationTable from 'theme/shared/components/table/DataPaginationTable';
import './styles.scss';

function ListDataGrid(props) {
  const { currentPage, totalRecords, rowsPerPage, onPageChange, ...gridProps } = props;

  const pagesCount = Math.ceil(totalRecords / rowsPerPage);

  const handleClick = (e, index) => {
    e.preventDefault();
    onPageChange(index);
  };

  return (
    <div className="bfc-list-data-grid">
      <div className="table-wrapper">
        <DataPaginationTable {...gridProps} />
      </div>
      <div className="grid-pagination" style={{ paddingRight: 100}}>
        {pagesCount > 0 && (
          <>
            <PaginationComponent
              totalItems={totalRecords}
              pageSize={rowsPerPage}
              onSelect={onPageChange}
              maxPaginationNumbers={6}
              defaultActivePage={currentPage}
              firstPageText={'\xAB'}
              lastPageText={'\xBB'}
              // previousPageText={'\u2039'}
              // nextPageText={"\u203A"}
              previousPageText={'Previous'}
              nextPageText={'Next'}
            />

            <div className="grid-pagination-text">
              <span>
                Showing {currentPage} out of {pagesCount}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export const handleFilterChange = (updatedFilter) => (previousFilters) => {
  const newFilters = { ...previousFilters };

  if (updatedFilter.column?.apiFilterKey) {
    if (updatedFilter.filterTerm) {
      if (updatedFilter.column?.apiFilterUseLabel) {
        newFilters[updatedFilter.column.apiFilterKey] =
          typeof updatedFilter.filterTerm === 'object'
            ? `${updatedFilter.filterTerm.label}`
            : `${updatedFilter.filterTerm}`;
      } else {
        newFilters[updatedFilter.column.apiFilterKey] =
          typeof updatedFilter.filterTerm === 'object'
            ? `${updatedFilter.filterTerm.value}`
            : `${updatedFilter.filterTerm}`;
      }
    } else {
      delete newFilters[updatedFilter.column.apiFilterKey];
    }
  }

  return newFilters;
};

export default ListDataGrid;
