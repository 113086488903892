import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const financeInstalledCapacitySchema = yup.object().shape({
  commodity_form_id: yup.number().required('Please select commodity').positive('Please select commodity'),
  quantity: yup.number().required('Quantity is required').positive('Invalid Quantity'),
  unit: yup.number().required('Please select unit').positive('Please select unit'),
  periodicity_enum: yup.number().required('Please select Periodicity').positive('Please select Periodicity'),
});

export const financeSchema = yup.object().shape({
  year_of_incorporation: yup
    .number()
    .required('Year of Incorporation is required')
    .positive('Year of Incorporation is required')
    .max(new Date().getFullYear(), 'Year of Incorporation should be less than or equal to current year')
    .test('len', 'Invalid Year', (val) => `${val}`.length === 4),

  industry_type_enum: yup.number().required('Please select Industry Type').positive('Please select Industry Type'),
  business_size_enum: yup.number().required('Please select Business Size').positive('Please select Business Size'),
  financial_year: yup.number().required('Please select Financial Year').positive('Please select Financial Year'),
  annual_turnover: yup.number().required('Annual Turnover is required').positive('Annual Turnover is required'),
  people_employed_enum: yup
    .number()
    .required('Please select People Employed')
    .positive('Please select People Employed'),

  nature_of_business_enum: yup.number().when('company_affiliate_enum', {
    is: 1,
    then: yup.number().required('Please select Nature of Business').positive('Please select Nature of Business'),
    otherwise: yup.number().optional(),
  }),
});

export const fetchDetails = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(financeSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createInstalledCapacity = async (params) => {
  try {
    await helpers.validateSchema(financeInstalledCapacitySchema, params);
    const res = await api.createInstalledCapacity(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroyInstalledCapacity = async (id) => {
  try {
    let res = await api.destroyInstalledCapacity(id);
    return res;
  } catch (err) {
    throw err;
  }
};
