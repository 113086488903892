import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import 'assets/scss/TabNav.scss';

function TabNav(props) {
  const { links, className = 'bfc-tabs tabs-primary', rightPanContent } = props;
  const history = useHistory();
  return (
    <div className={`${className}`}>
      <ul className={rightPanContent ? 'w-100 d-flex justify-content-between align-items-center' : ''}>
        <div className="d-flex">
          {links &&
            links.map((link, index) => {
              return (
                <li key={index}>
                  <NavLink to={link.to}>
                    {link.title}
                  </NavLink>
                </li>
              );
            })}
        </div>
        {rightPanContent ? <div>{rightPanContent}</div> : ''}
      </ul>
    </div>
  );
}

TabNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
};

export default TabNav;
