import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { kycApprovalService } from 'services';
import { fetchAdditionalInfoDeliveries } from 'services/deliveryAllotments/api';
import { deliveriesAllotmentService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (deliveryScheduleDay) => _.get(deliveryScheduleDay, 'delivery_start_date_string', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => (a.name_of_farm || '').localeCompare(b.name_of_farm || ''),
});

const slice = createSlice({
  name: 'completedDeliveries',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchListCT = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchListCT(params);
    const ids = _.map(resData, 'transport_delivery_schedule_alloted_id') || [];
    let AdditionalParams = {
      ...params,
      transport_Delivery_Schedule_Allotted_Ids: ids,
      filters: {},
    };
    if (!_.isEmpty(ids)) {
      let additionalInfoRes = await fetchAdditionalInfoDeliveries(AdditionalParams);
      let mergedArray = Object.values(resData).map((obj1) => {
        let obj2 = Object.values(additionalInfoRes).find(
          (obj2) => obj2.transport_delivery_schedule_alloted_id === obj1.transport_delivery_schedule_alloted_id,
        );
        return { ...obj1, ...obj2 };
      });
      dispatch(slice.actions.setAll(mergedArray));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListByPersonId = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await deliveriesAllotmentService.fetchListByPersonId(params);
    const ids = _.map(resData, 'transport_delivery_schedule_alloted_id') || [];
    let AdditionalParams = {
      ...params,
      transport_Delivery_Schedule_Allotted_Ids: ids,
      filters: {},
    };
    if (!_.isEmpty(ids)) {
      let additionalInfoRes = await fetchAdditionalInfoDeliveries(AdditionalParams);
      let mergedArray = Object.values(resData).map((obj1) => {
        let obj2 = Object.values(additionalInfoRes).find(
          (obj2) => obj2.transport_delivery_schedule_alloted_id === obj1.transport_delivery_schedule_alloted_id,
        );
        return { ...obj1, ...obj2 };
      });
      dispatch(slice.actions.setAll(mergedArray));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchListCT,
  fetchListByPersonId,
};

const selectors = {
  ...adapter.getSelectors((state) => state.completedDeliveries),
};

export { actions, selectors };

export default slice.reducer;
