import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';

import { transportService } from 'services';
import { actions as transportsActions } from 'redux/transports';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Transport...',
      key: 'req-form',
    });

    const res = await transportService.create(values);

    notifications.show({
      type: 'success',
      message: 'Transport Created.',
      key: 'req-form',
    });
    // console.log('form values12345', userid);
    globalThis.store.dispatch(transportsActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.person_id}/transport`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Transport Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'transport_detail_id') || 0;
    const res = await transportService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Transport details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportsActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.person_id}/transport`));

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await transportService.destroy(id);
    globalThis.store.dispatch(transportsActions.setDirty());
    // redirect.web('/account/transport');
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
  return false;
}

export const handleCreateTransportRouteServed = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Location...',
      key: 'req-form',
    });

    const res = await transportService.handleCreateTransportRouteServed(values);
    globalThis.store.dispatch(transportsActions.setDirty());
    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }
};

export const handleDeleteTransportRouteServed = async (id) => {
  try {
    const res = await transportService.handleDeleteTransportRouteServed(id);
    globalThis.store.dispatch(transportsActions.setDirty());
    res &&
      notifications.show({
        type: 'success',
        message: 'Location deleated successfully.',
        key: 'req-form',
      });
    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }
};
