import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

import normalize from 'normalize-object';
import * as helpers from 'services/helpers';

export const reqTransform = {
  fetchStatusAgainstDeliveryId(params) {
    const qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },

  fetchDetails(params) {
    const qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },

  updateStatusAgainstDeliveryId(params) {
    let bodyParams;

    if (params.delivery_id == null) {
      bodyParams = {
        ...params,
        delivery_id: 0,
        transport_delivery_schedule_alloted_id: params.transport_delivery_schedule_alloted_id,
        delivery_phase_enum: 1,
      };
    } else {
      bodyParams = {
        ...params,
        delivery_id: params.delivery_id,
        transport_delivery_schedule_alloted_id: 0,
        delivery_phase_enum: params.delivery_phase_enum,
      };
    }
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        ...d,
        id: _.get(d, 'transport_delivery_schedule_alloted_id') || 0,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },

  fetchDetails(resData) {
    let data = resData || {};

    try {
      data = {
        delivery_id: resData.delivery_id,
        tripDetail: [
          {
            id: 1,
            icon_color: '#003366',
            mail_icon: 'location-on',
            lable_1: 'Pickup Location:',
            lable_1_value: `${
              resData.pickup_location.address_text +
              ', ' +
              resData.pickup_location.address_line1 +
              ', ' +
              resData.pickup_location.address_line2 +
              ', ' +
              resData.pickup_location.address_line3 +
              ', ' +
              resData.pickup_location.village_code +
              ', ' +
              resData.pickup_location.taluka_code +
              ', ' +
              resData.pickup_location.district_code +
              ' ' +
              resData.pickup_location.pin_code +
              ', ' +
              resData.pickup_location.state_code
            }`,
            lable_2: 'Delivery Location:',
            lable_2_value: `${
              resData.delivery_location.address_text +
              ', ' +
              resData.delivery_location.address_line1 +
              ', ' +
              resData.delivery_location.address_line2 +
              ', ' +
              resData.delivery_location.address_line3 +
              ', ' +
              resData.delivery_location.village_code +
              ', ' +
              resData.delivery_location.taluka_code +
              ', ' +
              resData.delivery_location.district_code +
              ' ' +
              resData.delivery_location.pin_code +
              ', ' +
              resData.delivery_location.state_code
            }`,
            next_icon: 'location-on',
          },
          {
            id: 2,
            icon_color: '#003366',
            mail_icon: 'progress-clock',
            lable_1: 'Pickup Date:',
            lable_1_value: dayjs(resData.pick_up_datetime).format('DD-MMM-YY'),
            lable_2: 'Pickup Time',
            lable_2_value: dayjs(resData.pick_up_datetime).format('hh:mm A'),
            next_icon: null,
          },
          {
            id: 3,
            icon_color: '#003366',
            mail_icon: 'account-switch',
            lable_1: 'Consignee:',
            lable_1_value: resData.consignee,
            lable_2: 'Consignor:',
            lable_2_value: resData.consignor,
            next_icon: null,
          },
          {
            id: 4,
            icon_color: '#003366',
            mail_icon: 'map-marker-distance',
            lable_1: 'Estimeted KM:',
            lable_1_value: `${resData.estimated_km}`,
            lable_2: 'Estimeted Time:',
            lable_2_value: `${resData.estimated_time}`,
            next_icon: null,
          },
          {
            id: 5,
            icon_color: '#003366',
            mail_icon: 'car-pickup',
            lable_1: 'Vehicle:',
            lable_1_value: resData.vehicle_code,
            lable_2: 'Driver:',
            lable_2_value: resData.driver,
            next_icon: null,
          },
        ],
        id: _.get(data, 'transport_delivery_schedule_alloted_id') || 0,
      };
    } catch (err) {
      console.error('While transfoming data getting error ', err);
    }
    return data;
  },

  fetchStatusAgainstDeliveryId(resData) {
    let data = resData || {};

    data = {
      ...data,
      id: _.get(data, 'transport_delivery_schedule_alloted_id') || 0,
    };

    return data;
  },

  updateStatusAgainstDeliveryId(resData) {
    let data = resData || {};

    data = {
      ...data,
      id: _.get(data, 'transport_delivery_schedule_alloted_id') || 0,
    };

    return data;
  },
};
