import utils from './../utils';

const validateValues = (values) => {
  const errors = {};

  if (!values['otp']) {
    errors['otp'] = 'Invalid OTP';
  }

  if (!errors.otp && utils.isNaN(values['otp'])) {
    errors['otp'] = 'Invalid OTP';
  }

  if (!errors.otp && values['otp'].length !== 6) {
    errors['otp'] = 'Invalid OTP';
  }

  return errors;
};

export default validateValues;
