import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileThumbNail from 'assets/images/placeholders/profileThumb.png';
import moment from 'moment';
import { Skeleton } from 'antd';
import history from 'lib/history';
import { NOTIFICATION_ENUM, NOTIFICATION_CATEGORY_ENUM } from 'constants/enums';
import * as handlers from 'containers/Account/Notifications/handlers';
import notificationUtils from './notificationUtils';
import ChangeModal from 'containers/Layout/topbar/supplyPortalBuyRedirect';

const NotificationActivity = ({ busy, img, name, date, children, data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const text = children?.replace(/(<([^>]+)>)/gi, '');
  const dateTimeAgo = moment(date).fromNow();
  let notificationLink = notificationUtils.getNotificationLinks(data);
  const [showBuyDirectPopup, setShowBuyDirectPopup] = useState(false);
  const enviraCommodityId =  _.get(data, 'sub_category_entity_id') || '';

  function closeModal() {
    setShowBuyDirectPopup(false);
  }

  let messageStyle = {
    padding: '10px',
    paddingBottom: '10px',
    paddingLeft: '0px',
    paddingRight: '0px',
    cursor: 'pointer',
    borderBottom: '1px solid #eff1f3',
  };
  const is_read = _.get(data, 'is_read') || false;
  if (!is_read) {
    messageStyle = {
      padding: '10px',
      paddingBottom: '10px',
      paddingLeft: '0px',
      paddingRight: '0px',
      backgroundColor: '#f4fcf9',
      cursor: 'pointer',
      borderBottom: '1px solid #eff1f3',
    };
  }

  const handleMessageClick = () => {
    const sid = _.get(user, 'social_profile_id', 0);
    const notificationDeatilId = _.get(data, 'notification_detail_id') || 0;
    const description = _.get(data, 'header_description') || '';
    const params = {
      notification_details_ids: [notificationDeatilId],
      is_read: true,
    };
    handlers.handleMarkMessage(params, sid, dispatch);
    // history.push(description.includes('BiofuelCircle- Storage Alert') ? '/storages' : notificationLink);
    if(description.includes('BiofuelCircle- Storage Alert')){
      history.push('/storages')
    } else if(description.includes('Envira™')){
      setShowBuyDirectPopup(true)
    } else {
      history.push(notificationLink)
    }
  };
  return (
    <div>
      <div
        className="profile__activity"
        style={messageStyle}
        onClick={() => {
          handleMessageClick();
        }}>
        <div className="profile__activity-avatar">
          {busy ? <Skeleton
            active="true"
            // shape="circle"
            title={false}
            paragraph={false}
            avatar />
          : <img src={`${ProfileThumbNail + `?${new Date()}`}`} alt="" />
        }
        </div>
        <div className="w-100">
          <p className="profile__activity-name" style={{ flex: 1 }}>
            {busy ? <Skeleton
              block={false}
              title={true}
              paragraph={false}
              active="true" />
            : <Link to="#" onClick={(e) => e.preventDefault()}> {name ? name : 'Header'} </Link>}
            {/* : <Link to={notificationLink}> {name ? name : 'Header'} </Link>} */}
            {busy ? <Skeleton
              title={true}
              paragraph={false}
              active="true" />
            : <span className="profile__activity-date"> {dateTimeAgo}</span>}
          </p>
          {busy ? <Skeleton
            title={true}
            paragraph={false}
            active="true" />
          : <p className="profile__activity-content m-0">{text}</p>}
        </div>
        {showBuyDirectPopup && <ChangeModal isOpen={showBuyDirectPopup} color="primary" closeModal={closeModal} envira_commodity_id={enviraCommodityId} type = "Envira"/>}
      </div>
    </div>
  );
};

NotificationActivity.propTypes = {
  date: PropTypes.string,
  img: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

NotificationActivity.defaultProps = {
  date: '',
  img: '',
  name: '',
};

export default NotificationActivity;
