import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const VehicleSchema = yup.object().shape({
  vehicle_registration_id: yup.string().required('Registration No. is a required field'),
  name_on_vehicle_registration: yup.string().required('Name on vehicle registration is a required field'),
  vehicle_type_id: yup.number().required().positive('Select vehicle capacity'),
  vehicle_type_enum: yup.number().required().positive('Select vehicle type'),
  owned_rented_enum: yup.number().required().positive('Select Owned or Rented type'),
  // expiry_date: yup.string().required('Expiry Date is a required field'),
});

export const eKycDocumentValiadtion = yup.object().shape({
  vehicle_registration_id: yup.string().required('Registration No. is a required field'),
  name_on_vehicle_registration: yup.string().required('Name of vehicle registration is a required field'),
  // expiry_date: yup.string().required('Expiry Date is a required field'),
});
export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(VehicleSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params) => {
  try {
    await helpers.validateSchema(VehicleSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
//EKyc
export const eKycDocumentVerification = async (params) => {
  try {
    await helpers.validateSchema(eKycDocumentValiadtion, params);
    const res = await api.eKycDocumentVerification(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateVehicleAgainstTransporter_New = async (params) => {
  try {
    await helpers.validateSchema(VehicleSchema, params);
    const res = await api.saveUpdateVehicleAgainstTransporter_New(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllVehiclesForTransporter_CT = async (params) => {
  try {
    const res = await api.fetchAllVehiclesForTransporter_CT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteVehicleAgainstTransporter_CT = async (params) => {
  try {
    const res = await api.deleteVehicleAgainstTransporter_CT(params);
    return res;
  } catch (err) {
    throw err;
  }
};
