import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  getOAuthToken(params = {}) {
    return params;
  },

  getLocationOptions(params = {}) {
    return params;
  },

  getLocationDetails(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  getOAuthToken(resData) {
    return resData;
  },

  getLocationOptions(resData) {
    return resData;
  },

  getLocationDetails(resData) {
    return resData;
  },
};
