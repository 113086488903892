import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const recordPaymentByBuyerSellerSchema = yup.object().shape({
  // amount_paid_received: yup.number().required('Amount is required').positive('Amount is required'),
  transaction_date: yup.string().required('Payment Date is required'),
  // tds: yup.number().min(0, 'Invalid TDS'),
  tds: yup.number(),
  bank_account_number: yup.string().required('bank Account is required'),
  utr: yup.string().required('Payment Ref/UTR No. is required'),
  // amount_due: yup.number().when('is_p2', {
  //   is: false,
  //   then: yup.number().test('amount_due', 'Invalid Amount!', function (item) {
  //     let val = parseFloat(this.parent.amount_paid_received);
  //     // let val = parseFloat(this.parent.amount_paid_received + this.parent.tds);
  //     let result = false;
  //     if (val <= this.parent.amount_due) {
  //       result = true;
  //     }
  //     // if (val > this.parent.amount_due) {
  //     //   result = true;
  //     // }
  //     return result;
  //   }),
  //   otherwise: yup.number().test('amount_due', 'Invalid Amount!', function (item) {
  //     let val = parseFloat(this.parent.amount_paid_received + this.parent.tds);
  //     let result = false;
  //     if (val === this.parent.amount_due) {
  //       result = true;
  //     }
  //     return result;
  //   }),
  // }),
});

export const fetchOnlinePaymentOrderDetails = async (params = {}) => {
  try {
    let res = await api.fetchOnlinePaymentOrderDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchOnlinePaymentOrderDetailsFinance = async (params = {}) => {
  try {
    let res = await api.fetchOnlinePaymentOrderDetailsFinance(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handelPaymentDelete = async (params = {}) => {
  try {
    let res = await api.handelPaymentDelete(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchReportList = async (params = {}) => {
  try {
    let res = await api.fetchReportList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPaymentDetails = async (params) => {
  try {
    let res = await api.fetchPaymentDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const recordPaymentByBuyerSeller = async (params) => {
  try {
    // await helpers.validateSchema(recordPaymentByBuyerSellerSchema, params);
    await helpers.validateSchema(recordPaymentByBuyerSellerSchema, params?.payment_list?.[0]);
    const res = await api.recordPaymentByBuyerSeller(params);
    return res;
  } catch (err) {
    const allErrors = _.get(err, 'errors') || '';
    const amountToPay = _.get(allErrors, 'amount_due') || '';
    let error = {
      ...err,
    };
    if (amountToPay == `Invalid Amount!`) {
      let errors = _.get(error, 'errors') || {};
      errors = {
        ...errors,
        amount_due: `Invalid Amount.Amount Due: ${params['amount_due']}`,
      };
      error = {
        ...err,
        errors: errors,
      };
    }

    throw error;
  }
};

export const fetchPaymentDetailsForEditPayment = async (params) => {
  try {
    let res = await api.fetchPaymentDetailsForEditPayment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAuditAgainstEntityId = async (params) => {
  try {
    let res = await api.fetchAuditAgainstEntityId(params);
    return res;
  } catch (err) {
    throw err;
  }
};
