import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import history from 'lib/history';
import * as yup from 'yup';

import { actions as farmActions } from 'redux/farms';
import { farmService } from 'services';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Farm...',
      key: 'req-form',
    });
    values.address_line1 = values.address_line1.trim();
    values.pin_code = values.pin_code.trim();
    values.location_code = values.location_code.trim();
    const res = await farmService.create(values);
    notifications.show({
      type: 'success',
      message: 'Farm Created.',
      key: 'req-form',
    });

    globalThis.store.dispatch(farmActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/farms`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Farm Details...',
      key: 'req-form',
    });
    values.address_line1 = values.address_line1.trim();
    values.pin_code = values.pin_code.trim();
    values.location_code = values.location_code.trim();

    const id = _.get(values, 'id') || 0;
    const res = await farmService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Farm details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(farmActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/farms`));

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await farmService.destroy(id);

    globalThis.store.dispatch(farmActions.removeOne(id));
    // redirect.web('/account/farms');
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}
