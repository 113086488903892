import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

import _ from 'lodash';

export default function FormikAOISelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialValue,
    entity,
    disabled,
    enumApiCall,
    qparams,
    extendedProfileQuality,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const referenceData = useSelector((state) => state.referenceData);
  const [value, setValue] = React.useState([]);
  let loading = false;
  // let entityData = _.get(referenceData, `data[${entity}]`);
  let entityData = useSelector((state) => {
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams)[0];
      return qId > 0 ? state.referenceData.data[entity][qId] || [] : [];
    }

    const found = state.referenceData.data[entity];

    return Array.isArray(found) ? found : [];
  });
  if (!Array.isArray(entityData)) {
    entityData = [];
  }

  if (!entityData.length) {
    loading = true;
  }

  let options = _.map(entityData, (item, index) => {
    return {
      label: item.display_member || '',
      value: {
        id: extendedProfileQuality ? parseInt(item.quality_spec_id) : parseInt(item.value_member),
        ...item,
      },
    };
  });

  // console.log('v-------------entityData before------------------->', options, entityData, initialValue, value);

  let selectedLabels = initialValue;
  // options = options.filter((item) => value.every((item2) => item2.value.id != item.value.id));
  useEffect(() => {
    if (!_.isEmpty(qparams)) {
      dispatch(refDataActions.getCommodityDropdownData(entity, qparams));
    } else if (enumApiCall) {
      dispatch(refDataActions.getEnumData(entity));
    } else {
      dispatch(refDataActions.getReferenceData(entity));
    }
  }, [entity]);

  useEffect(() => {
    const initialSelected = options.filter((o) => initialValue.includes(o.value?.id));
    // console.log('v-------------initialSelected after------------------->', options, initialValue, initialSelected);
    if (!_.isEmpty(initialSelected)) {
      setValue(initialSelected);
    }
  }, [initialValue, entityData]);

  const transformOptions = options.filter((o) => !initialValue.includes(o.value?.id));
  // console.log('v-------------entityData after------------------->', options, transformOptions);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-multi-select ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        multiple
        size="small"
        disableClearable
        fullWidth
        limitTags={10}
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        // filterOptions={(x) => x}
        // loading={true}
        options={transformOptions}
        // filterSelectedOptions={true} // TODO : debug - does not work
        // includeInputInList
        value={value}
        onChange={(event, newValue) => {
          // console.log('FormikAOISelect----newValue---->', newValue);
          const tranformValue = (newValue || []).map((item) => {
            // console.log(`newValue item`, item);
            // const newObject = item?.value?.quality_spec_id;
            const newObject = extendedProfileQuality ? item?.value?.quality_spec_id : item?.value?.value_member

            return newObject;
          })
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(tranformValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
