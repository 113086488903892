import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Drivers
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/CarNot/FetchAllCarnotDeviceDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    console.log('OmkarResData', resData);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

export const saveAndUpdateCarnotDeviceChanges = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/CarNot/SaveAndUpdateCarnotDeviceChanges`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};
