import _ from 'lodash';
import utils from 'lib/utils';
import qs from 'query-string';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const getOAuthToken = async (params = {}) => {
  try {
    let bodyParams = reqTransform.getOAuthToken(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/LocationSetup/GetOAuthToken`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.getOAuthToken(resData);
  } catch (err) {
    console.log('api-transportDeal-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetOAuthToken');
    throw errors;
  }
};

export const getLocationOptions = async (params = {}) => {
  try {
    let qs = reqTransform.getLocationOptions(params);
    const url = `https://prep.biofuelcircle.com/node/proxy/https://atlas.mapmyindia.com/api/places/search/json?query=${qs}`;

    const location_token = localStorage.getItem('location_token');

    const location_token_parsed = JSON.parse(location_token);

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + location_token_parsed.access_token,
      },
    };

    const res = await fetch(url, options);
    const resData = await utils.processApiResELoc(res);
    return resTransform.getLocationOptions(resData);
  } catch (err) {
    console.log('api-transportDeal-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'getLocationOptions');
    throw errors;
  }
};

export const getLocationDetails = async (params = {}) => {
  try {
    const qs = reqTransform.getLocationDetails(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/LocationSetup/GetLocationDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.getLocationDetails(resData);
  } catch (err) {
    console.log('api-transportDeal-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetLocationDetails');
    throw errors;
  }
};

export const getReverseLocationDetails = async (params = {}) => {
  try {
    const qs = reqTransform.getLocationDetails(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/LocationSetup/GetReverseGeoCode?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.getLocationDetails(resData);
  } catch (err) {
    console.log('api-transportDeal-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetReverseGeoCode');
    throw errors;
  }
};
