import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchDealAllotments = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchDealAllotments(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};
export const fetchUnconfirmedDelieveries = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchUnconfirmedDelieveries(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchUnconfirmedDeliverySchedule`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchNotConfirmedList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};
export const fetchDealAllotmentsELoc = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchDealAllotments(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const fetchListByPersonId = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchListByPersonId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_ELOC_Lite`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveries_ELOC_Lite');
    throw errors;
  }
};

export const fetchAdditionalInfoDeliveries = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchListByPersonId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_AdditionalParams`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchDeliveries->err---->', err);
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const fetchListByPersonIdReport = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchListByPersonId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_ELOC_Lite`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListByPersonId(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveries_ELOC_Lite');
    throw errors;
  }
};

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchDeliveryAllotmentsAgainstCommodityDealMasterId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveryAllotmentsAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log(
      'UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId-update->err---->',
      err,
    );

    const errors = await utils.processApiErrors(
      err,
      '',
      'UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId',
    );
    throw errors;
  }
};
export const updateCT = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log(
      'UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId-update->err---->',
      err,
    );

    const errors = await utils.processApiErrors(
      err,
      '',
      'UpdateAndDeleteDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleAllotedId_CT',
    );
    throw errors;
  }
};

export const updateUnusedDeliveryStatus = async (params, deliveryArray) => {
  try {
    const qs = reqTransform.updateUnusedDeliveryStatus(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateUnusedDeliveryStatus?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(deliveryArray),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateUnusedDeliveryStatus(resData);
  } catch (err) {
    console.log('api-UpdateUnusedDeliveryStatus-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateUnusedDeliveryStatus');
    throw errors;
  }
};
