import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { discoveryBuyersSellersService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'discoveryBuyersSellers',
  initialState: adapter.getInitialState({
    explorelist: [],
    busy: false,
    hasMore: true,
    commodity_count: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setExplorelist: (state, { payload }) => ({ ...state, explorelist: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setCommodityCount(state, action) {
      state.commodity_count = action.payload;
    },
  },
});

const fetchDiscoveryBuyersSellers = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await discoveryBuyersSellersService.fetchDiscoveryBuyersSellers(params, dispatch);
    console.log('check resData', resData);
    if (_.isEmpty(resData) || Object.keys(resData).length < 30) {
      dispatch(slice.actions.setHasMore(true));
    }
    dispatch(slice.actions.upsertMany(resData));
    // dispatch(slice.actions.setExplorelist(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateCommodityCount = (commodity_count) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCommodityCount(commodity_count));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
};
const actions = {
  ...slice.actions,
  fetchDiscoveryBuyersSellers,
  updateCommodityCount,
};

const selectors = {
  ...adapter.getSelectors((state) => state.discoveryBuyersSellers),
};

export { actions, selectors };

export default slice.reducer;
