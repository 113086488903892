import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import TransportDealService, { TransportDealType } from 'services/transportDeal';

export interface DealState {
  data: Record<string, TransportDealType>;
  order: number[];
  loading: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: DealState = {
  data: {},
  order: [],
  loading: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const slice = createSlice({
  name: 'transportDeals',
  initialState,
  reducers: {
    startFetching(state) {
      state.loading = true;
    },
    endFetching(state) {
      state.loading = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.loading = false;
    },
    add(state, action: PayloadAction<TransportDealType[]>) {
      const order: number[] = [];
      const data = {};
      if (action.payload) {
      }

      for (let deal of action.payload) {
        const id = deal.transport_deal_master_id;
        data[id] = { ...deal };
        order.push(id);
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.loading = false;
    },
    // create(state: PlainOfferState, action) {
    //   console.log(action);
    //   debugger;
    //   // //@TODO: pass person_id filed value to post & handle response
    //   // api.createSocialPost(action.payload);
    // },
    // update(state: PlainOfferState, action) {
    //   const { commodity_req_offer_id, ...rest } = action.payload;
    //   state.data[commodity_req_offer_id] = { ...state.data[commodity_req_offer_id], ...rest };
    //   state.updatedAt = Date.now();
    // },
    // delete(state: PlainOfferState, action) {
    //   // debugger;
    //   const post_id = action.payload;
    //   let order = _.get(state, 'order', []) || [];
    //   // const posts = state.data.filter(p => p.post_id !== post_id)
    //   delete state['data'][post_id];
    //   let filteredOrder = order.filter((id) => id !== post_id);
    //   state['order'] = filteredOrder;
    // },
  },
});

const FetchAllTransportDealDetails = (page, pageSize) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await TransportDealService.FetchAllTransportDealDetails(page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchTransportDealDetailsAgainstTransportDealMasterId = (id) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await TransportDealService.FetchTransportDealDetailsAgainstTransportDealMasterId(id);
      dispatch(slice.actions.add([resData.data]));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

// const FetchBalanceQuantity = (id) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch(slice.actions.startFetching());
//       const resData = await TransportDealService.FetchBalanceQuantity(id);
//       dispatch(slice.actions.add(resData.data));
//     } catch (errors) {
//       // dispatch(slice.actions.handleErrors({ ...errors }));
//       dispatch(slice.actions.add([]));
//     }
//   };
// };

export const actions = {
  ...slice.actions,
  // FetchBalanceQuantity,
  FetchAllTransportDealDetails,
  FetchTransportDealDetailsAgainstTransportDealMasterId,
};

export default slice.reducer;
