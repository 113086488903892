import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as teamLocationActions } from 'redux/teamLocation';

import _ from 'lodash';

export default function FormikLocationSelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialValue,
    entity,
    disabled,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const teamLocation = useSelector((state) => state.teamLocation);
  const [value, setValue] = React.useState([]);
  let loading = false;
  let entityData = _.get(teamLocation, `entities`) || {};

  // if (!entityData.length) {
  //   loading = true;
  // }

  let options = [];
  _.map(entityData, (item, index) => {
    options.push({
      label: item.display_member || '',
      value: {
        id: parseInt(item.value_member),
        ...item,
      },
    });
  });

  let selectedLabels = (initialValue || []).map((v) => v.label);
  options = options.filter((option) => !selectedLabels.includes(option.label));

  useEffect(() => {
    dispatch(teamLocationActions.fetchList());
  }, []);

  useEffect(() => {
    const initialSelected = options.filter((o) => initialValue.includes(o.value?.id));
    setValue(initialSelected);
  }, [initialValue, entityData]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-multi-select ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        multiple
        size="small"
        disableClearable
        fullWidth
        limitTags={3}
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
