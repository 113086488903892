import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['entity_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'farm_address.person_address_id') || 0,
        ...(_.get(d, 'farm_address') || {}),
        name_of_farm: _.get(d, 'farm_address.address_text') || '',
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
};
