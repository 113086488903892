import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';

const slice = createSlice({
  name: 'system',
  initialState: { isServerUnderMaintenance: false },
  reducers: {
    update(state, actions) {
      state.isServerUnderMaintenance = actions.payload;
    },
  },
});

const updateServerUnderMaintenanceStatus = (value) => async (dispatch) => {
  try {
    dispatch(slice.actions.update(value));
  } catch (err) {
    dispatch(slice.actions.update(false));
    utils.displayErrors(err);
  }
};

const actions = {
  ...slice.actions,
  updateServerUnderMaintenanceStatus,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
