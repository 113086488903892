/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = '/BioFuelTransportRequirementOffers/api';

const SaveTransportRequirement = async (payload) => {
  const finalPayload = {
    ...payload,
    rate_uom_id: payload.max_capacity_uom_id,
    entity_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.user_type_enum,
    company_affiliate_enum: 1, // @TODO: hardcoded,
  };

  const url = `${BASE_URL}/TransportRequirement/SaveTransportRequiremet`;

  const details = await fetch.create(url, finalPayload);

  return details;
};

const EditTransportRequirement = async (id: number, payload) => {
  const finalPayload = {
    ...payload,
    transport_req_offer_id: id,
    rate_uom_id: payload.max_capacity_uom_id,
    entity_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.user_type_enum,
    company_affiliate_enum: 1, // @TODO: hardcoded,
  };

  const url = `${BASE_URL}/TransportRequirement/EditTransportRequirement`;

  const details = await fetch.update(url, finalPayload);

  return details;
};

const PublishTransportRequirement = async (id: number) => {
  const params = {
    transport_req_offer_id: id,
    entity_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/PublishTransportRequirement?${qs}`;

  const details = await fetch.update(url, {});

  return details;
};

const FetchTransportRequirementDetailsAgainstId = async (id: number) => {
  const params = {
    transport_req_offer_id: id,
    person_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/FetchTransportRequirementDetailsAgainstId?${qs}`;

  const details = await fetch.fetchList(url);

  return details;
};

/**@deprecated */
const FetchAllTransportRequirementWithinCircle = async (pageSize: number) => {
  const params = {
    entity_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.user_type_enum || 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
    offset: 0,
    page_size: pageSize,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/FetchAllTransportRequirementWithinCircle?${qs}`;

  const details = await fetch.fetchList(url);

  return details;
};

const AcceptTransportOffer = async (id: number) => {
  const params = {
    transport_req_offer_response_id: id,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/AcceptTransportOffer?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const RejectTransportOffer = async (id: number) => {
  const params = {
    transport_req_offer_response_id: id,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/RejectTransportOffer?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const WithdrawTransportRequirementById = async (id: number) => {
  const params = {
    transport_req_offer_id: id,
    Entity_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/WithdrawTransportRequirementById?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const FollowUnfollowTransportRequirement = async (id: number, followUnfollow: number) => {
  const params = {
    person_id: globalThis?.user?.userid,
    transport_req_offer_id: id,
    user_type_enum: globalThis?.user?.user_type_enum || 2,
    follow_unfollow_enum: followUnfollow ? 1 : 2,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TransportRequirement/FollowUnfollowTransportRequirement?${qs}`;

  const details = await fetch.update<{}>(url, {});

  return details;
};

const TransportRequirementFilteredData = async (filters = {}, pageSize: number = 20) => {
  const finalPayload = {
    filters: {
      ...filters,
    },
    offset: 0,
    page_size: pageSize,
    controller_enum: 3,
  };

  const url = `/BioFuelCommonUtilities/api/Filter/GetControllerData_ELOC`;
  const response = await fetch.create(url, finalPayload);
  return response;
};

export default {
  TransportRequirementFilteredData,
  SaveTransportRequirement,
  FetchTransportRequirementDetailsAgainstId,
  FetchAllTransportRequirementWithinCircle,
  EditTransportRequirement,
  AcceptTransportOffer,
  RejectTransportOffer,
  PublishTransportRequirement,
  WithdrawTransportRequirementById,
  FollowUnfollowTransportRequirement,
};
