import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { appConfigService } from 'services';
import history from 'lib/history';
import { actions as userActions } from 'redux/user';
import { actions as contextAction } from 'redux/context';
import { userService, authService } from 'services';
import config from 'config';

const { indentDealsUrl } = config;

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'appConfig',
  initialState: adapter.getInitialState({
    busy: false,
    appConfigData: {},
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setAppConfigData: (state, { payload }) => ({ ...state, appConfigData: payload }),
    setMargin: (state, { payload }) => ({ ...state, margin: payload }),
    setInterestRate: (state, { payload }) => ({ ...state, interestRate: payload }),
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const getAppConfig = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await appConfigService.getAppConfig(params);
    dispatch(slice.actions.setAppConfigData(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const verifySsoToken = (params, setIsOpen) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await appConfigService.verifySsoToken(params);
    if (resData?.stat) {
      if (resData?.message === 'NumberNotFoundInTheSystem') {
        setIsOpen(true);
        return;
        // await appConfigService.registerUserViaSso(params);
      }
      if (window) {
        window.location.href = '/';
        if (window.location.href.includes(`${indentDealsUrl}`)) {
          window.location.href = indentDealsUrl + '/dashboard';
        }
        return;
      }

      globalThis.store.dispatch(
        userActions.updateUser({
          ...resData,
          isAuthenticated: false,
        }),
      );
      let userProfile = {};
      userProfile = await userService.fetchPersonalDetails();

      globalThis.store.dispatch(
        miscActions.update({
          flush: true,
        }),
      );

      globalThis.store.dispatch(
        userActions.updateUser({
          ...resData,
          ...userProfile,
          isAuthenticated: true,
        }),
      );
      dispatch(slice.actions.setBusy(false));
      return resData;
    }
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const registerUserViaSso = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await appConfigService.registerUserViaSso(params);
    if (window) {
      window.location.href = '/';
      if (window.location.href.includes(`${indentDealsUrl}`)) {
        window.location.href = indentDealsUrl + '/dashboard';
      }
      return;
    }

    globalThis.store.dispatch(
      userActions.updateUser({
        ...resData,
        isAuthenticated: false,
      }),
    );
    let userProfile = {};
    userProfile = await userService.fetchPersonalDetails();

    globalThis.store.dispatch(
      miscActions.update({
        flush: true,
      }),
    );

    globalThis.store.dispatch(
      userActions.updateUser({
        ...resData,
        ...userProfile,
        isAuthenticated: true,
      }),
    );
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const validateSession = (params, ssoId) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await appConfigService.validateSession(params, ssoId);
    if (window) {
      // window.location.href = '/';
      if (!_.isEmpty(resData)) {
        await dispatch(contextAction.updateContext('2'));
        const iProfitMappedMaterialData = resData?.iprofit_data?.material;
        const iProfitMappedCompanyId = resData?.iprofit_data?.company_id;
        const iProfitMappedCompanyCode = resData?.iprofit_data?.company_code;
        if (params?.page_id == 1) {
          window.location.href = getUrlWithEncryptedQueryParams(
            `${window?.location?.origin}/commodity/offers/create?iProfit_commodity_id=${iProfitMappedMaterialData?.commodity_id}&iProfit_commodity_code=${iProfitMappedMaterialData?.commodity_code}&iProfit_commodity_type_id=${iProfitMappedMaterialData?.commodity_type_id}&iProfit_commodity_type_code=${iProfitMappedMaterialData?.commodity_type_code}&iProfit_commodity_form_id=${iProfitMappedMaterialData?.commodity_form_id}&iProfit_commodity_form_code=${iProfitMappedMaterialData?.commodity_form_code}&iProfit_commodity_residue_id=${iProfitMappedMaterialData?.commodity_residue_id}&iProfit_commodity_residue_code=${iProfitMappedMaterialData?.commodity_residue_code}&iProfit_company_id=${iProfitMappedCompanyId}&iProfit_company_code=${iProfitMappedCompanyCode}`,
          );
        } else if (params?.page_id == 2) {
          window.location.href = getUrlWithEncryptedQueryParams(
            `${window?.location?.origin}/commodity/requirements/all?page=1&commodity_id=${iProfitMappedMaterialData?.commodity_id}&commodity_form_id=${iProfitMappedMaterialData?.commodity_form_code}`,
          );
        } else if (params?.page_id == 3) {
          window.location.href = getUrlWithEncryptedQueryParams(
            `${window?.location?.origin}/commodity/requirements/create?iProfit_commodity_id=${iProfitMappedMaterialData?.commodity_id}&iProfit_commodity_code=${iProfitMappedMaterialData?.commodity_code}&iProfit_commodity_type_id=${iProfitMappedMaterialData?.commodity_type_id}&iProfit_commodity_type_code=${iProfitMappedMaterialData?.commodity_type_code}&iProfit_commodity_form_id=${iProfitMappedMaterialData?.commodity_form_id}&iProfit_commodity_form_code=${iProfitMappedMaterialData?.commodity_form_code}&iProfit_commodity_residue_id=${iProfitMappedMaterialData?.commodity_residue_id}&iProfit_commodity_residue_code=${iProfitMappedMaterialData?.commodity_residue_code}&iProfit_company_id=${iProfitMappedCompanyId}&iProfit_company_code=${iProfitMappedCompanyCode}`,
          );
        } else if (params?.page_id == 4) {
          window.location.href = getUrlWithEncryptedQueryParams(
            `${window?.location?.origin}/commodity/offers/all?page=1&commodity_id=${iProfitMappedMaterialData?.commodity_id}&commodity_form_id=${iProfitMappedMaterialData?.commodity_form_code}`,
          );
        }
      }
      if (window.location.href.includes(`${indentDealsUrl}`)) {
        window.location.href = indentDealsUrl + '/dashboard';
      }
      return;
    }

    globalThis.store.dispatch(
      userActions.updateUser({
        ...resData,
        isAuthenticated: false,
      }),
    );
    let userProfile = {};
    userProfile = await userService.fetchPersonalDetails();

    globalThis.store.dispatch(
      miscActions.update({
        flush: true,
      }),
    );

    globalThis.store.dispatch(
      userActions.updateUser({
        ...resData,
        ...userProfile,
        isAuthenticated: true,
      }),
    );
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  getAppConfig,
  verifySsoToken,
  registerUserViaSso,
  validateSession,
};

const selectors = {
  ...adapter.getSelectors((state) => state.transportDriver),
};

export { actions, selectors };

export default slice.reducer;
