import React, { useState, useEffect } from 'react';
import { Route, Switch, NavLink, Link } from 'react-router-dom';
import Details from './Details';
import Organisations from './Organisations';
import Teams from './Teams';
import Farms from './Farms';
import Transport from './Transport';
import UploadIndividual from './UploadIndividual';
import KYCuserIdentity from './KYCIndividual/index';
import KYCBusinessIdentity from './KYCBusiness';
import './profile.scss';
import { useSelector, useDispatch } from 'react-redux';
import utils, {  getDecryptedUrl, getUrlWithEncryptedQueryParams, useAnalyticsEventTracker } from 'lib/utils';
import useQuery from 'lib/hooks/useQuery';
import BackbuttonIcon from '../../Html/images/back-button.svg';
import { actions as kycUserActions } from 'redux/kycUser';
import { actions as userActions } from 'redux/user';
import { actions as rolesAndRightsActions } from 'redux/rolesAndRights';
import { actions as kycRolesAndRightsActions } from 'redux/kycRolesAndRights';
import MySubscription from 'pages/subscription/components/MySubscription';
import Loader from 'common/Loader';
import { PROFILE_TABS_ENUM, TYPE_OF_INDIVIDUAL_ENUM } from 'constants/enums';
import _ from 'lodash';
import Vehiclelocations from './Transport/VehicleLocations';
import Members from './Members';

const Profile = (props) => {
  const dispatch = useDispatch();
  const { back, isUserKyc } = useQuery();
  const id = props.id;
  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  let rolesAndRights = useSelector((state) => state.rolesAndRights);
  let rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  let kycRolesAndRights = useSelector((state) => state.kycRolesAndRights);
  let kycRolesAndRightsList = _.get(kycRolesAndRights, 'entities') || {};

  const isFacilitator = user?.profile?.person_role?.some(role => role.role_code === 'Facilitator')
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const isSuperUser = _.get(user, 'profile.person_role')?.some(role => role?.role_id === 20) || false;
  const typeOfIndividualEnum = Boolean(isUserKyc) ? _.get(kycUser, 'profile.person.type_of_individual_enum') : _.get(user, 'profile.person.type_of_individual_enum');

  const kycUserTypeEnum = _.get(kycUser, 'profile.person.user_type_enum');

  const isFPO = Boolean(isUserKyc) ? _.get(kycUser, 'profile.person.is_fpo') : _.get(user, 'profile.person.is_fpo')
  const isKYCDone = Boolean(isUserKyc) ? _.get(kycUser, 'profile.person.is_kyc_done') : _.get(user, 'profile.person.is_kyc_done')
  const isAdminUser = _.get(user, 'profile.person.is_Admin') || user?.profile?.person_role?.some(item => item.role_id === 1)
  const kycUserPersonId = _.get(kycUser, 'profile.person.person_id');

  let idCompare = parseInt(id);

  let isAdmin = false;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }

  let acl = utils.getACL(user);
  let userHasRole = user?.profile?.person?.type_of_individual_enum !== 0 ? true : false;

  if (user.userid !== parseInt(id)) {
    acl = utils.getACL(kycUser);
    userHasRole = kycUser?.profile?.person?.type_of_individual_enum !== 0 ? true : false;
    rolesAndRightsList = {};
    rolesAndRightsList = kycRolesAndRightsList;
    // typeOfIndividualEnum = _.get(kycUser, 'profile.person.type_of_individual_enum');
  }

  let navClass = 'tabs-primary';
  if (user.userid !== parseInt(id)) {
    navClass = 'tabs-secondary';
  }

  useEffect(() => {
    if (user.userid !== parseInt(id)) {
      dispatch(kycUserActions.fetchPersonalDetails(id));
      dispatch(kycRolesAndRightsActions.fetchList({ view_profile_of_person_id: id }));
    } else if (user.userid == parseInt(id)) {
      dispatch(userActions.fetchPersonalDetails(id));
      dispatch(rolesAndRightsActions.fetchList());
    }
  }, [id]);

  if (user.id != id) {
    const kycUserId = _.get(kycUser, 'profile.person.person_id');
    if (kycUserId !== parseInt(id)) {
      return <Loader loading={true} />;
    }
  }

  const showPersonalDetails = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.PERSONALDETAILS),
  );
  const showOrganisation = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.ORGANISATION),
  );
  const showTeam = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TEAM),
  );
  const showTransport = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TRANSPORT),
  );
  const showKyc = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.KYC),
  );
  const showUpload = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.UPLOAD),
  );
  const showFrams = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.FARMS_CROPS),
  );
  const showFinance = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.FINANCE, x.sub_module_id === PROFILE_TABS_ENUM.FINANCE),
  );

  const encodeBack = utils.encodeCustomUrl(back);

  const encodeBackUrl = utils.encodeBackUrl();
  const loc = getDecryptedUrl(window.location.href);

  let backLink = `/account/${id}/myaccount`;
  try {
    backLink = utils.decodeBackUrl(back);
  } catch (err) {
    console.log('decode back url issue', err);
  }

  const gaEventTracker = useAnalyticsEventTracker('Profile');

  const toggle = (action) => {
    gaEventTracker(action)
    // console.log('gaEventTracker(action)', gaEventTracker)
  };

  return (
    <React.Fragment>
      <div className="account-profile-wrapper">
        <div className={`bfc-tabs ${navClass} d-flex`}>
          <ul className="mr-auto overflow-auto">
            {isAdmin && (
              <Link to={backLink}>
                <img src={BackbuttonIcon} alt="" className="mt-10" />
              </Link>
            )}
            <li>
              {isAdmin ? (
                <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/myaccount?back=${back}`)}>Personal Details</NavLink>
              ) : (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/myaccount`)}>Personal Details</NavLink>
              )}
            </li>
            {(showOrganisation?.full_access || showOrganisation?.read_access) && (
              <React.Fragment>
                <li>
                  {isAdmin ? (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/organisations?back=${back}`)}>Organisation</NavLink>
                  ) : (
                      <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/organisations`)}>Organisation</NavLink>
                  )}
                </li>
              </React.Fragment>
            )}

            {(showTeam?.full_access || showTeam?.read_access) && (
              <React.Fragment>
                <li>
                  {isAdmin ? (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/teams?page=1&back=${back}`)}>Teams</NavLink>
                  ) : (
                      <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/teams?page=1`)}>Teams</NavLink>
                  )}
                </li>
              </React.Fragment>
            )}

            {(isFPO || typeOfIndividualEnum == 10) && isKYCDone && !Boolean(isUserKyc) && (
              <li>
                <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/members?page=1&back=${back}`)}>Members</NavLink>
              </li>
            )}

            {(isFPO || typeOfIndividualEnum == 10) && isKYCDone && Boolean(isUserKyc) && isSuperUser && (
              <li>
                <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/members?page=1&isUserKyc=${isUserKyc}&back=${back}`)}>Members</NavLink>
              </li>
            )}

            {(showFrams?.full_access || showFrams?.read_access) && (
              <li>
                {isAdmin ? (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/farms?back=${back}`)}>Farms & Crops</NavLink>
                ) : (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/farms`)}>Farms & Crops</NavLink>
                )}
              </li>
            )}

            {(showTransport?.full_access || showTransport?.read_access) && (
              <React.Fragment>
                <li>
                  {isAdmin ? (
                    <NavLink
                      to={
                        loc.includes(getUrlWithEncryptedQueryParams(`/account/${id}/locations`))
                          ? getUrlWithEncryptedQueryParams(`/account/${id}/locations?back=${back}`)
                          : getUrlWithEncryptedQueryParams(`/account/${id}/transport?back=${back}`)
                      }>
                      Transport
                    </NavLink>
                  ) : (
                    <NavLink
                      to={
                          loc.includes(getUrlWithEncryptedQueryParams(`/account/${id}/locations`))
                            ? getUrlWithEncryptedQueryParams(`/account/${id}/locations`)
                            : getUrlWithEncryptedQueryParams(`/account/${id}/transport`)
                      }>
                      Transport
                    </NavLink>
                  )}
                </li>
              </React.Fragment>
            )}

            {(showKyc?.full_access || showKyc?.read_access) && acl.isIndividual && (
              <li>
                {isAdmin ? (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/kyc?isCtKyc=true&back=${back}&id=${id}`)}>KYC</NavLink>
                ) : (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/kyc`)}>KYC</NavLink>
                )}
              </li>
            )}
            {(showKyc?.full_access || showKyc?.read_access) && acl.isCompanyAdmin && (
              <li>
                {isAdmin ? (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/kyc-b?isCtKyc=true&back=${back}`)}>KYC</NavLink>
                ) : (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/kyc-b`)}>KYC</NavLink>
                )}
              </li>
            )}
            {(showUpload?.full_access || showUpload?.read_access) && (acl.isCompanyAdmin || acl.isIndividual) && (
              <li>
                {isAdmin ? (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/upload?back=${back}`)}>Upload</NavLink>
                ) : (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/upload`)}>Upload</NavLink>
                )}
              </li>
            )}
            {(showFinance?.full_access || showFinance?.read_access) && acl.isCompanyAdmin && (
              <li>
                {isAdmin ? (
                  <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/finance?back=${back}`)}>Finance</NavLink>
                ) : (
                    <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/finance`)}>Finance</NavLink>
                )}
              </li>
            )}
            {isControlTowerUser && isAdmin && (
              <li>
                <NavLink to={getUrlWithEncryptedQueryParams(`/account/${id}/subscription?back=${back}`)}>Subscription</NavLink>
              </li>
            )}
          </ul>
          <div className="supplier-profile-link mt-15">
            {(typeOfIndividualEnum === TYPE_OF_INDIVIDUAL_ENUM.BUSINESS_ORGANISATION ||
              typeOfIndividualEnum === TYPE_OF_INDIVIDUAL_ENUM.FPO_USER) && !isFacilitator &&
              showKyc?.full_access &&
              !isControlTowerUser ? (
              <Link
                onClick={() => toggle({
                  hitType: 'event',
                  eventCategory: 'My Profile Details',
                  eventAction: 'Open Supplier Profile List',
                  eventLabel: 'Supplier Profile List'
                })}
                to={`/extended_profile?back=${encodeBackUrl}`}
                style={{ fontSize: 'var(--fs-base__two)', fontWeight: 'bold', color: 'var(--unnamed-color-1b62ab)', whiteSpace: 'nowrap' }}>
                Supplier Profile
              </Link>
            ) : null}
            {/* <NavLink style={{marginLeft:"20px", fontSize: 'var(--fs-base__two)', fontWeight: 'bold', color: 'var(--unnamed-color-1b62ab)', whiteSpace: 'nowrap' }} to={`/accountDelete/${id}/delete`}>Delete Account</NavLink> */}
          </div>
        </div>
        <Switch>
          <Route path={`/account/:id/teams`}>
            <Teams id={id} />
          </Route>
          <Route path={`/account/:id/members`}>
            <Members id={id} kycUserPersonId={kycUserPersonId} />
          </Route>
          <Route path={`/account/:id/organisations`}>
            <Organisations id={id} />
          </Route>
          <Route path={`/account/:id/farms`}>
            <Farms id={id} />
          </Route>
          <Route path={`/account/:id/transport`}>
            <Transport id={id} />
          </Route>
          <Route path={`/account/:id/locations`}>
            <Vehiclelocations id={id} />
          </Route>
          <Route path={`/account/:id/kyc`}>
            <KYCuserIdentity id={id} />
          </Route>
          <Route exact path={`/account/:id/upload`}>
            {/* {userHasRole && acl.isIndividual ? <UploadIndividual /> : <UploadBusiness />} */}
            {userHasRole && acl.isCompany && <KYCBusinessIdentity id={id} />}
            {userHasRole && acl.isIndividual && <UploadIndividual id={id} />}
          </Route>
          <Route exact path={`/account/:id/kyc-b`}>
            <KYCBusinessIdentity id={id} />
          </Route>
          <Route path={`/account/:id/finance`}>
            <KYCBusinessIdentity id={id} />
          </Route>
          <Route path={`/account/:id/subscription`}>
            <MySubscription id={id} isAdmin={isAdmin} />
          </Route>
          <Route exact path={`/account/:id/myaccount`}>
            <Details id={id} />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default Profile;
