import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Plan/FetchAllPlans_PhaseTwo`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllPlans-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPlans_PhaseTwo');
    throw errors;
  }
};
export const fetchDetails = async (id) => {
  try {
    const qs = reqTransform.fetchDetails(id);
    const url = `${baseUrl}/BioFuelPayments/api/Plan/FetchPlanAgainstSubscriptionPlanId_PhaseTwo?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSubcriptionDetails(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPlanAgainstSubscriptionPlanId_PhaseTwo');
    throw errors;
  }
};
export const fetchUserSubscriptionDetails = async () => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchAllSubscriptions`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserSubscriptionList(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllSubscriptions');
    throw errors;
  }
};
export const fetchKycUserSubscriptionDetails = async (params) => {
  try {
    const qs = reqTransform.fetchKycUserSubscriptionDetails(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchAllSubscriptions?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserSubscriptionList(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllSubscriptions');
    throw errors;
  }
};
export const create = async (params) => {
  try {
    const qs = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/CreateSubscription_New?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('CreateSubscription-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateSubscription_New');
    throw errors;
  }
};

export const createTrialSubscription = async (params) => {
  try {
    const qs = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/CreateTrialSubscription?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('CreateSubscription-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateTrialSubscription');
    throw errors;
  }
};

export const createContact = async () => {
  try {
    const bodyParams = reqTransform.createContact();
    const url = `${baseUrl}/BioFuelCommonUtilities/api/CommonUtilities/CreateEnquiry`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createContact(resData);
  } catch (err) {
    console.log('CreateEnquiry-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateEnquiry');
    throw errors;
  }
};

export const updateCoupon = async (params) => {
  try {
    const qs = reqTransform.updateCoupon(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/ApplyDiscountCouponCode?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ApplyDiscountCouponCode');
    throw errors;
  }
};

export const fetchUserSubscriptionPhaseTwoDetails = async (params) => {
  try {
    const qs = reqTransform.fetchKycUserSubscriptionDetails(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchAllSubscriptions_PhaseTwo?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserSubscriptionList(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllSubscriptions');
    throw errors;
  }
};
