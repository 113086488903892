import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Ratings/FetchAllRatings?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData, params);
  } catch (err) {
    console.log('api-farms-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllRatings');
    throw errors;
  }
};

export const fetchQualityList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Ratings/FetchQualityRatings?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData, params);
  } catch (err) {
    console.log('api-farms-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchQualityRatings');
    throw errors;
  }
};

export const fetchRatingDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchRatingDetails(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Ratings/FetchRatingsOverview?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchRatingDetailsList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchRatingsOverview');
    throw errors;
  }
};
