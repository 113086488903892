import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const mySubscriptions = async (params) => {
  try {
    const qs = reqTransform.mySubscriptions(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchMySubscriptions?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.mySubscriptions(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchMySubscriptions');
    throw errors;
  }
};
