import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import './styles.scss';
function CommodityField({ commodity_residue_code, commodity_code, custom_styling_class }) {

  const renderPopover = (content) => (
    <Popover content={content} placement="topLeft">
      {content}
    </Popover>
  );

  return (
    <div className={`bfc-table-list-components ${custom_styling_class}`}>
      <div className="commodity-field ellipsis-text-commodity">
        {commodity_code && (
          <>
            <span className="commodity-code">
                {commodity_code ? renderPopover(commodity_code) : ' '}
            </span>
            <br />
          </>
        )}
        <div className="commodity-field ellipsis-text-commodity">
        {commodity_residue_code ? renderPopover(commodity_residue_code) : ' '}
        </div>
      </div>
    </div>
  );
}

CommodityField.propTypes = {
  commodity_residue_code: PropTypes.string.isRequired,
  commodity_code: PropTypes.string.isRequired,
};

export default CommodityField;
