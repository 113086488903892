import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from './../lib/api';
import notifications from 'lib/notifications';
import utils from 'lib/utils';

const initialState = {
  data: {},
  order: [],
  uomData: [],
  priceUomData: [],
  busy: false,
  isAllListLoaded: false,
  isMyListLoaded: false,
  pageno: 1,
  pagesize: 1000,
  updatedAt: null,
};

const getFilteredRequirements = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));

      const filterParams = {
        ...params,
      };

      filterParams['filters'] = {
        ...(params.filters || {}),
        req_offer_type_enum: '1',
      };

      filterParams['controller_enum'] = 2;

      let resData = await api.getFilteredData(filterParams);

      // resData = resData.map(item => {
      //       let tmpId = Math.floor(Math.random() * Math.floor(100000));
      //       if(!item['commodityreqofferid']){
      //         item['commodity_req_offer_id'] = tmpId
      //         item['commodityreqofferid'] = tmpId
      //       }

      //       return item
      //     })

      // dispatch(
      //   slice.actions.add({
      //     flush: true,
      //     isAllListLoaded: true,
      //     updatedAt: Date.now(),
      //     resData,
      //   }),
      // );
      dispatch(slice.actions.setData(resData));
    } catch (err) {
      console.log('getFilteredRequirements-->Err', err);
      const errorMessage = utils.getErrMessage(err);
      notifications.show({
        type: 'error',
        message: errorMessage || err.message,
      });
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const getRequirementDetails = (id = 0, selectedAddressId = 0) => {
  return async (dispatch) => {
    try {
      // debugger;
      dispatch(slice.actions.setBusy(true));
      let resData = await api.getRequirementDetails(id, selectedAddressId);
      dispatch(
        slice.actions.update({
          id: id,
          data: resData,
          detailsUpdatedAt: Date.now(),
        }),
      );
    } catch (err) {
      console.log('getRequirementDetails-->Err', err);
      notifications.show({
        type: 'error',
        message: 'Error processing request',
      });
    }
    dispatch(slice.actions.setBusy(false));
  };
};

const getAllRequirements = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let resData = await api.getAllRequirements(params);
      dispatch(
        slice.actions.add({
          isAllListLoaded: true,
          updatedAt: Date.now(),
          resData,
        }),
      );
    } catch (err) {
      console.log('getAllRequirements-->Err', err);
      notifications.show({
        type: 'error',
        message: 'Error processing request',
      });
    }
    dispatch(slice.actions.setBusy(false));
  };
};

const getUserRequirements = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let resData = await api.getUserRequirements(params);
      dispatch(
        slice.actions.add({
          isMyListLoaded: true,
          updatedAt: Date.now(),
          resData,
        }),
      );
    } catch (err) {
      console.log('getUserRequirements-->Err', err);
      notifications.show({
        type: 'error',
        message: 'Error processing request',
      });
    }
    dispatch(slice.actions.setBusy(false));
  };
};

const getFilteredIndentRequirementsOffer = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));

      const filterParams = {
        ...params,
      };

      filterParams['filters'] = {
        ...(params.filters || {}),
        req_offer_type_enum: '1',
      };

      filterParams['controller_enum'] = 4;

      let resData = await api.getFilteredData(filterParams);

      dispatch(
        slice.actions.add({
          flush: true,
          isAllListLoaded: true,
          updatedAt: Date.now(),
          resData,
        }),
      );
    } catch (err) {
      console.log('getFilteredIndentRequirementsOffer-->Err', err);
      const errorMessage = utils.getErrMessage(err);
      notifications.show({
        type: 'error',
        message: errorMessage || err.message,
      });
    }

    dispatch(slice.actions.setBusy(false));
  };
};

const fetchUOMAgainstCommodityTypeForm = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await api.fetchUOMAgainstCommodityTypeForm(params);
    if (params?.price_qty_enum === 1) {
      dispatch(slice.actions.setPriceUomData(resData));
    } else if (params?.price_qty_enum === 2) {
      dispatch(slice.actions.setUomData(resData));
    }
    // dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
    dispatch(slice.actions.setBusy(false));
    return err;
  }
};

const fetchUOMAgainstVehicleTypeEnum = (params) => async (dispatch) => {
  try {
    if (params?.isPrice) {
      params.price_qty_enum = 1;
    } else {
      params.price_qty_enum = 2;
    }
    const resData = await api.fetchUOMAgainstVehicleTypeEnum(params);
    if (params?.isPrice) {
      dispatch(slice.actions.setPriceUomData(resData));
    } else {
      dispatch(slice.actions.setUomData(resData));
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
    return err;
  }
};

const slice = createSlice({
  name: 'requirements',
  initialState,
  reducers: {
    setData: (state, { payload }) => ({ ...state, data: payload }),
    setUomData: (state, { payload }) => ({ ...state, uomData: payload }),
    setPriceUomData: (state, { payload }) => ({ ...state, priceUomData: payload }),
    setBusy(state, action) {
      state.busy = action.payload;
    },

    add(state, action) {
      const { flush, resData, isAllListLoaded, updatedAt, isMyListLoaded } = action.payload;

      let existingData = state.data || {};
      let existingOrder = state.order || [];

      let data = {
        ...existingData,
      };

      let order = existingOrder.concat([]);

      if (flush) {
        data = {};
        order = [];
      }

      const sortedData = _.orderBy(resData, 'publish_date', 'desc');

      for (let requirement of sortedData) {
        let id = requirement['commodity_req_offer_id'] || 0;

        if (id) {
          data[id] = {
            isListLoaded: true,
            ...(data[id] || {}),
            ...requirement,
          };

          //precalculating data for searching and sorting
          data[id]['from_date_timestamp'] = new Date(requirement['from_date']).getTime();
          data[id]['to_date_timestamp'] = new Date(requirement['to_date']).getTime();
          data[id]['closing_on_timestamp'] = new Date(requirement['closing_On']).getTime();

          let searchValues = _.pick(data[id], [
            'commodity_type_code',
            'commodity_code',
            'commodity_form_code',
            'commodity_residue_code',
            'payment_term_code',
            'coarse_location',
            'req_offer_status_enum_code',
            'qty',
            'periodicity_enum_code',
            'delivery_date_range',
            'min_qty',
            'closing_on',
            'expected_price',
            'req_offer_status_enum_code',
          ]);

          let searchStr = Object.values(searchValues).join('==');
          data[id]['searchStr'] = searchStr.toLowerCase();
          // searchStr = searchStr.replace(/\./gi, '');
          // searchStr = searchStr.replace(/\*/gi, '');
          // searchStr = searchStr.replace(/-/gi, '');
          // searchStr = searchStr.replace(/ /gi, '');
          // searchStr = searchStr.replace(/_/gi, '');

          if (!order.includes(id)) {
            order.push(id);
          }
        }
      }

      state['data'] = data;
      state['order'] = order;

      if (isAllListLoaded !== undefined) {
        state['isAllListLoaded'] = !!isAllListLoaded;
      }

      if (isMyListLoaded !== undefined) {
        state['isMyListLoaded'] = !!isMyListLoaded;
      }

      if (updatedAt !== undefined) {
        state['updatedAt'] = updatedAt;
      }

      // state.fetching = false;
    },

    update(state, action) {
      const { id, data, detailsUpdatedAt } = action.payload;
      state.data[id] = {
        // isDetailsLoaded : true,
        ...state.data[id],
        ...data,
        detailsUpdatedAt: detailsUpdatedAt,
      };
      // state['updatedAt'] = utils.uid();
    },
    delete(state, action) {
      // debugger;
      const post_id = action.payload;
      let order = _.get(state, 'order', []) || [];
      // const posts = state.data.filter(p => p.post_id !== post_id)
      delete state['data'][post_id];
      let filteredOrder = order.filter((id) => id !== post_id);
      state['order'] = filteredOrder;
    },
  },
});

const actions = {
  ...slice.actions,
  getAllRequirements,
  getRequirementDetails,
  getUserRequirements,
  getFilteredRequirements,
  getFilteredIndentRequirementsOffer,
  fetchUOMAgainstCommodityTypeForm,
  fetchUOMAgainstVehicleTypeEnum,
  // getAllFeed,
  // deletePost,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
