import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Vehicle
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/FetchAllVehiclesForTransporter`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllVehiclesForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllVehiclesForTransporter');
    throw errors;
  }
};

//Add Vehicle
export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveUpdateVehicleAgainstTransporter`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateVehicleAgainstTransporter');
    throw errors;
  }
};

//Update Vehicle
export const update = async (id, params) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveUpdateVehicleAgainstTransporter`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateVehicleAgainstTransporter');
    throw errors;
  }
};

//Delete Vehicle
export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/DeleteVehicleAgainstTransporter?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('DeleteVehicleAgainstTransporter->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteVehicleAgainstTransporter');
    throw errors;
  }
};

export const eKycDocumentVerification = async (params = {}) => {
  try {
    let bodyParams = reqTransform.eKycDocumentVerification(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/KYCVerification`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.eKycVerification(resData);
  } catch (err) {
    console.log('KYCVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'KYCVerification');
    throw errors;
  }
};

export const saveUpdateVehicleAgainstTransporter_New = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveUpdateVehicleAgainstTransporter_New`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateVehicleAgainstTransporter');
    throw errors;
  }
};

export const fetchAllVehiclesForTransporter_CT = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/FetchAllVehiclesForTransporter_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllVehiclesForTransporter_CT');
    throw errors;
  }
};

export const deleteVehicleAgainstTransporter_CT = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/DeleteVehicleAgainstTransporter_CT?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteVehicleAgainstTransporter_CT');
    throw errors;
  }
};
