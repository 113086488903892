import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUserSubscriptionDetails = async () => {
  try {
    let res = await api.fetchUserSubscriptionDetails();
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchKycUserSubscriptionDetails = async (params) => {
  try {
    let res = await api.fetchKycUserSubscriptionDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const create = async (params) => {
  try {
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTrialSubscription = async (params) => {
  try {
    const res = await api.createTrialSubscription(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createContact = async () => {
  try {
    const res = await api.createContact();
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateCoupon = async (params) => {
  try {
    let res = await api.updateCoupon(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchUserSubscriptionPhaseTwoDetails = async (params) => {
  try {
    let res = await api.fetchUserSubscriptionPhaseTwoDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
