import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import history from 'lib/history';
import * as yup from 'yup';
import { actions as transportSearchDriverActions } from 'redux/transportSearchDriver';

import { driverService } from 'services';
import { actions as transportDriverActions } from 'redux/transportDriver';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Driver Details...',
      key: 'req-form',
    });

    const res = await driverService.create(values);
    notifications.show({
      type: 'success',
      message: 'Driver Details saved.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportDriverActions.setDirty(true));
    redirect.web('/network/driver');

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Driver Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await driverService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Driver details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportDriverActions.setDirty(true));
    redirect.web('/network/driver');

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleKycUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Driver Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await driverService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Driver details updated.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await driverService.destroy(id);
    globalThis.store.dispatch(transportDriverActions.setDirty(true));
    redirect.web('/network/driver');
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
  return false;
}

export const handleSearch = async (values, formikBag, dispatch) => {
  try {
    notifications.show({
      type: 'info',
      message: `Searching for ${values.mobile_number}`,
      key: 'req-form',
    });
    const res = await dispatch(transportSearchDriverActions.fetchDriverByMobile(values));

    if (!_.isEmpty(res)) {
      notifications.show({
        type: 'success',
        message: 'Record Found',
        key: 'req-form',
      });
    } else {
      notifications.show({
        type: 'success',
        message: 'Record not Found',
        key: 'req-form',
      });
    }
    if (!_.isEmpty(res)) {
      let driverId = 0;
      _.map(res, (details, id) => {
        if (values.mobile_number == details.mobile_number) {
          driverId = details.id;
        }
      });

      history.push(getUrlWithEncryptedQueryParams(`/network/driver/edit/${driverId}`));
    } else {
      // let driverId = 0;
      // history.push(`/network/driver/create`);
      history.push({
        pathname: `/network/driver/create`,
        state: { mobile_number: values.mobile_number },
      });
    }

    return res;
  } catch (err) {
    console.log('handleSearch--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
export const handleLink = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Driver Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await driverService.link(id, values);
    notifications.show({
      type: 'success',
      message: 'Driver details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportDriverActions.setDirty(true));
    redirect.web('/network/driver');

    return res;
  } catch (err) {
    console.log('handleLink--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
export const handleEKycDocument = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Documents ...',
      key: 'req-form',
    });

    const res = await driverService.eKycDocumentVerification(values);
    notifications.show({
      type: 'success',
      message: _.get(res, 'kyc_message') || res,
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleEKyc--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
