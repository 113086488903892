import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/extendedProfile/location-access-icon.svg';

function LocationAccessIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default LocationAccessIcon;
