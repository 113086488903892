import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';

export const fetchPickupActivityDetails = async (params) => {
  try {
    let res = await api.fetchPickupActivityDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdatePickupActivity = async (params) => {
  try {
    let res = await api.saveAndUpdatePickupActivity(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdatePickupActivityForDashboard = async (params) => {
  try {
    let res = await api.saveAndUpdatePickupActivityForDashboard(params);
    return res;
  } catch (err) {
    throw err;
  }
};
