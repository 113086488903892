import _ from 'lodash';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { deliveryService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (farm) => _.get(farm, 'farm_address.person_address_id', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) =>
  //   (a.transport_delivery_schedule_alloted_id || '').localeCompare(b.transport_delivery_schedule_alloted_id || ''),
});

const slice = createSlice({
  name: 'deliveries',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    dirty: true,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setDirty(state, action) {
      let dirty = action.payload;
      if (dirty === false) {
        state.dirty = false;
      } else {
        state.dirty = true;
      }
    },
  },
});

const fetchList =
  (params = {}) =>
  async (dispatch) => {
    try {
      const resData = await deliveryService.fetchList(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setDirty(false));
  };

const fetchDetails =
  (params = {}) =>
  async (dispatch) => {
    try {
      const resData = await deliveryService.fetchDetails(params);
      dispatch(slice.actions.upsertOne(resData));
    } catch (err) {
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setDirty(false));
  };

const enableDisableEarlyPaymentOnDelivery =
  (params = {}) =>
  async (dispatch) => {
    try {
      await deliveryService.enableDisableEarlyPaymentOnDelivery(params);
      if (params?.is_early_payment_delivery) {
        notifications.show({
          type: 'success',
          message: 'Early payment has been successfully enabled for this delivery',
        });
      } else {
        notifications.show({
          type: 'warning',
          message: 'Early payment has been successfully disabled for this delivery',
        });
      }
      return true;
    } catch (err) {
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setDirty(false));
  };

const actions = {
  ...slice.actions,
  fetchList,
  fetchDetails,
  enableDisableEarlyPaymentOnDelivery,
};

// // Rename the exports for readability in component usage
// export const {
//   selectById: selectUserById,
//   selectIds: selectUserIds,
//   selectEntities: selectUserEntities,
//   selectAll: selectAllUsers,
//   selectTotal: selectTotalUsers
// } = usersAdapter.getSelectors(state => state.users)

const selectors = {
  ...adapter.getSelectors((state) => state.deliveries),
};

export { actions, selectors };

export default slice.reducer;
