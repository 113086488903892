import { createSlice } from '@reduxjs/toolkit';
import utils from 'lib/utils';

import { dataSetupService } from 'services';

const slice = createSlice({
  name: 'dataSetup',
  initialState: {
    busy: false,
    commodityTypes: [],
    commodityForms: [],
    commodityResidue: [],
    commodities: [],
    platformChargePerSubscription: [],
    platformChargePerSubscriptionDetails: [],
    companyRelatedPlatformCharge: [],
    companyRelatedPlatformChargeDetails: [],
    bidOfferCsvData: [],
    reportGenerationData: [],
    downloadedReport: [],
    hasMore: true,
  },
  reducers: {
    getCommodityTypes: (state, { payload }) => ({ ...state, commodityTypes: payload }),
    getCommodityForms: (state, { payload }) => ({ ...state, commodityForms: payload }),
    getCommodityResidue: (state, { payload }) => ({ ...state, commodityResidue: payload }),
    getCommodities: (state, { payload }) => ({ ...state, commodities: payload }),
    getPlatformChargePerSubscription: (state, { payload }) => ({ ...state, platformChargePerSubscription: payload }),
    getPlatformChargePerSubscriptionDetails: (state, { payload }) => ({
      ...state,
      platformChargePerSubscriptionDetails: payload,
    }),
    getCompanyRelatedPlatformCharge: (state, { payload }) => ({ ...state, companyRelatedPlatformCharge: payload }),
    getCompanyRelatedPlatformChargeDetails: (state, { payload }) => ({
      ...state,
      companyRelatedPlatformChargeDetails: payload,
    }),
    getExtractedInvoices: (state, { payload }) => ({ ...state, extractedInvoices: payload }),
    setBidOfferCsvData: (state, { payload }) => ({ ...state, bidOfferCsvData: payload }),
    setReportGenerationData: (state, { payload }) => ({ ...state, reportGenerationData: payload }),
    setDownloadedReport: (state, { payload }) => ({ ...state, downloadedReport: payload }),
    setHasMore: (state, { payload }) => ({ ...state, hasMore: payload }),
    setBusy: (state, { payload }) => {
      let busy = payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

// Fetch Or Fetch Commodities start
const getAllCommodityTypes = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    if (params?.isAddUpdate) {
      //Update CommodityType
      await dataSetupService.addUpdateCommodities(params, 'SaveUpdateCommodityType');
    }
    if (params?.isDelete) {
      //Delete CommodityType
      await dataSetupService.deleteCommodities(params, 'DeleteCommodityType');
    }
    const fetchParams = {
      filters: params.filters ? params.filters : {},
      page_size: params.page_size,
      offset: params.offset,
    };
    const resData = await dataSetupService.getAllCommodityDetails(fetchParams, 'FetchAllCommodityType');
    dispatch(slice.actions.getCommodityTypes(resData));
  } catch (err) {
    // dispatch(slice.actions.getCommodityTypes({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getAllCommodityForms = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    if (params?.isAddUpdate) {
      //Update CommodityForm
      await dataSetupService.addUpdateCommodities(params, 'SaveUpdateCommodityForm');
    }
    if (params?.isDelete) {
      //Delete CommodityForm
      await dataSetupService.deleteCommodities(params, 'DeleteCommodityForm');
    }
    const fetchParams = {
      filters: params.filters ? params.filters : {},
      page_size: params.page_size,
      offset: params.offset,
    };
    const resData = await dataSetupService.getAllCommodityDetails(fetchParams, 'FetchAllCommodityForm');
    dispatch(slice.actions.getCommodityForms(resData));
  } catch (err) {
    // dispatch(slice.actions.getCommodityForms({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getAllCommodityResidue = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    if (params?.isAddUpdate) {
      //Update CommodityResidue
      await dataSetupService.addUpdateCommodities(params, 'SaveUpdateCommodityResidue');
    }
    if (params?.isDelete) {
      //Delete CommodityResidue
      await dataSetupService.deleteCommodities(params, 'DeleteCommodityResidue');
    }
    const fetchParams = {
      filters: params.filters ? params.filters : {},
      page_size: params.page_size,
      offset: params.offset,
    };
    const resData = await dataSetupService.getAllCommodityDetails(fetchParams, 'FetchAllCommodityResidue');
    dispatch(slice.actions.getCommodityResidue(resData));
  } catch (err) {
    // dispatch(slice.actions.getCommodityResidue({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getAllCommodities = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    if (params?.isAddUpdate) {
      //Update Commodities
      await dataSetupService.addUpdateCommodities(params, 'SaveUpdateCommodity');
    }
    if (params?.isDelete) {
      //Delete Commodity
      await dataSetupService.deleteCommodities(params, 'DeleteCommodity');
    }
    const fetchParams = {
      filters: params.filters ? params.filters : {},
      page_size: params.page_size,
      offset: params.offset,
    };
    const resData = await dataSetupService.getAllCommodityDetails(fetchParams, 'FetchAllCommodity');
    dispatch(slice.actions.getCommodities(resData));
  } catch (err) {
    // dispatch(slice.actions.getCommodities({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteMaterialAndMultilingual = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const fetchParams = {
      filters: params.filters ? params.filters : {},
      page_size: params.page_size,
      offset: params.offset,
    };
    if (params.isMultilingual) {
      await dataSetupService.addUpdateCommodities(params.itemsToDelete, 'DeleteCommodityMultilingual');
    } else {
      await dataSetupService.addUpdateCommodities(params.itemsToDelete, 'DeleteMaterial');
    }
    const resData = await dataSetupService.getAllCommodityDetails(fetchParams, 'FetchAllCommodity');
    dispatch(slice.actions.getCommodities(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
// Fetch Or Fetch Commodities  End

//Fetch all platform charge per subscription
const getPlatformChargePerSubscription = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const fetchParams = {
      filters: params.ct_Platform_Master_Id
        ? { ct_Platform_Master_Id: `${params.ct_Platform_Master_Id}` }
        : params.platform_Master_Id
        ? { platform_Master_Id: `${params.platform_Master_Id}` }
        : params.filters
        ? params.filters
        : {},
      page_size: params.page_size ? params.page_size : 10,
      offset: params.offset ? params.offset : 0,
    };
    let resData;
    if (params.isDetails) {
      resData = await dataSetupService.getPlatformCharges(fetchParams, 'FetchAllCT_PlatformChargeDetail');
      dispatch(slice.actions.getPlatformChargePerSubscriptionDetails(resData));
    } else if (params.isUpdate) {
      await dataSetupService.getPlatformCharges(params, 'SaveAndUpdatePlatformCharge_CT');
    } else if (params.isDelete) {
      await dataSetupService.getPlatformCharges(params.recordsToDelete, 'DeletePlatformMaster_CT');
    }
    if (params.isDetailsSaveUpdate) {
      resData = await dataSetupService.getPlatformCharges(
        { ...fetchParams, offset: 0 },
        'FetchAllCT_PlatformChargeDetail',
      );
      dispatch(slice.actions.getPlatformChargePerSubscriptionDetails(resData));
    } else if (!params.isDetails) {
      resData = await dataSetupService.getPlatformCharges(fetchParams, 'FetchAllCT_PlatformChargeMaster');
      dispatch(slice.actions.getPlatformChargePerSubscription(resData));
    }
    if (params.isFetch) {
      const newParams = {
        filters: {},
        page_size: params.page_size,
        offset: params.offset,
      };
      resData = await dataSetupService.getPlatformCharges(newParams, 'FetchAllCT_PlatformChargeMaster');
      dispatch(slice.actions.getPlatformChargePerSubscription(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

//Fetch all company type platform charge
const getCompanyRelatedPlatformCharge = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const fetchParams = {
      filters: params.ct_Platform_Master_Id
        ? { ct_Platform_Master_Id: `${params.ct_Platform_Master_Id}` }
        : params.platform_Master_Id
        ? { platform_Master_Id: `${params.platform_Master_Id}` }
        : params.filters
        ? params.filters
        : {},
      page_size: params.page_size ? params.page_size : 10,
      offset: params.offset ? params.offset : 0,
    };
    let resData;
    if (params.isDetails) {
      resData = await dataSetupService.getPlatformCharges(fetchParams, 'FetchAllPlatformDetail');
      dispatch(slice.actions.getCompanyRelatedPlatformChargeDetails(resData));
    } else if (params.isUpdate) {
      await dataSetupService.getPlatformCharges(params, 'SaveAndUpdatePlatformCharge');
    } else if (params.isDelete) {
      await dataSetupService.getPlatformCharges(params.recordsToDelete, 'DeletePlatformMaster');
    }
    if (params.isDetailsSaveUpdate) {
      resData = await dataSetupService.getPlatformCharges({ ...fetchParams, offset: 0 }, 'FetchAllPlatformDetail');
      dispatch(slice.actions.getCompanyRelatedPlatformChargeDetails(resData));
    } else if (!params.isDetails) {
      resData = await dataSetupService.getPlatformCharges(fetchParams, 'FetchAllPlatformMaster');
      dispatch(slice.actions.getCompanyRelatedPlatformCharge(resData));
    }
    if (params.isFetch) {
      const newParams = {
        filters: {},
        page_size: params.page_size,
        offset: params.offset,
      };
      resData = await dataSetupService.getPlatformCharges(newParams, 'FetchAllPlatformMaster');
      dispatch(slice.actions.getCompanyRelatedPlatformCharge(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchDataExtractInvoices = (params) => async (dispatch) => {
  try {
    const resData = await dataSetupService.getAllCommodityDetails(params, 'FetchDataForExtract');
    dispatch(slice.actions.getExtractedInvoices(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const getBidOfferCsvData = (params) => async (dispatch) => {
  const newParams = {
    ...params,
    page_size: 1000000,
  };
  try {
    const resData = await dataSetupService.getAllCommodityDetails(newParams, 'FetchDataForExtract');
    dispatch(slice.actions.setBidOfferCsvData(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const fetchGenerateReport = () => async (dispatch) => {
  try {
    const resData = await dataSetupService.fetchGenerateReport();
    dispatch(slice.actions.setReportGenerationData(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const fetchDownloadedReport = (params) => async (dispatch) => {
  try {
    const storeState = globalThis.store.getState();
    const reportData = _.get(storeState, 'dataSetup.downloadedReport') || {};
    const resData = await dataSetupService.fetchDownloadedReport(params);
    if (params?.isScrolling) {
      dispatch(slice.actions.setDownloadedReport([...reportData, ...resData]));
    } else {
      dispatch(slice.actions.setDownloadedReport(resData));
    }
    if (!resData.length) {
      dispatch(slice.actions.setHasMore(false));
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const generateReportDataExtract = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await dataSetupService.generateReportDataExtract(params);
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

export const actions = {
  ...slice.actions,
  getAllCommodityTypes,
  getAllCommodityForms,
  getAllCommodityResidue,
  getAllCommodities,
  getPlatformChargePerSubscription,
  getCompanyRelatedPlatformCharge,
  deleteMaterialAndMultilingual,
  fetchDataExtractInvoices,
  getBidOfferCsvData,
  fetchGenerateReport,
  fetchDownloadedReport,
  generateReportDataExtract,
};

export default slice.reducer;
