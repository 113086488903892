import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import './styles.scss';

function DeliveryDateField({ form_date, to_date }) {
  return (
    <div className="bfc-table-list-components d-inline-block">
      <div className="delivery-date-field">
        <div className="label">
          <span>From </span>
          <span>To </span>
        </div>
        <div className="value">
          <span>{!dayjs(form_date).isValid() ? 'N/A' : dayjs(form_date).format('D-MMM-YY')}</span>
          <span>{!dayjs(to_date).isValid() ? 'N/A' : dayjs(to_date).format('D-MMM-YY')}</span>
        </div>
      </div>
    </div>
  );
}

DeliveryDateField.propTypes = {
  form_date: PropTypes.string.isRequired,
  to_date: PropTypes.string.isRequired,
};

export default DeliveryDateField;
