import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/FetchFinancePartners`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchFinancePartners-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinancePartners');
    throw errors;
  }
};

export const fetchDetails = async (params) => {
  try {
    const qsParams = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/FetchFinancePartnerDetails?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFinancePartnerDetails(resData);
  } catch (err) {
    console.log('api-FetchFinancePartnerDetails-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinancePartnerDetails');
    throw errors;
  }
};

export const withdrawInvoiceFinanceRequest = async (params) => {
  try {
    const qsParams = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/WithdrawInvoiceFinanceReuest?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFinancePartnerDetails(resData);
  } catch (err) {
    console.log('api-FetchFinancePartnerDetails-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinancePartnerDetails');
    throw errors;
  }
};

export const fetchChat = async (params) => {
  try {
    const qsParams = reqTransform.fetchChat(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/FetchFinancePartnerChat?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchChat(resData);
  } catch (err) {
    console.log('api-FetchFinancePartnerChat-fetchChat->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinancePartnerChat');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/ApplyForFinance`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-ApplyForFinance-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ApplyForFinance');
    throw errors;
  }
};

export const getFinanceDeals_Applicant = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/GetFinanceDeals_Applicant`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ id: item?.commodity_deal_master_id, ...item }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetFinanceDeals_Applicant');
    throw errors;
  }
};

export const getFinanceDeals_NBFC = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/GetFinanceDeals_NBFC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item, index) => ({ id: index, ...item }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetFinanceDeals_NBFC');
    throw errors;
  }
};

export const getFinancedDealDetails = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/GetFinancedDealDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetFinancedDealDetails');
    throw errors;
  }
};

export const upload = async (params) => {
  try {
    const formData = new FormData();
    formData.append('entity_Id', params.entity_id);
    formData.append('entity_Type_Enum', params.entity_type_enum);
    formData.append('document_Module_Enum', params.document_module_enum);
    formData.append('company_Affiliate_Enum', params.company_affiliate_enum);
    formData.append('user_Type_Enum', params.user_type_enum);
    formData.append('attachment_File', params.file);
    formData.append('attachment_Id', params.attachment_id);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/AttachDocument_Azure`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AttachDocument_Azure');
    throw errors;
  }
};

export const fetchUploadList = async (params) => {
  try {
    const qs = reqTransform.fetchUploadList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    const attachmentsRes = _.get(resData, 'attachments') || [];
    return resTransform.fetchUploadList(attachmentsRes);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetAttachmentAgainstEntityId');
    throw errors;
  }
};
export const sendMessageFromBody = async (params) => {
  try {
    const qs = reqTransform.sendMessage(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/BankCommon/SendBankChatMessage_FromBody`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    // const resData = await res.json();
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-SendBankChatMessage-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SendBankChatMessage');
    throw errors;
  }
};

export const sendMessage = async (params) => {
  try {
    const qs = reqTransform.sendMessage(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/BankCommon/SendBankChatMessage?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    // const resData = await res.json();
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-SendBankChatMessage-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SendBankChatMessage');
    throw errors;
  }
};

export const fetchMessages = async (params) => {
  try {
    const qs = reqTransform.fetchMessages(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/BankCommon/FetchBankChatMessages?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchMessages(resData);
  } catch (err) {
    console.log('api-SendBankChatMessage-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBankChatMessages');
    throw errors;
  }
};

export const createBank = async (params) => {
  try {
    const bodyParams = reqTransform.createBank(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/UpdateFinanceCompanyDetails`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createBank(resData);
  } catch (err) {
    console.log('api-UpdateFinanceCompanyDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateFinanceCompanyDetails');
    throw errors;
  }
};

export const fetchBankDetails = async (params) => {
  try {
    const qsParams = reqTransform.fetchBankDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchFinanceCompanyDetails?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchBankDetails(resData);
  } catch (err) {
    console.log('api-FetchFinanceCompanyDetails-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinanceCompanyDetails');
    throw errors;
  }
};

export const createResponse = async (params) => {
  try {
    const bodyParams = reqTransform.createResponse(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/RespondApplication`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createResponse(resData);
  } catch (err) {
    console.log('api-RespondApplication-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RespondApplication');
    throw errors;
  }
};

export const createInvoiceRespond = async (params) => {
  try {
    const bodyParams = reqTransform.createInvoiceRespond(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/RespondFinanceInvoiceApplication`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createInvoiceRespond(resData);
  } catch (err) {
    console.log('api-RespondApplication-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RespondFinanceInvoiceApplication');
    throw errors;
  }
};
export const fetchCreditLimitList = async (params) => {
  try {
    const qs = reqTransform.fetchCreditLimitList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchCreditLimitAgainstInvokingPersonId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCreditLimitList(resData);
  } catch (err) {
    console.log('api-FetchCreditLimitAgainstInvokingPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCreditLimitAgainstInvokingPersonId');
    throw errors;
  }
};

export const fetchDataForCreditLimitUpdate = async (params) => {
  try {
    const qs = reqTransform.fetchCreditLimitList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchCreditLimitAgainstId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCreditLimitList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCreditLimitAgainstId');
    throw errors;
  }
};

export const fetchCreditLimitCompanyList = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchAllAffiliatesAndCompanies`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCreditLimitCompanyList(resData);
  } catch (err) {
    console.log('api-FetchAllAffiliatesAndCompanies->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllAffiliatesAndCompanies');
    throw errors;
  }
};

export const createCreditLimit = async (params) => {
  try {
    const bodyParams = reqTransform.createCreditLimit(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/SaveAndUpdateCreditLimit`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createCreditLimit(resData);
  } catch (err) {
    console.log('api-SaveAndUpdateCreditLimit-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateCreditLimit');
    throw errors;
  }
};

export const fetchDocument = async (params) => {
  try {
    const qsParams = reqTransform.fetchDocument(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/DownloadTradeFinanceProfile?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDocument(resData);
  } catch (err) {
    console.log('api-DownloadTradeFinanceProfile-fetchDocument->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DownloadTradeFinanceProfile');
    throw errors;
  }
};

export const fetchJobCardDocument = async (params) => {
  try {
    const qsParams = reqTransform.fetchJobCardDocument(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/DownloadBankTransferInstructionDocument?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchJobCardDocument(resData);
  } catch (err) {
    console.log('api-DownloadBankTransferInstructionDocument-fetchDocument->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DownloadBankTransferInstructionDocument');
    throw errors;
  }
};

export const updatePaymentDetails = async (params) => {
  try {
    const bodyParams = reqTransform.updatePaymentDetails(params);
    const url = `${baseUrl}/BioFuelPayments/api/TradeFinance/RecordPaymentByNBFCAgainstFinanceInvoiceApplicationId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updatePaymentDetails(resData);
  } catch (err) {
    console.log('api-RecordPaymentByNBFCAgainstFinanceInvoiceApplicationId-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RecordPaymentByNBFCAgainstFinanceInvoiceApplicationId');
    throw errors;
  }
};

export const updateFinancePaymentDetails = async (params) => {
  try {
    const bodyParams = reqTransform.updatePaymentDetails(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/RecordPaymentByBankIntoVirtualAccount`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updatePaymentDetails(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RecordPaymentByBankIntoVirtualAccount');
    throw errors;
  }
};

export const disburseAmountAgainstFinancedDealDetailId = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/TradeFinance/DisburseAmountAgainstFinancedDealDetailId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return true;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DisburseAmountAgainstFinancedDealDetailId');
    throw errors;
  }
};

export const fetchSupplierRatings = async (params) => {
  try {
    const qs = reqTransform.fetchSupplierRatings(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ExtendedProfile/FetchExtendedProfileRating?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSupplierRatings(resData, params);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchExtendedProfileRating');
    throw errors;
  }
};
