import { getIn } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import utils from 'lib/utils';
// import { Icon, Cascader } from 'rsuite';
import { TextField } from '@material-ui/core';

// import default style
//import 'rsuite/lib/styles/index.less'; // or 'rsuite/dist/styles/rsuite-default.css'
// import 'rsuite/dist/styles/rsuite-default.css';
import { referenceDataService } from 'services';
import CloseIcon from 'mdi-react/CloseThickIcon';
import BackIcon from 'mdi-react/ArrowLeftThickIcon';
import LogoLoader from 'common/LogoLoader';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ProductSales from 'pages/dashboard/components/ProductSales';

function getLocationValus(loc, isLeafNode) {
  let locationId = _.get(loc, 'village.id') || 0;
  let locationCode = _.get(loc, 'village.location_code') || '';

  if (isLeafNode) {
    locationId = _.get(loc, 'village.id') || _.get(loc, 'country.id') || _.get(loc, 'state.id') || 0;
    locationCode =
      _.get(loc, 'village.location_code') ||
      _.get(loc, 'country.location_code') ||
      _.get(loc, 'state.location_code') ||
      '';
  }
  const locValues = {
    location_id: locationId,
    location_code: locationCode,
    address_text: '',
    lattitude: '',
    longitude: '',
    village_id: _.get(loc, 'village.id') || 0,
    village_code: _.get(loc, 'village.location_code') || '',
    taluka_id: _.get(loc, 'taluka.id') || 0,
    taluka_code: _.get(loc, 'taluka.location_code') || '',
    city_id: _.get(loc, 'city.id') || null,
    city_code: _.get(loc, 'city.location_code') || null,
    district_id: _.get(loc, 'district.id') || 0,
    district_code: _.get(loc, 'district.location_code') || '',
    state_id: _.get(loc, 'state.id') || 0,
    state_code: _.get(loc, 'state.location_code') || '',
    state_id: _.get(loc, 'state.id') || 0,
    state_code: _.get(loc, 'state.location_code') || '',
    country_id: _.get(loc, 'country.id') || 0,
    country_code: _.get(loc, 'country.location_code') || '',
    // pin_code: '',
  };
  let locStr = locValues['country_code'];
  if (locStr && locValues['state_code']) {
    locStr = `${locValues['state_code']}, ${locStr}`;
  }
  if (locStr && locValues['district_code']) {
    locStr = `${locValues['district_code']}, ${locStr}`;
  }
  if (locStr && locValues['taluka_code']) {
    locStr = `${locValues['taluka_code']}, ${locStr}`;
  }

  if (locStr && locValues['village_code']) {
    locStr = `${locValues['village_code']}, ${locStr}`;
  }

  locValues['location_code'] = locStr;

  locValues['address_text'] = locValues['location_code'];

  return locValues;
}

const FormikLocationSelect = (props) => {
  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    initialDisplayText,
    placeholder,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';
  // const entities = ['state', 'district', 'taluka', 'village'];
  // const entities = ['district', 'taluka', 'village'];
  const entities = ['country', 'state', 'district', 'taluka', 'village'];

  // let loc = {};
  const wrapperRef = useRef(null);

  const [data, setData] = useState([]);
  const [loc, setLoc] = useState({});
  const [currIndex, setCurrIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(initialDisplayText);

//   useEffect(() => {
//     if (open) {
//       document.addEventListener('click', handleClickOutside, false);
//     } else {
//       document.removeEventListener('click', handleClickOutside, false);
//     }

//     return () => {
//       document.removeEventListener('click', handleClickOutside, false);
//     };
//   }, [open]);

  const updateLocations = (locId = 46096) => {
    if (open && !loading) {
      setLoading(true);
      locId = locId || 46096;
      if (currIndex) {
        const locType = entities[currIndex - 1];
        locId = _.get(loc, `${locType}[id]`) || 0;
      }

      referenceDataService
        .fetchLocations(locId)
        .then((resData) => {
          let locData = Object.values(resData);
          let leafNodes = [];
          let nonLeafNodes = [];
          _.map(locData, (item, id) => {
            if (!item.is_leaf_node) {
              nonLeafNodes.push(item);
            } else {
              leafNodes.push(item);
            }
          });
          leafNodes = _.orderBy(leafNodes, 'location_code', 'asc');
          nonLeafNodes = _.orderBy(nonLeafNodes, 'location_code', 'asc');

          let data = nonLeafNodes.concat(leafNodes);
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          utils.displayErrors(err);
          setLoading(false);
        });
    }
  };

  const handleClear = () => {
    setCurrIndex(0);
    setLoc({});
    setOpen(false);
    setValue('');
    props.cb(getLocationValus({}));
  };

  useEffect(() => {
    // debugger;
    if (open) {
      updateLocations();
    } else {
      setCurrIndex(0);
      setLoc({});
    }
  }, [currIndex, open]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const onItemSelect = (item) => {
    // let locType = (item['location_type_enum_code'] || '').toLowerCase();
    let locationCode = _.get(item, 'location_code', '');
    let location_id = _.get(item, 'location_id', '');
    if (location_id !== 1) {
      props.form.setFieldValue('location_code', locationCode);
    } else {
      props.form.setFieldValue('location_code', '');
    }

    // let updateLoc = {
    //   ...loc,
    //   [locType]: item,
    // };

    // const locValues = getLocationValus(updateLoc, item.is_leaf_node);

    // if (!item.is_leaf_node) {
    //   setLoc(updateLoc);
    //   setCurrIndex(currIndex + 1);
    // } else {
    //   props.cb(locValues);
    //   setOpen(false);
    // }
    // setValue(locValues['location_code'] || '');
    props.form.setFieldValue('country_code', locationCode);
    props.form.setFieldValue('country_id', location_id);

    setOpen(false);
  };


  const renderOptions = () => {
    const html = (
      <div className="dropdown-body">
        <div className="search d-none">
          <input type="text" placeholder="Search" />
          <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </div>
        {loading && (
          <div className="location-loader">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )}
        <React.Fragment>
          {/*
            <div
              className={`back-btn ${currIndex ? 'invisible' : ''}`}
              onClick={(e) => {
                setCurrIndex(currIndex - 1);
                return false;
              }}>
              <BackIcon color="#1B62AB" className="icon" size={24} />
              Back
            </div>
            */}
          <ul>
            {data.map((item, index) => {
              return (
                <li key={index} onClick={(e) => onItemSelect(item)} className="option-item">
                  {item.location_name}
                  {/* {!item.is_leaf_node && (
                    <span className="icon">
                      <ChevronRightIcon size={24} />
                    </span>
                  )} */}
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      </div>
    );

    return html;
  };

  // let locationStr = '';
  // if (!_.isEmpty(loc)) {
  //   locationStr = _.get(getLocationValus(loc), 'location_code') || '';
  // }

  return (
    <div className={`formik-location-drilldown ${hasError ? 'has-error' : ''}`} ref={wrapperRef}>
      <TextField
        {...field}
        fullWidth
        variant={variant || 'outlined'}
        size="small"
        disabled={disabled}
        error={hasError}
        helperText={errText}
        placeholder={placeholder || 'Select Location'}
        readOnly
        value={props.field.value || ''}
        onFocus={() => setOpen(true)}
        // onBlur={() => {
        //   alert('blured');
        //   setOpen(false);
        // }}
        // onWheel={(event) => {
        // event.preventDefault();
        //   event.target.blur();
        // }}
      />
      {value && !disabled && (
        <span onClick={handleClear} className="icon-box">
          <CloseIcon color="#1B62AB" size={24} />
        </span>
      )}

      {/*
        <div onBlur={() => setOpen(false)} onClick={() => setOpen(true)} className="dropdown-header">
          <span>Select</span>
          <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z"></path>
          </svg>
          <div className="filter-item">
            <span>Home</span>
            <span>About</span>
            <span>Contact</span>
            <a href="" className="close">
              <WebIcon />
            </a>
          </div>
        </div>
        */}
      <div className="cust-dropdown">{open && renderOptions()}</div>
    </div>
  );
};

export default FormikLocationSelect;

//usage
/*
<Field
  placeholder={initialValues.location_code || 'Select Location'}
  name="location_id"
  cb={(locationValues) => {
    let formikValues = formikBag.values || {};
    formikBag.setValues({
      ...formikValues,
      ...locationValues,
    });
  }}
  component={FormikLocationSelect}
  disabled={isAdmin}
/>;
*/
