import React from 'react';
import { Link } from 'react-router-dom';

/*
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarMail from './TopbarMail';
import TopbarLanguage from './TopbarLanguage';
import TopbarNotification from './TopbarNotification';
import TopbarNav from './tobar_nav/TopbarNav';
import TopbarSearch from './TopbarSearch';
import TopbarNavMenu from './TopbarNavMenu';
*/

import TopbarSidebarButton from './TopbarSidebarButton';
import SocialFollow from './SocialFollow';

const Footer = ({ changeMobileSidebarVisibility }) => (
  <div className="d-none topbar topbar--navigation bfc-footer">
    <div className="container-lg">
      <div className="row">
        <div className="col">
          <div className="bfc-footer-content">
            <p>
              © 2020 BiofuelCircle.
              <br /> All rights reserved. <br />
              <Link target="_blank" className="bfc-underline" to="/privacy-policy">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
        <div className="col">
          <div className="bfc-footer-content">
            <p>
              Address <br /> D1 Kubera Gulshan, DP Road,
              <br /> Aundh, Pune - 411007. India.
            </p>
          </div>
        </div>
        <div className="col">
          <div className="bfc-footer-content">
            <p>
              Email <br /> info@biofuelcircle.com
            </p>
          </div>
        </div>
        <div className="col">
          <div className="bfc-footer-content">
            <SocialFollow />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
