import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const resTransform = {
  fetchEarlyPaymentRecords(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details, index) => {
      return resTransform.fetchEarlyPaymentDetails(details, index);
    });
    return _.keyBy(generateReportData, 'id');
  },
  fetchEarlyPaymentDetails(resData, index) {
    let data = {
      total_count: _.get(resData, 'total_count') || 0,
      commodity_deal_master_id: _.get(resData, 'commodity_deal_master_id') || 0,
      transport_delivery_schedule_alloted_id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
      delivery_status_enum_code: _.get(resData, 'delivery_status_enum_code') || '',
      // delivery_status_enum: _.get(resData, 'delivery_status_enum') || 0,
      buyer_company_code: _.get(resData, 'buyer_company_code') || '',
      seller_company_code: _.get(resData, 'seller_company_code') || '',
      custom_invoice_number: _.get(resData, 'custom_invoice_number') || '',
      invoice_status_enum: _.get(resData, 'invoice_status_enum') || 0,
      invoice_status_enum_code: _.get(resData, 'invoice_status_enum_code') || '',
      deal_price: _.get(resData, 'deal_price') || 0,
      early_payment_discount: _.get(resData, 'early_payment_discount') || 0,
      cost_of_borrowing: _.get(resData, 'cost_of_borrowing') || 0,
      margin: _.get(resData, 'margin') || 0,
      adjustment_factor: _.get(resData, 'adjustment_factor') || 0,
      invoice_master_id: _.get(resData, 'invoice_master_id') || 0,
      pickup_date: _.get(resData, 'pickup_date') || '',
      cost_of_borrowing_rate: _.get(resData, 'cost_of_borrowing_rate') || 0,
      margin_rate: _.get(resData, 'margin_rate') || 0,
      adjustment_rate: _.get(resData, 'adjustment_rate') || 0,
      invoice_quantity: _.get(resData, 'invoice_quantity') || 0,
      due_date: _.get(resData, 'due_date') || '',
      invoice_amount: _.get(resData, 'invoice_amount') || 0,
      to_deal_ids: _.get(resData, 'to_deal_ids') || 0,
      to_delivery_ids: _.get(resData, 'to_delivery_ids') || 0,
      // delivery_allotment_status_enum: _.get(resData, 'delivery_allotment_status_enum') || 0,
      // qty_uom_id: _.get(resData, 'qty_uom_id') || 0,
      qty_uom_code: _.get(resData, 'qty_uom_code') || '',
      // price_uom_id: _.get(resData, 'price_uom_id') || 0,
      price_uom_code: _.get(resData, 'price_uom_code') || '',
      id: index, //_.get(index, 'index'),
      linked_custominvoicenumber: _.get(resData, 'linked_custominvoicenumber'),
      linked_invoicestatusenum: _.get(resData, 'linked_invoicestatusenum'),
    };
    return data;
  },
};
