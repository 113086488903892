import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import * as helpers from 'services/helpers';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/CreateSocialPost`;

    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: bodyParams,
      },
      false,
    ).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-farms-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateSocialPost');
    throw errors;
  }
};

export const sharePost = async (params) => {
  try {
    let bodyParams = reqTransform.sharePost(params);

    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/SharePostOnTimeline`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.sharePost(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SharePostOnTimeline');
    console.log('SharePostOnTimeline-->', err);
    throw errors;
  }
};

export const update = async (params) => {
  try {
    let bodyParams = reqTransform.update(params);
    const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/EditPost`;

    const res = await utils.fetch(
      url,
      {
        method: 'PUT',
        body: bodyParams,
      },
      false,
    ).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('api-farms-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EditPost');
    throw errors;
  }
};

export const destroy = async (post_id) => {
  try {
    const bodyParams = reqTransform.destroy(post_id);
    const qs = utils.getQs(bodyParams);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeletePostFromTimeline?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeletePostFromTimeline');

    throw errors;
  }
};

export const savePost = async (post_id) => {
  try {
    let bodyParams = reqTransform.savePost(post_id);
    const url = `${baseUrl}/BioFuelCreatePost/api/CreatePost/SavePost`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.savePost(resData);
  } catch (err) {
    console.log('api-farms-savePost->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SavePost');
    throw errors;
  }
};

export const fetchReportedPosts = async (params = {}) => {
  try {
    const qs = reqTransform.fetchReportedPosts(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/GetReportedPost_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchReportedPosts(resData);
  } catch (err) {
    console.log('api-posts-fetchReportedPosts->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetReportedPost_CT');
    throw errors;
  }
};

export const deleteReportedPost = async (params) => {
  try {
    let bodyParams = reqTransform.deleteReportedPost(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/DeletePostOrCommentOrImage_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteReportedPost(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeletePostOrCommentOrImage_CT');
    console.log('api-farms-deleteReportedPost->err---->', err);
    console.log('api-farms-deleteReportedPost->errors---->', errors);
    throw errors;
  }
};

export const markPostAsSafe = async (params) => {
  try {
    let bodyParams = reqTransform.markPostAsSafe(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/DeletePostOrCommentOrImage_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.markPostAsSafe(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeletePostOrCommentOrImage_CT');
    console.log('api-farms-markPostAsSafe->err---->', err);
    console.log('api-farms-markPostAsSafe->errors---->', errors);
    throw errors;
  }
};

export const fetchReportedComments = async (params = {}) => {
  try {
    const qs = reqTransform.fetchReportedComments(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/GetReportedComment_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchReportedComments(resData);
  } catch (err) {
    console.log('api-posts-fetchReportedComments->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetReportedComment_CT');
    throw errors;
  }
};

export const deleteReportedComment = async (params) => {
  try {
    let bodyParams = reqTransform.deleteReportedComment(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/DeletePostOrCommentOrImage_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteReportedComment(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeletePostOrCommentOrImage_CT');
    console.log('api-farms-deleteReportedComment->err---->', err);
    console.log('api-farms-deleteReportedComment->errors---->', errors);
    throw errors;
  }
};

export const markCommentAsSafe = async (params) => {
  try {
    let bodyParams = reqTransform.markCommentAsSafe(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/DeletePostOrCommentOrImage_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.markCommentAsSafe(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeletePostOrCommentOrImage_CT');
    console.log('api-farms-markCommentAsSafe->err---->', err);
    console.log('api-farms-markCommentAsSafe->errors---->', errors);
    throw errors;
  }
};

export const fetchAllFeed = async (params = {}) => {
  try {
    const qs = reqTransform.fetchAllFeed(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchAllFeed?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllFeed(resData);
  } catch (err) {
    console.log('api-posts-fetchAllFeed->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllFeed');
    throw errors;
  }
};

export const fetchAllFeedWithFilters = async (params) => {
  try {
    let bodyParams = reqTransform.fetchAllFeedWithFilters(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchAllFeed_WithFilters`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllFeedWithFilters(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', '/api/SocialFeed/FetchAllFeed_WithFilters');
    console.log('api-farms-markCommentAsSafe->err---->', err);
    console.log('api-farms-markCommentAsSafe->errors---->', errors);
    throw errors;
  }
};

export const fetchAllCTFeed = async (params = {}) => {
  try {
    const qs = reqTransform.fetchAllCTFeed(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/Moderator/GetAllFeed_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllCTFeed(resData);
  } catch (err) {
    console.log('api-posts-fetchAllCTFeed->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetAllFeed_CT');
    throw errors;
  }
};

export const fetchPostComments = async (params) => {
  try {
    const qs = reqTransform.fetchPostComments(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchComments?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPostComments(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchComments');
    console.log('api-posts-fetchPostComments->err---->', err);
    console.log('api-posts-fetchPostComments->errors---->', errors);
    throw errors;
  }
};

export const createPostComment = async (params) => {
  try {
    const bodyParams = reqTransform.createPostComment(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/CreateComment_New`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createPostComment(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'CreateComment_New');
    console.log('api-posts-createPostComment->err---->', err);
    console.log('api-posts-createPostComment->errors---->', errors);
    throw errors;
  }
};
export const editSavedComment = async (params) => {
  try {
    const bodyParams = reqTransform.editSavedComment(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/EditComment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.editSavedComment(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'EditComment');
    console.log('api-posts-editSavedComment->err---->', err);
    console.log('api-posts-editSavedComment->errors---->', errors);
    throw errors;
  }
};

export const fetchTimelinePost = async (params) => {
  try {
    const qs = reqTransform.fetchTimelinePost(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchTimelinePost?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTimelinePost(resData);
  } catch (err) {
    console.log('getAllFeed->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchTimelinePost');

    throw errors;
  }
};

export const fetchSavedPosts = async (params) => {
  try {
    const qs = reqTransform.fetchSavedPosts(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchSavedPosts?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSavedPosts(resData);
  } catch (err) {
    console.log('getAllFeed->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchSavedPosts');

    throw errors;
  }
};

export const deleteSavedPost = async (post_id) => {
  try {
    const qs = reqTransform.deleteSavedPost(post_id);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeleteSavedPost?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteSavedPost(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteSavedPost');

    throw errors;
  }
};

export const deleteSavedComment = async (params) => {
  try {
    const qs = reqTransform.deleteSavedComment(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/DeleteComment?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteSavedComment(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'deleteSavedComment');

    throw errors;
  }
};

export const reportPostOrComment = async (params) => {
  try {
    const bodyParams = reqTransform.reportPostOrComment(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/ReportPostOrComment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.reportPostOrComment(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'ReportPostOrComment');
    throw errors;
  }
};

export const followUnfollowUser = async (pid, isFollow) => {
  try {
    const bodyParams = reqTransform.followUnfollowUser(pid, isFollow);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FollowUnfollowUser`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.followUnfollowUser(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FollowUnfollowUser');
    console.log('followUnfollowUser-->', err);
    throw errors;
  }
};

export const searchPost = async (params = {}) => {
  try {
    const bodyParams = reqTransform.searchPost(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/SearchPost`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllFeed(resData);
  } catch (err) {
    console.log('api-posts-SearchPost->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SearchPost');
    throw errors;
  }
};

export const fetchUserFollowersFollowings = async (params) => {
  try {
    const qs = reqTransform.fetchUserFollowersFollowings(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchUserProfileFollowersFollowings?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserFollowersFollowings(resData);
  } catch (err) {
    console.log('getAllFeed->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchUserProfileFollowersFollowings');

    throw errors;
  }
};

export const fetchUserMention = async (params) => {
  try {
    const qs = reqTransform.fetchUserMention(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/GetAddressingEntities?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserMention(resData);
  } catch (err) {
    console.log('GetAddressingEntities->', err);

    const errors = await utils.processApiErrors(err, '', 'GetAddressingEntities');

    throw errors;
  }
};

export const fetchCompanyPersonList = async (params) => {
  try {
    const qs = reqTransform.fetchUserMention(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Banner/GetAllCompaniesAndPersons?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserMention(resData);
  } catch (err) {
    console.log('GetAddressingEntities->', err);

    const errors = await utils.processApiErrors(err, '', 'GetAddressingEntities');

    throw errors;
  }
};

export const fetchUserLikedPostList = async (params) => {
  try {
    const qs = reqTransform.fetchUserLikedPostList(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchReactions?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserLikedPostList(resData);
  } catch (err) {
    console.log('getAllFeed->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchReactions');

    throw errors;
  }
};

export const fetchPostDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchPostDetails(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchPostDetailsAgainstPostId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPostDetails(resData);
  } catch (err) {
    console.log('api-posts-FetchPostDetailsAgainstPostId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPostDetailsAgainstPostId');
    throw errors;
  }
};

export const fetchSharePostDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchSharePostDetails(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchPostDetailsAgainstPostIdForExternalUse?${qs}`;
    const res = await utils.fetchShare(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSharePostDetails(resData);
  } catch (err) {
    console.log('api-posts-FetchPostDetailsAgainstPostIdForExternalUse->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPostDetailsAgainstPostIdForExternalUse');
    throw errors;
  }
};

export const fetchBfcFeedPostList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchBfcFeedPostList(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchAllFeedForBiofuelCircleMainWebsite`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchBfcFeedPostList(resData);
  } catch (err) {
    console.log('api-posts-FetchPostDetailsAgainstPostId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPostDetailsAgainstPostId');
    throw errors;
  }
};
