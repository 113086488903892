import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const BFCRedirect = (props) => {
  const redirect = useSelector((state) => state.redirect);
  const updatedAt = redirect.updatedAt || '';
  const history = useHistory();

  useEffect(() => {
    // console.log('useHistory->redirect-->', redirect);
    if (updatedAt) {
      // console.log('useHistory->redirecting--to-->', redirect.path);
      history.push(redirect.path);
    }
  }, [updatedAt]);

  return null;
};

export default BFCRedirect;
