import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const KycApprovalSchema = yup.object().shape({});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
