import qs from 'query-string';

import * as fetch from 'services/fetch';
import { number } from 'prop-types';

import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import utils from 'lib/utils';

const BASE_URL = `/BioFuelRegistration/api`;

export const fetchLocations = async (id = 1) => {
  try {
    let res = await api.fetchLocations(id);
    return res;
  } catch (err) {
    throw err;
  }
};

/** api fields
ReferenceData/ReadReferenceData?Reference_Data_Enum=8&Reference_Data_Operation_Enum=3&Entity_Id=3
Response body
{
  "stat": true,
  "message": "Success",
  "data": [
    {
      "displayMember": "Sugar Cane",
      "valueMember": 3
    },
    {
      "displayMember": "Rice",
      "valueMember": 4
    }
  ]
}
*/
/**
ReferenceData/ReadReferenceData?Reference_Data_Enum=8&Reference_Data_Operation_Enum=1&Entity_Id=3
{
  stat: true,
  message: 'Success',
  data: [
    {
      commodity_Id: 3,
      commodity_code: 'Sugar Cane',
      commodity_Name: 'Sugar Cane',
      commodity_Group_1_Id: null,
      commodity_Group_2_Id: null,
      commodity_Description: null,
      external_Ref: null,
      status_Enum: true,
      created_By: 1,
      created_Datetime: '2020-10-23T14:01:06.523+05:30',
      modified_By: null,
      modified_Datetime: null,
      lock_Id: null,
      id: 3,
    },
    {
      commodity_Id: 4,
      commodity_code: 'Rice',
      commodity_Name: 'Rice',
      commodity_Group_1_Id: null,
      commodity_Group_2_Id: null,
      commodity_Description: null,
      external_Ref: null,
      status_Enum: true,
      created_By: 1,
      created_Datetime: '2020-11-04T23:10:10.48+05:30',
      modified_By: null,
      modified_Datetime: null,
      lock_Id: null,
      id: 4,
    },
  ],
};
*/

let ddd = {
  stat: true,
  message: 'Success',
  data: [
    {
      commodity_Id: 3,
      commodity_code: 'Sugar Cane',
      commodity_Name: 'Sugar Cane',
      commodity_Group_1_Id: null,
      commodity_Group_2_Id: null,
      commodity_Description: null,
      external_Ref: null,
      status_Enum: true,
      created_By: 1,
      created_Datetime: '2020-10-23T14:01:06.523+05:30',
      modified_By: null,
      modified_Datetime: null,
      lock_Id: null,
      id: 3,
    },
  ],
};

export enum ReferenceDataEnum {
  BF_SETUP_COMPANY = 1,
  BF_SETUP_PERSON = 2,
  BF_SETUP_HASHTAGS = 3,
  BF_SEC_ROLE_MODULE_PERMISSION = 4,
  BF_SEC_MODULE = 5,
  BF_SETUP_CIRCLE = 6,
  BF_SETUP_CIRCLE_DETAIL = 7,
  BF_SETUP_COMMODITY = 8,
  BF_SETUP_COMMODITY_FORM = 9,
  BF_SETUP_COMMODITY_SPEC = 10,
  BF_SETUP_COMMODITY_TYPE = 11,
  BF_SETUP_COMPANY_ADDRESS = 12,
  BF_SETUP_COMPANY_BANK_ADDRESS = 13,
  BF_SETUP_COST_TYPE = 14,
  BF_SETUP_CURRENCY = 15,
  BF_SETUP_EXTERNAL_SHARING = 16,
  BF_SETUP_LOCATION = 17,
  BF_SETUP_PAYMENT_TERM = 18,
  BF_SETUP_PERSON_ADDRESS = 19,
  BF_SETUP_PERSON_BANK_ACCOUNT = 20,
  BF_SETUP_PERSON_CIRCLE = 21,
  BF_SETUP_PERSON_COMMODITY = 22,
  BF_SETUP_STORAGE = 23,
  BF_SETUP_STORAGE_LEVEL = 24,
  BF_SETUP_TAX_DETAIL = 25,
  BF_SETUP_TAX_HEADER = 26,
  BF_SETUP_UOM = 27,
  BF_SETUP_VEHICLE = 28,
  BF_SETUP_COMMODITY_RESIDUE = 29,
  BF_SETUP_COMPANY_AFFILIATE = 30,
  APP_CONFIGURATION = 31,
  APP_CONFIGURATION_DETAIL = 32,
  BF_SEC_ROLE = 'BF_SEC_ROLE',
  BF_SETUP_EQUIPMENT_MAKE = 49,
  BF_SETUP_EQUIPMENT_VARIANT = 50,
  BF_SETUP_EQUIPMENT_TYPE = 53,
}

export enum ReferenceDataOperationEnum {
  WholeRefData = 1,
  RefDataAgainstId = 2,
  RefDataForDropDown = 3,
  RefDataAgainstPersonId = 4,
}

type RefDataDropDownType = { value_member: number; display_member: string };

const ReadReferenceData = async <T>(
  referenceDataEnum: ReferenceDataEnum,
  referenceDataOperationEnum: ReferenceDataOperationEnum,
  entityId?: number,
) => {
  let urlParams = {
    reference_data_enum: referenceDataEnum,
    reference_data_operation_enum: referenceDataOperationEnum,
  };

  if (entityId) {
    urlParams['entity_id'] = entityId;
  }
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/ReferenceData/ReadReferenceData?${qs}`;

  const listData = await fetch.fetchList<T>(url);

  return listData;
};

const getDataForDropDown = async (referenceDataEnum: ReferenceDataEnum, entityId?: number) => {
  return await ReadReferenceData<RefDataDropDownType>(
    referenceDataEnum,
    ReferenceDataOperationEnum.RefDataForDropDown,
    entityId,
  );
};

const getEnumerator = async <T>(enumGroup: string) => {
  const urlParams = {
    enum_group: enumGroup,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/ReferenceData/GetEnumerator?${qs}`;

  const listData = await fetch.fetchList<RefDataDropDownType & { enum_group: string }>(url);

  return listData;
};

const getAllCommoditySpecsAgainstCommodityId = async <T>(enumGroup: string) => {
  const urlParams = {
    enum_group: enumGroup,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/ReferenceData/FetchCommodityFormAgainstCommodityId?${qs}`;

  // /api/ReferenceData/FetchCommodityFormAgainstCommodityId
  // /api/ReferenceData/FetchCommodityResidueAgainstCommodityId

  const listData = await fetch.fetchList<RefDataDropDownType & { enum_group: string }>(url);

  return listData;
};

export default {
  getDataForDropDown,
  getEnumerator,
  getAllCommoditySpecsAgainstCommodityId,
};
