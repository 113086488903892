import React, { useState, useEffect, } from 'react';
import dayjs from 'dayjs';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Formik, Field, Form, FieldArray, useFormikContext } from 'formik';
import { Tooltip } from 'antd';
import FormikMaterialTextField from './FormikMaterialTextField';
import FormikMaterialDatePicker from './FormikDatePickerField';
import FormikDatePickerField from '../../KYCIndividual/components/FormikDatePickerField';

import FormikMaterialTextFieldAcct from './FormikMaterialTextFieldAcct';
import { useSelector, useDispatch } from 'react-redux';
import RemoveCircleIcon from 'mdi-react/RemoveCircleIcon';
import ReferenceDataSelect from '../../components/ReferenceDataSelect';
import FormikLocationSelect from 'common/Form/FormikLocationSelectEloc';
import searchLocation from 'common/Form/searchLocation';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import LogoLoader from 'common/LogoLoader';
import EyeIcon from 'mdi-react/EyeIcon';
import { actions as kycBusinessActions } from 'redux/kycBusiness';
import { actions as kycBusinessTaxIdentityActions } from 'redux/kycBusinessTaxIdentity';
import { actions as kycBusinessBankIdentityActions } from 'redux/kycBusinessBankIdentity';
import { actions as companyActions } from 'redux/companies';
import { actions as affiliateActions } from 'redux/affiliates';
import { actions as eKycStatusActions } from 'redux/eKycStatus';
import { actions as transportSearchDriverActions } from 'redux/transportSearchDriver';
import { actions as kycBusinessCompanyActions } from 'redux/companyAffiliates';
import PropTypes from 'prop-types';
import * as handlers from '../handlers';
import * as driverHandlers from 'pages/network/driver/handlers';
import Collapse from '../../components/Collapse';
import useQuery from 'lib/hooks/useQuery';
import CompanyLocationSelect from './companyLocationSelect';
import BankSelect from './bankSelect';
import PopupForm from 'common/Form/PopupForm';
import BankForm from './BankForm';
import BankPopup from 'containers/Account/Profile/KYCIndividual/components/BankPopup';
import BankVerificationForm from 'containers/Account/Profile/KYCIndividual/components/BankVerificationForm';
import * as bankHandlers from 'containers/Account/Profile/KYCIndividual/handlers';
import ToggleSwitch from 'containers/Account/Profile/KYCIndividual/components/toggleSwitchDisplay';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { locationElocService } from 'services';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import FormikDateTimePickerField from 'shared/components/form/FormikDateTimePickerFieldQS';
import _ from 'lodash';
import notifications from 'lib/notifications';

const BUSINESS_TYPE = {
  PROPRIETORSHIP: 1,
  PRIVATE_LIMITED_CO: 2,
  PUBLIC_LIMITED_CO: 4,
  LLP: 5,
};

const getInitialValues = (kycDetails, taxDetails, businessType, user, driverDetails, locDetailsValue, isSHG) => {

  let params = {
    company_tax_detail_id: _.get(kycDetails, 'company_tax_detail_id') || 0,
    company_affiliate_enum: _.get(kycDetails, 'company_affiliate_enum') || 0, //@TODO Hardcode
    business_pan_id: _.get(kycDetails, 'business_pan_id') || '',
    name_as_per_pan: _.get(kycDetails, 'name_as_per_pan') || '',
    cin: _.get(kycDetails, 'cin') || '',
    llpin: _.get(kycDetails, 'llpin') || '',
    pan_of_authorized_signatory: _.get(kycDetails, 'pan_of_authorized_signatory') || '',
    dl_of_authorized_signatory: _.get(kycDetails, 'dl_of_authorized_signatory') || '',
    company_id_of_authorized_signatory: _.get(kycDetails, 'company_id_of_authorized_signatory') || '',
    u_aadhar: _.get(kycDetails, 'u_aadhar') || '',
    name_of_authorized_signatory: _.get(kycDetails, 'name_of_authorized_signatory') || '',
    same_as_registered_address_flag: _.get(kycDetails, 'same_as_registered_address_flag') || true,

    business_type_enum: _.get(kycDetails, 'business_type_enum') || businessType || 0,
    identity_type_enum: _.get(kycDetails, 'identity_type_enum') || 0, //@TODO Hardcode
    identity_type_enum_code: _.get(kycDetails, 'identity_type_enum_code') || '', //@TODO Hardcode

    nabl_bis_accrediation_no: _.get(kycDetails, 'nabl_bis_accrediation_no') || '',
    accrediation_valid_upto_date: _.get(kycDetails, 'accrediation_valid_upto_date') || '',
    msme_classification_enum: _.get(kycDetails, 'msme_classification_enum') || 1,
    msme_classification_enum_code: _.get(kycDetails, 'msme_classification_enum_code') || 'None',
    location_id: _.get(kycDetails, 'location_id') || 0,
    location_code: _.get(kycDetails, 'location_code') || '',
    // village_id: _.get(kycDetails, 'village_id') || 0,
    village_code: _.get(kycDetails, 'village_code') || '',
    // taluka_id: _.get(kycDetails, 'taluka_id') || 0,
    taluka_code: _.get(kycDetails, 'taluka_code') || '',
    // city_id: _.get(kycDetails, 'city_id') || 0,
    city_code: _.get(kycDetails, 'city_code') || '',
    // district_id: _.get(kycDetails, 'district_id') || 0,
    district_code: _.get(kycDetails, 'district_code') || '',
    // state_id: _.get(kycDetails, 'state_id') || 0,
    state_code: _.get(kycDetails, 'state_code') || '',
    country_id: _.get(kycDetails, 'country_id') || 0,
    country_code: _.get(kycDetails, 'country_code') || 'India',
    address_lattitude: _.get(kycDetails, 'lattitude') || '',
    address_longitude: _.get(kycDetails, 'longitude') || '',
    address_line_1: _.get(kycDetails, 'address_line_1') || '',
    address_line_2: _.get(kycDetails, 'address_line_2') || '',
    address_line_3: _.get(kycDetails, 'address_line_3') || '',
    pin_code: _.get(kycDetails, 'pin_code') || '',
    locality: '',
    place_id: _.get(kycDetails, 'place_id') || '',

    company_address_id: _.get(kycDetails, 'company_address_id') || 0,
    location_tuple: _.get(kycDetails, 'location_tuple') || '',

    //Tax
    company_gst_detail_id: _.get(taxDetails, 'company_gst_detail_id') || 0,
    have_gst_flag: _.get(taxDetails, 'have_gst_flag', true),
    gstin: _.get(taxDetails, 'gstin') || '',

    user_id: _.get(window, 'user.userid') || 0,

    //driver
    first_name: _.get(user, 'profile.person.first_name') || '',
    last_name: _.get(user, 'profile.person.last_name') || '',
    middle_name: _.get(driverDetails, 'middle_name') || '',
    dob: _.get(driverDetails, 'dob') || _.get(kycDetails, 'dob') || '1999-01-01',
    license_number: _.get(driverDetails, 'license_number') || _.get(kycDetails, 'dl_of_authorized_signatory') || '',
    mobile_number: _.get(user, 'profile.person.mobile_number') || '',
    entity_type_enum: kycDetails?.dl_of_authorized_signatory ? { title: 'Driving License', type: 'Driving License' } : kycDetails?.pan_of_authorized_signatory ? { title: 'PAN', type: 'Pan' } : kycDetails?.company_id_of_authorized_signatory ? { title: 'Company ID Card', type: 'Employee Num.' } : { title: 'PAN', type: 'Pan' },
    nrlm_registration_number: kycDetails?.nrlm_registration_number || '',
    isSHG: isSHG
  }
  return params;
};

const KycForm = (props) => {
  const { entity_id, company_affiliate_enum, kyc_status_enum_code, display_member } = props;
  const id = props.id;
  let company = props.company;
  const { back } = useQuery();
  const user = useSelector((state) => state.user);
  let driverUserDetails = user;
  const kycUser = useSelector((state) => state.kycUser);
  const qualityLaboratory = _.get(kycUser, 'profile.person.quality_laboratory') || false;
  let userRole = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  let phoneNumber = _.get(user, 'profile.person.mobile_number') || '';
  let isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  let typeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const [timestamp, setTimestamp] = useState(Date.now());

  //fetch business type enum
  let companies = useSelector((state) => state.companies);
  let affiliates = useSelector((state) => state.affiliates);
  const eKycStatus = useSelector((state) => state.eKycStatus);
  const kycBankBusiness = useSelector((state) => state.kycBusinessBankIdentity);
  const bankDataWithVirtualAccount = _.get(kycBankBusiness, 'bankDetails') || {};
  const formikRef = React.useRef();
  let businessType = '';
  let isKycDone = false;

  //fetch business type enum from organisation if not present dispatch action
  if (company_affiliate_enum === 1) {
    if (_.isEmpty(companies)) {
      dispatch(companyActions.setDirty());
    }
    companies = useSelector((state) => state.companies);
    const companyData = _.get(companies, `entities[${entity_id}]`) || {};
    businessType = _.get(companyData, 'business_type_enum') || 0;
  } else if (company_affiliate_enum === 2) {
    if (_.isEmpty(affiliates)) {
      dispatch(affiliateActions.setDirty());
    }
    affiliates = useSelector((state) => state.affiliates);
    const affiliateData = _.get(affiliates, `entities[${entity_id}]`) || {};
    businessType = _.get(affiliateData, 'business_type_enum') || 0;
  }

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
    driverUserDetails = kycUser;
    userRole = _.get(kycUser, 'profile.person.type_of_individual_enum') || 0;
    phoneNumber = _.get(kycUser, 'profile.person.mobile_number') || '';
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  let driverDetails = {};
  let busyDriver = false;
  if (userRole == 2) {
    const transportSearchDriver = useSelector((state) => state.transportSearchDriver);
    busyDriver = _.get(transportSearchDriver, 'busy') || false;
    const driverIds = _.get(transportSearchDriver, 'ids') || [];
    const driverId = driverIds[0];
    driverDetails = _.get(transportSearchDriver, `entities.${driverId}`);
  }

  let kycStatusKey = false;
  if (kyc_status_enum_code == 'Approved' || kyc_status_enum_code == 'Pending') {
    kycStatusKey = true;
  }
  if (kyc_status_enum_code == 'Rejected' || kyc_status_enum_code == 'Not Submitted') {
    kycStatusKey = false;
  }

  const acl = utils.getACL(user);
  let nextLink = `/account/${id}/upload`;
  let prevLink = `/account/${id}/transport`;
  if (acl.isCompany) {
    nextLink = `/account/${id}/upload`;
    prevLink = `/account/${id}/transport`;
  }
  if (acl.isQALab) {
    nextLink = `/account/${id}/upload`;
    prevLink = `/account/${id}/teams`;
  }
  if (isAdmin) {
    nextLink = `/account/${id}/upload?back=${back}`;
    prevLink = `/account/${id}/transport?back=${back}`;
    if (acl.isCompany) {
      nextLink = `/account/${id}/upload?back=${back}`;
      prevLink = `/account/${id}/transport?back=${back}`;
    }
    if (qualityLaboratory) {
      nextLink = `/account/${id}/upload?back=${back}`;
      prevLink = `/account/${id}/teams?back=${back}`;
    }
  }
  const kycBusiness = useSelector((state) => state.kycBusiness);
  const kycTaxBusiness = useSelector((state) => state.kycBusinessTaxIdentity);

  //busy flag
  const busyCompanies = _.get(companies, 'busy') || false;
  const busyKyc = _.get(kycBusiness, 'busy') || false;
  const busyTax = _.get(kycTaxBusiness, 'busy') || false;
  const busyEKyc = _.get(eKycStatus, 'busy') || false;
  let kycDetails = _.get(kycBusiness, 'entities') || {};
  let kycDetailsLoaderCheck = _.get(kycBusiness, 'entities') || {};
  let taxDetails = _.get(kycTaxBusiness, 'entities') || {};
  let taxDetailsLoaderCheck = _.get(kycTaxBusiness, 'entities') || {};

  const eKycStatusList = _.get(eKycStatus, 'entities') || {};

  //

  //to render between company and its affiliates
  let entityIdKyc = _.get(kycDetails, 'company_id') || _.get(kycDetails, 'affiliate_id') || 0;
  let entityIdTax = _.get(taxDetails, 'company_id') || _.get(taxDetails, 'affiliate_id') || 0;
  //

  if (entity_id !== entityIdKyc) {
    kycDetails = {};
  }
  if (entity_id !== entityIdTax) {
    taxDetails = {};
  }

  const company_tax_detail_id = _.get(kycDetails, 'company_tax_detail_id') || 0;
  const company_gst_detail_id = _.get(taxDetails, 'company_gst_detail_id') || 0;

  const dispatch = useDispatch();

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState();
  const [authorizedSignatoryDocType, setAuthorizedSignatoryDocType] = useState({});

  const [latLongData, setLatLongData] = useState({});
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');

  let addressProof = [
    { title: 'PAN', type: 'Pan' },
    { title: 'Driving License', type: 'Driving License' },
  ];

  if (businessType === 4 || businessType === 2) {
    addressProof.push({ title: 'Company ID Card', type: 'Employee Num.' },)
  } else {
    addressProof = [
      { title: 'PAN', type: 'Pan' },
      { title: 'Driving License', type: 'Driving License' },
    ]
  }


  useEffect(() => {
    if (businessType !== 4 && _.isEmpty(kycDetails)) {
      setAuthorizedSignatoryDocType(addressProof[0])
    }
  }, [businessType])


  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  //EKyc
  const [panText, setPanText] = useState('Verify');
  const [drivingLicenseText, setDrivingLiceneceText] = useState('Verify');
  const [showVerify, setShowVerify] = useState(true);
  const [businessPanText, setBusinessPanText] = useState('Verify');
  const [gstText, setGstText] = useState('Verify');
  const [licenseText, setLicenseText] = useState('Verify');
  const haveGst = _.get(taxDetails, 'have_gst_flag');

  let haveGstYes = true;
  let haveGstNo;

  if (haveGst == true) {
    haveGstYes = true;
  }

  if (haveGst == false) {
    haveGstNo = true;
  } else {
    haveGstNo = false;
  }
  const handleEKyc = async (values, formikBag) => {
    if (values.eKyc == 'DriverLicense') {
      const success = await driverHandlers.handleEKycDocument(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (success == 'Identity verified' || kycMessage == 'Identity already verified.') {
        setLicenseText('Verified');
      }
    }
  };

  useEffect(() => {
    if (entity_id !== undefined) {
      const params = {
        entity_id: entity_id,
        company_affiliate_enum: company_affiliate_enum,
        person_id: id,
      };
      dispatch(kycBusinessActions.fetchKYCBusinessIdentityDetails(params));
      dispatch(kycBusinessTaxIdentityActions.fetchTaxDetails(params));
      if (isControlTowerUser) {
        const user_type_enum = _.get(user, 'profile.person.user_type_enum') || 0;
        dispatch(
          eKycStatusActions.fetchEKycStatusCt({
            person_id: entity_id,
            company_affiliate_enum: company_affiliate_enum,
            user_type_enum: user_type_enum,
          }),
        );
      } else {
        dispatch(eKycStatusActions.fetchList({ person_id: entity_id, company_affiliate_enum: company_affiliate_enum }));
      }
    }

    if (userRole == 2) {
      let driverParams = {
        mobile_number: phoneNumber,
      };
      dispatch(transportSearchDriverActions.fetchDriverByMobile(driverParams));
    }
  }, [props.entity_id, timestamp,]);

  const params = {
    person_id: parseInt(id),
  };

  useEffect(() => {
    dispatch(companyActions.fetchList(params));
  }, [companies.dirty]);

  useEffect(() => {
    dispatch(affiliateActions.fetchList(params));
  }, [affiliates.dirty]);
  //


  useEffect(() => {
    if (!_.isEmpty(kycDetails)) {
      if (kycDetails?.dl_of_authorized_signatory) {
        setAuthorizedSignatoryDocType({ title: 'Driving License', type: 'Driving License' })
      }
      else if (kycDetails?.pan_of_authorized_signatory) {
        setAuthorizedSignatoryDocType({ title: 'PAN', type: 'Pan' })
      }
      if (kycDetails?.company_id_of_authorized_signatory) {
        setAuthorizedSignatoryDocType({ title: 'Company ID Card', type: 'Employee Num.' })
      }
    }
  }, [kycDetails?.dl_of_authorized_signatory,
  kycDetails?.company_id_of_authorized_signatory,
  kycDetails?.pan_of_authorized_signatory])

  const SetPanText = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      !_.isEmpty(eKycStatusList) && !_.isEmpty(values) && _.map(eKycStatusList, (details, id) => {
        if (values.entity_type_enum.type === 'Pan') {
          if (details.enum_name == 'PanVerification') {
            if ((details.document_status && (details.document_number === values.pan_of_authorized_signatory)) || (!showVerify)) {
              setPanText('Verified');
              setShowVerify(true)
              // } else if (showVerify && (kycDetails.pan_of_authorized_signatory !== values.pan_of_authorized_signatory)) {
              //   setPanText('Verify');
          } else if (values.pan_of_authorized_signatory.length < 10) {
            setPanText('Verify');
            }
          }
        }
      });

    }, [showVerify, values?.pan_of_authorized_signatory, kycDetails, eKycStatusList])
    return null;
  }

  if (
    busyCompanies ||
    busyKyc ||
    busyTax ||
    busyEKyc ||
    busyDriver ||
    (entity_id !== entityIdKyc && acl.isControlTowerUser) ||
    (entity_id !== entityIdTax && acl.isControlTowerUser)
  ) {
    // if (busyKyc || busyTax || busyEKyc || busyDriver) {

    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  const handleCheck = async (values, formikBag) => {

    values['dob'] = dayjs(values['dob']).format('YYYY-MM-DD');
    if (values.eKyc == 'businessPanId') {
      const success = await handlers.handleEKycBusinessPan(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (
        success == 'Identity verified' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.'
      ) {
        if (values.eKyc == 'businessPanId') {
          setBusinessPanText('Verified');
        }
      }
    } else if (values.eKyc == 'panOfAuthorizedSignatory') {
      const success = await handlers.handleEKycBusinessPan(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';

      if (
        success == 'Identity verified.' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.'
      ) {
        if (values.eKyc == 'panOfAuthorizedSignatory') {
          setPanText('Verified');
          setShowVerify(false)
          // setTimestamp(Date.now())
        }
      }
    } else if (values.eKyc == 'DLOfAuthorizedSignatory') {
      const success = await handlers.handleEKycBusinessPan(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (
        success == 'Identity verified.' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.'
      ) {
        if (values.eKyc == 'DLOfAuthorizedSignatory') {
          setDrivingLiceneceText('Verified');
        }
      }
    } else if (values.eKyc == 'gst') {
      const success = await handlers.handleEKycBusinessPan(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (
        success == 'Identity verified' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.'
      ) {
        if (values.eKyc == 'gst') {
          setGstText('Verified');
        }
      }
    } else {
      if (userRole == 2) {
        let driverParams = {
          first_name: values['first_name'],
          middle_name: values['middle_name'],
          last_name: values['last_name'],
          mobile_number: values['mobile_number'],
          license_number: values['license_number'],
          person_id: _.get(user, 'person_id') || 0,
          dob: values['dob'] || '1999-01-01',
        };
        await driverHandlers.handleKycUpdate(driverParams, formikBag);

      }

      delete values['first_name'];
      delete values['last_name'];
      delete values['middle_name'];
      delete values['mobile_number'];

      let resKycData = {};
      let resTaxData = {};

      if (values['accrediation_valid_upto_date'] != '') {
        values['accrediation_valid_upto_date'] = dayjs(values['accrediation_valid_upto_date']).format('YYYY-MM-DD');
      } else {
        delete values['accrediation_valid_upto_date'];
      }
      //kyc
      company_tax_detail_id
        ? (resKycData = await handlers.handleUpdate(values, formikBag))
        : (resKycData = await handlers.handleCreate(values, formikBag));
      //tax
      company_gst_detail_id
        ? (resTaxData = await handlers.handleTaxUpdate(values, formikBag))
        : (resTaxData = await handlers.handleTaxCreate(values, formikBag));

      if (!bankDataWithVirtualAccount?.length) {
        notifications.show({ type: 'error', message: 'Please provide bank details to complete the KYC.' })
      }

      if (!_.isEmpty(resKycData) || !_.isEmpty(resTaxData)) {
        setTimestamp(Date.now());
      }
    }
  };

  const selectedDocumentTooltip = (
    <div>
      {
        (<p className="p-0 m-0">Name of Authorized Signatory must exactly match with the name on {authorizedSignatoryDocType?.title}</p>)
      }
    </div>
  );

  let isSHG = false;
  if (userRole == 10) {
    isSHG = true;
  }
  return (
    <div className="">
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues(
          kycDetails,
          taxDetails,
          // bankArray,
          businessType,
          driverUserDetails,
          driverDetails,
          locDetailsValue,
          isSHG
        )}
        // onSubmit={company_tax_detail_id ? handlers.handleUpdate : handlers.handleCreate}>
        onSubmit={handleCheck}>
        {(formikBag) => {
          const { values, initialValues } = formikBag;

          values.company_affiliate_enum = company_affiliate_enum;
          values.entity_id = entity_id;
          if (authorizedSignatoryDocType?.type === 'Pan') {
            values['dl_of_authorized_signatory'] = ''
          } else {
            values['pan_of_authorized_signatory'] = ''
          }
          values.address_lattitude = (_.get(latLongData, 'latitude') || '').toString();
          values.address_longitude = (_.get(latLongData, 'longitude') || '').toString();
          // values.place_id = (_.get(latLongData, 'eloc') || '').toString();
          _.map(eKycStatusList, (details, id) => {
            try {
              if (details.enum_name == 'BusinessPanVerification') {
                if (details.document_status) {
                  setBusinessPanText('Verified');
                }
              }
              if (details.enum_name == 'DLVerification') {
                if (details.document_status) {
                  setDrivingLiceneceText('Verified')
                }
              }
              // if (details.enum_name == 'PanVerification') {

              //   if ((details.document_status && (kycDetails.pan_of_authorized_signatory === values.pan_of_authorized_signatory)) || (!showVerify)) {
              //     setPanText('Verified');
              //     setShowVerify(true)
              //   } else if (showVerify && (kycDetails.pan_of_authorized_signatory !== values.pan_of_authorized_signatory)) {
              //     setPanText('Verify');
              //   }

              // }
              if (details.enum_name == 'GSTVerification') {
                if (details.document_status) {
                  setGstText('Verified');
                }
              }
            } catch (err) {
              console.log('eKyc status=========>error', err);
            }
          });
          return (
            <React.Fragment>
              <Form className="formik-form">
                <Card>
                  <CardBody className="main-cardbody">
                    <Collapse title="Business Identity" className="with-shadow">
                      <Card>
                        <CardBody>
                          <SetPanText />
                          <div className="row form__form-group">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">Business Type</span>
                              <span class="required">*</span>
                              <div className="form-group-field">
                                <Field
                                  name="business_type_enum"
                                  component={ReferenceDataSelect}
                                  entity="businessTypeEnum"
                                  initialDisplayText={_.isEmpty(businessType) ? businessType : ''}
                                  // disabled={isKycDone ? true : false || isAdmin || kycStatusKey}
                                  disabled={true}
                                  dependentFields={['business_pan_id', 'name_as_per_pan', 'cin', 'llpin']}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row form__form-group">
                            {_.get(values, 'business_type_enum') !== BUSINESS_TYPE.PROPRIETORSHIP && (
                              <>
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">Business PAN</span>
                                  <span class="required">{userRole == 10 ? '' : '*'}</span>
                                  <div className="form-group-field with-verified__btn">
                                    <div className="row no-gutters">
                                      <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                        <Field
                                          name="business_pan_id"
                                          component={FormikMaterialTextField}
                                          disabled={isAdmin || kycStatusKey}
                                        // disabled={_.get(values, 'business_type_enum') === BUSINESS_TYPE.PROPRIETORSHIP}
                                        />
                                      </div>
                                      <div className="col-3 col-sm-2 col-md-2">
                                        {values.business_pan_id && (
                                          <div className="">
                                            <div className="form-group-field">
                                              <button
                                                type="button"
                                                className={`form__form-group-button ${businessPanText === 'Verified' || isAdmin || kycStatusKey
                                                  ? ''
                                                  : 'active'
                                                  }`}
                                                disabled={
                                                  businessPanText === 'Verified' || isAdmin || kycStatusKey
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  formikBag.values.eKyc = 'businessPanId';
                                                  formikBag.handleSubmit(formikBag.values, formikBag);
                                                }}>
                                                {formikBag.isSubmitting ? (
                                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                ) : (
                                                  businessPanText
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {_.get(values, 'business_type_enum') !== BUSINESS_TYPE.PROPRIETORSHIP && (
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Business Name as per PAN</span>
                                <span class="required">{userRole == 10 ? '' : '*'}</span>
                                <div className="form-group-field">
                                  <Field
                                    name="name_as_per_pan"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  // disabled={_.get(values, 'business_type_enum') === BUSINESS_TYPE.PROPRIETORSHIP}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row form__form-group">
                            {(_.get(values, 'business_type_enum') === BUSINESS_TYPE.PRIVATE_LIMITED_CO ||
                              _.get(values, 'business_type_enum') === BUSINESS_TYPE.PUBLIC_LIMITED_CO) && (
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">CIN</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field">
                                    <Field
                                      name="cin"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    // disabled={
                                    //   _.get(values, 'business_type_enum') !== BUSINESS_TYPE.PRIVATE_LIMITED_CO &&
                                    //   _.get(values, 'business_type_enum') !== BUSINESS_TYPE.PUBLIC_LIMITED_CO
                                    // }
                                    />
                                  </div>
                                </div>
                              )}
                            {_.get(values, 'business_type_enum') === BUSINESS_TYPE.LLP && (
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">LLPIN</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <Field
                                    name="llpin"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  // disabled={_.get(values, 'business_type_enum') !== BUSINESS_TYPE.LLP}
                                  />
                                </div>
                              </div>
                            )}
                            {!(_.get(values, 'business_type_enum') === 4) && (
                              <>
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">MSME Classification</span>
                                  {_.get(values, 'msme_classification_enum') === 2 ||
                                    _.get(values, 'msme_classification_enum') === 3 ||
                                    _.get(values, 'msme_classification_enum') === 4
                                    ? <span class="required">*</span> : ''}

                                  <div className="form-group-field">
                                    <Field
                                      name="msme_classification_enum"
                                      component={ReferenceDataSelect}
                                      entity="msmEClassificationEnum"
                                      disabled={isAdmin || kycStatusKey}
                                      dependentFields={['u_aadhar']}
                                      cb={(newVal) => {
                                        const display_member = _.get(newVal, 'display_member') || '';
                                        const value_member = _.get(newVal, 'value_member') || 0;
                                        formikBag.setFieldValue('msme_classification_enum_code', display_member);
                                        formikBag.setFieldValue('msme_classification_enum', value_member);
                                      }}
                                    />

                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">Udyam/Udyog Aadhaar</span>
                                  {_.get(values, 'msme_classification_enum') === 2 ||
                                    _.get(values, 'msme_classification_enum') === 3 ||
                                    _.get(values, 'msme_classification_enum') === 4
                                    ? <span class="required">*</span> : ''}
                                  <div className="form-group-field">
                                    <Field
                                      name="u_aadhar"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey || _.get(values, 'msme_classification_enum') === 1}
                                      isNumberAlphabetAllow={true}
                                    />
                                  </div>
                                </div>
                              </>)}
                          </div>
                          {userRole == 10 ? (<div className="row form__form-group">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label"> NRLM/SRLM Registration Number</span>
                              <span class="required">*</span>
                              <div className="form-group-field">
                                <Field
                                  name="nrlm_registration_number"
                                  component={FormikMaterialTextField}
                                  disabled={isAdmin || kycStatusKey}
                                />
                              </div>
                            </div>

                          </div>) : null}

                          <div className="row form__form-group">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">Authorized Signatory's Documents</span>
                              <span class="required">*</span>
                              <Autocomplete
                                size="small"
                                name="entity_type_enum"
                                options={addressProof}
                                getOptionLabel={(option) => option.title}
                                value={authorizedSignatoryDocType}
                                onChange={(e, dropdownValues) => {

                                  setAuthorizedSignatoryDocType(dropdownValues);
                                  formikBag.setFieldValue('entity_type_enum', dropdownValues);
                                  formikBag.setFieldValue('pan_of_authorized_signatory', '');
                                  formikBag.setFieldValue('dl_of_authorized_signatory', '');
                                  formikBag.setFieldValue('company_id_of_authorized_signatory', '');
                                }}
                                closeIcon={false}
                                dependentFields={['pan_of_authorized_signatory', 'dl_of_authorized_signatory']}
                                disabled={isAdmin || kycStatusKey}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                              />
                            </div>
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">Name as per {authorizedSignatoryDocType?.title}</span>
                              <span class="required">*</span>
                              <Tooltip placement="bottomLeft" title={authorizedSignatoryDocType?.type ? selectedDocumentTooltip : null} arrow>
                                <div className="form-group-field">
                                  <Field
                                    name="name_of_authorized_signatory"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </Tooltip>


                            </div>
                          </div>
                          <div className="row form__form-group">
                            {
                              values.entity_type_enum?.type == "Driving License" || values.dl_of_authorized_signatory ? (
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">Date of Birth</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field driver-dob custom-input">
                                    <Field
                                      name="dob"
                                      component={FormikDatePickerField}
                                      defaultValue={values.dob}
                                      disabled={isAdmin || kycStatusKey}
                                      showYearDropdown={true}
                                      showMonthDropdown={true}
                                    />
                                    <img src={calendarIcon} className="datepicker-calendar-icon" />
                                  </div>
                                  <div className="form-group-field">
                                    <div className="notes-block">
                                      <p className="redbg mt-10">
                                        We only need this to verify your identity using the Driving Licence number
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) :
                                null
                            }
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">{authorizedSignatoryDocType?.type || ` ${'Document'} `} of Authorized Signatory</span>
                              <span class="required">*</span>
                              <div className="form-group-field with-verified__btn">
                                <div className="row no-gutters">
                                  <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                    <Field
                                      name={
                                        authorizedSignatoryDocType?.type === 'Pan'
                                          ? 'pan_of_authorized_signatory'
                                          : authorizedSignatoryDocType?.type === 'Driving License' ? 'dl_of_authorized_signatory' : 'company_id_of_authorized_signatory'
                                      }
                                      component={FormikMaterialTextField}
                                      isNumberAlphabetAllow={true}
                                      autoCapitalize={true}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                  {authorizedSignatoryDocType?.type !== 'Company ID Card' && <div className="col-3 col-sm-2 col-md-2">
                                    {(values.pan_of_authorized_signatory) ? (
                                      <div className="">
                                        <div className="form-group-field">
                                          <button
                                            type="button"
                                            className={`form__form-group-button ${panText === 'Verified' || isAdmin || kycStatusKey ? '' : 'active'
                                              }`}
                                            disabled={panText === 'Verified' || isAdmin || kycStatusKey ? true : false}
                                            onClick={() => {
                                              if (authorizedSignatoryDocType?.type === 'Pan') {
                                                formikBag.values.eKyc = 'panOfAuthorizedSignatory';
                                                formikBag.handleSubmit(formikBag.values, formikBag);
                                                delete values['dl_of_authorized_signatory'];
                                              } else if (authorizedSignatoryDocType?.type === 'Driving License') {
                                                formikBag.values.eKyc = 'DLOfAuthorizedSignatory';
                                                formikBag.handleSubmit(formikBag.values, formikBag);
                                                delete values['pan_of_authorized_signatory'];
                                              }
                                            }}>
                                            {formikBag.isSubmitting ? (
                                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            ) : (
                                              panText
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    )
                                      : ''}
                                    {values.dl_of_authorized_signatory ? (<div className="">
                                      <div className="form-group-field">
                                        <button
                                          type="button"
                                          className={`form__form-group-button ${drivingLicenseText === 'Verified' || isAdmin || kycStatusKey ? '' : 'active'
                                            }`}
                                          disabled={drivingLicenseText === 'Verified' || isAdmin || kycStatusKey ? true : false}
                                          onClick={() => {
                                            if (authorizedSignatoryDocType?.type === 'Driving License') {
                                              formikBag.values.eKyc = 'DLOfAuthorizedSignatory';
                                              formikBag.handleSubmit(formikBag.values, formikBag);
                                              delete values['pan_of_authorized_signatory'];
                                            }
                                          }}>
                                          {formikBag.isSubmitting ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                          ) : (
                                            drivingLicenseText
                                          )}
                                        </button>
                                      </div>
                                    </div>) : null}
                                  </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                          {(acl.isQALab || (acl.isControlTowerUser && userRole === 8)) && (
                            <div className="row form__form-group">
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">NABL/ BIS Accreditation Number</span>
                                <div className="form-group-field">
                                  <Field
                                    name="nabl_bis_accrediation_no"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Accreditation valid upto</span>
                                <span class="required">*</span>
                                <div className="form-group-field custom-input">
                                  <Field
                                    name="accrediation_valid_upto_date"
                                    defaultValue={kycDetails.accrediation_valid_upto_date || ''}
                                    component={FormikMaterialDatePicker}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                  <img src={calendarIcon} className="datepicker-calendar-icon" />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row form__form-group">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">Address</span>
                              <span class="required">*</span>
                              <div className="form-group-field">
                                <div className="row no-gutters">
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <Field
                                      name="company_address_id"
                                      component={CompanyLocationSelect}
                                      placeholder="Select Location"
                                      id={id}
                                      entity_id={values.entity_id || entity_id || 0}
                                      company_affiliate_enum={
                                        values.company_affiliate_enum || company_affiliate_enum || 0
                                      }
                                      initialDisplayText={values.company_address_id || values.location_code || 0}
                                      disabled={isAdmin || kycStatusKey}
                                      cb={(newVal) => {
                                        let addressId = _.get(newVal, 'value.id') || 0;
                                        let placeId = _.get(newVal, 'value.place_id') || 0;
                                        formikBag.setFieldValue('company_address_id', addressId);
                                        formikBag.setFieldValue('place_id', placeId || '');
                                      }}
                                    />
                                  </div>
                                  {!isAdmin && !kycStatusKey && (
                                    <div className="col-sm-4 col-md-4">
                                      <div className="">
                                        <div className="form-group-field">
                                          <Link
                                            to={getUrlWithEncryptedQueryParams(`/account/${id}/organisations/locations/create`)}
                                            style={{
                                              minWidth: 'fit-content',
                                              width: '100%',
                                              height: '38px',
                                            }}
                                            target="_blank">
                                            +Add Location
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>

                    <Collapse title="Tax Identity" className="with-shadow">
                      <Card>
                        <CardBody>
                          <div className="businesstax-block">
                            <div className="radiobox-wrapper">
                              <label className="radio-color-btn">
                                <input
                                  type="radio"
                                  name="radiocust3"
                                  // defaultChecked={values.have_gst_flag ? true : false}
                                  defaultChecked={haveGstYes}
                                  disabled={isAdmin || kycStatusKey}
                                  onClick={(v) => formikBag.setFieldValue('have_gst_flag', true)}
                                />{' '}
                                We have a registered GSTIN
                                <div className="control-indicator"></div>
                              </label>
                              <label className="radio-color-btn">
                                <input
                                  type="radio"
                                  name="radiocust3"
                                  // defaultChecked={values.have_gst_flag ? true : false}
                                  defaultChecked={haveGstNo}
                                  disabled={isAdmin || kycStatusKey}
                                  onClick={(v) => formikBag.setFieldValue('have_gst_flag', false)}
                                />{' '}
                                We don't have a GSTIN
                                <div className="control-indicator"></div>
                              </label>
                            </div>
                          </div>
                          {values.have_gst_flag === true && (
                            <div className="row form__form-group">
                              <div className="col-md-5 col-lg-5 col-xl-4">
                                <div className="form-group-field with-verified__btn">
                                  <span className="form-group-label">GST</span>
                                  <span class="required">*</span>
                                  <div className="row no-gutters">
                                    <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                      <Field
                                        name="gstin"
                                        component={FormikMaterialTextField}
                                        disabled={isAdmin || kycStatusKey}
                                        autoCapitalize={true}
                                      />
                                    </div>
                                    <div className="col-3 col-sm-2 col-md-2">
                                      {values.gstin && (
                                        <div className="">
                                          <div className="form-group-field">
                                            <button
                                              type="button"
                                              className={`form__form-group-button ${gstText === 'Verified' || isAdmin || kycStatusKey ? '' : 'active'
                                                }`}
                                              disabled={
                                                gstText === 'Verified' || isAdmin || kycStatusKey ? true : false
                                              }
                                              onClick={() => {
                                                formikBag.values.eKyc = 'gst';
                                                (formikBag.values.document_name = display_member),
                                                  formikBag.handleSubmit(formikBag.values, formikBag);
                                              }}>
                                              {gstText}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="form-group-field">
                                  <div className="notes-block">
                                    <p className="redbg mt-25">
                                      The GST Number entered here should match either the Registered Address or
                                      Operating Address
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Collapse>

                    <Collapse title="Bank Details" className="with-shadow">
                      <Card>
                        <CardBody>
                          <BankForm
                            entity_id={entity_id}
                            company_affiliate_enum={company_affiliate_enum}
                            isAdmin={isAdmin}
                            kycStatusKey={kycStatusKey}
                            id={id}
                            business_type_enum={values.business_type_enum || businessType}
                            selectedUser={display_member}
                          />
                        </CardBody>
                      </Card>
                    </Collapse>
                    {userRole == 2 && (
                      <Collapse title="Driver Identity" className="with-shadow">
                        <Card>
                          <CardBody>
                            <div className="row form__form-group">
                              <div className="col-md-4">
                                <span className="form-group-label">First Name</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <Field
                                    name="first_name"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <span className="form-group-label">Middle Name</span>
                                <div className="form-group-field">
                                  <Field
                                    name="middle_name"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form__form-group">
                              <div className="col-md-4">
                                <span className="form-group-label">Last Name</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <Field
                                    name="last_name"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group-field">
                                  <span className="form-group-label">Mobile Number</span>
                                  <span class="required">*</span>
                                  <Field
                                    name="mobile_number"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form__form-group">
                              <div className="col-md-4">
                                <span className="form-group-label">Date of Birth</span>
                                <span class="required">*</span>
                                <div className="form-group-field driver-dob custom-input">
                                  <Field
                                    name="dob"
                                    component={FormikDatePickerField}
                                    defaultValue={values.dob}
                                    disabled={isAdmin || kycStatusKey}
                                    showYearDropdown={true}
                                    showMonthDropdown={true}
                                  />
                                  <img src={calendarIcon} className="datepicker-calendar-icon" />
                                </div>
                                <div className="form-group-field">
                                  <div className="notes-block">
                                    <p className="redbg mt-10">
                                      We only need this to verify your identity using the Driving Licence number
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Driving Licence</span>
                                <span class="required">*</span>
                                <div className="form-group-field with-verified__btn">
                                  <div className="row no-gutters">
                                    <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                      <Field
                                        name="license_number"
                                        component={FormikMaterialTextField}
                                        disabled={isAdmin || kycStatusKey}
                                      />
                                    </div>
                                    <div className="col-3 col-sm-2 col-md-2">
                                      {values.license_number && (
                                        <div className="">
                                          <div className="form-group-field">
                                            <button
                                              type="button"
                                              className={`form__form-group-button ${licenseText === 'Verified' ? '' : 'active'
                                                }`}
                                              disabled={licenseText === 'Verified' || isAdmin || kycStatusKey}
                                              onClick={() => {
                                                formikBag.values.eKyc = 'DriverLicense';
                                                handleEKyc(formikBag.values, formikBag);
                                              }}>
                                              {licenseText}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    )}
                  </CardBody>
                </Card>

                <div className="btn-wrapper justify-content-space-between">
                  <div className="">
                    <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                      Prev
                    </NavLink>
                  </div>
                  {!isAdmin && !kycStatusKey && (
                    <div>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          formikBag.values.eKyc = '';
                          formikBag.handleSubmit(formikBag.values, formikBag);
                        }}
                        disabled={formikBag.isSubmitting}
                        className="next bfc-button-next">
                        {formikBag.isSubmitting && (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        )}{' '}
                        Save
                      </Button>
                    </div>
                  )}
                  <div className="">
                    <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} color="link">
                      Next
                    </NavLink>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
};

export default KycForm;
