import _ from 'lodash';
import * as api from './api';

export const fetchDiscoveryBuyersSellers = async (params = {}, dispatch) => {
  try {
    let res = await api.fetchDiscoveryBuyersSellers(params, dispatch);
    return res;
  } catch (err) {
    throw err;
  }
};

export const connectBuySell = async (params = {}) => {
  try {
    let res = await api.connectProfile(params);
    return res;
  } catch (err) {
    throw err;
  }
};
