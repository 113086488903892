import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const storageSchema = yup.object().shape({
  // storage_level_id: yup.number().required('Please select Location').positive('Please select Location'),
  max_capacity: yup.number().required('Max Capacity is required').min(0, 'Max Capacity is required'),
  min_capacity: yup.number().required('Min Capacity is required').min(0, 'Min Capacity is required'),
});

export const consumeSchemaForAdjustment = yup.object().shape({
  stock_in_out_type_enum: yup.number().required('Please select Stock Type').positive('Please select Stock Type'),
  commodity_id: yup.number().required('Please select commodity').positive('Please select commodity'),
  // commodity_form_id: yup.number().required('Please select Form').positive('Please select Form'),
  commodity_residue_id: yup.number().required('Please select Residue').positive('Please select Residue'),
  // quantity: yup.number().required('Quantity is required').min(1, 'Quantity is required'),
  quantity: yup.number().required('Quantity is required'),
  transfer_datetime: yup.date().required('Please select Date and Time'),
  // from_date: yup.date().required('Please select From Date'),
  // to_date: yup.date().required('Please select To Date'),
});

export const consumeSchemaForMoveInOut = yup.object().shape({
  stock_in_out_type_enum: yup.number().required('Please select Stock Type').positive('Please select Stock Type'),
  commodity_id: yup.number().required('Please select commodity').positive('Please select commodity'),
  // commodity_form_id: yup.number().required('Please select Form').positive('Please select Form'),
  commodity_residue_id: yup.number().required('Please select Residue').positive('Please select Residue'),
  quantity: yup.number().required('Quantity is required').min(1, 'Quantity is required'),
  transfer_datetime: yup.date().required('Please select Date and Time'),
  // from_date: yup.date().required('Please select From Date'),
  // to_date: yup.date().required('Please select To Date'),
});

export const quickTransferSchema = yup.object().shape({
  commodity_id: yup.number().required('Please Select commodity').positive('Please Select commodity'),
  commodity_form_id: yup.number().required('Please Select Form').positive('Please Select Form'),
  commodity_residue_id: yup.number().required('Please Select Residue').positive('Please Select Residue'),
  from_address_id: yup.string().required('Please Select From Location'),
  to_address_id: yup.string().required('Please Select To Location'),
  transfer_price: yup.string().required('Please Enter Transfer Price'),
  transfer_out_qty: yup.number().required('Move Out is required').min(1, 'Move Out is required'),
  transfer_in_qty: yup.number().required('Move In is required').min(1, 'Move In is required'),
  transfer_in_date: yup.string().required('Please select move in date'),
  transfer_out_date: yup.string().required('Please select move out date'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetailsList = async (id) => {
  try {
    let res = await api.fetchDetailsList(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getStoragePlans = async (params) => {
  try {
    let res = await api.getStoragePlans(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(storageSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const consumeProduceCreate = async (params) => {
  try {
    await helpers.validateSchema(
      params?.stock_in_out_type_enum === 2 ? consumeSchemaForAdjustment : consumeSchemaForMoveInOut,
      params,
    );
    const res = await api.consumeProduceCreate(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const consumeProduceUpdate = async (params) => {
  try {
    await helpers.validateSchema(
      params?.stock_in_out_type_enum === 2 ? consumeSchemaForAdjustment : consumeSchemaForMoveInOut,
      params,
    );
    const res = await api.consumeProduceUpdate(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const consumeProduceDelete = async (params) => {
  try {
    const res = await api.consumeProduceDelete(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(storageSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchStorageLevels = async (params = {}) => {
  try {
    const res = await api.fetchStorageLevels(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateStoragePlan = async (params = {}) => {
  try {
    const res = await api.saveUpdateStoragePlan(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteStoragePlan = async (params = {}) => {
  try {
    const res = await api.deleteStoragePlan(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteStoragePlanDetail = async (params = {}) => {
  try {
    const res = await api.deleteStoragePlanDetail(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const stockTransferQuick = async (params = {}) => {
  try {
    await helpers.validateSchema(quickTransferSchema, params);
    const res = await api.stockTransferQuick(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchStockTransfers = async (params = {}) => {
  try {
    const res = await api.fetchStockTransfers(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const stockTransferWithTransport = async (params = {}) => {
  try {
    const res = await api.stockTransferWithTransport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTransportationChargeForST = async (params = {}) => {
  try {
    const res = await api.fetchTransportationChargeForST(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchQualitySpecsAgainstStockTransferId = async (params = {}) => {
  try {
    const res = await api.fetchQualitySpecsAgainstStockTransferId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchStockAgainstAddressId = async (params = {}) => {
  try {
    const res = await api.fetchStockAgainstAddressId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteQuickStockTransfer = async (params = {}) => {
  try {
    const res = await api.deleteQuickStockTransfer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteStockTransferWithTransport = async (params = {}) => {
  try {
    const res = await api.deleteStockTransferWithTransport(params);
    return res;
  } catch (err) {
    throw err;
  }
};
