import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const qs = reqTransform.fetchRolesRightsList(params);
    let url;
    if (!_.isEmpty(qs)) {
      url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/FetchRolesAndRights?${qs}`;
    } else {
      url = `${baseUrl}/BioFuelCommonUtilities/api/Filter/FetchRolesAndRights`;
    }
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchRolesAndRights-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchRolesAndRights');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['role_module_permission_id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};
