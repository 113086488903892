import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const fetchPickupBuyOffers = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchPickupBuyOffers`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchPickupBuyOffers');
    throw errors;
  }
};
