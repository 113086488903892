import OverviewHeader from 'common/OverviewHeader';
import React from 'react';
import MUITextField from './TextField';
import { Table } from 'antd';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIAutocomplete from './Autocomplete';
import MUIDatePicker from './DateTimePicker';
import MUIButton from './Button';
import { EditRounded } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import MoodIcon from '@material-ui/icons/Mood';
import MUIToggleControl from './ToggleControl';
import MUIModal from './Modal';
import MUITimeline from './Timeline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: '20px !important',
      fontWeight: 'bold',
      letterSpacing: '1px !important',
    },
  }),
);

const MUIDemo = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [open, setOpen] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const TABLE_HEADERS = [
    {
      title: 'Parameter',
      dataIndex: 'param',
      width: '150px',
      fixed: true,
    },
    {
      title: 'Type/Possible Values',
      dataIndex: 'value',
      width: '300px',
    },
    {
      title: 'Default Value',
      dataIndex: 'default',
      width: '150px',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];
  const tableData = [
    {
      key: '1',
      param: 'size',
      value: "'small' | 'medium'",
      default: 'small',
      description: 'The size of the input field',
    },
    {
      key: '2',
      param: 'variant',
      value: "'outlined' | 'standard' | 'filled'",
      default: 'outlined',
      description: 'The variant of the input field',
    },
    {
      key: '3',
      param: 'color',
      value: "'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string",
      default: 'muiPrimary',
      description: 'The color of the input field',
    },
    {
      key: '4',
      param: 'fieldType',
      value:
        "'text' | 'textarea' | 'numeric' | 'plainNumeric' | 'alphabetic' | 'alphanumeric' | 'percent' | 'price' | 'qty' | 'email' | 'mobile'",
      default: 'text',
      description: 'The type of the input field',
    },
    {
      key: '5',
      param: 'uomType',
      value: "'text' | 'dropdown'",
      default: 'text',
      description: 'The type of the unit of measure input field',
    },
    {
      key: '6',
      param: 'dropdownData',
      value: '[{}]',
      default: 'N/A',
      description: 'An array of objects containing dropdown data',
    },
    {
      key: '7',
      param: 'dropdownSelectId',
      value: 'number',
      default: 'N/A',
      description: 'The ID of the selected dropdown item',
    },
    {
      key: '8',
      param: 'dropdownDataStructure',
      value: '{ labelApiParam: string; valueApiParam: string | number }',
      default: 'N/A',
      description: 'The structure of the dropdown data',
    },
    {
      key: '9',
      param: 'handelDropdownChange',
      value: '(value: any) => void',
      default: 'N/A',
      description: 'A function to handle dropdown changes',
    },
    {
      key: '10',
      param: 'value',
      value: 'string | number',
      default: 'N/A',
      description: 'The value of the input field',
    },
    {
      key: '11',
      param: 'label',
      value: 'string',
      default: 'N/A',
      description: 'The label of the input field',
    },
    {
      key: '12',
      param: 'placeholder',
      value: 'string',
      default: 'N/A',
      description: 'The placeholder of the input field',
    },
    {
      key: '13',
      param: 'disabled',
      value: 'boolean',
      default: 'false',
      description: 'Whether the input field is disabled or not',
    },
    {
      key: '14',
      param: 'maxRows',
      value: 'number',
      default: '4',
      description: 'The maximum number of rows for a textarea input field',
    },
    {
      param: 'fullWidth',
      value: 'boolean',
      default: 'true',
      description: 'If true, the input will take up the full width of its container.',
    },
    {
      param: 'StartIcon',
      value: 'ElementType | string',
      default: 'N/A',
      description: 'An icon to display before the input.',
    },
    {
      param: 'EndIcon',
      value: 'ElementType | string',
      default: 'N/A',
      description: 'An icon to display after the input.',
    },
    {
      param: 'field',
      value: 'Formik Field',
      default: 'N/A',
      description: 'The `Field` object provided by `formik`.',
    },
    {
      param: 'form',
      value: 'Formik Form',
      default: 'N/A',
      description: 'The `Formik` object.',
    },
    {
      param: 'required',
      value: 'boolean',
      default: 'false',
      description: 'If true, the input is required.',
    },
    {
      param: 'qtyPrecision',
      value: 'number',
      default: '3',
      description: 'The number of decimal places to display for quantity inputs.',
    },
    {
      param: 'pricePrecision',
      value: 'number',
      default: '2',
      description: 'The number of decimal places to display for price inputs.',
    },
    {
      param: 'isError',
      value: 'boolean',
      default: 'false',
      description: 'If true, the input has an error.',
    },
    {
      param: 'errorMsg',
      value: 'string',
      default: 'N/A',
      description: 'The error message to display for the input.',
    },
    {
      param: 'onChange',
      value: '(value: any) => void',
      default: 'N/A',
      description: 'A function to call when the input value changes.',
    },
    {
      param: 'inputRef',
      value: 'any',
      default: 'N/A',
      description: 'A ref to the input element.',
    },
  ];

  const AutoCompleteDataSource = [
    {
      param: 'type',
      value: "'normal' | 'multiselect' | 'search'",
      default: 'normal',
      description: 'The type of the component.',
    },
    {
      param: 'size',
      value: "'small' | 'medium'",
      default: 'small',
      description: 'The size of the component.',
    },
    {
      param: 'variant',
      value: "'outlined' | 'standard' | 'filled'",
      default: 'outlined',
      description: 'The variant of the component.',
    },
    {
      param: 'color',
      value: "'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string",
      default: 'muiPrimary',
      description: 'The color of the component.',
    },
    {
      param: 'data',
      value: '[{}]',
      default: 'N/A',
      description: 'The data for the component.',
    },
    {
      param: 'selectedCodeOrId',
      value: 'string | number',
      default: 'N/A',
      description: 'The selected code or ID of the component.',
    },
    {
      param: 'dataStructure',
      value: '{ labelApiParam: string; valueApiParam: string | number }',
      default: 'N/A',
      description: 'The structure of the data.',
    },
    {
      param: 'apiParams',
      value: '{ reqUrl: string; method: string; isQueryParams?: boolean; getReqParams?: (value: string) => {} }',
      default: 'N/A',
      description: 'The API parameters for the component.',
    },
    {
      param: 'loading',
      value: 'boolean',
      default: 'false',
      description: 'Whether the component is in a loading state.',
    },
    {
      param: 'label',
      value: 'string',
      default: 'N/A',
      description: 'The label for the component.',
    },
    {
      param: 'placeholder',
      value: 'string',
      default: 'N/A',
      description: 'The placeholder for the component.',
    },
    {
      param: 'disabled',
      value: 'boolean',
      default: 'false',
      description: 'Whether the component is disabled.',
    },
    {
      param: 'fullWidth',
      value: 'boolean',
      default: 'true',
      description: 'Whether the component should be full-width.',
    },
    {
      param: 'StartIcon',
      value: 'ElementType | string',
      default: 'N/A',
      description: 'The start icon for the component.',
    },
    {
      param: 'EndIcon',
      value: 'ElementType | string',
      default: 'N/A',
      description: 'The end icon for the component.',
    },
    {
      param: 'field',
      value: 'any',
      default: 'N/A',
      description: 'The field for the component.',
    },
    {
      param: 'form',
      value: 'any',
      default: 'N/A',
      description: 'The form for the component.',
    },
    {
      param: 'isError',
      value: 'boolean',
      default: 'false',
      description: 'Whether the component has an error.',
    },
    {
      param: 'errorMsg',
      value: 'string',
      default: 'N/A',
      description: 'The error message for the component.',
    },
    {
      param: 'onChange',
      value: '(value: any) => void',
      default: 'N/A',
      description: 'The callback',
    },
    {
      param: 'generateEndContent',
      value: '(value: any) => HTMLElement',
      default: 'N/A',
      description: 'The callback',
    },
    {
      param: 'getSanitizedData',
      value: '(value: [{}]) => [{}]',
      default: 'N/A',
      description: 'The callback',
    },
    {
      param: 'onInputChange',
      value: '(value: string, reason: input | reset | clear) => void',
      default: 'N/A',
      description: 'This callback is called when the input value changes or cleared',
    },
  ];

  const datePickerDataSource = [
    {
      param: 'type',
      value: 'date | range | time | date-time',
      default: 'date',
      description: 'Type of picker to be rendered.',
    },
    {
      param: 'pickerType',
      value: 'date | month | year | quarter',
      default: 'date',
      description: 'Type of picker to be rendered for date and range types.',
    },
    {
      param: 'label',
      value: 'string',
      default: 'N/A',
      description: 'Label to be displayed for the input element.',
    },
    {
      param: 'monthsShown',
      value: 'number',
      default: '1',
      description: 'Number of months to be displayed in the picker.',
    },
    {
      param: 'timeIntervals',
      value: 'number',
      default: '15',
      description: 'Time intervals in minutes for time picker type.',
    },
    {
      param: 'showMonthDropdown',
      value: 'boolean',
      default: 'true',
      description: 'Flag to display the month dropdown in the picker.',
    },
    {
      param: 'showYearDropdown',
      value: 'boolean',
      default: 'true',
      description: 'Flag to display the year dropdown in the picker.',
    },
    {
      param: 'popperPlacement',
      value:
        "'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end'",
      default: 'N/A',
      description: 'Placement of the picker popup.',
    },
    {
      param: 'form',
      value: 'any',
      default: 'N/A',
      description: 'Reference to the form element to which this input belongs.',
    },
    {
      param: 'field',
      value: 'any',
      default: 'N/A',
      description: 'Reference to the field element of the form.',
    },
    {
      param: 'value',
      value: 'Date | string | { start: Date | string; end: Date | string }',
      default: 'N/A',
      description: 'Value of the input element.',
    },
    {
      param: 'onChange',
      value: '(value: Date | string | [Date | string, Date | string]) => void',
      default: 'N/A',
      description: 'Callback function to be called on change of the input element.',
    },
  ];

  const buttonDataSource = [
    {
      param: 'type',
      value: "'normal' | 'icon' | 'group' | 'buttonDropdown' | 'iconDropdown'",
      default: 'normal',
      description: 'Specifies the type of the button component.',
    },
    {
      param: 'size',
      value: "'small' | 'medium' | 'large'",
      default: 'medium',
      description: 'Specifies the size of the button component.',
    },
    {
      param: 'variant',
      value: "'contained' | 'outlined' | 'text'",
      default: 'contained',
      description: 'Specifies the variant of the button component.',
    },
    {
      param: 'color',
      value: "'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string",
      default: 'muiPrimary',
      description: 'Specifies the color of the button component.',
    },
    {
      param: 'onClick',
      value: 'Function',
      default: 'N/A',
      description: 'Function that will be called when the button is clicked.',
    },
    {
      param: 'startIcon',
      value: 'React.ElementType | string',
      default: 'N/A',
      description: 'Specifies the start icon for the button component.',
    },
    {
      param: 'endIcon',
      value: 'React.ElementType | string',
      default: 'N/A',
      description: 'Specifies the end icon for the button component.',
    },
    {
      param: 'disabled',
      value: 'boolean',
      default: 'false',
      description: 'Specifies whether the button component is disabled or not.',
    },
    {
      param: 'linkUrl',
      value: 'string',
      default: 'N/A',
      description:
        'Specifies the link URL for the button component. When this prop is specified, the button will be rendered as a link.',
    },
    {
      param: 'children',
      value: 'React.ElementType | string',
      default: 'N/A',
      description: 'Specifies the content of the button component.',
    },
    {
      param: 'options',
      value: '[{ label: string; disabled: boolean }]',
      default: 'N/A',
      description:
        "An array of options to be used with the 'group' and 'buttonDropdown' types. Each option has a 'label' and 'disabled' property.",
    },
    {
      param: 'indexForPrimaryBtn',
      value: 'number',
      default: '0',
      description: "The index of the primary button in the 'group' type.",
    },
    {
      param: 'disableMenuItemIndex',
      value: 'number',
      default: 'N/A',
      description: "The index of the option to be disabled in the 'buttonDropdown' type.",
    },
    {
      param: 'Icon',
      value: 'React.ElementType | string',
      default: 'N/A',
      description: "Specifies the icon for the 'icon' and 'iconDropdown' types.",
    },
    {
      param: 'iconBtnSize',
      value: "'small' | 'medium'",
      default: 'medium',
      description: "Specifies the size of the icon button for the 'icon' and 'iconDropdown' types.",
    },
  ];

  const toggleControlDataSource = [
    {
      param: 'control',
      value: "'checkbox' | 'switch' | 'radio'",
      default: 'checkbox',
      description: 'The type of control to render',
    },
    {
      param: 'type',
      value: "'single' | 'group'",
      default: 'single',
      description: 'The type of control group to render',
    },
    {
      param: 'label',
      value: 'string',
      default: '',
      description: 'The label to display next to the control',
    },
    {
      param: 'labelPlacement',
      value: "'top' | 'bottom' | 'start' | 'end'",
      default: 'end',
      description: 'The position of the label relative to the control',
    },
    {
      param: 'size',
      value: "'small' | 'medium'",
      default: 'medium',
      description: 'The size of the control',
    },
    {
      param: 'color',
      value: 'string',
      default: '',
      description: 'The color of the control',
    },
    {
      param: 'Icon',
      value: 'React.ElementType',
      default: '',
      description: 'The icon to display for the control',
    },
    {
      param: 'CheckedIcon',
      value: 'React.ElementType',
      default: '',
      description: 'The icon to display when the control is checked',
    },
    {
      param: 'disabled',
      value: 'boolean',
      default: 'false',
      description: 'Whether the control is disabled or not',
    },
    {
      param: 'onChange',
      value: '(e: React.ChangeEvent<HTMLInputElement>, checked: boolean | string, name: string) => void',
      default: '',
      description: 'The function to call when the control is changed',
    },
    {
      param: 'options',
      value: '[{ label: string; checked: boolean; disabled: boolean }]',
      default: '',
      description: 'The options to display for the control group',
    },
    {
      param: 'orientation',
      value: "'row' | 'column'",
      default: 'column',
      description: 'The orientation of the control group',
    },
    {
      param: 'field',
      value: 'any',
      default: '',
      description: 'The field object provided by Formik',
    },
    {
      param: 'form',
      value: 'any',
      default: '',
      description: 'The form object provided by Formik',
    },
    {
      param: 'value',
      value: 'boolean | string | [{}]',
      default: '',
      description: 'The value of the control',
    },
  ];

  const modalDataSource = [
    {
      param: 'type',
      value: "'confirmation' | 'modal'",
      default: "'confirmation'",
      description: 'Defines the type of dialog box to be displayed.',
    },
    {
      param: 'confirmationType',
      value: "'info' | 'error'",
      default: "'info'",
      description: 'Specifies the type of confirmation dialog box.',
    },
    {
      param: 'open',
      value: 'boolean',
      default: 'N/A',
      description: 'Controls the visibility of the dialog box.',
    },
    {
      param: 'setOpen',
      value: 'Function',
      default: 'N/A',
      description: 'Sets the state of the dialog box visibility.',
    },
    {
      param: 'title',
      value: 'string',
      default: 'N/A',
      description: 'Specifies the title of the dialog box.',
    },
    {
      param: 'Icon',
      value: 'React.ElementType | string',
      default: 'N/A',
      description: 'Specifies the icon to be displayed on the dialog box.',
    },
    {
      param: 'size',
      value: "'xs' | 'sm' | 'md' | 'lg' | 'xl'",
      default: "type === 'confirmation' ? 'xs' : 'sm'",
      description: 'Specifies the size of the dialog box.',
    },
    {
      param: 'busy',
      value: 'boolean',
      default: 'N/A',
      description: 'Specifies whether the dialog box is busy or not.',
    },
    {
      param: 'children',
      value: 'React.ReactElement',
      default: 'N/A',
      description: 'Specifies the content of the dialog box.',
    },
    {
      param: 'actionButtons',
      value: "[{ name: string; onClick: () => {}; variant?: 'contained' | 'outlined' | 'text' }]",
      default: 'N/A',
      description: 'Specifies the action buttons to be displayed on the dialog box.',
    },
    {
      param: 'confirmationMessage',
      value: 'string',
      default: 'N/A',
      description: 'Specifies the confirmation message to be displayed on the dialog box.',
    },
  ];

  const timelineDataSource = [
    { param: 'data', value: '[{}]', default: '', description: 'Data array containing the timeline items' },
    {
      param: 'align',
      value: 'left | right | alternate',
      default: 'left',
      description: 'Alignment of timeline items',
    },
    {
      param: 'themeColor',
      value: 'old | primary | muiPrimary | secondary | ternary',
      default: 'muiPrimary',
      description: 'Color theme of the timeline',
    },
    {
      param: 'timelineDotVariant',
      value: 'outlined | default',
      default: 'outlined',
      description: 'Variant of timeline dots',
    },
    {
      param: 'Icon',
      value: 'ElementType | string',
      default: '',
      description: 'Icon displayed next to timeline dots',
    },
    {
      param: 'isAvatar',
      value: 'boolean',
      default: 'true',
      description: 'Whether to display an avatar image next to timeline items',
    },
    {
      param: 'generateChildContent',
      value: '(value: any) => ReactNode',
      default: '',
      description: 'Function to generate child content for each timeline item',
    },
    {
      param: 'fetchMoreData',
      value: '() => any',
      default: '',
      description: 'Function to fetch more data for infinite scrolling',
    },
    {
      param: 'scrollHeight',
      value: 'string',
      default: 'auto',
      description: 'Height of the scrollable area for infinite scrolling',
    },
    {
      param: 'apiDateParam',
      value: 'string',
      default: 'date_time',
      description: 'API parameter for timeline item date',
    },
    {
      param: 'apiAvatarImageParam',
      value: 'string',
      default: 'image_url',
      description: 'API parameter for timeline item avatar image',
    },
    {
      param: 'apiUsernameParam',
      value: 'string',
      default: 'person_name',
      description: 'API parameter for timeline item username',
    },
    {
      param: 'apiHeadingParam',
      value: 'string',
      default: 'person_company_affiliate_name',
      description: 'API parameter for timeline item heading',
    },
  ];

  const historyData = [
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 151,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 2.5,
      notes:
        'Test this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-03-03T18:34:29.257',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 141,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 2.5,
      notes:
        'Test this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is testTest this is test this is test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-03-03T18:34:29.257',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 140,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 2.5,
      notes:
        'Test dfsdfsdfsdfsdfdsfdsfsdfdsfdfsdfsdfsdfdsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfdsfsdfsdfdsfsdfsdfdsfsdfdsfdsfsfsdfsdfsdfdsfsdfsdfsdfsdfsdfd',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-24T16:27:55.497',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 126,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 2.5,
      notes: 'Test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-24T16:26:45.463',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 125,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 2,
      notes: 'Test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-17T12:27:31.52',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 122,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 3.5,
      notes: 'Test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-17T12:25:31.043',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 121,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 3.5,
      notes: 'Test',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-15T11:08:47.963',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 120,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 3.5,
      notes: 'NA',
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-15T11:07:01.693',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 119,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 3.5,
      notes: null,
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-14T19:33:03.94',
      initial_adjustment_factor: 5.6,
    },
    {
      total_count: 21,
      early_payment_adjustment_factor_id: 114,
      person_company_affiliate_id: 1330,
      person_company_affiliate_enum: 3,
      adjustment_factor: 20,
      notes: null,
      person_company_affiliate_name: 'Zeniac_Aff',
      person_name: 'Madhuri CT',
      date_time: '2023-02-07T20:05:07.343',
      initial_adjustment_factor: 5.6,
    },
  ];

  return (
    <>
      <OverviewHeader heading={`Material UI Components Guideline`} />
      <div className="invoice-table bfc-body p-3">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Text Field</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField size="medium" label="Medium Size Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField size="small" label="Small Size Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="alphabetic" size="small" label="Alphabetic Text Field" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="alphanumeric" size="small" label="Alphanumeric Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="email" size="small" label="Email Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="mobile" size="small" label="Mobile Text Field" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="numeric" size="small" label="Numeric Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="percent" size="small" label="Percent Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="plainNumeric" size="small" label="PlainNumeric Text Field" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="price" size="small" label="Price Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="qty" size="small" label="Qty Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField fieldType="textarea" size="small" label="Textarea Text Field" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField variant="standard" label="Standard Variant Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField label="Outlined Variant Text Field" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUITextField variant="filled" label="Filled Text Field" />
                </div>
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={tableData} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Autocomplete</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    label="Normal small Outlined"
                    dataStructure={{
                      labelApiParam: 'config_display_text',
                      valueApiParam: 'config_value',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {},
                        offset: 0,
                        page_size: 10000,
                      }),
                    }}
                    getSanitizedData={(data) => data?.filter((item) => item?.['can_edit'] === true) as []}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    size="medium"
                    label="Normal Medium Outlined"
                    dataStructure={{
                      labelApiParam: 'config_display_text',
                      valueApiParam: 'config_value',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {},
                        offset: 0,
                        page_size: 10000,
                      }),
                    }}
                    getSanitizedData={(data) => data?.filter((item) => item?.['can_edit'] === true) as []}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    variant="filled"
                    label="Normal standard"
                    dataStructure={{
                      labelApiParam: 'config_display_text',
                      valueApiParam: 'config_value',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {},
                        offset: 0,
                        page_size: 10000,
                      }),
                    }}
                    getSanitizedData={(data) => data?.filter((item) => item?.['can_edit'] === true) as []}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    variant="standard"
                    label="Normal standard"
                    dataStructure={{
                      labelApiParam: 'config_display_text',
                      valueApiParam: 'config_value',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {},
                        offset: 0,
                        page_size: 10000,
                      }),
                    }}
                    getSanitizedData={(data) => data?.filter((item) => item?.['can_edit'] === true) as []}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    type="multiselect"
                    label="Multiselect Outlined"
                    dataStructure={{
                      labelApiParam: 'config_display_text',
                      valueApiParam: 'config_value',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {},
                        offset: 0,
                        page_size: 10000,
                      }),
                    }}
                    getSanitizedData={(data) => data?.filter((item) => item?.['can_edit'] === true) as []}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIAutocomplete
                    type="search"
                    dataStructure={{
                      labelApiParam: 'person_company_affiliate_code',
                      valueApiParam: 'person_company_affiliate_id',
                    }}
                    apiParams={{
                      reqUrl: `BioFuelPayments/api/EarlyPayment/FetchCompanyAffiliateForEarlyPayment`,
                      method: 'POST',
                      getReqParams: (newVal) => ({
                        filters: {
                          person_company_affiliate_code: newVal,
                        },
                      }),
                    }}
                    onChange={(newValue) => {}}
                    data={[]}
                    selectedCodeOrId={''}
                  />
                </div>
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={AutoCompleteDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Date Time Picker</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker label="Date Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="date-time" label="Date Time Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="range" label="Date Range Picker" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="time" label="Time Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker pickerType="month" label="Month Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker pickerType="year" label="Year Picker" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker pickerType="quarter" label="Quarter Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="range" pickerType="month" label="Month Range Picker" />
                </div>
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="range" pickerType="year" label="Year Range Picker" />
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col-md-6 col-lg-5 col-xl-4">
                  <MUIDatePicker type="range" pickerType="quarter" label="Quarter Range Picker" />
                </div>
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={datePickerDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Button</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="row form__form-group">
                <div className="m-3">
                  <MUIButton onClick={() => {}}>Contained Button</MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton variant="outlined" onClick={() => {}}>
                    Outlined Button
                  </MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton variant="text" onClick={() => {}}>
                    Text Button
                  </MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton size="small" onClick={() => {}}>
                    Small Contained Button
                  </MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton size="large" onClick={() => {}}>
                    Large Contained Button
                  </MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton startIcon={<EditRounded />} onClick={() => {}}>
                    Button With StartIcon
                  </MUIButton>
                </div>
                <div className="m-3">
                  <MUIButton endIcon={<EditRounded />} onClick={() => {}}>
                    Button With EndIcon
                  </MUIButton>
                </div>
                <div className="m-3">
                  <Tooltip arrow title="Icon Button">
                    <MUIButton type="icon" variant="text" Icon={EditRounded} onClick={() => {}} />
                  </Tooltip>
                </div>
                <div className="m-3">
                  <MUIButton
                    type="group"
                    options={[
                      { label: 'Contained', disabled: false },
                      { label: 'Group', disabled: false },
                      { label: 'Button', disabled: false },
                    ]}
                    onClick={() => {}}
                  />
                </div>
                <div className="m-3">
                  <MUIButton
                    type="group"
                    variant="outlined"
                    options={[
                      { label: 'Outlined', disabled: false },
                      { label: 'Group', disabled: false },
                      { label: 'Button', disabled: false },
                    ]}
                    onClick={() => {}}
                  />
                </div>
                <div className="m-3">
                  <MUIButton
                    type="group"
                    variant="text"
                    options={[
                      { label: 'Text', disabled: false },
                      { label: 'Group', disabled: false },
                      { label: 'Button', disabled: false },
                    ]}
                    onClick={() => {}}
                  />
                </div>
                <div className="m-3">
                  <MUIButton
                    type="buttonDropdown"
                    options={[
                      { label: 'Contained', disabled: false },
                      { label: 'Button', disabled: false },
                      { label: 'Dropdown', disabled: false },
                    ]}
                    onClick={() => {}}
                  />
                </div>
                <div className="m-3">
                  <MUIButton
                    type="iconDropdown"
                    variant="text"
                    options={[
                      { label: 'Icon', disabled: false },
                      { label: 'Button', disabled: false },
                      { label: 'Dropdown', disabled: false },
                    ]}
                    onClick={() => {}}
                  />
                </div>
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={buttonDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Toggle Controls</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <MUIToggleControl
                size="medium"
                control="checkbox"
                type="single"
                label="Single Medium Checkbox"
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                control="checkbox"
                type="single"
                label="Single Small Checkbox"
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                size="medium"
                control="radio"
                type="single"
                label="Single Medium Radio"
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl control="radio" type="single" label="Single Small Radio" onChange={() => {}} value="" />
              <MUIToggleControl
                control="switch"
                size="medium"
                type="single"
                label="Single Medium Switch"
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                control="switch"
                type="single"
                label="Single Small Switch"
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                control="checkbox"
                type="group"
                options={[
                  { label: 'Group Checkbox 1', checked: false, disabled: false },
                  { label: 'Group Checkbox 2 ', checked: true, disabled: false },
                  { label: 'Group Disabled Checkbox 3', checked: false, disabled: true },
                ]}
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                control="radio"
                type="group"
                options={[
                  { label: 'Group Radio 1', checked: false, disabled: false },
                  { label: 'Group Radio 2 ', checked: true, disabled: false },
                  { label: 'Group Disabled Radio 3', checked: false, disabled: true },
                ]}
                onChange={() => {}}
                value=""
              />
              <MUIToggleControl
                control="switch"
                type="group"
                options={[
                  { label: 'Group Switch 1', checked: false, disabled: false },
                  { label: 'Group Switch 2 ', checked: true, disabled: false },
                  { label: 'Group Disabled Switch 3', checked: false, disabled: true },
                ]}
                onChange={() => {}}
                value=""
              />
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={toggleControlDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Modal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="m-3">
                <MUIButton className="m-3" onClick={() => setOpen('modal1')}>
                  Open Small Modal
                </MUIButton>
                <MUIModal
                  open={open === 'modal1'}
                  setOpen={setOpen}
                  title="Open Small Modal"
                  type="modal"
                  actionButtons={[
                    { name: 'Save', onClick: () => {} },
                    { name: 'Cancel', onClick: () => setOpen(false) },
                  ]}
                >
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal2')}>
                  Open Extra Small Modal
                </MUIButton>
                <MUIModal
                  size="xs"
                  open={open === 'modal2'}
                  setOpen={setOpen}
                  title="Open Extra Small Modal"
                  type="modal"
                  actionButtons={[
                    { name: 'Save', onClick: () => {} },
                    { name: 'Cancel', onClick: () => setOpen(false) },
                  ]}
                >
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal3')}>
                  Open Medium Modal
                </MUIButton>
                <MUIModal size="md" open={open === 'modal3'} setOpen={setOpen} title="Open Medium Modal" type="modal">
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal4')}>
                  Open Large Modal
                </MUIButton>
                <MUIModal size="lg" open={open === 'modal4'} setOpen={setOpen} title="Open Large Modal" type="modal">
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal4')}>
                  Open Extra Large Modal
                </MUIButton>
                <MUIModal
                  size="xl"
                  open={open === 'modal4'}
                  setOpen={setOpen}
                  title="Open Extra Large Modal"
                  type="modal"
                >
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal5')}>
                  Open Confirmation Modal
                </MUIButton>
                <MUIModal
                  open={open === 'modal5'}
                  setOpen={setOpen}
                  title="Open Confirmation Modal"
                  type="confirmation"
                  actionButtons={[
                    { name: 'Confirm', onClick: () => {} },
                    { name: 'Cancel', onClick: () => setOpen(false) },
                  ]}
                >
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
                <MUIButton className="m-3" onClick={() => setOpen('modal6')}>
                  Open Warning Confirmation Modal
                </MUIButton>
                <MUIModal
                  open={open === 'modal6'}
                  setOpen={setOpen}
                  title="Open Warning Confirmation Modal"
                  type="confirmation"
                  confirmationType="error"
                  confirmationMessage="Are you sure you want to proceed?"
                  actionButtons={[
                    { name: 'Confirm', onClick: () => {} },
                    { name: 'Cancel', onClick: () => setOpen(false) },
                  ]}
                >
                  <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Possimus vero quod quisquam voluptatum
                    blanditiis, sint qui soluta libero fugiat, perspiciatis numquam, suscipit perferendis amet
                    laboriosam quia ea sunt aliquid impedit!
                  </div>
                </MUIModal>
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={modalDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Timeline</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <MUITimeline
                    align="left"
                    timelineDotVariant="default"
                    data={historyData}
                    generateChildContent={(item) => (
                      <div style={{ letterSpacing: 0.5 }}>
                        <strong className="d-block">{item.adjustment_factor}%</strong>
                        <span className="d-block">Initially set to {item.initial_adjustment_factor}%</span>
                      </div>
                    )}
                    fetchMoreData={() => {}}
                  />
                </div>
                <div className="col-md-6">
                  <MUITimeline
                    align="right"
                    data={historyData}
                    generateChildContent={(item) => (
                      <div style={{ letterSpacing: 0.5 }}>
                        <strong className="d-block">{item.adjustment_factor}%</strong>
                        <span className="d-block">Initially set to {item.initial_adjustment_factor}%</span>
                      </div>
                    )}
                    timelineDotVariant={'outlined'}
                    fetchMoreData={() => {}}
                  />
                </div>
              </div>
              <div className="col-md-8 m-auto">
                <MUITimeline
                  align="alternate"
                  timelineDotVariant="default"
                  Icon={MoodIcon}
                  data={historyData}
                  generateChildContent={(item) => (
                    <div style={{ letterSpacing: 0.5 }}>
                      <strong className="d-block">{item.adjustment_factor}%</strong>
                      <span className="d-block">Initially set to {item.initial_adjustment_factor}%</span>
                    </div>
                  )}
                  fetchMoreData={() => {}}
                />
              </div>
              <h4>Props</h4>
              <Table bordered columns={TABLE_HEADERS} dataSource={timelineDataSource} pagination={false} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default MUIDemo;
