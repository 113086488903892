import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchAutoDetails = async (params) => {
  try {
    const qs = reqTransform.fetchAutoDetails(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/FetchPlainOfferAgainstId_ELOC?${qs}`;
    const res = await utils.fetch(url, {}).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAutoDetails(resData);
  } catch (err) {
    console.log('api-FetchPlainOfferAgainstId_ELOC-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPlainOfferAgainstId_ELOC');
    throw errors;
  }
};
