import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import pageParams from 'constants/pageParams';

export const BuySchema = yup.object().shape({
  name_of_farm: yup.string().required(),
  size_of_farm: yup.string().required(),
  location_id: yup.number().positive().required(),
  village_id: yup.number().positive().required(),
  taluka_id: yup.number().positive().required(),
  // city_id: yup.number().positive().required(),
  district_id: yup.number().positive().required(),
  state_id: yup.number().positive().required(),
  country_id: yup.number().positive().required(),
  pin_code: yup.string().required(),
  // address_line1: yup.string('Address Line 1 is required.').when('location_id', (location_id, schema) => {
  //   if (!location_id) {
  //     return schema.required();
  //   }

  //   return schema.optional();
  // }),
  crops: yup.array().of(
    yup.object().shape({
      farm_detail_id: yup.number().required(),
      crop_id: yup.number().positive().required(),
      crop_area: yup.number().positive().required(),
      harvest_date: yup.date().required(),
    }),
  ),
});

export const fetchTransportationCost = async (params = {}) => {
  try {
    let res = await api.fetchTransportationCost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUnloadingCharges = async (params = {}) => {
  try {
    let res = await api.fetchUnloadingCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLoadingCharges = async (params = {}) => {
  try {
    let res = await api.fetchLoadingCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchMatchingCount = async (params = {}) => {
  try {
    let res = await api.fetchMatchingCount(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchMatchingOverviewCount = async (params = {}) => {
  try {
    let res = await api.fetchMatchingOverviewCount(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const hideAutoMatch = async (params = {}) => {
  try {
    let res = await api.hideAutoMatch(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const counterAutoBidOffer = async (params = {}, counterParams) => {
  try {
    let res = await api.counterAutoBidOffer(params, counterParams);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDerivedAutoBidOffers = async (id, params = {}) => {
  try {
    let res = await api.fetchDerivedAutoBidOffers(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAutoAndRegularBidOffers = async (params = {}) => {
  try {
    let res = await api.fetchAutoAndRegularBidOffers(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawAutoBidOffer = async (params = {}) => {
  try {
    let res = await api.withdrawAutoBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptRejectAutoBidOfferCounter = async (params = {}) => {
  try {
    let res = await api.acceptRejectAutoBidOfferCounter(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptRejectAutoBidOffer = async (params = {}) => {
  try {
    let res = await api.acceptRejectAutoBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const setTransportationCost = async (params = {}) => {
  try {
    let res = await api.setTransportationCost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const refreshBuySellCompatibilityMatrix = async (params = {}) => {
  try {
    let res = await api.refreshBuySellCompatibilityMatrix(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const editPickUpOfferByOwner = async (params = {}) => {
  try {
    let res = await api.editPickUpOfferByOwner(params);
    return res;
  } catch (err) {
    throw err;
  }
};
