import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import utils from 'lib/utils';
import NumberFormat from 'react-number-format';

const FormikMaterialTextFieldNumber = (props) => {
  const { field, form, variant, disabled, placeholder, name, type, ...rest } = props;

  const hasError = !!(getIn(form.touched, field.name) && getIn(form.errors, field.name));
  const errText = hasError ? getIn(form.errors, field.name) : '';

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      <NumberFormat
        error={hasError}
        placeholder={placeholder}
        thousandsGroupStyle="lakh"
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        decimalScale={2}
        style={{
          height: 40,
          width: '100%',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          border: '2px solid #dbdbdb',
        }}
        {...field}
      />
      {form.touched[field.name] && form.errors[field.name] && <span className="form__form-group-error">{errText}</span>}
    </div>
  );
};

export default FormikMaterialTextFieldNumber;
