import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/FetchScheduleAgainstCommodityDealMasterId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchScheduleAgainstCommodityDealMasterId-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchScheduleAgainstCommodityDealMasterId');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateScheduleAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('UpdateScheduleAgainstCommodityDealMasterId-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateScheduleAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const confirm = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.confirm(id, params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateScheduleAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('UpdateScheduleAgainstCommodityDealMasterId-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'UpdateScheduleAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const autoAcceptDeliverySchedules = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/AutoAcceptDeliverySchedules`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AutoAcceptDeliverySchedules');
    throw errors;
  }
};
