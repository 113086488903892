import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const getOAuthToken = async () => {
  try {
    let res = await api.getOAuthToken();
    return res;
  } catch (err) {
    throw err;
  }
};

export const getLocationOptions = async (params) => {
  try {
    let res = await api.getLocationOptions(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getLocationDetails = async (params) => {
  try {
    let res = await api.getLocationDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getReverseLocationDetails = async (params) => {
  try {
    let res = await api.getReverseLocationDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
