import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { resTransform } from './transforms';

const { baseUrl } = config;

export const fetchCommodityExploreData = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/GetRelevantMarketMetadata`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCommodityExploreData(resData);
  } catch (err) {
    console.log('api-GetRelevantMarketMetadata-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetRelevantMarketMetadata');
    throw errors;
  }
};
