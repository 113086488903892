import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const DriverSchema = yup.object().shape({
  first_name: yup.string().required('First Name is a required field'),
  // middle_name: yup.string().required(),
  last_name: yup.string().required('Last Name is a required field'),
  mobile_number: yup
    .string()
    .required('Phone Number is a required field')
    .matches(/^[0-9]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
  // mobile_number: yup.string().required('Phone Number is a required field'),
  dob: yup.string().required('Date Of Birth is required'),
  license_number: yup.string().required('License Number is a required field'),
});

export const mobileSchema = yup.object().shape({
  mobile_number: yup
    .string()
    .required('Phone Number is a required field')
    .matches(/^[0-9]+$/, 'Invalid phone number')
    .min(10, 'Invalid phone number')
    .max(10, 'Invalid phone number'),
});

export const eKycDocumentValiadtion = yup.object().shape({
  first_name: yup.string().required('First Name is a required field'),
  middle_name: yup.string().required('Middle Name is a required field'),
  last_name: yup.string().required('Last Name is a required field'),
  dob: yup.string().required('Date Of Birth is required'),
  license_number: yup.string().required('License Number is a required field').min(5, 'Invalid License Number'),
});

export const operatorSchema = yup.object().shape({
  first_name: yup.string().required('First Name is a required field'),
  // middle_name: yup.string().required('Middle Name is a required field'),
  // last_name: yup.string().required('Last Name is a required field'),
  mobile_number: yup.string().required('Mobile Number is a required field'),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(DriverSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params) => {
  try {
    await helpers.validateSchema(params?.isAggregatorServiceContract ? operatorSchema : DriverSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchDriverByMobile = async (params = {}) => {
  try {
    await helpers.validateSchema(mobileSchema, params);
    const res = await api.fetchDriverDetailsByMobile(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const link = async (id, params) => {
  try {
    const res = await api.link(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

//EKyc
export const eKycDocumentVerification = async (params) => {
  try {
    await helpers.validateSchema(eKycDocumentValiadtion, params);
    const res = await api.eKycDocumentVerification(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createReposOrder = async (params) => {
  try {
    const res = await api.createReposOrder(params);
    return res;
  } catch (err) {
    throw err;
  }
};
