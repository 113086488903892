import _ from 'lodash';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchAllCost(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchPreviewForManualInvoice(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchManualInvoicesDraft(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  saveUpdateManualInvoiceHistory(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  saveUpdateManualInvoice(params = {}) {
    let bodyParams = {
      ...params,
      invoice_date: params['invoice_date'] ? dayjs(params['invoice_date']).format('YYYY-MM-DD') : null,
      buyer_order_no_date: params['buyer_order_no_date']
        ? dayjs(params['buyer_order_no_date']).format('YYYY-MM-DD')
        : null,
      e_way_bill_no_date: params['e_way_bill_no_date']
        ? dayjs(params['e_way_bill_no_date']).format('YYYY-MM-DD')
        : null,
      payment_due_date: params['payment_due_date'] ? dayjs(params['payment_due_date']).format('YYYY-MM-DD') : null,
    };
    // if (bodyParams['manual_invoice_id']) {
    //   params['operation_Enum'] = 3;
    // } else {
    //   bodyParams['operation_Enum'] = 1;
    // }
    return bodyParams;
  },
  createManualInvoice(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  deleteManualInvoiceCharge(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  deleteManualInvoice(params = {}) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchAllCostList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllCostDetails(details);
    });
    return _.keyBy(deliveriesData, 'cost_type_id');
  },
  fetchAllCostDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'cost_type_id') || 0,
    };
    return data;
  },
  fetchPreviewForManualInvoiceList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchPreviewForManualInvoiceDetails(details);
    });
    return _.keyBy(deliveriesData, 'manual_invoice_id');
  },
  fetchPreviewForManualInvoiceDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'manual_invoice_id') || 0,
    };
    return data;
  },

  fetchManualInvoicesDraftList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchManualInvoicesDraftDetails(details);
    });
    return _.keyBy(deliveriesData, 'manual_invoice_id');
  },
  fetchManualInvoicesDraftDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'manual_invoice_id') || 0,
    };
    return data;
  },
  fetchInvoiceApprovalHistoryList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchInvoiceApprovalHistoryDetails(details);
    });
    return _.keyBy(deliveriesData, 'manual_invoice_history_id');
  },
  fetchInvoiceApprovalHistoryDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'manual_invoice_history_id') || 0,
    };
    return data;
  },
  saveUpdateManualInvoiceHistory(resData) {
    return resData;
  },
  saveUpdateManualInvoice(resData) {
    return resData;
  },
  createManualInvoice(resData) {
    return resData;
  },
  deleteManualInvoiceCharge(resData) {
    return resData;
  },
  deleteManualInvoice(resData) {
    return resData;
  },
};
