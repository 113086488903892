import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import notifications from 'lib/notifications';
import { userService } from 'services';

const initialState = {
  data: {},
  order: [],
  busy: false,
  updatedAt: null,
};

const getProfile = (sid) => async (dispatch) => {
  try {
    dispatch(slice.actions.setBusy(true));
    const resData = await userService.fetchSocialProfile(sid);
    const updates = {
      id: _.get(resData, `[0].social_profile_id`) || 0,
      data: resData[0],
      updatedAt: Date.now(),
    };
    dispatch(slice.actions.update(updates));
  } catch (err) {
    console.log('getUserProfile-->Err', err);
  }
  dispatch(slice.actions.setBusy(false));
};

const slice = createSlice({
  name: 'socialProfiles',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    add(state, action) {
      const { flush, resData, isAllListLoaded, updatedAt, isMyListLoaded } = action.payload;

      let existingData = state.data || {};
      let existingOrder = state.order || [];

      let data = {
        ...existingData,
      };

      let order = existingOrder.concat([]);

      if (flush) {
        data = {};
        order = [];
      }

      for (let requirement of resData) {
        let id = requirement['commodityreqofferid'] || 0;

        if (id) {
          data[id] = {
            isListLoaded: true,
            ...(data[id] || {}),
            ...requirement,
          };

          //precalculating data for searching and sorting
          data[id]['from_date_Timestamp'] = new Date(requirement['from_date']).getTime();
          data[id]['to_date_Timestamp'] = new Date(requirement['to_date']).getTime();
          data[id]['closing_On_Timestamp'] = new Date(requirement['closing_On']).getTime();

          let searchValues = _.pick(data[id], [
            'commodity_Type_Code',
            'commodity_code',
            'commodity_form_code',
            'commodity_residue_code',
            'payment_Term_Code',
            'coarse_location',
            'req_Offer_Status_Enum_Code',
            'qty',
            'periodicity_enum_code',
            'delivery_date_range',
            'min_qty',
            'closing_on',
            'expected_price',
            'req_offer_status_enum_code',
          ]);

          let searchStr = Object.values(searchValues).join('==');
          data[id]['searchStr'] = searchStr.toLowerCase();
          // searchStr = searchStr.replace(/\./gi, '');
          // searchStr = searchStr.replace(/\*/gi, '');
          // searchStr = searchStr.replace(/-/gi, '');
          // searchStr = searchStr.replace(/ /gi, '');
          // searchStr = searchStr.replace(/_/gi, '');

          if (!order.includes(id)) {
            order.push(id);
          }
        }
      }

      state['data'] = data;
      state['order'] = order;

      if (isAllListLoaded !== undefined) {
        state['isAllListLoaded'] = !!isAllListLoaded;
      }

      if (isMyListLoaded !== undefined) {
        state['isMyListLoaded'] = !!isMyListLoaded;
      }

      if (updatedAt !== undefined) {
        state['updatedAt'] = updatedAt;
      }

      // state.fetching = false;
    },

    update(state, action) {
      const { id, data, updatedAt } = action.payload;
      state.data[id] = {
        // isDetailsLoaded : true,
        ...state.data[id],
        ...data,
        updatedAt: updatedAt,
      };
      // state['updatedAt'] = utils.uid();
    },

    delete(state, action) {
      // debugger;
      const id = action.payload;
      let order = _.get(state, 'order', []) || [];
      // const posts = state.data.filter(p => p.post_id !== post_id)
      delete state['data'][id];
      let filteredOrder = order.filter((uid) => uid !== id);
      state['order'] = filteredOrder;
    },
  },
});

const storeProfile = (resData) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setBusy(true));
    const updates = {
      id: _.get(resData, `social_profile_id`) || 0,
      data: resData,
      updatedAt: Date.now(),
    };
    dispatch(slice.actions.update(updates));
  } catch (err) {
    console.log('getUserProfile-->Err', err);
  }
  // dispatch(slice.actions.setBusy(false));
};
const actions = {
  ...slice.actions,
  getProfile,
  storeProfile,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
