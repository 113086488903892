import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },

  fetchListSchemes(params = {}) {
    const qsParams = {
      ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },

  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    // let component_enum = 0;
    // if (params['service_enum'] == 2 && params['subscription_type'] ) {
    //   component_enum = 1;
    // } else if (params['service_enum'] == 2 && !params['subscription_type']) {
    //   component_enum = 2;
    // }

    console.log('creat plans transform params', params);
    if (params['service_enum'] !== 2) {
      params['component_enum'] = 0;
    }
    let component_addon_enum = 0;
    if (params['service_enum'] == 2 && !params['subscription_type'] && params['subscriptionAddOnType']) {
      component_addon_enum = 1;
    } else if (params['service_enum'] == 2 && !params['subscription_type'] && !params['subscriptionAddOnType']) {
      component_addon_enum = 2;
    }
    let bodyParams = {
      ...params,
      rate_value: parseInt(params['rate_value']),
      vehicle_type_code: params['vehicle_type_code'],
      rate_uom_id: params['discount_rate_type_enum'],
      component_addon_enum: component_addon_enum,
      // component_enum: component_enum,
      operation_Enum: 1,
      discount_plan_master_id: 0,
    };
    return bodyParams;
  },

  update(params = {}) {
    console.log('update plans transform params', params);
    let component_addon_enum = 0;
    if (params['service_enum'] == 2 && !params['subscription_type'] && params['subscriptionAddOnType']) {
      component_addon_enum = 1;
    } else if (params['service_enum'] == 2 && !params['subscription_type'] && !params['subscriptionAddOnType']) {
      component_addon_enum = 2;
    }
    let bodyParams = {
      ...params,
      rate_value: parseInt(params['rate_value']),
      vehicle_type_code: params['vehicle_type_code'],
      rate_uom_id: params['discount_rate_type_enum'],
      component_addon_enum: component_addon_enum,
      operation_Enum: 3,
      discount_plan_master_id: params['discount_plan_master_id'],
    };
    return bodyParams;
  },

  createScheme(params = {}) {
    console.log('params in create scheme', params);

    // params['scheme_start_date'] = dayjs(params['scheme_start_date']).format('YYYY-MM-DD HH:mm:ss');
    // params['scheme_end_date'] = dayjs(params['scheme_end_date']).format('YYYY-MM-DD HH:mm:ss');

    params['scheme_start_date'] = dayjs(params['scheme_start_date']).format('YYYY-MM-DDTHH:mm:ss');
    params['scheme_end_date'] = dayjs(params['scheme_end_date']).format('YYYY-MM-DDTHH:mm:ss');

    params['total_usage_count'] = parseInt(params['total_usage_count']);
    params['specific_entity_id'] = parseInt(params['specific_entity_id']);

    let user_enum = 0;
    if (params['userType']) {
      user_enum = 1;
    } else if (!params['userType']) {
      user_enum = 2;
    }

    let number_of_usages_enum = 0;
    if (params['numberOfUsages']) {
      number_of_usages_enum = 1;
    } else if (!params['numberOfUsages']) {
      number_of_usages_enum = 2;
    }

    let scheme_status_enum = 0;
    if (params['schemeStatus']) {
      scheme_status_enum = 1;
    } else if (!params['schemeStatus']) {
      scheme_status_enum = 2;
    }

    delete params['schemeStatus'];
    delete params['numberOfUsages'];
    delete params['userType'];
    let bodyParams = {
      ...params,

      user_enum: user_enum,
      number_of_usages_enum: number_of_usages_enum,
      scheme_status_enum: scheme_status_enum,
      operation_Enum: 1,
      discount_scheme_detail_id: 0,
      discount_scheme_detail_company_id: 0,
    };
    return bodyParams;
  },

  updateScheme(params = {}) {
    console.log('params in create scheme', params);
    console.log('params[userType in create scheme', params['userType']);

    params['scheme_start_date'] = dayjs(params['scheme_start_date']).format('YYYY-MM-DDTHH:mm:ss');
    params['scheme_end_date'] = dayjs(params['scheme_end_date']).format('YYYY-MM-DDTHH:mm:ss');

    params['total_usage_count'] = parseInt(params['total_usage_count']);
    params['specific_entity_id'] = parseInt(params['specific_entity_id']);

    let user_enum = 0;
    if (params['userType']) {
      user_enum = 1;
    } else {
      user_enum = 2;
    }

    let number_of_usages_enum = 0;
    if (params['numberOfUsages']) {
      number_of_usages_enum = 1;
    } else if (!params['numberOfUsages']) {
      number_of_usages_enum = 2;
    }

    let scheme_status_enum = 0;
    if (params['schemeStatus']) {
      scheme_status_enum = 1;
    } else if (!params['schemeStatus']) {
      scheme_status_enum = 2;
    }

    delete params['schemeStatus'];
    delete params['numberOfUsages'];
    delete params['userType'];
    let bodyParams = {
      ...params,

      user_enum: user_enum,
      number_of_usages_enum: number_of_usages_enum,
      scheme_status_enum: scheme_status_enum,
      operation_Enum: 3,
      // discount_scheme_detail_id: 0,
      // discount_scheme_detail_company_id: 0,
    };
    return bodyParams;
  },

  destroy(id) {
    return id;
  },

  fetchListClaim(params = {}) {
    const qsParams = {
      ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let DiscountPlanData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(DiscountPlanData, 'discount_plan_master_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'discount_plan_master_id') || 0,
    };
    return data;
  },

  fetchListSchemes(resData) {
    let DiscountPlanData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetailsSchemes(details);
    });
    return _.keyBy(DiscountPlanData, 'discount_scheme_detail_id');
  },
  fetchDetailsSchemes(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'discount_scheme_detail_id') || 0,
    };
    return data;
  },

  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },

  createScheme(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },

  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },

  fetchListClaim(resData) {
    console.log('fetchListClaim resData', resData);

    let DiscountPlanData = (_.isArray(resData) ? resData : []).map((details) => {
      console.log('fetchListClaim details', details);

      let data = {
        ...details,
        id: _.get(details, 'discount_scheme_usage_detail_id') || 0,
      };
      return data;
    });
    console.log('fetchListClaim DiscountPlanData', DiscountPlanData);
    return _.keyBy(DiscountPlanData, 'discount_scheme_usage_detail_id');
  },
};
