import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BiofuelRequirementOffers/api/Requirement/FetchAllIndentEnabledCompanies`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.error('api-company-fetchList->raw--err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllIndentEnabledCompanies');
    console.error('api-company-fetchList->errors---->', errors);
    throw errors;
  }
};

export const fetchAllSellerIndentEnabledCompanies = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllSellerIndentEnabledCompanies`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllSellerIndentEnabledCompanies');
    throw errors;
  }
};
