import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import RupeeIcon from 'mdi-react/RupeeIcon';
import useQuery from 'lib/hooks/useQuery';
import utils from 'lib/utils';

import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import CommoditySelect from './CommoditySelect';
import CommodityTypeFormSelect from 'pages/commodity/components/CommodityTypeFormSelect';
import CommodityResidueSelect from 'pages/commodity/components/CommodityResidueSelect';
import CommodityPackagingSelect from 'pages/commodity/components/CommodityPackagingSelect';

import FormikDateTimePickerField from 'shared/components/form/FormikDateTimePickerField';
import CompanySelect from 'pages/commodity/components/CompanySelect';
import CompanyLocationSelect from 'pages/commodity/components/CompanyLocationSelect_PhaseTwo';
import FarmLocationSelect from 'pages/commodity/components/FarmLocationSelect';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import { Tag } from 'antd';
import history from 'lib/history';
import { actions as storageActions } from 'redux/storage';
import { TextField, IconButton, MenuItem, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ToleranceIcon from 'assets/images/icons/tolerance.svg';
import { actions as requirementsActions } from 'redux/requirements';
import ToggleSwitch from 'pages/commodity/components/toggleSwitch';

const mapPropsToValues = (props) => {
  const isOwner = _.get(props, 'commodityData.is_owner');
  const entity_id = _.get(props, 'commodityData.entity_id') || 0;

  return {
    transfer_out_date: props?.commodityData?.transfer_out_date || '',
    transfer_in_date: props?.commodityData?.transfer_in_date || '',
    transfer_out_qty: props?.commodityData?.transfer_out_qty || 0,
    transfer_in_qty: props?.commodityData?.transfer_in_qty || 0,
    from_address_id: props?.commodityData?.from_address_id || 0,
    to_address_id: props?.commodityData?.to_address_id || 0,
    qty_Uom_Id: 1,
    is_Draft: false,
    stock_Transfer_Id: null,


    commodity_type_id: _.get(props, 'commodityData.commodity_type_id') || '',
    commodity_type_code: _.get(props, 'commodityData.commodity_type_code') || '',
    commodity_form_code: _.get(props, 'commodityData.commodity_form_code') || '',
    commodity_code: _.get(props, 'commodityData.commodity_code') || '',
    commodity_req_offer_id: _.get(props, 'commodityData.commodity_req_offer_id') || 0,
    commodity_id: _.get(props, 'commodityData.commodity_id') || 0,
    commodity_form_id: _.get(props, 'commodityData.commodity_form_id') || 0,
    commodity_residue_id: _.get(props, 'commodityData.commodity_residue_id') || 0,
    qty: _.get(props, 'commodityData.qty') || '',
    qty_uom_id: _.get(props, 'commodityData.qty_uom_id') || 1,
    qty_uom_code: _.get(props, 'commodityData.qty_uom_code') || 'MT',
    periodicity_enum: _.get(props, 'commodityData.periodicity_enum') || 0,
    from_date: _.get(props, 'commodityData.from_date') || '',
    to_date:
      new Date(props?.commodityData?.to_date).getFullYear() === 9999 ? '' : _.get(props, 'commodityData.to_date') || '',
    min_qty: _.get(props, 'commodityData.min_qty') || '',
    // // "total_qty" : 0,
    expected_price: _.get(props, 'commodityData.is_gcv_based')
      ? _.get(props, 'commodityData.price_per_gcv') || ''
      : _.get(props, 'commodityData.expected_price') || '',
    price_currency_id: _.get(props, 'commodityData.price_currency_id') || 1,
    price_uom_id: _.get(props, 'commodityData.price_uom_id') || 1,
    price_uom_code: _.get(props, 'commodityData.price_uom_code') || 1,
    // address_id: address_id,
    bill_to_address_id: _.get(props, 'commodityData.bill_to_address_id') || 0,
    company_affiliate_enum: _.get(props, 'commodityData.company_affiliate_enum', 0) || 0,
    entity_id: entity_id,
    payment_term_id: _.get(props, 'commodityData.payment_term_id', 0) || 0,
    additional_comments: _.get(props, 'commodityData.additional_comments', '') || '',
    closing_on: _.get(props, 'commodityData.closing_on') || '',
    valid_until: _.get(props, 'commodityData.valid_until') || '',
    vehicle_type_id: _.get(props, 'commodityData.vehicle_type_id') || 0,
    quality_measured_at_enum: _.get(props, 'commodityData.quality_measured_at_enum') || 0,
    qty_measured_at_enum: _.get(props, 'commodityData.qty_measured_at_enum') || 0,
    req_offer_status_enum: _.get(props, 'commodityData.req_offer_status_enum') || 0,

    plan_name: _.get(props, 'commodityData.plan_name') || '',
    plan_id: _.get(props, 'commodityData.plan_id') || 0,
    plan_actual_enum: _.get(props, 'commodityData.plan_actual_enum') || 0,

    typeString: 'ActualForm',

    packaging_id: _.get(props, 'commodityData.packaging_id') || 0,
    packaging_code: _.get(props, 'commodityData.packaging_code') || '',
    loading_charge_value: _.get(props, 'commodityData.loading_charge_value') || 0,
    unloading_charge_value: _.get(props, 'commodityData.unloading_charge_value') || 0,

    is_counter_offer_enabled: _.get(props, 'commodityData.is_counter_offer_enabled', true),

    commodity_req_offer_quality_spec:
      _.get(props, 'commodityData.commodity_req_offer_quality_spec') ||
      _.get(props, 'commodityData.requirement_spec') ||
      [],
    is_walk_in: (_.get(props, 'commodityData.is_walk_in') || false) && false, //remove && in future
    is_gcv_based: _.get(props, 'commodityData.is_gcv_based') || false,
    discount_Value: _.get(props, 'commodityData.is_gcv_based')
      ? _.get(props, 'commodityData.commodity_req_offer_quality_spec.discount_on_price_enum_code')
      : _.get(props, 'commodityData.commodity_req_offer_quality_spec.discount_Value'),
    premium_Value: _.get(props, 'commodityData.is_gcv_based')
      ? _.get(props, 'commodityData.commodity_req_offer_quality_spec.premium_on_price_enum_code')
      : _.get(props, 'commodityData.commodity_req_offer_quality_spec.premium_Value'),
    is_subscribed: _.get(props, 'commodityData.commodity_req_offer_id', 0)
      ? true
      : false,
    visible_only_for_fpo_members: _.get(props, 'commodityData.visible_only_for_fpo_members', true),
    bf_social_group_id: _.get(props, 'commodityData.bf_social_group_id', 0),
    is_own_transport: false,
    stock_transfer_id: _.get(props, 'commodityData.stock_transfer_id', 0),
    transfer_price: _.get(props, 'commodityData.transfer_price', ''),
    from_address_id: _.get(props, 'commodityData.from_address_id', 0),
    to_address_id: _.get(props, 'commodityData.to_address_id', 0),
    tolerance_qty: _.get(props, 'commodityData.tolerance_qty', 0),
    is_aggregate_quantity_variations: _.get(props, 'commodityData.is_aggregate_quantity_variations', false),
  };
};

const COMMODITY_TYPE = {
  REQUIREMENTS: 'requirements',
  OFFERS: 'offers',
};

function CreateQuickTransferForm(props) {
  const commodityData = props.commodityData || {};
  const type = props.type;
  const isCounterValue = _.get(props, 'commodityData.is_counter_offer_enabled', true);
  const isGroupFeedValue = _.get(props, 'commodityData.visible_only_for_fpo_members', true);
  let bidOfferText = type === COMMODITY_TYPE.REQUIREMENTS ? 'offers' : 'Bids';
  const [packagingCharges, setPackagingCharges] = useState('');
  const [packagingParams, setPackagingParams] = useState({});
  const [isOpenBuySell, setOpenBuySell] = useState(false);
  const [isNotSubscribedLoacation, setIsNotSubscribedLoacation] = useState(false);
  const [isPrepaidPlanLocation, setIsPrepaidPlanLocation] = useState(false);
  const user = useSelector((state) => state.user);
  const isFpo = _.get(user, 'profile.person.is_fpo');
  const isFarmer = _.get(user, 'profile.person.type_of_individual_enum') === 1;
  const typeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const acl = utils.getACL(user);
  const isProfileSubscribed = _.get(user, 'profile.is_subscribed') || false;
  const [isWalkInImpact, setWalkInImpact] = useState(false);
  const [commodityCost, setCommodityCost] = useState(0);
  const [dealChargesParams, setDealChargesParams] = useState({});
  const [prepaidBalance, setPrepaidBalance] = useState(0);
  const [openMatchModal, setOpenMatchModal] = useState(false);
  const [LoadingLocationStock, setLoadingLocationStock] = useState(false);
  const [DeliveryLocationStock, setDeliveryLocationStock] = useState(false);
  const [priceUomCode, setPriceUomCode] = useState('');

  let quantityFieldLabel = 'Quantity Required';
  let OpenBuySellHedding = 'Open Buy';
  let normalBuySell = 'Buy';
  if (type === COMMODITY_TYPE.OFFERS) {
    quantityFieldLabel = 'Quantity Offered';
    OpenBuySellHedding = 'Open Sell';
    normalBuySell = 'Sell';
  }

  const uomData = useSelector((state) => {
    return state.requirements?.uomData;
  }, shallowEqual);

  const priceUomData = useSelector((state) => {
    return state.requirements?.priceUomData;
  }, shallowEqual);
  const filterOptions = useSelector((state) => state.filterOptions);

  const locations = useSelector((state) => state.locations);
  const dispatch = useDispatch();
  const { back } = useHistory();
  const { stock_transfer_id, iscopy, isEdit } = useQuery();
  // const storageState = useSelector((state) => state.userStorage);
  // const storageTransfers = storageState?.storageTransfers?.filter(item => item.stock_transfer_id === parseInt(stock_transfer_id))?.[0];

  const encodeBackUrl = utils.encodeBackUrl();
  const decodeBackUrl = utils.decodeBackUrl(back);

  const getStockDetails = async (formikBag, addressId, isLoading) => {
    const { values } = formikBag;
    // if (values?.commodity_type_id && values?.commodity_form_id && values?.commodity_id && values?.commodity_residue_id && (values?.from_address_id || values?.to_address_id)) {
    if (values?.commodity_type_id && values?.commodity_form_id && values?.commodity_id && values?.commodity_residue_id) {
      const params = {
        commodity_type_id: values?.commodity_type_id,
        commodity_form_id: values?.commodity_form_id,
        commodity_residue_id: values?.commodity_residue_id,
        commodity_id: values?.commodity_id,
      }
      if (addressId) {
        const data = await dispatch(storageActions.fetchStockAgainstAddressId({ ...params, address_id: addressId }))
        data && isLoading ? setLoadingLocationStock(data?.[0]?.qty) : setDeliveryLocationStock(data?.[0]?.qty)
      } else {
        if (values?.from_address_id) {
          const data = await dispatch(storageActions.fetchStockAgainstAddressId({ ...params, address_id: values?.from_address_id }))
          data && setLoadingLocationStock(data?.[0]?.qty)
        } if (values?.to_address_id) {
          const data = await dispatch(storageActions.fetchStockAgainstAddressId({ ...params, address_id: values?.to_address_id }))
          data && setDeliveryLocationStock(data?.[0]?.qty)
        }
      }
    }
  }


  useEffect(() => {
    if (commodityData.commodity_type_id && commodityData.commodity_form_id) {
      const params = {
        commodity_type_id: commodityData.commodity_type_id,
        commodity_form_id: commodityData.commodity_form_id,
        price_qty_enum: 2,
      }
      dispatch(requirementsActions.fetchUOMAgainstCommodityTypeForm(params))
      dispatch(requirementsActions.fetchUOMAgainstCommodityTypeForm({ ...params, price_qty_enum: 1 }))
    }
  }, [commodityData]);

  const UOMSelectDropdown = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'transparent',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
        '& .MuiSelect-select': {
          fontWeight: 'bold',
        },
        '& .Mui-disabled': {
          borderColor: 'transparent',
        },
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      }
    },
  })(TextField);

  return (
    <div className="collapsable-wrapper">
      <Formik
        initialValues={mapPropsToValues({
          ...props,
        })}
        onSubmit={async (values, formikBag) => {
          if (values?.is_draft) {
            values.stock_transfer_status_enum = values?.is_draft ? 1 : 2;
          }
          if (Boolean(iscopy) || !values?.stock_transfer_id) {
            values.operation_enum = 1
          } else {
            values.operation_enum = 3
          }
          values.transfer_out_date = values.transfer_out_date ? dayjs(values.transfer_out_date).format('YYYY-MM-DDTHH:mm:ss[Z]') : ''
          values.transfer_in_date = values.transfer_in_date ? dayjs(values.transfer_in_date).format('YYYY-MM-DDTHH:mm:ss[Z]') : ''
          const success = await dispatch(storageActions.stockTransferQuick(values));
          success && history.push('/market/storage/transfers');
        }}>
        {(formikBag) => {
          const { initialValues, isSubmitting, errors, values, touched, setErrors, setFieldValue, handleSubmit } =
            formikBag;
          return (
            <React.Fragment>
              <Form className="formik-form pl-40 pr-40 pt-20 pb-20">
                <div>
                  {acl.isCompany && <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Company</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="company_id"
                          component={CompanySelect}
                          placeholder="Select Company"
                          initialDisplayText={initialValues.company_code || ''}
                          cb={(newVal) => {
                            let type = _.get(newVal, 'value.type') || '';
                            let entity_id = _.get(newVal, 'value.id') || 0;
                            let person_company_affiliate_enum = _.get(newVal, 'value.person_company_affiliate_enum') || 0;
                            setFieldValue('person_company_affiliate_enum', person_company_affiliate_enum);
                            if (type === 'company') {
                              setFieldValue('company_affiliate_enum', 1);
                            } else if (type === 'affiliate') {
                              setFieldValue('company_affiliate_enum', 2);
                            }
                            if (entity_id === props?.commodityData?.entity_id) {
                              setFieldValue('company_id', entity_id);
                              setFieldValue('entity_id', entity_id);
                              setFieldValue('address_id', props?.commodityData?.address_id);
                            } else {
                              setFieldValue('company_id', entity_id);
                              setFieldValue('entity_id', entity_id);
                              setFieldValue('address_id', 0);
                            }
                          }}
                        />
                        {values?.commodity_form_id && values?.commodity_residue_id && values?.commodity_id ? <Tag color={LoadingLocationStock > 0 ? "green" : 'red'} style={{ fontSize: 13.5, padding: 8, marginTop: '8px', width: '100%', display: 'flex', flexWrap: 'wrap', gap: '2px' }}>Current Stock: <span style={{ fontWeight: 'bold' }}>{LoadingLocationStock ? LoadingLocationStock + values?.qty_uom_code : "N/A"}</span>{!LoadingLocationStock ? ' (New storage level will be created)' : ''}</Tag> : ''}
                      </div>
                    </div>
                  </div>}
                  <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Commodity Form</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="commodity_type_code"
                          component={CommodityTypeFormSelect}
                          placeholder="Select commodity form"
                          // disabled={!iscopy && isFormFieldDisabled}
                          entity="commodityTypeForm"
                          // initialDisplayText={`${commodityData.commodity_type_code} - ${commodityData.commodity_form_code}`}
                          dependentFields={['commodity_id', 'commodity_residue_id']}
                          // is_walk_in={values.is_walk_in}
                          check="CommodityForm"
                          // cb={() => {
                          //   getStockDetails(formikBag)
                          // }}
                          cb={async (newValue) => {
                            getStockDetails(formikBag)
                            setFieldValue('is_walk_in', false);
                            setFieldValue('is_pick_up', false);
                            if (newValue?.commodity_form_code?.toLowerCase() === 'liquid' || newValue?.commodity_form_code?.toLowerCase() === 'gas') {
                              let packagingData = _.get(filterOptions, 'data.packaging_code') || [];
                              packagingData = packagingData?.find(item => item?.text?.toLowerCase() === 'bulk')
                              setFieldValue('packaging_id', packagingData?.value);
                              setFieldValue('packaging_code', packagingData?.text);
                            } else {
                              setFieldValue('packaging_id', '');
                              setFieldValue('packaging_code', '');
                            }
                            if (newValue.commodity_type_id && newValue.commodity_form_id) {
                              const params = {
                                commodity_type_id: newValue.commodity_type_id,
                                commodity_form_id: newValue.commodity_form_id,
                                price_qty_enum: 2,
                              }
                              const success = await dispatch(requirementsActions.fetchUOMAgainstCommodityTypeForm(params));
                              const res = await dispatch(requirementsActions.fetchUOMAgainstCommodityTypeForm({ ...params, price_qty_enum: 1 }));
                              if (success) {
                                setFieldValue('qty_uom_id', success?.[0]?.uom_id);
                                setFieldValue('qty_uom_code', success?.[0]?.uom_code)
                              }
                              if (res) {
                                setPriceUomCode(res?.[0]?.uom_code)
                                setFieldValue('price_uom_id', res?.[0]?.uom_id);
                                setFieldValue('price_uom_code', res?.[0]?.uom_code)
                              }
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Commodity</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="commodity_id"
                          component={CommoditySelect}
                          entity="typeFormCommodity"
                          placeholder="Select commodity type"
                          qparams={{
                            commodity_type_code: values.commodity_type_code,
                            commodity_form_code: values.commodity_form_code,
                          }}
                          initialDisplayText={commodityData.commodity_code || ''}
                          // disabled={(!iscopy && isFormFieldDisabled) || !values.commodity_type_code}
                          dependentFields={['commodity_residue_id']}
                          cb={(newVal) => {
                            if (values?.commodity_form_code?.toLowerCase() === 'liquid') {
                              let packagingData = _.get(filterOptions, 'data.packaging_code') || [];
                              packagingData = packagingData?.find(item => item?.text?.toLowerCase() === 'bulk')
                              setFieldValue('packaging_id', packagingData?.value);
                              setFieldValue('packaging_code', packagingData?.text);
                            } else {
                              setFieldValue('packaging_id', '');
                              setFieldValue('packaging_code', '');
                            }
                            setDealChargesParams({
                              ...dealChargesParams,
                              commodity_id: newVal.value_member,
                              commodity_form_id: values.commodity_form_id,
                            });
                            getStockDetails(formikBag)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Commodity Residue/Grade</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="commodity_residue_id"
                          component={CommodityResidueSelect}
                          placeholder="Select commodity residue type"
                          entity="commodityResidue"
                          qparams={{
                            commodity_id: _.get(values, 'commodity_id') || 0,
                            commodity_type_code: values.commodity_type_code,
                            commodity_form_code: values.commodity_form_code,
                            commodity_code: values.commodity_code,
                          }}
                          initialDisplayText={commodityData.commodity_residue_code || ''}
                          // disabled={(!iscopy && isFormFieldDisabled) || !(_.get(values, 'commodity_id') || 0)}
                          cb={() => {
                            getStockDetails(formikBag)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Packaging</span>
                      <div className="form-group-field">
                        <Field
                          name="packaging_id"
                          component={CommodityPackagingSelect}
                          placeholder="Select Packaging type"
                          qparams={{
                            commodity_id: _.get(values, 'commodity_id') || 0,
                            commodity_type_code: values.commodity_type_code,
                            commodity_form_code: values.commodity_form_code,
                            commodity_code: values.commodity_code,
                          }}
                          initialDisplayText={commodityData.packaging_code || ''}
                          // disabled={(!iscopy && isFormFieldDisabled) || !(_.get(values, 'commodity_id') || 0)}
                          cb={(newVal) => {
                            const id = _.get(newVal, 'value') || 0;
                            const code = _.get(newVal, 'text') || '';
                            setFieldValue('packaging_id', id);
                            // setFieldValue('packaging_code', code);
                            setPackagingParams({
                              location_id: values.address_id,
                              commodity_form_id: values.commodity_form_id,
                              packaging_id: id,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">From Location</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="from_address_id"
                          component={acl.isCompany ? CompanyLocationSelect : FarmLocationSelect}
                          placeholder="Select Location"
                          // disabled={
                          //   (!iscopy && isFormFieldDisabled) || (acl.isCompany ? !values.entity_id : false)
                          // }
                          entity_id={values.entity_id || 0}
                          company_affiliate_enum={values.company_affiliate_enum || 0}
                          setFieldValue={setFieldValue}
                          initialDisplayText={commodityData.address_code || commodityData.coarse_location || ''}
                          setDealChargesParams={setDealChargesParams}
                          setPrepaidBalance={setPrepaidBalance}
                          setIsNotSubscribedLoacation={setIsNotSubscribedLoacation}
                          setIsPrepaidPlanLocation={setIsPrepaidPlanLocation}
                          setPackagingParams={setPackagingParams}
                          getStockDetails={getStockDetails}
                          formik={formikBag}
                          cb={(newVal) => {
                            let isSubscribed = _.get(newVal, 'value.is_subscribed') || false;
                            let addressId = _.get(newVal, 'value.id') || 0;
                            let subscriptionPlanId = _.get(newVal, 'value.subscription_plan_id') || 0;
                            if (typeOfIndividualEnum === 1) {
                              isSubscribed = isProfileSubscribed;
                            }
                            setFieldValue('address_id', addressId);
                            setFieldValue('from_address_id', addressId);
                            setFieldValue('is_subscribed', isSubscribed);
                            // setPrepaidBalance(newVal.value.prepaid_balance);
                            if (typeOfIndividualEnum === 1) {
                              isSubscribed = isProfileSubscribed;
                            }
                            // setDealChargesParams({
                            //   commodity_id: _.get(values, 'commodity_id'),
                            //   commodity_form_id: _.get(values, 'commodity_form_id'),
                            //   plan_id: subscriptionPlanId,
                            //   buyer_seller_id: newVal.value.entity_id,
                            //   company_address_id: addressId,
                            // });
                            setPackagingParams({
                              location_id: addressId,
                              commodity_form_id: values.commodity_form_id,
                              packaging_id: values.packaging_id,
                            });
                            // if (isSubscribed) {
                            //   setIsNotSubscribedLoacation(false);
                            //   setIsPrepaidPlanLocation(false);
                            //   if (subscriptionPlanId === 1 || subscriptionPlanId === 2 || subscriptionPlanId === 3) {
                            //     setIsPrepaidPlanLocation(true);
                            //   }
                            // } else {
                            //   if (acl.isCompany) {
                            //     setIsNotSubscribedLoacation(true);
                            //     setIsPrepaidPlanLocation(false);
                            //   } else {
                            //     setIsNotSubscribedLoacation(false);
                            //     setIsPrepaidPlanLocation(false);
                            //   }
                            // }
                            getStockDetails(formikBag, addressId, true)
                          }}
                        />
                        {/* {values?.commodity_form_id && values?.commodity_residue_id && values?.commodity_id ? <Tag color={LoadingLocationStock > 0 ? "green" : 'red'} style={{ fontSize: 13.5, padding: 8, marginTop: '8px', width: '100%', display: 'flex', flexWrap: 'wrap', gap: '2px' }}>Current Stock: <span style={{ fontWeight: 'bold' }}>{LoadingLocationStock ? LoadingLocationStock + values?.qty_uom_code : "N/A"}</span>{!LoadingLocationStock ? ' (New storage level will be created)' : ''}</Tag> : ''} */}
                        {typeOfIndividualEnum !== 1 && (
                          <span
                            style={{
                              color: 'var(--unnamed-color-afafaf)',
                              fontSize: 'var(--fs-base__four)',
                              marginTop: '5px',
                            }}>
                            {packagingCharges !== '' ? packagingCharges : ''}
                            {packagingCharges !== '' ? (
                              <Link to="/configuration/loading-unloading-charges" target="_blank">
                                {' '}
                                Click here{' '}
                              </Link>
                            ) : (
                              ''
                            )}
                            {packagingCharges !== '' ? <span>to change.</span> : ''}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">To Location</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="to_address_id"
                          component={acl.isCompany ? CompanyLocationSelect : FarmLocationSelect}
                          placeholder="Select Location"
                          // disabled={
                          //   (!iscopy && isFormFieldDisabled) || (acl.isCompany ? !values.entity_id : false)
                          // }
                          entity_id={values.entity_id || 0}
                          company_affiliate_enum={values.company_affiliate_enum || 0}
                          setFieldValue={setFieldValue}
                          initialDisplayText={commodityData.address_code || commodityData.coarse_location || ''}
                          setDealChargesParams={setDealChargesParams}
                          setPrepaidBalance={setPrepaidBalance}
                          getStockDetails={getStockDetails}
                          formik={formikBag}
                          cb={(newVal) => {
                            let isSubscribed = _.get(newVal, 'value.is_subscribed') || false;
                            let addressId = _.get(newVal, 'value.id') || 0;
                            let subscriptionPlanId = _.get(newVal, 'value.subscription_plan_id') || 0;
                            setFieldValue('bill_to_address_id', addressId);
                            setFieldValue('to_address_id', addressId);
                            getStockDetails(formikBag, addressId, false)
                          }}
                        />
                        {/* {values?.commodity_form_id && values?.commodity_residue_id && values?.commodity_id ? <Tag color={DeliveryLocationStock > 0 ? "green" : 'red'} style={{ fontSize: 13.5, padding: 8, marginTop: '8px', width: '100%', display: 'flex', flexWrap: 'wrap', gap: '2px' }}>Current Stock: <span style={{ fontWeight: 'bold' }}>{DeliveryLocationStock ? DeliveryLocationStock + values?.qty_uom_code : "N/A"}</span>{!DeliveryLocationStock ? ' (New storage level will be created)' : ''}</Tag> : ''} */}
                      </div>
                    </div>
                  </div>
                  <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Transfer Price</span>
                      <span class="required">*</span>
                      <div className={'form-group-field  with-extention align-items-start'}>
                        <Field
                          name="transfer_price"
                          // disabled={!iscopy && isFormFieldDisabled}
                          component={FormikMaterialTextField}
                          type="number"
                          isAdornment={true}
                          position="both"
                          startAdornmentContent={<RupeeIcon />}
                          // endAdornmentStyles={{ background: 'none' }}
                          // endAdornmentContent={<span style={{ fontWeight: 'bold' }}>/MT</span>}
                          endAdornmentStyles={{ background: 'none', padding: uomData?.length ? 0 : '20px 10px' }}
                          endAdornmentContent={
                            priceUomData?.length ?
                              <UOMSelectDropdown
                                disabled={priceUomData?.length < 2}
                                select
                                variant='outlined'
                                size='small'
                                value={values?.price_uom_id}
                                onChange={(e) => {
                                  setFieldValue('price_uom_id', e.target.value)
                                  setFieldValue('price_uom_code', priceUomData?.find(item => item.uom_id === parseInt(e.target.value)))
                                }}
                              >
                                {priceUomData?.map((option) => (
                                  <MenuItem key={option.uom_id} value={option?.uom_id}>
                                    {option?.uom_code}
                                  </MenuItem>
                                ))}
                              </UOMSelectDropdown> : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row form__form-group">
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Qty Moved Out</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="transfer_out_qty"
                          // disabled={!iscopy && isFormFieldDisabled}
                          component={FormikMaterialTextField}
                          type="number"
                          isAdornment={true}
                          position="end"
                          endAdornmentStyles={{ background: 'none', padding: uomData?.length ? 0 : '20px 10px' }}
                          endAdornmentContent={
                            uomData?.length ?
                              <UOMSelectDropdown
                                disabled={uomData?.length < 2}
                                select
                                variant='outlined'
                                size='small'
                                value={values?.qty_uom_id}
                                onChange={(e) => {
                                  setFieldValue('qty_uom_id', e.target.value)
                                  setFieldValue('qty_uom_code', uomData?.find(item => item.uom_id === parseInt(e.target.value)))
                                }}
                              >
                                {uomData?.map((option) => (
                                  <MenuItem key={option.uom_id} value={option?.uom_id}>
                                    {option?.uom_code}
                                  </MenuItem>
                                ))}
                              </UOMSelectDropdown> : ''
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <span className="form-group-label">Qty Moved In</span>
                      <span class="required">*</span>
                      <div className="form-group-field">
                        <Field
                          name="transfer_in_qty"
                          // disabled={!iscopy && isFormFieldDisabled}
                          component={FormikMaterialTextField}
                          type="number"
                          isAdornment={true}
                          position="end"
                          // endAdornmentStyles={{ background: 'none' }}
                          // endAdornmentContent={<span style={{ fontWeight: 'bold' }}>MT</span>}
                          endAdornmentStyles={{ background: 'none', padding: uomData?.length ? 0 : '20px 10px' }}
                          endAdornmentContent={
                            uomData?.length ?
                              <UOMSelectDropdown
                                disabled={uomData?.length < 2}
                                select
                                variant='outlined'
                                size='small'
                                value={values?.qty_uom_id}
                                onChange={(e) => {
                                  setFieldValue('qty_uom_id', e.target.value)
                                  setFieldValue('qty_uom_code', uomData?.find(item => item.uom_id === parseInt(e.target.value)))
                                }}
                              >
                                {uomData?.map((option) => (
                                  <MenuItem key={option.uom_id} value={option?.uom_id}>
                                    {option?.uom_code}
                                  </MenuItem>
                                ))}
                              </UOMSelectDropdown> : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {!isOpenBuySell && (
                    <div className="row form__form-group">
                      <div className="col-md-6 col-lg-5 col-xl-4">
                        <span className="form-group-label">Date Moved Out</span>
                        <span class="required">*</span>
                        <div className="form-group-field custom-input">
                          <Field
                            name="transfer_out_date"
                            defaultValue={commodityData.transfer_out_date}
                            component={FormikDateTimePickerField}
                            // minDate={new Date()}
                            // isClosingOn={true}
                            isShowTime={false}
                          />
                          <img src={calendarIcon} className="datepicker-calendar-icon" />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-5 col-xl-4">
                        <span className="form-group-label">Date Moved In</span>
                        <span class="required">*</span>
                        <div className="form-group-field custom-input">
                          <Field
                            name="transfer_in_date"
                            defaultValue={commodityData.transfer_in_date}
                            component={FormikDateTimePickerField}
                            minDate={values.transfer_out_date || new Date()}
                            isShowTime={false}
                          />
                          <img src={calendarIcon} className="datepicker-calendar-icon" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-btns">
                  <div className="button">
                    <Button
                      color="primary"
                      size="sm"
                      type="button"
                      name="publish"
                      className="mr-3"
                      // disabled={isNotSubscribedLoacation}
                      onClick={() => {
                        handleSubmit();
                      }}>
                      OK
                    </Button>
                    <Button
                      color="secondary"
                      type="button"
                      size="sm"
                      name="draft"
                      onClick={() => {
                        setFieldValue('is_draft', true)
                        handleSubmit();
                      }}>
                      {Boolean(isEdit) ? 'Update' : 'Save As Draft'}
                    </Button>
                  </div>
                  <div className="button">
                    <Link color="link" type="button" to={'/market/storage/transfers'}>
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateQuickTransferForm;
