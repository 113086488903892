import React, { useEffect, useState } from 'react';
import useQuery from 'lib/hooks/useQuery';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as companyActions } from 'redux/companies';
import { actions as locationActions } from 'redux/locations';
import _ from 'lodash';
import api from 'lib/api';
import { Link, useParams } from 'react-router-dom';
import utils, { decryptionInHex, getDecryptedRouteParams, getDecryptedUrl } from 'lib/utils';
import ShieldIcon from 'common/Shield/shield';

export default function CompanyLocationSelect(props) {
  const {
    field,
    form: { touched, errors, values, setFieldValue },
    label,
    children,
    select,
    variant,
    initialDisplayText,
    entity_id,
    company_affiliate_enum,
    disabled,
    placeholder,
    setDealChargesParams,
    setPrepaidBalance,
    setIsNotSubscribedLoacation,
    setIsPrepaidPlanLocation,
    setPackagingParams,
    isEditBidPopup,
    setIsLUTLocation,
    setIsSezLocation,
    getStockDetails,
    getTransportationCharges,
    formik,
    setFromLocationId,
    setToLocationId,
    isBidPopup,
    isProcessingDeal,
    ...rest
  } = props;

  const { ctIndentCompany } = useQuery();
  let { id, iscopy } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);

  let loading = false;
  let options = [];
  const loc = getDecryptedUrl(window.location.href);
  const [value, setValue] = React.useState(null);

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations);
  const locationData = ctIndentCompany || isProcessingDeal ? _.get(locations, `entities`) || {} : _.get(locations, `locations`) || {};
  // const locationData = _.get(locations, `locations`) || {};
  const user = useSelector((state) => state.user);
  const acl = utils.getACL(user);

  if (entity_id) {
    _.each(locationData, (item, id) => {
      if (item.entity_id === entity_id || item.company_id === item.company_id) {
        options.push({
          label: item.address_text || item.location_code || '',
          value: {
            id: parseInt(id),
            ...item,
          },
        });
      }
    });
  }
  if (!options.length) {
    loading = true;
  }

  useEffect(() => {
    setValue(null);
    if (setIsNotSubscribedLoacation) {
      setIsNotSubscribedLoacation(false)
    }
  }, [entity_id, loading]);

  useEffect(() => {
    if (ctIndentCompany || isProcessingDeal) {
      if (entity_id) {
        const params = {
          filters: {},
          offset: 0,
          page_Size: 100,
          company_id: entity_id,
        };
        dispatch(locationActions.fetchCompanyAndAffiliateLocations(params));
      }
    } else {
      // if (loading) {
      // dispatch(locationActions.fetchList());
      if (values?.person_company_affiliate_enum && entity_id) {
        const params = {
          entity_id: entity_id,
          person_company_affiliate_enum: values?.person_company_affiliate_enum,
          person_id: user.person_id
        }
        dispatch(locationActions.fetchBusinessAndAffiliateLocationsAgainstEntityId(params));
      }
      // }
    }
    // }, [ctIndentCompany, entity_id, loading, values?.person_company_affiliate_enum]);
  }, [entity_id]);

  useEffect(() => {
    getStockDetails && formik && getStockDetails(formik)
    getTransportationCharges && formik && getTransportationCharges(formik)
  }, [loading, values]);

  useEffect(() => {
    if (!loading) {
      // let initialAddressId = _.get(props, 'form.values.address_id') || 0;
      let initialAddressId = values?.[field.name] || 0;
      const initialSelected = options.filter((o) => (initialAddressId === o.value?.id) || (initialAddressId === o.value.company_address_id));
      if (initialAddressId && initialSelected.length > 0) {
        setDealChargesParams && setDealChargesParams({
          plan_id: initialSelected[0].value.subscription_plan_id,
          buyer_seller_id: initialSelected[0].value.entity_id,
          company_address_id: initialSelected[0].value.company_address_id,
          commodity_id: values.commodity_id,
          commodity_form_id: values.commodity_form_id,
        });
        setPrepaidBalance && setPrepaidBalance(initialSelected[0].value.prepaid_balance);
        setValue(initialSelected);
      }
    }
  }, [loading, values?.address_id, values?.buyer_bill_to_address_id, values?.processor_ship_to_address_id,values?.processor_bill_to_address_id]);

  useEffect(() => {
    const initialSelected = options.filter((o) => values?.[field.name] === o.value?.id);
    if (initialSelected.length > 0) {
      let isLUTLocation = _.get(initialSelected[0], 'value.is_lut_location')
      let isSezLocation = _.get(initialSelected[0], 'value.is_sez_location')
      let addressId = _.get(initialSelected[0], 'value.id')
      setIsLUTLocation && setIsLUTLocation(isLUTLocation)
      setIsSezLocation && setIsSezLocation(isSezLocation)
      if (isLUTLocation || isSezLocation) {
        setFieldValue('bill_to_address_id', addressId);
        setValue(initialSelected[0]);
      }
      // props.cb(initialSelected[0])
    }
  }, [values?.address_id]);

  const getSubscriptionValidation = (isSubscribed, subscriptionPlanId) => {
    if (setIsNotSubscribedLoacation && setIsPrepaidPlanLocation) {
      if (isSubscribed) {
        setIsNotSubscribedLoacation(false);
        setIsPrepaidPlanLocation(false);
        if (subscriptionPlanId === 1 || subscriptionPlanId === 2 || subscriptionPlanId === 3 || subscriptionPlanId === 9) {
          setIsPrepaidPlanLocation(true);
        }
      } else {
        if (acl.isCompany) {
          setIsNotSubscribedLoacation(true);
          setIsPrepaidPlanLocation(false);
        } else {
          setIsNotSubscribedLoacation(false);
          setIsPrepaidPlanLocation(false);
        }
      }
    }
  };

  useEffect(() => {
    if (options.length === 1 && field.name != 'bill_to_address_id') {
      setValue(options[0]);
      const addressId = _.get(options[0], 'value.company_address_id') || 0;
      setFieldValue(field.name, addressId);
      props.cb(options[0]);
    } else if (options.length && field.name != 'bill_to_address_id') {
      let selectedAddress;
      let addressId = values[field.name];
      if (
        (loc.includes('create') ||
          loc.includes(`overview`) ||
          loc.includes(`details`) ||
          loc.includes(`bids`) ||
          loc.includes(`deals`) ||
          loc.includes(`balance-position`)) && !values[field.name]
      ) {
        selectedAddress = options.filter((item) => item?.value?.is_registered === true);
      }
      if (selectedAddress?.length) {
        addressId = addressId || _.get(selectedAddress?.[0], 'value.company_address_id') || 0;
        setFieldValue(field.name, addressId);
        let isLUTLocation = _.get(selectedAddress?.[0], 'value.is_lut_location');
        let isSezLocation = _.get(selectedAddress?.[0], 'value.is_sez_location');
        setIsLUTLocation && setIsLUTLocation(isLUTLocation);
        setIsSezLocation && setIsSezLocation(isSezLocation);
        setValue(selectedAddress?.[0]);
        props.cb(selectedAddress?.[0]);
      }
    }
  }, [loading, entity_id]);


  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }}
        renderOption={(option) => {
          let messageColor = '';
          switch (option.value.subscription_plan_name || option.value.plan_name) {
            case 'Prepaid Pack':
            case 'Top Up':
              messageColor = '#1B62AB';
              break;
            case 'Business Pack':
              messageColor = '#1B62AB';
              break;
            case 'Bioenergy Pack':
              messageColor = '#1B62AB';
              break;
            case 'Enterprise Pack':
              messageColor = '#707070';
              break;
          }
          return (
            <table className="w-100">
              <tr>
                <td>{option.label}</td>
                <td style={{ float: 'right' }}>
                  {(option.value.is_subscribed || values?.isCompanyAffSubscribed) ? (
                    <>
                      {option.value.subscription_plan_name === 'Prepaid Pack' || option.value.subscription_plan_name === 'Top Up' || option.value.plan_name === 'Prepaid Pack' ? (
                        // <p style={{ color: messageColor }}>
                        //   {option.value.prepaid_balance ? `₹: ${utils.formatQty(option.value.prepaid_balance)}` : `₹: 0`}</p>
                        <ShieldIcon dontShowPopUp={true} tickvalue={option?.value?.tick_color_enum == null || option?.value?.tick_color_enum == 0 ? 4 : option?.value?.tick_color_enum} />
                      ) : (
                        <div className='d-flex align-items-center'>
                          <p style={{ color: messageColor }}>{option.value.is_subscribed ? option.value.subscription_plan_name || option.value.plan_name : ''}</p>
                          <ShieldIcon style={{ width: '20px', height: '20px', marginLeft: 6, marginTop: 0 }} dontShowPopUp={true} tickvalue={option?.value?.tick_color_enum == null || option?.value?.tick_color_enum == 0 ? 4 : option?.value?.tick_color_enum} />
                        </div>
                      )}
                    </>
                  ) : !values?.isCompanyAffSubscribed && (
                    <Link to="/subscription-packages" target="_blank">
                      Subscribe
                    </Link>
                  )}
                </td>
              </tr>
            </table>
          );
        }}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            style={{ backgroundColor: 'white' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
}
