import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    return params;
  },
};

export const resTransform = {
  fetchList(resData) {
    let deliveryContractData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(deliveryContractData, 'transport_deal_master_id');
  },
  fetchDetails(resData) {
    let data = {
      // ...resData,
      id: _.get(resData, 'transport_deal_master_id') || 0,
      transport_deal_master_id: _.get(resData, 'transport_deal_master_id') || 0,
      'Deal Name': _.get(resData, 'deal_name') || 0,
      'Contract Date': _.get(resData, 'contract_date') || 0,
      'Hiring Party': _.get(resData, 'buyer_company_code') || 0,
      'Transport Provider': _.get(resData, 'seller_company_code') || 0,
      'Vehicle Type': _.get(resData, 'vehicle_type_enum_code') || 0,
      'Vehicle Capacity': _.get(resData, 'max_capacity_of_vehicle_type') || 0,
      'No Of Vehicles': _.get(resData, 'number_of_vehicles') || 0,
      'Hire Date From': _.get(resData, 'from_date') || 0,
      'Hire Date To': _.get(resData, 'to_date') || 0,
      'Type Of Hire': _.get(resData, 'hire_type_enum_code') || 0,
      'Operating Location': _.get(resData, 'operating_circle_id_code') || 0,
      'From Location': _.get(resData, 'from_location_id_code') || 0,
      'To Location': _.get(resData, 'to_location_code') || 0,
      'Est Km': _.get(resData, 'estimated_km') || 0,
      'Rate Per Day': _.get(resData, 'per_day_rate') || 0,
      'Rate Per MT': _.get(resData, 'per_mt_rate') || 0,
      'Rate Per Km': _.get(resData, 'per_km_rate') || 0,
      'Min Km Per Day': _.get(resData, 'minimum_km_per_day') || 0,
      'Extra Run Rate Per Km': _.get(resData, 'extra_run_rate_per_km') || 0,
      'Tax Status': _.get(resData, 'tax_applicability_enum_code') || 0,
      'Payment Terms': _.get(resData, 'payment_term_code') || 0,
      'Contract Status': _.get(resData, 'transport_contract_status_enum_code') || 0,
    };
    return data;
  },
};
