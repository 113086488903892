import _ from 'lodash';
import * as api from './api';

// export const fetchCommodityExploreData = async (params = {}) => {
//   try {
//     let res = await api.fetchCommodityExploreData(params);
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };

export const fetchCommodityExploreData = async (params = {}) => {
  try {
    let res = await api.fetchCommodityExploreData(params);
    return res;
  } catch (err) {
    throw err;
  }
};
