import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import { yupToFormErrors } from 'formik';

export const deliveryContractSchema = yup.object().shape({
  contract_date: yup.string().required('Please select Contract Date'),
  from_date: yup.string().required('Please select From Date'),
  to_date: yup.string().required('Please select To Date'),

  hire_type_enum: yup.number().required('Hire Type is required').positive('Hire Type is required'),

  per_day_rate: yup.string().when('hire_type_enum', {
    is: 1,
    then: yup.string().required('Per Day Rate is required'),
    otherwise: yup.string().optional(),
  }),

  per_mt_rate: yup.string().when('hire_type_enum', {
    is: 3,
    then: yup.string().required('Per MT Rate is required'),
    otherwise: yup.string().optional(),
  }),
  per_km_rate: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Per KM Rate is required'),
    otherwise: yup.string().optional(),
  }),
  extra_run_rate_per_km: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Extra run rate per KM is required'),
    otherwise: yup.string().optional(),
  }),
  minimum_km_per_day: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Minimum KM per Day is required'),
    otherwise: yup.string().optional(),
  }),

  // from_location_id: yup.number().required('From Location is required').positive('From Location is required'),
  // to_location_id: yup.number().required('To Location is required').positive('To Location is required'),

  // from_location_id: yup.number().when('hire_type_enum', {
  //   is: 3,
  //   then: yup.number().required('From Location is required').positive('From Location is required'),
  //   otherwise: yup.number().optional(),
  // }),
  // to_location_id: yup.number().when('hire_type_enum', {
  //   is: 3,
  //   then: yup.number().required('To Location is required').positive('To Location is required'),
  //   otherwise: yup.number().optional(),
  // }),
  // operating_circle_id: yup.number().when('hire_type_enum', {
  //   is: 3,
  //   then: yup.number().optional(),
  //   otherwise: yup.number().required('Operating Circle is required').positive('Operating Circle is required'),
  // }),

  // estimated_km: yup.number().required('Estimated KM is required').positive('Estimated KM is required'),
  vehicle_type_enum: yup.number().required('Vehicle Type is required').positive('Vehicle Type is required'),
  number_of_vehicles: yup
    .number()
    .required('Number of vehicles is required')
    .positive('Number of vehicles must be positive number'),
  max_capacity_of_vehicle_type_id: yup
    .number()
    .required('Vehicle Capacity is required')
    .positive('Vehicle Capacity is required'),

  payment_term_id: yup.number().required('Payment Term is required').positive('Payment Term is required'),
  deal_name: yup.string().required('Deal Name is required'),
  transport_contract_status_enum: yup
    .number()
    .required('Transport contract status is required')
    .positive('Transport contract status is required'),
  tax_applicability_enum: yup
    .number()
    .required('Tax applicability is required')
    .positive('Tax applicability is required'),

  transport_provider_company_id: yup
    .number()
    .required('Transport Provider is required')
    .positive('Transport Provider is required'),
  hiring_party_id: yup.number().required('Hiring party is required').positive('Hiring party is required'),
  hiring_party_address_id: yup.number().required('Location Name is required').positive('Location Name is required'),
  // hiring_party_address_id: yup.string().required('Location Name is required'),
  location_code: yup.string().required('Location code is required'),
  // usable_capacity_minimum_billing:yup
  // .number()
  // .nullable()
  // .when('vehicle_type_enum', {
  //   is: 2,
  //   then: yup.number().min(1,'Usable capacity minimum billing is required').required('Usable capacity minimum billing is required'),
  // })
});

export const transportDealSchema = yup.object().shape({
  transport_deal_master_id: yup.number().required('Please select Transporter'),
});

export const operatorSchema = yup.object().shape({
  contract_date: yup.string().required('Please select Contract Date'),
  from_date: yup.string().required('Please select From Date'),
  to_date: yup.string().required('Please select To Date'),

  hire_type_enum: yup.number().required('Hire Type is required').positive('Hire Type is required'),

  per_day_rate: yup.string().when('hire_type_enum', {
    is: 1,
    then: yup.string().required('Per Day Rate is required'),
    otherwise: yup.string().optional(),
  }),

  per_mt_rate: yup.string().when('hire_type_enum', {
    is: 3,
    then: yup.string().required('Per MT Rate is required'),
    otherwise: yup.string().optional(),
  }),
  per_km_rate: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Per KM Rate is required'),
    otherwise: yup.string().optional(),
  }),
  extra_run_rate_per_km: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Extra run rate per KM is required'),
    otherwise: yup.string().optional(),
  }),
  minimum_km_per_day: yup.string().when('hire_type_enum', {
    is: 2,
    then: yup.string().required('Minimum KM per Day is required'),
    otherwise: yup.string().optional(),
  }),

  vehicle_type_enum: yup.number().required('Vehicle Type is required').positive('Vehicle Type is required'),
  number_of_vehicles: yup
    .number()
    .required('Number of vehicles is required')
    .positive('Number of vehicles must be positive number'),
  max_capacity_of_vehicle_type_id: yup
    .number()
    .required('Vehicle Capacity is required')
    .positive('Vehicle Capacity is required'),

  payment_term_id: yup.number().required('Payment Term is required').positive('Payment Term is required'),
  deal_name: yup.string().required('Deal Name is required'),
  transport_contract_status_enum: yup
    .number()
    .required('Transport contract status is required')
    .positive('Transport contract status is required'),
  tax_applicability_enum: yup
    .number()
    .required('Tax applicability is required')
    .positive('Tax applicability is required'),

  transport_provider_company_id: yup
    .number()
    .required('Transport Provider is required')
    .positive('Transport Provider is required'),
  hiring_party_id: yup.number().required('Hiring party is required').positive('Hiring party is required'),
  operator_first_name: yup.string().required('Please Enter First Name'),
  // operator_middle_name: yup.string().required('Please Enter Middle Name'),
  // operator_last_name: yup.string().required('Please Enter Last Name'),
  operator_mobile_number: yup.string().required('Please Enter Mobile Number'),
  default_vehicle_id: yup.number().required('Please Select Default Vehicle'),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(params?.contract_type_enum === 2 ? operatorSchema : deliveryContractSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(
      params?.['contract_type_enum'] === 2 ? operatorSchema : deliveryContractSchema,
      params,
    );
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchFilterData = async (params = {}) => {
  try {
    let res = await api.fetchFilterData(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTransports = async (params = {}) => {
  try {
    let res = await api.fetchTransports(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTransportDeal = async (id, params) => {
  try {
    // await helpers.validateSchema(transportDealSchema, id);
    const res = await api.createTransportDeal(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchDeliveryAllotmentsAgainstTransportDealMasterId = async (params) => {
  try {
    const res = await api.FetchDeliveryAllotmentsAgainstTransportDealMasterId(params);
    return res;
  } catch (err) {
    throw err;
  }
};
