import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import { InputAdornment } from '@material-ui/core';
import { Popover, Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { COMMODITY_FILTER, COMMODITY_TYPE } from 'constants/pageParams';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={','}
      decimalSeparator={'.'}
      isNumericString
      prefix={props.prefix} //"$"
    />
  );
}

const FormikMaterialTextField = (props) => {
  // console.log('MaterialFormikTextField--->props', props)

  const {
    field,
    form: { touched, errors, values, setFieldValue },
    variant,
    disabled,
    placeholder,
    type,
    isAdornment = false,
    position,
    startAdornmentContent,
    endAdornmentContent,
    startAdornmentStyles,
    endAdornmentStyles,
    getTransportationCharges,
    formik,
    isPercentField,
    isRadiusField,
    invoice_qty_uom_code,
    isKgField = false,
    isDecimalPriceField = false,
    isReceipt,
    transportCost,
    loadingCharges,
    unloadingCharges,
    target,
    details,
    commodityType,
    isRFQ,
    is_gcv_based,
    showPosition,
    isTargetPriceModal,
    isError,
    initialValues,
    isDirectDealEnabled,
    formType,
    expectedPrice,
    commodity,
    yourPrice,
    isMultiline,
    FetchYourPriceAgainstBuySell,
    isFca,
    isUpdateQuote,
    mapTeleField,
    isAvoidSettingOfferBidPrice,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';
  // console.log('HAS ERR:', touched, errors);

  // let exactPrice = values?.is_own_transport === true ? expectedPrice : initialValues?.offer_bid_price;
  let exactPrice = values?.is_own_transport === true ? expectedPrice : yourPrice;
  // if (field.name === 'formData.basicInfo.name_of_farm') {
  //   console.log('\n---------FormikMaterialTextField----------');
  //   console.log('field.name-->', field.name);
  //   console.log('field-->', field);
  //   console.log('props->', props);
  //   console.log('errors->', errors);
  //   console.log('touched->', touched);
  //   console.log('hasError-->', hasError);
  //   console.log('errText-->', errText);
  // }

  const inputStartAdornmentStyles = {
    background: '#c9c2c2',
    maxHeight: '40px',
    padding: 0,
    padding: '20px 10px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    fontWeight: 'bold',
    ...startAdornmentStyles,
  };
  const inputEndAdornmentStyles = {
    background: '#c9c2c2',
    maxHeight: '40px',
    padding: 0,
    padding: '20px 10px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    fontWeight: 'bold',
    margin: '0px',
    ...endAdornmentStyles,
  };

  useEffect(() => {
    if (field.name === 'radius') {
      setFieldValue('span_type_enum', 2);
      setFieldValue('district_to', '');
      setFieldValue('to_city', '');
      setFieldValue('to_pin_code', '');
      setFieldValue('to_state', '');
    }
  }, [field.name]);
  useEffect(() => {
    if (commodityType === COMMODITY_TYPE.REQUIREMENTS && !values?.is_gcv_based && !isRFQ) {
      if (!values?.is_own_transport) {
        if (
          values?.auction_response_qty &&
          values?.delivery_start_date &&
          values?.delivery_end_date &&
          field.name == 'auction_response_price' &&
          _.isEmpty(details)
        ) {
          let newAuctionPrice = target - transportCost - loadingCharges - unloadingCharges;
          setFieldValue('auction_response_price', newAuctionPrice);
        }
      } else {
        if (!isRFQ && _.isEmpty(details)) {
          setFieldValue('landed_price', target);
        }
      }
    } else if (commodityType === COMMODITY_TYPE.OFFERS && !values?.is_gcv_based) {
      if (!values?.is_own_transport) {
        if (
          values?.auction_response_qty &&
          values?.delivery_start_date &&
          values?.delivery_end_date &&
          field.name == 'auction_response_price' &&
          _.isEmpty(details)
        ) {
          let newAuctionPrice = target + transportCost + loadingCharges + unloadingCharges;
          setFieldValue('landed_price', newAuctionPrice);
          setFieldValue('auction_response_price', target);
        }
      } else {
        if (_.isEmpty(details)) {
          setFieldValue('auction_response_price', target);
        }
      }
    }
  }, [values?.auction_response_qty, values?.delivery_start_date, values?.delivery_end_date, !values?.is_own_transport]);

  useEffect(() => {
    if (values?.auction_response_qty?.length === 1 && !values?.is_own_transport) {
      if (commodityType === COMMODITY_TYPE.REQUIREMENTS && !values?.is_gcv_based && !isRFQ) {
        if (values.auction_response_price && transportCost) {
          let newLanded =
            parseFloat(values?.auction_response_price) + transportCost + loadingCharges + unloadingCharges;
          setFieldValue('landed_price', newLanded);
        }
        if (
          values?.auction_response_qty &&
          values?.delivery_start_date &&
          values?.delivery_end_date &&
          field.name == 'auction_response_price' &&
          _.isEmpty(details)
        ) {
          let newAuctionPrice = target - transportCost - loadingCharges - unloadingCharges;
          setFieldValue('auction_response_price', newAuctionPrice);
        }
      }

      if (commodityType === COMMODITY_TYPE.OFFERS && !values?.is_gcv_based) {
        if (
          values?.auction_response_qty &&
          values?.delivery_start_date &&
          values?.delivery_end_date &&
          field.name == 'auction_response_price' &&
          _.isEmpty(details)
        ) {
          let newAuctionPrice = target + transportCost + loadingCharges + unloadingCharges;
          setFieldValue('landed_price', newAuctionPrice);
          setFieldValue('auction_response_price', target);
        }
      }
    }
  }, [transportCost]);

  useEffect(() => {
    getTransportationCharges && formik && getTransportationCharges(formik);
  }, [values]);
  let value;
  if (field.value < 0) {
    value = '';
  } else if (field.name == 'offer_bid_price' && isFca) {
    if (field.value == 0) {
      value = '';
    } else {
      value = field.value;
    }
  } else {
    value = field.value;
  }

  // console.log('check values==>', yourPrice, expectedPrice, exactPrice);
  return (
    <div
      style={mapTeleField ? { width: '400px' } : {}}
      className={
        showPosition
          ? `mui-formik-text-field custom ${hasError ? 'has-error' : ''}`
          : `mui-formik-text-field ${hasError ? 'has-error' : ''}`
      }
    >
      {isKgField ? (
        <div className="cargo-popover">
          <Popover
            placement="top"
            content={`Enter weights in ${invoice_qty_uom_code || 'KG'} now!`}
            trigger="click"
            style={{ color: 'red' }}
          >
            <NumberFormat
              {...field}
              // error={hasError}
              placeholder={placeholder}
              decimalSeparator="."
              thousandsGroupStyle="lakh"
              displayType="input"
              type={'text'}
              thousandSeparator
              disabled={disabled}
              decimalScale={3}
              className="position-absolute"
              style={{
                height: 40,
                width: '92%',
                borderRadius: '5px',
                border: '1px solid #dbdbdb',
                textAlign: 'left',
                paddingLeft: '15px',
                fontSize: 'var(--fs-base__two)',
              }}
              onChange={(event) => {
                const value = event.target.valueAsNumber;
                if (isPercentField) {
                  if ((value <= 100 && event.target.value.match(/^[0-9]+(?:\.[0-9]{1,3})?$/)) || !value) {
                    setFieldValue(field.name, value);
                  }
                } else {
                  setFieldValue(field.name, event.target.value);
                }
                props.cb && props.cb(event.target.value);
              }}
              onWheel={(event) => {
                event.preventDefault();
                // event.target.blur();
              }}
            />
            <div
              className="position-relative"
              style={{ right: isReceipt ? '' : '20px', float: 'right', top: isReceipt ? '' : '5px' }}
            >
              {endAdornmentContent}
            </div>

            {/* <TextField
              {...field}
              fullWidth
              variant={variant || 'outlined'}
              size="small"
              disabled={disabled}
              error={hasError}
              helperText={errText}
              placeholder={placeholder}
              InputProps={
                isAdornment
                  ? {
                      startAdornment:
                        position === 'start' || position === 'both' ? (
                          <InputAdornment style={inputStartAdornmentStyles} position="start">
                            {startAdornmentContent}
                          </InputAdornment>
                        ) : (
                          ''
                        ),
                      endAdornment:
                        position === 'end' || position === 'both' ? (
                          <InputAdornment style={inputEndAdornmentStyles} position="end">
                            {endAdornmentContent}
                          </InputAdornment>
                        ) : (
                          ''
                        ),
                    }
                  : {}
              }
              onChange={(event) => {
                const value = event.target.valueAsNumber;
                if (isPercentField) {
                  if ((value <= 100 && event.target.value.match(/^[0-9]+(?:\.[0-9]{1,3})?$/)) || !value) {
                    setFieldValue(field.name, value);
                  }
                } else {
                  setFieldValue(field.name, event.target.value);
                }
                props.cb && props.cb(event.target.value);
              }}
              onWheel={(event) => {
                // event.preventDefault();
                event.target.blur();
              }}
              type={type}
            /> */}
          </Popover>
        </div>
      ) : (
        <TextField
          {...field}
          fullWidth
          variant={variant || 'outlined'}
          size="small"
          disabled={disabled}
          error={hasError}
          helperText={errText}
          value={value}
          // value={field.value < 0 ? '' : field.value}
          placeholder={placeholder}
          className={isError ? 'custom' : ''}
          multiline={isMultiline}
          InputProps={
            isAdornment
              ? {
                  startAdornment:
                    position === 'start' || position === 'both' ? (
                      <InputAdornment style={inputStartAdornmentStyles} position="start">
                        {startAdornmentContent}
                      </InputAdornment>
                    ) : (
                      ''
                    ),
                  endAdornment:
                    position === 'end' || position === 'both' ? (
                      <InputAdornment style={inputEndAdornmentStyles} position="end">
                        {endAdornmentContent}
                      </InputAdornment>
                    ) : (
                      ''
                    ),
                }
              : {}
          }
          onChange={(event) => {
            const value = event.target.value;
            if (isPercentField) {
              if (isDirectDealEnabled) {
                if (field.name == 'qty') {
                  if (event.target.value?.match(/^(\d+)?(?:\.\d{1,3})?$/)) {
                    setFieldValue(field.name, value);
                    setFieldValue('is_directly_accepted', true);
                  }
                }
              } else if (field.name == 'qty') {
                if (event.target.value?.match(/^(\d+)?(?:\.\d{1,3})?$/)) {
                  setFieldValue(field.name, value);
                }
              } else if (field.name == 'expected_price' || field.name == 'price') {
                if (event.target.value?.match(/^(\d+)?(?:\.\d{1,2})?$/)) {
                  setFieldValue(field.name, value);
                } else if (event.target.value.includes(',')) {
                  // checking if expected_price contains comma, if yes, replace it with empty string, to make it a number so that it can parse
                  setFieldValue(field.name, event.target.value.replace(',', ''));
                }
              } else {
                if ((value <= 100 && event.target.value.match(/^[0-9]+(?:\.[0-9]{1,3})?$/)) || !value) {
                  setFieldValue(field.name, value);
                }
              }
            } else if (isDecimalPriceField) {
              if (
                field.name == 'offer_bid_price' ||
                field.name == 'unloading_charge_value' ||
                field.name == 'loading_charge_value'
              ) {
                if (event.target.value.match(/^(\d+)?(?:\.\d{1,2})?$/)) {
                  setFieldValue(field.name, value);
                }
              } else if (field.name == 'offer_bid_qty') {
                if (event.target.value.match(/^(\d+)?(?:\.\d{1,3})?$/)) {
                  setFieldValue(field.name, value);
                }
              } else {
                if (event.target.value.match(/^[0-9]+(?:\.[0-9]{1,3})?$/) || !value) {
                  setFieldValue(field.name, value);
                }
              }
            } else {
              setFieldValue(field.name, event.target.value);
            }
            if (isDirectDealEnabled) {
              if (formType === 'requirements') {
                if (exactPrice === event.target.value || event.target.value <= exactPrice) {
                  setFieldValue(field.name, value);
                  setFieldValue('is_directly_accepted', true);
                } else {
                  setFieldValue(field.name, value);
                  setFieldValue('is_directly_accepted', false);
                }
              } else if (formType === 'offers') {
                if (exactPrice === event.target.value || event.target.value >= exactPrice) {
                  setFieldValue(field.name, value);
                  setFieldValue('is_directly_accepted', true);
                } else {
                  setFieldValue(field.name, value);
                  setFieldValue('is_directly_accepted', false);
                }
              }
            } else if (isRadiusField) {
              setFieldValue(field.name, parseInt(event.target.value));
            } else {
              setFieldValue(field.name, event.target.value);
              setFieldValue('is_directly_accepted', false);
            }
            props.cb && props.cb(event.target.value);

            if (field.name == 'offer_bid_qty' && props.FetchYourPriceAgainstBuySell) {
              const offerBidQty = parseFloat(event.target.value);
              if (!isNaN(offerBidQty)) {
                props
                  .FetchYourPriceAgainstBuySell(
                    offerBidQty,
                    props.form.values?.address_id,
                    props.form.values?.vehicle_type_id,
                    props.form.values,
                  )
                  .then((resData) => {
                    const updatedOfferBidPrice = resData?.your_price;
                    if (!isUpdateQuote && !isAvoidSettingOfferBidPrice) {
                      props.form.setFieldValue('offer_bid_price', updatedOfferBidPrice);
                    } else if (isAvoidSettingOfferBidPrice) {
                      props.form.setFieldValue('offer_bid_price', updatedOfferBidPrice - resData?.transportation_cost);
                    }
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                  });
              }
            }
          }}
          onWheel={(event) => {
            // event.preventDefault();
            event.target.blur();
          }}
          type={type}
        />
      )}
    </div>
  );
};

export default FormikMaterialTextField;
