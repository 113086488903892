import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const manualInvoiceSchema = yup.object().shape({
  manual_invoice_type_enum: yup
    .number()
    .required('Manual Invoice is required.')
    .positive('Manual Invoice is required.')
    .integer('Manual Invoice is required.'),

  // invoice_category_enum: yup
  //   .number()
  //   .required('Invoice Category is required.')
  //   .positive('Invoice Category is required.')
  //   .integer('Invoice Category is required.'),

  seller_id: yup.number().required('Seller Id is required.'),

  seller_address_id: yup.number().required('Seller Address Id is required.'),

  buyer_id: yup.number().required('Buyer Id is required.'),

  buyer_address_id: yup.number().required('Buyer Address Id is required.'),

  // custom_invoice_number: yup.string().required('Custom Invoice Number is required.'),

  //invoice_date: yup.date().required('Invoice Date is required.'),

  commodity_deal_master_id: yup.number().when('invoice_category_enum', {
    is: 4,
    then: yup
      .number()
      .required('Commodity Deal Master Id is required.')
      .positive('Commodity Deal Master Id is required.')
      .integer('Commodity Deal Master Id is required.'),
  }),

  delivery_id: yup.number().when('invoice_category_enum', {
    is: 4,
    then: yup
      .number()
      .required('Delivery Id is required.')
      .positive('Delivery Id is required.')
      .integer('Delivery Id is required.'),
  }),

  // commodity_deal_master_id: yup.number().required('Commodity Deal Master Id is required.'),

  // transport_contract_no: yup
  //   .number()
  //   .required('Transport Contract No is required.')
  //   .positive('Transport Contract No is required.')
  //   .integer('Transport Contract No is required.'),

  // delivery_id: yup
  //   .number()
  //   .required('Delivery Id is required.')
  //   .positive('Delivery Id is required.')
  //   .integer('Delivery Id is required.'),

  // supplier_reference: yup.string().required('supplier Reference is required.'),

  // other_reference: yup.string().required('Other Reference is required.'),

  // buyer_order_no: yup.string().required('Buyer Order No is required.'),

  // buyer_order_no_date: yup.date().required('Buyer Order No Date is required.'),

  // e_way_bill_no: yup.string().required('E_Way Bill No is required.'),

  // e_way_bill_no_date: yup.date().required('E_Way Bill No Date is required.'),

  transporter_id: yup.number().when('invoice_category_enum', {
    is: 1,
    then: yup
      .number()
      .required('Transporter Id is required.')
      .positive('Transporter Id is required.')
      .integer('Transporter Id is required.'),
  }),

  // .required('Transporter Id is required.')
  // .positive('Transporter Id is required.')
  // .integer('Transporter Id is required.')

  motor_vehicle_no: yup.string().when('invoice_category_enum', {
    is: 1,
    then: yup.string().required('Motor Vehicle No is required.'),
  }),

  verifydoc: yup
    .boolean()
    .required('Please upload document or select Do not have PDF Document option')
    .oneOf([true], 'Please upload document or select Do not have PDF Document option'),
  // payment_due_date: yup.date().required('Payment Due Date is required.'),

  manual_invoice_charge_detail: yup
    .array()
    .of(
      yup.object().shape({
        sac_code: yup.string().required('HSN/SAC is required'),
        cost_type_id: yup
          .number()
          .required('Cost Type is required.')
          .positive('Cost Type is required.')
          .integer('Cost Type is required.'),
        qty: yup.number().required('Quantity is required.').positive('Quantity is required.'),
        // .integer('Quantity is required.'),
        price: yup.number().required('Price is required.'),
        // .positive('Price is required.')
        pricing_type_enum: yup
          .number()
          .required('Price Type is required.')
          .positive('Price Type is required.')
          .integer('Price Type is required.'),
      }),
    )
    .min(1, 'Cost Type is required.'),
});
export const historySchema = yup.object().shape({
  pending_approved_enum: yup.number().required('Please select action').positive('Please select action'),
});

export const commonManualSchema = yup.object().shape({
  manual_invoice_type_enum: yup
    .number()
    .required('Manual Invoice is required.')
    .positive('Manual Invoice is required.')
    .integer('Manual Invoice is required.'),
  seller_id: yup.number().required('Seller Id is required.'),

  seller_address_id: yup.number().required('Seller Address Id is required.'),

  buyer_id: yup.number().required('Buyer Id is required.'),

  buyer_address_id: yup.number().required('Buyer Address Id is required.'),
  //invoice_date: yup.date().required('Invoice Date is required.'),
  transporter_id: yup.number().when('invoice_category_enum', {
    is: 1,
    then: yup
      .number()
      .required('Transporter Id is required.')
      .positive('Transporter Id is required.')
      .integer('Transporter Id is required.'),
  }),
  motor_vehicle_no: yup.string().when('invoice_category_enum', {
    is: 1,
    then: yup.string().required('Motor Vehicle No is required.'),
  }),
  verifydoc: yup
    .boolean()
    .required('Please upload document or select Do not have PDF Document option')
    .oneOf([true], 'Please upload document or select Do not have PDF Document option'),
  // payment_due_date: yup.date().required('Payment Due Date is required.'),

  // commodity_deal_master_id: yup.number().when('invoice_category_enum', {
  //   is: 1,
  //   then: yup
  //     .number()
  //     .required('Commodity Deal Master Id is required.')
  //     .positive('Commodity Deal Master Id is required.')
  //     .integer('Commodity Deal Master Id is required.'),
  // }),

  // delivery_id: yup.number().when('invoice_category_enum', {
  //   is: 1,
  //   then: yup
  //     .number()
  //     .required('Delivery Id is required.')
  //     .positive('Delivery Id is required.')
  //     .integer('Delivery Id is required.'),
  // }),

  manual_invoice_charge_detail: yup
    .array()
    .of(
      yup.object().shape({
        sac_code: yup.string().required('HSN/SAC is required'),
        cost_type_id: yup
          .number()
          .required('Cost Type is required.')
          .positive('Cost Type is required.')
          .integer('Cost Type is required.'),
        qty: yup
          .number()
          .required('Quantity is required.')
          .positive('Quantity is required.')
          .min(0.1, 'Invalid Quantity'),
        // .integer('Quantity is required.'),
        price: yup.number().required('Price is required.'),
        // .positive('Price is required.').min(0.1, 'Invalid Price')
        pricing_type_enum: yup
          .number()
          .required('Price Type is required.')
          .positive('Price Type is required.')
          .integer('Price Type is required.'),
      }),
    )
    .min(1, 'Cost Type is required.'),
});

export const manualDealDeliveriesSchema = yup.object().shape({
  commodity_deal_master_id: yup.number().when('invoice_category_enum', {
    is: 1,
    then: yup
      .number()
      .typeError('Commodity Deal Master Id is required.')
      .required('Commodity Deal Master Id is required.')
      .positive('Commodity Deal Master Id is required.')
      .integer('Commodity Deal Master Id is required.'),
  }),

  delivery_id: yup.number().when('invoice_category_enum', {
    is: 1,
    then: yup
      .number()
      .typeError('Delivery Id is required.')
      .required('Delivery Id is required.')
      .positive('Delivery Id is required.')
      .integer('Delivery Id is required.'),
  }),
});

export const manualPlatformSchema = yup.object().shape({
  commodity_deal_master_id: yup.number().when('invoice_category_enum', {
    is: 2,
    then: yup
      .number()
      .typeError('Commodity Deal Master Id is required.')
      .required('Commodity Deal Master Id is required.')
      .positive('Commodity Deal Master Id is required.')
      .integer('Commodity Deal Master Id is required.'),
  }),

  delivery_id: yup.number().when('invoice_category_enum', {
    is: 2,
    then: yup
      .number()
      .typeError('Delivery Id is required.')
      .required('Delivery Id is required.')
      .positive('Delivery Id is required.')
      .integer('Delivery Id is required.'),
  }),
});

export const manualAssuranceSchema = yup.object().shape({
  commodity_deal_master_id: yup.number().when('invoice_category_enum', {
    is: 3,
    then: yup
      .number()
      .typeError('Commodity Deal Master Id is required.')
      .required('Commodity Deal Master Id is required.')
      .positive('Commodity Deal Master Id is required.')
      .integer('Commodity Deal Master Id is required.'),
  }),

  delivery_id: yup.number().when('invoice_category_enum', {
    is: 3,
    then: yup
      .number()
      .typeError('Delivery Id is required.')
      .required('Delivery Id is required.')
      .positive('Delivery Id is required.')
      .integer('Delivery Id is required.'),
  }),
});

export const manualTransportChargesSchema = yup.object().shape({
  delivery_id: yup.number().when('invoice_category_enum', {
    is: 5,
    then: yup
      .number()
      .typeError('Delivery Id is required.')
      .required('Delivery Id is required.')
      .positive('Delivery Id is required.')
      .integer('Delivery Id is required.'),
  }),
});

export const manualSchema = yup
  .object()
  .concat(commonManualSchema)
  .concat(manualDealDeliveriesSchema)
  .concat(manualPlatformSchema)
  .concat(manualAssuranceSchema);

export const newManualPlatformSchema = yup.object().concat(commonManualSchema);

export const fetchAllCost = async (params = {}) => {
  try {
    let res = await api.fetchAllCost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPreviewForManualInvoice = async (params = {}) => {
  try {
    let res = await api.fetchPreviewForManualInvoice(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchManualInvoicesDraft = async (params = {}) => {
  try {
    let res = await api.fetchManualInvoicesDraft(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchInvoiceApprovalHistory = async (params = {}) => {
  try {
    let res = await api.fetchInvoiceApprovalHistory(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateManualInvoiceHistory = async (params = {}) => {
  try {
    await helpers.validateSchema(historySchema, params);
    const res = await api.saveUpdateManualInvoiceHistory(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateManualInvoice = async (params = {}) => {
  try {
    const manualInvoiceArray = params['manual_invoice_charge_detail'].filter(
      (item) => item.cost_type_id == 15 || item.cost_type_id == 16,
    );
    await helpers.validateSchema(
      params['invoice_category_enum'] === 4 ||
        params['invoice_category_enum'] === 6 ||
        params['invoice_category_enum'] === 7 ||
        params['invoice_category_enum'] === 8 ||
        params['invoice_category_enum'] === 2 ||
        params['invoice_category_enum'] === 3 ||
        (params['invoice_category_enum'] === 1 && manualInvoiceArray.length > 0)
        ? commonManualSchema
        : params['invoice_category_enum'] === 5
        ? manualTransportChargesSchema
        : manualSchema,
      params,
    );
    // delete params['attachment_file'];
    const res = await api.saveUpdateManualInvoice(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createManualInvoice = async (params = {}) => {
  try {
    const res = await api.createManualInvoice(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteManualInvoiceCharge = async (params = {}) => {
  try {
    const res = await api.deleteManualInvoiceCharge(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteManualInvoice = async (params = {}) => {
  try {
    const res = await api.deleteManualInvoice(params);
    return res;
  } catch (err) {
    throw err;
  }
};
