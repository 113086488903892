import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import StarRatings from 'react-star-ratings';
import { Popover, Button } from 'antd';
import { actions as ratingsActions } from 'redux/ratings';
// import RatingsPopupDetails from './RatingsPopupDetails';
import { actions as ratingDetailsActions } from 'redux/ratingDetails';
import NestedRatingModal from 'shared/components/NestedRatingModal';
import LogoLoader from 'common/LogoLoader';
import { getUrlWithEncryptedQueryParams } from 'lib/utils';

const RatingsPopup = ({ isBuyerSellerDiscovery, userId, addressId, rating, company_affiliate_enum, personId, id, entity_id, quality, editPageLink, isPerson, companyName ,openInAnotherTab}) => {
  const dispatch = useDispatch();
  const ratings = useSelector((state) => state.ratings);
  const ratingsArray = _.get(ratings, `entities.undefined[${entity_id}].data`, []);
  const [showDetails, setShowDetails] = useState(false);
  const ratingDetails = useSelector((state) => state.ratingDetails);
  const details = _.get(ratingDetails, 'entities.Participation') || {};
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const busy= useSelector((state) => state.ratings.busy)

  // useEffect(() => {
  //   const params = {
  //     entity_id: entity_id,
  //     company_affiliate_enum: company_affiliate_enum,
  //   };
  //   if (+entity_id) {
  //     if (quality) {
  //       dispatch(ratingsActions.fetchQualityList(params));
  //     } else {
  //       dispatch(ratingsActions.fetchList(params));
  //     }
  //   }
  // }, [entity_id, company_affiliate_enum]);

  const showAllRatings = () =>{
    const params = {
      entity_id: entity_id,
      company_affiliate_enum: company_affiliate_enum,
    };
    if (+entity_id) {
      if (quality) {
        dispatch(ratingsActions.fetchQualityList(params));
      } else {
        dispatch(ratingsActions.fetchList(params));
      }
    }
      if(!busy && ratingsArray?.length > 0){
        gtag('event', 'rating_drill_down_1',
          {
            Participation : ratingsArray.filter((data) => data.rating_type === 'Participation' ).map((data)=> data.ratings ),
            Transaction : ratingsArray.filter((data) => data.rating_type === 'Transaction' ).map((data)=> data.ratings ),
            Quantity : ratingsArray.filter((data) => data.rating_type === 'Quantity' ).map((data)=> data.ratings ),
            Payments : ratingsArray.filter((data) => data.rating_type === 'Payments' ).map((data)=> data.ratings ),
            OnTime : ratingsArray.filter((data) => data.rating_type === 'OnTime' ).map((data)=> data.ratings ),
            totalRating : rating,
            viewPersonId : personId,
            userId : userId ? userId : null,
            companyName : companyName,
            otherCompanyName : ratingsArray.filter((data) => data.rating_type === 'OnTime' ).map((data)=> data.compnay_code ),
          })
      }
  }

  const ratingToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const content = (
    <div className="p-0">
      {rating === 0 ? (
        <p className="rating-heading" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'var(--fs-base__two)', color: 'var(--unnamed-color-1b62ab)', }}>
          Not rated
        </p>
      ) : (
        <>
          <p className="rating-heading" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'var(--fs-base__two)', color: 'var(--unnamed-color-1b62ab)', }}>
            Total Rating {rating}
          </p>
          <ul style={{ padding: 0, margin: 0, listStyleImage: 'none', listStyleType: 'none' }}>
            {ratingsArray.map((item) => {
              return (
                <li style={{ margin: '10px 0px' }}>
                  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 50px', alignContent: 'center' }}>
                    <p style={{ margin: '4px 10px' }}>
                      <span style={{ color: 'var(--unnamed-color-707070)', fontSize: 'var(--fs-base__three)', }}>{item.rating_type || ''}</span>
                    </p>
                    <p style={{ margin: '0px 0px 0px 0px' }}>
                      <StarRatings
                        rating={item.ratings ? item.ratings : 0}
                        starRatedColor="#ffcc33"
                        numberOfStars={5}
                        starDimension="15px"
                        starSpacing="1px"
                        name="rating"
                      />
                    </p>
                    <p style={{ margin: '4px 10px' }}>
                      <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>{item.ratings || 0}</span>
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
          <hr />

          <div
            className="text-center"
            onClick={(e) => {
              setIsEditorOpen(true);
            }}
            style={{ color: 'var(--unnamed-color-1b62ab)', cursor: 'pointer' }}>
            What do these ratings mean?
          </div>
          <div className="nested-modal">
            {isEditorOpen && (
              <NestedRatingModal
                ratingsArray={ratingsArray}
                extendedProfilePersonId={personId}
                userId={userId}
                isOpen={isEditorOpen}
                toggle={ratingToggle}
                company_affiliate_enum={company_affiliate_enum}
                entity_id={entity_id}
                  editPageLink={getUrlWithEncryptedQueryParams(editPageLink)}
                openInAnotherTab={openInAnotherTab}
                rating={rating}
                isPerson={isPerson}
                companyName={companyName}
              />
            )}
          </div>
        </>
      )}
    </div>
  );


  return (
    <div className="demo">
      <div style={{ clear: 'both', whiteSace: 'nowrap' }}>
         <Popover placement="rightTop" content={busy ? <LogoLoader /> : content} onMouseEnter={showAllRatings} >
          <p className={isBuyerSellerDiscovery ? "no-title-rating" : "rating"}>
            <StarRatings
              rating={rating ? rating : 0}
              starRatedColor="#ffcc33"
              numberOfStars={5}
              starDimension="18px"
              starSpacing="1px"
              name="rating"
              trigger="click"
            />
          </p>
        </Popover>
      </div>
    </div>
  );
};

export default RatingsPopup;
