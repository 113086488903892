import React from 'react';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import api from 'lib/api';

const OTPTimer = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [attempt, setAttempt] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const onResendClick = async (e) => {
    e.preventDefault();

    // console.log('onResendClick->props---params-->', props.params);
    setLoading(true);

    let params = {
      ...(props.params || {}),
      isResend: true,
    };

    try {
      let res = await props.handleResendOTP(params);
      // console.log('onResendClick->res', res);
      setLoading(false);
      setAttempt(attempt + 1);
      setSeconds(30);
    } catch (err) {
      console.log('onResendClick->err', err);
      // throw new SubmissionError(err)
      setLoading(false);
    }
  };

  const minTxt = minutes;
  const secTxt = seconds < 10 ? `0${seconds}` : seconds;
  const isCountdownVisible = minutes || seconds;

  const isResendDisabled = loading || isCountdownVisible || attempt >= 3;

  return (
    <div className="otp-timer">
      <p className="otp-helper-text">
        <br />
        Didn't receive OTP?
      </p>
      <p className="otp-clock-container">
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status"></span>
        ) : (
          <span className={classnames('otp-timer-clock', { invisible: !isCountdownVisible })}>
            {minTxt}:{secTxt}
          </span>
        )}

        {isResendDisabled ? (
          <span className="otp-resend-btn disabled">Resend OTP</span>
        ) : (
          <span className="otp-resend-btn" onClick={onResendClick}>
            Resend OTP
          </span>
        )}
      </p>
    </div>
  );
};

export default OTPTimer;
