import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import LogoLoader from 'common/LogoLoader';
import { actions as kycBusinessCompanyActions } from 'redux/companyAffiliates';
import { COMPANY_AFFILIATE_ENUM } from 'constants/enums';
import KycCommentspopUp from '../KYCBusiness/components/kycCommentspopUp';
import PopupForm from 'common/Form/PopupForm';
import { Table, Radio, Divider } from 'antd';
import {  Skeleton } from 'antd';

// import scss file here
import 'antd/dist/antd.css';
import { getDecryptedUrl } from 'lib/utils';


const CompanyAffiliateTable = (props) => {
  const id = props.id;
  const companies = useSelector((state) => state.kycBusinessCompany);
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  const companiesList = companies.entities || {};
  let mainCompany = Object.values(companiesList).find(
    (x) => x.company_affiliate_enum === COMPANY_AFFILIATE_ENUM.COMPANY,
  );

  let companyAffilates = Object.values(companiesList).filter(
    (x) => x.company_affiliate_enum !== COMPANY_AFFILIATE_ENUM.COMPANY,
  );

  let company = Object.values(companiesList).filter(
    (x) => x.company_affiliate_enum === COMPANY_AFFILIATE_ENUM.COMPANY,
  );
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const busy = _.get(companies, 'busy') || false;
  let pathname = getDecryptedUrl(window.location.href);
  // let tableData = busy ? [] : Object.values(companiesList || {});
  let tableData = busy ? [] : [...company, ...companyAffilates];

  const dispatch = useDispatch();
  const [showPopUp, setPopUp] = useState(false);
  const [params, setParams] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid && CT_parent) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  const columns = [
    {
      title: 'Business Entity',
      dataIndex: 'display_member',
      Align: 'center',
      width: '400px',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
  ];

  if (pathname.includes('kyc-b') || pathname.includes('upload')) {
    columns.push({
      title: 'KYC Status',
      Align: 'center',
      dataIndex: 'kyc_status_enum_code',
      width: '250px',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    });
  }

  if (pathname.includes('kyc-b')) {
    columns.push({
      title: '',
      dataIndex: 'kyc_status_enum',
      width: '200px',
      key: 'value_member',
      render: (text, row) => {
        let params = {
          entity_id: row.value_member,
          details: row,
          // user_type_enum: 1,
          company_affiliate_enum: row.company_affiliate_enum,
        };
        return (
          <div className="bfc-table-list-components text-center">
            {isAdmin ? (
              <span
                onClick={() => {
                  setPopUp(true);
                  setParams(params);
                  setSelectedCompany(row);
                }}
                style={{ color: 'var(--unnamed-color-1b62ab)', textDecoration: 'underline', cursor: 'pointer' }}>
                Update Status
              </span>
            ) : (
              <span
                onClick={() => {
                  setPopUp(true);
                  setParams(params);
                  setSelectedCompany(row);
                }}
                style={{ color: 'var(--unnamed-color-1b62ab)', textDecoration: 'underline', cursor: 'pointer' }}>
                KYC Comments
              </span>
            )}
          </div>
        );
      },
    });
  }

  props.onChangeInUser(selectedCompany);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: (event) => {
      setSelectedCompany(event);
    },
    onChange: onSelectChange,
  };

  useEffect(() => {
    let params = {
      person_id: parseInt(id),
    };
    dispatch(kycBusinessCompanyActions.fetchCompanyAndAffiliatesAgainstPersonId(params));
    // dispatch(kycBusinessActions.fetchKYCBusinessIdentityDetails({}));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(mainCompany)) {
      setSelectedCompany(mainCompany);
      setSelectedRowKeys([mainCompany?.value_member]);
    }
  }, [busy]);

  // if (busy) {
  //   return (
  //     <div className="bfc-body bfc-form">
  //       <div className="panel__refresh">
  //         <LogoLoader />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      {showPopUp && (
        <PopupForm title={isAdmin ? 'Update KYC Status' : 'KYC Comments'} isShow={true} onClose={() => setPopUp(false)}>
          <KycCommentspopUp
            params={params}
            // close={() => setPopUp(false)}
            entity_id={selectedCompany.value_member}
            company_affiliate_enum={selectedCompany.company_affiliate_enum}
            isAdmin={isAdmin}
            id={id}
          />
        </PopupForm>
      )}
      <div className="d-flex justify-content-left">
        <div className="row">
          <div className="col-md-12">
            <Table
              style={{ Align: 'center' }}
              className="ant-table"
              size="small"
              rowKey={(record) => record.value_member}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              pagination={false}
              columns={columns}
              dataSource={tableData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAffiliateTable;
