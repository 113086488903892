import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
    //   user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
    //   company_affiliate_enum: '',
    // };

    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['entity_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum') || 0,
          company_affiliate_enum: '',
        };
      } else {
        qsParams = {
          ...params,
          entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
          company_affiliate_enum: '',
        };
      }
    } else {
      qsParams = {
        ...params,
        entity_id: params['person_id'] || _.get(storeState, 'user.userid', 0),
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum') || 0,
        company_affiliate_enum: '',
      };
    }

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    const storeData = globalThis.store.getState();
    const user = _.get(storeData, 'user');
    const acl = utils.getACL(user);
    let bodyParams = {};
    if (acl.isIndividual) {
      bodyParams = {
        ...params,
        entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
        number_of_vehicles: parseInt(_.get(params, 'number_of_vehicles') || 0),
        max_load_capacity: parseInt(_.get(params, 'max_load_capacity') || 0),
        user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
        company_affiliate_enum: 1,
        capacity_uom_id: 1,
        operation_Enum: 1,
      };
    } else if (acl.isCompany) {
      bodyParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        number_of_vehicles: parseInt(_.get(params, 'number_of_vehicles') || 0),
        max_load_capacity: parseInt(_.get(params, 'max_load_capacity') || 0),
        user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
        capacity_uom_id: 1,
        operation_Enum: 1,
      };
    }

    return bodyParams;
  },

  update(id, params) {
    const storeData = globalThis.store.getState();
    const user = _.get(storeData, 'user');
    const acl = utils.getACL(user);
    let bodyParams = {};
    if (acl.isIndividual) {
      bodyParams = {
        ...params,
        entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
        number_of_vehicles: parseInt(_.get(params, 'number_of_vehicles') || 0),
        max_load_capacity: parseInt(_.get(params, 'max_load_capacity') || 0),
        user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
        company_affiliate_enum: 1,
        capacity_uom_id: 1,
        operation_Enum: 3,
      };
    } else if (acl.isCompany) {
      bodyParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        number_of_vehicles: parseInt(_.get(params, 'number_of_vehicles') || 0),
        max_load_capacity: parseInt(_.get(params, 'max_load_capacity') || 0),
        user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
        capacity_uom_id: 1,
        operation_Enum: 3,
      };
    }

    return bodyParams;
  },

  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let transportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(transportData, 'transport_detail_id');
  },
  fetchDetails(resData) {
    return {
      entity_id: _.get(resData, 'company_id') || _.get(resData, 'affiliate_id') || 0,
      entity_name: _.get(resData, 'company_display_name') || _.get(resData, 'affiliate_display_name') || '',
      // company_display_name:  _.get(resData, 'company_display_name') || '' ,

      //@TODO
      company_affiliate_enum: _.get(resData, 'company_id') ? 1 : 2,

      base_location_id: _.get(resData, 'base_location_id') || 0,
      base_location_name: _.get(resData, 'base_location_code') || '',
      capacity_uom_id: _.get(resData, 'capacity_uom_id') || 0,
      capacity_uom_name: _.get(resData, 'capacity_uom_name') || '',
      goods_type_enum: _.get(resData, 'goods_type_enum') || 0,
      goods_type_enum_text: _.get(resData, 'goods_type_enum_code') || '',
      max_load_capacity: _.get(resData, 'max_load_capacity') || 0,
      number_of_vehicles: _.get(resData, 'number_of_vehicles') || 0,
      person_id: _.get(resData, 'person_id') || '',
      transport_detail_id: _.get(resData, 'transport_detail_id') || 0,
      vehicle_type_enum: _.get(resData, 'vehicle_type_enum') || 0,
      vehicle_type_enum_text: _.get(resData, 'vehicle_type_enum_code') || '',
      vehicle_type_id: _.get(resData, 'vehicle_type_id') || 0,
      vehicle_type_code: _.get(resData, 'vehicle_type_code') || '',
      owned_rented_enum_code: _.get(resData, 'owned_rented_enum_code') || '',
      owned_rented_enum: _.get(resData, 'owned_rented_enum'),
      truck_type_enum: _.get(resData, 'truck_type_enum'),
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
