import React from 'react';
import LogoLoaderGif from 'assets/images/gif/bfc-logo-gif.gif';

const LogoLoader = (props) => {
	const { loading = true, type, minHeight, style, position, imgHeight, imgTop } = props;

	const minNHeight='calc(100vh - 125px)';

	if (!loading) {
		return null;
	}

	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={style ? style : {
				position: position ? 'initial' : 'relative',
				minHeight: minHeight ? minHeight : minNHeight,
				width: '100%',
			}}>
			<div style={{ display: 'contents' }}>
				<img
					src={LogoLoaderGif}
					alt=""
					style={{ width: imgHeight ? imgHeight : '100px', height: imgHeight && imgHeight, top: imgTop && imgTop }} />
			</div>
		</div>
	);
};

export default LogoLoader;
