import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  create(params = {}) {
    let qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    let qsParams = {
      subscription_plan_id: id,
    };

    return utils.getQs(qsParams);
  },
  fetchKycUserSubscriptionDetails(params) {
    let qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },

  updateCoupon(params) {
    let qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },

  createContact() {
    const storeState = globalThis.store.getState();
    const user = _.get(storeState, 'user') || {};
    const firstName = _.get(user, 'profile.person.first_name') || '';
    const lastName = _.get(user, 'profile.person.last_name') || '';
    let admin = '';
    if (_.get(user, 'profile.person.is_company_admin') == true) {
      admin = 'Admin';
    }
    let bodyParams = {
      name: firstName + ' ' + lastName,
      email: _.get(user, 'profile.person.email_id') || '',
      phone: _.get(user, 'profile.person.mobile_number') || '',
      query_description: 'Enterprise Pack Price',
      company_name: _.get(user, 'profile.person.company_code') || '',
      designation: admin,
      know_about_us_enum: 1,
      enquiry_record_enum: 1,
    };

    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let planData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(planData, 'subscription_plan_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'subscription_plan_id') || 0,
    };
    return res;
  },
  fetchSubcriptionDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'subscription_plan_id') || 0,
    };
    return res;
  },
  fetchUserSubscriptionList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'invoice_master_id') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchUserSubscriptionDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'subscription_entity_id') || 0,
    };
    return res;
  },
  create(resData) {
    return resData;
  },
  createContact(resData) {
    return resData;
  },
};
