import React, { useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import NotificationActivities from './NotificationActivities';
import * as handlers from '../handlers';
const NotificationTabs = () => {
  const user = useSelector((state) => state.user);
  const sid = _.get(user, 'social_profile_id', 0);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const params = {
    notification_details_ids: [],
    is_read: true,
  };

  return (
    <Col md={12}>
      <Card className="pb-0">
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap" style={{ minWidth: 'auto' }}> 
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggleTab('1');
                  }}>
                  Notifications
                </NavLink>
              </NavItem>
            </Nav>
            <span className="float-right" style={{ margin: '-30px 10px' }}>
              <button
                className="topbar__collapse-button"
                type="button"
                onClick={() => handlers.handleMarkAll(params, sid, dispatch)}
                style={{ fontSize: 'var(--fs-base__three)', }}>
                Mark all as read
              </button>
            </span>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="pt-0">
                <NotificationActivities />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default NotificationTabs;
