import React from 'react';
import profileThumb from 'assets/images/placeholders/profileThumb.png';
import profileBg from 'assets/images/placeholders/profileBg.jpg';
import LogoLoader from 'common/LogoLoader';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import BasicCarousel from './BasicCarousel';
import { Link } from 'react-router-dom';

function PostLoader({ position }) {
  return (
    <div
      className="bfc-feed"
      style={{
        position: position ? 'initial' :'relative',
        minHeight: '250px',
      }}>
      <div className="panel__refresh">
        <LogoLoader position={position} />
      </div>
      <div className="row">
        <div className="bfc-feedAccountIcon">
          <span
            style={{
              backgroundImage: `url(${profileThumb})`,
            }}
          />
        </div>
        <p>
          <Link to={`#`}>
            <strong>
              <span style={{ color: 'var(--unnamed-color-1b62ab)', }}></span>
            </strong>{' '}
          </Link>
          <br />
          {/*`01-Jan-2020 05:30 PM`*/}
          <br />
          <br />
        </p>
      </div>
      <div className="bfc-feedInfo" dangerouslySetInnerHTML={{ __html: '' }} />

      <div className="bfc-feed-carousal d-none">
        <BasicCarousel images={[profileBg]} />
      </div>

      <div className="">
        <div className="bfc-socialfeedAction"></div>
        <div className="d-none">
          <input
            className="bfc-socialfeedCommentBox"
            type="text"
            placeholder="Add comment here"
            aria-label="Search"
          />
          <div className="row bfc-socialfeedUserComments">
            <AccountCircleIcon className="bfc-feedAccountIcon" />
            <p className="userName">
              <strong>User Name</strong> <br /> Such an inspiring post, thank you for the updates
            </p>
          </div>
          <p className="bfc-socialfeedMoreComments">
            View more comments <br />
            <ChevronDownIcon color="grey" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default PostLoader;
