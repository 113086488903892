import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const UserUploadSchema = yup.object().shape({
  entity_type_enum: yup.number().required().positive('Please select Authorised Signatorys Address Proof'),
});

export const upload = async (params, entity_type_enum, front_back_enum, entity_id, attachmentIdToPass) => {
  try {
    await helpers.validateSchema(UserUploadSchema, { entity_type_enum });
    const res = await api.upload(params, entity_type_enum, front_back_enum, entity_id, attachmentIdToPass);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id, dispatch) => {
  try {
    let res = await api.fetchDetails(id, dispatch);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params, dispatch) => {
  try {
    let res = await api.fetchList(params, dispatch);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getAttachment = async (params, dispatch) => {
  try {
    let res = await api.getAttachment(params, dispatch);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handleQualityLabUpload = async (
  params,
  entity_type_enum,
  front_back_enum,
  entity_id,
  attachmentIdToPass,
  document_module_enum,
  company_affiliate_enum,
) => {
  try {
    await helpers.validateSchema(UserUploadSchema, { entity_type_enum });
    const res = await api.handleQualityLabUpload(
      params,
      entity_type_enum,
      front_back_enum,
      entity_id,
      attachmentIdToPass,
      document_module_enum,
      company_affiliate_enum,
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchQualityLabList = async (qualityId) => {
  try {
    let res = await api.fetchQualityLabList(qualityId);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchExtendedProfilePhotosList = async (qualityId) => {
  try {
    let res = await api.fetchExtendedProfilePhotosList(qualityId);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchManualInvoiceDocumentList = async (params) => {
  try {
    let res = await api.fetchManualInvoiceDocumentList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
