import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Drivers
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/FetchAllDriversForTransporter`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

//Add Driver
export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveUpdateDriver`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveUpdateDriver-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDriver');
    throw errors;
  }
};

//Update Driver
export const update = async (id, params) => {
  try {
    let bodyParams = params?.isAggregatorServiceContract ? { ...params } : reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveUpdateDriver`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveUpdateDriver-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDriver');
    throw errors;
  }
};

//Delete Driver
export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/DeleteDriverForTransporter?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('DeleteDriverForTransporter->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteDriverForTransporter');
    throw errors;
  }
};

//Driver Details by Mobile
export const fetchDriverDetailsByMobile = async (params = {}) => {
  try {
    const qs = reqTransform.fetchDriverDetailsByMobile(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/FetchAllDriversByPhone?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversByPhone');
    throw errors;
  }
};

//Link Driver to Transport Company
export const link = async (id, params) => {
  try {
    let qs = reqTransform.link(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/DriverVehicle/SaveDriverAgainstTransporterCompanyId?${qs}`;

    const res = await utils.fetch(url, {
      method: 'POST',
      // body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.link(resData);
  } catch (err) {
    console.log('SaveDriverAgainstTransporterCompanyId-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveDriverAgainstTransporterCompanyId');
    throw errors;
  }
};
export const eKycDocumentVerification = async (params = {}) => {
  try {
    let bodyParams = reqTransform.eKycDocumentVerification(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/KYCVerification`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.eKycVerification(resData);
  } catch (err) {
    console.log('KYCVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'KYCVerification');
    throw errors;
  }
};

export const createReposOrder = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/Repos/CreateReposOrder`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'CreateReposOrder');
    throw errors;
  }
};
