/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = '/BioFuelTransportRequirementOffers/api';

export type TransportDealType = {
  transport_deal_master_id: number;
  buyer_person_id: number;
  seller_person_id: number;
  transport_req_offer_id: number;
  buy_sell_initial_enum: number;
  buy_sell_initial_enum_code: string;
  deal_initiated_by_enum: number;
  deal_initiated_by_enum_code: string;
  buyer_company_id: number;
  seller_company_id: number;
  deal_date: Date;
  deal_status_enum: 2;
  deal_status_enum_code: string;
  hire_type_enum: number;
  hire_type_enum_code: string;
  number_of_vehicles: 50;
  max_capacity_of_vehicle_type: number;
  max_capacity_uom_id: number;
  max_capacity_uom_code: string;
  from_date: Date;
  to_date: Date;
  rate_currency_id: number;
  rate_currency_code: string;
  rate_uom_id: number;
  rate_uom_code: string;
  minimum_km_per_day: number;
  extra_run_rate_per_km: number;
  payment_term_id: number;
  payment_term_code: string;
  req_offer_status_enum: number;
  req_offer_status_enum_code: string;
  offer_validity_datetime: string;
  vehicles_available_number: number;
};

export type BalanceQuantityType = {
  table_id: number;
  requirement_qty: number;
  purchased_qty: number | null;
  balance_qty: number | null;
  period_name: string;
};

const FetchAllTransportDealDetails = async (page: number, pageSize: number) => {
  const urlParams = {
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/TransportDeal/FetchAllTransportDealDetails_ELOC?${qs}`;

  const list = await fetch.fetchList<TransportDealType>(url);

  return list;
};

const FetchTransportDealDetailsAgainstTransportDealMasterId = async (transportReqOfferId) => {
  const urlParams = {
    transport_req_offer_id: transportReqOfferId,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/TransportDeal/FetchTransportDealDetailsAgainstTransportDealMasterId?${qs}`;

  const details = await fetch.fetchOne<TransportDealType>(url);

  return details;
};

const FetchBalanceQuantity = async (transportReqOfferId) => {
  const urlParams = {
    transport_req_offer_id: transportReqOfferId,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/TransportDeal/GetBalanceQuantity?${qs}`;

  const bqDetails = await fetch.fetchList<BalanceQuantityType>(url);

  return bqDetails;
};

export default {
  FetchBalanceQuantity,
  FetchAllTransportDealDetails,
  FetchTransportDealDetailsAgainstTransportDealMasterId,
};
