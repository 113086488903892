import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import moment from 'moment';
import AddCircle from '@material-ui/icons/AddCircle';
import ImportIcon from '@material-ui/icons/GetApp';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import { Formik, Field, Form } from 'formik';
import { Table, Tag, Tooltip, Switch, Popover } from 'antd';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useHistory, Link } from 'react-router-dom';
import LogoLoader from 'common/LogoLoader';
import CachedIcon from '@material-ui/icons/Cached';
import { toast } from 'react-toastify';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DeleteMembersIcon from 'assets/images/icons/delete-members-icon.svg';

import AntPagination from 'common/AntTablePagination';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
import PopupForm from 'common/Form/PopupForm';
import { actions as memberActions } from 'redux/members';
import { actions as dataSetupActions } from 'redux/dataSetup';
import useQuery from 'lib/hooks/useQuery';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import searchLocation from 'common/Form/searchLocation';
import FilterUtils from 'lib/filterUtils';
import AntTableActions from 'common/TableActions';

import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import DialogPopup from 'common/DialogPopUp';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// scss
import './members.scss';
import { getUrlWithEncryptedQueryParams } from 'lib/utils';


const Members = ({ kycUserPersonId }) => {
  const memberState = useSelector((state) => state?.members);
  const members = Object.values(memberState?.entities);
  const busy = memberState?.busy;
  const existingMembers = members?.existingMembers;
  const dataExtractionData = useSelector((state) => state.dataSetup);
  const downloadedData = _.get(dataExtractionData, 'downloadedReport') || [];
  const hasMorePost = _.get(dataExtractionData, 'hasMore') || [];
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();

  const { page = 1, full_name, mobile_number, fpo_membership_id, kyc_status_enum_code, location, isUserKyc } = useQuery();

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isLinkOpen, setIsLinkOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  useEffect(() => {
    const params = {
      filters,
      bf_Social_Group_Id: 0,
      group_Member_Type_Enum: 1,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_Size: rowsPerPage,
    };
    if(Boolean(isUserKyc)) {
      params.person_id = kycUserPersonId;
    }
    dispatch(memberActions.fetchMembersAgainstBfSocialGroupId(params));

    const uploadParam = {
      offset: 0,
      page_size: 10,
      DownloadUpload_File_Enum: 2,
    };
    dispatch(dataSetupActions.fetchDownloadedReport(uploadParam));
  }, [isReRenderRequired, filters, rowsPerPage, page]);

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'farmer_location_code':
        delete params['location'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('location'));
        break;
      case 'kyc_status_enum_code':
        delete params['kyc_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('kyc_status_enum'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'farmer_location_code':
        params = {
          ...params,
          location: e,
        };
        break;
      case 'kyc_status_enum_code':
        params = {
          ...params,
          kyc_status_enum: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const deletedContent = <p style={{ lineHeight: '10px'}}>User has Deleted this Account</p>;


  const MEMBERS_TABLE_HEADER = [
    {
      title: 'Full Name',
      dataIndex: 'first_name',
      key: 'full_name',
      width: '200px',
      align: 'left',
      fixed: true,
      sorter: (a, b) => a.full_name && a.full_name.localeCompare(b.full_name && b.full_name),
      render: (text, record) => {
        return (
          <>
            {record.is_active ? (
              <span className="bfc-table-list-components text-center d-flex justify-content-start align-items-center">
                <Link className="mr-2" to={getUrlWithEncryptedQueryParams(`/social/profile/about/${record.social_profile_id}`)}>
                  {record.full_name}
                </Link>
                {record?.is_group_admin ? (
                  <Tooltip title="FPO Admin">
                    <SupervisorAccountIcon style={{ fill: 'var(--unnamed-color-1b62ab)' }} />
                  </Tooltip>
                ) : (
                  ''
                )}
              </span>
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-start">
                  <Popover placement="top" content={deletedContent}>
                    <IconButton size="small">
                      <img src={DeleteMembersIcon} alt={DeleteMembersIcon} height="24" width="24"/>
                    </IconButton>
                  </Popover>
                  <span className="bfc-table-list-components text-center d-flex justify-content-start align-items-center">
                      <Link className="mr-2" to={getUrlWithEncryptedQueryParams(`/social/profile/about/${record.social_profile_id}`)}>
                      {record.full_name}
                    </Link>
                  </span>
                </div>
              </>
            )}
          </>
        )
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'full_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={full_name}
          name={'Name'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'full_name'),
      filteredValue: full_name !== undefined ? full_name : null,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      width: '200px',
      align: 'center',
      // sorter: (a, b) => a.full_name && a.full_name.localeCompare(b.full_name && b.full_name),
      render: (text, record) => <span>{record.mobile_number}</span>,
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'mobile_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={mobile_number}
          name={'Mobile No.'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'mobile_number'),
      filteredValue: mobile_number !== undefined ? mobile_number : null,
    },
    {
      title: 'Location',
      dataIndex: 'location_code',
      width: '250px',
      align: 'left',
      render: (text, record) => <span>{record.location_code}</span>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'farmer_location_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={location}
          codeBaisedFilter={true}
          name={'Location'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'location'),
      filteredValue: location !== undefined ? location : null,
    },
    {
      title: 'Is Member',
      dataIndex: 'membership_number',
      width: '100px',
      align: 'center',
      render: (text, record) => (
        <span>
          {record.fpo_membership_id ? (
            <IconButton size="small">
              <CheckCircleIcon style={{ fill: 'var(--unnamed-color-32a836)' }} />
            </IconButton>
          ) : (
            <IconButton size="small">
              <CancelIcon style={{ fill: 'var(--unnamed-color-1b62ab)' }} />
            </IconButton>
          )}
        </span>
      ),
      // filterDropdown: (props) => (
      //   <TextSearchFilter
      //     dataIndex={'fpo_membership_id'}
      //     data={props}
      //     onFilterChange={onFilterChange}
      //     handleReset={handleReset}
      //     value={fpo_membership_id}
      //     name={'Membership No.'}
      //   />
      // ),
      // filterIcon: (filtered) => getSearchIcon(filtered, 'fpo_membership_id'),
      // filteredValue: fpo_membership_id !== undefined ? fpo_membership_id : null,
    },
    {
      title: 'Membership No.',
      dataIndex: 'membership_number',
      width: '200px',
      align: 'center',
      render: (text, record) => <span>{record.fpo_membership_id}</span>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'fpo_membership_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={fpo_membership_id}
          name={'Membership No.'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'fpo_membership_id'),
      filteredValue: fpo_membership_id !== undefined ? fpo_membership_id : null,
    },
    {
      title: 'Bank A/C No.',
      dataIndex: 'account_number',
      width: '200px',
      align: 'center',
      render: (text, record) => <span>{record.account_number}</span>,
      // filterDropdown: (props) => (
      //   <NumericSearchFilter
      //     dataIndex={'account_number'}
      //     data={props}
      //     onFilterChange={onFilterChange}
      //     handleReset={handleReset}
      //     value={account_number}
      //     name={'Account No.'}
      //   />
      // ),
      // filterIcon: (filtered) => getSearchIcon(filtered, 'account_number'),
      // filteredValue: account_number !== undefined ? account_number : null,
    },
    {
      title: 'KYC Status',
      dataIndex: 'kyc_status_enum_code',
      key: 'kyc_status_enum_code',
      width: '200px',
      sorter: (a, b) =>
        a.kyc_status_enum_code &&
        a.kyc_status_enum_code.localeCompare(b.kyc_status_enum_code && b.kyc_status_enum_code),
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Pending' && (
            <Tag color={'lime'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Rejected' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Approved' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
          {(text == 'Not Submitted' || text == 'NotSubmitted') && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'kyc_status_enum_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={kyc_status_enum_code}
          name={'KYC Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'kyc_status_enum_code'),
      filteredValue: kyc_status_enum_code !== undefined ? kyc_status_enum_code : null,
    },
    ...(!Boolean(isUserKyc) ? [{
      title: '',
      align: 'center',
      width: '100px',
      render: (text, record) => (
        <AntTableActions
          row={record}
          showFlag={true}
          controls={[
            { name: 'Edit', onClick: () => handelEdit(record), controlColor: 'var(--unnamed-color-1b62ab)' },
            ...(!record?.is_group_admin
              ? [
                {
                  name: 'Remove',
                  onClick: () => {
                    setSelectedMember(record);
                    setIsOpen(true);
                  },
                  controlColor: '#ff4d4f',
                },
              ]
              : []),
          ]}
        />
      ),
    }] : []),
  ];

  const handelEdit = (record) => {
    const { setFieldValue } = record?.formik;
    setIsEdit(true);
    setFieldValue('first_name', record?.first_name);
    setFieldValue('last_name', record?.last_name);
    setFieldValue('mobile_number', record?.mobile_number);
    setFieldValue('membership_number', record?.fpo_membership_id);
    setFieldValue('ifsc_code', record?.ifsc_code);
    setFieldValue('pan_id', record?.pan_id);
    setFieldValue('account_number', record?.account_number);
    setFieldValue('location_code', record?.location_code);
    setFieldValue('pincode', record?.pin_code);
    setFieldValue('village_code', record?.village_code);
    setFieldValue('district_code', record?.district_code);
    setFieldValue('village_district', record?.district_code);
    setFieldValue('city_code', record?.city_code);
    setFieldValue('state_code', record?.state_code);
    setFieldValue('place_id', record?.place_id);
    setFieldValue('locality', record?.locality);
    setFieldValue('address_line1', record?.address_line1);
    setFieldValue('address_line2', record?.address_line2);
    setFieldValue('address_line3', record?.address_line3);
    setFieldValue('size_of_farm', record?.size_of_farm);
    setFieldValue('address_lattitude', record?.address_lattitude);
    setFieldValue('address_longitude', record?.address_longitude);
    setFieldValue('location_id', record?.location_id);
    setFieldValue('country_code', record?.country_code);
    setFieldValue('address_text', record?.address_text);
    setFieldValue('person_id', record?.person_id);
    setFieldValue('bf_social_group_participant_id', record?.bf_social_group_participant_id);
    setFieldValue('is_non_member', record?.fpo_membership_id ? false : true);
  };

  const handelUpdate = async (record, formik) => {
    const params = {
      bf_social_group_participant_id: record?.bf_social_group_participant_id,
      fpo_membership_id: record?.membership_number,
      is_fpo_member: !record?.is_non_member
    };
    const success = await dispatch(memberActions.updateFPOMembershipId(params));
    if (success) {
      handelClose(formik);
      setIsReRenderRequired(!isReRenderRequired);
      toast.success('Membership id updated successfully');
    }
  };

  const handelDelete = (record) => {
    const params = {
      bf_social_group_participant_id: record?.bf_social_group_participant_id,
    };
    const success = dispatch(memberActions.removeParticipantFromGroup(params));
    if (success) {
      setIsReRenderRequired(!isReRenderRequired);
      toast.success('Member deleted successfully');
      setIsOpen(false)
    }
  };

  let tableData = [...members];
  let totalRecords = tableData?.[0]?.total_count || 0;

  const initialValues = {
    first_name: _.get(members, 'first_name') || '',
    last_name: _.get(members, 'last_name') || '',
    mobile_number: _.get(members, 'mobile_number') || '',
    membership_number: _.get(members, 'membership_number') || '',
    pan_id: _.get(members, 'pan_id') || '',
    location_code: _.get(members, 'location_code') || '',
    account_number: _.get(members, 'account_number') || '',
    ifsc_code: _.get(members, 'ifsc_code') || '',
    is_non_member: _.get(members, 'is_fpo_member') || false,
  };

  const handelClose = (formik) => {
    setIsEdit(false);
    setOpen(false);
    setIsImportOpen(false);
    setIsLinkOpen(false);
    formik && formik.resetForm();
    dispatch(memberActions.setFarmers([]));
  };

  const handleSubmit = async (values, formik) => {
    let success;
    if (isLinkOpen) {
      const params = {
        bf_social_group_id: members?.[0]?.bf_social_group_id,
        farmer_person_id: values?.person_id,
        fpo_membership_id: values?.membership_number ? String(values?.membership_number) : '',
      };
      success = await dispatch(memberActions.linkExistingFarmer(params));
    } else {
      if (isEdit) {
        handelUpdate(values, formik);
      } else {
        const params = {
          first_name: values?.first_name,
          middle_name: values?.middle_name || '',
          last_name: values?.last_name,
          email_id: '',
          mobile_number: values?.mobile_number ? String(values?.mobile_number) : '',
          address_lattitude: values?.address_lattitude,
          address_longitude: values?.address_longitude,
          location_id: values?.location_id,
          location_code: values?.location_code,
          is_transaction_address: true,
          agent_id: 0,
          is_newsLetter_subscribed: true,
          village_code: values?.village_code,
          district_code: values?.district_code,
          city_code: values?.city_code,
          state_code: values?.state_code,
          place_id: values?.place_id,
          locality: values?.locality,
          country_code: values?.country_code || '',
          address_text: values?.address_text || '',
          pincode: values?.pincode,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          address_line3: values?.address_line3,
          fpO_membership_id: values?.membership_number ? String(values?.membership_number) : '',
          size_of_farm: values?.size_of_farm ? values?.size_of_farm : null,
          account_number: values?.account_number,
          ifsc_code: values?.ifsc_code,
          pan_id: values?.pan_id,
          is_fpo_member: !values?.is_non_member
        };
        success = await dispatch(memberActions.addSingleFarmer(params));
      }
    }
    if (success) {
      setIsLinkOpen(false);
      formik.resetForm();
      formik.setSubmitting(false);
      handelClose();
      setIsReRenderRequired(!isReRenderRequired);
      if (isLinkOpen) {
        toast.success('Farmer linked successfully');
      } else {
        toast.success('Farmer added successfully');
      }
    }
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const loadMore = () => {
    const params = {
      DownloadUpload_File_Enum: 2,
      offset: downloadedData?.length,
      page_size: 10,
      isScrolling: true,
    };
    dispatch(dataSetupActions.fetchDownloadedReport(params));
  };

  const textOverflowStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 20px)',
    display: 'block',
    whiteSpace: 'nowrap',
  };

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => {
        tableData = tableData.map((item) => ({ ...item, formik }));
        const { values, setFieldValue } = formik;
        const isDisabled =
          open || isEdit
            ? !values['first_name'] ||
            !values['last_name'] ||
            !values['mobile_number'] ||
            !values['location_code'] ||
            !values['account_number'] ||
            !values['ifsc_code'] ||
            !values['pincode']
            : !values['farmer_person_id'];

        return (
          <Form className="formik-form">
            {busy && (
              <div className="panel__refresh">
                <LogoLoader />
              </div>
            )}
            <DialogPopup
              type='warning'
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              heading={'Remove Member'}
              message={`Are you sure you want to remove member with name ${selectedMember?.full_name}?`}
              reset={() => { }}
              onClick={() => handelDelete(selectedMember)}
            ></DialogPopup>
            <div className="member-container px-3 pt-3">
              {!Boolean(isUserKyc) ? <div className="button-container mb-3 d-flex justify-content-end align-items-center">
                <Button
                  onClick={() => setOpen(true)}
                  type="button"
                  size="sm"
                  className="mr-2 btn-sm d-flex justify-content-center align-items-center">
                  <AddCircle className="icon" />
                  Add Member
                </Button>
                <Button
                  className="mr-2 btn-sm d-flex justify-content-center align-items-center"
                  type="button"
                  onClick={() => {
                    setIsImportOpen(true);
                  }}>
                  <ImportIcon className="icon" />
                  Import Members
                </Button>
              </div> : ''}
              {(open || isLinkOpen || isEdit) && (
                <PopupForm
                  title={`${open ? 'Add Member' : isImportOpen ? 'Import Members' : 'Edit Member'}`}
                  onClose={() => handelClose(formik)}>
                  <div className="form-wrapper full-width">
                    {(open || isEdit) && (
                      <>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Mobile Number</span>
                          <span class="required">*</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            disabled={isEdit}
                            value={values['mobile_number']}
                            onChange={async (e) => {
                              if (e.target.value.length <= 10) {
                                setFieldValue('mobile_number', e.target.value);
                              }
                              if (e.target.value.length === 10) {
                                const success = await dispatch(
                                  memberActions.verifyMobileNumber({ mobile_number: e.target.value }),
                                );
                                if (!_.isEmpty(success) && _.isEmpty(success.errors)) {
                                  toast.info(
                                    'Farmer against this Mobile number already exists, pre-populating all data available on the platform.Please fill out rest of the mandatory fields to link the member to the group',
                                  );
                                  setIsError(false);
                                  setIsLinkOpen(true);
                                  setFieldValue('first_name', success?.[0]?.first_name);
                                  setFieldValue('last_name', success?.[0]?.last_name);
                                  setFieldValue('mobile_number', e?.target?.value || success?.[0]?.mobile_number);
                                  setFieldValue('membership_number', success?.[0]?.fpo_membership_id);
                                  setFieldValue('ifsc_code', success?.[0]?.ifsc_code);
                                  setFieldValue('pan_id', success?.[0]?.pan_id);
                                  setFieldValue('account_number', success?.[0]?.account_number);
                                  setFieldValue('location_code', success?.[0]?.location_code);
                                  setFieldValue('pincode', success?.[0]?.pin_code);
                                  setFieldValue('village_code', success?.[0]?.village_code);
                                  setFieldValue('district_code', success?.[0]?.district_code);
                                  setFieldValue('village_district', success?.[0]?.district_code);
                                  setFieldValue('city_code', success?.[0]?.city_code);
                                  setFieldValue('state_code', success?.[0]?.state_code);
                                  setFieldValue('place_id', success?.[0]?.place_id);
                                  setFieldValue('locality', success?.[0]?.locality);
                                  setFieldValue('address_line1', success?.[0]?.address_line1);
                                  setFieldValue('address_line2', success?.[0]?.address_line2);
                                  setFieldValue('address_line3', success?.[0]?.address_line3);
                                  setFieldValue('size_of_farm', success?.[0]?.size_of_farm);
                                  setFieldValue('address_lattitude', success?.[0]?.address_lattitude);
                                  setFieldValue('address_longitude', success?.[0]?.address_longitude);
                                  setFieldValue('location_id', success?.[0]?.location_id);
                                  setFieldValue('country_code', success?.[0]?.country_code);
                                  setFieldValue('address_text', success?.[0]?.address_text);
                                  setFieldValue('person_id', success?.[0]?.person_id);
                                } else {
                                  if (_.isEmpty(success.errors)) {
                                    toast.info(
                                      'Farmer against this Mobile number is not available on the platform.Please fill out all the details and add member to the group',
                                    );
                                    setIsError(false);
                                  } else {
                                    setIsError(true);
                                  }
                                  setIsLinkOpen(false);
                                  setFieldValue('first_name', '');
                                  setFieldValue('last_name', '');
                                  setFieldValue('membership_number', '');
                                  setFieldValue('ifsc_code', '');
                                  setFieldValue('pan_id', '');
                                  setFieldValue('account_number', '');
                                  setFieldValue('location_code', '');
                                  setFieldValue('pincode', '');
                                  setFieldValue('village_code', '');
                                  setFieldValue('district_code', '');
                                  setFieldValue('village_district', '');
                                  setFieldValue('city_code', '');
                                  setFieldValue('state_code', '');
                                  setFieldValue('place_id', '');
                                  setFieldValue('locality', '');
                                  setFieldValue('address_line1', '');
                                  setFieldValue('address_line2', '');
                                  setFieldValue('address_line3', '');
                                  setFieldValue('size_of_farm', '');
                                  setFieldValue('address_lattitude', '');
                                  setFieldValue('address_longitude', '');
                                  setFieldValue('country_code', '');
                                  setFieldValue('address_text', '');
                                  setFieldValue('person_id', '');
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">First Name</span>
                          <span class="required">*</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={isEdit || isError}
                            value={values['first_name']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[a-zA-Z]+$/) || !e.target.value) {
                                setFieldValue('first_name', e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Last Name</span>
                          <span class="required">*</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={isEdit || isError}
                            value={values['last_name']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[a-zA-Z]+$/) || !e.target.value) {
                                setFieldValue('last_name', e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <div className="d-flex justify-content-between align-item-center">
                            <span className="form-group-label">Membership Number</span>
                            <span className="form-group-label">
                              <Switch size="small" checked={values?.is_non_member} onChange={(isChecked) => {
                                setFieldValue('is_non_member', isChecked)
                              }} />
                              <span className='ml-2 font-weight-bold' style={{ fontSize:'var(--fs-base__four)' }}>Non-Member</span>
                            </span>
                          </div>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={isError || values?.is_non_member}
                            value={values['membership_number']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[a-zA-Z0-9]+$/) || !e.target.value) {
                                setFieldValue('membership_number', e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">PAN</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={isEdit || isError}
                            value={values['pan_id']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[a-zA-Z0-9]+$/) || !e.target.value) {
                                setFieldValue('pan_id', e.target.value.toUpperCase());
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Bank A/c Number</span>
                          <span class="required">*</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            disabled={isEdit || isError}
                            value={values['account_number']}
                            onChange={(e) => {
                              if (
                                (e.target.value.length <= 18 && e.target.value.match(/^[0-9]+$/)) ||
                                !e.target.value
                              ) {
                                setFieldValue('account_number', e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">IFSC Code</span>
                          <span class="required">*</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="text"
                            disabled={isEdit || isError}
                            value={values['ifsc_code']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[a-zA-Z0-9]+$/) || !e.target.value) {
                                setFieldValue('ifsc_code', e.target.value.toUpperCase());
                              }
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Landholding</span>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            type="number"
                            disabled={isEdit || isError}
                            value={values['size_of_farm']}
                            onChange={(e) => {
                              if (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,3})?$/) || !e.target.value) {
                                setFieldValue('size_of_farm', e.target.valueAsNumber);
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{
                                      fontSize: 'var(--fs-base__three)',
                                      fontWeight: 'bold',
                                      color: '#707070',
                                      marginLeft: '12px',
                                    }}>
                                    Acres
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Select Location</span>
                          <span class="required">*</span>
                          <Field
                            name={`location_code`}
                            disabled={isEdit || isError}
                            component={searchLocation}
                            initialDisplayText={values.location_code || ''}
                            cb={(locationValues) => {
                              let locationDetails = locationValues[0] || {};
                              let addressLine1 =
                                (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                  ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.houseName', '')
                                  ? _.get(locationDetails, 'addressTokens.houseName', '')
                                  : '');

                              let addressLine2 =
                                (_.get(locationDetails, 'addressTokens.street', '')
                                  ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  : '');

                              let addressLine3 =
                                (_.get(locationDetails, 'addressTokens.subLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.locality', '')
                                  ? _.get(locationDetails, 'addressTokens.locality', '')
                                  : '');

                              // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                              formik.setFieldValue('pincode', _.get(locationDetails.addressTokens, 'pincode') || '');
                              formik.setFieldValue('address_line1', addressLine1);
                              formik.setFieldValue('address_line2', addressLine2);
                              formik.setFieldValue('address_line3', addressLine3);
                              formik.setFieldValue('city_code', _.get(locationDetails.addressTokens, 'city') || '');
                              formik.setFieldValue('locality', _.get(locationDetails.addressTokens, 'locality') || '');
                              formik.setFieldValue(
                                'village_code',
                                _.get(locationDetails.addressTokens, 'village') ||
                                _.get(locationDetails.addressTokens, 'city') ||
                                _.get(locationDetails.addressTokens, 'subDistrict') ||
                                '',
                              );
                              formik.setFieldValue('city_code', _.get(locationDetails.addressTokens, 'city') || '');
                              formik.setFieldValue(
                                'district_code',
                                _.get(locationDetails.addressTokens, 'district') || '',
                              );
                              const village_district =
                                _.get(locationDetails.addressTokens, 'village') +
                                _.get(locationDetails.addressTokens, 'district');
                              formik.setFieldValue(
                                'village_district',
                                _.get(locationDetails.addressTokens, 'district') || '',
                              );
                              formik.setFieldValue('state_code', _.get(locationDetails.addressTokens, 'state') || '');
                              formik.setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
                              formik.setFieldValue(
                                'address_lattitude',
                                _.get(locationDetails.addressTokens, 'latitude', '1'),
                              );
                              formik.setFieldValue(
                                'address_longitude',
                                _.get(locationDetails.addressTokens, 'longitude', '1'),
                              );
                              formik.setFieldValue(
                                'location_code',
                                (_.get(locationDetails, 'placeName', '')
                                  ? _.get(locationDetails, 'placeName', '') + ', '
                                  : '') + _.get(locationDetails, 'placeAddress', ''),
                              );
                            }}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Address 1</span>
                          <Field
                            name="address_line1"
                            disabled={isEdit || isError}
                            component={FormikMaterialTextField}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Address 2</span>
                          <Field
                            name="address_line2"
                            disabled={isEdit || isError}
                            component={FormikMaterialTextField}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Address 3</span>
                          <Field
                            name="address_line3"
                            disabled={isEdit || isError}
                            component={FormikMaterialTextField}
                          />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Village/ District</span>
                          <Field name="village_district" disabled component={FormikMaterialTextField} />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">Pin Code</span>
                          <span class="required">*</span>
                          <Field name="pincode" disabled component={FormikMaterialTextField} />
                        </div>
                        <div className="form-group-field mb-3">
                          <span className="form-group-label">State</span>
                          <Field name="state_code" disabled component={FormikMaterialTextField} />
                        </div>
                      </>
                    )}
                  </div>
                  {isEdit ? (
                    <div className="text-center">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className="next bfc-button-next btn-sm">
                        {formik.isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"></span>
                        )}
                        {isLinkOpen ? 'Link' : 'Save'}
                      </Button>
                    </div>
                  ) : (
                    <div className="text-center">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={formik.isSubmitting || isDisabled}
                        className="next bfc-button-next btn-sm">
                        {formik.isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"></span>
                        )}
                        {isLinkOpen ? 'Link' : 'Save'}
                      </Button>
                    </div>
                  )}
                </PopupForm>
              )}
              <div className="ant-table-body ant-table">
                <Table
                  ref={tableRef}
                  columns={MEMBERS_TABLE_HEADER}
                  dataSource={tableData || []}
                  bordered
                  pagination={false}
                  scroll={{ y: 550 }}
                  className="ant-table"
                  size="small"
                />
                <AntPagination
                  total={parseInt(totalRecords)}
                  page={parseInt(page)}
                  handlePageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  setScrollToFirstRow={setScrollToFirstRow}
                />
              </div>
              <DropzoneDialog
                dropzoneClass="dropzone-container d-flex align-items-center justify-content-center"
                acceptedFiles={['.xls,.xlsx,.csv']}
                cancelButtonText={'cancel'}
                submitButtonText={'submit'}
                maxFileSize={5240000}
                open={isImportOpen}
                onClose={() => setIsImportOpen(false)}
                onSave={async (files) => {
                  const formData = new FormData();
                  formData.append('UploadedFile', files[0]);
                  const success = await dispatch(memberActions.importMembers(formData));
                  if (success) {
                    toast.success('Bulk upload successful');
                    setIsImportOpen(false);
                    setIsReRenderRequired(!isReRenderRequired);
                  }
                }}
                showPreviews={true}
                previewText="Files:"
                showFileNamesInPreview={true}
                useChipsForPreview={true}
                previewChipProps={{ color: 'var(--unnamed-color-1b62ab)', variant: 'filled' }}
                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                showAlerts={false}
                getFileAddedMessage={(fileName) => toast.success(`File ${fileName} successfully added.`)}
                getFileRemovedMessage={(fileName) => toast.info(`File ${fileName} removed.`)}
                getDropRejectMessage={(file) =>
                  toast.error(`File ${file.name} is too big. Max file size allowed is 5MB.`)
                }
                dialogTitle={
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="MuiTypography-root MuiTypography-h6">Upload File</h2>
                    {user?.profile?.download_sample_file_url && (
                      <a href={`${user?.profile?.download_sample_file_url}`} style={{ fontSize: 'var(--fs-base__four)' }}>
                        Download sample file
                      </a>
                    )}
                  </div>
                }
              />
              {!Boolean(isUserKyc) ? <div className="delivery-overview pt-20 form__form-group">
                <div className="overview-wrapper">
                  <div className="container p-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-20 p-0">
                      <Card className="card overview-card px-0 py-0">
                        <div className="d-flex justify-content-between align-items-center pl-15 pr-15">
                          <span
                            className="title"
                            style={{ fontWeight: 'bold', color: '#1b62ab', fontSize: 'var(--fs-base__one)' }}>
                            Recent Reports
                          </span>
                          <IconButton
                            size="medium"
                            onClick={() => {
                              setIsReRenderRequired(!isReRenderRequired);
                            }}>
                            <CachedIcon />
                          </IconButton>
                        </div>
                        <InfiniteScroll
                          height={600}
                          style={{ overflowX: 'hidden' }}
                          dataLength={downloadedData?.length}
                          next={loadMore}
                          hasMore={hasMorePost}>
                          {downloadedData?.map((item) => (
                            <div
                              className="row py-3 m-3"
                              style={{
                                borderRadius: 4,
                                border: '1px solid #e3e3e3',
                              }}>
                              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div className="info">
                                  <p className="pt-0 recent-reports" style={{ color: '#000', fontSize: 'var(--fs-base__three)' }}>
                                    {item?.report_type_enum_code}
                                  </p>
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: '#707070',
                                      fontWeight: 'normal',
                                      ...textOverflowStyle,
                                    }}>
                                    {item.report_name}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div className="info">
                                  <p className="pt-0 recent-reports" style={{ color: '#000', fontSize: 'var(--fs-base__three)' }}>
                                    Format
                                  </p>
                                  <span style={{ fontSize: 'var(--fs-base__three)', color: '#707070', fontWeight: 'normal' }}>
                                    {item?.file_type_enum_code?.toUpperCase()}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div className="info">
                                  <p className="pt-0 recent-reports" style={{ color: '#000', fontSize: 'var(--fs-base__three)' }}>
                                    Requested
                                  </p>
                                  <span style={{ fontSize: 'var(--fs-base__three)', color: '#1b62ab', fontWeight: 'normal' }}>
                                    {moment(item.requested_on_datetime).fromNow()}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 m-auto">
                                <div className="info">
                                  <p className="pt-0 recent-reports">
                                    {item.report_generation_status_enum_code === 'Success' ? (
                                      <a href={item.attachment_path} className="downloadWord">
                                        Download
                                      </a>
                                    ) : (
                                      (item.report_generation_status_enum_code === 'Failure' && (
                                        <p className="FailureWord pt-0 recent-reports">Failure</p>
                                      )) ||
                                      (item.report_generation_status_enum_code === 'Submitted' && (
                                        <p className="SubmittedWord pt-0 recent-reports">Submitted</p>
                                      )) ||
                                      (item.report_generation_status_enum_code === 'Generating' && (
                                        <p className="generatingdWord pt-0 recent-reports">Generating</p>
                                      )) ||
                                      (item.report_generation_status_enum_code === 'Fetching Data' && (
                                        <p className="SubmittedWord pt-0 recent-reports">
                                          {item.report_generation_status_enum_code}
                                        </p>
                                      ))
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </InfiniteScroll>
                      </Card>
                    </div>
                  </div>
                </div>
              </div> : ''}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default Members;
