import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';

import { uploadDocumentService } from 'services';
import { actions as uploadActions } from 'redux/uploadDoc';
import { qualityService } from 'services';
import { actions as qualityServices } from 'redux/qualityServices';
import history from 'lib/history';
import * as DeliveryService from 'services/delivery';

export const handleUpload = async (
  values,
  entity_type_enum,
  front_back_enum,
  entity_id,
  attachmentIdToPass,
  formikBag,
) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Uploading Document ...',
      key: 'req-form',
    });
    const res = await uploadDocumentService.upload(
      values,
      entity_type_enum,
      front_back_enum,
      entity_id,
      attachmentIdToPass,
    );
    notifications.show({
      type: 'success',
      message: 'Document Uploaded.',
      key: 'req-form',
    });

    // globalThis.store.dispatch(uploadActions.setDirty());
    // redirect.web('/account/upload');
    let params = {
      entity_id: entity_id,
      company_affiliate_enum: 1,
    };
    globalThis.store.dispatch(uploadActions.fetchList(params));
    // redirect.web('/account/upload');
    return res;
  } catch (err) {
    console.log('Upload Document--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleQualityLabUpload = async (
  values,
  entity_type_enum,
  front_back_enum,
  entity_id,
  attachmentIdToPass,
  document_module_enum,
  company_affiliate_enum,
  setTimestamp,
  formikBag,
) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Uploading File ...',
      key: 'req-form',
    });
    // console.log('params in handlers', values);
    const res = await uploadDocumentService.handleQualityLabUpload(
      values,
      entity_type_enum,
      front_back_enum,
      entity_id,
      attachmentIdToPass,
      document_module_enum,
      company_affiliate_enum,
    );
    notifications.show({
      type: 'success',
      message: 'File Uploaded.',
      key: 'req-form',
    });

    // globalThis.store.dispatch(uploadActions.setDirty());
    // redirect.web('/account/upload');
    if (entity_type_enum === 26) {
      const params = { filters: { Quality_Record_Master_Id: `${entity_id}` }, page_size: 10, offset: 0 };

      await globalThis.store.dispatch(qualityServices.fetchList(params));

      const back = `/network/quality-services?page=1`;
      const encodeBack = utils.encodeCustomUrl(back);
      // history.push(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`);
      history.push(getUrlWithEncryptedQueryParams(`/network/quality-services/${entity_id}/edit?back=${encodeBack}`));
    } else {
      setTimestamp(Date.now);
    }
    // redirect.web('/account/upload');
    return res;
  } catch (err) {
    console.log('Upload Document--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
export const handleDeleteUploadedFileAgainstId = async (values) => {
  try {
    const res = await DeliveryService.deleteUploadedFileAgainstId(values);

    return res;
  } catch (err) {
    console.log('handleClose--->error---->', err);
    throw err;
  }
};
