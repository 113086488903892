import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import history from 'lib/history';
import * as yup from 'yup';

import { authService, userService } from 'services';
import { actions as userActions } from 'redux/user';
import { actions as rolesAndRightsActions } from 'redux/rolesAndRights';
import { PROFILE_TABS_ENUM } from 'constants/enums';

const userid = _.get(window, 'user.userid', 0);

export const ProfileDetailsSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  gender_enum: yup.string().required('Gender is a required field'),
  mobile_number: yup.number().typeError('Mobile number required').required(),
  type_of_individual_enum: yup
    .number()
    .positive('I am a is a required field')
    .typeError('I am a is a required field')
    .required(),

  // address_line1: yup.string().required('Address Line 1 is a required field'),

  // pin_code: yup.string().required(),
});

export const handlePersonalDetailsUpdate = async (values, formikBag) => {
  try {
    values.first_name = values.first_name.trim();
    values.last_name = values.last_name.trim();
    values.address_line1 = values.address_line1.trim();
    values.pin_code = values.pin_code.trim();
    values.location_code = values.location_code.trim();
    await utils.validateSchema(ProfileDetailsSchema, values);

    notifications.show({
      type: 'info',
      message: 'Saving Personal Details...',
      key: 'req-form',
    });

    const params = {
      ...values,
    };
    const res = await api.profile.updatePersonalDetails(params);
    notifications.show({
      type: 'success',
      message: 'Personal details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(userActions.fetchPersonalDetails());
    globalThis.store.dispatch(rolesAndRightsActions.fetchList());

    let personID = values['person_id'] || userid;

    let uType = res.type_of_individual_enum || values.type_of_individual_enum || 0;
    let isProprietor = res.is_proprietor || values.is_proprietor || 0;
    const storeState = globalThis.store.getState();
    const userInfo = _.get(storeState, 'user') || {};
    let acl = utils.getACL(user);
    const rolesAndRights = _.get(storeState, 'rolesAndRights') || {};
    let rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
    const showOrganisation = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.ORGANISATION),
    );
    const showFrams = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.FARMS_CROPS),
    );
    const showTeam = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TEAM),
    );
    const showTransport = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TRANSPORT),
    );
    const showKyc = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.KYC),
    );
    const showUpload = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.UPLOAD),
    );

    const showFinance = Object.values(rolesAndRightsList).find(
      (x) => (x.module_id === PROFILE_TABS_ENUM.FINANCE, x.sub_module_id === PROFILE_TABS_ENUM.FINANCE),
    );

    if (showOrganisation?.full_access || showOrganisation?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/organisations`));
      redirect.mobile('organisations');
    } else if (showFrams?.full_access || showFrams?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/farms`));
      redirect.mobile('farms');
    } else if (showTeam?.full_access || showTeam?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/teams`));
      redirect.mobile('teams');
    } else if (showTransport?.full_access || showTransport?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/transport`));
      redirect.mobile('transports');
    } else if ((showKyc?.full_access || showKyc?.read_access) && acl.isIndividual) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/kyc`));
      redirect.mobile('kyc');
    } else if ((showKyc?.full_access || showKyc?.read_access) && acl.isCompanyAdmin) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/kyc-b`));
      redirect.mobile('kyc');
    } else if (showUpload?.full_access || showUpload?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/upload`));
      redirect.mobile('upload');
    } else if (showFinance?.full_access || showFinance?.read_access) {
      history.push(getUrlWithEncryptedQueryParams(`/account/${personID}/finance`));
      redirect.mobile('finance');
    }

    return res;
  } catch (err) {
    console.log('catch--->error---->', err);

    utils.displayErrors(err, formikBag);
  }
};

export const FarmCropsSchema = yup.object().shape({
  basicInfo: yup.object().shape({
    name_of_farm: yup.string().required(),
    size_of_farm: yup.string().required(),
    operation_enum: yup.number().required(),
  }),
  crops: yup.array().of(
    yup.object().shape({
      farm_detail_id: yup.number().required(),
      crop_id: yup.number().positive().required(),
      crop_area: yup.number().positive().required(),
      harvest_date: yup.date().required(),
      operation_enum: yup.number().required(),
    }),
  ),

  location: yup.object().shape({
    location_id: yup.number().positive().required(),
    pin_code: yup.number().positive().required(),
    address_line1: yup.string('Address Line 1 is required.').when('location_id', (location_id, schema) => {
      if (!location_id) {
        return schema.required();
      }

      return schema.optional();
    }),
  }),
});

export const FarmCropsArraySchema = yup.object().shape({
  formData: yup.array().of(FarmCropsSchema),
});

export const saveFarmDetails = async (values, formikBag) => {
  try {
    await utils.validateSchema(FarmCropsSchema, values);

    notifications.show({
      type: 'info',
      message: 'Saving Farm Details...',
      key: 'req-form',
    });

    const res = await api.profile.updateFarmCrops(values);
    notifications.show({
      type: 'success',
      message: 'Farm details updated.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('catch--->error---->', err);

    utils.displayErrors(err, formikBag);
  }
};

export async function deleteFarm(id = 0) {
  try {
    let res = await api.profile.deleteFarm(id);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}

export const handleUpdateTransport = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving transport details...',
      key: 'req-form',
    });

    const res = await api.profile.updateTransportDetails(values);
    notifications.show({
      type: 'success',
      message: 'Personal details updated.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('catch--->error---->', err);

    utils.displayErrors(err, formikBag);
  }
};

export const onTransportDetailsSubmit = async (values, formikBag) => {
  try {
    const params = {
      ...values,
    };

    const res = await api.profile.updateTransportDetails(params);
    nextPage();
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';
  }
};

export async function handleRequestOTP(values, formikBag) {
  try {
    const res = await authService.sendSignupOTP({
      ...values,
    });
    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }
}

export async function handleVerifyOTP(values, formikBag) {
  try {
    let params = {
      ...values,
    };

    const res = await authService.verifySignupOTP(params);

    params = {
      log_id: res.log_id || 0,
      login_through_enum: res.login_through_enum || 0,
      user_name: res.user_name || '',
      person_id: values.person_id,
    };

    const verifyRes = await userService.updateVerified(params);

    return {
      ...res,
      ...verifyRes,
    };
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }
}

export async function handleResendOTP(params) {
  try {
    let res = await authService.sendSignupOTP({
      ...params,
      isResend: true,
      log_id: null,
    });
  } catch (err) {
    utils.displayErrors(err);
  }
}

export const deleteAccount = async (id) => {
  try {
    // notifications.show({
    //   type: 'info',
    //   message: 'Saving transport details...',
    //   key: 'req-form',
    // });

    const res = await api.profile.deleteAccount(id);
    // notifications.show({
    //   type: 'success',
    //   message: 'Personal details updated.',
    //   key: 'req-form',
    // });

    return res;
  } catch (err) {
    console.log('catch--->error---->', err);

    utils.displayErrors(err);
  }
};

export const fetchDeleteReasons = async (params) => {
  try {
    // notifications.show({
    //   type: 'info',
    //   message: 'Saving transport details...',
    //   key: 'req-form',
    // });

    const res = await api.profile.fetchDeleteReasons(params);
    // notifications.show({
    //   type: 'success',
    //   message: 'Personal details updated.',
    //   key: 'req-form',
    // });

    return res;
  } catch (err) {
    console.log('catch--->error---->', err);

    utils.displayErrors(err);
  }
};

export const sendOtpForDeleteAccount = async (params) => {
  try {
    const res = await api.profile.sendOtpForDeleteAccount(params);
    return res;
  } catch (err) {
    console.log('catch--->error---->', err);
    utils.displayErrors(err);
  }
};

export const verifyOtpForDeleteAccount = async (params) => {
  try {
    const res = await api.profile.verifyOtpForDeleteAccount(params);
    return res;
  } catch (err) {
    console.log('catch--->error---->', err);
    utils.displayErrors(err);
  }
};

export const deactivatePerson = async (selectedReasonId) => {
  try {
    const res = await api.profile.deactivatePerson(selectedReasonId);
    return res;
  } catch (err) {
    console.log('catch--->error---->', err);
    utils.displayErrors(err);
  }
};

export const updateShippingBillingAddress = async (params) => {
  notifications.show({
    type: 'info',
    message: 'Updating Address.',
  });
  try {
    const res = await api.profile.updateShippingBillingAddress(params);
    notifications.show({
      type: 'success',
      message: 'Address Updated.',
    });
    return res;
  } catch (err) {
    console.log('catch--->error---->', err);
    utils.displayErrors(err);
  }
};

export const UpdateOldAddressInDeliveryAudit = async (params) => {
  // notifications.show({
  //   type: 'info',
  //   message: 'Updating Address.',
  // });
  try {
    const res = await api.profile.UpdateOldAddressInDeliveryAudit(params);
    // notifications.show({
    //   type: 'success',
    //   message: 'Address Updated.',
    // });
    return res;
  } catch (err) {
    console.log('catch--->error---->', err);
    utils.displayErrors(err);
  }
};
