import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/extendedProfile/sustainable-business-icon.svg';

function SustainableBusinessIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default SustainableBusinessIcon;
