import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { affiliateService } from 'services';

// const companiesAdapter = createEntityAdapter({
//   // Assume IDs are stored in a field other than `book.id`
//   selectId: (company) => company.company_id,
//   // Keep the "all IDs" array sorted based on book titles
//   sortComparer: (a, b) => a.company_name.localeCompare(b.company_name),
// });

const slice = createSlice({
  name: 'context',
  // initialState: booksAdapter.getInitialState(),
  initialState: { ctx: '1' },
  reducers: {
    update(state, action) {
      const { id } = action.payload;
      state.ctx = id;
    },
  },
});

const updateContext = (id) => async (dispatch) => {
  try {
    dispatch(
      slice.actions.update({
        id: id,
      }),
    );
  } catch (err) {
    dispatch(
      slice.actions.update({
        id: '1',
      }),
    );
    utils.displayErrors(err);
  }
};

const actions = {
  ...slice.actions,
  updateContext,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
