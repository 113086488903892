import _ from 'lodash';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {};

export const resTransform = {
  getAllEquipmentsReport(resData) {
    let equipmentData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchListByDetails(details);
    });
    return _.keyBy(equipmentData, 'equipment_master_id');
  },
  fetchListByDetails(details) {
    let data = {
      id: _.get(details, 'id') || '',
      equipment_master_id: _.get(details, 'equipment_master_id') || '',
      equipment_category_code: _.get(details, 'equipment_category_code') || '',
      equipment_type_code: _.get(details, 'equipment_type_code') || '',
      equipment_make_code: _.get(details, 'equipment_make_code') || '',
      equipment_model_code: _.get(details, 'equipment_model_code') || '',
      equipment_variant_code: _.get(details, 'equipment_variant_code') || '',
      price_uom_code: _.get(details, 'price_uom_code') || '',
      serial_number: _.get(details, 'serial_number') || '',
      telemetry_device_id: _.get(details, 'telemetry_device_id') || '',
      equipment_description: _.get(details, 'equipment_description') || '',
      equipment_name: _.get(details, 'equipment_name') || '',
      creation_year: _.get(details, 'creation_year') || '',
      registration_no: _.get(details, 'registration_no') || '',
      model_no: _.get(details, 'model_no') || '',
      is_verified: _.get(details, 'is_verified') || '',
      is_draft: _.get(details, 'is_draft') || '',
      verification_status_enum_code: _.get(details, 'verification_status_enum_code') || '',
      equipment_metadata: _.get(details, 'equipment_metadata') || '',
      entity_code: _.get(details, 'entity_code') || '',
      user_type_enum_code: _.get(details, 'user_type_enum_code') || '',
      company_affiliate_enum_code: _.get(details, 'company_affiliate_enum_code') || '',
      equipment_status_enum_code: _.get(details, 'equipment_status_enum_code') || '',
      is_operator_required: _.get(details, 'is_operator_required') || '',
      is_equipment_insured: _.get(details, 'is_equipment_insured') || '',
      insured_from_datetime: _.get(details, 'insured_from_datetime') || '',
      insured_to_datetime: _.get(details, 'insured_to_datetime') || '',
      equipment_address: _.get(details, 'equipment_address') || '',
      location_code: _.get(details, 'location_code') || '',
      address_lattitude: _.get(details, 'address_lattitude') || '',
      address_longitude: _.get(details, 'address_longitude') || '',
      city_code: _.get(details, 'city_code') || '',
      country_code: _.get(details, 'country_code') || '',
      district_code: _.get(details, 'district_code') || '',
      place_id: _.get(details, 'place_id') || '',
      state_code: _.get(details, 'state_code') || '',
      village_code: _.get(details, 'village_code') || '',
      expected_price: _.get(details, 'expected_price') || '',
      payment_term_code: _.get(details, 'payment_term_code') || '',
      is_tractor_available: _.get(details, 'is_tractor_available') || '',
      equipment_offer_yype_enum_code: _.get(details, 'equipment_offer_yype_enum_code') || '',
      mobile_Number: _.get(details, 'mobile_number') || '',
    };
    return data;
  },
};
