import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import { util } from 'prettier';

export const reqTransform = {
  fetchTransportationCost(params = {}) {
    const qsParams = {
      address_Id: params['address_id'],
      entity_Id: params['buySellId'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 2,
      vehicle_type_id: params['vehicle_type_id'],
      total_offer_bid_qty: params['offer_bid_qty'],
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
    // return params;
  },
  fetchCharges(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };

    return helpers.getQueryParams(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = [params].map((farm) => {
      let crops = farm.crops
        // .filter((crop) => !!crop.operation_enum)
        .map((crop) => {
          return {
            person_id: _.get(window, 'user.userid') || 0,
            person_address_id: 0,
            status_enum: true,
            operation_enum: 1,
            ...crop,
            crop_area: crop.crop_area ? parseInt(crop.crop_area) : 0,
          };
        });

      return {
        ...farm,
        crops,
      };
    });

    bodyParams = bodyParams.map((farm) => {
      return {
        farm_address: {
          person_address_id: 0,
          person_id: _.get(window, 'user.userid') || 0,
          registered_address_flag: true,
          address_lattitude: '',
          address_longitude: '',
          landmark: '',
          district_id: 1,
          state_id: 1,
          country_id: 1,
          is_transaction_address: true,
          acreage: 1,
          created_by: 1,
          created_datetime: '2021-01-04T06:00:10.823Z',
          modified_by: 0,
          modified_datetime: '2021-01-04T06:00:10.823Z',
          lock_id: 0,
          location_id: 1,
          taluka_id: 1,
          village_id: 1,
          name_of_farm: '',
          address_line1: '',
          address_line2: '',
          address_line3: '',
          pin_code: '',
          id: 1,
          person_code: '',
          offset_day_type_enum_code: '',
          location_code: '',
          country_code: '',
          state_code: '',

          district_code: '',
          taluka_code: '',
          village_code: '',
          address_type_enum_code: '',
          ...farm,
          city_id: 1,
          city_code: '',
          size_of_farm: parseInt(_.get(farm, 'size_of_farm') || 0),
          status_enum: true,
          address_type_enum: 3,
          type_of_individual_enum: 1,
          address_text: _.get(farm, 'name_of_farm') || '',
        },
        crop_details: farm.crops,
        operation_enum: 1,
      };
    });

    return bodyParams;
  },
  update(id, params) {
    let bodyParams = [params].map((farm) => {
      let crops = farm.crops
        // .filter((crop) => !!crop.operation_enum)
        .map((crop) => {
          return {
            person_id: _.get(window, 'user.userid') || 0,
            person_address_id: id,
            status_enum: true,
            operation_enum: 3,
            ...crop,
            crop_area: crop.crop_area ? parseInt(crop.crop_area) : 0,
          };
        });

      return {
        ...farm,
        crops,
      };
    });

    bodyParams = bodyParams.map((farm) => {
      return {
        farm_address: {
          person_address_id: id,
          person_id: _.get(window, 'user.userid') || 0,
          registered_address_flag: true,
          address_lattitude: '',
          address_longitude: '',
          landmark: '',
          district_id: 1,
          state_id: 1,
          country_id: 1,
          is_transaction_address: true,
          acreage: 1,
          created_by: 1,
          created_datetime: '2021-01-04T06:00:10.823Z',
          modified_by: 0,
          modified_datetime: '2021-01-04T06:00:10.823Z',
          lock_id: 0,
          location_id: 1,
          taluka_id: 1,
          village_id: 1,
          name_of_farm: '',
          address_line1: '',
          address_line2: '',
          address_line3: '',
          pin_code: '',
          id: 1,
          person_code: '',
          offset_day_type_enum_code: '',
          location_code: '',
          country_code: '',
          state_code: '',
          district_code: '',
          taluka_code: '',
          village_code: '',
          address_type_enum_code: '',
          ...farm,
          city_id: 1,
          city_code: '',
          size_of_farm: parseInt(_.get(farm, 'size_of_farm') || 0),
          status_enum: true,
          address_type_enum: 3,
          type_of_individual_enum: 1,
          address_text: _.get(farm, 'name_of_farm') || '',
        },
        crop_details: farm.crops,
        operation_enum: 3,
      };
    });

    return bodyParams;
  },
  destroy(id) {
    const qsParams = {
      person_address_id: id,
    };

    return helpers.getQueryParams(qsParams);
  },

  fetchMatchingCount(params) {
    const bodyParams = {
      ...params,
    };

    return bodyParams;
  },
  fetchMatchingOverviewCount(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  hideAutoMatch(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  counterAutoBidOffer(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  fetchDerivedAutoBidOffers(params) {
    const bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchAutoAndRegularBidOffers(params) {
    const bodyParams = {
      ...params,
    };

    return bodyParams;
  },
  fetchAutoBidOffers(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  withdrawAutoBidOffer(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  acceptRejectAutoBidOfferCounter(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  acceptRejectAutoBidOffer(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  setTransportationCost(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  refreshBuySellCompatibilityMatrix(params) {
    const bodyParams = {
      ...params,
    };

    return utils.getQs(bodyParams);
  },
  createCounter(params = {}) {
    let bodyParams = {
      ...params,
    };

    return bodyParams;
  },
};

export const resTransform = {
  fetchTransportationCost(resData = {}) {
    return resData;
  },

  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'farm_address.person_address_id') || 0,
        ...(_.get(d, 'farm_address') || {}),
        name_of_farm: _.get(d, 'farm_address.address_text') || '',
        crops: _.get(d, 'crop_details') || [],
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchDetails(resData) {
    let data = resData || {};

    data = {
      id: _.get(data, 'farm_address.person_address_id') || 0,
      ...(_.get(data, 'farm_address') || {}),
      crops: _.get(data, 'crops') || [],
    };

    return data;
  },
  create(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
  fetchCharges(resData) {
    return resData;
  },
  fetchMatchingCount(resData) {
    return resData;
  },
  fetchMatchingOverviewCount(resData) {
    return resData;
  },
  hideAutoMatch(resData) {
    return resData;
  },
  counterAutoBidOffer(resData) {
    return resData;
  },
  fetchDerivedAutoBidOffersList(resData) {
    let listData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDerivedAutoBidOffersDetails(details);
    });
    return _.keyBy(listData, 'buy_sell_compatibility_matrix_id');
  },
  fetchDerivedAutoBidOffersDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'buy_sell_compatibility_matrix_id'),
    };
    return res;
  },
  fetchAutoAndRegularBidOffers(resData) {
    return resData;
  },
  fetchAutoBidOffers(resData) {
    return resData;
  },
  withdrawAutoBidOffer(resData) {
    return resData;
  },
  acceptRejectAutoBidOfferCounter(resData) {
    return resData;
  },
  acceptRejectAutoBidOffer(resData) {
    return resData;
  },
  setTransportationCost(resData) {
    return resData;
  },
  refreshBuySellCompatibilityMatrix(resData) {
    return resData;
  },
};
