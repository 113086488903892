import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const KycApprovalSchema = yup.object().shape({});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListSchemes = async (params = {}) => {
  try {
    let res = await api.fetchListSchemes(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createScheme = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.createScheme(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateScheme = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.updateScheme(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroyPlan = async (id) => {
  try {
    let res = await api.destroyPlan(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroyScheme = async (id) => {
  try {
    let res = await api.destroyScheme(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListClaims = async (params = {}) => {
  try {
    let res = await api.fetchListClaims(params);
    return res;
  } catch (err) {
    throw err;
  }
};
