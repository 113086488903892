import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    // return utils.getQs(params);
    if (parseInt(params['company_affiliate_enum'])) {
      return utils.getQs(params);
    } else {
      delete params['company_affiliate_enum'];
      return utils.getQs(params);
    }
  },
  fetchRatingDetails(params = {}) {
    if (parseInt(params['company_affiliate_enum'])) {
      return utils.getQs(params);
    } else {
      delete params['company_affiliate_enum'];
      return utils.getQs(params);
    }
  },
};

export const resTransform = {
  fetchList(resData, params) {
    let data = [{ id: params.entity_id, data: resData }];
    return _.keyBy(data, 'id');
  },

  fetchRatingDetailsList(resData) {
    let storageData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchRatingDetails(details);
    });
    return _.keyBy(storageData, 'rating_type_enum');
  },
  fetchRatingDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'rating_type_enum') || '',
    };
  },
};
