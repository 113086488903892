import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const TransportSchema = yup.object().shape({
  entity_id: yup.number().required(),
  base_location_id: yup
    .number()
    .positive('Base location is a required field')
    .typeError('Base location is a required field')
    .required(),
  vehicle_type_enum: yup
    .number()
    .positive('Vehicle type is a required field')
    .typeError('Vehicle type is a required field')
    .required(),
  goods_type_enum: yup
    .number()
    .positive('Goods type is a required field')
    .typeError('Goods type is a required field')
    .required(),
  vehicle_type_id: yup
    .number()
    .positive('Rated capacity is a required field')
    .typeError('Rated capacity is a required field')
    .required(),
  max_load_capacity: yup.number(),
  number_of_vehicles: yup.string().required('Number of vehicles is a required field'),
  // vehicle_type_id: yup.number().required(),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchListForTranportRouteServed = async (params = {}) => {
  try {
    let res = await api.fetchListForTranportRouteServed(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(TransportSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(TransportSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handleCreateTransportRouteServed = async (params) => {
  try {
    // await helpers.validateSchema(TransportSchema, params);
    const res = await api.handleCreateTransportRouteServed(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const handleDeleteTransportRouteServed = async (params) => {
  try {
    const res = await api.handleDeleteTransportRouteServed(params);
    return res;
  } catch (err) {
    throw err;
  }
};
