import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import * as helpers from 'services/helpers';

export const reqTransform = {
  connectProfile(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  withdrawConnect(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  respondConnectRequest(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  fetchCompanyBusinessProfile(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  fetchNotConnectedBusinessProfile(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  fetchConnectionRequests(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  connectProfile(resData) {
    let res = {
      ...resData,
    };
    return res;
  },

  withdrawConnect(resData) {
    let res = {
      ...resData,
    };
    return res;
  },

  respondConnectRequest(resData) {
    let res = {
      ...resData,
    };
    return res;
  },

  fetchCompanyBusinessProfile(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'mobile_number') || 0,
    };
    return res;
  },
  fetchNotConnectedBusinessProfile(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'mobile_number') || 0,
    };
    return res;
  },
  fetchConnectionRequests(resData) {
    let connectData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(connectData, 'bf_business_social_connect_id');
  },

  fetchDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'bf_business_social_connect_id'),
    };
  },
};
