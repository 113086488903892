import _ from 'lodash';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { REQ_OFFER_STATUS_ENUM } from 'constants/enums';
import api from 'lib/api';
import * as commodityPlanApi from 'services/commodityPlan/api';
import OfferService from 'services/offer';
import utils from 'lib/utils';
import history from 'lib/history';
import notifications from 'lib/notifications';
import { qualityService } from 'services';
import { actions as qualityServices } from 'redux/qualityServices';
import { ShareQualityReport } from 'services/deal/api';

const QualityServicesSchema = yup.object().shape({
  entity_id: yup.number().required().positive().integer(),
  sample_received_on: yup.date().required('Please select sample received date'),
  test_date: yup.date().required('Please select test date'),
  business_location_id: yup.number().required('Please business location'),
  commodity_type_code: yup.string().required('Commodity type is required'),
  commodity_id: yup.number().required('Please select Commodity'),
  commodity_residue_id: yup.number('Location is required.').required('Commodity Residue is required.'),
  remarks: yup.string().required('Remark is required'),
});

export const handleSaveQualityRecord = async (values, formikBag, commodityData, setCommodityData, setTimestamp) => {
  try {
    await utils.validateSchema(QualityServicesSchema, values);
    notifications.show({
      type: 'info',
      message: 'Saving Quality Record ...',
      key: 'req-form',
    });
    const res = await qualityService.create(values);
    let quality_record_master_id = _.get(res, 'quality_record_master_id') || 0;

    // const data = { ...commodityData, ...res };
    // setCommodityData(data);
    // setTimestamp(Date.now());
    notifications.show({
      type: 'success',
      message: 'Quality Record Saved.',
      key: 'req-form',
    });

    const params = { filters: { Quality_Record_Master_Id: `${quality_record_master_id}` }, page_size: 10, offset: 0 };

    await globalThis.store.dispatch(qualityServices.fetchList(params));

    const back = `/network/quality-services?page=1`;
    history.push(back);
    // const encodeBack = utils.encodeCustomUrl(back);
    // history.push(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`);
    // history.push(`/network/quality-services/${quality_record_master_id}/edit?back=${encodeBack}`);

    // `/network/quality-services/${d['quality_record_master_id']}/edit`

    // const encodeBack = utils.encodeCustomUrl('/network/quality-services?page=1');
    // history.push(`/network/quality-services?back=${encodeBack}`);

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
    throw err;
  }
};

export const handleUpdateQualityRecord = async (values, formikBag, commodityData, setCommodityData) => {
  try {
    await utils.validateSchema(QualityServicesSchema, values);

    notifications.show({
      type: 'info',
      message: 'Updating Quality Record ...',
      key: 'req-form',
    });
    const res = await qualityService.update(values);
    notifications.show({
      type: 'success',
      message: 'Quality Record Updated.',
      key: 'req-form',
    });

    let quality_record_master_id = _.get(res, 'quality_record_master_id') || 0;

    let data = {
      ...res,
      id: quality_record_master_id,
    };

    quality_record_master_id = parseInt(quality_record_master_id);
    globalThis.store.dispatch(qualityServices.setAll({}));

    const back = `/network/quality-services?page=1`;
    history.push(back);
    // const encodeBack = utils.encodeCustomUrl(back);
    // // history.push(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`);
    // history.push(`/network/quality-services/${quality_record_master_id}/edit?back=${encodeBack}`);

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
    throw err;
  }
};

export async function handleDestroy(id = 0, params) {
  try {
    let res = await qualityService.destroy(id);

    const params = { filters: {}, page_size: 10, offset: 0 };

    globalThis.store.dispatch(qualityServices.removeOne(id));

    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}
export async function handleQualityParameterDestroy(id = 0) {
  try {
    let res = await qualityService.handleQualityParameterDestroy(id);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}

export async function handleQualityUploadDestroy(id = 0, setTimestamp) {
  try {
    let res = await qualityService.handleQualityUploadDestroy(id);
    setTimestamp(Date.now);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}

export const handleShareQualityReport = async (params) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Sharing Quality Services Reports...',
      key: 'req-form',
    });

    const res = await ShareQualityReport(params);

    if (res && res.stat) {
      notifications.show({
        type: 'success',
        message: 'Quality Services Reports Shared Successfully.',
        key: 'req-form',
      });
    }

    return res;
  } catch (err) {
    utils.displayErrors(err);

    // notifications.show({
    //   type: 'error',
    //   message: 'Failed to Share Quality Services Reports.',
    //   key: 'req-form',
    // });

    throw err;
  }
};
