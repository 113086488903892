import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { fuelService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'fuelPrice',
  initialState: adapter.getInitialState({
    busy: false,
    dirty: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setDirty(state, action) {
      let dirty = action.payload;
      state.dirty = dirty;
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await fuelService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
};

const selectors = {
  ...adapter.getSelectors((state) => state.fuelPrice),
};

export { actions, selectors };

export default slice.reducer;
