import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/extendedProfile/raw-material-products-icon.svg';

function RawMaterialProductsIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default RawMaterialProductsIcon;
