import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditIcon from 'mdi-react/ClipboardEditOutlineIcon';

import useQuery from 'lib/hooks/useQuery';

import OverviewHeader from 'common/OverviewHeader';
import utils, { getDecryptedRouteParams } from 'lib/utils';
import CreateQuickTransferForm from './CreateQuickTransferForm';
import { actions as storageActions } from 'redux/storage';

const COMMODITY_TYPE = {
  REQUIREMENTS: 'requirements',
  OFFERS: 'offers',
};

const Create = () => {
  const dispatch = useDispatch();
  const { type } = getDecryptedRouteParams(useParams());
  const { back, stock_transfer_id, isEdit, iscopy } = useQuery();
  const backLink = back ? back : `/commodity/${type}/all`;
  const decodeBackUrl = utils.decodeBackUrl(back);
  const user = useSelector((state) => state.user)
  const storageState = useSelector((state) => state.userStorage);
  const storageTransfers = storageState?.storageTransfers

  useEffect(() => {
    if(Boolean(isEdit) || Boolean(iscopy)) {
      const params = {
        filters: { stock_transfer_id: Number(stock_transfer_id) },
        offset: 0,
        page_size: 10,
      };
      dispatch(storageActions.fetchStockTransfers(params));
    }
  }, [stock_transfer_id]);

  return (
    <React.Fragment>
      <div className="bfc-html">
        <div className="bfc-header">
          <OverviewHeader
            heading={Boolean(isEdit) ? 'Edit Quick Transfer' : Boolean(iscopy) ? 'Copy Quick Transfer' : 'Create Quick Transfer'}
            headingIcon={<EditIcon size={35} />}
            backLink={back} />
        </div>
        <div className="bfc-body create-form-body">
          <CreateQuickTransferForm
            // type={type}
            backLink={decodeBackUrl}
            commodityData={(Boolean(isEdit) || Boolean(iscopy)) ? storageTransfers?.[0] : []}
          // handleSave={handlers.handleSave}
          // handlePublish={handlers.handlePublish}
          // heading='Create Quick Transfer'
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Create;
