import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const auctionSchema = yup.object().shape({
  commodity_id: yup
    .number()
    .required('Commodity is required.')
    .positive('Commodity is required.')
    .integer('Commodity is required.'),
  // commodity_Residue_Id: yup.number('Commodity residue is required.').required('Commodity residue is required.').positive('Commodity residue is required.').integer('Commodity residue is required.'),
  // commodity_Form_Id: yup.number('Commodity form is required.').required('Commodity form is required.').positive('Commodity form is required.').integer('Commodity form is required.'),
  qty: yup.number().typeError('Quantity is required.').required('Quantity is required.'),
  qty_uom_id: yup
    .number()
    .required('Quantity unit of measure is required.')
    .positive('Quantity unit of measure is required.')
    .integer('Quantity unit of measure is required.'),
  periodicity_enum: yup
    .number()
    .required('Periodicity is required.')
    .positive('Periodicity is required.')
    .integer('Periodicity is required.'),

  // min_qty: yup
  //   .number()
  //   .required('Minimum quantity is required.')
  //   .max(yup.ref('qty'), 'Minimum Quantity should be less than Quantity'),
  // // "total_qty" : 0,
  expected_price: yup
    .number()
    .typeError('Expected price is required.')
    .nullable(true)
    .when('is_rfq', {
      is: true,
      then: yup.number().typeError('Expected price is required.').optional(),
      otherwise: yup.number().typeError('Expected price is required.').required('Expected price is required.'),
    }),
  price_currency_id: yup.number(), //.required().positive().integer(),
  price_uom_id: yup
    .number()
    .required('Price unit of measure is required.')
    .positive('Price unit of measure is required.')
    .integer('Price unit of measure is required.'),
  address_id: yup
    .number()
    .required('Location is required.')
    .positive('Location is required.')
    .integer('Location is required.'),
  payment_term_id: yup
    .number()
    .required('Payment term is required.')
    .positive('Payment term is required.')
    .integer('Payment term is required.'),
  vehicle_type_id: yup
    .number()
    .required('Max vehicle capacity is required.')
    .positive('Max vehicle capacity is required.')
    .integer('Max vehicle capacity is required.'),
  // closing_on: yup.date().required('Closing on date is required.'),
  // valid_until: yup.date().required('Valid until date is required.'),
  qty_measured_at_enum: yup
    .number()
    .required('Quantity measured at is required.')
    .positive('Quantity measured at is required.')
    .integer('Quantity measured at is required.'),
  quality_measured_at_enum: yup
    .number()
    .required('Quality measured at is required.')
    .positive('Quality measured at is required.')
    .integer('Quality measured at is required.'),
  commodity_type_code: yup.string().required('Commodity form is required.'),
  commodity_residue_id: yup
    .number()
    .required('Commodity residue is required.')
    .positive('Commodity residue is required.')
    .integer('Commodity residue is required.'),
  entity_id: yup
    .number()
    .required('Please select Company')
    .positive('Please select Company')
    .integer('Please select Company'),
  from_date: yup.date().typeError('From Date is required.').required('From Date is required.'),
  to_date: yup.date().typeError('To Date is required.').required('To Date is required.'),
  auction_start_datetime: yup
    .date()
    .typeError('Auction Start DateTime is required')
    .required('Auction Start DateTime is required.'),
  minimum_change: yup.string().required('Minimum Change is required.'),
  auto_accept_time_in_minutes: yup.string().required('Accept/Reject Time is required.'),
  packaging_id: yup
    .number()
    .typeError('Please select Packaging')
    .required('Please select Packaging')
    .positive('Please select Packaging'),
  auction_duration_enum: yup
    .number()
    .required('Please Select Duration')
    .positive('Please Select Duration')
    .integer('Please Select Duration'),
});

export const targetPriceSchema = yup.object().shape({
  target_price: yup
    .number()
    .required('Target Price is required.')
    .min(yup.ref('min_target_price'), 'min')
    .max(yup.ref('max_target_price'), 'max'),
});
export const fetchChatMessages = async (params) => {
  try {
    let res = await api.fetchChatMessages(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteChatMessage = async (id) => {
  try {
    let res = await api.deleteChatMessage(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchList = async (params) => {
  try {
    let res = await api.fetchAllAuction(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchOwnAuctions = async (params) => {
  try {
    let res = await api.fetchOwnAuction(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchOwnBids = async (params) => {
  try {
    let res = await api.fetchOwnBids(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (type, values) => {
  try {
    // await helpers.validateSchema(planSchema, values);
    const res = await api.create(type, values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const save = async (type, values) => {
  try {
    await helpers.validateSchema(auctionSchema, values);
    const res = await api.save(type, values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (type, values) => {
  try {
    // await helpers.validateSchema(planSchema, values);
    const res = await api.update(type, values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAuctionDetails = async (params) => {
  try {
    let res = await api.fetchAuctionDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const enterAuction = async (params) => {
  try {
    const schema = yup.object().shape({
      auction_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      // periodicity_enum_code: yup.mixed().required('This field type required.'),

      vehicle_type_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      // periodicity_enum: yup.mixed().required('This field type required.'),

      address_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      bill_to_address_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      // company_affiliate_enum: yup
      //   .number()
      //   .required('This field is required.')
      //   .positive('This field is required.')
      //   .integer('This field is required.'),

      entity_id: yup.number().when('typeOfIndividualEnum', {
        is: 4,
        then: yup
          .number()
          .required('This field is required.')
          .positive('This field is required.')
          .integer('This field is required.'),
      }),

      // is_own_transport: yup.mixed().required('This field type required.'),
    });

    await helpers.validateSchema(schema, params);
    let res = await api.enterAuction(params['auction_id'] || 0, params['bill_to_address_id'] || 0);
    return res;
  } catch (err) {
    throw err;
  }
};

export const multipartyEnterAuction = async (params, commodityId) => {
  try {
    const schema = yup.object().shape({
      buy_sell_enum: yup.string().required('User Role is Required'),
      expected_price: yup
        // .number()
        .string()
        .required('Price is required.'),
      // .positive('Price is required.')
      // .integer('Price is required.'),
      quantity: yup
        .number()
        .required('Quantity is required.')
        .positive('Quantity is required.')
        .integer('Quantity is required.'),
      auction_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      vehicle_type_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),

      address_id: yup
        .number()
        .required('This field is required.')
        .positive('This field is required.')
        .integer('This field is required.'),
    });

    let res;
    // if (enterAuction) {
    //   res = await api.enterAuction(params['auction_id'] || 0);
    // } else {
    if (
      params['buy_sell_enum'] == 'Watch' ||
      params['buy_sell_multiparty_auction_id'] ||
      !_.isEmpty(params['buy_sell_multiparty_ids_arr'])
    ) {
      let enterParams;
      if (params['buy_sell_enum'] == 'Watch') {
        enterParams = {
          Commodity_Req_Offer_Multiparty_Auction_Ids: [],
        };
      } else if (params['buy_sell_multiparty_auction_id'] || params['buy_sell_multiparty_ids_arr']) {
        enterParams = {
          Commodity_Req_Offer_Multiparty_Auction_Ids: params['buy_sell_multiparty_ids_arr'],
        };
      }
      res = await api.enterMultipartyAuction(enterParams);
    } else {
      await helpers.validateSchema(schema, params);
      let enterParams = {
        Commodity_Req_Offer_Multiparty_Auction_Ids: [commodityId],
      };
      res = await api.enterMultipartyAuction(enterParams);
    }
    // }
    return res;
  } catch (err) {
    throw err;
  }
};

export const addToGroup = async (params) => {
  try {
    let res = await api.addToGroup(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const sendMessage = async (params) => {
  try {
    let res = await api.sendMessageFromBody(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const offerBidAuction = async (params, tsCost, type) => {
  try {
    const schema = yup.object().shape({
      auction_response_qty: yup.string().required('Please enter offer quantity'),
      auction_response_price: yup.string().required('Please enter auction response price'),
      delivery_start_date: yup.date().required('Please enter delivery_start_date'),
      delivery_end_date: yup.date().required('Please enter delivery_end_date'),
    });

    await helpers.validateSchema(schema, params.values);

    let res = await api.offerBidAuction(params, tsCost, type);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLandingPagePositions = async (id) => {
  try {
    let res = await api.fetchLandingPagePositions(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAuctionOfferBidDetails = async (params) => {
  try {
    let res = await api.fetchAuctionOfferBidDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const rejectBidOffer = async (params) => {
  try {
    let res = await api.rejectBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptBidOffer = async (params) => {
  try {
    let res = await api.acceptBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteBidOffer = async (params) => {
  try {
    let res = await api.deleteBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTransportationCost = async (params = {}) => {
  try {
    let res = await api.fetchTransportationCost(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchLiveroomDeals = async (params) => {
  try {
    let res = await api.fetchLiveroomDeals(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawAuction = async (params) => {
  try {
    let res = await api.withdrawAuction(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUnloadingCharges = async (params = {}) => {
  try {
    let res = await api.fetchUnloadingCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLoadingCharges = async (params = {}) => {
  try {
    let res = await api.fetchLoadingCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchChatDetails = async (userId, auctionId) => {
  try {
    let res = await api.fetchChatDetails(userId, auctionId);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTargetPrice = async (params) => {
  try {
    // await helpers.validateSchema(targetPriceSchema, params);
    const res = await api.updateTargetPrice(params);
    return res;
  } catch (err) {
    const allErrors = _.get(err, 'errors') || '';
    const targetPrice = _.get(allErrors, 'target_price') || '';
    let error = {
      ...err,
    };
    if (targetPrice == 'min') {
      let errors = _.get(error, 'errors') || {};
      errors = {
        ...errors,
        target_price: `Invalid Amount. Minimum Amount is: ${params['min_target_price']}`,
      };
      error = {
        ...err,
        errors: errors,
      };
    } else if (targetPrice == 'max') {
      let errors = _.get(error, 'errors') || {};
      errors = {
        ...errors,
        target_price: `Invalid Amount. Maximum Amount is: ${params['max_target_price']}`,
      };
      error = {
        ...err,
        errors: errors,
      };
    }
    throw error;
  }
};
