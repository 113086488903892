import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  type: 'info',
  message: '',
  visible: false,
};

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    show(state, action) {
      const { type, message } = action.payload;
      state.visible = true;
      state.type = type;
      state.message = message;
    },
    hide(state, action) {
      state.visible = false;
      state.type = 'info';
      state.message = '';
    },
  },
});

const actions = {
  ...slice.actions,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
