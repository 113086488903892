import { actions as redirectActions } from 'redux/redirect';
const redirect = {
	web: (path = '', props = {}) => {
		globalThis.store.dispatch(
			redirectActions.update({
				path,
				props,
			}),
		);
	},
	mobile: (path = '', props = {}) => {
		console.log('mobile->redirection-skiped-->', path);
	},
};

export default redirect;
