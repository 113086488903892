import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import utils, { getDecryptedUrl } from 'lib/utils';
import { actions as userActions } from 'redux/user';
import { actions as miscActions } from 'redux/misc';

import { userService, authService } from 'services';

import config from 'config';
import { toast } from 'react-toastify';

export async function handleRequestOTP(values, formikBag) {
  try {
    const res = await authService.sendLoginOTP({
      ...values,
    });
    // console.log('handleRequestOTP---->', res);

    globalThis.store.dispatch(
      miscActions.update({
        ...res,
        authStep: 'otp',
      }),
    );
  } catch (err) {
    utils.displayErrors(err, formikBag);
    console.log('handleRequestOTP->error--->>>', err);
  }
}

export async function handleRequestOTPforQuote(values, formikBag) {
  try {
    const res = await authService.sendLoginOTP({
      ...values,
    });
    // console.log('handleRequestOTP---->', res);

    // globalThis.store.dispatch(
    //   miscActions.update({
    //     ...res,
    //     authStep: 'otp',
    //   }),
    //   );
    // console.log('res==', res);
    localStorage.setItem('quoteVerification', JSON.stringify(res));
    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
    console.log('handleRequestOTP->error--->>>', err);
  }
}

export async function handleOTPSubmit(values, formikBag, isMobile, isIPad) {
  const { indentDealsUrl } = config;
  const loc = getDecryptedUrl(window.location.href);

  try {
    const params = {
      ...values,
      isMobile,
      isIPad,
    };

    let res = {};
    res = await authService.login(params);

    gtag('event', 'User_Login_Details', values);
    gtag('event', 'User_Login_Response', res);
    gtag('event', 'login', { ...res, ...values });
    if (window) {
      window.location.href = '/';
      if (loc.includes(`${indentDealsUrl}`)) {
        window.location.href = indentDealsUrl + '/dashboard';
      }
      return;
    }

    globalThis.store.dispatch(
      userActions.updateUser({
        ...res,
        isAuthenticated: false,
      }),
    );
    let userProfile = {};
    userProfile = await userService.fetchPersonalDetails();

    globalThis.store.dispatch(
      miscActions.update({
        flush: true,
      }),
    );

    globalThis.store.dispatch(
      userActions.updateUser({
        ...res,
        ...userProfile,
        isAuthenticated: true,
      }),
    );
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }
}

export async function handleOTPSubmitQuote(values, formikBag, isMobile, isIPad, setShowAccept) {
  const { indentDealsUrl } = config;
  const loc = getDecryptedUrl(window.location.href);
  // console.log('inside handleOTPSubmitQuote handler');
  try {
    const params = {
      ...values,
      isMobile,
      isIPad,
    };

    let res = {};
    res = await authService.loginQuote(params);
    // console.log('verify res', res);
    !_.isEmpty(res) ? setShowAccept(true) : setShowAccept(false);
    // window.location.href=`/printable-quote/${id}`
    // return res;

    // gtag('event', 'User_Login_Details', values);
    // gtag('event', 'User_Login_Response', res);
    // gtag('event', 'login', { ...res, ...values });
    // if (window) {
    //   window.location.href = '/';
    //   if (loc.includes(`${indentDealsUrl}`)) {
    //     window.location.href = indentDealsUrl + '/dashboard';
    //   }
    //   return;
    // }

    globalThis.store.dispatch(
      userActions.updateUser({
        ...res,
        isAuthenticated: false,
      }),
    );
    let userProfile = {};
    userProfile = await userService.fetchPersonalDetails();

    globalThis.store.dispatch(
      miscActions.update({
        flush: true,
      }),
    );

    globalThis.store.dispatch(
      userActions.updateUser({
        ...res,
        ...userProfile,
        isAuthenticated: true,
      }),
    );
  } catch (err) {
    setShowAccept(false);
    utils.displayErrors(err, formikBag);
  }
}

export async function handleResendOTP(params) {
  try {
    let res = await authService.sendLoginOTP(params);
    globalThis.store.dispatch(
      miscActions.update({
        ...res,
        authStep: 'otp',
      }),
    );
    // console.log('onResendOTP->res', res);
  } catch (err) {
    utils.displayErrors(err);
  }
}
