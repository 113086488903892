import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { resTransform, reqTransform } from './transforms';
import { actions as discoveryBuyersSellersActions } from 'redux/discoveryBuyersSellers';

const { baseUrl } = config;

export const fetchDiscoveryBuyersSellers = async (params = {}, dispatch) => {
  try {
    let bodyParams = reqTransform.fetchDiscoveryBuyersSellers(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/DiscoverBuyerSellers`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    const buyerSellerList = _.get(resData, 'buyer_sellers') || [];
    const commodityCountList = _.get(resData, 'commodity_count') || [];
    dispatch(discoveryBuyersSellersActions.updateCommodityCount(commodityCountList));
    return resTransform.fetchDiscoveryBuyersSellers(buyerSellerList);
  } catch (err) {
    console.log('api-DiscoverBuyerSellers-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DiscoverBuyerSellers');
    throw errors;
  }
};

export const connectProfile = async (params) => {
  try {
    const qs = reqTransform.connectProfile(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/ConnectDisconnect?To_Company_Id=${qs.to_company_id}&Connection_Status_Enum=${qs.connection_status_enum}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.connectProfile(resData);
  } catch (err) {
    console.log('api-connectProfile-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ConnectDisconnect');
    throw errors;
  }
};
