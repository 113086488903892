import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { actions as supplierRatingsActions } from 'redux/supplierRatings';
import { Progress, Popover } from 'antd';
import LogoLoader from 'common/LogoLoader';
import ShieldGreenIcon from 'assets/images/icons/shield_green.svg';
import ShieldBlueIcon from 'assets/images/icons/shield_blue.svg';
import ShieldVerifiedIcon from 'assets/images/icons/verified-icon.svg';
import ShieldNotverifiedIcon from 'assets/images/icons/notverified.svg';
import NestedExtentedProfileModal from 'shared/components/NestedExtentedProfileModal';
import NotFound from 'common/Form/NoDataFound';
import { Empty } from 'antd';
import ShieldIcon from 'common/Shield/shield';

import 'antd/dist/antd.css';
import './style.css';

function SupplierProgressBar({ discoveryCardPage, userId, companyName, dealOverviewBar, value, personId, id, addressId, tickvalue, isProfile, social_profile_id, personCompanyAddressId, isSinglePartyAuction }) {
    const dispatch = useDispatch();
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isMouseEnter, setIsMouseEnter] = useState(false);

    const supplierRating = useSelector((state) => state.supplierRatings);
    const busy = _.get(supplierRating, 'busy') || false;
    const ratingDetails = _.get(supplierRating, `entities.undefined`) || {}
    const validUntil = _.get(ratingDetails, 'Health_Safety.valid_until') || '';
    const formatValidUntil = utils.formatDate(validUntil);
    const validUntilNoHyphens = formatValidUntil.replace(/-/g, ' ');

    let ratingDetailsArray = Object.values(ratingDetails || {});

    let textShow = isSinglePartyAuction ? false : true;

    const first = value > 0 ? '#D2D2D2' : 'white';
    const second = value > 50 ? '#97C7E9' : 'white';
    const third = value > 100 ? '#1B62AB' : 'white';
    const forth = value > 150 ? '#1B62AB' : 'white';
    const green = value > 200 ? '#32A836' : 'white';

    const showAllRatings = () => {
        if (id != null) {
            const params = {
                extended_profile_id: id,
                address_id: addressId,
            };
            dispatch(supplierRatingsActions.fetchSupplierRatings(params));
        } else {
            dispatch(supplierRatingsActions.setAll({}));
        }
        if(!busy && ratingDetailsArray?.length > 0){
            setIsMouseEnter(true)
        }
    }

    const ratingToggle = () => {
        setIsEditorOpen(!isEditorOpen);
    };

    const content = (
        <div className="p-0" style={{ width: id ? '320px' : '300px' }}>
            <p className="rating-heading" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'var(--fs-base__two)', color: 'var(--unnamed-color-1b62ab)', width: '100%' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ gap: '10px' }}>
                  <ShieldIcon
                    tickvalue={tickvalue}
                    shieldText={true}
                  />
                    {/* <img src={shieldIcon} alt="" height="20" style={{ width: 'auto' }} />
                    <p className="m-0 fw-600">{message}</p> */}
                </div>
            </p>
            <ul style={{ padding: 0, margin: 0, listStyleImage: 'none', listStyleType: 'none' }}>
                {ratingDetailsArray.length > 0 ? (
                    <>
                    {ratingDetailsArray.map((item) => {
                        return (
                            <>
                                <li style={{ margin: '10px 0px' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 50px', alignContent: 'center' }}>
                                        <p style={{ margin: '4px 10px' }}>
                                            <span style={{ color: 'var(--unnamed-color-707070)', fontSize: 'var(--fs-base__three)', }}>{item.score_type}</span>
                                        </p>
                                        <p style={{ margin: '0px 0px 0px 0px', marginTop: '5px', marginLeft: '30px' }}>
                                            <div className="supplier-rating-table">
                                                {item.score_type != 'Sustainable_Practices' ? (
                                                    <>
                                                        <Progress percent={100} steps={1} strokeColor={item.score > 0 ? '#D2D2D2' : 'white'} format={(percent) => null} />
                                                        <Progress percent={100} steps={1} strokeColor={item.score > 25 ? '#97C7E9' : 'white'} format={(percent) => null} />
                                                        <Progress percent={100} steps={1} strokeColor={item.score > 50 ? '#1B62AB' : 'white'} format={(percent) => null} />
                                                        <Progress percent={100} steps={1} strokeColor={item.score > 75 ? '#1B62AB' : 'white'} format={(percent) => null} />
                                                        <Progress percent={100} steps={1} strokeColor={item.score > 100 ? '#32A836' : 'white'} format={(percent) => null} />
                                                    </>
                                                ) : <span style={{ color: 'var(--unnamed-color-707070)', fontSize: 'var(--fs-base__three)', }}>Coming soon</span>}
                                            </div>
                                        </p>
                                    </div>
                                </li>
                            </>
                        );
                    })}
                    </>
                ) : (
                    (!id || id != 0) && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    )
                )}
                {id ? <div className="text-center" style={{ color: 'var(--unnamed-color-1b62ab)', cursor: 'pointer' }}>
                    <span style={{ color: '#D9D9D9', fontSize: '12px', width: '100%' }}>Valid: {validUntilNoHyphens ? validUntilNoHyphens : '-'}</span>
                </div> : null}
                {id != null ? (
                    <>
                        <hr className="mt-10 mb-10"/>
                        <div
                            className="text-center"
                            onClick={(e) => {
                            setIsEditorOpen(true);
                            }}
                            style={{ color: 'var(--unnamed-color-1b62ab)', cursor: 'pointer' }}>
                            Know more
                        </div>
                    </>
                ) : ''}
            </ul>
            <div className="nested-modal">
                {isEditorOpen && (
                <NestedExtentedProfileModal
                    id={id}
                    userId={userId}
                    value={value}
                    extendedProfilePersonId={personId}
                    tickvalue={tickvalue}
                    addressId={addressId}
                    isOpen={isEditorOpen}
                    toggle={ratingToggle}
                    companyName={companyName}
                />
                )}
            </div>
        </div>
    );

    useEffect(()=>{
        if(isMouseEnter){
            gtag('event', 'Supplier_profile_drill_down_1',
            {
                Location_Access : ratingDetailsArray.filter((data) => data.id === 'Location_Access' ).map((data)=> data.score ),
                Infra_Equipment: ratingDetailsArray.filter((data) => data.id === 'Infra_Equipment' ).map((data)=> data.score ),
                Health_Safety : ratingDetailsArray.filter((data) => data.id === 'Health_Safety' ).map((data)=> data.score ),
                Quality_Processes : ratingDetailsArray.filter((data) => data.id === 'Quality_Processes' ).map((data)=> data.score ),
                Sustainable_Practices : ratingDetailsArray.filter((data) => data.id === 'Sustainable_Practices' ).map((data)=> data.score ),
                extendedProfilePersonId: personId ? personId : null,
                extendedProfileId : id ? id : null,
                extendedProfileAddressId : addressId ? addressId : null,
                userId : userId ? userId : null,
                companyName : companyName,
                otherCompanyName : ratingDetailsArray.filter((data) => data.id === 'Location_Access' ).map((data)=> data.company_code )
            })
            setIsMouseEnter(false)
        }
    },[isMouseEnter])

    return (
        <div className={dealOverviewBar ? "deals-progress__bar" : discoveryCardPage ? "supplier-rating-table__discovery" : "supplier-progress__bar"}>
            {isSinglePartyAuction ? (
                <>
                    {tickvalue != null && (
                        <Popover placement="rightTop" content={busy ? <LogoLoader /> : content} onMouseEnter={showAllRatings}>
                          <div>
                            <ShieldIcon
                              tickvalue={tickvalue}
                              shieldText={textShow}
                            />
                          </div>
                            {/* <img src={shieldIcon} alt="" height="18" style={{ marginTop: '-3px' }} /> */}
                        </Popover>
                    )}
                </>
            ) : (
                <>
                    {!isProfile ? (
                        <>
                            {tickvalue != null ? (
                                <Popover placement="rightTop" content={busy ? <LogoLoader /> : content} onMouseEnter={showAllRatings} >
                                    <Progress percent={100} steps={1} strokeColor={first} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={second} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={third} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={forth} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={green} format={(percent) => null} />
                                </Popover>
                            ) : (
                                <>
                                    <Progress percent={100} steps={1} strokeColor={first} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={second} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={third} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={forth} format={(percent) => null} />
                                    <Progress percent={100} steps={1} strokeColor={green} format={(percent) => null} />
                                </>
                            )}
                        </>
                    ) : (
                        tickvalue != null && (
                            <Popover placement="rightTop" content={'Verified Supplier'} >
                                <Link
                        to={getUrlWithEncryptedQueryParams(`/social/profile/extended-profile/${social_profile_id}?person_company_addressId=${addressId}`)}
                                    style={{ cursor: 'pointer' }}>
                                    <div>
                                      <ShieldIcon
                                        tickvalue={tickvalue}
                                        shieldText={true}
                                      />
                                    </div>
                                    {/* <img src={shieldIcon} alt="" height="18" style={{ marginTop: '-3px' }} /> */}
                                </Link>
                            </Popover>
                        )
                    )}
                </>
            )}
        </div>
    );
}

export default SupplierProgressBar;
