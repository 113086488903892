import React from 'react';
import ShieldGreyIcon from 'assets/images/icons/shield_grey.svg';
import ShieldGreenIcon from 'assets/images/icons/shield_green.svg';
import ShieldBlueIcon from 'assets/images/icons/shield_blue.svg';
import ShieldVerifiedIcon from 'assets/images/icons/verified-icon.svg';
import ShieldNotverifiedIcon from 'assets/images/icons/notverified.svg';
import { Popover } from 'antd';

const Shield = ({ dealVerifiedIcon, tickvalue, dontShowPopUp, onClick, isProfile, shieldText, ...rest }) => {
  //   const tickvalue = 1;
  let shieldIcon = null;
  let message = '';
  let messageColor = '';
  switch (tickvalue) {
    case 1:
    case '1':
      shieldIcon = ShieldBlueIcon;
      message = 'Verified Blue';
      messageColor = '#1B62AB';
      break;
    case 2:
    case '2':
      shieldIcon = ShieldGreenIcon;
      message = 'Verified Green';
      messageColor = '#599906';
      break;
    case 3:
    case '3':
      shieldIcon = ShieldVerifiedIcon;
      message = 'Verified Silver';
      messageColor = '#F1B043';
      break;
    case 4:
    case '4':
    // case 0:
    // case '0':
      shieldIcon = ShieldNotverifiedIcon;
      message = 'KYC Verified';
      messageColor = '#ff8080';
      break;
  }

  const content = (
    <div className="p-0" style={{zIndex: 10000}}>
      <p className="rating-heading" style={{ textAlign: 'center', fontSize: 'var(--fs-base__four)', color: messageColor }}>
        {message}
      </p>
    </div>
  );

  return (
    <>
      {tickvalue ? (
        dontShowPopUp != true ? (
          dealVerifiedIcon ? (
            <img src={shieldIcon} alt="" height="34" style={{ marginTop: '-3px', ...rest?.style }} />
          ) : (
            isProfile ? (
              <Popover zIndex={1400} placement="rightTop" content={'Verified Supplier'} >
                <img src={shieldIcon} alt="" height="20" style={{ marginTop: '-3px', ...rest?.style }} className="ml-auto mr-auto" onClick={onClick} />
              </Popover>
            ) : (
              shieldText ? (
                <>
                  <img src={shieldIcon} alt="" height="20" style={{ width: 'auto' }} />
                  <p className="m-0 fw-900" style={{ color: 'var(--unnamed-color-1b62ab)', fontFamily: 'sans-serif' }}>{message}</p>
                </>
              ) : (
                <>
                <img src={shieldIcon} alt="" height="18" style={{ marginTop: '-3px', ...rest?.style }} className="ml-auto mr-auto" onClick={onClick} />
                </>
              )
            )
          )
        ) : (
          <Popover zIndex={1400} placement="rightTop" content={content}>
            {dealVerifiedIcon ? <img src={shieldIcon} alt="" height="26" style={{ marginTop: '-3px', ...rest?.style }} /> : <img src={shieldIcon} alt="" height="20" style={{ marginTop: '-3px', ...rest?.style }} />}
          </Popover>
        )
      ) : null}
    </>
  );
};

export default Shield;
