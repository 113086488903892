import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { Formik, Field, Form, FieldArray } from 'formik';
import history from 'lib/history';
import utils from 'lib/utils';
import config from 'config';

const ConfirmExitLiveroomModal = (props) => {
  const { isWholeSaleTile, selectedCommodityFormCode, envira_commodity_id, isWholeSale, showLocationSourceId, urlParams, type, id, closeModal, color, btn, colored, header, rtl, backLink, isOpen, toggle, message, is_indent_enabled, is_seller_indent_enabled, transactionType} = props;
  const [loading, setLoading] = useState(false);
  const { indentDealsUrl } = config;

  const trimmedTransactionType = transactionType ?  transactionType.trim() : '';
const isAuctionTransaction = trimmedTransactionType === "Sell Auction" || trimmedTransactionType === "Buy Auction";
// console.log("Type===>", trimmedTransactionType, isAuctionTransaction);

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  const handleConfirm = (values, formikBag) => {
    if (isWholeSale) {
      window.open(indentDealsUrl + `/commodity/offers/all?page=1&wholeSaleRequirement=true&is_discovery=true&source_address_id=${showLocationSourceId}${urlParams}`, "_blank")
      closeModal(false)
    } else if (isWholeSaleTile) {
      window.open(indentDealsUrl + `/commodity/offers/all?page=1&wholeSaleRequirement=true&commodity_form_id=${selectedCommodityFormCode}&commodity_id=${envira_commodity_id}`, "_blank")
      closeModal(false)
    } else {
      if (!is_seller_indent_enabled && !is_indent_enabled) {
        if(type === "Sell" && isAuctionTransaction){
          window.open(indentDealsUrl + '/commodity/requirements/auction-all?page=1&wholeSaleOffer=true', "_blank");
        }else if(type === "Buy" && isAuctionTransaction){
          window.open(indentDealsUrl + '/commodity/offers/auction-all?page=1&wholeSaleRequirement=true', "_blank");
        }
        else if(type === "Sell"){
          window.open(indentDealsUrl + '/commodity/requirements/all?page=1&wholeSaleOffer=true', "_blank");
        } else if(type === "Buy"){
          window.open(indentDealsUrl + '/commodity/offers/all?page=1&wholeSaleRequirement=true', "_blank");
        }
        else if(type === "Auction"){
          window.open(indentDealsUrl + '/commodity/offers/auction-all?wholeSaleRequirement=true&page=1', "_blank")
        }
        else if(type === "Envira"){
          window.open(indentDealsUrl +`/commodity/offers/${envira_commodity_id}/overview`, "_blank")
        }
        closeModal(false)
      } else {
        // window.open(indentDealsUrl + '/commodity/requirements/user?page=1', "_blank");
        // closeModal(false)

        window.open(indentDealsUrl + '/dashboard', "_blank")
        closeModal(false)
      }
    }
  }
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        modalClassName={`ltr-support confirm-delete-modal`}
        className={`modal-dialog--${color} ${modalClass}`}>
        <div className="modal__header text-white" style={{ backgroundColor: '#1b62ab' }}>
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeModal} />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title text-white m-0">Redirection Notice</h4>
        </div>
        <div className="modal__body mt-20">
          {(
            // <span>You are leaving the BiofuelCircle Market Place & will be redirected to the Supply {is_seller_indent_enabled ? 'Seller' : 'Buyer'}<sup>TM</sup> Portal?</span>
            <span>You are leaving the BiofuelCircle Market Place & will be redirected to the Supply Portal?</span>
          )}
        </div>
        <ButtonToolbar className="modal__footer mb-20" style={{ padding: '0px 20px' }}>
          <Button disabled={loading} className="modal_cancel btn-sm w-100" onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={loading} className="modal_ok btn-sm w-100" outline={colored} color="primary" onClick={handleConfirm}>
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}{' '}
            {loading ? `Processing...` : `Proceed`}
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

export default ConfirmExitLiveroomModal;
