import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    return params;
  },
};

export const resTransform = {
  fetchList(resData) {
    let deliveryData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(deliveryData, 'transport_delivery_schedule_alloted_id');
  },
  fetchDetails(resData) {
    let data = {
      // ...resData,
      transport_delivery_schedule_alloted_id: _.get(resData, 'transport_delivery_schedule_alloted_id'),
      'Delivery No.': _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
      'Deal No': _.get(resData, 'commodity_deal_master_id') || 0,
      'Pick Up Date': _.get(resData, 'pickup_datetime') || 0,
      'Pick Location': _.get(resData, 'pickup_location') || 0,
      'Delivery Location': _.get(resData, 'delivery_location') || 0,
      Consignor: _.get(resData, 'consignor') || 0,
      Consignee: _.get(resData, 'consignee') || 0,
      'Distance KM': _.get(resData, 'estimated_km') || 0,
      'Expected Time': _.get(resData, 'estimated_time') || 0,
      Transporter: _.get(resData, 'transport_company_code') || 0,
      'Transport Contract No': _.get(resData, 'transport_deal_master_id') || 0,
      'Vehicle Regn No': _.get(resData, 'vehicle') || 0,
      'Vehicle Size': _.get(resData, 'vehicle_type_code') || 0,
      'Driver Name': _.get(resData, 'driver') || 0,
      'Driver Mobile No': _.get(resData, 'driver_mobile_no') || 0,
      'Gross Wt Loading': _.get(resData, 'gross_weight_at_load') || 0,
      'Tare Wt Loading': _.get(resData, 'tare_weight_at_load') || 0,
      'Net Wt Loading': _.get(resData, 'net_quantity_at_loading') || 0,
      'Gross Wt Delivery': _.get(resData, 'gross_weight_at_delivery') || 0,
      'Tare Wt Delivery': _.get(resData, 'tare_weight_at_delivery') || 0,
      'Net Wt Delivery': _.get(resData, 'net_quantity_at_delivery') || 0,
      'Delivery Status': _.get(resData, 'delivery_allotment_status_enum_code') || 0,
      'Loading Acknowledgment Date': _.get(resData, 'loading_acknowledgement_datetime') || '',
      'Delivery Acknowledgment Date': _.get(resData, 'delivery_acknowledgement_datetime') || '',
      id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
      'Linked Deal Id': _.get(resData, 'linked_deal_id') || 0,
      'Linked Delivery Id': _.get(resData, 'linked_delivery_id') || 0,
      'Early Payment Discount': _.get(resData, 'early_payment_discount'),
      'Early Payment Status': _.get(resData, 'is_early_payment_applicable')
        ? 'Yes'
        : _.get(resData, 'is_early_payment_applicable') === false
        ? 'No'
        : 'No',
      'Own Transport': _.get(resData, 'is_own_transport') ? 'Yes' : 'No',
      'Is Verified Supplier Services': _.get(resData, 'is_verified_supplier_services'),
    };
    return data;
  },
};
