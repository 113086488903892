import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Loading from '../../shared/components/Loading';
import inMemoryJWT from 'services/auth/inMemoryJWT';
import { actions as userActions } from '../../redux/user';
import { actions as rolesAndRightsActions } from 'redux/rolesAndRights';
import { actions as notificationsTopbarActions } from 'redux/notificationsTopbar';
import { actions as socialProfileActions } from 'redux/socialProfiles';
import { actions as locationElocActions } from 'redux/locationEloc';
import { useSelector, useDispatch } from 'react-redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import config from 'config';
import { debounce } from 'lodash';
import utils from 'lib/utils';
const { livechatUrl, notificationUrl, baseUrl, liveChatPassword } = config;

const BFCAuthProvider = (props) => {
  const dispatch = useDispatch();
  const { children } = props;
  const [loading, setLoading] = useState(true);
  const [userLoaded, setUserLoaded] = useState(true);
  const user = useSelector((state) => state.user);
  const isAuthenticated = _.get(user, 'isAuthenticated');
  const profileLoaded = _.get(user, 'profile.profileLoaded');
  const isCTUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined') || '';

  const sid = _.get(user, 'social_profile_id', 0);
  const companySocialProfileId = _.get(user, 'profile.company_social_profile_id', 0);

  const personRoles = _.get(user, 'profile.person_role') || []
  const isFacilitator = personRoles.some((item) => item.role_id == 52)
  const isCTFacilitator = user?.profile?.person_role?.some(item => item?.role_code === 'CT Facilitator');
  const isCTCommercial = personRoles.some((item) => item.role_id == 23)
  const isCTSuperUser = personRoles.some((item) => item.role_id == 20)
  const userHasRights = (isFacilitator || isCTFacilitator || isCTCommercial || isCTSuperUser);


  const roles = useSelector((state) => state.rolesAndRights);

  const rolesList = roles.entities || {};
  let ct_reportcustomer_success_team = Object.values(rolesList).find(
    (x) => (x.module_name === 'Report', x.sub_module_name === 'Customer_Success_Team'),
  );
  const ct_reportcustomer_success_teamrights =
    _.get(ct_reportcustomer_success_team, `full_access`) || _.get(ct_reportcustomer_success_team, `read_access`);

  //TODO : explain - doesn't work for updates - should use useSelector Hook
  //const storeState = globalThis.store.getState();
  // const userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum') || _.get(storeState, 'user.person.user_type_enum');

  useEffect(() => {
    const initAuth = async () => {
      setLoading(true);
      if (!isAuthenticated) {
        const result = await inMemoryJWT.checkTokens();
        if (result) {
          try {
            dispatch(
              userActions.updateUser({
                isAuthenticated: true,
                ...result,
              }),
            );
          } catch (err) {
            console.log('getRefreshedToken-->failed', err);
          }
        }
      }

      setLoading(false);
    };

    initAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.fetchPersonalDetails());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(rolesAndRightsActions.fetchList());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(locationElocActions.getOAuthToken());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isCTUser || ct_reportcustomer_success_teamrights || userHasRights) {
      const script = document.createElement('script');
      script.src = `https://apis.mapmyindia.com/advancedmaps/v1/${token}/map_load?v=1.5&plugin=prunecluster`;
      script.async = true;
      document.head.appendChild(script);
    }
  }, [isCTUser, ct_reportcustomer_success_teamrights, userHasRights, token]);

  const userId = _.get(user, 'userid') || 0;

  const ReceiveMessage = (message) => {
    dispatch(notificationsTopbarActions.updateNewNotificationCount(1));
    let params = {
      offset: 0,
      page_size: 5,
    };
    dispatch(notificationsTopbarActions.fetchNotification(params));
  };
  useEffect(() => {
    if (userId !== 0) {
      // const params = {
      //   userId: userId,
      // }
      // const qs = utils.getQs(params)
      const url = `${baseUrl}/BiofuelSignalR/NotificationUserHub/?userId=${userId}`
      // const url = `${baseUrl}/BiofuelSignalR/NotificationUserHub/?${qs}`
      const connect = new HubConnectionBuilder()
        .configureLogging(LogLevel.Debug)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();
      connect
        .start()
        .then(() => {
          connect.invoke('GetConnectionId').then((res) => console.log('NotificationToken--->', res));
          connect.on('ReceiveMessage', (message) => ReceiveMessage(message));
        })
        .catch((error) => {
          console.error('HubConnectionBuilder', error);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (sid) {
      dispatch(userActions.fetchSocialProfile(sid));
    }
  }, [sid]);

  useEffect(()=>{
    window.FreshworksWidget('hide', 'launcher');
  },[])

  useEffect(() => {
    if (companySocialProfileId) {
      dispatch(userActions.fetchSocialProfile(companySocialProfileId));
    }
  }, [companySocialProfileId]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (isAuthenticated && !profileLoaded) {
    return <Loading loading={loading} />;
  }

  return children;
};

// const mapStateToProps = (state) => ({
//   // user : state.user,
//   isAuthenticated: _.get(state.user, 'isAuthenticated', false),
// });

// const mapDispatchToProps = (dispatch) => ({
//   userActions: bindActionCreators(userActions, dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(BFCAuthProvider);

export default BFCAuthProvider;
