import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import redirect from 'lib/redirect';
import LocationIcon from 'assets/images/icons/maps-and-flags.svg';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PrepaidIcon from 'assets/subcriptionNew/prepaidPlanLogo.svg';
import BusinessIcon from 'assets/subcriptionNew/businessPlanLogo.svg';
import EnterpriseIcon from 'assets/subcriptionNew/enterprisePlanLogo.svg';
import EnterpriseWhiteIcon from 'assets/subcriptionNew/enterprisePlanWhiteLogo.svg';
import _ from 'lodash';

const OverviewMySubscriptionCard = (props) => {
  // let shieldIcon = null;
  const { data, encodeBackUrl, propsId } = props;
  const user = useSelector((state) => state.user);
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const id =
    _.get(data, 'company_address_id') || _.get(data, 'person_address_id') || 0;
  const location = _.get(data, 'company_address') || _.get(data, 'person_address') || '';
  const personCompanyAffiliateEnum = _.get(data, 'person_company_affiliate_enum') || 0;
  let personCompanyAffiliateId;
  if (isControlTowerUser && id === 0 && personCompanyAffiliateEnum !== 3) {
    personCompanyAffiliateId = propsId || user?.person_id;
  } else {
    personCompanyAffiliateId = _.get(data, 'subscription_entity_id') || 0
  }

  // const encodeBackUrl = utils.encodeBackUrl();
  // let locationLink = `/location/subscription/${id}/${personCompanyAffiliateId}/${personCompanyAffiliateEnum}?back=${encodeBackUrl}&page=1&entityId=${propsId}`;
  let locationLink = `/location/subscription/${id}/${personCompanyAffiliateId}/${personCompanyAffiliateEnum}?page=1&subscription_entity_id=${_.get(data, 'subscription_entity_id') || 0}&entityId=${propsId || user?.person_id}&planId=${data.group_plan_id}&back=${encodeBackUrl}`;

  const history = useHistory();
  let messageBg = '';
  let messageColor = '';
  let icon = PrepaidIcon;
  let iconSize = '';
  switch (data.subscription_plan_id) {
    case 4:
      messageBg =
        'transparent linear-gradient(68deg, #F3FFF2 0%, #E3FEE2 17%, #DCFFDA 48%, #F3FFF2 72%, #DCFFDA 100%) 0% 0% no-repeat padding-box';
      messageColor = '#1B62AB';
      icon = BusinessIcon;
      iconSize = '25';
      break;
    case 5:
      messageBg =
        'transparent linear-gradient(68deg, #F3FFF2 0%, #E3FEE2 17%, #DCFFDA 48%, #F3FFF2 72%, #DCFFDA 100%) 0% 0% no-repeat padding-box';
      messageColor = '#1B62AB';
      icon = BusinessIcon;
      iconSize = '25';
      break;
    case 6:
      messageBg =
        'transparent linear-gradient(67deg, #F9F0AB 0%, #FCF1B6 17%, #FFF8C9 48%, #FFF7C2 50%, #FCF3B6 72%, #F3E58F 100%) 0% 0% no-repeat padding-box';
      messageColor = '#707070';
      icon = EnterpriseIcon;
      iconSize = '20';
      break;
    case 11:
    case 12:
      messageBg =
        'rgb(101 184 119)';
      messageColor = '#ffffff';
      icon = EnterpriseWhiteIcon;
      iconSize = '20';
      break;

    default:
      messageBg = 'transparent linear-gradient(68deg, #E8F7FF 0%, #D0F2EA 48%, #EEFCFB 72%, #D5F4EB 100%)';
      messageColor = '#1B62AB';
      icon = PrepaidIcon;
      iconSize = '20';
      break;
  }

  return (
    <Link to={getUrlWithEncryptedQueryParams(locationLink)}>
      <div className="card pb-0">
        <div
          className="card-body" style={{ background: messageBg }}>
          <div className="top-content">
            <div class="d-flex align-items-center">
              <h5 className="" style={{ color: messageColor, fontSize: 'var(--fs-base__one)', }}>
                {data.subscription_entity_name}
              </h5>
              {
                data.is_in_trial &&
                <div class="ml-auto d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: 'var(--unnamed-color-ffffff)',
                      padding: '0px 15px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#096DD9',
                    }}>
                    <span>{'Trial'}</span>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="middle-content" style={{ minHeight: 100 }}>
            <div className="d-flex">
              <span className="" style={{ marginTop: '7px' }}>
                {location && <img src={LocationIcon} alt="" height={'20'} />}
              </span>
              <p className="address-content ml-5 mb-0" style={{ color: messageColor }}>
                {location}
              </p>
            </div>
          </div>
          <div className="bottom-content">
            <div class="d-flex align-items-center">
              <div class="">
                <img src={icon} alt="" height={iconSize} />
              </div>
              <div class="ml-10" style={{ color: messageColor, lineHeight: 'normal' }}>
                <p style={{ color: messageColor, fontWeight: 600 }}>{data.plan_name}</p>
                <small>
                  {data.subscription_plan_id === 1 || data.subscription_plan_id === 2 || data.subscription_plan_id === 3 || data.subscription_plan_id === 9 || data.subscription_plan_id === 10
                    ? `Balance: ${utils.formatPrice(data.prepaid_balance)} `
                    : data.is_subscribed
                      ? `Valid Until: ${utils.formatDate(data?.valid_till_date)}`
                      : null}
                </small>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <div
                  style={{
                    backgroundColor: 'var(--unnamed-color-ffffff)',
                    padding: '0px 15px',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    color: `${new Date(data.valid_till_date) > new Date() ? data.is_subscribed ? '#18B300' : '#096DD9' : 'red'}`,
                  }}>
                  <span>{new Date(data.valid_till_date) > new Date() ? data.is_subscribed ? 'Active' : 'To Pay' : 'Expired'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default OverviewMySubscriptionCard;
