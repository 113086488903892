import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { postService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (farm) => _.get(farm, 'farm_address.person_address_id', 0),
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => (a.ts === b.ts ? 0 : a.ts < b.ts ? 1 : -1),
});

const slice = createSlice({
  name: 'posts',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
    hasMore: true,
    // count: 0,
    groupPosts: [],
    filters: [],
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setGroupPosts: (state, { payload }) => ({ ...state, groupPosts: payload }),
    setFilters: (state, { payload }) => ({ ...state, filters: payload }),
    setBusy(state, action) {
      state.busy = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    // setCount(state, action) {
    //   state.count = action.payload;
    // },
    // getCount(state, action) {
    //   return state.count;
    // },
  },
});

const fetchReportedPosts =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchReportedPosts(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchReportedComments =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchReportedComments(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchAllFeed =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchAllFeed(params);
      if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
        dispatch(slice.actions.setHasMore(false));
      }
      dispatch(slice.actions.upsertMany(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

// const fetchFiltersCount =  (count) =>
// async (dispatch) => {
//   dispatch(slice.actions.setCount(count));
// };

const fetchAllFeedWithFilters =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchAllFeedWithFilters(params);
      if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
        dispatch(slice.actions.setHasMore(true));
      }
      dispatch(slice.actions.upsertMany(resData));
      // dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchAllGroupFeed =
  (params = {}) =>
  async (dispatch) => {
    const storeState = globalThis.store.getState();
    const groupPosts = _.get(storeState, 'posts.groupPosts') || [];
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchAllFeed(params);
      if (params?.isScrolling) {
        dispatch(
          slice.actions.setGroupPosts(
            _.orderBy([...Object.values(groupPosts), ...Object.values(resData)], 'id', 'desc'),
          ),
        );
      } else {
        dispatch(slice.actions.setGroupPosts(_.orderBy(Object.values(resData), 'id', 'desc')));
      }
      if (!Object.values(resData).length) {
        dispatch(slice.actions.setHasMore(false));
      }
    } catch (err) {
      // dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchAllCTFeed =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchAllCTFeed(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchPostComments = (params) => async (dispatch) => {
  dispatch(
    slice.actions.upsertOne({
      id: params['post_id'],
      busy: true,
    }),
  );
  try {
    const resData = await postService.fetchPostComments(params);
    dispatch(
      slice.actions.upsertOne({
        id: params['post_id'],
        comments: resData,
      }),
    );
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(
    slice.actions.upsertOne({
      id: params['post_id'],
      busy: false,
    }),
  );
};
const editSavedComment = (params) => async (dispatch) => {
  dispatch(
    slice.actions.upsertOne({
      comment_id: params['post_comment_id'],
      busy: true,
    }),
  );
  try {
    const resData = await postService.editSavedComment(params);
    dispatch(
      slice.actions.upsertOne({
        comment_id: params['post_comment_id'],
        comments: resData,
      }),
    );
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(
    slice.actions.upsertOne({
      id: params['post_id'],
      busy: false,
    }),
  );
};

const fetchTimelinePost =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchTimelinePost(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const fetchSavedPosts =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.fetchSavedPosts(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const deleteSavedPost = (id, isSaved) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await postService.deleteSavedPost(id, isSaved);
    dispatch(slice.actions.removeOne(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const deleteSavedComment = (params) => async (dispatch) => {
  try {
    const resData = await postService.deleteSavedComment(params);
    dispatch(slice.actions.removeOne(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
};
const fetchSearchPost =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await postService.searchPost(params);
      if (_.isEmpty(resData)) {
        dispatch(slice.actions.setHasMore(false));
      }
      dispatch(slice.actions.upsertMany(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const actions = {
  ...slice.actions,
  fetchAllFeed,
  // fetchFiltersCount,
  fetchAllFeedWithFilters,
  fetchPostComments,
  fetchTimelinePost,
  fetchSavedPosts,
  deleteSavedPost,
  deleteSavedComment,
  fetchReportedPosts,
  fetchReportedComments,
  fetchAllCTFeed,
  fetchSearchPost,
  editSavedComment,
  fetchAllGroupFeed,
};

// // Rename the exports for readability in component usage
// export const {
//   selectById: selectUserById,
//   selectIds: selectUserIds,
//   selectEntities: selectUserEntities,
//   selectAll: selectAllUsers,
//   selectTotal: selectTotalUsers
// } = usersAdapter.getSelectors(state => state.users)

const selectors = {
  ...adapter.getSelectors((state) => state.posts),
};

export { actions, selectors };

export default slice.reducer;
