import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

// export const TeamsSchemaWithEmailValidation = yup.object().shape({
export const TeamsSchema = yup.object().shape({
  first_name: yup.string().required('First Name is a required field'),
  last_name: yup.string().required('Last Name is a required field'),
  email_id: yup.string().email().required('Email ID is a required field'),
  company_address_ids: yup.string().required('Select Location is a required field'),
});

// export const TeamsSchemaWithMobileValidation = yup.object().shape({
//   first_name: yup.string().required('First Name is a required field'),
//   last_name: yup.string().required('Last Name is a required field'),
//   mobile_number: yup.string().max(10).required('Mobile number is a required field'),
//   company_address_ids: yup.string().required('Select Location is a required field'),
// });

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTeamMembersFromOrganization = async (params = {}) => {
  try {
    let res = await api.fetchTeamMembersFromOrganization(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(TeamsSchema, params);
    // await helpers.validateSchema(
    //   params?.['mobile_number'] ? TeamsSchemaWithMobileValidation : TeamsSchemaWithEmailValidation,
    //   params,
    // );
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(TeamsSchema, params);
    // await helpers.validateSchema(
    //   params?.['mobile_number'] ? TeamsSchemaWithMobileValidation : TeamsSchemaWithEmailValidation,
    //   params,
    // );
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAddressList = async (params = {}) => {
  try {
    let res = await api.fetchAddressList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchEPList = async (params = {}) => {
  try {
    let res = await api.fetchEPList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
