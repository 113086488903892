import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as companyActions } from 'redux/companies';
import { actions as bankActions } from 'redux/bank';
import _ from 'lodash';
import api from 'lib/api';

export default function UnitSelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialDisplayText,
    entity_id,
    company_affiliate_enum,
    disabled,
    ...rest
  } = props;

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);

  options = [
    { value: 1, label: `MT` },
  ];
  if (!options.length) {
    loading = true;
  }

  useEffect(() => {
    if (!loading) {
      let initialAddressId = _.get(props, 'form.initialValues.unit') || 0;
      const initialSelected = options.filter((o) => initialAddressId === o.value);
      setValue(initialSelected);
    }
  }, [loading]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
