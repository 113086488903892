// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

let SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL || '';
let environment = 'localhost';
if (window.location.href.includes('https://my.biofuelcircle.com')) {
  environment = 'production';
} else if (window.location.href.includes('prep')) {
  environment = 'prep';
} else if (window.location.href.includes('test')) {
  environment = 'test';
} else if (window.location.href.includes('demo')) {
  environment = 'demo';
} else if (window.location.href.includes('staging')) {
  environment = 'staging';
} else if (window.location.href.includes('dev')) {
  environment = 'dev';
}

Sentry.init({
  dsn: SENTRY_DSN_URL,
  environment: environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  initialScope: (scope) => {
    let pid = localStorage.getItem('pid') || 'NA';
    scope.setTags({ pid: pid });
    return scope;
  },
});

// ReactDOM.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>,
// 	document.getElementById('root'),
// );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
