import _ from 'lodash';
import * as api from './api';

export const fetchPickupBuyOffers = async (params) => {
  try {
    let res = await api.fetchPickupBuyOffers(params);
    return res;
  } catch (err) {
    throw err;
  }
};
