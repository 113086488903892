import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import LogoLoader from 'common/LogoLoader';
import { Link, useHistory } from 'react-router-dom';
import { Table, Tag ,Popover, Skeleton } from 'antd';
import _ from 'lodash';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';

import useQuery from 'lib/hooks/useQuery';
import OverviewHeader from 'common/OverviewHeader';
import AntPagination from 'common/AntTablePagination';
import { actions as facilitatorAction } from 'redux/facilitator';
import dayjs from 'dayjs';
import FilterUtils from 'lib/filterUtils';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import utils, {  getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import OverviewNav from 'common/OverviewNav';
import ClosingDateField from 'common/TableComponents/closingDateField';
import requirements from 'redux/requirements';
import notifications from 'lib/notifications';

const Facilitator = () => {
  const dispatch = useDispatch();
  const facilitatorState = useSelector((state) => state.facilitator);
  const facilitators = !_.isEmpty(facilitatorState) && Object.values(facilitatorState?.entities);
  const userInfo = useSelector((state) => state.user);
  const isCTUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;

  const loc = getDecryptedUrl(window.location.href);
  const csvLink = useRef();
  const tableRef = useRef();

  let {
    page,
    back,
    kyc_status_enum,
    facilitator_person_name,
    type_of_individual_enum,
    facilitator_organisation,
    person_company_affiliate_name,
    location,
    number_of_vehicles,
    kyc_approved_on,
    farm_profile_complete,
    buy_sell,
    bid_offer,
    deal_no,
    deal_quantity,
    deal_status,
    deal_date,
    company_affiliate_name,
    person_name,
    bank_verification_enum,
    created_datetime,
    isNetwork
  } = useQuery();
  const history = useHistory();

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  useEffect(() => {
    const params = {
      filters: loc.includes('control-tower/facilitator')
        ? { ...filters, assist_type_enum: '1,5' }
        : { ...filters, assist_type_enum: '3,4' },
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(facilitatorAction.fetchFacilitatorLog(params));
  }, [
    page,
    rowsPerPage,
    kyc_status_enum,
    facilitator_person_name,
    type_of_individual_enum,
    facilitator_organisation,
    person_company_affiliate_name,
    location,
    number_of_vehicles,
    kyc_approved_on,
    farm_profile_complete,
    filters,
    loc,
    buy_sell,
    bid_offer,
    deal_no,
    deal_quantity,
    deal_status,
    deal_date,
    company_affiliate_name,
    person_name,
    bank_verification_enum,
    created_datetime,
  ]);

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (isNetwork) {
      if (back != undefined || back != null) {
        history.push(pathname + '?' + `back=${back}&page=1&isNetwork=true`);
      } else {
        history.push(pathname + '?' + `&page=1&isNetwork=true`);
      }
    } else {
      if (back != undefined || back != null) {
        history.push(pathname + '?' + `back=${back}&page=1`);
      } else {
        history.push(pathname + '?' + `&page=1`);
      }
    }
  };


  const rejectedContent = (
    <p style={{ lineHeight: '10px', color: 'black' }}>Counteparty Rejected, not willing to wait 24 hrs</p>
  );
  const cancelledContent = <p style={{ lineHeight: '10px', color: 'black' }}>Deal cancelled as No Subscription</p>;
  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'kyc_status_enum_code':
        delete params['kyc_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('kyc_status_enum'));
        break;
      case 'type_of_user':
        delete params['type_of_individual_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('type_of_individual_enum'));
        break;
      case 'deal_status_enum_code':
        delete params['deal_status'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('deal_status'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params;
    if (isNetwork) {
      params = {
        ...filters,
        isNetwork: isNetwork ? true : false,
      };
    } else {
      params = {
        ...filters,
      };
    }
    switch (dataIndex) {
      case 'kyc_status_enum_code':
        params = {
          ...params,
          kyc_status_enum: e,
        };
        break;
      case 'type_of_user':
        params = {
          ...params,
          type_of_individual_enum: e,
        };
        break;
      case 'deal_status_enum_code':
        params = {
          ...params,
          deal_status: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }

    if (isNetwork) {
      history.push(FilterUtils.createUrl(params));
      delete params['isNetwork'];
      setFilters(params);
      setScrollToFirstRow(true);
    } else {
      history.push(FilterUtils.createUrl(params));
      setFilters(params);
      setScrollToFirstRow(true);
    }
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const backLink = utils.encodeBackUrl();

  const FACILITATOR_TABLE_HEADER = [
    {
      title: 'Facilitator',
      dataIndex: 'facilitator_person_name',
      width: '150px',
      align: 'left',
      fixed: true,
      sorter: (a, b) =>
        a.facilitator_person_name &&
        a.facilitator_person_name.localeCompare(b.facilitator_person_name && b.facilitator_person_name),
      render: (text, row) => {
        return (
          <div className="bfc-table-list-components text-left">
            <div className="d-block">{row.facilitator_person_name}</div>
            <div>({row.mobile_number})</div>
          </div>
        );
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'facilitator_person_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={facilitator_person_name}
          name={'Facilitator'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'facilitator_person_name'),
      filteredValue: facilitator_person_name !== undefined ? facilitator_person_name : null,
    },
    ...(loc.includes('control-tower/facilitator') || loc.includes('control-tower/transactions')
      ? [
        {
          title: 'Facilitator Organization',
          dataIndex: 'facilitator_organisation',
          width: '200px',
          align: 'left',
          fixed: true,
          sorter: (a, b) =>
            a.facilitator_organisation &&
            a.facilitator_organisation.localeCompare(b.facilitator_organisation && b.facilitator_organisation),
          render: (text, row) => {
            return <div className="bfc-table-list-components text-left">{row.facilitator_organisation}</div>;
          },
          filterDropdown: (props) => (
            <TextSearchFilter
              dataIndex={'facilitator_organisation'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={facilitator_organisation}
              name={'Facilitator Organization'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'facilitator_organisation'),
          filteredValue: facilitator_organisation !== undefined ? facilitator_organisation : null,
        },
      ]
      : []),
    {
      title: 'User',
      dataIndex: 'person_name',
      width: '150px',
      align: 'left',
      sorter: (a, b) => a.person_name && a.person_name.localeCompare(b.person_name && b.person_name),
      render: (text, row) => {
        return isCTUser ? (
          <Link className="d-block" to={getUrlWithEncryptedQueryParams(`/account/${row?.person_company_affiliate_id}/myaccount?back=${backLink}`)}>
            {row.person_name}
          </Link>
        ) : (
          // <Link className="d-block" to={`/account/${row?.person_company_affiliate_id}/myaccount?back=${backLink}`}>
            <div className="bfc-table-list-components text-left">{row.person_name}</div>
          // </Link>
        );
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'person_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={person_name}
          name={'User'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'person_name'),
      filteredValue: person_name !== undefined ? person_name : null,
    },
    {
      title: 'Type',
      dataIndex: 'type_of_individual_enum_code',
      width: '200px',
      align: 'left',
      sorter: (a, b) =>
        a.type_of_individual_enum_code &&
        a.type_of_individual_enum_code.localeCompare(b.type_of_individual_enum_code && b.type_of_individual_enum_code),
      render: (text, row) => {
        return <div className="bfc-table-list-components text-left">{row.type_of_individual_enum_code}</div>;
      },
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'type_of_user'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={type_of_individual_enum}
          name={'Type Of User'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'type_of_individual_enum'),
      defaultFilteredValue: type_of_individual_enum !== undefined ? type_of_individual_enum : null,
    },
    ...(loc.includes('control-tower/transactions')
      ? [
        {
          title: 'Business/Affiliate',
          dataIndex: 'company_affiliate_name',
          width: '200px',
          align: 'left',
          sorter: (a, b) =>
            a.company_affiliate_name &&
            a.company_affiliate_name.localeCompare(b.company_affiliate_name && b.company_affiliate_name),
          render: (text, row) => {
            return <div className="bfc-table-list-components text-left">{row.company_affiliate_name}</div>;
          },
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'company_affiliate_name'}
              codeBaisedFilter={true}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={company_affiliate_name}
              name={'Business/Affiliate'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'company_affiliate_name'),
          defaultFilteredValue: company_affiliate_name !== undefined ? company_affiliate_name : null,
        },
      ]
      : []),
    {
      title: 'Location',
      dataIndex: 'location',
      width: '150px',
      align: 'center',
      sorter: (a, b) => a.location && a.location.localeCompare(b.location && b.location),
      render: (text, row) => {
        return <div className="bfc-table-list-components text-center">{row.location}</div>;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'location'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={location}
          name={'Location'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'location'),
      filteredValue: location !== undefined ? location : null,
    },
    ...(loc.includes('control-tower/facilitator')
      ? [
        {
          title: 'Bank A/C',
          dataIndex: 'bank_verification_enum_code',
          width: '150px',
          align: 'center',
          sorter: (a, b) =>
            a.bank_verification_enum_code &&
            a.bank_verification_enum_code.localeCompare(b.bank_verification_enum_code && b.bank_verification_enum_code),
          render: (text, row) => (
            <span>
              {text == 'Verified' && (
              <Tag color={'lime'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Failed' && (
              <Tag color={'volcano'} key={text}>
                <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
              </Tag>
            )}
            </span>
          ),
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'bank_verification_enum'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={bank_verification_enum}
              name={'Status'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'bank_verification_enum'),
          defaultFilteredValue: bank_verification_enum !== undefined ? bank_verification_enum : null,
        },
        {
          title: 'KYC Status',
          dataIndex: 'kyc_status_enum_code',
          width: '150px',
          align: 'center',
          sorter: (a, b) =>
            a.kyc_status_enum_code &&
            a.kyc_status_enum_code.localeCompare(b.kyc_status_enum_code && b.kyc_status_enum_code),
          render: (text, row) => (
            <span>
              {text == 'Pending' && (
                <Tag color={'lime'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Rejected' && (
                <Tag color={'red'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Approved' && (
                <Tag color={'green'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {(text == 'Not Submitted' || text == 'NotSubmitted') && (
                <Tag color={'blue'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
            </span>
          ),
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'kyc_status_enum_code'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={kyc_status_enum}
              name={'Status'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'kyc_status_enum'),
          defaultFilteredValue: kyc_status_enum !== undefined ? kyc_status_enum : null,
        },
        {
          title: 'KYC Approved On',
          dataIndex: 'kyc_approved_on',
          width: '170px',
          align: 'center',
          sorter: (a, b) =>
            a.kyc_approved_on && a.kyc_approved_on.localeCompare(b.kyc_approved_on && b.kyc_approved_on),
          render: (text, row) => {
            return (
              <div className="bfc-table-list-components text-center">
                {row.kyc_approved_on ? dayjs(row.kyc_approved_on).format('DD-MMM-YY') : ''}
              </div>
            );
          },
          filterDropdown: (props) => (
            <RangeDateSearchFilter
              dataIndex={'kyc_approved_on'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={kyc_approved_on}
              name={'KYC Approved On'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'kyc_approved_on'),
          defaultFilteredValue: kyc_approved_on !== undefined ? kyc_approved_on : null,
        },
        {
          title: 'Modified Datetime',
          dataIndex: 'created_datetime',
          width: '170px',
          sorter: (a, b) => a.created_datetime && a.created_datetime.localeCompare(b.created_datetime && b.created_datetime),
          ellipsis: true,
          align: 'center',
          render: (text, row) => {
            return (
              <div>
                {row.created_datetime ? (
                  <ClosingDateField closing_on={row.created_datetime} to_date={row.created_datetime} />
                ) : (
                  ''
                )}
              </div>
            );
          },
          filterDropdown: (props) => (
            <RangeDateSearchFilter
              dataIndex={'created_datetime'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={created_datetime}
            />
          ),
          filterIcon: (filtered) => getDateIcon(filtered, 'created_datetime'),
          defaultFilteredValue: created_datetime !== undefined ? created_datetime : null,
        },
        {
          title: 'Farm Profile',
          dataIndex: 'farm_profile_complete',
          width: '150px',
          align: 'center',
          sorter: (a, b) =>
            a.farm_profile_complete &&
            a.farm_profile_complete.localeCompare(b.farm_profile_complete && b.farm_profile_complete),
          render: (text, row) => {
            return <div className="bfc-table-list-components text-center">{row.farm_profile_complete}</div>;
          },
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'farm_profile_complete'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={farm_profile_complete}
              name={'Status'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'farm_profile_complete'),
          defaultFilteredValue: farm_profile_complete !== undefined ? farm_profile_complete : null,
        },
        {
          title: 'Vehicles/ Equipment',
          dataIndex: 'number_of_vehicles',
          width: '180px',
          align: 'center',
          sorter: (a, b) =>
            a.number_of_vehicles && a.number_of_vehicles - b.number_of_vehicles && b.number_of_vehicles,
          render: (text, row) => {
            return <div className="bfc-table-list-components text-center">{row.number_of_vehicles}</div>;
          },
          filterDropdown: (props) => (
            <NumericSearchFilter
              dataIndex={'number_of_vehicles'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={number_of_vehicles}
              name={'Vehicles/ Equipment'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'number_of_vehicles'),
          filteredValue: number_of_vehicles !== undefined ? number_of_vehicles : null,
        },
      ]
      : []),
    ...(loc.includes('control-tower/transactions')
      ? [
        {
          title: 'Buy/Sell',
          dataIndex: 'buy_sell',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.buy_sell && a.buy_sell - b.buy_sell && b.buy_sell,
          render: (text, row) => {
            return (
              <div className="bfc-table-list-components text-center">
                {row.buy_sell === 0 ? (
                  '-'
                ) : (
                  <Link
                      to={getUrlWithEncryptedQueryParams(`/commodity/${row?.req_offer_type_enum_code?.toLowerCase() + 's'}/${row.buy_sell
                      }/overview?back=${backLink}`)}>
                    {row.buy_sell}
                  </Link>
                )}
              </div>
            );
          },
          filterDropdown: (props) => (
            <NumericSearchFilter
              dataIndex={'buy_sell'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={number_of_vehicles}
              name={'Buy/Sell'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'buy_sell'),
          filteredValue: buy_sell !== undefined ? buy_sell : null,
        },
        {
          title: 'Bid/Offer',
          dataIndex: 'bid_offer',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.bid_offer && a.bid_offer - b.bid_offer && b.bid_offer,
          render: (text, row) => {
            return (
              <div className="bfc-table-list-components text-center">
                {/* {row?.offer_bid_type_enum_code === 0 ? (
                  '-'
                ) : (
                  <Link
                    to={`/commodity/${row?.offer_bid_type_enum_code?.toLowerCase() + 's'}/${row.against_buy_sell}/bids?back=${backLink}`}>
                    {row.bid_offer}
                  </Link>
                )} */}
                {row?.offer_bid_type_enum_code === 0 ? (
                  '-'
                ) : (
                  row?.offer_bid_type_enum_code === 'Offer' ? (
                    <Link
                        to={getUrlWithEncryptedQueryParams(`/commodity/${'requirements'}/${row.against_buy_sell}/bids?back=${backLink}`)}>
                      {row.bid_offer}
                    </Link>
                  ) : (
                    <Link
                          to={getUrlWithEncryptedQueryParams(`/commodity/${'offers'}/${row.against_buy_sell}/bids?back=${backLink}`)}>
                      {row.bid_offer}
                    </Link>
                  )
                )}
              </div>
            );
          },
          filterDropdown: (props) => (
            <NumericSearchFilter
              dataIndex={'bid_offer'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={bid_offer}
              name={'Bid/Offer'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'bid_offer'),
          filteredValue: bid_offer !== undefined ? bid_offer : null,
        },
        {
          title: 'Deal No',
          dataIndex: 'deal_no',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.deal_no && a.deal_no - b.deal_no && b.deal_no,
          render: (text, row) => {
            return (
              <div className="bfc-table-list-components text-center">
                <Link to={getUrlWithEncryptedQueryParams(`/commodity/deals/${row.deal_no}/overview?back=${backLink}`)}>{row.deal_no}</Link>
              </div>
            );
          },          filterDropdown: (props) => (
            <NumericSearchFilter
              dataIndex={'deal_no'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={deal_no}
              name={'Deal'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'deal_no'),
          filteredValue: deal_no !== undefined ? deal_no : null,
        },
        {
          title: 'Deal Quantity',
          dataIndex: 'deal_quantity',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.deal_quantity && a.deal_quantity - b.deal_quantity && b.deal_quantity,
          render: (text, row) => {
            return <div className="bfc-table-list-components text-center">{`${row.deal_quantity || ''} ${row?.qty_uom_code || ''}`}</div>;
          },
          filterDropdown: (props) => (
            <NumericSearchFilter
              dataIndex={'deal_quantity'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={deal_quantity}
              name={'Deal Quantity'}
            />
          ),
          filterIcon: (filtered) => getSearchIcon(filtered, 'deal_quantity'),
          filteredValue: deal_quantity !== undefined ? deal_quantity : null,
        },
        {
          title: 'Deal Status',
          dataIndex: 'deal_status',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.deal_status && a.deal_status - b.deal_status && b.deal_status,
          render: (text, row) => (
            <span>
              {(text == 'Rejected' || text == 'Deleted') && (
                <Tag color={'red'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Completed' && (
                <Tag color={'blue'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {(text == 'Accepted' || text == 'Confirmed') && (
                <Tag color={'green'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {(text == 'Pending' || text == 'Draft') && (
                <Tag color={'purple'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Finalized' && (
                <Tag color={'orange'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Reviewed' && (
                <Tag color={'cyan'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Buyer to Confirm' && (
                <Tag color={'magenta'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Seller to Confirm' && (
                <Tag color={'magenta'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Buyer and Seller to Confirm' && (
                <Tag color={'geekblue'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Seller to Confirm Subscription' && (
                <Tag color={'magenta'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Buyer to Confirm Subscription' && (
                <Tag color={'magenta'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Rejected*' && (
                <Popover placement="rightTop" content={rejectedContent}>
                  <Tag color={'volcano'} key={text}>
                    <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                  </Tag>
                </Popover>
              )}
              {text == 'Buyer and Seller to Confirm Subscription' && (
                <Tag color={'geekblue'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
              {text == 'Cancelled' && (
                <Popover placement="rightTop" content={cancelledContent}>
                  <Tag color={'volcano'} key={text}>
                    <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                  </Tag>
                </Popover>
              )}
              {text == 'Cancelled*' && (
                <Tag color={'volcano'} key={text}>
                  <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                </Tag>
              )}
            </span>
          ),
          filterDropdown: (props) => (
            <AutoCompleteFilter
              dataIndex={'deal_status_enum_code'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={deal_status}
              name={'Status'}
            />
          ),
          filterIcon: (filtered) => getFilteredIcon(filtered, 'deal_status'),
          filteredValue: deal_status !== undefined ? deal_status : null,
        },
        {
          title: 'Deal Date',
          dataIndex: 'deal_date',
          width: '180px',
          align: 'center',
          sorter: (a, b) => a.deal_date && a.deal_date - b.deal_date && b.deal_date,
          render: (text, row) => {
            return (
              <div className="bfc-table-list-components text-center">
                <ClosingDateField closing_on={row?.deal_date} to_date={row?.deal_date} />
              </div>
            );
          },
          filterDropdown: (props) => (
            <RangeDateSearchFilter
              dataIndex={'deal_date'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={deal_date}
              name={'Vehicles/ Equipment'}
            />
          ),
          filterIcon: (filtered) => getDateIcon(filtered, 'deal_date'),
          filteredValue: deal_date !== undefined ? deal_date : null,
        },
      ]
      : []),
  ];

  const getExportReport = async () => {
    const params = {
      filters: loc.includes('control-tower/facilitator')
        ? { ...filters, assist_type_enum: '1, 5' }
        : { ...filters, assist_type_enum: '3,4' },
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: 100000,
    };
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    const success = await dispatch(facilitatorAction.fetchFacilitatorCsvExtract(params));
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  const tableData = facilitators || [];
  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  let links;
  if (isNetwork) {
    links = [
      { to: `/control-tower/facilitator?page=1&isNetwork=true`, title: 'Users' },
      { to: `/control-tower/transactions?page=1&isNetwork=true`, title: 'Transactions' },
    ];
  } else {
    links = [
      { to: `/control-tower/facilitator?page=1`, title: 'Users' },
      { to: `/control-tower/transactions?page=1`, title: 'Transactions' },
    ];
  }

  return (
    <div>
      <OverviewNav links={links} />
      {/* <OverviewHeader heading="Facilitator" /> */}
      <div className="invoice-table">
        {/* {facilitatorState?.busy && (
          <div className="commodity-wrapper">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )} */}
        <div className="ant-table-body ant-table">
          <Table
            ref={tableRef}
            dataSource={!facilitatorState?.busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
            columns={facilitatorState?.busy ? FACILITATOR_TABLE_HEADER.map((column) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      active="true"
                      key={column.dataIndex}
                      title={true}
                      paragraph={false}
                    />
                  );
                },
              };
            }) : FACILITATOR_TABLE_HEADER}
            pagination={false}
            onChange={handlePageChange}
            scroll={{ y: 550 }}
            className="ant-table"
            size="small"
          />
          <AntPagination
            total={parseInt(totalRecords)}
            handlePageChange={handlePageChange}
            page={page}
            clearFilters={clearFilters}
            rowsPerPage={rowsPerPage}
            reportData={Object.values(facilitatorState?.facilitatorCsvExtract)}
            getExportReport={getExportReport}
            csvLink={csvLink}
            type={'Facilitation Logs'}
            setScrollToFirstRow={setScrollToFirstRow}
          />
        </div>
      </div>
    </div>
  );
};
export default Facilitator;
