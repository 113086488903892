import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  path: '',
  props: '',
  updatedAt: null,
};

const slice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    update(state, action) {
      state = {
        ...state,
        ...(action.payload || {}),
        updatedAt: Date.now(),
      };
      return state;
    },
  },
});

const actions = {
  ...slice.actions,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
