import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const BannerUploadSchema = yup.object().shape(
  {
    // tagged_company_id: yup.string().required('First Name is a required field'),
    // tagged_person_id: yup.number(),
    // redirection_url: yup.string(),

    tagged_company_id: yup.number().when(['tagged_person_id', 'redirection_url'], {
      is: (tagged_person_id, redirection_url) => !tagged_person_id && !redirection_url,
      then: yup.number().required('Please tag Company/Person or enter Redirection Url'),
    }),

    tagged_person_id: yup.number().when(['tagged_company_id', 'redirection_url'], {
      is: (tagged_company_id, redirection_url) => !tagged_company_id && !redirection_url,
      then: yup.number().required('Please tag Company/Person or enter Redirection Url'),
    }),

    redirection_url: yup.string().when(['tagged_person_id', 'tagged_company_id'], {
      is: (tagged_person_id, tagged_company_id) => !tagged_person_id && !tagged_company_id,
      then: yup.string().required('Please tag Company/Person or enter Redirection Url'),
    }),
  },
  [
    ['tagged_company_id', 'redirection_url'],
    ['tagged_person_id', 'tagged_company_id'],
    ['tagged_person_id', 'redirection_url'],
  ],
);

export const upload = async (params) => {
  try {
    await helpers.validateSchema(BannerUploadSchema, params);
    const res = await api.upload(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
