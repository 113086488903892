import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchDetails(params) {
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
      // person_id: _.get(window, 'user.userid', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    let bodyParams = {
      bf_setup_installed_capacity_id: id,
    };
    return utils.getQs(bodyParams);
  },
  createInstalledCapacity(params = {}) {
    let bodyParams = {
      ...params,
      quantity: parseInt(params['quantity']),
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'company_id') || _.get(resData, 'company_affiliate_id') || _.get(resData, 'person_id') || 0,
    };
    return res;
  },
  create(resData) {
    return resData;
  },
  createInstalledCapacity(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
  fetchList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchInstalledCapacityDetails(details);
    });
    return _.keyBy(data, 'bf_setup_installed_capacity_id');
  },
  fetchInstalledCapacityDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'bf_setup_installed_capacity_id'),
    };
    return res;
  },
};
