import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const addSingleFarmer = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/AddSingleFarmer`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'AddSingleFarmer');
    throw errors;
  }
};

export const linkExistingFarmer = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/LinkExistingFarmer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'linkExistingFarmer');
    throw errors;
  }
};

export const fetchGroupsAgainstInvokingPersonId = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/FetchGroupsAgainstInvokingPersonId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchGroupsAgainstInvokingPersonId');
    throw errors;
  }
};

export const fetchMembersAgainstBfSocialGroupId = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/FetchMembersAgainstBfSocialGroupId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchMembersAgainstBfSocialGroupId');
    throw errors;
  }
};

export const fetchAllFarmers = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/FetchAllFarmers?${qs}`;
    const res = await utils.fetch(url).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllFarmers');
    throw errors;
  }
};

export const importMembers = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelReportGeneration/api/ReportGeneration/ImportMembers`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: params,
      },
      false,
    ).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'ImportMembers');
    throw errors;
  }
};

export const fetchSocialGroupDetailsAgainstBfSocialGroupId = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/FetchSocialGroupDetailsAgainstBfSocialGroupId?${qs}`;
    const res = await utils.fetch(url).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchSocialGroupDetailsAgainstBfSocialGroupId');
    throw errors;
  }
};

export const removeParticipantFromGroup = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/RemoveParticipantFromGroup?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'RemoveParticipantFromGroup');
    throw errors;
  }
};

export const updateFPOMembershipId = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/UpdateFPOMembershipId?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdateFPOMembershipId');
    throw errors;
  }
};

export const verifyMobileNumber = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialGroup/VerifyMobileNumber?${qs}`;
    const res = await utils.fetch(url).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'VerifyMobileNumber');
    throw errors;
  }
};
