import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessProfileDetails_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.fetchList(resData);
  } catch (err) {
    console.error('api-company-fetchList->raw--err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessProfileDetails_ELOC');
    console.error('api-company-fetchList->errors---->', errors);
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.error('api-company-fetchDetails->raw--err---->', err);
    const errors = await utils.processApiErrors(err);
    console.error('api-company-fetchDetails->errors---->', errors);
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdatePrimaryBusinessOrganisationDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.error('api-company-create->raw--err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdatePrimaryBusinessOrganisationDetails');
    console.error('api-company-create->errors---->', errors);
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdatePrimaryBusinessOrganisationDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.error('api-company-update->raw--err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdatePrimaryBusinessOrganisationDetails');
    console.error('api-company-update->errors---->', errors);
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    let bodyParams = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/FarmDetails/SaveAndUpdateFarm`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.error('api-company-destroy->raw--err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateFarm');
    console.error('api-company-destroy->errors---->', errors);
    throw errors;
  }
};
