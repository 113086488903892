import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';

export const reqTransform = {
  fetchLocations(id = 1) {
    const reqParams = {
      location_id: id,
    };
    return utils.getQs(reqParams);
  },
};

export const resTransform = {
  fetchLocations(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.location_id || 0,

        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
};
