import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/styles/makeStyles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colord as colorFormat } from 'colord';

import getColor from '../colors';

type stylePropTypes = {
  color: string;
  size: 'small' | 'medium';
};

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiIconButton-root:hover': {
      backgroundColor: ({ color }: stylePropTypes) => `${colorFormat(color).alpha(0.04).toHex()} !important`,
    },
  },
  disabled: {
    color: '#bdbdbd !important',
  },
  label: {
    fontWeight: 'bold',
    letterSpacing: '1px',
  },
  switchRoot: {
    height: ({ size }: stylePropTypes) => (size === 'medium' ? '45px' : '27px'),
    width: ({ size }: stylePropTypes) => (size === 'medium' ? '64px' : '46px'),
    '& .Mui-checked + .MuiSwitch-track': {
      // backgroundColor: ({ color }: stylePropTypes) => `${color} !important`,
      backgroundColor: ({ color }: stylePropTypes) => `rgb(63 181 130 / 59%) !important`,
      opacity: 0.7,
    },
    '& .MuiSwitch-switchBase': {
      top: ({ size }: stylePropTypes) => (size === 'medium' ? '10px' : '6px'),
      left: ({ size }: stylePropTypes) => (size === 'medium' ? '11px' : '7px'),
      padding: ({ size }: stylePropTypes) => (size === 'medium' ? '6px' : '4px'),
      '&.MuiSwitch-colorSecondary': {
        color: ({ color }: stylePropTypes) => `${color}`,
      },
    },
  },
  track: {
    height: ({ size }: stylePropTypes) => (size === 'medium' ? '24px' : '17px'),
    width: ({ size }: stylePropTypes) => (size === 'medium' ? '65px' : '29px'),
    borderRadius: '12px',
    backgroundColor: ({ color }: stylePropTypes) => `${color}`,
    opacity: 0.25,
  },
  thumb: {
    height: ({ size }: stylePropTypes) => (size === 'medium' ? '16px !important' : '11px !important'),
    width: ({ size }: stylePropTypes) => (size === 'medium' ? '16px !important' : '11px !important'),
    boxShadow: 'none',
    backgroundColor: ({ color }: stylePropTypes) => `${color}`,
  },
  switchChecked: {
    '& .MuiSwitch-thumb': {
      backgroundColor: ({ color }: stylePropTypes) => `rgb(63 181 130) !important`,
    },
    '&.MuiSwitch-colorSecondary': {
      color: ({ color }: stylePropTypes) => `rgb(63 181 130) !important`,
    },
    transform: ({ size }: stylePropTypes) =>
      size === 'medium' ? 'translateX(14px) !important' : 'translateX(10px) !important',
  },
  switchDisabled: {
    color: '#bdbdbd !important',
    '& .MuiSwitch-thumb': {
      backgroundColor: '#bdbdbd !important',
    },
    '& .MuiSwitch-track': {
      backgroundColor: `#bdbdbdad !important`,
      opacity: 0.7,
    },
  },
}));

type MUICheckboxPropTypes = {
  control: 'checkbox' | 'switch' | 'radio';
  type?: 'single' | 'group';
  label?: string;
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
  size?: 'small' | 'medium';
  color?: string;
  Icon?: React.ElementType;
  CheckedIcon?: React.ElementType;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean | string, name: string) => void;
  options?: { label: string; checked: boolean; disabled: boolean }[];
  orientation?: 'row' | 'column';
  field?: any;
  form?: any;
  value: boolean | string | [{}];
};

const MUIToggleControl = ({
  control = 'checkbox',
  type = 'single',
  label,
  labelPlacement = 'end',
  size = 'small',
  color = 'old',
  Icon = CheckBoxOutlineBlankIcon,
  CheckedIcon = CheckBoxIcon,
  disabled,
  onChange,
  options,
  orientation = 'row',
  field,
  form,
  value,
  ...rest
}: MUICheckboxPropTypes) => {
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(config?.theme_color || color);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const classes = useStyles({ color: bgColor, size });

  const [fieldValue, setFieldValue] = React.useState(value);
  const [controlState, setControlState] = React.useState(typeof value === 'object' ? value : {});

  const handelChange = (e, value, name) => {
    onChange(e, value, name);
    form && form?.setFieldValue(field?.name, value);
    setFieldValue(value);
    if (type === 'group') {
      setControlState({ ...controlState, [name]: value });
    }
  };

  React.useEffect(() => {
    if (type === 'group') {
      if (!_.isEmpty(controlState)) {
        const obj = {};
        for (const key of controlState as [{}]) {
          if (!_.isEmpty(key) && key?.['label']) {
            obj[key['label']] = true;
          }
        }
        setControlState(obj);
      }
    } else {
      setFieldValue(value);
    }
  }, [value]);

  const StyledControl = ({ optionLabel = '', optionDisabled = false, optionChecked = false }) => {
    return (
      <FormControlLabel
        {...rest}
        label={label || optionLabel}
        labelPlacement={labelPlacement}
        classes={{ label: classes.label }}
        control={
          control === 'checkbox' ? (
            <Checkbox
              {...field}
              size={size}
              disabled={disabled || optionDisabled}
              classes={{ root: classes.root, disabled: classes.disabled }}
              style={{ color: bgColor }}
              icon={<Icon />}
              checkedIcon={<CheckedIcon />}
              name={label || optionLabel}
              checked={type === 'single' ? fieldValue : controlState?.[optionLabel] || optionChecked}
              onChange={(e, checked) => handelChange(e, checked, e?.currentTarget?.name)}
            />
          ) : control === 'switch' ? (
            <Switch
              {...field}
              size={size}
              disabled={disabled || optionDisabled}
              classes={{
                root: classes.switchRoot,
                disabled: classes.switchDisabled,
                track: classes.track,
                thumb: classes.thumb,
                checked: classes.switchChecked,
              }}
              // style={{ color: bgColor }}
              name={label || optionLabel}
              checked={type === 'single' ? fieldValue : controlState?.[optionLabel] || optionChecked}
              onChange={(e, checked) => handelChange(e, checked, e?.currentTarget?.name)}
            />
          ) : (
            <Radio
              size={size}
              disabled={disabled || optionDisabled}
              classes={{ root: classes.root, disabled: classes.disabled }}
              style={{ color: bgColor }}
              name={label || optionLabel}
              value={label || optionLabel}
              onChange={(e, checked) => handelChange(e, checked, e?.currentTarget?.name)}
            />
          )
        }
      />
    );
  };

  return type === 'single' ? (
    <StyledControl />
  ) : control === 'radio' ? (
    <RadioGroup
      {...field}
      row={orientation === 'row' ? true : false}
      value={fieldValue}
      onChange={(e, value) => handelChange(e, value, e?.currentTarget?.name)}
    >
      {options?.map((option) => (
        <StyledControl
          key={option?.label}
          optionLabel={option?.label}
          optionChecked={option?.checked}
          optionDisabled={option?.disabled}
        />
      ))}
    </RadioGroup>
  ) : (
    <FormGroup row={orientation === 'row' ? true : false}>
      {options?.map((option) => (
        <StyledControl
          key={option?.label}
          optionLabel={option?.label}
          optionChecked={option?.checked}
          optionDisabled={option?.disabled}
        />
      ))}
    </FormGroup>
  );
};
export default MUIToggleControl;
