import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

import * as fetch from 'services/fetch';

const { baseUrl } = config;
const BASE_URL = '/BioFuelAuctions/api';

export const connectProfile = async (params) => {
  try {
    const qs = reqTransform.connectProfile(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/ConnectDisconnect?${qs}`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.connectProfile(resData);
  } catch (err) {
    console.log('api-connectProfile-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ConnectDisconnect');
    throw errors;
  }
};

export const withdrawConnect = async (params) => {
  try {
    const qs = reqTransform.withdrawConnect(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/RespondConnectRequest?${qs}`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.withdrawConnect(resData);
  } catch (err) {
    console.log('api-withdrawConnect-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RespondConnectRequest');
    throw errors;
  }
};

export const fetchCompanyBusinessProfile = async (params = {}) => {
  try {
    const qs = reqTransform.fetchCompanyBusinessProfile(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/FetchCompanyBusinessProfile?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCompanyBusinessProfile(resData);
  } catch (err) {
    console.log('api-posts-fetchReportedPosts->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCompanyBusinessProfile');
    throw errors;
  }
};

export const fetchNotConnectedBusinessProfile = async (params = {}) => {
  try {
    const qs = reqTransform.fetchNotConnectedBusinessProfile(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/FetchNotConnectedCompanyBusinessProfile?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchNotConnectedBusinessProfile(resData);
  } catch (err) {
    console.log('api-posts-fetchReportedPosts->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchNotConnectedBusinessProfile');
    throw errors;
  }
};

export const fetchConnectionRequests = async (params = {}) => {
  try {
    const qs = reqTransform.fetchConnectionRequests(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/FetchConnectionRequests?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchConnectionRequests(resData);
  } catch (err) {
    console.log('api-posts-fetchReportedPosts->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchConnectionRequests');
    throw errors;
  }
};

export const respondConnectRequest = async (params) => {
  try {
    const qs = reqTransform.respondConnectRequest(params);
    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialConnect/RespondConnectRequest?${qs}`;

    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.respondConnectRequest(resData);
  } catch (err) {
    console.log('api-respondConnectRequest-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RespondConnectRequest');
    throw errors;
  }
};
