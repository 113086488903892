import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(globalThis, 'user.userid', 0),
    // };

    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] != _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
  fetchDetails(params) {
    let qsParams = {};
    const storeState = globalThis.store.getState();
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum'),
        };
      } else {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
      };
    }
    delete qsParams['person_id'];
    return utils.getQs(qsParams);
  },
  create(params = {}) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let company_id = 0,
      affiliate_id = 0;
    if (companyAffiliateEnum === 1) {
      company_id = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      affiliate_id = params['entity_id'] || 0;
    }
    let bodyParams = {
      ...params,
      company_id: company_id, //_.get(window, 'user.profile.person.company_id', 0),
      affiliate_id: affiliate_id, //_.get(window, 'user.profile.person.affiliate_id', 0),
      city_id: parseInt(params['city_id']) || 0,
      // city_code: parseInt(params['city_code']) || '',
      state_id: parseInt(params['state_id']),
      operation_enum: 1,
    };

    //Remove Tax Identity Parameters
    delete bodyParams['company_gst_detail_id'];
    delete bodyParams['have_gst_flag'];
    delete bodyParams['gstin'];

    //Remove Bank Parameters
    delete bodyParams['bank_account_id'];
    delete bodyParams['account_name'];
    delete bodyParams['account_number'];
    delete bodyParams['branch'];
    delete bodyParams['ifsc_code'];
    delete bodyParams['bank_address'];

    delete bodyParams['eKyc'];
    delete bodyParams['display_member'];

    return bodyParams;
  },
  update(id, params) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let company_id = 0,
      affiliate_id = 0;
    if (companyAffiliateEnum === 1) {
      company_id = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      affiliate_id = params['entity_id'] || 0;
    }

    let bodyParams = {
      ...params,
      company_tax_detail_id: id,
      ...params,
      company_id: company_id, //_.get(window, 'user.profile.person.company_id', 0),
      affiliate_id: affiliate_id, //_.get(window, 'user.profile.person.affiliate_id', 0),
      city_id: parseInt(params['city_id']) || 0,
      // city_code: parseInt(params['city_code']) || '',
      state_id: parseInt(params['state_id']),
      operation_enum: 3,
    };

    //Remove Tax Identity Parameters
    delete bodyParams['company_gst_detail_id'];
    delete bodyParams['have_gst_flag'];
    delete bodyParams['gstin'];

    //Remove Bank Parameters
    delete bodyParams['bank_account_id'];
    delete bodyParams['account_name'];
    delete bodyParams['account_number'];
    delete bodyParams['branch'];
    delete bodyParams['ifsc_code'];
    delete bodyParams['bank_address'];

    return bodyParams;
  },

  //Tax identity

  fetchTaxDetails(params) {
    let qsParams = {};
    const storeState = globalThis.store.getState();
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum'),
        };
      } else {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
      };
    }
    delete qsParams['person_id'];
    return utils.getQs(qsParams);
  },

  createTax(params = {}) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let company_id = 0,
      affiliate_id = 0;
    if (companyAffiliateEnum === 1) {
      company_id = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      affiliate_id = params['entity_id'] || 0;
    }
    let bodyParams = {
      company_id: company_id,
      affiliate_id: affiliate_id,
      company_gst_detail_id: params['company_gst_detail_id'],
      have_gst_flag: params['have_gst_flag'],
      gstin: params['gstin'],
      company_affiliate_enum: params['company_affiliate_enum'],
      operation_Enum: 1,
    };
    return bodyParams;
  },

  updateTax(id, params) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let company_id = 0,
      affiliate_id = 0;
    if (companyAffiliateEnum === 1) {
      company_id = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      affiliate_id = params['entity_id'] || 0;
    }
    let bodyParams = {
      company_id: company_id,
      affiliate_id: affiliate_id,
      company_gst_detail_id: params['company_gst_detail_id'],
      have_gst_flag: params['have_gst_flag'],
      gstin: params['gstin'],
      company_affiliate_enum: params['company_affiliate_enum'],
      operation_Enum: 3,
    };
    return bodyParams;
  },

  //Bank
  fetchBankDetails(params) {
    const storeState = globalThis.store.getState();
    let qsParams = {};
    if (!_.isEmpty(params)) {
      if (params['person_id'] != _.get(storeState, 'user.userid', 0)) {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'kycUser.profile.person.user_type_enum'),
        };
      } else {
        qsParams = {
          ...params,
          user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        user_type_enum: _.get(storeState, 'user.profile.person.user_type_enum', 0),
      };
    }
    delete qsParams['person_id'];
    return utils.getQs(qsParams);
  },

  createBank(params = {}) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let entityId = 0;
    if (companyAffiliateEnum === 1) {
      entityId = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      entityId = params['entity_id'] || 0;
    }
    let bodyParams = {
      entity_id: entityId,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0), //2
      company_affiliate_enum: params['company_affiliate_enum'],
      bank_account_details_request_model: params['bank_account_details_request_model'],
    };
    return bodyParams;
  },

  updateBank(id, params) {
    let companyAffiliateEnum = params['company_affiliate_enum'];
    let entityId = 0;
    if (companyAffiliateEnum === 1) {
      entityId = params['entity_id'] || _.get(window, 'user.profile.person.company_id', 0);
    } else if (companyAffiliateEnum === 2) {
      entityId = params['entity_id'] || 0;
    }
    let bodyParams = {
      entity_id: entityId,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0), //2
      company_affiliate_enum: params['company_affiliate_enum'],
      bank_account_details_request_model: params['bank_account_details_request_model'],
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  eKycDocumentVerification(params) {
    let bodyParams = {};
    if (params['eKyc'] == 'panOfAuthorizedSignatory') {
      bodyParams = {
        document_number: params['pan_of_authorized_signatory'],
        document_name: params['name_of_authorized_signatory'],
        entity_id: params['entity_id'],
        verification_type_enum: 1,
        expiry_date: null,
        company_affiliate_enum: params['company_affiliate_enum'],
      };
    }
    if (params['eKyc'] == 'businessPanId') {
      bodyParams = {
        document_number: params['business_pan_id'],
        document_name: params['name_as_per_pan'],
        entity_id: params['entity_id'],
        verification_type_enum: 2,
        expiry_date: null,
        company_affiliate_enum: params['company_affiliate_enum'],
      };
    }
    if (params['eKyc'] == 'DLOfAuthorizedSignatory') {
      bodyParams = {
        document_number: params['dl_of_authorized_signatory'],
        document_name: params['name_of_authorized_signatory'],
        entity_id: params['entity_id'],
        verification_type_enum: 3,
        expiry_date: null,
        company_affiliate_enum: params['company_affiliate_enum'],
        dob: params['dob'],
      };
    }
    if (params['eKyc'] == 'gst') {
      bodyParams = {
        document_number: params['gstin'],
        document_name: params['document_name'],
        entity_id: params['entity_id'],
        verification_type_enum: 6,
        expiry_date: null,
        company_affiliate_enum: params['company_affiliate_enum'],
      };
    }
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let affiliateData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(affiliateData, 'company_affiliate_id');
  },
  fetchDetails(resData) {
    return {
      company_tax_detail_id: _.get(resData, 'company_tax_detail_id') || 0,
      dl_of_authorized_signatory: _.get(resData, 'dl_of_authorized_signatory') || 0,
      company_id_of_authorized_signatory: _.get(resData, 'company_id_of_authorized_signatory') || 0,
      company_id: _.get(resData, 'company_id') || 0,
      affiliate_id: _.get(resData, 'affiliate_id') || 0,
      company_affiliate_enum: _.get(resData, 'company_affiliate_enum') || 0, //@TODO Hardcode
      business_pan_id: _.get(resData, 'business_pan_id') || '',
      name_as_per_pan: _.get(resData, 'name_as_per_pan') || '',
      cin: _.get(resData, 'cin') || '',
      llpin: _.get(resData, 'llpin') || '',
      pan_of_authorized_signatory: _.get(resData, 'pan_of_authorized_signatory') || '',
      u_aadhar: _.get(resData, 'u_aadhar') || '',
      name_of_authorized_signatory: _.get(resData, 'name_of_authorized_signatory') || '',
      same_as_registered_address_flag: _.get(resData, 'same_as_registered_address_flag') || false,
      address_line_1: _.get(resData, 'address_line_1') || '',
      address_line_2: _.get(resData, 'address_line_2') || '',
      address_line_3: _.get(resData, 'address_line_3') || '',
      dob: _.get(resData, 'dob') || '',
      village_code: _.get(resData, 'village_code') || 0,
      pin_code: _.get(resData, 'pin_code') || '',
      state_id: _.get(resData, 'state_id') || 0,
      taluka_id: _.get(resData, 'taluka_id') || 0,
      country_id: _.get(resData, 'country_id') || 0,
      city_id: _.get(resData, 'city_id') || 0,
      district_id: _.get(resData, 'district_id') || 0,
      location_id: _.get(resData, 'location_id') || 0,

      state_code: _.get(resData, 'state_code') || '',
      city_code: _.get(resData, 'city_code') || '',
      taluka_code: _.get(resData, 'taluka_code') || '',
      country_code: _.get(resData, 'country_code') || '',
      // city_id: _.get(resData, 'city_id') || 0,
      district_code: _.get(resData, 'district_code') || '',
      location_code: _.get(resData, 'location_code') || '',

      business_type_enum: _.get(resData, 'business_type_enum') || 0,

      company_address_id: _.get(resData, 'company_address_id') || 0,
      location_tuple: _.get(resData, 'location_tuple') || '',
      place_id: _.get(resData, 'place_id') || '',

      nabl_bis_accrediation_no: _.get(resData, 'nabl_bis_accrediation_no') || '',
      accrediation_valid_upto_date: _.get(resData, 'accrediation_valid_upto_date') || '',

      msme_classification_enum: _.get(resData, 'msme_classification_enum'),
      msme_classification_enum_code: _.get(resData, 'msme_classification_enum_code'),
      nrlm_registration_number: _.get(resData, 'nrlm_registration_number'),
      // identity_type_enum: _.get(resData, 'identity_type_enum') || 1, //@TODO Hardcode
      // identity_type_enum_code: _.get(resData, 'identity_type_enum_code') || 'Aadhar', //@TODO Hardcode
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },

  //company and affiliates list
  fetchCompanyList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCompanyDetails(details);
    });
    return _.keyBy(data, 'value_member');
  },

  fetchCompanyAndAffiliatesWithSocialProfile(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCompanyDetails(details);
    });
    return data;
  },

  fetchCompanyDetails(resData) {
    return resData;
  },

  //Tax
  fetchTaxDetails(resData) {
    let response = {
      ...resData,
      company_gst_detail_id: _.get(resData, 'company_gst_detail_id'),
    };
    return response;
  },
  createTax(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  updateTax(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },

  //Bank
  fetchBankList(resData) {
    let bankData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchBankDetails(details);
    });
    return _.keyBy(bankData, 'company_bank_address_id');
  },
  fetchBankDetails(resData) {
    let response = {
      ...resData,
      person_bank_account_id: _.get(resData, 'company_bank_address_id'),
      bank_account_id: _.get(resData, 'company_bank_address_id'),
    };
    return response;
  },

  createBank(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  updateBank(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  eKycVerification(resData) {
    return resData;
  },
};
