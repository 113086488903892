import utils from './../utils';

const validateValues = (values) => {
  const errors = {};

  const isMobile = !utils.isNaN(values.user_name);
  if (!values.user_name) {
    errors['user_name'] = 'Email is required';
  } else if (isMobile && values.user_name.length !== 10) {
    // errors['user_name'] = 'Please enter 10 digit mobile number';
  } else if (isMobile === false && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.user_name)) {
    errors['user_name'] = 'Invalid email address';
  }

  if (!values.agree) {
    errors['agree'] = 'Please accept terms and condition to proceed.';
  }

  return errors;
};

export default validateValues;
