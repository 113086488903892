import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from './components/FormikMaterialTextField';
// import FormikDateTimePickerField from '../../shared/components/form/FormikDateTimePickerField';
import Collapse from './components/Collapse';
import FormikLocationSelect from 'common/Form/FormikLocationSelectEloc';
import ReferenceDataSelect from './components/ReferenceDataSelect';
import VerficationFormModal from './components/VerficationFormModal';
import FormikCheckBox from 'containers/Account/Profile/components/FormikCheckBox';
// import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelect';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as userActions } from 'redux/user';
import { actions as kycUserActions } from 'redux/kycUser';
import { actions as locationElocActions } from 'redux/locationElocDetails';
import LogoLoader from 'common/LogoLoader';
import MUITextField from '@material-ui/core/TextField';
import FormikAOISelect from './components/FormikAOISelect';
import InterestFormikAOISelect from './components/InterestFormikAOISelect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import { locationElocService } from 'services';
import useQuery from 'lib/hooks/useQuery';
import searchLocation from 'common/Form/searchLocation';
import locations from 'redux/locations';
import { PROFILE_TABS_ENUM, TYPE_OF_INDIVIDUAL_ENUM } from 'constants/enums';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const getInitialValues = (user, locDetailsValue) => {
  const person = _.get(user, 'profile.person') || {};
  const address = _.get(user, 'profile.person_adress') || {};
  const hashTags = _.get(user, 'profile.person_hashtags') || [];
  const buyInterestCommodityMappingIds = _.get(user, 'profile.buy_interest_commodity_mapping_ids') || [];
  const sellInterestCommodityMappingIds = _.get(user, 'profile.sell_interest_commodity_mapping_ids') || [];
  const mobileNumberCountryCode = _.get(user, 'profile.person.mobile_number_country_code') || 0;
  const whatsappNumberCountryCode = _.get(user, 'profile.person.whatsapp_number_country_code') || 0;

  return {
    person_address_id: _.get(address, 'person_address_id') || 0,
    first_name: _.get(person, 'first_name') || '',
    last_name: _.get(person, 'last_name') || '',
    email_id: _.get(person, 'email_id') || '',
    mobile_number: _.get(person, 'mobile_number') || '',
    whatsapp_number: _.get(person, 'whatsapp_number') || '',
    type_of_individual_enum: _.get(person, 'type_of_individual_enum') || 0,
    is_email_verified: _.get(person, 'is_email_verified') || false,
    is_phone_verified: _.get(person, 'is_phone_verified') || false,
    gender_enum: _.get(person, 'gender_enum') || '',

    location_id: _.get(address, 'location_id') || 0,
    location_code: _.get(address, 'location_code') || '',
    village_id: _.get(address, 'village_id') || 0,
    village_code: _.get(address, 'village_code') || '',
    taluka_id: _.get(address, 'taluka_id') || 0,
    taluka_code: _.get(address, 'taluka_code') || '',
    city_id: _.get(address, 'city_id') || 0,
    city_code: _.get(address, 'city_code') || '',
    district_id: _.get(address, 'district_id') || 0,
    district_code: _.get(address, 'district_code') || '',
    state_id: _.get(address, 'state_id') || 0,
    state_code: _.get(address, 'state_code') || '',
    // country_id: _.get(address, 'country_id') || 1,
    country_id: 0,
    country_code: _.get(address, 'country_code') || 'India',
    mobile_number_country_code:  _.get(address, 'mobile_number_country_code') || mobileNumberCountryCode,
    whatsapp_number_country_code:  _.get(address, 'whatsapp_number_country_code') || whatsappNumberCountryCode,
    address_lattitude: _.get(address, 'address_lattitude') || '',
    address_longitude: _.get(address, 'address_longitude') || '',
    address_line1: _.get(address, 'address_line1') || '',
    address_line2: _.get(address, 'address_line2') || '',
    address_line3: _.get(address, 'address_line3') || '',
    pin_code: _.get(address, 'pin_code') || '',
    hash_tag_id: hashTags.map((item) => item.hash_tag_id),
    buy_interest_commodity_mapping_ids: buyInterestCommodityMappingIds.map((item) => item),
    sell_interest_commodity_mapping_ids: sellInterestCommodityMappingIds.map((item) => item),

    person_id: '',

    is_proprietor: _.get(person, 'is_proprietor') || false,
    locality: _.get(address, 'locality') || '',
    place_id: _.get(address, 'place_id') || '',
    canEdit : _.get(user, 'profile.can_edit')
  };
};

const Details = (props) => {
  //optimize this
  const id = props.id;
  const dispatch = useDispatch();
  const { back, isinterst } = useQuery();
  const scrollingDivRef = useRef(null);
  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  let acl = utils.getACL(user);
  let busy = _.get(user, 'busy') || false;
  if (user.id === parseInt(id)) {
    busy = _.get(user, 'busy') || false;
  } else if (user.id !== parseInt(id)) {
    busy = _.get(kycUser, 'busy') || false;
  }

  let userIntialValues = user;
  if (user.id === parseInt(id)) {
    userIntialValues = user;
  } else if (user.id !== parseInt(id)) {
    userIntialValues = kycUser;
  }

  const mobile_number =  userIntialValues?.profile?.person?.mobile_number || ""
  const whatsapp_number =  userIntialValues?.profile?.person?.whatsapp_number || ""

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  let rolesAndRights = useSelector((state) => state.rolesAndRights);
  let rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  let kycRolesAndRights = useSelector((state) => state.kycRolesAndRights);
  let kycRolesAndRightsList = _.get(kycRolesAndRights, 'entities') || {};
  const interestTypeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  // const isCompanyAdmin = _.get(user, 'profile.person.is_company_admin') || false;
  const isCompanyAdmin = false;

  let userHasRole = user?.profile?.person?.type_of_individual_enum !== 0 ? true : false;

  if (user.userid !== parseInt(id)) {
    acl = utils.getACL(kycUser);
    userHasRole = kycUser?.profile?.person?.type_of_individual_enum !== 0 ? true : false;
    rolesAndRightsList = {};
    rolesAndRightsList = kycRolesAndRightsList;
  }

  let showOrganisationOrFarm = false;

  let nextLink = ``;

  if (isAdmin) {
    const isCompany = _.get(kycUser, 'profile.person.user_type_enum');
    const isTransporter = _.get(kycUser, 'profile.person.is_transporter');
    if (isCompany == 1 || isTransporter) {
      nextLink = `/account/${id}/organisations?back=${back}`;
      const showOrganisation = Object.values(rolesAndRightsList).find(
        (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.ORGANISATION),
      );
      showOrganisationOrFarm = showOrganisation?.full_access || showOrganisation?.read_access;
    } else {
      nextLink = `/account/${id}/farms?back=${back}`;
      const showFrams = Object.values(rolesAndRightsList).find(
        (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.FARMS_CROPS),
      );
      showOrganisationOrFarm = showFrams?.full_access || showFrams?.read_access;
    }
  }
  if (!isAdmin) {
    if (acl.isCompany || acl.isTransporter) {
      nextLink = `/account/${id}/organisations`;
      const showOrganisation = Object.values(rolesAndRightsList).find(
        (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.ORGANISATION),
      );
      showOrganisationOrFarm = showOrganisation?.full_access || showOrganisation?.read_access;
    } else {
      nextLink = `/account/${id}/farms?back=${back}`;
      const showFrams = Object.values(rolesAndRightsList).find(
        (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.FARMS_CROPS),
      );
      showOrganisationOrFarm = showFrams?.full_access || showFrams?.read_access;
    }
  }

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState();
  const [countryPhone, setCountryPhone] = useState('');
  const [whatsAppCountryPhone, setWhatsAppCountryPhone] = useState('');

  const [latLongData, setLatLongData] = useState({});
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');

  // Function to scroll to the center of the div
  const scrollToCenter = () => {
    if (scrollingDivRef.current) {
      scrollingDivRef.current.scrollTop = 395;
    }
  };

  // Call the autoScroll function after the component mounts
  useEffect(() => {
    if (isinterst) {
      scrollToCenter();
    }
  }, [isinterst]); // Empty dependency array ensures the effect runs only once after mount


  useEffect(() => {
    dispatch(userActions.fetchPersonalDetails(user.id));
  }, [user.id]);

  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [modalCtx, setModalCtx] = useState(false);
  const [sameNumber, setSameNumber] = useState(false);

  const history = useHistory();

  const person = _.get(user, 'profile.person') || {};
  const address = _.get(user, 'profile.person_adress') || {};
  const hashTags = _.get(user, 'profile.person_hashtags') || [];

  const isCtUser = _.get(user, 'profile.person.is_control_tower_user', false)
  const mobileNumber = _.get(user, 'profile.person.mobile_number', '')
  const whatsappNumber = _.get(user, 'profile.person.whatsapp_number', '')
  const mobileNumberCountryCode = _.get(user, 'profile.person.mobile_number_country_code', '')
  const whatsappNumberCountryCode = _.get(user, 'profile.person.whatsapp_number_country_code', '')

  if (busy) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="bfc-body bfc-form profile-height-body"
      id="scrollingDiv" ref={scrollingDivRef} style={{ height: '10px', overflowY: 'scroll', }}>

      <Formik
        initialValues={getInitialValues(userIntialValues, locDetailsValue)}
        onSubmit={handlers.handlePersonalDetailsUpdate}>
        {(formikBag) => {
          const { values, initialValues } = formikBag;
          // console.log('\n------------profileDetails-------values------', formikBag, values);
          values.person_id = user.id;
          values.latitude = _.get(latLongData, 'latitude') || _.get(address, 'address_lattitude') || '';
          values.longitude = _.get(latLongData, 'longitude') || _.get(address, 'address_longitude') || '';
          // if (values.location_code === ', ') {
          //   values.location_code = '';
          // }
          return (
            <Form className="form-container">

              <Card>
                <CardBody className="main-cardbody">
                  <Collapse title="Basic Information" className="with-shadow">
                    <Card>
                      <CardBody>
                        {/* {utils.debug(user)} */}
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <span className="form-group-label">First Name</span>
                            <span class="required">*</span>
                            <div className="form-group-field">
                              <Field name="first_name" component={FormikMaterialTextField} disabled={isAdmin} />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <span className="form-group-label">Last Name</span>
                            <span class="required">*</span>
                            <div className="form-group-field">
                              <Field name="last_name" component={FormikMaterialTextField} disabled={isAdmin} />
                            </div>
                          </div>
                        </div>

                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Gender</span>
                              <span class="required">*</span>
                              <Field
                                name="gender_enum"
                                component={ReferenceDataSelect}
                                entity="genderEnum"
                                size="small"
                                disabled={isAdmin}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <span className="form-group-label">Email ID</span>
                            {!acl.isPhoneDisabled && <span class="required">*</span>}
                            <div className="form-group-field">
                              <Field
                                name="email_id"
                                component={FormikMaterialTextField}
                                type="email"
                                disabled={acl.isEmailDisabled || isAdmin}
                              />
                              {acl.canUpdateEmail && !isCtUser && (
                                <span
                                  className="action-text  sub-text"
                                  onClick={(e) => {
                                    setModalCtx('email');
                                  }}>
                                  Update your Email ID
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Contact No.</span>
                              <span class="required">*</span>
                              <PhoneInput
                                name="mobile_number"
                                country={'in'}
                                value={mobile_number ? mobileNumberCountryCode + mobile_number : countryPhone}
                                disabled={acl.isPhoneDisabled || isAdmin}
                                preferredCountries={['in']}
                                onChange={(event, name) => {
                                  setCountryPhone(event);
                                  const originalString = event;
                                  const dialCodeLength = name.dialCode;
                                  const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                                  formikBag.setFieldValue('mobile_number_country_code', parseInt(name.dialCode) || 0);
                                  formikBag.setFieldValue('mobile_number', stringWithoutFirstThree || '');
                                  }
                                }
                                inputProps={{
                                  name: 'mobile_number',
                                  required: true,
                                  autoFocus: false,
                                }}
                              />
                               {acl.canUpdatePhone && !isCtUser && (
                                <span
                                  className="action-text  sub-text"
                                  onClick={(e) => {
                                    setModalCtx('phone');
                                  }}>
                                  Update your Phone Number
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">WhatsApp No.</span>
                              <PhoneInput
                                name="whatsapp_number"
                                country={'in'}
                                value={sameNumber ? values?.whatsapp_number_country_code + values?.whatsapp_number : whatsapp_number ? whatsappNumberCountryCode + whatsapp_number : whatsAppCountryPhone}
                                preferredCountries={['in']}
                                disabled={isAdmin}
                                onChange={(event, name) => {
                                  setWhatsAppCountryPhone(event);
                                  const originalString = event;
                                  const dialCodeLength = name.dialCode;
                                  const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                                  formikBag.setFieldValue('whatsapp_number_country_code', parseInt(name.dialCode) || 0);
                                  formikBag.setFieldValue('whatsapp_number', stringWithoutFirstThree || '');
                                  }
                                }
                                inputProps={{
                                  name: 'whatsapp_number',
                                  required: true,
                                  autoFocus: false,
                                }}
                              />
                               <span
                                className="action-text"
                                onClick={(e) => {
                                  const phoneNo = _.get(formikBag, 'values.mobile_number') || '';
                                  const mobileNumberCountryCode = _.get(formikBag, 'values.mobile_number_country_code') || '';
                                  formikBag.setFieldValue('whatsapp_number', phoneNo);
                                  formikBag.setFieldValue('whatsapp_number_country_code', mobileNumberCountryCode);
                                  setSameNumber(true);
                                }}>
                                Same as Mobile Number?
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Contact No.</span>
                              <span class="required">*</span>
                              <Field
                                name="mobile_number"
                                component={FormikMaterialTextField}
                                disabled={acl.isPhoneDisabled || isAdmin}
                              />
                              {acl.canUpdatePhone && !isCtUser && (
                                <span
                                  className="action-text  sub-text"
                                  onClick={(e) => {
                                    setModalCtx('phone');
                                  }}>
                                  Update your Phone Number
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">WhatsApp No.</span>
                              <Field name="whatsapp_number" component={FormikMaterialTextField} disabled={isAdmin} />
                              <span
                                className="action-text"
                                onClick={(e) => {
                                  const phoneNo = _.get(formikBag, 'values.mobile_number') || '';
                                  formikBag.setFieldValue('whatsapp_number', phoneNo);
                                }}>
                                Same as Mobile Number?
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse title="About Me" className="with-shadow">
                    <Card>
                      <CardBody>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">I am a...</span>
                              <span class="required">*</span>
                              <Field
                                name="type_of_individual_enum"
                                component={ReferenceDataSelect}
                                entity="typeOfIndividualEnum"
                                size="small"
                                disabled={_.get(formikBag, 'initialValues.type_of_individual_enum') || false || isAdmin}
                              />
                            </div>
                            {values.type_of_individual_enum == 0 && (
                              <div className="notes-block">
                                <p className="redbg" style={{ marginTop: '5px' }}>
                                  Careful! You won't be able to change the role once you save.
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Area Of Interest</span>
                              <Field
                                name={`hash_tag_id`}
                                component={FormikAOISelect}
                                entity="hashTags"
                                initialValue={values.hash_tag_id}
                                disabled={isAdmin}
                                cb={(selectedValue) => {
                                  // let hashTags = _.get(formikBag, 'values.hash_tag_id') || [];
                                  // if (!Array.isArray(hashTags)) {
                                  //   hashTags = [];
                                  // }
                                  formikBag.setFieldValue(
                                    'hash_tag_id',
                                    (selectedValue || []).map((v) => v.value.id),
                                  );
                                }}
                                // initialValues={hashTags.map((item) => {
                                //   return {
                                //     display_member: item.hash_tag_code,
                                //     value_member: item.hash_tag_id,
                                //   };
                                // })}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          {(interestTypeOfIndividualEnum === 10 || interestTypeOfIndividualEnum  === 9 || interestTypeOfIndividualEnum === 4) && (
                            <div className="row form__form-group">
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="form-group-field">
                                  <span className="form-group-label">I would like to buy...</span>
                                  <Field
                                    name={`buy_interest_commodity_mapping_ids`}
                                    component={InterestFormikAOISelect}
                                    entity="InterestCommodities"
                                    initialValue={values.buy_interest_commodity_mapping_ids}
                                    disabled={isAdmin}
                                    isCompanyAdmin={isCompanyAdmin}
                                    isinterst={isinterst}
                                    cb={(selectedValue) => {
                                      formikBag.setFieldValue(
                                        'buy_interest_commodity_mapping_ids',
                                        (selectedValue || []).map((v) => v?.value.id),
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="form-group-field">
                                  <span className="form-group-label">I would like to sell...</span>
                                  <Field
                                    name={`sell_interest_commodity_mapping_ids`}
                                    component={InterestFormikAOISelect}
                                    entity="InterestCommodities"
                                    initialValue={values.sell_interest_commodity_mapping_ids}
                                    disabled={isAdmin}
                                    isCompanyAdmin={isCompanyAdmin}
                                    cb={(selectedValue) => {
                                      formikBag.setFieldValue(
                                        'sell_interest_commodity_mapping_ids',
                                        (selectedValue || []).map((v) => v?.value.id),
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {values.type_of_individual_enum == 2 && (
                          <>
                            <div className="row form__form-group">
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="form-group-field">
                                  <div className="pan-wrapper">
                                    <div className="dont-pan" style={{ width: '150px', marginTop: '30px' }}>
                                      <Field
                                        name="is_proprietor"
                                        component={FormikCheckBox}
                                        disabled={
                                          _.get(formikBag, 'initialValues.type_of_individual_enum') || false || isAdmin
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Collapse>

                  <Collapse title="Address" className="with-shadow">
                    <Card>
                      <CardBody>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Location</span>
                              <span class="required">*</span>
                              <input id="locationData" className="invisible"></input>
                              <Field
                                name="location_code"
                                component={searchLocation}
                                // initialDisplayText={values.location_code || ''}
                                disabled={isAdmin || (values?.canEdit ? false : true)}
                                cb={(locationValues) => {
                                  let locationDetails = locationValues[0] || {};
                                  //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                                  // if (locationValues) {
                                  //   locationDetails = locationValues[0] || {};
                                  //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                                  //   setLocDetailsEloc(locationValues[0]?.eLoc);
                                  // }

                                  // let formikValues = formikBag.values || {};
                                  // formikBag.setValues({
                                  //   ...formikValues,
                                  //   ...locationDetails,
                                  // });
                                  let addressLine1 =
                                    (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                      ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.houseName', '')
                                      ? _.get(locationDetails, 'addressTokens.houseName', '')
                                      : '');

                                  let addressLine2 =
                                    (_.get(locationDetails, 'addressTokens.street', '')
                                      ? _.get(locationDetails, 'addressTokens.street', '') + ' ,'
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                      ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                      : '');

                                  let addressLine3 =
                                    (_.get(locationDetails, 'addressTokens.subLocality', '')
                                      ? _.get(locationDetails, 'addressTokens.subLocality', '') + ' ,'
                                      : '') +
                                    (_.get(locationDetails, 'addressTokens.locality', '')
                                      ? _.get(locationDetails, 'addressTokens.locality', '')
                                      : '');

                                  // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                                  formikBag.setFieldValue(
                                    'pin_code',
                                    _.get(locationDetails.addressTokens, 'pincode') || '',
                                  );
                                  formikBag.setFieldValue('address_line1', addressLine1);
                                  formikBag.setFieldValue('address_line2', addressLine2);
                                  formikBag.setFieldValue('address_line3', addressLine3);
                                  formikBag.setFieldValue(
                                    'locality',
                                    _.get(locationDetails.addressTokens, 'locality') || '',
                                  );

                                  formikBag.setFieldValue(
                                    'city_code',
                                    _.get(locationDetails.addressTokens, 'city') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'village_code',
                                    _.get(locationDetails.addressTokens, 'village') ||
                                      _.get(locationDetails.addressTokens, 'city') ||
                                      _.get(locationDetails.addressTokens, 'subDistrict') ||
                                      '',
                                  );
                                  formikBag.setFieldValue(
                                    'district_code',
                                    _.get(locationDetails.addressTokens, 'district') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'sub_district_code',
                                    _.get(locationDetails.addressTokens, 'subDistrict') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'city_code',
                                    _.get(locationDetails.addressTokens, 'city') || '',
                                  );
                                  formikBag.setFieldValue(
                                    'state_code',
                                    _.get(locationDetails.addressTokens, 'state') || '',
                                  );
                                  formikBag.setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
                                  // formikBag.setFieldValue('address_lattitude', locationDetails.latitude);
                                  // formikBag.setFieldValue('address_longitude', locationDetails.longitude);
                                  // const placeName = _.get(locationDetails, 'placeName', '');
                                  // const placeAddress = _.get(locationDetails, 'placeAddress', '');
                                  // let locationsCode = '';
                                  // if (placeName || placeAddress) {
                                  //   locationsCode = (placeName ? placeName + ', ' : '') + placeAddress;
                                  // }
                                  // formikBag.setFieldValue('location_code', locationsCode);
                                  formikBag.setFieldValue(
                                    'location_code',
                                    (_.get(locationDetails, 'placeName', '')
                                      ? _.get(locationDetails, 'placeName', '') + ', '
                                      : '') + _.get(locationDetails, 'placeAddress', ''),
                                  );

                                  if (locationValues) {
                                    // locationDetails = locationValues[0] || {};
                                    // setLocDetailsValue(locationValues[0]?.addressTokens);
                                    setLocDetailsEloc(locationValues[0]?.eLoc);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Address Line 1</span>
                              {/* <span class="required">*</span> */}
                              <Field
                                id="address_line1"
                                name="address_line1"
                                component={FormikMaterialTextField}
                                disabled={isAdmin}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Address Line 2</span>
                              <Field name="address_line2" component={FormikMaterialTextField} disabled={isAdmin} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Address Line 3</span>
                              <Field name="address_line3" component={FormikMaterialTextField} disabled={isAdmin} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Village / District</span>
                              <MUITextField
                                fullWidth
                                size="small"
                                disabled
                                variant={'outlined'}
                                value={`${values.village_code} / ${values.district_code}`}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Pincode</span>
                              {/* <span class="required">*</span> */}
                              <Field name="pin_code" component={FormikMaterialTextField} disabled={isAdmin} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">State</span>
                              <MUITextField
                                fullWidth
                                size="small"
                                disabled
                                variant={'outlined'}
                                value={locDetailsValue?.state || `${values.state_code}`}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col-md-6 col-lg-5 col-xl-4">
                            <div className="form-group-field">
                              <span className="form-group-label">Country</span>
                              <MUITextField
                                fullWidth
                                size="small"
                                disabled
                                variant={'outlined'}
                                value={`${values.country_code}` || 'India'}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </CardBody>
              </Card>

              <div className="btn-wrapper justify-content-space-between">
                <div className="invisible">
                  <NavLink to="/account/farms" color="link">
                    Prev
                  </NavLink>
                </div>

                {!isAdmin && (
                  <div>
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      disabled={formikBag.isSubmitting}
                      onClick={() => {formikBag.handleSubmit();}}
                      className="next bfc-button-next">
                      {props.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}{' '}
                      Save
                    </Button>
                  </div>
                )}
                <div className={acl.isAgriBio ? 'invisible' : ''}>
                  {showOrganisationOrFarm ? (
                    <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} color="link">
                      Next
                    </NavLink>
                  ) : null}
                </div>
              </div>
              <VerficationFormModal
                onSuccessCb={({ user_name }) => {
                  formikBag.setFieldValue('email_id', user_name);
                  formikBag.setFieldValue('is_email_verified', true);
                }}
                ctx={modalCtx}
                id={id}
                closeModal={(e) => setModalCtx(false)}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Details;
