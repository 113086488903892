import React, { useEffect, useState } from 'react';
import utils from 'lib/utils';
import classnames from 'classnames';
import FacilityAreaIcon from 'assets/images/icons/extendedProfile/facility-area-icon.svg';
import LocationImage from 'assets/images/icons/extendedProfile/location-image.png';
import { Popover } from 'antd';
import { Col, Row } from 'reactstrap';
import SupplierPopover from 'common/ExtendedProfileCard/popover';
import NightLoadingIcon from 'assets/images/icons/extendedProfile/night-loading-icon.svg';
import RawMaterialCatchmentIcon from 'assets/images/icons/extendedProfile/raw-material-catchment-icon.svg';


function OverviewInfoCard({
  type,
  heading,
  productsCommodityFormCode,
  rawMaterialProductsData,
  isThereLocationAccessData,
  rawMaterialProductsData1,
  rawMaterialData,
  NoSuppliersIcon,
  rawMaterialSuppliersCountData,
  rawMaterialProcurementRangeFrom,
  rawMaterialProcurementRangeTo,
  infraEquipmentsArray,
  healthSafetyArray,
  safetyEqipmentsCodeData,
  fireFightingEquipmentTypeEnumData,
  qualityStandardData,
  qualityProcessArray,
  rightSideIcon,
  middleText,
  onClick,
  closeIcon,
  rawData,
  locationAccessData,
  addressText,
  infrastructureEquipmentData,
  equipmentsCodeData,
  healthSafety,
  qualityProcess,
  comingSoon,
  extendedProfilePersonId,
  extendedProfileId,
  extendedProfileAddressId,
  userId
}) {

  let zIndexPopup = '111111';

  const cardClass = classnames(['overview-single', { 'bg-color': type === 'highlight' }]);

  const rawMaterialFilter = rawMaterialData?.filter((item) => item.commodityCode);

  const ProductsContent = ({ details }) => (
    <div>
      {details.commodityTypeCode} {details.commodityFormCode}
    </div>
  );

  const EquipmentContent = ({ details }) => <div>{details.machineNameEnumCode}</div>;

  const FireFightingEquipmentContent = ({ details }) => <div>{details.fireFightingEquipmentTypeEnumCode}</div>;

  const SafetyEquipmentContent = ({ details }) => <div>{details.safetyEquipmentTypeEnumCode}</div>;

  const QualityProcessContent = ({ details }) => <div>{details.qualitySpecCode}</div>;

  const CoveredContent = ({ rawMaterialsCoveredInMT, rawMaterialsCoveredUsableInMT }) => (
    <div>
      <table>
        <tr>
          <td>
            <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Available Space:</small>
          </td>
          <td style={{ color: 'var(--unnamed-color-1b62ab)' }}>
            <small className="fw-600">{rawMaterialsCoveredInMT} MT</small>
          </td>
        </tr>
        <tr>
          <td>
            <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Usable Space:</small>
          </td>
          <td style={{ color: 'var(--unnamed-color-1b62ab)' }}>
            <small className="fw-600">{rawMaterialsCoveredUsableInMT} MT</small>
          </td>
        </tr>
      </table>
    </div>
  );

  const OpenedContent = ({ rawMaterialsOpenInMT, rawMaterialsOpenUsableInMT }) => (
    <div>
      <table>
        <tr>
          <td>
            <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Available Space:</small>
          </td>
          <td style={{ color: 'var(--unnamed-color-1b62ab)' }}>
            <small className="fw-600">{rawMaterialsOpenInMT} MT</small>
          </td>
        </tr>
        <tr>
          <td>
            <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Usable Space:</small>
          </td>
          <td style={{ color: 'var(--unnamed-color-1b62ab)' }}>
            <small className="fw-600">{rawMaterialsOpenUsableInMT} MT</small>
          </td>
        </tr>
      </table>
    </div>
  );

  const ManufacturingEquipmentContent = ({ details }) => (
    <div>
      <small className="text-center d-flex justify-content-center fw-600" style={{ color: 'var(--unnamed-color-1b62ab)' }}>Capacity</small>
      <table>
        <tbody>
          <tr>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Installed : </small>
            </td>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>{details.Capacity} MT/Day </small>
            </td>
          </tr>
          <tr>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Utilised : </small>
            </td>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>{details.Utilized_Capacity} MT/Day </small>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <hr style={{ color: 'var(--unnamed-color-ccc)', margin: '5px 0px 0px' }} />
            </td>
          </tr>
          <tr>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>Breakdowns : </small>
            </td>
            <td>
              <small style={{ color: 'var(--unnamed-color-afafaf)' }}>{details.BreakDown_Type_Enum_Code} </small>
            </td>
          </tr>
          <small style={{ color: 'var(--unnamed-color-afafaf)' }}>(Last 3 Months)</small>
        </tbody>
      </table>
    </div>
  );

  const QualityTestingContent = ({ details, text }) => (
    <div>
      <span>{text}</span><br />
    </div>
  );

  const FireEvent = () => {
    // gtag('event', 'Supplier_profile_drill_down_2',
    // {
    //   extendedProfilePersonId : extendedProfilePersonId,
    //   extendedProfileId : extendedProfileId,
    //   extendedProfileAddressId : extendedProfileAddressId,
    //   userId : userId ? userId : null
    // })
  }

  return (
    <div
      class="card extended__overview-card"
      style={{ boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 5%)', borderRadius: '5px' }} >
      <div className="card-body p-0">
        <div className="company-info d-flex justify-content-between pb-10">
          <div className="icon-wrapper d-flex align-items-center">
            {rightSideIcon}
            <p
              className="heading position-relative fw-600 m-0 ml-10"
              style={{ color: 'var(--unnamed-color-1b62ab)', fontFamily: 'var(--unnamed-font-segoeUI)' }}>
              {heading}
            </p>
          </div>
          {/* Close Icon Button */}
          <div>
            <button aria-label="Close" className="close" type="button" title="Close Card" onClick={onClick}>
              <span aria-hidden>×</span>
            </button>
          </div>
        </div>
        <div className="extended__middle-part flex-wrap ml-20">
          {rawData && (
            <div style={{display:'contents'}}>
              <span>
                <ul className="extended__ul">
                  {_.map(productsCommodityFormCode, (details) => {
                    return (
                      <>
                        <li className="extended__li">
                          <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={<ProductsContent details={details} />}>
                            <img className="cursor-pointer" src={details.post_image_URL} alt={details.post_image_URL} height="25" width="25" />
                          </Popover>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <small className="d-flex mt-10 ml-5">Products</small>
              </span>

              {rawMaterialFilter.length > 0 ? (
                <span>
                  <ul className="extended__ul flex-wrap h-auto">
                    <>
                      {_.map(rawMaterialData, (details) => {
                        return (
                          <li className="extended__li" style={{ display: 'inline-grid', textAlign: 'left' }}>
                            {/* {details.commodity_code_text_URL && (
                              <span>{details.commodity_code_text_URL}</span>
                            )} */}
                            {details.commodity_code_image_URL && (
                              <img className="w-auto"  src={details.commodity_code_image_URL} alt={details.commodity_code_image_URL} height="15" width="15" style={{ marginTop: '-5px' }} />
                            )}
                            <small className="count" style={{ lineHeight: '10px', width: 'max-content', display: 'inline-table' }}>{details.commodityCode}</small>
                            <small style={{ color: 'var(--unnamed-color-1b62ab)', width: 'max-content' }}>{details.commodityResidueCode} </small>
                          </li>
                        );
                      })}
                    </>
                  </ul>
                  <small className="d-flex mt-10 ml-5">Raw Materials</small>
                </span>
              ) : ''}

              {rawMaterialSuppliersCountData ? (
                <span>
                  <ul className="extended__ul">
                    <li className="extended__li">
                      <small style={{ color: 'var(--unnamed-color-1b62ab)', display: 'grid', textAlign: 'center', marginTop: '-5px' }}>
                        <img src={NoSuppliersIcon} alt={NoSuppliersIcon} height="20" width="20" />
                        <h4 className="count mb-0">{rawMaterialSuppliersCountData}</h4>
                      </small>
                    </li>
                  </ul>
                  <small className="d-flex text-center mt-10">
                    Dedicated <br />
                    Suppliers
                  </small>
                </span>
              ) : ''}

              {rawMaterialProcurementRangeFrom || rawMaterialProcurementRangeTo ? (
                <span>
                  <small className="text-center">Raw Material Catchment (km)</small><br/>
                  <img src={RawMaterialCatchmentIcon} alt={RawMaterialCatchmentIcon} height="25" width="50" />
                  <p className="" style={{ marginTop: '12px', fontSize: '80%', color: '#000000D9' }}>{rawMaterialProcurementRangeFrom} - {rawMaterialProcurementRangeTo} </p>
                  {/* <ul className="extended__ul">
                    <li className="extended__li text-center" style={{ marginTop: '-5px' }}>
                      <span className="count">
                        {rawMaterialProcurementRangeFrom} <br />
                        <hr className='m-0' />
                        {rawMaterialProcurementRangeTo}
                      </span>
                    </li>
                  </ul>
                  <small className="d-flex mt-5 ml-5">
                    Raw Material<br />
                    Catchment (km)
                  </small> */}
                </span>
              ) : ''}
            </div>
          )}

          {locationAccessData && (
            <div style={{display:'contents'}}>
              {isThereLocationAccessData ? _.map(rawMaterialProductsData, (details) => {
                return (
                  <>

                    {details.ownRentedEnumCode && (
                      <div className="">
                        {details.unclearIcon && (
                          <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                            padding: '0px', marginTop: '-5px', marginBottom: 15 }}>
                            <li className="extended__li ml-0 mr-0">
                              <span className="count">
                                {details.unclearIcon && (
                                  <SupplierPopover imageSrc55={details.unclearIcon} title_one={details.popoverText} />
                                )}
                              </span>
                            </li>
                          </ul>
                        )}

                        {details.ownRentedIcon && (
                          <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                            padding: '0px' }}>
                            <li className="extended__li ml-0 mr-0" style={{ marginTop: '-10px' }}>
                              <span className="count">
                                {details.ownRentedIcon && (
                                  <SupplierPopover imageSrc80={details.ownRentedIcon} title_one={details.popoverText} />
                                )}
                              </span>
                            </li>
                          </ul>
                        )}

                        {/* <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0" style={{ marginTop: '-10px' }}>
                            <span className="count">
                              {details.unclearIcon && (
                                <SupplierPopover imageSrc55={details.unclearIcon} title_one={details.popoverText} />
                                // <img src={details.unclearIcon} alt={details.unclearIcon}
                                // style={{ height: '60px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/>
                              )}
                              {details.ownRentedIcon && (
                                <SupplierPopover imageSrc80={details.ownRentedIcon} title_one={details.popoverText} />
                                // <img src={details.ownRentedIcon} alt={details.ownRentedIcon}
                                // style={{ height: '80px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/>
                              )}
                            </span>
                          </li>
                        </ul> */}
                        <small className="d-flex justify-content-center text-center mt-10">{details.ownRentedtext}</small>
                      </div>
                    )}

                    {details.facilityStartYear ? (
                      <span className="">
                        <ul className="extended__ul">
                          <li className="extended__li ml-0 mr-0">
                            <small style={{ color: 'var(--unnamed-color-1b62ab)', display: 'grid', textAlign: 'center', marginTop: '-5px' }}>
                              Since
                              <h4 className="count">{details.facilityStartYear}</h4>
                            </small>
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center mt-10">{details.yearsInOperationText}</small>
                      </span>
                    ) : ''}

                    {details.type === 'Storage1' && (
                      <div className="">
                        <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0">
                            <span className="count">
                            <Popover
                              placement="top"
                              overlayStyle={{ zIndex: zIndexPopup }}
                              content={'Covered Storage'}>
                              <img className="cursor-pointer" src={details.storageCoveredIcon} alt={details.storageCoveredIcon}
                              style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/>
                            </Popover>
                            </span>
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center mt-10">
                          <table>
                            <tr>
                              <td className="text-left"><small style={{ color: 'var(--unnamed-color-afafaf)' }}>Raw:</small></td>
                              <td style={{ color: 'var(--unnamed-color-1b62ab)' }}><small className="fw-600">{details.rawMaterialsCoveredInMT} MT</small></td>
                            </tr>
                            <tr>
                              <td><small style={{ color: 'var(--unnamed-color-afafaf)' }}>Finished Goods:</small></td>
                              <td style={{ color: 'var(--unnamed-color-1b62ab)' }}><small className="fw-600">{details.rawMaterialsCoveredUsableInMT} MT</small></td>
                            </tr>
                          </table>
                        </small>
                      </div>
                    )}
                    {details.type === 'Storage2' && (
                      <div className="">
                        <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0">
                            <span className="count">
                            <Popover
                            placement="top"
                            overlayStyle={{ zIndex: zIndexPopup }}
                            content={'Open Storage'}>
                                <img className="cursor-pointer" src={details.storageOpenedIcon} alt={details.storageOpenedIcon}
                                style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/>
                            </Popover>
                            </span>
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center mt-10">
                          <table>
                            <tr>
                              <td className="text-left"><small style={{ color: 'var(--unnamed-color-afafaf)' }}>Raw:</small></td>
                              <td style={{ color: 'var(--unnamed-color-1b62ab)' }}><small className="fw-600">{details.rawMaterialsOpenInMT} MT</small></td>
                            </tr>
                            <tr>
                              <td><small style={{ color: 'var(--unnamed-color-afafaf)' }}>Finished Goods:</small></td>
                              <td style={{ color: 'var(--unnamed-color-1b62ab)' }}><small className="fw-600">{details.rawMaterialsOpenUsableInMT} MT</small></td>
                            </tr>
                          </table>
                        </small>
                      </div>
                    )}
                    {details.roadTypeEnumCode && (
                      <div className="">
                        <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0" style={{ marginTop: '-10px' }}>
                            <span className="count">
                              <SupplierPopover imageSrc80={details.roadTypeEnumIcon} title_one={'Facility road access'} />
                              {/* <img src={details.roadTypeEnumIcon} alt={details.roadTypeEnumIcon}
                              style={{ height: '80px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-10px' }}/> */}
                            </span>
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center mt-10">{details.roadTypeEnumText}</small>
                      </div>
                    )}

                    {rawMaterialProductsData1 != null && (
                      <>
                        {details.totalMenEmployed && (
                          <div className="">
                            <ul className="extended__ul">
                              <li className="extended__li">
                                <span className="count" style={{ lineHeight: '12px' }}>
                                  <SupplierPopover imageSrc={details.menWomenIconIcon} title_one={'Labour force Gender ratio'} />
                                  {/* <img src={details.menWomenIconIcon} alt="" height="20" width="20" /> */}
                                </span>
                              </li>
                            </ul>
                            <small className="d-flex justify-content-center text-center mt-10">{details.totalMenEmployed ? details.totalMenEmployed : 0} {' : '}{details.totalWomenEmployed ? details.totalWomenEmployed : 0}</small>
                          </div>
                        )}
                      </>
                    )}

                    {details.icon && (
                      <div className="">
                        <ul className="extended__ul">
                          <li className="extended__li">
                            <span className="count" style={{ lineHeight: '12px' }}>
                              {details.icon && (<img src={details.icon} alt="" height="20" width="20" style={{ marginTop: '-15px' }} />)}
                            </span>
                          </li>
                        </ul>
                        {details.text && <small className="d-flex justify-content-center text-center mt-10">{details.text} </small>}
                      </div>
                    )}

                    {/* {details.noOfEmployessIcon && (
                      <div className="">
                        <ul className="extended__ul">
                          <li className="extended__li">
                            <span className="count" style={{ lineHeight: '12px' }}>
                              <img src={details.noOfEmployessIcon} alt="" height="15" width="15" style={{ marginTop: '-10px' }} />
                              <small className="d-flex justify-content-center" style={{ fontWeight: 700, fontSize: '18px' }}>{details.noOfEmployeeText}</small>
                            </span>
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center mt-10">{details.text}</small>
                      </div>
                    )} */}

                    {details.facilityArea && (
                      <div className="">
                        <ul className="extended__ul">
                          <li className="extended__li">
                            <span className="count" style={{ lineHeight: '12px', display: 'inline-block' }}>
                              <Popover
                                placement="top"
                                overlayStyle={{ zIndex: zIndexPopup }}
                                content={'total area of your facility'}
                                >
                                <img src={details.facilityAreaIcon} alt={details.facilityAreaIcon} height="15" width="15" style={{ marginTop: '-10px' }} />
                              </Popover>
                              <small className="d-flex justify-content-center fw-700">{details.facilityArea ? details.facilityAreaText : ''}</small>
                              <small className="d-flex justify-content-center">sq.ft</small>
                            </span>
                          </li>
                        </ul>
                        {details.text && <small className="d-flex justify-content-center text-center mt-10">{details.text}</small>}
                      </div>
                    )}
                  </>
                );
              }) : ''}

              {/* <span>
                <img src={LocationImage} alt={LocationImage} style={{ height: '60px', width: '200px' }}/> <br />
                <small className="mt-10">{addressText}</small>
              </span> */}
            </div>
          )}

          {infrastructureEquipmentData && (
            <div className="d-flex flex-wrap justify-content-start w-100" style={{ gap: '15px', marginTop: '-5px' }}>
              {equipmentsCodeData.length > 0  ? (
                <div className="d-flex" style={{ marginLeft: '-10px' }}>
                  {_.map(equipmentsCodeData, (details) => {
                    return (
                      <>
                        {details.is_equipment === true && (
                          <span>
                            <ul className="extended__ul justify-content-center p-0 mr-15" style={{  backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                              padding: '0px', marginTop: '0px', marginLeft: '0px' }}>
                              <li className="extended__li ml-0 mr-0" style={{  marginTop: '-20px' }}>
                                <span className="count">
                                  <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={<ManufacturingEquipmentContent details={details} />}>
                                    <img src={details.manufacturing_image_URL} alt={details.manufacturing_image_URL} className="cursor-pointer mt-10"
                                    style={{ height: '70px', width: '100%', objectFit: 'cover' }}/>
                                  </Popover>
                                </span>
                              </li>
                            </ul>
                            <small className="d-block justify-content-center mt-5 ml-auto mr-auto text-center"
                              style={{ textOverflow: 'ellipsis', maxHeight: '3em', width: '7em', overflow: 'hidden',}}>{details.make_of_machine}
                            </small>
                            {/* <small className="d-flex justify-content-center mt-5 w-auto ml-auto mr-auto text-center"
                              style={{ maxWidth: '70px', maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{details.make_of_machine}
                            </small> */}
                          </span>
                        )}
                      </>
                    );
                  })}
                </div>
              ) : ''}

              {_.map(infraEquipmentsArray, (details) => {
                return (
                  <div>
                    <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                      padding: '0px' }}>
                      <li className="extended__li ml-0 mr-0" style={{ marginTop: '-10px' }}>
                        <span className="count">
                          {details.backupGeneratorIcon && (
                            <img className="" src={details.backupGeneratorIcon} alt={details.backupGeneratorIcon}
                              style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-5px' }}/>
                          )}
                          {/* {details.generatorCapacityIcon && (
                            <>
                              <img className="position-absolute" src={details.generatorCapacityIcon} alt={details.generatorCapacityIcon}
                                style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content', marginTop: '-15px' }}/>
                              <small className="d-flex justify-content-center align-items-end position-relative ml-25 fw-700" style={{ top: '22px', gap: '5px' }}>{details.value}</small>
                            </>
                          )} */}
                          {details.dryingFacilityEnumCodeIcon && (
                            <>
                              <SupplierPopover imageSrc80={details.dryingFacilityEnumCodeIcon} title_one={details.popoverText} />
                              {/* <img className="" src={details.dryingFacilityEnumCodeIcon} alt={details.dryingFacilityEnumCodeIcon}
                              style={{ height: '60px', width: '100%', objectFit: 'cover', borderRadius: '50px', marginTop: '-5px' }}/> */}
                            </>
                          )}
                          {details.popoverText ? (
                            <>
                              {details.icon && (
                                <>
                                  <SupplierPopover imageSrc80={details.icon} title_one={details.popoverText} />
                                </>
                              )}
                            </>
                          ) : (
                            <img src={details.icon} alt={details.icon}
                              style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                          )}
                          {details.isLoadingUnloadingAllowedAtNightData ? (
                            <img src={NightLoadingIcon} alt={NightLoadingIcon}
                              style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px', width: 'min-content' }}/>
                          ) : ''}
                        </span>
                      </li>
                    </ul>
                    {details.MultiText && (<small className="d-flex justify-content-center text-center mt-5 ml-auto mr-auto" style={{width: '50px'}}>{details.MultiText}</small>)}
                    {details.text && (<small className="d-flex justify-content-center text-center mt-5 ml-auto mr-auto" style={{ width: '70px' }}>{details.text}</small>)}
                  </div>
                );
              })}

              {equipmentsCodeData.length > 0 ? (
                <span className="ml-5">
                  <ul className="extended__ul" style={{ height: '45px', marginTop: '-3px' }}>
                    {_.map(equipmentsCodeData, (details) => {
                      return (
                        <div>
                          {(details.is_equipment === false) && (
                            <li className="extended__li">
                              <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={<EquipmentContent details={details} />}>
                                <img className="cursor-pointer" src={details.machine_name_enum_code_image_URL} alt="" height="20" width="20" />
                              </Popover>
                              {/* <small style={{     position: relative; top: 15px;
                                left: 10px; }}> {details.msg} </small> */}
                            </li>
                          )}
                        </div>
                      );
                    })}
                  </ul>
                  <small className="d-flex mt-5 ml-5">Equipment</small>
                </span>
              ) : ''}
            </div>
          )}

          {healthSafety && (
            <div style={{display:'contents'}}>
              {_.map(healthSafetyArray, (details) => {
                return (
                  <span>
                    {details.healthSafetyCertificationEnumCode && (
                      <span>
                        {details.ohssIcon && (
                          <ul className="extended__ul p-0 h-auto" style={{ backgroundColor: 'transparent', boxShadow: 'none', height: '50px', width: '100%', marginTop: '-10px', padding: '0px' }}>
                            <li className="extended__li ml-0 mr-0">
                              <>
                                <SupplierPopover imageSrc80={details.ohssIcon} title_one={details.popoverText} />
                                {/* <img src={details.ohssIcon} alt={details.ohssIcon}
                                  style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                              </>
                            </li>
                          </ul>
                        )}
                        {details.otherIcon && (
                            <ul className="extended__ul p-0  p-0 ml-0 mr-0 d-flex justify-content-center" style={{ width: '50px',  }}>
                              <li className="extended__li ml-0 mr-0">
                              <Popover
                                placement="top"
                                overlayStyle={{ zIndex: zIndexPopup }}
                                content={'Health Certification'}
                                >
                                  <span className="count m-0 ml-5" style={{ position: 'relative', top: 15, right: 5 }}>
                                    {details.otherIcon}
                                  </span>
                                </Popover>
                              </li>
                            </ul>
                          )}
                        {details.ohssIcon ? (
                          <small className="d-flex justify-content-center text-center" style={{ whiteSpace: 'nowrap' }}>
                            {details.healthSafetyCertificationEnumCode}
                          </small>
                        ) : (
                          <small className="d-flex justify-content-center text-center mt-10" style={{ whiteSpace: 'nowrap' }}>
                            {details.healthSafetyCertificationEnumCode}
                          </small>
                        )}
                      </span>
                    )}
                    {details.lastSafetyTrainingOn && (
                      <span>
                        <ul className="extended__ul p-0 h-auto" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0">
                            <SupplierPopover imageSrc80={details.SafetyTrainingIcon} title_one={details.popoverText} />
                            {/* <img src={details.SafetyTrainingIcon} alt={details.SafetyTrainingIcon}
                              style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center" style={{ whiteSpace: 'nowrap' }}>
                          {details.SafetyTrainingTitle}
                        </small>
                      </span>
                    )}
                    {details.weeklyCheck && (
                      <span>
                        <ul className="extended__ul p-0 h-auto" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                          padding: '0px' }}>
                          <li className="extended__li ml-0 mr-0">
                            <SupplierPopover imageSrc80={details.WeeklyCheckIcon} title_one={details.popoverText} />
                            {/* <img src={details.WeeklyCheckIcon} alt={details.WeeklyCheckIcon}
                              style={{ height: '75px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                          </li>
                        </ul>
                        <small className="d-flex justify-content-center text-center" style={{ whiteSpace: 'nowrap' }}>
                          {details.WeeklyCheckTitle}
                        </small>
                      </span>
                    )}
                    {details.icon && (
                      <ul className="extended__ul p-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', marginTop: '-10px',
                        marginBottom: '10px', padding: '0px' }}>
                        <li className=" ml-0 mr-0">
                          {details.icon && (
                            <>
                              <img src={details.icon} alt={details.icon}
                                style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                            </>
                          )}
                        </li>
                      </ul>
                    )}
                    {details.text && (
                      <ul className="extended__ul p-0 w-auto justify-content-center">
                        <li className="extended__li" style={{ textAlign: 'center' }}>
                          <h4 className="mt-10" style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '800', position: 'relative' }}>
                            {details.text}
                          </h4>
                        </li>
                      </ul>
                    )}
                    {details.MultiText && (<small className="d-flex justify-content-center text-center mt-10" style={{ whiteSpace: 'pre-wrap', width: '60px' }}>{details.MultiText}</small>)}
                    <small className="d-flex justify-content-center text-center mt-10" style={{ whiteSpace: 'nowrap', marginLeft: '-10px' }}>
                      {details.title}
                      <br />
                      {details.subtitle}
                    </small>
                  </span>
                );
              })}

              {safetyEqipmentsCodeData.length > 0 ? (
                <span>
                  <ul className="extended__ul mb-5">
                    {_.map(safetyEqipmentsCodeData, (details) => {
                      return (
                        <>
                          <li className="extended__li">
                            <Popover
                              placement="top"
                              overlayStyle={{ zIndex: zIndexPopup }}
                              content={<SafetyEquipmentContent details={details} />}
                              >
                              <img className="cursor-pointer" src={details.safety_equipment_type_enum_image_URL} alt="" style={{ width: '40px', height: '20px' }}/>
                            </Popover>
                            <span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '800' }}>
                              {/* {details.fireFightingEquipmentTypeEnumCode} */}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <small className="ml-5" style={{ display: 'contents' }}>
                    Safety Equipment
                  </small>
                </span>
              ) : ''}

              {fireFightingEquipmentTypeEnumData.length > 0 ? (
                <span>
                  <ul className="extended__ul mb-5">
                    {_.map(fireFightingEquipmentTypeEnumData, (details) => {
                      return (
                        <>
                          <li className="extended__li">
                            <Popover
                              placement="top"
                              overlayStyle={{ zIndex: zIndexPopup }}
                              content={<FireFightingEquipmentContent details={details} />}
                              >
                              <img className="cursor-pointer" src={details.fire_fighting_equipment_image_URL} alt="" height="25" width="25" />
                            </Popover>
                            <span style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '800' }}>
                              {/* {details.fireFightingEquipmentTypeEnumCode} */}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <small className="ml-5" style={{ display: 'contents' }}>
                    Fire Equipment
                  </small>
                </span>
              ) : ''}
            </div>
          )}

          {qualityProcess && (
            <div className="d-flex" style={{ gap: '15px' }}>
              <span>
                <ul className="extended__ul" style={{ height: '45px', marginTop: '-3px' }}>
                  {_.map(qualityStandardData, (details) => {
                    return (
                      <>
                        <li className="extended__li">
                          <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={<QualityProcessContent details={details} />} >
                            <img className="cursor-pointer" src={details.quality_spec_code_image_URL} alt="" height="20" width="20" />
                          </Popover>
                        </li>
                      </>
                    );
                  })}
                </ul>
                <small className="d-flex mt-15 ml-5">
                  Parameters<br />
                  Tested
                </small>
              </span>

              {_.map(qualityProcessArray, (details) => {
                return (
                  <span>
                    {details.icon && (
                      <ul className="extended__ul pl-0 justify-content-center" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                        padding: '0px', position: 'relative', bottom: 15 }}>
                        <li className="extended__li ml-0 mr-0">
                          <span className="count">
                          <SupplierPopover imageSrc80={details.icon} title_one={details.popoverText} />
                            {/* <img src={details.icon} alt={details.icon}
                            style={{ height: '80px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                          </span>
                        </li>
                      </ul>
                    )}

                    {details.qualityCertificationEnumCode && (
                      <ul className="extended__ul pl-0 justify-content-center" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                      padding: '0px', position: 'relative', bottom: 15 }}>
                        <li className="extended__li ml-0 mr-0">
                          <span className="count">
                            <SupplierPopover imageSrc80={details.qualityCertificationEnumCodeIcon} title_one={details.popoverText} />
                            {/* <img src={details.qualityCertificationEnumCodeIcon} alt={details.qualityCertificationEnumCodeIcon}
                            style={{ height: '80px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/> */}
                          </span>
                        </li>
                      </ul>
                    )}

                    {details.testReportSentToBuyerFrequencyEnumCode && (
                      <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                        padding: '0px', position: 'relative', bottom: 15 }}>
                        <li className="extended__li ml-0 mr-0">
                          <span className="count">
                            <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={'Test Reports for Buyers'}>
                              <img className="cursor-pointer" src={details.reportBuyerIcon} alt={details.reportBuyerIcon}
                              style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                            </Popover>
                          </span>
                        </li>
                      </ul>
                    )}

                    {details.quality_certification_enum_code_icon_9001 && (
                      <ul className="extended__ul pl-0 justify-content-center ml-auto mr-auto">
                        <li className="extended__li ml-0 mr-0">
                          <span className="count">
                            <SupplierPopover imageSrcQualityCertification={details.quality_certification_enum_code_icon_9001} title_one={details.popoverText} />
                            {/* <img className="ml-5" src={details.quality_certification_enum_code_icon_9001} alt={details.quality_certification_enum_code_icon_9001}
                            style={{ height: '40px', width: '100%', }}/> */}
                          </span>
                        </li>
                      </ul>
                    )}

                    {details.qualityTestingFrequencyEnumCode && (
                      <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                        padding: '0px', position: 'relative', bottom: 15 }}>
                        <li className="extended__li ml-0 mr-0">
                          <span className="count">
                            <Popover placement="top" overlayStyle={{ zIndex: zIndexPopup }} content={<QualityTestingContent text={'Availability of test reports'} />}>
                              <img className="cursor-pointer" src={details.testingFrequencyIcon} alt={details.testingFrequencyIcon}
                              style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}/>
                            </Popover>
                          </span>
                        </li>
                      </ul>
                    )}

                    {details.text && (
                      <ul className="extended__ul pl-0" style={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%',
                        padding: '0px', position: 'relative', bottom: 15 }}>
                        <li className="extended__li">
                          <span className="mt-5" style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '800', top: '25%', position: 'relative' }}>
                            {details.text}
                          </span>
                        </li>
                      </ul>
                    )}
                    {details.titleText ? <small className="mt-5 text-center" style={{ whiteSpace: 'pre-wrap', width: '60px' }}>{details.titleText}</small> : ''}
                    {details.subtitle ? <small className="d-flex justify-content-center text-center mt-5">{details.subtitle}</small> : ''}
                    <small className="d-flex justify-content-center text-center mt-5">
                      {details.title ? details.title : ''}
                    </small>
                  </span>
                );
              })}
            </div>
          )}

          {comingSoon && (
            <ul
              className="extended__ul ml-auto mr-auto mt-auto"
              style={{ height: '35px', transform: 'rotate(180deg)' }}>
              <li className="extended__li">
                <p
                  className="fw-600"
                  style={{ marginTop: '-5px',
                    transform: 'rotate(180deg)',
                    color: 'var(--unnamed-color-1b62ab)',
                    fontFamily: 'var(--unnamed-font-segoeUI)',
                    fontSize: 'var(--fs-base__one)' }}
                  >
                  {middleText}
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default OverviewInfoCard;
