import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OverviewHeader from 'common/OverviewHeader';
import MUIButton from 'common/MUIComponents/Button';
import { actions as loadingUnloadingActions } from 'redux/loadingUnloading';
import MUITextField from 'common/MUIComponents/TextField';
import _ from 'lodash';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import { ReactComponent as ZohoIcon } from 'assets/images/icons/zoho.svg';

const zohoConfiguration = () => {
  const dispatch = useDispatch();
  const zohoDetails = useSelector((state) => state.loadingUnloading.zohoDetails);
  const user = useSelector((state) => state.user);
  const state = useSelector((state) => state);

  const [zohoCredentialId, setZohoCredentialId] = useState(0);
  const [zohoCustomerId, setZohoCustomerId] = useState('');
  const [code, setCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [selectedCompanyAffiliate, setSelectedCompanyAffiliate] = useState('');
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [templateId, setTemplateId] = useState('');

  useEffect(() => {
    if (!_.isEmpty(selectedCompanyAffiliate)) {
      const params = {
        person_company_affiliate_enum: selectedCompanyAffiliate?.person_company_affiliate_enum,
        person_company_affiliate_id: selectedCompanyAffiliate?.value_member,
      };
      dispatch(loadingUnloadingActions.fetchZohoAccountCreds(params));
    }
  }, [isReRenderRequired, selectedCompanyAffiliate]);

  useEffect(() => {
    setZohoCredentialId(zohoDetails?.zoho_credential_id || '');
    setZohoCustomerId(zohoDetails?.zoho_customer_id || '');
    setCode(zohoDetails?.zoho_client_code || '');
    setClientId(zohoDetails?.zoho_client_id || '');
    setClientSecret(zohoDetails?.zoho_client_secret || '');
    setTemplateId(zohoDetails?.template_id);
  }, [zohoDetails]);

  return (
    <div>
      <OverviewHeader
        heading={
          <>
            <ZohoIcon style={{ width: 30, height: 30, borderRadius: '50%' }} />
            <span className="d-block ml-2">ZOHO Configuration</span>
          </>
        }
      />
      <div className="zoho-table bfc-body">
        <div className="ant-table-body ant-table p-2">
          <div className="col-md-4">
            <div className="col mb-3 p-0">
              <span className="form-group-label">Select Company/Affiliate</span>
              <div className="form-group-field">
                <MUIAutocomplete
                  selectedCodeOrId={zohoDetails?.person_company_affiliate_id}
                  dataStructure={{
                    labelApiParam: 'display_member',
                    valueApiParam: 'value_member',
                  }}
                  apiParams={{
                    reqUrl: `BioFuelRegistration/api/ReferenceData/FetchCompanyAndAffiliatesAgainstPersonId`,
                    method: 'GET',
                    isQueryParams: true,
                    getReqParams: (newVal) => ({
                      person_id: user?.person_id,
                    }),
                  }}
                  onChange={(newValue) => {
                    setSelectedCompanyAffiliate(newValue);
                  }}
                />
              </div>
            </div>
            {/* {!_.isEmpty(zohoDetails) ? (
              <div className="col mb-3 p-0">
                <span className="form-group-label">Zoho Credential ID</span>
                <div className="form-group-field">
                  <MUITextField
                    fieldType="plainNumeric"
                    value={zohoCredentialId}
                    onChange={(newValue) => setZohoCredentialId(newValue)}
                  />
                </div>
              </div>
            ) : (
              ''
            )} */}
            <div className="col mb-3 p-0">
              <span className="form-group-label">Organization ID</span>
              <div className="form-group-field">
                <MUITextField
                  fieldType="plainNumeric"
                  value={zohoCustomerId}
                  onChange={(newValue) => setZohoCustomerId(newValue)}
                />
              </div>
            </div>
            <div className="col mb-3 p-0">
              <span className="form-group-label">Code</span>
              <div className="form-group-field">
                <MUITextField fieldType="alphanumeric" value={code} onChange={(newValue) => setCode(newValue)} />
              </div>
            </div>
            <div className="col mb-3 p-0">
              <span className="form-group-label">Client ID</span>
              <div className="form-group-field">
                <MUITextField
                  fieldType="alphanumeric"
                  value={clientId}
                  onChange={(newValue) => setClientId(newValue)}
                />
              </div>
            </div>
            <div className="col mb-3 p-0">
              <span className="form-group-label">Client Secret</span>
              <div className="form-group-field">
                <MUITextField
                  fieldType="alphanumeric"
                  value={clientSecret}
                  onChange={(newValue) => setClientSecret(newValue)}
                />
              </div>
            </div>
            <div className="col mb-3 p-0">
              <span className="form-group-label">Template Id</span>
              <div className="form-group-field">
                <MUITextField
                  fieldType="plainNumeric"
                  value={templateId}
                  onChange={(newValue) => setTemplateId(newValue)}
                />
              </div>
            </div>
            <div className="col mb-3 p-0">
              <div className="d-flex justify-content-end">
                <MUIButton
                  className="mr-2"
                  onClick={async () => {
                    const params = {
                      zoho_credential_id: zohoCredentialId,
                      zoho_customer_id: zohoCustomerId,
                      code,
                      client_id: clientId,
                      client_secret: clientSecret,
                      template_id: templateId,
                      person_company_affiliate_enum: selectedCompanyAffiliate?.person_company_affiliate_enum,
                      person_company_affiliate_id: selectedCompanyAffiliate?.value_member,
                    };
                    const success = await dispatch(loadingUnloadingActions.saveAndUpdateZohoAccountCreds(params));
                    success && setIsReRenderRequired(!isReRenderRequired);
                  }}>
                  {!_.isEmpty(zohoDetails) ? 'Update' : 'Save'}
                </MUIButton>
                <MUIButton
                  disabled={zohoDetails?.is_connected}
                  onClick={async () => {
                    const params = {
                      zoho_credential_id: zohoCredentialId,
                      zoho_customer_id: zohoCustomerId,
                      code,
                      client_id: clientId,
                      client_secret: clientSecret,
                    };
                    const success = await dispatch(loadingUnloadingActions.createTokenForZohoAccount(params));
                    success && setIsReRenderRequired(!isReRenderRequired);
                  }}>
                  {zohoDetails?.is_connected ? 'Connected' : 'Connect'}
                </MUIButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default zohoConfiguration;
