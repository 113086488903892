import { createSlice, createSelector, Dispatch } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from 'lib/api';
import { RootState } from './store';

type RefData = { value_member: number; display_member: string };

type ReferenceData = {
  uom: RefData[];
  currency: RefData[];
  location: RefData[];
  addressTypeEnum: RefData[];
  userTypeEnum: RefData[];
  genderEnum: RefData[];
  commodity: RefData[];
  commodityForm: Record<string, RefData[]>;
  commodityResidue: Record<string, RefData[]>;
  periodicityEnum: RefData[];
  paymentTerm: RefData[];
  vehicleType: RefData[];
  companyAffiliateType: RefData[];
  commoditySpecs: Record<string, RefData[]>;
  aboveBelowPenaltyEnum: Record<string, RefData[]>;
  aboveBelowRejectionEnum: Record<string, RefData[]>;
  minMaxEnum: RefData[];
  differenceByEnum: RefData[];
  commodityTypeForm: RefData[];
  typeFormCommodity: RefData[];
  roleTeam: RefData[];
  storage: RefData[];
  NatureOfBusinessEnum: RefData[];
  IndustryTypeEnum: RefData[];
  BusinessSizeEnum: RefData[];
  PeopleEmployedEnum: RefData[];
  MachineNameEnum: RefData[];
  ownRentedEnum: RefData[];
  RoadTypeEnum: RefData[];
  PowerCutFrequencyEnum: RefData[];
  ConnectedLoadEnum: RefData[];
  LoadingFacilitiesTypeEnum: RefData[];
  DryingFacilityEnum: RefData[];
  SafetyEquipmentTypeEnum: RefData[];
  HealthAndSafetyCertificationEnum: RefData[];
  SafetyTrainingFrequencyEnum: RefData[];
  QualityTestingPlaceEnum: RefData[];
  QualityCertificationEnum: RefData[];
  commodityForExtendedProfile: Record<string, RefData[]>;
  residueForExtendedProfile: Record<string, RefData[]>;
  InspectionEnum: RefData[];
  FireFightingEquipmentTypeEnum: RefData[];
  BreakDownTypeEnum: RefData[];
  ExtendedProfileEnum: RefData[];
};

export interface ReferenceDataState {
  data: ReferenceData;
  busy: boolean;
  errors: any;
  updatedAt: number | null;
}

const initialState: ReferenceDataState = {
  data: {
    uom: [],
    currency: [],
    location: [],
    addressTypeEnum: [],
    userTypeEnum: [],
    genderEnum: [],
    commodity: [],
    commodityForm: {},
    commodityResidue: {},
    periodicityEnum: [],
    vehicleType: [],
    companyAffiliateType: [],
    paymentTerm: [],
    commoditySpecs: {},
    aboveBelowPenaltyEnum: {},
    aboveBelowRejectionEnum: {},
    minMaxEnum: [],
    differenceByEnum: [],
    commodityTypeForm: [],
    typeFormCommodity: {},
    roleTeam: [],
    storage: [],
    NatureOfBusinessEnum: [],
    IndustryTypeEnum: [],
    BusinessSizeEnum: [],
    PeopleEmployedEnum: [],
    MachineNameEnum: [],
    ownRentedEnum: [],
    RoadTypeEnum: [],
    PowerCutFrequencyEnum: [],
    ConnectedLoadEnum: [],
    LoadingFacilitiesTypeEnum: [],
    DryingFacilityEnum: [],
    SafetyEquipmentTypeEnum: [],
    HealthAndSafetyCertificationEnum: [],
    SafetyTrainingFrequencyEnum: [],
    QualityTestingPlaceEnum: [],
    QualityCertificationEnum: [],
    commodityForExtendedProfile: {},
    residueForExtendedProfile: {},
    InspectionEnum: [],
    FireFightingEquipmentTypeEnum: [],
    BreakDownTypeEnum: [],
    ExtendedProfileEnum: [],
    equipmentCategory: [],
    equipment_type: [],
    equipment_spec: [],
    equipment_make: [],
    equipment_model: [],
    equipment_variant: [],
  },
  busy: false,
  errors: null,
  updatedAt: null,
};

const slice = createSlice({
  name: 'referenceData',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    update(state, action) {
      const { entity, qparams, resData } = action.payload;

      if (
        [
          'typeFormCommodity',
          'commodityResidue',
          'equipmentCategory',
          'equipment_type',
          'equipment_spec',
          'equipment_make',
          'equipment_model',
          'equipment_variant',
        ].includes(entity) &&
        !_.isEmpty(qparams)
      ) {
        state.data[entity][Object.values(qparams).join('')] = resData;
        // state.data[entity] = resData;
      } else {
        if (!_.isEmpty(qparams)) {
          const qId = Object.values(qparams)[0];
          if (qId) state.data[entity][qId] = resData;
        } else {
          state.data[entity] = resData;
        }
      }

      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload;
      state.busy = false;
    },
  },
});

const getCommodityDropdownData = (entity: string, qparams: any) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getCommodityDropdownData({ entity, qparams });
      dispatch(slice.actions.update({ entity, qparams, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, qparams, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getEnumData = (entity: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getEnumData({ enumgroup: entity });
      dispatch(slice.actions.update({ entity, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getReferenceData = (entity: string, entity_id?: string | number) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getReferenceData(entity_id ? { entity, entity_id, optype: 4 } : { entity });
      dispatch(slice.actions.update({ entity, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getPaymentTermForEquipmentRental = (entity: string, entity_id?: string | number) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getPaymentTermForEquipmentRental();
      dispatch(slice.actions.update({ entity, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getReferenceDataDiscount = (entity: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getReferenceData({ entity });
      resData.push({
        displayMember: 'All',
        display_member: 'All',
        displaymember: 'All',
        valueMember: 0,
        value_member: 0,
        valuemember: 0,
      });
      dispatch(slice.actions.update({ entity, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getSpecificParticipant = (typeOfIndividual) => {
  let entity = 'specificEntityId';
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getSpecificParticipant(typeOfIndividual);

      dispatch(slice.actions.update({ entity, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

const getEquiSpecDropdown = (entity: string, qparams: any) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const resData = await api.getEquiSpecDropdown({ entity, qparams });
      dispatch(slice.actions.update({ entity, qparams, resData }));
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ entity, qparams, errors }));
      setTimeout(() => dispatch(slice.actions.handleErrors(null)), 5000);
    }
  };
};

export const actions = {
  // ...slice.actions,
  getCommodityDropdownData,
  getEnumData,
  getReferenceData,
  getReferenceDataDiscount,
  getSpecificParticipant,
  getEquiSpecDropdown,
  getPaymentTermForEquipmentRental,
};

const referenceDataSelector = (state: RootState) => state.referenceData;

const commoditySelector = createSelector(
  referenceDataSelector,
  (refData: ReferenceDataState) => refData.data.commodity,
);

export const selectors = {
  commodity: commoditySelector,
};

export default slice.reducer;
