import * as Yup from 'yup';
import styles from '../components/invoiceApprovalPopup.module.css';

import notifications from 'lib/notifications';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import utils, { getDecryptedUrl, getUrlWithEncryptedQueryParams } from 'lib/utils';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelectOkayToPay';
import RupeeIcon from 'mdi-react/RupeeIcon';
import FormikDatePickerFieldInvoice from './FormikDatePickerFieldInvoice';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import { TextField, withStyles } from '@material-ui/core';
import { Tag, Tooltip } from 'antd';
import { actions as dealInvoicesActions } from 'redux/dealInvoices';
import { saveUpdateInvoiceVerificationStatus } from 'services/dealInvoices';
import AmountField from 'common/TableComponents/amountField';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import MUITextField from 'common/MUIComponents/TextField';
import MUIButton from 'common/MUIComponents/Button';
import Info from '@material-ui/icons/Info';
import MUIDatePicker from 'common/MUIComponents/DateTimePicker';
import MUITimeline from 'common/MUIComponents/Timeline';
import MUITypography from 'common/MUIComponents/Typography';

const generateChildContent = (data) => {
  let color = null;
  if (data?.invoice_verification_enum === 1) {
    color = 'red';
  } else if (data?.invoice_verification_enum === 2) {
    color = 'blue';
  } else if (data?.invoice_verification_enum === 3) {
    color = 'green';
  } else {
    color = 'purple';
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <Tag className="fw-100" color={color}>
          {data?.invoice_verification_enum_code}
        </Tag>
        <div className="d-flex flex-column align-items-end">
          <MUITypography variant="subtitle2">
            Created On: {dayjs(data?.created_datetime).format('DD-MM-YYYY')}
          </MUITypography>
          <MUITypography variant="subtitle2">
            {data?.created_by_person}
          </MUITypography>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-start pt-5">
        <MUITypography type='currency' variant="subtitle2">{data?.amount_to_pay}</MUITypography>
        <MUITypography variant="subtitle2">
          Pay On : {dayjs(data?.pay_on_date).format('DD-MM-YYYY')}
        </MUITypography>
      </div>
      {data?.remarks?.length ? (
        <div>
          <p style={{ fontSize: '.725rem', paddingTop: 4 }} className="text-muted">
            {data?.remarks?.length > 70 ? (
              <>
                <MUITypography variant="caption">{data?.remarks?.slice(0, 70)}...</MUITypography>
                <Tooltip
                  placement="topLeft"
                  title={<div style={{ whiteSpace: 'pre-line' }}>{data?.remarks}</div>}
                  style={{ width: '100%' }}>
                  <MUITypography variant="caption" style={{ textDecoration: 'underline' }}>see more</MUITypography>
                </Tooltip>
              </>
            ) : (
              <MUITypography variant="caption" style={{ whiteSpace: 'pre-line' }}>
                {data?.remarks}
              </MUITypography>
            )}
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const InvoiceApprovalPopup = React.memo(function InvoiceApprovalPopup(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { toggle, invoiceDetails, setTimeStamp } = props;

  const loc = getDecryptedUrl(window.location.href);

  const invoiceHistoryRaw = useSelector((state) => state.dealInvoices);
  const busy = _.get(invoiceHistoryRaw, 'busy');
  const invoiceHistoryData = _.get(invoiceHistoryRaw, 'invoiceVerificationStatusHistory') || [];

  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

  const paymentDueDate = new Date(invoiceDetails?.payment_due_date);
  const paymentDueDateUTC = new Date(
    Date.UTC(paymentDueDate.getUTCFullYear(), paymentDueDate.getUTCMonth(), paymentDueDate.getUTCDate()),
  );
  // console.log("Omkar---->", paymentDueDate,paymentDueDateUTC, today,todayUTC, paymentDueDate < today, today.toISOString().split('T')[0]);
  const initialValues = {
    invoice_Verification_Enum: invoiceDetails?.invoice_verification_enum,
    amount_To_Pay:
      invoiceDetails?.workflow_amount_to_pay === null
        ? Math.abs(invoiceDetails?.amount_due)
        : Math.abs(invoiceDetails?.workflow_amount_to_pay),
    pay_On_Date: new Date(
      invoiceDetails?.workflow_pay_on_date === null
        ? paymentDueDateUTC < todayUTC
          ? todayUTC.toISOString().split('T')[0]
          : invoiceDetails?.payment_due_date
        : invoiceDetails?.workflow_pay_on_date,
    ),
    // remarks: invoiceDetails?.workflow_remarks,
    mapped_invoice_master_id: invoiceDetails?.mapped_invoice_master_id?.split(',')?.map((item) => parseInt(item)),
    mapped_advance_invoice_master_id: invoiceDetails?.mapped_advance_invoice_master_id?.split(',')?.map((item) => parseInt(item)),
    // mapped_invoice_master_id: invoiceDetails?.workflow_remarks?.split('-').filter(item => parseInt(item))?.map(item => item?.trim())
  };

  const [remark, setRemark] = useState('');
  const [crRemark, setCrRemark] = useState('');
  const [advanceRemark, setAdvanceRemark] = useState('');
  const [totalCRAmount, setTotalCRAmount] = useState(0);
  const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
  const [amountToPay, setAmountToPay] = useState('');
  const [isOnchangeEvent, setIsOnchangeEvent] = useState(false);

  useEffect(() => {
    if (invoiceDetails?.workflow_remarks) {
      setRemark(invoiceDetails?.workflow_remarks);
    }
    if (invoiceDetails?.workflow_amount_to_pay || invoiceDetails?.amount_due) {
      const amountToPay = invoiceDetails?.workflow_amount_to_pay === null
        ? Math.abs(invoiceDetails?.amount_due)
        : Math.abs(invoiceDetails?.workflow_amount_to_pay);
      setAmountToPay(amountToPay);
    }
  }, [invoiceDetails?.workflow_remarks, invoiceDetails?.workflow_amount_to_pay, invoiceDetails?.amount_due]);

  useEffect(() => {
    if (isOnchangeEvent) {
      const amountDue = Math.abs(invoiceDetails?.amount_due);
      const totalAmount = totalCRAmount + totalAdvanceAmount;
      setAmountToPay(amountDue - totalAmount);
      const combinedRemark = `${crRemark}${crRemark && advanceRemark ? `\n\n` : ''}${advanceRemark}`;
      setRemark(combinedRemark);
      setIsOnchangeEvent(false);
    }
  }, [totalCRAmount, totalAdvanceAmount, crRemark, advanceRemark]);

  const handleSubmit = async (values, formikBag) => {
    try {
      const params = {
        invoice_Master_Id: invoiceDetails?.invoice_master_id,
        operation_Enum: 1, // 1 means "Add"
        invoice_Verification_Enum: values.invoice_Verification_Enum,
        amount_To_Pay: Number(amountToPay),
        pay_On_Date: dayjs(values.pay_On_Date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        remarks: remark,
        mapped_invoice_master_id: values?.mapped_invoice_master_id,
        mapped_advance_invoice_master_id: values?.mapped_advance_invoice_master_id,
      }
      const resSubmit = await saveUpdateInvoiceVerificationStatus(params);

      if (resSubmit) {
        setTimeStamp(Date.now());
        toggle();

        notifications.show({
          type: 'success',
          message: 'success',
          key: 'bids-form',
          duration: null,
        });
      }
    } catch (err) {
      console.error(err);

      let errors = _.get(err, 'errors') || {};
      let errMessage = _.get(err, 'message') || 'Unable to process request.';

      if (!_.isEmpty(errors)) {
        formikBag.setErrors({
          ...errors,
        });

        errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
      }

      notifications.show({
        type: 'error',
        message: errMessage,
        key: 'bids-form',
        duration: null,
      });
    }
  };

  // const isDisabledWorkFlow = invoiceDetails?.invoice_status_enum == 10 || invoiceDetails?.invoice_status_enum == 4 ? invoiceDetails?.invoice_status_enum_code :

  //fetching invoice history data here
  useEffect(() => {
    dispatch(dealInvoicesActions.getInvoiceVerificationStatusHistory(invoiceDetails));
  }, []);

  const encodeBack = utils.encodeBackUrl();
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchemaforPopup}
        onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          return (
            <Form className="formik-form">
              <div className="modal__body counter-modal__body" style={{ height: 'auto', paddingBottom: '10px' }}>
                <div className="container-fluid">
                  <div className="row mt-10">
                    <div className="col-md-12">
                      <React.Fragment>
                        <div className="">
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">Workflow Status</span>
                              <div className="form-group-field">
                                <MUIAutocomplete
                                  dataStructure={{
                                    labelApiParam: 'display_member',
                                    valueApiParam: 'value_member',
                                  }}
                                  apiParams={{
                                    reqUrl:
                                      'BioFuelRegistration/api/ReferenceData/GetEnumerator?Enum_Group=InvoiceVerificationEnum',
                                    method: 'GET',
                                  }}
                                  disabled={
                                    invoiceDetails?.invoice_status_enum == 10 ||
                                    invoiceDetails?.invoice_status_enum == 4
                                      ? true
                                      : false
                                  }
                                  getSanitizedData={(data) =>
                                    data.filter(
                                      (item) =>
                                        item.value_member == 1 || item.value_member == 2 || item.value_member == 3,
                                    )
                                  }
                                  selectedCodeOrId={values?.invoice_Verification_Enum}
                                  onChange={(value) => {
                                    setFieldValue('invoice_Verification_Enum', value?.value_member);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">
                                Amount to Pay <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType="price"
                                  // value={values?.amount_To_Pay}
                                  value={amountToPay}
                                  onChange={(newValue) => {
                                    // setFieldValue('amount_To_Pay', newValue);
                                    setAmountToPay(newValue);
                                  }}
                                  EndIcon={''}
                                />
                              </div>
                            </div>
                          </div>

                          {invoiceDetails?.invoice_type_enum == 2 || invoiceDetails?.invoice_type_enum == 3 || invoiceDetails?.invoice_type_enum == 7 ? null : (
                            <div className="row w-auto mb-15">
                              <div className="col">
                                <span className="form-group-label d-flex">
                                  Adjustments
                                  <MUIButton
                                    className="ml-2"
                                    type="icon"
                                    variant="text"
                                    iconBtnSize="small"
                                    Icon={Info}
                                    tooltipText="Credit Notes & Receivables"
                                    tooltipPlacement="right"
                                    disableRipple
                                  />
                                  {/* <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span> */}
                                </span>
                                <div className="form-group-field">
                                  <MUIAutocomplete
                                    type="multiselect"
                                    dataStructure={{
                                      labelApiParam: 'custom_invoice_number',
                                      valueApiParam: 'invoice_master_id',
                                    }}
                                    apiParams={{
                                      reqUrl: 'BioFuelDeliveries/api/Invoices/FetchAllReceivableInvoices',
                                      method: 'POST',
                                      getReqParams: (value) => ({
                                        filters: {},
                                        invoice_master_id: invoiceDetails?.invoice_master_id,
                                        consignee_id: invoiceDetails?.consignee_id,
                                        consignor_id: invoiceDetails?.consignor_id,
                                        buyer_person_company_affiliate_enum:
                                          invoiceDetails?.buyer_person_company_affiliate_enum,
                                        seller_person_company_affiliate_enum:
                                          invoiceDetails?.seller_person_company_affiliate_enum,
                                      }),
                                    }}
                                    multiselectArray={values?.mapped_invoice_master_id}
                                    generateEndContent={(value) => (
                                      <span style={{ color: value?.amount_due > 0 ? 'green' : 'red' }}>
                                        ₹{utils.formatNumberPrice(value?.amount_due)}
                                      </span>
                                    )}
                                    callOnChangeAgain={true}
                                    onChange={(value, setSelectedValue) => {
                                      // comment this once okay to pay is fixed
                                      // const invoiceAmountDue = invoiceDetails?.workflow_amount_to_pay === null ? Math.abs(invoiceDetails?.amount_due) : Math.abs(invoiceDetails?.workflow_amount_to_pay);
                                      // uncomment this once okay to pay is fixed
                                      const invoiceAmountDue = Math.abs(invoiceDetails?.amount_due);
                                      const amountArray = value?.length ? value?.map((item) => item?.amount_due) : [];
                                      const totalSelectedInvoicePrice = value?.length
                                        ? amountArray?.reduce((acc, crr) => acc + crr)
                                        : 0;
                                      setTotalCRAmount(totalSelectedInvoicePrice);
                                      if (invoiceAmountDue - totalSelectedInvoicePrice < 0) {
                                        const selectedInv = [...value];
                                        selectedInv.pop();
                                        setSelectedValue(selectedInv);
                                        setFieldValue(
                                          'mapped_invoice_master_id',
                                          selectedInv?.map((item) => item?.invoice_master_id),
                                        );
                                        notifications.show({
                                          type: 'error',
                                          message: `Oops! It looks like the adjusted invoice amount exceeds the total due. Amount to Pay can not be negative. If you proceed this invoice won't be adjusted`,
                                        });
                                      } else {
                                        const amountBeingAdded = value?.length
                                          ? value
                                              ?.filter((item) => item?.amount_due > 0)
                                              ?.map(
                                                (item) =>
                                                  `${item?.custom_invoice_number} - ${item?.invoice_master_id} - ₹${item?.amount_due}`,
                                              )
                                              ?.join('\n')
                                          : '';
                                        const amountBeingSubtracted = value?.length
                                          ? value
                                              ?.filter((item) => item?.amount_due < 0)
                                              ?.map(
                                                (item) =>
                                                  `${item?.custom_invoice_number} - ${item?.invoice_master_id} - ₹${
                                                    item?.amount_due * -1
                                                  }`,
                                              )
                                              ?.join(`\n`)
                                          : '';
                                        // setFieldValue(
                                        //   'amount_To_Pay',
                                        //   value?.length
                                        //     ? invoiceAmountDue - totalSelectedInvoicePrice
                                        //     : invoiceAmountDue,
                                        // );
                                        const remarkString = `${amountBeingAdded ? `Subtracted:\n` : ''}${amountBeingAdded}${amountBeingSubtracted ? `\n` : ''}${amountBeingSubtracted}`
                                        setCrRemark(remarkString);
                                        setFieldValue(
                                          'mapped_invoice_master_id',
                                          value?.map((item) => item?.invoice_master_id),
                                        );
                                        setIsOnchangeEvent(true);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {invoiceDetails?.invoice_type_enum == 2 || invoiceDetails?.invoice_type_enum == 3 || invoiceDetails?.invoice_type_enum == 7 ? null : (
                            <div className="row w-auto mb-15">
                              <div className="col">
                                <span className="form-group-label d-flex">
                                  Adv. Adjustments
                                  <MUIButton
                                    className="ml-2"
                                    type="icon"
                                    variant="text"
                                    iconBtnSize="small"
                                    Icon={Info}
                                    tooltipText="Advance payments"
                                    tooltipPlacement="right"
                                    disableRipple
                                  />
                                </span>
                                <div className="form-group-field">
                                  <MUIAutocomplete
                                    type="multiselect"
                                    dataStructure={{
                                      labelApiParam: 'custom_invoice_number',
                                      valueApiParam: 'invoice_master_id',
                                    }}
                                    dataStoreEntityName="AdvancePayments"
                                    apiParams={{
                                      reqUrl: 'BioFuelDeliveries/api/Invoices/FetchAllReceivableInvoices',
                                      method: 'POST',
                                      getReqParams: (value) => ({
                                        filters: { fetch_advances_only: "1" },
                                        invoice_master_id: invoiceDetails?.invoice_master_id,
                                        consignee_id: invoiceDetails?.consignee_id,
                                        consignor_id: invoiceDetails?.consignor_id,
                                        buyer_person_company_affiliate_enum:
                                          invoiceDetails?.buyer_person_company_affiliate_enum,
                                        seller_person_company_affiliate_enum:
                                          invoiceDetails?.seller_person_company_affiliate_enum,
                                      }),
                                    }}
                                    multiselectArray={values?.mapped_advance_invoice_master_id}
                                    generateEndContent={(value) => (
                                      <span style={{ color: value?.amount_due > 0 ? 'green' : 'red' }}>
                                        ₹{utils.formatNumberPrice(value?.amount_due)}
                                      </span>
                                    )}
                                    callOnChangeAgain={true}
                                    onChange={(value, setSelectedValue) => {
                                      // comment this once okay to pay is fixed
                                      // const invoiceAmountDue = invoiceDetails?.workflow_amount_to_pay === null ? Math.abs(invoiceDetails?.amount_due) : Math.abs(invoiceDetails?.workflow_amount_to_pay);
                                      // uncomment this once okay to pay is fixed
                                      const invoiceAmountDue = Math.abs(invoiceDetails?.amount_due);
                                      const amountArray = value?.length ? value?.map((item) => item?.amount_due) : [];
                                      const totalSelectedInvoicePrice = value?.length
                                        ? amountArray?.reduce((acc, crr) => acc + crr)
                                        : 0;
                                      setTotalAdvanceAmount(totalSelectedInvoicePrice);
                                      if (invoiceAmountDue - totalSelectedInvoicePrice < 0) {
                                        const selectedInv = [...value];
                                        selectedInv.pop();
                                        setSelectedValue(selectedInv);
                                        setFieldValue(
                                          'mapped_advance_invoice_master_id',
                                          selectedInv?.map((item) => item?.invoice_master_id),
                                        );
                                        notifications.show({
                                          type: 'error',
                                          message: `Oops! It looks like the adjusted invoice amount exceeds the total due. Amount to Pay can not be negative. If you proceed this invoice won't be adjusted`,
                                        });
                                      } else {
                                        const amountBeingAdded = value?.length
                                          ? value
                                            ?.filter((item) => item?.amount_due > 0)
                                            ?.map(
                                              (item) =>
                                                `${item?.custom_invoice_number} - ${item?.invoice_master_id} - ₹${item?.amount_due}`,
                                            )
                                            ?.join('\n')
                                          : '';
                                        const amountBeingSubtracted = value?.length
                                          ? value
                                            ?.filter((item) => item?.amount_due < 0)
                                            ?.map(
                                              (item) =>
                                                `${item?.custom_invoice_number} - ${item?.invoice_master_id} - ₹${item?.amount_due * -1
                                                }`,
                                            )
                                            ?.join(`\n`)
                                          : '';
                                        // setFieldValue(
                                        //   'amount_To_Pay',
                                        //   value?.length
                                        //     ? invoiceAmountDue - totalSelectedInvoicePrice
                                        //     : invoiceAmountDue,
                                        // );
                                        const remarkString = `${amountBeingAdded ? `Advances Subtracted:\n` : ''}${amountBeingAdded}${amountBeingSubtracted ? `\n` : ''}${amountBeingSubtracted}`;
                                        setAdvanceRemark(remarkString);
                                        setFieldValue(
                                          'mapped_advance_invoice_master_id',
                                          value?.map((item) => item?.invoice_master_id),
                                        );
                                        setIsOnchangeEvent(true);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">
                                Pay on Date <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <MUIDatePicker
                                value={values?.pay_On_Date}
                                onChange={(value) => {
                                  setFieldValue('pay_On_Date', value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">Remarks</span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType="textarea"
                                  value={remark}
                                  onChange={(newValue) => {
                                    setRemark(newValue);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                <div className={styles.invoice_hyperlinks}>
                  <div>
                    <MUITypography color='blue' variant='body2' style={{textDecoration: 'underline'}} onClick={() => {
                      window.open(
                        getUrlWithEncryptedQueryParams(`/control-tower/ledger?page=1&seller_person_company_affiliate_id=${invoiceDetails?.consignor_id
                        }&seller_person_company_affiliate_enum=${invoiceDetails?.seller_person_company_affiliate_enum
                        }&viewing_person_company_affiliate_id=${invoiceDetails?.consignee_id
                        }&viewing_person_company_affiliate_enum=${invoiceDetails?.buyer_person_company_affiliate_enum
                        }&okay_to_pay=${true}&back=${encodeBack}`)
                      );
                    }}>View Ledger</MUITypography>
                  </div>
                  <div>
                    <MUITypography color='blue' variant='body2' style={{ textDecoration: 'underline' }} onClick={() => {
                      invoiceDetails?.commodity_deal_master_id &&
                        window.open(
                          getUrlWithEncryptedQueryParams(`/control-tower/ledger?page=1&seller_person_company_affiliate_id=${invoiceDetails?.consignor_id
                          }&seller_person_company_affiliate_enum=${invoiceDetails?.seller_person_company_affiliate_enum
                          }&viewing_person_company_affiliate_id=${invoiceDetails?.consignee_id
                          }&viewing_person_company_affiliate_enum=${invoiceDetails?.buyer_person_company_affiliate_enum
                          }&okay_to_pay=${true}&commodity_deal_master_id=${invoiceDetails?.commodity_deal_master_id
                          }&back=${encodeBack}`)
                        );
                    }}>
                      (for this deal)
                    </MUITypography>
                  </div>
                  {loc.includes('advance/payments') ? '' : <div>
                    <MUITypography color='blue' variant='body2' style={{ textDecoration: 'underline' }} onClick={() => {
                      (invoiceDetails?.transport_delivery_schedule_alloted_id || invoiceDetails?.delivery_id) &&
                        window.open(
                          getUrlWithEncryptedQueryParams(`/control-tower/ledger?page=1&seller_person_company_affiliate_id=${invoiceDetails?.consignor_id
                          }&seller_person_company_affiliate_enum=${invoiceDetails?.seller_person_company_affiliate_enum
                          }&viewing_person_company_affiliate_id=${invoiceDetails?.consignee_id
                          }&viewing_person_company_affiliate_enum=${invoiceDetails?.buyer_person_company_affiliate_enum
                          }&okay_to_pay=${true}&transport_delivery_schedule_alloted_id=${invoiceDetails?.transport_delivery_schedule_alloted_id || invoiceDetails?.delivery_id
                          }&back=${encodeBack}`)
                        );
                    }}>
                      (for this delivery)
                    </MUITypography>
                  </div>}
                </div>
              </div>
              <div className="modal__footer" style={{ marginTop: '10px', marginBottonm: '10px', display: 'block' }}>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    size="sm"
                    class="btn btn-primary btn-sm"
                    style={{ marginRight: '10px' }}
                    onClick={() => toggle()}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="sm"
                    onClick={formikBag.onSubmit}
                    disabled={formikBag.isSubmitting}>
                    Submit
                  </Button>
                </div>
                <br />
                {invoiceHistoryData?.length ? <hr className="m-0" /> : ''}
                <MUITimeline data={invoiceHistoryData} isAvatar={false} generateChildContent={generateChildContent} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
});

export default InvoiceApprovalPopup;
