import _ from 'lodash';
import utils from 'lib/utils';
import dayjs from 'dayjs';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  create(params = {}) {
    let qsParams = {
      ...params,
      tare_weight: parseFloat((parseFloat(params['tare_weight']) / 1000).toFixed(3)),
      gross_weight: parseFloat((parseFloat(params['gross_weight']) / 1000).toFixed(3)),
      offered_qty: parseFloat((parseFloat(params['offered_qty']) / 1000).toFixed(3)),
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  create(resData) {
    return resData;
  },
};
