import notifications from 'lib/notifications';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import { Tag, Tooltip } from 'antd';
import { actions as dealInvoicesActions } from 'redux/dealInvoices';
import MUITextField from 'common/MUIComponents/TextField';
import MUIDatePicker from 'common/MUIComponents/DateTimePicker';
import MUITimeline from 'common/MUIComponents/Timeline';
import MUITypography from 'common/MUIComponents/Typography';
import CompanySelect from 'pages/commodity/components/CompanySelect';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import MUIButton from 'common/MUIComponents/Button';

const AddAdvancePopUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { toggle, invoiceDetails, setTimeStamp } = props;

  const invoiceHistoryRaw = useSelector((state) => state.dealInvoices);
  const busy = _.get(invoiceHistoryRaw, 'busy');
  const invoiceHistoryData = _.get(invoiceHistoryRaw, 'invoiceVerificationStatusHistory') || [];

  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

  const paymentDueDate = new Date(invoiceDetails?.payment_due_date);
  const paymentDueDateUTC = new Date(
    Date.UTC(paymentDueDate.getUTCFullYear(), paymentDueDate.getUTCMonth(), paymentDueDate.getUTCDate()),
  );

  const [amount, setAmount] = useState('');
  const [tds, setTds] = useState('');
  const [remark, setRemark] = useState('');

  const initialValues = {
    paid_to: '',
    advance_purpose_enum: '',
    paid_on: '',
    remarks: '',
  };

  useEffect(() => {
    if (invoiceDetails?.workflow_remarks) {
      setRemark(invoiceDetails?.workflow_remarks);
    }
  }, [invoiceDetails?.workflow_remarks]);

  const handleSubmit = async (values, formikBag) => {
    const {
      payment_due_date,
      advance_purpose_enum,
      advance_purpose_entity_id,
      paid_by_person_company_affiliate_id,
      paid_by_person_company_affiliate_enum,
      paid_to_person_company_affiliate_id,
      paid_to_person_company_affiliate_enum,
    } = values;
    try {
      const params = {
        paid_by_person_company_affiliate_id,
        paid_by_person_company_affiliate_enum,
        paid_to_person_company_affiliate_id,
        paid_to_person_company_affiliate_enum,
        purposes: [
          {
            advance_purpose_enum,
            advance_purpose_entity_id,
          },
        ],
        amount,
        tds: tds ? parseFloat(tds) : 0,
        payment_due_date: dayjs(payment_due_date).format('YYYY-MM-DD'),
        remarks: remark,
      };
      const resData = await dispatch(dealInvoicesActions.createAdvance(params));

      if (!_.isEmpty(resData)) {
        setTimeStamp(Date.now());
        toggle();
        notifications.show({
          type: 'success',
          message: 'success',
          key: 'bids-form',
          duration: null,
        });
      }
    } catch (err) {
      console.error(err);

      let errors = _.get(err, 'errors') || {};
      let errMessage = _.get(err, 'message') || 'Unable to process request.';

      if (!_.isEmpty(errors)) {
        formikBag.setErrors({
          ...errors,
        });

        errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
      }

      notifications.show({
        type: 'error',
        message: errMessage,
        key: 'bids-form',
        duration: null,
      });
    }
  };

  const encodeBack = utils.encodeBackUrl();
  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          const {
            payment_due_date,
            advance_purpose_enum,
            advance_purpose_entity_id,
            paid_by_person_company_affiliate_id,
            paid_by_person_company_affiliate_enum,
            paid_to_person_company_affiliate_id,
            paid_to_person_company_affiliate_enum,
          } = values;
          return (
            <Form className="formik-form">
              <div className="modal__body counter-modal__body" style={{ height: 'auto' }}>
                <div className="container-fluid">
                  <div className="row mt-10">
                    <div className="col-md-12">
                      <>
                        <div className="">
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1">Payer</span>
                              <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              <div className="form-group-field">
                                <Field
                                  name="entity_id"
                                  component={CompanySelect}
                                  placeholder="Select Company"
                                  initialDisplayText={paid_by_person_company_affiliate_id}
                                  cb={async (newVal) => {
                                    let entity_id = _.get(newVal, 'value.id') || 0;
                                    let person_company_affiliate_enum =
                                      _.get(newVal, 'value.person_company_affiliate_enum') || 0;
                                    setFieldValue('paid_by_person_company_affiliate_id', entity_id);
                                    setFieldValue(
                                      'paid_by_person_company_affiliate_enum',
                                      person_company_affiliate_enum,
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">
                                Pay To <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <MUIAutocomplete
                                type="search"
                                selectedCodeOrId={paid_to_person_company_affiliate_id}
                                dataStructure={{
                                  labelApiParam: 'vendor_code',
                                  valueApiParam: 'vendor_id',
                                }}
                                apiParams={{
                                  method: 'POST',
                                  reqUrl: 'BioFuelCommonUtilities/api/Configuration/FetchAllVendors',
                                  getReqParams: (value) => ({
                                    filters: { vendor_code: value, is_invoked_from_manual_invoice: 'true' },
                                    offset: 0,
                                    page_size: 50,
                                  }),
                                }}
                                onChange={(value) => {
                                  setFieldValue('paid_to_person_company_affiliate_id', value?.vendor_id);
                                  setFieldValue(
                                    'paid_to_person_company_affiliate_enum',
                                    value?.person_company_affiliate_enum,
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">
                                Category (Purpose){' '}
                                <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <MUIAutocomplete
                                selectedCodeOrId={advance_purpose_enum}
                                placeholder="Please select reason"
                                apiParams={{
                                  reqUrl: `BioFuelRegistration/api/ReferenceData/GetEnumerator`,
                                  method: 'GET',
                                  isQueryParams: true,
                                  getReqParams: (value) => ({ Enum_Group: 'AdvancePurposeEnum' }),
                                }}
                                data={[]}
                                dataStructure={{
                                  labelApiParam: 'display_member',
                                  valueApiParam: 'value_member',
                                }}
                                onChange={(value) => {
                                  setFieldValue('advance_purpose_enum', value?.value_member);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">
                                Deal/Contract Ref{' '}
                                <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <MUIAutocomplete
                                selectedCodeOrId={advance_purpose_entity_id}
                                placeholder="Please select reason"
                                areRequiredApiParamsPresent={
                                  advance_purpose_enum &&
                                  paid_by_person_company_affiliate_id &&
                                  paid_by_person_company_affiliate_enum &&
                                  paid_to_person_company_affiliate_id &&
                                  paid_to_person_company_affiliate_enum
                                }
                                dependentApiParams={advance_purpose_enum}
                                apiParams={{
                                  reqUrl: `BioFuelPayments/api/Advance/GetPurposeEntities`,
                                  method: 'POST',
                                  isQueryParams: false,
                                  getReqParams: (value) => ({
                                    advance_purpose_enum,
                                    paid_by_person_company_affiliate_id,
                                    paid_by_person_company_affiliate_enum,
                                    paid_to_person_company_affiliate_id,
                                    paid_to_person_company_affiliate_enum,
                                  }),
                                }}
                                data={[]}
                                dataStructure={{
                                  labelApiParam: 'entity_code',
                                  valueApiParam: 'entity_id',
                                }}
                                onChange={(value) => {
                                  setFieldValue('advance_purpose_entity_id', value?.entity_id);
                                }}
                              />
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">
                                Amount <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType="price"
                                  EndIcon={false}
                                  value={amount}
                                  onChange={(newValue) => {
                                    setAmount(newValue);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">TDS</span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType="price"
                                  EndIcon={false}
                                  value={tds}
                                  onChange={(newValue) => {
                                    setTds(newValue);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">
                                Pay on Date <span style={{ color: 'red', transform: 'translate(2px, 0px)' }}>*</span>
                              </span>
                              <MUIDatePicker
                                value={payment_due_date}
                                onChange={(value) => {
                                  setFieldValue('payment_due_date', value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label mb-1 d-flex">Remarks</span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType="textarea"
                                  value={remark}
                                  onChange={(newValue) => {
                                    setRemark(newValue);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal__footer" style={{ marginTop: '10px', marginBottonm: '10px', display: 'block' }}>
                <div className="d-flex justify-content-center">
                  <MUIButton size="large" buttonStyle="rounderCorners" variant="outlined" onClick={() => toggle()}>
                    Cancel
                  </MUIButton>
                  <MUIButton
                    size="large"
                    buttonStyle="rounderCorners"
                    className="ml-3"
                    onClick={() => handleSubmit(values, formikBag)}
                    disabled={formikBag.isSubmitting}>
                    Submit
                  </MUIButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default AddAdvancePopUp;
