import React from 'react';
// import { Field, reduxForm } from 'redux-form';
import { Alert, Button } from 'reactstrap';
import OtpInput from 'react-otp-input';
import Timer from './Timer';
import validate from 'lib/validations/otp';
import { Formik, withFormik, Field, Form } from 'formik';
import * as handlers from './../handlers';

const renderOTPField = (props) => {
  const {
    field,
    form: { touched, errors, values},
    formikBag,
    ...rest
  } = props;

  let errClass = errors[field.name] ? 'bfc-has-error' : '';

  return (
    <div className={errClass}>
      <OtpInput
        value={field.value}
        // // onChange={this.onOtpChange}

        onChange={(otp) => {
          props.form.setFieldValue('otp', otp);
          // field.onChange(otp);
          if (otp?.length === 6) {
            handlers.handleOTPSubmit({ ...values, otp }, formikBag)
          }
        }}
        numInputs={6}
        // separator={<span>-</span>}
        isInputNum={true}
        inputStyle="bfc-otp-input input-without-border-radius"
        containerStyle="bfc-otp-container form__form-group-field justify-content-between"
        shouldAutoFocus={true}
      />
      {touched[field.name] && errors[field.name] && (
        <span className="form__form-group-error otp-err-text">{errors[field.name]}</span>
      )}
    </div>
  );
};

const OtpForm = (props) => {
  return (
    <Formik
      initialValues={{
        user_name: _.get(props, 'user.user_name') || '',
        otp: '',
      }}
      onSubmit={handlers.handleOTPSubmit}>
      {(formikBag) => {
        return (
          <Form className="form bfc-otp-form material-form">
            <div className="form__form-group">
              <span className="form__form-group-label text-center bfc-center">Enter 6-digit Verification Code sent via SMS / Email </span>
              <Field name="otp" component={renderOTPField} formikBag={formikBag} />
              <span className="text-right mr-30" style={{ display: 'block' }}>Valid for 10 mins</span>
            </div>

            <div className="bfc-center bfc-full-width">
              <Button disabled={formikBag.isSubmitting} type="submit" color="primary" size="sm">
                {formikBag.isSubmitting && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}{' '}
                Verify Code
              </Button>
            </div>
            <Timer initialSeconds={30} params={formikBag.values} ctx="login" />
          </Form>
        );
      }}
    </Formik>
  );
};

export default OtpForm;
