import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/FetchAllDiscountPlans`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDiscountPlans');
    throw errors;
  }
};

export const fetchListSchemes = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchListSchemes(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/FetchAllDiscountSchemesByPlan`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListSchemes(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDiscountSchemesByPlan');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/SaveUpdateDiscountPlans`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDiscountPlans');
    throw errors;
  }
};

export const createScheme = async (params) => {
  try {
    let bodyParams = reqTransform.createScheme(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/SaveUpdateDiscountScheme`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createScheme(resData);
  } catch (err) {
    console.log('SaveUpdateDiscountScheme->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDiscountScheme');
    throw errors;
  }
};

export const updateScheme = async (params) => {
  try {
    let bodyParams = reqTransform.updateScheme(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/SaveUpdateDiscountScheme`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createScheme(resData);
  } catch (err) {
    console.log('SaveUpdateDiscountScheme->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDiscountScheme');
    throw errors;
  }
};

export const update = async (params) => {
  try {
    let bodyParams = reqTransform.update(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/SaveUpdateDiscountPlans`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateDiscountPlans');
    throw errors;
  }
};

export const destroyPlan = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/DeleteDiscountPlanAgainstId?discount_plan_master_id=${qs}`;
    // const res = await utils.fetch(url).ready;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteDiscountPlanAgainstId');
    throw errors;
  }
};

export const destroyScheme = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/DeleteDiscountSchemeAgainstId?discount_scheme_detail_id=${qs}`;
    // const res = await utils.fetch(url).ready;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteDiscountSchemeAgainstId');
    throw errors;
  }
};

export const fetchListClaims = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchListClaim(params);
    const url = `${baseUrl}/BioFuelRegistration/api/DiscountScheme/FetchAllDiscountClaimsByScheme`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListClaim(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDiscountClaimsByScheme');
    throw errors;
  }
};
