import {
  createAction,
  // createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { userService, authService } from 'services';
import { actions as notificationsTopbarActions } from 'redux/notificationsTopbar';
import { actions as socialProfileActions } from 'redux/socialProfiles';

const slice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    busy: false,
  },
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    updateUser(state, action) {
      if (action.payload.flush) {
        state = action.payload;
        if (!state) {
          // debugger;
        }
        return state;
      }
      const userInfo = {
        ...state,
        ...action.payload,
      };

      userInfo['person_id'] = parseInt(userInfo['person_id']);
      userInfo['userid'] = userInfo['person_id']; // @TODO: remove later
      userInfo['id'] = userInfo['person_id'];
      // storage.setItem('pid', userInfo['person_id']).then((result) => {
      // });
      // let deleteArr = ['client_machine_name', 'client_machine_ip', 'login_user_id'];

      // for (let fld of deleteArr) {
      //   delete userInfo[fld];
      //   delete userInfo[utils.lowerStr(fld)];
      // }

      globalThis.user = userInfo;

      state = userInfo;
      if (!state) {
        // debugger;
      }

      return state;
    },
    logout(state, action) {
      storage.removeItem('accessToken');
      storage.removeItem('refreshToken');
      storage.removeItem('pid');
      storage.removeItem('sid');
      state = {
        isAuthenticated: false,
        busy: false,
      };

      if (typeof window !== 'undefined') {
        window.location.href = '/account/login';
      }

      return state;
    },
    logoutIprofit(state, action) {
      storage.removeItem('accessToken');
      storage.removeItem('refreshToken');
      storage.removeItem('pid');
      storage.removeItem('sid');
      state = {
        isAuthenticated: false,
        busy: false,
      };
      return state;
    },
  },
  extraReducers: {
    'persist/REHYDRATE': (state, action) => {
      globalThis.user = _.get(action, 'payload.user', {}) || {};
    },
  },
});

const fetchSocialProfile = (sid) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await userService.fetchSocialProfile(sid);
    const updates = {
      socialProfile: {
        ...resData[0],
        updatedAt: Date.now(),
      },
      // profileUpdatedAt: Date.now(),
    };
    const notificationCount = _.get(resData, '[0].unread_messages_count') || 0;
    dispatch(notificationsTopbarActions.updateNotificationCount(notificationCount));
    dispatch(slice.actions.updateUser(updates));
    dispatch(socialProfileActions.storeProfile(resData[0]));
  } catch (err) {
    console.log('fetchUserProfile-->Err', err);
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const fetchPersonalDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await userService.fetchPersonalDetails(params);
    const updates = {
      profile: {
        ...resData,
        profileLoaded: true,
        updatedAt: Date.now(),
      },
    };
    dispatch(slice.actions.updateUser(updates));
  } catch (err) {
    console.log('fetchPersonalDetails-->Err', err);
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const logout = () => async (dispatch) => {
  dispatch(slice.actions.logout());
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await userService.logout();
    if (resData) {
      dispatch(slice.actions.logout());
      await authService.logout();
    }
  } catch (err) {
    console.log('logout-->Err', err);
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const logoutIprofit = (iProfitUrl) => async (dispatch) => {
  dispatch(slice.actions.logoutIprofit());
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await userService.logoutIprofit(iProfitUrl);
    // if (resData ) {
    //   dispatch(slice.actions.logoutIprofit());
    //   await authService.logout();
    // }
  } catch (err) {
    console.log('logout-->Err', err);
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const actions = {
  ...slice.actions,
  fetchSocialProfile,
  fetchPersonalDetails,
  logout,
  logoutIprofit,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
