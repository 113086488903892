import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const UserIdentitySchema = yup.object().shape({
  // identity_type_enum: yup.number().required(),
  // identity_doc_number: yup.string().required(),
  // name_as_per_identity: yup.string().required(),
  // same_as_registered_address: yup.boolean().required(),
  // address_line_1: yup.string().required(),
  // address_line_2: yup.string().required(),
  // address_line_3: yup.string().required(),
  // // village_id: yup.string(),
  // pin_code: yup.string().required(),
  // state_id: yup.number(),
});

export const upload = async (params, entity_id, document_module_enum) => {
  try {
    // await helpers.validateSchema(UserIdentitySchema, params);
    const res = await api.upload(params, entity_id, document_module_enum);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
