import _ from 'lodash';
import utils, { getDecryptedUrl } from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchPlanRequirementAgainstEntityId(params = {}) {
    const qsParams = {
      filters: params['filters'],
      entity_id: _.get(window, 'user.profile.person.company_id', 0),
      offset: params['offset'],
      page_size: params['page_size'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      company_affiliate_enum: 1,
    };
    return qsParams;
  },

  fetchPlanOfferAgainstEntityId(params = {}) {
    const qsParams = {
      filters: params['filters'],
      entity_id: _.get(window, 'user.profile.person.company_id', 0),
      offset: params['offset'],
      page_size: params['page_size'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      company_affiliate_enum: 1,
    };
    return qsParams;
  },

  fetchRequirements(params = {}) {
    const qsParams = {
      plan_id: params['id'],
      offset: params['offset'],
      page_size: params['page_size'],
    };
    return utils.getQs(qsParams);
  },

  fetchOffers(params = {}) {
    const qsParams = {
      plan_id: params['id'],
      offset: params['offset'],
      page_size: params['page_size'],
    };
    return utils.getQs(qsParams);
  },

  fetchBalancePosition(params = {}) {
    const qsParams = {
      plan_id: params,
    };
    return utils.getQs(qsParams);
  },

  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let planActualEnum = 1;
    if (params['typeString'] === 'ActualForm') {
      planActualEnum = 2;
    }
    let bodyParams = {
      ...params,
      Is_RFQ: params['is_rfq'] || false,
      plan_actual_enum: planActualEnum,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
    };
    return bodyParams;
  },

  update(params = {}) {
    let planActualEnum = 1;
    if (params['typeString'] === 'ActualForm') {
      planActualEnum = 2;
    }
    let bodyParams = {
      ...params,
      plan_id: params['commodity_req_offer_id'] || 0,
      plan_actual_enum: planActualEnum,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
    };
    return bodyParams;
  },

  destroy(id) {
    return id;
  },

  fetchPlanDetails(params = {}) {
    const qsParams = {
      ...params,
      person_id: _.get(window, 'user.userid') || 0,
    };
    return utils.getQs(qsParams);
  },

  fetchIndentDetails(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  fetchAllIndents(params) {
    const qsParams = {
      ...params,
    };
    return qsParams;
  },

  fetchLinkedBuys(params = {}) {
    const qsParams = {
      ...params,
    };
    return qsParams;
  },

  fetchOffersAgainstIndentBuy(params = {}) {
    const qsParams = {
      ...params,
    };
    return qsParams;
  },

  saveAndUpdatePlateformCharges(params = {}) {
    const qsParams = {
      ...params,
    };
    return qsParams;
  },
  savePlanAsRequirement(params = {}) {
    const isCopy = getDecryptedUrl(window.location.href).split('?')[1].split('=')[0] === 'iscopy';
    const acl = utils.getACL(_.get(window, 'user') || {});
    if (!acl.isCompany) {
      params['entity_id'] = params['person_id'] || params['entity_id'];
    }
    if (isCopy) {
      params['commodity_req_offer_id'] = 0;
    }
    let bodyParams = {
      ...params,
      from_date: dayjs(params['from_date']).format('YYYY-MM-DD'),
      to_date: dayjs(params['to_date']).format('YYYY-MM-DD'),
      plan_actual_enum: 2,
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
      periodicity_enum: parseInt(params['periodicity_enum']),
      commodity_form_id: parseInt(params['commodity_form_id']),
      commodity_residue_id: parseInt(params['commodity_residue_id']),
      person_id: _.get(window, 'user.userid', 0),
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchPlanRequirementAgainstEntityId(resData) {
    let commodityPlanData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(commodityPlanData, 'plan_id');
  },

  fetchPlanOfferAgainstEntityId(resData) {
    let commodityPlanData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(commodityPlanData, 'plan_id');
  },

  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  fetchOffers(resData) {
    let commodityPlanOffer = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchOffersDetails(details);
    });

    return _.keyBy(commodityPlanOffer, 'plan_id');
  },

  fetchOffersDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  fetchRequirements(resData) {
    let commodityPlanRequirements = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchRequirementsDetails(details);
    });

    return _.keyBy(commodityPlanRequirements, 'plan_id');
  },

  fetchRequirementsDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  create(resData) {
    return resData;
  },

  destroy(resData) {
    return resData;
    // return res;
  },

  fetchBalancePosition(resData) {
    return resData;
  },
  fetchPlanDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_req_offer_id') || 0,
    };
    return data;
  },
  savePlanAsRequirement(resData) {
    return resData;
  },
  fetchAllIndents(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.commodity_req_offer_id,
      };
      return newDetails;
    });
    return _.keyBy(data, 'id');
  },
  fetchAllPlateFormMaster(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.platform_master_id,
      };
      return newDetails;
    });
    return _.keyBy(data, 'id');
  },
  fetchIndentDetails(resData) {
    let data = {
      ...resData,
    };
    return data;
  },
  fetchLinkedBuys(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.commodity_req_offer_id,
      };
      return newDetails;
    });
    return _.keyBy(data, 'id');
    // let data = {
    //   ...resData,
    // };
    // return data;
  },
  fetchOffersAgainstIndentBuy(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.commodity_req_offer_response_id,
      };
      return newDetails;
    });
    return _.keyBy(data, 'id');
    // let data = {
    //   ...resData,
    // };
    // return data;
  },
  fetchListSmartBuyerConfigurationReport(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.platform_master_id,
      };
      return newDetails;
    });
    return _.keyBy(data, 'platform_master_id');
  },
};
