import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let kycApprovalData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(kycApprovalData, 'unique_id');
  },
  fetchDetails(resData) {
    let unique_id =
      _.get(resData, 'person_id') +
      '-' +
      (_.get(resData, 'company_id') || 0) +
      '-' +
      (_.get(resData, 'company_affiliate_id') || 0);
    let bodyParams = {
      ...resData,
      unique_id: unique_id,
      id: unique_id,
    };
    delete bodyParams['busines_type_enum'];
    return bodyParams;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
