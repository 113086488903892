import _ from 'lodash';
import * as yup from 'yup';
import { commodityIndentService } from 'services';
import notifications from 'lib/notifications';
import utils from 'lib/utils';

const buyerPlatformChargeSchema = yup.object().shape({
  platform_charge_at_enum: yup.number().required().positive().integer(),
  buyer_company_id: yup.number().required().positive('Buyer Company is Required'),
});

const sellerPlatformChargeSchema = yup.object().shape({
  platform_charge_at_enum: yup.number().required().positive().integer(),
  seller_company_id: yup.number().required().positive('Seller Company is Required'),
});

export const SaveandUpdatePlateformCharge = async (values, formikBag, loc) => {
  try {
    await utils.validateSchema(
      loc?.includes('smart-buyer-configuration') ? buyerPlatformChargeSchema : sellerPlatformChargeSchema,
      values,
    );
    const res = await commodityIndentService.saveAndUpdatePlateformCharges(values);
    if (res) {
      notifications.show({
        type: 'info',
        message: values?.platform_master_id ? 'Record updated' : 'Record Created',
        key: 'req-form',
      });
    }
    return res;
  } catch (err) {
    console.log('handleCounter--->error---->', err);
    utils.displayErrors(err, formikBag);
    notifications.show({
      type: 'error',
      message: err,
      key: 'req-form',
    });
  }
};
