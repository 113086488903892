import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchQualityList = async (params = {}) => {
  try {
    let res = await api.fetchQualityList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchRatingDetails = async (params = {}) => {
  try {
    let res = await api.fetchRatingDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
