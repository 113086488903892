import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchSubscriptionsHistory(filters, params) {
    let bodyParams = {
      filters: { ...filters },
      ...params,
    };

    return bodyParams;
  },
};

export const resTransform = {
  fetchSubscriptionsHistoryList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchSubscriptionsHistoryDetails(details);
    });
    return _.keyBy(configurationData, 'subscription_id');
  },
  fetchSubscriptionsHistoryDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'subscription_id'),
    };
    return res;
  },
};
