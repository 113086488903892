import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchPassBook(filters, params) {
    let bodyParams = {
      filters: { ...filters },
      ...params,
    };

    return bodyParams;
  },
};

export const resTransform = {
  fetchPassBook(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'serial') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
};
