import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
// import FormikDateTimePickerField from '../../shared/components/form/FormikDateTimePickerField';
import Collapse from './../components/Collapse';
import ReferenceDataSelect from './../components/ReferenceDataSelect';
import VerficationFormModal from './../components/VerficationFormModal';
// import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelect';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as userActions } from 'redux/user';
import { actions as teamsActions } from 'redux/teams';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { actions as rolesActions } from 'redux/roles';
import { actions as companyActions } from 'redux/companies';
import { PROFILE_TABS_ENUM } from 'constants/enums';

import MUITextField from '@material-ui/core/TextField';
import FormikAOISelect from './../components/FormikAOISelect';
import ConfirmDeleteModal from './../components/ConfirmDeleteModal';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import TeamsForm from './TeamsForm';
import PopupForm from 'common/Form/PopupForm';
import { Table } from 'antd';
import AntTableActions from 'common/AntTableActions';
import AntPagination from 'common/AntTablePagination';
import useQuery from 'lib/hooks/useQuery';
import FilterUtils from 'lib/filterUtils';
import BackbuttonIcon from 'assets/images/icons/back-button.svg';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import {  Skeleton } from 'antd';
// import LogoLoader from 'common/LogoLoader';

const Teams = (props) => {
  //optimize this
  const id = props.id;
  const user = useSelector((state) => state.user);
  const { back, page = 1, full_name, designation, mobile_number, email_id, alloted_locations } = useQuery();
  const decodeBackUrl = utils.decodeBackUrl(back);
  const isCompanyAdmin = _.get(user, 'profile.person.is_company_admin') || false;
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const teams = useSelector((state) => state.teams);
  // const teamsList = teams?.entities || [];
  const teamsList = teams?.team || [];
  const busy = teams?.busy;
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  const [deleteTeamMemberId, setDeleteTeamMemberId] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isRerenderRequired, setIsRerenderRequired] = useState(false);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  // let tableData = Object.values(teamsList || {});
  let tableData = teamsList;

  const rolesAndRights = useSelector((state) => state.rolesAndRights);
  const kycUser = useSelector((state) => state.kycUser);
  const qualityLaboratory = _.get(kycUser, 'profile.person.quality_laboratory') || false;
  const rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  const showTeam = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TEAM),
  );
  const readAccess = _.get(showTeam, `read_access`);
  const fullAccess = _.get(showTeam, `full_access`);
  // console.log('teamsDetails', teams);

  const acl = utils.getACL(user);

  const isCompanyUser = acl.isUserCompany;

  let idCompare = parseInt(id);

  let isAdmin;
  let companyId;
  let companies = {};
  if (idCompare !== window.user.userid) {
    isAdmin = true;
    companies = useSelector((state) => state.companies);
    companyId = _.get(kycUser, 'profile.person.company_id') || 0;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
    companies = useSelector((state) => state.companies);
    companyId = _.get(user, 'profile.person.company_id') || 0;
  }
  // debugger;
  let nextLink = `/account/${id}/transport`;
  let prevLink = `/account/${id}/organisations`;
  if (acl.isCompany) {
    nextLink = `/account/${id}/transport`;
    prevLink = `/account/${id}/organisations`;
  }
  if (acl.isQALab) {
    nextLink = `/account/${id}/kyc-b`;
    prevLink = `/account/${id}/organisations`;
  }
  if (isAdmin) {
    nextLink = `/account/${id}/transport?back=${back}`;
    prevLink = `/account/${id}/organisations?back=${back}`;
    if (acl.isCompany) {
      nextLink = `/account/${id}/transport?back=${back}`;
      prevLink = `/account/${id}/organisations?back=${back}`;
    }
    if (qualityLaboratory) {
      nextLink = `/account/${id}/kyc-b?back=${back}`;
      prevLink = `/account/${id}/organisations?back=${back}`;
    }
  }

  tableData = tableData.map((d) => {
    return {
      ...d,
      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/teams/edit/${d['team_member_id']}?page=${page}`),
    };
  });

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDestroy(id);
      if(res) {
        setIsRerenderRequired(!isRerenderRequired)
      }
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(pathname + '?' + `page=${page}&back=${back}`);
    } else {
      history.push(pathname + '?' + `page=${page}`);
    }
  };

  const TEAMS_TABLE_HEADER = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      width: '200px',
      align: 'center',
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}{' '}
        </span>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'full_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={full_name}
          name={'Name'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'full_name'),
      filteredValue: full_name !== undefined ? full_name : null,
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'designation'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={designation}
          name={'Designation'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'designation'),
      filteredValue: designation !== undefined ? designation : null,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'mobile_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={mobile_number}
          name={'Mobile Number'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'mobile_number'),
      filteredValue: mobile_number !== undefined ? mobile_number : null,
    },
    {
      title: 'Email ID',
      dataIndex: 'email_id',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'email_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={email_id}
          name={'Email ID'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'email_id'),
      filteredValue: email_id !== undefined ? email_id : null,
    },
    {
      title: 'Roles',
      dataIndex: 'person_role',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value?.map((item) => {
            return (
              <div className="d-flex" style={{ width: 'max-content' }}>
                {'\u25CF'} {item.role_code}{' '}
              </div>
            );
          }),
          props: {},
        };

        return obj;
      },
    },
    {
      title: 'Locations',
      dataIndex: 'alloted_locations',
      width: '200px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: value?.map((item) => {
            return (
              <div className="d-flex">
                {'\u25CF'} {item.company_address_code}{' '}
              </div>
            );
          }),
          props: {},
        };

        return obj;
      },
      // filterDropdown: (props) => (
      //   <TextSearchFilter
      //     dataIndex={'alloted_locations'}
      //     data={props}
      //     onFilterChange={onFilterChange}
      //     handleReset={handleReset}
      //     value={alloted_locations}
      //     name={'Locations'}
      //   />
      // ),
      // filterIcon: (filtered) => getSearchIcon(filtered, 'alloted_locations'),
      // filteredValue: alloted_locations !== undefined ? alloted_locations : null,
    },

    {
      title: 'Actions',
      dataIndex: 'address_text_',
      width: '120px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.team_member_id}
              name={row.first_name}
              cancel={cancel}
              showFlag={!isAdmin && fullAccess}
            />
          ),
          props: {},
        };
        if (index == 0) {
          obj.props.rowSpan = row.rowSpan;
        } else {
          obj.props.rowSpan = row.rowSpan;
        }
        return obj;
      },
    },
  ];

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/teams/create`));
  const editMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/teams/edit/:id`));

  useEffect(() => {
    // if (!editMatch) {
      let params = {
        // entity_id: id,
        filters,
        page_size: rowsPerPage,
        offset: (parseInt(page) - 1) * rowsPerPage,
        company_id:parseInt(companyId)
      };
      // dispatch(teamsActions.fetchList(params));
      dispatch(teamsActions.fetchTeamMembersFromOrganization(params));
    // }
  }, [rowsPerPage, page, full_name, designation, mobile_number, email_id, alloted_locations, isRerenderRequired]);


  // useEffect(() => {
  //   // if (!editMatch) {
  //   let params = {
  //     // entity_id: id,
  //     filters,
  //     page_size: rowsPerPage,
  //     offset: (parseInt(page) - 1) * rowsPerPage,
  //   };
  //   // dispatch(teamsActions.fetchList(params));
  //   dispatch(teamsActions.fetchTeamMembersFromOrganization(params));
  //   // }

  //   // }
  // }, [teams?.dirty]);

  const roles = useSelector((state) => state.roles);

  useEffect(() => {
    dispatch(rolesActions.fetchList());
  }, []);

  let team_Member_Id = null;
  if (createMatch) {
    team_Member_Id = 0;
  } else if (editMatch) {
    team_Member_Id = _.get(editMatch, 'params.id') || 0;
    team_Member_Id = decryptionInHex(team_Member_Id) ? decryptionInHex(team_Member_Id) : team_Member_Id;
  }

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="bfc-body bfc-form profile-height-body">
      {/* {busy && (
        <div className="commodity-wrapper">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      )} */}
      <React.Fragment>
        <div className="form-container">
          <div className="farm-wrapper">
            <div className="farm-table">
              <div className="table-farms-wrapper">
                {!isControlTowerUser &&
                  <div>
                    {back ? (
                      <Link to={decodeBackUrl}>
                        <img src={BackbuttonIcon} alt={BackbuttonIcon} title="back to the page" />
                      </Link>
                    ) :
                      null
                    }
                  </div>
                }
                {fullAccess && (
                  <div className="addnew-row add-new-btn-box float-right">
                    <Button
                      onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/teams/create`))}
                      type="button"
                      className="add-new-btn"
                      disabled={isAdmin}
                      size="sm">
                      <span className="icon">+</span>
                      <span className="text">Add Team Member</span>
                    </Button>
                  </div>
                )}
                <Table
                  ref={tableRef}
                  // columns={TEAMS_TABLE_HEADER}
                  // dataSource={tableData}
                  dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                  columns={busy ? TEAMS_TABLE_HEADER.map((column) => {
                    return {
                      ...column,
                      render: function renderPlaceholder() {
                        return (
                          <Skeleton
                            active
                            key={column.dataIndex}
                            title={true}
                            paragraph={false}
                          />
                        );
                      },
                    };
                }) : TEAMS_TABLE_HEADER}
                  bordered
                  size="small"
                  pagination={false}
                  style={{ whiteSpace: 'pre' }}
                />
                <AntPagination
                  total={parseInt(totalRecords)}
                  page={parseInt(page)}
                  handlePageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  clearFilters={clearFilters}
                  setScrollToFirstRow={setScrollToFirstRow}
                />
              </div>
            </div>

            <div className="btn-wrapper justify-content-space-between">
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} className="link">
                  Prev
                </NavLink>
              </div>
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} className="link">
                  Next
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        {editMatch || createMatch ? (
          <div>
            <PopupForm title={'Team'} onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/teams?page=${page}`))}>
              <TeamsForm team_member_id={team_Member_Id} id={id} setIsRerenderRequired={setIsRerenderRequired} isRerenderRequired={isRerenderRequired} page={page} />
            </PopupForm>
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default Teams;
