import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const FarmSchema = yup.object().shape({
  name_of_farm: yup.string().required('Name of farm is a required field'),
  size_of_farm: yup.string().required('Size of farm is a required field'),
  // location_id: yup
  //   .number()
  //   .positive('Location is a required field')
  //   .typeError('Location is a required field')
  //   .required('Location is a required field'),
  // village_id: yup.number().positive().required(),
  // taluka_id: yup.number().positive().required(),
  // city_id: yup.number().positive().required(),
  // district_id: yup.number().positive().required(),
  // state_id: yup.number().positive().required(),
  // country_id: yup.number().positive().required(),
  address_line1: yup.string().required('Address Line 1 is a required field'),
  pin_code: yup.string().required('Pin Code is a required field'),
  location_code: yup.string().required('Location is a required field'),
  // address_line1: yup.string('Address Line 1 is required.').when('location_id', (location_id, schema) => {
  //   if (!location_id) {
  //     return schema.required();
  //   }

  //   return schema.optional();
  // }),
  crops: yup.array().of(
    yup.object().shape({
      farm_detail_id: yup.number().required(),
      crop_id: yup.number().positive('Crop is a required field').typeError('Crop is a required field').required(),
      crop_area: yup.number().positive().typeError('Size of farm is a required field').required(),
      harvest_date: yup.string().required('Harvest date a required field'),
    }),
  ),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(FarmSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(FarmSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
