import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Vehicle
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchAllQualityRecordMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllVehiclesForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllQualityRecordMaster');
    throw errors;
  }
};

export const fetchQualityServiceListCt = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchAllQualityRecordMaster_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllVehiclesForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllQualityRecordMaster_CT');
    throw errors;
  }
};

export const fetchDetails = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchAllQualityRecordMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllQualityRecordMaster-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllQualityRecordMaster');
    throw errors;
  }
};

export const fetchQualityServiceDetails = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchAllQualityRecordMaster_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllQualityRecordMaster-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllQualityRecordMaster_CT');
    throw errors;
  }
};

export const FetchSharedQualityReports = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchSharedQualityReports`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchSharedQualityReports-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchSharedQualityReports');
    throw errors;
  }
};

//Add Vehicle
export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/SaveAndUpdateQualityRecordMaster`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateQualityRecordMaster');
    throw errors;
  }
};

//Update Vehicle
export const update = async (params) => {
  try {
    let bodyParams = reqTransform.update(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/SaveAndUpdateQualityRecordMaster`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateQualityRecordMaster');
    throw errors;
  }
};

//Delete Vehicle
export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/DeleteQualityRecordMasterAgainstId?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('DeleteVehicleAgainstTransporter->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteQualityRecordMasterAgainstId');
    throw errors;
  }
};

export const handleQualityParameterDestroy = async (id) => {
  try {
    const qs = reqTransform.handleQualityParameterDestroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/DeleteQualityRecordDetailAgainstId?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.handleQualityParameterDestroy(resData);
  } catch (err) {
    console.log('DeleteVehicleAgainstTransporter->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteQualityRecordDetailAgainstId');
    throw errors;
  }
};

export const fetchLabData = async (params) => {
  try {
    let bodyParams = reqTransform.fetchLabData(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchQualityLabGarphData`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchLabData(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchQualityLabGarphData');
    throw errors;
  }
};

export const fetchObservedLabData = async (params) => {
  try {
    let bodyParams = reqTransform.fetchObservedLabData(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchQualityObservedGraphData`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchObservedLabData(resData);
  } catch (err) {
    console.log('SaveUpdateVehicleAgainstTransporter-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchQualityObservedGraphData');
    throw errors;
  }
};

export const handleQualityUploadDestroy = async (id) => {
  try {
    const qs = reqTransform.handleQualityUploadDestroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/DeleteUploadedFileAgainstId?${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.handleQualityUploadDestroy(resData);
  } catch (err) {
    console.log('DeleteVehicleAgainstTransporter->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteUploadedFileAgainstId');
    throw errors;
  }
};

export const fetchAllFTKMachineDetails = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/FetchAllFTKMachineDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ ...item, id: item?.bf_setup_ftk_machine_id }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllFTKMachineDetails');
    throw errors;
  }
};

export const saveAndUpdateFTKMachine = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/SaveAndUpdateFTKMachine`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateFTKMachine');
    throw errors;
  }
};

export const deleteFTKMachine = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/DeleteFTKMachine`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'DeleteFTKMachine');
    throw errors;
  }
};

export const saveAndUpdateFTKMachineAllotment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/SaveAndUpdateFTKMachineAllotment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateFTKMachineAllotment');
    throw errors;
  }
};

export const fetchAllFTKMachineAllotment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/FetchAllFTKMachineAllotment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ ...item, id: item?.bf_setup_ftk_machine_allotment_id }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllFTKMachineAllotment');
    throw errors;
  }
};

export const saveAndUpdateFTKResult = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/SaveAndUpdateFTKResult`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateFTKResult');
    throw errors;
  }
};

export const fetchAllFTKResult = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/FetchAllFTKResult`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ ...item, id: item?.bf_setup_ftk_result_id }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllFTKResult');
    throw errors;
  }
};

export const fetchAllFTKDetailsCombine = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/FieldTestingKit/FetchAllFTKDetailsCombine`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ ...item, id: item?.bf_setup_ftk_machine_id }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllFTKDetailsCombine');
    throw errors;
  }
};
