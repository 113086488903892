import _ from 'lodash';
import utils from 'lib/utils';
import { useSelector, useDispatch } from 'react-redux';
import config from 'config';

let documentModuleEnum, companyAffiliateEnum;

export const reqTransform = {
  fetchList(params = {}) {
    const storeState = globalThis.store.getState();
    let userTypeEnum;

    let entity_id = params['entity_id'];
    if (params['entity_id'] != _.get(window, 'user.userid', 0)) {
      userTypeEnum = _.get(storeState, 'kycUser.profile.person.user_type_enum') || 0;
      if (userTypeEnum == 2) {
        (entity_id = _.get(storeState, 'kycUser.profile.person.person_id')), (documentModuleEnum = 1);
      } else {
        let company_affiliate = params['company_affiliate_enum'];
        if (company_affiliate == 1) {
          (entity_id = params['entity_id']), (companyAffiliateEnum = 1), (documentModuleEnum = 2);
        } else {
          (entity_id = params['entity_id']), (companyAffiliateEnum = 2), (documentModuleEnum = 3);
        }
      }
    } else {
      userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum') || 0;
      if (userTypeEnum == 2) {
        (entity_id = _.get(storeState, 'user.profile.person.person_id')), (documentModuleEnum = 1);
      } else {
        let company_affiliate = params['company_affiliate_enum'];
        if (company_affiliate == 1) {
          (entity_id = params['entity_id']), (companyAffiliateEnum = 1), (documentModuleEnum = 2);
        } else {
          (entity_id = params['entity_id']), (companyAffiliateEnum = 2), (documentModuleEnum = 3);
        }
      }
    }

    const qsParams = {
      entity_primary_id: entity_id,
      document_module_enum: documentModuleEnum,
      company_affiliate_enum: params['company_affiliate_enum'],
    };

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let bodyParams = {
      ...params,
      person_kyc_id: 0,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      state_id: parseInt(params['state_id']),
    };
    return bodyParams;
  },

  upload(params, entity_id) {
    const storeState = globalThis.store.getState();

    const userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum');

    if (userTypeEnum == 2) {
      (entity_id = _.get(window, 'user.profile.person.person_id')),
        (companyAffiliateEnum = 1),
        (documentModuleEnum = 1);
    } else {
      const company_affiliate = storeState.kycBusinessCompany.entities[entity_id].company_affiliate_enum;
      if (company_affiliate == 1) {
        (entity_id = entity_id), (companyAffiliateEnum = 1), (documentModuleEnum = 2);
      } else {
        (entity_id = entity_id), (companyAffiliateEnum = 2), (documentModuleEnum = 3);
      }
    }

    let bodyParams = {
      ...params,
      entity_id: entity_id,
      user_type_enum: userTypeEnum,
      company_affiliate_enum: companyAffiliateEnum,
      document_module_enum: documentModuleEnum,
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_kyc_id: id,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      state_id: parseInt(params['state_id']),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  handleQualityLabUpload(params, entity_id) {
    const storeState = globalThis.store.getState();

    const userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum');

    // if (userTypeEnum == 2) {
    //   (entity_id = _.get(window, 'user.profile.person.person_id')),
    //     (companyAffiliateEnum = 1),
    //     (documentModuleEnum = 1);
    // } else {
    //   const company_affiliate = storeState.kycBusinessCompany.entities[entity_id].company_affiliate_enum;
    //   if (company_affiliate == 1) {
    //     (entity_id = entity_id), (companyAffiliateEnum = 1), (documentModuleEnum = 2);
    //   } else {
    //     (entity_id = entity_id), (companyAffiliateEnum = 2), (documentModuleEnum = 3);
    //   }
    // }

    let bodyParams = {
      ...params,
      entity_id: entity_id,
      user_type_enum: 1,
      company_affiliate_enum: companyAffiliateEnum,
      document_module_enum: documentModuleEnum,
    };
    return bodyParams;
  },

  fetchQualityLabList(qualityId = 0) {
    const qsParams = {
      entity_primary_id: qualityId,
      entity_type_enum: 'QL_TestPictures',
      // document_module_enum: documentModuleEnum,
      // company_affiliate_enum: params['company_affiliate_enum'],
    };

    return utils.getQs(qsParams);
  },
  fetchExtendedProfilePhotosList(qualityId = 0) {
    const qsParams = {
      entity_primary_id: qualityId,
      // entity_type_enum: null,
      document_module_enum: 'ExtendedProfileDocs',
      // company_affiliate_enum: params['company_affiliate_enum'],
    };

    return utils.getQs(qsParams);
  },
  fetchManualInvoiceDocumentList(params) {
    const qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(uploadData, 'attachment_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  fetchQualityLabList(resData) {
    // let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
    //   return resTransform.fetchDetails(details);
    // });

    // return _.keyBy(uploadData, 'attachment_id');
    let uploadData = [{ id: 0, data: resData }];
    return _.keyBy(uploadData, 'id');
  },

  fetchExtendedProfilePhotosList(resData) {
    // let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
    //   return resTransform.fetchDetails(details);
    // });

    // return _.keyBy(uploadData, 'attachment_id');
    let uploadData = [{ id: 0, data: Object.values(resData?.attachments) }];
    return _.keyBy(uploadData, 'id');
  },

  fetchManualInvoiceDocumentList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchManualInvoiceDocumentDetails(details);
    });

    return _.keyBy(uploadData, 'attachment_id');
  },
  fetchManualInvoiceDocumentDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'attachment_id') || 0,
    };
    return res;
  },
};
