import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ControlTower/FetchKycStatusDetails_Individual`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchKycStatusDetails_Individual');
    throw errors;
  }
};

export const fetchListCT = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchListCT(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_CT_ELOC_Lite`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListCT(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveries_CT_ELOC_Lite');
    throw errors;
  }
};

export const revertDeliveries = async (params = {}) => {
  try {
    const qsParams = reqTransform.revertDeliveriesQueryParams(params);
    let bodyParams = reqTransform.revertDeliveries(params.deliveriesToRevert);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/RevertDeliveryToStage?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.revertDeliveries(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RevertDeliveryToStage');
    throw errors;
  }
};

export const updateUnconfirmedDeliverySchedule = async (params = {}) => {
  try {
    let bodyParams = params;
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateUnconfirmedDeliverySchedule`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateUnconfirmedDeliverySchedule(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'MoveDeliveryDate');
    throw errors;
  }
};

export const RejectDelivery_CT = async (params = {}, id) => {
  try {
    const qsParams = utils.getQs(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/RejectDelivery_CT?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([id]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RejectDelivery_CT');
    throw errors;
  }
};

export const fetchInvoiceListCT = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchInvoiceListCT(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchAllInvoices_CT_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchInvoiceListCT(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoices_CT_ELOC');
    throw errors;
  }
};

export const FetchPlatformLedger = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelWebHookReceiver/api/PlatformLedger/FetchPlatformLedger`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.bf_platform_ledger_id }));
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchPlatformLedger');
    throw errors;
  }
};

export const FetchPlatformLedgerDetails = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelWebHookReceiver/api/PlatformLedger/FetchPlatformLedger`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData?.map((item) => ({ ...item, id: item.bf_platform_ledger_id }));
    resData = _.keyBy(resData, 'id');
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchPlatformLedger');
    throw errors;
  }
};

export const recordUnallocatedPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/RecordUnallocatedPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RecordUnallocatedPayment');
    throw errors;
  }
};

export const deleteUnallocatedPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/DeleteUnallocatedPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteUnallocatedPayment');
    throw errors;
  }
};

export const fetchPlatformLedgerMetadata = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelWebHookReceiver/api/PlatformLedger/FetchPlatformLedgerMetadata`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchPlatformLedgerMetadata');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateAffiliateDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateAffiliateDetails');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateAffiliateDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateAffiliateDetails');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteAffiliateAgainstComapnyAffiliateId?company_affiliate_id=${qs}`;
    // const res = await utils.fetch(url).ready;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAffiliateAgainstComapnyAffiliateId');
    throw errors;
  }
};
export const linkDealsOrDeliveries = async (params = {}) => {
  try {
    let url;
    if (params['company_id']) {
      url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/LinkDealsOrDeliveries?deal_delivery_enum=${params['deal_delivery_enum']}&Deal_Delivery_Id=${params['deal_delivery_id']}&company_id=${params['company_id']}`;
    } else {
      url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/LinkDealsOrDeliveries?deal_delivery_enum=${params['deal_delivery_enum']}&Deal_Delivery_Id=${params['deal_delivery_id']}`;
    }
    const res = await utils.fetch(url, {
      method: 'POST',
      // body: JSON.stringify(params['to_deal_delivery_id']?.map((item) => parseInt(item))),
      body: JSON.stringify({
        id_Qty: [
          {
            to_Deal_Delivery_Id: parseInt(params['to_deal_delivery_id']),
            quantity: parseInt(params['deal_delivery_qty']),
            enable_full_replication: params['enable_full_replication'],
          },
        ],
      }),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
    // return resTransform.linkDealsOrDeliveries(resData);
  } catch (err) {
    console.log('LinkDealsOrDeliveries-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'LinkDealsOrDeliveries');
    throw errors;
  }
};

export const deleteDealsOrDeliveriesFromLink = async (params) => {
  try {
    let qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/DeleteDealsOrDeliveriesFromLink?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      // body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteDealsOrDeliveriesFromLink');
    throw errors;
  }
};

export const getLinkedDealDelivery = async (params) => {
  try {
    let qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/CommonUtilities/GetLinkedDealDelivery?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetLinkedDealDelivery');
    throw errors;
  }
};
