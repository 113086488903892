import _ from 'lodash';
import utils, { getDecryptedUrl } from 'lib/utils';
import config from 'config';

import normalize from 'normalize-object';

let loc = getDecryptedUrl(window.location.href);

export const reqTransform = {
  fetchTopBarLocationList(params = {}) {
    let qsParams = {};
    qsParams = {
      ...params,
      Is_Invoked_By_Social_Feed: 'true',
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
};

export const resTransform = {
  fetchTopBarLocationList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    // return loactionData;
    return _.keyBy(loactionData, 'company_address_id');
  },
  fetchDetails(resData) {
    return {
      ...resData,
      location_name: _.get(resData, 'location_name') || _.get(resData, 'address_text') || '',
    };
  },
};
