import _ from 'lodash';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import tripDetailsServices from 'services/tripDetails';
import utils from 'lib/utils';

import {
  fetchList,
  fetchStatusAgainstDeliveryId,
  updateStatusAgainstDeliveryId,
  fetchDetails,
} from 'services/tripDetails/api';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (farm) => _.get(farm, 'farm_address.person_address_id', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) =>
  //   (a.transport_delivery_schedule_alloted_id || '').localeCompare(b.transport_delivery_schedule_alloted_id || ''),
});

const slice = createSlice({
  name: 'tripDetails',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    dirty: true,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setDirty(state, action) {
      let dirty = action.payload;
      if (dirty === false) {
        state.dirty = false;
      } else {
        state.dirty = true;
      }
    },
    setBusy(state, action) {
      state.busy = action.payload;
    },
  },
});

const getDeliveriesList = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
    console.log('getDeliveriesList-->Err', err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getDeliveriesStatus = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await fetchStatusAgainstDeliveryId(params);
    dispatch(slice.actions.upsertOne(resData));
  } catch (err) {
    // dispatch(slice.actions.upsertOne({}));
    utils.displayErrors(err);
    console.log('getDeliveriesStatus-->Err', err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const updateDeliveriesStatus = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await updateStatusAgainstDeliveryId(params);
    dispatch(slice.actions.upsertOne(resData));
  } catch (err) {
    console.log('getDeliveriesStatus-->Err', err);

    // dispatch(slice.actions.upsertOne({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

const fetchDeliveryDetails = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await fetchDetails(params);
    // debugger
    dispatch(slice.actions.upsertOne(resData));
  } catch (err) {
    utils.displayErrors(err);
    console.log('fetchDeliveryDetails-->Err', err);
  }
  dispatch(slice.actions.setDirty(false));
  dispatch(slice.actions.setBusy(false));
};

// const getAllTripDetails = (payload) => {
//   return async (dispatch) => {
//     try {
//       dispatch(slice.actions.setBusy(true));
//       const response = await DeliveryAssignmentServices.FetchAllTripDetails(payload);
//       dispatch(slice.actions.add(response.data));
//     } catch (err) {
//       console.log('getAllTripDetails-->Err', err);
//     } finally {
//       dispatch(slice.actions.setBusy(false));
//     }
//   };
// };

// const getTripDetails = (payload) => {
//   return async (dispatch) => {
//     try {
//       dispatch(slice.actions.setBusy(true));
//       const response = await DeliveryAssignmentServices.FetchTripDetailsAgainstTransportDeliveryScheduledId(payload);
//       dispatch(slice.actions.add(response.data));
//     } catch (err) {
//       console.log('getTripDetails-->Err', err);
//     } finally {
//       dispatch(slice.actions.setBusy(false));
//     }
//   };
// };

const actions = {
  ...slice.actions,
  getDeliveriesList,
  getDeliveriesStatus,
  updateDeliveriesStatus,
  fetchDeliveryDetails,
};

const selectors = {
  ...adapter.getSelectors((state) => state.tripDetails),
};

export { actions, selectors };

export default slice.reducer;
