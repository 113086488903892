import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { subscriptionPassbook } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'subscriptionPassbook',
  initialState: adapter.getInitialState({
    busy: false,
    csvData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setCsvData: (state, { payload }) => ({ ...state, csvData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchPassBook = (filters, params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    // dispatch(slice.actions.removeAll());
    const resData = await subscriptionPassbook.fetchPassBook(filters, params);
    if (params?.isCsv) {
      dispatch(
        slice.actions.setCsvData(
          Object.values(resData)?.map((item) => {
            delete item.id;
            delete item.serial;
            return { ...item };
          }),
        ),
      );
      dispatch(slice.actions.setBusy(false));
      return resData;
    } else {
      dispatch(slice.actions.setAll(resData));
    }
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updatePassbook = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await subscriptionPassbook.updatePassbook(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Passbook updated Successfully.' });
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const transferPassbookAmount = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await subscriptionPassbook.transferPassbookAmount(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Transfer Successful.' });
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchPassBook,
  updatePassbook,
  transferPassbookAmount,
};

const selectors = {
  ...adapter.getSelectors((state) => state.subscriptionPassbook),
};

export { actions, selectors };

export default slice.reducer;
