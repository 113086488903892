import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Table, Tag, Space } from 'antd';
import useQuery from 'lib/hooks/useQuery';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { actions as mySubscription } from 'redux/mySubscriptions';
import { actions as subscriptionHistory } from 'redux/subscriptionHistory';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import FilterUtils from 'lib/filterUtils';
import _ from 'lodash';
import LocationIcon from 'common/Icons/LocationIcon';
import LogoLoader from 'common/LogoLoader';
import MySubscriptionCard from 'common/OverviewMySubscriptionCard';
import AntPagination from 'common/AntTablePagination';
import BackbuttonIcon from 'assets/images/icons/back-button.svg';
import NotFound from 'common/Form/NoDataFound';


// scss
import '../subscriptionStyle.scss';
import 'antd/dist/antd.css';

const MySubscription = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  let { page, back } = useQuery();
  const mySubscriptions = useSelector((state) => state.mySubscriptions);
  const mySubscriptionHistory = useSelector((state) => state.subscriptionHistory);
  const user = useSelector((state) => state.user);
  const entities = _.get(mySubscriptions, 'entities') || {};
  const busy = _.get(mySubscriptions, 'busy') || false;
  const busyTable = _.get(mySubscriptionHistory, 'busy') || false;
  const Entities = _.get(mySubscriptionHistory, 'entities') || {};
  const ids = _.get(mySubscriptions, 'ids') || [];
  const is_subscribed = _.get(user, 'profile.is_subscribed') || false;
  const type_of_individual_enum = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const encodeBackUrl = utils.encodeBackUrl();
  const decodeBackUrl = back !== 'undefined' && utils.decodeBackUrl(back);
  const [showHistory, setShowHistory] = useState(false);
  const Details = _.get(entities, `${ids[0]}`) || {};
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const toggle = () => {
    setShowHistory(!showHistory);
  };
  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${props.id}/subscription`));

  useEffect(() => {
    let params = {};
    if (createMatch) {
      params = { view_subscription_of_person_id: props.id };
    }
    dispatch(mySubscription.fetchMySubscriptions(params));
  }, []);

  useEffect(() => {
    if (showHistory) {
      let filters = {};
      let params = {
        offset: (page - 1) * 10,
        page_size: rowsPerPage,
      };
      if (isControlTowerUser) {
        params['view_subscription_of_person_id'] = parseInt(props.id);
      }
      dispatch(subscriptionHistory.fetchSubscriptionsHistory(filters, params));
    }
  }, [page, showHistory, rowsPerPage]);


  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  // const clearFilters = () => {
  //   setFilters({});
  //   let pathname = window.location.pathname;
  //   history.push(pathname + '?' + `page=1`);
  // };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  let tableData = busy ? [] : Object.values(Entities || {});
  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  let data = [];
  _.map(Entities, (entity) => {
    data.push(entity);
  });

  data = _.orderBy(data, ['subscription_id'], ['desc']);

  const columns = [
    {
      title: `${type_of_individual_enum === 1 ? 'Farmer Name' : 'Company Name'}`,
      dataIndex: 'company_affiliate_person_name',
      key: 'company_affiliate_person_name',
      width: '100px',
      align: 'center',
      render: (text, row) => {
        return (
          <div>
            <div className="bfc-table-list-components text-center">
              {row.company_affiliate_person_name ? `${row.company_affiliate_person_name}` : `-`}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      key: 'location_name',
      align: 'center',
      width: '100px',
      render: (text, row) => {
        return (
          <div>
            <div className="bfc-table-list-components ">{row.location_name ? `${row.location_name}` : `-`}</div>
          </div>
        );
      },
    },
    {
      title: 'Pack',
      dataIndex: 'plan_name',
      key: 'plan_name',
      align: 'center',
      width: '70px',
      render: (text, row) => {
        return (
          <div>
            <div className="bfc-table-list-components text-center">{row.plan_name ? `${row.plan_name}` : `-`}</div>
          </div>
        );
      },
    },
    // {
    //   title: 'Statement',
    //   // dataIndex: 'plan_name',
    //   // key: 'plan_name',
    //   align: 'center',
    //   width: '70px',
    //   render: (text, row) => {
    //     const viewLedgerLink = `/subscription/plan-passbook/${row.person_company_affiliate_address_id}` +
    //       `/${row.person_company_affiliate_id}/${row.person_company_affiliate_enum}` +
    //       `?back=${encodeBackUrl}&page=1${row.group_plan_id < 4 ? `&planId=${row.group_plan_id}` : ''}`;
    //     return (
    //       <div className="bfc-table-list-components text-center">
    //         {row.group_plan_id === 1 || row.group_plan_id === 2 || row.group_plan_id === 3 ?
    //           <Link className="cursor-pointer" to={viewLedgerLink}>
    //             <span>View</span>
    //           </Link> : `-`}
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Subscription date',
      dataIndex: 'created_datetime',
      key: 'created_datetime',
      align: 'center',
      width: '70px',
      render: (text, row) => {
        return row.plan_name ? (
          <div>
            <div className="bfc-table-list-components text-center">{utils.formatDate(row?.created_datetime)}</div>
          </div>
        ) : (
          <small>-</small>
        );
      },
    },
    {
      title: 'Status',
      key: 'susbcription_status',
      dataIndex: 'susbcription_status',
      align: 'center',
      width: '70px',
      render: (text, row) => {
        return (
          <span>
            {text == 'Expired' && (
              <Tag color={'red'} key={text}>
                <div className="bfc-table-list-components text-center">{row.susbcription_status.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Initiated' && (
              <Tag color={'blue'} key={text}>
                <div className="bfc-table-list-components text-center">{row.susbcription_status.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Active' && (
              <Tag color={'green'} key={text}>
                <div className="bfc-table-list-components text-center">{row.susbcription_status.toUpperCase()}</div>
              </Tag>
            )}

            {text == 'Failed' && (
              <Tag color={'volcano'} key={text}>
                <div className="bfc-table-list-components text-center">{row.susbcription_status.toUpperCase()}</div>
              </Tag>
            )}
            {text == 'Void' && (
              <Tag color={'cyan'} key={text}>
                <div className="bfc-table-list-components text-center">{row.susbcription_status.toUpperCase()}</div>
              </Tag>
            )}
          </span>
        );
      },
      // render: (tags) => (
      //   <>
      //     {tags.map((tag) => {
      //       let color = tag.length > 5 ? 'geekblue' : 'green';
      //       if (tag === 'loser') {
      //         color = 'volcano';
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
  ];

  if (busy) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  // useEffect(() => {
  //   if (scrollToFirstRow && tableRef.current) {
  //     const firstRow = tableRef.current.querySelector('.ant-table-row');
  //     if (firstRow) {
  //       firstRow.scrollIntoView({ behavior: 'smooth' });
  //     }
  //     setScrollToFirstRow(false);
  //   }
  // }, [scrollToFirstRow]);

  return (
    <div className="mt-20">
      <div className="container">
        <div className="bfc-body" style={{ borderRadius: 5, minHeight: '320px' }}>
          <div className="p-15">
            <div class="d-flex align-items-center" style={{ justifyContent: 'end' }}>
            <div class="flex-grow-1 row align-items-center">
                  <Link to={decodeBackUrl}>
                    <img src={BackbuttonIcon} alt="" />
                  </Link>
                  <h4 className="title mt-5" style={{ color: 'var(--unnamed-color-1b62ab)' }}>
                    My Subscriptions
                  </h4>
                </div>
              <>
                {(!is_subscribed || (type_of_individual_enum === 4 || type_of_individual_enum === 9 || type_of_individual_enum === 10)) && !isControlTowerUser ?
                  < div class="mr-20">
                    <Link to="/subscription-packages" style={{ color: 'var(--unnamed-color-1b62ab)', textDecoration: 'underline' }}>
                      More packages
                    </Link>
                  </div> : null
                }
                <div className="cursor-pointer float-right" onClick={toggle}>
                  <p style={{ color: 'var(--unnamed-color-1b62ab)', textDecoration: 'underline' }}>History</p>
                </div>
              </>
            </div>
            <div className="subscription-cards__box container">
              <Row className="justify-content-start">
                {ids.length == 0 ? (
                  <Col md={12} className="text-center">
                    <span className="ml-20"> {!isControlTowerUser ? 'View "More Packages" to subscribe' : <NotFound feedPosts={true} />}</span>
                  </Col>
                ) : (
                  _.map(ids, (id) => {
                    const data = _.get(entities, `${id}`) || {};
                    return (
                      <Col md={6} lg={4} className="mt-20 mb-20">
                        <MySubscriptionCard data={data} propsId={props.id} encodeBackUrl={encodeBackUrl} />
                      </Col>
                    );
                  })
                )}
              </Row>
            </div>
          </div>
        </div>
        {showHistory && (
          <div className="bfc-body mt-20 p-0" style={{ borderRadius: 5 }}>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <h4 className="title p-15 mb-0" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__zero)', }}>
                  Package History
                </h4>
              </div>
              <h5
                className="title p-15 mb-0 cursor-pointer"
                style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: '100', fontSize: 'var(--fs-base__two)', textDecoration: 'underline' }}
                onClick={toggle}>
                Hide
              </h5>
            </div>
            <div className="row">
              <div className="col-md-12">
                {busyTable ? (
                  <div className="bfc-body bfc-form">
                    <div className="panel__refresh">
                      <LogoLoader />
                    </div>
                  </div>
                ) : (
                  <div className="pb-15">
                    <Table
                      // ref={tableRef}
                      columns={columns}
                      onChange={handlePageChange}
                      dataSource={data}
                      pagination={false}
                      size="small"
                      style={{ whiteSpace: 'pre' }}
                    />
                    <AntPagination
                      total={parseInt(totalRecords)}
                      handlePageChange={handlePageChange}
                      page={page}
                      // clearFilters={clearFilters}
                      rowsPerPage={rowsPerPage}
                      setScrollToFirstRow={setScrollToFirstRow}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default MySubscription;
