import qs from 'query-string';
import * as yup from 'yup';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import _ from 'lodash';
import * as fetch from 'services/fetch';
import axios from 'axios';
import config from 'config';

const BASE_URL = `/BioFuelRequirementOffers/api`;
const { baseUrl } = config;

/** api fields
{
  commodity_deal_master_id: null,
  commodity_req_offer_id: 1,
  commodity_req_offer_response_id: 13,
  created_by: 6,
  created_datetime: '2020-11-17t08:20:19.407',
  end_date: '2020-12-02t18:30:00',
  offer_bid_price: -50,
  offer_bid_qty: 50,
  offer_bid_qty_uom_id: 1,
  offer_bid_qty_uom_code: 'mt',
  offer_bid_type_enum: 2,
  offer_bid_type_enum_string: 'bid',
  periodicity_enum: 1,
  periodicity_enum_string: 'once',
  req_offer_response_status_enum: 1,
  req_offer_response_status_enum_string: 'submitted',
  total_offer_bid_qty: 50,
  transportation_cost: 100,
  address_id: 1266,
  person_id: 6,
  company_affiliate_enum: 0,
  company_affiliate_enum_string: null,
  user_type_enum: 2,
  user_type_enum_string: 'non enterprise',
}
*/

const BidDataSchema = yup
  .object()
  .shape({
    commodity_req_offer_id: yup.number().required().positive().integer(), // PK
    offer_bid_qty: yup.number().required().positive().integer(),
    offer_bid_uom_id: yup.number().required().positive().integer(),
    // start_date: yup.date().required(),
    // end_date: yup.date().required(),
    // start_date: yup.date().required(),
    // end_date: yup.date().required(),
    offer_bid_price: yup.number().required().positive().integer(),
    // total_offer_bid_qty: yup.number().required().positive().integer(),
    // periodicity_enum: yup.number().required().positive().integer(),
    address_id: yup.number().required().positive().integer(),
    // transportation_cost: yup.number().required().positive().integer(),
    vehicle_type_id: yup.number().required().positive().integer(),
    // maximum_capacity: yup.number().required().positive().integer(),
    // maximum_cap_uom_id: yup.number().required().positive().integer(),
    // person_id: yup.mixed().oneOf([1, 2]).required(),
    // user_type_enum: yup.mixed().oneOf([1, 2]).required(),
    // company_affiliate_enum: yup.mixed().oneOf([1, 2]).required(),
  })
  .defined();

const BidLabelSchema = yup
  .object()
  .shape({
    commodity_req_offer_response_id: yup.number().required().positive().integer(),
    offer_bid_uom_code: yup.string().defined(),
    offer_bid_qty_uom_id: yup.number().required().positive().integer(),
    offer_bid_qty_uom_code: yup.string().defined(),
    offer_bid_type_enum: yup.number().required().positive().integer(),
    offer_bid_type_enum_string: yup.string().defined(),
    periodicity_enum_string: yup.string().defined(),
    req_offer_response_status_enum: yup.number().required().positive().integer(),
    req_offer_response_status_enum_string: yup.string().defined(),
    address_code: yup.string().defined(),
    created_by: yup.number().required().positive().integer(),
    person_id: yup.number().required().positive().integer(),
    user_type_enum_string: yup.string().defined(),
    created_datetime: yup.date().defined(),
    end_date: yup.date().defined(),
  })
  .defined();

const BidMixedSchema = BidDataSchema.concat(BidLabelSchema);
const BidMixedArraySchema = yup.array().of(BidMixedSchema);

export type BidDataType = yup.InferType<typeof BidDataSchema>;
export type BidLabelType = yup.InferType<typeof BidLabelSchema>;
export type BidMixedType = yup.InferType<typeof BidMixedSchema>;

const SaveBid = async (payload, isOpenBuySell, isSendQuote) => {
  const storeState = globalThis.store.getState();

  const finalPayload = {
    ...payload,
    open_buy_sell_valid_until:
      // Changed format of date because of backend wanted this change.
      payload.open_buy_sell_valid_until && dayjs(payload.open_buy_sell_valid_until).format('YYYY-MM-DD HH:mm:ss'),
    // payload.open_buy_sell_valid_until && dayjs(payload.open_buy_sell_valid_until).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
    start_date: dayjs(payload.start_date).format('YYYY-MM-DD'),
    end_date: dayjs(payload.end_date).format('YYYY-MM-DD'),
    // person_id: globalThis?.user?.userid,
    person_id: isSendQuote ? payload.person_id : globalThis?.user?.userid,
    user_type_enum:
      _.get(window, 'user.profile.person.user_type_enum') ||
      _.get(storeState, 'user.profile.person.user_type_enum') ||
      2,
    // user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    // company_affiliate_enum: 1, // @TODO: hardcoded
    // ...(isSendQuote && { is_directly_accepted: true })
  };

  finalPayload.total_offer_bid_qty = utils.caculateFinalQuantity(
    finalPayload.offer_bid_qty,
    finalPayload.periodicity_enum,
    finalPayload.start_date,
    finalPayload.end_date,
  );

  const acl = utils.getACL(_.get(window, 'user') || {});
  if (!acl.isCompany) {
    finalPayload['entity_id'] = finalPayload['person_id'];
  }
  let url;
  if (isOpenBuySell) {
    url = `${BASE_URL}/OpenBuySell/SaveBid_ELOC_PhaseTwo`;
  } else {
    url = `${BASE_URL}/Bid/SaveBid_ELOC_PhaseTwo`;
  }

  return await fetch.create<BidDataType>(url, finalPayload);
};

const EditBid = async (payload, isOpenBuySell) => {
  const storeState = globalThis.store.getState();
  const finalPayload = {
    ...payload,
    open_buy_sell_valid_until:
      // Changed format of date because of backend wanted this change.
      payload.open_buy_sell_valid_until && dayjs(payload.open_buy_sell_valid_until).format('YYYY-MM-DD HH:mm:ss'),
    // payload.open_buy_sell_valid_until && dayjs(payload.open_buy_sell_valid_until).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
    start_date: dayjs(payload.start_date).format('YYYY-MM-DD'),
    end_date: dayjs(payload.end_date).format('YYYY-MM-DD'),
    person_id: globalThis?.user?.userid,
    user_type_enum:
      _.get(window, 'user.profile.person.user_type_enum') ||
      _.get(storeState, 'user.profile.person.user_type_enum') ||
      2,
    //user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    // company_affiliate_enum: 1, // @TODO: hardcoded
  };

  finalPayload.total_offer_bid_qty = utils.caculateFinalQuantity(
    finalPayload.offer_bid_qty,
    finalPayload.periodicity_enum,
    finalPayload.start_date,
    finalPayload.end_date,
  );

  const acl = utils.getACL(_.get(window, 'user') || {});
  if (!acl.isCompany) {
    finalPayload['entity_id'] = finalPayload['person_id'];
    finalPayload['user_type_enum'] = 2;
  } else {
    finalPayload['user_type_enum'] = 1;
  }

  let url;
  if (isOpenBuySell) {
    url = `${BASE_URL}/OpenBuySell/EditBid`;
  } else {
    url = `${BASE_URL}/Bid/EditBid_PhaseTwo`;
  }

  return await fetch.update<BidDataType>(url, finalPayload);
};

const FetchAllBidsAgainstEntityId = async (filters = {}, page: number, pageSize: number) => {
  // const urlParams = {
  //   entity_id: globalThis?.user?.userid,
  //   user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
  //   offset: (page - 1) * pageSize,
  //   page_size: pageSize,
  // };

  const finalPayload = {
    filters: {
      ...filters,
      // preferred_blocked_enum: 1,
    },
    entity_id: globalThis?.user?.userid,
    offset: (page - 1) * pageSize,
    page_size: pageSize,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1,
  };

  const acl = utils.getACL(_.get(window, 'user') || {});
  const companyId = _.get(window, 'user.profile.person.company_id') || 0;
  if (acl.isCompany) {
    finalPayload['entity_id'] = companyId;
    finalPayload['user_type_enum'] = 1;
    finalPayload['company_affiliate_enum'] = 1;
  } else {
    finalPayload['entity_id'] = _.get(window, 'user.userid') || 0;
    finalPayload['user_type_enum'] = 2;
  }

  // const acl = utils.getACL(_.get(window, 'user') || {});
  // if (!acl.isCompany) {
  //   finalPayload['entity_id'] = finalPayload['person_id'];
  //   finalPayload['user_type_enum'] = 2;
  // } else {
  //   finalPayload['user_type_enum'] = 1;
  // }

  const url = `${BASE_URL}/Bid/FetchAllBidsAgainstEntityId_ELOC`;

  const response = await fetch.create(url, finalPayload);

  return response;
};

const FetchBidDetailsAgainstId = async (bidId) => {
  // /api/Bid/FetchBidDetailsAgainstId

  const urlParams = {
    commodity_req_offer_response_id: bidId,
    person_id: _.get(window, 'user.userid') || 0,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Bid/FetchBidDetailsAgainstId_ELOC?${qs}`;

  const details = await fetch.fetchOne<BidMixedType>(url);

  // PlainOfferMixedArraySchema.validateSync(listData.data);

  // yup.array().of(PlainOfferLabelSchema).validateSync(listData.data);

  return details;
};

const FetchBidDetailsAgainstIdQuote = async (bidId) => {
  // /api/Bid/FetchBidDetailsAgainstId

  const urlParams = {
    commodity_req_offer_response_id: bidId,
    // person_id: _.get(window, 'user.userid') || 0,
  };

  const url = `${baseUrl}/BioFuelRequirementOffers/api/Bid/FetchBidDetailsAgainstId_ELOC?${qs.stringify(urlParams)}`;

  const details = await fetch.fetchOne<BidMixedType>(url);
  // const details = axios.get(url);

  // PlainOfferMixedArraySchema.validateSync(listData.data);

  // yup.array().of(PlainOfferLabelSchema).validateSync(listData.data);

  return details;
};

const FetchBidsAgainstPlainOfferId = async (
  commodityReqOfferId: string,
  filters = {},
  page: number,
  pageSize: number,
) => {
  // const urlParams = {
  //   commodity_req_offer_id: commodityReqOfferId,
  //   offset: (page - 1) * pageSize,
  //   pageSize: pageSize,
  // };

  const finalPayload = {
    filters: {
      ...filters,
      // commodity_req_offer_id: commodityReqOfferId,
      // preferred_blocked_enum: 1,
    },
    entity_id: parseInt(commodityReqOfferId),
    invoking_person_id: globalThis?.user?.userid,
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };

  const url = `${BASE_URL}/PlainOffer/FetchBidsAgainstPlainOfferId_ELOC`;

  const listData = await fetch.create(url, finalPayload);

  return listData;
};

const WithdrawBidById = async (commodityReqOfferResponseId) => {
  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/Bid/WithdrawBidById?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};

const AcceptBid = async (commodityReqOfferResponseId) => {
  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/AcceptBid?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};

const AcceptBidQuote = async (bodyParams, isOpenBuySell) => {
  // let quoteData = localStorage.getItem('quoteVerification') || {};
  // quoteData = JSON.parse(quoteData);
  // const urlParams = {
  //   Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
  //   Person_Id: quoteData?.person_id,
  // };
  delete bodyParams.is_quotation;
  let url;
  if (isOpenBuySell) {
    url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SaveBid_ELOC_PhaseTwo`;
  } else {
    url = `${baseUrl}/BioFuelRequirementOffers/api/Bid/SaveBid_ELOC_PhaseTwo`;
  }

  const res = await utils.fetch(url, {
    method: 'POST',
    body: JSON.stringify(bodyParams),
  }).ready;

  const resData = await utils.processApiRes(res);

  return resData;
};
const RejectBidById = async (commodityReqOfferResponseId) => {
  const urlParams = {
    Commodity_Req_Offer_Response_Id: commodityReqOfferResponseId,
    Person_Id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/RejectBidById?${qs}`;

  const updated = await fetch.update<Object>(url, {});

  return updated;
};

export default {
  SaveBid,
  EditBid,
  FetchAllBidsAgainstEntityId,
  FetchBidDetailsAgainstId,
  FetchBidsAgainstPlainOfferId,
  WithdrawBidById,
  AcceptBid,
  RejectBidById,
  FetchBidDetailsAgainstIdQuote,
  AcceptBidQuote,
};
