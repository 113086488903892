import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';

import OverviewHeader from 'common/OverviewHeader';
import AntPagination from 'common/AntTablePagination';
import { actions as earlyPaymentsActions } from 'redux/earlyPayments';
import useQuery from 'lib/hooks/useQuery';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterUtils from 'lib/filterUtils';

import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import { actions as generateEquipmentRentalCSVReportActions } from 'redux/generateReportCommon';
import OverviewNav from 'common/OverviewNav';
import utils from 'lib/utils';
// import LogoLoader from 'common/LogoLoader';
import AntTableActions from 'common/TableActions';
import PopupForm from 'common/Form/PopupForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputAdornment, TextField } from '@material-ui/core';
import { Button } from 'reactstrap';
import MUITimeline from 'common/MUIComponents/Timeline';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import MUITextField from 'common/MUIComponents/TextField';
import MUIButton from 'common/MUIComponents/Button';

const AdjustmentFactor = () => {
  const dispatch = useDispatch();
  const { page = 1 } = useQuery();
  const history = useHistory();
  const csvLink = useRef();
  const tableRef = useRef();
  let { person_company_affiliate_code, modified_by, modified_on } = useQuery();
  const rentalState = useSelector((state) => state.earlyPayments);
  const equipmentData = Object.values(rentalState.entities);
  const companyAffiliates = rentalState?.companyAffiliates;
  const historyData = rentalState?.historyData;
  const busy = _.get(rentalState, 'busy') || false;

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCompanyAffiliate, setSelectedCompanyAffiliate] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [adjustmentFactor, setAdjustmentFactor] = useState('');
  const [addAdjustmentFactor, setAddAdjustmentFactor] = useState(false);
  const [editAdjustmentFactor, setEditAdjustmentFactor] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [typicalDelay, setTypicalDelay] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [note, setNote] = useState('');
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};

  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        delete params['delivery_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_status_enum'));
        break;
      case 'modified_by_person':
        delete params['modified_by'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('modified_by'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        params = {
          ...params,
          delivery_status_enum: e,
        };
        break;
      case 'modified_by_person':
        params = {
          ...params,
          modified_by: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      person_company_affiliate_code,
      modified_by,
      modified_on,
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(earlyPaymentsActions.fetchAdjustmentFactorForEarlyPayment(params));
  }, [person_company_affiliate_code, modified_by, modified_on, rowsPerPage, page, isReRenderRequired]);

  useEffect(() => {
    if (searchValue !== '') {
      let params = {
        filters: { person_company_affiliate_code: searchValue },
        // offset: 0,
        // page_size: 10,
      };
      dispatch(earlyPaymentsActions.fetchCompanyAffiliateForEarlyPayment(params));
    }
  }, [searchValue]);

  useEffect(() => {
    if (editAdjustmentFactor) {
      setSelectedCompanyAffiliate(companyAffiliates?.[0]);
    }
  }, [companyAffiliates]);

  useEffect(() => {
    if (selectedRow) {
      setSearchValue(selectedRow?.person_company_affiliate_code);
      setAdjustmentFactor(selectedRow.adjustment_factor);
      setNote(selectedRow.notes);
    }
  }, [editAdjustmentFactor]);

  const fetchMoreData = async () => {
    if (selectedRow) {
      let params = {
        filters: {},
        busiuness_affiliate_id: selectedRow?.person_company_affiliate_id,
        person_company_affiliate_enum: selectedRow?.person_company_affiliate_enum,
        offset: historyData?.length,
        page_size: 10,
      };
      dispatch(earlyPaymentsActions.fetchAdjustmentFactorHistoryForEarlyPayment(params));
    }
  };

  useEffect(() => {
    if (selectedRow) {
      let params = {
        filters: {},
        busiuness_affiliate_id: selectedRow?.person_company_affiliate_id,
        person_company_affiliate_enum: selectedRow?.person_company_affiliate_enum,
        offset: 0,
        page_size: 10,
      };
      dispatch(earlyPaymentsActions.fetchAdjustmentFactorHistoryForEarlyPayment(params));
    }
  }, [editAdjustmentFactor]);

  const back = utils.encodeBackUrl();

  const TRACKING_TABLE_HEADERS = [
    {
      title: 'Company Name',
      dataIndex: 'person_company_affiliate_code',
      key: 'person_company_affiliate_code',
      width: '130px',
      align: 'left',
      fixed: true,
      render: (text, row) => (
        <div className="d-flex justify-content-start align-items-center">{row.person_company_affiliate_code}</div>
      ),
      sorter: (a, b) =>
        a.person_company_affiliate_code &&
        a.person_company_affiliate_code.localeCompare(
          b.person_company_affiliate_code && b.person_company_affiliate_code,
        ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'person_company_affiliate_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={person_company_affiliate_code}
          name={'Company Name'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'person_company_affiliate_code'),
      filteredValue: person_company_affiliate_code !== undefined ? person_company_affiliate_code : null,
    },
    {
      title: 'Adjustment Factor',
      dataIndex: 'adjustment_factor',
      key: 'adjustment_factor',
      width: '100px',
      align: 'right',
      fixed: true,
      render: (text, row) => (
        <div className="d-flex justify-content-end align-items-center">{row.adjustment_factor}%</div>
      ),
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      key: 'notes',
      width: '150px',
      align: 'left',
      ellipsis: true,
      render: (text, row) => (
        <div
          className="text-left"
          style={{
            width: '100%',
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          {row.notes}
        </div>
      ),
    },
    {
      title: 'Modified By',
      dataIndex: 'modified_by',
      key: 'modified_by',
      width: '170px',
      align: 'left',
      render: (text, row) => <div className="d-flex justify-content-start align-items-center">{row.modified_by}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'modified_by_person'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={modified_by}
          name={'Status'}
          codeBaisedFilter={true}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'modified_by'),
      defaultFilteredValue: modified_by !== undefined ? modified_by : null,
    },
    {
      title: 'Modified On',
      dataIndex: 'modified_on',
      key: 'modified_on',
      width: '150px',
      ellipsis: true,
      align: 'center',
      sorter: (a, b) => a.modified_on && a.modified_on.localeCompare(b.modified_on && b.modified_on),
      render: (text, row) => (
        <div className="bfc-table-list-components text-left d-flex justify-content-center">
          {row.modified_on ? dayjs(row.modified_on).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'modified_on'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={modified_on}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'modified_on'),
      defaultFilteredValue: modified_on !== undefined ? modified_on : null,
    },
    {
      title: 'Action',
      dataIndex: 'equipment_address',
      key: 'equipment_address',
      width: '100px',
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {CT_parent && <AntTableActions
            showFlag={true}
            row={row}
            controls={[
              {
                name: 'Edit',
                onClick: () => {
                  setEditAdjustmentFactor(true);
                  setSelectedRow(row);
                },
                controlColor: 'var(--unnamed-color-1b62ab)',
              },
              {
                name: 'Delete',
                onClick: async () => {
                  const success = await dispatch(
                    earlyPaymentsActions.deleteAdjustmentFactor({
                      person_company_affiliate_id: row.person_company_affiliate_id,
                    }),
                  );
                  success && setIsReRenderRequired(!isReRenderRequired);
                },
                controlColor: '#ff4d4f',
              },
            ]}
          />}
        </div>
      ),
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };
  const GenerateEquipmentRentalReport = useSelector((state) => state.generateReportCommon);
  const tableData = equipmentData;
  const totalRecords = tableData?.[0]?.total_count;
  const reportData = busy ? [] : Object.values(GenerateEquipmentRentalReport.entities || {});
  const getExportReport = async () => {
    const filterParams = {
      filters: {
        ...filters,
      },
      page_size: 10000,
      offset: 0,
    };
    const success = await dispatch(generateEquipmentRentalCSVReportActions.getEquipmentRentalReport(filterParams));
    if (success) {
      csvLink.current.link.click();
    }
  };

  let links = [
    {
      to: `/control-tower/early-payment?page=1`,
      title: 'Tracking',
    },
    {
      to: `/control-tower/adjustment-factor?page=1`,
      title: 'Adj. Factor',
    },
    {
      to: `/control-tower/minimum-discount?page=1`,
      title: 'Min. Discount',
    },
  ];

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div>
      <OverviewHeader heading={`Early Payment`} />
      <OverviewNav
        links={links}
        rightPanContent={
          <Button className="btn-sm mr-2" onClick={() => setAddAdjustmentFactor(true)} disabled={!CT_parent}>
            Add
          </Button>
        }
      />
      {/* {busy && (
        <div className="commodity-wrapper">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      )} */}
      <div className="delivery-table bfc-body">
        <div>
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? TRACKING_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : TRACKING_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 510 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              clearFilters={clearFilters}
              rowsPerPage={rowsPerPage}
              setScrollToFirstRow={setScrollToFirstRow}
            // reportData={reportData}
            // getExportReport={getExportReport}
            // csvLink={csvLink}
            // type={'Equipment Rental'}
            />
            {(addAdjustmentFactor || editAdjustmentFactor) && (
              <PopupForm
                title={editAdjustmentFactor ? 'Edit Adjustment Factor' : 'Add Adjustment Factor'}
                onClose={() => {
                  setAddAdjustmentFactor(false);
                  setEditAdjustmentFactor(false);
                  setSearchValue('');
                  setAdjustmentFactor('');
                  setNote('');
                  setSelectedCompanyAffiliate('');
                  setTypicalDelay('');
                  setInterestRate('');
                  setSelectedRow('')
                }}>
                <div className="col mb-3 p-0">
                  <span className="form-group-label">Select Company/Affiliate</span>
                  <div className="form-group-field">
                    {/* <Autocomplete
                      size="small"
                      fullWidth
                      value={selectedCompanyAffiliate}
                      options={companyAffiliates || []}
                      getOptionLabel={(option) => option?.person_company_affiliate_code}
                      onChange={(e, newValue) => {
                        setSelectedCompanyAffiliate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          className="material-form__field"
                          placeholder={'Enter 3 characters to search'}
                          // label={`Facilitator's Number`}
                          onChange={(e) => {
                            const searchText = e.target.value;
                            if (searchText.length >= 3) {
                              setSearchValue(searchText);
                            }
                          }}
                        // error={touched['agent_code'] && errors['agent_code']}
                        // helperText={touched['agent_code'] && errors['agent_code']}
                        />
                      )}
                    /> */}
                    <MUIAutocomplete
                      type="search"
                      selectedCodeOrId={selectedRow?.person_company_affiliate_id}
                      dataStructure={{
                        labelApiParam: 'person_company_affiliate_code',
                        valueApiParam: 'person_company_affiliate_id',
                      }}
                      apiParams={{
                        reqUrl: `BioFuelPayments/api/EarlyPayment/FetchCompanyAffiliateForEarlyPayment`,
                        method: 'POST',
                        getReqParams: (newVal) => ({
                          filters: {
                            person_company_affiliate_code: selectedRow?.person_company_affiliate_code || newVal
                          }
                        })
                      }}
                      onChange={(newValue) => {
                        setSelectedCompanyAffiliate(newValue);
                      }}
                    />
                  </div>
                </div>
                <div className="row col mb-3 ml-0 p-0">
                  <div className="col pl-0">
                    <span className="form-group-label">Typical Delay</span>
                    <div className="form-group-field">
                      {/* <TextField
                        type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        className="material-form__field"
                        value={typicalDelay}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span style={{ fontWeight: 'bold' }}>Days</span>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          if (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,2})?$/) || !e.target.value) {
                            setTypicalDelay(e.target.value);
                            setAdjustmentFactor((+(interestRate || 0) / +e.target.value)?.toFixed(2));
                          }
                        }}
                      /> */}
                      <MUITextField
                        fieldType="qty"
                        EndIcon={'Days'}
                        value={typicalDelay}
                        onChange={(newValue) => {
                          setTypicalDelay(newValue);
                          setAdjustmentFactor((+interestRate || +newValue) ? ((+interestRate || 0) / (+newValue || 0))?.toFixed(2) : '');
                        }}
                      />
                    </div>
                  </div>
                  <div className="col p-0">
                    <span className="form-group-label">Interest Rate</span>
                    <div className="form-group-field">
                      {/* <TextField
                        type="number"
                        size="small"
                        fullWidth
                        variant="outlined"
                        className="material-form__field"
                        value={interestRate}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span style={{ fontWeight: 'bold' }}>%</span>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          if (
                            (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,2})?$/) && parseFloat(e.target.value) <= 100) ||
                            !e.target.value
                          ) {
                            setInterestRate(e.target.value);
                            setAdjustmentFactor((+e.target.value / +typicalDelay)?.toFixed(2));
                          }
                        }}
                      /> */}
                      <MUITextField
                        fieldType="percent"
                        value={interestRate}
                        onChange={(newValue) => {
                          setInterestRate(newValue);
                          setAdjustmentFactor((+newValue || +typicalDelay) ? ((+newValue || 0) / (+typicalDelay || 0))?.toFixed(2) : '');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col mb-3 p-0">
                  <span className="form-group-label">Adjustment Factor</span>
                  <div className="form-group-field">
                    {/* <TextField
                      type="number"
                      size="small"
                      fullWidth
                      variant="outlined"
                      className="material-form__field"
                      value={adjustmentFactor}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span style={{ fontWeight: 'bold' }}>%</span>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        if (
                          (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,2})?$/) && parseFloat(e.target.value) <= 100) ||
                          !e.target.value
                        ) {
                          setAdjustmentFactor(e.target.value);
                        }
                      }}
                    /> */}
                    <MUITextField
                      fieldType="percent"
                      value={adjustmentFactor}
                      onChange={(newValue) => setAdjustmentFactor(newValue)}
                    />
                  </div>
                </div>
                <div className="col mb-3 p-0">
                  <span className="form-group-label">Note</span>
                  <div className="form-group-field">
                    {/* <TextField
                      type="text"
                      size="small"
                      fullWidth
                      variant="outlined"
                      className="material-form__field"
                      multiline
                      maxRows={5}
                      value={note}
                      onChange={(e) => {
                        e.target.value?.length <= 250 && setNote(e.target.value);
                      }}
                    /> */}
                    <MUITextField fieldType="textarea" value={note} onChange={(newValue) => setNote(newValue)} />
                  </div>
                </div>
                <div className="d-flex justify-content-center align-content-center">
                  {/* <Button
                    color="primary"
                    onClick={async () => {
                      const params = {
                        person_company_affiliate_id: selectedCompanyAffiliate?.person_company_affiliate_id,
                        person_company_affiliate_enum: selectedCompanyAffiliate?.person_company_affiliate_enum,
                        adjustment_factor: adjustmentFactor,
                        notes: note,
                        operation_enum: editAdjustmentFactor ? 3 : 1,
                      };
                      const success = await dispatch(earlyPaymentsActions.saveAndUpdateAdjustmentFactor(params));
                      if (success) {
                        setIsReRenderRequired(!isReRenderRequired);
                      setAddAdjustmentFactor(false);
                      setEditAdjustmentFactor(false);
                        setSearchValue('');
                        setAdjustmentFactor('');
                        setNote('');
                        setSelectedCompanyAffiliate('');
                        setTypicalDelay('');
                        setInterestRate('');
                      }
                    }}>
                    {editAdjustmentFactor ? 'Update' : 'Save'}
                  </Button> */}
                  <MUIButton onClick={async () => {
                    const params = {
                      person_company_affiliate_id: selectedCompanyAffiliate?.person_company_affiliate_id,
                      person_company_affiliate_enum: selectedCompanyAffiliate?.person_company_affiliate_enum,
                      adjustment_factor: adjustmentFactor,
                      notes: note,
                      operation_enum: editAdjustmentFactor ? 3 : 1,
                    };
                    const success = await dispatch(earlyPaymentsActions.saveAndUpdateAdjustmentFactor(params));
                    if (success) {
                      setIsReRenderRequired(!isReRenderRequired);
                      setAddAdjustmentFactor(false);
                      setEditAdjustmentFactor(false);
                      setSearchValue('');
                      setAdjustmentFactor('');
                      setNote('');
                      setSelectedCompanyAffiliate('');
                      setTypicalDelay('');
                      setInterestRate('');
                    }
                  }}>{editAdjustmentFactor ? 'Update' : 'Save'}</MUIButton>
                </div>
                <hr />
                {editAdjustmentFactor ? (
                  <MUITimeline
                    data={historyData}
                    fetchMoreData={fetchMoreData}
                    generateChildContent={(item) => (
                      <div>
                        <strong className="d-block">{item.adjustment_factor}%</strong>
                        <span className="d-block">Initially set to {item.initial_adjustment_factor}%</span>
                      </div>
                    )}
                  />
                ) : (
                  ''
                )}
              </PopupForm>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdjustmentFactor;
