import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchDetailsList(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  consumeProduceCreate(params = {}) {
    let bodyParams = {
      ...params,
      quantity: parseFloat(params['quantity']),
      storage_id: parseInt(params['storage_id']),
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      commodity_deal_master_id: parseInt(id),
      invoking_person_id: _.get(window, 'user.userid', 0),
      is_saved_as_draft: true,
      bf_transport_delivery_schedule: [],
    };

    bodyParams['bf_transport_delivery_schedule'] = (params.schedule || []).map((item) => {
      return {
        transport_delivery_schedule_id: item.transport_delivery_schedule_id,
        proposed_delivery_qty: parseInt(item.total_quantity),
      };
    });

    return bodyParams;
  },

  fetchStorageLevels(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  consumeProduceDelete(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let storageData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(storageData, 'storage_level_id');
  },
  fetchDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'storage_level_id') || '',
    };
  },
  fetchDetailsList(resData) {
    return resData;
  },
  create(resData) {
    return resData;
  },
  consumeProduceCreate(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  fetchStorageLevels(resData) {
    let storageData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchStorageLevelsDetails(details);
    });
    return _.keyBy(storageData, 'sched_stock_in_out_id');
  },
  fetchStorageLevelsDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'sched_stock_in_out_id') || '',
    };
  },
  consumeProduceDelete(resData) {
    return resData;
  },
};
