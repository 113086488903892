import * as api from './api';

export const getAllCommodityDetails = async (params, commodityDetailsTOFetch: string) => {
  try {
    const res = await api.getAllCommodityDetails(params, commodityDetailsTOFetch);
    return res;
  } catch (err) {
    throw err;
  }
};

export const addUpdateCommodities = async (params = {}, commoditiesToUpdate: string) => {
  try {
    const res = await api.addUpdateCommodities(params, commoditiesToUpdate);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteCommodities = async (params = {}, commoditiesToDelete: string) => {
  try {
    const res = await api.deleteCommodities(params, commoditiesToDelete);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPlatformCharges = async (params = {}, platformCharges: string) => {
  try {
    const res = await api.getPlatformCharges(params, platformCharges);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchGenerateReport = async () => {
  try {
    const res = await api.fetchGenerateReport();
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDownloadedReport = async (params: {
  offset: number;
  page_size: number;
  DownloadUpload_File_Enum?: number;
}) => {
  try {
    const res = await api.fetchDownloadedReport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const generateReportDataExtract = async (params) => {
  try {
    const res = await api.generateReportDataExtract(params);
    return res;
  } catch (err) {
    throw err;
  }
};
