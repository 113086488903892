import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import utils from 'lib/utils';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { actions as profileActions } from 'redux/extendedProfile';
import { actions as supplierRatingsActions } from 'redux/supplierRatings';
import PostLoader from 'pages/socialFeed/components/Feed/PostLoader';
import ExtendedProfileCard from 'common/ExtendedProfileCard/modal';
import SubExtendedProfileCard from 'common/ExtendedProfileCard/subModal';
import ShieldIcon from 'common/Shield/shield';

// Icons
import RawMaterialProductsIcon from 'common/Icons/ExtendedProfile/RawMaterialProductsIcon';
import NoSuppliersIcon from 'assets/images/icons/extendedProfile/no-suppliers-icon.svg';
import LocationAccessIcon from 'common/Icons/ExtendedProfile/LocationAccessIcon';
import InfrastructureEquipmentIcon from 'common/Icons/ExtendedProfile/InfrastructureEquipmentIcon';
import HealthSafetyIcon from 'common/Icons/ExtendedProfile/HealthSafetyIcon';
import QualityProcessIcon from 'common/Icons/ExtendedProfile/QualityProcessIcon';
import SustainableBusinessIcon from 'common/Icons/ExtendedProfile/SustainableBusinessIcon';
import ProductsRawIcon from 'assets/images/icons/extendedProfile/products-raw-icon.svg';
import OwnedIcon from 'assets/images/icons/extendedProfile/owned-icon.svg';
import RentedIcon from 'assets/images/icons/extendedProfile/rented-icon.svg';
import UnclearIcon from 'assets/images/icons/extendedProfile/unclear-icon.svg';

//Raw Material Products icon
import productSolidIcon from 'assets/images/icons/extendedProfile/product-solid.svg';
import productLiquidIcon from 'assets/images/icons/extendedProfile/product-liquid.svg';
import BioIndustrialIcon from 'assets/images/icons/extendedProfile/bio-industrial.svg';
import BiomassRawIcon from 'assets/images/icons/extendedProfile/biomass-raw-icon.svg';
import BiomassIndustrialIcon from 'assets/images/icons/extendedProfile/biomass-industrial-icon.svg';
import PelletsIcon from 'assets/images/icons/extendedProfile/pellets-icon.svg';
import LongPelletsIcon from 'assets/images/icons/extendedProfile/long-pellets-icon.svg';
import ProuctsBriquttesIcon from 'assets/images/icons/extendedProfile/products-briquttes-icon.svg';
import BiomedBriqutteIcon from 'assets/images/icons/extendedProfile/biomed-briqutte-icon.svg';
import BiomassSilageIcon from 'assets/images/icons/extendedProfile/biomass-silage-icon.svg';
import BiodegradableRawIcon from 'assets/images/icons/extendedProfile/biodegradable-raw-icon.svg';
import BiomassLiquidIcon from 'assets/images/icons/extendedProfile/biomass-liquid-icon.svg';
import BioEnggIcon from 'assets/images/icons/extendedProfile/bioengg-icon.svg';
import BiogasIcon from 'assets/images/icons/extendedProfile/biogas-icon.svg';
import CottonIcon from 'assets/images/icons/extendedProfile/cotton-icon.svg';
import SugarCaneIcon from 'assets/images/icons/extendedProfile/sugarcane-icon.svg';
import SoybeanIcon from 'assets/images/icons/extendedProfile/soybean-icon.svg';
import GrounduntIcon from 'assets/images/icons/extendedProfile/groundunt-icon.svg';
import CornIcon from 'assets/images/icons/extendedProfile/corn-icon.svg';
import ArecanutIcon from 'assets/images/icons/extendedProfile/arecanut-icon.svg';
import BananaIcon from 'assets/images/icons/extendedProfile/banana-icon.svg';
import CashewsIcon from 'assets/images/icons/extendedProfile/cashews-icon.svg';
import CoconutIcon from 'assets/images/icons/extendedProfile/coconut-icon.svg';
import CorianderIcon from 'assets/images/icons/extendedProfile/coriander-icon.svg';
import EucalyptusIcon from 'assets/images/icons/extendedProfile/eucalyptus-icon.svg';
import GooseberryIcon from 'assets/images/icons/extendedProfile/gooseberry-icon.svg';
import JowerBajraIcon from 'assets/images/icons/extendedProfile/jower-bajra-icon.svg';
import JuteIcon from 'assets/images/icons/extendedProfile/jute-icon.svg';
import RiceWheatIcon from 'assets/images/icons/extendedProfile/rice-wheat-icon.svg';
import TurIcon from 'assets/images/icons/extendedProfile/tur-icon.svg';
import WoodIcon from 'assets/images/icons/extendedProfile/wood-icon.svg';

import BambooIcon from 'assets/images/icons/extendedProfile/bamboo-icon.svg';
import CoffeeIcon from 'assets/images/icons/extendedProfile/coffee-icon.svg';
import CuminSeedIcon from 'assets/images/icons/extendedProfile/cumin-seed-icon.svg';
import BiocharIcon from 'assets/images/icons/extendedProfile/biochar-icon.svg';
import PalmIcon from 'assets/images/icons/extendedProfile/palm-icon.svg';
import CompressedBiogasIcon from 'assets/images/icons/extendedProfile/compressed-biogas-icon.svg';
import AtillaIcon from 'assets/images/icons/extendedProfile/atilla-icon.svg';
import InformationIcon from 'assets/images/icons/extendedProfile/information-icon.svg';
import BiodegradableBiodegradableIcon from 'assets/images/icons/extendedProfile/biodegradable-biodegradable-icon.svg';
import BiofuelLongPelletsIcon from 'assets/images/icons/extendedProfile/biofuel-long-pellets-icon.svg';
import BioenggBioenggIcon from 'assets/images/icons/extendedProfile/bioengg-bioengg-icon.svg';
import MaterialProductsSolidIcon from 'assets/images/icons/extendedProfile/material-products-solid.svg';
import MaterialProductsLiquidIcon from 'assets/images/icons/extendedProfile/material-products-liquid.svg';

// Location and Access
import ConcreteTarIcon from 'assets/images/icons/extendedProfile/concrete-tar-icon.svg';
import DamagedIcon from 'assets/images/icons/extendedProfile/damaged-icon.svg';
import GravelIcon from 'assets/images/icons/extendedProfile/gravel-icon.svg';
import MudIcon from 'assets/images/icons/extendedProfile/mud-icon.svg';

// People and facility
import MenWomenIconIcon from 'assets/images/icons/extendedProfile/men-women-icon.svg';
import TotalMenWomenIcon from 'assets/images/icons/extendedProfile/total-men-women-icon.svg';
import FacilityAreaIcon from 'assets/images/icons/extendedProfile/facility-area-icon.svg';
import StorageCoverIcon from 'assets/images/icons/extendedProfile/storage-covered_icon.svg';
import StorageOpenIcon from 'assets/images/icons/extendedProfile/storage-open-icon.svg';

// Infrastructure icon
import PowerSupplyIcon from 'assets/images/icons/extendedProfile/power-supply-icon.svg';
import HighTensionIcon from 'assets/images/icons/extendedProfile/high-tension-icon.svg';
import BackupGeneratorTrueIcon from 'assets/images/icons/extendedProfile/backup-generator-true-icon.svg';
import LowTensionIcon from 'assets/images/icons/extendedProfile/low-tension-icon.svg';
import BackupGeneratorIcon from 'assets/images/icons/extendedProfile/backup-generator-icon.svg';
import GeneratorCapacityIcon from 'assets/images/icons/extendedProfile/generator-capacity-icon.svg';
import BelazIcon from 'assets/images/icons/extendedProfile/belaz-icon.svg';
import ManualLoadingIcon from 'assets/images/icons/extendedProfile/manual-loading-icon.svg';
import MechanicalLoadingIcon from 'assets/images/icons/extendedProfile/mechanical-loading-icon.svg';
import DryingFacilitiesIcon from 'assets/images/icons/extendedProfile/drying-facilities-icon.svg';
import CoveredStorageDryerIcon from 'assets/images/icons/extendedProfile/covered-storage-dryer-icon.svg';
import SunDriedIcon from 'assets/images/icons/extendedProfile/sun-dried-icon.svg';
import MaterialHandlingIcon from 'assets/images/icons/extendedProfile/material-handling-icon.svg';
import TractorIcon from 'assets/images/icons/extendedProfile/tractor-icon.svg';
import CraneIcon from 'assets/images/icons/extendedProfile/crane-icon.svg';
import TelescopicHandlerIcon from 'assets/images/icons/extendedProfile/telescopic-handler-icon.svg';
import HammerMillIcon from 'assets/images/icons/extendedProfile/hammer-mill-icon.svg';
import ConveyorSystemIcon from 'assets/images/icons/extendedProfile/conveyor-system-icon.svg';
import ThresherIcon from 'assets/images/icons/extendedProfile/thersher-icon.svg';
import CompactorIcon from 'assets/images/icons/extendedProfile/compactor-icon.svg';
import LoaderIcon from 'assets/images/icons/extendedProfile/loader-icon.svg';
import HydrantSystemIcon from 'assets/images/icons/extendedProfile/hydrant-system-icon.svg';

// Health and Safety icon
import SafetyTrainingIcon from 'assets/images/icons/extendedProfile/safety-training-icon.svg';
import OHSSCertifiedIcon from 'assets/images/icons/extendedProfile/ohss-certified-icon.png';
import ISOCertifiedIcon from 'assets/images/icons/extendedProfile/iso-certified-icon.png';
import GlovesIcon from 'assets/images/icons/extendedProfile/gloves-icon.svg';
import GogglesIcon from 'assets/images/icons/extendedProfile/goggles-icon.svg';
import HelmetIcon from 'assets/images/icons/extendedProfile/helmet-icon.svg';
import HardHatIcon from 'assets/images/icons/extendedProfile/hard-hat-icon.svg';
import SafetyBootsIcon from 'assets/images/icons/extendedProfile/safety-boots-icon.svg';
import IndustrialJacketsIcon from 'assets/images/icons/extendedProfile/industrial-jackets-icon.svg';
import FireAlarmsIcon from 'assets/images/icons/extendedProfile/fire-alarms-icon.svg';
import FireExtinguisherIcon from 'assets/images/icons/extendedProfile/fire-extinguisher-icon.svg';
import FireSprinklerIcon from 'assets/images/icons/extendedProfile/fire-sprinkler-icon.svg';
import SmokeMasksIcon from 'assets/images/icons/extendedProfile/smoke-masks-icon.svg';
import SmokeDetectorIcon from 'assets/images/icons/extendedProfile/smoke-detector-icon.svg';
import FireHouseIcon from 'assets/images/icons/extendedProfile/fire-house-icon.svg';
import FireBucketIcon from 'assets/images/icons/extendedProfile/fire-bucket-icon.svg';
import WeeklyCheckIcon from 'assets/images/icons/extendedProfile/weekly-check-icon.svg';

// Quality & Process icon
import GcvIcon from 'assets/images/icons/extendedProfile/gcv-icon.svg';
import AshIcon from 'assets/images/icons/extendedProfile/ash-icon.svg';
import MoistureIcon from 'assets/images/icons/extendedProfile/moisture-icon.svg';
import FixedCarbonIcon from 'assets/images/icons/extendedProfile/fixed-carbon-icon.svg';
import DryMatterIcon from 'assets/images/icons/extendedProfile/dry-matter-icon.svg';
import CrudeProteinIcon from 'assets/images/icons/extendedProfile/crude-protein-cion.svg';
import DigestibilityIcon from 'assets/images/icons/extendedProfile/digestibility-icon.svg';
import VolatileSolidIcon from 'assets/images/icons/extendedProfile/volatile-solid-icon.svg';

import testingFrequencyIcon from 'assets/images/icons/extendedProfile/testing-frequency-icon.svg';
import reportsBuyerIcon from 'assets/images/icons/extendedProfile/reports-buyer-icon.svg';
import externalLabTestingIcon from 'assets/images/icons/extendedProfile/external-lab-testing-icon.svg';
import InHouseTestingIcon from 'assets/images/icons/extendedProfile/in-house-testing-icon.svg';
import ExternalHouseIcon from 'assets/images/icons/extendedProfile/external-house-icon.svg';
import isoQualityCertificationsIcon from 'assets/images/icons/extendedProfile/iso-quality-certifications-icon.png';
import ISO9001Icon from 'assets/images/icons/extendedProfile/ISO-9001-icon.png';

// Shield Icon
import ShieldGreenIcon from 'assets/images/icons/shield_green.svg';
import ShieldBlueIcon from 'assets/images/icons/shield_blue.svg';
import ShieldVerifiedIcon from 'assets/images/icons/verified-icon.svg';
import ShieldNotverifiedIcon from 'assets/images/icons/notverified.svg';

const NestedRatingsPopup = ({
  toggle,
  color,
  btn,
  title,
  colored,
  header,
  isOpen,
  tickvalue,
  value,
  extendedProfilePersonId,
  id,
  userId,
  addressId,
  companyName
}) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const dispatch = useDispatch();
  // Show and hide cards for raw material
  const [showRawMaterialCard, setShowRawMaterialCard] = useState(false);
  const [showAllRawMaterialCard, setShowAllRawMaterialCard] = useState(true);
  //Location and access people
  const [showLocationPeopleCard, setShowLocationPeopleCard] = useState(false);
  const [showAllLocationPeopleCard, setShowAllLocationPeopleCard] = useState(true);
  //Location and access people
  const [showInfrastructureEquipmentCard, setShowInfrastructureEquipmentCard] = useState(false);
  const [showAllInfrastructureEquipmentCard, setShowAllInfrastructureEquipmentCard] = useState(true);
  // Health and safety people
  const [showHealthSafetyCard, setShowHealthSafetyCard] = useState(false);
  const [showAllHealthSafetyCard, setShowAllHealthSafetyCard] = useState(true);
  // Quality and Process
  const [showQualityProcessCard, setShowQualityProcessCard] = useState(false);
  const [showAllQualityProcessCard, setShowAllQualityProcessCard] = useState(true);
  // coming soon
  const [showComingSoonCard, setShowComingSoonCard] = useState(true);
  // const [showAllComingSoonCard, setShowAllComingSoonCard] = useState(true);

  const extendedProfileData = useSelector((state) => state.extendedProfile);
  const entities = _.get(extendedProfileData, `entities[${addressId}]`, {});
  const extended_profile_enum_code = _.get(entities, `user.profile.extended_profile_enum_code`, '');
  const otherCompanyName = _.get(entities, `user.profile.company_code`,'')
  let extendedUser;
  if(extended_profile_enum_code === `Inspection Confirmed`) {
    extendedUser = _.get(entities, `inspector`, {});
  } else {
    extendedUser = _.get(entities, `user`, {});
  }

  // Quality & Process
  const qualityProcessData = _.get (extendedUser, 'profile') || {};
  const qualityStandardData = _.get(extendedUser, 'quality_standard') || [];
  const qualityTestingFrequencyEnumCode = _.get(qualityProcessData, 'quality_testing_frequency_enum_code') || '';
  const testReportSentToBuyerFrequencyEnumCode = _.get(qualityProcessData, 'test_report_sent_to_buyer_frequency_enum_code') || '';
  const qualityTestingPlaceEnumCode = _.get(qualityProcessData, 'quality_testing_place_enum_code') || '';
  const qualityCertificationEnumCode = _.get(qualityProcessData, 'quality_certification_enum_code') || '';
  const last_Quality_Testing_On = _.get(qualityProcessData, 'last_quality_testing_on', '');
  // const last_Quality_Testing_On_Date_Format = new Date(last_Quality_Testing_On).toLocaleDateString('hi-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const qualityCertificationValidntil = _.get(qualityProcessData, 'quality_certification_valid_until', '');
  // const qualityCertificationValidntil_Date_Format = new Date(qualityCertificationValidntil).toLocaleDateString('hi-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const isQualityProcessData = qualityStandardData.length || qualityTestingFrequencyEnumCode || testReportSentToBuyerFrequencyEnumCode || qualityTestingPlaceEnumCode || qualityCertificationEnumCode;


  const maintainedRecords = _.get(extendedUser, `maintained_records`, []);
  const maintainedRecordsData = maintainedRecords.map((item) => {
    const document_module_enum_code = item.document_module_enum_code;
    return {
      document_module_enum_code,
    };
  });

  let quality_certification_enum_code_icon = '';
  let quality_certification_enum_code_icon_9001 = '';
  switch(qualityCertificationEnumCode) {
    case 'ISO 9001:2008' :
      quality_certification_enum_code_icon = ISO9001Icon;
      break;
    case 'ISO 9001:2015' :
      quality_certification_enum_code_icon = isoQualityCertificationsIcon;
      break;
    case 'Others':
      quality_certification_enum_code_icon = 'Others';
      break;
  }

  let qualityTestingPlaceEnumCodeIcon = '';
  switch(qualityProcessData.quality_testing_place_enum_code) {
    case 'In House':
      qualityTestingPlaceEnumCodeIcon = InHouseTestingIcon;
      break;
    case 'External Lab':
      qualityTestingPlaceEnumCodeIcon = externalLabTestingIcon;
      break;
    case 'Both':
      qualityTestingPlaceEnumCodeIcon = ExternalHouseIcon;
      break;
  }

  const qualityStandardDataMap = qualityStandardData.map((item) => {
    const qualitySpecCode = item.quality_spec_code;

    let quality_spec_code_image_URL;
    let msg = '';
    switch (qualitySpecCode) {
      case 'Moisture':
        // msg = 'Raw';
        quality_spec_code_image_URL = MoistureIcon;
        break;
      case 'GCV':
        // msg = 'Raw';
        quality_spec_code_image_URL = GcvIcon;
        break;
      case 'Ash':
        // msg = 'Raw';
        quality_spec_code_image_URL = AshIcon;
        break;
      case 'Dry Matter':
        // msg = 'Raw';
        quality_spec_code_image_URL = DryMatterIcon;
        break;
      case 'Crude Protein':
        // msg = 'Raw';
        quality_spec_code_image_URL = CrudeProteinIcon;
        break;
      case 'Fixed Carbon':
        // msg = 'Raw';
        quality_spec_code_image_URL = FixedCarbonIcon;
        break;
      case 'Digestability':
        quality_spec_code_image_URL = DigestibilityIcon;
        break;
      case 'Volatile Solids':
        quality_spec_code_image_URL = VolatileSolidIcon;
        break;
    }
    return {
      qualitySpecCode,
      msg,
      quality_spec_code_image_URL,
    };
  });

  const qualityProcessArray = [
    {
      qualityTestingFrequencyEnumCode: `${qualityTestingFrequencyEnumCode}`, type: 'enum', testingFrequencyIcon: testingFrequencyIcon, title: `${qualityTestingFrequencyEnumCode}`,
    },
    {
      testReportSentToBuyerFrequencyEnumCode: `${testReportSentToBuyerFrequencyEnumCode}`, type: 'enum', reportBuyerIcon: reportsBuyerIcon, title: `${testReportSentToBuyerFrequencyEnumCode}`,
    },
    {
      qualityTestingPlaceEnumCode: `${qualityTestingPlaceEnumCode}`, type: 'enum', icon: qualityTestingPlaceEnumCodeIcon, title: `${qualityTestingPlaceEnumCode}`, popoverText: 'Quality Tests'
    },
    {
      qualityCertificationEnumCode: `${qualityCertificationEnumCode}`, type: 'enum', title: `${qualityCertificationEnumCode}`, qualityCertificationEnumCodeIcon: `${quality_certification_enum_code_icon}`, popoverText: 'Quality Certifications',
    },
  ]

  // Health & Safety
  const healthSafetyData = _.get (extendedUser, 'profile') || {};
  const healthSafetyCertificationEnumCode = _.get(healthSafetyData, 'health_and_safety_certification_enum_code') || '';
  const health_and_safety_certification_valid_until = _.get(healthSafetyData, 'health_and_safety_certification_valid_until', '');
  // const health_and_safety_certification_valid_until_Date_Format = new Date(health_and_safety_certification_valid_until).toLocaleDateString('hi-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const quality_certification_valid_until = _.get(healthSafetyData, 'quality_certification_valid_until', '');
  // const quality_certification_valid_until_Date_Format = new Date(quality_certification_valid_until).toLocaleDateString('hi-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const safetyEqipmentsData = _.get(extendedUser, 'safety_equipments') || [];
  const fireFightingEquipmentsData = _.get(extendedUser, 'fire_fighting_equipments') || [];
  const lastSafetyTrainingOn = _.get(healthSafetyData, 'last_safety_training_on') || '';
  // const last_safety_training_on_date_format = new Date(lastSafetyTrainingOn).toLocaleDateString('hi-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const safety_training_frequency_enum_code = _.get(healthSafetyData, 'safety_training_frequency_enum_code') || '';
  const incidentFreeDays = _.get(healthSafetyData, 'incident_free_days') || '';
  const weeklyCheck = _.get(healthSafetyData, 'health_checkup_frequency_enum_code') || '';
  // const isThereHealthSafetyData = safetyEqipmentsData || healthSafetyCertificationEnumCode || lastSafetyTrainingOn || safety_training_frequency_enum_code || incidentFreeDays || weeklyCheck || loadingFacilitiesTypeEnumCodeData || isLoadingUnloadingAllowedAtNightData || dryingFacilityEnumCodeData;
  const isThereHealthSafetyData = safetyEqipmentsData.length || fireFightingEquipmentsData.length || healthSafetyCertificationEnumCode || lastSafetyTrainingOn || safety_training_frequency_enum_code || incidentFreeDays || weeklyCheck;

  let healthAndSafetyCertificationEnumCodeIcon = '';
  let otherIcon = '';
  switch(healthSafetyCertificationEnumCode) {
    case 'OHSAS 18001':
      healthAndSafetyCertificationEnumCodeIcon = OHSSCertifiedIcon;
      break;
    case 'ISO 45001':
      healthAndSafetyCertificationEnumCodeIcon = ISOCertifiedIcon;
      break;
    case 'Other':
      otherIcon = 'Other';
      break;
  }

  const safetyEqipmentsCodeData = safetyEqipmentsData.map((item) => {
    const safetyEquipmentTypeEnumCode = item.safety_equipment_type_enum_code;

    let safety_equipment_type_enum_image_URL;
    let msg = '';
    switch (safetyEquipmentTypeEnumCode) {
      case 'SafetyGoggles':
        safety_equipment_type_enum_image_URL = GogglesIcon;
        break;
      case 'HardHat':
        safety_equipment_type_enum_image_URL = HardHatIcon;
        break;
      case 'Helmet':
        safety_equipment_type_enum_image_URL = HelmetIcon;
        break;
      case 'Gloves':
        safety_equipment_type_enum_image_URL = GlovesIcon;
        break;
      case 'SafetyShoes':
        safety_equipment_type_enum_image_URL = SafetyBootsIcon;
        break;
      case 'Overalls':
        safety_equipment_type_enum_image_URL = IndustrialJacketsIcon;
        break;
    }
    return {
      safetyEquipmentTypeEnumCode,
      msg,
      safety_equipment_type_enum_image_URL,
    };
  });
  let newSafetyEqipmentsCodeDataArray = safetyEqipmentsCodeData.slice(0, 4); // show only 4 products

  const equipmentsData = _.get(extendedUser, `equipments`) || [];
  // const equipments = equipmentsData.filter((item) => item.is_equipment === true);

  const equipmentsCodeData = equipmentsData.map((item) => {
    const is_equipment = item.is_equipment;
    const make_of_machine = item.make_of_machine;
    const machineNameEnumCode = item.machine_name_enum_code;
    const Commodity_Form_Code = item.commodity_form_code;
    const Commodity_type_Code = item.commodity_type_code;
    const BreakDown_Type_Enum_Code = item.breakdown_type_enum_code
    const Capacity = item.capacity;
    const Utilized_Capacity = item.utilized_capacity;

    let manufacturing_image_URL;
    if( make_of_machine){
      manufacturing_image_URL = BioIndustrialIcon;
    }
    // if( Commodity_type_Code === 'Biomass' && Commodity_Form_Code === 'Briquette'){
    //   manufacturing_image_URL = BioIndustrialIcon;
    // }
    // if( Commodity_type_Code === 'Biomass' && Commodity_Form_Code === 'Industrial'){
    //   manufacturing_image_URL = BioIndustrialIcon;
    // }
    // if( Commodity_type_Code === 'Biomass' && Commodity_Form_Code === 'Pellet'){
    //   manufacturing_image_URL = BioIndustrialIcon;
    // }
    // if( Commodity_type_Code === 'Biomass' && Commodity_Form_Code === 'Raw'){
    //   manufacturing_image_URL = BioIndustrialIcon;
    // }
    // if( Commodity_type_Code === 'Biomass' && Commodity_Form_Code === 'Silage'){
    //   manufacturing_image_URL = BioIndustrialIcon;
    // }

    let machine_name_enum_code_image_URL;
    let msg = '';
    switch (machineNameEnumCode) {
      case 'Crane':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = CraneIcon;
        break;
      case 'Tractor':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = TractorIcon;
        break;
      case 'Telescopic Handler':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = TelescopicHandlerIcon;
        break;
      case 'Hammer Mill':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = HammerMillIcon;
        break;
      case 'Conveyor System':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = ConveyorSystemIcon;
        break;
      case 'Thresher':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = ThresherIcon;
        break;
      case 'Compactor':
        // msg = 'Raw';
        machine_name_enum_code_image_URL = CompactorIcon;
        break;
      case 'Loader':
        machine_name_enum_code_image_URL = LoaderIcon;
        break;
    }
    return {
      is_equipment,
      Commodity_Form_Code,
      Commodity_type_Code,
      BreakDown_Type_Enum_Code,
      Capacity,
      Utilized_Capacity,
      make_of_machine,
      machineNameEnumCode,
      msg,
      manufacturing_image_URL,
      machine_name_enum_code_image_URL,
    };
  });

  let newEquipmentsCodeDataArray = equipmentsCodeData.slice(0, 3); // show only 4 products

  const fireFightingEquipmentTypeEnumData = fireFightingEquipmentsData.map((item) => {
    const fireFightingEquipmentTypeEnumCode = item.fire_fighting_equipment_type_enum_code;

    let fire_fighting_equipment_image_URL;
    let msg = '';
    switch (fireFightingEquipmentTypeEnumCode) {
      case 'Sprinkler':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = FireSprinklerIcon;
        break;
      case 'Fire Extinguisher':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = FireExtinguisherIcon;
        break;
      case 'Helmets':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = HelmetIcon;
        break;
      case 'Fire Alarms':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = FireAlarmsIcon;
        break;
      case 'Masks':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = SmokeMasksIcon;
        break;
      case 'Smoke Detector':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = SmokeDetectorIcon;
        break;
      case 'Fire Hose':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = FireHouseIcon;
        break;
      case 'Fire Buckets':
        // msg = 'Raw';
        fire_fighting_equipment_image_URL = FireBucketIcon;
        break;
      case 'Hydrant System':
        fire_fighting_equipment_image_URL = HydrantSystemIcon;
        break;
      }
    return {
      fireFightingEquipmentTypeEnumCode,
      msg,
      fire_fighting_equipment_image_URL,
    };
  });
  let newFireFightingEquipmentTypeEnumDataArray = fireFightingEquipmentTypeEnumData.slice(0, 3); // show only 4 products

  const healthSafetyArray = [
    {
      healthSafetyCertificationEnumCode: `${healthSafetyCertificationEnumCode}`, type: 'enum', otherIcon: `${otherIcon}`, ohssIcon: `${healthAndSafetyCertificationEnumCodeIcon}`, popoverText: 'Health Certification',
    },
    {
      lastSafetyTrainingOn: `${lastSafetyTrainingOn}`, type: 'enum', SafetyTrainingIcon: SafetyTrainingIcon, SafetyTrainingTitle: `${safety_training_frequency_enum_code}`, popoverText: 'Safety training'
    },
    {
      incidentFreeDays: `${incidentFreeDays}`, text: `${incidentFreeDays}`, MultiText: 'Incident Free Days',
    },
    {
      weeklyCheck: `${weeklyCheck}`, type: 'enum', WeeklyCheckIcon: WeeklyCheckIcon, WeeklyCheckTitle: `${weeklyCheck}`, popoverText: 'Health Check ups',
    },
  ]

  // Infrastructure & Equipments
  const infraEquipmentsData = _.get(extendedUser, 'profile') || {} || null;
  const powerCutFrequencyEnumCodeData = _.get(infraEquipmentsData, 'power_cut_frequency_enum_code') || '';
  const connectedLoadEnumCodeData = _.get(infraEquipmentsData, 'connected_load_enum_code') || '';
  const isGeneratorAvailableData = _.get(infraEquipmentsData, 'is_generator_available') || '';
  // const generatorCapacity = _.get(infraEquipmentsData, 'generator_capacity') || '';
  const vehicleTypeCodeData = _.get(infraEquipmentsData, 'vehicle_type_code') || '';
  const loadingFacilitiesTypeEnumCodeData = _.get(infraEquipmentsData, 'loading_facilities_type_enum_code') || '';
  const isLoadingUnloadingAllowedAtNightData = _.get(infraEquipmentsData, 'is_loading_unloading_allowed_at_night') || false;
  const dryingFacilityEnumCodeData = _.get(infraEquipmentsData, 'drying_facility_enum_code') || '';
  const isThereInfrastructureData = powerCutFrequencyEnumCodeData || connectedLoadEnumCodeData || connectedLoadEnumCodeData || isGeneratorAvailableData || vehicleTypeCodeData || loadingFacilitiesTypeEnumCodeData || isLoadingUnloadingAllowedAtNightData || dryingFacilityEnumCodeData;

  let connectedLoadEnumCodeIcon = '';
  switch(infraEquipmentsData.connected_load_enum_code) {
    case 'High Tension':
      connectedLoadEnumCodeIcon = HighTensionIcon;
      break;
    case 'Low Tension':
      connectedLoadEnumCodeIcon = LowTensionIcon;
      break;
  }

  const loading_facilities_type_enum_code = _.get(extendedUser, `profile.loading_facilities_type_enum_code`, '');
  let loading_facilities_type_enum_code_icon = '';
  switch(loading_facilities_type_enum_code) {
    case 'Manual' :
      loading_facilities_type_enum_code_icon = ManualLoadingIcon;
      break;
    case 'Mechanised' :
      loading_facilities_type_enum_code_icon = MechanicalLoadingIcon;
      break;
  }

  let dryingFacilityEnumCodeIcon = '';
  switch(infraEquipmentsData.drying_facility_enum_code) {
    case 'Using a Dryer':
      dryingFacilityEnumCodeIcon = DryingFacilitiesIcon;
      break;
    case 'Sun Dried':
      dryingFacilityEnumCodeIcon = SunDriedIcon;
      break;
    case 'Covered Storage & Dryer':
      dryingFacilityEnumCodeIcon = CoveredStorageDryerIcon;
      break;
    case 'Covered Storage':
      dryingFacilityEnumCodeIcon = StorageCoverIcon;
      break;
    case 'No Facility':
      dryingFacilityEnumCodeIcon = UnclearIcon;
      break;
  }
  const InfraEquipmentsArray = [
    {
      title: `${powerCutFrequencyEnumCodeData}`, type: 'enum', icon: PowerSupplyIcon, text: `${powerCutFrequencyEnumCodeData}`, popoverText: 'Power disruption /Week',
    },
    {
      title: `${connectedLoadEnumCodeData}`, type: 'enum', icon: connectedLoadEnumCodeIcon, MultiText: `${connectedLoadEnumCodeData}`, popoverText: 'Connected Load'
    },
    {
      title: `${isGeneratorAvailableData}`, type: 'enum', backupGeneratorIcon: `${isGeneratorAvailableData === true ? BackupGeneratorTrueIcon : BackupGeneratorIcon}`, MultiText: 'Backup Generator'
    },
    // {
    //   title: `${generatorCapacity}`, type: 'enum', generatorCapacityIcon: GeneratorCapacityIcon, value: `${generatorCapacity}`, MultiText: 'Generator Capacity'
    // },
    {
      title: `${vehicleTypeCodeData}`, type: 'enum', icon: BelazIcon, text: `${vehicleTypeCodeData}`, popoverText: 'Largest truck size'
    },
    {
      title: `${loadingFacilitiesTypeEnumCodeData}`, type: 'enum', icon: loading_facilities_type_enum_code_icon, text: `${loadingFacilitiesTypeEnumCodeData}`, popoverText: 'Loading Facility'
    },
    {
      title: `${isLoadingUnloadingAllowedAtNightData}`, isLoadingUnloadingAllowedAtNightData:`${isLoadingUnloadingAllowedAtNightData === true ? isLoadingUnloadingAllowedAtNightData : '' }`, type: 'enum', text: `${isLoadingUnloadingAllowedAtNightData === true ? 'Night Loading' : ''}`
    },
    {
      title: `${dryingFacilityEnumCodeData}`, type: 'enum', dryingFacilityEnumCodeIcon: dryingFacilityEnumCodeIcon, MultiText: `${dryingFacilityEnumCodeData}`, popoverText: 'Drying Methods'
    },
  ]

  // Location Access & People
  const rawMaterialProductsData = _.get(extendedUser, 'profile') || {} || null;
  const address_text = _.get(rawMaterialProductsData, 'address_text') || '';
  const ownRentedEnumCodeData = _.get(rawMaterialProductsData, 'own_rented_enum_code') || '';
  const facilityStartYearData = _.get(rawMaterialProductsData, 'facility_start_year') || '';
  const totalMenEmployedData = _.get(rawMaterialProductsData, 'total_men_employed') || '';
  const totalWomenEmployedData = _.get(rawMaterialProductsData, 'total_women_employed') || '';
  const rawMaterialsCoveredInMt = _.get(extendedUser, 'storages[1].covered_space_in_mt') || 0;
  const rawMaterialsOpenInMt = _.get(extendedUser, 'storages[1].open_space_in_mt') || 0;
  const finishedGoodsCoveredInMT = _.get(extendedUser, 'storages[3].covered_space_in_mt') || 0;
  const finishedGoodsOpenInMT = _.get(extendedUser, 'storages[3].open_space_in_mt') || 0;
  // const openAreaData = _.get(rawMaterialProductsData, 'open_area') || '';
  // const noOfEmployess = parseInt(totalMenEmployedData) + parseInt(totalWomenEmployedData) || '';
  const facilityAreaData = _.get(rawMaterialProductsData, 'facility_area') || '';
  const roadTypeEnumCodeData = _.get(rawMaterialProductsData, 'road_type_enum_code') || '';
  const isThereLocationAccessData = ownRentedEnumCodeData || facilityStartYearData|| totalMenEmployedData || rawMaterialsCoveredInMt || rawMaterialsOpenInMt || finishedGoodsCoveredInMT || finishedGoodsOpenInMT || facilityAreaData || roadTypeEnumCodeData;


  let roadTypeEnumCodeDataIcon = '';
  switch(roadTypeEnumCodeData) {
    case 'Damaged' :
      roadTypeEnumCodeDataIcon = DamagedIcon;
      break;
    case 'Gravel' :
      roadTypeEnumCodeDataIcon = GravelIcon;
      break;
    case 'Murum' :
      roadTypeEnumCodeDataIcon = GravelIcon;
      break;
    case 'Mud' :
      roadTypeEnumCodeDataIcon = MudIcon;
      break;
    case 'Concrete / Tar' :
      roadTypeEnumCodeDataIcon = ConcreteTarIcon;
      break;
    }

  let facilityOwnRentedIcon = '';
  let unclearIcon = '';
  switch(rawMaterialProductsData.own_rented_enum_code) {
    case 'Owned':
      facilityOwnRentedIcon = OwnedIcon;
      break;
    case 'Rented':
      facilityOwnRentedIcon = RentedIcon;
      break;
    case 'Unclear':
      unclearIcon = UnclearIcon;
      break;
  }
  const ProfileArray = [
    {
      ownRentedEnumCode: `${ownRentedEnumCodeData}`, type: 'enum', ownRentedIcon: facilityOwnRentedIcon, unclearIcon: unclearIcon, ownRentedtext: `${ownRentedEnumCodeData}`, popoverText: 'Type of facility'
    },
    {
      facilityStartYear: `${facilityStartYearData}`, yearsInOperationText: `In Operation`,
    },
    {
      totalMenEmployed: `${totalMenEmployedData}`, type: 'enum', menWomenIconIcon: MenWomenIconIcon, totalMenEmployed: `${totalMenEmployedData}`, totalWomenEmployed: `${totalWomenEmployedData}`
    },
    {
      type: 'Storage1', storageCoveredIcon: StorageCoverIcon, text: 'Storage Covered', rawMaterialsCoveredInMT: `${utils.formatQtyPricePerUOM(rawMaterialsCoveredInMt)}`, rawMaterialsCoveredUsableInMT: `${utils.formatQtyPricePerUOM(finishedGoodsCoveredInMT)}`,
    },
    {
      type: 'Storage2', storageOpenedIcon: StorageOpenIcon, text: 'Storage Open', rawMaterialsOpenInMT: `${utils.formatQtyPricePerUOM(rawMaterialsOpenInMt)}`, rawMaterialsOpenUsableInMT: `${utils.formatQtyPricePerUOM(finishedGoodsOpenInMT)}`,
    },
    {
      facilityArea: `${facilityAreaData}`, type: 'enum', facilityAreaIcon: FacilityAreaIcon, text: 'Area', facilityAreaText: `${utils.formatQtyPricePerUOM(facilityAreaData)}`,
    },
    {
      roadTypeEnumCode: `${roadTypeEnumCodeData}`, type: 'enum', roadTypeEnumIcon: roadTypeEnumCodeDataIcon, roadTypeEnumText: `${roadTypeEnumCodeData}`
    },
  ]


  // Raw Material & Products
  const productsData = _.get(extendedUser, 'products') || [];
  const rawMaterialSuppliersCountData = _.get(extendedUser, 'profile.raw_material_suppliers_count') || '';
  const rawMaterialProcurementRangeFrom = _.get(extendedUser, 'profile.raw_material_procurement_range_from', '');
  const rawMaterialProcurementRangeTo = _.get(extendedUser, 'profile.raw_material_procurement_range_to', '');
  const rawMaterial = _.get(extendedUser, 'raw_material_and_product', []);
  const isRawMaterialProductsData = productsData.length || rawMaterialSuppliersCountData || rawMaterialProcurementRangeFrom || rawMaterialProcurementRangeTo || rawMaterial.length;

  const rawMaterialData = rawMaterial.map((item) => {
    const commodityCode = item.commodity_code;
    const commodityResidueCode = item.commodity_residue_code;

    let commodity_code_image_URL;
    switch (commodityCode) {
      case 'Mustard and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Wood and Bamboo':
        commodity_code_image_URL = InformationIcon;
        break;
       case 'Cotton and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Corn and Cotton':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Mustard and Groundnut':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Groundnut and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Sugar cane and Coffee':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Sugar cane and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Corn and Sugar cane':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Soyabean and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Cotton and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Corn and Cotton':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Castor and Wood':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Soyabean and Groundnut':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Sugar cane and Rice':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Corn and Sugar cane':
        commodity_code_image_URL = InformationIcon;
        break;
      case 'Groundnut':
        commodity_code_image_URL = GrounduntIcon;
        break;
      case 'Bamboo':
        commodity_code_image_URL = BambooIcon;
        break;
      case 'Coffee':
        commodity_code_image_URL = CoffeeIcon;
        break;
      case 'Cumin Seed':
      case 'Cumin':{
        commodity_code_image_URL = CuminSeedIcon;
        break;
      }
      case 'Biochar':
      case 'Biofertilizer':{
        commodity_code_image_URL = BiocharIcon;
        break;
      }
      case 'Palm':
        commodity_code_image_URL = PalmIcon;
        break;
      case 'Compressed Bio Gas':
        commodity_code_image_URL = CompressedBiogasIcon;
        break;
      case 'Soyabean':
      case 'Soybean':{
        commodity_code_image_URL = SoybeanIcon;
        break;
      }
      case 'Cotton':
        commodity_code_image_URL = CottonIcon;
        break;
      case 'Wheat':
      case 'Niger':
      case 'Linseed':
      case 'Rice':
      case 'Sesame': {
        commodity_code_image_URL = RiceWheatIcon;
        break;
      }
      case 'Cumin Seed':
        commodity_code_image_URL = SugarCaneIcon;
        break;
      case 'Sugar cane':
        commodity_code_image_URL = SugarCaneIcon;
        break;
      case 'Corn':
        commodity_code_image_URL = CornIcon;
        break;
      case 'Wood':
      case 'Teak Wood':
      case 'Casuarina Wood':
      case 'Mixed Wood': {
        commodity_code_image_URL = WoodIcon;
        break;
      }
      case 'Jowar':
      case 'Ragi':
      case 'Bajra':
      case 'Barley':{
        commodity_code_image_URL = JowerBajraIcon;
        break;
      }
      case 'Coconut':
        commodity_code_image_URL = CoconutIcon;
        break;
      case 'Tur (arhar)':
      case 'Gaur':
      case 'Gram':
      case 'Lentil':
      case 'Sunflower':
      case 'Safflower':
      case 'Mustard':
      case 'Small Millet':
      case 'Rapeseed':
      case 'Castor Seed':
      case 'Castor':
      case 'Moong':
      case 'Urad': {
        commodity_code_image_URL = TurIcon;
        break;
      }
      case 'Arecanut':
        commodity_code_image_URL = ArecanutIcon;
        break;
      case 'Banana':
        commodity_code_image_URL = BananaIcon;
        break;
      case 'Jute':
      case 'Grass':{
        commodity_code_image_URL = JuteIcon;
        break;
      }
      case 'Gooseberry':
        commodity_code_image_URL = GooseberryIcon;
        break;
      case 'Coriander':
        commodity_code_image_URL = CorianderIcon;
        break;
      case 'Cashew':
        commodity_code_image_URL = CashewsIcon;
        break;
      case 'Eucalyptus':
      case 'Eucalyptus Wood': {
        commodity_code_image_URL = EucalyptusIcon;
        break;
      }
      case 'Astillas': {
        commodity_code_image_URL = AtillaIcon;
        break;
      }
    }
    return {
      commodityCode,
      commodityResidueCode,
      commodity_code_image_URL,
    };
  });

  const productsCommodityFormCode = productsData.map((item) => {
    let post_image_URL;
    const commodityTypeCode = item.commodity_type_code;
    const commodityFormCode = item.commodity_form_code;

    let msg = '';

    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Raw'){
      post_image_URL = BiomassRawIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Industrial'){
      post_image_URL = BiomassIndustrialIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Pellet'){
      post_image_URL = PelletsIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Long Pellets'){
      post_image_URL = LongPelletsIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Briquette'){
      post_image_URL = BiomassRawIcon;
    }
    if( commodityTypeCode === 'BioMed' && commodityFormCode === 'Briquette'){
      post_image_URL = BiomedBriqutteIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Silage'){
      post_image_URL = BiomassSilageIcon;
    }
    if( commodityTypeCode === "BioDegradable" && commodityFormCode === 'Raw'){
      post_image_URL = BiodegradableRawIcon;
    }
    if( commodityTypeCode === "BioDegradable" && commodityFormCode === 'BioEnng'){
      post_image_URL = BiodegradableRawIcon;
    }
    if( commodityTypeCode === "Biomass" && commodityFormCode === 'CForm1'){
      post_image_URL = ProuctsBriquttesIcon;
    }
    if( commodityTypeCode === 'Biomass' && commodityFormCode === 'Liquid'){
      post_image_URL = BiomassLiquidIcon;
    }
    if( commodityTypeCode === 'Biogas'){
      post_image_URL = BiogasIcon;
    }
    if( commodityTypeCode === 'BioEngg' && commodityFormCode === 'Raw'){
      post_image_URL = BioEnggIcon;
    }
    if( commodityTypeCode === 'BioFuel' && commodityFormCode === 'Long Pellet'){
      post_image_URL = BiofuelLongPelletsIcon;
    }
    if( commodityTypeCode === 'By Products' && commodityFormCode === 'Solid'){
      post_image_URL = productSolidIcon;
    }
    if( commodityTypeCode === 'By Products' && commodityFormCode === 'Liquid'){
      post_image_URL = productLiquidIcon;
    }
    return {
      commodityTypeCode,
      commodityFormCode,
      msg,
      post_image_URL,
    };
  });

  let newCommodityFormCodeArray = productsCommodityFormCode.slice(0, 4); // show only 2 products

  useEffect(() => {
    dispatch(profileActions.fetchDetails(addressId));
  }, [isOpen]);

  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  const rawMaterialViewMoreClick = () => {
    setShowRawMaterialCard(true);
    setShowAllLocationPeopleCard(false);

    setShowAllRawMaterialCard(false);
  }

  const rawMaterialCloseIconClick = () => {
    setShowRawMaterialCard(false);
    setShowAllLocationPeopleCard(true);
    setShowAllRawMaterialCard(true);
  }

  const LocationPeopleViewMoreClick = () => {
    setShowLocationPeopleCard(true);
    setShowAllRawMaterialCard(false);
    setShowAllLocationPeopleCard(false);
  }

  const LocationPeopleCloseIconClick = () => {
    setShowLocationPeopleCard(false);
    setShowAllRawMaterialCard(true);
    setShowAllLocationPeopleCard(true);
  }

  const InfrastructureEquipmentViewMoreClick = () => {
    setShowInfrastructureEquipmentCard(true);
    setShowAllHealthSafetyCard(false);
    setShowAllInfrastructureEquipmentCard(false);
  }

  const InfrastructureEquipmentCloseIconClick = () => {
    setShowInfrastructureEquipmentCard(false);
    setShowAllHealthSafetyCard(true);
    setShowAllInfrastructureEquipmentCard(true);
  }

  const HealthSafetyViewMoreClick = () => {
    setShowHealthSafetyCard(true);
    setShowAllInfrastructureEquipmentCard(false);
    setShowAllHealthSafetyCard(false);
  }

  const HealthSafetyCloseIconClick = () => {
    setShowHealthSafetyCard(false);
    setShowAllInfrastructureEquipmentCard(true);
    setShowAllHealthSafetyCard(true);
  }

  const QualityProcessViewMoreClick = () => {
    setShowQualityProcessCard(true);
    setShowComingSoonCard(false);
    setShowAllQualityProcessCard(false);
  }

  const QualityProcessCloseIconClick = () => {
    setShowQualityProcessCard(false);
    setShowComingSoonCard(true);
    setShowAllQualityProcessCard(true);
  }

  const FireEvent = () => {
    setIsMouseEnter(true)
  }
  useEffect(()=>{
    if(isMouseEnter && otherCompanyName){
      gtag('event', 'Supplier_profile_drill_down_2',
      {
        extendedProfilePersonId : extendedProfilePersonId,
        extendedProfileId : id,
        extendedProfileAddressId : addressId,
        userId : userId ? userId : null,
        companyName : companyName,
        otherCompanyName : otherCompanyName
      })
    }
  },[isMouseEnter,otherCompanyName])

  return (
    <div className="bfc-post-text-editor-modal" onLoad={FireEvent}>
      <div className="bfc-follow-editor-section">
        <Button className="d-none" color={color} onClick={toggle}>
          {btn}
        </Button>
        <Modal
          zIndex={1500}
          isOpen={isOpen}
          toggle={toggle}
          modalClassName="ltr-support theme-light bfc-post-text-editor-modal supplier-drill__down-modal"
          className={`modal-dialog--${color} ${modalClass} `}>
          <Formik initialValues={{}}>
            {(formikBag) => {
              const { values } = formikBag;
              return (
                <Form className="material-form extended-modal">
                  <div
                    className="modal__header"
                    style={{
                      borderBottom: 'transparent!important',
                      backgroundColor: '#F1F2F3',
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }}>
                    <p className="text-modal modal__title bfc-modalTitle d-flex justify-content-between">
                      <div
                        className="d-flex justify-content-center align-items-center ml-auto mr-auto"
                        style={{ gap: '10px' }}>
                        <ShieldIcon
                          tickvalue={tickvalue}
                          shieldText={true}
                        />
                        {/* <img src={shieldIcon} alt="" height="20" style={{ width: 'auto' }} />
                        <p className="m-0 fw-900" style={{ color: 'var(--unnamed-color-1b62ab)', fontFamily: 'sans-serif' }}>{message}</p> */}
                      </div>
                      <button aria-label="Close" className=" close" type="button" onClick={toggle}>
                        <span aria-hidden>×</span>
                      </button>
                    </p>
                  </div>
                  <div
                    className="modal__body extended__body p-0">
                    <div className="extended-container">
                      <div className="row">
                        {showAllRawMaterialCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Raw Material & Products'}
                              NoSuppliersIcon={NoSuppliersIcon}
                              isRawMaterialProductsData={isRawMaterialProductsData}
                              productsCommodityFormCode={newCommodityFormCodeArray}
                              rawMaterialSuppliersCountData={rawMaterialSuppliersCountData}
                              rawMaterialProcurementRangeFrom={rawMaterialProcurementRangeFrom}
                              rawMaterialProcurementRangeTo={rawMaterialProcurementRangeTo}
                              rightSideIcon={<RawMaterialProductsIcon size={30} />}
                              rawData={true}
                              viewMore={true}
                              onClick={() => rawMaterialViewMoreClick()}
                            />
                          </div>
                        )}

                        {showRawMaterialCard && (
                          <div className="col-12 col-md-12 mb-0 p-5">
                            <SubExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Raw Material & Products'}
                              NoSuppliersIcon={NoSuppliersIcon}
                              rawMaterialData={rawMaterialData}
                              productsCommodityFormCode={productsCommodityFormCode}
                              rawMaterialSuppliersCountData={rawMaterialSuppliersCountData}
                              rawMaterialProcurementRangeFrom={rawMaterialProcurementRangeFrom}
                              rawMaterialProcurementRangeTo={rawMaterialProcurementRangeTo}
                              rightSideIcon={<RawMaterialProductsIcon size={30} />}
                              rawData={true}
                              viewMore={true}
                              onClick={() => rawMaterialCloseIconClick()}
                            />
                          </div>
                        )}

                        {showAllLocationPeopleCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Location, Access & People'}
                              rawMaterialProductsData={ProfileArray}
                              isThereLocationAccessData={isThereLocationAccessData}
                              rawMaterialProductsData1={rawMaterialProductsData}
                              rightSideIcon={<LocationAccessIcon size={30} />}
                              locationAccessData={true}
                              viewMore={true}
                              onClick={() => LocationPeopleViewMoreClick()}
                            />
                          </div>
                        )}

                        {showLocationPeopleCard && (
                          <div className="col-12 col-md-12 mb-0 p-5">
                            <SubExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Location, Access & People'}
                              rawMaterialProductsData={ProfileArray}
                              rawMaterialProductsData1={rawMaterialProductsData}
                              isThereLocationAccessData={isThereLocationAccessData}
                              addressText={address_text}
                              rightSideIcon={<LocationAccessIcon size={30} />}
                              locationAccessData={true}
                              viewMore={true}
                              onClick={() => LocationPeopleCloseIconClick()}
                            />
                          </div>
                        )}

                        {showAllInfrastructureEquipmentCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Infrastructure & Equipment'}
                              infraEquipmentsArray={InfraEquipmentsArray}
                              isThereInfrastructureData={isThereInfrastructureData}
                              infraEquipmentsData={infraEquipmentsData}
                              equipmentsCodeData={equipmentsCodeData}
                              rightSideIcon={<InfrastructureEquipmentIcon size={30} />}
                              infrastructureEquipmentData={true}
                              viewMore={true}
                              onClick={() => InfrastructureEquipmentViewMoreClick()}
                            />
                          </div>
                        )}

                        {showInfrastructureEquipmentCard && (
                          <div className="col-12 col-md-12 mb-0 p-5">
                            <SubExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Infrastructure & Equipment'}
                              infraEquipmentsArray={InfraEquipmentsArray}
                              equipmentsCodeData={equipmentsCodeData}
                              rightSideIcon={<InfrastructureEquipmentIcon size={30} />}
                              infrastructureEquipmentData={true}
                              viewMore={true}
                              onClick={() => InfrastructureEquipmentCloseIconClick()}
                            />
                          </div>
                        )}

                        {showAllHealthSafetyCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Health & Safety'}
                              healthSafetyArray={healthSafetyArray}
                              healthSafetyData={healthSafetyData}
                              isThereHealthSafetyData={isThereHealthSafetyData}
                              newSafetyEqipmentsCodeDataArray={newSafetyEqipmentsCodeDataArray}
                              newFireFightingEquipmentTypeEnumDataArray={newFireFightingEquipmentTypeEnumDataArray}
                              rightSideIcon={<HealthSafetyIcon size={30} />}
                              healthSafety={true}
                              viewMore={true}
                              onClick={() => HealthSafetyViewMoreClick()}
                            />
                          </div>
                        )}

                        {showHealthSafetyCard && (
                          <div className="col-12 col-md-12 mb-0 p-5">
                            <SubExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Health & Safety'}
                              healthSafetyArray={healthSafetyArray}
                              safetyEqipmentsCodeData={safetyEqipmentsCodeData}
                              fireFightingEquipmentTypeEnumData={fireFightingEquipmentTypeEnumData}
                              rightSideIcon={<HealthSafetyIcon size={30} />}
                              healthSafety={true}
                              viewMore={true}
                              onClick={() => HealthSafetyCloseIconClick()}
                            />
                          </div>
                        )}

                        {showAllQualityProcessCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Quality & Processes'}
                              qualityStandardData={qualityStandardDataMap}
                              qualityProcessArray={qualityProcessArray}
                              isQualityProcessData={isQualityProcessData}
                              maintainedRecordsData={maintainedRecordsData}
                              rightSideIcon={<QualityProcessIcon size={30} />}
                              qualityProcess={true}
                              viewMore={true}
                              onClick={() => QualityProcessViewMoreClick()}
                            />
                          </div>
                        )}

                        {showQualityProcessCard && (
                          <div className="col-12 col-md-12 mb-0 p-5">
                            <SubExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Quality & Processes'}
                              qualityStandardData={qualityStandardDataMap}
                              maintainedRecordsData={maintainedRecordsData}
                              qualityProcessArray={qualityProcessArray}
                              rightSideIcon={<QualityProcessIcon size={30} />}
                              qualityProcess={true}
                              viewMore={true}
                              onClick={() => QualityProcessCloseIconClick()}
                            />
                          </div>
                        )}

                        {showComingSoonCard && (
                          <div className="col-12 col-sm-6 col-xl-6 mb-0 p-5">
                            <ExtendedProfileCard
                              userId={userId}
                              extendedProfilePersonId={extendedProfilePersonId}
                              extendedProfileId={id}
                              extendedProfileAddressId={addressId}
                              heading={'Sustainable Business Practices'}
                              middleText={'Coming Soon'}
                              comingSoon={true}
                              rightSideIcon={<SustainableBusinessIcon size={30} />}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </div>
  );
};

NestedRatingsPopup.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

NestedRatingsPopup.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
  color: 'primary',
};

export default NestedRatingsPopup;
