import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  mySubscriptions(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  mySubscriptions(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: _.get(d, 'subscription_id') || 0,
        ...d,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
};
