import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Table } from 'antd';
import { Button } from 'reactstrap';

import { actions as farmerActions } from 'redux/farmerOnboarding';
import AntTableActions from 'common/TableActions';

const SearchIFSCForm = ({ formikBag, setIsOpen }) => {
  const farmerOnboarding = useSelector((state) => state.farmerOnboarding);

  const dispatch = useDispatch();

  const [bankName, setBankName] = useState('');
  const [area, setArea] = useState('');
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    dispatch(farmerActions.getBankNames());
  }, []);

  useEffect(() => {
    if (farmerOnboarding?.bankList?.length) {
      const modifiedData = farmerOnboarding.bankList.filter((item) => item !== '' || item !== null || item !== ' ');
      setBankList(modifiedData);
    }
  }, [farmerOnboarding?.bankList]);

  const BANK_TABLE_HEADER = [
    {
      title: 'Bank Name',
      key: 'bank_name',
      dataIndex: 'bank_name',
      align: 'left',
      ellipsis: true,
      width: '10px',
      render: (text, row) => <div className="bfc-table-list-components">{`${row.bank_name} - ${row.branch}`}</div>,
    },
    {
      title: 'IFSC',
      key: 'ifsc_code',
      dataIndex: 'ifsc_code',
      align: 'left',
      width: '10px',
      render: (text, row) => (
        <div className="bfc-table-list-components d-flex align-items-center">
          {row.ifsc} -{' '}
          <AntTableActions
            showFlag={true}
            row={row}
            controls={[
              {
                name: 'Copy',
                onClick: () => {
                  formikBag.setFieldValue('ifsc_code', row.ifsc);
                  setIsOpen(false);
                },
                controlColor: 'var(--unnamed-color-1b62ab)',
              },
            ]}
          />
        </div>
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'area',
    //   dataIndex: 'area',
    //   align: 'left',
    //   width: '30px',
    //   render: (text, row) => (
    //     <div className="bfc-table-list-components">
    //       <AntTableActions
    //         showFlag={true}
    //         row={row}
    //         controls={[
    //           {
    //             name: 'Copy',
    //             onClick: () => {
    //               formikBag.setFieldValue('ifsc_code', row.ifsc)
    //               setIsOpen(false)
    //             },
    //             controlColor: 'var(--unnamed-color-1b62ab)',
    //           },
    //         ]}
    //       />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="form-wrapper">
      <div className="row form__form-group">
        <div className="col-md-6 col-lg-5 col-xl-4">
          <span className="form-group-label font-weight-bold">Select Bank</span>
          <span style={{ color: 'red' }}>*</span>
          <div className="form-group-field">
            <Autocomplete
              value={bankName}
              size="small"
              fullWidth
              options={bankList}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setBankName(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  className="material-form__field"
                  // label={label}
                  // error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
                  // children={children}
                  // helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
                  // FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
                />
              )}
            />
          </div>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4">
          <span className="form-group-label font-weight-bold">Area</span>
          <span style={{ color: 'red' }}>*</span>
          <div className="form-group-field">
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group-field d-flex align-items-end">
          <button
            className="ml-3 mt-3"
            style={{
              backgroundColor: 'var(--unnamed-color-1b62ab)',
              color: '#fff',
              border: 'none',
              padding: '5px 25px',
              borderRadius: '30px',
              fontSize: '1rem',
              fontWeight: 'bold',
              opacity: !area || !bankName ? '0.3' : '1'
            }}
            size="small"
            color="primary"
            disabled={!area || !bankName}
            onClick={(e) => {
              e.preventDefault();
              if (area && bankName) {
                const params = {
                  bank_name: bankName,
                  area_name: area,
                };
                dispatch(farmerActions.getBankDetailsAgainstName(params));
              }
            }}>
            Get Bank Details
          </button>
        </div>
      </div>

      <div className="ant-table-body ant-table ml-2">
        <Table
          columns={BANK_TABLE_HEADER}
          dataSource={farmerOnboarding.bankDetails || []}
          pagination={false}
          // onChange={handlePageChange}
          scroll={{ y: 550, x: 550 }}
          className="ant-table"
          size="small"
        />
      </div>
    </div>
  );
};
export default SearchIFSCForm;
