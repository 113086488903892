import _ from 'lodash';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
import LogoLoader from 'common/LogoLoader';
import { actions as kycCommentsActions } from 'redux/kycComments';
import { Formik, Field, Form, FieldArray, getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as handlers from '../handlers';
import ReferenceDataSelect from '../../components/ReferenceDataSelect';
import FormikMaterialTextField from '../../components/FormikMaterialTextField';
import utils from 'lib/utils';
import KycComment from './KycComment';

const getInitialValues = () => {
  let formFields = {
    kyc_status_enum: 0,
    notes: '',
  };

  return formFields;
};

const KycCommentsPopUp = ({ close, params, isAdmin, id, entity_id, company_affiliate_enum }) => {
  const dispatch = useDispatch();
  const userId = id;
  const user = useSelector((state) => state.user);

  const kycComments = useSelector((state) => state.kycComments);
  const kycCommentsList = _.get(kycComments, 'entities') || {};
  const busy = _.get(kycComments, 'busy') || false;

  const kycBusiness = useSelector((state) => state.kycBusiness);
  const kycDetails = _.get(kycBusiness, 'entities') || {};
  const kyc_status_enum_code = _.get(params, 'details.kyc_status_enum_code') || '';

  const person_id = parseInt(id);

  params={
    ...params,
    person_id
  }

  const acceptReject = [
    { title: 'Accept', id: 2 },
    { title: 'Reject', id: 3 },
  ];

  const [dropValues, setdropValues] = useState(0);
  const onTagsChange = (event, dropValues) => {
    setdropValues(dropValues?.id);
  };

  const handleCreateComment = (values, formikBag) => {
    let params1 = {
      ...values,
      kyc_status_enum: dropValues,
      entity_id: params.entity_id,
      company_affiliate_enum: params.company_affiliate_enum,
      user_type_enum: 1,
    };
    handlers.handleCreateStatus(params1, formikBag);
  };

  useEffect(() => {
    dispatch(kycCommentsActions.fetchList(params));
  }, []);
  return (
    <React.Fragment>
      <Formik initialValues={getInitialValues()} onSubmit={handleCreateComment}>
        {(formikBag) => {
          const { values, setFieldValue, errors, touched } = formikBag;
          const hasError = !!(getIn(touched, 'kyc_status_enum') && getIn(errors, 'kyc_status_enum'));
          const errText = hasError ? getIn(errors, 'kyc_status_enum') : '';

          return (
            <Form className="">
              {isAdmin == true && kyc_status_enum_code !== 'Not Submitted' && (
                <div>
                  <div>
                    <div className="form-wrapper full-width">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Approve/Reject</span>
                          <div className="form-group-field">
                            <Autocomplete
                              name="approveReject"
                              options={acceptReject}
                              getOptionLabel={(option) => option.title}
                              onChange={onTagsChange}
                              renderInput={(params) => <TextField {...params}
                              className="p-0"
                              error={hasError}
                              helperText={errText}
                              variant="outlined"/>}
                            />
                            {/* {hasError ? <span>{errText}</span> : null} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-wrapper full-width">
                      <div className="row form__form-group">
                        <div className="col">
                        <span className="form-group-label">Notes</span>
                          <div className="form-group-field custom-input">
                            <Field
                              name="notes"
                              className="notes_box"
                              component="textarea"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btn-wrapper mb-20">
                    <div className="bid-popup-bottom-btn-wrapper ">
                      <Button
                        color="primary"
                        type="button"
                        size="sm"
                        onClick={formikBag.handleSubmit}
                      // disabled={formikBag.isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {isAdmin == true && kyc_status_enum_code === 'Not Submitted' && (
                <div>
                  <span>KYC not submitted by User</span>
                </div>
              )}

              {busy ? (
                <div
                  className=""
                  style={{
                    position: 'relative',
                    minHeight: '150px',
                  }}>
                  <div className="panel__refresh">
                    <LogoLoader />
                  </div>
                </div>
              ) : Object.keys(kycCommentsList).length > 0 ? (
                <div>
                  <div classNames="kyc-comment-list">
                    {_.map(kycCommentsList, (details, kyc_history_id) => {
                      return (
                        <React.Fragment>
                          <KycComment key={kyc_history_id} comment={details} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              ) : (
                isAdmin == false && (
                  <div>
                    <span>{kyc_status_enum_code}</span>
                  </div>
                )
              )}
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default KycCommentsPopUp;
