import React from 'react';
import PropTypes from 'prop-types';
import LogoLoader from 'common/LogoLoader';
import LogoLoaderGif from 'assets/images/gif/bfc-logo-gif.gif';

const Loading = ({ loading }) => (
  <div className={`load${loading ? '' : ' loaded'}`}>
    <div className="load__icon-wrap" style={{ top: 'auto', height: 'auto' }}>
      <img
        src={LogoLoaderGif}
        alt=""
        style={{ height: '100px', width: '100px' }} />
      {/* <LogoLoader
        minHeight={'calc(100vh - 100px)'} /> */}
      {/* <svg className="load__icon">
        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg> */}
    </div>
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
