import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { uploadDocumentService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'fetchExtendedProfilePhotos',
  initialState: adapter.getInitialState({
    busy: false,
    dirty: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setDirty(state, action) {
      let dirty = action.payload;
      state.dirty = dirty;
    },
  },
});

const fetchList = (qualityId) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await uploadDocumentService.fetchExtendedProfilePhotosList(qualityId);

    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllExtendedPhotos = (userExtended_Profile_Id, inspectorExtended_Profile_Id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    let resData = await uploadDocumentService.fetchExtendedProfilePhotosList(userExtended_Profile_Id);
    const userData = _.get(resData, '0.data', []);
    let resData2 = [];
    if (inspectorExtended_Profile_Id) {
      resData2 = await uploadDocumentService.fetchExtendedProfilePhotosList(inspectorExtended_Profile_Id);
    }
    const inspectorData = _.get(resData2, '0.data', []) || [];
    resData = {
      0: { id: 0, data: [...userData, ...inspectorData] },
    };
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    console.log('Redux-uploadDocumentService->Err', err);
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchAllExtendedPhotos,
};

const selectors = {
  ...adapter.getSelectors((state) => state.uploadBanner),
};

export { actions, selectors };

export default slice.reducer;
