import _ from 'lodash';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { actions as financeInstalledCapacityActions } from 'redux/financeInstalledCapacity';
import utils, {  decryptionInHex, getUrlWithEncryptedQueryParams } from 'lib/utils';
import { COMPANY_AFFILIATE_ENUM } from 'constants/enums';
import PopupForm from 'common/Form/PopupForm';
import AddInstalledCapacityForm from './AddInstalledCapacityForm';
import * as handlers from '../handlers';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import AntTableActions from 'common/AntTableActions';
import {  Skeleton } from 'antd';
import LogoLoader from 'common/LogoLoader';

const InstalledCapacity = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [timestamp, setTimestamp] = useState(Date.now());
  const { id, entity_id, company_affiliate_enum, kycStatusKey, isAdmin } = props;

  const financeInstalledCapacity = useSelector((state) => state.financeInstalledCapacity);
  const busy = _.get(financeInstalledCapacity, 'busy') || false;
  let installedCapacityList = _.get(financeInstalledCapacity, 'entities') || {};

  const detailsId = _.get(financeInstalledCapacity, 'ids[0]') || {};
  let installedCapacityDetails = _.get(installedCapacityList, `entities[${detailsId}]`) || {};

  // let entityIdCapacity =
  //   _.get(installedCapacityDetails, 'company_id') || _.get(installedCapacityDetails, 'affiliate_id') || 0;
  // if (entity_id !== entityIdCapacity) {
  //   installedCapacityList = {};
  // }

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDeleteInstalledCapacity(id);
      if (res) {
        setTimestamp(Date.now());
      }
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  let tableData = busy ? [] : Object.values(installedCapacityList || {});
  tableData = tableData.map((d) => {
    return {
      ...d,
      key: d['bf_setup_installed_capacity_id'],
      editPageLink: getUrlWithEncryptedQueryParams(`/account/${id}/finance/edit/${d['bf_setup_installed_capacity_id']}`),
    };
  });

  const TABLE_HEADERS = [
    {
      title: 'Commodity',
      dataIndex: 'commodity_form_name',
      width: '120px',
      align: 'center',
      fixed: true,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.commodity_type_code}-{row.commodity_form_name}
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center',
      width: '120px',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
    {
      title: 'Unit',
      dataIndex: 'unit_code',
      width: '100px',
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
    {
      title: 'Periodicity',
      dataIndex: 'periodicity_enum_code',
      width: '100px',
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
    {
      title: '',
      dataIndex: 'bf_setup_installed_capacity_id',
      width: '50px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: (
            <AntTableActions
              row={row}
              handleDelete={handleDelete}
              id={row.bf_setup_installed_capacity_id}
              cancel={cancel}
              showFlag={!isAdmin}
            />
          ),
          props: {},
        };
        return obj;
      },
    },
  ];

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/finance/create`));
  const editMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/finance/edit/:id`));

  let bf_setup_installed_capacity_id = null;
  if (createMatch) {
    bf_setup_installed_capacity_id = 0;
  } else if (editMatch) {
    bf_setup_installed_capacity_id = _.get(editMatch, 'params.id') || 0;
    bf_setup_installed_capacity_id = decryptionInHex(bf_setup_installed_capacity_id);
  }

  useEffect(() => {
    if (entity_id !== undefined) {
      let companyId = 0;
      let affiliateId = 0;
      if (company_affiliate_enum == COMPANY_AFFILIATE_ENUM.COMPANY) {
        companyId = entity_id;
      } else {
        affiliateId = entity_id;
      }
      const params = {
        company_id: companyId,
        company_affiliate_id: affiliateId,
        person_id: 0,
      };
      dispatch(financeInstalledCapacityActions.fetchList(params));
    }
  }, [entity_id, timestamp]);

  if (busy) {
    return (
      <div
        className=""
        style={{
          position: 'relative',
          minHeight: '150px',
        }}>
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }
  return (
    <div className="row form__form-group">
      <div className="bfc-table-box w-100 container-fluid">
        <div className="add-new-btn-box mt-20">
          <Button
            onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/finance/create`))}
            type="button"
            className="add-new-btn btn-sm"
            disabled={isAdmin || kycStatusKey}>
            <span className="icon">+</span>
            <span className="text">Add a Row</span>
          </Button>
        </div>
        <div>
          <Table
            // columns={TABLE_HEADERS}
            // dataSource={tableData}
            dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                columns={busy ? TABLE_HEADERS.map((column) => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <Skeleton
                          active
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                        />
                      );
                    },
                  };
              }) : TABLE_HEADERS}
            pagination={false}
            scroll={{ y: 350 }}
            className="ant-table"
            size="small"
          />
        </div>
      </div>
      {editMatch || createMatch ? (
        <div>
          <PopupForm
            title={bf_setup_installed_capacity_id !== 0 ? 'Edit Installed Capacity' : 'Add Installed Capacity'}
            onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/finance`))}>
            <AddInstalledCapacityForm
              backLink={getUrlWithEncryptedQueryParams(`/account/${id}/finance`)}
              setTimeStamp={setTimestamp}
              bf_setup_installed_capacity_id={bf_setup_installed_capacity_id}
              entity_id={entity_id}
              company_affiliate_enum={company_affiliate_enum}
            />
          </PopupForm>
        </div>
      ) : null}
    </div>
  );
};

export default InstalledCapacity;
