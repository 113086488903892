import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  GetAllMarketTransportLocation(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
};

export const resTransform = {
  GetAllMarketTransportLocation(resData) {
    let addressData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAddressDetails(details);
    });
    return _.keyBy(addressData, 'display_text');
  },

  fetchAddressDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'display_text'),
    };
    return res;
  },

  // GetAllMarketTransportLocation(resData) {
  //   let data = {
  //     ...resData,
  //   };
  //   return data;
  // },
};
