import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// import PlainOfferService, { PlainOfferMixedType } from 'services/offer';
import BidService, { BidDataType, BidMixedType } from 'services/bid';
import normalize from 'normalize-object';
import utils from 'lib/utils';
export interface BidState {
  data: Record<string, BidMixedType>;
  order: number[];
  busy: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: BidState = {
  data: {},
  order: [],
  busy: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const slice = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    setData: (state, { payload }) => ({ ...state, data: payload }),
    startFetching(state) {
      state.busy = true;
    },
    endFetching(state) {
      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.busy = false;
    },
    add(state, action: PayloadAction<BidMixedType[]>) {
      const order: number[] = [];
      const data = {};

      for (let bid of action.payload) {
        const id = bid.commodity_req_offer_response_id;
        data[id] = { ...bid };
        order.push(id);
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.busy = false;
    },
    update(state, action) {
      const { id, data } = action.payload;
      state.data[id] = {
        ...state.data[id],
        ...data,
        detailsUpdatedAt: Date.now(),
      };
    },
    // create(state: PlainOfferState, action) {
    //   // //@TODO: pass person_id filed value to post & handle response
    //   // api.createSocialPost(action.payload);
    // },
    // update(state: BidState, action) {
    //   const { commodity_req_offer_id, ...rest } = action.payload;
    //   state.data[commodity_req_offer_id] = { ...state.data[commodity_req_offer_id], ...rest };
    //   state.updatedAt = Date.now();
    // },
    // delete(state: PlainOfferState, action) {
    //   // debugger;
    //   const post_id = action.payload;
    //   let order = _.get(state, 'order', []) || [];
    //   // const posts = state.data.filter(p => p.post_id !== post_id)
    //   delete state['data'][post_id];
    //   let filteredOrder = order.filter((id) => id !== post_id);
    //   state['order'] = filteredOrder;
    // },
  },
});

const FetchBidsAgainstPlainOfferId = (
  commodityReqOfferId: string,
  filters,
  page: number = 1,
  pageSize: number = 10,
) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await BidService.FetchBidsAgainstPlainOfferId(commodityReqOfferId, filters, page, pageSize);
      dispatch(slice.actions.add(resData.data));
      // dispatch(slice.actions.setData(resData.data));
      // dispatch(slice.actions.endFetching());
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      // dispatch(slice.actions.add([]));
      dispatch(slice.actions.setData([]));
      utils.displayErrors(errors);
    }
  };
};

const FetchBidDetailsAgainstId = (bidId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await BidService.FetchBidDetailsAgainstId(bidId);
      dispatch(
        slice.actions.update({
          id: bidId,
          data: resData.data,
        }),
      );
    } catch (errors) {
      //console.log(errors);
      utils.displayErrors(errors);
    }

    dispatch(slice.actions.endFetching());
  };
};

const FetchBidDetailsAgainstIdQuote = (bidId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await BidService.FetchBidDetailsAgainstIdQuote(bidId);
      const response = normalize(resData.data, 'snake');
      dispatch(
        slice.actions.update({
          id: bidId,
          data: response,
        }),
      );
    } catch (errors) {
      //console.log(errors);
      utils.displayErrors(errors);
    }

    dispatch(slice.actions.endFetching());
  };
};

const FetchAllBidsAgainstEntityId = (filters, page: number = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await BidService.FetchAllBidsAgainstEntityId(filters, page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
      utils.displayErrors(errors);
    }
  };
};

const SaveBid = async (payload: Partial<BidDataType>) => {
  const resData = await BidService.SaveBid(payload);
  return resData;
};

export const actions = {
  ...slice.actions,
  //bids
  SaveBid,
  FetchBidsAgainstPlainOfferId,
  FetchBidDetailsAgainstId,
  FetchAllBidsAgainstEntityId,
  FetchBidDetailsAgainstIdQuote,
};

export default slice.reducer;
