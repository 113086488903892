import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';

function AntTableActions(props) {
  const { row, showFlag, toggle, } = props;
  const menu = (row) => {
    return (
      <Menu>
        <Menu.Item>
          <span>
            <a herf="#" onClick={(e) => row.showDetailsPopup(e, row)}>
              Edit
            </a>
          </span>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Dropdown overlay={menu(row)} placement="bottomCenter">
      {showFlag ? (
        <a className="ant-dropdown-link d-flex justify-content-center" onClick={(e) => e.preventDefault()}>
          Actions <DownOutlined className="mt-10 ml-5" />
        </a>
      ) : (
        <div></div>
      )}
    </Dropdown>
  );
}

export default AntTableActions;
