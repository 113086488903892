import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] !== _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },

  fetchRolesRightsList(params = {}) {
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['view_profile_of_person_id'] !== _.get(window, 'user.userid', 0)) {
        qsParams = {
          view_profile_of_person_id:
            params['view_profile_of_person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {};
        //  {
        //   view_profile_of_person_id: params['view_profile_of_person_id'] || _.get(window, 'user.userid', 0),
        // };
      }
    } else {
      qsParams = {};
      //  {
      //   view_profile_of_person_id: params['view_profile_of_person_id'] || _.get(window, 'user.userid', 0),
      // };
    }
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let rolesAndRightsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(rolesAndRightsData, 'role_module_permission_id');
  },

  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'role_module_permission_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
