import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { earlyPaymentsService } from 'services';
import history from 'lib/history';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'earlyPayments',
  initialState: adapter.getInitialState({
    busy: false,
    companyAffiliates: [],
    margin: '',
    interestRate: '',
    reportData: [],
    historyData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setCompanyAffiliates: (state, { payload }) => ({ ...state, companyAffiliates: payload }),
    setMargin: (state, { payload }) => ({ ...state, margin: payload }),
    setInterestRate: (state, { payload }) => ({ ...state, interestRate: payload }),
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setHistoryData: (state, { payload }) => ({ ...state, historyData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchEarlyPaymentRecords = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchEarlyPaymentRecords(params);
    if (params?.is_csv_report) {
      dispatch(slice.actions.setReportData(resData));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAdjustmentFactorForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchAdjustmentFactorForEarlyPayment(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchCompanyAffiliateForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchCompanyAffiliateForEarlyPayment(params);
    dispatch(slice.actions.setCompanyAffiliates(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateAdjustmentFactor = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.saveAndUpdateAdjustmentFactor(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateMargin = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.updateMargin(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Margin updated successfully' });
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateCostOfBorrowing = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.updateCostOfBorrowing(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Interest rate updated successfully' });
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMarginForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchMarginForEarlyPayment(params);
    dispatch(slice.actions.setMargin(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchCostOfBorrowingForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchCostOfBorrowingForEarlyPayment(params);
    dispatch(slice.actions.setInterestRate(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteAdjustmentFactor = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.deleteAdjustmentFactor(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Adjustment factor deleted successfully' });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAdjustmentFactorHistoryForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchAdjustmentFactorHistoryForEarlyPayment(params);
    dispatch(slice.actions.setHistoryData(resData));
    dispatch(slice.actions.setBusy(false));
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchDiscountForEarlyPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.fetchDiscountForEarlyPayment(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteEarlyPaymentDiscount = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.deleteEarlyPaymentDiscount(params);
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateEarlyPaymentDiscount = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.saveAndUpdateEarlyPaymentDiscount(params);
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateAppConfiguration = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await earlyPaymentsService.updateAppConfiguration(params);
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchEarlyPaymentRecords,
  fetchAdjustmentFactorForEarlyPayment,
  fetchCompanyAffiliateForEarlyPayment,
  saveAndUpdateAdjustmentFactor,
  updateMargin,
  updateCostOfBorrowing,
  fetchMarginForEarlyPayment,
  fetchCostOfBorrowingForEarlyPayment,
  deleteAdjustmentFactor,
  fetchAdjustmentFactorHistoryForEarlyPayment,
  fetchDiscountForEarlyPayment,
  deleteEarlyPaymentDiscount,
  saveAndUpdateEarlyPaymentDiscount,
  updateAppConfiguration,
};

const selectors = {
  ...adapter.getSelectors((state) => state.transportDriver),
};

export { actions, selectors };

export default slice.reducer;
