import _ from 'lodash';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as handlers from '../handlers';
import FormikMaterialTextField from './FormikMaterialTextField';
import * as yup from 'yup';

const formValidation = yup.object().shape({
  amount: yup
    .number()
    .positive('Invalid')
    .required('Required Field')
    .test('is-dot', 'Invalid', function (value) {
      let str = value + '';
      let patt1 = /^\d*\.{1}\d{1,2}$/;
      let patt2 = /^[0-9]+$/g;
      let result = str.match(patt1) || str.match(patt2);
      return result;
    }),
});
const BankVerificationForm = ({ close, id, backLink, type }) => {
  const dispatch = useDispatch();

  const [stickToBottom, setStickToBottom] = useState(false);
  const modalFormClass = classNames(['bfc-modalform', 'overflow-hidden', { stickToBottomStyle: stickToBottom }]);
  let intialValues = {
    person_bank_account_id: id,
    amount: 0,
  };
  if (type == 'company_bank_address_id') {
    intialValues = {
      company_bank_address_id: id,
      amount: 0,
    };
  }

  const handleSubmit = async (values, formikBag) => {
    const success = await handlers.handleCompleteBankVerification(values, formikBag);
    if(success){

    }
    close();
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={intialValues}
        validationSchema={formValidation}
        onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          return (
            <div className={modalFormClass}>
              <div className="bfc-modalform-header">
                <div
                  className="icons-wrapper"
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setStickToBottom(!stickToBottom);
                  }}>
                  <span className="title p-relative">Bank Verification</span>
                </div>
                <div className="close-wrapper">
                  <a href={backLink} onClick={close}>
                    X
                  </a>
                </div>
              </div>
              <Form className="form-container">
                <div className="modalform-body">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Amount</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="amount" component={FormikMaterialTextField} type="number" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper" style={{ flex: 1, justifyContent: 'center' }}>
                    <Button
                      color="primary"
                      type="button"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      Verify
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BankVerificationForm;
