import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { toJS } from 'immer';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { recordPaymentService, financeUserService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'recordPayment',
  initialState: adapter.getInitialState({
    busy: false,
    reportData: [],
    paymentTransactionData: [],
    paymentData: [],
    paymentDetailsData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setPaymentTransactionData: (state, { payload }) => ({ ...state, paymentTransactionData: payload }),
    setPaymentData: (state, { payload }) => ({ ...state, paymentData: payload }),
    setPaymentDetailsData: (state, { payload }) => {
      const existingData = payload?.recordPaymentState?.['paymentDetailsData'];
      const filteredData = existingData.filter(
        (item) => item.header_invoice_master_id !== payload?.resData?.[0]?.header_invoice_master_id,
      );
      return { ...state, paymentDetailsData: [...filteredData, ...payload?.resData] };
    },
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllInvoiceDetails_ForPayouts = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchAllInvoiceDetails_ForPayouts(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllInvoiceDetailsReceiver = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchAllInvoiceDetailsReceiver(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchUserInoviceList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchUserInvoiceList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const recordPaymentByBuyerSeller = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.recordPaymentByBuyerSeller(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const recordPaymentByBuyerSellerMultiple = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.recordPaymentByBuyerSellerMultiple(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const recordPaymentByBuyerSellerMultipleO = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.recordPaymentByBuyerSellerMultipleO(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchFinanceInvoiceList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await financeUserService.fetchFinanceList(params);
    if (params?.isCsv) {
      const reportData = [...Object.values(resData)];
      dispatch(slice.actions.setReportData(reportData));
    } else {
      dispatch(slice.actions.setAll(resData));
    }
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchUserFinanceInvoiceList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await financeUserService.fetchUserFinanceList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const createPayout = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.createPayout(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const createPayoutO = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.createPayoutO(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getPayoutAttempts = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.getPayoutAttempts(params);
    dispatch(slice.actions.setPaymentTransactionData(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setPaymentTransactionData([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchInvoicesForPayments = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchInvoicesForPayments(params);
    dispatch(slice.actions.setPaymentData(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const fetchInvoicesForPaymentsDetails = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await recordPaymentService.fetchInvoicesForPaymentsDetails(params);
    const recordPaymentState = window.store.getState()?.recordPayment;
    dispatch(slice.actions.setPaymentDetailsData({ resData, recordPaymentState }));
    // dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchUserInoviceList,
  fetchFinanceInvoiceList,
  fetchUserFinanceInvoiceList,
  recordPaymentByBuyerSeller,
  fetchAllInvoiceDetailsReceiver,
  recordPaymentByBuyerSellerMultiple,
  fetchAllInvoiceDetails_ForPayouts,
  createPayout,
  getPayoutAttempts,
  fetchInvoicesForPayments,
  fetchInvoicesForPaymentsDetails,
  createPayoutO,
  recordPaymentByBuyerSellerMultipleO,
};

const selectors = {
  ...adapter.getSelectors((state) => state.recordPayment),
};

export { actions, selectors };

export default slice.reducer;
