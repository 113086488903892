import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';
import store from 'redux/store';
import { teamsService } from 'services';
import { actions as teamsActions } from 'redux/teams';

export const handleCreate = async (
  values,
  formikBag,
  setIsRerenderRequired,
  isRerenderRequired,
  page,
  setIsMobileReqOrUpdateErr,
) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Team Member...',
      key: 'req-form',
    });

    const res = await teamsService.create(values);

    notifications.show({
      type: 'success',
      message: 'Team Member Created.',
      key: 'req-form',
    });

    // store.dispatch(teamsActions.setDirty());
    setIsRerenderRequired(!isRerenderRequired);
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.person_id}/teams?page=${page}`));

    return res;
  } catch (err) {
    if (err.errors.company_id === 'Unable to Process.Count Exceeded') {
      notifications.show({
        type: 'error',
        message: 'Can not add team member to selected location',
        key: 'req-form',
      });
    } else {
      formikBag.setSubmitting(false);
      console.log('handleCreate--->error---->', err);
      if (err.errors.mobile_number) {
        setIsMobileReqOrUpdateErr(err.errors.mobile_number);
      }
      utils.displayErrors(err, formikBag);
    }
  }
};

export const handleUpdate = async (
  values,
  formikBag,
  setIsRerenderRequired,
  isRerenderRequired,
  page,
  setIsMobileReqOrUpdateErr,
) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Team Members...',
      key: 'req-form',
    });

    const value = 7;
    const id = _.get(values, 'team_member_id') || 0;
    let roleIds = Array.from(new Set(values.role_ids));
    roleIds = roleIds.filter(function (item) {
      return item !== value;
    });
    // roleIds.splice(0, 1);
    values.role_ids = roleIds;
    const res = await teamsService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Team member details updated.',
      key: 'req-form',
    });

    // console.log('userid in userid', userid);
    // store.dispatch(teamsActions.setDirty());
    setIsRerenderRequired(!isRerenderRequired);
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.person_id}/teams?page=${page}`));

    return res;
  } catch (err) {
    formikBag.setSubmitting(false);
    console.log('handleUpdate--->error---->', err);
    if (err.errors.mobile_number) {
      setIsMobileReqOrUpdateErr(err.errors.mobile_number);
    }
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await teamsService.destroy(id);
    store.dispatch(teamsActions.setDirty());
    // redirect.web('/account/transport');
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
  return false;
}
