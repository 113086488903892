import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { dealInvoicesService, rentalService } from 'services';
import { kycApprovalService, kycApprovalBusinessService } from 'services';
import { paymentService } from 'services';
import { deliveryContractReportService } from 'services';
import { deliveryReportService } from 'services';
import { generateBuySellReportService } from 'services';
import { cashflowService, storageService, manualInvoiceService } from 'services';
import { transportRateService } from 'services';
import { commodityPlanService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (deliveryScheduleMonth) => _.get(deliveryScheduleMonth, 'month_string', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => (a.name_of_farm || '').localeCompare(b.name_of_farm || ''),
});

const slice = createSlice({
  name: 'generateReportCommon',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchListInvoiceReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await dealInvoicesService.fetchListAgainstPersonIdReport(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListInvoiceReportCT = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchInvoiceListCT(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListPaymentReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await paymentService.fetchReportList(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListDeliveryContract = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await deliveryContractReportService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListDeliveryReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await deliveryReportService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchKycIndividualListReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchKycBusinessListReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalBusinessService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchBuySellTrackerListReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await generateBuySellReportService.fetchReportList(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListCashflowReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await cashflowService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const fetchStorageList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await storageService.fetchStorageLevels(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchmanualInvoiceList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await manualInvoiceService.fetchManualInvoicesDraft(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchSupplierProfilesListReport = (params, isControlTowerUser) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await generateBuySellReportService.fetchSupplierProfilesListReport(params, isControlTowerUser);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const platformChargesReportData = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await generateBuySellReportService.platformChargesReportData(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getEmissionsSustainabilityReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await generateBuySellReportService.getEmissionsSustainabilityReport(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const GetAllMarketTransportRate_ElocReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await transportRateService.GetAllMarketTransportRate_Eloc(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const getEquipmentRentalReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await rentalService.getAllEquipmentsReport(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const fetchListSmartBuyerConfigurationReport = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityPlanService.fetchListSmartBuyerConfigurationReport(params);
    dispatch(slice.actions.setAll(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchListInvoiceReport,
  fetchListInvoiceReportCT,
  fetchListPaymentReport,
  fetchListDeliveryContract,
  fetchListDeliveryReport,
  fetchKycIndividualListReport,
  fetchKycBusinessListReport,
  fetchBuySellTrackerListReport,
  fetchListCashflowReport,
  fetchStorageList,
  fetchmanualInvoiceList,
  fetchSupplierProfilesListReport,
  platformChargesReportData,
  getEmissionsSustainabilityReport,
  GetAllMarketTransportRate_ElocReport,
  getEquipmentRentalReport,
  fetchListSmartBuyerConfigurationReport,
};

const selectors = {
  ...adapter.getSelectors((state) => state.generateReportCommon),
};

export { actions, selectors };

export default slice.reducer;
