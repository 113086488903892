import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';

import { actions as companyActions } from 'redux/companies';
import { actions as userActions } from 'redux/user';
import { companyService } from 'services';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Company...',
      key: 'req-form',
    });

    const res = await companyService.create(values);
    notifications.show({
      type: 'success',
      message: 'Company Created.',
      key: 'req-form',
    });

    globalThis.store.dispatch(companyActions.setDirty());
    globalThis.store.dispatch(userActions.fetchPersonalDetails());
    globalThis.store.dispatch(companyActions.fetchList());
    // if (window) {
    //   return window.location.reload();
    // }
    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Company Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'company_id') || 0;
    const res = await companyService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Company details updated.',
      key: 'req-form',
    });
    globalThis.store.dispatch(companyActions.setDirty());
    globalThis.store.dispatch(userActions.fetchPersonalDetails());
    // if (window) {
    //   return window.location.reload();
    // }
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await companyService.destroy(id);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
  return false;
}
