import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params) {
    const bodyparams = {
      ...params,
      filters: params['filters'],
    };

    return bodyparams;
  },
  create(params = {}) {
    params['test_date'] = dayjs(params['test_date']).format('YYYY-MM-DDTHH:mm:ss');
    // YYYY-MM-DDTHH:mm:ss
    const quality_record_Test_Detail = params['commodity_req_offer_quality_spec'].map((item) => {
      const transformedItem = { ...item, observed_value: parseFloat(item.observed_value) };
      return transformedItem;
    });
    const bodyparams = {
      ...params,
      // remarks: 'test',
      person_company_affiliate_enum: params['company_affiliate_enum'],
      commodity_form_master_id: params['commodity_form_id'],
      commodity_residue_master_id: params['commodity_residue_id'],
      quality_record_Test_Detail: quality_record_Test_Detail,
      // Batch_Lot_No: '',
      // transporter_person_id: _.get(window, 'user.userid', 0), //526
      commodity_type_master_id: params['commodity_type_id'],
      operation_enum: 1,
    };

    return bodyparams;
  },

  update(params) {
    params['test_date'] = dayjs(params['test_date']).format('YYYY-MM-DDTHH:mm:ss');
    const quality_record_Test_Detail = params['commodity_req_offer_quality_spec'].map((item) => {
      const transformedItem = { ...item, observed_value: parseFloat(item.observed_value) };
      return transformedItem;
    });

    const bodyparams = {
      ...params,
      // remarks: 'test',
      person_company_affiliate_enum: params['company_affiliate_enum'],
      commodity_form_master_id: params['commodity_form_id'],
      commodity_residue_master_id: params['commodity_residue_id'],
      quality_record_Test_Detail: quality_record_Test_Detail,
      // Batch_Lot_No: '',
      // transporter_person_id: _.get(window, 'user.userid', 0), //526
      commodity_type_master_id: params['commodity_type_id'],
      operation_enum: 3,
    };

    return bodyparams;
  },

  destroy(id) {
    const qsParams = {
      Quality_Record_Master_Id: id,
    };

    return utils.getQs(qsParams);
  },

  handleQualityParameterDestroy(id) {
    const qsParams = {
      Quality_record_Detail_Id: id,
    };

    return utils.getQs(qsParams);
  },
  fetchLabData(params = {}) {
    const bodyparams = {
      Company_Affiliate_Id: `${params['entity_id']}`,
      Location_Id: `${params['address_id']}`,
      Commodity_Form_Master_Id: `${params['commodity_form_id']}`,
      Commodity_Residue_Master_Id: `${params['commodity_residue_id']}`,
      Commodity_Id: `${params['commodity_id']}`,
      Duration: `${params['duration']}`,
      Person_Company_Affiliate_Enum: `${params['company_affiliate_enum']}`,
    };

    // const bodyparams = {
    //   Company_Affiliate_Id: `${610}`,
    //   Location_Id: `${923}`,
    //   // Commodity_Form_Master_Id: `${params['commodity_form_id']}`,
    //   // Commodity_Residue_Master_Id: `${params['commodity_residue_id']}`,
    //   // Commodity_Id: `${params['commodity_id']}`,
    //   Duration: `${365}`,
    //   Person_Company_Affiliate_Enum: `${1}`,
    // };

    return bodyparams;
  },
  fetchObservedLabData(params = {}) {
    // function toString(o) {
    //   Object.keys(o).forEach((k) => {
    //     if (typeof o[k] === 'object') {
    //       return toString(o[k]);
    //     }

    //     o[k] = '' + o[k];
    //   });

    //   return o;
    // }

    const bodyparams = {
      Company_Affiliate_Id: `${params['entity_id']}`,
      Location_Id: `${params['address_id']}`,
      Commodity_Form_Master_Id: `${params['commodity_form_id']}`,
      Commodity_Residue_Master_Id: `${params['commodity_residue_id']}`,
      Commodity_Id: `${params['commodity_id']}`,
      Duration: `${params['duration']}`,
      Person_Company_Affiliate_Enum: `${params['company_affiliate_enum']}`,
    };

    // const bodyparams = {
    //   Company_Affiliate_Id: `${673}`,
    //   Location_Id: `${991}`,
    //   // Commodity_Form_Master_Id: `${params['commodity_form_id']}`,
    //   // Commodity_Residue_Master_Id: `${params['commodity_residue_id']}`,
    //   // Commodity_Id: `${params['commodity_id']}`,
    //   Duration: `${365}`,
    //   Person_Company_Affiliate_Enum: `${1}`,
    // };

    return bodyparams;
  },

  handleQualityUploadDestroy(id) {
    const qsParams = {
      Attachment_Id: id,
    };

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let driverData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(driverData, 'quality_record_master_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'quality_record_master_id'),
    };
    return data;
  },
  create(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },

  handleQualityParameterDestroy(resData) {
    return resData;
  },
  fetchLabData(resData) {
    let data = [{ id: 0, data: resData }];
    return _.keyBy(data, 'id');
  },
  fetchObservedLabData(resData) {
    let data = [{ id: 0, data: resData }];
    return _.keyBy(data, 'id');
  },
  handleQualityUploadDestroy(resData) {
    return resData;
  },
};
