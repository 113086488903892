import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import * as api from './api';

export const resTransform = {
  fetchReportList(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details, index) => {
      return resTransform.fetchReportDetails(details, index);
    });
    return _.keyBy(generateReportData, 'id');
  },
  fetchReportDetails(resData, index) {
    let data = {
      total_count: _.get(resData, 'total_count') || 0,
      modified_datetime: _.get(resData, 'created_datetime') || '',
      against_buy_sell: _.get(resData, 'against_buy_sell') || 0,
      facilitation_log_id: _.get(resData, 'facilitation_log_id') || 0,
      facilitator_person_id: _.get(resData, 'facilitator_person_id') || 0,
      facilitator_person_name: _.get(resData, 'facilitator_person_name') || '',
      mobile_number: _.get(resData, 'mobile_number') || '',
      social_profile_id: _.get(resData, 'social_profile_id') || 0,
      person_company_affiliate_id: _.get(resData, 'person_company_affiliate_id') || 0,
      // person_company_affiliate_enum: _.get(resData, 'person_company_affiliate_enum') || 0,
      created_datetime: _.get(resData, 'created_datetime') || '',
      // assist_type_enum: _.get(resData, 'assist_type_enum') || 0,
      assist_type_enum_code: _.get(resData, 'assist_type_enum_code') || '',
      farm_profile_complete: _.get(resData, 'farm_profile_complete') || '',
      facilitator_organisation: _.get(resData, 'facilitator_organisation') || '',
      person_company_affiliate_enum_code: _.get(resData, 'person_company_affiliate_enum_code') || '',
      person_name: _.get(resData, 'person_name') || '',
      company_affiliate_name: _.get(resData, 'company_affiliate_name') || '',
      // type_of_individual_enum: _.get(resData, 'type_of_individual_enum') || 0,
      type_of_individual_enum_code: _.get(resData, 'type_of_individual_enum_code') || '',
      // kyc_status_enum: _.get(resData, 'kyc_status_enum') || 0,
      kyc_status_enum_code: _.get(resData, 'kyc_status_enum_code') || '',
      kyc_approved_on: _.get(resData, 'kyc_approved_on') || '',
      number_of_vehicles: _.get(resData, 'number_of_vehicles') || 0,
      buy_sell: _.get(resData, 'buy_sell') || 0,
      bid_offer: _.get(resData, 'bid_offer') || 0,
      offer_bid_type_enum_code: _.get(resData, 'offer_bid_type_enum_code') || 0,
      deal_no: _.get(resData, 'deal_no') || 0,
      deal_quantity: _.get(resData, 'deal_quantity') || 0,
      deal_date: _.get(resData, 'deal_date') || 0,
      // deal_status_enum: _.get(resData, 'deal_status_enum') || 0,
      deal_status: _.get(resData, 'deal_status') || 0,
      req_offer_type_enum_code: _.get(resData, 'req_offer_type_enum_code') || '',
      location: _.get(resData, 'location') || '',
      qty_uom_code: _.get(resData, 'qty_uom_code') || '',
      price_uom_code: _.get(resData, 'price_uom_code') || '',
      bank_verification_enum: _.get(resData, 'bank_verification_enum') || 0,
      bank_verification_enum_code: _.get(resData, 'bank_verification_enum_code') || 0,
      id: index, //_.get(index, 'index'),
    };
    return data;
  },
};
