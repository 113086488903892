import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import * as helpers from 'services/helpers';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    const qsParams = {
      transport_delivery_schedule_allotment_id: id,
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
  },
  fetchCargoDetails(deliveryId) {
    const qsParams = {
      delivery_id: deliveryId,
      invoking_person_id: _.get(window, 'user.userid', 0),
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
  },
  fetchDeliveryPhases(deliveryId) {
    const qsParams = {
      delivery_id: deliveryId,
    };

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
  },
  updateCargoDetails(params = {}, deliveryId) {
    const bodyParams = {
      ...params,
      tare_weight: parseFloat((parseFloat(params['tare_weight']) / 1000).toFixed(3)),
      gross_weight: parseFloat((parseFloat(params['gross_weight']) / 1000).toFixed(3)),
      netWeight: parseFloat((parseFloat(params['netWeight']) / 1000).toFixed(3)),
      invoice_qty:
        params['uom_type_enum'] !== 2
          ? parseFloat((parseFloat(params['invoice_qty']) / 1000).toFixed(3))
          : // : params['price_uom_code'] === 'L' ? parseFloat(params['invoice_qty']) : parseFloat((parseFloat(params['invoice_qty']) / 1000).toFixed(3)),
            parseFloat((parseFloat(params['invoice_qty']) / 1000).toFixed(3)),
      receipt_qty:
        params['uom_type_enum'] !== 2
          ? parseFloat((parseFloat(params['receipt_qty']) / 1000).toFixed(3))
          : // : params['price_uom_code'] === 'L' ? parseFloat(params['receipt_qty']) : parseFloat((parseFloat(params['receipt_qty']) / 1000).toFixed(3)),
            parseFloat((parseFloat(params['receipt_qty']) / 1000).toFixed(3)),
      invoking_person_id: _.get(window, 'user.person_id'),
      delivery_id: deliveryId,
    };
    return bodyParams;
  },
  deleteUploadedFileAgainstId(params) {
    let bodyParams = {
      attachment_id: parseInt(params['attachment_id']),
    };
    // return helpers.getQueryParams(bodyParams);
    return utils.getQs(bodyParams);
  },
  createDelayQsInvoice(params) {
    let bodyParams = {
      delivery_id: parseInt(params['delivery_id']),
    };
    // return helpers.getQueryParams(bodyParams);
    return utils.getQs(bodyParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        ...data,
        id: _.get(data, 'transport_delivery_schedule_alloted_id') || 0,
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchDetails(resData) {
    let data = resData || {};

    data = {
      ...data,
      id: _.get(data, 'transport_delivery_schedule_alloted_id') || 0,
    };

    return data;
  },

  ////
  fetchTransportDeliveryDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_delivery_schedule_alloted_id'),
    };
    return data;
  },
  fetchCargoDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'at_loading.delivery_id'),
    };
    return data;
  },

  fetchDeliveryPhasesDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'sched_master_id'),
    };
    return data;
  },

  fetchQSList(resData) {
    let qsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchQSDetails(details);
    });
    return _.keyBy(qsData, 'sched_delivery_quality_spec_id');
  },
  fetchQSDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'sched_delivery_quality_spec_id'),
    };
    return data;
  },
  deleteUploadedFileAgainstId(resData) {
    return resData;
  },
};
