import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import DealService, { DealMixedType } from 'services/deal';
import { financeUserService } from 'services';
import utils from 'lib/utils';

export interface DealState {
  data: Record<string, DealMixedType>;
  order: number[];
  busy: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: DealState = {
  data: {},
  order: [],
  busy: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const slice = createSlice({
  name: 'feedbackUser',
  initialState,
  reducers: {
    startFetching(state) {
      state.busy = true;
    },
    endFetching(state) {
      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.busy = false;
    },
    add(state, action: PayloadAction<DealMixedType[]>) {
      const order: number[] = [];
      const data = {};
      if (action.payload) {
      }

      for (let deal of action.payload) {
        const id = deal.commodity_deal_master_id;
        if (id > 0) {
          data[id] = { ...deal };
          order.push(id);
        }
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.busy = false;
    },
    update(state, action) {
      const { id, data } = action.payload;
      state.data[id] = {
        ...state.data[id],
        ...data,
        detailsUpdatedAt: Date.now(),
      };
    },
  },
});

const FetchAllFeedbacks = () => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchAllFeedbacks();
      dispatch(slice.actions.add(resData));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

export const actions = {
  ...slice.actions,
  FetchAllFeedbacks,
};

export default slice.reducer;
