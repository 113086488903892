import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/pickup-truck.svg';

function PickupBuyIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default PickupBuyIcon;
