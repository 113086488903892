import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { transportRateService, transportLocationService } from 'services';
import { actions as transportRateActions } from 'redux/transportRate';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

const adapter = createEntityAdapter({});
export const transportRateSchema = yup.object().shape({
  district_from: yup.string().required('Please Select From Location'),
  district_to: yup.string().required('Please Select To Location'),
  // commodity_type_id: yup.number().required('Please Select Goods Type'),
  // commodity_form_id: yup.number().required('Please Select Goods Type'),
  goods_type: yup.string().required('Please Select Goods Type'),
  fuel_id: yup.number().nullable().typeError('Please Select Fuel'),
  fuel_price: yup.number().nullable().typeError('Fuel Price is required field'),
  rate: yup.number().nullable().typeError('Rate is a required field'),
  radius: yup.number().nullable().typeError('Distance is a required field'),
  vehicle_type_id: yup.array().of(yup.number().required().min(0)).required().min(1, 'Please select vehicle size'),
  // vehicle_type_id: yup.number().required().positive('Select vehicle size'),
  valid_until: yup.date().nullable().typeError('Valid until date is required'),
  remark: yup.string().required('Remark field is required'),
});
const slice = createSlice({
  name: 'transportRate',
  initialState: adapter.getInitialState({
    busy: false,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const GetAllMarketTransportRate_Eloc = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await transportRateService.GetAllMarketTransportRate_Eloc(params);
    dispatch(slice.actions.setAll(resData));
    // dispatch(slice.actions.setBusy(false));
    //return resData
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const DeleteMarketTransportRate = (id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await transportRateService.DeleteMarketTransportRate(id);
    // dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const SaveUpdateMarketTransportRate = (params, toLocationToggle) => async (dispatch) => {
  try {
    const dynamicSchema = toLocationToggle
      ? transportRateSchema.shape({
          district_to: yup.string(),
          radius: yup.number().required('Distance is a required field'),
        })
      : transportRateSchema.shape({
          district_to: yup.string().required('Please Select To Location'),
          radius: yup.number(),
        });
    await helpers.validateSchema(dynamicSchema, params);
    dispatch(slice.actions.setBusy(true));
    const resData = await transportRateService.SaveUpdateMarketTransportRate(params);
    console.log('Params--->iii', params);
    // dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    if (params?.rate > 0) {
      notifications.show({
        type: 'success',
        message: 'Transport rate added successfully',
        key: 'req-form',
      });
    } else {
      notifications.show({
        type: 'warning',
        message: 'Transport rate added with 0 value',
        key: 'req-form',
      });
    }
    return resData;
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  GetAllMarketTransportRate_Eloc,
  SaveUpdateMarketTransportRate,
  DeleteMarketTransportRate,
};

const selectors = {
  ...adapter.getSelectors((state) => state.transportDriver),
};

export { actions, selectors };

export default slice.reducer;
