import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../../components/FormikMaterialTextField';
import ReferenceDataSelect from './../../components/ReferenceDataSelect';
import MUITextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import LogoLoader from 'common/LogoLoader';
import { actions as userActions } from 'redux/user';

import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as handlers from './handlers';

const getInitialValues = (affiliateDetails = {}) => {
  let formFields = {
    company_affiliate_id: _.get(affiliateDetails, 'company_affiliate_id') || 0,
    person_id: 0,
    business_type_enum: _.get(affiliateDetails, 'business_type_enum') || 0,
    affiliate_code:_.get(affiliateDetails, 'affiliate_code') || '' ,
    affiliate_name: _.get(affiliateDetails, 'affiliate_name') || '',
    display_name: _.get(affiliateDetails, 'display_name') || '',
    affiliate_relation_enum: _.get(affiliateDetails, 'affiliate_relation_enum') || 0,
    is_kyc_done: false,
    user_id: _.get(window,'user.userid') || 0,
  };
  return formFields;
};

const AffiliateForm = (props) => {
  const affiliates = useSelector((state) => state.affiliates);
  const affiliateId = props.affiliateId || 0;
  const id = props.id || 0;
  const dispatch = useDispatch();
  const history = useHistory();

  const affiliateDetails = _.get(affiliates, `entities[${affiliateId}]`) || {};
  const busy = _.get(user, 'busy') || false;

  if (affiliateId && _.isEmpty(affiliateDetails)) {
    return (
      <div className="bfc-modalform">
        <div className="bfc-modalform-header">
          <div className="icons-wrapper">
            <img src={Bidicon} alt="" />
            <span className="title">Business Affiliation</span>
          </div>
          <div className="close-wrapper">
            <a href="" className="d-none">
              -
            </a>
            <Link to={getUrlWithEncryptedQueryParams(`/account/${id}/organisations`)}>X</Link>
          </div>
        </div>
        <div className="modalform-body">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(affiliateDetails)}
        onSubmit={affiliateId ? handlers.handleUpdate : handlers.handleCreate}>
        {(formikBag) => {
          const { values } = formikBag;
          return (
            <div>
              {/* <div className="bfc-modalform-header">
                <div className="icons-wrapper">
                  <img src={Bidicon} alt="" />
                  <span className="title">Business Affiliation</span>
                </div>
                <div className="close-wrapper">
                  <a href="" className="d-none">
                    -
                  </a>
                  <Link to={`/account/${id}/organisations`}>X</Link>
                </div>
              </div> */}
              <div>
                <Form className="form-container">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Affiliated Business Name</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="affiliate_name" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Display Name</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="display_name" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Business Type</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="business_type_enum"
                              component={ReferenceDataSelect}
                              entity="businessTypeEnum"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Relationship</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="affiliate_relation_enum"
                              component={ReferenceDataSelect}
                              entity="affiliateRelationEnum"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      {affiliateId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default AffiliateForm;
