import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const GetAllMarketTransportLocation = async (params) => {
  try {
    let bodyParams = reqTransform.GetAllMarketTransportLocation(params);
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/MarketTransportRate/GetAllMarketTransportLocation`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.GetAllMarketTransportLocation(resData);
  } catch (err) {
    console.log('api-transport-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTransportDetails');
    throw errors;
  }
};
