import _ from 'lodash';
import React, { useEffect } from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';
import { actions as refDataActionsFilter } from 'redux/filterOptions';


const dataCache = {};

function ReferenceDataSelect(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);
  let finalOptions = [];

  let typeOfIndividual = props.params;

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    placeholder,
    defaultUnit,
    isAddQsClicked,
    isEdit,
    isFuel = false,
    getTransportationCharges,
    formik,
    width,
    isMultiPartyAuction,
    entity_id,
    vehicle_type_enum,
    is_workflow,
    ...rest
  } = props;

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }

  const filterOptions = useSelector((state) => state.filterOptions);
  let entityData = useSelector((state) => {
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams)[0];
      return qId > 0 ? state.referenceData.data[entity][qId] || [] : [];
    }

    const found = state.referenceData.data[entity];

    return Array.isArray(found) ? found : [];
  });


  let valuemember = _.get(values, field.name, 0);

  const loading = open && entityData && entityData.length === 0;


  let selectedValue = entityData.find((e) => e.value_member === valuemember);

  if (!selectedValue) {
    selectedValue = { value_member: valuemember, display_member: valuemember ? initialDisplayText : '' };
  }

  if (is_workflow) {
    entityData = entityData.filter((item) => item.value_member == 1 || item.value_member == 2 || item.value_member == 3)
  }


  React.useEffect(() => {
    let active = true;

    let specificParams = {
      entity: entity,
      typeOfIndividual: typeOfIndividual,
    };

    const loadOptions = async () => {
      if (!entityData.length) {
        try {
          if (!_.isEmpty(qparams)) {
            dispatch(refDataActions.getCommodityDropdownData(entity, qparams));
          } else if (
            (entity || '').toLowerCase().includes('enum') ||
            (entity || '').toLowerCase().includes('typeofnote')
          ) {
            dispatch(refDataActions.getEnumData(entity)); // as a "enumgroup"
          } else if (entity == 'vehicleTypeDiscount') {
            dispatch(refDataActions.getReferenceDataDiscount(entity));
          } else if (entity == 'specificEntityId') {
            dispatch(refDataActions.getSpecificParticipant(entity));
          } else if (isFuel && entity === 'fuel') {
            dispatch(refDataActionsFilter.getFilterDataByFilterEnum(entity));
          } else if (entity === 'vehicleType') {
            dispatch(refDataActions.getReferenceData(entity, entity_id));
          } else {
            dispatch(refDataActions.getReferenceData(entity));
          }


          dataCache[entity] = entityData;
          setOptions(entityData);
        } catch (err) {
          console.log('tries-->', tries);
          console.log('getCompanies error', err);

          if (tries < 3) {
            setTries(tries + 1);
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading || qparams?.commodity_id) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams, typeOfIndividual, values]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  // className={`${hasError ? 'has-error' : ''} bfc-ref-data-select-wrapper`}
  if ((entity || '').toLowerCase().includes('enum') || (entity || '').toLowerCase().includes('typeofnote')) {
    finalOptions = _.orderBy(entityData, ['sequence']);
  } else {
    finalOptions = _.orderBy(entityData, ['display_member']);
  }

  return (
    <div className={`bfc-ref-data-select-wrapper`}>
      {initialDisplayText ? (
        <Autocomplete
          id={`${entity}-autocomplete-${timestamp}`}
          defaultValue={initialDisplayText}
          disabled={disabled}
          disableClearable
          size="small"
          // autoHighlight
          // style={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={finalOptions}
          loading={loading}
          onChange={(event, newValue) => {
            let entity_id = newValue?.value_member;
            const d = entityData.find((c) => c.display_member === (newValue.display_member || newValue.displayMember));
            if (d && d.value_member) {
              entity_id = d.value_member;
              if (entity === 'InvoiceCategoryEnum') {
                props.form.setFieldValue('manual_invoice_charge_detail', []);
              }
            }

            props.form.setFieldValue(field.name, entity_id);

            if (props.onCallback) {
              props.onCallback(entity_id);
            }

            if (dependentFields && dependentFields.length) {
              for (let df of dependentFields) {
                props.form.setFieldValue(df, '');
              }
            }

            if (props.cb) {
              props.cb(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              style={{ width: `${width}` }}
              {...params}
              variant="outlined"
              // {...field}
              className="bg-white material-form__field"
              label={label}
              error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
              children={children}
              helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
              FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              placeholder={placeholder}
            />
          )}
        />) : (
        <Autocomplete
          value={selectedValue}
          id={`${entity}-autocomplete-${timestamp}`}
          defaultValue={initialDisplayText || ''}
          disabled={!!disabled}
          disableClearable
          size="small"
          // autoHighlight
          // style={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(o, value) => {
            try {
              if (o?.display_member && value?.display_member) {
                return (
                  (o.display_member || '').trim().toLowerCase() === (value.display_member || '').trim().toLowerCase()
                );
              }

              if (o?.displaymember && value?.displaymember) {
                return (o.displaymember || '').trim().toLowerCase() === (value.displaymember || '').trim().toLowerCase();
              }

              if (o?.value_member && value?.value_member) {
                return o.value_member === value.value_member;
              }
            } catch (err) {
              console.log('err--->', err);
            }

            return false;
          }}
          getOptionLabel={(o) => {
            if (o.display_member) {
              return o.display_member;
            }
            if (o.displaymember) {
              return o.displaymember;
            }

            return '';
          }}
          options={finalOptions}
          loading={loading}
          onChange={(event, newValue) => {
            let entity_id = newValue?.value_member;
            const d = entityData.find((c) => c.display_member === (newValue.display_member || newValue.displayMember));
            if (d && d.value_member) {
              entity_id = d.value_member;
              if (entity === 'InvoiceCategoryEnum') {
                props.form.setFieldValue('manual_invoice_charge_detail', []);
              }
            }

            props.form.setFieldValue(field.name, entity_id);

            if (props.onCallback) {
              props.onCallback(entity_id);
            }

            if (dependentFields && dependentFields.length) {
              for (let df of dependentFields) {
                props.form.setFieldValue(df, '');
              }
            }

            if (props.cb) {
              props.cb(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              style={{ width: `${width}` }}
              {...params}
              variant="outlined"
              // {...field}
              className="bg-white material-form__field"
              label={label}
              error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
              children={children}
              helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
              FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              placeholder={placeholder}
            />
          )}
        />)}

    </div>
  );
}

export default ReferenceDataSelect;
