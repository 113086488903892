import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { Button as ReactStrapButton } from 'reactstrap';
import { IconButton, Button, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Edit, Delete, HighlightOffOutlined, DateRange } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import dayjs from 'dayjs';
import { isToday, isPast } from 'date-fns';

import notifications from 'lib/notifications';
import { actions as locationActions } from 'redux/locations';
import { actions as refDataActions } from 'redux/referenceData';
import { actions as storageActions } from 'redux/storage';
import { ReactComponent as calendarIcon } from 'containers/Html/images/calendar-black-icon.svg';

const CreatePlan = ({ setIsLoading, setIsCreatePlan, isEditPlan, setIsEditPlan, selectedPlan }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user);
  const commodityTypeForm = useSelector((state) => state.referenceData.data['commodityTypeForm']);
  const typeFormCommodity = useSelector((state) => state.referenceData.data['typeFormCommodity']);
  const commodityResidue = useSelector((state) => state.referenceData.data['commodityResidue']);
  const locations = useSelector((state) => state?.locations?.storageLocations);
  const userStoragePlans = useSelector((state) => state?.userStorage?.storagePlans);
  let consume = isEditPlan
    ? selectedPlan?.child_rows?.filter((item) => item.stock_in_out_type_enum === 5)
    : userStoragePlans?.[0]?.consume || [];
  consume = consume.map((item) => ({ ...item, level_id: item.level_id ? item.level_id : Math.random() }));
  let produce = isEditPlan
    ? selectedPlan?.child_rows?.filter((item) => item.stock_in_out_type_enum === 4)
    : userStoragePlans?.[0]?.produce || [];
  produce = produce.map((item) => ({ ...item, level_id: item.level_id ? item.level_id : Math.random() }));

  useEffect(() => {
    const personId = userDetails?.person_id;
    const params = `person_id=${personId}`;
    dispatch(locationActions.getStorageLocations(params));
  }, []);

  const [consumeTableData, setConsumeTableData] = useState([...consume]);

  const [produceTableData, setProduceTableData] = useState([...produce]);

  const [isEditable, setIsEditable] = useState(false);
  const [storageId, setStorageId] = useState(isEditPlan ? selectedPlan?.storage_id : 0);
  const [selectedLocation, setSelectedLocation] = useState(
    isEditPlan ? (selectedPlan?.location_code ? selectedPlan?.location_code : '') : '',
  );
  const [selectedLocationId, setSelectedLocationId] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [planName, setPlanName] = useState(isEditPlan ? selectedPlan?.storage_plan_name : '');
  const [startDate, setStartDate] = useState(
    isEditPlan ? (selectedPlan?.movement_from_date ? new Date(selectedPlan?.movement_from_date) : '') : '',
  );
  const [endDate, setEndDate] = useState(
    isEditPlan ? (selectedPlan?.movement_to_date ? new Date(selectedPlan?.movement_to_date) : '') : '',
  );

  useEffect(() => {
    if (!Object.values(commodityTypeForm).length) {
      dispatch(refDataActions.getCommodityDropdownData('commodityTypeForm', {}));
    }
  }, []);

  useEffect(() => {
    if (selectedPlan?.location_code && locations.length) {
      setSelectedLocation(locations?.find((item) => item.address_text === selectedPlan?.location_code));
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (consume?.length) {
      setConsumeTableData(consume);
    }
    if (produce?.length) {
      setProduceTableData(produce);
    }
  }, []);

  const deletePlanDetials = async (storagePlanDetailId) => {
    const success = await dispatch(storageActions.deleteStoragePlanDetail(storagePlanDetailId));
    success &&
      notifications.show({
        type: 'success',
        message: 'Plan details deleted successfully.',
        key: 'req-form',
      });
    return success;
  };

  const handelConsumeDelete = async (e) => {
    const rowToDelete = parseFloat(e.currentTarget.id);
    const data = consumeTableData.filter((item) => item.level_id !== rowToDelete);
    const selectedRow = consumeTableData.filter((item) => item.level_id === rowToDelete);
    const storagePlanDetailId = selectedRow?.[0]?.storage_plan_detail_id;
    if (storagePlanDetailId) {
      const success = await deletePlanDetials(storagePlanDetailId);
      success && setConsumeTableData(data);
    } else {
      setConsumeTableData(data);
    }
  };

  const handelProduceDelete = async (e) => {
    const rowToDelete = parseFloat(e.currentTarget.id);
    const data = produceTableData.filter((item) => item.level_id !== rowToDelete);
    const selectedRow = produceTableData.filter((item) => item.level_id === rowToDelete);
    const storagePlanDetailId = selectedRow?.[0]?.storage_plan_detail_id;
    if (storagePlanDetailId) {
      const success = await deletePlanDetials(storagePlanDetailId);
      success && setProduceTableData(data);
    } else {
      setProduceTableData(data);
    }
  };

  const handelReset = () => {
    setPlanName('');
    setStorageId('');
    setStartDate('');
    setEndDate('');
    setSelectedLocation('');
    setSelectedLocationId('')
    setConsumeTableData([]);
    setProduceTableData([]);
  };

  useEffect(() => {
    !isEditPlan && handelReset()
  }, [isEditPlan])

  const CONSUME_TABLE_HEADERS = [
    {
      title: 'Form',
      dataIndex: 'form',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
            <Autocomplete
              value={commodityTypeForm.find((e) => e.commodity_form_master_id === row.commodity_form_master_id)}
              disableClearable
              options={commodityTypeForm}
              getOptionLabel={(o) => {
                if (o.display_member) {
                  return o.display_member;
                }
                return '';
              }}
              onChange={(event, newValue) => {
                let data = [...consumeTableData];
                data[index] = {
                  ...data[index],
                  commodity_form_master_id: newValue?.commodity_form_master_id,
                  commodity_form_master_code: newValue?.commodity_form_code,
                  commodity_type_master_id: newValue?.commodity_type_master_id,
                  commodity_type_master_code: newValue?.commodity_type_code,
                  commodity_form_code: newValue?.commodity_form_code,
                  type: newValue?.commodity_type_id,
                  commodity_type_code: newValue?.commodity_type_code,
                };
                setConsumeTableData(data);
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            />
          ) : (
            row.commodity_form_master_code
          )}
        </div>
      ),
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity',
      width: '200px',
      align: 'center',
      render: (text, row, index) => {
        let qId;
        if (row.commodity_form_master_code && row.commodity_type_master_code) {
          const qparams = {
            commodity_type_code: row.commodity_type_master_code,
            commodity_form_code: row.commodity_form_master_code,
          };
          qId = Object.values(qparams).join('');
          useEffect(() => {
            dispatch(refDataActions.getCommodityDropdownData('typeFormCommodity', qparams));
          }, [row.commodity_type_master_code, row.commodity_form_master_code]);
        }
        return (
          <div className="bfc-table-list-components text-center">
            {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
              <Autocomplete
                value={
                  typeFormCommodity[qId]?.length > 0
                    ? typeFormCommodity[qId]?.find((e) => e.display_member === row.commodity_code)
                    : ''
                }
                disableClearable
                options={typeFormCommodity[qId]?.length ? typeFormCommodity[qId] : []}
                getOptionLabel={(o) => {
                  if (o.display_member) {
                    return o.display_member;
                  }
                  return '';
                }}
                onChange={(event, newValue) => {
                  let data = [...consumeTableData];
                  data[index] = {
                    ...data[index],
                    commodity_id: newValue?.value_member,
                    commodity_code: newValue?.display_member,
                  };
                  setConsumeTableData(data);
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
              />
            ) : (
              row.commodity_code
            )}
          </div>
        );
      },
    },
    {
      title: 'Residue',
      dataIndex: 'residue',
      width: '200px',
      align: 'center',
      render: (text, row, index) => {
        let qId;
        if (row.commodity_form_master_code && row.commodity_type_master_code && row.commodity_code) {
          const qparams = {
            commodity_type_code: row.commodity_type_master_code,
            commodity_form_code: row.commodity_form_master_code,
            commodity_code: row.commodity_code,
          };
          qId = Object.values(qparams).join('');
          useEffect(() => {
            dispatch(refDataActions.getCommodityDropdownData('commodityResidue', qparams));
          }, [row.commodity_type_master_code, row.commodity_form_master_code, row.commodity_code]);
        }
        return (
          <div className="bfc-table-list-components text-center">
            {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
              <Autocomplete
                value={
                  commodityResidue[qId]?.length > 0
                    ? commodityResidue[qId]?.find((e) => e.display_member === row.commodity_residue_master_code)
                    : ''
                }
                disableClearable
                options={commodityResidue[qId]?.length ? commodityResidue[qId] : []}
                getOptionLabel={(o) => {
                  if (o.display_member) {
                    return o.display_member;
                  }
                  return '';
                }}
                onChange={(event, newValue) => {
                  let data = [...consumeTableData];
                  if (data.every((item) => item.commodity_residue_master_code !== newValue?.display_member)) {
                    data[index] = {
                      ...data[index],
                      commodity_residue_master_id: newValue?.commodity_residue_master_id,
                      commodity_residue_code: newValue?.display_member,
                      commodity_residue_master_code: newValue?.display_member,
                    };
                    setConsumeTableData(data);
                  } else {
                    notifications.show({
                      type: 'error',
                      message: 'Duplicate Combinations not allowed. Please create unique combinations.',
                      key: 'req-form',
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
              />
            ) : (
              row.commodity_residue_master_code
            )}
          </div>
        );
      },
    },
    {
      title: 'Current Quantity',
      dataIndex: 'current_quantity',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
            <TextField
              id={row.id}
              type="number"
              variant="outlined"
              size="small"
              disabled
              value={row.current_quantity}
              onChange={(e) => {
                let data = [...consumeTableData];
                data[index] = {
                  ...data[index],
                  current_quantity: parseFloat(e.target.value),
                };
                setConsumeTableData(data);
              }}
            />
          ) : (
            row.current_quantity
          )}
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
            <div className="d-flex align-items-center">
              <TextField
                id={row.id}
                type="number"
                variant="outlined"
                size="small"
                value={row.qty}
                onChange={(e) => {
                  let data = [...consumeTableData];
                  data[index] = {
                    ...data[index],
                    qty: parseFloat(e.target.value),
                  };
                  setConsumeTableData(data);
                }}
              />
              <span className="font-weight-bold ml-2">/Day</span>
            </div>
          ) : (
            row.qty
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {isEditable && !row.isAdd && selectedRowId === row.level_id && consume?.length > 0 ? (
            <IconButton
              id={row.level_id}
              size="small"
              style={{ color: 'var(--unnamed-color-1b62ab)' }}
              onClick={() => setIsEditable(false)}>
              <HighlightOffOutlined />
            </IconButton>
          ) : (
            !row.isAdd && (
              <>
                <IconButton
                  id={row.level_id}
                  size="small"
                  style={{ color: 'var(--unnamed-color-1b62ab)' }}
                  onClick={() => {
                    setIsEditable(true);
                    setSelectedRowId(row.level_id);
                  }}>
                  <Edit />
                </IconButton>
                <IconButton
                  id={row.level_id}
                  size="small"
                  style={{ color: 'red' }}
                  onClick={(e) => handelConsumeDelete(e)}>
                  <Delete />
                </IconButton>
              </>
            )
          )}
          {row.isAdd && (
            <IconButton id={row.level_id} size="small" style={{ color: 'red' }} onClick={(e) => handelConsumeDelete(e)}>
              <Delete />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const PRODUCE_TABLE_HEADERS = [
    {
      title: 'Form',
      dataIndex: 'form',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && produce?.length > 0) || row.isAdd ? (
            <Autocomplete
              value={commodityTypeForm.find((e) => e.commodity_form_master_id === row.commodity_form_master_id)}
              disableClearable
              options={commodityTypeForm}
              getOptionLabel={(o) => {
                if (o.display_member) {
                  return o.display_member;
                }
                return '';
              }}
              onChange={(event, newValue) => {
                let data = [...produceTableData];
                data[index] = {
                  ...data[index],
                  commodity_form_master_id: newValue?.commodity_form_master_id,
                  commodity_form_master_code: newValue?.commodity_form_code,
                  commodity_type_master_id: newValue?.commodity_type_master_id,
                  commodity_type_master_code: newValue?.commodity_type_code,
                  commodity_form_code: newValue?.commodity_form_code,
                  type: newValue?.commodity_type_id,
                  commodity_type_code: newValue?.commodity_type_code,
                };
                setProduceTableData(data);
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            />
          ) : (
            row.commodity_form_master_code
          )}
        </div>
      ),
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity',
      width: '200px',
      align: 'center',
      render: (text, row, index) => {
        let qId;
        if (row.commodity_form_master_code && row.commodity_type_master_code) {
          const qparams = {
            commodity_type_code: row.commodity_type_master_code,
            commodity_form_code: row.commodity_form_master_code,
          };
          qId = Object.values(qparams).join('');
          useEffect(() => {
            dispatch(refDataActions.getCommodityDropdownData('typeFormCommodity', qparams));
          }, [row.commodity_type_master_code, row.commodity_form_master_code]);
        }
        return (
          <div className="bfc-table-list-components text-center">
            {(isEditable && selectedRowId === row.level_id && produce?.length > 0) || row.isAdd ? (
              <Autocomplete
                value={
                  typeFormCommodity[qId]?.length > 0
                    ? typeFormCommodity[qId]?.find((e) => e.display_member === row.commodity_code)
                    : ''
                }
                disableClearable
                options={typeFormCommodity[qId]?.length ? typeFormCommodity[qId] : []}
                getOptionLabel={(o) => {
                  if (o.display_member) {
                    return o.display_member;
                  }
                  return '';
                }}
                onChange={(event, newValue) => {
                  let data = [...produceTableData];
                  data[index] = {
                    ...data[index],
                    commodity_id: newValue?.value_member,
                    commodity_code: newValue?.display_member,
                  };
                  setProduceTableData(data);
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
              />
            ) : (
              row.commodity_code
            )}
          </div>
        );
      },
    },
    {
      title: 'Residue',
      dataIndex: 'residue',
      width: '200px',
      align: 'center',
      render: (text, row, index) => {
        let qId;
        if (row.commodity_form_master_code && row.commodity_type_master_code && row.commodity_code) {
          const qparams = {
            commodity_type_code: row.commodity_type_master_code,
            commodity_form_code: row.commodity_form_master_code,
            commodity_code: row.commodity_code,
          };
          qId = Object.values(qparams).join('');
          useEffect(() => {
            dispatch(refDataActions.getCommodityDropdownData('commodityResidue', qparams));
          }, [row.commodity_type_master_code, row.commodity_form_master_code, row.commodity_code]);
        }
        return (
          <div className="bfc-table-list-components text-center">
            {(isEditable && selectedRowId === row.level_id && produce?.length > 0) || row.isAdd ? (
              <Autocomplete
                value={
                  commodityResidue[qId]?.length > 0
                    ? commodityResidue[qId]?.find((e) => e.display_member === row.commodity_residue_master_code)
                    : ''
                }
                disableClearable
                options={commodityResidue[qId]?.length ? commodityResidue[qId] : []}
                getOptionLabel={(o) => {
                  if (o.display_member) {
                    return o.display_member;
                  }
                  return '';
                }}
                onChange={(event, newValue) => {
                  let data = [...produceTableData];
                  if (data.every((item) => item.commodity_residue_master_code !== newValue?.display_member)) {
                    data[index] = {
                      ...data[index],
                      commodity_residue_master_id: newValue?.commodity_residue_master_id,
                      commodity_residue_code: newValue?.display_member,
                      commodity_residue_master_code: newValue?.display_member,
                    };
                    setProduceTableData(data);
                  } else {
                    notifications.show({
                      type: 'error',
                      message: 'Duplicate Combinations not allowed. Please create unique combinations.',
                      key: 'req-form',
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
              />
            ) : (
              row.commodity_residue_master_code
            )}
          </div>
        );
      },
    },
    {
      title: 'Current Quantity',
      dataIndex: 'current_quantity',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && consume?.length > 0) || row.isAdd ? (
            <TextField
              id={row.id}
              type="number"
              variant="outlined"
              size="small"
              value={row.current_quantity}
              disabled
              onChange={(e) => {
                let data = [...consumeTableData];
                data[index] = {
                  ...data[index],
                  current_quantity: parseFloat(e.target.value),
                };
                setConsumeTableData(data);
              }}
            />
          ) : (
            row.current_quantity
          )}
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '200px',
      align: 'center',
      render: (text, row, index) => (
        <div className="bfc-table-list-components text-center">
          {(isEditable && selectedRowId === row.level_id && produce?.length > 0) || row.isAdd ? (
            <div className="d-flex align-items-center">
              <TextField
                id={row.id}
                type="number"
                variant="outlined"
                size="small"
                value={row.qty}
                onChange={(e) => {
                  let data = [...produceTableData];
                  data[index] = {
                    ...data[index],
                    qty: parseFloat(e.target.value),
                  };
                  setProduceTableData(data);
                }}
              />
              <span className="font-weight-bold ml-2">/Day</span>
            </div>
          ) : (
            row.qty
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {isEditable && !row.isAdd && selectedRowId === row.level_id && produce?.length > 0 ? (
            <IconButton
              id={row.level_id}
              size="small"
              style={{ color: 'var(--unnamed-color-1b62ab)' }}
              onClick={() => setIsEditable(false)}>
              <HighlightOffOutlined />
            </IconButton>
          ) : (
            !row.isAdd && (
              <>
                <IconButton
                  id={row.level_id}
                  size="small"
                  style={{ color: 'var(--unnamed-color-1b62ab)' }}
                  onClick={() => {
                    setIsEditable(true);
                    setSelectedRowId(row.level_id);
                  }}>
                  <Edit />
                </IconButton>
                <IconButton
                  id={row.level_id}
                  size="small"
                  style={{ color: 'red' }}
                  onClick={(e) => handelProduceDelete(e)}>
                  <Delete />
                </IconButton>
              </>
            )
          )}
          {row.isAdd && (
            <IconButton id={row.level_id} size="small" style={{ color: 'red' }} onClick={(e) => handelProduceDelete(e)}>
              <Delete />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="mt-2">
      <div className="row form__form-group">
        <div className="col-md-4">
          <span className="form-group-label" style={{ fontWeight: 'bold' }}>
            Plan Name
          </span>
          <span className="required" style={{ color: 'red' }}>
            *
          </span>
          <div className="form-group-field">
            <TextField
              fullWidth
              value={planName}
              onChange={(e) => {
                if (userStoragePlans.every((item) => item?.storage_plan_name !== e.target.value)) {
                  setPlanName(e.target.value);
                } else {
                  notifications.show({
                    type: 'error',
                    message: 'Storage plan with same name already exists. Please use different plan name.',
                    key: 'req-form',
                  });
                }
              }}
              variant="outlined"
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="row form__form-group">
        <div className="col-md-4">
          <span className="form-group-label" style={{ fontWeight: 'bold' }}>
            Location
          </span>
          <span className="required" style={{ color: 'red' }}>
            *
          </span>
          <div className="form-group-field">
            <Autocomplete
              value={selectedLocation}
              disableClearable
              options={locations.length ? locations.filter((item) => item.storage_id !== null) : []}
              getOptionLabel={(o) => {
                if (o.address_text) {
                  return o.address_text;
                }
                return '';
              }}
              disabled={isEditPlan ? true : false}
              onChange={(event, newValue) => {
                // setSelectedLocation(newValue?.address_text);
                setSelectedLocation(newValue);
                setSelectedLocationId(newValue?.company_address_id);
                setStorageId(newValue?.storage_id);
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            />
          </div>
        </div>
        <div className="col-md-7">
          <span className="form-group-label" style={{ fontWeight: 'bold' }}>
            Movement Dates
          </span>
          <span className="required" style={{ color: 'red' }}>
            *
          </span>
          <div className="row form__form-group">
            <div className="col-md-6">
              <div className="form__form-group-field custom-input date-picker">
                <div className={`bfc-date-time-wrapper w-100"`}>
                  <DatePicker
                    selected={startDate}
                    selectsStart
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    dropDownMode="select"
                    withPortal={isMobileOnly}
                    minDate={new Date()}
                    placeholderText={'From'}
                    disabled={isEditPlan && isToday(new Date(startDate)) && isPast(new Date(startDate))}
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SvgIcon component={calendarIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form__form-group-field custom-input date-picker">
                <div className={`bfc-date-time-wrapper w-100"`}>
                  <DatePicker
                    className={`bfc-date-time-wrapper w-100"`}
                    selected={endDate}
                    selectsStart
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    dropDownMode="select"
                    withPortal={isMobileOnly}
                    minDate={new Date()}
                    placeholderText={'To'}
                    customInput={
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SvgIcon component={calendarIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="d-flex flex-column justify-content-between" style={{ borderTop: '1px solid #f0f0f0' }}>
          <div className="consume-container p-2 pt-3" style={{ borderBottom: '1px solid #f0f0f0' }}>
            <span
              style={{
                fontSize: 'var(--fs-base__zero)',
                fontWeight: 'bold',
                color: 'var(--unnamed-color-1b62ab)',
                marginLeft: 16,
              }}>
              Consume
            </span>
            <Table
              columns={CONSUME_TABLE_HEADERS}
              dataSource={consumeTableData || []}
              pagination={false}
              className="ant-table"
              size="middle"
            />
            <div className="d-flex align-items-center justify-content-end mt-2">
              <Button
                color="primary"
                variant="text"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setIsEditable(true);
                  setIsAdd(true);
                  const data = {
                    level_id: Math.random(),
                    stock_in_out_type_enum: 5,
                    commodity_form_code: '',
                    commodity_code: '',
                    commodity_residue_code: '',
                    quantity: '',
                    current_quantity: 0,
                    isAdd: true,
                  };
                  setSelectedRowId(data?.level_id);
                  setConsumeTableData([...consumeTableData, data]);
                }}
                style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'bold', textTransform: 'none' }}>
                Add a Row
              </Button>
            </div>
          </div>
          <div className="produce-container p-2 pt-3" style={{ borderBottom: '1px solid #f0f0f0' }}>
            <span
              style={{
                fontSize: 'var(--fs-base__zero)',
                fontWeight: 'bold',
                color: 'var(--unnamed-color-1b62ab)',
                marginLeft: 16,
              }}>
              Produce
            </span>
            <Table
              columns={PRODUCE_TABLE_HEADERS}
              dataSource={produceTableData || []}
              pagination={false}
              className="ant-table"
              size="middle"
            />
            <div className="d-flex align-items-center justify-content-end  mt-2">
              <Button
                color="primary"
                variant="text"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setIsEditable(true);
                  setIsAdd(true);
                  const data = {
                    level_id: Math.random(),
                    stock_in_out_type_enum: 4,
                    commodity_form_code: '',
                    commodity_code: '',
                    commodity_residue_code: '',
                    quantity: '',
                    current_quantity: 0,
                    isAdd: true,
                  };
                  setSelectedRowId(data?.level_id);
                  setProduceTableData([...produceTableData, data]);
                }}
                style={{ color: 'var(--unnamed-color-1b62ab)', fontWeight: 'bold', textTransform: 'none' }}>
                Add a Row
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mb-3 mt-4">
          <ReactStrapButton
            color="primary"
            onClick={async () => {
              if (planName && selectedLocation && startDate && endDate) {
                let consume = [...consumeTableData];
                let produce = [...produceTableData];
                consume = consume.map((item) => ({
                  ...item,
                  stock_in_out_type_enum: 5,
                  storage_plan_detail_id: item.isAdd ? 0 : item.storage_plan_detail_id,
                }));
                produce = produce.map((item) => ({
                  ...item,
                  stock_in_out_type_enum: 4,
                  storage_plan_detail_id: item.isAdd ? 0 : item.storage_plan_detail_id,
                }));
                const params = {
                  storage_plan_master_id: isEditPlan ? selectedPlan?.storage_plan_master_id : 0,
                  storage_plan_name: planName,
                  storage_id: storageId,
                  address_id: selectedLocationId,
                  movement_from_date: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
                  movement_to_date: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
                  storage_plan_detail: [...consume, ...produce],
                };
                setIsLoading(true);
                const success = await dispatch(storageActions.saveUpdateStoragePlan(params));
                if (success) {
                  handelReset();
                  setIsCreatePlan(false);
                  setIsEditPlan(false);
                  setIsLoading(false);
                  notifications.show({
                    type: 'success',
                    message: `Plan ${isEditPlan ? 'updated' : 'created'} successfully.`,
                    key: 'req-form',
                  });
                } else {
                  setTimeout(() => setIsLoading(false), 3000);
                }
              } else {
                notifications.show({
                  type: 'error',
                  message: 'Please fill all the required fields.',
                  key: 'req-form',
                });
              }
            }}>
            Save
          </ReactStrapButton>
        </div>
      </>
    </div>
  );
};
export default CreatePlan;
