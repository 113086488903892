import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

//List of All Drivers
export const fetchList = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/DieselDispense/FetchAllDieselDispenseDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

export const saveAndUpdateFuelConfiguration = async (params = {}) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/DieselDispense/SaveAndUpdateDieselDispenselChanges`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
    throw errors;
  }
};

// // export const fetchRefDataCategory = async (params = {}) => {
// //   try {
// //     const bodyParams = reqTransform.create(params);
// //     const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/ReadReferenceData`;
// //     const res = await utils.fetch(url, {
// //       method: 'GET',
// //       body: JSON.stringify(bodyParams),
// //     }).ready;
// //     const resData = await utils.processApiRes(res);
// //     console.log("Omkar Response Data: ", resData)
// //     return resTransform.create(resData);
// //   } catch (err) {
// //     console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
// //     const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
// //     throw errors;
// //   }
// // };

export const fetchRefDataCategory = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/ReadReferenceData?${qs}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchZohoAccountCreds');
    throw errors;
  }
};

// export const deleteStorageRecordFuel = async (params = {}) => {
//   try {
//     const bodyParams = reqTransform.create(params);
//     const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/DeleteStorageRecordFuel`;
//     const res = await utils.fetch(url, {
//       method: 'PUT',
//       body: JSON.stringify(bodyParams),
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.create(resData);
//   } catch (err) {
//     console.log('api-FetchAllDriversForTransporter-fetchList->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'FetchAllDriversForTransporter');
//     throw errors;
//   }
// };

// export const deleteStorageRecordFuel = async (id) => {
//   try {
//     const qs = reqTransform.destroy(id);
//     const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/DeleteStorageRecordFuel?${qs}`;
//     const res = await utils.fetch(url, {
//       method: 'PUT',
//     }).ready;
//     const resData = await utils.processApiRes(res);
//     return resTransform.destroy(resData);
//   } catch (err) {
//     console.log('DeleteDriverForTransporter->err---->', err);
//     const errors = await utils.processApiErrors(err, '', 'DeleteDriverForTransporter');
//     throw errors;
//   }
// };
