import React, { useEffect, useState, useRef } from 'react';
import { Table, Skeleton } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';

import AntPagination from 'common/AntTablePagination';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import AmountField from 'common/TableComponents/amountField';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import ClosingDateField from 'common/TableComponents/closingDateField';
import AntTableStatus from 'common/AntTableStatus';
import AntTableActions from 'common/TableActions';
import { actions as rentalActions } from 'redux/equipmentRental';
import OverviewHeader from 'common/OverviewHeader';
import FilterUtils from 'lib/filterUtils';
import useQuery from 'lib/hooks/useQuery';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import { paymentService } from 'services';
// import LogoLoader from 'common/LogoLoader';
import notifications from 'lib/notifications';

const EquipmentPayments = () => {
  const dispatch = useDispatch();
  const { page = 1, payment_master_id, commodity_deal_master_id, payment_made_date, paid_to, paid_by, custom_invoice_number } = useQuery();
  const history = useHistory();
  const tableRef = useRef();
  const csvLink = useRef();
  const rentalState = useSelector((state) => state.rental);
  const equipmentPaymentData = Object.values(rentalState.entities);
  const busy = _.get(rentalState, 'busy') || false;
  const reportData = _.get(rentalState, 'reportData') || [];
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const encodeBackUrl = utils.encodeBackUrl();

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      payment_master_id,
      commodity_deal_master_id,
      payment_made_date,
      paid_to,
      paid_by,
      custom_invoice_number
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(rentalActions.getEquipmentRentalPaymentDetails(params));
  }, [page, rowsPerPage, payment_master_id, commodity_deal_master_id, payment_made_date, paid_by, paid_to, custom_invoice_number]);

  const PAYMENT_TABLE_HEADERS = [
    {
      title: 'Payment ID',
      dataIndex: 'payment_master_id',
      width: '130px',
      align: 'center',
      fixed: true,
      sorter: (a, b) => a.payment_master_id - b.payment_master_id,
      ellipsis: true,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {/* {row.payment_master_id ? <Link to={row.detailsPageLink}>{row.payment_master_id}</Link> : 'N/A'} */}
          {row.payment_master_id}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'payment_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_master_id}
          name={'Payment ID'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'payment_master_id'),
      filteredValue: payment_master_id !== undefined ? payment_master_id : null,
    },
    {
      title: 'Deal Id',
      dataIndex: 'commodity_deal_master_id',
      key: 'commodity_deal_master_id',
      width: '120px',
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{row.commodity_deal_master_id}</div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'commodity_deal_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_deal_master_id}
          name={'Deal Id'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_deal_master_id'),
      filteredValue: commodity_deal_master_id !== undefined ? commodity_deal_master_id : null,
    },
    {
      title: 'Invoice',
      dataIndex: 'custom_invoice_number',
      width: '150px',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components">
          <div className="commodity-form text-center">
            <Link to={getUrlWithEncryptedQueryParams(`/invoices/${row?.invoice_master_id}?back=${encodeBackUrl}`)}>{row.custom_invoice_number}</Link>
          </div>
        </div>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'custom_invoice_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={custom_invoice_number}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'custom_invoice_number'),
      filteredValue: custom_invoice_number !== undefined ? custom_invoice_number : null,
    },
    {
      title: 'Buyer',
      dataIndex: 'paid_by',
      key: 'paid_by',
      width: '200px',
      align: 'center',
      sorter: (a, b) =>
        a.paid_by_entity_code && a.paid_by_entity_code.localeCompare(b.paid_by_entity_code && b.paid_by_entity_code),
      ellipsis: true,
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.paid_by_entity_code}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'paid_by'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={paid_by}
          name={'Paid By'}
          codeBaisedFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'paid_by'),
      filteredValue: paid_by !== undefined ? paid_by : null,
    },
    {
      title: 'Seller',
      dataIndex: 'paid_to',
      key: 'paid_to',
      width: '200px',
      align: 'center',
      sorter: (a, b) =>
        a.paid_to_entity_code && a.paid_to_entity_code.localeCompare(b.paid_to_entity_code && b.paid_to_entity_code),
      ellipsis: true,
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.paid_to_entity_code}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'paid_to'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={paid_to}
          name={'Paid To'}
          codeBaisedFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'paid_to'),
      filteredValue: paid_to !== undefined ? paid_to : null,
    },
    {
      title: 'TDS',
      align: 'center',
      width: '120px',
      dataIndex: 'tds_amount',
      render: (text, row) => <AmountField expected_price={row.tds} type={'payment'} />,
    },
    {
      title: 'Total Amount',
      align: 'center',
      width: '120px',
      dataIndex: 'total_payment_amount',
      render: (text, row) => <AmountField expected_price={row.total_payment_amount} type={'payment'} />,
    },
    {
      title: 'Amount Paid',
      align: 'center',
      width: '120px',
      dataIndex: 'total_payment_amount',
      render: (text, row) => <AmountField expected_price={row.amount_paid} type={'payment'} />,
    },
    {
      title: 'Amount Due',
      align: 'center',
      width: '120px',
      dataIndex: 'total_payment_amount',
      render: (text, row) => <AmountField expected_price={row.amount_due} type={'payment'} />,
    },
    {
      title: 'Paid Date Time',
      dataIndex: 'payment_made_date',
      align: 'center',
      width: '150px',
      sorter: (a, b) =>
        a.payment_made_date && a.payment_made_date.localeCompare(b.payment_made_date && b.payment_made_date),
      render: (text, row) => <ClosingDateField closing_on={row.payment_made_date} to_date={row.to_date} />,
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'payment_made_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_made_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'payment_made_date'),
      defaultFilteredValue: payment_made_date !== undefined ? payment_made_date : null,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'pg_payment_enum_code',
    //   align: 'center',
    //   width: '120px',
    //   render: (text, row) => (
    //     <span>
    //       {text == 'Failed' && <AntTableStatus text={row.pg_payment_enum_code} color="red" />}
    //       {text == 'In Process' && <AntTableStatus text={row.pg_payment_enum_code} color="blue" />}
    //       {text == 'Paid' && <AntTableStatus text={row.pg_payment_enum_code} color="green" />}
    //       {text == 'Captured' && <AntTableStatus text={row.pg_payment_enum_code} color="lime" />}
    //       {text == 'Recorded' && <AntTableStatus text={row.pg_payment_enum_code} color="gold" />}
    //       {text == 'In Dispute' && <AntTableStatus text={row.pg_payment_enum_code} color="orange" />}
    //       {text == 'Settled' && <AntTableStatus text={row.pg_payment_enum_code} color="purple" />}
    //     </span>
    //   ),
    //   filterDropdown: (props) => (
    //     <AutoCompleteFilter
    //       dataIndex={'pg_payment_enum_code'}
    //       data={props}
    //       onFilterChange={onFilterChange}
    //       handleReset={handleReset}
    //       value={pg_payment_enum}
    //       name={'Status'}
    //     />
    //   ),
    //   filterIcon: (filtered) => getFilteredIcon(filtered, 'pg_payment_enum'),
    //   defaultFilteredValue: pg_payment_enum !== undefined ? pg_payment_enum : null,
    // },
    {
      title: '',
      dataIndex: 'edit_',
      width: '90px',
      align: 'center',
      render: (value, row, index) => {
        const showFlag = row.is_paid === 0;
        const obj = {
          children: (
            <AntTableActions
              row={row}
              showFlag={showFlag}
              controls={[
                {
                  name: 'Pay',
                  onClick: () => handleOnlinePayment(row),
                  controlColor: 'var(--unnamed-color-1b62ab)',
                },
              ]}
            />
          ),
          props: {},
        };
        if(CT_parent){
          return obj;
        }else{
          return null;
        }

      },
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const handleOnlinePayment = async (row) => {
    try {
      notifications.show({
        type: 'info',
        message: 'Initiating Payment...please wait',
      });

      const params = {
        commodity_deal_master_id: row?.commodity_deal_master_id,
      };
      const resData = await dispatch(rentalActions.payEquipmentRentalPayment(params));
      let razorPayParams = {
        key: _.get(resData, 'bf_prefill_data_model.pg_key') || '',
        amount: _.get(resData, 'bf_payment_master_response.total_payment_amount') || '',
        name: _.get(resData, 'bf_prefill_data_model.name') || '',
        order_id: _.get(resData, 'bf_payment_master_response.pg_order_id') || '',
        handler: function (response) {
          notifications.show({
            type: 'success',
            message: 'Payment Successful',
          });
          const backLink = utils.encodeBackUrl();
          history.push(getUrlWithEncryptedQueryParams(`/payment/${paymentId}/overview?back=${backLink}`));
          handleReset(null, 'clearFilter');
        },
        prefill: {
          name: _.get(resData, 'bf_prefill_data_model.name') || '',
        },
      };
      var rzp1 = new Razorpay(razorPayParams);
      rzp1.open();
    } catch (err) {
      utils.displayErrors(err);
    }
  };

  let tableData = equipmentPaymentData;
  const totalRecords = tableData?.[0]?.total_count;

  tableData = tableData.map((d) => {
    return {
      ...d,
      detailsPageLink: getUrlWithEncryptedQueryParams(`/payment/${d.payment_master_id}/overview?back=${encodeBackUrl}`),
    };
  });

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  const getExportReport = async () => {
    const filterParams = {
      filters: {
        ...filters,
      },
      page_size: 10000,
      offset: 0,
      isCsv: true
    };
    notifications.show({type: 'info', message: 'Downloading Started...'})
    const success = await dispatch(rentalActions.getEquipmentRentalPaymentDetails(filterParams));
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' })
    }
  };

  return (
    <dev>
      <OverviewHeader heading={`Equipment Rental Payments`} />
      <div className="invoice-table bfc-body">
        {/* {busy && (
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        )} */}
        <div>
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? PAYMENT_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : PAYMENT_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 510 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              clearFilters={clearFilters}
              setScrollToFirstRow={setScrollToFirstRow}
              reportData={reportData}
              getExportReport={getExportReport}
              csvLink={csvLink}
              type={'Equipment Rental Payments'}
            />
          </div>
        </div>
      </div>
    </dev>
  );
};
export default EquipmentPayments;
