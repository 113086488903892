import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ConfirmModal from 'common/ConfirmModal';

const AntTableActions = ({
  row,
  showFlag,
  controls,
  isConfirmModal,
  confirmModalMsg,
  confirmModalOkBtnText,
  confirmModalCancelBtnText,
  isOpen,
}) => {
  const menu = (row) => {
    return (
      <Menu>
        {controls.map((control) =>
          (control.name === 'Delete' || control.name === 'Save' || control.name === 'Withdraw') && isConfirmModal ? (
            <ConfirmModal
              key={control.name}
              isOpen={isOpen}
              header={true}
              title="Confirm"
              message={confirmModalMsg ? confirmModalMsg : `Are you sure to proceed?`}
              btnName={control.name}
              btnStyles={{ padding: '0px 12px', color: control.controlColor }}
              color="primary"
              colored={false}
              isLink={true}
              confirmModalOkBtnText={confirmModalOkBtnText}
              confirmModalCancelBtnText={confirmModalCancelBtnText}
              onConfirm={(toggle) => {
                control.onClick();
                toggle();
              }}
            />
          ) : (
            <Menu.Item key={control.name}>
              <span
                style={{ color: control.controlColor }}
                className="ant-dropdown-link d-flex justify-content-start"
                onClick={() => control.onClick()}>
                {control.name}
              </span>
            </Menu.Item>
          ),
        )}
      </Menu>
    );
  };
  return controls.length > 1 ? (
    <Dropdown overlayStyle={{ zIndex: 1310 }} trigger="click" overlay={menu(row)} placement="bottomCenter">
      {showFlag ? (
        <a className="ant-dropdown-link d-flex justify-content-center" onClick={(e) => e.preventDefault()}>
          Actions <DownOutlined className="mt-10 ml-5" />
        </a>
      ) : (
        <div></div>
      )}
    </Dropdown>
  ) : showFlag && (
    <span style={{ color: controls?.[0]?.controlColor, cursor: 'pointer' }} className="ant-dropdown-link d-flex justify-content-center" onClick={() => controls?.[0]?.onClick()}>
      {controls?.[0]?.name}
    </span>
  );
};

export default AntTableActions;
