import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LogoLoader from 'common/LogoLoader';
import { useSelector } from 'react-redux';
import { colord as colorFormat } from 'colord';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import MUIButton from '../Button';

import getColor from '../colors';

type stylePropTypes = {
  color: string;
  textColor: string;
  type: 'modal' | 'confirmation';
};

const useStyles = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 5px ${(props: stylePropTypes) => props?.color}`,
      borderRadius: '10px',
      backgroundColor: (props: stylePropTypes) => `${colorFormat(props?.color).alpha(0.13).toHex()} !important`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props: stylePropTypes) => props?.color,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: (props: stylePropTypes) => colorFormat(props?.color).darken(50),
    },
  },
  actionsRoot: {
    padding: '12px',
  },
  iconButtonRoot: {
    padding: '7px',
    '&:hover': {
      backgroundColor: (props: stylePropTypes) =>
        `${colorFormat(props?.type === 'confirmation' ? props?.textColor : props?.color)
          .alpha(0.08)
          .toHex()} !important`,
    },
  },
}));

type MUIModalPropTypes = {
  type: 'modal' | 'confirmation';
  confirmationType?: 'info' | 'warn' | 'error';
  open: boolean;
  setOpen: Function;
  title: string;
  Icon?: React.ElementType | string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  busy?: boolean;
  children: React.ReactElement;
  actionButtons?: {
    name: string;
    onClick: Function;
    variant?: 'contained' | 'outlined' | 'text';
    disabled?: boolean;
  }[];
  confirmationMessage?: string;
};

const MUIModal = ({
  type = 'confirmation',
  confirmationType = 'info',
  open,
  setOpen,
  title,
  Icon,
  size = type === 'confirmation' ? 'xs' : 'sm',
  busy,
  children,
  actionButtons,
  confirmationMessage,
  ...rest
}: MUIModalPropTypes) => {
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config')!;
  let configColor;
  if (type === 'confirmation') {
    configColor =
      confirmationType === 'error'
        ? '#f44336'
        : confirmationType === 'warn'
        ? '#f1c40f'
        : config?.['theme_color'] || 'muiPrimary';
  }
  const bgColor = getColor(configColor);
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();
  const classes = useStyles({ color: bgColor, textColor, type });

  const handelClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={size} open={open} onClose={handelClose} {...rest}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px 24px',
          boxShadow: '0px 0px 10px #00000033',
          background:
            type === 'confirmation'
              ? confirmationType === 'error'
                ? '#f44336'
                : confirmationType === 'warn'
                ? '#f1c40f'
                : bgColor
              : '',
        }}
      >
        <div>
          {Icon ? (
            typeof Icon === 'string' ? (
              <img style={{ maxWidth: '100%', width: 'auto', marginRight: '15px' }} src={Icon} alt="" />
            ) : (
              <Icon style={{ height: 35, width: 35, fill: textColor, color: textColor }} />
            )
          ) : (
            ''
          )}
          <span
            style={{
              fontSize: 'var(--fs-base__zero)',
              fontWeight: 'bold',
              color: type === 'confirmation' ? textColor : bgColor,
              letterSpacing: '1px',
            }}
          >
            {title}
          </span>
        </div>
        <IconButton
          style={{ color: type === 'confirmation' ? textColor : bgColor }}
          onClick={handelClose}
          classes={{ root: classes.iconButtonRoot }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ padding: 0, overflowY: 'auto', position: 'relative' }} className={classes.root}>
        <div style={{ padding: '14px 24px', height: '100%', letterSpacing: '1px' }}>
          {type === 'confirmation' ? (
            <DialogContentText className="mb-2">
              <span style={{ fontSize: 'var(--fs-base__two)', letterSpacing: '1px' }}>{confirmationMessage}</span>
            </DialogContentText>
          ) : (
            ''
          )}
          {busy && (
            <div
              className="panel__refresh"
              style={{ position: 'fixed', left: 'calc(50% - 22.5px)', top: '50%', height: 'auto', width: 'auto' }}
            >
              <LogoLoader />
            </div>
          )}
          {children}
        </div>
      </DialogContent>
      {actionButtons?.length ? (
        <DialogActions classes={{ root: classes.actionsRoot }}>
          {actionButtons?.map((btn) => (
            <MUIButton
              color={bgColor}
              className={btn.name}
              key={btn.name}
              variant={btn?.variant || 'text'}
              onClick={btn.onClick}
              disabled={btn?.disabled}
            >
              {btn.name}
            </MUIButton>
          ))}
        </DialogActions>
      ) : (
        ''
      )}
    </Dialog>
  );
};
export default MUIModal;
