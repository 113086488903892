import normalize from 'normalize-object';
import notifications from 'lib/notifications';
import { yupToFormErrors } from 'formik';

export const validateSchema = async (schema, values) => {
  try {
    let result = await schema.validate(values, {
      abortEarly: false,
    });
    return result;
  } catch (err) {
    throw {
      errors: yupToFormErrors(err),
      // message: 'Schema validation error',
      message: err && err.errors ? err.errors[0] : 'Schema validation error',
    };
  }
};

export const getQueryParams = (params = {}) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};
