import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchDetails = async (id) => {
  try {
    const qs = reqTransform.fetchDetails(id);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Counter/GetLatestCounterAgainstOfferBidId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('GetLatestCounterAgainstOfferBidId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetLatestCounterAgainstOfferBidId');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Counter/CreateCounterAgainstOfferBidId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('CreateCounterAgainstOfferBidId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateCounterAgainstOfferBidId');
    throw errors;
  }
};
