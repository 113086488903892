import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const recordPaymentSchema = yup.object().shape({
  filters: yup.object().shape({
    entity_id: yup.string().required('Please Select Customer'),
  }),
});

export const fetchList = async (params) => {
  try {
    await helpers.validateSchema(recordPaymentSchema, params);
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllInvoiceDetailsReceiver = async (params) => {
  try {
    await helpers.validateSchema(recordPaymentSchema, params);
    let res = await api.fetchAllInvoiceDetailsReceiver(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchUserInvoiceList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const recordPaymentByBuyerSeller = async (params) => {
  try {
    let res = await api.recordPaymentByBuyerSeller(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const recordPaymentByBuyerSellerMultiple = async (params) => {
  try {
    let res = await api.recordPaymentByBuyerSellerMultiple(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const recordPaymentByBuyerSellerMultipleO = async (params) => {
  try {
    let res = await api.recordPaymentByBuyerSellerMultipleO(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchAllInvoiceDetails_ForPayouts = async (params) => {
  try {
    let res = await api.fetchAllInvoiceDetails_ForPayouts(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const createPayout = async (params) => {
  try {
    let res = await api.createPayout(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const createPayoutO = async (params) => {
  try {
    let res = await api.createPayoutO(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const getPayoutAttempts = async (params) => {
  try {
    let res = await api.getPayoutAttempts(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchInvoicesForPayments = async (params) => {
  try {
    let res = await api.fetchInvoicesForPayments(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchInvoicesForPaymentsDetails = async (params) => {
  try {
    let res = await api.fetchInvoicesForPaymentsDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
