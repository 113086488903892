import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../../components/FormikMaterialTextField';
import ReferenceDataSelect from './../../components/ReferenceDataSelect';
import FormikDatePickerField from 'shared/components/form/FormikDateTimePickerField';
import FormikLocationSelect from 'common/Form/FormikLocationSelectEloc';
import searchLocation from 'common/Form/searchLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { locationElocService } from 'services';
import FormikCompanySelect from './../../components/FormikCompanySelect';
// import LocationModal from '../components/locationModal';
import MUITextField from '@material-ui/core/TextField';
import { locationIdService } from 'services';
import { Popover, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import LogoLoader from 'common/LogoLoader';
import MUIButton from 'common/MUIComponents/Button';
import MUITypography from 'common/MUIComponents/Typography';
import LocationModal from 'containers/Account/Profile/Organisations/components/locationModal';

import { actions as userActions } from 'redux/user';
// import Collapse from './../components/Collapse';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as handlers from './handlers';

const getInitialValues = (address = {}, GSTINChecked) => {
  let formFields = {
    company_address_id: _.get(address, 'company_address_id') || 0,
    address_type_enum: _.get(address, 'address_type_enum') || 0,
    company_affiliate_enum: _.get(address, 'company_affiliate_enum') || 0,
    // entity_id: _.get(address, 'entity_id') || 0,
    entity_id: address?.company_affiliate_enum === 2 ? address?.company_affiliate_id : address?.company_id,
    gstin: _.get(address, 'gstin') || '',
    // address_Text: 0,
    // landmark: 0,
    // location_id: _.get(address, 'location_id') || 0,
    location_code: _.get(address, 'location_code') || '',
    // village_id: _.get(address, 'village_id') || 0,
    village_code: _.get(address, 'village_code') || '',
    // taluka_id: _.get(address, 'taluka_id') || 0,
    taluka_code: _.get(address, 'taluka_code') || '',
    // city_id: _.get(address, 'city_id') || 0,
    city_code: _.get(address, 'city_code') || '',
    // district_id: _.get(address, 'district_id') || 0,
    district_code: _.get(address, 'district_code') || '',
    // state_id: _.get(address, 'state_id') || 0,
    state_code: _.get(address, 'state_code') || '',
    country_id: _.get(address, 'country_id') || 0,
    country_code: 'India',
    address_lattitude: _.get(address, 'address_lattitude') || '',
    address_longitude: _.get(address, 'address_longitude') || '',
    place_id: _.get(address, 'place_id') || '',
    address_line1: _.get(address, 'address_line1') || '',
    address_line2: _.get(address, 'address_line2') || '',
    address_line3: _.get(address, 'address_line3') || '',
    pin_code: _.get(address, 'pin_code') || '',
    locality: _.get(address, 'locality') || '',

    sez_registration_number: _.get(address, 'sez_registration_number') || '',
    sez_registration_number_valid_until: _.get(address, 'sez_registration_number_valid_until') || null,
    lut_number: _.get(address, 'lut_number') || '',
    lut_number_valid_until: _.get(address, 'lut_number_valid_until') || null,

    location_name: _.get(address, 'location_name') || _.get(address, 'address_text') || '',
    user_id: _.get(window, 'user.userid') || 0,

    acceptTerms: GSTINChecked,
  };
  return formFields;
};

const LocationForm = (props) => {
  const locations = useSelector((state) => state.locations);
  const userInfo = useSelector((state) => state.user);
  const isBorg = userInfo?.profile?.person?.type_of_individual_enum === 4
  const isFPO = userInfo?.profile?.person?.is_fpo
  const locationId = props.locationId || 0;
  const id = props.id || 0;
  const dispatch = useDispatch();
  const history = useHistory();
  const locationDetails = locationId ? Object.values(_.get(locations, `entities`))?.find(item => item.company_address_id === parseInt(locationId)) : {};
  const busy = _.get(user, 'busy') || false;
  const isSubscribed = _.get(locationDetails, 'is_subscribed') || false;
  const canEdit = _.get(locationDetails, 'can_edit') || false;
  const gstin = _.get(locationDetails, 'gstin') || '';

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState();
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const [latLongData, setLatLongData] = useState({});
  const [latLongDataLoader, setLatLongDataLoader] = useState(false);
  const [GSTINChecked, setGSTINChecked] = useState(locationId ? gstin === '' ? true : false : false);
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');

  const [locationData, setLocationData] = useState({});

  const locationToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  };

  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };
  const getLocationEloc = async (params) => {
    const resData = await locationIdService.fetchLocationEloc(params);
    return resData;
  }

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  const followToggle = () => {
    setIsEditorOpen(!isEditorOpen);
  }

  if (locationId && _.isEmpty(locationDetails)) {
    return (
      <div className="bfc-modalform">
        <div className="bfc-modalform-header">
          <div className="icons-wrapper">
            <img src={Bidicon} alt="" />
            <span className="title">Business Location</span>
          </div>
          <div className="close-wrapper">
            <a href="" className="d-none">
              -
            </a>
            <Link to={getUrlWithEncryptedQueryParams(`/account/${id}/organisations`)}>X</Link>
          </div>
        </div>
        <div className="modalform-body">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(locationDetails, GSTINChecked)}
        onSubmit={(values, formik) => {
          locationId ? handlers.handleUpdate(values, formik, props?.setIsRerenderRequired, props?.isRerenderRequired, props?.page) : handlers.handleCreate(values, formik, props?.setIsRerenderRequired, props?.isRerenderRequired, props?.page)
        }}>
        {(formikBag) => {
          const { values } = formikBag;
          console.log('values: ', values);

          values.address_lattitude = (
            _.get(locationData, 'Latitude') ||
            _.get(latLongData, 'latitude') ||
            _.get(locationDetails, 'address_lattitude') ||
            ''
          ).toString();
          values.address_longitude = (
            _.get(locationData, 'Longitude') ||
            _.get(latLongData, 'longitude') ||
            _.get(locationDetails, 'address_longitude') ||
            ''
          ).toString();
          return (
            <div>
              <div>
                <Form className="form-container">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Select Business Entity</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name={`entity_id`}
                              component={FormikCompanySelect}
                              initialDisplayText={values?.entity_id}
                              disabled={isSubscribed ? !locationId == 0 : false}
                              cb={(newVal) => {
                                let type = _.get(newVal, 'value.type') || '';
                                let entity_id = _.get(newVal, 'value.id') || 0;
                                if (type === 'company') {
                                  formikBag.setFieldValue('company_affiliate_enum', 1);
                                } else if (type === 'affiliate') {
                                  entity_id = _.get(newVal, 'value.company_affiliate_id') || 0;
                                  formikBag.setFieldValue('company_affiliate_enum', 2);
                                }

                                if (entity_id) {
                                  formikBag.setFieldValue('entity_id', entity_id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="terms-block d-flex align-items-baseline">
                            <div>
                              <span className="form-group-label">GSTIN</span>
                              {GSTINChecked === false ? (
                                <span class="required">*</span>
                              ) : ''}
                            </div>
                            <div className='d-flex align-items-center ml-10' style={{ columnGap: 5 }}>
                              <Checkbox
                                defaultChecked={GSTINChecked}
                                onChange={(e) => {
                                  setGSTINChecked(e.target.checked);
                                  formikBag.setFieldValue('acceptTerms', e.target.checked);
                                  if (e.target.checked === true) {
                                    formikBag.setFieldValue('gstin', '');
                                  } else {
                                    formikBag.setFieldValue('gstin', _.get(locationDetails, 'gstin') || '');
                                  }
                                }}>
                                  Don't have GSTIN at this location
                              </Checkbox>
                            </div>
                          </div>
                          <div className="form-group-field">
                            <Field name="gstin" component={FormikMaterialTextField} disabled={GSTINChecked} GSTINChecked={GSTINChecked} />
                          </div>
                        </div>
                      </div>
                      {(isBorg || isFPO) ? <>
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">SEZ Registration No.</span>
                            <div className="form-group-field">
                              <Field name="sez_registration_number" component={FormikMaterialTextField} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">SEZ Valid Until</span>
                            <div className="form-group-field custom-input">
                              <Field name="sez_registration_number_valid_until" component={FormikDatePickerField} isShowTime={false} isClosingOn={true} defaultValue={values?.sez_registration_number_valid_until || ''} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">LUT Registration No.</span>
                            <div className="form-group-field">
                              <Field name="lut_number" component={FormikMaterialTextField} />
                            </div>
                          </div>
                        </div>
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">LUT Valid Until</span>
                            <div className="form-group-field custom-input">
                              <Field name="lut_number_valid_until" component={FormikDatePickerField} isShowTime={false} isClosingOn={true} defaultValue={values?.lut_number_valid_until || ''} />
                            </div>
                          </div>
                        </div>
                      </> : ""}
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Type of Location</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="address_type_enum"
                              component={ReferenceDataSelect}
                              entity="addressTypeEnum"
                              // disabled={isSubscribed ? !locationId == 0 : false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Name of Location</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              placeholderText={'E.g Pune Factory'}
                              name="location_name"
                              component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      {/*<div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">
                            Map Location
                            <p className="mb-0 float-right"
                              style={{ color: 'red' }}
                              onClick={(e) => {
                                setIsEditorOpen(true);
                              }}>
                              {values.location_code ? (
                                'Update Location'
                              ) : ''}
                            </p>
                          </span>
                          <span class="required">*</span>
                          <div className="form-group-field mt-5">
                            <div
                              className="d-flex"
                              onClick={(e) => {
                                setIsEditorOpen(true);
                              }}>
                              <LocationOnIcon className="mt-5" style={{ marginLeft: '-5px' }} />
                              <span>
                                {values.location_code ? (
                                  <span style={{ color: `#1b62ab` }}>{values.location_code}</span>
                                ) : (
                                  'Click here to location.'
                                )}
                              </span>
                            </div>
                            {isEditorOpen && (
                              <Field
                                name="location_code_geomap"
                                component={LocationModal}
                                disabled={values.location_code ? true : false}
                                initialDisplayText={values.location_code || ''}
                                isOpen={isEditorOpen}
                                toggle={locationToggle}
                                cb={async (locationValues) => {
                                  setLocationData(locationValues);
                                  let params = {
                                    location_code: locationValues.formatted_address,
                                    bearer_token: token,
                                    lat: locationValues.lat,
                                    lng: locationValues.lng,
                                  }
                                  let Eloc = _.get(locationValues, 'eLoc') || _.get(locationValues, 'e_loc') || '';
                                  if (!Eloc) {
                                    let res = await getLocationEloc(params);
                                    let data = res.suggested_locations;
                                    let found = data.find(element => element.place_address.includes(locationValues.pincode));
                                    Eloc = _.get(found, 'e_loc') || null;
                                  }
                                  let locationDetails = locationValues || '';
                                  //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                                  // if (locationValues) {
                                  //   locationDetails = locationValues[0] || {};
                                  //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                                  //   setLocDetailsEloc(locationValues[0]?.eLoc);
                                  // }
                                  let latitude = _.get(locationValues, 'lat', '1');
                                  let longitude = _.get(locationValues, 'lng', '1')
                                  let addressLine1 =
                                    (_.get(locationDetails, 'houseNumber', '')
                                      ? _.get(locationDetails, 'houseNumber', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'houseName', '')
                                      ? _.get(locationDetails, 'houseName', '')
                                      : '');

                                  let addressLine2 =
                                    (_.get(locationDetails, 'street', '')
                                      ? _.get(locationDetails, 'street', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'subSubLocality', '')
                                      ? _.get(locationDetails, 'subSubLocality', '')
                                      : '');

                                  let addressLine3 =
                                    (_.get(locationDetails, 'subLocality', '')
                                      ? _.get(locationDetails, 'subLocality', '') + ', '
                                      : '') +
                                    (_.get(locationDetails, 'locality', '')
                                      ? _.get(locationDetails, 'locality', '')
                                      : '');

                                  formikBag.setFieldValue('place_id', Eloc);
                                  formikBag.setFieldValue('address_lattitude', latitude.toString());
                                  formikBag.setFieldValue('address_longitude', longitude.toString());
                                  // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                                  formikBag.setFieldValue('pin_code', _.get(locationDetails, 'pincode') || '');
                                  formikBag.setFieldValue('address_line1', addressLine1);
                                  formikBag.setFieldValue('address_line2', addressLine2);
                                  formikBag.setFieldValue('address_line3', addressLine3);
                                  formikBag.setFieldValue('city_code', _.get(locationDetails, 'city') || '');
                                  formikBag.setFieldValue('locality', _.get(locationDetails, 'locality') || '');
                                  formikBag.setFieldValue(
                                    'village_code',
                                    _.get(locationDetails, 'village') ||
                                    _.get(locationDetails, 'city') ||
                                    _.get(locationDetails, 'subDistrict') ||
                                    '',
                                  );
                                  formikBag.setFieldValue('city_code', _.get(locationDetails, 'city') || '');
                                  formikBag.setFieldValue('district_code', _.get(locationDetails, 'district') || '');
                                  formikBag.setFieldValue('state_code', _.get(locationDetails, 'state') || '');
                                  // formikBag.setFieldValue(
                                  //   'location_code',
                                  //   (_.get(locationDetails, 'placeName', '')
                                  //     ? _.get(locationDetails, 'placeName', '') + ', '
                                  //     : '') + _.get(locationDetails, 'placeAddress', ''),
                                  // );
                                  if (locationValues) {
                                    // locationDetails = locationValues[0] || {};
                                    // setLocDetailsValue(locationValues[0]?.addressTokens);
                                    setLocDetailsEloc(locationValues[0]?.eLoc);
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Location</span>
                          <span class="required">*</span>
                          <Row style={{ marginLeft: 0 }}>
                          <div className="form-group-field" style={{ width: '85%' }}>
                            <Field
                              name={`location_code`}
                              component={searchLocation}
                              initialDisplayText={values.location_code || ''}
                              disabled={isSubscribed ? !locationId == 0 : false}
                              cb={(locationValues) => {
                                let locationDetails = locationValues[0] || {};
                                //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                                // if (locationValues) {
                                //   locationDetails = locationValues[0] || {};
                                //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                                //   setLocDetailsEloc(locationValues[0]?.eLoc);
                                // }

                                let addressLine1 =
                                  (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                    ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                    : '') +
                                  (_.get(locationDetails, 'addressTokens.houseName', '')
                                    ? _.get(locationDetails, 'addressTokens.houseName', '')
                                    : '');

                                let addressLine2 =
                                  (_.get(locationDetails, 'addressTokens.street', '')
                                    ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                    : '') +
                                  (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                    ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                    : '');

                                let addressLine3 =
                                  (_.get(locationDetails, 'addressTokens.subLocality', '')
                                    ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                    : '') +
                                  (_.get(locationDetails, 'addressTokens.locality', '')
                                    ? _.get(locationDetails, 'addressTokens.locality', '')
                                    : '');

                                // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                                formikBag.setFieldValue(
                                  'pin_code',
                                  _.get(locationDetails.addressTokens, 'pincode') || '',
                                );
                                formikBag.setFieldValue('address_line1', addressLine1);
                                formikBag.setFieldValue('address_line2', addressLine2);
                                formikBag.setFieldValue('address_line3', addressLine3);
                                formikBag.setFieldValue(
                                  'city_code',
                                  _.get(locationDetails.addressTokens, 'city') || '',
                                );
                                formikBag.setFieldValue(
                                  'locality',
                                  _.get(locationDetails.addressTokens, 'locality') || '',
                                );
                                formikBag.setFieldValue(
                                  'village_code',
                                  _.get(locationDetails.addressTokens, 'village') ||
                                  _.get(locationDetails.addressTokens, 'city') ||
                                  _.get(locationDetails.addressTokens, 'subDistrict') ||
                                  '',
                                );
                                formikBag.setFieldValue(
                                  'city_code',
                                  _.get(locationDetails.addressTokens, 'city') || '',
                                );
                                formikBag.setFieldValue(
                                  'district_code',
                                  _.get(locationDetails.addressTokens, 'district') || '',
                                );
                                formikBag.setFieldValue(
                                  'state_code',
                                  _.get(locationDetails.addressTokens, 'state') || '',
                                );
                                formikBag.setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
                                // formikBag.setFieldValue('address_lattitude', _.get(locationDetails.addressTokens, 'latitude', '1'));
                                // formikBag.setFieldValue('address_longitude', _.get(locationDetails.addressTokens, 'longitude', '1'));
                                formikBag.setFieldValue(
                                  'location_code',
                                  (_.get(locationDetails, 'placeName', '')
                                    ? _.get(locationDetails, 'placeName', '') + ', '
                                    : '') + _.get(locationDetails, 'placeAddress', ''),
                                );
                                if (locationValues) {
                                  // locationDetails = locationValues[0] || {};
                                  // setLocDetailsValue(locationValues[0]?.addressTokens);
                                  setLocDetailsEloc(locationValues[0]?.eLoc);
                                }
                              }}
                            />
                          </div>
                          <div className="ml-auto mr-auto">
                              <div className="icon-group">
                                {/* <Popover content={ isSubscribed ? "Location already Subscribed" :"Click to select location"}> */}
                                <Popover placement='topLeft' content={ !canEdit && isSubscribed ? "This location has been used for KYC approval or for a deal being initiated or completed" :"Click to select location"}>
                                  <span
                                    className="icon edit-icon"
                                    // onClick={() => isSubscribed ? null : setIsEditorOpen(true)}
                                    onClick={() => !canEdit && isSubscribed ? null : setIsEditorOpen(true)}
                                    >
                                    <MUIButton
                                      variant="text"
                                      type="icon"
                                      Icon={LocationOnIcon}
                                      // color={isSubscribed ? "lightgrey" : "red"}
                                      color={!canEdit && isSubscribed ? "lightgrey" : "red"}
                                      // onClick={() => isSubscribed ? null : setIsEditorOpen(true)}>
                                      onClick={() => !canEdit && isSubscribed ? null : setIsEditorOpen(true)}>
                                    </MUIButton>
                                  </span>
                                </Popover>
                              </div>
                            </div>
                          </Row>
                          <div className="mt-2">
                            <div className="form-group-field">
                              <div>
                                {/* <MUIButton
                                  variant="text"
                                  type="icon"
                                  Icon={LocationOnIcon}
                                  color="red"
                                  onClick={() => setIsEditorOpen(true)}></MUIButton> */}
                                {/* <MUITypography
                                  variant="button"
                                  className="cursor-pointer"
                                  onClick={() => setIsEditorOpen(true)}>
                                  Click to select location
                                </MUITypography> */}
                              </div>
                              {isEditorOpen && (
                            <Field
                              name="latitude"
                              // isSubscribed={isSubscribed}
                              isSubscribed={!canEdit && isSubscribed}
                              component={LocationModal}
                              entity="RoadTypeEnum"
                              isOpen={isEditorOpen}
                              toggle={followToggle}
                              cb={async (locationValues) => {
                                let locationDetails = locationValues || {};
                                let params = {
                                  Latitude: locationDetails?.lat,
                                  Longitude: locationDetails?.lng,
                                };
                                setLocationData(params);
                                setLatLongDataLoader(true);
                                const resData = await locationElocService.getReverseLocationDetails(params);
                                setLatLongDataLoader(false);

                                formikBag.setFieldValue('pin_code', _.get(resData?.results?.[0], 'pincode') || '');
                                formikBag.setFieldValue('city_code', _.get(resData?.results?.[0], 'city') || '');
                                let addressLine1 =
                                (_.get(resData?.results?.[0], 'houseNumber', '')
                                  ? _.get(resData?.results?.[0], 'houseNumber', '') + ', '
                                  : '') +
                                (_.get(resData?.results?.[0], 'houseName', '')
                                  ? _.get(resData?.results?.[0], 'houseName', '')
                                  : '');

                              let addressLine2 =
                                (_.get(resData?.results?.[0], 'street', '')
                                  ? _.get(resData?.results?.[0], 'street', '') + ', '
                                  : '') +
                                (_.get(resData?.results?.[0], 'sub_sub_locality', '')
                                  ? _.get(resData?.results?.[0], 'sub_sub_locality', '')
                                  : '');

                              let addressLine3 =
                                (_.get(resData?.results?.[0], 'sub_locality', '')
                                  ? _.get(resData?.results?.[0], 'sub_locality', '') + ', '
                                  : '') +
                                (_.get(resData?.results?.[0], 'locality', '')
                                  ? _.get(resData?.results?.[0], 'locality', '')
                                  : '');

                              formikBag.setFieldValue('address_line1', addressLine1);
                              formikBag.setFieldValue('address_line2', addressLine2);
                              formikBag.setFieldValue('address_line3', addressLine3);
                              formikBag.setFieldValue(
                                  'district_code',
                                  _.get(resData?.results?.[0], 'district') || '',
                                );
                                formikBag.setFieldValue(
                                  'village_code',
                                  _.get(resData?.results?.[0], 'village') ||
                                    _.get(resData?.results?.[0], 'city') ||
                                    _.get(resData?.results?.[0], 'subDistrict') ||
                                    '',
                                );
                                formikBag.setFieldValue('state_code', _.get(resData?.results?.[0], 'state') || '');
                                formikBag.setFieldValue('place_id', "");

                                formikBag.setFieldValue('address_lattitude', _.get(resData?.results?.[0], 'lat') || '');
                                formikBag.setFieldValue('address_longitude', _.get(resData?.results?.[0], 'lng') || '');
                                formikBag.setFieldValue('location_code', _.get(resData?.results?.[0], 'formatted_address') || '');

                                // formikBag.setFieldValue('address_lattitude', `${locationDetails?.lat}`);
                                // formikBag.setFieldValue('address_longitude', `${locationDetails?.lng}`);
                                // formikBag.setFieldValue('location_code', _.get(locationDetails, 'formatted_address', ''));
                              }}
                            />
                          )}
                              <div
                              // style={{cursor:"pointer"}}
                              // onClick={()=>setIsEditorOpen(true)}
                              >
                              <MUITypography onClick={()=>setIsEditorOpen(true)} variant="body2">{latLongDataLoader ? 'Please wait...' : values.location_code}</MUITypography>
                              </div>
                            </div>
                            <ErrorMessage name="latitude" component="div" className="text-danger" />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Address 1</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="address_line1" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Address 2</span>
                          <div className="form-group-field">
                            <Field name="address_line2" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Address 3</span>
                          <div className="form-group-field">
                            <Field name="address_line3" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Village / District</span>
                            <MUITextField
                              fullWidth
                              size="small"
                              disabled
                              variant={'outlined'}
                              value={`${values.village_code} / ${values.district_code}`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Pin Code</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="pin_code" component={FormikMaterialTextField} disabled={isSubscribed ? !locationId == 0 : false} />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">State</span>
                            <MUITextField
                              fullWidth
                              size="small"
                              disabled
                              variant={'outlined'}
                              value={locDetailsValue.state || `${values.state_code}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      {locationId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment >
  );
};

export default LocationForm;

