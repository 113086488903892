/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as api from './api';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import utils from 'lib/utils';

const BASE_URL = '/BioFuelDeliveries/api';

const FetchAllTripDetails = async (payload) => {
  const params = {
    page_size: 100,
    offset: 0,
    delivery_date_type_enum: 3,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TripDetails/FetchAllTripDetails?${qs}`;

  const details = await fetch.fetchList(url);

  return details;
};

const FetchTripDetailsAgainstTransportDeliveryScheduledId = async (id) => {
  const params = {
    transport_delivery_schedule_id: id, //3212,
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TripDetails/FetchTripDetailsAgainstTransportDeliveryScheduledId?${qs}`;

  const details = await fetch.fetchOne(url);

  return details;
};

const SaveDeliverySchedAndQualitySpecsDetails = async (id) => {
  const params = {
    transport_delivery_schedule_id: id, //3212,4323
  };
  const qs = utils.getQs(params);
  const url = `${BASE_URL}/TripDetails/SaveDeliverySchedAndQualitySpecsDetails?${qs}`;

  const details = await fetch.fetchOne(url);

  return details;
};

const UpdateDeliveryPhasesAndQuantity = async (id) => {
  const params = {
    delivery_Id: 0,
    delivery_Phase_Enum: 1,
    qty_At_Load: 0,
    qty_At_Load_Uom_Id: 0,
    qty_At_Delivery: 0,
    qty_At_Delivery_Uom_Id: 0,
    qualitySpecs: [
      {
        commodity_Spec_Id: 0,
        observed_Value_At_Load: 0,
        observed_Value_At_Delivery: 0,
      },
    ],
  };

  const url = `${BASE_URL}/TripDetails/UpdateDeliveryPhasesAndQuantity`;

  const details = await fetch.create(url, params);

  return details;
};

export const updateStatusAgainstDeliveryId = async (params = {}) => {
  try {
    const res = await api.updateStatusAgainstDeliveryId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateStatusAgainstDeliveryIdCT = async (params = {}) => {
  try {
    const res = await api.updateStatusAgainstDeliveryIdCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export default {
  FetchAllTripDetails,
  FetchTripDetailsAgainstTransportDeliveryScheduledId,
  SaveDeliverySchedAndQualitySpecsDetails,
  UpdateDeliveryPhasesAndQuantity,
  updateStatusAgainstDeliveryId,
};
