import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchPassBook = async (filters, params) => {
  try {
    let res = await api.fetchPassBook(filters, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePassbook = async (params) => {
  try {
    let res = await api.updatePassbook(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const transferPassbookAmount = async (params) => {
  try {
    let res = await api.transferPassbookAmount(params);
    return res;
  } catch (err) {
    throw err;
  }
};
