import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const BusinessIdentitySchema = yup.object().shape({
  business_pan_id: yup.string().when('business_type_enum', {
    is: 1,
    then: yup.string().optional(),
    otherwise: yup.string().required(),
  }),

  pan_of_authorized_signatory: yup.string().when('entity_type_enum.title', {
    is: 'PAN',
    then: yup.string().required('PAN of Authorized signatory is required'),
    otherwise: yup.string().optional(),
  }),

  company_id_of_authorized_signatory: yup.string().when('entity_type_enum.title', {
    is: 'Company ID Card',
    then: yup.string().required('Company ID Card of Authorized signatory is required'),
    otherwise: yup.string().optional(),
  }),

  name_as_per_pan: yup.string().when('business_type_enum', {
    is: 1,
    then: yup.string().optional(),
    otherwise: yup.string().required(),
  }),

  cin: yup
    .string()
    .when('business_type_enum', {
      is: 2,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    })
    .when('business_type_enum', {
      is: 4,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),

  llpin: yup.string().when('business_type_enum', {
    is: 5,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),

  // pan_of_authorized_signatory: yup.string().required(),
  name_of_authorized_signatory: yup.string().required(),
  business_type_enum: yup.number().required(),

  pin_code: yup.string().when('same_as_registered_address_flag', {
    is: false,
    then: yup.string().required(),
  }),
  company_address_id: yup.number().required('Company Location is required').positive('Company Location is required'),

  //tax
  gstin: yup.string().when('have_gst_flag', {
    is: true,
    then: yup.string().required(),
  }),
  license_number: yup.string().when('userRole', {
    is: 2,
    then: yup.string().required('License Number is a required field'),
    otherwise: yup.string().optional(),
  }),

  u_aadhar: yup
    .string()
    .when('msme_classification_enum', {
      is: 2,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 3,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 4,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    }),
});

export const BusinessIdentitySchemaSHG = yup.object().shape({
  nrlm_registration_number: yup.string().required('NRLM/SRLM Registration Number is required'),
  cin: yup
    .string()
    .when('business_type_enum', {
      is: 2,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    })
    .when('business_type_enum', {
      is: 4,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),

  llpin: yup.string().when('business_type_enum', {
    is: 5,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),

  // pan_of_authorized_signatory: yup.string().required(),
  name_of_authorized_signatory: yup.string().required(),
  business_type_enum: yup.number().required(),

  pin_code: yup.string().when('same_as_registered_address_flag', {
    is: false,
    then: yup.string().required(),
  }),
  company_address_id: yup.number().required('Company Location is required').positive('Company Location is required'),

  //tax
  gstin: yup.string().when('have_gst_flag', {
    is: true,
    then: yup.string().required(),
  }),
  license_number: yup.string().when('userRole', {
    is: 2,
    then: yup.string().required('License Number is a required field'),
    otherwise: yup.string().optional(),
  }),

  u_aadhar: yup
    .string()
    .when('msme_classification_enum', {
      is: 2,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 3,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 4,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    }),
});

export const BusinessIdentitySchemaDL = yup.object().shape({
  business_pan_id: yup.string().when('business_type_enum', {
    is: 1,
    then: yup.string().optional(),
    otherwise: yup.string().required(),
  }),

  dl_of_authorized_signatory: yup.string().required('Driving license required'),

  name_as_per_pan: yup.string().when('business_type_enum', {
    is: 1,
    then: yup.string().optional(),
    otherwise: yup.string().required(),
  }),

  cin: yup
    .string()
    .when('business_type_enum', {
      is: 2,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    })
    .when('business_type_enum', {
      is: 4,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),

  llpin: yup.string().when('business_type_enum', {
    is: 5,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),

  // pan_of_authorized_signatory: yup.string().required(),
  name_of_authorized_signatory: yup.string().required(),
  business_type_enum: yup.number().required(),

  pin_code: yup.string().when('same_as_registered_address_flag', {
    is: false,
    then: yup.string().required(),
  }),
  company_address_id: yup.number().required('Company Location is required').positive('Company Location is required'),

  //tax
  gstin: yup.string().when('have_gst_flag', {
    is: true,
    then: yup.string().required(),
  }),
  license_number: yup.string().when('userRole', {
    is: 2,
    then: yup.string().required('License Number is a required field'),
    otherwise: yup.string().optional(),
  }),
  dob: yup.string().required('Date Of Birth is required'),
});

export const eKycBusinessPanValiadtion = yup.object().shape({
  business_pan_id: yup.string().required('PAN Number is required'),
  name_as_per_pan: yup.string().required('Name on PAN is required'),
});

export const eKycBusinessPanAuthorizeValiadtion = yup.object().shape({
  pan_of_authorized_signatory: yup.string().required('PAN Number is required'),
  name_of_authorized_signatory: yup.string().required('Name on PAN is required'),
});
export const eKycBusinessDLAuthorizeValiadtion = yup.object().shape({
  dl_of_authorized_signatory: yup.string().required('PAN Number is required'),
  name_of_authorized_signatory: yup.string().required('Name on PAN is required'),
  dob: yup.string().required('Date Of Birth is required'),
});
export const eKycGSTValiadtion = yup.object().shape({
  gstin: yup.string().required('GST Number is required'),
});

export const kycSubmittedSchema = yup.object().shape({
  udyam_msmeRegistrationCertificate: yup
    .string()
    .when('msme_classification_enum', {
      is: 2,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 3,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    })
    .when('msme_classification_enum', {
      is: 4,
      then: yup.string().required('Udyam/Udyog Aadhaar is required.'),
    }),
  // udyam_msmeRegistrationCertificate: yup.string().required('Udyam / MSME Registration Certificate is required')
});

export const fetchList = async (params = {}) => {
  try {
    await helpers.validateSchema(kycSubmittedSchema, params);
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCompanyAndAffiliatesWithSocialProfile = async (params = {}) => {
  try {
    let res = await api.fetchCompanyAndAffiliatesWithSocialProfile(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    if (params.isSHG) {
      await helpers.validateSchema(BusinessIdentitySchemaSHG, params);
    } else {
      if (params.entity_type_enum?.type === 'Driving License') {
        await helpers.validateSchema(BusinessIdentitySchemaDL, params);
      } else {
        await helpers.validateSchema(BusinessIdentitySchema, params);
      }
    }
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    if (params.isSHG) {
      await helpers.validateSchema(BusinessIdentitySchemaSHG, params);
    } else {
      if (params.entity_type_enum?.type === 'Driving License') {
        await helpers.validateSchema(BusinessIdentitySchemaDL, params);
      } else {
        await helpers.validateSchema(BusinessIdentitySchema, params);
      }
    }
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

//Tax
export const fetchTaxDetails = async (id) => {
  try {
    let res = await api.fetchTaxDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTax = async (params) => {
  try {
    if (params.isSHG) {
      await helpers.validateSchema(BusinessIdentitySchemaSHG, params);
    } else {
      if (params.entity_type_enum?.type === 'Driving License') {
        await helpers.validateSchema(BusinessIdentitySchemaDL, params);
      } else {
        await helpers.validateSchema(BusinessIdentitySchema, params);
      }
    }
    const res = await api.createTax(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTax = async (id, params = {}) => {
  try {
    if (params.isSHG) {
      await helpers.validateSchema(BusinessIdentitySchemaSHG, params);
    } else {
      if (params.entity_type_enum?.type === 'Driving License') {
        await helpers.validateSchema(BusinessIdentitySchemaDL, params);
      } else {
        await helpers.validateSchema(BusinessIdentitySchema, params);
      }
    }
    const res = await api.updateTax(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

//Bank
export const fetchBankDetails = async (id) => {
  try {
    let res = await api.fetchBankDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createBank = async (params, saveBankSchema) => {
  try {
    await helpers.validateSchema(saveBankSchema, params['bank_account_details_request_model']?.[0]);
    const res = await api.createBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateBank = async (id, params = {}, saveBankSchema) => {
  try {
    await helpers.validateSchema(saveBankSchema, params['bank_account_details_request_model']?.[0]);
    const res = await api.updateBank(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const eKycBusinessPanVerification = async (params) => {
  try {
    if (params['eKyc'] == 'panOfAuthorizedSignatory') {
      await helpers.validateSchema(eKycBusinessPanAuthorizeValiadtion, params);
    } else if (params['eKyc'] == 'DLOfAuthorizedSignatory') {
      await helpers.validateSchema(eKycBusinessDLAuthorizeValiadtion, params);
    } else if (params['eKyc'] == 'businessPanId') {
      await helpers.validateSchema(eKycBusinessPanValiadtion, params);
    } else if (params['eKyc'] == 'gst') {
      await helpers.validateSchema(eKycGSTValiadtion, params);
    }
    const res = await api.eKycDocumentVerification(params);
    return res;
  } catch (err) {
    throw err;
  }
};
