import _ from 'lodash';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchDealAllotments(params = {}) {
    let qsParams;
    const filterLength = Object.keys(params.filters).length;
    if (filterLength === 0) {
      qsParams = {
        filters: {
          ...(params.filters || {}),
        },
        offset: params.offset || 0,
        page_size: params.pageSize || params.page_size || 10,
        invoking_person_id: params.personId ? params.personId : _.get(window, 'user.userid') || 0,
        commodity_deal_master_id: params.commodity_deal_master_id || 0,
      };
    } else {
      qsParams = {
        filters: {
          ...(params.filters || {}),
        },
        offset: 0,
        page_size: params.pageSize || params.page_size || 10,
        invoking_person_id: _.get(window, 'user.userid') || 0,
        commodity_deal_master_id: params.commodity_deal_master_id || 0,
      };
    }

    return qsParams;
  },

  fetchListByPersonId(params = {}) {
    let qsParams;
    qsParams = {
      ...params,
      invoking_person_id: _.get(window, 'user.userid') || 0,
    };
    return qsParams;
  },

  fetchUnconfirmedDelieveries(params = {}) {
    let qsParams;
    qsParams = {
      ...params,
      // invoking_person_id: _.get(window, 'user.userid') || 0,
    };
    return qsParams;
  },
  fetchList(params = {}) {
    const qsParams = {
      // ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      commodity_deal_master_id: params['id'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 0,
      offset: params.offset || 0,
      page_size: 100,
    };

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  update(id, params) {
    let pickTime = dayjs(params['delivery_allotments'][0]['pickup_time']).format('YYYY-MM-DDTHH:mm:ss');
    params['delivery_allotments'][0]['pickup_time'] = pickTime;
    let bodyParams = {
      ...params,
      operation_Enum: 3,
      // params['delivery_allotments'][0]['pickup_time']:
    };
    return bodyParams;
  },
  updateUnusedDeliveryStatus(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchNotConfirmedList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchNotConfirmedListDetails(details);
    });
    return _.keyBy(deliveriesData, 'transport_delivery_schedule_id');
  },
  fetchNotConfirmedListDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_delivery_schedule_id') || 0,
      'Early Payment Discount': _.get(resData, 'early_payment_discount'),
      'Early Payment Status': _.get(resData, 'is_early_payment_applicable')
        ? 'Yes'
        : _.get(resData, 'is_early_payment_applicable') === false
        ? 'No'
        : 'No',
    };
    return data;
  },

  fetchList(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(deliveriesData, 'transport_delivery_schedule_alloted_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
      'Early Payment Discount': _.get(resData, 'early_payment_discount'),
      'Early Payment Status': _.get(resData, 'is_early_payment_applicable')
        ? 'Yes'
        : _.get(resData, 'is_early_payment_applicable') === false
        ? 'No'
        : 'No',
    };
    return data;
  },
  fetchListByPersonId(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchListByPersonIdDetails(details);
    });
    return _.keyBy(deliveriesData, 'id');
  },
  fetchListByPersonIdDetails(resData) {
    let data = {
      id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
      'Delivery No.': _.get(resData, 'transport_delivery_schedule_alloted_id') || '',
      'Deal No': _.get(resData, 'commodity_deal_master_id') || '',
      'Pick Up Date': _.get(resData, 'pickup_datetime')
        ? dayjs(_.get(resData, 'pickup_datetime')).format('YYYY-MM-DD')
        : '',
      'Load Location': _.get(resData, 'pickup_location') || '',
      'Delivery Location': _.get(resData, 'delivery_location') || '',
      Consignor: _.get(resData, 'consignor') || '',
      Consignee: _.get(resData, 'consignee') || '',
      Transporter: _.get(resData, 'transport_company_code') || '',
      'Transport Contract No': _.get(resData, 'transport_deal_master_id') || '',
      'Vehicle Regn No': _.get(resData, 'vehicle') || '',
      'Vehicle Size': _.get(resData, 'vehicle_type_code') || '',
      Driver: _.get(resData, 'driver') || '',
      'Gross Wt Loading': _.get(resData, 'gross_weight_at_load') || '',
      'Tare Wt Loading': _.get(resData, 'tare_weight_at_load') || '',
      'Net Wt Loading': _.get(resData, 'net_quantity_at_loading') || '',
      'Gross Wt Delivery': _.get(resData, 'gross_weight_at_delivery') || '',
      'Tare Wt Delivery': _.get(resData, 'tare_weight_at_delivery') || '',
      'Net Wt Delivery': _.get(resData, 'net_quantity_at_delivery') || '',
      Status: _.get(resData, 'delivery_allotment_status_enum_code') || '',
      'Scheduled Qty': _.get(resData, 'scheduled_qty') || '',
      'Loading Acknowledgment Date': _.get(resData, 'loading_acknowledgement_datetime')
        ? dayjs(_.get(resData, 'loading_acknowledgement_datetime')).format('YYYY-MM-DD HH:mm:ss')
        : '',

      'Delivery Acknowledgment Date': _.get(resData, 'delivery_acknowledgement_datetime')
        ? dayjs(_.get(resData, 'delivery_acknowledgement_datetime')).format('YYYY-MM-DD HH:mm:ss')
        : '',

      'Own Transport': _.get(resData, 'is_own_transport') ? 'Yes' : 'No',
      kms: _.get(resData, 'estimated_km') || '',
      'Linked Deal Id': _.get(resData, 'linked_deal_id') || 0,
      'Linked Delivery Id': _.get(resData, 'linked_delivery_id') || 0,
      'Early Payment Discount': _.get(resData, 'early_payment_discount'),
      'Early Payment Status': _.get(resData, 'is_early_payment_applicable')
        ? 'Yes'
        : _.get(resData, 'is_early_payment_applicable') === false
        ? 'No'
        : 'No',
      'Is Verified Supplier Services': _.get(resData, 'is_verified_supplier_services'),
    };
    return data;
  },
  update(resData) {
    return resData;
  },
  updateUnusedDeliveryStatus(resData) {
    return resData;
  },
};
