import * as Yup from 'yup';
import styles from '../components/invoiceApprovalPopup.module.css';

import notifications from 'lib/notifications';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Form, Field, Formik } from 'formik';
import utils from 'lib/utils';
import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelectOkayToPay';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import { Tag, Tooltip } from 'antd';
import { SaveUpdateInvoiceVerificationStatus_Bulk } from "services/dealInvoices";
import MUITextField from 'common/MUIComponents/TextField';
import AmountField from 'common/TableComponents/amountField';

function BulkInvoiceApprovalPopup(props) {
  const history = useHistory();
  const [totalAmount, setTotalAmount] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const { toggle, color, setInvoiceDetails, invoiceDetails, dispatch, page, bulkInvoiceDetails, isBulkWorkflowApproval, selectedBulkInvoiceIds, setIsReRenderRequired, setEmptyData } = props;

  const initialValues = {
    invoice_Master_Ids: selectedBulkInvoiceIds,
    invoice_Verification_Enum: '',
    remarks: '',
  };

  useEffect(() => {
    if (setShowMore) {
      let totalAmount = 0;
      bulkInvoiceDetails.map((invoice) => {
        totalAmount += invoice.amount_due;
      });
      setTotalAmount(totalAmount);
    }
  }, [showMore])

  const handleSubmit = async (values, formikBag) => {
    try {

      const resSubmit = await SaveUpdateInvoiceVerificationStatus_Bulk({
        invoice_Master_Ids: selectedBulkInvoiceIds,
        invoice_Verification_Enum: values.invoice_Verification_Enum,
        remarks: values.remarks,
      });

      if (resSubmit) {
        toggle();
        setIsReRenderRequired(true);
        setEmptyData();

        notifications.show({
          type: 'success',
          message: resSubmit,
          key: 'bids-form',
          duration: null,
        });
      }
    } catch (err) {
      console.error(err);

      let errors = _.get(err, 'errors') || {};
      let errMessage = _.get(err, 'message') || 'Unable to process request.';

      if (!_.isEmpty(errors)) {
        formikBag.setErrors({
          ...errors,
        });

        errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
      }

      notifications.show({
        type: 'error',
        message: errMessage,
        key: 'bids-form',
        duration: null,
      });
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          return (
            <Form className="formik-form">
              <div className="modal__body counter-modal__body" style={{ height: 'auto', paddingBottom: '10px' }}>
                <div className="container-fluid">
                  <div className="row mt-10">
                    <div className="col-md-12">
                      <React.Fragment>
                        <div className="">
                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">Workflow Status</span>
                              <div className="form-group-field">
                                <Field
                                  name="invoice_Verification_Enum"
                                  component={ReferenceDataSelect}
                                  entity="InvoiceVerificationEnum"
                                  is_workflow={true}
                                  disabled={(bulkInvoiceDetails[0].invoice_status_enum == 10 || bulkInvoiceDetails[0].invoice_status_enum == 4) ? true : false}
                                  initialDisplayText={''}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row w-auto mb-15">
                            <div className="col">
                              <span className="form-group-label d-flex">Remarks</span>
                              <div className="form-group-field">
                                <MUITextField
                                  fieldType='textarea'
                                  value={''}
                                  onChange={(newValue) => {
                                    setFieldValue('remarks', newValue)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>


                <div className={styles.invoice_hyperlinks_total}>

                  <div className='pl-2 pt-2'>
                    Total Amount
                    <p
                      onClick={() => {
                        setShowMore(!showMore);
                      }}
                      className={styles.invo_hyper}
                      style={{ color: 'blue' }}>
                      {showMore ? 'Show Less...' : 'Show More...'}
                    </p>
                    {showMore && (
                      <div className="d-flex align-items-center justify-content-center">
                        <AmountField expected_price={totalAmount} type={'invoice'} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal__footer" style={{ marginTop: '10px', marginBottonm: '10px', display: 'block' }}>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    size="sm"
                    class="btn btn-primary btn-sm"
                    style={{ marginRight: '10px' }}
                    onClick={() => toggle()}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    size="sm"
                    onClick={formikBag.onSubmit}
                    disabled={formikBag.isSubmitting}>
                    Submit
                  </Button>
                </div>
                <br />

                <hr className="m-0" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

export default BulkInvoiceApprovalPopup;

