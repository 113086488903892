import _ from 'lodash';
import utils, { decryption } from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

import * as fetch from 'services/fetch';

export type BalanceQuantityType = {
  table_id: number;
  requirement_qty: number;
  purchased_qty: number | null;
  balance_qty: number | null;
  period_name: string;
};

const { baseUrl } = config;
const BASE_URL = '/BioFuelAuctions/api';

export const fetchChatMessages = async (params = {}) => {
  try {
    const qs = reqTransform.fetchChatMessages(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/FetchAuctionChatAgainstAuctionMasterId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchChatMessages(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAuctionChatAgainstAuctionMasterId');
    throw errors;
  }
};

export const deleteChatMessage = async (id) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/DeleteAuctionChatMessage?notification_detail_id=${id}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchChatMessages(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteAuctionChatMessage');
    throw errors;
  }
};

export const fetchAllAuction = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllAuction(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/FetchAllAuctions_Eloc`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllAuction(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllAuctions_Eloc');
    throw errors;
  }
};

export const fetchOwnAuction = async (params) => {
  try {
    const bodyParams = reqTransform.fetchOwnAuction(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/FetchOwnAuctions_Eloc`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchOwnAuction(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchOwnAuctions_Eloc');
    throw errors;
  }
};

export const fetchOwnBids = async (params) => {
  try {
    const bodyParams = reqTransform.fetchOwnBids(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/FetchOwnBidsOrOffers`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchOwnBids(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchOwnBidsOrOffers');
    throw errors;
  }
};

export const create = async (type, params) => {
  try {
    let bodyParams = reqTransform.create(type, params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/SaveAndUpdateBuySellAuction_ELOC_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveRequirementNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBuySellAuction_ELOC_PhaseTwo');
    throw errors;
  }
};

export const save = async (type, params) => {
  try {
    let bodyParams = reqTransform.save(type, params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/SaveAndUpdateBuySellAuction_ELOC_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveRequirementNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBuySellAuction_ELOC_PhaseTwo');
    throw errors;
  }
};

export const update = async (type, params) => {
  try {
    let bodyParams = reqTransform.update(type, params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/SaveAndUpdateBuySellAuction_ELOC_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveRequirementNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBuySellAuction_ELOC_PhaseTwo');
    throw errors;
  }
};

export const fetchAuctionDetails = async (params) => {
  try {
    const qs = reqTransform.fetchAuctionDetails(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/FetchAuctionDetailsAgainstAuctionMasterId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAuctionDetails(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAuctionDetailsAgainstAuctionMasterId_ELOC');
    throw errors;
  }
};

export const enterAuction = async (params, params1) => {
  try {
    const qs = reqTransform.enterAuction(params, params1);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/EnterAuction?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.enterAuction(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EnterAuction');
    throw errors;
  }
};

export const enterMultipartyAuction = async (params) => {
  try {
    const qs = reqTransform.enterMultipartyAuction(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/EnterMultipartyAuction`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.enterAuction(resData);
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EnterAuction');
    throw errors;
  }
};

export const addToGroup = async (params) => {
  try {
    const qs = reqTransform.addToGroup(params);
    const url = `${baseUrl}/BioFuelSignalR/api/Negotiation/AddToGroup?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.addToGroup(resData);
  } catch (err) {
    console.log('api-auctions-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'AddToGroup');
    throw errors;
  }
};

export const sendMessage = async (params) => {
  try {
    const qs = reqTransform.sendMessage(params);
    const url = `${baseUrl}/BioFuelSignalR/api/Negotiation/SendChatMessage?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    // const resData = await res.json();
    return resData;
  } catch (err) {
    console.log('api-auctions-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SendChatMessage');
    throw errors;
  }
};

export const sendMessageFromBody = async (params) => {
  try {
    const qs = reqTransform.sendMessage(params);
    const url = `${baseUrl}/BioFuelSignalR/api/Negotiation/SendChatMessage_FromBody`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    // const resData = await res.json();
    return resData;
  } catch (err) {
    console.log('api-auctions-sendMessage->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SendChatMessage');
    throw errors;
  }
};

export const offerBidAuction = async (params, tsCost, type) => {
  try {
    let bodyParams = reqTransform.offerBidAuction(params, tsCost, type);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/SaveAndUpdateBidOfferAgainstAuctionMasterId_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.offerBidAuction(resData);
  } catch (err) {
    console.log('offerBidAuction->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBidOfferAgainstAuctionMasterId_PhaseTwo');
    throw errors;
  }
};

export const fetchLandingPagePositions = async (params) => {
  const qs = reqTransform.fetchLandingPagePositions(params);
  const url = `${BASE_URL}/Deal/FetchLandingPagePositions?${qs}`;

  const bqDetails = await fetch.fetchList<BalanceQuantityType>(url);
  let res = {
    ...bqDetails,
    id: params['commodity_auction_master_id'],
  };
  console.log('bqDetails=============>', bqDetails, params);
  return res;
};

export const fetchAuctionOfferBidDetails = async (params) => {
  try {
    let qs = reqTransform.fetchAuctionOfferBidDetails(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/FetchOfferBidDetailsAgainstCommodityAuctionResponseDetailId_Eloc?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAuctionOfferBidDetails(resData);
  } catch (err) {
    console.log('FetchOfferBidDetailsAgainstCommodityAuctionResponseDetailId->err---->', err);
    const errors = await utils.processApiErrors(
      err,
      '',
      'FetchOfferBidDetailsAgainstCommodityAuctionResponseDetailId_Eloc',
    );
    throw errors;
  }
};

export const rejectBidOffer = async (params) => {
  try {
    let qs = reqTransform.rejectBidOffer(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/RejectBidOfferAgainstCommodityAuctionResponseDetailId?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.rejectBidOffer(resData);
  } catch (err) {
    console.log('RejectBidOfferAgainstCommodityAuctionResponseDetailId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RejectBidOfferAgainstCommodityAuctionResponseDetailId');
    throw errors;
  }
};

export const acceptBidOffer = async (params) => {
  try {
    let qs = reqTransform.acceptBidOffer(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Deal/AcceptBidOrOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.acceptBidOffer(resData);
  } catch (err) {
    console.log('AcceptBidOrOffer->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'AcceptBidOrOffer');
    throw errors;
  }
};

export const deleteBidOffer = async (params) => {
  try {
    let qs = reqTransform.deleteBidOffer(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/WithdrawBidOfferAgainstCommodityAuctionResponseDetailId?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteBidOffer(resData);
  } catch (err) {
    console.log('DeleteBidOrOffer->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'WithdrawBidOfferAgainstCommodityAuctionResponseDetailId');
    throw errors;
  }
};

export const fetchTransportationCost = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTransportationCost(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/FetchTransportationCost_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransportationCost(resData);
  } catch (err) {
    console.log('api-FetchTransportationCost->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTransportationCost_ELOC');
    throw errors;
  }
};
export const fetchLiveroomDeals = async (params) => {
  try {
    let bodyParams = reqTransform.liveroomDeals(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Deal/FetchDealsAgainstAuctionMasterId_Eloc`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.liveroomDeals(resData);
  } catch (err) {
    console.log('fetchliveRoomDeals->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDealsAgainstAuctionMasterId_Eloc');
    throw errors;
  }
};

export const withdrawAuction = async (params) => {
  try {
    let qs = reqTransform.withdrawAuction(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/WithdrawAuctionById_ELOC?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.withdrawAuction(resData);
  } catch (err) {
    console.log('RejectBidOfferAgainstCommodityAuctionResponseDetailId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'WithdrawAuctionById_ELOC');
    throw errors;
  }
};

export const fetchUnloadingCharges = async (params = {}) => {
  try {
    const qs = reqTransform.fetchCharges(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/FetchUnloadingCharges?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCharges(resData);
  } catch (err) {
    console.log('api-FetchUnloadingCharges-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchUnloadingCharges');
    throw errors;
  }
};

export const fetchLoadingCharges = async (params = {}) => {
  try {
    const qs = reqTransform.fetchCharges(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Bid/FetchLoadingCharges?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCharges(resData);
  } catch (err) {
    console.log('api-FetchLoadingCharges-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchLoadingCharges');
    throw errors;
  }
};

export const fetchChatDetails = async (userId, auctionId) => {
  try {
    const params = {
      User_Id: userId,
      Auction_Master_Id: auctionId,
      negotiateVersion: 1,
    };
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelSignalR/api/Negotiation/negotiate?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData;
    if (config.isApiEncryptionEnabled == 'true') {
      const textRes = await res.text();
      const decryptedRes = decryption(textRes);
      resData = await JSON.parse(decryptedRes);
      return { url: resData?.URL, accessToken: resData?.AccessToken };
    } else {
      resData = await res.json();
      return resData;
    }
  } catch (err) {
    console.log('api-auctions-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchChatDetails');
    throw errors;
  }
};

export const updateTargetPrice = async (params) => {
  try {
    let qsParams = reqTransform.updateTargetPrice(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Auctions/UpdateTargetPrice?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateTargetPrice(resData);
  } catch (err) {
    console.log('updateTargetPrice->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateTargetPrice');
    throw errors;
  }
};
