import _ from 'lodash';
import utils from 'lib/utils';
import { useSelector, useDispatch } from 'react-redux';
import config from 'config';

let entity_id, documentModuleEnum, companyAffiliateEnum;

export const reqTransform = {
  fetchList(params = {}) {
    const storeState = globalThis.store.getState();
    const userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum');

    if (userTypeEnum == 2) {
      (entity_id = _.get(window, 'user.profile.person.person_id')), (documentModuleEnum = 1);
    } else {
      let company_id_param = params['companyId'];
      let company_affiliate =
        params['company_affiliate'] || storeState.kycBusinessCompany.entities[company_id_param].company_affiliate_enum;

      if (company_affiliate == 1) {
        (entity_id = params['companyId']), (companyAffiliateEnum = 1), (documentModuleEnum = 2);
      } else {
        (entity_id = params['companyId']), (companyAffiliateEnum = 2), (documentModuleEnum = 3);
      }
    }

    const qsParams = {
      // ...params,
      entity_primary_id: entity_id,
      document_module_enum: documentModuleEnum,
      // entity_type_enum: params,
    };
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let bodyParams = {
      ...params,
      person_kyc_id: 0,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      state_id: parseInt(params['state_id']),
    };
    return bodyParams;
  },

  upload(params, entity_id, document_module_enum) {
    const storeState = globalThis.store.getState();

    const userTypeEnum = _.get(storeState, 'user.profile.person.user_type_enum');

    let bodyParams = {
      ...params,
      entity_id: entity_id,
      user_type_enum: 1,
      company_affiliate_enum: 1,
      document_module_enum: document_module_enum,
      entity_type_enum: 12,
    };
    return bodyParams;
  },

  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(uploadData, 'attachment_id');
  },
  fetchDetails(resData) {
    return resData;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
