import _ from 'lodash';
import * as yup from 'yup';

import * as helpers from 'services/helpers';
import * as api from './api';

export const CargoSchema = yup.object().shape({
  tare_weight: yup
    .number()
    .typeError('Tare Weight is required')
    .max(20000, 'Tare Weight seems too large (over 20 MT/ 20,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Tare Weight invalid')
    .required('Tare Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
  gross_weight: yup
    .number()
    .typeError('Gross Weight is required')
    .max(60000, 'Gross Weight seems too large (over 60 MT/ 60,000 KG). Check Qtys in MT vs KG')
    .min(1, 'Gross Weight invalid')
    .moreThan(yup.ref('tare_weight'), 'Gross weight must be greater than Tare weight')
    .required('Gross Weight is required')
    .test('is-decimal', 'Invalid', (value) => (value + '').match(/^\d+$/)),
  // vehicle_size: yup.number(),
  // netWeight: yup
  //   .number()
  //   .nullable()
  //   .test('netWeight', 'Vehicle is overloaded', function (item) {
  //     let netWeightCal = (parseFloat(this.parent.gross_weight) || 0) - (parseFloat(this.parent.tare_weight) || 0);
  //     let overloadLimit = this.parent.vehicle_size * (1 + 0.1 * this.parent.vehicle_size);
  //     let result = false;
  //     if (netWeightCal < overloadLimit) {
  //       result = true;
  //     }
  //     return result;
  //   }),
});

export const loadingVolumeCargoSchema = yup.object().shape({
  invoice_qty: yup.number().typeError('Invoice Quantity is required'),
});

export const deliveryVolumeCargoSchema = yup.object().shape({
  receipt_qty: yup.number().typeError('Receipt Quantity is required'),
});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  throw new Error('No api to create delivery');
};

export const update = async (id, params = {}) => {
  // try {
  //   await helpers.validateSchema(FarmSchema, params);
  //   const res = await api.update(id, params);
  //   return res;
  // } catch (err) {
  //   throw err;
  // }
  throw new Error('No api to update delivery');
};

export const updateCargoDetails = async (deliveryId, params = {}) => {
  try {
    if (params['uom_type_enum'] !== 2) {
      await helpers.validateSchema(CargoSchema, params);
    } else {
      await helpers.validateSchema(
        params['isLoadingEditClicked'] ? loadingVolumeCargoSchema : deliveryVolumeCargoSchema,
        params,
      );
    }
    const res = await api.updateCargoDetails(deliveryId, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateCargoDetailsCT = async (deliveryId, params = {}) => {
  try {
    if (params['uom_type_enum'] !== 2) {
      await helpers.validateSchema(CargoSchema, params);
    } else {
      await helpers.validateSchema(
        params['isLoadingEditClicked'] ? loadingVolumeCargoSchema : deliveryVolumeCargoSchema,
        params,
      );
    }
    // await helpers.validateSchema(CargoSchema, params);
    const res = await api.updateCargoDetailsCT(deliveryId, params);
    return res;
  } catch (err) {
    throw err;
  }
};

///api/DeliveryAssignment/UpdateQsAgainstDeliveryQualitySpecId
export const updateQS = async (params) => {
  try {
    const res = await api.updateQS(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateQSCT = async (params) => {
  try {
    const res = await api.updateQSCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

///api/DeliveryAssignment/UpdateQsAgainstDeliveryId
export const updateQualitySpecs = async (deliveryId, params = {}) => {
  try {
    const res = await api.updateQualitySpecs(deliveryId, params);
    return res;
  } catch (err) {
    throw err;
  }
};

///api/DeliveryAssignment/UpdateQsAgainstDeliveryId
export const updateTripStatus = async (deliveryId, params = {}) => {
  try {
    const res = await api.updateTripStatus(deliveryId, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTripStatusCT = async (deliveryId, params = {}) => {
  try {
    const res = await api.updateTripStatusCT(deliveryId, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  // try {
  //   let res = await api.destroy(id);
  //   return res;
  // } catch (err) {
  //   throw err;
  // }
  throw new Error('No api to destroy delivery');
};

export const fetchTransportDeliveryDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCargoDetails = async (id) => {
  try {
    let res = await api.fetchCargoDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCargoDetailsCT = async (id) => {
  try {
    let res = await api.fetchCargoDetailsCT(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDeliveryPhasesDetails = async (id) => {
  try {
    let res = await api.fetchDeliveryPhases(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDeliveryPhasesDetailsCT = async (id) => {
  try {
    let res = await api.fetchDeliveryPhasesCT(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchQSDetails = async (id) => {
  try {
    let res = await api.fetchQualitySpecs(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchQSDetailsCT = async (id) => {
  try {
    let res = await api.fetchQualitySpecsCT(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateBuyerSellerRefOnDeal = async (params) => {
  try {
    let res = await api.UpdateBuyerSellerRefOnDeal(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateBuyerSellerRefOnDelivery = async (params) => {
  try {
    let res = await api.UpdateBuyerSellerRefOnDelivery(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteUploadedFileAgainstId = async (params) => {
  try {
    let res = await api.deleteUploadedFileAgainstId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createDelayQsInvoice = async (params) => {
  try {
    let res = await api.createDelayQsInvoice(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const enableDisableEarlyPaymentOnDelivery = async (params) => {
  try {
    let res = await api.enableDisableEarlyPaymentOnDelivery(params);
    return res;
  } catch (err) {
    throw err;
  }
};
