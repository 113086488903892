import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessAndAffiliateLocationsAgainstPersonId_ELOC_PhaseTwo?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchBusinessAndAffiliateLocationsAgainstPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessAndAffiliateLocationsAgainstPersonId_ELOC');
    throw errors;
  }
};

export const fetchTopBarLocationList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTopBarLocationList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessAndAffiliateLocationsAgainstPersonId_ELOC_PhaseTwo?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTopBarLocationList(resData);
  } catch (err) {
    console.log('fetchTopBarLocationList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchTopBarLocationList');
    throw errors;
  }
};

export const fetchBusinessAndAffiliateLocationsAgainstEntityId = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchBusinessAndAffiliateLocationsAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchBusinessAndAffiliateLocationsAgainstPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessAndAffiliateLocationsAgainstPersonId_ELOC');
    throw errors;
  }
};

export const fetchCompanyAndAffiliateLocations = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchCompanyAndAffiliateLocations`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    // return resTransform.fetchList(resData);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCompanyAndAffiliateLocations');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const qs = reqTransform.fetchDetails(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessLocationsAgainstCompanyAddressId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('FetchBusinessLocationsAgainstCompanyAddressId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessLocationsAgainstCompanyAddressId_ELOC');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    console.log('bodyParams in create location', bodyParams);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateBusinessAndAffiliateLocations_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('Save BusinessAndAffiliateLocations->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBusinessAndAffiliateLocations_ELOC');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateBusinessAndAffiliateLocations_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('Update BusinessAndAffiliateLocations->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBusinessAndAffiliateLocations_ELOC');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteLocationAgainstCompanyAddressId?company_address_id=${qs}`;
    // const res = await utils.fetch(url).ready;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('DeleteLocationAgainstCompanyAddressId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteLocationAgainstCompanyAddressId');
    throw errors;
  }
};

export const fetchTransportList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTransportList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchBusinessAndAffiliateLocationsAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransportList(resData);
  } catch (err) {
    console.log('FetchBusinessAndAffiliateLocationsAgainstPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessAndAffiliateLocationsAgainstEntityId');
    throw errors;
  }
};

export const getStorageLocations = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStorageLocationsAgainstPersonId_Eloc?${params}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchStorageLocationsAgainstPersonId_Eloc');
    throw errors;
  }
};

export const fetchFarmerList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchFarmerList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchBusinessAndAffiliateLocationsAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return params?.['person_company_affiliate_enum']
      ? resData?.map((item, i) => ({ ...item, id: i }))
      : resTransform.fetchFarmerList(resData);
  } catch (err) {
    console.log('FetchBusinessAndAffiliateLocationsAgainstPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessAndAffiliateLocationsAgainstEntityId');
    throw errors;
  }
};

export const fetchInvoiceLocationList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchInvoiceLocationList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/FetchBusinessAndAffiliateLocationsAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchInvoiceLocationList(resData);
  } catch (err) {
    console.log('FetchBusinessAndAffiliateLocationsAgainstPersonId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBusinessAndAffiliateLocationsAgainstEntityId');
    throw errors;
  }
};

export const getBankNames = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/BankCommon/GetBankNames`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetBankNames');
    throw errors;
  }
};

export const getBankDetailsAgainstName = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/BankCommon/GetBankDetailsAgainstName?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'getBankDetailsAgainstName');
    throw errors;
  }
};

export const verifyBankAccount = async (params = {}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/VerifyBankAccount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'VerifyBankAccount');
    throw errors;
  }
};

export const kycVerification = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/KYCVerification`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'KYCVerification');
    throw errors;
  }
};

export const getFacilitators = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/CommonUtilities/GetFacilitators`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetFacilitators');
    throw errors;
  }
};

export const FetchPersonalDetailsForFacilitator = async (person_id) => {
  try {
    const params = {
      person_id,
    };
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRegistration/api/Facilitator/FetchPersonalDetailsForFacilitator?${qs}`;
    const res = await utils.fetch(url).ready;

    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};
