import _ from 'lodash';
import React from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

const dataCache = {};

function ReferenceDataSelect(props) {
  const dispatch = useDispatch();

  const compareFormCode = _.get(props, 'qparams.commodity_form_code');

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    placeholder,
    ...rest
  } = props;

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }

  let entityData = useSelector((state) => {
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams).join('');
      return state.referenceData.data[entity][qId] || [];
    }

    const found = state.referenceData.data[entity];

    return Array.isArray(found) ? found : [];
  });

  const loading = open && entityData && entityData.length === 0;

  const value_member = _.get(values, field.name, 0);

  let selectedValue = entityData.find((e) => e.value_member === value_member);

  if (!selectedValue) {
    selectedValue = { value_member: value_member, display_member: value_member ? initialDisplayText : '' };
  }

  React.useEffect(() => {
    let active = true;

    const loadOptions = async () => {
      if (!entityData.length) {
        if (qparams.commodity_form_code !== '') {
          try {
            dispatch(refDataActions.getCommodityDropdownData(entity, qparams));

            dataCache[entity] = entityData;
            setOptions(entityData);
          } catch (err) {
            console.log('tries-->', tries);
            console.log('getCommodityDropdownData error', err);

            if (tries < 3) {
              setTries(tries + 1);
            }
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  // className={`${hasError ? 'has-error' : ''} bfc-ref-data-select-wrapper`}
  return (
    <div className={`bfc-ref-data-select-wrapper`}>
      <Autocomplete
        value={selectedValue}
        id={`${entity}-autocomplete-${timestamp}`}
        defaultValue={'initialDisplayText' || ''}
        disabled={!!disabled}
        disableClearable
        size="small"
        // autoHighlight
        // style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(o, value) => {
          if (o?.display_member && value?.display_member) {
            return o.display_member.trim().toLowerCase() === value.display_member.trim().toLowerCase();
          }

          if (o?.display_member && value?.display_member) {
            return o.display_member.trim().toLowerCase() === value.display_member.trim().toLowerCase();
          }

          return false;
        }}
        getOptionLabel={(o) => {
          if (o.display_member) {
            return o.display_member;
          }
          if (o.display_member) {
            return o.display_member;
          }

          return '';
        }}
        options={_.orderBy(entityData, ['display_member'])}
        loading={loading}
        onChange={(event, newValue) => {
          let entity_id = newValue?.value_member;
          let entity_code = newValue?.display_member;
          const d = entityData.find((c) => c.display_member === newValue.display_member);
          if (d && d.value_member) {
            entity_id = d.value_member;
            entity_code = d.display_member;
            // if(utils.isNumber(entity_id)){
            //   entity_id = parseInt(entity_id)
            // }
          }

          props.form.setFieldValue(field.name, entity_id);
          props.form.setFieldValue('commodity_code', entity_code);
          // api
          //   .getCommodityDropdownData({
          //     entity: 'commodityForm',
          //     qparams: {
          //       commodity_id: entity_id,
          //     },
          //   })
          //   .then((resData) => {

          //     let cmmodityFormId
          //     if(resData.length == 1){
          //      cmmodityFormId = _.get(resData, `[0].value_member`) || '';
          //     }
          //     else {
          //       for(let i=0 ; i< resData.length; i++)
          //       {
          //         if(compareFormCode == resData[i].display_member )
          //         {
          //           cmmodityFormId = resData[i].value_member ;
          //         }
          //       }

          //     }
          //     props.form.setFieldValue('commodity_form_id', cmmodityFormId);

          //   })
          //   .catch((err) => {
          //     console.log('onCommodityChange--err--->', err);
          //   });

          if (props.onCallback) {
            props.onCallback(entity_id);
          }
          if (props.cb) {
            props.cb(newValue);
          }

          if (dependentFields && dependentFields.length) {
            for (let df of dependentFields) {
              props.form.setFieldValue(df, 0);
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // {...field}
            className="material-form__field"
            label={label}
            error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
            children={children}
            helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
            FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
}

export default ReferenceDataSelect;
