import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as transportVehicleActions } from 'redux/transportVehicle';
import _ from 'lodash';
import api from 'lib/api';

export default function VehicleSelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialDisplayText,
    entity_id,
    company_affiliate_enum,
    disabled,
    transportPersonId,
    ...rest
  } = props;

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);
  const [searchValue, setSearchValue] = useState('');
  const [optionValue , setOptionValue] = useState('');
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state.transportVehicle);
  const vehicleList = _.get(vehicles, 'entities') || {};
  const busy = _.get(vehicles, 'busy') || false;

  // if(searchValue){
    _.each(vehicleList, (item, id) => {
      let name = item.vehicle_type_enum_code + ' (' + item.vehicle_type_code + ') ' + item.vehicle_registration_id;
      options.push({
        label: name || '',
        value: {
          id: parseInt(id),
          ...item,
        },
      });
    });
  // }

  // if (!options.length) {
  //   loading = true;
  // }

  useEffect(() => {
    // if (searchValue != '') {
      const params = {
        filters: {
          vehicle_registration_number: searchValue
        },
        page_size: 100,
        offset: 0,
        transporter_person_id: transportPersonId,
      };
      dispatch(transportVehicleActions.fetchList(params));
    // }
  }, [searchValue]);

  // useEffect(() => {
  //   if (!loading) {
  //     let initialAddressId = _.get(props, 'form.initialValues.delivery_allotments[0].vehicle_id') || 0;
  //     const initialSelected = options.filter((o) => initialAddressId === o.value?.id);
  //     setValue(initialSelected);
  //   }
  // }, [loading]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const handleChange = (e) => {
    const searchText = e.target.value;
    if (searchText.length >= 2) {
      setSearchValue(searchText);
    }
  }

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }} // filterOptions={(x) => x}
        // loading={true}
        options={options}
        noOptionsText={optionValue.length < 3 ? 'Enter minimum 3 characters' : 'No options'}
        // filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            onChange={(e) => {
              setOptionValue(e.target.value)
              handleChange(e)}
            }
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
