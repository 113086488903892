import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';

import { deliveriesAllotmentService } from 'services';
import { kycApprovalService } from 'services';
import { actions as transportVehicleActions } from 'redux/transportVehicle';

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating delivery Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await deliveriesAllotmentService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Delivery details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportVehicleActions.setDirty(true));
    // redirect.web('/network/deliveries');

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdateCT = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating delivery Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await deliveriesAllotmentService.updateCT(id, values);
    notifications.show({
      type: 'success',
      message: 'Delivery details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(transportVehicleActions.setDirty(true));
    // redirect.web('/network/deliveries');

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleMoveDateOfNotConfirmedSchedules = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating delivery Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_id') || 0;
    const res = await kycApprovalService.updateUnconfirmedDeliverySchedule(values);
    notifications.show({
      type: 'success',
      message: 'Delivery details updated.',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
