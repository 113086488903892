import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';

const FormikMaterialTextField = (props) => {
  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    setIsDiscountValue,
    handleApplyCoupon,
    formikBag,
    discountValueLength,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const handleChange = async (e) => {
    const searchText = e.target.value;
    setIsDiscountValue(searchText);
  };

  useEffect(() => {
    if (discountValueLength.length >= 7) {
      handleApplyCoupon(formikBag.values, formikBag);
    }
  }, [discountValueLength]);

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      <TextField
        fullWidth
        variant={variant || 'outlined'}
        size="small"
        disabled={disabled}
        error={hasError}
        helperText={errText}
        onWheel={(event) => {
          // event.preventDefault();
          event.target.blur();
        }}
        // onBlur={() => discountValueLength.length >7 && handleApplyCoupon(formikBag.values, formikBag)}
        onChange={(e) => handleChange(e)}
        {...field}
        style={{
          backgroundColor: '#F8F8F8',
          border: '1px dashed #B8B8B8',
          height: '38px',
          width: '100%',
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        }}
      />
    </div>
  );
};

export default FormikMaterialTextField;
