import React, { useState, useEffect } from 'react';
import { Link, Redirect, NavLink } from 'react-router-dom';
import { Nav, NavItem, TabContent, TabPane, Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import SignupForm from './components/SignupForm';
import OtpForm from './components/OtpForm';
import WelcomeCard from 'shared/components/WelcomeCard';
import Footer from '../../Layout/footer/Footer';
import notifications from 'lib/notifications';
import { useSelector } from 'react-redux';
import DetailsForm from './components/DetailsForm';
import YoutubeIcon from 'assets/images/youtube-png.png';
import TopBannerUnderMaintain from '../TopBannerUnderMaintain';
import TopBannerContryAlertMessage from '../TopBannerContryAlertMessage';
import useQuery from 'lib/hooks/useQuery';

const Signup = (props) => {
  const { isFarmer, isNewFarmer } = useQuery();
  // const isFarmerUser = Boolean(isFarmer || isNewFarmer)
  const isFarmerUser = false; // Added to disable quick farmer onboarding link
  const user = useSelector((state) => state.user);
  const misc = useSelector((state) => state.misc);
  const authStep = misc.authStep || '';

  const [showDisabled, setShowDisabled] = useState(false);

  const is_kyc_done = _.get(user, 'profile.person.is_kyc_done');

  const type_of_individual_enum = _.get(user, 'profile.person.type_of_individual_enum');

  const user_type_enum = _.get(user, 'profile.person.user_type_enum');

  const id = _.get(user, 'profile.person.person_id');


  let currLocation = window.location.pathname;
  let profilePage = currLocation.includes('account');
  let kycPage = currLocation.includes('kyc');

  const underMaintenance = false;

  // useEffect(() => {
  //   const tmr = setInterval(() => {
  //     window.location.reload(false);
  //   }, 5 * 60 * 1000);

  //   return () => {
  //     clearInterval(tmr);
  //   };
  // }, []);

  useEffect(()=>{
    window.FreshworksWidget('show', 'launcher');
  },[])

  const renderForm = () => {
    let html = null;

    if (authStep === 'signup-otp') {
      html = (
        <React.Fragment>
          <OtpForm
            user={{
              ...user,
              ...misc,
            }}
          />
        </React.Fragment>
      );
    } else if (['details'].includes(authStep)) {
      html = (
        <React.Fragment>
          <DetailsForm
            // isFarmerUser={Boolean(isFarmer)} //commented to disable quick farmer onboarding link
            // isNewFarmer={Boolean(isNewFarmer)} //commented to disable quick farmer onboarding link
            user={{
              ...user,
              ...misc,
            }}
          />
        </React.Fragment>
      );
    } else {
      html = (
        <div className="tabs tabs--justify tabs--bordered-bottom bfc-account-tabs">
          <div className="tabs__wrap">
            <Nav tabs>
              {!isFarmerUser && <NavItem>
                <NavLink className="nav-link" color="#1B62AB" to="/account/login">
                  LOG IN
                </NavLink>
              </NavItem>}
              {!isFarmerUser && <NavItem>
                <NavLink className="nav-link  active" to="/account/signup">
                  SIGN UP
                </NavLink>
              </NavItem>}
              {isFarmerUser && <NavItem>
                <NavLink className="nav-link  active" to="/account/signup?isFarmer=true">
                  SIGN UP
                </NavLink>
              </NavItem>}
            </Nav>
            <TabContent activeTab={'1'}>
              <TabPane tabId="1">
                {/* {!isFarmerUser && <p className="font-weight-bold text-center mr-3 cursor-pointer" style={{fontSize: 18}}>
                  <Link to="/account/signup?isFarmer=true">Are you a farmer?</Link>
                </p>} */}
                <SignupForm isFarmerUser={isFarmerUser}  setShowDisabled={setShowDisabled} showDisabled={showDisabled} />
                <div className="account__have-account bfc-login-page">
                  <p>
                    Already have an account?
                    <Link to="/account/login"> Login</Link>
                  </p>
                </div>
                <div
                  className="account-embed-link">
                  <div className="embed-link__content">
                    {/* <iframe
                      className="embed-link__video"
                      width="120"
                      height="80"
                      src="https://www.youtube.com/embed/R_z6pxMcqH4"
                      frameborder="0"
                      allowfullscreen>
                    </iframe> */}
                    <a target="_blank" href="https://www.youtube.com/watch?v=R_z6pxMcqH4">
                      <h5 className="embed-link__title text-center ml-0">
                        How to Register on the Platform
                      </h5>
                    </a>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      );
    }

    return html;
  };

  return (
    <>
      {underMaintenance ? (
        <>
          <div className="bfc-alert-wrapper">
            <TopBannerUnderMaintain id={id} user_type_enum={user_type_enum} />
          </div>
          <Container className="bfc-auth mt-10" style={{ pointerEvents: 'none', opacity: .1, }}>
            <Row className="no-gutters set-border" style={{marginLeft: isFarmerUser ? '12%' : ''}}>
              <div className="col col-lg-3 offset-lg-3 bfc-container">
                <WelcomeCard />
              </div>

              <div className="col col-lg-3 bfc-container bfc-signup-side">
                <div className="account bfc-account">
                  <div className="account__wrapper">
                    <div className="account__card">{renderForm()}</div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </>
      ) : (
        <>
          {showDisabled && (
            <div className="bfc-alert-wrapper phone-alert">
              <TopBannerContryAlertMessage customMessage="Please use an email address to sign up, if you have a non-India number. Alternatively, you can sign up with WhatsApp." />
            </div>
          )}
          <Container className="bfc-auth mt-10">
            <Row className="no-gutters set-border">
              <div className="col col-lg-3 offset-lg-3 bfc-container">
                <WelcomeCard isFarmerUser={isFarmerUser} />
              </div>

              <div className="col col-lg-3 bfc-container bfc-signup-side">
                <div className="account bfc-account">
                  <div className="account__wrapper">
                    <div className="account__card">{renderForm()}</div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Signup;
