import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchList(params = {}) {
    return utils.getQs(params);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }
    data = data.map((d) => {
      let item = {
        id: _.get(d, 'company_address_id') || 0,
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
};
