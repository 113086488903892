import _ from 'lodash';
import React from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from 'mdi-react/CloseIcon';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

const dataCache = {};

function ReferenceDataSelect(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);
  const [commodityClear, setCommodityClear] = React.useState(false);

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    placeholder,
    qualityLab,
    is_walk_in,
    setOpenMatchModal,
    check,
    getTransportationCharges,
    formik,
    is_pick_up,
    setDefaultFormId,
    setDefaultCommodityId,
    setDefaultResidueId,
    ispopup,
    qualityProfile,
    popOpen,
    setPopOpen,
    commodity_id,
    commodity_type_code,
    commodity_form_code,
    setDefaultCall,
    isMarketWholesaleEnum,
    cb,
    ...rest
  } = props;
  // const [popOpen, setPopOpen] = React.useState(ispopup);

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }

  let entityData = useSelector((state) => state.referenceData.data[entity]);
  if (!Array.isArray(entityData)) {
    entityData = [];
  }

  const loading = open && entityData && entityData.length === 0;

  const value_member = `${_.get(values, 'commodity_type_code') || ''}${_.get(values, 'commodity_form_code') || ''}` || `${commodity_type_code || ''}${commodity_form_code || ''}`;

  let selectedValue = entityData.find((e) => e.value_member === value_member);

  if (!selectedValue) {
    selectedValue = { value_member: (value_member || commodity_type_code), display_member: (value_member || commodity_form_code) ? initialDisplayText : '' };
  }

  if (is_walk_in || is_pick_up) {
    entityData = entityData.filter(function (el) {
      return el.commodity_form_code === 'Raw';
    });
  }
  // console.log('check entityData', entityData, isMarketWholesaleEnum)

  // if(values?.vehicle_type_enum === 2) {
  //   entityData = entityData.filter(item => item.commodity_form_code === 'Liquid' || item.commodity_form_code === 'Gas');
  // }
  // if(values?.vehicle_type_enum === 1 || values?.vehicle_type_enum === 3) {
  //   entityData = entityData.filter(item => item.commodity_form_code !== 'Liquid' && item.commodity_form_code !== 'Gas');
  // }

  const clearCommodity = () => {
    setCommodityClear(true);
    props.form.setFieldValue('commodity_type_code', '');
    selectedValue = { value_member: "", display_member: ""};
  };

  React.useEffect(() => {
    let active = true;

    const loadOptions = async () => {
      if (!entityData.length) {
        try {
          dispatch(refDataActions.getCommodityDropdownData(entity, {}));

          dataCache[entity] = entityData;
          setOptions(entityData);
        } catch (err) {
          console.log('getCompanies error', err);

          if (tries < 3) {
            setTries(tries + 1);
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    getTransportationCharges && formik && getTransportationCharges(formik)
  }, [values])

  React.useEffect(() => {
    if (ispopup) {
      setDefaultFormId(selectedValue.commodity_form_id ? selectedValue.commodity_form_id : 0);
    }
  }, [ispopup, selectedValue])

  const hasError = getIn(touched, field.name) && getIn(errors, field.name);

  return (
    <div className={`bfc-ref-data-select-wrapper`}>
      <Autocomplete
        value={qualityProfile ? selectedValue.display_member != "undefined - undefined" ? selectedValue : '' : selectedValue}
        id={`${entity}-autocomplete-${timestamp}`}
        defaultValue={initialDisplayText || ''}
        disabled={!!disabled}
        disableClearable
        autoHighlight
        // id="combo-box-demo"
        size="small"
        style={{ backgroundColor: 'white' }}
        // open={popOpen && true}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(o, value) => {
          if (o?.display_member && value?.display_member) {
            return o.display_member.trim().toLowerCase() === value.display_member.trim().toLowerCase();
          }
          return false;
        }}
        getOptionLabel={(o) => {
          if (o.display_member) {
            return o.display_member;
          }
          return '';
        }}
        options={_.orderBy(entityData, ['display_member'])}
        loading={loading}
        onChange={(event, newValue) => {
          if (qualityLab) {
            props.form.setFieldValue('commodity_type_id', newValue.commodity_type_master_id);
            props.form.setFieldValue('commodity_form_id', newValue.commodity_form_master_id);
            props.form.setFieldValue('commodity_type_code', newValue.commodity_type_code);
            props.form.setFieldValue('commodity_form_code', newValue.commodity_form_code);
          } else {
            if (newValue != null) {
              props.form.setFieldValue('commodity_type_id', newValue.commodity_type_id);
              props.form.setFieldValue('commodity_form_id', newValue.commodity_form_id);
              props.form.setFieldValue('commodity_type_master_id', newValue.commodity_type_master_id);
              props.form.setFieldValue('commodity_form_master_id', newValue.commodity_form_master_id);
              props.form.setFieldValue('commodity_type_code', newValue.commodity_type_code);
              props.form.setFieldValue('commodity_form_code', newValue.commodity_form_code);
              if (ispopup) {
                setDefaultFormId(newValue.commodity_form_id);
                // setDefaultCommodityId(0);
                // setDefaultResidueId(0);
              }
            }
          }
          if (qualityProfile) {
            setPopOpen(false);
          }
            // setDefaultFormId && setDefaultFormId(newValue.commodity_form_id);
          // }
          // setDefaultCall && setDefaultCall(newValue.commodity_form_id);
          if (dependentFields && dependentFields.length) {
            for (let df of dependentFields) {
              props.form.setFieldValue(df, 0);
            }
          }
          if (check === 'CommodityForm') { setOpenMatchModal && setOpenMatchModal(true); }
          cb && cb(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className="material-form__field"
            label={label}
            error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
            children={children}
            helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
            FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={placeholder}
          />
        )}
      />

      {(selectedValue?.display_member === "" || selectedValue?.display_member === "undefined - undefined") ? (
        ''
      ) : (
        <>
          {qualityProfile && (
            <CloseIcon
              className="w-auto quality-profile-clear__icon"
              onClick={() => {
                clearCommodity();
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ReferenceDataSelect;
