import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const counterSchema = yup.object().shape({
  price: yup.number().min(0, 'Invalid Amount'),
});
export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(counterSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};
