import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchFacilitatorLog = async (params) => {
  try {
    let res = await api.fetchFacilitatorLog(params);
    return res;
  } catch (err) {
    throw err;
  }
};
