import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import NotificationTabs from './components/NotificationTabs';

const Notifications = () => (
  <div className="bfc-body overflow-hidden">
    <div className="profile notification-profile">
      <Row>
        <NotificationTabs />
      </Row>
    </div>
  </div>
);

export default Notifications;
