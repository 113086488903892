import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';
import store from 'redux/store';
import { notificationService } from 'services';
import { actions as userActions } from 'redux/user';

export const handleMarkAll = async (values, sid, dispatch) => {
  try {
    const res = await notificationService.updateNotificationRecord(values);
    dispatch(userActions.fetchSocialProfile(sid));
    return res;
  } catch (err) {
    console.log('handleMarkAll--->error---->', err);
    notifications.show({
      type: 'error',
      message: err,
      key: 'req-form',
    });
  }
};
export const handleMarkMessage = async (values, sid, dispatch) => {
  try {
    const res = await notificationService.updateNotificationRecord(values);
    dispatch(userActions.fetchSocialProfile(sid));
    return res;
  } catch (err) {
    console.log('handleMarkMessage--->error---->', err);
    notifications.show({
      type: 'error',
      message: err,
      key: 'req-form',
    });
  }
};
