import _ from 'lodash';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import Profile from './Profile';
import Login from './Login';
import Signup from './Signup';
import Notifications from './Notifications';
import './account.scss';
import { decryptionInHex, getDecryptedRouteParams } from 'lib/utils';


const Account = (props) => {
  let { id } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);

  return (
    <React.Fragment>
      <div className="bfc-old-style">
        <div className="bfc-html">
          <div className="account-wrapper">
            <Switch>
              <Route exact path="/account/login">
                <Login />
              </Route>

              <Route exact path="/account/signup">
                <Signup />
              </Route>
              <Route exact path="/account/notifications">
                <Notifications />
              </Route>

              <Route path={`/account/:id`}>
                <Profile id={id} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Account;

//Issues :
// create social post adding n char in api response
