import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import normalize from 'normalize-object';

export const resTransform = {
  fetchList(resData) {
    let bankData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(bankData, 'value_member');
  },
  fetchDetails(resData) {
    return {
      value_member: _.get(resData, 'value_member'),
      display_member: _.get(resData, 'display_member'),
    };
  },
};
