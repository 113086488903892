import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const submitForApproval = async (affiliate_id = 0) => {
  try {
    // await helpers.validateSchema(KycSchema, params);
    const res = await api.submitForApproval(affiliate_id);
    return res;
  } catch (err) {
    throw err;
  }
};
