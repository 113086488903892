import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchClosingBalance(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchClosingBalance(resData) {
    let data = {
      ...resData,
      id: 0,
    };
    return data;
  },
};
