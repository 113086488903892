import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
// import LogoLoader from 'common/LogoLoader';
import OverviewHeader from 'common/OverviewHeader';
import ListDataGrid from 'common/ListDataGrid';
import { Table, Tag, Popover, Menu, Dropdown, Tooltip, Skeleton } from 'antd';
import AntTableActions from './components/AntTableActions';
import InfoIcon from 'common/Icons/InfoIcon';
import ProgressBar from 'common/SupplierProgressBar';
import AntPagination from 'common/AntTablePagination';
import { actions as smartBuyerConfigActions } from 'redux/smartBuyerConfig';
import { actions as generatesmartBuyerConfigurationReportActions } from 'redux/generateReportCommon';
import utils, { getDecryptedUrl } from 'lib/utils';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import PopupForm from 'common/Form/PopupForm';
import ConfigEditPopup from './components/configEditForm';
import useQuery from 'lib/hooks/useQuery';
import FilterUtils from 'lib/filterUtils';
import ClosingDateField from 'common/TableComponents/closingDateField';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import TableListHeader from 'common/TableListHeader';
import OverviewNav from 'common/OverviewNav';
import notifications from 'lib/notifications';

const SmartBuyerConfiguration = () => {
  const dispatch = useDispatch();
  const smartBuyerConfig = useSelector((state) => state.smartBuyerConfig);
  const GenerateReportAllData = useSelector((state) => state.generateReportCommon);
  const smartBuyerConfigData = _.get(smartBuyerConfig, 'entities');
  const busy = _.get(smartBuyerConfig, 'busy');
  let tableData = busy ? [] : Object.values(smartBuyerConfigData || {});
  const [isEdit, setIsEdit] = useState(false);
  const [rowData, setRowData] = useState({});
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [timeStamp, setTimeStamp] = useState(Date.now());
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const csvLink = useRef();
  const tableRef = useRef();

  const loc = getDecryptedUrl(window.location.href);

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};

  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  let { page, platform_charge_at_enum, buyer_company, modified_by, modified_on, back } = useQuery();
  const history = useHistory();

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'platform_charge_at_enum_code':
        delete params['platform_charge_at_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('platform_charge_at_enum'));
        break;
      case 'buyer_company_code':
        delete params['buyer_company'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('buyer_company'));
        break;
      case 'modified_datetime':
        delete params['modified_on'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('modified_on'));
        break;
      case 'modified_by_code':
        delete params['modified_by'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('modified_by'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'platform_charge_at_enum_code':
        params = {
          ...params,
          platform_charge_at_enum: e,
        };
        break;
      case 'buyer_company_code':
        params = {
          ...params,
          buyer_company: e,
        };
        break;
      case 'modified_datetime':
        params = {
          ...params,
          modified_on: e,
        };
        break;
      case 'modified_by_code':
        params = {
          ...params,
          modified_by: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const SMART_BUYER_CONFIG_TABLE_HEADER = [
    {
      title: `platform Master Id`,
      dataIndex: 'platform_master_id',
      width: '150px',
      align: 'center',
      fixed: true,
      sorter: (a, b) => a.platform_master_id - b.platform_master_id,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center d-flex align-items-center">
          <div className="commodity-form">
            <table>
              <tr>
                <td style={{ width: '50%', textAlign: 'end' }}>
                  {/* <Link to={row.detailsPageLink} style={{ color: 'var(--unnamed-color-51c4c3)' }}> */}
                    {row.platform_master_id}
                  {/* </Link> */}
                </td>
                <td style={{ width: '50%' }}>
                  {row?.is_published_by_control_tower === true ? (
                    <Popover placement="top" content={'Published by Control Tower'} trigger="hover">
                      <div className="bfc-table-list-components text-center">
                        <InfoIcon size={14} className="ml-2" />
                      </div>
                    </Popover>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      ),
      // filterDropdown: (props) => (
      //   <TextSearchFilter
      //     dataIndex={'commodity_req_offer_id'}
      //     data={props}
      //     onFilterChange={onFilterChange}
      //     handleReset={handleReset}
      //     value={commodity_req_offer_id}
      //     name={`${myCommodityName}`}
      //   />
      // ),
      // filterIcon: (filtered) => getSearchIcon(filtered, 'commodity_req_offer_id'),
      // filteredValue: commodity_req_offer_id !== undefined ? commodity_req_offer_id : null,
    },
    {
      title: `platform Charge At`,
      dataIndex: 'platform_charge_at_enum_code',
      width: '170px',
      align: 'center',
      sorter: (a, b) =>
        a.platform_charge_at_enum_code &&
        a.platform_charge_at_enum_code.localeCompare(b.platform_charge_at_enum_code && b.platform_charge_at_enum_code),
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{row.platform_charge_at_enum_code}</div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'platform_charge_at_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={platform_charge_at_enum}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'platform_charge_at_enum'),
      filteredValue: platform_charge_at_enum !== undefined ? platform_charge_at_enum : null,
    },
    {
      title: loc?.includes('smart-buyer-configuration') ? `Buyer Company` : 'Seller Company',
      dataIndex: 'buyer_company_code',
      width: '170px',
      align: 'center',
      sorter: (a, b) =>
        a.buyer_company_code && a.buyer_company_code.localeCompare(b.buyer_company_code && b.buyer_company_code),
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.buyer_company_code}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'buyer_company_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={buyer_company}
          name={'Buyer Company'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'buyer_company'),
      filteredValue: buyer_company !== undefined ? buyer_company : null,
    },
    {
      title: `Modified By`,
      dataIndex: 'modified_by_code',
      width: '170px',
      align: 'center',
      sorter: (a, b) =>
        a.modified_by_code && a.modified_by_code.localeCompare(b.modified_by_code && b.modified_by_code),
      render: (text, row) => <div className="bfc-table-list-components text-center">{row.modified_by_code}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'modified_by_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={modified_by}
          name={'Modified By'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'modified_by'),
      defaultFilteredValue: modified_by !== undefined ? modified_by : null,
    },
    {
      title: `Modified On`,
      dataIndex: 'modified_datetime',
      width: '170px',
      align: 'center',
      sorter: (a, b) =>
        a.modified_datetime && a.modified_datetime.localeCompare(b.modified_datetime && b.modified_datetime),
      render: (text, row) => (
        <div>
          {row.modified_datetime ? (
            <ClosingDateField closing_on={row.modified_datetime} to_date={row.modified_datetime} />
          ) : (
            ''
          )}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'modified_datetime'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={modified_on}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'modified_on'),
      defaultFilteredValue: modified_on !== undefined ? modified_on : null,
    },
    {
      title: '',
      dataIndex: 'operation_id',
      width: '100px',
      align: 'center',
      render: (value, row, index) => {
        const obj = {
          children: <AntTableActions showFlag={true} deleteFlag={false} toogle={true} row={row} />,
          props: {},
        };
        if (CT_parent) {
          return obj;
        } else {
          return null;
        }
      },
    },
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  tableData = tableData.map((d) => {
    return {
      ...d,
      showDetailsPopup: (e, row) => {
        e.preventDefault();
        setIsEdit(true);
        setRowData(row);
      },
    };
  });

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;
  let reportData = busy ? [] : Object.values(GenerateReportAllData.entities || {});
  tableData = _.orderBy(tableData, ['invoiceDateTime'], ['desc']);

  const getExportReport = async () => {
    const params = {
      filters: {
        ...filters,
        Is_Indent_Enabled: '1',
      },
      page_size: 10000,
      offset: 0,
    };
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    let success = await dispatch(generatesmartBuyerConfigurationReportActions.fetchListSmartBuyerConfigurationReport(params));
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(pathname + '?' + `back=${back}&page=1`);
    } else {
      history.push(pathname + '?' + `&page=1`);
    }
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      platform_charge_at_enum,
      buyer_company,
      modified_by,
      modified_on,
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
      ...(loc?.includes('smart-buyer-configuration') ? { Is_Indent_Enabled: '1' } : { Is_Seller_Indent_Enabled: '1' }),
      Is_Indent_buy_sell: '1',
    };
    let params = {
      filters: filterParams,
      // "Is_Indent_Enabled": "1"
      // },
      page_size: rowsPerPage,
      offset: (parseInt(page) - 1) * rowsPerPage,
    };

    dispatch(smartBuyerConfigActions.fetchAllPlateFormMaster(params));
  }, [timeStamp, rowsPerPage, page, platform_charge_at_enum, buyer_company, modified_by, modified_on, loc]);

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  let links = [
    { to: '/control-tower/smart-buyer-configuration?page=1', title: 'Smart Buyer' },
    { to: '/control-tower/smart-seller-configuration?page=1', title: 'Smart Seller' },
  ];

  return (
    <div>
      <div className="row1">
        {isEdit && (
          <PopupForm title={!_.isEmpty(rowData) ? 'Edit' : 'Create'} onClose={() => setIsEdit(false)}>
            <ConfigEditPopup data={rowData} close={() => setIsEdit(false)} setTimeStamp={setTimeStamp} />
          </PopupForm>
        )}
        <OverviewNav links={links} rightPanContent={
          <>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setIsEdit(true);
                setRowData({});
              }}
              disabled={!CT_parent}>
              Create
            </Button>
          </>
        } />
      </div>
      <div className="bfc-body">
        {/* {busy && (
          <div className="commodity-wrapper">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )} */}

        <div className="invoice-table">
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? SMART_BUYER_CONFIG_TABLE_HEADER.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : SMART_BUYER_CONFIG_TABLE_HEADER}
              pagination={false}
              // onChange={handlePageChange}
              scroll={{ y: 550 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              clearFilters={clearFilters}
              reportData={reportData}
              getExportReport={getExportReport}
              csvLink={csvLink}
              type={'Smart_Buyer_Configuration'}
              rowsPerPage={rowsPerPage}
              setScrollToFirstRow={setScrollToFirstRow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartBuyerConfiguration;
