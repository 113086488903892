import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const updateNotificationRecord = async (params) => {
  try {
    let bodyParams = reqTransform.updateNotificationRecord(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Notification/UpdateNotificationRecord`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.updateNotificationRecord(resData);
  } catch (err) {
    console.log('updateNotificationRecord-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateNotificationRecord');
    throw errors;
  }
};
