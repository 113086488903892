import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { userService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (farm) => _.get(farm, 'farm_address.person_address_id', 0),
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => (a.ts === b.ts ? 0 : a.ts < b.ts ? 1 : -1),
});

const slice = createSlice({
  name: 'notifications',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
    notificationCount: 0,
    hasMore: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      state.busy = action.payload;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
  },
});

const fetchAll =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await userService.fetchAllNotifications(params);
      if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
        dispatch(slice.actions.setHasMore(false));
      }
      dispatch(slice.actions.upsertMany(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const updateNotificationCount = (count) => async (dispatch) => {
  try {
    dispatch(
      slice.actions.setNotificationCount({
        notificationCount: count,
      }),
    );
  } catch (err) {
    dispatch(
      slice.actions.setNotificationCount({
        notificationCount: 0,
      }),
    );
    utils.displayErrors(err);
  }
};

const actions = {
  ...slice.actions,
  fetchAll,
  updateNotificationCount,
};

// // Rename the exports for readability in component usage
// export const {
//   selectById: selectUserById,
//   selectIds: selectUserIds,
//   selectEntities: selectUserEntities,
//   selectAll: selectAllUsers,
//   selectTotal: selectTotalUsers
// } = usersAdapter.getSelectors(state => state.users)

const selectors = {
  ...adapter.getSelectors((state) => state.notifications),
};

export { actions, selectors };

export default slice.reducer;
