
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReferenceDataSelect from 'pages/CTSettings/components/ReferenceDataSelect';
import { Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import handlers from '../CTEquipmentRental/handlers'
import utils, { getDecryptedRouteParams } from 'lib/utils';
import _ from 'lodash';
import LogoLoader from 'common/LogoLoader';

const getInitialValues = () => {
    let formFields = {
        equipment_master_id: 14,
        verification_status_enum: 1,
        comment: '',

    };

    return formFields;
};

const VerifyStatusPopup = () => {
    const dispatch = useDispatch();
    const { id } = getDecryptedRouteParams(useParams())
    const commentData = useSelector((state) => state.rental);
    const allCommentList = _.get(commentData, `entities[0]`);
    const commentList = _.get(allCommentList, 'equipment_verification_history_detail')
    const busy = _.get(commentData, 'busy') || false;

    const handleSubmit = async (values, formikBag) => {
        const params = {
            ...values,
            equipment_master_id: id,
            verification_status_enum: values.verification_status_enum,
            comment: values.comment
        }
        handlers.handleVerifyEquipment(params, formikBag)
    }
    return (
        <React.Fragment>
            <Formik initialValues={getInitialValues} onSubmit={handleSubmit}>
                {(formikBag) => {
                    const { values } = formikBag;
                    return (
                        <Form>
                            <div>
                                <div className="form-wrapper full-width">
                                    <div className="row form__form-group">
                                        <div className="col">
                                            <span className="form-group-label">Approve/Reject</span>
                                            <span class="required">*</span>
                                            <div className="form-group-field">
                                                <Field
                                                    name="verification_status_enum"
                                                    component={ReferenceDataSelect}
                                                    entity="verificationStatusEnum"
                                                    size="small"
                                                // disabled={_.get(formikBag, 'initialValues.type_of_individual_enum') || false || isAdmin}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-wrapper full-width">
                                    <div className="row form__form-group">
                                        <div className="col">
                                            <span className="form-group-label">Comment</span>
                                            <span class="required">*</span>
                                            <div className="form-group-field custom-input">
                                                <Field name="comment" className="notes_box" component="textarea" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrapper mb-20">
                                <div className="bid-popup-bottom-btn-wrapper ">
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={formikBag.handleSubmit}
                                        size="sm"
                                    //   disabled={formikBag.isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>

                            {busy ?
                                <div
                                    className=""
                                    style={{
                                        position: 'relative',
                                        minHeight: '150px',
                                    }}>
                                    <div className="panel__refresh">
                                        <LogoLoader />
                                    </div>
                                </div> :
                                <div>{_.map(commentList, (singleComment, history_key) => {
                                        return (
                                            <div className="card-body chat-care" key={history_key}>
                                                <ul className="chat">
                                                    <li className="admin">
                                                        <div className="chat-body">
                                                            <div className="header d-flex">
                                                                <strong className="primary-font">
                                                                    {singleComment.verification_status_code}
                                                                </strong>
                                                                <div className="float-right ml-auto">
                                                                    <p style={{ fontSize: '.625rem' }} className="text-muted">
                                                                        {/* {utils.formatDateTime(details.created_datetime)} */}
                                                                        {/* <p className="right-subtext">{details.verified_by}</p> */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <p className="chat-msg">{singleComment.verification_comment}</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            }



                        </Form>

                    )
                }}

            </Formik>
        </React.Fragment>
    );
};

export default VerifyStatusPopup;
