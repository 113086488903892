import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { KycBusinessIdentityService } from 'services';

// const companiesAdapter = createEntityAdapter({
//   // Assume IDs are stored in a field other than `book.id`
//   selectId: (company) => company.company_id,
//   // Keep the "all IDs" array sorted based on book titles
//   sortComparer: (a, b) => a.company_name.localeCompare(b.company_name),
// });

const slice = createSlice({
  name: 'kycBusinessTaxIdentity',
  // initialState: booksAdapter.getInitialState(),
  initialState: { ids: [], entities: {}, dirty: true, busy: false },
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },
    add(state, action) {
      const { resData } = action.payload;
      if (resData !== undefined) {
        state['entities'] = resData;
        state['ids'] = resData.company_gst_detail_id;
        state['dirty'] = false;
        state['updatedAt'] = Date.now();
      }
      // state.fetching = false;
    },
    update(state, action) {
      if (action.payload.flush === true) {
        state = action.payload;
        return state;
      }

      state = {
        ...state,
        ...action.payload,
        updatedAt: Date.now(),
      };
      // state['updatedAt'] = utils.uid();
    },
    setDirty(state, action) {
      state = { ids: [], entities: {}, dirty: true };
      return state;
    },
  },
});

const fetchTaxDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await KycBusinessIdentityService.fetchTaxDetails(params);
    dispatch(
      slice.actions.add({
        resData: resData,
      }),
    );
  } catch (err) {
    // dispatch(slice.actions.add({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchTaxDetails,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
