import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { postService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'userMention',
  initialState: adapter.getInitialState({
    busy: false,
    hasMore: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
  },
});

const fetchList = (params = {}, loadmore) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await postService.fetchUserMention(params);
    if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
      dispatch(slice.actions.setHasMore(false));
    }
    dispatch(slice.actions.upsertMany(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchCompanyPersonList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await postService.fetchCompanyPersonList(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchCompanyPersonList,
};

const selectors = {
  ...adapter.getSelectors((state) => state.userMention),
};

export { actions, selectors };

export default slice.reducer;
