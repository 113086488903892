import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Popover, TextField } from '@material-ui/core';
import { Button } from 'reactstrap';
import ColorPicker from 'react-best-gradient-color-picker';

import OverviewHeader from 'common/OverviewHeader';
import { actions as earlyPaymentsActions } from 'redux/earlyPayments';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import getColor from 'common/MUIComponents/colors';
import MUIButton from 'common/MUIComponents/Button';
import MUITextField from 'common/MUIComponents/TextField';

const SystemConfiguration = () => {
  const dispatch = useDispatch();
  const earlyPayments = useSelector((state) => state.earlyPayments);
  const marginState = earlyPayments?.margin?.margin;
  const interestRateState = earlyPayments?.interestRate?.cost_of_borrowing;
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(config?.theme_color || color);
  const [margin, setMargin] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [color, setColor] = useState(bgColor);
  const [theme, setTheme] = useState('Old');
  const [selectedConfigData, setSelectedConfigData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);
  useEffect(() => {
    dispatch(earlyPaymentsActions.fetchMarginForEarlyPayment());
    dispatch(earlyPaymentsActions.fetchCostOfBorrowingForEarlyPayment());
  }, []);

  useEffect(() => {
    marginState && setMargin(marginState);
    interestRateState && setInterestRate(interestRateState);
  }, [marginState, interestRateState]);

  const isPercentField =
    selectedConfigData?.config_parameter?.toLowerCase() === 'earlypaymentmargin' ||
    selectedConfigData?.config_parameter?.toLowerCase() === 'earlypaymentcob';
  const isNumericField =
    selectedConfigData?.config_parameter?.toLowerCase() === 'price_precision' ||
    selectedConfigData?.config_parameter?.toLowerCase() === 'qty_precision';

  return (
    <div>
      <OverviewHeader heading={`System Configuration`} />
      {/* <div className="col-md-6 col-lg-5 col-xl-4 mb-3">
        <span className="form-group-label">Margin</span>
        <span class="required">*</span>
        <div className="form-group-field">
          <TextField
            variant="outlined"
            size="small"
            type="number"
            value={margin}
            onChange={(e) => {
              if (
                (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,2})?$/) && e.target.valueAsNumber <= 100) ||
                !e.target.value
              ) {
                setMargin(e.target.valueAsNumber);
              }
            }}
          />
          <Button
            className="ml-3"
            color="primary"
            disabled={!margin}
            onClick={() => dispatch(earlyPaymentsActions.updateMargin({ margin }))}>
            Save
          </Button>
        </div>
      </div> */}
      {/* <div className="col-md-6 col-lg-5 col-xl-4 mb-2">
        <span className="form-group-label">Interest</span>
        <span class="required">*</span>
        <div className="form-group-field">
          <TextField
            variant="outlined"
            size="small"
            type="number"
            value={interestRate}
            onChange={(e) => {
              if (
                (e.target.value.match(/^[0-9]+(?:\.[0-9]{1,2})?$/) && e.target.valueAsNumber <= 100) ||
                !e.target.value
              ) {
                setInterestRate(e.target.valueAsNumber);
              }
            }}
          />
          <Button
            className="ml-3"
            color="primary"
            disabled={!interestRate}
            onClick={() => dispatch(earlyPaymentsActions.updateCostOfBorrowing({ cost_of_borrowing: interestRate }))}>
            Save
          </Button>
        </div>
      </div> */}
      <div className="col-md-6 col-lg-5 col-xl-4 mb-2">
        <span className="form-group-label">Select Config Parameter</span>
        <span class="required">*</span>
        <div className="form-group-field">
          <MUIAutocomplete
            dataStructure={{
              labelApiParam: 'config_display_text',
              valueApiParam: 'config_value',
            }}
            apiParams={{
              reqUrl: `BioFuelCommonUtilities/api/Configuration/GetAppConfiguration`,
              method: 'POST',
              getReqParams: (newVal) => ({
                filters: {},
                offset: 0,
                page_size: 10000,
              }),
            }}
            getSanitizedData={(data) => data?.filter((item) => item?.can_edit === true)}
            onChange={(newValue) => {
              setSelectedConfigData(newValue);
            }}
          />
        </div>
      </div>
      {selectedConfigData && selectedConfigData?.config_parameter?.toLowerCase() !== 'theme_color' ? (
        <div className="col-md-6 col-lg-5 col-xl-4 mb-2">
          <span className="form-group-label">
            {selectedConfigData?.config_parameter?.toLowerCase() === 'earlypaymentmargin'
              ? 'Margin'
              : selectedConfigData?.config_parameter?.toLowerCase() === 'earlypaymentcob'
                ? 'Interest'
                : selectedConfigData?.config_display_text || 'Value'}
          </span>
          <span class="required">*</span>
          <div className="form-group-field">
            <MUITextField
              fieldType={isPercentField ? 'percent' : isNumericField ? 'numeric' : 'alphanumeric'}
              value={selectedConfigData?.config_value}
              onChange={(newValue) => setSelectedConfigData({ ...selectedConfigData, config_value: newValue })}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {selectedConfigData?.config_parameter?.toLowerCase() === 'theme_color' ? (
        <div className="col-md-6 col-lg-5 col-xl-4 mb-2">
          <span className="form-group-label">Select Theme Color</span>
          <span class="required">*</span>
          <div className="form-group-field">
            <MUIAutocomplete
              selectedCodeOrId={selectedConfigData?.config_value}
              data={[
                { label: 'Old', value: 'old' },
                { label: 'Material UI', value: 'muiPrimary' },
                { label: 'Primary', value: 'primary' },
                { label: 'Secondary', value: 'secondary' },
                { label: 'Ternary', value: 'ternary' },
                { label: 'Custom', value: 'custom' },
              ]}
              dataStructure={{
                labelApiParam: 'label',
                valueApiParam: 'value',
              }}
              apiParams={{}}
              onChange={(newValue) => {
                setTheme(newValue);
              }}
            />
            {theme?.value === 'custom' ? (
              <div className="mt-2">
                <span className="form-group-label mr-2">Select Custom Color</span>
                <IconButton
                  style={{ padding: 4, border: `3px solid`, borderColor: color, borderRadius: '50%' }}
                  aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined}
                  onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <div
                    style={{
                      height: 20,
                      width: 20,
                      background: color,
                      borderColor: color,
                      borderRadius: '50%',
                    }}></div>
                </IconButton>
              </div>
            ) : (
              ''
            )}
            {theme?.value === 'custom' ? (
              <Popover
                style={{ padding: 10 }}
                id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                <div className="p-2">
                  <ColorPicker value={color} onChange={setColor} />
                </div>
              </Popover>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {selectedConfigData ? (
        <div className="col-md-6 col-lg-5 col-xl-4 mb-2">
          <div className="d-flex justify-content-end align-items-center mt-2">
            <MUIButton
              disabled={!CT_parent}
              onClick={async () => {
                const success = await dispatch(
                  earlyPaymentsActions.updateAppConfiguration({
                    config_parameter: selectedConfigData?.config_parameter,
                    config_value: theme?.value
                      ? theme?.value === 'custom'
                        ? color
                        : theme?.value
                      : selectedConfigData?.config_value,
                  }),
                );
                if (success) {
                  setSelectedConfigData(false);
                  window.location.reload();
                }
              }}>
              Save
            </MUIButton>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default SystemConfiguration;
