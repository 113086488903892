import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const bankNbfcSchema = yup.object().shape({
  // base_roi: yup.number().required('Required field ').positive('Invalid'),
  // base_amount_offered: yup.number().required('Required field ').positive('Invalid'),
  company_name: yup.string().required('Required field'),
});

export const responseApprovedSchema = yup.object().shape({
  loan_account_number: yup
    .string()
    .when('finance_enum', {
      is: 4,
      then: yup.string().required('Required field').max(20, 'Invalid'),
      otherwise: yup.string().optional(),
    })
    .matches(/^[a-zA-Z0-9]{1,100}$/, 'Only alphabets and numbers are allowed for this field '),

  approved_by: yup.string().required('Required field'),
  proposed_amount: yup.number().when('finance_enum', {
    is: 4,
    then: yup.number().required('Required field').positive('Invalid'),
    otherwise: yup.number().optional(),
  }),
  proposed_roi: yup.number().when('finance_enum', {
    is: 4,
    then: yup
      .number()
      .required('Required field')
      .positive('Invalid')
      .max(100, 'Invalid')
      .test('is-decimal', 'please enter 3 digits after decimal point', (value) =>
        (value + '').match(/^\d*(\.\d{0,3})?$/),
      ),
    otherwise: yup.number().optional(),
  }),
  proposal_valid_till_months: yup.string().when('finance_enum', {
    is: 4,
    then: yup.string().required('Required field'),
    otherwise: yup.string().optional(),
  }),
  margin: yup
    .number()
    .required('Required field')
    .positive('Invalid')
    .max(100, 'Invalid')
    .test('is-decimal', 'please enter 3 digits after decimal point', (value) =>
      (value + '').match(/^\d*(\.\d{0,3})?$/),
    ),
  penal_interest: yup
    .number()
    .required('Required field')
    .positive('Invalid')
    .max(100, 'Invalid')
    .test('is-decimal', 'please enter 3 digits after decimal point', (value) =>
      (value + '').match(/^\d*(\.\d{0,3})?$/),
    ),
});
export const responseRejectSchema = yup.object().shape({
  proposal_description: yup.string().required('Required field'),
  proposal_valid_till_months: yup.string().when('finance_enum', {
    is: 3,
    then: yup.string().required('Required field'),
    otherwise: yup.string().optional(),
  }),
});
export const responseNeedMoreInfoSchema = yup.object().shape({
  proposal_description: yup.string().required('Required field'),
});

export const updatePaymentSchema = yup.object().shape({
  payment_made_date: yup.string().required('payment Date is required'),
  ref_utr_number: yup.string().required('Payment Ref./ UTR No is required'),
  // from_bank_id: yup.number().positive('Please Select Bank').required('Please Select Bank'),
  // to_bank_id: yup.number().positive('Please Select Bank').required('Please Select Bank'),
  paid_to_bank: yup
    .number()
    .positive('Last 4 Digits of "Paid to Bank" Account is required')
    .required('Last 4 Digits of "Paid to Bank" Account is required'),
  // disbursed_amount: yup
  // .number()
  // .required('Please add amount to pay')
  // .test('amount_to_pay', `Invalid Amount!`, function (item) {
  //   let val =
  //     parseFloat(this.parent.amount_to_pay) >= parseFloat(this.parent.summary_amount_to_pay) &&
  //     parseFloat(this.parent.amount_to_pay) <= parseFloat(this.parent.payable);
  //   return val;
  // }),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const invoiceRespondSchema = yup.object().shape({
  action_taken_by_person_name: yup.string().when('invoice_finance_application_enum', {
    is: 3,
    then: yup.string().required('Required field'),
    otherwise: yup.string().optional(),
  }),
  disbursed_amount: yup.string().when('invoice_finance_application_enum', {
    is: 3,
    then: yup
      .string()
      .required('Required field')
      .test('Invalid', 'Invalid', function (val) {
        return parseFloat(val) > 0;
      }),
    otherwise: yup.string().optional(),
  }),
});

export const creditLimitSchema = yup.object().shape({
  company_affiliate_id: yup.number().when('company_affiliate_enum', {
    is: 2,
    then: yup.number().required('Required field').positive('Required field'),
    otherwise: yup.number().optional(),
  }),
  company_id: yup.number().when('company_affiliate_enum', {
    is: 1,
    then: yup.number().required('Required field').positive('Required field'),
    otherwise: yup.number().optional(),
  }),
  credit_limit: yup
    .string()
    .required('Required field')
    .test('Invalid', 'Invalid', function (val) {
      return parseFloat(val) > 0;
    }),
});
export const fetchDetails = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawInvoiceFinanceRequest = async (params) => {
  try {
    let res = await api.withdrawInvoiceFinanceRequest(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchChat = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const create = async (params) => {
  try {
    let res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const upload = async (params) => {
  try {
    let res = await api.upload(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUploadList = async (params) => {
  try {
    let res = await api.fetchUploadList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const sendMessage = async (params) => {
  try {
    let res = await api.sendMessageFromBody(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchMessages = async (params) => {
  try {
    let res = await api.fetchMessages(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createBank = async (params) => {
  try {
    await helpers.validateSchema(bankNbfcSchema, params);
    let res = await api.createBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchBankDetails = async (params) => {
  try {
    let res = await api.fetchBankDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createResponse = async (params) => {
  try {
    if (params['finance_enum'] == 4) {
      await helpers.validateSchema(responseApprovedSchema, params);
    } else if (params['finance_enum'] == 3) {
      await helpers.validateSchema(responseRejectSchema, params);
    } else if (params['finance_enum'] == 6) {
      await helpers.validateSchema(responseNeedMoreInfoSchema, params);
    }
    let res = await api.createResponse(params);
    return {};
  } catch (err) {
    throw err;
  }
};

export const createInvoiceRespond = async (params) => {
  try {
    await helpers.validateSchema(invoiceRespondSchema, params);
    let res = await api.createInvoiceRespond(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCreditLimitList = async (params) => {
  try {
    let res = await api.fetchCreditLimitList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDataForCreditLimitUpdate = async (params) => {
  try {
    let res = await api.fetchDataForCreditLimitUpdate(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCreditLimitCompanyList = async (params) => {
  try {
    let res = await api.fetchCreditLimitCompanyList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createCreditLimit = async (params) => {
  try {
    await helpers.validateSchema(creditLimitSchema, params);
    let res = await api.createCreditLimit(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDocument = async (params) => {
  try {
    let res = await api.fetchDocument(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchJobCardDocument = async (params) => {
  try {
    let res = await api.fetchJobCardDocument(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePaymentDetails = async (params) => {
  try {
    await helpers.validateSchema(updatePaymentSchema, params);
    let res = await api.updatePaymentDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateFinancePaymentDetails = async (params) => {
  try {
    await helpers.validateSchema(updatePaymentSchema, params);
    let res = await api.updateFinancePaymentDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const disburseAmountAgainstFinancedDealDetailId = async (params) => {
  try {
    let res = await api.disburseAmountAgainstFinancedDealDetailId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchSupplierRatings = async (params) => {
  try {
    let res = await api.fetchSupplierRatings(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getFinanceDeals_Applicant = async (params) => {
  try {
    let res = await api.getFinanceDeals_Applicant(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getFinanceDeals_NBFC = async (params) => {
  try {
    let res = await api.getFinanceDeals_NBFC(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getFinancedDealDetails = async (params) => {
  try {
    let res = await api.getFinancedDealDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};
