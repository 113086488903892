import React, { useState } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { Button, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, ButtonGroup } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const BulkActionsDropdown = (props) => {
    const {
        checkBoxArray,
        closeOutBtnTxt,
        disableCloseOutBtn,
        closeOutInitialValues,
        closeOutHandle,
        closeOutModalTitleTxt,
        handleAcceptSubmit,
        getCloseInitialValues,
        handleDeleteDeliveries,
        setHandler,
        setModalValue,
        setModalFormikBag,
        setModalTitle,
        setModal,
    } = props;

    let loc = window.location.href;

    return (
        <UncontrolledDropdown>
            <ButtonGroup dir="ltr">
                {checkBoxArray.length > 0 && (
                    <>
                        <Button
                            color="secondary"
                            className="ml-3"
                            style={{ minWidth: '100px', cursor: 'default' }}
                        >
                            Actions
                        </Button>
                        <DropdownToggle
                            color="secondary"
                            className="icon icon--right d-flex align-items-center">
                            <ChevronDownIcon style={{ margin: '0 7px' }} />
                        </DropdownToggle>
                    </>
                )}
            </ButtonGroup>
            <DropdownMenu right className="dropdown__menu" style={{ width: 'auto' }}>
                {(!disableCloseOutBtn && !(loc.includes('controltower/deliveries/not-confirmed'))) &&
                    <Formik initialValues={closeOutInitialValues}>
                        {(formikBag) => {
                            const { values } = formikBag;
                            return (
                                <Form>
                                    <DropdownItem key={1} disabled={disableCloseOutBtn} onClick={() => {
                                        setHandler(() => closeOutHandle);
                                        setModalValue(formikBag.values);
                                        setModalFormikBag(formikBag);
                                        setModalTitle(closeOutModalTitleTxt);
                                        setModal(true);
                                    }}>
                                        {closeOutBtnTxt}
                                    </DropdownItem>
                                </Form>
                            );
                        }}
                    </Formik>
                }
                <Formik initialValues={getCloseInitialValues()}>
                    {(formikBag) => {
                        const { values } = formikBag;
                        const showMoveDateForPages = (loc.includes('controltower/deliveries/upcoming') || loc.includes('controltower/deliveries/missed'))
                        const showRevertStatusForPages = (loc.includes('controltower/deliveries/upcoming') || loc.includes('controltower/deliveries/ongoing') || loc.includes('controltower/deliveries/completed') || loc.includes('controltower/deliveries/missed'))
                        return (
                            <Form>
                                {
                                    (showMoveDateForPages) &&
                                    <DropdownItem key={2} onClick={() => {
                                        setHandler(() => handleAcceptSubmit);
                                        setModalValue(formikBag.values);
                                        setModalFormikBag(formikBag);
                                        setModalTitle('Move Date');
                                        setModal(true);
                                    }}>
                                        Move Date
                                    </DropdownItem>
                                }
                                {(showRevertStatusForPages) &&
                                    <DropdownItem key={3} onClick={() => {
                                        setHandler(() => handleAcceptSubmit);
                                        setModalValue(formikBag.values);
                                        setModalFormikBag(formikBag);
                                        setModalTitle('Revert Status To');
                                        setModal(true);
                                    }}>
                                        Revert Status To
                                    </DropdownItem>
                                }
                                {
                                    (loc.includes('controltower/deliveries/not-confirmed')) &&
                                    <DropdownItem key={4} onClick={() => {
                                        setHandler(() => handleDeleteDeliveries);
                                        setModalValue(formikBag.values);
                                        setModalFormikBag(formikBag);
                                        setModalTitle('Delete Schedule(s)');
                                        setModal(true);
                                    }}>
                                        Delete
                                    </DropdownItem>
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default BulkActionsDropdown;
