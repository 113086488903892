import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader } from 'reactstrap';
import utils from 'lib/utils';
const KycComment = (props) => {
  const { comment = {} } = props;
  return (
    // <Card className="kyc-comment-card">
      <div className="">
      {/* {utils.debug(comment)} */}
        <div className="card-body chat-care">
          <ul className="chat">
            <li className="admin">
              <div className="chat-body">
                <div className="header d-flex">
                  <strong className="primary-font">
                    {comment.kyc_status_enum_code}{' '}
                  </strong>
                  <div className="float-right ml-auto">
                    <p style={{ fontSize: '.625rem' }} className="text-muted">
                      {utils.formatDateTime(comment.created_datetime)}
                      <p className="right-subtext">{comment.control_tower_person_code}</p>
                    </p>
                  </div>
                </div>
                <p className="chat-msg">{comment.note_text}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    //   <CardHeader
    //     style={{
    //       backgroundColor: '#35858D08',
    //       color: 'var(--unnamed-color-707070)',
    //       border: '1px solid #FFFFFF',
    //     }}>
    //     <div className="d-flex justify-content-between">
    //       <span className="text-left">{comment.kyc_status_enum_code}</span>
    //       <span className="text-right">
    //         {utils.formatDateTime(comment.created_datetime)}
    //         <br />
    //         {comment.control_tower_person_code}
    //       </span>
    //     </div>
    //   </CardHeader>
    //   <CardBody>
    //     <span className="text-left">{comment.note_text}</span>
    //   </CardBody>
    // </Card>
  );
};

export default KycComment;
