import React, { useState } from 'react';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';

const FormikDatePickerField = (props) => {
  // const { fieldName, placeholder, formikBag } = props;
  // const { touched, errors } = formikBag;

  const { field, form, fieldName, defaultValue, placeholder, minDate, disabled = false } = props;
  const { touched, errors } = form;

  const name = field.name || fieldName;

  const [date, setDate] = useState(props.defaultValue ? new Date(props.defaultValue) : null);

  const handleChange = (updatedDate) => {
    setDate(updatedDate);
    form.setFieldValue(name, updatedDate);
  };

  const hasError = touched[name] && errors[name];

  return (
    <div className="date-picker">
      <div className={`${hasError ? 'has-error' : ''} bfc-date-time-wrapper w-100"`}>
        {/* <div className="date-picker date-picker--single"> */}
        <DatePicker
          // timeFormat="HH:mm"
          // className="form__form-group-datepicker"
          selected={date}
          selectsStart
          onChange={handleChange}
          defaultValue={defaultValue}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          dropDownMode="select"
          withPortal={isMobileOnly}
          maxDate={new Date()}
          placeholderText={placeholder}
          disabled={disabled}
        />
        {touched[name] && errors[name] && <span className="form__form-group-error">{errors[name]}</span>}

      </div>
    </div>
  );
};

export default FormikDatePickerField;
