import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { resTransform } from './transforms';

const { baseUrl } = config;

export const fetchEarlyPaymentRecords = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchEarlyPaymentRecords`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    if (params?.is_csv_report) {
      return resTransform.fetchEarlyPaymentRecords(resData);
    } else {
      return resData.map((item, index) => ({ ...item, id: index }));
    }
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchEarlyPaymentRecords');
    throw errors;
  }
};

export const fetchAdjustmentFactorForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchAdjustmentFactorForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAdjustmentFactorForEarlyPayment');
    throw errors;
  }
};

export const fetchCompanyAffiliateForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchCompanyAffiliateForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchCompanyAffiliateForEarlyPayment');
    throw errors;
  }
};

export const saveAndUpdateAdjustmentFactor = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/SaveandUpdateAdjustmentFactor?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveandUpdateAdjustmentFactor');
    throw errors;
  }
};

export const updateMargin = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/UpdateMargin?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdateMargin');
    throw errors;
  }
};

export const updateCostOfBorrowing = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/UpdateCostOfBorrowing?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdateCostOfBorrowing');
    throw errors;
  }
};

export const fetchMarginForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchMarginForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchMarginForEarlyPayment');
    throw errors;
  }
};

export const fetchCostOfBorrowingForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchCostOfBorrowingForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchCostOfBorrowingForEarlyPayment');
    throw errors;
  }
};

export const deleteAdjustmentFactor = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/DeleteAdjustmentFactor?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'DeleteAdjustmentFactor');
    throw errors;
  }
};

export const fetchAdjustmentFactorHistoryForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchAdjustmentFactorHistoryForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAdjustmentFactorHistoryForEarlyPayment');
    throw errors;
  }
};

export const fetchDiscountForEarlyPayment = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/FetchDiscountForEarlyPayment`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    resData = resData.map((item, index) => ({ ...item, id: index }));
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchDiscountForEarlyPayment');
    throw errors;
  }
};

export const deleteEarlyPaymentDiscount = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/DeleteEarlyPaymentDiscount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'DeleteEarlyPaymentDiscount');
    throw errors;
  }
};

export const saveAndUpdateEarlyPaymentDiscount = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/SaveandUpdateEarlyPaymentDiscount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveandUpdateEarlyPaymentDiscount');
    throw errors;
  }
};

export const updateAppConfiguration = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/UpdateAppConfiguration?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdateAppConfiguration');
    throw errors;
  }
};
