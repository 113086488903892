import _ from 'lodash';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { actions as kycBusinessBankIdentityActions } from 'redux/kycBusinessBankIdentity';
import utils from 'lib/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PopupForm from 'common/Form/PopupForm';
import BankPopup from 'containers/Account/Profile/KYCIndividual/components/BankPopup';
import BankVerificationForm from 'containers/Account/Profile/KYCIndividual/components/BankVerificationForm';
import * as handlers from '../handlers';
import * as individualHandlers from 'containers/Account/Profile/KYCIndividual/handlers';
import ToggleSwitch from 'containers/Account/Profile/KYCIndividual/components/toggleSwitch';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { Table, Switch, Radio, Space } from 'antd';
import AntTableActions from 'common/AntTableActions';
import AntTableKYCActions from 'common/AntTableKYCActions';
import {  Skeleton } from 'antd';
import LogoLoader from 'common/LogoLoader';

const getInitialValues = (bankDetails) => {
  let params = {
    bank_account_details_request_model: bankDetails || [],
  };
  return params;
};
const BankForm = (props) => {
  const dispatch = useDispatch();
  const [showPopUp, setPopUp] = useState(false);
  const [showVerificationForm, setVerificationForm] = useState(false);
  const [personBankAccountId, setPersonBankAccountId] = useState(0);
  const [showCancelledCheque, setShowCancelledCheque] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [bankRow, setBankRow] = useState(-1);
  const { id, entity_id, company_affiliate_enum, kycStatusKey, isAdmin } = props;
  const kycBankBusiness = useSelector((state) => state.kycBusinessBankIdentity);
  const bankIds = _.get(kycBankBusiness, 'ids') || {};
  const bankDataWithVirtualAccount = _.get(kycBankBusiness, 'bankDetails') || {};
  const bankDetailsIds = _.get(kycBankBusiness, 'ids[0]') || {};
  let bankDetails = _.get(kycBankBusiness, `entities[${bankDetailsIds}]`) || {};
  const busyBank = _.get(kycBankBusiness, 'busy') || false;
  const user = useSelector((state) => state.user?.profile?.person);
  let bankCount = 0;
  // let bankArray = [];
  let bankArray = bankDataWithVirtualAccount;
  // for (bankCount = 0; bankCount < bankIds.length; bankCount++) {
  //   for (bankCount = 0; bankCount < bankDataWithVirtualAccount?.length; bankCount++) {
  //   let id = bankIds[bankCount];
  //   // const bankDetails = _.get(kycBankBusiness, `entities[${id}]`) || {};
  //   const bankDetails = bankDataWithVirtualAccount;
  //   let newBankDetails = {
  //     ...bankDetails,
  //     operation_enum: 3,
  //   };
  //   bankArray.push(newBankDetails);
  // }
  let entityIdBank = _.get(bankDetails, 'company_id') || _.get(bankDetails, 'affiliate_id') || 0;
  if (entity_id !== entityIdBank) {
    bankDetails = {};
  }

  // NEW TABLE

  const handleDelete = async (id) => {
    try {
      let res = await handlers.handleDeleteBank(id);
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
  };

  const removeBankDetails = async (index) => {
    let params = {
      entity_id: entity_id,
    };
    const success = await handlers.handleDeleteBank(params);
    if (success) {
      setTimestamp(Date.now());
    }
  };
  const cancel = (e) => {
    console.log(e);
  };

  const columns = [
    {
      title: 'Beneficiary Name',
      dataIndex: 'account_name',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.va_id ? `${props.selectedUser ? props.selectedUser : bankArray[0]?.account_name} c/o ${text}` : text}
        </div>
      ),
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      align: 'center',
      render: (text, row) => {
        let accountNumber = row.account_number;
        if (!isAdmin) {
          let trailingCharsIntactCount = 4;
          if (row?.account_number?.length > 4 && !row.va_id) {
            accountNumber =
              new Array(row.account_number.length - trailingCharsIntactCount + 1).join('x') +
              row.account_number.slice(-trailingCharsIntactCount);
          }
        }

        return <div className="bfc-table-list-components text-center">{accountNumber}</div>;
      },
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifsc_code',
      align: 'center',
      render: (text, row) => <div className="bfc-table-list-components text-center">{text}</div>,
    },
    {
      title: 'Default',
      dataIndex: 'default',
      align: 'center',
      render: (text, row) => (
        <FormControlLabel control={<ToggleSwitch checked={row.is_default} disabled={row.va_id} />} />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      align: 'center',
      render: (text, row) => (
        <FormControlLabel control={<ToggleSwitch checked={row.is_active} disabled={row.va_id} />} />
      ),
    },
    {
      title: 'Verification Status',
      dataIndex: 'verification_status',
      align: 'center',
      render: (text, row) => {
        let canEdit =
          row.bank_verification_enum == 1 || row.bank_verification_enum == 4 || row.bank_verification_enum == 6;
        let verificationText = 'Verify';
        let bankVerificationCode = row.bank_verification_enum_code;
        if (bankVerificationCode == 'VerificationFailed' || bankVerificationCode == 'ZoopFailed') {
          setShowCancelledCheque(true);
        } else if (bankVerificationCode == 'VerificationSuccessful' || bankVerificationCode == 'VERIFIED'){
          setShowCancelledCheque(false);
        }
        let pennyDropped = row.bank_verification_enum == 3;
        if (pennyDropped) {
          verificationText = 'Complete Verification';
        }
        return (
          <div className="bfc-table-list-components text-center">
            {row.bank_verification_enum_code || 'N/A'}
            <br />
            {(canEdit || pennyDropped) && (
              <span
                style={{
                  color: 'var(--unnamed-color-1b62ab)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!isAdmin && !kycStatusKey) {
                    if (pennyDropped) {
                      setPersonBankAccountId(row.person_bank_account_id);
                      setVerificationForm(true);
                    } else if (canEdit) {
                      handlers.handleBankVerification(
                        {
                          bank_entity_id: row.bank_account_id || row.person_bank_account_id,
                          company_affiliate_enum: company_affiliate_enum,
                        },

                        setTimestamp,
                      );
                    }
                  }
                }}>
                {verificationText}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      align: 'center',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">
          {row.is_bank_manually_verified && row.bank_verification_enum === 5
            ? 'Verified By Control Tower'
            : row.va_id
            ? 'Your Virtual Account for Online Payments'
            : row.bank_verification_enum === 4 || row.bank_verification_enum === 6
              ? row.bank_verification_failure_reason || 'N/A'
              : 'N/A'}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'address_text_',
      width: '50px',
      align: 'center',
      render: (value, row, index) => {
        let canEdit =
          row.bank_verification_enum == 1 || row.bank_verification_enum == 4 || row.bank_verification_enum == 6;
        let params = {
          entity_id: entity_id,
        };
        const obj = {
          children: (
            <AntTableKYCActions
              row={row}
              handleDelete={handleDelete}
              id={row.bank_account_id}
              name={row.account_name}
              cancel={cancel}
              showFlag={!(isAdmin || kycStatusKey)}
              setBankRow={setBankRow}
              params={params}
              removeBankDetails={removeBankDetails}
            />
          ),
          props: {},
        };
        return obj;
      },
    },
  ];

  useEffect(() => {
    if (entity_id !== undefined) {
      const params = {
        entity_id: entity_id,
        company_affiliate_enum: company_affiliate_enum,
        person_id: id,
        isSerilized: false,
      };
      dispatch(kycBusinessBankIdentityActions.fetchBankDetailsWithVistualAccount(params));
    }
  }, [entity_id, timestamp]);

  if (busyBank) {
    return (
      <div
        className=""
        style={{
          position: 'relative',
          minHeight: '150px',
        }}>
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(bankArray)}
        onSubmit={async (values, formikBag) => {
          // handleCheck(values, formikBag);
        }}>
        {(formikBag) => {
          const { values } = formikBag;
          const bankRowCount = _.get(values, 'bank_account_details_request_model.length') || 0;
          const removeQualitySpec = async (index) => {
            let params = {
              entity_id: entity_id,
            };
            const success = await individualHandlers.handleDeleteBank(params);
            if (success) {
              let bank_account_details_request_model = [...values.bank_account_details_request_model];

              bank_account_details_request_model.splice(index, 1);

              formikBag.setFieldValue('bank_account_details_request_model', bank_account_details_request_model);
            }
          };
          return (
            <Form className="formik-form">
              {bankRow >= 0 && (
                <PopupForm
                  title={_.isEmpty(values.bank_account_details_request_model[bankRow]) ? 'Add Bank' : 'Update Bank'}
                  onClose={() => setBankRow(-1)}>
                  <BankPopup
                    bankData={values.bank_account_details_request_model[bankRow] || {}}
                    business_type_enum={props.business_type_enum}
                    type={'business'}
                    setTimestamp={setTimestamp}
                    entity_id={entity_id}
                    company_affiliate_enum={company_affiliate_enum}
                    bankDetails={bankArray}
                    onSave={(qpValue) => {
                      let bank_account_details_request_model = [...values.bank_account_details_request_model];

                      if (bank_account_details_request_model[bankRow]) {
                        bank_account_details_request_model[bankRow] = qpValue;
                      } else {
                        bank_account_details_request_model.push(qpValue);
                      }

                      formikBag.setFieldValue('bank_account_details_request_model', bank_account_details_request_model);
                      setBankRow(-1);
                    }}
                    close={() => setBankRow(-1)}
                  />
                </PopupForm>
              )}
              <div className="row form__form-group">
                <div className="bfc-table-box w-100 container-fluid">
                  {values['bank_account_details_request_model'].length == 0 && (
                    <div className="add-new-btn-box mt-20">
                      <Button
                        onClick={() => setBankRow(bankRowCount + 1)}
                        type="button"
                        className="add-new-btn"
                        disabled={isAdmin || kycStatusKey}
                        size="sm">
                        <span className="icon">+</span>
                        <span className="text">Add Bank</span>
                      </Button>
                    </div>
                  )}
                  <Table
                    // dataSource={values.bank_account_details_request_model}
                    // columns={columns}
                    dataSource={!busyBank ? values.bank_account_details_request_model : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    columns={busyBank ? columns.map((column) => {
                      return {
                        ...column,
                        render: function renderPlaceholder() {
                          return (
                            <Skeleton
                              active
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          );
                        },
                      };
                  }) : columns}
                    pagination={false}
                    size="small"
                    className="ant-table"
                  />
                  {showCancelledCheque && (
                    <div className="form-group-field">
                      <div className="notes-block">
                        <p style={{ marginTop: '10px', color: 'red' }}>
                          Your bank verification has failed. Upload a pic of crossed cancelled cheque for manual bank
                          verification in the upload tab
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BankForm;
