/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classnames from 'classnames';

class CheckBoxField extends PureComponent {
  render() {
    const { disabled, className, name, value, onChange, label, color } = this.props;

    const CheckboxClass = classnames({
      'checkbox-btn': true,
      disabled,
    });

    const id = `${name}-${Date.now()}`;

    return (
       <label>
       Don't have a PAN
       <input type="checkbox" id={id}
          name={name}
          onChange={onChange}
          disabled={disabled}
          defaultChecked={value} />
     </label>
    );
  }
}

const FormikCheckBox = (props) => {

  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  let errClass = errors[field.name] ? 'bfc-has-error' : '';

  return (
    <div className={errClass}>
      <CheckBoxField
        {...field}
        {...rest}
      />
    </div>
  );
};

export default FormikCheckBox;
