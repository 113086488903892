import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/GetTeamMembers?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resData1.team_members;
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-transport-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetTeamMembers');
    throw errors;
  }
};

export const fetchTeamMembersFromOrganization = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/FetchTeamMembersFromOrganisation`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resData1.team_members;
    // return resTransform.fetchList(resData);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchTeamMembersFromOrganisation');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-farms-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/CreateTeamMember`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-teams-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CreateTeamMember');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/CreateTeamMember`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('api-team-member-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'CreateTeamMember');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/DeleteTeamMember?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-delete->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteTeamMember');
    throw errors;
  }
};

export const fetchListPost = async (params) => {
  try {
    let bodyParams = reqTransform.fetchListPost(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/FetchAllCommodityDealsAgainstEntityIdWithFilter_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    const finalRes = {
      data: [...resData],
    };
    return finalRes;
    //  return resTransform.fetchListPost(resData);
  } catch (err) {
    console.log('api-teams-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCommodityDealsAgainstEntityIdWithFilter_ELOC');
    throw errors;
  }
};

export const fetchDealList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchListPost(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/FetchAllCommodityDealsAgainstEntityIdWithFilter_CT`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    const finalRes = {
      data: [...resData],
    };
    return finalRes;
  } catch (err) {
    console.log('api-teams-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCommodityDealsAgainstEntityIdWithFilter_CT');
    throw errors;
  }
};

export const fetchAddressList = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchAllCompanyAndAffiliateAddresses`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAddressList(resData);
  } catch (err) {
    console.log('api-transport-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCompanyAndAffiliateAddresses');
    throw errors;
  }
};
export const fetchEPList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/GetTeamMembers?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resData1.team_members_for_ep_allotment;
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-transport-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetTeamMembers');
    throw errors;
  }
};
