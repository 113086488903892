import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';
import pageParams from 'constants/pageParams';

const { baseUrl } = config;

export const fetchTransportationCost = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTransportationCost(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Offer/FetchTransportationCost_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTransportationCost(resData);
  } catch (err) {
    console.log('api-farms-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTransportationCost_ELOC');
    throw errors;
  }
};

export const fetchUnloadingCharges = async (params = {}) => {
  try {
    const qs = reqTransform.fetchCharges(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Bid/FetchUnloadingCharges?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCharges(resData);
  } catch (err) {
    console.log('api-FetchUnloadingCharges-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchUnloadingCharges');
    throw errors;
  }
};

export const fetchLoadingCharges = async (params = {}) => {
  try {
    const qs = reqTransform.fetchCharges(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Offer/FetchLoadingCharges?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCharges(resData);
  } catch (err) {
    console.log('api-FetchLoadingCharges-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchLoadingCharges');
    throw errors;
  }
};

export const fetchMatchingCount = async (params = {}) => {
  try {
    const qs = reqTransform.fetchMatchingCount(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchMatchingBuySellsWhileCreatingBuySell`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchMatchingCount(resData);
  } catch (err) {
    console.log('api-FetchMatchingBuySellsWhileCreatingBuySell-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchMatchingBuySellsWhileCreatingBuySell');
    throw errors;
  }
};

export const hideAutoMatch = async (params = {}) => {
  try {
    const qs = reqTransform.hideAutoMatch(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/HideAutoBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.hideAutoMatch(resData);
  } catch (err) {
    console.log('api-HideAutoBidOffer-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'HideAutoBidOffer');
    throw errors;
  }
};

export const counterAutoBidOffer = async (params = {}, counterParams) => {
  try {
    const qs = reqTransform.counterAutoBidOffer(params);
    const bodyParams = reqTransform.createCounter(counterParams);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/CounterAutoBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.counterAutoBidOffer(resData);
  } catch (err) {
    console.log('api-CounterAutoBidOffer-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CounterAutoBidOffer');
    throw errors;
  }
};

export const fetchDerivedAutoBidOffers = async (id, params = {}) => {
  try {
    const qs = reqTransform.fetchDerivedAutoBidOffers(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchDerivedAutoBidOffers?commodity_req_offer_id=${id}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDerivedAutoBidOffersList(resData);
  } catch (err) {
    console.log('api-FetchDerivedAutoBidOffers-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDerivedAutoBidOffers');
    throw errors;
  }
};

export const fetchAutoAndRegularBidOffers = async (params = {}) => {
  try {
    const qs = reqTransform.fetchAutoAndRegularBidOffers(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchDerivedAutoBidOffers`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(qs),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAutoAndRegularBidOffers(resData);
  } catch (err) {
    console.log('api-FetchDerivedAutoBidOffers-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDerivedAutoBidOffers');
    throw errors;
  }
};

export const fetchAutoBidOffers = async (params = {}) => {
  try {
    const qs = reqTransform.fetchAutoBidOffers(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchAutoBidOffers?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAutoBidOffers(resData);
  } catch (err) {
    console.log('api-FetchAutoBidOffers-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAutoBidOffers');
    throw errors;
  }
};

export const withdrawAutoBidOffer = async (params = {}) => {
  try {
    const qs = reqTransform.withdrawAutoBidOffer(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/WithdrawAutoBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.withdrawAutoBidOffer(resData);
  } catch (err) {
    console.log('api-WithdrawAutoBidOffer-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'WithdrawAutoBidOffer');
    throw errors;
  }
};

export const acceptRejectAutoBidOfferCounter = async (params = {}) => {
  try {
    const qs = reqTransform.acceptRejectAutoBidOfferCounter(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/AcceptRejectAutoBidOfferCounter?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.acceptRejectAutoBidOfferCounter(resData);
  } catch (err) {
    console.log('api-AcceptRejectAutoBidOfferCounter-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'AcceptRejectAutoBidOfferCounter');
    throw errors;
  }
};

export const acceptRejectAutoBidOffer = async (params = {}) => {
  try {
    const qs = reqTransform.acceptRejectAutoBidOffer(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/AcceptRejectAutoBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.acceptRejectAutoBidOffer(resData);
  } catch (err) {
    console.log('api-AcceptRejectAutoBidOffer-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'AcceptRejectAutoBidOffer');
    throw errors;
  }
};

export const setTransportationCost = async (params = {}) => {
  try {
    const qs = reqTransform.setTransportationCost(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/SetTransportationCost?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.setTransportationCost(resData);
  } catch (err) {
    console.log('api-SetTransportationCost-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SetTransportationCost');
    throw errors;
  }
};

export const refreshBuySellCompatibilityMatrix = async (params = {}) => {
  try {
    const qs = reqTransform.refreshBuySellCompatibilityMatrix(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/RefreshBuySellCompatibilityMatrix?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.refreshBuySellCompatibilityMatrix(resData);
  } catch (err) {
    console.log('api-RefreshBuySellCompatibilityMatrix-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RefreshBuySellCompatibilityMatrix');
    throw errors;
  }
};

export const fetchMatchingOverviewCount = async (params = {}) => {
  try {
    const qs = reqTransform.fetchMatchingOverviewCount(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/AutoBidOffer/FetchMatchingBuySellsCount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchMatchingOverviewCount(resData);
  } catch (err) {
    console.log('api-FetchMatchingBuySellsCount-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchMatchingBuySellsCount');
    throw errors;
  }
};

export const editPickUpOfferByOwner = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Offer/EditPickUpOfferByOwner`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchMatchingOverviewCount(resData);
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'EditPickUpOfferByOwner');
    throw errors;
  }
};
