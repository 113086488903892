import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchSubscriptionsHistory = async (filters, params) => {
  try {
    let res = await api.fetchSubscriptionsHistory(filters, params);
    return res;
  } catch (err) {
    throw err;
  }
};
