import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

const FormikMaterialTextField = (props) => {
  const {
    field,
    form: { touched, errors, setFieldValue, values },
    setShowDisabled,
    setShowEmail,
    islogin,
    isSignup,
    ...rest
  } = props;

  const [type, setType] = useState(false);
  const [emailText, setEmailText] = useState(false);

  useEffect(() => {
    if (props.form.values.user_name && !type) {
      if (props.form.values.user_name.length > 8) {
        setFieldValue('user_name', props.form.values.user_name);
        setFieldValue('country_code', islogin || isSignup ? 91 : '');
      }
    }
  }, [props.form.values.user_name, !type]);

  return props?.isFarmerUser ? (
    <TextField
      allowBlank={true}
      onWheel={(event) => {
        // event.preventDefault();
        event.target.blur();
      }}
      value={values[field.name]}
      onChange={(e) => {
        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
          setFieldValue(field.name, e.target.value);
        }
      }}
      className="material-form__field"
      error={touched[field.name] && errors[field.name]}
      helperText={touched[field.name] && errors[field.name] ? errors[field.name] : ''}
      // {...field}
      {...rest}
    />
  ) : (
    <>
      {islogin || isSignup ? (
        <TextField
          allowBlank={true}
          onWheel={(event) => {
            event.target.blur();
          }}
          onChange={(event) => {
            setType(true);
            if (islogin || isSignup) {
              let originalString = event.target.value;
              if (originalString.length > 0) {
                const pattern = /^(\+\d{1,})?(\d{1,})$/;
                const checkPattern = pattern.test(originalString);
                let getPlusSign = originalString?.charAt(0);
                let getCountryCode = originalString?.substr(0, 3);
                let getCountryPhone = originalString?.substr(3, originalString.length - 1);
                let checkEmail = /^[0-9]+$/.test(event.target.value); //@/.test(checkPattern);
                setShowEmail(checkEmail);
                setEmailText(checkEmail);
                if (checkPattern) {
                  if (getPlusSign == '+' && getCountryCode == '+91') {
                    if (getCountryCode == '+91') {
                      setFieldValue('country_code', parseInt(getCountryCode));
                    }
                    let getphoneNumber = originalString?.substr(3, 15);
                    if (getphoneNumber) {
                      setFieldValue('user_name', getphoneNumber);
                    }
                  } else if (getPlusSign == '+' && getCountryCode != '+91') {
                    if (getCountryCode != '+91') {
                      setFieldValue('country_code', parseInt(getCountryCode));
                    }
                    let getphoneNumber = originalString?.substr(3, 15);
                    if (getphoneNumber) {
                      setFieldValue('user_name', getphoneNumber);
                    }
                  } else {
                    let getphoneNumber = originalString?.substr(0, 15);
                    setFieldValue('user_name', getphoneNumber);
                    setFieldValue('country_code', 91);
                  }
                  if (getPlusSign == '+' && getCountryCode != '+91') {
                    setShowDisabled(true);
                  } else {
                    setShowDisabled(false);
                  }
                } else {
                  setFieldValue('user_name', originalString || '');
                  setFieldValue('country_code', 0);
                  setShowDisabled(false);
                }
              } else {
                setShowEmail(false);
              }
            }
          }}
          className="material-form__field"
          error={emailText ? '' : touched[field.name] && errors[field.name]}
          helperText={emailText ? '' : touched[field.name] && errors[field.name] ? errors[field.name] : ''}
          // {...field}
          {...rest}
        />
      ) : (
        <TextField
          allowBlank={true}
          onWheel={(event) => {
            event.target.blur();
          }}
          className="material-form__field"
          error={touched[field.name] && errors[field.name]}
          helperText={touched[field.name] && errors[field.name] ? errors[field.name] : ''}
          {...field}
          {...rest}
        />
      )}
    </>
  );
};

export default FormikMaterialTextField;
