import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
import { useSelector, useDispatch } from 'react-redux';
import LogoLoader from 'common/LogoLoader';
import ReferenceDataSelect from './../components/ReferenceDataSelect';
import ReferenceDataSelectforTransport from './../components/ReferenceDataSelectTransportDetails'
import FormikCompanySelect from 'containers/Account/Profile/components/FormikCompanySelect';

import CompanyLocationSelect from './CompanyLocationSelect';
import CompanySelect from 'pages/commodity/components/CompanySelect';
import FarmLocationSelect from './FarmLocationSelect';

import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as handlers from './handlers';

const getInitialValues = (transportDetails = {}) => {
  // console.log('transportDetails in initial', transportDetails);

  let address_id = _.get(transportDetails, 'base_location_id') || 0;
  let formFields = {
    transport_detail_id: _.get(transportDetails, 'transport_detail_id') || 0,
    entity_id: _.get(transportDetails, 'entity_id') || 0,

    base_location_id: address_id,

    vehicle_type_enum: _.get(transportDetails, 'vehicle_type_enum') || '',
    goods_type_enum: _.get(transportDetails, 'goods_type_enum') || '',
    vehicle_type_id: _.get(transportDetails, 'vehicle_type_id') || 0,
    number_of_vehicles: _.get(transportDetails, 'number_of_vehicles') || '',

    company_affiliate_enum: _.get(transportDetails, 'company_affiliate_enum') || 0,
    base_location_name: _.get(transportDetails, 'base_location_name') || '',

    entity_name: _.get(transportDetails, 'entity_name') || '',
    person_id: _.get(window, 'user.userid') || 0,

    truck_type_enum: _.get(transportDetails, 'truck_type_enum') || 0,
    owned_rented_enum: _.get(transportDetails, 'owned_rented_enum') || 0,
  };

  return formFields;
};

const TransportForm = (props) => {
  // console.log('propsprops', props);
  const user = useSelector((state) => state.user);
  const acl = utils.getACL(user);
  const transport_detail_id = props.transport_detail_id || 0;
  const id = props.id || 0;
  const transports = useSelector((state) => state.transports);
  const transportDetails = _.get(transports, `entities[${transport_detail_id}]`) || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const uType = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  const isProprietor = _.get(user, 'profile.person.is_proprietor') || false;
  const addressId = _.get(user, 'profile.person_adress.person_address_id') || 0;
  const addressText = _.get(user, 'profile.person_adress.address_text') || '';

  if (transport_detail_id && _.isEmpty(transportDetails)) {
    return (
      <div className="bfc-modalform">
        <div className="bfc-modalform-header">
          <div className="icons-wrapper">
            <img src={Bidicon} alt="" />
            <span className="title">Transport Details</span>
          </div>
          <div className="close-wrapper">
            <a href="" className="d-none">
              -
            </a>
            <Link to={getUrlWithEncryptedQueryParams(`/account/${id}/transport`)}>X</Link>
          </div>
        </div>
        <div className="modalform-body">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(transportDetails)}
        onSubmit={transport_detail_id ? handlers.handleUpdate : handlers.handleCreate}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          if (uType === 2 && isProprietor === false) {
            values['base_location_name'] = addressText;
            values['address_id'] = addressId;
            values['base_location_id'] = addressId;
          }

          return (
            <div>
              {/* <div className="bfc-modalform-header">
                <div className="icons-wrapper">
                  <img src={Bidicon} alt="" />
                  <span className="title">Transport Details</span>
                </div>
                <div className="close-wrapper">
                  <a href="" className="d-none">
                    -
                  </a>
                  <Link to={`/account/${id}/transport`}>X</Link>
                </div>
              </div> */}
              <div>
                <Form className="form-container">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      {acl.isCompany && (
                        <React.Fragment>
                          <div className="row form__form-group">
                            <div className="col">
                              <span className="form-group-label">Business Entity</span>
                              <div className="form-group-field">
                                <div className="form-group-field">
                                  <Field
                                    name="entity_id"
                                    component={CompanySelect}
                                    placeholder="Select Company"
                                    checkKYCStatus={false}
                                    initialDisplayText={values.entity_name || ''}
                                    cb={(newVal) => {
                                      let type = _.get(newVal, 'value.type') || '';
                                      let entity_id = _.get(newVal, 'value.id') || 0;
                                      if (type === 'company') {
                                        setFieldValue('company_affiliate_enum', 1);
                                      } else if (type === 'affiliate') {
                                        setFieldValue('company_affiliate_enum', 2);
                                      }

                                      if (entity_id) {
                                        setFieldValue('entity_id', entity_id);
                                        setFieldValue('address_id', 0);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {/* <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Base Location</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            {/* <Field name="base_location_name" component={FormikMaterialTextField} />
                            <Field
                              name="address_id"
                              component={acl.isCompany ? CompanyLocationSelect : FarmLocationSelect}
                              placeholder="Select Location"
                              disabled={acl.isCompany ? !values.entity_id : false}
                              entity_id={values.entity_id || 0}
                              company_affiliate_enum={values.company_affiliate_enum || 0}
                              initialDisplayText={values.base_location_name || 0}
                              cb={(newVal) => {
                                let addressId = _.get(newVal, 'value.id') || 0;
                                let addressText = _.get(newVal, 'value.address_text') || 0;
                                setFieldValue('address_id', addressId);
                                setFieldValue('base_location_id', addressId);
                                setFieldValue('base_location_name',addressText);
                              }}
                            />
                          </div>
                        </div>
                      </div> */}
                      {uType === 2 && isProprietor === false ? (
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">Base Location</span>
                            <span class="required">*</span>
                            <div className="form-group-field">
                              <Field name="base_location_name" component={FormikMaterialTextField} disabled={true} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">Base Location</span>
                            <span class="required">*</span>
                            <div className="form-group-field">
                              {/* <Field name="base_location_name" component={FormikMaterialTextField} /> */}
                              <Field
                                name="address_id"
                                component={acl.isCompany ? CompanyLocationSelect : FarmLocationSelect}
                                placeholder="Select Location"
                                disabled={acl.isCompany ? !values.entity_id : false}
                                entity_id={values.entity_id || 0}
                                company_affiliate_enum={values.company_affiliate_enum || 0}
                                initialDisplayText={values.base_location_name || 0}
                                cb={(newVal) => {
                                  let addressId = _.get(newVal, 'value.id') || 0;
                                  let addressText = _.get(newVal, 'value.address_text') || 0;
                                  setFieldValue('address_id', addressId);
                                  setFieldValue('base_location_id', addressId);
                                  setFieldValue('base_location_name', addressText);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Vehicle Type</span>
                            <span class="required">*</span>
                            <Field
                              name="vehicle_type_enum"
                              component={ReferenceDataSelect}
                              entity="vehicleTypeEnum"
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      {values.vehicle_type_enum == 1 && (
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">Truck Type</span>
                            <div className="form-group-field">
                              <Field
                                name="truck_type_enum"
                                component={ReferenceDataSelect}
                                entity="truckTypeEnum"
                                size="small"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Owned or Rented Type</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="owned_rented_enum"
                              component={ReferenceDataSelect}
                              entity="ownedRentedEnum"
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Goods Type</span>
                            <span class="required">*</span>
                            <Field
                              name="goods_type_enum"
                              component={ReferenceDataSelect}
                              entity="goodsTypeEnum"
                              size="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Rated Capacity</span>
                            <span class="required">*</span>
                            <Field
                              name={`vehicle_type_id`}
                              component={ReferenceDataSelectforTransport}
                              entity="vehicleType"
                              size="small"
                              entityId={values.vehicle_type_enum == 2 ? 45 : 1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <div className="form-group-field">
                            <span className="form-group-label">Number Of Vehicles</span>
                            <span class="required">*</span>
                            <Field type="number" name="number_of_vehicles" component={FormikMaterialTextField} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      {transport_detail_id ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default TransportForm;
