import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { userService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'notificationsTopbar',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
    notificationCount: 0,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      state.busy = action.payload;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload.notificationCount;
    },
    setNewNotificationCount(state, action) {
      state.notificationCount = state.notificationCount + action.payload.notificationCount;
    },
  },
});

const fetchNotification =
  (params = {}) =>
  async (dispatch) => {
    dispatch(slice.actions.setBusy(true));
    try {
      const resData = await userService.fetchAllNotifications(params);
      dispatch(slice.actions.setAll(resData));
    } catch (err) {
      dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setBusy(false));
  };

const updateNotificationCount = (count) => async (dispatch) => {
  try {
    dispatch(
      slice.actions.setNotificationCount({
        notificationCount: count,
      }),
    );
  } catch (err) {
    dispatch(
      slice.actions.setNotificationCount({
        notificationCount: 0,
      }),
    );
    utils.displayErrors(err);
  }
};

const updateNewNotificationCount = (count) => async (dispatch) => {
  try {
    dispatch(
      slice.actions.setNewNotificationCount({
        notificationCount: count,
      }),
    );
  } catch (err) {
    dispatch(
      slice.actions.setNewNotificationCount({
        notificationCount: 0,
      }),
    );
    utils.displayErrors(err);
  }
};

const actions = {
  ...slice.actions,
  updateNotificationCount,
  fetchNotification,
  updateNewNotificationCount,
};

const selectors = {
  ...adapter.getSelectors((state) => state.notificationsTopbar),
};

export { actions, selectors };

export default slice.reducer;
