import React from 'react';
import { Popover } from 'antd';

function SupplierPopover({ title_one, imageSrc, imageSrc80, imageSrc50, imageSrc55, imageSrcQualityCertification }) {
  let zIndexPopup = '111111';

  const Content = ({ title_one }) => (
    <div>
      <p>{title_one}</p>
    </div>
  );

  return (
    <>
      <Popover
        overlayStyle={{ zIndex: zIndexPopup }}
        content={<Content title_one={title_one} />}>
        {imageSrc && (
          <>
            <img className="cursor-pointer" src={imageSrc} alt={imageSrc} height="20" width="20" />
            <br />
          </>
        )}
        {imageSrc80 && (
          <>
            <img
              className="cursor-pointer"
              src={imageSrc80}
              alt={imageSrc80}
              style={{ height: '70px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}
            />
            <br />
          </>
        )}
        {imageSrc50 && (
          <>
            <img
              className="cursor-pointer"
              src={imageSrc50}
              alt={imageSrc50}
              style={{ height: '50px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}
            />
            <br />
          </>
        )}
        {imageSrc55 && (
          <>
            <img
              className="cursor-pointer"
              src={imageSrc55}
              alt={imageSrc55}
              style={{ height: '55px', width: '100%', objectFit: 'cover', borderRadius: '50px' }}
            />
            <br />
          </>
        )}

        {imageSrcQualityCertification && (
          <>
            <img
              className="cursor-pointer"
              src={imageSrcQualityCertification}
              alt={imageSrcQualityCertification}
              style={{ height: '40px', width: '100%', position: 'relative', bottom: 5, left: 5 }}
            />
            <br />
          </>
        )}
      </Popover>
    </>
  );
}

export default SupplierPopover;
