import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const fetchPickupActivityDetails = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/FetchPickupActivityDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchPickupActivityDetails');
    throw errors;
  }
};

export const saveAndUpdatePickupActivity = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/SaveAndUpdatePickupActivity`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdatePickupActivity');
    throw errors;
  }
};

export const saveAndUpdatePickupActivityForDashboard = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/SaveAndUpdatePickupActivityForDashboard`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdatePickupActivityForDashboard');
    throw errors;
  }
};
