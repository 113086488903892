/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classnames from 'classnames';

class CheckBoxField extends PureComponent {
  // componentDidMount() {
  //   const { onChange, defaultChecked } = this.props;
  //   onChange(defaultChecked);
  // }

  render() {
    const { disabled, className, name, value, onChange, label, color } = this.props;

    // let error = false
    const CheckboxClass = classnames({
      'checkbox-btn': true,
      disabled,
      // 'bfc-has-error' : touched && error
    });

    const id = `${name}-${Date.now()}`;

    return (
      <label className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`} htmlFor={id}>
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={id}
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        <span className="checkbox-btn__checkbox-custom" style={color ? { background: color, borderColor: color } : {}}>
          <CheckIcon />
        </span>
        {className === 'button' ? (
          <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span>
        ) : (
          ''
        )}
        <span className="checkbox-btn__label">{label}</span>
      </label>
    );
  }
}

const FormikCheckBox = (props) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  let errClass = errors[field.name] ? 'bfc-has-error' : '';

  return (
    <div className={errClass}>
      <CheckBoxField
        {...field}
        {...rest}
        // meta={meta}
      />
      {/*touched && error && (<span className="bfc-error">
          {error}
        </span>)*/}
    </div>
  );
};

export default FormikCheckBox;
