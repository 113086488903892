import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import { postService } from 'services';

const adapter = createEntityAdapter({
  sortComparer: (a, b) => (a.ts === b.ts ? 0 : a.ts < b.ts ? 1 : -1),
});

const slice = createSlice({
  name: 'searchPosts',
  initialState: adapter.getInitialState({
    busy: false,
    hasMore: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      state.busy = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
  },
});

const fetchSearchPost = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await postService.searchPost(params);
    if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
      dispatch(slice.actions.setHasMore(false));
    }
    dispatch(slice.actions.upsertMany(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};
const fetchSearchDeals = (params = {}) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await postService.searchPost(params);
    if (_.isEmpty(resData) || Object.keys(resData).length < 10) {
      dispatch(slice.actions.setHasMore(false));
    }
    dispatch(slice.actions.upsertMany(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchSearchPost,
  fetchSearchDeals,
};

const selectors = {
  ...adapter.getSelectors((state) => state.searchPosts),
};

export { actions, selectors };

export default slice.reducer;
