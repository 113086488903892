import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  updateNotificationRecord(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
};

export const resTransform = {
  updateNotificationRecord(resData) {
    return resData;
  },
};
