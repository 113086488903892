import { createAction, createSelector, createEntityAdapter, createSlice, current } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { multiPartyAuctionService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'multipartyAuctions',
  initialState: adapter.getInitialState({
    busy: false,
    auctionDetails: [],
    bidOfferList: [],
    bidOfferDetails: [],
    bidOfferWatchDetails: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setAuctionDetails: (state, { payload }) => ({ ...state, auctionDetails: payload }),
    setBidOfferList: (state, { payload }) => ({ ...state, bidOfferList: payload }),
    setBidOfferDetails: (state, { payload }) => ({ ...state, bidOfferDetails: payload }),
    setBidOfferWatchDetails(state, { payload }) {
      let existingData = state.bidOfferWatchDetails || [];
      let data = [...existingData];
      data = data.filter((item) => item.id !== payload.id);
      data.push(payload);
      state.bidOfferWatchDetails = data;
    },
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const FetchAllMultipartyAuctions = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.FetchAllMultipartyAuctions(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const FetchMultipartyAuctionOverview = (id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.FetchMultipartyAuctionOverview(id);
    dispatch(slice.actions.addOne(resData));
  } catch (err) {
    dispatch(slice.actions.addOne({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMultipartyLandingPagePositions = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.fetchMultipartyLandingPagePositions(params);
    dispatch(slice.actions.setBidOfferList(resData));
  } catch (err) {
    // dispatch(slice.actions.setBidOfferList([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMultipartyLandingPagePositionsMetadata = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.fetchMultipartyLandingPagePositionsMetadata(params);
    dispatch(slice.actions.setAuctionDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAuctionDetails([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchBidOffersAgainstBuySell = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.fetchBidOffersAgainstBuySell(params);
    dispatch(slice.actions.setBidOfferWatchDetails({ id: params?.commodity_req_offer_multiparty_auction_id, resData }));
  } catch (err) {
    dispatch(slice.actions.setBidOfferWatchDetails([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const pinUnpinBuySellBidOffer = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.pinUnpinBuySellBidOffer(params);
    if (resData) {
      notifications.show({
        type: 'success',
        message: params?.is_pinned ? 'Pinned successfully' : 'UnPinned successfully',
      });
    }
    // dispatch(slice.actions.setBidOfferDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setBidOfferDetails([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const withdrawBidOffer = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: 'Withdrawing Bid/Offer' });
    const resData = await multiPartyAuctionService.withdrawBidOffer(params);
    if (resData) {
      notifications.update({ type: 'success', message: 'Bid/Offer withdrawn successfully' });
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const withdrawMultipartyAuctionBuySell = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: 'Withdrawing Buy/Sell' });
    const resData = await multiPartyAuctionService.withdrawMultipartyAuctionBuySell(params);
    if (resData) {
      notifications.update({ type: 'success', message: 'Buy/Sell withdrawn successfully' });
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const counterBidOffer = (params, type) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: `Countering ${type}` });
    const resData = await multiPartyAuctionService.counterBidOffer(params);
    if (resData) {
      notifications.update({ type: 'success', message: `${type} countered successfully` });
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdateBidOffer = (params, type) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: `Submitting ${type}` });
    const resData = await multiPartyAuctionService.saveAndUpdateBidOffer(params);
    if (resData) {
      notifications.update({
        type: 'success',
        message: params?.operation_Enum === 3 ? `${type} Updated successfully` : `${type} submitted successfully`,
      });
    }

    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const acceptMultipartyOfferBid = (params, type) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: `Accepting ${type}` });
    const resData = await multiPartyAuctionService.acceptMultipartyOfferBid(params);
    if (resData) {
      notifications.update({ type: 'success', message: `${type} accepted successfully` });
    }
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const rejectBidOffer = (params, type) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    notifications.show({ type: 'info', message: `Rejecting ${type}` });
    const resData = await multiPartyAuctionService.rejectBidOffer(params);
    if (resData) {
      notifications.update({ type: 'success', message: ` ${type} rejected successfully` });
    }

    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMultipartyLandingPagePositionsSpectator = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.fetchMultipartyLandingPagePositionsSpectator(params);
    dispatch(slice.actions.setBidOfferList(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMultipartyLandingPagePositionsCT = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.fetchMultipartyLandingPagePositionsCT(params);
    dispatch(slice.actions.setBidOfferList(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const FetchRivals = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await multiPartyAuctionService.FetchRivals(params);
    dispatch(slice.actions.setBidOfferDetails(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setBidOfferDetails([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

// const DeleteMyBuySells = (params) => async (dispatch) => {
//   dispatch(slice.actions.setBusy(true));
//   try {
//     const resData = await multiPartyAuctionService.DeleteMyBuySells(params);
//     dispatch(slice.actions.setBusy(false));
//     return resData;
//   } catch (err) {
//     dispatch(slice.actions.setBidOfferDetails([]));
//     utils.displayErrors(err);
//   }
//   dispatch(slice.actions.setBusy(false));
// };

const actions = {
  ...slice.actions,
  FetchAllMultipartyAuctions,
  FetchMultipartyAuctionOverview,
  fetchMultipartyLandingPagePositions,
  fetchMultipartyLandingPagePositionsMetadata,
  fetchBidOffersAgainstBuySell,
  pinUnpinBuySellBidOffer,
  withdrawBidOffer,
  counterBidOffer,
  saveAndUpdateBidOffer,
  acceptMultipartyOfferBid,
  withdrawMultipartyAuctionBuySell,
  rejectBidOffer,
  fetchMultipartyLandingPagePositionsSpectator,
  fetchMultipartyLandingPagePositionsCT,
  FetchRivals,
  // DeleteMyBuySells,
};

const selectors = {
  ...adapter.getSelectors((state) => state.multipartyAuctions),
};

export { actions, selectors };

export default slice.reducer;
