import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';
import { util } from 'prettier';

export const reqTransform = {
  create(params = {}) {
    const formData = new FormData();
    // formData.append("person_id", 4);
    const Social_Post_Type_Ids_Array = params?.Social_Post_Type_Ids;
    const Social_Post_Type_Ids_Data = Social_Post_Type_Ids_Array?.join('');
    //TODO : tmp - removing html tags
    // params.text = params.text.replace(/(<([^>]+)>)/gi, '');

    // params.text = params.text.replace(/'/gi, '');
    // params.text = params.text.replace(/"/gi, '');
    // return;
    formData.append('Post_MetaData[post_Caption]', (params.text || '').trim());
    formData.append('Post_MetaData[posted_by_person_id]', params.person_id);
    formData.append('Post_MetaData[posted_by_company_id]', params.company_id);
    formData.append('Post_MetaData[posted_by_company_affiliate_id]', params.affiliate_id);
    formData.append('Post_MetaData[post_classification_enum]', 1);
    formData.append('pdf_Doc', params?.pdf_Doc?.[0] || []);
    formData.append('PDF_Doc_Name', params?.pdf_Doc?.[0]?.name || '');

    if (params?.bf_social_group_id) {
      formData.append('bf_social_group_id', params?.bf_social_group_id);
    }

    if (params?.pdf_Doc?.length || 0) {
      formData.append('Post_Type_Enum', 5);
    } else {
      formData.append('Post_Type_Enum', 1);
    }
    formData.append('Post_Category', 1);
    // formData.append('HashTag_Ids[0]', 1);
    // formData.append('HashTag_Ids[1]', 2);

    for (let i = 0; i < params.hashtag.length; i += 1) {
      formData.append('HashTag_Ids', params.hashtag[i]);
    }
    for (let i = 0; i < params.images.length; i += 1) {
      formData.append('Request_Images', params.images[i]);
    }
    for (let i = 0; i < Social_Post_Type_Ids_Data.length; i += 1) {
      formData.append('Post_MetaData[Social_Post_Type_Ids]', Social_Post_Type_Ids_Data[i]);
    }
    return formData;
  },

  sharePost(params = {}) {
    let reqParams;
    // let postedByUserId = params.person_id;
    // if (params.company_id !== undefined) {
    //   postedByUserId = params.company_id;
    // }
    // const Social_Post_Type_Ids_Array = params?.Social_Post_Type_Ids;
    // const Social_Post_Type_Ids_Data = Social_Post_Type_Ids_Array?.join("");
    let Social_Post_Type_Ids_Array = params.Social_Post_Type_Ids?.map((ele) => Number(ele));

    if (params.company_id) {
      reqParams = {
        post_caption: params.post_caption,
        post_id: params.post_id,
        reposted_by_person_id: null,
        reposted_by_affiliate_id: null,
        reposted_by_company_id: params.company_id,
        Social_Post_Type_Ids: Social_Post_Type_Ids_Array,
      };
    } else if (params.affiliate_id) {
      reqParams = {
        post_caption: params.post_caption,
        post_id: params.post_id,
        reposted_by_person_id: null,
        reposted_by_company_id: null,
        reposted_by_affiliate_id: params.affiliate_id,
        Social_Post_Type_Ids: Social_Post_Type_Ids_Array,
      };
    } else {
      reqParams = {
        post_caption: params.post_caption,
        post_id: params.post_id,
        reposted_by_person_id: params.person_id,
        reposted_by_company_id: null,
        reposted_by_affiliate_id: null,
        Social_Post_Type_Ids: Social_Post_Type_Ids_Array,
      };
    }

    let neWreqParams;
    neWreqParams = {
      ...reqParams,
      HashTag_Ids: params?.HashTag_Ids || [],
    };

    return neWreqParams;
  },

  update(params = {}) {
    const formData = new FormData();

    const Social_Post_Type_Ids_Array = params?.Social_Post_Type_Ids;
    const Social_Post_Type_Ids_Data = Social_Post_Type_Ids_Array?.join('');

    //TODO : tmp - removing html tags
    // params.text = params.text.replace(/(<([^>]+)>)/gi, '');

    // params.text = params.text.replace(/'/gi, '');
    // params.text = params.text.replace(/"/gi, '');

    formData.append('Post_MetaData[Caption]', (params.text || '').trim());
    formData.append('Post_MetaData[Person_Id]', params.userid);
    formData.append('Post_MetaData[Post_Id]', params.post_id);
    formData.append('Post_MetaData[Company_Id]', params.company_id);
    formData.append('Post_MetaData[Company_Affiliate_Id]', params.affiliate_id);
    // formData.append('Post_MetaData[Post_Images]', params.images);
    formData.append('Post_MetaData[Existing_Cover_Images_Count]', 0);
    formData.append('Post_MetaData[Post_Hashtags]', '[]');
    formData.append('Post_MetaData[Existing_Post_Images_Count]', 0);
    formData.append('Post_MetaData[Existing_Hashtag_Count]', 0);
    formData.append('Request_PDF', params?.pdf_Doc?.[0] || params?.pdf_Doc || '');
    formData.append('Request_PDF_Name', params?.pdf_Doc?.name || params?.pdf_Doc?.attachment_file_name || '');

    for (let i = 0; i < Social_Post_Type_Ids_Data.length; i += 1) {
      formData.append('Post_MetaData[Social_Post_Type_Ids]', Social_Post_Type_Ids_Data[i]);
    }

    for (let i = 0; i < params?.HashTag_Ids.length; i += 1) {
      formData.append('HashTag_Ids', params.HashTag_Ids[i]);
    }

    if (params?.pdf_Doc?.length) {
      const post_pdf = [];
      const addPdfParams = {
        attachment_Id: 0,
        PDF_Url: params?.pdf_Doc[0].name ? params?.pdf_Doc[0].name : params?.pdf_Doc[0].attachment_file_name,
        image_type_enum: 4,
        is_added: 1,
        is_deleted: 0,
      };
      const deletePdfParams = {
        attachment_Id: params?.post_pdf[0].attachment_id,
        PDF_Url: params?.post_pdf[0].attachment_path,
        image_type_enum: 4,
        is_added: 0,
        is_deleted: 1,
      };
      post_pdf.push(addPdfParams);
      post_pdf.push(deletePdfParams);
      formData.append('Post_MetaData[Post_PDF]', JSON.stringify(post_pdf || []));
    }

    let Post_Images = [];
    for (let image of params.images) {
      // Post_Images = params.postimage
      let imgObj = {
        post_Image_Id: 0,
        image_url: image.name,
        image_Type_Enum: 1,
        is_Added: 1,
        is_Deleted: 0,
      };
      if (image.is_deleted == 1) {
        imgObj = {
          post_Image_Id: image.post_image_id,
          image_url: image.image_url,
          image_Type_Enum: 1,
          is_Added: 0,
          is_Deleted: 1,
        };
      }
      Post_Images.push(imgObj);
      // Post_Images.pop(delImgObj);
      formData.append('Request_Images', image);
    }

    formData.append('Post_MetaData[Post_Images]', JSON.stringify(Post_Images || []));

    // let pdfArray = [];
    // for (let pdf of params.pdf_Doc) {
    //   // Post_Images = params.postimage
    //   let imgObj = {
    //     post_Image_Id: 0,
    //     image_url: pdf.name,
    //     image_Type_Enum: 4,
    //     is_Added: 1,
    //     is_Deleted: 0,
    //   };
    //   if (pdf.is_deleted == 1) {
    //     imgObj = {
    //       post_Image_Id: pdf.post_image_id,
    //       image_url: pdf.name,
    //       image_Type_Enum: 4,
    //       is_Added: 0,
    //       is_Deleted: 1,
    //     };
    //   } else {
    //     formData.append('PDF_Doc_Name', pdf?.name || '');
    //   }
    //   pdfArray.push(imgObj);
    //   // Post_Images.pop(delImgObj);
    //   formData.append('pdf_Doc', pdf);
    // }

    // formData.append('Post_MetaData[Post_PDF]', JSON.stringify(pdfArray || []));

    return formData;
  },
  destroy(post_id) {
    const reqParams = {
      post_id: post_id,
      deleting_person_id: _.get(globalThis, 'user.userid', 0),
      reason: 'Deleted',
    };

    return reqParams;
  },
  savePost(post_id) {
    const reqParams = {
      post_id: post_id,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return reqParams;
  },
  fetchReportedPosts(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      ...params,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return utils.getQs(reqParams);
  },
  deleteReportedPost(params = {}) {
    const reqParams = {
      entity_id: params['post_id'],
      deleting_reasoning: params['reason'],
      post_or_comment_type_enum: 1,
      is_safe: false,
      deleting_person_id: _.get(globalThis, 'user.userid', 0),
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  markPostAsSafe(params = {}) {
    const reqParams = {
      entity_id: params['post_id'],
      deleting_reasoning: '',
      post_or_comment_type_enum: 1,
      is_safe: true,
      deleting_person_id: _.get(globalThis, 'user.userid', 0),
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  fetchReportedComments(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      ...params,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return utils.getQs(reqParams);
  },
  deleteReportedComment(params = {}) {
    const reqParams = {
      entity_id: params['comment_id'],
      deleting_reasoning: params['reason'],
      post_or_comment_type_enum: 2,
      is_safe: false,
      deleting_person_id: _.get(globalThis, 'user.userid', 0),
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  markCommentAsSafe(params = {}) {
    const reqParams = {
      entity_id: params['comment_id'],
      deleting_reasoning: '',
      post_or_comment_type_enum: 2,
      is_safe: true,
      deleting_person_id: _.get(globalThis, 'user.userid', 0),
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  fetchAllFeed(params = {}) {
    // const reqParams = {
    //   offset: 0,
    //   page_size: 100,
    //   ...params,
    //   person_id: _.get(globalThis, 'user.userid', 0),
    // };
    const reqParams = {
      ...params,
      person_id: _.get(globalThis, 'user.userid', 0),
    };
    return utils.getQs(reqParams);
  },
  fetchAllFeedWithFilters(params = {}) {
    const reqParams = {
      ...params,
    };
    return reqParams;
  },
  fetchAllCTFeed(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      isspecificposttype: false,
      ...params,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return helpers.getQueryParams(reqParams);
  },
  fetchPostComments(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      post_or_comment_type_enum: 1,
      ...params,
      entity_id: params['post_id'] || 0,
      person_id: _.get(globalThis, 'user.userid', 0),
    };
    // return reqParams;
    return utils.getQs(reqParams);
  },
  createPostComment(params = {}) {
    const reqParams = {
      comment: params['comment'],
      entity_id: parseInt(params['post_id']) || 0,
      post_or_comment_type_enum: 1,
      comment_person_id: params['comment_person_id'] ? params['comment_person_id'] : 0,
      comment_company_id: params['comment_company_id'] ? params['comment_company_id'] : 0,
      comment_affiliate_id: params['comment_affiliate_id'] ? params['comment_affiliate_id'] : 0,
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  editSavedComment(params = {}) {
    const reqParams = {
      comment_id: parseInt(params['comment_id']) || 0,
      comment: params['comment'],
      comment_person_id: params['comment_person_id'] ? params['comment_person_id'] : 0,
      comment_company_id: params['comment_company_id'] ? params['comment_company_id'] : 0,
      comment_affiliate_id: params['comment_affiliate_id'] ? params['comment_affiliate_id'] : 0,
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  fetchTimelinePost(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      ...params,
      // invoking_person_id: _.get(globalThis, 'user.userid', 0),
    };

    return utils.getQs(reqParams);
  },
  fetchSavedPosts(params = {}) {
    const reqParams = {
      offset: 0,
      page_size: 100,
      ...params,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return utils.getQs(reqParams);
  },
  deleteSavedPost(post_id) {
    const reqParams = {
      post_id: post_id,
      person_id: _.get(globalThis, 'user.userid', 0),
    };

    return utils.getQs(reqParams);
  },

  deleteSavedComment(params) {
    const reqParams = {
      comment_id: params.comment_id,
      person_id: params.person_id,
    };

    return utils.getQs(reqParams);
  },

  reportPostOrComment(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },

  followUnfollowUser(pid, isFollow) {
    let bodyParams = {
      to_person_id: _.get(window, 'user.userid', 0),
      to_person_id: pid,
      follow: isFollow,
    };
    return bodyParams;
  },

  searchPost(params) {
    const bodyParams = {
      ...params,
    };

    return bodyParams;
  },

  fetchUserFollowersFollowings(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },

  fetchUserLikedPostList(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },

  fetchUserMention(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },

  fetchPostDetails(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },
  fetchBfcFeedPostList(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },
  fetchSharePostDetails(params) {
    let qParams = {
      ...params,
    };
    return utils.getQs(qParams);
  },
};

export const resTransform = {
  create(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  sharePost(resData) {
    return resData;
  },
  update(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  destroy(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  savePost(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  fetchReportedPosts(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  deleteReportedPost(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  markPostAsSafe(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  fetchReportedComments(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  deleteReportedComment(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  markCommentAsSafe(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  fetchAllFeed(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchAllFeedWithFilters(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchAllCTFeed(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchPostComments(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  createPostComment(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
  editSavedComment(resData) {
    return resData;
  },
  fetchTimelinePost(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },

  fetchSavedPosts(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  deleteSavedPost(resData) {
    return resData;
  },
  deleteSavedComment(resData) {
    return resData;
  },
  reportPostOrComment(resData) {
    return resData;
  },
  followUnfollowUser(resData) {
    return resData;
  },
  fetchUserFollowersFollowings(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUserFollowersFollowingsDetails(details);
    });
    if (data.every((item) => item?.followings_id !== 0)) {
      return _.keyBy(data, 'followings_id');
    } else {
      return _.keyBy(data, 'entity_id');
    }
  },
  fetchUserFollowersFollowingsDetails(resData) {
    let res = {
      ...resData,
      id: resData?.followings_id !== 0 ? _.get(resData, 'followings_id') : _.get(resData, 'entity_id'),
    };
    return res;
  },

  fetchUserLikedPostList(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUserLikedPostListDetails(details);
    });
    return _.keyBy(data, 'person_id');
  },
  fetchUserLikedPostListDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'person_id'),
    };
    return res;
  },

  fetchUserMention(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUserMentionDetails(details);
    });
    return data;
    // return _.keyBy(data, 'id');
  },
  fetchUserMentionDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'entity_id'),
      // text: _.get(resData, 'entity_name'),
      // value:_.get(resData, 'entity_name'),
      // url: `/social/profile/`+_.get(resData, 'social_profile_id'),
    };
    return res;
  },

  fetchPostDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'post_id'),
    };
    return res;
  },
  fetchBfcFeedPostList(resData) {
    let data = resData;
    if (!Array.isArray(data)) {
      data = [];
    }

    data = data.map((d) => {
      let item = {
        id: d.post_id || 0,
        ts: utils.getUnixTs(d.created_datetime),
        ...(d || {}),
      };
      return item;
    });

    return _.keyBy(data, 'id');
  },
  fetchSharePostDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'post_id'),
    };
    return res;
  },
};
