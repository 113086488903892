import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import normalize from 'normalize-object';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] !== _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let data = resData;
    if (!_.isArray(resData)) {
      data = [];
    }
    data = data.map((details) => {
      let newDetails = {
        ...details,
        id: details.value_member,
      };
      return newDetails;
    });
    return _.keyBy(data, 'id');
  },
};
