import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Button } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ThumbUpOutlineIcon from 'mdi-react/ThumbUpOutlineIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { actions as alertActions } from 'redux/alert';
import notifications from 'lib/notifications';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

const AlertComponent = (props) => {
  const { className, icon, alert, customMessage } = props;
  const { type, message, visible } = alert;

  const [collapse, setCollapse] = useState(true);

  const onDismiss = () => {
    setCollapse(false);
  };

  let Icon;

  useEffect(() => {
    let nOpts = {
      type: type,
      message: message,
    };

    if (visible) {
      if (nOpts['type'] == 'warning') {
        nOpts['type'] = 'error';
      }

      notifications.reset();
      setTimeout(() => {
        notifications.show(nOpts);
      }, 100);
    } else {
      notifications.reset();
    }
  }, [visible, type]);

  // return null;

  switch (type) {
    case 'info':
      Icon = <InformationOutlineIcon />;
      break;
    case 'success':
      Icon = <ThumbUpOutlineIcon />;
      break;
    case 'warning':
      Icon = <CommentAlertOutlineIcon />;
      break;
    case 'danger':
      Icon = <CloseCircleOutlineIcon />;
      break;
    default:
      console.log('Wrong type!');
      break;
  }
  if (type === 'warning') {
    title = 'warning';
  } else if (type === 'danger') {
    title = 'Error';
  } else if (type === 'warning') {
    title = 'Warning';
  }

  if (collapse || visible) {
    return (
      <Alert
        style={{ position: 'fixed', width: '100%' }}
        color="danger"
        className={className}
        isOpen={collapse || visible}
        toggle={onDismiss}
      >
        {icon && <div className="alert__icon">{Icon}</div>}
        <div className="alert__content">
          <p className="text-center">
            <span className="bold-text">{customMessage}</span>
          </p>
        </div>
      </Alert>
    );
  }

  return null;
};

// export default AlertComponent;

AlertComponent.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  // children: PropTypes.element.isRequired,
};

AlertComponent.defaultProps = {
  type: '',
  icon: false,
  className: '',
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertActions: bindActionCreators(alertActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponent);
