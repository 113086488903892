import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchDetails = async (params) => {
  try {
    const qsParams = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/FetchFinanceProfile?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-FetchFinanceProfile-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchFinanceProfile');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/SaveAndUpdateFinanceProfile`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateFinanceProfile-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateFinanceProfile');
    throw errors;
  }
};

export const fetchList = async (params) => {
  try {
    const qsParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/FetchInstalledCapcities?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchInstalledCapcities-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchInstalledCapcities');
    throw errors;
  }
};

export const createInstalledCapacity = async (params) => {
  try {
    const bodyParams = reqTransform.createInstalledCapacity(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/SaveAndUpdateInstalledCapacity`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createInstalledCapacity(resData);
  } catch (err) {
    console.log('api-SaveAndUpdateInstalledCapacity-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateInstalledCapacity');
    throw errors;
  }
};

export const destroyInstalledCapacity = async (id) => {
  try {
    const qsParams = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelTradeFinance/api/TradeFinance/DeleteInstalledCapacityAgainstBfSetupInstalledCapacityId?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-DeleteInstalledCapacityAgainstBfSetupInstalledCapacityId-destroy->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteInstalledCapacityAgainstBfSetupInstalledCapacityId');
    throw errors;
  }
};
