import _ from 'lodash';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
import LogoLoader from 'common/LogoLoader';
import { actions as kycCommentsActions } from 'redux/kycComments';
import { Formik, Field, Form, FieldArray } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as handlers from '../handlers';
import ReferenceDataSelect from '../../components/ReferenceDataSelect';
import FormikMaterialTextField from '../../components/FormikMaterialTextField';
import utils from 'lib/utils';

const getInitialValues = () => {
  let formFields = {
    notes: '',
  };

  return formFields;
};

const KycCommentsPopUp = ({ close, params, isAdmin, id }) => {
  const dispatch = useDispatch();
  const userId = id;
  const user = useSelector((state) => state.user);
  const [stickToBottom, setStickToBottom] = useState(false);
  const modalFormClass = classNames(['bfc-modalform', 'overflow-hidden', { stickToBottomStyle: stickToBottom }]);

  const kycComments = useSelector((state) => state.kycComments);
  const kycUser = useSelector((state) => state.kycUser);
  const kycCommentsList = _.get(kycComments, 'entities') || {};
  const busy = _.get(kycComments, 'busy') || false;

  let kycStatusEnumCode;
  {
    isAdmin
      ? (kycStatusEnumCode = _.get(kycUser, 'profile.person.kyc_status_enum_code'))
      : (kycStatusEnumCode = _.get(user, 'profile.person.kyc_status_enum_code'));
  }
  const kycIndividual = useSelector((state) => state.kycIndividual);
  const kycDetails = _.get(kycIndividual, 'entities') || {};

  const acceptReject = [
    { title: 'Accept', id: 2 },
    { title: 'Reject', id: 3 },
  ];

  const [dropValues, setdropValues] = useState(0);
  const onTagsChange = (event, dropValues) => {
    setdropValues(dropValues?.id);
  };

  const handleCreateComment = (values, formikBag) => {
    let params1 = {
      ...values,
      kyc_status_enum: dropValues,
      entity_id: userId,
      user_type_enum: 2,
      company_affiliate_enum: 1,
    };
    handlers.handleCreateStatus(params1, formikBag);
  };

  useEffect(() => {
    let commentsParams = {
      ...params,
      person_id: userId,
    };
    if (isAdmin) {
      commentsParams = {
        entity_id: userId,
      };
    }
    dispatch(kycCommentsActions.fetchList(commentsParams));
  }, []);
  return (
    <React.Fragment>
      <Formik initialValues={getInitialValues()} onSubmit={handleCreateComment}>
        {(formikBag) => {
          const { values, setFieldValue } = formikBag;
          return (
            <div>
              <Form className="formik-form">
                <div>
                  {isAdmin == true && (
                    <div>
                      <div>
                        <div className="form-wrapper full-width">
                          <div className="row form__form-group">
                            <div className="col">
                              <span className="form-group-label">Approve/Reject</span>
                              <div className="form-group-field">
                                <Autocomplete
                                  name="approveReject"
                                  options={acceptReject}
                                  getOptionLabel={(option) => option.title}
                                  onChange={onTagsChange}
                                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-wrapper full-width">
                          <div className="row form__form-group">
                            <div className="col">
                              <span className="form-group-label">Notes</span>
                              <div className="form-group-field custom-input">
                                <Field name="notes" className="notes_box" component="textarea" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="btn-wrapper mb-20">
                        <div className="bid-popup-bottom-btn-wrapper ">
                          <Button
                            color="primary"
                            type="button"
                            onClick={formikBag.handleSubmit}
                            size="sm"
                            // disabled={formikBag.isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  {isAdmin == true && !kycStatusEnumCode == 'Pending' && (
                    <div>
                      <span>KYC not submitted by User</span>
                    </div>
                  )}

                  {busy ? (
                    <div
                      className=""
                      style={{
                        position: 'relative',
                        minHeight: '150px',
                      }}>
                      <div className="panel__refresh">
                        <LogoLoader />
                      </div>
                    </div>
                  ) : Object.keys(kycCommentsList).length > 0 ? (
                    <div>
                      {_.map(kycCommentsList, (details, kyc_history_id) => {
                        return (
                          <div key={kyc_history_id} className="card-body chat-care">
                            <ul className="chat">
                              <li className="admin">
                                <div className="chat-body">
                                  <div className="header d-flex">
                                    <strong className="primary-font">
                                      {details.kyc_status_enum_code}{' '}
                                    </strong>
                                    <div className="float-right ml-auto">
                                      <p style={{ fontSize: '.625rem' }} className="text-muted">
                                        {utils.formatDateTime(details.created_datetime)}
                                        <p className="right-subtext">{details.control_tower_person_code}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <p className="chat-msg">{details.note_text}</p>
                                </div>
                              </li>
                            </ul>
                          </div>

                          // <div key={kyc_history_id} className="row form__form-group">
                          //   <div className="col-md-6">{details.kyc_status_enum_code}</div>
                          //   <div className="col-md-6">{utils.formatDateTime(details.created_datetime)}</div>
                          //   <div className="row form__form-group">
                          //     <div className="col-md-7"></div>
                          //     <div className="col-md-5">{details.control_tower_person_code}</div>
                          //   </div>
                          //   <div className="row form__form-group">
                          //     <div className="col-md-2"></div>
                          //     <div className="col-md-8">{details.note_text}</div>
                          //     <div className="col-md-2"></div>
                          //   </div>
                          //   <div
                          //     style={{
                          //       color: 'var(--unnamed-color-707070)',
                          //       backgroundcolor: 'var(--unnamed-color-707070)',
                          //       width: '100%',
                          //       height: '1px',
                          //     }}></div>
                          // </div>
                        );
                      })}
                    </div>
                  ) : (
                    isAdmin == false && <div>{/* <span>KYC {kycStatusEnumCode}</span> */}</div>
                  )}
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default KycCommentsPopUp;
