import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
// import FormikDateTimePickerField from '../../shared/components/form/FormikDateTimePickerField';
import Collapse from './../components/Collapse';
import ReferenceDataSelect from './../components/ReferenceDataSelect';
import VerficationFormModal from './../components/VerficationFormModal';
// import ReferenceDataSelect from 'shared/components/form/ReferenceDataSelect';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './../handlers';
import { actions as userActions } from 'redux/user';
import { actions as companyActions } from 'redux/companies';
import { actions as kycUserActions } from 'redux/kycUser';
import LogoLoader from 'common/LogoLoader';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { PROFILE_TABS_ENUM } from 'constants/enums';
import useQuery from 'lib/hooks/useQuery';
import { TYPE_OF_INDIVIDUAL_ENUM } from 'constants/enums';
import MUITextField from '@material-ui/core/TextField';
import FormikAOISelect from './../components/FormikAOISelect';
import ConfirmDeleteModal from './../components/ConfirmDeleteModal';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import Company from './Company';
import Affiliates from './Affiliates';
import Locations from './Locations';

const Organization = (props) => {
  //optimize this
  const id = parseInt(props.id);
  const { back, islocation } = useQuery();
  const scrollingDivRef = useRef(null);

  const user = useSelector((state) => state.user);
  let type_of_individual_enum = _.get(user, 'profile.person.type_of_individual_enum') || false;
  const kycUser = useSelector((state) => state.kycUser);
  const rolesAndRights = useSelector((state) => state.rolesAndRights);
  const rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  const showOrganisation = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.ORGANISATION),
  );
  const readAccess = _.get(showOrganisation, `read_access`);
  const fullAccess = _.get(showOrganisation, `full_access`);

  const acl = utils.getACL(user);
  const isCompanyUser = acl.isUserCompany;

  let companies = {};
  let companyId = 0;
  let companyData = {};
  let profileUpdatedAt = false;
  let companiesUpdatedAt = false;
  let companyLocations = {};
  let busy;
  if (user.id === id) {
    companies = useSelector((state) => state.companies);
    companyId = _.get(user, 'profile.person.company_id') || 0;
    companyData = _.get(companies, `entities[${companyId}]`) || {};
    profileUpdatedAt = _.get(user, 'profile.updatedAt') || false;
    companiesUpdatedAt = _.get(companies, 'updatedAt') || false;
    companyLocations = companyData.locations || {};
    busy = _.get(companies, 'busy') || false;
  } else if (user.id !== id) {
    companies = useSelector((state) => state.companies);
    companyId = _.get(kycUser, 'profile.person.company_id') || 0;
    companyData = _.get(companies, `entities[${companyId}]`) || {};
    profileUpdatedAt = _.get(kycUser, 'profile.updatedAt') || false;
    companiesUpdatedAt = _.get(companies, 'updatedAt') || false;
    companyLocations = companyData.locations || {};
    busy = _.get(companies, 'busy') || false;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const [deleteFarmId, setDeleteFarmId] = useState(0);

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  let prevLink = `/account/${id}/myaccount`;
  let nextLink = `/account/${id}/teams`;
  if (type_of_individual_enum == TYPE_OF_INDIVIDUAL_ENUM.NBFC_BANKING_INSTITUTE) {
    nextLink = `/account/${id}/kyc-b`;
    prevLink = `/account/${id}/organisations`;
  }
  if(isAdmin){
    type_of_individual_enum = _.get(kycUser, 'profile.person.type_of_individual_enum') || false;
    prevLink = `/account/${id}/myaccount?back=${back}`;
    nextLink = `/account/${id}/teams?back=${back}`;
  }
  const params = {
    person_id: parseInt(id),
  };

  // Function to scroll to the center of the div
  const scrollToCenter = () => {
    if (scrollingDivRef.current) {
      scrollingDivRef.current.scrollTop = scrollingDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (companyId) {
      dispatch(companyActions.fetchList(params));
    }
  }, [companies.dirty]);

  // Call the autoScroll function after the component mounts
  useEffect(() => {
    if (islocation) {
      scrollToCenter();
    }
  }, [islocation, Object.values(companyData) != '']);

  let isCompanyDataLoading = companyId && busy;

  if (isCompanyDataLoading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '150px',
        }}>
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="bfc-body bfc-form profile-height-body" ref={scrollingDivRef}>
      <div className="form-container">
        <div className="farm-wrapper">
          <Card>
            <CardBody className="main-cardbody">
              <Collapse title="Organisation Info" className="with-shadow">
                <Company
                  companyId={companyId}
                  companyData={companyData}
                  id={id}
                  isCompanyUser={readAccess}
                  loading={isCompanyDataLoading}
                />
              </Collapse>
              {companyId ? (
                <React.Fragment>
                  <Collapse title="Business Affiliations" className="with-shadow">
                    <Affiliates id={id} isCompanyUser={fullAccess} />
                  </Collapse>
                  <Collapse title="Business Locations" className="with-shadow">
                    <Locations id={id} isCompanyUser={fullAccess} companyId={companyId}/>
                  </Collapse>
                </React.Fragment>
              ) : null}
            </CardBody>
          </Card>
          {companyId ? (
            <div className="btn-wrapper justify-content-space-between">
              <div className="d-none">
                <Button
                  color="secondary"
                  size="sm"
                  type="button"
                  onClick={console.log}
                  className="next bfc-button-next">
                  {props.isSubmitting && (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  )}{' '}
                  Next
                </Button>
              </div>
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                  Prev
                </NavLink>
              </div>
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} color="link">
                  Next
                </NavLink>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Organization;
