import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const createSingleInvoiceSchema = yup.object().shape({
  custom_invoice_number: yup.string().when('is_user_defined_invoice_number', {
    is: 1,
    then: yup.string().required('Invoice Number is required'),
    otherwise: yup.string().optional(),
  }),
  invoice_date: yup.string().required('Invoice Date is required'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const setSingleInvoiceDeals = async (params) => {
  try {
    let res = await api.setSingleInvoiceDeals(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const CreateSingleInvoice = async (params, ids) => {
  try {
    await helpers.validateSchema(createSingleInvoiceSchema, params);
    const res = await api.CreateSingleInvoice(params, ids);
    return res;
  } catch (err) {
    throw err;
  }
};
