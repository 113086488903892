import _ from 'lodash';
import utils from 'lib/utils';
import qs from 'query-string';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchAllPayments`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchAllPayments');
    throw errors;
  }
};

export const handelPaymentDelete = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelPayments/api/Payment/DeleteRecordedPayment?payment_master_id=${params}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteRecordedPayment');
    throw errors;
  }
};

export const fetchReportList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchAllPayments`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchReportList(resData);
  } catch (err) {
    console.log('api-FetchAllPayments-fetchList->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchAllPayments');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const fetchPaymentDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchPaymentDetails(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchPaymentByPaymentMasterId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPaymentDetails(resData);
  } catch (err) {
    console.log('api-FetchInvoiceDetailsAgainstInvoiceMasterId-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPaymentByPaymentMasterId');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);

    const url = `${baseUrl}/BioFuelPayments/api/Payment/SaveUpdatePaymentAllocationAgainstInvoiceIds`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds');
    throw errors;
  }
};

export const fetchOnlinePaymentOrderDetails = async (params) => {
  try {
    let bodyParams = reqTransform.fetchOnlinePaymentOrderDetails(params);

    const url = `${baseUrl}/BioFuelPayments/api/Payment/SaveUpdatePaymentAllocationAgainstInvoiceIds`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.fetchOnlinePaymentOrderDetails(resData);
  } catch (err) {
    console.log('-fetchOnlinePaymentOrderDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds');
    throw errors;
  }
};
export const fetchOnlinePaymentOrderDetailsFinance = async (params) => {
  try {
    let bodyParams = reqTransform.fetchOnlinePaymentOrderDetails(params);

    const url = `${baseUrl}/BioFuelPayments/api/TradeFinance/SaveUpdatePaymentAllocationAgainstInvoiceIds_Finance`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.fetchOnlinePaymentOrderDetails(resData);
  } catch (err) {
    console.log('-fetchOnlinePaymentOrderDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds_Finance');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);

    const url = `${baseUrl}/BioFuelPayments/api/Payment/SaveUpdatePaymentAllocationAgainstInvoiceIds`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'SaveUpdatePaymentAllocationAgainstInvoiceIds');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteAffiliateAgainstComapnyAffiliateId?company_affiliate_id=${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAffiliateAgainstComapnyAffiliateId');
    throw errors;
  }
};

export const recordPaymentByBuyerSeller = async (params) => {
  try {
    let bodyParams = reqTransform.recordPaymentByBuyerSeller(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/RecordPaymentByBuyerSeller_Multiple`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.recordPaymentByBuyerSeller(resData);
  } catch (err) {
    console.log('recordPaymentByBuyerSeller-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RecordPaymentByBuyerSeller');
    throw errors;
  }
};

export const fetchPaymentDetailsForEditPayment = async (params = {}) => {
  try {
    const qs = reqTransform.fetchPaymentDetailsForEditPayment(params);
    const url = `${baseUrl}/BioFuelPayments/api/Payment/FetchPaymentDetailsForEditPayment?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPaymentDetailsForEditPayment(resData);
  } catch (err) {
    console.log('api-FetchPaymentDetailsForEditPayment-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPaymentDetailsForEditPayment');
    throw errors;
  }
};

export const fetchAuditAgainstEntityId = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Audit/FetchAuditAgainstEntityId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchAuditAgainstEntityId');
    throw errors;
  }
};
