import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils, { useAnalyticsEventTracker } from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { extendedProfile } from 'services';
import history from 'lib/history';

const adapter = createEntityAdapter({});
// const user = useSelector((state) => state.user);
const slice = createSlice({
  name: 'extendedProfile',
  initialState: adapter.getInitialState({
    busy: false,
    dirty: true,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setDirty(state, action) {
      let dirty = action.payload;
      state.dirty = dirty;
    },
  },
});

const fetchDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let refreshCheck;
  try {
    const resData = await extendedProfile.fetchDetails(params);
    refreshCheck = _.get(resData, '1.user.profile.company_address_id', 0);
    // if (refreshCheck) {
    //   dispatch(slice.actions.setBusy(true));
    // }
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  // if (refreshCheck) {
  //   dispatch(slice.actions.setBusy(false));
  // }
  dispatch(slice.actions.setBusy(false));
};

const fetchNotConnectedDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let refreshCheck;
  try {
    const resData = await extendedProfile.fetchNotConnectedDetails(params);
    refreshCheck = _.get(resData, '1.user.profile.company_address_id', 0);
    // if (refreshCheck) {
    //   dispatch(slice.actions.setBusy(true));
    // }
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  // if (refreshCheck) {
  //   dispatch(slice.actions.setBusy(false));
  // }
  dispatch(slice.actions.setBusy(false));
};

const create = (userID, params, formikBag, activeTab, tabName) => async (dispatch) => {
  dispatch(slice.actions.setDirty(true));
  try {
    notifications.show({
      type: 'info',
      message: `Saving Profile...`,
      key: 'req-form',
    });
    const resData = await extendedProfile.create(params, formikBag, activeTab, tabName);
    await dispatch(slice.actions.setAll(resData));
    // setTimeStamp(Date.now());
    notifications.show({
      type: 'success',
      message: 'Profile saved.',
      key: 'req-form',
    });
    const gaEventTracker = useAnalyticsEventTracker('Create New Supplier Profile');
    const data = {
      hitType: 'event',
      eventCategory: 'Create_New_Supplier_Profile',
      eventAction: 'Create New Supplier Profile',
      eventLabel: `New Supplier Profile --> ${userID}`,
      //   ProfileData : params
    };
    const profieData = {
      params,
      userID: userID,
    };
    gaEventTracker(data);
    // gaEventTracker.bind(params)
    // gtag('event', 'SupplierProfile', {params});
    gtag('event', 'New_Supplier_Profile_Tabs', params, userID);
    return 'success';
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err, formikBag);
  }
  dispatch(slice.actions.setDirty(false));
};

const update = (userID, params, formikBag, activeTab, tabName) => async (dispatch) => {
  dispatch(slice.actions.setDirty(true));
  try {
    notifications.show({
      type: 'info',
      message: `Saving Profile...`,
      key: 'req-form',
    });

    const resData = await extendedProfile.update(params, formikBag, activeTab, tabName);
    dispatch(slice.actions.setAll(resData));
    notifications.show({
      type: 'success',
      message: 'Profile saved.',
      key: 'req-form',
    });

    const gaEventTracker = useAnalyticsEventTracker('Supplier Profile Update');
    const data = {
      hitType: 'event',
      eventCategory: 'Update_Supplier_Profile',
      eventAction: 'Update Supplier Profile',
      eventLabel: `Update Supplier Profile --> ${userID}`,
    };
    const profieData = {
      ...params,
      userID: userID,
    };
    gaEventTracker(data);
    gtag('event', 'Update_Supplier_Profile_Tabs', profieData);
    return 'success';
  } catch (err) {
    console.log('in redux err', err);
    utils.displayErrors(err, formikBag);
  }
  dispatch(slice.actions.setDirty(false));
  // dispatch(slice.actions.setBusy(false));
};

const sumbitForInspection = (id, setTimestamp) => async (dispatch) => {
  dispatch(slice.actions.setDirty(true));
  try {
    notifications.show({
      type: 'info',
      message: `Submitting for inspection...`,
      key: 'req-form',
    });
    const resData = await extendedProfile.sumbitForInspection(id);
    notifications.show({
      type: 'success',
      message: 'Your request for Verification has been Submitted. You will be notified of Inspection by BiofuelCircle.',
      key: 'req-form',
    });
    //   dispatch(slice.actions.setAll(resData));
    // setTimestamp(Date.now());
    history.push(`/extended_profile`);
  } catch (err) {
    //   dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  // dispatch(slice.actions.setBusy(false));
};

const submitForAssessment =
  (id, comments = '', setTimestamp) =>
  async (dispatch) => {
    dispatch(slice.actions.setDirty(true));
    try {
      notifications.show({
        type: 'info',
        message: `Submitting for assessment...`,
        key: 'req-form',
      });
      const resData = await extendedProfile.submitForAssessment(id, comments);
      // dispatch(slice.actions.setAll(resData));
      notifications.show({
        type: 'success',
        message: 'Assessment submitted.',
        key: 'req-form',
      });
      // setTimestamp(Date.now());
      history.push(`/extended_profile`);
    } catch (err) {
      // dispatch(slice.actions.setAll({}));
      utils.displayErrors(err);
    }
    dispatch(slice.actions.setDirty(false));
    // dispatch(slice.actions.setBusy(false));
  };

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let refreshCheck;
  try {
    const resData = await extendedProfile.fetchList(params);
    refreshCheck = _.get(resData, '1.user.profile.company_address_id', 0);
    // if (refreshCheck) {
    //   dispatch(slice.actions.setBusy(true));
    // }
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  // if (refreshCheck) {
  //   dispatch(slice.actions.setBusy(false));
  // }
  dispatch(slice.actions.setBusy(false));
};

const fetchCTList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let refreshCheck;
  try {
    const resData = await extendedProfile.fetchCTList(params);
    refreshCheck = _.get(resData, '1.user.profile.company_address_id', 0);
    // if (refreshCheck) {
    //   dispatch(slice.actions.setBusy(true));
    // }
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
  // if (refreshCheck) {
  //   dispatch(slice.actions.setBusy(false));
  // }
  dispatch(slice.actions.setBusy(false));
};

const allotInspection = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await extendedProfile.allotInspection(params);

    dispatch(slice.actions.setBusy(false));
    return 'success';
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
};
const updateInspectionStatus = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await extendedProfile.updateInspectionStatus(params);

    dispatch(slice.actions.setBusy(false));
    return 'success';
  } catch (err) {
    dispatch(slice.actions.setBusy(false));
    // dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
};

const cancelInspection = (id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await extendedProfile.cancelInspection(id);

    dispatch(slice.actions.setBusy(false));
    return 'success';
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
};

const rollBackSubmission = (id) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await extendedProfile.rollBackSubmission(id);

    dispatch(slice.actions.setBusy(false));
    return 'success';
  } catch (err) {
    // dispatch(slice.actions.setAll({}));
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
};

const unassignInspector = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await extendedProfile.unassignInspector(params);
    dispatch(slice.actions.setBusy(false));
    notifications.show({ type: 'success', message: 'Inspector unassigned successfully' });
    return true;
  } catch (err) {
    dispatch(slice.actions.setBusy(false));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setDirty(false));
};

const actions = {
  ...slice.actions,
  fetchDetails,
  fetchNotConnectedDetails,
  update,
  create,
  sumbitForInspection,
  submitForAssessment,
  fetchList,
  fetchCTList,
  allotInspection,
  updateInspectionStatus,
  cancelInspection,
  rollBackSubmission,
  unassignInspector,
};

const selectors = {
  ...adapter.getSelectors((state) => state.transportDriver),
};

export { actions, selectors };

export default slice.reducer;
