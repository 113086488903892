import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform, reqTransportRouteSrved, resTransportRouteSrved } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/FetchAllTransportDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-transport-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllTransportDetails');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-farms-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/SaveAndUpdateTransportDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-transport-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTransportDetails');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/SaveAndUpdateTransportDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTransportDetails');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/DeleteTransportDetails?transport_detail_Id=${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-delete->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteTransportDetails');
    throw errors;
  }
};
export const fetchListForTranportRouteServed = async (params = {}) => {
  try {
    //const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/FetchAllTransportRouteServed`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-transport-fetchListForTranportRouteServed->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllTransportRouteServed');
    throw errors;
  }
};

export const handleCreateTransportRouteServed = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/SaveAndUpdateTransportRouteServed`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTransportRouteServed');
    throw errors;
  }
};

export const handleDeleteTransportRouteServed = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/DeleteTransportRoutServed?transport_routes_served_id=${params}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteTransportRoutServed');
    throw errors;
  }
};
