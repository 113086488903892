import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    // let errMessage = _.get(err, 'errors.company_id') || '';
    // if (errMessage === 'Invalid') {
    //   return {};
    // }
    throw err;
  }
};

export const fetchAllSellerIndentEnabledCompanies = async (params = {}) => {
  try {
    let res = await api.fetchAllSellerIndentEnabledCompanies(params);
    return res;
  } catch (err) {
    throw err;
  }
};
