import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const SaveAndUpdateMultipartyAuction = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/SaveAndUpdateMultipartyAuction`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateMultipartyAuction');
    throw errors;
  }
};

export const DeleteMultipartyAuctionBuySell = async (id) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/DeleteMultipartyAuctionBuySell?commodity_req_offer_multiparty_auction_id=${id}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyAuctionOverview');
    throw errors;
  }
};

export const FetchAllMultipartyAuctions = async (params) => {
  try {
    // const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchAllMultipartyAuctions`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchMultipartyAuctions(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllMultipartyAuctions');
    throw errors;
  }
};

export const FetchMultipartyAuctionOverview = async (id) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchMultipartyAuctionOverview?commodity_auction_master_id=${id}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllMultipartyAuctionOverview(resData, id);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyAuctionOverview');
    throw errors;
  }
};

export const SaveAndUpdateBuySellAgainstMultipartyAuction = async (params) => {
  try {
    // const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/SaveAndUpdateBuySellAgainstMultipartyAuction`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBuySellAgainstMultipartyAuction');
    throw errors;
  }
};

export const fetchMultipartyLandingPagePositions = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchMultipartyLandingPagePositions`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyLandingPagePositions');
    throw errors;
  }
};

export const fetchMultipartyLandingPagePositionsMetadata = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchMultipartyLandingPagePositionsMetadata?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyLandingPagePositionsMetadata');
    throw errors;
  }
};
export const fetchBidOffersAgainstBuySell = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchBidOffersAgainstBuySell?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchBidOffersAgainstBuySell');
    throw errors;
  }
};

export const pinUnpinBuySellBidOffer = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/PinUnpinBuySellBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'PinUnpinBuySellBidOffer');
    throw errors;
  }
};

export const withdrawBidOffer = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/WithdrawBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'WithdrawBidOffer');
    throw errors;
  }
};

export const withdrawMultipartyAuctionBuySell = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/WithdrawMultipartyAuctionBuySell?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'WithdrawMultipartyAuctionBuySell');
    throw errors;
  }
};

export const counterBidOffer = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/CounterBidOffer`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'CounterBidOffer');
    throw errors;
  }
};

export const saveAndUpdateBidOffer = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/SaveAndUpdateBidOffer`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBidOffer');
    throw errors;
  }
};

export const acceptMultipartyOfferBid = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/Deal/AcceptMultipartyOfferBid?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AcceptMultipartyOfferBid');
    throw errors;
  }
};

export const rejectBidOffer = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/RejectBidOffer?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RejectBidOffer');
    throw errors;
  }
};

export const fetchMultipartyLandingPagePositionsSpectator = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchMultipartyLandingPagePositions_Spectator?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyLandingPagePositions_Spectator');
    throw errors;
  }
};

export const fetchMultipartyLandingPagePositionsCT = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchMultipartyLandingPagePositions_CT?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchMultipartyLandingPagePositions_CT');
    throw errors;
  }
};

export const GetLatestCounterAgainstOfferBidId = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/GetLatestCounterAgainstOfferBidId?${qs}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetLatestCounterAgainstOfferBidId');
    throw errors;
  }
};

export const FetchRivals = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/FetchRivals?${qs}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchRivals');
    throw errors;
  }
};

export const DeleteMyBuySells = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelAuctions/api/MultipartyAuction/DeleteMyBuySells?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteMyBuySells');
    throw errors;
  }
};
