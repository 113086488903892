import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchPlanRequirementAgainstEntityId = async (params) => {
  try {
    const bodyParams = reqTransform.fetchPlanRequirementAgainstEntityId(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllPlanRequirementAgainstEntityId_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchRequirements(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPlanRequirementAgainstEntityId_ELOC');
    throw errors;
  }
};

export const fetchPlanOfferAgainstEntityId = async (params) => {
  try {
    const bodyParams = reqTransform.fetchPlanOfferAgainstEntityId(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/FetchAllPlanOfferAgainstEntityId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchRequirements(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPlanOfferAgainstEntityId_ELOC');
    throw errors;
  }
};

export const fetchRequirements = async (params) => {
  try {
    const qs = reqTransform.fetchRequirements(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllRequirementAgainstPlanId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchRequirements(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllRequirementAgainstPlanId_ELOC');
    throw errors;
  }
};

export const fetchOffers = async (params) => {
  try {
    const qs = reqTransform.fetchOffers(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/FetchAllPlainOffersAgainstPlanId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchOffers(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllPlainOffersAgainstPlanId_ELOC');
    throw errors;
  }
};

export const fetchBalancePosition = async (params) => {
  try {
    const qs = reqTransform.fetchBalancePosition(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/FetchBalancePositionAgainstPlanId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchBalancePosition(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBalancePositionAgainstPlanId');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/SaveRequirementNew_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveRequirementNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveRequirementNew_PhaseTwo');
    throw errors;
  }
};

export const createOpenBuy = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SaveRequirementNew_PhaseTwo`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveRequirementNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveRequirementNew_PhaseTwo');
    throw errors;
  }
};

export const createOffer = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/SavePlainOfferNew`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SavePlainOfferNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SavePlainOfferNew');
    throw errors;
  }
};

export const createOpenSell = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SavePlainOfferNew`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SavePlainOfferNew-Plan-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SavePlainOfferNew');
    throw errors;
  }
};

export const updateRequirement = async (params, isOpenBuySell) => {
  try {
    let bodyParams = reqTransform.update(params);
    let url;
    if (isOpenBuySell) {
      url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/EditRequirementNew`;
    } else {
      url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/EditRequirementNew`;
    }

    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('EditRequirementNew-Plan-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EditRequirementNew');
    throw errors;
  }
};
export const updateOffer = async (params, isOpenBuySell) => {
  try {
    let bodyParams = reqTransform.update(params);
    let url;
    if (isOpenBuySell) {
      url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/EditPlainOfferNew`;
    } else {
      url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/EditPlainOfferNew`;
    }

    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('EditPlainOfferNew-Plan-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EditPlainOfferNew');
    throw errors;
  }
};
export const fetchPlanDetails = async (params, type) => {
  try {
    const qs = reqTransform.fetchPlanDetails(params);
    let url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchRequirementAgainstId_ELOC?${qs}`;
    if (type === 'offers') {
      url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/FetchPlainOfferAgainstId_ELOC?${qs}`;
    }
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPlanDetails(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchPlanDetails');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/DeletePlansAgainstPlanId?plan_id=${qs}`;
    // const res = await utils.fetch(url).ready;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeletePlansAgainstPlanId');
    throw errors;
  }
};

export const savePlanAsRequirement = async (params) => {
  try {
    const bodyParams = reqTransform.savePlanAsRequirement(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/SaveRequirementNew_PhaseTwo`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.savePlanAsRequirement(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveRequirementNew_PhaseTwo');

    throw errors;
  }
};

export const savePlanAsOffer = async (params) => {
  try {
    const bodyParams = reqTransform.savePlanAsRequirement(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/SavePlainOfferNew`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.savePlanAsRequirement(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SavePlainOfferNew');

    throw errors;
  }
};

// Prathmesh
export const fetchAllIndents = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllIndents(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchAllIndents`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllIndents(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchAllIndents');
    throw errors;
  }
};

export const fetchAllPlateFormMaster = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllIndents(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchAllPlatformMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllPlateFormMaster(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchAllIndents');
    throw errors;
  }
};

export const saveAndUpdatePlateformCharges = async (params) => {
  try {
    const bodyParams = reqTransform.saveAndUpdatePlateformCharges(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/SaveAndUpdatePlatformCharge`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchAllIndents');
    throw errors;
  }
};

export const fetchIndentDetails = async (params, type) => {
  try {
    const qs = reqTransform.fetchIndentDetails(params);
    let url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/PublishRequirment_PhaseTwo?${qs}`;
    if (type === 'offers') {
      url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/PublishPlainOffer_PhaseTwo?${qs}`;
    }
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchIndentDetails(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'fetchIndentDetails');
    throw errors;
  }
};

export const fetchLinkedBuys = async (params) => {
  try {
    const bodyParams = reqTransform.fetchLinkedBuys(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchLinkedBuys`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchLinkedBuys(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchLinkedBuys');
    throw errors;
  }
};

export const fetchOffersAgainstIndentBuy = async (params) => {
  try {
    const bodyParams = reqTransform.fetchOffersAgainstIndentBuy(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchOffersAgainstIndentBuy`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchOffersAgainstIndentBuy(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchOffersAgainstIndentBuy');
    throw errors;
  }
};

export const selectOffersForPickUp = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SelectOffersForPickUp`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SelectOffersForPickUp');
    throw errors;
  }
};

export const saveAndUpdatePickupActivity = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/SaveAndUpdatePickupActivity`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'saveAndUpdatePickupActivity');
    throw errors;
  }
};

export const fetchListSmartBuyerConfigurationReport = async (params) => {
  try {
    const bodyParams = reqTransform.fetchAllIndents(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/FetchAllPlatformMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchListSmartBuyerConfigurationReport(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchAllIndents');
    throw errors;
  }
};

export const expireBuySell = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/ExpireBuySell?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'ExpireBuySell');
    throw errors;
  }
};

export const sendNotificationsForPickUpBuy = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/OpenBuySell/SendNotificationsForPickUpBuy?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'sendNotificationsForPickUpBuy');
    throw errors;
  }
};

export const acceptPickUpASCOffers = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/AcceptPickUpASCOffers`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AcceptPickUpASCOffers');
    throw errors;
  }
};
