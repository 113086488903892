import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';

import { uploadDocumentService, kycUserIdentityService } from 'services';
import { actions as uploadActions } from 'redux/uploadDoc';
import * as DeliveryService from 'services/delivery';

export const handleUpload = async (
  values,
  entity_type_enum,
  front_back_enum,
  entity_id,
  attachmentIdToPass,
  company_affiliate_enum,
  formikBag,
) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Uploading Document ...',
      key: 'req-form',
    });

    const res = await uploadDocumentService.upload(
      values,
      entity_type_enum,
      front_back_enum,
      entity_id,
      attachmentIdToPass,
    );
    notifications.show({
      type: 'success',
      message: 'Document Uploaded.',
      key: 'req-form',
    });

    let params = {
      entity_id: entity_id,
      company_affiliate_enum: company_affiliate_enum,
    };
    store.dispatch(uploadActions.fetchList(params));
    // window.location.reload();

    return res;
  } catch (err) {
    console.log('Upload Document--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateStatus = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'error',
      message: 'Updating KYC Status',
      key: 'req-form',
    });

    // console.log('values in handlers', values);
    const res = await kycUserIdentityService.createStatus(values);
    notifications.show({
      type: 'success',
      message: 'KYC Status Updated.',
      key: 'req-form',
    });

    redirect.web(`/kycapproval/business`);

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleDeleteUploadedFileAgainstId = async (values) => {
  try {
    const res = await DeliveryService.deleteUploadedFileAgainstId(values);

    return res;
  } catch (err) {
    console.log('handleClose--->error---->', err);
    throw err;
  }
};
