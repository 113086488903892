import _ from 'lodash';
import utils from 'lib/utils';

export const reqTransform = {
  fetchFollowPreferences_CT(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchFollowPreferences(resData) {
    let data = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(data, 'follow_preference_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'follow_preference_id'),
    };
    return res;
  },
};
