import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchKYCIdentityDetails_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('FetchKYCIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchKYCIdentityDetails_ELOC');
    throw errors;
  }
};

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchCompanyAndAffiliatesAgainstPersonId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCompanyList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCompanyAndAffiliatesAgainstPersonId');
    console.log('FetchCompanyAndAffiliatesAgainstPersonId->err---->', err);
    console.log('FetchCompanyAndAffiliatesAgainstPersonId->errors---->', errors);
    throw errors;
  }
};

export const fetchCompanyAndAffiliatesWithSocialProfile = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/FetchCompanyAndAffiliatesWithSocialProfile?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchCompanyAndAffiliatesWithSocialProfile(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateKYC_BusinessUser_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateKYC_BusinessUser_ELOC');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateKYC_BusinessUser_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateKYC_BusinessUser_ELOC');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteAffiliateAgainstComapnyAffiliateId?company_affiliate_id=${qs}`;

    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAffiliateAgainstComapnyAffiliateId');
    throw errors;
  }
};

//Tax
export const fetchTaxDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTaxDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchTaxIdentityDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTaxDetails(resData);
  } catch (err) {
    console.log('FetchTaxIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTaxIdentityDetails');
    throw errors;
  }
};

export const createTax = async (params) => {
  try {
    //Tax
    let bodyParams = reqTransform.createTax(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateTaxIdentity_BusinessUser`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.createTax(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_BusinessUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTaxIdentity_BusinessUser');
    throw errors;
  }
};

export const updateTax = async (id, params = {}) => {
  try {
    //Tax
    let bodyParams = reqTransform.updateTax(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateTaxIdentity_BusinessUser`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.updateTax(resData);
  } catch (err) {
    console.log('SaveAndUpdateTaxIdentity_BusinessUser-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTaxIdentity_BusinessUser');
    throw errors;
  }
};
//Bank
export const fetchBankDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchBankDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchBankAccountDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    if (params['isSerilized'] === false) {
      return resData;
    }
    return resTransform.fetchBankList(resData);
  } catch (err) {
    console.log('FetchBankAccountDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBankAccountDetails');
    throw errors;
  }
};

export const createBank = async (params) => {
  try {
    //Bank
    let bodyParams = reqTransform.createBank(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateBankAccountDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.createBank(resData);
  } catch (err) {
    console.log('SaveAndUpdateBankAccountDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBankAccountDetails');
    throw errors;
  }
};

export const updateBank = async (id, params = {}) => {
  try {
    //Bank
    let bodyParams = reqTransform.updateBank(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateBankAccountDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.updateBank(resData);
  } catch (err) {
    console.log('SaveAndUpdateBankAccountDetails-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBankAccountDetails');
    throw errors;
  }
};

export const eKycDocumentVerification = async (params = {}) => {
  try {
    let bodyParams = reqTransform.eKycDocumentVerification(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/KYCVerification`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.eKycVerification(resData);
  } catch (err) {
    console.log('KYCVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'KYCVerification');
    throw errors;
  }
};
