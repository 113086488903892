import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import { resTransform, reqTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    params['invoking_person_id'] = parseInt(_.get(globalThis, 'user.userid') || 0);

    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchDeliveries_ELOC_Lite`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchDeliveries->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchDeliveries_ELOC_Lite');
    throw errors;
  }
};

export const fetchStatusAgainstDeliveryId = async (params = {}, isCtDelivery: boolean = false) => {
  try {
    const qs = reqTransform.fetchStatusAgainstDeliveryId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/${
      isCtDelivery
        ? 'FetchTransportDeliveryPhasesAgainstDeliveryId_CT'
        : 'FetchTransportDeliveryPhasesAgainstDeliveryId'
    }?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchStatusAgainstDeliveryId(resData);
  } catch (err) {
    console.log('fetchStatusAgainstDeliveryId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTransportDeliveryPhasesAgainstDeliveryId');
    throw errors;
  }
};

export const updateStatusAgainstDeliveryId = async (params = {}) => {
  try {
    const bodyParams = reqTransform.updateStatusAgainstDeliveryId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/TripDetails/UpdateDeliveryPhasesAgainstDeliveryId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchStatusAgainstDeliveryId(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateDeliveryPhasesAgainstDeliveryId_ELOC');
    console.log('updateStatusAgainstDeliveryId->errors---->', errors);

    throw errors;
  }
};

export const fetchStatusAgainstDeliveryIdCT = async (params = {}) => {
  try {
    const qs = reqTransform.fetchStatusAgainstDeliveryId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryPhasesAgainstDeliveryId_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchStatusAgainstDeliveryId(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchTransportDeliveryPhasesAgainstDeliveryId_CT');
    throw errors;
  }
};

export const updateStatusAgainstDeliveryIdCT = async (params = {}) => {
  try {
    const bodyParams = reqTransform.updateStatusAgainstDeliveryId(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/TripDetails/UpdateDeliveryPhasesAgainstDeliveryId_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchStatusAgainstDeliveryId(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateDeliveryPhasesAgainstDeliveryId_CT');
    throw errors;
  }
};

export const fetchDetails = async (params) => {
  try {
    const qs = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryDetailsAgainstTransportDeliveryScheduleAllotedId_ELOC?${qs}`;

    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('trip detials fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(
      err,
      '',
      'FetchTransportDeliveryDetailsAgainstTransportDeliveryScheduleAllotedId_ELOC',
    );

    console.log('trip detilas fetchDetails->err---->', errors);

    throw errors;
  }
};
