import _ from 'lodash';
import React from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

const dataCache = {};

function ReferenceDataSelect(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    variant,
    // placeholder,
    ...rest
  } = props;

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }
  // console.log('====================')
  // console.log('entity-->', entity)
  // console.log('disabled-->', disabled)
  // console.log('!!disabled-->', !!disabled)
  // console.log('qparams-->', qparams)
  // console.log('isEmpty(qparams)->', _.isEmpty(qparams))
  // console.log('\n====================')

  let entityData = useSelector((state) => {
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams)[0];
      return qId > 0 ? state.referenceData.data[entity][qId] || [] : [];
    }

    const found = state.referenceData.data[entity];

    return Array.isArray(found) ? found : [];
  });

  // console.log('entityData--->', entityData);
  const loading = open && entityData && entityData.length === 0;

  const valuemember = _.get(values, field.name, 0);
  // if(field.name.includes('commodity_Req_Offer_Quality_Spec[0].commodity_Spec_Id')){
  //   debugger;
  // }
  let selectedValue = entityData.find((e) => e.valuemember === valuemember);

  if (!selectedValue) {
    selectedValue = { valuemember, displaymember: valuemember ? initialDisplayText : '' };
  }

  React.useEffect(() => {
    let active = true;

    const loadOptions = async () => {
      // console.log('dataCache-->', dataCache);

      if (!entityData.length) {
        try {
          if (!_.isEmpty(qparams)) {
            dispatch(refDataActions.getCommodityDropdownData(entity, qparams));
          } else if ((entity || '').toLowerCase().includes('enum')) {
            dispatch(refDataActions.getEnumData(entity)); // as a "enumgroup"
          } else {
            dispatch(refDataActions.getReferenceData(entity));
          }

          // console.log('entityData--->', entityData);

          dataCache[entity] = entityData;
          setOptions(entityData);
        } catch (err) {
          console.log('tries-->', tries);
          console.log('getCompanies error', err);

          if (tries < 3) {
            setTries(tries + 1);
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  // className={`${hasError ? 'has-error' : ''} bfc-ref-data-select-wrapper`}
  return (
    <Autocomplete
      value={selectedValue}
      id={`${entity}-autocomplete-${timestamp}`}
      defaultValue={initialDisplayText || ''}
      disabled={!!disabled}
      disableClearable
      size="small"
      // autoHighlight
      // style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(o, value) => {
        if (o?.displaymember && value?.displaymember) {
          return o.displaymember.trim().toLowerCase() === value.displaymember.trim().toLowerCase();
        }

        return false;
      }}
      getOptionLabel={(o) => {
        return o?.displaymember || '';
      }}
      options={_.orderBy(entityData, ['displaymember'])}
      loading={loading}
      onChange={(event, newValue) => {
        let entity_id = newValue?.valuemember;
        const d = entityData.find((c) => c.displaymember === newValue.displaymember);
        if (d && d.valuemember) {
          entity_id = d.valuemember;
          // if(utils.isNumber(entity_id)){
          //   entity_id = parseInt(entity_id)
          // }
        }

        props.form.setFieldValue(field.name, entity_id);

        if (props.onCallback) {
          props.onCallback(entity_id);
        }

        if (dependentFields && dependentFields.length) {
          for (let df of dependentFields) {
            props.form.setFieldValue(df, '');
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant || 'outlined'}
          // {...field}
          className="material-form__field"
          label={label}
          error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
          children={children}
          helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
          FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default ReferenceDataSelect;
