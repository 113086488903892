import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchOnlinePaymentOrderDetails(params = {}) {
    const reqParams = {
      ...params,
      operation_enum: 1,
      online_offline_enum: 1,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      // company_id: _.get(window, 'user.profile.person.company_id', 0),
      // affiliate_code: params['affiliate_name'] || '',
    };
    return reqParams;
    // return helpers.getQueryParams(reqParams);
  },
  fetchList(params = {}) {
    let bodyParams = {
      ...params,
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };
    return bodyParams;
  },
  fetchDetails(id) {
    return id;
  },

  fetchPaymentDetails(params = {}) {
    const qsParams = {
      ...params,
      payment_master_id: params['payment_master_id'],
    };
    return utils.getQs(qsParams);
  },

  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },

  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  recordPaymentByBuyerSeller(params = {}) {
    let bodyParams = {
      ...params,
      tds: parseFloat(params['tds'] || 0),
    };

    delete bodyParams['is_p2'];
    delete bodyParams['amount_due'];
    return bodyParams;
  },
  fetchPaymentDetailsForEditPayment(params) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let paymentData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(paymentData, 'payment_master_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'payment_master_id') || 0,
    };
    return data;
  },
  fetchPaymentDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'bf_payment_master_response.payment_master_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },

  fetchOnlinePaymentOrderDetails(resData) {
    return resData;
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  fetchReportList(resData) {
    let paymentData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchReportDetails(details);
    });
    return _.keyBy(paymentData, 'payment_master_id');
  },
  fetchReportDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'payment_master_id') || 0,
    };
    delete data['total_count'];
    delete data['pg_order_enum'];
    delete data['pg_payment_enum'];
    delete data['online_offline_enum'];
    delete data['paid_by_company_affiliate_enum'];
    delete data['received_by_company_affiliate_enum'];
    delete data['received_by_user_type_enum'];
    delete data['received_by_user_type_enum_code'];
    delete data['paid_by_user_type_enum'];
    delete data['paid_by_user_type_enum_code'];
    delete data['from_bank_id'];
    delete data['to_bank_id'];
    delete data['paid_by_person_id'];
    delete data['received_by_person_id'];
    delete data['payment_currency_id'];
    delete data['payment_status_enum'];
    delete data['payment_method_id'];

    return data;
  },

  recordPaymentByBuyerSeller(resData) {
    return resData;
  },

  fetchPaymentDetailsForEditPayment(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'bank_account') || 0,
    };
    return res;
  },
};
