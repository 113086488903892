import {
  createAction,
  // createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { userService, authService } from 'services';

const slice = createSlice({
  name: 'kycUser',
  initialState: {
    busy: false,
  },
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    updateUser(state, action) {
      if (action.payload.flush) {
        state = action.payload;
        if (!state) {
          debugger;
        }
        return state;
      }
      const userInfo = {
        ...state,
        ...action.payload,
      };

      userInfo['person_id'] = parseInt(userInfo['person_id']);
      userInfo['userid'] = userInfo['person_id']; // @TODO: remove later
      userInfo['id'] = userInfo['person_id'];
      // storage.setItem('pid', userInfo['person_id']).then((result) => {
      // });
      let deleteArr = ['client_machine_name', 'client_machine_ip', 'login_user_id'];

      for (let fld of deleteArr) {
        delete userInfo[fld];
        delete userInfo[utils.lowerStr(fld)];
      }

      // globalThis.user = userInfo;

      state = userInfo;
      if (!state) {
        debugger;
      }

      return state;
    },
  },
});

const fetchPersonalDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await userService.fetchPersonalDetails(params);
    const updates = {
      profile: {
        ...resData,
        updatedAt: Date.now(),
      },
    };
    dispatch(slice.actions.updateUser(updates));
  } catch (err) {
    console.log('kyc fetchPersonalDetails-->Err', err);
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchPersonalDetails,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
