import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import utils from 'lib/utils';
import config from 'config';
import storage from 'lib/storage';
// import { format } from 'timeago.js';
import apiErrorLogger from 'services/sentry/index';

const { baseUrl } = config;

export const fetchAllNotifications = async (params = {}) => {
  try {
    const bodyParams = {
      filters: {
        notification_method_enum: '4',
        start_date: null,
        end_date: null,
      },
      offset: params['offset'],
      page_size: params['page_size'],
    };

    // const bodyParams = utils.getBackendParams(backendFields, params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Notification/FetchNotificationDetailsAgainstPersonId`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);

    let transformedData = resData.map((item) => {
      return {
        id: item.notification_detail_id || 0,
        ts: utils.getUnixTs(item.notification_sent_on),
        // timeago: format(item.notification_sent_on),
        ...(item || {}),
      };
    });

    transformedData = _.keyBy(transformedData, 'id');
    return transformedData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchNotificationDetailsAgainstPersonId');
    throw errors;
  }
};

export const updateProfile = async (params = {}, id) => {
  try {
    const formData = new FormData();
    const user = _.get(globalThis, 'user') || {};
    formData.append('invoking_person_id', parseInt(user.id));

    const acl = utils.getACL(user);
    if (acl.isCompany) {
      let sid = id || 0;
      let entity_id = _.get(user, 'id') || 0;
      let company_id = _.get(user, 'profile.person.company_id') || 0;
      let company_social_id = _.get(user, 'profile.company_social_profile_id') || 0;
      if (id === company_social_id) {
        formData.append('social_profile_id', parseInt(company_social_id));
        formData.append('entity_id', parseInt(company_id));
      } else {
        formData.append('social_profile_id', parseInt(sid));
        formData.append('entity_id', parseInt(entity_id));
      }
      formData.append('user_type_enum', 1);
    } else {
      let sid = _.get(user, 'social_profile_id') || 0;
      let entity_id = _.get(user, 'id') || 0;
      formData.append('social_profile_id', parseInt(sid));
      formData.append('entity_id', parseInt(entity_id));
      formData.append('user_type_enum', 2);
    }

    if (params['profile_pic']) {
      formData.append('profile_pic_url', params['profile_pic']);
    }

    if (params['cover_pic']) {
      formData.append('cover_pic_url', params['cover_pic']);
    }

    if (params['about_me']) {
      let aboutMe = params['about_me']; //.replace(/(<([^>]+)>)/gi, '');
      formData.append('about_me', JSON.stringify(aboutMe));
    }
    if (params['headline']) {
      let headline = params['headline']; //.replace(/(<([^>]+)>)/gi, '');
      formData.append('headline', headline);
    }

    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/UpdateProfile`;

    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;
    const resData = await utils.processApiRes(res);
    // return resTransform.update(resData);
  } catch (err) {
    console.log('api-farms-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateProfile');
    throw errors;
  }
};

export const fetchSocialProfile = async (sid) => {
  try {
    const backendFields = {
      social_profile_id: sid,
      from_Person_Id: _.get(window, 'user.userid', 0),
    };

    // const bodyParams = utils.getBackendParams(backendFields, params);
    const bodyParams = backendFields;
    // const qs = Object.keys(bodyParams)
    //   .map((key) => `${key}=${bodyParams[key]}`)
    //   .join('&');
    const qs = utils.getQs(bodyParams);

    const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchUserProfile_ELOC?${qs}`;

    const res = await utils.fetch(url).ready;

    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err) {
    console.log('fetchUserProfile->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchUserProfile_ELOC');

    throw errors;
  }
};

export const fetchPersonalDetails = async (params) => {
  try {
    let person_id_fetch;
    if (params) {
      person_id_fetch = params;
    } else {
      person_id_fetch = _.get(window, 'user.userid', 0);
    }
    const backendFields = {
      // Person_Id: _.get(window, 'user.userid', 0),
      Person_Id: person_id_fetch,
      Invoking_Domain_URL: window.location.origin,
    };

    const bodyParams = utils.getBackendParams(backendFields, params);
    // const qs = Object.keys(bodyParams)
    //   .map((key) => `${key}=${bodyParams[key]}`)
    //   .join('&');
    const qs = utils.getQs(bodyParams);
    const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/FetchPersonalDetails_ELOC?${qs}`;

    if (bodyParams.Person_Id) {
      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } else {
      return utils.forceLogout();
    }

    // return resData;
  } catch (err) {
    console.log('fetchUserProfile->', err);

    const errors = await utils.processApiErrors(err, 'fetchPersonalDetails', 'FetchPersonalDetails_ELOC');

    throw errors;
  }
};

export const logout = async (params = {}) => {
  try {
    const loginStatusId = await storage.getItem('login_status_id');

    const backendFields = {
      login_status_id: loginStatusId,
    };

    const bodyParams = utils.getBackendParams(backendFields, params);

    // const qs = Object.keys(bodyParams)
    //   .map((key) => `${key}=${bodyParams[key]}`)
    //   .join('&');
    const qs = utils.getQs(bodyParams);
    const url = `${baseUrl}/BioFuelOAuthServer/Users/logout?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;

    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err) {
    await apiErrorLogger(err);
    const errors = await utils.processApiErrors(err, '', 'logout');

    throw errors;
  }
};

export const logoutIprofit = async (iProfitUrl) => {
  try {
    const loginStatusId = await storage.getItem('login_status_id');

    const backendFields = {
      login_status_id: loginStatusId,
    };

    const bodyParams = utils.getBackendParams(backendFields, {});

    const qs = Object.keys(bodyParams)
      .map((key) => `${key}=${bodyParams[key]}`)
      .join('&');

    const url = `${baseUrl}/BioFuelOAuthServer/Users/logout?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;

    const iProfitData = window?.user?.profile?.sso_config;
    console.log('iProfitUrl logout', iProfitUrl, iProfitData);
    const params = {
      User_ID: iProfitData?.iprofit_user_id,
      Organization_Id: iProfitData?.iprofit_company_id,
      AccessToken: iProfitData?.iprofit_session_detail?.iprofit_refresh_token,
    };
    const resIprofit = await fetch(`${iProfitUrl}/api/iprofitThirdPartyIntegration/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const success = await resIprofit?.json();
    console.log('success', success);
    if (success?.responseData?.refresh_token) {
      await localStorage.clear();
      window.location.replace(`${success?.responseData?.return_callback_url}/${success?.responseData?.refresh_token}`);
    }
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    await apiErrorLogger(err);
    const errors = await utils.processApiErrors(err, '', 'logout');
    throw errors;
  }
};

export const updateVerified = async (params = {}) => {
  try {
    const bodyParams = params;
    // const qs = Object.keys(bodyParams)
    //   .map((key) => `${key}=${bodyParams[key]}`)
    //   .join('&');
    const qs = utils.getQs(bodyParams);
    let url = `${baseUrl}/BioFuelRegistration/api/UserProfile/UpdateEmailOrPhoneNumber?${qs}`;

    let res = await utils.fetch(url, {
      method: 'PUT',
      // body: JSON.stringify(bodyParams),
    }).ready;

    const resData = await utils.processApiRes(res);

    return resData;
  } catch (err) {
    console.log('logout->', err);

    const errors = await utils.processApiErrors(err, '', 'UpdateEmailOrPhoneNumber');

    throw errors;
  }
};
