import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const AffiliateSchema = yup.object().shape({
  company_affiliate_id: yup.number(),
  business_type_enum: yup.number().required('Please select Business type').positive('Please select Business type'),
  affiliate_name: yup.string().required('Please enter affiliate name'),
  display_name: yup.string().required('Please enter display name'),
  affiliate_relation_enum: yup
    .number()
    .required('Please select Affiliate relationship')
    .positive('Please select Affiliate relationship'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
