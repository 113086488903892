import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchSubscriptionsHistory = async (filters = {}, params) => {
  try {
    let bodyParams = reqTransform.fetchSubscriptionsHistory(filters, params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchSubscriptionHistory`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    // const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchSubscriptionsHistoryList(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchSubscriptionsHistory');
    throw errors;
  }
};
