import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const planSchema = yup.object().shape({
  commodity_type_code: yup.string().required('Commodity Form is required.'),

  qty: yup
    .number()
    .typeError('Quantity is required.')
    .required('Quantity is required.')
    .positive('Quantity is required.'),

  qty_uom_id: yup
    .number()
    .required('Quantity unit of measure is required.')
    .positive('Quantity unit of measure is required.')
    .integer('Quantity unit of measure is required.'),

  periodicity_enum: yup
    .number()
    .required('Periodicity is required.')
    .positive('Periodicity is required.')
    .integer('Periodicity is required.'),

  from_date: yup.date().typeError('From Date is required.').required('From Date is required.'),

  to_date: yup.date().typeError('To Date is required.').required('To Date is required.'),

  expected_price: yup.number().typeError('Expected price is required.').required('Expected price is required.'),

  price_currency_id: yup.number(),

  price_uom_id: yup
    .number()
    .required('Price unit of measure is required.')
    .positive('Price unit of measure is required.')
    .integer('Price unit of measure is required.'),

  address_id: yup
    .number()
    .required('Location is required.')
    .positive('Location is required.')
    .integer('Location is required.'),

  entity_id: yup
    .number()
    .required('Please select Company')
    .positive('Please select Company')
    .integer('Please select Company'),

  plan_name: yup.string().required('Plan Name is required.'),
});

export const create = async (values) => {
  try {
    await helpers.validateSchema(planSchema, values);
    const res = await api.create(values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createOffer = async (values) => {
  try {
    await helpers.validateSchema(planSchema, values);
    const res = await api.createOffer(values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlanRequirementAgainstEntityId = async (params) => {
  try {
    let res = await api.fetchPlanRequirementAgainstEntityId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlanOfferAgainstEntityId = async (params) => {
  try {
    let res = await api.fetchPlanOfferAgainstEntityId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchBalancePosition = async (params) => {
  try {
    let res = await api.fetchBalancePosition(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateRequirement = async (values) => {
  try {
    await helpers.validateSchema(planSchema, values);
    const res = await api.updateRequirement(values);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateOffer = async (values) => {
  try {
    await helpers.validateSchema(planSchema, values);
    const res = await api.updateOffer(values);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchPlanDetails = async (params, type) => {
  try {
    let res = await api.fetchPlanDetails(params, type);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchRequirements = async (params) => {
  try {
    let res = await api.fetchRequirements(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchOffers = async (params) => {
  try {
    let res = await api.fetchOffers(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const savePlanAsRequirement = async (params) => {
  try {
    let res = await api.savePlanAsRequirement(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const savePlanAsOffer = async (params) => {
  try {
    let res = await api.savePlanAsOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllIndents = async (params) => {
  try {
    let res = await api.fetchAllIndents(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllPlateFormMaster = async (params) => {
  try {
    let res = await api.fetchAllPlateFormMaster(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdatePlateformCharges = async (params) => {
  try {
    let res = await api.saveAndUpdatePlateformCharges(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchIndentDetails = async (params, type) => {
  try {
    let res = await api.fetchIndentDetails(params, type);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchLinkedBuys = async (params) => {
  try {
    let res = await api.fetchLinkedBuys(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchOffersAgainstIndentBuy = async (params) => {
  try {
    let res = await api.fetchOffersAgainstIndentBuy(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const selectOffersForPickUp = async (params) => {
  try {
    let res = await api.selectOffersForPickUp(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdatePickupActivity = async (params) => {
  try {
    let res = await api.saveAndUpdatePickupActivity(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListSmartBuyerConfigurationReport = async (params = {}) => {
  try {
    let res = await api.fetchListSmartBuyerConfigurationReport(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const expireBuySell = async (params = {}) => {
  try {
    let res = await api.expireBuySell(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const sendNotificationsForPickUpBuy = async (params = {}) => {
  try {
    let res = await api.sendNotificationsForPickUpBuy(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptPickUpASCOffers = async (params = {}) => {
  try {
    let res = await api.acceptPickUpASCOffers(params);
    return res;
  } catch (err) {
    throw err;
  }
};
