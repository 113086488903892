import { getIn } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import utils from 'lib/utils';
// import { Icon, Cascader } from 'rsuite';
import { TextField } from '@material-ui/core';

// import default style
//import 'rsuite/lib/styles/index.less'; // or 'rsuite/dist/styles/rsuite-default.css'
// import 'rsuite/dist/styles/rsuite-default.css';
import { referenceDataService } from 'services';
import { locationElocService } from 'services';
import CloseIcon from 'mdi-react/CloseThickIcon';
import BackIcon from 'mdi-react/ArrowLeftThickIcon';
import LogoLoader from 'common/LogoLoader';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import CircularProgress from '@material-ui/core/CircularProgress';

function getLocationValus(loc, isLeafNode) {
  let locationId = _.get(loc, 'village.id') || 0;
  let locationCode = _.get(loc, 'village.location_code') || '';

  if (isLeafNode) {
    locationId = _.get(loc, 'village.id') || _.get(loc, 'country.id') || _.get(loc, 'state.id') || 0;
    locationCode =
      _.get(loc, 'village.location_code') ||
      _.get(loc, 'country.location_code') ||
      _.get(loc, 'state.location_code') ||
      '';
  }
  const locValues = {
    location_id: locationId,
    location_code: locationCode,
    address_text: '',
    lattitude: '',
    longitude: '',
    village_id: _.get(loc, 'village.id') || 0,
    village_code: _.get(loc, 'village.location_code') || '',
    taluka_id: _.get(loc, 'taluka.id') || 0,
    taluka_code: _.get(loc, 'taluka.location_code') || '',
    city_id: _.get(loc, 'city.id') || null,
    city_code: _.get(loc, 'city.location_code') || null,
    district_id: _.get(loc, 'district.id') || 0,
    district_code: _.get(loc, 'district.location_code') || '',
    state_id: _.get(loc, 'state.id') || 0,
    state_code: _.get(loc, 'state.location_code') || '',
    state_id: _.get(loc, 'state.id') || 0,
    state_code: _.get(loc, 'state.location_code') || '',
    country_id: _.get(loc, 'country.id') || 0,
    country_code: _.get(loc, 'country.location_code') || '',
    // pin_code: '',
  };
  let locStr = locValues['country_code'];
  if (locStr && locValues['state_code']) {
    locStr = `${locValues['state_code']}, ${locStr}`;
  }
  if (locStr && locValues['district_code']) {
    locStr = `${locValues['district_code']}, ${locStr}`;
  }
  if (locStr && locValues['taluka_code']) {
    locStr = `${locValues['taluka_code']}, ${locStr}`;
  }

  if (locStr && locValues['village_code']) {
    locStr = `${locValues['village_code']}, ${locStr}`;
  }

  locValues['location_code'] = locStr;

  locValues['address_text'] = locValues['location_code'];

  return locValues;
}

const FormikLocationSelect = (props) => {
  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    initialDisplayText,
    placeholder,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';
  const entities = ['country', 'state', 'district', 'taluka', 'village'];

  const wrapperRef = useRef(null);

  const [data, setData] = useState([]);
  const [locDetailData, setLocDetailData] = useState([]);
  const [loc, setLoc] = useState({});
  const [currIndex, setCurrIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(field.value);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside, false);
    } else {
      document.removeEventListener('click', handleClickOutside, false);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [open]);

  const updateLocations = () => {
    if (value.length >= 1) {
      setLoading(true);
      locationElocService
        .getLocationOptions(value)
        .then((resData) => {
          let locData = Object.values(resData);
          locData = _.orderBy(locData, 'place_name', 'asc');
          setData(locData);
          setLoading(false);

          if (locData.length >= 1) {
            setOpen(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          utils.displayErrors(err);
          setLoading(false);
        });
    }
  };

  const handleClear = () => {
    setCurrIndex(0);
    setLoc({});
    setOpen(false);
    setValue('');
    setInputValue('');
    props.cb(getLocationValus({}));
  };

  useEffect(() => {
    updateLocations();
  }, [value]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setValue(value);
  };

  const onItemSelect = (item) => {
    setOpen(false);
    // setValue(item['place_address'] || '');
    setInputValue(item['place_address']);

    locationElocService
      .getLocationDetails(item.e_loc)
      .then((resData) => {
        let locDetailData = Object.values(resData);
        locDetailData = _.orderBy(locDetailData, 'place_name', 'asc');

        props.cb(locDetailData);
        setLocDetailData(locDetailData);
        setLoading(false);
      })
      .catch((err) => {
        utils.displayErrors(err);
        setLoading(false);
      });
  };

  const renderOptions = () => {
    const html = (
      <div className="dropdown-body">
        <div className="search d-none">
          <input type="text" placeholder="Search" />
          <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </div>
        {/* {loading && (
          <div className="location-loader">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )} */}
        <React.Fragment>
          <ul>
            {data.map((item, index) => {
              return (
                <li key={index} onClick={(e) => onItemSelect(item)} className="option-item-loc">
                  {item.place_address}
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      </div>
    );

    return html;
  };

  return (
    <div className={`formik-location-drilldown ${hasError ? 'has-error' : ''}`} ref={wrapperRef}>
      {/* <input
                key='test'
                type="search"
                value={inputValue}
                className="form-control input-sm"
                onChange={(e) => handleChange(e)}
                style = {{ height: '38px'}}
                InputProps={{
                  // ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      <CircularProgress color="inherit" size={20} />
                    </React.Fragment>
                  ),
                }}
              /> */}

      <TextField
        {...field}
        fullWidth
        variant={'outlined'}
        value={inputValue}
        autoComplete="off"
        className="form-control input-sm"
        onChange={(e) => handleChange(e)}
        margin="dense"
        style={{ height: 38 }}
        InputProps={{
          endAdornment: (
            <React.Fragment>{loading ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>
          ),
        }}
      />

      {/* {value && !disabled && ( */}
      {/* <span onClick={handleClear} className="icon-box">
          <CloseIcon color="#1B62AB" size={24} />
        </span> */}
      {/* )} */}

      <div className="cust-dropdown">{open && renderOptions()}</div>
    </div>
  );
};

export default FormikLocationSelect;
