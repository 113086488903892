import _ from 'lodash';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import PlainOfferService, { PlainOfferMixedType, PlainOfferDataType } from 'services/offer';
import BidService, { BidDataType } from 'services/bid';
import api from 'lib/api';
import notifications from 'lib/notifications';
import utils from 'lib/utils';
import normalize from 'normalize-object';

export interface PlainOfferState {
  data: Record<string, PlainOfferMixedType>;
  order: number[];
  busy: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: PlainOfferState = {
  data: {},
  order: [],
  busy: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const getFilteredOffers = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startFetching());

      const filterParams = {
        ...params,
      };

      filterParams['filters'] = {
        ...(params.filters || {}),
        req_offer_type_enum: '2',
      };

      filterParams['controller_enum'] = 2;

      let resData = await api.getFilteredData(filterParams);

      // resData = resData.map(item => {
      //       let tmpId = Math.floor(Math.random() * Math.floor(100000));
      //       if(!item['commodityreqofferid']){
      //         item['commodity_req_offer_id'] = tmpId
      //         item['commodityreqofferid'] = tmpId
      //       }

      //       return item
      //     })

      // dispatch(slice.actions.add(resData));
      dispatch(slice.actions.setData(resData));
      dispatch(slice.actions.endFetching());
    } catch (err) {
      console.log('getFilteredOffers-->Err', err);
      const errorMessage = utils.getErrMessage(err);
      notifications.show({
        type: 'error',
        message: errorMessage || err.message,
      });
    }

    // dispatch(slice.actions.startFetching(false));
  };
};

const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setData: (state, { payload }) => ({ ...state, data: payload }),
    startFetching(state) {
      state.busy = true;
    },
    endFetching(state) {
      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.busy = false;
    },
    add(state, action: PayloadAction<PlainOfferMixedType[]>) {
      const order: number[] = [];
      const data = {};
      const sortedData = _.orderBy<PlainOfferMixedType>(action.payload, 'publish_date', 'desc');

      for (let plainOffer of sortedData) {
        const id = plainOffer.commodity_req_offer_id;
        data[id] = { ...plainOffer };

        data[id]['from_date_timestamp'] = new Date(plainOffer['from_date']).getTime();
        data[id]['to_date_timestamp'] = new Date(plainOffer['to_date']).getTime();
        data[id]['closing_on_timestamp'] = new Date(plainOffer['closing_on']).getTime();

        let searchValues = _.pick(data[id], [
          'commodity_type_code',
          'commodity_code',
          'commodity_form_code',
          'commodity_residue_code',
          'payment_term_code',
          'coarse_location',
          'qty',
          'periodicity_enum_code',
          'delivery_date_range',
          'min_qty',
          'closing_on',
          'expected_price',
          'req_offer_status_enum_code',
        ]);

        let searchStr = Object.values(searchValues).join('==');
        data[id]['searchStr'] = searchStr.toLowerCase();

        order.push(id);
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.busy = false;
    },
    update(state: PlainOfferState, action) {
      const { id, data } = action.payload;
      state.data[id] = {
        ...state.data[id],
        ...data,
        detailsUpdatedAt: Date.now(),
      };
    },
    delete(state: PlainOfferState, action) {
      // debugger;
      const post_id = action.payload;
      let order = _.get(state, 'order', []) || [];
      // const posts = state.data.filter(p => p.post_id !== post_id)
      delete state['data'][post_id];
      let filteredOrder = order.filter((id) => id !== post_id);
      state['order'] = filteredOrder;
    },
  },
});

const FetchAllPlainOfferWithinCircle = (page: number = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await PlainOfferService.FetchAllPlainOfferWithinCircle(page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchAllPlainOfferAgainstEntityId = (page: number = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await PlainOfferService.FetchAllPlainOfferAgainstEntityId(page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
      // throw errors;
    }
  };
};

const getPlainOfferDetails = (commodityReqOfferId: string, selectedAddressId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await PlainOfferService.FetchPlainOfferAgainstId(commodityReqOfferId, selectedAddressId);
      dispatch(slice.actions.add([resData.data]));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      // throw errors;
    }
    dispatch(slice.actions.endFetching());
  };
};

const getPlainOfferDetailsQuote = (commodityReqOfferId: string, selectedAddressId: string) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await PlainOfferService.FetchPlainOfferAgainstIdQuote(commodityReqOfferId, selectedAddressId);
      const response = normalize(resData.data, 'snake');
      dispatch(slice.actions.add([response]));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      // throw errors;
    }
    dispatch(slice.actions.endFetching());
  };
};

const SavePlainOffer = async (payload: Partial<PlainOfferDataType>) => {
  const resData = await PlainOfferService.SavePlainOffer(payload);
  return resData;

  // return async (dispatch: Dispatch, getState) => {
  //   try {
  //     dispatch(slice.actions.startFetching());
  //     const resData = await PlainOfferService.SavePlainOffer(payload);
  //     dispatch(slice.actions.add([resData.data]));
  //   } catch (errors) {
  //     dispatch(slice.actions.handleErrors({ ...errors }));
  //     throw errors;
  //   }
  // };
};

const EditPlainOffer = async (payload: Partial<PlainOfferDataType>) => {
  const resData = await PlainOfferService.EditPlainOffer(payload);
  return resData;
};

const PublishPlainOffer = async (commodityReqOfferId: string) => {
  const resData = await PlainOfferService.PublishPlainOffer(commodityReqOfferId);
  return resData;
};

const WithdrawPlainOfferById = async (commodityReqOfferId: string) => {
  const resData = await PlainOfferService.WithdrawPlainOfferById(commodityReqOfferId);
  return resData;
};

const FollowUnfollowPlainOffer = async (commodityReqOfferId: number, isFollow: boolean) => {
  const resData = await PlainOfferService.FollowUnfollowPlainOffer(commodityReqOfferId, isFollow);
  return resData;
};

const SaveBid = async (payload: Partial<BidDataType>) => {
  const resData = await BidService.SaveBid(payload);
  return resData;

  // return async (dispatch: Dispatch, getState) => {
  //   try {
  //     dispatch(slice.actions.startFetching());
  //     const resData = await PlainOfferService.SavePlainOffer(payload);
  //     dispatch(slice.actions.add([resData.data]));
  //   } catch (errors) {
  //     dispatch(slice.actions.handleErrors({ ...errors }));
  //     throw errors;
  //   }
  // };
};

export const actions = {
  ...slice.actions,
  FetchAllPlainOfferWithinCircle,
  FetchAllPlainOfferAgainstEntityId,
  getPlainOfferDetails,
  SavePlainOffer,
  EditPlainOffer,
  PublishPlainOffer,
  WithdrawPlainOfferById,
  FollowUnfollowPlainOffer,
  //bids
  SaveBid,
  getFilteredOffers,
  getPlainOfferDetailsQuote,
};

export default slice.reducer;
