import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { commodityIndentService } from 'services';
import dayjs from 'dayjs';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (deliveryScheduleDay) => _.get(deliveryScheduleDay, 'delivery_start_date_string', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => (a.name_of_farm || '').localeCompare(b.name_of_farm || ''),
});

const slice = createSlice({
  name: 'commodityPlanDetails',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchAllIndents = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.fetchAllIndents(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchIndentDetails = (params, type) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.fetchIndentDetails(params, type);
    dispatch(slice.actions.removeAll());
    dispatch(slice.actions.addOne(resData));
  } catch (err) {
    dispatch(slice.actions.addOne({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchLinkedBuys = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.fetchLinkedBuys(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchOffersAgainstIndentBuy = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.fetchOffersAgainstIndentBuy(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const selectOffersForPickUp = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.selectOffersForPickUp(params);
    // notifications.show({
    //   type: 'success',
    //   message: `${params?.activityTypeLabel || 'Activity'} scheduled successfully for ${dayjs(
    //     params?.pick_up_datetime,
    //   ).format('DD-MMM-YYYY')}`,
    // });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdatePickupActivity = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.saveAndUpdatePickupActivity(params);
    // notifications.show({
    //   type: 'success',
    //   message: `Activity scheduled successfully for ${dayjs(params?.pick_up_datetime).format('DD-MMM-YYYY')}`,
    // });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const expireBuySell = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.expireBuySell(params);
    notifications.show({
      type: 'success',
      message: `Successfully marked as expired`,
    });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const sendNotificationsForPickUpBuy = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.sendNotificationsForPickUpBuy(params);
    notifications.show({
      type: 'success',
      message: `Notification sent successfully`,
    });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const acceptPickUpASCOffers = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await commodityIndentService.acceptPickUpASCOffers(params);
    notifications.show({
      type: 'success',
      message: `Offer${params?.offer_ids?.length > 1 ? 's' : ''} accepted successfully`,
    });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchAllIndents,
  fetchIndentDetails,
  fetchLinkedBuys,
  fetchOffersAgainstIndentBuy,
  selectOffersForPickUp,
  expireBuySell,
  sendNotificationsForPickUpBuy,
  acceptPickUpASCOffers,
  saveAndUpdatePickupActivity,
};

// // Rename the exports for readability in component usage
// export const {
//   selectById: selectUserById,
//   selectIds: selectUserIds,
//   selectEntities: selectUserEntities,
//   selectAll: selectAllUsers,
//   selectTotal: selectTotalUsers
// } = usersAdapter.getSelectors(state => state.users)

const selectors = {
  ...adapter.getSelectors((state) => state.commodityPlanDetails),
};

export { actions, selectors };

export default slice.reducer;
