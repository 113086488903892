import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import inMemoryJWT from 'services/auth/inMemoryJWT';
import storage from 'lib/storage';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl, pat } = config;

export const getAppConfig = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelOAuthServer/api/SingleSignIn/get-appConfig?${qs}`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
      },
      false,
      true,
    ).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const verifySsoToken = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelOAuthServer/api/SingleSignIn/verifyssotoken?${qs}`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
      },
      false,
      true,
    ).ready;
    const resData = await utils.processApiRes(res);
    const token = _.get(resData, 'data.jwt_token', '');
    const tokenExpiry = _.get(resData, 'data.jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'data.refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'data.person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'data.social_profile_id');
    await storage.setItem('sid', `${sid}`);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const registerUserViaSso = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelOAuthServer/api/SingleSignIn/registeruserviasso?${qs}`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
      },
      false,
      true,
    ).ready;
    const resData = await utils.processApiRes(res);
    const token = _.get(resData, 'login_response.jwt_token', '');
    const tokenExpiry = _.get(resData, 'login_response.jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'login_response.refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'login_response.person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'login_response.social_profile_id');
    await storage.setItem('sid', `${sid}`);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const validateSession = async (params, ssoId) => {
  try {
    const url = `${baseUrl}/BioFuelThirdpartyIntegration/api/IProfit/ValidateSession`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(params),
      },
      true,
      true,
      ssoId,
    ).ready;
    const resData = await utils.processApiRes(res);
    const token = _.get(resData, 'jwt_token', '');
    const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

    const rfToken = _.get(resData, 'refresh_token', '');
    await inMemoryJWT.setToken(token, tokenExpiry);

    const loginStatusId = _.get(resData, 'login_status_id', '');
    await storage.setItem('login_status_id', `${loginStatusId}`);

    await inMemoryJWT.setRefreshToken(rfToken);

    //TODO : tmp - storing person_id in local storage cause refreshToken api needs it
    const pid = _.get(resData, 'person_id', '');
    await storage.setItem('pid', `${pid}`);

    //also storing sid since we need to call this after calling refresh token
    const sid = _.get(resData, 'social_profile_id');
    await storage.setItem('sid', `${sid}`);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};
