import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchAllCost = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchAllCost(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchAllCost`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchAllCostList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchAllCost');
    throw errors;
  }
};

export const fetchPreviewForManualInvoice = async (params = {}) => {
  try {
    const qsParams = reqTransform.fetchPreviewForManualInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchPreviewForManualInvoice?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPreviewForManualInvoiceList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchPreviewForManualInvoice');
    throw errors;
  }
};

export const fetchManualInvoicesDraft = async (params = {}) => {
  try {
    const bodyParams = reqTransform.fetchManualInvoicesDraft(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchManualInvoicesDraft`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchManualInvoicesDraftList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchManualInvoicesDraft');
    throw errors;
  }
};

export const fetchInvoiceApprovalHistory = async (params = {}) => {
  try {
    const qsParams = reqTransform.fetchPreviewForManualInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/FetchInvoiceApprovalHistory?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchInvoiceApprovalHistoryList(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchInvoiceApprovalHistory');
    throw errors;
  }
};

export const saveUpdateManualInvoiceHistory = async (params = {}) => {
  try {
    const bodyParams = reqTransform.saveUpdateManualInvoiceHistory(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/SaveUpdateManualInvoiceHistory`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.saveUpdateManualInvoiceHistory(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateManualInvoiceHistory');
    throw errors;
  }
};

export const saveUpdateManualInvoice = async (params = {}) => {
  try {
    const bodyParams = reqTransform.saveUpdateManualInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/SaveUpdateManualInvoice`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.saveUpdateManualInvoice(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateManualInvoice');
    throw errors;
  }
};

export const createManualInvoice = async (params = {}) => {
  try {
    const qsParams = reqTransform.createManualInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateManualInvoice?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createManualInvoice(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'CreateManualInvoice');
    throw errors;
  }
};

export const deleteManualInvoiceCharge = async (params = {}) => {
  try {
    const qsParams = reqTransform.deleteManualInvoiceCharge(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/DeleteManualInvoiceCharge?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteManualInvoiceCharge(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteManualInvoiceCharge');
    throw errors;
  }
};

export const deleteManualInvoice = async (params = {}) => {
  try {
    const qsParams = reqTransform.deleteManualInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/DeleteManualInvoice?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteManualInvoice(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteManualInvoice');
    throw errors;
  }
};
