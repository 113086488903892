import _ from 'lodash';

export const resTransform = {
  fetchCommodityExploreData(resData) {
    let data = {
      ...resData,
    };

    return data;
  },
};
