import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import utils from 'lib/utils';
import { actions as userActions } from 'redux/user';
import { actions as miscActions } from 'redux/misc';
import * as uploadHandlers from '../Profile/UploadBusiness/handlers';

import redirect from 'lib/redirect';
import { authService } from 'services';
import { resTransform } from 'services/transport/transforms';

export async function handleRequestOTP(values, formikBag) {
  try {
    const res = await authService.sendSignupOTP({
      ...values,
    });
    gtag('event', 'New_User_SignUp_Details', values);
    gtag('event', 'New_User_SignUp_Response', res);
    gtag('event', 'sign_up', { ...res, ...values });

    globalThis.store.dispatch(
      miscActions.update({
        ...res,
        authStep: 'signup-otp',
      }),
    );
  } catch (err) {
    console.log('handler--->signup-->handleRequestOTP-->err', err);
    utils.displayErrors(err, formikBag);
    gtag('event', 'New_User_SignUp_Details', values);
    // gtag('event', 'generate_lead', err )
  }
}

export async function handleOTPSubmit(values, formikBag) {
  try {
    const params = {
      ...values,
    };
    let res = await authService.verifySignupOTP(params);
    globalThis.store.dispatch(
      miscActions.update({
        ...res,
        authStep: 'details',
      }),
    );
  } catch (err) {
    console.log('handler--->signup-->handleOTPSubmit-->err', err);
    utils.displayErrors(err, formikBag);
  }
}

export async function handleResendOTP(params) {
  try {
    let res = await authService.sendSignupOTP(params);
    globalThis.store.dispatch(
      miscActions.update({
        ...res,
        authStep: 'signup-otp',
      }),
    );
  } catch (err) {
    console.log('handler--->signup-->handleResendOTP-->err', err);
    utils.displayErrors(err);
  }
}

export async function handleDetailsSubmit(values, formikBag, isFarmerUser) {
  try {
    values.first_name = values.first_name.trim();
    values.last_name = values.last_name.trim();
    values.onboarding_way_enum = 8;
    let res;
    if (isFarmerUser) {
      values.farm_acreage = parseFloat(values.farm_acreage);
      res = await authService.farmerSignup(values);
    } else {
      res = await authService.signup(values);
    }

    if (window) {
      window.location.href = '/';
      return;
    }

    notifications.show({
      type: 'success',
      message: 'Account Created. Please login to continue',
      key: 'req-form',
    });

    globalThis.store.dispatch(
      miscActions.update({
        flush: true,
      }),
    );

    globalThis.store.dispatch(
      userActions.updateUser({
        flush: true,
      }),
    );

    redirect.web('/account/login');
    redirect.mobile('Home');
  } catch (err) {
    console.log('handler--->signup-->handleAdditionalDetailsSubmit-->err', err);

    utils.displayErrors(err, formikBag);
  }

  // formikBag.setSubmitting(false);
}

export async function handleFarmerDetailsSubmit(
  values,
  formikBag,
  latLongData,
  isFarmerUser,
  isNewFarmer,
  isRegisterPopupOpen,
  setIsRegisterPopupOpen,
  setVpaData,
  vpaData,
) {
  if (isNewFarmer) {
    try {
      values.first_name = values.first_name.trim();
      values.last_name = values.last_name.trim();
      values.farm_location_detail.address_lattitude = (_.get(latLongData, 'latitude') || '').toString();
      values.farm_location_detail.address_longitude = (_.get(latLongData, 'longitude') || '').toString();
      let res;
      values.farm_acreage = parseFloat(values.farm_acreage);
      res = await authService.farmerSignup(values);

      const file1 = await uploadHandlers.handleUpload(values?.files, 15, 1, res.person_id, 0, 0, formikBag);
      if (window) {
        window.location.href = '/';
        return;
      }

      notifications.show({
        type: 'success',
        message: 'Account Created. Please login to continue',
        key: 'req-form',
      });

      globalThis.store.dispatch(
        miscActions.update({
          flush: true,
        }),
      );

      globalThis.store.dispatch(
        userActions.updateUser({
          flush: true,
        }),
      );

      redirect.web('/account/login');
      redirect.mobile('Home');
    } catch (err) {
      console.log('handler--->signup-->handleAdditionalDetailsSubmit-->err', err);
      utils.displayErrors(err, formikBag);
    }
  }
  if (isFarmerUser) {
    let res = await authService.getVPAAgainstMobileNumber(`91${values?.mobile_number}`);
    if ((!_.isEmpty(res) && res?.errors?.vpa) || isRegisterPopupOpen) {
      isRegisterPopupOpen
        ? ''
        : utils.displayMessage(
            'No VPA found against this mobile number, Continuing registration with provided details.',
          );
      const params = {
        mobile_Number: `91${values?.mobile_number}`,
        full_Name: vpaData ? vpaData?.name_at_bank : `${values?.first_name} ${values?.last_name}`,
        agent_code: values?.agent_code,
        location_Details: {
          houseNumber: '',
          houseName: '',
          poi: '',
          poi_dist: '',
          street: '',
          street_dist: '',
          subSubLocality: '',
          subLocality: '',
          locality: '',
          village: values?.farm_location_detail?.village_code,
          district: values?.farm_location_detail?.district_code,
          subDistrict: '',
          city: values?.farm_location_detail?.city_code,
          state: values?.farm_location_detail?.state_code,
          pincode: values?.farm_location_detail?.pincode,
          lat: values?.farm_location_detail?.address_lattitude,
          lng: values?.farm_location_detail?.address_longitude,
          area: '',
          formatted_address: values?.farm_location_detail?.location_code,
        },
        onboarding_way_enum: 1,
      };
      let resData = await authService.registerUser(params);
      if (!_.isEmpty(resData)) {
        const params = {
          mobile_Number: `91${values?.mobile_number}`,
          vpa: vpaData ? vpaData?.vpa : null,
          full_name: vpaData ? vpaData?.name_at_bank : `${values?.first_name} ${values?.last_name}`,
        };
        let resData = await authService.updateUserDetailsAgainstMobileNumber(params);
        if (!_.isEmpty(resData)) {
          setIsRegisterPopupOpen(false);
          if (window) {
            window.location.href = '/';
          }
          notifications.show({
            type: 'success',
            message:
              'Welcome to BiofuelCircle! You have now joined thousands of farmers who can earn income from their farm wastes',
            key: 'req-form',
          });
        }
      }
    } else if (!_.isEmpty(res)) {
      setIsRegisterPopupOpen(true);
      setVpaData(res);
    }
  }

  // formikBag.setSubmitting(false);
}

export const verifyBankAccount = async (params) => {
  try {
    let res = await authService.verifyBankAccount(params);
    if (!_.isEmpty(res)) {
      notifications.show({ type: 'success', message: 'Bank verification successful' });
    }
    return res;
  } catch (err) {
    utils.displayErrors(err);
  }
};

export const verifyMissedCallMobileNumber = async (params) => {
  try {
    let res = await authService.verifyMissedCallMobileNumber(params);
    if (!_.isEmpty(res) && !res?.person_id) {
      notifications.show({
        type: 'info',
        message: `User has given the concent via ${res?.consent_type_enum_code}. Please fill the remaining details to onboard the farmer on the platform.`,
        key: 'req-form',
      });
    } else {
      notifications.show({ type: 'success', message: 'Mobile number verified successfully' });
    }
    return res;
  } catch (err) {
    utils.displayErrors(err);
  }
};

export const SaveUpdatePersonalDetails = async (params) => {
  try {
    let res = await authService.SaveUpdatePersonalDetails(params);
    return res;
  } catch (err) {
    utils.displayErrors(err);
  }
};
