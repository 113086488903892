import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { storageService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'storage',
  initialState: adapter.getInitialState({
    busy: false,
    storagePlans: [],
    storageTransfers: [],
    stockTransferTransportationCharge: {},
    qualitySpecs: {},
    stockDetails: {},
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    getStoragePlans: (state, { payload }) => ({ ...state, storagePlans: payload }),
    getStorageTransfers: (state, { payload }) => ({ ...state, storageTransfers: payload }),
    setQualitySpec: (state, { payload }) => ({ ...state, qualitySpecs: payload }),
    setStockDetails: (state, { payload }) => ({ ...state, stockDetails: payload }),
    setStockTransferTransportationCharge: (state, { payload }) => ({
      ...state,
      stockTransferTransportationCharge: payload,
    }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await storageService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchDetailsList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await storageService.fetchDetailsList(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getStoragePlans = (params) => async (dispatch) => {
  try {
    const resData = await storageService.getStoragePlans(params);
    dispatch(slice.actions.getStoragePlans(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.getStoragePlans({}));
    utils.displayErrors(err);
  }
};

const saveUpdateStoragePlan = (params) => async (dispatch) => {
  try {
    const resData = await storageService.saveUpdateStoragePlan(params);
    // dispatch(slice.actions.getStoragePlans(resData));
    return resData;
  } catch (err) {
    dispatch(slice.actions.getStoragePlans({}));
    utils.displayErrors(err);
  }
};

const deleteStoragePlan = (params) => async (dispatch) => {
  try {
    const resData = await storageService.deleteStoragePlan(params);
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const deleteStoragePlanDetail = (params) => async (dispatch) => {
  try {
    const resData = await storageService.deleteStoragePlanDetail(params);
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const stockTransferQuick = (params) => async (dispatch) => {
  try {
    notifications.show({
      type: 'info',
      message: params?.operation_enum === 1 ? 'Creating Quick Transfer.' : 'Updating Quick Transfer.',
    });
    const resData = await storageService.stockTransferQuick(params);
    notifications.update({
      type: 'success',
      message:
        params?.operation_enum === 1 ? 'Quick Transfer created successfully.' : 'Quick Transfer updated successfully.',
    });
    return true;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const fetchStockTransfers = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    let qsData;
    let resData = await storageService.fetchStockTransfers(params);
    if (params.filters.stock_transfer_id) {
      qsData = await storageService.fetchQualitySpecsAgainstStockTransferId({
        stock_transfer_id: params.filters.stock_transfer_id,
      });
      resData = resData.map((item) => ({ ...item, commodity_req_offer_quality_spec: qsData }));
    }
    dispatch(slice.actions.getStorageTransfers(resData));
    dispatch(slice.actions.setBusy(false));
    return true;
  } catch (err) {
    utils.displayErrors(err);
    dispatch(slice.actions.setBusy(false));
  }
};

const stockTransferWithTransport = (params) => async (dispatch) => {
  try {
    const resData = await storageService.stockTransferWithTransport(params);
    // dispatch(slice.actions.getStorageTransfers(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const fetchTransportationChargeForST = (params) => async (dispatch) => {
  try {
    const resData = await storageService.fetchTransportationChargeForST(params);
    dispatch(slice.actions.setStockTransferTransportationCharge(resData));
    return resData;
  } catch (err) {
    const toastId = 'address-toast';
    utils.displayErrors(err, false, toastId);
  }
};

const fetchQualitySpecsAgainstStockTransferId = (params) => async (dispatch) => {
  try {
    const resData = await storageService.fetchQualitySpecsAgainstStockTransferId(params);
    dispatch(slice.actions.setQualitySpec(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const fetchStockAgainstAddressId = (params) => async (dispatch) => {
  try {
    const resData = await storageService.fetchStockAgainstAddressId(params);
    dispatch(slice.actions.setStockDetails(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const deleteQuickStockTransfer = (params) => async (dispatch) => {
  try {
    const resData = await storageService.deleteQuickStockTransfer(params);
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const deleteStockTransferWithTransport = (params) => async (dispatch) => {
  try {
    const resData = await storageService.deleteStockTransferWithTransport(params);
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchDetailsList,
  getStoragePlans,
  saveUpdateStoragePlan,
  deleteStoragePlan,
  deleteStoragePlanDetail,
  stockTransferQuick,
  fetchStockTransfers,
  stockTransferWithTransport,
  fetchTransportationChargeForST,
  fetchQualitySpecsAgainstStockTransferId,
  fetchStockAgainstAddressId,
  deleteQuickStockTransfer,
  deleteStockTransferWithTransport,
};

const selectors = {
  ...adapter.getSelectors((state) => state.deliveryScheduleDecade),
};

export { actions, selectors };

export default slice.reducer;
