import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ControlTower/FetchKycStatusDetails_Business`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchKycStatusDetails_Business');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-transport-fetchDetails->err---->', err);
    throw err;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateAffiliateDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateAffiliateDetails');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateAffiliateDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify([bodyParams]),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateAffiliateDetails-update->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateAffiliateDetails');
    throw errors;
  }
};

export const destroy = async (id) => {
  try {
    const qs = reqTransform.destroy(id);
    const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/DeleteAffiliateAgainstComapnyAffiliateId?company_affiliate_id=${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.destroy(resData);
  } catch (err) {
    console.log('api-transport-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteAffiliateAgainstComapnyAffiliateId');
    throw errors;
  }
};
