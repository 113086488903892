import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import * as helpers from 'services/helpers';

export const reqTransform = {
  submitForApproval(affiliate_id) {
    const reqParams = {
      affiliateid: affiliate_id,
    };

    return reqParams;
  },
};

export const resTransform = {
  submitForApproval(resData) {
    return resData;
    // return {
    //   id: post_id,
    //   ...resData,
    // };
  },
};
