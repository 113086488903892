import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

const api = (() => {
  const fetchSocialProfile = async (sid) => {
    try {
      const backendFields = {
        social_profile_id: sid,
        from_Person_Id: _.get(window, 'user.userid', 0),
      };

      // const bodyParams = utils.getBackendParams(backendFields, params);
      const bodyParams = backendFields;
      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelSocialFeed/api/SocialFeed/FetchUserProfile_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchPersonalDetails = async (params = {}) => {
    try {
      const backendFields = {
        Person_Id: _.get(window, 'user.userid', 0),
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);

      const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/FetchPersonalDetails_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchOrganizationDetails = async (params = {}) => {
    try {
      const backendFields = {
        // Person_Id: _.get(window, 'user.userid', 0),
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/FetchBusinessProfileDetails_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchUserProfile->', err);

      const errors = await utils.processApiErrors(err);

      if (_.get(errors, 'errors.person_id') === 'No Primary Business') {
        return {};
      }

      throw errors;
    }
  };

  const fetchKYCIdentityDetails = async (params = {}) => {
    try {
      const backendFields = {
        // Person_Id: _.get(window, 'user.userid', 0),
        entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
        User_Type_Enum: 2,
        Company_Affiliate_Enum: 1,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchKYCIdentityDetails_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('fetchKYC->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const updateFarmCrops = async (params) => {
    try {
      //sending these fields works
      // const backendFields = {

      // [
      //   {
      //     farm_Address: {
      //       person_Address_Id: 0,
      //       person_id: 10,

      //       address_Lattitude: '100',
      //       address_Longitude: '2000',
      //       address_Text: 'Nagpur',
      //       landmark: 'nagpur',
      //       city_Id: 0,
      //       district_Id: 0,
      //       state_Id: 0,
      //       country_Id: 0,

      //       location_Id: 0,
      //       taluka_Id: 0,
      //       village_Id: 0,
      //       name_Of_Farm: 'FarmVilla',
      //       size_Of_Farm: 100,
      //       address_Line1: 'Nagpur',
      //       address_Line2: 'Nagpur',
      //       address_Line3: 'Nagpur',
      //       pin_Code: '440016',
      //     },
      //     crop_Details: [
      //        {
      //           "farm_Detail_Id": 0,

      //           "crop_Id": 1,
      //           "crop_Area": 10,
      //           "harvest_Date": "2021-12-27T00:00:00",

      //           "operation_Enum": 1
      //         }
      //     ],
      //     operation_Enum: 1,
      //   },
      // ];
      // };

      // console.log('backendFields ----->', backendFields);

      // const bodyParams = utils.getBackendParams(backendFields, params);

      // console.log('\n-------------------');
      // console.log('updateFarmCrops --params--->', params);

      // let bodyParams = params.formData.filter((farm) => !!farm.basicInfo.operation_enum);
      let bodyParams = [params];
      bodyParams = bodyParams.map((farm) => {
        let crops = farm.crops
          .filter((crop) => !!crop.operation_enum)
          .map((crop) => {
            return {
              ...crop,
              crop_area: crop.crop_area ? parseInt(crop.crop_area) : 10,
              person_id: crop?.person_id || _.get(window, 'user.userid') || 0,
              status_enum: crop.operation_enum !== 4,
            };
          });

        return {
          ...farm,
          crops,
        };
      });

      bodyParams = bodyParams.map((farm) => {
        return {
          farm_address: {
            ...farm.basicInfo,
            ...farm.location,
            size_of_farm: farm.basicInfo?.size_of_farm ? parseInt(farm.basicInfo?.size_of_farm) : 10,
            person_id: farm.basicInfo?.person_id || _.get(window, 'user.userid') || 0,
            status_enum: farm.basicInfo?.operation_enum !== 4,
            address_type_enum: 3,
          },
          crop_details: farm.crops,
          operation_enum: farm.basicInfo?.operation_enum,
        };
      });

      // console.log('bodyParams--->', bodyParams);

      // const bodyParams = {
      //   ...params,
      //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      // };

      // return;

      // const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');

      const url = `${baseUrl}/BioFuelRegistration/api/FarmDetails/SaveAndUpdateFarm`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('updateFarmCrops--->errr---->', err);

      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const fetchAllTransportDetails = async (params = {}) => {
    try {
      const bodyParams = {
        ...params,
        entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
        // entity_id: 2,
        user_type_enum: 1,
        company_affiliate_enum: 1,
      };

      // console.log('\n\n=======>Transport Details==========');
      // console.log('params-->', params);
      // console.log('bodyParams-->', bodyParams);
      // console.log('bodyParamsJson-->', JSON.stringify(bodyParams));

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams);
      const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/FetchAllTransportDetails?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('api-fetchTransportDetails->err', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const saveAndUpdateBusiness = async (params) => {
    // console.log('in api ---', params);
    try {
      const backendFields = {
        generiC_PRIMARY_BUSINESS_REQUEST_MODEL: {
          operation_Enum: 1,
          company_Id: 0,
          company_Code: 'string',
          company_Name: 'string',
          company_Legal_Name: 'string',
          registration_Id: 0,
          is_Kyc_Done: true,
          company_Type_Enum: 1,
          company_Contact: 'string',
          company_Phone_Number: 'string',
          company_Website: 'string',
          company_Email_Id: 'string',
          company_Mobile: 'string',
          nature_Of_Business_Enum: 1,
          businesS_LOCATION_REQUEST_MODEL: [
            {
              operation_Enum: 1,
              company_Address_Id: 0,
              address_Type_Enum: 1,
              address_Text: 'string',
              landmark: 'string',
              city_Id: 0,
              district_Id: 0,
              state_Id: 0,
              country_Id: 0,
              is_Transaction_Address: true,
              acreage: 0,
            },
          ],
        },
        companY_AFFILIATE_REQUEST_MODEL: [
          {
            operation_Enum: 1,
            company_Affiliate_Id: 0,
            affiliate_Code: 'string',
            affiliate_Name: 'string',
            affiliate_Relation_Enum: 1,
            businesS_LOCATION_REQUEST_MODEL: [
              {
                operation_Enum: 1,
                company_Address_Id: 0,
                address_Type_Enum: 1,
                address_Text: 'string',
                landmark: 'string',
                city_Id: 0,
                district_Id: 0,
                state_Id: 0,
                country_Id: 0,
                is_Transaction_Address: true,
                acreage: 0,
              },
            ],
          },
        ],
        person_id: 0,
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      const url = `${baseUrl}/BioFuelRegistration/api/BusinessProfile/SaveAndUpdateBusiness`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);
      // console.log('Orgnization Response ----------------', resData);
      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const updatePersonalDetails = async (params) => {
    try {
      //sending these fields works
      // const backendFields = {
      //   person_id: 115,
      //   first_Name: 'bfc12',
      //   last_Name: 'bfc12',
      //   mobile_Number: '9999999913',
      //   whatsapp_Number: '9999999913',
      //   email_Id: 'bfc15@mailinator.com',
      //   type_Of_Individual_Enum: 1,
      //   gender_Enum: 1,
      //   hash_Tag_Id: [],
      //   address_Lattitude: '',
      //   address_Longitude: '',
      //   location_Id: 1586,
      //   village_Id: 0,
      //   taluka_Id: 0,
      //   city_Id: 0,
      //   district_Id: 0,
      //   state_Id: 0,
      //   country_Id: 0,
      //   address_Line1: '',
      //   address_Line2: '',
      //   address_Line3: '',
      //   login_Through_Enum: 2,
      //   is_Email_Verified: true,
      //   is_Phone_Verified: false,
      //   pin_Code: '',
      // };

      // console.log('backendFields ----->', backendFields);

      // const bodyParams = utils.getBackendParams(backendFields, params);

      // console.log('bodyParams ----->', bodyParams);

      const bodyParams = {
        // Type_Of_Individual_Enum_Text: '',
        // Gender_Enum_Text: '',
        // Login_Through_Enum_Code: '',
        // hash_tag_id: [1],
        city_code: '',
        ...params,
        // hash_tag_id: [1],
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        // Address_Text: params['location_code'] || '',

        // Address_Text: '',
        Address_Type_Enum_Code: '',
        // city_code: '',
        Gender_Enum_Text: '',
        Login_Through_Enum_Code: '',
        Type_Of_Individual_Enum_Text: '',
        address_lattitude: params['latitude'].toString(),
        address_longitude: params['longitude'].toString(),
      };

      // const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');

      if (!bodyParams['address_text']) {
        bodyParams['address_text'] = bodyParams['location_code'] || '';
      }

      const url = `${baseUrl}/BioFuelRegistration/api/UserProfile/UpdatePersonalDetails_ELoc`;

      const res = await utils.fetch(url, {
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const updateTransportDetails = async (params) => {
    // console.log('in updateTransportDetails ---', params);
    try {
      const backendFields = [
        {
          // Person_Id: _.get(window, 'user.userid', 0),

          transport_Detail_Id: 0,
          entity_Id: 2,
          user_Type_Enum: 1,
          company_Affiliate_Enum: 1,
          base_Location_Id: 1,
          // vehicle_Type_Enum: 1,
          goods_Type_Enum: 1,
          vehicle_Type_Id: 1,
          max_Load_Capacity: 1234,
          capacity_Uom_Id: 10,
          number_Of_Vehicles: 10,
          operation_Enum: 1,
        },
      ];

      // console.log(' trans response -----', backendFields);

      // const bodyParams = utils.getBackendParams(backendFields, params);

      const bodyParams = [
        {
          ...params,
          entity_id: params['person_id'] || _.get(window, 'user.userid', 0),
          vehicle_Type_Enum: params['vehicle_Type_Enum'],
          goods_Type_Enum: params['goods_Type_Enum'],
          //  number_Of_Vehicles: params['number_Of_Vehicles'],
          number_Of_Vehicles: 14,
          //  max_Load_Capacity: params['rated_Capacity'],
          max_Load_Capacity: 555,
          user_Type_Enum: 1,
          company_Affiliate_Enum: 1,
          base_Location_Id: 1,
          capacity_Uom_Id: 10,
          operation_Enum: 1,
        },
      ];

      // console.log(' bodyParams response -----', bodyParams);

      // const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');

      const url = `${baseUrl}/BioFuelRegistration/api/TransportDetails/SaveAndUpdateTransportDetails`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      console.log('updateTransportDetails->', err);

      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const deleteAccount = async (id) => {
    try {
      // const bodyParams = id;
      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      let url = `${baseUrl}/BioFuelRegistration/api/UserProfile/CloseAccount?Person_Company_Affiliate_Id=${id}&Person_Company_Affiliate_Enum=Person`;
      let res = await utils.fetch(url, {
        method: 'PUT',
        // body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('logout->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const fetchDeleteReasons = async (params) => {
    try {
      const url = `${baseUrl}/BioFuelRegistration/api/ReferenceData/GetEnumerator?Enum_Group=${params}`;
      const res = await utils.fetch(url, {
        method: 'GET',
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('api-AcceptEquipment_Bid->err---->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const sendOtpForDeleteAccount = async (params) => {
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/SendOTPForEquipment`;
      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('api-AcceptEquipment_Bid->err---->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const verifyOtpForDeleteAccount = async (params) => {
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/EquipmentDetails/VerifyOTPForEquipment`;
      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('api-AcceptEquipment_Bid->err---->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const deactivatePerson = async (selectedReasonId) => {
    try {
      let url = `${baseUrl}/BioFuelRegistration/api/UserProfile/DeactivatePerson?Reason_For_Account_Closure=${selectedReasonId}`;
      let res = await utils.fetch(url, {
        method: 'PUT',
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('logout->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const updateShippingBillingAddress = async (params) => {
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateShippingBillingAddress`;
      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('api-AcceptEquipment_Bid->err---->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const UpdateOldAddressInDeliveryAudit = async (params) => {
    try {
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateOldAddressInDeliveryAudit`;
      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
      }).ready;
      const resData = await utils.processApiRes(res);
      return resData;
    } catch (err) {
      console.log('api-AcceptEquipment_Bid->err---->', err);
      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  return {
    fetchSocialProfile,
    fetchPersonalDetails,
    fetchOrganizationDetails,
    // fetchFarmCrops,
    // updateFarmCrops,
    fetchAllTransportDetails,
    saveAndUpdateBusiness,
    updatePersonalDetails,
    updateTransportDetails,
    fetchKYCIdentityDetails,
    deleteAccount,
    fetchDeleteReasons,
    sendOtpForDeleteAccount,
    verifyOtpForDeleteAccount,
    deactivatePerson,
    updateShippingBillingAddress,
    UpdateOldAddressInDeliveryAudit,
  };
})();

export default api;
