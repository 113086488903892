import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import KycForm from './components/kycForm';
import useQuery from 'lib/hooks/useQuery';
import CompanyAffiliateTable from '../components/CompanyAffiliateTable';
import UploadForm from '../UploadBusiness/components/uploadForm';
import FinanceForm from '../finance/components/financeForm';

// import scss file here
import 'antd/dist/antd.css';
import { getDecryptedUrl } from 'lib/utils';

const getInitialValues = (kycDetails) => {
  let params = {
    company_tax_detail_id: _.get(kycDetails, 'company_tax_detail_id') || 0,
    company_affiliate_enum: _.get(kycDetails, 'company_affiliate_enum') || 0, //@TODO Hardcode
    business_pan_id: _.get(kycDetails, 'business_pan_id') || '',
    name_as_per_pan: _.get(kycDetails, 'name_as_per_pan') || '',
    cin: _.get(kycDetails, 'cin') || '',
    llpin: _.get(kycDetails, 'llpin') || '',
    pan_of_authorized_signatory: _.get(kycDetails, 'pan_of_authorized_signatory') || '',
    u_aadhar: _.get(kycDetails, 'u_aadhar') || '',
    name_of_authorized_signatory: _.get(kycDetails, 'name_of_authorized_signatory') || '',
    same_as_registered_address_flag: _.get(kycDetails, 'same_as_registered_address_flag') || false,

    business_type_enum: _.get(kycDetails, 'business_type_enum') || 0,
    identity_type_enum: _.get(kycDetails, 'identity_type_enum') || 0, //@TODO Hardcode
    identity_type_enum_code: _.get(kycDetails, 'identity_type_enum_code') || '', //@TODO Hardcode

    location_id: _.get(kycDetails, 'location_id') || 0,
    location_code: _.get(kycDetails, 'location_code') || '',
    village_id: _.get(kycDetails, 'village_id') || 0,
    village_code: _.get(kycDetails, 'village_code') || '',
    taluka_id: _.get(kycDetails, 'taluka_id') || 0,
    taluka_code: _.get(kycDetails, 'taluka_code') || '',
    city_id: _.get(kycDetails, 'city_id') || 0,
    city_code: _.get(kycDetails, 'city_code') || '',
    district_id: _.get(kycDetails, 'district_id') || 0,
    district_code: _.get(kycDetails, 'district_code') || '',
    state_id: _.get(kycDetails, 'state_id') || 0,
    state_code: _.get(kycDetails, 'state_code') || '',
    country_id: _.get(kycDetails, 'country_id') || 0,
    country_code: _.get(kycDetails, 'country_code') || '',
    address_lattitude: _.get(kycDetails, 'lattitude') || '',
    address_longitude: _.get(kycDetails, 'longitude') || '',
    address_line_1: _.get(kycDetails, 'address_line_1') || '',
    address_line_2: _.get(kycDetails, 'address_line_2') || '',
    address_line_3: _.get(kycDetails, 'address_line_3') || '',
    pin_code: _.get(kycDetails, 'pin_code') || '',
    company_address_id: _.get(kycDetails, 'company_address_id') || 0,
  };
  return params;
};

const KYCBusinessIdentity = (props) => {
  const id = props.id;
  const { back } = useQuery();
  // const user = useSelector((state) => state.user);

  let pathname = getDecryptedUrl(window.location.href);

  const [selectedCompany, setSelectedCompany] = useState({});
  // const kycBusiness = useSelector((state) => state.kycBusiness);
  // const kycDetails = _.get(kycBusiness, 'entities') || {};

  // const company_tax_detail_id = _.get(kycDetails, 'company_tax_detail_id') || 0;

  const onChangeHandler = (company) => {
    setSelectedCompany(company);
  };

  return (
    <div className="bfc-body bfc-form profile-height-body">
      <Formik
        // initialValues={getInitialValues(kycDetails)}
        // onSubmit={company_tax_detail_id ? handlers.handleUpdate : handlers.handleCreate}
        >
        {(formikBag) => {
          // const { values } = formikBag;
          // const { kyc } = values;
          // values.entity_id = selectedCompany;
          return (
            <Form className="form-container">
              <div className="farm-wrapper">
                <div className="farm-table">
                  <div className="table-farms-wrapper">
                    <div className="ant-table-body ant-table">
                      <CompanyAffiliateTable id={id} onChangeInUser={onChangeHandler} company={selectedCompany} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {!_.isEmpty(selectedCompany) && pathname.includes('kyc-b') && (
                  <KycForm
                    entity_id={selectedCompany.value_member}
                    company_affiliate_enum={selectedCompany.company_affiliate_enum}
                    id={id}
                    kyc_status_enum_code={selectedCompany.kyc_status_enum_code}
                    display_member={selectedCompany.display_member}
                  />
                )}
                {!_.isEmpty(selectedCompany) && pathname.includes('upload') && (
                  <UploadForm
                    entity_id={selectedCompany.value_member}
                    company_affiliate_enum={selectedCompany.company_affiliate_enum}
                    person_company_affiliate_enum={selectedCompany?.person_company_affiliate_enum}
                    id={id}
                    kyc_status_enum_code={selectedCompany.kyc_status_enum_code}
                    display_member={selectedCompany.display_member}
                  />
                )}
                {!_.isEmpty(selectedCompany) && pathname.includes('finance') && (
                  <FinanceForm
                    entity_id={selectedCompany.value_member}
                    company_affiliate_enum={selectedCompany.company_affiliate_enum}
                    id={id}
                    kyc_status_enum_code={selectedCompany.kyc_status_enum_code}
                    display_member={selectedCompany.display_member}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default KYCBusinessIdentity;
