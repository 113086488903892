import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';

import { KycBusinessIdentityService, kycUserIdentityService } from 'services';
import { actions as kycBusinessActions } from 'redux/kycBusiness';
import { actions as kycBusinessBankIdentityActions } from 'redux/kycBusinessBankIdentity';
import { actions as kycBusinessTaxIdentityActions } from 'redux/kycBusinessTaxIdentity';

//KYC
export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Business Identity ...',
      key: 'req-form',
    });

    const res = await KycBusinessIdentityService.create(values);
    notifications.show({
      type: 'success',
      message: 'Business Identity Created.',
      key: 'req-form',
    });

    store.dispatch(kycBusinessActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/kyc-b`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateStatus = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating KYC Status',
      key: 'req-form',
    });

    // console.log('values in handlers', values);
    const res = await kycUserIdentityService.createStatus(values);
    notifications.show({
      type: 'success',
      message: 'KYC Status Updated.',
      key: 'req-form',
    });

    redirect.web(`/kycapproval/business`);

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Tax
export const handleTaxCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Tax Identity ...',
      key: 'req-form',
    });

    const res = await KycBusinessIdentityService.createTax(values);
    notifications.show({
      type: 'success',
      message: 'Tax identity created',
      key: 'req-form',
    });

    store.dispatch(kycBusinessTaxIdentityActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/kyc-b`));

    return res;
  } catch (err) {
    console.log('handleTaxCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Bank
export const handleBankCreate = async (values, formikBag, setTimestamp) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Bank Identity ...',
      key: 'req-form',
    });

    const res = await KycBusinessIdentityService.createBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Identity created',
      key: 'req-form',
    });
    // setTimestamp(Date.now());
    store.dispatch(kycBusinessBankIdentityActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/kyc-b`));

    return res;
  } catch (err) {
    console.log('handleBankCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Bank
export const handleNewBankCreate = async (values, formikBag, setTimestamp, saveBankSchema) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Bank Identity ...',
      key: 'req-form',
    });

    const res = await KycBusinessIdentityService.createBank(values, saveBankSchema);
    notifications.show({
      type: 'success',
      message: 'Bank Identity created',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    console.log('handleBankCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//kyc
export const handleUpdate = async (values, formikBag, setTimestamp) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Business Identity ...',
      key: 'req-form',
    });
    const id = _.get(values, 'company_tax_detail_id') || 0;
    const res = await KycBusinessIdentityService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Individual Business details updated.',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Tax
export const handleTaxUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Business Tax Identity ...',
      key: 'req-form',
    });
    const id = _.get(values, 'company_gst_detail_id') || 0;
    const res = await KycBusinessIdentityService.updateTax(id, values);
    notifications.show({
      type: 'success',
      message: 'Tax Details updated',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleTaxUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Bank
export const handleBankUpdate = async (values, formikBag, setTimestamp, saveBankSchema) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Business Bank Identity ...',
      key: 'req-form',
    });
    const id = _.get(values, 'company_bank_address_id') || 0;
    const res = await KycBusinessIdentityService.updateBank(id, values, saveBankSchema);
    notifications.show({
      type: 'success',
      message: 'Bank Details updated',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    console.log('handleBankUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await affiliateService.destroy(id);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}
export const handleEKycBusinessPan = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Documents ...',
      key: 'req-form',
    });

    const res = await KycBusinessIdentityService.eKycBusinessPanVerification(values);
    notifications.show({
      type: 'success',
      message: _.get(res, 'kyc_message') || res,
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleEKyc--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
export const handleverifyIFSC = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Bank ...',
      key: 'req-form',
    });
    const res = await kycUserIdentityService.verifyIFSC(values);
    notifications.show({
      type: 'success',
      message: 'Details verified',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleverifyIFSC--->error---->', err);
    notifications.show({
      type: 'error',
      message: err,
      key: 'req-form',
    });
  }
};

export const handleBankVerification = async (values, setTimestamp) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.verifyBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Details Verified.',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    const er = Object.values(err.errors);
    console.log('handleBankVerification--->error---->', err, er);
    const error = _.get(err, 'zoop_error') || er[0];
    notifications.show({
      type: 'error',
      message: error,
      key: 'req-form',
    });

    setTimestamp(Date.now());
  }
};

export const handleDeleteBank = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Deleting Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.deleteBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Details Deleted.',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleDeleteBank--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
