import React from 'react';
import PropTypes from 'prop-types';

import utils from 'lib/utils';
import { Link } from 'react-router-dom';

import BackbuttonIcon from 'assets/images/icons/back-button.svg';

function Header({ heading, headingIcon, backLink, rightPanContent }) {
  //TODO : tmp fix
  let tmpBkLink = '/network';
  if (window.location.href.includes('/network/')) {
    tmpBkLink = '/network';
  }

  return (
    <div className="bfc-header bfc-invoice-header">
      <div className="content-wrapper">
        {backLink && (
          <div className="bck-btn">
            <Link to={backLink || tmpBkLink}>
              <img src={BackbuttonIcon} alt="" />
            </Link>
          </div>
        )}

        <div className="content-block">
          <div className="top-content">
            {heading && (
              <h2 className="title">
                {headingIcon && (
                  <span>
                    {headingIcon}
                  </span>
                )}{' '}
                {heading}
              </h2>
            )}
          </div>
        </div>
      </div>
      <div className="btn-wrapper">{rightPanContent}</div>
    </div>
  );
}

Header.propTypes = {
  heading: PropTypes.string.isRequired,
  headingIcon: PropTypes.element,
  backLink: PropTypes.string,
  rightPanContent: PropTypes.element,
};

export default Header;
