import utils from './../utils';

const validateValues = (values) => {
  const errors = {};
  const isMobile = utils.isNumber(values.user_name);

  if (!values.first_name) {
    errors['first_name'] = 'First Name is required';
  }

  if (!values.last_name) {
    errors['last_name'] = 'Last Name is required';
  }

  if (values.email_id && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_id)) {
    errors['email_id'] = 'Invalid email address';
  }

  if (!isMobile && !values.email_id) {
    errors['email_id'] = 'Email is required';
  }

  if (parseInt(values.country_id) == 1) {
    if (!values.mobile_number) {
      errors['mobile_number'] = 'Mobile number is required';
    } else if (utils.isNaN(values.mobile_number)) {
      errors['mobile_number'] = 'Invalid mobile number';
    } else if (`${values.mobile_number}`.length !== 10) {
      errors['mobile_number'] = 'Invalid mobile number';
    }
  }

  if (!values.company_id) {
    // errors['company_id'] = 'Organisation is required';
  }

  if (!values.location_code) {
    errors['location_code'] = 'Location is required';
  }

  // if (!values.address_type_enum) {
  //   errors['address_type_enum'] = 'Address Type is required';
  // }

  // if (!values.user_type_enum) {
  //   errors['user_type_enum'] = 'User Type is required';
  // }

  if (values.is_newsletter_subscribed === undefined) {
    errors['is_newsletter_subscribed'] = 'This field is required';
  }

  return errors;
};

export default validateValues;
