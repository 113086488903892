import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { membersService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (deliveryScheduleDay) => _.get(deliveryScheduleDay, 'delivery_start_date_string', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => (a.name_of_farm || '').localeCompare(b.name_of_farm || ''),
});

const slice = createSlice({
  name: 'members',
  initialState: adapter.getInitialState({
    busy: false,
    linkedMembers: [],
    existingMembers: [],
    farmers: [],
    groups: [],
    socialGroupDetails: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setLinkedMembers: (state, { payload }) => ({ ...state, linkedMembers: payload }),
    setExistingMembers: (state, { payload }) => ({ ...state, existingMembers: payload }),
    setFarmers: (state, { payload }) => ({ ...state, farmers: payload }),
    setGroups: (state, { payload }) => ({ ...state, groups: payload }),
    setSocialGroupDetails: (state, { payload }) => ({ ...state, socialGroupDetails: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchGroupsAgainstInvokingPersonId = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.fetchGroupsAgainstInvokingPersonId(params);
    dispatch(slice.actions.setGroups(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const linkExistingFarmer = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.linkExistingFarmer(params);
    dispatch(slice.actions.setLinkedMembers(resData));
    3;
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
    dispatch(slice.actions.setBusy(false));
  }
};

const addSingleFarmer = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.addSingleFarmer(params);
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchMembersAgainstBfSocialGroupId = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.fetchMembersAgainstBfSocialGroupId(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllFarmers = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.fetchAllFarmers(params);
    dispatch(slice.actions.setFarmers(resData));
  } catch (err) {
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const importMembers = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.importMembers(params);
    dispatch(slice.actions.setFarmers(resData));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const fetchSocialGroupDetailsAgainstBfSocialGroupId = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.fetchSocialGroupDetailsAgainstBfSocialGroupId(params);
    dispatch(slice.actions.setSocialGroupDetails(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const removeParticipantFromGroup = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    return await membersService.removeParticipantFromGroup(params);
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const updateFPOMembershipId = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    return await membersService.updateFPOMembershipId(params);
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const verifyMobileNumber = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await membersService.verifyMobileNumber(params);
    dispatch(slice.actions.setExistingMembers(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
    dispatch(slice.actions.setBusy(false));
    return err;
  }
};

const actions = {
  ...slice.actions,
  fetchGroupsAgainstInvokingPersonId,
  linkExistingFarmer,
  addSingleFarmer,
  fetchMembersAgainstBfSocialGroupId,
  fetchAllFarmers,
  importMembers,
  fetchSocialGroupDetailsAgainstBfSocialGroupId,
  removeParticipantFromGroup,
  updateFPOMembershipId,
  verifyMobileNumber,
};

const selectors = {
  ...adapter.getSelectors((state) => state.locationEloc),
};

export { actions, selectors };

export default slice.reducer;
