import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import DealModal from 'pages/network/qualityServices/components/dealModal';
import { useSelector } from 'react-redux';

function AntTableActions(props) {
  const { row, handleDelete, id, name, cancel, showFlag, show, deleteFlag, toggle, params, myCommodityName, isVehicle = false, row2, attachDealLink, setIsOpen, setSelectedRowData, setClosePopup, setIsEdit, isRecordSaved, isDriver, isQualityServices } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const personRoles = _.get(userInfo, 'profile.person_role') || "";
  const hasCTSuperuserRole = personRoles.some(obj => obj.role_code === "CT Superuser");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const menu = (row) => {
    const handleMenuClick = (e) => {
      if (e.key === 'share') {
        showModal();
      }
    };
    return (
      <Menu>
          {!toggle ? (
            !show ? isVehicle ? <Menu.Item><a  herf="#" onClick={() => {
              row.setVehicleData(row)
              row.setOpen(true);
              row.setEdit(true);
          }}>Edit</a></Menu.Item> : setIsOpen ? <Menu.Item onClick={() => (setSelectedRowData(row), setIsOpen(true), setIsEdit(true), setClosePopup(false))}>Edit</Menu.Item> : <Menu.Item><Link to={row.editPageLink}>Edit</Link></Menu.Item>
            :
              <>
                <Menu.Item>
                  <Link to={row}>Create {myCommodityName === 'requirements' ? 'Buy' : 'Sell'}</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={row2}>Create Auction</Link>
                </Menu.Item>
                {attachDealLink ? <Menu.Item>
                  <Link to={attachDealLink}>Link {myCommodityName === 'requirements' ? 'Buy' : 'Sell'} Deal</Link>
                </Menu.Item> : ''}
              </>
          ) : (
            <Menu.Item>
              <span>
                <a herf="#" onClick={(e) => row.showDetailsPopup(e, row)}>
                  Edit
                </a>
              </span>
            </Menu.Item>
          )}
        {(row?.stock_in_out_type_enum !== 8 && row?.stock_in_out_type_enum !== 9) && <Popconfirm
          title={`Are you sure to delete?`}
          onConfirm={() => (params ? handleDelete(id, params) : handleDelete(id, isRecordSaved))}
          onCancel={cancel}
          okText="Yes"
          cancelText="No">
          {deleteFlag != false && (
            <Menu.Item danger>
              <span>Delete</span>
            </Menu.Item>
          )}
        </Popconfirm>}
        {
          hasCTSuperuserRole && isQualityServices && (
            <Menu.Item  onClick={handleMenuClick} key="share"> Share </Menu.Item>
          )
        }
      </Menu>
    );
  };
  return (
    <div>
    <Dropdown overlay={menu(row)} placement="bottomCenter">
      {showFlag ? (
        <a className="ant-dropdown-link d-flex justify-content-center" onClick={(e) => e.preventDefault()}>
          Actions <DownOutlined className="mt-10 ml-5" />
        </a>
      ) : (
        <div></div>
      )}
    </Dropdown>
    {isModalVisible &&
      <DealModal visible={isModalVisible} onCancel={handleCancel} row={row} DealId={id} role={"Network"}/>}
    </div>
  );
}

export default AntTableActions;
