import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const resTransform = {
  fetchRoles(resData) {
    let rolesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(rolesData, 'value_member');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'value_member') || 0,
    };
    return data;
  },
};
