import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';

function AntTableKYCActions(props) {
  const { row, handleDelete, id, name, cancel, showFlag, toggle, params, setBankRow, removeBankDetails,entityIdBank,setShowUpiPopupForm, setSelectedRow, isFacilitator } = props;
  const menu = (row) => {
    return (
      <Menu>
        <Menu.Item>
          <span onClick={() => row.is_vpa || row?.vpa_id || row?.VPA_Id ? (setShowUpiPopupForm(true), setSelectedRow({...row, operation_enum: 3})) : setBankRow(0)} style={{color: 'var(--unnamed-color-1b62ab)'}}>Edit</span>
        </Menu.Item>
        <Popconfirm
          title={`Are you sure to delete?`}
          onConfirm={() => removeBankDetails(isFacilitator ? row : params)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No">
          <Menu.Item danger>
            <span>Delete</span>
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };
  return (
    <Dropdown overlay={menu(row)} placement="bottomCenter">
      {showFlag ? (
        <a className="ant-dropdown-link d-flex justify-content-center" onClick={(e) => e.preventDefault()}>
          Actions <DownOutlined className="mt-10 ml-5" />
        </a>
      ) : (
        <div></div>
      )}
    </Dropdown>
  );
}

export default AntTableKYCActions;
