import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { resTransform } from './transforms';

const { baseUrl } = config;

export const fetchFacilitatorLog = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRegistration/api/Facilitator/FetchFacilitatorLog`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchReportList(resData);
    // return resData?.map((item, index) => ({ ...item, id: index }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'FetchFacilitatorLog');
    throw errors;
  }
};
