import { decryptionInHex } from 'lib/utils';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const parsedUrl = useLocation().search;
  if (parsedUrl) {
    const queryParams = parsedUrl.split('?')[1];
    const isAlreadyEncrypted = decryptionInHex(queryParams);
    if (isAlreadyEncrypted) {
      const queryParamObject = queryString.parse(`?${isAlreadyEncrypted}`);
      if (Object.keys(queryParamObject).includes('back')) {
        queryParamObject.back = queryParamObject?.back === 'undefined' ? '' : queryParamObject?.back;
      }
      return queryParamObject;
    }
  }
  return queryString.parse(useLocation().search);
}
