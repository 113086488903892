import _ from 'lodash';
import dayjs from 'dayjs';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      // person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  fetchListCT(params = {}) {
    let qsParams;

    qsParams = {
      ...params,
      invoking_person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    };

    return qsParams;
  },
  revertDeliveries(params = {}) {
    let bodyParams;
    bodyParams = params;
    return bodyParams;
  },

  deleteDeliveries(params = {}) {
    let bodyParams;
    bodyParams = params;
    return bodyParams;
  },

  revertDeliveriesQueryParams(params = {}) {
    const qsParams = {
      RevertDeliveryPhaseEnum: params['revertEnum'],
      Reversal_Reason: params['reversalReason'],
    };
    return utils.getQs(qsParams);
  },

  deleteDeliveriesQueryParams(params = {}) {
    return utils.getQs(params);
  },

  fetchInvoiceListCT(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };
    return qsParams;
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  deleteDealsOrDeliveriesFromLink(params) {
    let bodyParams = {
      deal_delivery_enum: 1,
      deal_delivery_id: params['id'],
      to_deal_delivery_id: params['linkedDeal'],
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let kycApprovalData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(kycApprovalData, 'table_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'table_id') || 0,
    };
    return res;
  },

  fetchListCT(resData) {
    let deliveriesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetailsCT(details);
    });
    return _.keyBy(deliveriesData, 'transport_delivery_schedule_alloted_id');
  },
  revertDeliveries(resData) {
    return resData;
  },
  updateUnconfirmedDeliverySchedule(resData) {
    return resData;
  },
  fetchDetailsCT(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'transport_delivery_schedule_alloted_id') || 0,
    };
    return data;
  },

  fetchInvoiceListCT(resData) {
    let invoicesData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchInvoiceDetailsListCT(details);
    });
    return _.keyBy(invoicesData, 'invoice_master_id');
  },
  fetchInvoiceDetailsListCT(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'invoice_master_id') || 0,
      created_datetime: resData.created_datetime && dayjs(resData.created_datetime).format('DD-MMM-YYYY'),
      invoice_date: resData.invoice_date && dayjs(resData.invoice_date).format('DD-MMM-YYYY'),
      payment_due_date: resData.payment_due_date && dayjs(resData.payment_due_date).format('DD-MMM-YYYY'),
      buyer_order_date: resData.buyer_order_date && dayjs(resData.buyer_order_date).format('DD-MMM-YYYY HH:mm:ss a'),
      eway_bill_date: resData.eway_bill_date && dayjs(resData.eway_bill_date).format('DD-MMM-YYYY  HH:mm:ss a'),
    };
    return data;
  },

  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  deleteDealsOrDeliveriesFromLink(resData) {
    return resData;
  },
};
