import _ from 'lodash';
import React from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from 'mdi-react/CloseIcon';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

const dataCache = {};

function CommodityResidueSelect(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);
  const [commodityClear, setCommodityClear] = React.useState(false);

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    placeholder,
    qualityLab,
    getTransportationCharges,
    formik,
    isBidOfferPopup,
    is_any_commodity,
    isSell,
    setDefaultResidueId,
    qualityProfile,
    ...rest
  } = props;

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }

  let entityData = useSelector((state) => {
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams).join('');

      return state.referenceData.data[entity][qId] || [];
    }

    const found = state.referenceData.data[entity];

    return Array.isArray(found) ? found : [];
  });

  const loading = values?.is_any_commodity && entityData.length === 0 ? true : open && entityData && entityData.length === 0;

  const value_member = _.get(values, field.name, 0);
  let selectedValue;
  if (props.isStorageScreen && props.isEdit) {
    selectedValue = entityData.find((e) => e.commodity_residue_master_id === value_member);
  } else if (typeof value_member === 'string') {
    selectedValue = entityData.find((e) => e.display_member === value_member);
  } else {
    selectedValue = entityData.find((e) => e.value_member === value_member);
  }

  if (!selectedValue) {
    selectedValue = { value_member: value_member, display_member: value_member ? initialDisplayText : '' };
  }

  const clearCommodity = () => {
    setCommodityClear(true);
    props.form.setFieldValue('commodity_residue_id', '');
    selectedValue = { value_member: "", display_member: ""};
  };

  React.useEffect(() => {
    let active = true;

    const loadOptions = async () => {
      if (!entityData.length) {
        if (!_.isEmpty(qparams.commodity_type_code) && !_.isEmpty(qparams.commodity_code)) {
          try {
            dispatch(refDataActions.getCommodityDropdownData(entity, qparams));

            dataCache[entity] = entityData;
            setOptions(entityData);
          } catch (err) {
            console.log('getCommodityDropdownData error', err);

            if (tries < 3) {
              setTries(tries + 1);
            }
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams]);

  React.useEffect(() => {
    if (!open && !isBidOfferPopup) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    getTransportationCharges && formik && getTransportationCharges(formik)
  }, [values])

  React.useEffect(() => {
    if(isBidOfferPopup && entityData.length) {
      entityData = entityData.filter(option => option.value_member !== 99)
      setOptions(entityData)
    }
  }, [isBidOfferPopup, entityData])

  React.useEffect(() => {
    if(is_any_commodity && entityData.length) {
      const commodityData = entityData.find(option => option?.display_member === 'Any Residue')
      props.form.setFieldValue('commodity_residue_id', commodityData?.value_member);
      props.form.setFieldValue('commodity_residue_code', commodityData?.display_member);
    }
  }, [is_any_commodity, entityData])

  React.useEffect(() => {
    if (isSell && entityData.length) {
      const updatedOptions = entityData.filter(item => item.display_member !== 'Any Residue')
      setOptions(updatedOptions);
    }
  }, [isSell, entityData]);

  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  return (
    <div className={`bfc-ref-data-select-wrapper`}>
      <Autocomplete
        // value={selectedValue}
        value={qualityProfile ? selectedValue.display_member != "undefined - undefined" ? selectedValue : '' : selectedValue}
        id={`${entity}-autocomplete-${timestamp}`}
        defaultValue={'initialDisplayText' || ''}
        disabled={!!disabled}
        disableClearable
        autoHighlight
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(o, value) => {
          if (o?.display_member && value?.display_member) {
            return o.display_member.trim().toLowerCase() === value.display_member.trim().toLowerCase();
          }

          if (o?.display_member && value?.display_member) {
            return o.display_member.trim().toLowerCase() === value.display_member.trim().toLowerCase();
          }

          return false;
        }}
        getOptionLabel={(o) => {
          if (o.display_member) {
            return o.display_member;
          }
          if (o.display_member) {
            return o.display_member;
          }

          return '';
        }}
        options={(isBidOfferPopup || isSell) ? _.orderBy(options, ['display_member']) : _.orderBy(entityData, ['display_member'])}
        loading={loading}
        onChange={(event, newValue) => {
          if (newValue != null) {
            let entity_id = newValue?.value_member;
            let entity_code = newValue?.display_member;
            const d = entityData.find((c) => c.display_member === newValue.display_member);
            if (d && d.value_member) {
              entity_id = d.value_member;
              entity_code = d.display_member;
            }

            props.form.setFieldValue(field.name, entity_id);
            props.form.setFieldValue('commodity_residue_code', entity_code);
            if (qualityProfile) {
              setDefaultResidueId(entity_id)
            }
            // setDefaultResidueId && setDefaultResidueId(entity_id)
            if (props.onCallback) {
              props.onCallback(entity_id);
            }
            if (props.cb) {
              props.cb(newValue);
            }

            if (dependentFields && dependentFields.length) {
              for (let df of dependentFields) {
                props.form.setFieldValue(df, 0);
              }
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className="material-form__field"
            style={{ backgroundColor: 'white' }}
            label={label}
            error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
            children={children}
            helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
            FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={placeholder}
          />
        )}
      />
      {(selectedValue?.display_member === "" || selectedValue?.display_member === "undefined - undefined") ? (
        ''
      ) : (
        <>
          {qualityProfile && (
            <CloseIcon
              className="w-auto quality-profile-clear__icon"
              onClick={() => {
                clearCommodity();
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default CommodityResidueSelect;
