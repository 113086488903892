import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import utils from 'lib/utils';

import './styles.scss';

function DueDateField({ closing_on }) {
  return (
    <div className="bfc-table-list-components">
      <div className="closing-date-field text-center">
        {closing_on ? utils.formatDate(closing_on) : <div className="bfc-table-list-components text-center">-</div>}
      </div>
    </div>
  );
}

DueDateField.propTypes = {
  closing_on: PropTypes.string.isRequired,
};

export default DueDateField;
