import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';

import { affiliateService } from 'services';
import { actions as affiliateActions } from 'redux/affiliates';
import { actions as locationsActions } from 'redux/locations';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Affiliate...',
      key: 'req-form',
    });

    const res = await affiliateService.create(values);
    notifications.show({
      type: 'success',
      message: 'Affiliate Created.',
      key: 'req-form',
    });

    globalThis.store.dispatch(affiliateActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/organisations`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Affiliate Details...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_address_id') || 0;
    const res = await affiliateService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Affiliate details updated.',
      key: 'req-form',
    });

    globalThis.store.dispatch(affiliateActions.setDirty());
    globalThis.store.dispatch(locationsActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/organisations`));

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await affiliateService.destroy(id);
    globalThis.store.dispatch(affiliateActions.setDirty());
    // redirect.web('/account/organisations');
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
  return false;
}
