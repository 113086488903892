import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import { bannerAndFollowPreferencesService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'bannerAndFollowPreferences',
  initialState: adapter.getInitialState({
    busy: false,
    personCompanyData: {},
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setPersonCompanyData: (state, { payload }) => ({ ...state, personCompanyData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchFollowPreferences = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await bannerAndFollowPreferencesService.fetchFollowPreferences(params);
    // const resData = await bannerAndFollowPreferencesService.callFollowPreferenceApi(params, 'FetchFollowPreference');
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchFollowPreferences_CT = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await bannerAndFollowPreferencesService.fetchFollowPreferences_CT(params);
    // const resData = await bannerAndFollowPreferencesService.callFollowPreferenceApi(params, 'FetchFollowPreference_CT');
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchPersonCompanyData = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await bannerAndFollowPreferencesService.fetchPersonCompanyData(params);
    // const resData = await bannerAndFollowPreferencesService.callFollowPreferenceApi(params, 'FetchCompaniesAndPersons');
    dispatch(slice.actions.setPersonCompanyData(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const saveUpdateFollowPreference = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await bannerAndFollowPreferencesService.saveUpdateFollowPreference(params);
    return resData;
    // await bannerAndFollowPreferencesService.callFollowPreferenceApi(params, 'SaveUpdateFollowPreference');
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteFollowPreference = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await bannerAndFollowPreferencesService.callFollowPreferenceApi(
      params,
      'DeleteFollowPreference',
      true,
    );
    // dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchFollowPreferences,
  fetchFollowPreferences_CT,
  fetchPersonCompanyData,
  saveUpdateFollowPreference,
  deleteFollowPreference,
};

const selectors = {
  ...adapter.getSelectors((state) => state.bannerAndFollowPreferences),
};

export { actions, selectors };

export default slice.reducer;
