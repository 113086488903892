import React from 'react';

import _ from 'lodash';
import { ThemeProvider, Typography, TypographyProps, createTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import getColor from '../colors';
import { colord as colorFormat } from 'colord';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

type TypographyPropTypes = {
  type?: 'text' | 'number' | 'currency';
  color?: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  children: React.ReactNode;
  display?: 'initial' | 'block' | 'inline';
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant?: Variant | 'inherit';
  variantMapping?: Partial<Record<Variant, string>>;
  component?: any;
  style?: object;
};

const theme = createTheme({
  typography: {
    fontFamily: ['"Segoe UI"', 'Roboto'].join(','),
  },
});

const formatNumber = (number, type, maximumFractionDigits = 4, country = 'en-IN', currency = 'INR') => {
  const maxDecimalPointsAllowed = `${number}`.includes('.') ? { maximumFractionDigits } : { maximumFractionDigits: 0 };
  let options;
  if (type === 'currency') {
    options = { style: 'currency', currency, ...maxDecimalPointsAllowed };
  } else {
    options = { ...maxDecimalPointsAllowed };
  }
  let formattedPrice = new Intl.NumberFormat(country, options).format(number);
  return formattedPrice;
};

const MUITypography = ({ type = 'text', color, children, variant, component, style, ...rest }: TypographyPropTypes) => {
  const state = useSelector((state) => state);
  const config = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(color || config?.theme_color || 'black');
  const isLight = colorFormat(bgColor).isLight();
  const textColor = isLight ? colorFormat(bgColor).darken(1).toHex() : colorFormat(bgColor).lighten(1).toHex();

  return (
    <ThemeProvider theme={theme}>
      <Typography
        {...rest}
        variant={variant}
        component={component}
        style={{ color: bgColor, letterSpacing: '1px', cursor: rest?.['onClick'] ? 'pointer' : 'default', ...style }}
      >
        {type === 'text' ? children : formatNumber(children, type)}
      </Typography>
    </ThemeProvider>
  );
};
export default MUITypography;
