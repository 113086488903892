import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import createAuth0Client from '@auth0/auth0-spa-js';
import { actions as userActions } from './../../redux/user';
import _ from 'lodash';

const withBFCAuth = (WrappedComponent) => {
  const HocAuth = (props) => {
    const user = {
      ...props.user,
      actions: {
        ...props.userActions,
      },
    };

    return <WrappedComponent {...props} user={user} />;
  };

  const mapStateToProps = (state) => {
    return {
      // user : state.user,
      user: state.user,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      userActions: bindActionCreators(userActions, dispatch),
    };
  };

  // const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(withBFCAuth)
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(HocAuth));
};

export default withBFCAuth;
