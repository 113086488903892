import _ from 'lodash';
import * as yup from 'yup';

import * as helpers from 'services/helpers';
import * as api from './api';

export const GetNearByVendors = async (filters, id) => {
  try {
    let res = await api.GetNearByVendors(filters, id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchSellersAgainstCommodityReqOfferId = async (params) => {
  try {
    let res = await api.FetchSellersAgainstCommodityReqOfferId(params);
    return res;
  } catch (err) {
    throw err;
  }
};
