import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';
import store from 'redux/store';
import { deliveriesAllotmentService } from 'services';

export const handleCloseSubmit = async (values, deliveryArray, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Sending request...',
      key: 'req-form',
    });

    const res = await deliveriesAllotmentService.updateUnusedDeliveryStatus(values, deliveryArray);
    notifications.show({
      type: 'success',
      message: 'Request Sent.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCloseSubmit--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
