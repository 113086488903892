import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/delivery-truck-2.svg';

function DeliveryTruckIcon2(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default DeliveryTruckIcon2;
