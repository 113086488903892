import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// const companiesAdapter = createEntityAdapter({
//   // Assume IDs are stored in a field other than `book.id`
//   selectId: (company) => company.company_id,
//   // Keep the "all IDs" array sorted based on book titles
//   sortComparer: (a, b) => a.company_name.localeCompare(b.company_name),
// });

const slice = createSlice({
  name: 'debug',
  // initialState: booksAdapter.getInitialState(),
  initialState: {
    data: {},
    apiErrors: {},
    error: {},
    open: false,
  },
  reducers: {
    update(state, action) {
      state = {
        ...state,
        ...action.payload,
      };

      if (action.payload.flush) {
        state = action.payload;
      }

      return state;
    },
    toggle(state, action) {
      state.open = !state.open;
    },
  },
});

const actions = {
  ...slice.actions,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
