import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';

import { businessService, locationService } from 'services';
import { actions as locationActions } from 'redux/locations';

export const handleCreate = async (values, formikBag, setIsRerenderRequired, isRerenderRequired, page) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Location...',
      key: 'req-form',
    });
    values.address_line1 = values.address_line1.trim();
    values.pin_code = values.pin_code.trim();
    values.location_code = values.location_code.trim();
    const res = await locationService.create(values);
    notifications.show({
      type: 'success',
      message: 'Location Created.',
      key: 'req-form',
    });

    // globalThis.store.dispatch(locationActions.setDirty());
    setIsRerenderRequired(!isRerenderRequired);
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/organisations?page=${page || 1}`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    formikBag.setSubmitting(false);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag, setIsRerenderRequired, isRerenderRequired, page) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Saving Location Details...',
      key: 'req-form',
    });
    values.address_line1 = values.address_line1.trim();
    values.pin_code = values.pin_code.trim();
    values.location_code = values.location_code.trim();
    const id = _.get(values, 'person_address_id') || 0;
    const res = await locationService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Location details updated.',
      key: 'req-form',
    });

    // globalThis.store.dispatch(locationActions.setDirty());
    setIsRerenderRequired(!isRerenderRequired);
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/organisations?page=${page || 1}`));

    return res;
  } catch (err) {
    formikBag.setSubmitting(false);
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export async function handleDestroy(id = 0) {
  try {
    let res = await locationService.destroy(id);
    globalThis.store.dispatch(locationActions.setDirty());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${values.user_id}/organisations`));

    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}

export async function handleDestroyLocation(id = 0) {
  try {
    let res = await locationService.destroy(id);
    globalThis.store.dispatch(locationActions.setDirty());
    // redirect.web(`/account/${values.user_id}/organisations`);
    return res;
  } catch (err) {
    utils.displayErrors(err);
    throw err;
  }
}
