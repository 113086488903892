import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackbuttonIcon from 'assets/images/icons/back-button.svg';
import utils from 'lib/utils';
import { Tag } from 'antd';

import './styles.scss';
import 'assets/scss/TabNav.scss';

function TableListHeader({ links, rightPanContent, setEmptyData, setFilters, setRowsPerPage, backLink, setTitleFilter, setInitialFilters }) {
  let decodeBackLink = backLink;
  try {
    decodeBackLink = utils.decodeBackUrl(backLink);
  } catch (err) {
    console.log('decode back url issue', err);
  }
  return (
    <div className="list-view-header">
      <div className="left-content-box">
        <div className="d-flex tabs-primary">
          {backLink && (
            <div className="bck-btn">
              <Link to={decodeBackLink}>
                <img src={BackbuttonIcon} alt="" />
              </Link>
            </div>
          )}
          <ul>
            {links &&
              links.map((link, index) => {
                return (
                  <li>
                    {link.title === 'Ledger' ? <NavLink to={link.to}
                      onClick={() => {
                        setEmptyData && setEmptyData();
                        setFilters && setFilters({});
                        setRowsPerPage && setRowsPerPage(10);
                      }}>
                      {link.title}
                      {/* <Tag color="#87d068" style={{ fontSize: '11px', marginLeft: '3px' }}>BETA</Tag> */}
                    </NavLink> : <NavLink to={link.to}
                      onClick={() => {
                        setEmptyData && setEmptyData();
                        setFilters && setFilters({});
                        if (setTitleFilter && (link.title == "My Buys" || link.title == "Sellers" || link.title == "My Sells" || link.title == "Buyers")) {
                          setInitialFilters && setInitialFilters({ 'SortBy': `${(link.title).split(" ").join("")}` });
                        } else {
                          setInitialFilters && setInitialFilters({})
                        }

                        setRowsPerPage && setRowsPerPage(10);
                      }}>
                      {link.title}
                    </NavLink>}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="right-content-box">{rightPanContent}</div>
    </div>
  );
}

TableListHeader.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  rightPanContent: PropTypes.element,
};

export default TableListHeader;
