import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';
import * as helpers from 'services/helpers';
import dayjs from 'dayjs';

export const reqTransform = {
  fetchChatMessages(params = {}) {
    let qsParams;
    if (params?.isMPA) {
      qsParams = {
        commodity_auction_master_id: params['id'],
        offset: params.offset || 0,
        page_size: params.pageSize || 100,
        Buy_Sell_Enum: params.Buy_Sell_Enum,
        Buy_Sell_Id: params.Buy_Sell_Id,
      };
    } else {
      qsParams = {
        commodity_auction_master_id: params['id'],
        offset: params.offset || 0,
        page_size: params.pageSize || 100,
      };
    }

    // return helpers.getQueryParams(qsParams);
    return utils.getQs(qsParams);
    // return params;
  },
  fetchCharges(params = {}) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchAllAuction(params = {}) {
    const qsParams = {
      filters: params['filters'],
      // entity_id: _.get(window, 'user.profile.person.company_id', 0),
      offset: params['offset'],
      page_size: params['page_size'],
      // auction_buy_sell_enum: params['auction_buy_sell_enum'],
      auction_buy_sell_enum: params['auction_buy_sell_enum'],

      // user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      // company_affiliate_enum: 1,
    };
    return qsParams;
  },

  fetchOwnAuction(params = {}) {
    const qsParams = {
      filters: params['filters'],
      // entity_id: _.get(window, 'user.profile.person.company_id', 0),
      offset: params['offset'],
      page_size: params['page_size'],
      auction_buy_sell_enum: params['auction_buy_sell_enum'],
    };
    return qsParams;
  },

  enterAuction(params = {}, params1 = {}) {
    const qsParams = {
      commodity_auction_master_id: params,
      company_address_id: params1,
    };
    return utils.getQs(qsParams);
  },
  enterMultipartyAuction(params = {}) {
    const qsParams = {
      Commodity_Req_Offer_Multiparty_Auction_Id: params,
    };
    return utils.getQs(qsParams);
  },
  addToGroup(params) {
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  withdrawAuction(params = {}) {
    const qsParams = {
      commodity_auction_master_id: params,
    };
    return utils.getQs(qsParams);
  },

  liveroomDeals(params = {}) {
    let bodyParams = {
      filters: {
        // params['filters']
      },
      commodity_auction_master_id: parseInt(params['commodity_auction_master_id']),
      offset: params['offset'],
      page_size: params['pageSize'],
    };
    return bodyParams;
  },

  sendMessage(params = {}) {
    const storeState = globalThis.store.getState();
    const user = _.get(storeState, 'user');
    const firstName = _.get(user, 'profile.person.first_name') || '';
    const lastName = _.get(user, 'profile.person.last_name') || '';
    const qsParams = {
      auction_master_id: parseInt(params['id']),
      message: params['message'],
      sender_name: firstName + ' ' + lastName,
    };

    // return utils.getQs(qsParams);
    return qsParams;
  },

  fetchOwnBids(params = {}) {
    const qsParams = {
      filters: params['filters'],
      // entity_id: _.get(window, 'user.profile.person.company_id', 0),
      offset: params['offset'],
      page_size: params['page_size'],
      auction_response_buy_sell_enum: params['auction_buy_sell_enum'],
    };
    return qsParams;
  },

  create(type, params = {}) {
    let auction_buy_sell_enum = 1;
    if (type == 'offers') {
      auction_buy_sell_enum = 2;
    }
    let bodyParams = {
      ...params,
      auction_phase_enum: 2,
      commodity_auction_master_id: params['commodity_auction_master_id'],
      auction_buy_sell_enum: auction_buy_sell_enum,
      auction_initiator_user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      auction_initiator_company_id: params['entity_id'],
      auction_initiator_person_id: _.get(window, 'user.userid') || 0,
      auction_initiator_company_affiliate_enum: params['company_affiliate_enum'],
      auction_total_qty_uom: params['qty_uom_id'],
      auto_accept_time_in_minutes: params['auto_accept_time_in_minutes'],
      operation_enum: 3,
      target_price: parseFloat(params['expected_price']),
      target_price_currency_id: params['price_currency_id'],
      vehicle_type_enum: params['vehicle_type_code'],
      min_qty: parseInt(params['min_qty']),
      quantity: parseInt(params['qty']),
      quantity_uom_id: params['qty_uom_id'],
      open_qty: 1200,
      minimum_increment_price: parseFloat(params['minimum_change']),
      auction_start_datetime: params['auction_start_datetime'],
      delivery_end_date: params['to_date'],
      delivery_start_date: params['from_date'],
      initiator_address_id: params['address_id'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      commodity_auction_quality_spec: params['commodity_req_offer_quality_spec'],
      auction_total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
    };
    return bodyParams;
  },

  save(type, params = {}) {
    let auction_buy_sell_enum = 1;
    if (type == 'offers') {
      auction_buy_sell_enum = 2;
    }
    let bodyParams = {
      ...params,
      auction_phase_enum: 1,
      commodity_auction_master_id: 0,
      auction_buy_sell_enum: auction_buy_sell_enum,
      auction_initiator_user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      auction_initiator_company_id: params['entity_id'],
      auction_initiator_person_id: _.get(window, 'user.userid') || 0,
      auction_initiator_company_affiliate_enum: params['company_affiliate_enum'],
      auction_total_qty_uom: params['qty_uom_id'],
      auto_accept_time_in_minutes: params['auto_accept_time_in_minutes'],
      operation_enum: 1,
      target_price: parseInt(params['expected_price']),
      target_price_currency_id: params['price_currency_id'],
      vehicle_type_enum: params['vehicle_type_code'],
      min_qty: parseInt(params['min_qty']),
      quantity: parseInt(params['qty']),
      quantity_uom_id: params['qty_uom_id'],
      open_qty: 1200,
      minimum_increment_price: parseFloat(params['minimum_change']),
      auction_start_datetime: params['auction_start_datetime'],
      delivery_end_date: params['to_date'],
      delivery_start_date: params['from_date'],
      initiator_address_id: params['address_id'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      commodity_auction_quality_spec: params['commodity_req_offer_quality_spec'],
      auction_duration_enum: params['auction_duration_enum'],
      auction_total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
    };
    return bodyParams;
  },

  offerBidAuction(params: any = {}, tsCost, type) {
    let auction_buy_sell_enum = 1;
    if (type == 'offers') {
      auction_buy_sell_enum = 2;
    }
    let bodyParams = {
      commodity_auction_master_id: params.auction_values.auction_id,
      commodity_action_response_detail_id: params.values.commodity_action_response_detail_id,
      auction_response_buy_sell_enum: auction_buy_sell_enum,
      auction_responder_person_id: _.get(window, 'user.userid', 0),
      auction_responder_company_id: params.auction_values.entity_id,
      // auction_responder_company_affiliate_id: 0,
      auction_responder_user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      auction_responder_company_affiliate_enum: params.auction_values.company_affiliate_enum,
      auction_response_qty: params.values.auction_response_qty,
      auction_response_qty_uom_id: params?.values?.auction_response_qty_uom_id,
      auction_response_qty_uom_code: params?.values?.auction_response_qty_uom_code,
      auction_response_price_uom_id: params?.values?.auction_response_price_uom_id,
      auction_response_price_uom_code: params?.values?.auction_response_price_uom_code,
      delivery_start_date: params.values.delivery_start_date,
      delivery_end_date: params.values.delivery_end_date,
      auction_response_price: params.values.auction_response_price,
      auction_response_price_currency_id: 1,
      auction_response_address_id: params.auction_values.address_id,
      transportation_cost_per_mt: tsCost.transportation_cost,
      landed_cost: params.values.landed_cost,
      vehicle_type_id: params.auction_values.vehicle_type_id,
      approx_platform_charges_per_mt: 0,
      operation_enum: params.operation_enum,
      is_own_transport: params.values.is_own_transport,

      platform_charges: params.values.platform_charges,
      discount_charges: params.values.discount_charges,
      net_platform_charges: params.values.net_platform_charges,
      platform_detail_id: params.values.platform_detail_id,
      loading_charge_value: params.values.loading_charge_value,
      unloading_charge_value: params.values.unloading_charge_value,
      bill_to_address_id: params.values.bill_to_address_id,

      is_any_agri: params?.values?.is_any_agri,
      is_any_commodity: params?.values?.is_any_agri,
      commodity_type_id: _.get(params?.values, 'commodity_type_id') || '',
      commodity_type_code: _.get(params?.values, 'commodity_type_code') || '',
      commodity_form_id: _.get(params?.values, 'commodity_form_id') || 0,
      commodity_form_code: _.get(params?.values, 'commodity_form_code') || '',
      commodity_id: _.get(params?.values, 'commodity_id') || 0,
      commodity_code: _.get(params?.values, 'commodity_code') || '',
      commodity_residue_id: _.get(params?.values, 'commodity_residue_id') || 0,
      commodity_residue_code: _.get(params?.values, 'commodity_residue_code') || 0,
      gcv_value: _.get(params?.values, 'gcv_value') || 0,
      price_per_gcv: _.get(params?.values, 'price_per_gcv') || 0,
      is_gcv_based: _.get(params?.values, 'is_gcv_based') || false,
    };
    return bodyParams;
  },

  fetchRequirements(params = {}) {
    const qsParams = {
      plan_id: params['id'],
      offset: params['offset'],
      page_size: params['page_size'],
    };
    return utils.getQs(qsParams);
  },

  fetchOffers(params = {}) {
    const qsParams = {
      plan_id: params['id'],
      offset: params['offset'],
      page_size: params['page_size'],
    };
    return utils.getQs(qsParams);
  },

  fetchBalancePosition(params = {}) {
    const qsParams = {
      plan_id: params,
    };
    return utils.getQs(qsParams);
  },

  fetchAuctionDetails(id) {
    return utils.getQs(id);
  },

  fetchAuctionOfferBidDetails(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },

  fetchLandingPagePositions(id) {
    return utils.getQs(id);
  },

  update(type, params = {}) {
    params['auction_start_datetime'] = dayjs(params['auction_start_datetime']).format('YYYY-MM-DD HH:mm:ss');
    params['from_date'] = dayjs(params['from_date']).format('YYYY-MM-DD HH:mm:ss');
    params['to_date'] = dayjs(params['to_date']).format('YYYY-MM-DD HH:mm:ss');

    let auction_buy_sell_enum = 1;
    if (type == 'offers') {
      auction_buy_sell_enum = 2;
    }
    let bodyParams = {
      ...params,
      commodity_auction_master_id: params['commodity_auction_master_id'],
      auction_buy_sell_enum: auction_buy_sell_enum,
      auction_initiator_user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      auction_initiator_company_id: params['entity_id'],
      auction_initiator_person_id: _.get(window, 'user.userid') || 0,
      auction_initiator_company_affiliate_enum: params['auction_initiator_company_affiliate_enum'],
      auction_total_qty_uom: params['qty_uom_id'],
      auto_accept_time_in_minutes: params['auto_accept_time_in_minutes'],
      operation_enum: 3,
      target_price: parseInt(params['expected_price']),
      target_price_currency_id: params['price_currency_id'],
      vehicle_type_enum: params['vehicle_type_code'],
      min_qty: parseInt(params['min_qty']),
      quantity: parseInt(params['qty']),
      quantity_uom_id: params['qty_uom_id'],
      open_qty: 1200,
      minimum_increment_price: parseFloat(params['minimum_change']),
      auction_start_datetime: params['auction_start_datetime'],
      delivery_end_date: params['to_date'],
      delivery_start_date: params['from_date'],
      initiator_address_id: params['address_id'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      commodity_auction_quality_spec: params['commodity_req_offer_quality_spec'],
      auction_total_qty: utils.caculateFinalQuantity(
        params['qty'],
        params['periodicity_enum'],
        params['from_date'],
        params['to_date'],
      ),
    };
    return bodyParams;
  },
  rejectBidOffer(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  acceptBidOffer(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  deleteBidOffer(params) {
    let qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
  fetchTransportationCost(params = {}) {
    const qsParams = {
      address_Id: params['address_id'],
      entity_Id: params['buySellId'],
      user_type_enum: _.get(window, 'user.profile.person.user_type_enum') || 2,
      vehicle_type_id: params['vehicle_type_id'],
      total_offer_bid_qty: params['offer_bid_qty'],
    };

    return utils.getQs(qsParams);
    // return params;
  },
  updateTargetPrice(params = {}) {
    delete params['min_target_price'];
    delete params['max_target_price'];
    const qsParams = {
      ...params,
    };
    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchChatMessages(resData = {}) {
    return resData;
  },
  fetchAllAuction(resData) {
    let commodityAuctionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllAuctionDetails(details);
    });

    return _.keyBy(commodityAuctionData, 'commodity_auction_master_id');
  },

  fetchOwnAuction(resData) {
    let commodityAuctionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllAuctionDetails(details);
    });

    return _.keyBy(commodityAuctionData, 'commodity_auction_master_id');
  },

  fetchOwnBids(resData) {
    let commodityAuctionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchOwnBidsDetails(details);
    });

    return _.keyBy(commodityAuctionData, 'commodity_action_response_detail_id');
  },

  fetchOwnBidsDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_action_response_detail_id') || 0,
    };
    return data;
  },
  fetchAllAuctionDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_auction_master_id') || 0,
    };
    return data;
  },

  fetchAuctionDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_auction_master_id') || 0,
    };
    return data;
  },

  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  fetchOffers(resData) {
    let commodityPlanOffer = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchOffersDetails(details);
    });

    return _.keyBy(commodityPlanOffer, 'plan_id');
  },

  fetchOffersDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  fetchRequirements(resData) {
    let commodityPlanRequirements = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchRequirementsDetails(details);
    });

    return _.keyBy(commodityPlanRequirements, 'plan_id');
  },

  fetchRequirementsDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'plan_id') || 0,
    };
    return data;
  },

  enterAuction(resData) {
    return resData;
  },

  withdrawAuction(resData) {
    return resData;
  },

  fetchAuctionOfferBidDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'commodity_action_response_detail_id') || 0,
    };
    return res;
  },

  fetchLandingPagePositions(resData) {
    let landingPagePosition = (_.isArray(resData.table_data) ? resData.table_data : []).map((details) => {
      return resTransform.landingPagePositionDetails(details);
    });

    return _.keyBy(landingPagePosition, 'table_id');
  },

  landingPagePositionDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'table_data.table_id') || 0,
    };
    return data;
  },

  sendMessage(resData) {
    return resData;
  },

  destroy(resData) {
    return resData;
    // return res;
  },

  fetchBalancePosition(resData) {
    return resData;
  },
  fetchPlanDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_req_offer_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
  },

  offerBidAuction(resData) {
    return resData;
  },

  liveroomDeals(resData) {
    let auctionLiveroomDealsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllAuctionLiveroomDealsDetails(details);
    });

    return _.keyBy(auctionLiveroomDealsData, 'commodity_deal_master_id');
  },

  fetchAllAuctionLiveroomDealsDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_deal_master_id') || 0,
    };
    return data;
  },

  update(resData) {
    return resData;
  },

  savePlanAsRequirement(resData) {
    return resData;
  },
  rejectBidOffer(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  acceptBidOffer(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  deleteBidOffer(resData) {
    let res = {
      ...resData,
    };
    return res;
  },
  fetchTransportationCost(resData = {}) {
    return resData;
  },
  fetchCharges(resData) {
    return resData;
  },
  addToGroup(resData) {
    return resData;
  },
  updateTargetPrice(resData) {
    return resData;
  },
};
