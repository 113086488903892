import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import * as yup from 'yup';

import { financeService } from 'services';

export const handleCreate = async (values, formikBag, setTimeStamp, id) => {
  try {
    notifications.show({
      type: 'info',
      message: `Saving to Finance Data...`,
      key: 'req-form',
    });
    values.annual_turnover = parseFloat(utils.unformat(values.annual_turnover));
    const res = await financeService.create(values);
    notifications.show({
      type: 'success',
      message: `Finance Data Saved.`,
      key: 'req-form',
    });
    setTimeStamp(Date.now());
    redirect.web(getUrlWithEncryptedQueryParams(`/account/${id}/finance`));

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateInstalledCapacity = async (values, formikBag, type) => {
  try {
    if (type == 'create') {
      notifications.show({
        type: 'info',
        message: `Saving to installed Capacity...`,
        key: 'req-form',
      });
    }
    if (type == 'update') {
      notifications.show({
        type: 'info',
        message: `Updating installed Capacity...`,
        key: 'req-form',
      });
    }
    const res = await financeService.createInstalledCapacity(values);
    if (type == 'create') {
      notifications.show({
        type: 'success',
        message: `Installed Capacity saved.`,
        key: 'req-form',
      });
    }
    if (type == 'update') {
      notifications.show({
        type: 'success',
        message: `Installed Capacity updated.`,
        key: 'req-form',
      });
    }
    return res;
  } catch (err) {
    console.log('handleCreateInstalledCapacity--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
export const handleDeleteInstalledCapacity = async (id, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: `Deleting Installed Capacity...`,
      key: 'req-form',
    });

    const res = await financeService.destroyInstalledCapacity(id);
    notifications.show({
      type: 'success',
      message: `Installed Capacity deleted.`,
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleDeleteInstalledCapacity--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
