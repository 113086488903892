import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
  fetchSupplierProfilesListReport(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  platformChargesReportData(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  getEmissionsSustainabilityReport(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(generateReportData, 'commodity_req_offer_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'commodity_req_offer_id') || 0,
    };
    return data;
  },
  fetchPaymentDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'bf_payment_master_response.payment_master_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  fetchReportList(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchReportDetails(details);
    });
    return _.keyBy(generateReportData, 'id');
  },
  fetchReportDetails(resData) {
    let data = {
      id: _.get(resData, 'commodity_req_offer_id') || 0,
      Type: _.get(resData, 'type') || '',
      Grade: _.get(resData, 'commodity_standard') || '',
      Commodity: _.get(resData, 'commodity') || '',
      Commodity_Form: _.get(resData, 'commodity_form_code') || '',
      Commodity_Residue: _.get(resData, 'commodity_residue') || '',
      Created_Datetime: _.get(resData, 'created_datetime') || '',
      Is_Auction: _.get(resData, 'is_auction') || '',
      // 'Auction Duration': _.get(resData, 'auction_duration_time_in_minutes') || '',
      Price: _.get(resData, 'expected_price') || '',
      Qty: _.get(resData, 'qty') || '',
      Periodicity: _.get(resData, 'periodicity') || '',
      Total_Qty: _.get(resData, 'total_qty') || '',
      'Qty UOM': _.get(resData, 'qty_uom') || '',
      From_Date: _.get(resData, 'from_date') || '',
      To_Date: _.get(resData, 'to_date') || '',
      Balance_Quantity: _.get(resData, 'balance_quantity') || '',
      Initiater_User_Type: _.get(resData, 'initiator_user_type') || '',
      Initiater_Name: _.get(resData, 'initiator_name') || '',
      Initiator_Location: _.get(resData, 'initiator_location') || '',
      Closing_At_Datetime: _.get(resData, 'closing_at_datetime') || '',
      Auction_Opens_Datetime: _.get(resData, 'auction_opens_datetime') || '',
      Verified: _.get(resData, 'is_verified') || '',
      // 'Extened Profile Rating': _.get(resData, 'extended_profile_ratings') || '',
      Rating: _.get(resData, 'ratings') || '',
      Following_Count: _.get(resData, 'following_count') || '',
      Response_Count: _.get(resData, 'response_count') || '',
      Auto_Response: _.get(resData, 'auto_response_count') || '',
      CountersProposal_count: _.get(resData, 'counter_proposals_count') || '',
      Response_Accepted_Count: _.get(resData, 'response_accepted_count') || '',
      Deals_Rejected_Count: _.get(resData, 'deals_rejected_count') || '',
      'Deals_No(s)': _.get(resData, 'deal_no') || '',
      // 'Is open Buy sell': _.get(resData, 'is_open_buy_sell') || '',
      Status: _.get(resData, 'status') || '',
      Is_Indent_Buy_Sell: _.get(resData, 'is_indent_buy_sell') || '',
    };
    return data;
  },

  fetchSupplierProfilesListReport(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchSupplierProfilesReportDetails(details);
    });
    return _.keyBy(generateReportData, 'id');
  },
  fetchSupplierProfilesReportDetails(resData) {
    let data = {
      id: _.get(resData, 'extended_profile_id') || 0,
      'App No.': _.get(resData, 'extended_profile_inspection_id') || '',
      Company: _.get(resData, 'applicant_company_affiliate_name') || '',
      'Location Name': _.get(resData, 'address_text') || '',
      Location: _.get(resData, 'coarse_location') || '',
      Subscription: _.get(resData, 'subscription') || '',
      Package: _.get(resData, 'plan_name') || '',
      // 'Auction Duration': _.get(resData, 'auction_duration_time_in_minutes') || '',
      Status: _.get(resData, 'extended_profile_enum_code') || '-',
      'Status Date': _.get(resData, 'extended_profile_submited_on') || '',
      'Inspection Date': _.get(resData, 'inspection_conducted_on') || '',
      'Verified By': _.get(resData, 'inspection_verified_by_person_name') || '',
      'Verified Date': _.get(resData, 'inspection_verified_datetime') || '',
      'Verification Status': _.get(resData, 'inspection_enum_code') || '',
      'Valid Until': _.get(resData, 'inspection_valid_until') || '',
      'Applicants Score': _.get(resData, 'applicant_ratings') || '',
      'Inspector Score': _.get(resData, 'inspector_ratings') || '',
      'Inspected By': _.get(resData, 'inspected_by_person_name') || '',
    };
    return data;
  },
  getEmissionsSustainabilityReport(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.getEmissionsSustainabilityReportfetchDetails(details);
    });
    return _.keyBy(generateReportData, 'delivery_id');
  },
  getEmissionsSustainabilityReportfetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'delivery_id') || 0,
    };
    return data;
  },
  platformChargesReportData(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(generateReportData, 'invoice_master_id');
  },
};
