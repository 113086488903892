import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import { Popconfirm } from 'antd';
import ReferenceDataSelect from 'containers/Account/Profile/components/ReferenceDataSelect';
import FormikDateTimePickerField from './FormikDateTimePickerField';
import FormIkDatePickerFieldNotConfirmed from './FormIkDatePickerFieldNotConfirmed';
import DriverSelect from './driverSelect';
import VehicleSelect from './vehicleSelect';
import { useSelector, useDispatch } from 'react-redux';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import Loader from 'common/Loader';
import * as handlers from '../handlers';
import notifications from 'lib/notifications';
import { Delivery_Schedule_Enum, Operation_Enum } from '../../../../constants/enums'

const getInitialValues = (deliveryDetails = {}) => {
  let formFields = {
    delivery_allotments: [
      {
        delivery_Schedule_Id: [_.get(deliveryDetails, 'transport_delivery_schedule_id') || 0],
        // transport_delivery_schedule_alloted_id: _.get(deliveryDetails, 'transport_delivery_schedule_alloted_id') || 0,
        // vehicle_id: _.get(deliveryDetails, 'vehicle_id') || 0,
        // person_id: _.get(deliveryDetails, 'driver_person_id') || 0,
        pickup_time: _.get(deliveryDetails, 'pickup_datetime') || 0,
        reason_type_enum: _.get(deliveryDetails, 'reason_type_enum') || 0,
        operation_Enum: Operation_Enum.Update,
        delivery_Schedule_Enum: Delivery_Schedule_Enum.Schedules,
      },
    ],
  };
  return formFields;
};

const DeliveryFormNotConfirmed = (props) => {
  let loc = window.location.href;
  let deliveries = {};
  if (loc.includes('upcoming')) {
    deliveries = useSelector((state) => state.upcomingDeliveries)
  } else if (loc.includes('ongoing')) {
    deliveries = useSelector((state) => state.ongoingDeliveries)
  } else if (loc.includes('completed')) {
    deliveries = useSelector((state) => state.completedDeliveries)
  } else if (loc.includes('missed')) {
    deliveries = useSelector((state) => state.missedDeliveries)
  } else if (loc.includes('not-confirmed')) {
    deliveries = useSelector((state) => state.notConfirmedDeliveries)
  }

  const [isPickupDateChanged, setIsPickupDateChanged] = useState(false);

  const deliveryId = props.deliveryId || 0;
  const dispatch = useDispatch();
  const history = useHistory();

  const deliveryDetails = _.get(deliveries, `entities[${deliveryId}]`) || {};
  const transportPersonId = _.get(deliveryDetails, 'transport_person_id') || 0;
  const isControlTowerUser = useSelector((state) => state?.user?.profile?.person?.is_control_tower_user);
  const isTransporter = useSelector((state) => state?.user?.profile?.person?.is_transporter);
  const personRoles = useSelector((state) => state?.user?.profile?.person_role);
  const isAddReason = personRoles?.some(role => role.role_id === 20 || role.role_id === 21)
  const personId = useSelector((state) => state?.user?.profile?.person?.person_id);

  const isVehicleEditable = _.get(deliveryDetails, 'is_vehicle_editable') || 0;
  const isOwnTransport = _.get(deliveryDetails, 'is_own_transport')
  let disabledPickupDate = false;

  if (deliveryId && _.isEmpty(deliveryDetails)) {
    return <Loader loading={true} />;
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleCheck = async (values, formikBag) => {
    const delivery_allotments = _.get(values, 'delivery_allotments[0]') || {};
    const driverId = _.get(delivery_allotments, 'person_id') || 0;
    const vehicleId = _.get(delivery_allotments, 'vehicle_id') || 0;
    const reason = _.get(delivery_allotments, 'reason_type_enum') || 0;
    const initial_vehicle_id = _.get(deliveryDetails, 'vehicle_id') || 0;
    const initial_person_id = _.get(deliveryDetails, 'driver_person_id') || 0;
    if (
      isControlTowerUser &&
      deliveryDetails.pickup_datetime !== values.delivery_allotments[0].pickup_time &&
      reason === 0
    ) {
      setIsPickupDateChanged(true);
    } else {
      // if (driverId == 0 || driverId == initial_person_id || vehicleId == 0 || vehicleId == initial_vehicle_id) {
      //   notifications.show({
      //     type: 'info',
      //     message: 'Driver & Vehicle details are not provided. You are changing Pick Up Date & Time.',
      //     key: 'req-form',
      //     duration: 20,
      //   });
      // }

      await sleep(2000);
      let success = {};
      if (loc.includes('controltower/deliveries')) {
        // success = await handlers.handleUpdateCT(values, formikBag);
        const params = _.get(values, 'delivery_allotments[0]') || {};
        params.pickup_time = dayjs(params.pickup_time).format('YYYY-MM-DDTHH:mm:ss')
        success = await handlers.handleMoveDateOfNotConfirmedSchedules(params, formikBag);
      } else {
        success = await handlers.handleUpdate(values, formikBag);
      }
      // if (!_.isEmpty(success)) {
      props.close();
      props.setTimestamp(Date.now());
      // }
    }
  };
  return (
    <Formik initialValues={getInitialValues(deliveryDetails)} onSubmit={handleCheck}>
      {(formikBag) => {
        const { values, setFieldValue } = formikBag;
        return (
          <Form className="formik-form">
            <FieldArray
              name="delivery_allotments"
              render={(arrayHelpers) => (
                <>
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Pick up Date and Time</span>
                          <div className="form-group-field custom-input">
                            <Field
                              name={`delivery_allotments[0].pickup_time`}
                              defaultValue={deliveryDetails.pickup_datetime || ''}
                              component={FormIkDatePickerFieldNotConfirmed}
                              setIsPickupDateChanged={setIsPickupDateChanged}
                            />
                            <img
                              src={calendarIcon}
                              className="datepicker-calendar-icon"
                              style={{ marginLeft: '-30px', zIndex: '1' }}
                            />
                          </div>
                        </div>
                      </div>
                      {(isAddReason || isTransporter || isOwnTransport) && (
                        <div className="row form__form-group">
                          <div className="col">
                            <span className="form-group-label">Reason</span>
                            <div className="form-group-field">
                              <Field
                                name="delivery_allotments[0].reason_type_enum"
                                component={ReferenceDataSelect}
                                entity="ReasonTypeEnum"
                                disabled={!isPickupDateChanged}
                                cb={(newVal) => {
                                  let id = _.get(newVal, 'value.id') || 0;
                                  if (id) {
                                    setFieldValue(`delivery_allotments[0].reason_type_enum`, id);
                                  }
                                }}
                              />
                              {(isPickupDateChanged && (!values?.delivery_allotments[0].reason_type_enum)) && (
                                <span className="required">Please select reason for pickup date change</span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Popconfirm
                      title="Do you wish to proceed?"
                      onConfirm={formikBag.handleSubmit}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        color="primary"
                        type="button"
                        disabled={formikBag.isSubmitting || !isPickupDateChanged}
                        className="next bfc-button-next">
                        {formikBag.isSubmitting && (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        )}
                        &nbsp;{deliveryId ? 'Update' : 'Save'}
                      </Button>
                    </Popconfirm>
                  </div>
                </>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeliveryFormNotConfirmed;
