/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { withFormik, Field, Form, FieldArray } from 'formik';
import { Alert, Col, Container, Row, Table, Card, CardBody, Badge, Button, ButtonToolbar } from 'reactstrap';
// import Select from '@material-ui/core/Select';
import * as yup from 'yup'; // for everything
import _ from 'lodash';
import RemoveCircleIcon from 'mdi-react/RemoveCircleIcon';
import EditIcon from 'mdi-react/ClipboardEditOutlineIcon';
import utils from 'lib/utils';
import ReferenceDataSelect from './../components/ReferenceDataSelect';
import FormikMaterialTextField from './../components/FormikMaterialTextField';
import FormikDatePickerField from './../components/FormikDatePickerFieldCrop';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';

import { actions as refDataActions } from 'redux/referenceData';

const cropDetailFields = {
  farm_detail_id: 0,
  crop_id: 0,
  crop_area: '',
  harvest_date: '',
  operation_enum: 1,
  crop_code: '',
};

// ddd
const CropFields = (props) => {
  const { form } = props;

  // console.log('\n=============CropFields==========');
  // console.log('CropFields-->props-->', props);
  // console.log('CropFields-->form-->', form);

  const crops = _.get(props, 'form.values.crops') || [];

  let counter = 0;
  let activeCrops = crops.filter((crop) => crop.operation_enum !== 4);
  const cropFieldArr = crops.map((crop, index) => {
    // console.log('crop--->', crop);
    // console.log('index---->', index);

    if (crop.operation_enum === 4) {
      return null;
    }
    counter += 1;
    return (
      <React.Fragment key={index}>
        <div className="addanother-farm mt-10 mb-20">
          <span className="title ml-10">
            Crop {counter} {crop.crop_code ? ` - ${crop.crop_code}` : ''}
          </span>
          {activeCrops.length > 1 ? (
            <span
              className="icon remove-icon"
              onClick={(e) => {
                counter -= 1;
                form.setFieldValue(`crops[${index}].operation_enum`, 4);
                props.pop();
              }}>
              <RemoveCircleIcon color="#f44336" />
            </span>
          ) : null}
        </div>
        <div className="row form__form-group">
          <div className="col">
            <div className="form-group-field">
              <span className="form-group-label">Select Crop</span>
              <span class="required">*</span>
              <Field
                name={`crops[${index}].crop_id`}
                component={ReferenceDataSelect}
                entity="commodity"
                cb={(val) => {
                  // console.log('\n===============cb==========');
                  // console.log('val-->', val);
                  form.setFieldValue(`crops[${index}].crop_code`, val.display_member || '');
                  // console.log('form--->', form);
                  // console.log('indx--->', index);
                  // console.log('\n===============cb===end=======');
                }}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="row form__form-group">
          <div className="col">
            <span className="form-group-label">Size of farm (acres)</span>
            <span class="required">*</span>
            <div className="form-group-field">
              <Field type="number" name={`crops[${index}].crop_area`} component={FormikMaterialTextField} />
            </div>
          </div>
        </div>
        <div className="row form__form-group">
          <div className="col">
            <span className="form-group-label">Harvest Date</span>
            <span class="required">*</span>
            <div className="form-group-field custom-input">
              <Field
                name={`crops[${index}].harvest_date`}
                component={FormikDatePickerField}
                defaultValue={_.get(crops, `[${index}].harvest_date`)}
              />
              <img src={calendarIcon} className="datepicker-calendar-icon" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      <div className="corp-fields-box">
        {cropFieldArr}
        <div className="addnew-row add-new-btn-box text-right mt-10 mb-20">
          <Button
            type="button"
            className="add-new-btn"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              props.push({ ...cropDetailFields });
            }}>
            <span className="icon">+</span>
            <span className="text">Add Crop</span>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CropFields;
