import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const upload = async (params, entity_id, document_module_enum) => {
  try {
    let bodyParams = reqTransform.upload(params, entity_id, document_module_enum);
    const formData = new FormData();

    formData.append('entity_Id', bodyParams.entity_id);
    formData.append('entity_Type_Enum', bodyParams.entity_type_enum);
    formData.append('document_Module_Enum', bodyParams.document_module_enum);
    formData.append('company_Affiliate_Enum', bodyParams.company_affiliate_enum);
    formData.append('user_Type_Enum', bodyParams.user_type_enum);
    // formData.append('front_Back_Enum', front_back_enum);
    formData.append('attachment_File', bodyParams[0]);
    // formData.append('attachment_Id', attachmentIdToPass);

    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/AttachDocument_Azure`;
    const res = await utils.fetch(
      url,
      {
        method: 'POST',
        body: formData,
      },
      false,
    ).ready;

    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AttachDocument_Azure');
    throw errors;
  }
};

export const fetchList = async (params) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DocumentUpload/GetAttachmentAgainstEntityId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchUploadList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetAttachmentAgainstEntityId');
    throw errors;
  }
};

export const fetchDetails = async (id) => {
  try {
    const params = reqTransform.fetchDetails(id);
    let list = await fetchList({});
    const resData = list['id'];
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('api-uploadDoc-fetchDetails->err---->', err);
    throw err;
  }
};
