import React from 'react';
import NoDataFoundImg from 'assets/images/no-result.png';
import NoDataIcon from 'assets/images/icons/no-data.svg';
import EnviraIcon from 'assets/images/icons/explore/envira-logo-trademark.png'
// import EnviraIcon from 'assets/images/icons/Envira.svg'
import { Image, Empty } from 'antd';



const NoDataFound = ({ SupplierCard, SupplierDrillDownCard, feedPosts, pleaseWait, isStorage, isWholeSale, findBuyersSellers }) => {
  return (
    <>
      {SupplierCard && (
        <div class="bfc-body" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      {SupplierCard === false && SupplierDrillDownCard === false && (
        <div class="bfc-body mt-15" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      {SupplierDrillDownCard && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {feedPosts && !isWholeSale && (
        <div class="bfc-body mt-15" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      {findBuyersSellers && !isWholeSale && (
        <div class="bfc-body mt-5" style={{
          display: 'flex',
          borderRadius: '5px',
          height: 'calc(100vh - 200px)',
          alignItems: 'center'
        }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty
              style={{ height: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'column' }}/>
          </div>
        </div>
      )}
      {isWholeSale && (
        <div class="bfc-body mt-15" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <div className='d-flex align-items-center justify-content-center'>
              <img src={EnviraIcon} alt={EnviraIcon} style={{ width: 'auto', height: '80px', marginRight: '10px' }} />
              <p style={{ margin: 0, fontSize:20 }}>Coming Soon...</p>
            </div>
          </div>
        </div>
      )}
      {isStorage && (
        <div class="bfc-body mt-15 w-100" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      )}
      {pleaseWait && (
        <div class="bfc-body mt-15" style={{ borderRadius: 5 }}>
          <div class="card-body" style={{ backgroundColor: 'transparent' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  Please wait...
                </span>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
export default NoDataFound;
