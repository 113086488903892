import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import './styles.scss';
import { handleFilterChange } from 'common/ListDataGrid';

function deliveryField({ delivery_number, detailsPageLink}) {
  const history = useHistory();
  return (
    <div className="bfc-table-list-components ">
      <div className="inovice-field" >
        <Link to={detailsPageLink} className="commodity-code">
          {delivery_number ? delivery_number : ' '}
        </Link>

      </div>
    </div>
  );
}

deliveryField.propTypes = {
  delivery_number: PropTypes.string.isRequired,
};

export default deliveryField;
