import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import DeliveryAssignmentServices from 'services/deliveryAssignment';

const initialState = {
  data: {},
  order: [],
  busy: false,
  updatedAt: null,
};

const slice = createSlice({
  name: 'deliveryAssignments',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    add(state, action) {
      let data = {};
      let order = [];

      for (const deliveryAssignment of action.payload) {
        const key = deliveryAssignment.transport_delivery_schedule_id;
        data[key] = deliveryAssignment;
        order.push(key);
      }

      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();
    },
  },
});

const getDeliveryAssignmentDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await DeliveryAssignmentServices.FetchDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId(
        id,
      );
      dispatch(slice.actions.add(response.data));
    } catch (errorResponse) {
      console.log('getDeliveryAssignmentDetails-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const getDeliveryAssignmentAgainstTransportDealMasterId = (id, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await DeliveryAssignmentServices.FetchDeliveryAssignmentAgainstTransportDealMasterId(
        id,
        pageSize,
      );
      dispatch(slice.actions.add(response.data.bf_transport_delivery_schedule));
    } catch (errorResponse) {
      console.log('getDeliveryAssignmentAgainstTransportDealMasterId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const getDeliveryAssignmentAgainstCommodityDealMasterId = (id, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await DeliveryAssignmentServices.FetchDeliveryAssignmentAgainstCommodityDealMasterId(
        id,
        pageSize,
      );
      dispatch(slice.actions.add(response.data.bf_transport_delivery_schedule));
    } catch (errorResponse) {
      console.log('getDeliveryAssignmentAgainstCommodityDealMasterId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await DeliveryAssignmentServices.SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId(
        payload,
      );

      // dispatch(slice.actions.update(response.data.bf_transport_delivery_schedule));
    } catch (errorResponse) {
      console.log('SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const actions = {
  ...slice.actions,
  getDeliveryAssignmentDetails,
  getDeliveryAssignmentAgainstTransportDealMasterId,
  getDeliveryAssignmentAgainstCommodityDealMasterId,
  SaveAndUpdateDeliveryAssignmentDetailsAgainstTransportDeliveryScheduleId,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
