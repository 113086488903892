import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
      filters: params['filters'],
      offset: params['offset'],
      page_size: params['page_size'],
    };

    return qsParams;
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let generateReportData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(generateReportData, 'user_num');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'user_num') || 0,
    };
    return data;
  },
  fetchPaymentDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'bf_payment_master_response.payment_master_id') || 0,
    };
    return data;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
