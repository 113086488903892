import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import utils from 'lib/utils';

const commoditySchema = yup.object().shape({
  commodity_id: yup.number().min(1, 'Commodity is required'),
  commodity_residue_id: yup.number().min(1, 'Commodity residue is required'),
});

export const rowMaterialAndProductsSchema = yup.object().shape({
  products: yup.array().min(1).required('Product is a required field'),
  rawMaterialAndProduct: yup.array().of(commoditySchema).min(1, 'Minimum one raw material is required'),
  raw_Material_Procurement_Range_to: yup
    .number()
    .positive('Please select an approximate range for raw materials')
    .required('Please select an approximate range for raw materials'),
});
export const locationAndAccessSchema = yup.object().shape({
  own_Rented_Enum: yup
    .number()
    .required('Please select Type of Facility Area')
    .positive('Please select Type of Facility Area'),

  facility_start_year: yup.number().required('Please select year for Facility Area'),

  latitude: yup
    .number()
    .typeError('Please select located location')
    .positive('Please select located location')
    .required('Please select located location'),

  road_Type_Enum: yup
    .number()
    .typeError('Please select Type of Road access')
    .required('Please select Type of Road access')
    .positive('Please select Type of Road access'),
});

export const peopleAndFacilitiesSchema = yup.object().shape({
  facility_Area: yup.string().nullable().required('Please enter the value greater than zero'),
  rawMaterialsCoveredInMT: yup.string().nullable().required(),
  rawMaterialsCoveredUsableInMT: yup.string().nullable().required(),
  rawMaterialsOpenInMT: yup.string().nullable().required(),
  rawMaterialsOpenUsableInMT: yup.string().nullable().required(),

  finishedGoodsOpenInMT: yup.string().nullable().required(),
  finishedGoodsCoveredInMT: yup.string().nullable().required(),
  finishedGoodsCoveredUsableInMT: yup.string().nullable().required(),
  finishedGoodsOpenUsableInMT: yup.string().nullable().required(),
  total_Men_Employed: yup.string().nullable().required('Please select total men employeed'),
  total_Women_Employed: yup.string().nullable().required('Please select total women employeed'),
});

// const peopleAndFacilitiesSchema1 = peopleAndFacilitiesSchema.shape({
//   facility_Area: yup.number().min(1).required('Please enter the value greater than zero'),
// });

export const infrastuctureSchema = yup.object().shape({
  connected_Load_Enum: yup
    .number()
    .typeError('Please select Type of Connected Load')
    .required('Please select Type of Connected Load')
    .positive('Please select Type of Connected Load'),

  is_Generator_Available: yup.string().required('Please select backup generator'),

  generator_Capacity: yup.number().moreThan(-1, 'Generator capacity must be positive number'),

  vehicle_type_id: yup.number().required('Please select Vehicle size').positive('Please select Vehicle size'),

  loading_Facilities_Type_Enum: yup
    .number()
    .typeError('Please select Type of Loading facility')
    .required('Please select Type of Loading facility')
    .positive('Please select Type of Loading facility'),

  is_Loading_Unloading_Allowed_At_Night: yup.string().required('Please select backup generator'),

  power_Cut_Frequency_Enum: yup.string().typeError('Please select power supply').required('Please select power supply'),
});

const infrastuctureSchema1 = yup.object().shape({
  power_Cut_Frequency_Enum: yup
    .number()
    .positive('Please select power supply')
    .typeError('Please select power supply')
    .required('Please select power supply'),
});

export const equipmentsSchema = yup.object().shape({
  mainProducts: yup.array().of(
    yup.object().shape({
      commodity_form_id: yup.number().required('Please select Product'),
      commodity_type_id: yup.number().required('Please select Product'),
      commodity_type_code: yup.string().required('Please select Product'),
      make_of_machine: yup.string().required('Please select Make of Machine'),
      year_of_manufacture: yup
        .number()
        .typeError('Please select year of manufacture')
        .required('Please select year of manufacture'),
      breakdown_type_enum_code: yup.string().required('Please select breakdown duration'),
      capacity: yup.number().required('Please select Capacity').positive('Please select Capacity'),
      utilized_capacity: yup
        .number()
        .typeError('Utilized capacity is required')
        .min(0, 'Invalid Utilized Capacity')
        .required('Utilized capacity is required'),
    }),
  ),
  drying_Facility_Enum: yup
    .number()
    .typeError('Please select Type of Drying facility')
    .required('Please select Type of Drying facility')
    .positive('Please select Type of Drying facility'),

  is_Generator_Available: yup.string().required('Please select backup generator'),

  // equipments: yup.string().required('Please select equipments'),
});

export const healthAndSafetySchema = yup.object().shape({
  // safety_Training_Frequency_Enum: yup
  //   .number()
  //   .typeError('Please select safety training field')
  //   .required('Please select safety training field'),

  last_Safety_Training_On: yup.string().when('safety_Training_Frequency_Enum', {
    is: (value) => value != '' && value != 0,
    then: yup.string().nullable().required('When was the last safety Training done'),
  }),

  // last_Safety_Training_On: yup.string().nullable().required('When was the last safety Training done'),

  // incident_Free_Days: yup
  //   .number()
  //   .typeError('Please select incident free days field')
  //   .positive('Please select incident free days field')
  //   .required('Please select incident free days field'),
});

const healthAndSafetySchema1 = yup.object().shape({
  last_Health_Checkup_On: yup.string().when('health_Checkup_Frequency_Enum', {
    is: (value) => value != '' && value != 0,
    then: yup.string().nullable().required(' When was the last Heath Checkup done'),
  }),
});

const healthAndSafetySchema2 = yup.object().shape({
  health_And_Safety_Certification_Valid_Until: yup.string().when('health_And_Safety_Certification_Enum', {
    is: (value) => value != '' && value != 0,
    then: yup.string().nullable().required('Please select valid until'),
  }),
});

const qualityStandardProcessSchema = yup.object().shape({
  last_Quality_Testing_On: yup.string().when('quality_Testing_Frequency_Enum', {
    is: (value) => value != '' && value != 0,
    then: yup.string().nullable().required('When was the last test done'),
  }),
});

const qualityStandardProcessSchema1 = yup.object().shape({
  quality_Certification_Valid_Until: yup.string().when('quality_Certification_Enum', {
    is: (value) => value != '' && value != 0,
    then: yup.string().nullable().required('Please select valid until'),
  }),
});

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchNotConnectedDetails = async (id) => {
  try {
    let res = await api.fetchNotConnectExtendedProfileDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params, formikBag, activeTab, tabName) => {
  try {
    if (tabName === 'rawMaterialsAndProducts') {
      await helpers.validateSchema(rowMaterialAndProductsSchema, params);
    } else if (tabName === 'locationAndAccess') {
      await helpers.validateSchema(locationAndAccessSchema, params);
    } else if (tabName === 'peopleandfacilities') {
      await helpers.validateSchema(peopleAndFacilitiesSchema, params);
    } else if (tabName === 'infrastructure') {
      await helpers.validateSchema(infrastuctureSchema, params);
    } else if (tabName === 'equipments') {
      await helpers.validateSchema(equipmentsSchema, params);
    } else if (tabName === 'healthAndSafety') {
      if (params.safety_Training_Frequency_Enum != 0 && params.safety_Training_Frequency_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema, params);
      }
      if (params.health_Checkup_Frequency_Enum != 0 && params.health_Checkup_Frequency_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema1, params);
      }
      if (params.health_And_Safety_Certification_Enum != 0 && params.health_And_Safety_Certification_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema2, params);
      }
    } else if (tabName === 'qualityStandardProcess') {
      if (params.quality_Testing_Frequency_Enum != 0 && params.quality_Testing_Frequency_Enum != null) {
        await helpers.validateSchema(qualityStandardProcessSchema, params);
      }
      if (params.quality_Certification_Enum != 0 && params.quality_Certification_Enum != null) {
        await helpers.validateSchema(qualityStandardProcessSchema1, params);
      }
    }
    // await helpers.validateSchema(ExtendedProfileProductSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    //utils.displayErrors(err, formikBag);
    throw err;
  }
};

export const update = async (params, formikBag, activeTab, tabName) => {
  try {
    if (tabName === 'rawMaterialsAndProducts') {
      await helpers.validateSchema(rowMaterialAndProductsSchema, params);
    } else if (tabName === 'locationAndAccess') {
      await helpers.validateSchema(locationAndAccessSchema, params);
    } else if (tabName === 'peopleandfacilities') {
      await helpers.validateSchema(peopleAndFacilitiesSchema, params);
    } else if (tabName === 'infrastructure') {
      if (params.power_Cut_Frequency_Enum === 0) {
        await helpers.validateSchema(infrastuctureSchema1, params);
      } else {
        await helpers.validateSchema(infrastuctureSchema, params);
      }
    } else if (tabName === 'equipments') {
      await helpers.validateSchema(equipmentsSchema, params);
    } else if (tabName === 'healthAndSafety') {
      if (params.safety_Training_Frequency_Enum != 0 && params.safety_Training_Frequency_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema, params);
      }
      if (params.health_Checkup_Frequency_Enum != 0 && params.health_Checkup_Frequency_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema1, params);
      }
      if (params.health_And_Safety_Certification_Enum != 0 && params.health_And_Safety_Certification_Enum != null) {
        await helpers.validateSchema(healthAndSafetySchema2, params);
      }
    } else if (tabName === 'qualityStandardProcess') {
      if (params.quality_Testing_Frequency_Enum != 0 && params.quality_Testing_Frequency_Enum != null) {
        await helpers.validateSchema(qualityStandardProcessSchema, params);
      }
      if (params.quality_Certification_Enum != 0 && params.quality_Certification_Enum != null) {
        await helpers.validateSchema(qualityStandardProcessSchema1, params);
      }
    }
    //await helpers.validateSchema(ExtendedProfileProductSchema, params);

    const res = await api.update(params);

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    // utils.displayErrors(err, formikBag);
    throw err;
  }
};

export const sumbitForInspection = async (id) => {
  try {
    let res = await api.sumbitForInspection(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const submitForAssessment = async (id, comments) => {
  try {
    let res = await api.submitForAssessment(id, comments);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchCTList = async (params) => {
  try {
    let res = await api.fetchCTList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const allotInspection = async (params) => {
  try {
    let res = await api.allotInspection(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateInspectionStatus = async (params) => {
  try {
    let res = await api.updateInspectionStatus(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const cancelInspection = async (id) => {
  try {
    let res = await api.cancelInspection(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const rollBackSubmission = async (id) => {
  try {
    let res = await api.rollBackSubmission(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const unassignInspector = async (params) => {
  try {
    let res = await api.unassignInspector(params);
    return res;
  } catch (err) {
    throw err;
  }
};
