import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as companyActions } from 'redux/companies';
import { actions as affiliateActions } from 'redux/affiliates';
import _ from 'lodash';
import api from 'lib/api';

export default function CompanySelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialDisplayText,
    disabled,
    ...rest
  } = props;

  // console.log('CompanySelect-------->props', props);

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies);
  const affiliates = useSelector((state) => state.affiliates);

  const companyData = _.get(companies, `entities`) || {};
  const affiliateData = _.get(affiliates, `entities`) || {};

  _.each(companyData, (item, id) => {
    options.push({
      label: item.company_code || '',
      value: {
        ...item,
        id: parseInt(id),
        type: 'company',
      },
    });
  });

  _.each(affiliateData, (item, id) => {
    options.push({
      label: item.affiliate_name || '',
      value: {
        ...item,
        id: parseInt(id),
        type: 'affiliate',
      },
    });
  });

  if (!options.length) {
    loading = true;
  }

  if (companies.dirty || affiliates.dirty) {
    loading = true;
  }

  useEffect(() => {
    if (companies.dirty) {
      dispatch(companyActions.fetchList());
    }
  }, [companies.dirty]);

  useEffect(() => {
    if (affiliates.dirty) {
      dispatch(affiliateActions.fetchList());
    }
  }, [affiliates.dirty]);

  useEffect(() => {
    if (!loading) {
      let initiaEntityId = _.get(props, 'form.initialValues.entity_id') || 0;

      const initialSelected = options.filter((o) => initiaEntityId === o.value?.id);
      // console.log('\n\n\ninitiaEntityId---------->', initiaEntityId);
      // console.log('options---------->', options);
      // console.log('initialSelected---------->', initialSelected);
      setValue(initialSelected);
    }
  }, [loading]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`aoi-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={disabled}
        getOptionLabel={(option) => {
          // console.log('option---->', option);
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }} // filterOptions={(x) => x}
        // loading={true}
        options={options}
        // filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          // console.log('newValue------->', newValue);
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
