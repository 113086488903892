import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';
import dayjs from 'dayjs';

const { baseUrl } = config;

export const fetchList = async (params = {}) => {
  try {
    const qs = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/FarmDetails/FetchAllFarmsAgainstPersonId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-farms-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err);
    throw errors;
  }
};

export const fetchDetails = async (transportDeliveryScheduleAllotmentId) => {
  try {
    const qs = reqTransform.fetchDetails(transportDeliveryScheduleAllotmentId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryDetailsAgainstTransportDeliveryScheduleAllotedId_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.fetchDetails(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(
      err,
      '',
      'FetchTransportDeliveryDetailsAgainstTransportDeliveryScheduleAllotedId_ELOC',
    );
    throw errors;
  }
};

export const fetchCargoDetails = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchCargoDetails(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchCargoDetailsAgainstDeliveryId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchCargoDetails(resData1);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCargoDetailsAgainstDeliveryId');
    // throw errors;
    return {};
  }
};

export const fetchCargoDetailsCT = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchCargoDetails(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchCargoDetailsAgainstDeliveryId_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchCargoDetails(resData1);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCargoDetailsAgainstDeliveryId_CT');
    // throw errors;
    return {};
  }
};
export const fetchDeliveryPhases = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchDeliveryPhases(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryPhasesAgainstDeliveryId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchDeliveryPhasesDetails(resData1);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchTransportDeliveryPhasesAgainstDeliveryId');
    // throw errors;
    return {};
  }
};

export const fetchDeliveryPhasesCT = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchDeliveryPhases(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchTransportDeliveryPhasesAgainstDeliveryId_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchDeliveryPhasesDetails(resData1);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchTransportDeliveryPhasesAgainstDeliveryId_CT');
    // throw errors;
    return {};
  }
};

export const fetchQualitySpecs = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchCargoDetails(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchQsAgainstDeliveryId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchQSList(resData1);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchQsAgainstDeliveryId');
    // throw errors;
    return [];
  }
};

export const fetchQualitySpecsCT = async (deliveryId) => {
  try {
    const qs = reqTransform.fetchCargoDetails(deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/FetchQsAgainstDeliveryId_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData1 = await utils.processApiRes(res);
    const resData = resTransform.fetchQSList(resData1);

    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchQsAgainstDeliveryId');
    // throw errors;
    return [];
  }
};

export const create = async (params) => {
  throw new Error('No api to create delivery');
};

export const update = async (id, params = {}) => {
  throw new Error('No api to update delivery');
};

export const destroy = async (id) => {
  throw new Error('No api to destroy delivery');
};

export const updateCargoDetails = async (deliveryId, params = {}) => {
  try {
    const bodyParams = reqTransform.updateCargoDetails(params, deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateQuantityAndCargoDetailsAgainstDeliveryId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateQuantityAndCargoDetailsAgainstDeliveryId');
    throw errors;
  }
};

export const updateCargoDetailsCT = async (deliveryId, params = {}) => {
  try {
    const bodyParams = reqTransform.updateCargoDetails(params, deliveryId);
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateQuantityAndCargoDetailsAgainstDeliveryId_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateQuantityAndCargoDetailsAgainstDeliveryId_CT');
    throw errors;
  }
};

export const updateQS = async (params) => {
  try {
    const bodyParams = {
      ...params,
    };
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateQsAgainstDeliveryQualitySpecId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateQs');
    throw errors;
  }
};

export const updateQSCT = async (params) => {
  try {
    const bodyParams = {
      ...params,
    };
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateQsAgainstDeliveryQualitySpecId_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateQs');
    throw errors;
  }
};

export const updateQualitySpecs = async (deliveryId, params = {}) => {
  try {
    const bodyParams = {
      ...params,
      invoking_person_id: _.get(window, 'user.person_id'),
      delivery_id: deliveryId,
    };

    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateQsAgainstDeliveryId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateQsAgainstDeliveryId');
    throw errors;
  }
};

export const updateTripStatus = async (deliveryId, params = {}) => {
  try {
    const bodyParams = {
      delivery_phase_enum: 0,
      transport_delivery_schedule_alloted_id: 0,
      delivery_id: deliveryId,
      ...params,
    };

    const url = `${baseUrl}/BioFuelDeliveries/api/TripDetails/UpdateDeliveryPhasesAgainstDeliveryId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateDeliveryPhasesAgainstDeliveryId_ELOC');
    throw errors;
  }
};

export const updateTripStatusCT = async (deliveryId, params = {}) => {
  try {
    const bodyParams = {
      delivery_phase_enum: 0,
      transport_delivery_schedule_alloted_id: 0,
      delivery_id: deliveryId,
      ...params,
    };

    const url = `${baseUrl}/BioFuelDeliveries/api/TripDetails/UpdateDeliveryPhasesAgainstDeliveryId_CT`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateDeliveryPhasesAgainstDeliveryId_CT');
    throw errors;
  }
};

export const UpdateBuyerSellerRefOnDeal = async (params) => {
  try {
    params['buyer_seller_ref_date'] = dayjs(params['buyer_seller_ref_date']).format('YYYY-MM-DD HH:mm:ss');
    const bodyParams = {
      ...params,
    };
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateBuyerSellerRefOnDeal`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateBuyerSellerRefOnDeal');
    throw errors;
  }
};

export const UpdateBuyerSellerRefOnDelivery = async (params) => {
  try {
    params['buyer_seller_ref_date'] = dayjs(params['buyer_seller_ref_date']).format('YYYY-MM-DD');
    params['transport_delivery_schedule_alloted_id'] = parseInt(params['transport_delivery_schedule_alloted_id']);
    const bodyParams = {
      ...params,
    };
    const url = `${baseUrl}/BioFuelDeliveries/api/DeliveryAssignment/UpdateBuyerSellerRefOnDelivery`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'UpdateBuyerSellerRefOnDelivery');
    throw errors;
  }
};

export const deleteUploadedFileAgainstId = async (params) => {
  try {
    const bodyParams = reqTransform.deleteUploadedFileAgainstId(params);
    const url = `${baseUrl}/BioFuelRegistration/api/QualityLab/DeleteUploadedFileAgainstId?${bodyParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteUploadedFileAgainstId(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteUploadedFileAgainstId');
    throw errors;
  }
};

export const createDelayQsInvoice = async (params) => {
  try {
    const bodyParams = reqTransform.createDelayQsInvoice(params);
    const url = `${baseUrl}/BioFuelDeliveries/api/Invoices/CreateDelayQSInvoice?${bodyParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'createDelayQsInvoice');
    throw errors;
  }
};

export const enableDisableEarlyPaymentOnDelivery = async (params) => {
  try {
    let qsParams = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/EnableDisableEarlyPaymentOnDelivery?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'EnableDisableEarlyPaymentOnDelivery');
    throw errors;
  }
};
