import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { Formik, Field, Form, FieldArray } from 'formik';

const ConfirmDeleteModal = (props) => {
  const { farmDetails, entityId, closeModal, color, btn, title, message, colored, header, rtl, backLink } = props;
  const [loading, setLoading] = useState(false);

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  const handleConfirm = async (values, formikBag) => {
    setLoading(true);
    try {
      let entityId = values.entityId || 0;
      let res = await props.handleDelete(props.entityId);
      props.closeModal(res);
    } catch (err) {
      console.log('ConfirmDeleteModal-->handleConfirmErr---->', err);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="d-none">
        <Button color={color} onClick={closeModal}>
          {btn}
        </Button>
      </div>
      <Modal
        isOpen={entityId}
        toggle={closeModal}
        modalClassName={`ltr-support confirm-delete-modal`}
        className={`modal-dialog--${color} ${modalClass}`}>
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeModal} />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        <ButtonToolbar className="modal__footer">
          <Button disabled={loading} className="modal_cancel" onClick={closeModal}>
            Cancel
          </Button>{' '}
          <Button disabled={loading} className="modal_ok" outline={colored} color={color} onClick={handleConfirm}>
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}{' '}
            {loading ? `Processing...` : `Yes, I'm Sure`}
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteModal;
