import React, { useState, useEffect, useRef } from 'react';
import DebugBtn from 'common/debug/DebugBtn';
// import FormFillBtn from 'common/debug/FormFillBtn';
import { useLocation } from 'react-router-dom';
import { decryptionInHex } from './utils';

// Hook
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// export function renderDebugBtn(props) {
//   return <DebugBtn {...props} />;
// }

// export function renderFormFillBtn(props) {
//   return <FormFillBtn {...props} />;
// }

export function useQuery() {
  const parsedUrl = useLocation().search;
  if (parsedUrl) {
    const queryParams = parsedUrl.split('?')[1];
    const isAlreadyEncrypted = decryptionInHex(queryParams);
    if (isAlreadyEncrypted) {
      return new URLSearchParams(`?${isAlreadyEncrypted}`);
    }
  }
  return new URLSearchParams(useLocation().search);
}
