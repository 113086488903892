import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import notifications from 'lib/notifications';

let isWarning = false;
export const deliverySchema = yup.object().shape({
  schedule: yup.array().of(
    yup.object().shape({
      truck_size: yup.number().required(),
      total_quantity: yup
        .number()
        .required()
        .test('total_quantity', 'enter quantity must be in multiple of truck size', function (item) {
          let val = this.parent.total_quantity % this.parent.truck_size;
          let result = false;
          if (val === 0) {
            result = true;
          }
          if (val !== 0) {
            if (this.parent.uom_type_enum === 2) {
              !isWarning &&
                notifications.show({
                  type: 'warning',
                  message: 'Entered quantity should be in multiple of truck size',
                });
              isWarning = true;
              result = true;
            }
          }
          return result;
        }),
    }),
  ),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

// export const create = async (params) => {
//   try {
//     await helpers.validateSchema(AffiliateSchema, params);
//     const res = await api.create(params);
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };

export const update = async (id, params = {}) => {
  try {
    await helpers.validateSchema(deliverySchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const confirm = async (id, params = {}) => {
  try {
    await helpers.validateSchema(deliverySchema, params);
    const res = await api.confirm(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

// export const destroy = async (id) => {
//   try {
//     let res = await api.destroy(id);
//     return res;
//   } catch (err) {
//     throw err;
//   }
// };
