import React from 'react';
import LogoLoader from 'common/LogoLoader';

const Loader = (props) => {
	const { loading = true, type, minHeight = 'calc(100vh - 150px)', style } = props;

	if (!loading) {
		return null;
	}

	return (
		<div
			className=""
			style={style ? style : {
				position: 'relative',
				minHeight: minHeight,
				width: '100%',
			}}>
			<div className="panel__refresh loader" style={{ display: 'contents' }}>
				<LogoLoader />
			</div>
		</div>
	);

	/*
	return (
		<div className={`load${loading ? '' : ' loaded'}`}>
			<div className="load__icon-wrap">
				<svg className="load__icon">
					<path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
				</svg>
			</div>
		</div>
	)
*/
};

export default Loader;
