import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    const bodyParams = reqTransform.fetchList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchApplicationInbox`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchApplicationInbox-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchApplicationInbox');
    throw errors;
  }
};

export const fetchDetails = async (params) => {
  try {
    const qsParams = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchApplicantsProfile?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchUserList(resData);
  } catch (err) {
    console.log('api-FetchApplicantsProfile-fetchDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchApplicantsProfile');
    throw errors;
  }
};

export const fetchLedgerDocument = async (params) => {
  try {
    const qsParams = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/GetLedgerForLoan?${qsParams}`;
    const res = await utils.fetch(url).ready;
    await utils.processApiRes(res);
    return true;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'GetLedgerForLoan');
    throw errors;
  }
};

export const update = async (params) => {
  try {
    const bodyParams = reqTransform.update(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/UpdateFinanceCompanyDetails`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('api-UpdateFinanceCompanyDetails-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateFinanceCompanyDetails');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    const bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/RespondApplication`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('api-RespondApplication-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RespondApplication');
    throw errors;
  }
};

export const fetchDealsList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchDealsList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchAllCommodityDealsAgainstEntityIdWithFilter_Eloc`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    const finalRes = {
      data: [...resData],
    };
    return finalRes;
  } catch (err) {
    console.log('api-FetchAllCommodityDealsAgainstEntityId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCommodityDealsAgainstEntityIdWithFilter_Eloc');
    throw errors;
  }
};

export const fetchFinanceList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchFinanceList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchAllFinanceInvoiceApplication`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchProviderFinanceList(resData, params['isCsv']);
  } catch (err) {
    console.log('api-FetchAllFinanceInvoiceApplication-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllFinanceInvoiceApplication');
    throw errors;
  }
};

export const fetchCreditUtilization = async (params) => {
  try {
    let bodyParams = reqTransform.fetchFinanceList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/FetchCreditUtilization`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchCreditUtilization');
    throw errors;
  }
};

export const fetchUserFinanceList = async (params) => {
  try {
    let bodyParams = reqTransform.fetchFinanceList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/FetchAllInvoiceDetailsAgainstPersonId`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchFinanceList(resData);
  } catch (err) {
    console.log('api-FetchAllInvoiceDetailsAgainstPersonId-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllInvoiceDetailsAgainstPersonId');
    throw errors;
  }
};

export const fetchBankList = async (params) => {
  try {
    let qsParams = reqTransform.fetchBankList(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/FetchCreditLineApprovedFinanceCompanies?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchBankList(resData);
  } catch (err) {
    console.log('api-FetchAllInvoiceDetailsAgainstPersonId-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchCreditLineApprovedFinanceCompanies');
    throw errors;
  }
};

export const createInvoiceApply = async (params) => {
  try {
    let qsParams = reqTransform.createInvoiceApply(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceApplication/ApplyInvoiceForFinance?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createInvoiceApply(resData);
  } catch (err) {
    console.log('api-ApplyInvoiceForFinance-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ApplyInvoiceForFinance');
    throw errors;
  }
};

export const createConfirm = async (params) => {
  try {
    let qsParams = reqTransform.createConfirm(params);
    const url = `${baseUrl}/BioFuelTradeFinance/api/FinanceProvider/ConfirmInvoiceFinanceApplication?${qsParams}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.createConfirm(resData);
  } catch (err) {
    console.log('api-ConfirmInvoiceFinanceApplication-->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'ConfirmInvoiceFinanceApplication');
    throw errors;
  }
};

export const RejectDeal_CT = async (params) => {
  try {
    let qsParams = reqTransform.createConfirm(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/RejectDeal_CT?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'RejectDeal_CT');
    throw errors;
  }
};

export const MarkDealAsComplete = async (params) => {
  try {
    let qsParams = reqTransform.createConfirm(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/MarkDealAsCompleted?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'MarkDealAsComplete');
    throw errors;
  }
};

export const applyEarlyPaymentOffer = async (params) => {
  try {
    let qsParams = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/ApplyEarlyPaymentOffer?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'ApplyEarlyPaymentOffer');
    throw errors;
  }
};

export const allowEarlyPaymentCT = async (params) => {
  try {
    let qsParams = utils.getQs(params);
    const url = `${baseUrl}/BioFuelPayments/api/EarlyPayment/AllowEarlyPayment_CT?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AllowEarlyPayment_CT');
    throw errors;
  }
};

export const addManualDeliveries = async (params) => {
  try {
    let qsParams = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/SystemMaintenance/AddManualDeliveries?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'AddManualDeliveries');
    throw errors;
  }
};

export const convertToBioFuelCircleTransport = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransporterConversion/ConvertToBioFuelCircleTransport`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'ConvertToBioFuelCircleTransport');
    throw errors;
  }
};

export const checkDealLinkingApplicability = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/CheckDealLinkingApplicability?${qs}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'CheckDealLinkingApplicability');
    throw errors;
  }
};

export const attachDealToIndentBuy = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/AttachDealToIndentBuy?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'AttachDealToIndentBuy');
    throw errors;
  }
};
