import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as companyActions } from 'redux/companies';
import { actions as affiliateActions } from 'redux/affiliates';
import _ from 'lodash';
import api from 'lib/api';
import Loading from 'theme/shared/components/Loading';
import utils from 'lib/utils';

export default function CompanySelect(props) {
  const {
    field,
    form: { touched, errors, setFieldValue },
    label,
    children,
    select,
    variant,
    disabled,
    initialDisplayText,
    checkKYCStatus,
    placeholder,
    setCompanyAffilateRating,
    setSelectedUserType,
    entity_id,
    offerPersonId,
    isCTUser,
    ctPersonId,
    isPrepaidScreen,
    isDvo,
    isProcessingDeal,
    ...rest
  } = props;
  let checkKYC = true;
  if (!checkKYCStatus) {
    checkKYC = checkKYCStatus;
  }

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const companies = useSelector((state) => state.companies);
  const affiliates = useSelector((state) => state.affiliates);
  const companyData = _.get(companies, `entities`) || {};
  const reduxCompanyId = _.get(companies, `ids[0]`) || [];
  const affiliateData = _.get(affiliates, `entities`) || {};
  const companyId = _.get(user, 'profile.person.company_id');
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;

  const companyCodeLocation = Object.values(companyData);
  const affiliatesCodeLocation = Object.values(affiliates.entities);
  let personId;
  if (isCTUser) {
    if (ctPersonId && !isPrepaidScreen) {
      personId = ctPersonId;
    } else {
      personId = _.get(props, `form.initialValues.entity_id`) || 0;
    }
  }

  _.each(companyData, (item, id) => {
    if (checkKYC == false) {
      options.push({
        label: item.company_code || '',
        value: {
          ...item,
          id: parseInt(id),
          type: 'company',
        },
      });
    } else if (item.kyc_status_enum_code === 'Approved') {
      options.push({
        label: item.company_code || '',
        value: {
          ...item,
          id: parseInt(id),
          type: 'company',
        },
      });
    }
  })

  {
    !isControlTowerUser && (
      _.each(affiliateData, (item, id) => {
        if (checkKYC == false) {
          options.push({
            label: item.affiliate_name || '',
            value: {
              ...item,
              id: parseInt(id),
              type: 'affiliate',
            },
          });
        } else if (item.kyc_status_enum_code === 'Approved') {
          options.push({
            label: item.affiliate_name || '',
            value: {
              ...item,
              id: parseInt(id),
              type: 'affiliate',
            },
          });
        }
      })
    )
  };

  // console.log('check company select 1', isCTUser, ctPersonId, options, personId, reduxCompanyId, companyId);
  // console.log('check company select 2', value, user, companies)

  // if (!options.length || (reduxCompanyId !== companyId)) {
  //   loading = true;
  // }

  // _.each(affiliateData, (item, id) => {
  //   if (checkKYC == false) {
  //     options.push({
  //       label: item.affiliate_name || '',
  //       value: {
  //         ...item,
  //         id: parseInt(id),
  //         type: 'affiliate',
  //       },
  //     });
  //   } else if (item.kyc_status_enum_code === 'Approved') {
  //     options.push({
  //       label: item.affiliate_name || '',
  //       value: {
  //         ...item,
  //         id: parseInt(id),
  //         type: 'affiliate',
  //       },
  //     });
  //   }
  // });

  if (!options.length) {
    loading = true;
  }

  if (companies.dirty || affiliates.dirty) {
    loading = true;
  }

  useEffect(() => {
    if (isCTUser) {
      const params = {
        person_id: parseInt(personId),
      };
      let refreshCounter = 1;
      let refreshFunction = setInterval(function () {
        if (refreshCounter > 0) {
          dispatch(companyActions.fetchList(params));
          refreshCounter = refreshCounter - 1;
        } else {
          clearInterval(refreshFunction);
        }
      }, 1000);
    } else {
      if (companies.dirty) {
        dispatch(companyActions.fetchList());
      }
    }
  }, [isCTUser, companies.dirt]);

  useEffect(() => {
    if (isCTUser && !isPrepaidScreen) {
      const params = {
        person_id: parseInt(personId),
      };
      let refreshCounter = 1;
      let refreshFunction = setInterval(function () {
        if (refreshCounter > 0) {
          dispatch(companyActions.fetchList(params));
          refreshCounter = refreshCounter - 1;
        } else {
          clearInterval(refreshFunction);
        }
      }, 1000);
    } else {
      const params = {
        person_id: parseInt(personId),
      };
      if (affiliates.dirty) {
        dispatch(affiliateActions.fetchList(isPrepaidScreen ? params : {}));
      }
    }
  }, [isCTUser, affiliates.dirty]);

  useEffect(() => {
    // debugger
    // if (!loading) {
    let initiaEntityId = _.get(props, 'form.initialValues.entity_id') || entity_id || options[0]?.value?.id ||  0;
    if (offerPersonId) {
      initiaEntityId = offerPersonId || 0;
    }
    let initialSelected = reduxCompanyId == companyId && options.filter((o) => initiaEntityId === (o?.value.id || personId));
    // const initialSelected = reduxCompanyId == companyId && options.filter((o) => initiaEntityId === o?.value.id);
    if (isPrepaidScreen) {
      initialSelected = options?.filter((o) => o?.value?.created_by === parseInt(personId));
    }
    if (isDvo) {
      initialSelected = options;
    }
    // if (reduxCompanyId === companyId) {
    setValue(initialSelected?.[0]);
    // }
    if (initiaEntityId) {
      if (props.cb) {
        if (!_.isEmpty(initialSelected[0])) {
          props.cb(initialSelected[0]);
        }

      }
    }
    if (initialSelected?.[0]?.value?.type === 'company') {
      setFieldValue('company_affiliate_enum', 1);
    } else {
      setFieldValue('company_affiliate_enum', 2);
    }
    // }
  }, [loading, entity_id, options.length, companies.busy, affiliates.busy]);

  useEffect(() => {
    if (options.length === 1) {
      // if (reduxCompanyId === companyId) {
      setValue(options[0]);
      // }
      let type = _.get(options[0], 'value.type') || '';
      let entity_id = _.get(options[0], 'value.id') || 0;
      let rating = _.get(options[0], 'value.ratings') || 0;
      setFieldValue('entity_id', entity_id);
      let groupPlanId = _.get(options[0], 'value.group_plan_id') || false;
      setFieldValue('group_plan_id', groupPlanId);
      let isSubscribed = _.get(options[0], 'value.is_subscribed') || false;
      setFieldValue('isCompanyAffSubscribed', isSubscribed);
      setCompanyAffilateRating && setCompanyAffilateRating(rating)
      setSelectedUserType && setSelectedUserType(type)
      if (type === 'company') {
        setFieldValue('company_affiliate_enum', 1);
        setFieldValue('person_company_affiliate_enum', 2);
      } else {
        setFieldValue('company_affiliate_enum', 2);
        setFieldValue('person_company_affiliate_enum', 3);
      }
      props.cb && props.cb(options[0])
    }
  }, [loading, options.length, companies.busy, affiliates.busy]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';


  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`aoi-${Date.now()}`}
        size="small"
        disableClearable
        fullWidth
        loading={(reduxCompanyId == companyId) ? false : loading}
        disabled={isProcessingDeal && disabled ? disabled : (reduxCompanyId == companyId) ? false : loading || disabled}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }}
        renderOption={(option) => {
          let messageColor = '';
          switch (option?.value?.subscription_plan_name || option?.value?.plan_name) {
            case 'Prepaid Pack':
              messageColor = '#1B62AB';
              break;
            case 'Business Pack':
              messageColor = '#1B62AB';
              break;
            case 'Bioenergy Pack':
              messageColor = '#1B62AB';
              break;
            case 'Enterprise Pack':
              messageColor = '#707070';
              break;
          }
          return (
            <table className="w-100">
              <tr>
                <td>{option.label}</td>
                <td style={{ float: 'right' }}>
                  {option?.value?.is_subscribed ? (
                    <>
                      {option?.value?.subscription_plan_name === 'Prepaid Pack' || option?.value?.subscription_plan_name === 'Top Up' || option?.value?.plan_name === 'Prepaid Pack' ? (
                        <p style={{ color: messageColor }}>
                          {option?.value?.prepaid_balance ? `₹: ${utils.formatQty(option?.value?.prepaid_balance)}` : `₹: 0`}</p>
                      ) : (
                        // <p style={{ color: messageColor }}>{option?.value?.subscription_plan_name || option?.value?.plan_name}</p>
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            </table>
          );
        }}
        options={options}
        value={(reduxCompanyId == companyId) ? value : isPrepaidScreen || isDvo ? value && value : ''}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
          setFieldValue('person_company_affiliate_enum', newValue?.value?.person_company_affiliate_enum)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (props.cb) {
                props.cb(newValue);
              }
              setFieldValue('person_company_affiliate_enum', newValue?.value?.person_company_affiliate_enum)
            }}
            // style={{ backgroundColor: 'white' }}
            // placeholder={
            //   reduxCompanyId == companyId &&
            //   companies.busy ? '' : affiliatesCodeLocation.length == 0 && affiliatesCodeLocation.length !== null ? companyCodeLocation[0]?.company_code : ''
            // }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? reduxCompanyId != companyId ? <CircularProgress color="inherit" size={20} /> : '' : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
