import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import LogoLoader from 'common/LogoLoader';
import { TYPE_OF_INDIVIDUAL_ENUM } from 'constants/enums';
import { actions as companyActions } from 'redux/companies';
import { actions as affiliateActions } from 'redux/affiliates';
import { actions as uploadActions } from 'redux/uploadDoc';
import { actions as kycBusinessCompanyActions } from 'redux/companyAffiliates';
import { actions as kycBusinessActions } from 'redux/kycBusiness';
import FormikMaterialTextField from 'containers/Account/Profile/finance/components/FormikMaterialTextField';
import Collapse from '../../components/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import UploadDocument from './UploadDocument';
import { kycService } from 'services';
import FormWithBtn from 'common/Form/FormWithBtn';
import useQuery from 'lib/hooks/useQuery';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@material-ui/core';
import 'assets/scss/tooltip.scss';
import { actions as kycBusinessBankIdentityActions } from 'redux/kycBusinessBankIdentity';
import _ from 'lodash';
import { actions as dashboardActions } from 'redux/dashboard';
import { actions as userActions } from 'redux/user';
import MUIToggleControl from 'common/MUIComponents/ToggleControl';
import AuthorityLetterTemplate from 'assets/Letter of Authority for BiofuelCircle.docx'
import { Tooltip } from 'antd';
import MsWordIcon from 'assets/images/icons/ms-word.svg'

const getInitialValues = (uploadDetails) => {
  let params = {
    user_id: _.get(window, 'user.userid') || 0,
  };
  return params;
};

const uploadForm = (props) => {

  const { back } = useQuery();
  const { entity_id, company_affiliate_enum, kyc_status_enum_code, person_company_affiliate_enum } = props;
  const [showApproved, setShowApproved] = useState(false);
  const [showCancelledCheque, setShowCancelledCheque] = useState(false);
  const [showMSMEDocument, setShowMSMEDocument] = useState(false);
  const [isGpa, setIsGpa] = useState(true);
  const id = props.id;
  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  const qualityLaboratory = _.get(kycUser, 'profile.person.quality_laboratory') || false;
  const isKycTransporter = _.get(kycUser, 'profile.person.is_transporter') || false;
  const isTransporter = _.get(user, 'profile.person.is_transporter') || false;
  let type_of_individual_enum = _.get(user, 'profile.person.type_of_individual_enum') || false;
  const companyId = _.get(user, 'profile.person.company_id') || 0;
  let companies = useSelector((state) => state.companies);
  let affiliates = useSelector((state) => state.affiliates);
  let kycBusiness = useSelector((state) => state.kycBusiness?.entities);
  const kycBankBusiness = useSelector((state) => state.kycBusinessBankIdentity);
  const bankDetails = kycBankBusiness?.bankDetails || [];
  const companyData = _.get(companies, `entities[${entity_id}]`) || {};
  const affiliateData = _.get(affiliates, `entities[${entity_id}]`) || {};
  const businessTypeEnum = companyData.business_type_enum || affiliateData.business_type_enum;
  const isProprietor = _.get(user, 'profile.person.is_proprietor') || false;
  const isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  const globalParticipationData = user?.profile?.gpa_entity_list

  const [isOpen, setIsOpen] = useState(false);

  let businessType = '';
  let isKycDone = false;

  if (company_affiliate_enum === 1) {
    if (_.isEmpty(companies)) {
      dispatch(companyActions.setDirty());
    }
    companies = useSelector((state) => state.companies);
    const companyData = _.get(companies, `entities[${entity_id}]`) || {};
    businessType = _.get(companyData, 'business_type_enum') || 0;
  } else if (company_affiliate_enum === 2) {
    if (_.isEmpty(affiliates)) {
      dispatch(affiliateActions.setDirty());
    }
    affiliates = useSelector((state) => state.affiliates);
    let affiliateData = _.get(affiliates, `entities[${entity_id}]`) || {};
    businessType = _.get(affiliateData, 'business_type_enum') || 0;
  }
  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }
  if (isAdmin) {
    type_of_individual_enum = _.get(kycUser, 'profile.person.type_of_individual_enum') || false;
  }
  let prevLink = `/account/${id}/kyc-b`;
  let nextLink = `/account/${id}/finance`;
  if (isAdmin) {
    prevLink = `/account/${id}/kyc-b?back=${back}`;
    nextLink = `/account/${id}/finance?back=${back}`;
  }
  if (qualityLaboratory || isKycTransporter) {
    nextLink = `/account/${id}/subscription?back=${back}`;
  }
  let kycStatusKey = false;
  if (kyc_status_enum_code == 'Approved' || kyc_status_enum_code == 'Pending') {
    kycStatusKey = true;
  }
  if (kyc_status_enum_code == 'Rejected' || kyc_status_enum_code == 'Not Submitted') {
    kycStatusKey = false;
  }

  let kycStatusRejected = true;
  if (kyc_status_enum_code == 'Rejected' || kyc_status_enum_code == 'Not Submitted') {
    kycStatusRejected = false;
  }
  const upload = useSelector((state) => state.uploadDoc);
  let uploadDetails = _.get(upload, 'entities') || {};

  //busy flag
  const busyUpload = _.get(upload, 'busy');

  const dispatch = useDispatch();

  let uploadedFileNameFront,
    uploadedFileNameBack,
    attachmentIdFront,
    attachmentIdBack,
    attachmentIdBusinessReg,
    attachmentIdCompany,
    attachmentIdPropreitor,
    attachmentIdNameForm12,
    attachmentIdNameForm80,
    attachmentIdAuthority,
    attachmentIdNrlm,
    attachmentIdCancelledCheque,
    attachmentIdNablCertificate,
    attachmentPathFront,
    attachmentPathBack,
    attachmentPathBusinessReg,
    attachmentPathCompany,
    attachmentPathPropreitor,
    attachmentPathNameForm12,
    attachmentPathNameForm80,
    attachmentPathAuthority,
    attachmentPathNrlm,
    attachmentPathCancelledCheque,
    attachmentPathNablCertificate,
    attachmentIdBalanceSheet,
    attachmentIdPLStatement,
    attachmentIdIncomeTaxReturnsLatest,
    attachmentIdIncomeTaxReturnsPreviousYear,
    attachmentPathBalanceSheet,
    attachmentPathPLStatement,
    attachmentPathIncomeTaxReturnsLatest,
    attachmentPathIncomeTaxReturnsPreviousYear,
    uploadedEntityTypeEnum,
    uploadedEntityTypeEnumCode,
    uploadedEntityTypeEnumToPass = 0,
    uploadedFileNameBusinessReg,
    uploadedFileNameForm80,
    uploadedFileNameCompany,
    uploadedFileNamePropreitor,
    uploadedFileNameForm12,
    uploadedFileNameAuthority,
    uploadedFileNameCancelledCrossedCheque,
    uploadedFileNameNablCertificate,
    uploadFileNameBalanceSheet,
    uploadFileNamePLStatement,
    uploadFileNameIncomeTaxReturnsLatest,
    uploadFileNameIncomeTaxReturnsPreviousYear,
    uploadeNrlmRegistrationLetter,
    uploadedCompanyIdCard

  // console.log('check condition', kycBusiness)

  for (let i = 0; i < upload.ids.length; i++) {
    let id = upload.ids[i];
    const uploadList = upload.entities[id] || [];
    const frontBackEnumValue = uploadList.front_back_enum;

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 55 ||
      uploadList.entity_type_enum == 19
    ) {
      uploadedEntityTypeEnum = uploadList.entity_type_enum;
      uploadedEntityTypeEnumCode = uploadList.entity_type_enum_code;
    }
    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 55 ||
      uploadList.entity_type_enum == 19
    ) {
      frontBackEnumValue == 1
        ? (uploadedFileNameFront = uploadList.attachment_file_name)
        : (uploadedFileNameBack = uploadList.attachment_file_name);
    } else {
      if (uploadList.entity_type_enum == 6) {
        uploadedFileNameBusinessReg = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 7) {
        uploadedFileNameCompany = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 8) {
        uploadedFileNamePropreitor = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 9) {
        uploadedFileNameForm12 = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 10) {
        uploadedFileNameForm80 = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 11) {
        uploadedFileNameAuthority = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 15) {
        uploadedFileNameCancelledCrossedCheque = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 29) {
        uploadedFileNameNablCertificate = uploadList.attachment_file_name;
      }
      ////////////
      if (uploadList.entity_type_enum == 21) {
        uploadFileNameBalanceSheet = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 22) {
        uploadFileNamePLStatement = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 23) {
        uploadFileNameIncomeTaxReturnsLatest = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 24) {
        uploadFileNameIncomeTaxReturnsPreviousYear = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 54) {
        uploadeNrlmRegistrationLetter = uploadList.attachment_file_name;
      }
      if (uploadList.entity_type_enum == 55) {
        uploadedCompanyIdCard = uploadList.attachment_file_name;
      }
    }

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 55 ||
      uploadList.entity_type_enum == 19
    ) {
      frontBackEnumValue == 1
        ? (attachmentIdFront = uploadList.attachment_id)
        : (attachmentIdBack = uploadList.attachment_id);
    } else {
      if (uploadList.entity_type_enum == 6) {
        attachmentIdBusinessReg = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 7) {
        attachmentIdCompany = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 8) {
        attachmentIdPropreitor = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 9) {
        attachmentIdNameForm12 = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 10) {
        attachmentIdNameForm80 = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 11) {
        attachmentIdAuthority = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 15) {
        attachmentIdCancelledCheque = uploadList.attachment_id;
      }

      if (uploadList.entity_type_enum == 29) {
        attachmentIdNablCertificate = uploadList.attachment_id;
      }

      ///////
      if (uploadList.entity_type_enum == 21) {
        attachmentIdBalanceSheet = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 22) {
        attachmentIdPLStatement = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 23) {
        attachmentIdIncomeTaxReturnsLatest = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 24) {
        attachmentIdIncomeTaxReturnsPreviousYear = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 54) {
        attachmentIdNrlm = uploadList.attachment_id;
      }
      if (uploadList.entity_type_enum == 55) {
        uploadedCompanyIdCard = uploadList.attachment_file_name;
      }
    }

    if (
      uploadList.entity_type_enum == 1 ||
      uploadList.entity_type_enum == 2 ||
      uploadList.entity_type_enum == 3 ||
      uploadList.entity_type_enum == 16 ||
      uploadList.entity_type_enum == 17 ||
      uploadList.entity_type_enum == 18 ||
      uploadList.entity_type_enum == 55 ||
      uploadList.entity_type_enum == 19
    ) {
      frontBackEnumValue == 1
        ? (attachmentPathFront = uploadList.attachment_path)
        : (attachmentPathBack = uploadList.attachment_path);
    } else {
      if (uploadList.entity_type_enum == 6) {
        attachmentPathBusinessReg = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 7) {
        attachmentPathCompany = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 8) {
        attachmentPathPropreitor = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 9) {
        attachmentPathNameForm12 = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 10) {
        attachmentPathNameForm80 = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 11) {
        attachmentPathAuthority = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 15) {
        attachmentPathCancelledCheque = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 29) {
        attachmentPathNablCertificate = uploadList.attachment_path;
      }
      //////
      if (uploadList.entity_type_enum == 21) {
        attachmentPathBalanceSheet = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 22) {
        attachmentPathPLStatement = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 23) {
        attachmentPathIncomeTaxReturnsLatest = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 24) {
        attachmentPathIncomeTaxReturnsPreviousYear = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 54) {
        attachmentPathNrlm = uploadList.attachment_path;
      }
      if (uploadList.entity_type_enum == 55) {
        uploadedCompanyIdCard = uploadList.attachment_file_name;
      }
    }
  }

  let msmeDocs;
  if (Object.values(upload?.entities)?.length) {
    msmeDocs = Object.values(upload.entities)?.filter(item => item.entity_type_enum === 52)
  }

  const handleSubmitForApproval = async (values, formikBag) => {
    try {
      let affiliateId = entity_id;
      if (affiliateId === companyId) {
        affiliateId = 0;
      }

      let resData = await kycService.submitForApproval(affiliateId);

      let params = {
        person_id: parseInt(id),
      };
      await dispatch(kycBusinessCompanyActions.fetchCompanyAndAffiliatesAgainstPersonId(params));
      utils.displayMessage('Submitted For Approval', 'success');
      if (!_.isEmpty(resData) && ['Not Submitted'].includes(kyc_status_enum_code)) {
        const params = {
          gpa_acceptance: [{
            person_company_affiliate_id: entity_id,
            person_company_affiliate_enum
          }],
        };
        const success = await dispatch(dashboardActions.saveGPAAcceptance(params));
        if (!_.isEmpty(success)) {
          dispatch(userActions.fetchPersonalDetails());
        }
      }
    } catch (err) {
      utils.displayErrors(err);
      console.log('handleSubmitForApproval--->err--->', err);
    }
  };

  const [isSHG, setIsSHG] = useState(false);

  const addressProof = [
    { title: 'Passport', id: 2 },
    { title: 'Voter ID', id: 3 },
    { title: 'Driving Licence', id: 16 },
    { title: 'Electricity Bill', id: 17 },
    { title: 'LIC Premium Receipt', id: 18 },
    { title: 'Bank Passbook', id: 19 },
    ...(businessTypeEnum === 4 || businessType === 2 ? [{ title: 'Company Issued ID Card', id: 55 }] : [])
  ];

  const [dropValues, setdropValues] = useState(0);
  const [isRenderRequired, setIsRenderRequired] = useState(false);
  const authLetter = Object.values(uploadDetails).filter((item) => item.entity_type_enum === 11).length;

  useEffect(() => {
    if (uploadedEntityTypeEnum) {
      setdropValues(uploadedEntityTypeEnum)
    } else {
      setdropValues(0)
    }
  }, [uploadedEntityTypeEnum, isOpen])


  const onTagsChange = (event, dropdownValues) => {
    setdropValues(dropdownValues?.id);
    if (uploadedEntityTypeEnum && uploadedEntityTypeEnum !== dropdownValues.id) {
      setIsOpen(true);
    }
  };

  {
    uploadedEntityTypeEnum && kyc_status_enum_code != 'Rejected' && kyc_status_enum_code != 'Not Submitted'
      ? (uploadedEntityTypeEnumToPass = uploadedEntityTypeEnum)
      : (uploadedEntityTypeEnumToPass = dropValues);
  }

  useEffect(() => {
    if (!_.isEmpty(upload)) {
      const flag = _.get(upload, 'approvalFlag') || false;
      const cancelledChequeFlag = _.get(upload, 'cancelledChequeFlag') || false;
      const showMSMEClassificationFlag = _.get(upload, 'showMSMEClassificationFlag')
      setShowApproved(flag);
      setShowCancelledCheque(cancelledChequeFlag);
      setShowMSMEDocument(showMSMEClassificationFlag)
    }
  }, [upload]);

  useEffect(() => {
    let params = {
      entity_id: entity_id,
      company_affiliate_enum: company_affiliate_enum,
    };
    dispatch(uploadActions.fetchList(params));
    const newParams = {
      ...params,
      person_id: id,
    };
    dispatch(kycBusinessActions.fetchKYCBusinessIdentityDetails(newParams));
    setIsRenderRequired(false);
  }, [entity_id, isRenderRequired]);

  useEffect(() => {
    const params = {
      person_id: parseInt(id),
    };
    dispatch(companyActions.fetchList(params));
  }, [companies.dirty]);

  useEffect(() => {
    const params = {
      person_id: parseInt(id),
    };
    dispatch(affiliateActions.fetchList(params));
  }, [affiliates.dirty]);

  useEffect(() => {
    if (type_of_individual_enum == 10) {
      setIsSHG(true);
    }
  }, [])


  useEffect(() => {
    if (entity_id !== undefined) {
      const params = {
        entity_id: entity_id,
        company_affiliate_enum: company_affiliate_enum,
        person_id: id,
        isSerilized: false,
      };
      dispatch(kycBusinessBankIdentityActions.fetchBankDetailsWithVistualAccount(params));
    }
  }, [entity_id]);

  if (busyUpload) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }

  let mandatoryDocumentsForProprietor;
  if (showCancelledCheque) {
    if (dropValues == 17) {
      mandatoryDocumentsForProprietor = uploadedFileNameBusinessReg && uploadedFileNamePropreitor && uploadedFileNameFront && uploadedFileNameCancelledCrossedCheque
    } else {
      mandatoryDocumentsForProprietor = uploadedFileNameBusinessReg && uploadedFileNamePropreitor && uploadedFileNameFront && uploadedFileNameBack && uploadedFileNameCancelledCrossedCheque
    }
  } else {
    if (dropValues == 17) {
      mandatoryDocumentsForProprietor = uploadedFileNameBusinessReg && uploadedFileNamePropreitor && uploadedFileNameFront
    } else {
      mandatoryDocumentsForProprietor = uploadedFileNameBusinessReg && uploadedFileNamePropreitor && uploadedFileNameFront && uploadedFileNameBack
    }
  }

  // console.log('business type enum', !mandatoryDocumentsForProprietor ,msmeDocs?.[0]?.attachment_file_name,!mandatoryDocumentsForProprietor && !msmeDocs?.[0]?.attachment_file_name)
  const handleCheck = (values, formikBag) => { };
  return (
    <div className="">
      <Formik
        initialValues={getInitialValues(uploadDetails)}
        // onSubmit={company_tax_detail_id ? handlers.handleUpdate : handlers.handleCreate}>
        onSubmit={handleCheck}>
        {(formikBag) => {
          const { values } = formikBag;
          const { kyc } = values;
          values.company_affiliate_enum = company_affiliate_enum;
          values.entity_id = entity_id;
          // console.log('valuesvalues', uploadedEntityTypeEnum, dropValues)
          return (
            <Form className="">
              <Card className='pb-2'>
                <CardBody className="main-cardbody">
                  <Collapse title="Upload Documents" className="with-shadow">
                    <Card>
                      <CardBody>
                        <div className="form__form-group">
                          <div className="pl-0 col-md-6 col-lg-5 col-xl-4">
                            <span className="form-group-label">{businessTypeEnum === 4 ? 'Authorized Signatory’s Document Proof' : `Authorized Signatory's Address Proof`}</span>
                            <span class="required">*</span>
                            <div className="form-group-field">
                              <Autocomplete
                                size="small"
                                name="entity_type_enum"
                                value={addressProof.find(item => item.id === uploadedEntityTypeEnum)}
                                options={addressProof}
                                getOptionLabel={(option) => option.title}
                                onChange={onTagsChange}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                                disableClearable
                                disabled={
                                  //   // uploadedEntityTypeEnum ? true : false ||
                                  isAdmin || kycStatusKey || kycStatusRejected
                                  //   //  ||
                                  //   // attachmentIdFront ||
                                  //   // attachmentIdBack
                                  //   // && !kycStatusRejected
                                }
                              />
                            </div>
                            {dropValues === 19 && (
                              <div class="notes-block">
                                <p class="greenbg">Upload documents with Sign and Stamped</p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row form__form-group">
                          <Row className="col-md-12">
                            <span className="form-group-label col-md-3">Upload Front Page
                              <span class="required">*</span>
                            </span>

                            <div className="form-group-field col-md-9">
                              <Field
                                name="attachment_file"
                                entity_id={entity_id}
                                entity_type_enum={uploadedEntityTypeEnumToPass}
                                front_back_enum="1"
                                component={UploadDocument}
                                disabled={isAdmin || kycStatusKey}
                                fileName={uploadedFileNameFront}
                                attachmentId={attachmentIdFront}
                                attachmentPath={attachmentPathFront}
                                company_affiliate_enum={company_affiliate_enum}
                                isAuthorizedSignatory={true}
                                setIsRenderRequired={setIsRenderRequired}
                                kycStatusKey={kycStatusKey}
                                setdropValues={setdropValues}
                              />
                            </div>
                          </Row>
                        </div>
                        {dropValues !== 17 && (<div className="row form__form-group">
                          <Row className="col-md-12">
                            <span className="form-group-label col-md-3">Upload Back Page
                              {dropValues === 55 ? '' : <span class="required">*</span>}
                            </span>
                            <div className="form-group-field col-md-9">
                              <Field
                                name="attachment_file1"
                                entity_id={entity_id}
                                entity_type_enum={uploadedEntityTypeEnumToPass}
                                front_back_enum="2"
                                component={UploadDocument}
                                disabled={isAdmin || kycStatusKey}
                                fileName={uploadedFileNameBack}
                                attachmentId={attachmentIdBack}
                                attachmentPath={attachmentPathBack}
                                company_affiliate_enum={company_affiliate_enum}
                                isAuthorizedSignatory={true}
                                setIsRenderRequired={setIsRenderRequired}
                                kycStatusKey={kycStatusKey}
                                setdropValues={setdropValues}
                              />
                            </div>
                          </Row>
                        </div>)}


                        <div>
                          {' '}
                          <hr />
                        </div>

                        <div className="row form__form-group">
                          <Row className="col-md-12">
                            <span className="form-group-label col-md-3">
                              Entity Registration Proof
                              {businessTypeEnum === 1 ? <span className="required" style={{ fontWeight: 700, marginLeft: 3 }}>*</span> : ''}
                            </span>
                            <div className="form-group-field col-md-9">
                              <Field
                                name="businessRegistrationProof"
                                entity_id={entity_id}
                                entity_type_enum="6"
                                front_back_enum="1"
                                component={UploadDocument}
                                fileName={uploadedFileNameBusinessReg}
                                attachmentId={attachmentIdBusinessReg}
                                attachmentPath={attachmentPathBusinessReg}
                                disabled={isAdmin || kycStatusKey}
                                company_affiliate_enum={company_affiliate_enum}
                              />
                            </div>
                          </Row>
                        </div>
                        {
                          showMSMEDocument && businessTypeEnum !== 4 && (
                            <div className="row form__form-group">
                              <Row className="col-md-12">
                                <span className="form-group-label col-md-3">
                                  Udyam / MSME Registration Certificate
                                  {kycBusiness?.msme_classification_enum_code !== 'None' ? <span class="required">*</span> : ''}
                                </span>
                                <div className="form-group-field col-md-9">
                                  <Field
                                    name="udyam_msmeRegistrationCertificate"
                                    entity_id={entity_id}
                                    entity_type_enum="52"
                                    front_back_enum="1"
                                    component={UploadDocument}
                                    fileName={msmeDocs?.[0]?.attachment_file_name}
                                    attachmentId={msmeDocs?.[0]?.attachment_id}
                                    attachmentPath={msmeDocs?.[0]?.attachment_path}
                                    disabled={isAdmin || kycStatusKey}
                                    company_affiliate_enum={company_affiliate_enum}
                                  />
                                </div>
                              </Row>
                            </div>
                          )
                        }

                        {businessTypeEnum != 1 && (
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Company PAN</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="companyPAN"
                                  entity_id={entity_id}
                                  entity_type_enum="7"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadedFileNameCompany}
                                  attachmentId={attachmentIdCompany}
                                  attachmentPath={attachmentPathCompany}
                                  disabled={isAdmin || kycStatusKey}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                        )}

                        {businessTypeEnum == 1 && (
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">
                                Propreitorship PAN
                                <span className="required" style={{ fontWeight: 700, marginLeft: 3 }}>*</span>
                              </span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="propreitorshipPAN"
                                  entity_id={entity_id}
                                  entity_type_enum="8"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadedFileNamePropreitor}
                                  attachmentId={attachmentIdPropreitor}
                                  attachmentPath={attachmentPathPropreitor}
                                  disabled={isAdmin || kycStatusKey}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                        )}

                        {(businessTypeEnum == 6 || businessTypeEnum == 7 || businessTypeEnum == 8) && (
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Form 12A Allotment Letter</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="form12A"
                                  entity_id={entity_id}
                                  entity_type_enum="9"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadedFileNameForm12}
                                  attachmentId={attachmentIdNameForm12}
                                  attachmentPath={attachmentPathNameForm12}
                                  disabled={isAdmin || kycStatusKey}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                        )}

                        {(businessTypeEnum == 6 || businessTypeEnum == 7 || businessTypeEnum == 8) && (
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Form 80G Allotment Letter</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="form80G"
                                  entity_id={entity_id}
                                  entity_type_enum="10"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadedFileNameForm80}
                                  attachmentId={attachmentIdNameForm80}
                                  attachmentPath={attachmentPathNameForm80}
                                  disabled={isAdmin || kycStatusKey}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                        )}

                        <div className="row form__form-group">
                          <Row className="col-md-12">
                            <Tooltip title='Required if Authorized Signatory or Platform user is different from the Proprietor'>
                              <div className='col-md-3'>
                                <span className="form-group-label">Authority Letter</span>
                                {businessTypeEnum === 1 ? '' : <span className="required" style={{ fontWeight: 700 }}>*</span>}
                                <div className='d-flex justify-content-start align-items-center'>
                                  <img src={MsWordIcon} style={{width: 24, height: 24, marginRight: 8}}/>
                                  <a href={AuthorityLetterTemplate} target="_blank" download="Letter of Authority for BiofuelCircle" rel="noopener noreferrer">Download Sample</a>
                                </div>
                              </div>
                            </Tooltip>
                            {/* <ul class="col-md-3 fa-ul upload-tooltip">
                              <li class="tooltip_text">
                                <span className="form-group-label">Authority Letter</span>
                                {businessTypeEnum === 1 ? '' : <span className="required" style={{ fontWeight: 700 }}>*</span>}
                                <a href={AuthorityLetterTemplate} className='d-block' target="_blank" rel="noopener noreferrer">Download Sample</a>
                                {isControlTowerUser && (
                                  <span class="tooltip-hover">
                                    <ul class="tooltip_list">
                                      <li>
                                        Required if Authorized Signatory or Platform user is different from the
                                        Proprietor
                                      </li>
                                    </ul>
                                  </span>
                                )}
                              </li>
                            </ul> */}
                            <div className="form-group-field col-md-9">
                              <Field
                                name="authorityLetter"
                                entity_id={entity_id}
                                entity_type_enum="11"
                                front_back_enum="1"
                                component={UploadDocument}
                                fileName={uploadedFileNameAuthority}
                                attachmentId={attachmentIdAuthority}
                                attachmentPath={attachmentPathAuthority}
                                disabled={isAdmin || kycStatusKey}
                                company_affiliate_enum={company_affiliate_enum}
                              />
                            </div>
                          </Row>
                        </div>
                        {
                          type_of_individual_enum == 10 ? (<div className="row form__form-group">
                            <Row className="col-md-12">
                              <ul class="col-md-3 fa-ul upload-tooltip">
                                <li class="tooltip_text">
                                  <span className="form-group-label">NRLM/SRLM Registration Letter</span>
                                  <span className="required" style={{ fontWeight: 700 }}>*</span>
                                </li>
                              </ul>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="nrlm_registration_letter"
                                  entity_id={entity_id}
                                  entity_type_enum="54"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadeNrlmRegistrationLetter}
                                  attachmentId={attachmentIdNrlm}
                                  attachmentPath={attachmentPathNrlm}
                                  disabled={isAdmin || kycStatusKey}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>) : null
                        }

                        {showCancelledCheque && (
                          <div>
                            <div>
                              {' '}
                              <hr />
                            </div>
                            <div className="row form__form-group">
                              <Row className="col-md-12">
                                <span className="form-group-label col-md-3">
                                  Upload Crossed Cancelled Cheque<span class="required">*</span>
                                </span>

                                <div className="form-group-field col-md-9">
                                  <Field
                                    name="crossed_cancelled"
                                    entity_id={entity_id}
                                    entity_type_enum="15"
                                    front_back_enum="1"
                                    component={UploadDocument}
                                    fileName={uploadedFileNameCancelledCrossedCheque}
                                    attachmentId={attachmentIdCancelledCheque}
                                    attachmentPath={attachmentPathCancelledCheque}
                                    disabled={isAdmin || kycStatusKey}
                                    company_affiliate_enum={company_affiliate_enum}
                                  />
                                </div>
                              </Row>
                            </div>
                          </div>
                        )}

                        {type_of_individual_enum == 8 && (
                          <div>
                            <div>
                              {' '}
                              <hr />
                            </div>
                            <div className="row form__form-group">
                              <Row className="col-md-12">
                                <span className="form-group-label col-md-3">NABL/ BIS Accreditation Certificate</span>
                                <div className="form-group-field col-md-9">
                                  <Field
                                    name="nabl_bis_certificate"
                                    entity_id={entity_id}
                                    entity_type_enum="29"
                                    front_back_enum="1"
                                    component={UploadDocument}
                                    fileName={uploadedFileNameNablCertificate}
                                    attachmentId={attachmentIdNablCertificate}
                                    attachmentPath={attachmentPathNablCertificate}
                                    disabled={isAdmin || kycStatusKey}
                                    company_affiliate_enum={company_affiliate_enum}
                                  />
                                </div>
                              </Row>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Collapse>
                  {type_of_individual_enum == 4 && false && (
                    <Collapse title="Upload Finance Documents" className="with-shadow">
                      <Card>
                        <CardBody>
                          <div className="row form__form-group">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                              <span className="form-group-label">Financial Year</span>
                              <span class="required">*</span>
                              <div className="form-group-field">
                                <Field
                                  name="financial_year"
                                  component={FormikMaterialTextField}
                                  disabled={isAdmin}
                                  type="number"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Balance Sheet</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="authorityLetter"
                                  entity_id={entity_id}
                                  entity_type_enum="21"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadFileNameBalanceSheet}
                                  attachmentId={attachmentIdBalanceSheet}
                                  attachmentPath={attachmentPathBalanceSheet}
                                  disabled={isAdmin}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">P and L Statement</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="authorityLetter"
                                  entity_id={entity_id}
                                  entity_type_enum="22"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadFileNamePLStatement}
                                  attachmentId={attachmentIdPLStatement}
                                  attachmentPath={attachmentPathPLStatement}
                                  disabled={isAdmin}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Income Tax Returns (Latest)</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="authorityLetter"
                                  entity_id={entity_id}
                                  entity_type_enum="23"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadFileNameIncomeTaxReturnsLatest}
                                  attachmentId={attachmentIdIncomeTaxReturnsLatest}
                                  attachmentPath={attachmentPathIncomeTaxReturnsLatest}
                                  disabled={isAdmin}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                          <div className="row form__form-group">
                            <Row className="col-md-12">
                              <span className="form-group-label col-md-3">Income Tax Returns (Previous Year)</span>
                              <div className="form-group-field col-md-9">
                                <Field
                                  name="authorityLetter"
                                  entity_id={entity_id}
                                  entity_type_enum="24"
                                  front_back_enum="1"
                                  component={UploadDocument}
                                  fileName={uploadFileNameIncomeTaxReturnsPreviousYear}
                                  attachmentId={attachmentIdIncomeTaxReturnsPreviousYear}
                                  attachmentPath={attachmentPathIncomeTaxReturnsPreviousYear}
                                  disabled={isAdmin}
                                  company_affiliate_enum={company_affiliate_enum}
                                />
                              </div>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  )}
                </CardBody>
              </Card>
              <div className="d-flex justify-content-center">
                <MUIToggleControl disabled={['Approved', 'Pending'].includes(kyc_status_enum_code) && !globalParticipationData?.length} label={<>Agree To <a target="_blank" href="https://my.biofuelcircle.com/Participation_Agreement.htm">Global Participation Agreement</a></>} value={isGpa} onChange={(e, checked, name) => setIsGpa(checked)} />
              </div>
              <div className="btn-wrapper justify-content-space-between align-items-center">
                <div className="">
                  <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                    Prev
                  </NavLink>
                </div>

                {isSHG && isGpa ? (
                  <>
                    {((!isAdmin && kycBusiness?.msme_classification_enum_code === 'None') || !kycBusiness?.msme_classification_enum_code) && (
                      <div className="">
                        {['Rejected', 'Not Submitted'].includes(kyc_status_enum_code) && (
                          <FormWithBtn
                            message={
                              'Are you sure you want to submit KYC for approval? You will not be able to make any changes'
                            }
                            btnText={'Submit For Approval'}
                            handleSubmit={handleSubmitForApproval}
                            disabled={_.isEmpty(bankDetails) || !showApproved ? true : showCancelledCheque ? (!addressProof || !uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack || !uploadeNrlmRegistrationLetter || !authLetter || !uploadedFileNameCancelledCrossedCheque)
                              : (!addressProof || !uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack || !uploadeNrlmRegistrationLetter || !authLetter)}
                          />
                        )}
                      </div>
                    )}
                    {!isAdmin && kycBusiness?.msme_classification_enum_code && kycBusiness?.msme_classification_enum_code !== 'None' && (
                      <div className="">
                        {['Rejected', 'Not Submitted'].includes(kyc_status_enum_code) && (
                          <FormWithBtn
                            message={
                              'Are you sure you want to submit KYC for approval? You will not be able to make any changes'
                            }
                            btnText={'Submit For Approval'}
                            handleSubmit={handleSubmitForApproval}
                            disabled={_.isEmpty(bankDetails) || !showApproved ? true : showCancelledCheque ? (!uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack || !msmeDocs?.[0]?.attachment_file_name || !showMSMEDocument || !uploadeNrlmRegistrationLetter || !authLetter || !uploadedFileNameCancelledCrossedCheque) :
                              (!uploadedFileNameFront || dropValues !== 17 && !uploadedFileNameBack || !msmeDocs?.[0]?.attachment_file_name || !showMSMEDocument || !authLetter || !uploadeNrlmRegistrationLetter)}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : isGpa ? (
                  <>
                    {((!isAdmin && kycBusiness?.msme_classification_enum_code === 'None') || !kycBusiness?.msme_classification_enum_code) && (
                      <div className="">
                        {['Rejected', 'Not Submitted'].includes(kyc_status_enum_code) && (
                          <FormWithBtn
                            message={
                              'Are you sure you want to submit KYC for approval? You will not be able to make any changes'
                            }
                            btnText={'Submit For Approval'}
                            handleSubmit={handleSubmitForApproval}
                            disabled={_.isEmpty(bankDetails) || !showApproved ? true : businessTypeEnum === 1 ? !mandatoryDocumentsForProprietor : showCancelledCheque ? (!addressProof || !uploadedFileNameFront || !authLetter || !uploadedFileNameCancelledCrossedCheque || dropValues !== 17 && (dropValues === 55 && (businessTypeEnum === 4 || businessType === 2) ? '' : !uploadedFileNameBack)) : (!addressProof || !uploadedFileNameFront || !authLetter || dropValues !== 17 && (dropValues === 55 ? '' : !uploadedFileNameBack))}
                          />
                        )}
                      </div>
                    )}
                    {!isAdmin && kycBusiness?.msme_classification_enum_code && kycBusiness?.msme_classification_enum_code !== 'None' && (
                      <div className="">
                        {['Rejected', 'Not Submitted'].includes(kyc_status_enum_code) && (
                          <FormWithBtn
                            message={
                              'Are you sure you want to submit KYC for approval? You will not be able to make any changes'
                            }
                            btnText={'Submit For Approval'}
                            handleSubmit={handleSubmitForApproval}
                            disabled={_.isEmpty(bankDetails) || !showApproved ? true : businessTypeEnum === 1 ? !mandatoryDocumentsForProprietor || !msmeDocs?.[0]?.attachment_file_name : showCancelledCheque ? (!uploadedFileNameFront || dropValues !== 17 && (dropValues === 55 && (businessTypeEnum === 4 || businessType === 2) ? '' : !uploadedFileNameBack) || !msmeDocs?.[0]?.attachment_file_name || !showMSMEDocument || !authLetter || !uploadedFileNameCancelledCrossedCheque) : (!uploadedFileNameFront || dropValues !== 17 && (dropValues === 55 ? '' : !uploadedFileNameBack) || !msmeDocs?.[0]?.attachment_file_name || !showMSMEDocument || !authLetter)}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : ''}

                <div className="">
                  {type_of_individual_enum !== 8 &&
                    type_of_individual_enum !== TYPE_OF_INDIVIDUAL_ENUM.NBFC_BANKING_INSTITUTE && (
                      <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} className="link">
                        Next
                      </NavLink>
                    )}
                </div>
              </div>

              <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle className="pb-0" id="alert-dialog-title">
                  {'Want to change document type?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please delete the uploaded document/s for front page and back page to change address proof type.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsOpen(false)} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default uploadForm;
