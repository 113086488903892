import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import * as helpers from 'services/helpers';

const { baseUrl } = config;

const transformToQueryString = (params = {}) => {
  return helpers.getQueryParams(params);
};

export const getAllCommodityDetails = async (params, commodityDetailsTOFetch: string = 'FetchAllCommodityType') => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DataSetup/${commodityDetailsTOFetch}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllCommodityType');
    throw errors;
  }
};

export const addUpdateCommodities = async (params, commoditiesToUpdate: string) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DataSetup/${commoditiesToUpdate}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'addUpdateCommodities');
    throw errors;
  }
};

export const deleteCommodities = async (params, commoditiesToDelete: string) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/DataSetup/${commoditiesToDelete}`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'deleteCommodities');
    throw errors;
  }
};

export const getPlatformCharges = async (params, PlatformCharge: string) => {
  try {
    const url = `${baseUrl}/BioFuelCommonUtilities/api/Configuration/${PlatformCharge}`;
    const res = await utils.fetch(url, {
      method: PlatformCharge.startsWith('Delete') ? 'PUT' : 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-affiliates-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'getPlatformCharges');
    throw errors;
  }
};

export const fetchGenerateReport = async () => {
  try {
    const url = `${baseUrl}/BiofuelReportGeneration/api/ReportGeneration/FetchInbuiltReportInfo`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-FetchInbuiltReportInfo---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchInbuiltReportInfo');
    throw errors;
  }
};

export const fetchDownloadedReport = async (params: {
  offset: number;
  page_size: number;
  DownloadUpload_File_Enum?: number;
}) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelReportGeneration/api/ReportGeneration/FetchExtractedDocuments?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-FetchExtractedDocuments---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchExtractedDocuments');
    throw errors;
  }
};
export const generateReportDataExtract = async (params) => {
  try {
    const url = `${baseUrl}/BiofuelReportGeneration/api/ReportGeneration/GenerateReport`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    let resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    console.log('api-GenerateReport-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GenerateReport');
    throw errors;
  }
};
