import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from '../handlers';
import CommodityTypeFormSelect from 'pages/commodity/components/CommodityTypeFormSelect';
import FormikMaterialTextField from './FormikMaterialTextField';
import UnitSelect from './unitSelect';
import { COMPANY_AFFILIATE_ENUM } from 'constants/enums';
import RupeeIcon from 'mdi-react/RupeeIcon';
import ReferenceDataSelect from '../../components/ReferenceDataSelect';

const getInitialValues = (details, entity_id, company_affiliate_enum) => {
  let commodity_type_code = '';
  let commodity_form_code = '';
  if (!_.isEmpty(details)) {
    commodity_type_code = _.get(details, 'commodity_type_code') || 'Biomass';
    commodity_form_code = _.get(details, 'commodity_form_code') || _.get(details, 'commodity_form_name') || '';
  }
  let companyId = 0;
  let affiliateId = 0;
  if (company_affiliate_enum == COMPANY_AFFILIATE_ENUM.COMPANY) {
    companyId = entity_id;
  } else {
    affiliateId = entity_id;
  }
  let formFields = {
    bf_setup_installed_capacity_id: _.get(details, 'bf_setup_installed_capacity_id') || 0,
    commodity_type_id: _.get(details, 'commodity_type_id') || 0,
    commodity_form_id: _.get(details, 'commodity_form_id') || 0,
    quantity: _.get(details, 'quantity') || '',
    unit: _.get(details, 'unit') || 0,
    periodicity_enum: _.get(details, 'periodicity_enum') || 0,
    company_id: _.get(details, 'company_id') || companyId || 0,
    company_affiliate_id: _.get(details, 'company_affiliate_id') || affiliateId || 0,
    person_id: _.get(details, 'person_id') || 0,
    operation_enum: 1,

    commodity_type_code: commodity_type_code,
    commodity_form_code: commodity_form_code,
  };
  return formFields;
};

const AddInstalledCapacityForm = (props) => {
  const dispatch = useDispatch();
  const { bf_setup_installed_capacity_id, entity_id, company_affiliate_enum } = props;
  const financeInstalledCapacity = useSelector((state) => state.financeInstalledCapacity);
  const details = _.get(financeInstalledCapacity, `entities.${bf_setup_installed_capacity_id}`) || {};
  const history = useHistory();

  const handleSubmit = async (values, formikBag) => {
    let type = 'create';
    if (bf_setup_installed_capacity_id !== 0) {
      values['operation_enum'] = 3;
      type = 'update';
    }
    const success = await handlers.handleCreateInstalledCapacity(values, formikBag, type);
    if (success) {
      props.setTimeStamp(Date.now());
      history.push(props.backLink);
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(
          bf_setup_installed_capacity_id ? details : {},
          entity_id,
          company_affiliate_enum,
        )}
        onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values } = formikBag;
          return (
            <div>
              <div>
                <Form className="formik-form">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Commodity Form</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="commodity_form_id"
                              component={CommodityTypeFormSelect}
                              placeholder="Select commodity form"
                              entity="commodityTypeForm"
                              initialDisplayText={
                                `${details.commodity_type_code} - ${details.commodity_form_code}` || ' - '
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Quantity</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field name="quantity" component={FormikMaterialTextField} type="number" />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Unit</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="unit"
                              component={UnitSelect}
                              placeholder="Select Unit"
                              initialDisplayText={details.unit || ''}
                              cb={(newVal) => {
                                const value = _.get(newVal, 'value') || 0;
                                const label = _.get(newVal, 'label') || 0;
                                formikBag.setFieldValue('unit', value);
                                formikBag.setFieldValue('unit_code', label);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Periodicity</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name="periodicity_enum"
                              component={ReferenceDataSelect}
                              placeholder="Select periodicity"
                              entity="periodicityEnum"
                              installedCapacityPeriodicityEnum={1}
                              // initialDisplayText={commodityData.periodicity_enum_code || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      style={{ textTransform: 'capitalize' }}
                      disabled={formikBag.isSubmitting}
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {formikBag.isSubmitting && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      )}
                      {bf_setup_installed_capacity_id !== 0 ? 'Update' : 'Add'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default AddInstalledCapacityForm;
