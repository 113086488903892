import _ from 'lodash';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
import { actions as kycCommentsActions } from 'redux/kycComments';
import { Formik, Field, Form, FieldArray, getIn } from 'formik';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import * as handlers from './handlers';
import ReferenceDataSelect from 'containers/Account/Profile/components/ReferenceDataSelect';
import VendorSelect from './vendorSelect';
import { getDecryptedUrl } from 'lib/utils';

const getInitialValues = (data, loc) => {
  let formFields = {
    platform_charge_at_enum_code: _.get(data, 'platform_charge_at_enum_code'),
    platform_charge_at_enum: _.get(data, 'platform_charge_at_enum'),
    buyer_company_code: _.get(data, 'buyer_company_code'),
    // ...(loc?.includes('/control-tower/indent-deals') ? { buyer_company_code: _.get(data, 'buyer_company_code') } : { seller_company_code: _.get(data, 'seller_company_code') }),
    subscription_plan_code: _.get(data, 'subscription_plan_code'),
    platform_master_id: _.get(data, 'platform_master_id') || 0,
    // buyer_company_id: _.get(data, 'buyer_company_id') || _.get(data, 'seller_company_id'),
    ...(loc?.includes('smart-buyer-configuration') ? { buyer_company_id: _.get(data, 'buyer_company_id') } : { seller_company_id: _.get(data, 'seller_company_id') }),
    platform_type_enum: _.get(data, 'platform_type_enum'),
  };

  return formFields;
};

const configEditForm = ({ data, close, setTimeStamp }) => {
  const loc = getDecryptedUrl(window.location.href);
  const handleSubmit = async (values, formikBag) => {
    let newValues = {
      ...values,
      operation_Enum: values?.platform_master_id ? 3 : 1,
      ...(loc?.includes('smart-buyer-configuration') ? { Is_Indent_Enabled: '1' } : { Is_Seller_Indent_Enabled: '1' }),
    }
    const success = await handlers.SaveandUpdatePlateformCharge(newValues, formikBag, loc)
    if (success) {
      close()
      setTimeStamp(Date.now())
    }
  }
  return (
    <React.Fragment>
      <Formik initialValues={getInitialValues(data, loc)} onSubmit={handleSubmit}>
        {(formikBag) => {
          const { values, setFieldValue, errors, touched } = formikBag;

          return (
            <Form className="formik-form">
              <div className="row form__form-group">
                <div className="col">
                  <span className="form-group-label">Platform charge at</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                    <Field
                      name={`platform_charge_at_enum`}
                      component={ReferenceDataSelect}
                      isSmartBuyer={true}
                      entity="PlatformChargeAtEnum"
                      placeholder={
                        ' Plateform charge at'
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row form__form-group">
                <div className="col">
                  <span className="form-group-label">{loc?.includes('smart-buyer-configuration') ? 'Buyer Company' : 'Seller Company'}</span>
                  <span class="required">*</span>
                  <div className="form-group-field">
                    <Field
                      name={loc?.includes('smart-buyer-configuration') ? `buyer_company_id` : `seller_company_id`}
                      // component={FormikMaterialTextField}
                      component={VendorSelect}
                      initialDisplayText={values.buyer_company_code}
                      cb={(newVal) => {
                        if(loc?.includes('smart-buyer-configuration')) {
                          formikBag.setFieldValue('buyer_company_id', newVal.value.vendor_id);
                          formikBag.setFieldValue('buyer_company_code', newVal.value.vendor_code);
                        } else {
                          formikBag.setFieldValue('seller_company_id', newVal.value.vendor_id);
                          formikBag.setFieldValue('seller_company_code', newVal.value.vendor_code);
                        }
                      }}
                      disabled={values?.platform_master_id ? true :false}
                      placeholder={
                        loc?.includes('smart-buyer-configuration') ? 'Buyer Company' : 'Seller Company'
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="bfc-center">
                <Button
                  color="primary"
                  type="submit"
                  size="sm"
                  // disabled={ifscVerified ? false : true}
                  onClick={() => {
                    // formikBag.values.eKyc = '';
                    // handleSave(formikBag.values, formikBag);
                  }}>
                  {data?.platform_master_id ? 'Update' :'Create'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default configEditForm;
