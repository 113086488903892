import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import 'assets/scss/TabNav.scss';

function TabNav(props) {
  const { links, className = 'bfc-tabs tabs-primary', setEmptyData, setRowsPerPage, filters, setFilters } = props;
  const history = useHistory();
  return (
    <div className={`${className}`}>
      <ul>
        {links &&
          links.map((link, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={link.to}
                  onClick={() => {
                    setEmptyData();
                    let carryFilters = {}
                    if (filters.hasOwnProperty('commodity_deal_master_id')) {
                      carryFilters = {
                        ...carryFilters,
                        commodity_deal_master_id: filters['commodity_deal_master_id']
                      }
                    }
                    if (filters.hasOwnProperty('transport_delivery_schedule_alloted_id')) {
                      carryFilters = {
                        ...carryFilters,
                        transport_delivery_schedule_alloted_id: filters['transport_delivery_schedule_alloted_id']
                      }
                    }
                    setFilters({ ...carryFilters });
                    setRowsPerPage(10);
                  }}>
                  {link.title}
                </NavLink>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

TabNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
};

export default TabNav;
