import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

import normalize from 'normalize-object';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] !== _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }

    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let fields = {
      company_Code: '',
      company_Name: '',
      company_Legal_Name: '',
      registration_Id: 1,
      company_Tax_Id: 1,
      company_External_Ref: '',
      company_Type_Enum: 0,
      company_Contact: '',
      company_Phone_Number: '',
      company_Website: '',
      company_Email_Id: '',
      company_Mobile: '',
      created_By: 0,
      nature_Of_Business_Enum: 0,
      company_Affiliate_Enum: 1,
      business_Type_Enum: 0,
      buyer_Rating: 2,
      seller_Rating: 3,
      operation_Enum: 1,
      // temp fix
      company_id: 0,
      business_type_enum_code: '',
      company_affiliate_enum_code: '',
      company_type_enum_code: '',
      nature_of_business_enum_code: '',
      // temp fix
    };

    let bodyParams = {
      ...normalize(fields, 'snake'),
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.id'),
    };

    bodyParams['company_name'] = bodyParams['company_code'];

    return bodyParams;
  },
  update(id, params) {
    let fields = {
      company_Code: '',
      company_Name: '',
      company_Legal_Name: '',
      registration_Id: 1,
      company_Tax_Id: 1,
      company_External_Ref: '',
      company_Type_Enum: 0,
      company_Contact: '',
      company_Phone_Number: '',
      company_Website: '',
      company_Email_Id: '',
      company_Mobile: '',
      created_By: 0,
      nature_Of_Business_Enum: 0,
      company_Affiliate_Enum: 1,
      business_Type_Enum: 0,
      buyer_Rating: 2,
      seller_Rating: 3,
      operation_Enum: 3,
      business_type_enum_code: '',
      company_affiliate_enum_code: '',
      company_type_enum_code: '',
      nature_of_business_enum_code: '',
    };

    let bodyParams = {
      ...normalize(fields, 'snake'),
      ...params,
      company_id: id,
      person_id: params['person_id'] || _.get(window, 'user.id'),
    };

    bodyParams['company_name'] = bodyParams['company_code'];
    delete bodyParams['locations'];
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let compAffiliates = _.get(resData, 'company_affiliate_response_model') || [];
    compAffiliates = compAffiliates.map((item) => {
      let aff = {
        ...item,
        locations: _.keyBy(_.get(item, 'business_location_request_model') || [], 'company_address_id'),
      };

      delete aff['business_location_request_model'];
      return aff;
    });

    let reduxData = {
      company: {
        ...resData,
        locations: _.keyBy(_.get(resData, 'company_address_list') || [], 'company_address_id'),
      },
      affiliates: _.keyBy(compAffiliates, 'company_affiliate_id'),
    };

    delete reduxData['company']['company_address_list'];
    delete reduxData['company']['company_affiliate_response_model'];

    return reduxData;
  },
  fetchDetails(resData) {
    return resData;
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
