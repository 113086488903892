import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

const FormikMaterialTextField = (props) => {

  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    cb,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const [tokenOAuth, setTokenOAuth] = useState('');

  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');
  // setTokenOAuth(token);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
    script.async = true;
    script.onload = () => {
      // Place search plugin

      let optional_config = {
        location: [28.61, 77.23],
        bridge: false,
        tokenizeAddress: true,
        geolocation: false,
        pod: 'DIST',
      };

      window.MapmyIndia.search(document.getElementById('auto1'), optional_config, function (data1) {
        //pass data to callback function
        cb(data1);
      });
    };
    document.head.appendChild(script);
    // remove script after adding
    setTimeout(() => {
      document.head.removeChild(script);
    }, 100);
  }, [token]);

  return (
    <div>
      <input
        type="text"
        id="auto1"
        name="auto1"
        className="search-outer form-control as-input"
        placeholder="Search location"
        required=""
        spellCheck="false"
        value={inputValue}
        disabled={disabled}
        onChange={handleInputChange}></input>

      <span style={{ color: 'var(--unnamed-color-f44336)', fontSize: 'var(--fs-base__three)', fontFamily: 'var(--unnamed-font-segoeUI)', }}>{errors.location_code}</span>
    </div>
  );
};

export default FormikMaterialTextField;
