import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'reactstrap';
import ConfirmModal from './ConfirmModal';

const FormWithBtn = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Formik initialValues={props.initialValues || {}} onSubmit={props.handleSubmit}>
      {(formikBag) => {
        return (
          <Form className="form-container">
            <div className="bfc-center">
              <Button
                color="primary"
                type="button"
                disabled={formikBag.isSubmitting || props.disabled}
                // onClick={formikBag.handleSubmit}
                onClick={() => setIsModalOpen(true)}
                size={props.btnSize || 'sm'}>
                {formikBag.isSubmitting && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
                {props.btnText || 'Submit'}
              </Button>
            </div>
            <ConfirmModal
              isOpen={isModalOpen}
              closeModal={() => setIsModalOpen(false)}
              onConfirm={formikBag.handleSubmit}
              onCancel={() => setIsModalOpen(false)}
              formikBag={formikBag}
              header={true}
              color={'primary'}
              title={'Confirm Action'}
              {...props}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormWithBtn;
