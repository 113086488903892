import React from 'react';
import PropTypes from 'prop-types';
import utils from 'lib/utils';

import './styles.scss';

function PriceField({ expected_price, qty_uom_code,special_buy_sell_type_enum_code,is_gcv_based, landedCostDeals }) {
  let is_decimal = expected_price % 1 != 0
  const isExpectedPriceNumber = typeof expected_price === 'number'
  if(!isExpectedPriceNumber){
    expected_price = Number(expected_price)
  }
  return (
    <>
      <div className="bfc-table-list-components d-flex justify-content-end">
        <div className="price-field mt-5" style={{ textAlign : 'end', lineHeight: '20px' }}>
              {special_buy_sell_type_enum_code == 'Rfq' ? 'RFQ' : is_gcv_based && is_decimal ? expected_price.toFixed(3) : utils.formatQtyPricePerUOM(expected_price)}
          {/* {special_buy_sell_type_enum_code == 'Rfq' ? 'RFQ' : is_gcv_based && is_decimal ? expected_price : expected_price} */}
          {/* {special_buy_sell_type_enum_code == 'Rfq' ? 'RFQ' : is_gcv_based && is_decimal ? expected_price : utils.formatQtyPricePerUOM(expected_price)} */}
          <br/> <span className="price-enum">{'₹' + '/' + qty_uom_code}</span>
        </div>
      </div>
    </>
  );
}

PriceField.propTypes = {
  expected_price: PropTypes.number.isRequired,
  qty_uom_code: PropTypes.string.isRequired,
};

export default PriceField;
