import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Space, Button, Input } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';

function TextSearchFilter({ dataIndex, data, onFilterChange, handleReset, value, name, showPalceHolder }) {
  const { confirm, clearFilters, setSelectedKeys, selectedKeys } = data;

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {}}
        placeholder={!showPalceHolder ? "" : `Search ${name}`}
        value={selectedKeys || ''}
        onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : '')}
        onPressEnter={() => onFilterChange(selectedKeys, dataIndex, confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => onFilterChange(selectedKeys, dataIndex, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}>
          Search
        </Button>
        <Button onClick={(e) => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}

export default TextSearchFilter;
