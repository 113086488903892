import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
      invoking_person_id: params.person_id ? params.person_id : _.get(window, 'user.userid', 0),
      transport_periodicity_enum: 2,
    };
    return bodyParams;
  },
  fetchDetails(id) {
    return id;
  },
  // create(params = {}) {
  //   let bodyParams = {
  //     ...params,
  //     person_id: params['person_id'] || _.get(window, 'user.userid', 0),
  //     operation_Enum: 1,
  //     company_id: _.get(window, 'user.profile.person.company_id', 0),
  //     affiliate_code: params['affiliate_name'] || '',
  //   };
  //   return bodyParams;
  // },
  update(id, params) {
    let bodyParams = {
      commodity_deal_master_id: parseInt(id),
      invoking_person_id: _.get(window, 'user.userid', 0),
      is_saved_as_draft: true,
      bf_transport_delivery_schedule: [],
    };

    bodyParams['bf_transport_delivery_schedule'] = (params.schedule || []).map((item) => {
      return {
        transport_delivery_schedule_id: item.transport_delivery_schedule_id,
        proposed_delivery_qty: item.total_quantity,
      };
    });

    return bodyParams;
  },

  confirm(id, params) {
    let bodyParams = {
      commodity_deal_master_id: parseInt(id),
      invoking_person_id: _.get(window, 'user.userid', 0),
      is_saved_as_draft: false,
      bf_transport_delivery_schedule: [],
    };

    bodyParams['bf_transport_delivery_schedule'] = (params.schedule || []).map((item) => {
      return {
        transport_delivery_schedule_id: item.transport_delivery_schedule_id,
        proposed_delivery_qty: item.total_quantity,
      };
    });

    return bodyParams;
  },
  // destroy(id) {
  //   return id;
  // },
};

export const resTransform = {
  fetchList(resData) {
    let scheduleData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(scheduleData, 'delivery_start_date_string');
  },
  fetchDetails(resData) {
    return {
      ...resData,
      // total_quantity: _.get(resData, 'total_quantity') || 0,
      // delivery_status: _.get(resData, 'delivery_status') || '',
      // month_string: _.get(resData, 'month_string') || '',
      // delivery_start_date: _.get(resData, 'delivery_start_date') || '',
      // delivery_end_date: _.get(resData, 'delivery_end_date') || '',
      // transport_delivery_schedule_id: _.get(resData, 'transport_delivery_schedule_id') || 0,
      // is_editable: _.get(resData, 'is_editable') || false,
      // truck_size: _.get(resData, 'truck_size') || '',
      // qty_uom_code: _.get(resData, 'qty_uom_code') || '',
      // edit_till_date: _.get(resData, 'edit_till_date') || '',
      id: _.get(resData, 'delivery_start_date_string') || '',
      // delivery_end_date_string: _.get(resData, 'delivery_end_date_string') || '',
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
