import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import api from 'lib/api';
import TransportRequirementService from 'services/transportRequirement';

const initialState = {
  data: {},
  order: [],
  busy: false,
  updatedAt: null,
};

const slice = createSlice({
  name: 'transportRequirements',
  initialState,
  reducers: {
    setBusy(state, action) {
      state.busy = action.payload;
    },

    add(state, action) {
      let data = {};
      let order = [];

      for (const transportRequirement of action.payload) {
        const key = transportRequirement.transport_req_offer_id;
        data[key] = transportRequirement;
        let filteredData = _.pick(data[key], ['status', 'delivery_date_range']);

        let searchString = Object.values(filteredData).join('==');
        data[key]['searchStr'] = searchString.toLowerCase();

        order.push(key);
      }

      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();
    },

    update(state, action) {
      const { id, data, detailsUpdatedAt } = action.payload;
      state.data[id] = {
        // isDetailsLoaded : true,
        ...state.data[id],
        ...data,
        detailsUpdatedAt: detailsUpdatedAt,
      };
      // state['updatedAt'] = utils.uid();
    },

    // delete(state, action) {
    //   // debugger;
    //   const post_id = action.payload;
    //   let order = _.get(state, 'order', []) || [];
    //   // const posts = state.data.filter(p => p.post_id !== post_id)
    //   delete state['data'][post_id];
    //   let filteredOrder = order.filter((id) => id !== post_id);
    //   state['order'] = filteredOrder;
    // },
  },
});

const getTransportRequirementDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await TransportRequirementService.FetchTransportRequirementDetailsAgainstId(id);
      dispatch(slice.actions.add(response.data));
    } catch (errorResponse) {
      // debugger;
      console.log('getTransportRequirementDetails-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const getAllTransportRequirements = (params) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      const response = await TransportRequirementService.TransportRequirementFilteredData(params);
      // let response = await TransportRequirementService.FetchAllTransportRequirementWithinCircle(100);
      dispatch(slice.actions.add(response.data));
    } catch (errorResponse) {
      console.log('getAllTransportRequirements-->Err', errorResponse);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const getUserRequirements = (filters, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBusy(true));
      let resData = await api.getUserRequirements(filters, pageSize);
      dispatch(
        slice.actions.add({
          isMyListLoaded: true,
          updatedAt: Date.now(),
          resData,
        }),
      );
    } catch (err) {
      console.log('getAllRequirements-->Err', err);
    } finally {
      dispatch(slice.actions.setBusy(false));
    }
  };
};

const actions = {
  ...slice.actions,
  getAllTransportRequirements,
  getTransportRequirementDetails,
  getUserRequirements,
  // getAllFeed,
  // deletePost,
};

const selectors = {
  // selectLoader,
};

export { actions, selectors };

export default slice.reducer;
