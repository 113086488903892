import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import utils from 'lib/utils';
import { muiComponentService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'MUIDropdownData',
  initialState: adapter.getInitialState({
    busy: false,
    data: {},
    loading: {},
  }),
  reducers: {
    setData: (state, { payload }) => {
      const { entity, resData } = payload;
      state.data[entity] = resData;
    },
    // setBusy: (state, { payload }) => ({ ...state, busy: payload }),
    setBusy: (state, { payload }) => {
      const { entity, isBusy } = payload;
      state.loading[entity] = isBusy;
    },
  },
});

const getDataFromApi = (params, entity) => async (dispatch) => {
  dispatch(slice.actions.setBusy({ entity, isBusy: true }));
  try {
    const resData = await muiComponentService.getDataFromApi(params);
    dispatch(slice.actions.setData({ entity, resData }));
    dispatch(slice.actions.setBusy({ entity, isBusy: false }));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy({ entity, isBusy: false }));
};

const actions = {
  ...slice.actions,
  getDataFromApi,
};

export { actions };

export default slice.reducer;
