import React from 'react';
var globalThis = require('globalthis')();
import App from 'containers/App/App';
import { ErrorBoundary } from './AppErrorBoundary';

export default App;

/*
function ErrorFallback({ error, resetErrorBoundary }) {
  // @TODO: integrate crash analytics for web or ga crash report
  return (
    <div role="alert" className="root-error-boundary">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default () => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}>
    <App />
  </ErrorBoundary>
);
*/
