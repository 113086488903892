// import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

// import _ from 'lodash';
// import api from 'lib/api';
// import utils from 'lib/utils';
// import storage from 'lib/storage';
// import notifications from 'lib/notifications';
// import { indentEnabledCompanyService } from 'services';

// // const companiesAdapter = createEntityAdapter({
// //   // Assume IDs are stored in a field other than `book.id`
// //   selectId: (company) => company.company_id,
// //   // Keep the "all IDs" array sorted based on book titles
// //   sortComparer: (a, b) => a.company_name.localeCompare(b.company_name),
// // });

// const slice = createSlice({
//   name: 'indentEnabledCompany',
//   // initialState: booksAdapter.getInitialState(),
//   initialState: { ids: [], entities: {}, dirty: true },
//   reducers: {
//     setBusy(state, action) {
//       state.busy = action.payload;
//     },
//     add(state, action) {
//       const { resData } = action.payload;

//       //TODO : review - temp assuming flush true
//       // const flush = action.payload.flush || true;
//       const flush = true;

//       let existingData = state.entities || {};
//       let existingIds = state.ids || [];

//       let entities = {
//         ...existingData,
//       };

//       let ids = existingIds.concat([]);

//       if (flush) {
//         entities = {};
//         ids = [];
//       }

//       // debugger;

//       _.each(resData, (item, id) => {
//         if (parseInt(id)) {
//           entities[id] = {
//             // isListLoaded: true,
//             ...(entities[id] || {}),
//             ...item,
//           };
//           if (!ids.includes(id)) {
//             ids.push(id);
//           }
//         }
//       });

//       state['entities'] = entities;
//       state['ids'] = ids;
//       state['dirty'] = false;
//       state['updatedAt'] = Date.now();

//       // state.fetching = false;
//     },

//     update(state, action) {
//       if (action.payload.flush === true) {
//         state = action.payload;
//         return state;
//       }

//       state = {
//         ...state,
//         ...action.payload,
//         updatedAt: Date.now(),
//       };
//       // state['updatedAt'] = utils.uid();
//     },
//     create(state, action) {
//       // @TODO: pass person_id filed value to post & handle response
//       api.createSocialPost(action.payload);
//     },
//     delete(state, action) {
//       // debugger;
//       const post_id = action.payload;
//       let order = _.get(state, 'order', []) || [];
//       // const posts = state.data.filter(p => p.post_id !== post_id)
//       delete state['data'][post_id];
//       let filteredOrder = order.filter((id) => id !== post_id);
//       state['order'] = filteredOrder;
//     },
//     setDirty(state, action) {
//       state = { ids: [], entities: {}, dirty: true };
//       return state;
//     },
//   },
// });

// const fetchList = (params) => async (dispatch) => {
//   dispatch(slice.actions.setBusy(true));
//   try {
//     const resData = await indentEnabledCompanyService.fetchList(params);

//     //TODO : factor later;
//     let companyId = _.get(resData, `company.company_id`) || 0;
//     let company = resData.company || {};
//     dispatch(
//       slice.actions.add({
//         resData: {
//           [companyId]: company,
//         },
//       }),
//     );
//   } catch (err) {
//     dispatch(
//       slice.actions.add({
//         resData: {},
//       }),
//     );
//     utils.displayErrors(err);
//   }
//   dispatch(slice.actions.update({}));
//   dispatch(slice.actions.setBusy(false));
// };

// const actions = {
//   ...slice.actions,
//   fetchList,
// };

// const selectors = {
//   // selectLoader,
// };

// export { actions, selectors };

// export default slice.reducer;

import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { indentEnabledCompanyService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'indentEnabledCompanies',
  initialState: adapter.getInitialState({
    busy: false,
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await indentEnabledCompanyService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchAllSellerIndentEnabledCompanies = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await indentEnabledCompanyService.fetchAllSellerIndentEnabledCompanies(params);
    dispatch(slice.actions.setAll(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchAllSellerIndentEnabledCompanies,
};

const selectors = {
  ...adapter.getSelectors((state) => state.indentEnabledCompanies),
};

export { actions, selectors };

export default slice.reducer;
