import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, Modal, ModalHeader, ModalBody, Button, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from './FormikMaterialTextField';
import FormikOTPField from './FormikOTPField';
import OTPTimer from './OTPTimer';
import * as handlers from './../handlers';
import notifications from 'lib/notifications';
const ModalLoginForm = (props) => {
  const { onSuccessCb, ctx, closeModal, id } = props;
  const [isOTPSent, setIsOTPSent] = useState(false);
  const isEmailCtx = ctx === 'email';

  const handleFormSubmit = async (values, formikBag) => {
    // console.log('insidehandleFormSubmit-->', values, formikBag);
    try {
      if (!isOTPSent) {
        if(!isEmailCtx){
          values['is_requested_number_change']=  true;
          values['person_id'] = id || _.get(window, 'user.userid', 0);
        }
        let res = await handlers.handleRequestOTP(values, formikBag);
        // console.log('handleFormSubmit->isOTPSent--->res', res?.log_id);
        if (res) {
          formikBag.setFieldValue('log_id', res?.log_id);
          setIsOTPSent(true);
        }
      } else {
        values['person_id'] = id
        let res = await handlers.handleVerifyOTP(values, formikBag);
        // console.log('isOTPSent--->res', res);
        if (res) {
          notifications.show({
            type: 'success',
            message: 'Verification Successfull',
          });
          onSuccessCb(values);
          closeModal();
        }
      }
    } catch (err) {
      console.log('ModalLoginForm--->handleFormSubmit-err', err);
    }
  };

  return (
    <Modal
      toggle={closeModal}
      className={`modal-dialog--primary modal-dialog--header`}
      modalClassName={`ltr-support account-profile-wrapper verification-modal`}
      isOpen={!!ctx}>
      <ModalHeader className="d-none">{'Verify Email'}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            user_name: '',
            // otp: '',
            // log_id: null,
            // login_through_enum: 2,
          }}
          onSubmit={handleFormSubmit}>
          {(formikBag) => {
            // console.log('\n------------ModalLoginForm-------------');
            // console.log('formik-values--->', formikBag.values);
            // console.log('formik---------->', formikBag);

            return (
              <Form className="form-container">
                <div className="container">
                  {!isOTPSent ? (
                    <div className="row">
                      <div className="col-12">
                        <span className="form-group-label invisible">First Name</span>
                        <div className="form-group-field">
                          <Field
                            name="user_name"
                            component={FormikMaterialTextField}
                            placeholder={`Enter new ${isEmailCtx ? `email address` : `phone number`}`}
                          />
                        </div>
                        <p className="helper-text">
                          A six digit OTP will be sent via {`${isEmailCtx ? `Email` : `SMS`}`} for verification
                          <br />
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <span className="form-group-label invisible">OTP</span>
                        <div className="form-group-field">
                          <Field name="otp" component={FormikOTPField} />
                        </div>
                        <p className="helper-text">
                          Enter 6 digit OTP sent via {`${isEmailCtx ? `Email` : `SMS`}`}
                          <br />
                        </p>
                        <div className="otp-timer-box">
                          <OTPTimer
                            params={{ ...formikBag.values }}
                            initialSeconds={30}
                            handleResendOTP={handlers.handleResendOTP}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12">
                      <div className="form-btns">
                        <ButtonToolbar style={{ justifyContent: 'center' }}>
                          <Button className="modal_cancel" onClick={closeModal}>
                            Cancel
                          </Button>
                          <Button className="modal_ok" outline={false} color="primary" type="submit">
                            {formikBag.isSubmitting && (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            )}{' '}
                            {!isOTPSent ? `Request OTP` : `Validate OTP`}
                          </Button>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalLoginForm;
