import _ from 'lodash';
import * as helpers from 'services/helpers';

export const reqTransform = {
  fetchDiscoveryBuyersSellers(params = {}) {
    const reqParams = {
      ...params,
    };
    return reqParams;
  },
  connectProfile(resData) {
    let data = {
      ...resData,
    };

    return data;
  },
};

export const resTransform = {
  fetchDiscoveryBuyersSellers(resData) {
    let data = resData.map((details) => {
      return resTransform.fetchDiscoveryBuyersSellersDetails(details);
    });
    return _.keyBy(data, 'sort_by');
  },
  fetchDiscoveryBuyersSellersDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'sort_by') || 0,
    };
    return res;
  },
  connectProfile(resData) {
    let data = {
      ...resData,
    };

    return data;
  },
};
