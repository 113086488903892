import React, { useEffect, useRef } from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';

const DataPaginationTable = ({ tableKey = Math.random(), heads, rows, filtersVisible = true, ...rest }) => {
  const gridRef = useRef();

  const EmptyRowsView = () => {
    const message = 'No records found';
    return (
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <p>{message}</p>
      </div>
    );
  };

  useEffect(() => {
    if (filtersVisible) {
      setTimeout(() => gridRef.current?.onToggleFilter(), 1000);
    }
  }, []);

  return (
    <div className="table">
      <ReactDataGrid
        ref={gridRef}
        columns={heads}
        rowGetter={(i) => rows[i]}
        rowsCount={rows.length}
        rowHeight={50}
        minColumnWidth={100}
        emptyRowsView={EmptyRowsView}
        {...rest}
      />
    </div>
  );
};

DataPaginationTable.propTypes = {
  heads: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      editable: PropTypes.bool,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSorting: PropTypes.func.isRequired,
};

export default DataPaginationTable;
