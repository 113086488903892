import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import utils from 'lib/utils';

export default function FormikAOISelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialValue,
    entity,
    disabled,
    teamMemberId,
    ...rest
  } = props;

  const user = useSelector((state) => state.user);
  let acl = utils.getACL(user);
  let adminCheckBox = [];
  let checkboxValues = [];
  const checkboxValues1 = _.get(props, 'form.values.role_ids') || [];
  {
    checkboxValues1.includes(13) ? (checkboxValues = [13]) : (checkboxValues = checkboxValues1);
  }

  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles);
  const [value, setValue] = React.useState([]);
  let loading = false;
  let entityData = roles.entities || {};
  const is_maker = _.get(props, 'form.values.is_maker') || false;
  const is_checker = _.get(props, 'form.values.is_checker') || false;
  const is_platform_partner = _.get(user, 'profile.is_platform_partner') || false;
  // if (!Array.isArray(entityData)) {
  //   entityData = [];
  // }

  if (!entityData.length) {
    loading = true;
  }

  let options = _.map(entityData, (item, index) => {
    adminCheckBox.push(item.value_member);
    return {
      label: 'item.display_member' || '',
      value: {
        id: parseInt(item.value_member),
        ...item,
      },
    };
  });

  if(!is_platform_partner && Object.values(entityData).length) {
    entityData = Object.values(entityData).filter((item) => item.display_member !== 'Facilitator')
  }

  const adminRole = Object.values(entityData).filter((item) => item.display_member === 'Admin')[0]?.value_member || 0;

  const renderCheckbox = () => {
    return _.map(entityData, (item, index) => {
      // console.log('manual invoice values', item);
      if (item.parent_role_id === adminRole || item.display_member === 'Admin') {
        return (
          <ul key={index} className="pl-0 mb-0">
            <li>
              <div className="radiobox-wrapper">
                <label className="radio-color-btn">
                  <FormControlLabel
                    control={
                      <div className="">
                        <Checkbox
                          checked={checkboxValues.includes(item.value_member)}
                          disabled={checkboxValues.includes(13) && item.value_member > 13 ? true : false}
                          name="roleTeam"
                          value={item.value_member}
                          onChange={(evt) => {
                            let newValue = parseInt(evt.target.value);
                            // console.log('FormikAOISelect----newValue111---->', newValue);
                            let currentValues = _.get(props, 'form.values.role_ids') || [];
                            if (newValue == 13 && teamMemberId) {
                              currentValues = [7, ...currentValues];
                            }
                            // console.log('FormikAOISelect----currentValue---->', currentValues);
                            if (!currentValues.includes(newValue)) {
                              if (newValue == 13) {
                                // currentValues = adminCheckBox;
                                // currentValues = currentValues.filter((id) => id != 7);
                                currentValues = teamMemberId ? [7, 13] : [13];
                                // currentValues.push(newValue);
                              } else {
                                currentValues.push(newValue);
                              }
                            } else {
                              if (newValue == 13) {
                                // currentValues = [];
                                currentValues = currentValues.filter((id) => id != newValue);
                              } else {
                                currentValues = currentValues.filter((id) => id != newValue);
                                _.map(entityData, (subItem, index) => {
                                  if (item.value_member !== adminRole && item.value_member === subItem.parent_role_id) {
                                    // console.log('manual invoice values', item, subItem);
                                    currentValues = currentValues.filter((id) => id !== subItem.value_member);
                                  }
                                });

                                if (newValue === 30) {
                                  props.form.setFieldValue('is_maker', false);
                                  props.form.setFieldValue('is_checker', false);
                                }
                              }
                            }

                            // console.log('FormikAOISelect----currentValue---->', currentValues);

                            props.form.setFieldValue('role_ids', currentValues);

                            //  setValue(newValue);
                            // if (props.cb) {
                            //   props.cb(newValue);
                            // }
                          }}
                          // onClick={(v) => formikBag.setFieldValue('role_code', item.valueMember)}
                        />
                      </div>
                    }
                  />
                  {item.display_member}
                  <div className="control-indicator"></div>
                </label>

                {_.map(entityData, (subItem, index) => {
                  if (item.value_member !== adminRole && item.value_member === subItem.parent_role_id) {
                    // console.log('manual invoice values', item, subItem);
                    return (
                      <div className="radiobox-address">
                        <div className="radiobox-wrapper">
                          <label className="radio-color-btn ml-30">
                            <label className="radio-color-btn ml-20">
                              {subItem.display_member}
                              <input
                                type="radio"
                                name={item.display_member}
                                checked={checkboxValues.includes(subItem.value_member)}
                                value={`no`}
                                disabled={checkboxValues.includes(item.value_member) ? false : true}
                                onChange={(evt) => {
                                  let currentValues = _.get(props, 'form.values.role_ids') || [];
                                  // console.log('FormikAOISelect----currentValue---->', currentValues);
                                  if (!currentValues.includes(subItem.value_member)) {
                                    _.map(entityData, (subItem, index) => {
                                      if (
                                        item.value_member !== adminRole &&
                                        item.value_member === subItem.parent_role_id
                                      ) {
                                        // console.log('manual invoice values', item, subItem);
                                        currentValues = currentValues.filter((id) => id !== subItem.value_member);
                                      }
                                    });
                                    currentValues.push(subItem.value_member);
                                  } else {
                                    currentValues = currentValues.filter((id) => id != subItem.value_member);
                                  }

                                  // console.log('FormikAOISelect----currentValue---->', currentValues);

                                  props.form.setFieldValue('role_ids', currentValues);
                                }}
                              />
                              <div className="control-indicator"></div>
                            </label>
                          </label>
                        </div>
                      </div>
                    );
                  }
                })}
                {item.display_member === 'CT Assurance' ? (
                  <>
                  <div className="radiobox-address">
                    <div className="radiobox-wrapper">
                      <ul className="pl-50">
                        <li className="d-flex" style={{ minHeight: 35 }}>
                          <label className="radio-color-btn ml-20">
                            <input
                              type="radio"
                              name="radiocust2"
                              checked={is_maker}
                              value={`no`}
                              disabled={!checkboxValues.includes(30) ? true : false}
                              onChange={(e) => {
                                // console.log('check radio is maker', is_maker);
                                props.form.setFieldValue('is_maker', true);
                                props.form.setFieldValue('is_checker', false);
                              }}
                            />
                            <div className="control-indicator"></div>
                          </label>
                          {` `}Maker
                        </li>
                        <li className="d-flex">
                          <label className="radio-color-btn ml-20">
                            <input
                              type="radio"
                              name="radiocust2"
                              checked={is_checker}
                              value={`no`}
                              disabled={!checkboxValues.includes(30) ? true : false}
                              onChange={(e) => {
                                // console.log('check radio', is_checker);
                                props.form.setFieldValue('is_checker', true);
                                props.form.setFieldValue('is_maker', false);
                              }}
                            />
                            <div className="control-indicator"></div>
                          </label>
                          {' '}Checker
                        </li>
                      </ul>
                      <div>
                        {/* <Checkbox
                          checked={is_maker}
                          disabled={!checkboxValues.includes(30) ? true : false}
                          name="extendedProfile"
                          // value={true}
                          // onChange={(evt) => {
                          //   let newValue = parseInt(evt.target.value);
                          //   console.log('FormikAOISelect----newValue111---->', newValue);
                          //   props.form.setFieldValue('is_maker', !is_maker);

                          //   //  setValue(newValue);
                          //   // if (props.cb) {
                          //   //   props.cb(newValue);
                          //   // }
                          // }}
                          onClick={(v) => props.form.setFieldValue('is_maker', !is_maker)}
                        /> */}
                        {/* </div>
                        <div> */}
                        {/* <Checkbox
                          checked={is_checker}
                          disabled={!checkboxValues.includes(30) ? true : false}
                          name="extendedProfile"
                          // value={true}
                          // onChange={(evt) => {
                          //   let newValue = parseInt(evt.target.value);
                          //   console.log('FormikAOISelect----newValue111---->', newValue);
                          //   props.form.setFieldValue('is_maker', !is_maker);

                          //   //  setValue(newValue);
                          //   // if (props.cb) {
                          //   //   props.cb(newValue);
                          //   // }
                          // }}
                          onClick={(v) => props.form.setFieldValue('is_checker', !is_checker)}
                        /> */}
                      </div>
                    </div>
                  </div>
                  </>
                ) : null}
              </div>
            </li>
          </ul>
        );
      }
    });
  };

  // console.log('v-------------------------------->', entityData);

  let selectedLabels = (value || []).map((v) => v.label);
  options = options.filter((option) => !selectedLabels.includes(option.label));

  useEffect(() => {
    const initialSelected = options.filter((o) => initialValue.includes(o.value?.id));
    setValue(initialSelected);
  }, [initialValue]);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <div className={`mui-multi-select ${hasError ? 'has-error' : ''}`}>
      {renderCheckbox()}

      <Autocomplete
        className="invisible"
        id={`mui-autocomplete-${Date.now()}`}
        multiple
        size="small"
        disableClearable
        fullWidth
        limitTags={3}
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          // console.log('FormikAOISelect----newValue---->', newValue);
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
