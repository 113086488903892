import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';

const FormikMaterialTextField = (props) => {
  // console.log('MaterialFormikTextField--->props', props)

  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    type = 'text',
    placeholderText,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  // if (field.name === 'formData.basicInfo.name_of_farm') {
  //   console.log('\n---------FormikMaterialTextField----------');
  //   console.log('field.name-->', field.name);
  //   console.log('field-->', field);
  //   console.log('props->', props);
  //   console.log('errors->', errors);
  //   console.log('touched->', touched);
  //   console.log('hasError-->', hasError);
  //   console.log('errText-->', errText);
  // }

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      <TextField
        placeholder={placeholderText ? placeholderText : ''}
        style={{ cursor: disabled && 'not-allowed' }}
        type={type}
        fullWidth
        variant={variant || 'outlined'}
        size="small"
        disabled={disabled}
        error={hasError}
        helperText={errText}
        onWheel={(event) => {
          // event.preventDefault();
          event.target.blur();
        }}
        {...field}
      />
    </div>
  );
};

export default FormikMaterialTextField;
