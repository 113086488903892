import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import './index.css'
import { Formik, Field, Form } from 'formik';
import { Button } from 'reactstrap';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/InfoRounded';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import DemoImage from 'assets/images/data-not-found.png'
import { fetchAllVendorsList, fetchEquipmentData } from 'services/configuration';
import { getEquipmentDataUsingEquId, MapTelemetryId, DeLinkTelemetryId } from './handlers';
import { respondConnectRequest } from 'services/profileConnect';

const MapTelemetryIdPopupForm = (props) => {
  const {
    edit,
    setShowMapTeleId,
    singleRowData,
    trackerId,
    equipmentMasterId,
    rerender,
    setRerender
  } = props;
  const [vendors, setVendors] = useState([])
  const [vendorId, setVendorId] = useState(null)
  const [entityId, setEntityId] = useState(null)
  const [shippingSelected, setShippingSelected] = useState(false)
  const [isEquipmentIdPresent, setIsEquipmentIdPresent] = useState(true)
  const [equipmentDataById, setEquipmentDataById] = useState([])
  const [equipmentDataByMobile, setEquipmentDataByMobile] = useState([])
  let initialValues = {
    telemetry_id: trackerId ? trackerId : ''
  };


  const handleMapTelemetryId = async (values) => {
    try {
      const bodyParams = {
        equipment_master_id: values?.equipment_master_id,
        telemetry_id: values?.telemetry_id
      };
      const res = await MapTelemetryId(bodyParams);
      setRerender(!rerender);
      setShowMapTeleId(false);
    } catch (error) {
      console.log(error)
    }
  };

  const handleDeLinkTelemetryId = async () => {
    try {
      const bodyParams = {
        equipment_master_id: equipmentMasterId,
        telemetry_id: trackerId
      };
      const res = await DeLinkTelemetryId(bodyParams);
      setShowMapTeleId(false);
      setRerender(!rerender);
    } catch (error) {
      console.log(error)
    }
  };

  // useEffect(() => {
  //     const params = {
  //         filters: {
  //              "Type_Of_Individual_Enum": "4,9,10",
  //               "Is_Supply_Partner": "true"
  //         },
  //         "offset": 0,
  //         "page_Size": 1000
  //       }
  //     const vendorList = async () => {
  //         try {
  //             const resp = await fetchAllVendorsList(params)
  //             setVendors(resp)
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }
  //     vendorList()
  // }, [])

  // const handleGetQuipmentData = (id, type) => {
  //     const getEquipmentData = async () => {
  //         try {
  //             const resp = await fetchEquipmentData(id, type)
  //             if (type == 'equipment_id') {
  //                 setEquipmentDataById(resp)
  //             } else if (type == 'phone_number') {
  //                 setEquipmentDataByMobile(resp)
  //             }
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }
  //     getEquipmentData()
  // }
  return (
    <div className="bfc-modalform">
      <div className="bfc-modalform-header">
        <div className="icons-wrapper">
          <span
            style={{ fontSize: "20px", color: "#1b62ab" }}
          >{trackerId ? 'Update Tracker ID' : 'Link Tracker ID'}</span>
        </div>
        <div className="close-wrapper">
          <span
            className="close"
            style={{
              cursor: 'pointer',
              color: 'var(--unnamed-color-1b62ab)',
              fontWeight: '700',
            }}
            onClick={() => {
              setShowMapTeleId(false)
              // setRerender(!rerender)
            }}>
            <CloseIcon />
          </span>
        </div>
      </div>
      <div className='modalform-body' style={{
        overflow: "auto",
        maxHeight: "calc(100vh - 100px)" // Adjust this value based on your header height
      }}
        ref={(el) => el && (el.style.setProperty('overflow', 'auto', 'important'))}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            handleMapTelemetryId(values);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => {
            return (
              <Form
                style={{
                  paddingBottom: "25px"
                }}
              >
                <div className="form-wrapper full-width">
                  <div className="contailer-fluid" style={{ alignItems: "center" }}>

                  <div className="row form__form-group">
                      <div className="col" style={{ paddingRight: "0px" }}>
                        <span className="form-group-label">Equipment ID</span>
                        <span class="required">*</span>
                        <div className="form-group-field" style={{ marginTop: "10px" }}>
                          <Field
                            name="equipment_master_id"
                            component={FormikMaterialTextField}
                            type="text"
                            placeholder={'Enter Equipment ID'}
                            initialValues={initialValues}
                            style={{ margin: 0, padding: 0 }}
                            // disabled={trackerId ? true : false}
                            cb={(newVal) => {
                              setFieldValue('equipment_master_id', newVal)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row form__form-group">
                      <div className="col" style={{ paddingRight: "0px" }}>
                        <span className="form-group-label">Tracker ID</span>
                        <span class="required">*</span>
                        <div className="form-group-field" style={{ marginTop: "10px" }}>
                          <Field
                            name="telemetry_id"
                            component={FormikMaterialTextField}
                            type="text"
                            placeholder={'Enter Tracker ID'}
                            initialValues={initialValues}
                            style={{ margin: 0, padding: 0 }}
                            // disabled={trackerId ? true : false}
                            cb={(newVal) => {
                              setFieldValue('telemetry_id', newVal)
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row form__form-group">
                      <div className="col" style={{ paddingRight: "0px" }}>
                        <span className="form-group-label">Tracker ID</span>
                        <span class="required">*</span>
                        <div className="form-group-field" style={{ marginTop: "10px" }}>
                          <Field
                            name="telemetry_id"
                            component={FormikMaterialTextField}
                            type="text"
                            placeholder={trackerId ? trackerId : 'Enter Tracker ID'}
                            initialValues={initialValues}
                            style={{ margin: 0, padding: 0 }}
                            disabled={trackerId ? true : false}
                            cb={(newVal) => {
                              setFieldValue('telemetry_id', newVal)
                            }}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                      <Button
                        style={{ marginLeft: "20px" }}
                        type="submit"
                        // disabled={isSubmitting || trackerId?.length > 0 ? true : false}
                        disabled={isSubmitting || (!values?.telemetry_id || !values?.equipment_master_id)}
                      >
                        {edit ? 'Update' : 'Link'}
                      </Button>
                      {trackerId?.length > 0 && (
                        <Button
                          className='btn-secondary'
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            handleDeLinkTelemetryId();
                          }}
                        >
                          Delink
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div >
  );
};

export default MapTelemetryIdPopupForm;
