import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchAutoDetails(params) {
    let qs = {
      ...params,
      person_id: _.get(window, 'user.userid') || 0,
    };
    return utils.getQs(qs);
  },
};

export const resTransform = {
  fetchAutoDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'commodity_req_offer_id') || 0,
    };
    return res;
  },
};
