export const priceFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  // minimumIntegerDigits: 3,
  currencyDisplay: 'symbol',
});

export const amountFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  // minimumIntegerDigits: 3,
  currencyDisplay: 'symbol',
});

export const quantityFormatter = new Intl.NumberFormat('en-IN', {
  style: 'decimal',
  // currency: 'INR',
  // minimumIntegerDigits: 3,
  // currencyDisplay: 'symbol'
});
