import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import utils, { getDecryptedUrl } from 'lib/utils';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'lib/hooks/useQuery';
import ProgressClock from 'assets/images/icons/progress-clock.svg';
// import { ReactComponent as ProgressClock } from 'assets/images/icons/progress-clock.svg';
import BackbuttonIcon from 'assets/images/icons/back-button.svg';
import ShieldIcon from '../Shield/shield';
import SecuredIcon from 'assets/images/icons/securedIcon.png';
import SecuredTransport from 'assets/images/icons/secureTransport.svg';
import MatchingPopUp from 'common/MatchingPopupBuySell/MatchingPopup';
import DealVoucherModal from 'pages/commodityDetails/components/dealVoucherModal';
import SufficientBalanceIcon from 'assets/images/icons/sufficient_balance.svg';
import InsufficientBalanceIcon from 'assets/images/icons/insufficient_balance.svg';
import { Popover, Tooltip, Typography } from 'antd';
import { FormControlLabel } from '@material-ui/core';
import { COMMODITY_TYPE } from 'constants/pageParams';
import ToggleSwitch from 'pages/commodity/components/toggleSwitch';
import { Switch } from 'antd';
import PayIcon from 'common/Icons/PayIcon';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EnviraIcon from 'assets/images/icons/explore/envira-logo-trademark.png'
// import EnviraIcon from 'assets/images/icons/explore/envira-logo.svg'
// import EnviraIcon from 'assets/images/icons/Envira.svg'
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import MUIButton from 'common/MUIComponents/Button';
import MUITypography from 'common/MUIComponents/Typography';
import getColor from 'common/MUIComponents/colors';
import config from 'config';
import { Button } from 'reactstrap';

function OverviewHeader(props) {
  const { isindentconverted } = useQuery();

  const { indentDealsUrl } = config;
  const loc = getDecryptedUrl(window.location.href);

  const {
    className = 'bfc-header bfc-invoice-header',
    heading,
    headingIcon,
    status,
    backLink,
    closingOn,
    validUntil,
    rightPanContent,
    deliveryDetails,
    dealCreatedDateTime,
    showVerification,
    tickColor,
    id,
    type,
    isOpenBuySell,
    isWalkin,
    isPickup,
    is_secured,
    belowContent,
    is_owner,
    insufficient_bal,
    details,
    setTimeStamp,
    openingOn,
    auctionDuration,
    countBuyers,
    countSellers,
    age,
    isWarehouse,
    isAggregationEnabledBuy,
    isUnscheduled_Scheduled,
    headerTimeStamp,
    commodityDetails,
    removeTopContentClass,
    isNonConfirmed,
    // setShowShareBtn,
    // showShareBtn
  } = props;
  const state = useSelector((state) => state);
  const uiConfig = _.get(state, 'user.profile.ui_config');
  const bgColor = getColor(uiConfig?.theme_color);
  const history = useHistory();
  const userInfo = useSelector((state) => state.user);
  const [showdealVoucherModal, setShowDealVoucherModal] = useState(false)
  // const [showShareBtn, setShowShareBtn] = useState(false);
  const isControlTowerUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;
  const userTypeEnum = _.get(userInfo, 'profile.person.user_type_enum');
  const visible_only_for_fpo_members = details?.visible_only_for_fpo_members || null
  const isDirectDealEnabled = _.get(details, 'is_direct_deal_enabled') || false;
  const isFpo = _.get(userInfo, 'profile.person.is_fpo');
  const isFarmer = _.get(userInfo, 'profile.person.type_of_individual_enum') === 1;
  const isSHG = _.get(userInfo, 'profile.person.type_of_individual_enum') === 10;
  const is_indent_enabled = _.get(userInfo, 'profile.is_indent_enabled') || false;
  const is_seller_indent_enabled = _.get(userInfo, 'profile.is_seller_indent_enabled') || false;
  const groupState = useSelector((state) => state?.members);
  const groups = groupState?.groups;


  //TODO : tmp fix
  let tmpBkLink = '/commodity/offers/all';
  if (window.location.href.includes('/requirements/')) {
    tmpBkLink = '/commodity/requirements/all';
  }

  let deliveryLink = false;

  if (
    loc.includes('/deliveries/upcoming') ||
    loc.includes('/deliveries/ongoing') ||
    loc.includes('/deliveries/completed') ||
    loc.includes('/deliveries/missed') ||
    loc.includes(`/commodity/deals/${id}/deliveries`)
  ) {
    deliveryLink = false;
  } else if (window.location.href.includes('/deliveries')) {
    deliveryLink = true;
  }

  const pickUpDate = dayjs(deliveryDetails?.pick_up_datetime).format('DD MMM YY');
  const pickUpTime = dayjs(deliveryDetails?.pick_up_datetime).format('h:mm A');
  let decodeBackLink = backLink;
  try {
    decodeBackLink = utils.decodeBackUrl(backLink);
  } catch (err) {
    console.log('decode back url issue', err);
  }

  let WholesaleBackLink = '';
  if (!is_indent_enabled && !is_seller_indent_enabled && isControlTowerUser && loc.includes(indentDealsUrl)) {
    // if (isindentconverted) {
    //   if (type === COMMODITY_TYPE.OFFERS) {
    //     WholesaleBackLink = '/commodity/offers/all?page=1&wholeSaleRequirement=true'
    //   } else {
    //     WholesaleBackLink = '/commodity/requirements/all?page=1&wholeSaleOffer=true';
    //   }
    // } else {
    //   if (type === COMMODITY_TYPE.REQUIREMENTS) {
    //     WholesaleBackLink = '/commodity/offers/all?page=1&wholeSaleRequirement=true'
    //   } else {
    //     WholesaleBackLink = '/commodity/requirements/all?page=1&wholeSaleOffer=true';
    //   }
    // }
    WholesaleBackLink = decodeBackLink;
  } else {
    WholesaleBackLink = decodeBackLink;
  }


  // console.log('check WholesaleBackLink', WholesaleBackLink, decodeBackLink)
  return (
    <>
      <div className={`${className}`}>
        <div className="content-wrapper">
          {backLink && (
            <div className="bck-btn">
              <Link to={WholesaleBackLink}>
                <img src={BackbuttonIcon} alt="" />
              </Link>
            </div>
          )}

          <div className="content-block">
            <div className={removeTopContentClass ? '' : "top-content"}>
              {heading && (
                <h4 className="title">
                  <div className='d-flex align-items-center'>
                    {headingIcon && <span>{headingIcon}</span>} {heading}{' '}
                  </div>
                  <span className="ml-5 p-0" style={{ backgroundColor: 'var(--unnamed-color-ffffff)', borderRadius: '5px' }}>
                    {showVerification ? <ShieldIcon dontShowPopUp={true} tickvalue={tickColor == null || tickColor == 0 ? 4 : tickColor} /> : null}
                  </span>
                  {details?.is_indent_buy_sell && <PayIcon className="mt-5" size={18} />}
                  {isWarehouse ? <Tooltip title={<><div>Ready Stock</div><div>Quick Delivery</div></>}><HomeWorkIcon size={24} style={{color: 'var(--unnamed-color-1b62ab)', marginLeft: 12}} /></Tooltip> : ''}
                  {isControlTowerUser && is_secured && (
                    <div className="row ml-5">
                      {/* <img src={SecuredIcon} className="mt-5" style={{ width: '22px', height: '22px' }} /> */}
                      <img src={SecuredTransport} className="ml-5" style={{ width: '35px', height: '35px', marginTop: '-5px' }} />
                    </div>
                  )}
                </h4>
              )}

            </div>
            <div className={`bottom-content`}>
              <div className="col-md-12">
                <div className="row align-items-center">
                  {typeof status === 'string' && status && <label className="lbl lbl-published my-1">{status}</label>}
                  {typeof status === 'object' && status}
                  {age && (
                    <p className="pr-4 my-1 font-weight-bold">
                      <spa variant='body2' style={{textTransform: 'capitalize'}} >{age}</spa>
                    </p>
                  )}
                  {dealCreatedDateTime && (
                    <p className="pr-4 my-1">
                      <span>
                        <img src={ProgressClock} alt="" />
                      </span>
                      <span className="ml-5">
                        {utils.formatDateTime(dealCreatedDateTime)}
                      </span>
                    </p>
                  )}
                  {closingOn && !isOpenBuySell && !isPickup ? (
                    <p className="pr-4">
                      <span >
                        <img src={ProgressClock} alt="" />
                      </span>
                      <span className="ml-5">
                        Closing at {utils.formatDateTime(closingOn)}
                      </span>
                    </p>
                  ) : (
                    isOpenBuySell &&
                    !isWalkin && !isPickup && (
                      <p className="pr-4">
                        <span>
                          <img src={ProgressClock} alt="" />
                        </span>{' '}
                        <span className="ml-5">
                          Open
                        </span>
                      </p>
                    )
                  )}
                  {isWalkin && !isPickup && (
                    <p className="pr-4">
                      <span>
                        <img src={ProgressClock} alt="" />
                      </span>{' '}
                      <span className="ml-5">
                        Walk In
                      </span>
                    </p>
                  )}
                  {validUntil && !isOpenBuySell && !isPickup ? (
                    <p className="">
                      <span>
                        <img src={ProgressClock} alt="" />
                      </span>
                      <span className="ml-5">
                        Valid Until {utils.formatDateTime(validUntil)}
                      </span>
                    </p>
                  ) : null}
                  {isPickup && isWalkin && (
                    <p className="mr-2 pr-4">
                      <span>
                        <img src={ProgressClock} alt="" />
                      </span>{' '}
                      <span className="ml-5">
                        {isAggregationEnabledBuy ? <>Pickup <span style={{ color: 'var(--unnamed-color-1b62ab)' }}> (Aggregation Service Included)</span></> : 'Pickup'}
                      </span>
                    </p>
                  )}
                  {(isFpo || (isFarmer && groups.length) || isSHG || isControlTowerUser) && (loc.includes(`commodity/requirements/${id}`) || loc.includes(`commodity/offers/${id}`)) ?
                    <div className="my-1 mx-3">
                      <FormControlLabel
                        className="mb-0 mr-2"
                        control={
                          <Switch
                            className='ml-2'
                            size='small'
                            checked={!visible_only_for_fpo_members}
                            disabled={true}
                          />
                        }
                        label={
                          visible_only_for_fpo_members ? (
                            <span style={{ fontWeight: 'bold', color: 'var(--unnamed-color-1b62ab)' }}>{isSHG ? `My SHG` : isFarmer ? ' My FPO / SHG' : `My FPO`}</span>
                          ) : (
                            <span style={{ fontWeight: 'bold', color: 'var(--unnamed-color-1b62ab)' }}>Everyone</span>
                          )
                        }
                        labelPlacement="start"
                        style={{ marginLeft: '0px' }}
                      />
                    </div>
                    : ''}
                  {deliveryLink && !isNonConfirmed && (
                    <p className="pr-4">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 38 38"
                          class="mdi-icon "
                          fill="currentColor">
                          <path
                            class=""
                            d="M31.667,4.75a1.583,1.583,0,0,0-1.583-1.583h-4.75V0H22.167V3.167h-4.75V0H14.25V3.167H9.5V0H6.333V3.167H1.583A1.583,1.583,0,0,0,0,4.75V7.917H31.667Z"></path>
                          <path
                            class=""
                            d="M25.305,136.384a11.066,11.066,0,0,1,6.362,0V128H0v20.583a1.583,1.583,0,0,0,1.583,1.583h16.3A11.069,11.069,0,0,1,25.305,136.384Zm-3.138-5.217h3.167v3.167H22.167Zm-7.917,0h3.167v3.167H14.25ZM9.5,147H6.333v-3.167H9.5Zm0-6.333H6.333V137.5H9.5Zm0-6.333H6.333v-3.167H9.5Zm4.75,3.167h3.167v3.167H14.25Z"
                            transform="translate(0 -118.5)"></path>
                          <path
                            class=""
                            d="M265.5,256a9.5,9.5,0,1,0,9.5,9.5A9.5,9.5,0,0,0,265.5,256Zm1.119,10.619A1.594,1.594,0,0,1,265,267l-4.75-1.583,1-3.008,3.821,1.267,4.058-4.058,2.239,2.239Z"
                            transform="translate(-237 -237)"></path>
                        </svg>
                      </span>
                      <span className="ml-5">
                        {
                          isUnscheduled_Scheduled ?
                            `Pick up ${pickUpDate} | ${pickUpTime}`
                            :
                            headerTimeStamp ? headerTimeStamp : "loading..."
                        }
                      </span>
                    </p>
                  )}
                  {deliveryLink&& !isNonConfirmed && (
                    <p className="pr-4">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 46 34.141"
                          class="mdi-icon "
                          fill="currentColor">
                          <g transform="translate(0 0)">
                            <path
                              class=""
                              d="M412.9,220a.9.9,0,1,0,.9.9A.9.9,0,0,0,412.9,220Zm0,0"
                              transform="translate(-374.984 -200.234)"></path>
                            <path
                              class=""
                              d="M42.224,16.23l-1.549-6.2a2.694,2.694,0,0,0-1.862-4.642H31.625V2.7A2.7,2.7,0,0,0,28.93,0H2.7A2.7,2.7,0,0,0,0,2.7V26.055a2.7,2.7,0,0,0,2.7,2.7H5.93a5.391,5.391,0,1,0,10.781,0H33.422a5.391,5.391,0,1,0,10.781,0c0-.051,0-.1,0-.153A2.7,2.7,0,0,0,46,26.055V20.664a4.5,4.5,0,0,0-3.776-4.435ZM39.01,10.782l1.348,5.391H32.9l2.06-2.06a.9.9,0,0,0-1.27-1.27l-2.06,2.06v-4.12Zm0-1.219c0,.5.3-.578-.2-.578H31.625l7.384.578C39.505,9.562,39.01,9.067,39.01,9.562ZM11.321,32.344c-1.783,0-3.514-3.009-3.419-3.009a4.254,4.254,0,0,1-.475,1.259,4.81,4.81,0,0,0,3.6,2.334c1.957,0-.864-2.991-.864-.987C10.164,33.922,13.3,32.344,11.321,32.344ZM16.4,26.953a5.391,5.391,0,0,0-10.165,0H2.7a.9.9,0,0,1-.9-.9V2.7c0-.5-.5,24.258,0,24.258L28.93,1.8a.9.9,0,0,1,.9.9V26.953Zm22.41,5.391c-1.981,0,1.8,1.6,1.8-.384,0-2.009-4.085.741-2.133.741s.336-2.361.336-.357C38.813,34.326,40.794,32.344,38.813,32.344Zm5.018-5.562a5.391,5.391,0,0,0-10.1.172H31.625V17.969h9.883a2.7,2.7,0,0,1,2.541,1.8H41.508a.9.9,0,1,0,0,1.8h2.7v4.492a.9.9,0,0,1-.372.727Zm0,0"
                              transform="translate(0 0)"></path>
                            <path
                              class=""
                              d="M94.086,60a8.086,8.086,0,1,0,8.086,8.086A8.1,8.1,0,0,0,94.086,60Zm0,14.375c-3.468,0,1.875.12,1.522,0a3.178,3.178,0,0,0-1.522,0c-.005.12,1.4-.12,1.522,0S97.554,74.375,94.086,74.375Zm0,0"
                              transform="translate(-78.273 -54.609)"></path>
                            <path
                              class="b"
                              d="M170.492,103.594h-2.7v-2.7a.9.9,0,1,0-1.8,0v3.594a.9.9,0,0,0,.9.9h3.594a.9.9,0,0,0,0-1.8Zm0,0"
                              transform="translate(-151.086 -91.015)"></path>
                            <path
                              class="c"
                              d="M114.05,300a8.05,8.05,0,1,0,8.05,8.05A8.047,8.047,0,0,0,114.05,300Zm0,0"
                              transform="translate(-97.626 -294.897)"></path>
                            <path
                              class="b"
                              d="M413.8,300a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,413.8,300Zm0,0"
                              transform="translate(-374.984 -273.047)"></path>
                            <path
                              class="b"
                              d="M413.8,300a1.8,1.8,0,1,0,1.8,1.8A1.8,1.8,0,0,0,413.8,300Zm0,0"
                              transform="translate(-402.584 -273.047)"></path>
                          </g>
                        </svg>
                      </span>
                      <span className="ml-5">
                        Distance {deliveryDetails?.estimated_km} | {deliveryDetails?.estimated_time}
                      </span>
                    </p>
                  )}
                  {openingOn && (
                    <p className="pr-4">
                      <span >
                        <img src={ProgressClock} alt="" />
                      </span>
                      <span className="ml-5">
                        Opening On {utils.formatDateTime(openingOn)}
                      </span>
                    </p>
                  )}
                  {
                    auctionDuration && (
                      <p className="pr-4">
                        <span >
                          <img src={ProgressClock} alt="" />
                        </span>
                        <span className="ml-5">
                          Auction Duration : {`${auctionDuration} mins`}
                        </span>
                      </p>
                    )
                  }
                  {
                    countBuyers >= 0 && (
                      <p className="pr-4" style={{ color: 'red', fontWeight: 'bold' }}>
                        <span style={{ fontSize: 'var(--fs-base__zero)' }}>
                          {countBuyers}
                        </span>
                        <span className="ml-5">
                          Buyers
                        </span>
                      </p>
                    )
                  }
                  {
                    countSellers >= 0 && (
                      <p className="pr-4" style={{ color: 'rgb(50 168 54)', fontWeight: 'bold' }}>
                        <span style={{ fontSize: 'var(--fs-base__zero)' }}>
                          {countSellers}
                        </span>
                        <span className="ml-5" >
                          Sellers
                        </span>
                      </p>
                    )
                  }
                  {(details?.market_wholesale_enum == 2 || commodityDetails?.market_wholesale_enum == 2) && (
                    <p className="pr-4">
                      <span >
                        <Popover placement="top" content={'Wholesale'} trigger="hover">
                          <img src={EnviraIcon} style={{ height: '40px', width: 'auto' }} />
                        </Popover>
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-box">
          {is_owner && loc.includes(`/commodity/${type}/${id}/overview?back=${backLink}`) && userTypeEnum !== 2 ?
            insufficient_bal == 0 ?
              (
                <Popover content={'Sufficient Balance'} placement='left'>
                  <img src={SufficientBalanceIcon} className='mr-4' style={{ height: 22, width: 22, cursor: 'pointer' }} />
                </Popover>
              ) :
              insufficient_bal <= 2500 && insufficient_bal > 0 ? (
                <Popover content={'Insufficient Balance'} placement='left'>
                  <img src={InsufficientBalanceIcon} className='mr-4' style={{ height: 22, width: 22, cursor: 'pointer' }} onClick={() => setShowDealVoucherModal(true)} />
                </Popover>
              ) : (
                <Popover content={'Insufficient Balance'} placement='left'>
                  <img src={InsufficientBalanceIcon} className='mr-4' style={{ height: 22, width: 22, cursor: 'pointer' }} onClick={() => window.open("/subscription-packages", "_blank")} />
                </Popover>
              )
            : null}
          {rightPanContent}
        </div>
      </div>
      <div className="link-deal-delivery-container">
        {belowContent}
      </div>
      {showdealVoucherModal && is_owner &&
        (
          <DealVoucherModal
            isOpen={showdealVoucherModal}
            closeModal={() => setShowDealVoucherModal(false)}
            insufficient_bal={insufficient_bal}
            details={details}
            setTimeStamp={setTimeStamp}
            setShowDealVoucherModal={setShowDealVoucherModal}
            isOverview={true}
          />
        )}
    </>
  );
}

OverviewHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  headingIcon: PropTypes.element,
  status: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  backLink: PropTypes.string,
  closingOn: PropTypes.object,
  validUntil: PropTypes.object,
  rightPanContent: PropTypes.element,
};

export default OverviewHeader;
