import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Typography } from '@material-ui/core';
import { Dropdown, Menu, Table, Tag, Popover, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';
import useQuery from 'lib/hooks/useQuery';

import OverviewHeader from 'common/OverviewHeader';
import { actions as storageActions } from 'redux/storage';
import utils from 'lib/utils';
import dayjs from 'dayjs';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import CommodityField from 'common/TableComponents/commodityField';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import FilterUtils from 'lib/filterUtils';
import QuantityField from 'common/TableComponents/quantityField';
import PriceField from 'common/TableComponents/priceField';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import AntPagination from 'common/AntTablePagination';
import OverviewNav from 'common/OverviewNav';
import AntTableActions from 'common/TableActions';
import DialogPopup from 'common/DialogPopUp';
// import LogoLoader from 'common/LogoLoader';

const StorageTransfer = () => {
  const storageState = useSelector((state) => state.userStorage);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const tableRef = useRef();
  const history = useHistory();
  let {
    page,
    back,
    commodity_form_code,
    commodity_id,
    commodity_standard_enum,
    stock_transfer_status_enum,
    stock_transfer_id,
    date,
    from_address,
    to_address,
    transporter,
    delivery_end_date,
    delivery_from_date,
    isControlTower
  } = useQuery();

  const isCTUser = Boolean(isControlTower);
  const acl = utils.getACL(user);
  const storageTransfers = storageState?.storageTransfers;

  const busy = storageState.busy

  const [filters, setFilters] = useState({ transfer_type_enum: '1', is_invoked_by_ct: isCTUser ? '1' : '0' });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isRerenderRequired, setIsRerenderRequired] = useState(false);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let MarketLinksRecord = Object.values(rolesList).find((x) => x.module_name === 'Market');
  const MarketLinks = _.get(MarketLinksRecord, `full_access`);

  useEffect(() => {
    const params = {
      filters: filters,
      offset: 0,
      page_size: 10,
    };
    dispatch(storageActions.fetchStockTransfers(params));
  }, [filters, isRerenderRequired]);

  const encodeBackUrl = utils.encodeBackUrl();
  const decodeBackUrl = utils.decodeBackUrl(back);

  const menu = () => (
    <Menu className="" style={{ marginLeft: '-4px', width: '200px' }}>
      <div className="menu-list">
        <Menu.Item>
          <Typography
            onClick={() => {
              history.push(`/market/storage/create-quick-transfer?back=${encodeBackUrl}`);
            }}
            className="user-list">
            Quick Transfer
          </Typography>
        </Menu.Item>
        <Menu.Item>
          <Typography onClick={() => {
            history.push(`/commodity/requirements/create?isStockTransfer=true&back=${encodeBackUrl}`)
          }} className="user-list">
            Stock Transfer
          </Typography>
        </Menu.Item>
        <div
          style={{
            clipPath: 'polygon(50% 50%, 0 100%, 100% 100%)',
            position: 'absolute',
            top: '-13px',
            right: 7,
            height: 15,
            width: 15,
            backgroundColor: '#fff',
          }}></div>
      </div>
    </Menu>
  );

  const tableData = storageTransfers;
  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    if (isCTUser) {
      params = {
        ...filters,
        isControlTower: true,
        back
      };
    }
    switch (dataIndex) {
      case 'commodity_form_code':
        delete params['commodity_form_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_form_code'));
        break;
      case 'commodity_code':
        delete params['commodity_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_id'));
        break;
      case 'coarse_loaction':
      case 'locations':
        delete params['location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('location_code'));
        break;
      case 'req_offer_response_status_enum_code':
        delete params['req_offer_response_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('req_offer_response_status_enum'));
        break;
      case 'auction_response_phase_enum_code':
        delete params['auction_response_phase_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('auction_response_phase_enum'));
        break;
      case 'auction_duration_enum_code':
        delete params['auction_duration_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('auction_duration_enum'));
        break;
      case 'commodity_standard_enum_code':
        delete params['commodity_standard_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('commodity_standard_enum'));
        break;
      default:
        delete params[dataIndex];
        delete params['isControlTower'];
        delete params['back'];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'commodity_form_code':
        params = {
          ...params,
          commodity_form_code: e,
        };
        break;
      case 'commodity_code':
        params = {
          ...params,
          commodity_id: e,
        };
        break;
      case 'coarse_loaction':
      case 'locations':
        params = {
          ...params,
          location_code: e,
        };
        break;
      case 'req_offer_response_status_enum_code':
        params = {
          ...params,
          req_offer_response_status_enum: e,
        };
        break;
      case 'auction_response_phase_enum_code':
        params = {
          ...params,
          auction_response_phase_enum: e,
        };
        break;
      case 'auction_duration_enum_code':
        params = {
          ...params,
          auction_duration_enum: e,
        };
        break;
      case 'commodity_standard_enum_code':
        params = {
          ...params,
          commodity_standard_enum: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    if (isCTUser) {
      params = {
        ...filters,
        isControlTower: true,
        back
      };
    }
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };


  const clearFilters = () => {
    setFilters({ transfer_type_enum: 1, is_invoked_by_ct: isCTUser ? '1' : '0' });
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(pathname + '?' + `${isCTUser ? `isControlTower=true&` : ''}page=1&back=${back}`);
    } else {
      history.push(pathname + '?' + `${isCTUser ? `isControlTower=true` : ''}&page=1`);
    }
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const standardToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV upto 3600</p>
      <p className="p-0 m-0">Ash ~ 10% or more</p>
      <p className="p-0 m-0">Moist. ~ 12% or more</p>
    </div>
  );

  const premiumToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 4000 or more</p>
      <p className="p-0 m-0">Ash ~ 5% or less </p>
      <p className="p-0 m-0">Moist. ~ 8% or less</p>
    </div>
  );

  const superiorToolTipCode = (
    <div>
      <p className="p-0 m-0">GCV 3600 - 4000</p>
      <p className="p-0 m-0">Ash ~ 5% - 10%</p>
      <p className="p-0 m-0">Moist. ~ 8% - 12%</p>
    </div>
  );
  const warningToolTipCode = (
    <div>
      <p>Some quality specs missing or inconsistent.</p>
    </div>
  )

  const STORAGE_TRANSFER_TABLE_HEADERS = [
    {
      title: 'Transfer No',
      dataIndex: 'stock_transfer_id',
      key: 'stock_transfer_id',
      width: '130px',
      align: 'center',
      fixed: true,
      sorter: (a, b) => a.stock_transfer_id - b.stock_transfer_id,
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">{row.stock_transfer_id}</div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'stock_transfer_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={stock_transfer_id}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'stock_transfer_id'),
      filteredValue: stock_transfer_id !== undefined ? stock_transfer_id : null,
    },
    {
      title: 'Loading',
      dataIndex: 'from_address',
      key: 'from_address',
      width: '130px',
      align: 'left',
      sorter: (a, b) => a.from_address && a.from_address.localeCompare(b.from_address && b.from_address),
      render: (text, row) => <div className="align-left">{row.from_address}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'from_address'}
          isFarmer={acl.isCompany ? false : true}
          isControlTower={Boolean(isControlTower)}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={from_address}
          name={'Loading'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'from_address'),
      filteredValue: from_address !== undefined ? from_address : null,
    },
    {
      title: 'Delivery',
      dataIndex: 'to_address',
      key: 'to_address',
      width: '130px',
      align: 'left',
      sorter: (a, b) => a.to_address && a.to_address.localeCompare(b.to_address && b.to_address),
      render: (text, row) => <div className="align-left">{row.to_address}</div>,
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'to_address'}
          isFarmer={acl.isCompany ? false : true}
          isControlTower={Boolean(isControlTower)}
          codeBaisedFilter={true}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={to_address}
          name={'Delivery'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'to_address'),
      filteredValue: to_address !== undefined ? to_address : null,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '130px',
      align: 'center',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">{dayjs(row.date).format('DD-MMM-YYYY')}</div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={date}
          name={'Date'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'date'),
      filteredValue: date !== undefined ? date : null,
    },
    {
      title: 'Form',
      dataIndex: 'commodity_form_code',
      key: 'commodity_form_code',
      width: '130px',
      align: 'center',
      sorter: (a, b) => a.commodity_form_code.localeCompare(b.commodity_form_code),
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">{row.commodity_form_code}</div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_form_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_form_code}
          name={'Form'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_form_code'),
      filteredValue: commodity_form_code !== undefined ? commodity_form_code : null,
    },
    {
      title: 'Commodity',
      dataIndex: 'commodity_id',
      key: 'commodity_id',
      width: '130px',
      align: 'center',
      sorter: (a, b) => a.commodity_code && a.commodity_code.localeCompare(b.commodity_code && b.commodity_code),
      render: (text, row) => (
        <CommodityField commodity_residue_code={row?.commodity_residue_code} commodity_code={row.commodity_code} />
      ),
      sorter: (a, b) => a.commodity_code.localeCompare(b.commodity_code),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'commodity_code'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={commodity_id}
          name={'Commodity'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_id'),
      filteredValue: commodity_id !== undefined ? commodity_id : null,
    },
    {
      title: 'Quantity',
      dataIndex: 'transfer_no',
      key: 'transfer_no',
      width: '130px',
      align: 'center',
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">
          <QuantityField
            qty={row.total_qty}
            periodicity_enum_code={row.periodicity_enum_code}
            qty_uom_code={row.qty_uom_code}
          />
        </div>
      ),
    },
    {
      title: 'Transfer Rate',
      dataIndex: 'transfer_no',
      key: 'transfer_no',
      width: '130px',
      align: 'center',
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">
          <PriceField expected_price={row.transfer_price} qty_uom_code={row.qty_uom_code} />
        </div>
      ),
    },
    {
      title: 'From Date',
      dataIndex: 'delivery_from_date',
      key: 'delivery_from_date',
      width: '130px',
      align: 'center',
      sorter: (a, b) => new Date(a.delivery_from_date) - new Date(b.delivery_from_date),
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">
          {dayjs(row.delivery_from_date).format('DD-MMM-YYYY')}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'delivery_from_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={date}
          name={'Date'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'delivery_from_date'),
      filteredValue: delivery_from_date !== undefined ? delivery_from_date : null,
    },
    {
      title: 'To Date',
      dataIndex: 'delivery_end_date',
      key: 'delivery_end_date',
      width: '130px',
      align: 'center',
      sorter: (a, b) => new Date(a.delivery_end_date) - new Date(b.delivery_end_date),
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">
          {dayjs(row.delivery_end_date).format('DD-MMM-YYYY')}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'delivery_end_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={date}
          name={'Date'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'delivery_end_date'),
      filteredValue: delivery_end_date !== undefined ? delivery_end_date : null,
    },
    {
      title: 'Status',
      dataIndex: 'stock_transfer_status_enum_code',
      key: 'stock_transfer_status_enum_code',
      width: '130px',
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Confirmed' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.stock_transfer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.stock_transfer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
          {text == 'Deleted' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">
                {row.stock_transfer_status_enum_code.toUpperCase()}
              </div>
            </Tag>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'stock_transfer_status_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={stock_transfer_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'stock_transfer_status_enum'),
      filteredValue: stock_transfer_status_enum !== undefined ? stock_transfer_status_enum : null,
    },
    ...(!isCTUser && MarketLinks ? [{
      title: 'Action',
      dataIndex: 'stock_transfer_status_enum_code',
      key: 'stock_transfer_status_enum_code',
      width: '130px',
      align: 'center',
      render: (text, row) => (
        <div className="d-flex justify-content-center align-items-center">
          <AntTableActions
            showFlag={true}
            row={row}
            controls={[
              ...(row.stock_transfer_status_enum_code === 'Draft' ? [{
                name: 'Edit', onClick: async () => {
                  const params = {
                    filters: { stock_transfer_id: row.stock_transfer_id },
                    offset: 0,
                    page_size: 10,
                  };
                  const success = await dispatch(storageActions.fetchStockTransfers(params));
                  success && history.push(`/market/storage/create-quick-transfer?stock_transfer_id=${row.stock_transfer_id}&isEdit=true&back=${encodeBackUrl}`)
                }, controlColor: 'var(--unnamed-color-1b62ab)'
              }] : []),
              {
                name: 'Copy', onClick: async () => {
                  const params = {
                    filters: { stock_transfer_id: row.stock_transfer_id },
                    offset: 0,
                    page_size: 10,
                  };
                  const success = await dispatch(storageActions.fetchStockTransfers(params));
                  success && history.push(`/market/storage/create-quick-transfer?stock_transfer_id=${row.stock_transfer_id}isStockTransfer=true&iscopy=true&back=${encodeBackUrl}`)
                }, controlColor: 'var(--unnamed-color-1b62ab)'
              },
              ...(row.stock_transfer_status_enum_code !== 'Deleted' ? [{
                name: 'Delete', onClick: async () => {
                  setSelectedRow(row)
                  setIsOpen(true)
                }, controlColor: 'var(--unnamed-color-f44336)'
              }] : []),
            ]}
          />
        </div>
      ),
    }] : []),
  ];

  let links = [
    { to: `/commodity/deals?page=1&isStockTransfer=true&back=${back}`, title: 'Stock Transfer' },
    { to: `/market/storage/transfers`, title: 'Quick Transfer' }
  ]

  if (isCTUser) {
    links = [
      { to: `/commodity/deals?page=1&isStockTransfer=true&isControlTower=true&back=${back}`, title: 'Stock Transfer' },
      { to: `/market/storage/transfers?isControlTower=true`, title: 'Quick Transfer' }
    ]
  }

  const handelDealReject = () => {
    const success = dispatch(storageActions.deleteQuickStockTransfer({ stock_transfer_id: selectedRow?.stock_transfer_id }))
    if (success) {
      setIsRerenderRequired(!isRerenderRequired)
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="bfc-html">
      <OverviewNav links={links} backLink={decodeBackUrl}
        rightPanContent={
          !isCTUser && MarketLinks && <ButtonGroup dir="ltr" className="dropdown-button-group mr-2 d-flex justify-flex-end">
            <Button
              onClick={() => history.push(`/commodity/requirements/create?isStockTransfer=true&back=${encodeBackUrl}`)}
              className="d-flex justify-content-between align-items-center pl-3 pr-2"
              color="primary"
              style={{ padding: '5px 6px 5px 16px', minWidth: '20px', fontWeight: 'normal' }}>
              <span>Create</span>
            </Button>
            <Dropdown
              placement="bottomRight"
              overlayClassName="user-select-dropdown"
              overlay={menu}
              trigger={['click']}
            >
              <Button color="primary" style={{ padding: "0 !important", position: "relative", width: "37px" }} size="xs" className="icon icon--right right-toggle">
                <ChevronDownIcon
                  style={{ height: 24, width: 24, position: 'absolute', top: 4, right: 9, borderLeft: '1px solid' }}
                  className="icon ml-3 pl-1"
                />
              </Button>
            </Dropdown>
          </ButtonGroup>
        } />
      <div className="bfc-body create-form-body">
        <div className="invoice-table">
          {/* {busy && <div className="panel__refresh">
            <LogoLoader />
          </div>} */}
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
              columns={busy ? STORAGE_TRANSFER_TABLE_HEADERS.map((column) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        active="true"
                        key={column.dataIndex}
                        title={true}
                        paragraph={false}
                      />
                    );
                  },
                };
              }) : STORAGE_TRANSFER_TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 550 }}
              className="ant-table"
              size="small"
            />
            <AntPagination
              total={parseInt(totalRecords)}
              handlePageChange={handlePageChange}
              page={page}
              clearFilters={clearFilters}
              rowsPerPage={rowsPerPage}
              setScrollToFirstRow={setScrollToFirstRow}
            />
            <DialogPopup
              type="warning"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              heading={'Delete Quick Transfer'}
              message={`Are you sure you want to delete this transfer with transfer number #${selectedRow?.stock_transfer_id}?`}
              onClick={handelDealReject}>
            </DialogPopup>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StorageTransfer;
