import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import { subscriptionService } from 'services';

export const handleCreate = async (values, formikBag) => {
  try {
    // notifications.show({
    //   type: 'info',
    //   message: 'Subscribing...',
    //   key: 'req-form',
    // });

    const res = await subscriptionService.create(values);
    // notifications.show({
    //   type: 'success',
    //   message: 'Subscribed.',
    //   key: 'req-form',
    // });

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    throw err;
  }
};

export const handleCreateTrialSubscription = async (values, formikBag) => {
  try {
    const res = await subscriptionService.createTrialSubscription(values);
    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    throw err;
  }
};

export const handleContact = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Submitting request...',
      key: 'req-form',
    });

    const res = await subscriptionService.createContact();
    notifications.show({
      type: 'success',
      message: 'Thank you for your interest in the Enterprise Pack. A Sales Executive will get in touch shortly.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('createContact--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleApplyCoupon = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'applying coupon...',
      key: 'req-form',
    });

    const res = await subscriptionService.updateCoupon(values);
    notifications.show({
      type: 'success',
      message: 'Coupon applied.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleApplyCoupon--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};
