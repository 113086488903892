export const COMMODITY_TYPE = {
  REQUIREMENTS: 'requirements',
  OFFERS: 'offers',
};

export const COMMODITY_FILTER = {
  ALL: 'all',
  USER: 'user',
  BIDS: 'bids',
  PLANS: 'plans',
  ALLAUCTION: 'auction-all',
  USERAUCTION: 'auction-user',
  BIDSAUCTION: 'auction-bids',
  QUOTATIONS: 'quotations',
};

export default {
  COMMODITY_TYPE,
  COMMODITY_FILTER,
};
