import _ from 'lodash';
import utils, { getDecryptedUrl } from 'lib/utils';
import config from 'config';

import normalize from 'normalize-object';

let loc = getDecryptedUrl(window.location.href);

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] != _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }
    return utils.getQs(qsParams);
  },
  fetchTopBarLocationList(params = {}) {
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] != _.get(window, 'user.userid', 0)) {
        {
          loc.includes('social/feed')
            ? (qsParams = {
                ...params,
                Is_Invoked_By_Social_Feed: 'true',
                person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
              })
            : (qsParams = {
                ...params,
                person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
              });
        }
      } else {
        {
          loc.includes('social/feed')
            ? (qsParams = {
                ...params,
                Is_Invoked_By_Social_Feed: 'true',
                person_id: params['person_id'] || _.get(window, 'user.userid', 0),
              })
            : (qsParams = {
                ...params,
                person_id: params['person_id'] || _.get(window, 'user.userid', 0),
              });
        }
        // qsParams = {
        //   ...params,
        //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        // };
      }
    } else {
      {
        loc.includes('social/feed')
          ? (qsParams = {
              ...params,
              Is_Invoked_By_Social_Feed: 'true',
              person_id: params['person_id'] || _.get(window, 'user.userid', 0),
            })
          : (qsParams = {
              ...params,
              person_id: params['person_id'] || _.get(window, 'user.userid', 0),
            });
      }
      // qsParams = {
      //   ...params,
      //   Is_Invoked_By_Social_Feed: "true",
      //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      // };
    }
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },

  create(params = {}) {
    let fields = {
      company_address_id: 0,
      person_id: 0,
      address_type_enum: 0,
      address_text: '',
      landmark: '',
      address_line1: 0,
      address_line2: 0,
      address_line3: 0,
      taluka_id: 0,
      village_id: 0,
      city_id: 0,
      district_id: 0,
      state_id: 0,
      country_id: 0,
      is_transaction_address: true,
      acreage: 0,
      entity_id: 0,
      company_affiliate_enum: 0,
      location_id: 0,
      address_lattitude: 0,
      address_longitude: 0,
      pin_code: '0',
      operation_enum: 1,
    };

    let nFields = normalize(fields, 'snake');

    let bodyParams = {
      ...normalize(fields, 'snake'),
      city_code: ' ',
      ...params,
      is_transaction_address: true,
      person_id: params['person_id'] || _.get(window, 'user.id'),
    };

    bodyParams['address_text'] = bodyParams['location_name'] || '';

    // bodyParams['company_name'] = bodyParams['company_code'];

    return [bodyParams];
  },
  update(id, params) {
    let fields = {
      company_address_id: 0,
      person_id: 0,
      address_type_enum: 0,
      address_text: '',
      landmark: '',
      address_line1: 0,
      address_line2: 0,
      address_line3: 0,
      taluka_id: 0,
      village_id: 0,
      city_id: 0,
      district_id: 0,
      state_id: 0,
      country_id: 0,
      is_transaction_address: true,
      acreage: 0,
      entity_id: 0,
      company_affiliate_enum: 0,
      location_id: 0,
      address_lattitude: 0,
      address_longitude: 0,
      pin_code: '0',
      operation_enum: 3,
      lut_number: 0,
      lut_number_valid_until: 0,
      sez_registration_number: 0,
      sez_registration_number_valid_until: 0,
    };

    let nFields = normalize(fields, 'snake');

    let bodyParams = {
      ...normalize(fields, 'snake'),
      city_code: ' ',
      ...params,
      is_transaction_address: true,
      person_id: params['person_id'] || _.get(window, 'user.id'),
    };

    bodyParams['address_text'] = bodyParams['location_name'] || '';

    return [bodyParams];
  },
  destroy(id) {
    return id;
  },
  fetchTransportList(params = {}) {
    const qsParams = {
      ...params,
      person_company_affiliate_enum: 2,
    };

    return utils.getQs(qsParams);
  },
  fetchFarmerList(params = {}) {
    const qsParams = {
      ...params,
      person_company_affiliate_enum: params?.['person_company_affiliate_enum'] || 1,
    };

    return utils.getQs(qsParams);
  },
  fetchInvoiceLocationList(params = {}) {
    const qsParams = {
      ...params,
      // person_company_affiliate_enum: 1,
    };

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(loactionData, 'company_address_id');
  },
  fetchTopBarLocationList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return loactionData;
  },
  fetchDetails(resData) {
    return {
      ...resData,
      location_name: _.get(resData, 'location_name') || _.get(resData, 'address_text') || '',
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
  fetchTransportList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(loactionData, 'company_address_id');
  },
  fetchFarmerList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchFarmerLocationDetails(details);
    });

    return _.keyBy(loactionData, 'person_address_id');
  },
  fetchFarmerLocationDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'person_address_id') || 0,
    };
  },
  fetchInvoiceLocationList(resData) {
    let loactionData = (_.isArray(resData) ? resData : []).map((details) => {
      return {
        ...details,
        id: _.get(details, 'company_address_id') || 0,
      };
    });

    return _.keyBy(loactionData, 'company_address_id');
  },
};
