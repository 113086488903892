import React, { useState } from 'react';
import { Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import EditIcon2 from 'mdi-react/SquareEditOutlineIcon';
import TrueIcon from 'mdi-react/SquareEditOutlineIcon';
import * as handlers from '../handlers';
import notifications from 'lib/notifications';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import trueIcons from 'assets/images/correct-tick.png';

const UploadDocument = (props) => {
  const {
    field,
    form,
    entity_type_enum,
    front_back_enum,
    fileName,
    entity_id,
    attachmentId,
    attachmentPath,
    company_affiliate_enum,
    disabled,
    isAuthorizedSignatory,
    setIsRenderRequired,
    kycStatusKey,
    setdropValues,
  } = props;
  const { name, value, onChange } = field;
  const files = value;

  const onDrop = (onDropFiles) => {
    const val = onDropFiles.map((fl) =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      }),
    );

    if (val[0].size > 5242880) {
      try {
        notifications.show({
          type: 'error',
          message: 'Uploaded Attachment is exceeding the size. Please Upload Attachment of max 5MB size.',
          key: 'req-form',
        });
        return false;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      const fileToUpload = val[val.length - 1];
      val[0] = fileToUpload;
    }

    const attachmentIdToPass = props.attachmentId ? props.attachmentId : 0;
    const file1 = handlers.handleUpload(
      val,
      entity_type_enum,
      front_back_enum,
      entity_id,
      attachmentIdToPass,
      company_affiliate_enum,
    );

    return form.setFieldValue(name, val);
  };

  const handleDeleteUploadedDocument = async (params) => {
    await handlers.handleDeleteUploadedFileAgainstId(params);
    setIsRenderRequired && setIsRenderRequired(true);
  };

  const [isOpen, setState] = useState(false);
  const [showLightBox, setShowLightBox] = useState(false);
  let images_array = [];
  let file_name_array = fileName?.split('.').pop();

  console.log('check isAuthorizedSignatory && !kycStatusKey', isAuthorizedSignatory, !kycStatusKey)
  return (
    <div>
      <Row className='ml-0'>
        <div className="col-md-3">
          <Dropzone
            className="dropzone__input col-md-6"
            accept="image/jpeg, image/png, application/pdf"
            name={name}
            disabled={disabled}
            onDrop={(filesToUpload) => {
              onDrop(value ? value.concat(filesToUpload) : filesToUpload);
            }}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                {!files || files.length === 0}
                <input {...getInputProps()} />
                <div className="drag-block">
                  <Row>
                    <div className="icon-block">
                      <EditIcon2 />
                    </div>
                    <div className="d-sm-none d-md-none d-lg-block">Drop file here</div>
                  </Row>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        {attachmentId ? (
          fileName ? (
            <div className="col-12 col-md-8 align-item-center" style={{ cursor: 'pointer', color: '#1b62ab', position: 'relative' }}>
              {file_name_array.toLowerCase() === 'pdf' ? (
                <a href={attachmentPath} target="__blank"  title={fileName}>{fileName}</a>
              ) : (
                <>
                  <div className="" image-file__name onClick={() => {
                    setShowLightBox(true);
                  }}>
                    <span title={fileName}>{fileName}</span>
                  </div>
                  {showLightBox && <Lightbox image={attachmentPath} onClose={() => { setShowLightBox(false) }} />}
                </>
              )}
              {isAuthorizedSignatory && !kycStatusKey && (
              // {!disabled && (
                <TrashIcon
                  className="cursor-pointer"
                  style={{ color: 'var(--unnamed-color-f44336)', position: 'absolute', right: '30px', bottom: '10px', marginLeft: '8px' }}
                  onClick={() => {
                    let params = {
                      attachment_id: attachmentId,
                    };
                    handleDeleteUploadedDocument(params);
                    // setdropValues(0);
                  }}
                />
              )}
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}

        {files && Array.isArray(files) && (
          <div class="row col-md-6" style={{ marginLeft: 12 }}>
            {files.map((file, i) => (
              <div key={file.i}>
                <div class="column">
                  <div class="row">{/* <p>{file.name}</p> */}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Row>
    </div>
  );
};

export default UploadDocument;
