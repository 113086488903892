import _ from 'lodash';
import utils from 'lib/utils';
import { useSelector, useDispatch } from 'react-redux';
import config from 'config';

let documentModuleEnum, companyAffiliateEnum;

export const reqTransform = {
  fetchList(params = {}) {
    const qsParams = {
      ...params,
    };

    return utils.getQs(qsParams);
  },

  upload(params) {
    let bodyParams = {
      // ...params,
      advertisement_banner_id: 1,
      banner_name: 'banner',
      banner_pic_url: params['value'],
      banner_type_enum: '2',
      invoking_person_id: params['invoking_person_id'],
      operation_enum: '3',
      tagged_company_id: params['tagged_company_id'],
      tagged_person_id: params['tagged_person_id'],
      redirection_url: params['redirection_url'],
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let uploadData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });

    return _.keyBy(uploadData, 'advertisement_banner_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      // id: _.get(resData, 'advertisement_banner_id') || 0,
    };
    return res;
  },

  upload(resData) {
    return resData;
  },
};
