import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { dealInvoicesService, kycApprovalService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'dealInvoices',
  initialState: adapter.getInitialState({
    busy: false,
    hasMore: true,
    linkedDealDeliveries: [],
    invoiceVerificationStatusHistory: [],
    ledgerMetaData: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setHasMore: (state, { payload }) => ({ ...state, hasMore: payload }),
    setLinkedDealDeliveries: (state, { payload }) => ({ ...state, linkedDealDeliveries: payload }),
    setInvoiceVerificationStatusHistory: (state, { payload }) => ({
      ...state,
      invoiceVerificationStatusHistory: payload,
    }),
    setLedgerMetaData: (state, { payload }) => ({ ...state, ledgerMetaData: payload }),
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await dealInvoicesService.fetchList(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListAgainstPersonID = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await dealInvoicesService.fetchListAgainstPersonId(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchListAgainstCT = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchInvoiceListCT(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const FetchPlatformLedger = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  let resData;
  try {
    resData = await kycApprovalService.FetchPlatformLedger(params);
    if (_.isEmpty(resData) || Object.keys(resData).length < 20) {
      dispatch(slice.actions.setHasMore(false));
    }
    if (
      params?.filters?.seller_person_company_affiliate_id &&
      params?.filters?.seller_person_company_affiliate_enum &&
      params?.filters?.offset == 0
    ) {
      dispatch(slice.actions.setAll(resData));
    } else if (params?.filters?.offset == 0) {
      dispatch(slice.actions.setAll(resData));
    } else {
      dispatch(slice.actions.upsertMany(resData));
    }
    dispatch(slice.actions.upsertMany(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getLinkedDealDelivery = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.getLinkedDealDelivery(params);
    dispatch(slice.actions.setLinkedDealDeliveries(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setLinkedDealDeliveries({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const recordUnallocatedPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.recordUnallocatedPayment(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const deleteUnallocatedPayment = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.deleteUnallocatedPayment(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const fetchPlatformLedgerMetadata = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchPlatformLedgerMetadata(params);
    dispatch(slice.actions.setLedgerMetaData(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const getInvoiceVerificationStatusHistory = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));

  try {
    const resData = await dealInvoicesService.getInvoiceVerificationStatusHistory(params);

    dispatch(slice.actions.setInvoiceVerificationStatusHistory(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setInvoiceVerificationStatusHistory([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const createAdvance = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await dealInvoicesService.createAdvance(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
  fetchListAgainstPersonID,
  fetchListAgainstCT,
  FetchPlatformLedger,
  getLinkedDealDelivery,
  getInvoiceVerificationStatusHistory,
  recordUnallocatedPayment,
  deleteUnallocatedPayment,
  fetchPlatformLedgerMetadata,
  createAdvance,
};

const selectors = {
  ...adapter.getSelectors((state) => state.dealInvoices),
};

export { actions, selectors };

export default slice.reducer;
