const old = '#1b62ab';
const primary = '#886bd8';
const secondary = '#3fa7aa';
const ternary = '#f37759';
const muiPrimary = '#3f50b5';

const getColor = (color: 'old' | 'primary' | 'muiPrimary' | 'secondary' | 'ternary' | string): string => {
  let newColor;
  switch (color) {
    case 'old':
      newColor = old;
      break;
    case 'muiPrimary':
      newColor = muiPrimary;
      break;
    case 'primary':
      newColor = primary;
      break;
    case 'secondary':
      newColor = secondary;
      break;
    case 'ternary':
      newColor = ternary;
      break;
    default:
      newColor = color;
  }
  return newColor;
};
export default getColor;
