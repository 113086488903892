import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const configurationSchema = yup.object().shape({
  vendor_id: yup.number().required('Please select counterparty').positive('Please select counterparty'),
});

export const signatorySchema = yup.object().shape({
  entity_id: yup.number().required('Please select company').positive('Please select company'),
  user_id: yup.number().required('Please select user').positive('Please select user'),
  // signature_image: yup.mixed().required('A file is required'),
  signature_image: yup.mixed().when('signature_type_enum', {
    is: 1,
    then: yup.mixed().required('A file is required'),
    otherwise: yup.mixed().optional(),
  }),
  signerid: yup.string().when('signature_type_enum', {
    is: 2,
    then: yup.string().required('Signature Id is required'),
    otherwise: yup.string().optional(),
  }),
});

export const packagingSchema = yup.object().shape({
  company_id: yup.number().required('Please select company').positive('Please select company'),
  location_id: yup.number().required('Please select Location').positive('Please select Location'),
  commodity_type_code: yup.string().required('Commodity form is required.'),
  packaging_id: yup.number().required('Please Packaging').positive('Please Packaging'),
  loading_charge_value: yup.number().min(0, 'Invalid Amount'),
  unloading_charge_value: yup.number().min(0, 'Invalid Amount'),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    await helpers.validateSchema(configurationSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createPackaging = async (params) => {
  try {
    await helpers.validateSchema(packagingSchema, params);
    const res = await api.createPackaging(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllVendorsList = async (params) => {
  try {
    let res = await api.fetchAllVendorsList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllWarehouseDetails = async () => {
  try {
    let res = await api.fetchAllWarehouseDetails();
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchEquipmentData = async (id, type) => {
  try {
    let res = await api.fetchEquipmentData(id, type);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (params) => {
  try {
    let res = await api.destroy(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createSignatory = async (params) => {
  try {
    await helpers.validateSchema(signatorySchema, params);
    const res = await api.createSignatory(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteSignatory = async (id) => {
  try {
    let res = await api.deleteSignatory(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllAutorizedSignatory = async (params) => {
  try {
    let res = await api.fetchAllAutorizedSignatory(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchAllLoadingUnloading = async (params) => {
  try {
    let res = await api.fetchAllLoadingUnloading(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCompanyList = async () => {
  try {
    let res = await api.fetchCompanyList();
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchUserList = async () => {
  try {
    let res = await api.fetchUserList();
    return res;
  } catch (err) {
    throw err;
  }
};
export const deletePackaging = async (id) => {
  try {
    let res = await api.deletePackaging(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const invoiceConfiguration = async (params) => {
  try {
    let res = await api.invoiceConfiguration(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdateZohoAccountCreds = async (params) => {
  try {
    let res = await api.saveAndUpdateZohoAccountCreds(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTokenForZohoAccount = async (params) => {
  try {
    let res = await api.createTokenForZohoAccount(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchZohoAccountCreds = async (params) => {
  try {
    let res = await api.fetchZohoAccountCreds(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchZohoInvoiceLog = async (params) => {
  try {
    let res = await api.fetchZohoInvoiceLog(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchZohoPaymentLog = async (params) => {
  try {
    let res = await api.fetchZohoPaymentLog(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchGPAAcceptance = async (params) => {
  try {
    let res = await api.fetchGPAAcceptance(params);
    return res;
  } catch (err) {
    throw err;
  }
};
