import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import utils from 'lib/utils';

const counterBidOfferSchema = yup.object().shape({
  quantity: yup.number().required('Quantity is required.'),
  price: yup.number().required('Price is required.'),
  // commodity_req_offer_response_multiparty_auction_id: yup.number().required(),
});

const saveAndUpdateBidOfferSchema = yup.object().shape({
  operation_Enum: yup.number().required('operation_Enum is required.'),
  // commodity_req_offer_multiparty_auction_id: yup
  //   .number()
  //   .required('commodity_req_offer_multiparty_auction_id is required.'),
  qty: yup.number().required('Quantity is required.'),
  price: yup.number().required('Price is required.'),
  vehicle_type_id: yup.number().required('vehicle_type_id is required.'),
});

export const SaveAndUpdateMultipartyAuction = async (params = {}) => {
  try {
    let res = await api.SaveAndUpdateMultipartyAuction(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const DeleteMultipartyAuctionBuySell = async (id) => {
  try {
    let res = await api.DeleteMultipartyAuctionBuySell(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchAllMultipartyAuctions = async (params = {}) => {
  try {
    let res = await api.FetchAllMultipartyAuctions(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchMultipartyAuctionOverview = async (id) => {
  try {
    let res = await api.FetchMultipartyAuctionOverview(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const SaveAndUpdateBuySellAgainstMultipartyAuction = async (params = {}) => {
  try {
    let res = await api.SaveAndUpdateBuySellAgainstMultipartyAuction(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchMultipartyLandingPagePositions = async (params = {}) => {
  try {
    let res = await api.fetchMultipartyLandingPagePositions(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchMultipartyLandingPagePositionsMetadata = async (params = {}) => {
  try {
    let res = await api.fetchMultipartyLandingPagePositionsMetadata(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchBidOffersAgainstBuySell = async (params = {}) => {
  try {
    let res = await api.fetchBidOffersAgainstBuySell(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const pinUnpinBuySellBidOffer = async (params = {}) => {
  try {
    let res = await api.pinUnpinBuySellBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawBidOffer = async (params = {}) => {
  try {
    let res = await api.withdrawBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawMultipartyAuctionBuySell = async (params = {}) => {
  try {
    let res = await api.withdrawMultipartyAuctionBuySell(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const counterBidOffer = async (params = {}) => {
  try {
    await utils.validateSchema(counterBidOfferSchema, params);
    let res = await api.counterBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveAndUpdateBidOffer = async (params = {}) => {
  try {
    await utils.validateSchema(saveAndUpdateBidOfferSchema, params);
    let res = await api.saveAndUpdateBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const acceptMultipartyOfferBid = async (params = {}) => {
  try {
    let res = await api.acceptMultipartyOfferBid(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const rejectBidOffer = async (params = {}) => {
  try {
    let res = await api.rejectBidOffer(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchMultipartyLandingPagePositionsSpectator = async (params = {}) => {
  try {
    let res = await api.fetchMultipartyLandingPagePositionsSpectator(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchMultipartyLandingPagePositionsCT = async (params = {}) => {
  try {
    let res = await api.fetchMultipartyLandingPagePositionsCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetLatestCounterAgainstOfferBidId = async (params = {}) => {
  try {
    let res = await api.GetLatestCounterAgainstOfferBidId(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchRivals = async (params = {}) => {
  try {
    let res = await api.FetchRivals(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const DeleteMyBuySells = async (params = {}) => {
  try {
    let res = await api.DeleteMyBuySells(params);
    return res;
  } catch (err) {
    throw err;
  }
};
