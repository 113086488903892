import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params = {}) {
    // const qsParams = {
    //   ...params,
    //   person_id: params['person_id'] || _.get(window, 'user.userid', 0),
    // };
    let qsParams = {};
    if (!_.isEmpty(params)) {
      const storeState = globalThis.store.getState();
      if (params['person_id'] !== _.get(window, 'user.userid', 0)) {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(storeState, 'kycUser.profile.person.person_id'),
        };
      } else {
        qsParams = {
          ...params,
          person_id: params['person_id'] || _.get(window, 'user.userid', 0),
        };
      }
    } else {
      qsParams = {
        ...params,
        person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      };
    }
    return utils.getQs(qsParams);
  },
  fetchDetails(id) {
    return id;
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 1,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  update(id, params) {
    let bodyParams = {
      ...params,
      person_id: params['person_id'] || _.get(window, 'user.userid', 0),
      operation_Enum: 3,
      company_id: _.get(window, 'user.profile.person.company_id', 0),
      affiliate_code: params['affiliate_name'] || '',
    };
    return bodyParams;
  },
  destroy(id) {
    return id;
  },
};

export const resTransform = {
  fetchList(resData) {
    let affiliateData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(affiliateData, 'company_affiliate_id');
  },
  fetchDetails(resData) {
    return {
      // company_affiliate_id: _.get(resData, 'company_affiliate_id') || '',
      // affiliate_code: _.get(resData, 'affiliate_Code') || '',
      // affiliate_name: _.get(resData, 'affiliate_name') || '',
      // affiliate_relation_enum: _.get(resData, 'affiliate_relation_enum') || '',
      // affiliate_relation_enum_code: _.get(resData, 'affiliate_relation_enum_code') || '',
      // bf_setup_company_address: _.get(resData, 'bf_setup_company_address') || '',
      // company_id: _.get(resData, 'company_id') || '',
      // display_name: _.get(resData, 'display_name') || '',
      // business_type_enum_code: _.get(resData, 'business_type_enum_code') || '',
      // business_type_enum: _.get(resData, 'business_type_enum') || '',
      ...resData,
    };
  },
  create(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  update(resData) {
    return resData;
    // return resTransform.fetchDetails(details);
  },
  destroy(resData) {
    return resData;
    // return res;
  },
};
