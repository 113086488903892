import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';

import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';

const ModalComponent = ({
  color,
  btnName,
  btnColor,
  onConfirm,
  title,
  message,
  colored,
  header,
  isLink,
  extendedProfileButton,
  confirmModalOkBtnText,
  confirmModalCancelBtnText,
  isOpen,
  btnStyles,
  setWithdrawPage,
  showCancel = true,
  disabled = false
}) => {
  const [modal, setModal] = useState(isOpen);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const onClose = () => {
    setModal(false);
    setWithdrawPage && setWithdrawPage(false);
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      {!showCancel ? (
        ''
      ) : isLink ? (
        <AntButton type="link" style={btnStyles ? btnStyles : { padding: '5px 12px', height: 0 }} onClick={toggle} >
          {btnName}
        </AntButton>
      ) : (
        <Button
          disabled={disabled}
          color={btnColor}
          onClick={toggle}
          className={extendedProfileButton ? 'btn btn-secondary btn-sm extended-edit-btn' : ''}>
          {btnName}
        </Button>
      )}

      <Modal
        isOpen={modal}
        style={{ zIndex: 1500 }}
        toggle={onClose}
        modalClassName="ltr-support theme-light"
        className={`modal-dialog--${color} ${modalClass}`}>
        <div className="modal__header" style={{ borderRadius: '15px 15px 0px 0px' }}>
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
          {header ? '' : Icon}
          <h4 className="text-modal modal__title text-white text-center">{title}</h4>
        </div>
        <div className="modal__body text-center">{message}</div>
        <ButtonToolbar className="modal__footer pr-30 pl-30 mb-30">
          {showCancel && (
            <Button className="modal_cancel btn-sm w-100" onClick={onClose}>
              {confirmModalCancelBtnText ? confirmModalCancelBtnText : 'Cancel'}
            </Button>
          )}

          <Button className="modal_ok btn-sm w-100" outline={colored} color={color} onClick={() => onConfirm(toggle)}>
            {confirmModalOkBtnText ? confirmModalOkBtnText : 'Ok'}
          </Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btnName: PropTypes.string.isRequired,
  btnColor: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ModalComponent.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default ModalComponent;
