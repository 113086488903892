import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/finance_invoice.svg';

function FinanceInvoiceIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default FinanceInvoiceIcon;
