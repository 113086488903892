import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchList = async (params) => {
  try {
    let url;
    if (params?.person_id) {
      const qs = utils.getQs(params);
      url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchAllStorages?${qs}`;
    } else {
      url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchAllStorages`;
    }
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('FetchAllStorages-fetchList->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchAllStorages');
    throw errors;
  }
};

export const fetchDetailsList = async (params) => {
  try {
    const qs = reqTransform.fetchDetailsList(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStorageLevelDetailsAgainstStorageId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchList(resData);
  } catch (err) {
    console.log('api-FetchStorageLevelDetailsAgainstStorageId->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchStorageLevelDetailsAgainstStorageId');
    throw errors;
  }
};

export const getStoragePlans = async (params) => {
  try {
    // const qs = reqTransform.fetchDetailsList(params);
    // const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStoragePlan?${qs}`;
    const url = `${baseUrl}/BioFuelRequirementOffers/api/StoragePlan/FetchAllStoragePlan`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchStoragePlan');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/UpdateStorageLevelCapacity`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.create(resData);
  } catch (err) {
    console.log('UpdateStorageLevelCapacity-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateStorageLevelCapacity');
    throw errors;
  }
};

export const consumeProduceCreate = async (params) => {
  try {
    let bodyParams = reqTransform.consumeProduceCreate(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/SaveStorageTransferChanges`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.consumeProduceCreate(resData);
  } catch (err) {
    console.log('SaveStorageTransferChanges-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveStorageTransferChanges');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Deal/UpdateScheduleAgainstCommodityDealMasterId_ELOC`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.update(resData);
  } catch (err) {
    console.log('UpdateScheduleAgainstCommodityDealMasterId-update->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateScheduleAgainstCommodityDealMasterId_ELOC');
    throw errors;
  }
};

export const fetchStorageLevels = async (params = {}) => {
  try {
    let bodyParams = reqTransform.fetchStorageLevels(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStorageLevelsAgainstStorageId`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchStorageLevels(resData);
  } catch (err) {
    console.log('FetchStorageLevelsAgainstStorageId-fetchList->err---->', err);

    const errors = await utils.processApiErrors(err, '', 'FetchStorageLevelsAgainstStorageId');
    throw errors;
  }
};

export const consumeProduceUpdate = async (params) => {
  try {
    let bodyParams = reqTransform.consumeProduceCreate(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/EditStorageLevelTransferChanges`;
    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.consumeProduceCreate(resData);
  } catch (err) {
    console.log('EditStorageLevelTransferChanges-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'EditStorageLevelTransferChanges');
    throw errors;
  }
};

export const consumeProduceDelete = async (params) => {
  try {
    const qs = reqTransform.consumeProduceDelete(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/DeleteStorageLevelTransferChanges?${qs}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.consumeProduceDelete(resData);
  } catch (err) {
    console.log('api-DeleteStorageLevelTransferChanges->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteStorageLevelTransferChanges');
    throw errors;
  }
};

export const saveUpdateStoragePlan = async (params) => {
  try {
    // const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/SaveUpdateStoragePlan`;
    const url = `${baseUrl}/BioFuelRequirementOffers/api/StoragePlan/SaveUpdateStoragePlanMaster`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.consumeProduceDelete(resData);
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateStoragePlan');
    throw errors;
  }
};

export const deleteStoragePlan = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/StoragePlan/DeleteStoragePlan?Storage_Plan_Master_Id=${params}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return true;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteStoragePlan');
    throw errors;
  }
};

export const deleteStoragePlanDetail = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/StoragePlan/DeleteStoragePlanDetail?Storage_Plan_Detail_Id=${params}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return true;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteStoragePlanDetail');
    throw errors;
  }
};

export const stockTransferQuick = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/StockTransferQuick`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return true;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'StockTransferQuick');
    throw errors;
  }
};

export const fetchStockTransfers = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStockTransfers`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchStockTransfers');
    throw errors;
  }
};

export const stockTransferWithTransport = async (params) => {
  try {
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/StockTransferWithTransport`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'StockTransferWithTransport');
    throw errors;
  }
};

export const fetchTransportationChargeForST = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchTransportationChargeForST?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchTransportationChargeForST');
    throw errors;
  }
};

export const fetchQualitySpecsAgainstStockTransferId = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchQualitySpecsAgainstStockTransferId?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchQualitySpecsAgainstStockTransferId');
    throw errors;
  }
};

export const fetchStockAgainstAddressId = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/FetchStockAgainstAddressId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'FetchStockAgainstAddressId');
    throw errors;
  }
};

export const deleteQuickStockTransfer = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/DeleteQuickStockTransfer?${qs}`;
    const res = await utils.fetch(url, { method: 'PUT' }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteQuickStockTransfer');
    throw errors;
  }
};

export const deleteStockTransferWithTransport = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelRequirementOffers/api/Storage/DeleteStockTransferWithTransport?${qs}`;
    const res = await utils.fetch(url, { method: 'PUT' }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'DeleteStockTransferWithTransport');
    throw errors;
  }
};
