import _ from 'lodash';
import React from 'react';
import { getIn } from 'formik';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from 'lib/api';
import { actions as refDataActions, selectors as refDataSelect } from 'redux/referenceData';

const dataCache = {};

function ReferenceDataSelect(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [tries, setTries] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [preload, setPreloadDone] = React.useState(true);

  const {
    field,
    form: { touched, errors, values },
    label,
    children,
    select,
    entity,
    initialDisplayText,
    qparams,
    disabled,
    dependentFields,
    variant,
    entity_id,
    vehicle_type_enum,
    // placeholder,
    installedCapacityPeriodicityEnum,
    isSmartBuyer,
    ...rest
  } = props;

  if (entity === undefined) {
    throw new Error('entity prop must be defined');
  }
  // console.log('====================')
  // console.log('entity-->', entity)
  // console.log('disabled-->', disabled)
  // console.log('!!disabled-->', !!disabled)
  // console.log('qparams-->', qparams)
  // console.log('isEmpty(qparams)->', _.isEmpty(qparams))
  // console.log('\n====================')
  let finalOptions = [];
  let entityData = useSelector((state) => {
    // debugger;
    if (!_.isEmpty(qparams)) {
      const qId = Object.values(qparams)[0];
      return qId > 0 ? state.referenceData.data[entity][qId] || [] : [];
    }
    let found;
    if (installedCapacityPeriodicityEnum) {
      found = (state.referenceData.data[entity] || []).filter((item) => item.displaymember !== 'Once');
    } else {
      found = state.referenceData.data[entity];
    }

    console.log('found', found);
    return Array.isArray(found) ? found : [];
  });

  if(entity == 'vehicleType' && entityData?.length){
    if(vehicle_type_enum && vehicle_type_enum === 2) {
      entityData = entityData?.filter(item => item?.vehicle_type_code?.includes('KL'))
    } else {
      entityData = entityData?.filter(item => !item?.vehicle_type_code?.includes('KL'))
    }
    entityData = entityData?.map(item => ({...item, displaymember: item?.vehicle_type_code, valuemember: item?.vehicle_type_id}))
  }

  if(props?.isPlatformChargeDataSetup) {
    entityData = entityData?.filter(item => item?.group_plan_id)
  }

  const loading = open && entityData && entityData.length === 0;

  const valuemember = _.get(values, field.name, 0);
  // if(field.name.includes('commodity_Req_Offer_Quality_Spec[0].commodity_Spec_Id')){
  //   debugger;
  // }
  let selectedValue = entityData.find((e) => e.valuemember === valuemember);

  if (!selectedValue) {
    selectedValue = { valuemember, displaymember: valuemember ? initialDisplayText : '' };
  }

  React.useEffect(() => {
    let active = true;

    const loadOptions = async () => {
      // console.log('dataCache-->', dataCache);

      if (!entityData.length) {
        try {
          if (!_.isEmpty(qparams)) {
            dispatch(refDataActions.getCommodityDropdownData(entity, qparams));
          } else if ((entity || '').toLowerCase().includes('enum')) {
            dispatch(refDataActions.getEnumData(entity)); // as a "enumgroup"
          } else {
            dispatch(refDataActions.getReferenceData(entity));
          }

          dataCache[entity] = entityData;
          setOptions(entityData);
        } catch (err) {
          console.log('tries-->', tries);
          console.log('getCompanies error', err);

          if (tries < 3) {
            setTries(tries + 1);
          }
        }
      }

      if (active) {
        setOptions(entityData);
      }
    };

    if (loading) {
      loadOptions();
      return undefined;
    }

    if (preload === true) {
      loadOptions();
      setPreloadDone(false);
    }

    return () => {
      active = false;
    };
  }, [loading, tries, qparams]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  if ((entity || '').toLowerCase().includes('enum') || (entity || '').toLowerCase().includes('typeofnote')) {
    finalOptions = _.orderBy(entityData, ['sequence']);
  } else {
    finalOptions = _.orderBy(entityData, ['display_member']);
  }
  if(entity == 'vehicleType'){
    finalOptions = _.orderBy(entityData, ['maximum_capacity']);
  }

  if(window.location.href.includes('/controltower/data-setup/commodity-details')) {
    finalOptions = finalOptions?.filter(item => item.displaymember !== 'English')
  }

  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  // className={`${hasError ? 'has-error' : ''} bfc-ref-data-select-wrapper`}
  return (
    <div className={`refrence-data-select`}>
      <Autocomplete
        value={selectedValue}
        id={`${entity}-autocomplete-${timestamp}`}
        defaultValue={initialDisplayText || ''}
        disabled={disabled}
        disableClearable
        fullWidth
        size="small"
        // autoHighlight
        // style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(o, value) => {
          if (o?.displaymember && value?.displaymember) {
            return o.displaymember.trim().toLowerCase() === value.displaymember.trim().toLowerCase();
          }

          return false;
        }}
        getOptionLabel={(o) => {
          return o?.displaymember || '';
        }}
        // options={entity === 'ReasonTypeEnum' ? _.orderBy(entityData, ['sequence']) : _.orderBy(entityData, ['displaymember'])}
        options={finalOptions}
        loading={loading}
        onChange={(event, newValue) => {
          if (props.cb) {
            props.cb(newValue);
          }
          if(newValue.valueMember == 2 && isSmartBuyer && entity === "PlatformChargeAtEnum"){
            props.form.setFieldValue('platform_charge_at_enum_code', newValue.displaymember);
          }
          let entity_id = newValue?.valueMember;
          const d = entityData.find((c) => c.displaymember === newValue.displaymember);
          if (d && d.valuemember) {
            entity_id = d.valuemember;
            // if(utils.isNumber(entity_id)){
            //   entity_id = parseInt(entity_id)
            // }
          }

          props.form.setFieldValue(field.name, entity_id);

          if (props.onCallback) {
            props.onCallback(entity_id);
          }

          if (dependentFields && dependentFields.length) {
            for (let df of dependentFields) {
              props.form.setFieldValue(df, '');
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant || 'outlined'}
            // {...field}
            className="material-form__field"
            label={label}
            error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
            children={children}
            helperText={getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : ''}
            FormHelperTextProps={{ classes: { error: 'form__form-group-error' } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default ReferenceDataSelect;
