import _ from 'lodash';
import config from 'config';
let baseUrl = config.baseUrl;
import storage from 'lib/storage';
import helpers from 'services/helpers';
import crossFetch from 'cross-fetch';
import normalize from 'normalize-object';
import { actions as userActions } from 'redux/user';
import dayjs from 'dayjs';
import { decryption, encryption } from 'lib/utils';
const { isApiEncryptionEnabled } = config;

const inMemoryJWTManager = () => {
  let inMemoryJWT = config.REACT_APP_JWT || null;
  const requestHeaderAccessToken = config.requestHeaderAccessToken || null;
  let isRefreshing = null;

  let logoutEventName = 'ra-logout';
  // let refreshEndpoint = '/refresh-token';
  let refreshEndpoint = `${baseUrl}/BioFuelOAuthServer/Users/refresh-token`;
  let refreshTimeOutId;
  // const jwt = inMemoryJWTManager;

  const setLogoutEventName = (name) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const setRefreshTokenTimer = (delay) => {
    refreshTimeOutId = setTimeout(
      getRefreshedToken,
      (delay - 60) * 1000,
      // 15 * 60 * 1000 - 5000,
    ); // Validity period of the token in seconds, minus 5 seconds
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  // The method make a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
  const getRefreshedToken = async () => {
    if (window.getRefreshedTokenExecuting) {
      console.log('BFC-Function getRefreshedToken is already executing, aborting...');
      return 'REQUEST_ABORTED'; // Abort the function
    }

    // Set the flag to true to indicate that the function is executing
    window.getRefreshedTokenExecuting = true;

    // Your function logic goes here
    console.log('BFC-Function getRefreshedToken started executing...');

    let refreshToken = await storage.getItem('refreshToken');
    let pid = await storage.getItem('pid');

    if (!refreshToken) {
      ereaseToken();
      return false;
    }

    // Simulating asynchronous task
    setTimeout(function () {
      console.log('Allowing BFC-Function getRefreshedToken to execute for next request.');
      // Reset the flag after the function has completed
      window.getRefreshedTokenExecuting = false;
    }, 2000);

    try {
      isRefreshing = doFetch(refreshEndpoint, {
        method: 'POST',
        body: JSON.stringify({
          refresh_Token: refreshToken,
          // login_Status_Id: 0,
          // role_Id: 0,
          // person_id: parseInt(pid),
        }),
      }).ready;

      let res = await isRefreshing;

      console.log('BFC-Function getRefreshedToken api called');
      const resData = await processApiRes(res);
      //@TODO : set Autharization header in utils
      const token = _.get(resData, 'jwt_token', '');
      const tokenExpiry = _.get(resData, 'jwt_token_expire_datetime');

      refreshToken = _.get(resData, 'refresh_token', '');

      const personId = _.get(resData, 'person_id', 0);

      if (token) {
        await setToken(token, tokenExpiry);
        await setRefreshToken(refreshToken);
        return true;
      }

      ereaseToken();
      return false;
    } catch (err) {
      console.log('errr------->', err);

      const errors = await processApiErrors(err);
      // TODO : review - should throw errors?
      ereaseToken();
      return false;
      // return { token: null };
    }
  };

  const getToken = () => inMemoryJWT;

  const checkTokens = async () => {
    let accessToken = await storage.getItem('accessToken');
    if (!accessToken) {
      ereaseToken();
      return false;
    }

    let accessTokenExpiry = await storage.getItem('accessTokenExpiry');

    if (!accessTokenExpiry) {
      ereaseToken();
      return false;
    }

    let refreshToken = await storage.getItem('refreshToken');

    if (!refreshToken) {
      ereaseToken();
      return false;
    }

    await setToken(accessToken, accessTokenExpiry);

    const pid = await storage.getItem('pid');
    const sid = await storage.getItem('sid');

    return {
      person_id: pid,
      social_profile_id: sid,
    };
  };

  const setToken = async (accessToken, accessTokenExpiry) => {
    inMemoryJWT = accessToken;
    globalThis.accessToken = accessToken;
    await storage.setItem('accessToken', accessToken);
    await storage.setItem('accessTokenExpiry', accessTokenExpiry);
    let accessTokenDateTime = new Date(accessTokenExpiry);
    let currentDateTime = new Date();
    let difference = parseInt((accessTokenDateTime.getTime() - currentDateTime.getTime()) / 1000);
    // const delay =  dayjs(accessTokenExpiry).diff(dayjs());
    setRefreshTokenTimer(difference);

    // Trigger an event to signal other tabs to refresh
    const broadcastChannel = new BroadcastChannel('authChannel');
    broadcastChannel.postMessage('loggedIn');
    broadcastChannel.close();

    return true;
  };

  const setRefreshToken = async (refreshToken) => {
    await storage.setItem('refreshToken', refreshToken);

    // inMemoryJWT = token;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // refreshToken(delay);
    return true;
  };

  const ereaseToken = async () => {
    inMemoryJWT = null;

    abortRefreshToken();
    await storage.removeItem('accessToken');
    await storage.removeItem('accessTokenExpiry');
    await storage.removeItem('refreshToken');
    await storage.removeItem('persist:root');
    await storage.removeItem('pid');
    await storage.removeItem('sid');
    // await storage.setItem(logoutEventName, Date.now());

    return true;
  };

  const ereaseAccessToken = async () => {
    inMemoryJWT = null;

    abortRefreshToken();
    await storage.removeItem('accessToken');

    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  // addEventListener('storage', (event) => {
  //   if (event.key === logoutEventName) {
  //     inMemoryJWT = null;
  //   }
  // });

  const doFetch = (request, opts = {}, isJson = true) => {
    const controller = new AbortController();

    const { signal } = controller;

    let token = getToken() || '';

    opts = {
      method: 'GET',
      headers: {
        Authorization: token,
      },
      // credentials: 'include',
      ...opts,
    };

    if (isJson) {
      opts.headers['Content-Type'] = 'application/json';
    }

    if (
      request?.includes('BioFuelOAuthServer/Users/login') ||
      request?.includes('BioFuelOAuthServer/Users/verify-login') ||
      request?.includes('BioFuelOAuthServer/Users/refresh-token')
    ) {
      opts.headers['AccessToken'] = requestHeaderAccessToken;
    }

    const getRequest = async (req, opts) => {
      const reqString = opts?.['body'];
      let encryptedBody = encryption(reqString);
      return crossFetch(req, { ...opts, body: encryptedBody, signal });
    };
    let urlWithEncryptedQs;
    if (!opts?.['body']) {
      const parsedUrl = new URL(request);
      if (parsedUrl.search) {
        const queryParams = parsedUrl.search.split('?')[1];
        parsedUrl.search = '';
        const isAlreadyEncrypted = decryption(queryParams);
        urlWithEncryptedQs = isAlreadyEncrypted ? request : `${parsedUrl.toString()}?${queryParams}`;
      }
    }

    const req = {
      abort: () => controller.abort(),
      ready:
        isApiEncryptionEnabled === 'true'
          ? opts?.['body']
            ? getRequest(request, { ...opts, signal })
            : crossFetch(urlWithEncryptedQs || request, { ...opts, signal })
          : crossFetch(request, { ...opts, signal }),
    };

    // const req = {
    //   abort: () => controller.abort(),
    //   ready: crossFetch(request, { ...opts, signal }),
    // };

    setTimeout(() => {
      req.abort();
    }, 30 * 1000);

    return req;
  };

  const getResJSON = async (res) => {
    // debugger;
    if (!res.ok) {
      throw res;
    }

    if (res.status < 200 || res.status >= 300) {
      throw res;
    }

    let jsonRes = res;

    //incase we send response from othr than
    //fectch for processing e.g. mock response
    if (typeof res.json === 'function') {
      jsonRes = await res.json();
    }

    if (_.get(jsonRes, 'stat', '') === false) {
      throw jsonRes;
    }

    return jsonRes;
  };

  const processApiRes = async (res = {}) => {
    // const resJSON = await getResJSON(res);
    let resJSON = res;
    resJSON = isApiEncryptionEnabled === 'true' ? await res.text() : await res.json();
    if (isApiEncryptionEnabled === 'true') {
      let decryptedData = decryption(resJSON);
      resJSON = JSON.parse(decryptedData);
      if (!resJSON?.stat) {
        throw resJSON;
      }
    }

    const resData = _.get(resJSON, 'data', {}) || {};
    let snakeRes = normalize(resData, 'snake');
    return snakeRes;
  };

  const processApiErrors = async (err = {}) => {
    const errData = {
      errors: {},
    };

    const message = _.get(err, 'message', '');
    if (message === 'Network request failed') {
      errData.errors = {};
      errData.message = 'Unable to process request. please check your internet connection';
      return errData;
    }

    if (err.status === 500) {
      errData.errors = {
        500: 'Internal Server Error',
      };
      errData.message = '500 - Internal Server Error';
      return errData;
    }

    let body = {};
    try {
      body = await err.json();
      const backendFieldErrors = _.get(body, 'data') || _.get(body, 'errors') || {};
      const errMessage = _.get(body, 'message') || 'Error Processing Request';
      // errors.message = _.get(body, 'data[0]', '');

      const frontendFieldErrors = normalize(backendFieldErrors, 'snake');

      errData.errors = {
        ...frontendFieldErrors,
        // ...backendFieldErrors,
      };
      errData['message'] = errMessage || 'Unable to process request.';
    } catch (err) {
      let statusText = _.get(err, 'statusText') || '';
      const message = _.get(body, 'message') || '';
      errData['message'] = message ? message : statusText ? statusText : 'Unable to process request.';
    }

    return errData;
  };

  return {
    ereaseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh,
    setRefreshToken,
    checkTokens,
    ereaseAccessToken,
  };
};

export default inMemoryJWTManager();
