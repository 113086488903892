import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Space, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

function DateSearchFilter({ dataIndex, data, onFilterChange, handleReset, value }) {
  const { confirm, clearFilters, setSelectedKeys, selectedKeys } = data;
  const [searchValue, setSearchValue] = useState(dayjs(value).format('YYYY-MM-DD'));
  return (
    <div style={{ padding: 8 }}>
      <DatePicker
        onChange={(date, dateString) => {
          setSearchValue(dateString ? dayjs(dateString).format('YYYY-MM-DD') : dayjs(undefined).format('YYYY-MM-DD'));
          onFilterChange(dateString, dataIndex, confirm);
        }}
        value={moment(searchValue, 'YYYY-MM-DD')}
      />
      <Space>
        <Button
          onClick={(e) => {
            setSearchValue(dayjs(undefined).format('YYYY-MM-DD'));
            handleReset(clearFilters, dataIndex);
          }}
          size="small"
          style={{ width: 70, marginLeft: '5px' }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}

export default DateSearchFilter;
