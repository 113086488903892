import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const PostSchema = yup.object().shape({
  text: yup
    .string()
    .test({
      name: 'text',
      exclusive: true,
      message: 'Post cannot be empty.Please enter text',
      test: (value) => value != '<p></p>\n',
    })
    .required('Please enter text'),
});

export const EditPostSchema = yup.object().shape({
  text: yup
    .string()
    .test({
      name: 'text',
      exclusive: true,
      message: 'Post cannot be empty.Please enter text',
      test: (value) => value != '<p></p>\n',
    })
    .required('Please enter text'),
});

export const commentSchema = yup.object().shape({
  comment: yup.string().required('Please enter comment'),
  post_id: yup.number().positive().required(),
});

export const createPostSchema = yup.object().shape({
  text: yup.string().required('Please enter text'),
});

export const sharePostSchema = yup.object().shape({
  post_caption: yup.string().required('Please enter text'),
});

export const searchPostSchema = yup.object().shape({
  search_text: yup.string().required('Please enter search text'),
});

export const create = async (params) => {
  try {
    if (_.isEmpty(params.images) && _.isEmpty(params.pdf_Doc)) {
      await helpers.validateSchema(PostSchema, params);
    }
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const sharePost = async (params) => {
  try {
    params.post_caption = params.post_caption.trim();
    // await helpers.validateSchema(sharePostSchema, params);
    const res = await api.sharePost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (params) => {
  try {
    // if (_.isEmpty(params.images) && _.isEmpty(params.pdf_Doc)) {
    //   await helpers.validateSchema(EditPostSchema, params);
    // }
    if (params.text == '<p></p>\n' && (_.isEmpty(params.images) || _.isEmpty(params.pdf_doc))) {
      await helpers.validateSchema(PostSchema, params);
    }
    const res = await api.update(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const savePost = async (post_id) => {
  try {
    const res = await api.savePost(post_id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchReportedPosts = async (params = {}) => {
  try {
    let res = await api.fetchReportedPosts(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteReportedPost = async (params) => {
  try {
    let res = await api.deleteReportedPost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const markPostAsSafe = async (params) => {
  try {
    let res = await api.markPostAsSafe(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchReportedComments = async (params = {}) => {
  try {
    let res = await api.fetchReportedComments(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteReportedComment = async (params) => {
  try {
    let res = await api.deleteReportedComment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const markCommentAsSafe = async (params) => {
  try {
    let res = await api.markCommentAsSafe(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFeed = async (params = {}) => {
  try {
    let res = await api.fetchAllFeed(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllFeedWithFilters = async (params = {}) => {
  try {
    let res = await api.fetchAllFeedWithFilters(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchAllCTFeed = async (params = {}) => {
  try {
    let res = await api.fetchAllCTFeed(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPostComments = async (params) => {
  try {
    let res = await api.fetchPostComments(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createPostComment = async (params) => {
  try {
    params.comment = params.comment.trim();
    await helpers.validateSchema(commentSchema, params);
    const res = await api.createPostComment(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const editSavedComment = async (params) => {
  try {
    let res = await api.editSavedComment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchTimelinePost = async (params = {}) => {
  try {
    let res = await api.fetchTimelinePost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchSavedPosts = async (params = {}) => {
  try {
    let res = await api.fetchSavedPosts(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteSavedPost = async (post_id) => {
  try {
    let res = await api.deleteSavedPost(post_id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteSavedComment = async (params) => {
  try {
    let res = await api.deleteSavedComment(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const reportPostOrComment = async (params) => {
  try {
    let res = await api.reportPostOrComment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const followUnfollowUser = async (pid, isFollow) => {
  try {
    let res = await api.followUnfollowUser(pid, isFollow);
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchPost = async (params) => {
  try {
    // await helpers.validateSchema(searchPostSchema, params);
    const res = await api.searchPost(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUserFollowersFollowings = async (params) => {
  try {
    let res = await api.fetchUserFollowersFollowings(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUserMention = async (params) => {
  try {
    let res = await api.fetchUserMention(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const fetchCompanyPersonList = async (params) => {
  try {
    let res = await api.fetchCompanyPersonList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchUserLikedPostList = async (params) => {
  try {
    let res = await api.fetchUserLikedPostList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPostDetails = async (params) => {
  try {
    let res = await api.fetchPostDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchSharePostDetails = async (params) => {
  try {
    let res = await api.fetchSharePostDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchBfcFeedPostList = async (params) => {
  try {
    let res = await api.fetchBfcFeedPostList(params);
    return res;
  } catch (err) {
    throw err;
  }
};
