import React, { useEffect, useState, shallowEqual, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Skeleton } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, WarningFilled } from '@ant-design/icons';

import OverviewHeader from 'common/OverviewHeader';
import AntPagination from 'common/AntTablePagination';
import { actions as earlyPaymentsActions } from 'redux/earlyPayments';
import useQuery from 'lib/hooks/useQuery';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import FilterUtils from 'lib/filterUtils';

import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import OverviewNav from 'common/OverviewNav';
import utils from 'lib/utils';
// import LogoLoader from 'common/LogoLoader';
import AntTableActions from 'common/TableActions';
import PopupForm from 'common/Form/PopupForm';
import { MenuItem, TextField, withStyles } from '@material-ui/core';
import { Button } from 'reactstrap';
import CommodityTypeFormSelect from 'pages//commodity/components/CommodityTypeFormSelect';
import { Field, Form, Formik } from 'formik';
import { actions as requirementsActions } from 'redux/requirements';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import RupeeIcon from 'mdi-react/RupeeIcon';
import DialogPopup from 'common/DialogPopUp';

const getInitialValues = (data) => {
  const formData = {
    commodity_form_code: data?.commodity_form_code || '',
    commodity_form_id: data?.commodity_form_master_id || 0,
    commodity_type_code: data?.commodity_type_code || '',
    commodity_type_id: data?.commodity_type_mater_id || 0,
    minimum_discount: data?.minimum_discount || '',
    notes: data?.notes || '',
  };
  return formData;
};

const MinimumDiscount = () => {
  const dispatch = useDispatch();
  const { page = 1 } = useQuery();
  const history = useHistory();
  const tableRef = useRef();
  let { commodity_type, commodity_form, modified_by, modified_on } = useQuery();
  const rentalState = useSelector((state) => state.earlyPayments);
  const equipmentData = Object.values(rentalState.entities);
  const priceUomData = useSelector((state) => state.requirements?.priceUomData, shallowEqual);
  const busy = _.get(rentalState, 'busy') || false;

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};

  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        delete params['delivery_status_enum'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_status_enum'));
        break;
      case 'early_payment_modified_by':
        delete params['modified_by'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('modified_by'));
        break;
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'delivery_allotment_status_enum_code':
        params = {
          ...params,
          delivery_status_enum: e,
        };
        break;
      case 'early_payment_modified_by':
        params = {
          ...params,
          modified_by: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      commodity_type,
      commodity_form,
      modified_by,
      modified_on,
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
    };
    const params = {
      filters: filterParams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(earlyPaymentsActions.fetchDiscountForEarlyPayment(params));
  }, [commodity_type, commodity_form, modified_by, modified_on, rowsPerPage, page, isReRenderRequired]);

  const back = utils.encodeBackUrl();

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };

  const tableData = equipmentData;
  const totalRecords = tableData?.[0]?.total_count;

  let links = [
    {
      to: `/control-tower/early-payment?page=1`,
      title: 'Tracking',
    },
    {
      to: `/control-tower/adjustment-factor?page=1`,
      title: 'Adj. Factor',
    },
    {
      to: `/control-tower/minimum-discount?page=1`,
      title: 'Min. Discount',
    },
  ];

  const UOMSelectDropdown = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'transparent',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'transparent',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
        '& .MuiSelect-select': {
          fontWeight: 'bold',
        },
        '& .Mui-disabled': {
          borderColor: 'transparent',
        },
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        padding: `0 !important`,
      },
    },
  })(TextField);

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <Formik initialValues={getInitialValues({ ...selectedRow })} onSubmit={async (values, formikBag) => { }}>
      {(formik) => {
        const { values, setFieldValue } = formik;

        const MINIMUM_DISCOUNT_TABLE_HEADERS = [
          {
            title: 'Commodity Type',
            dataIndex: 'commodity_type',
            key: 'commodity_type',
            width: '130px',
            align: 'left',
            fixed: true,
            render: (text, row) => (
              <div className="d-flex justify-content-start align-items-center">{row.commodity_type_code}</div>
            ),
            sorter: (a, b) =>
              a.commodity_type_code &&
              a.commodity_type_code.localeCompare(b.commodity_type_code && b.commodity_type_code),
            filterDropdown: (props) => (
              <AutoCompleteFilter
                dataIndex={'commodity_type'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={commodity_type}
                codeBaisedFilter={true}
                name={'Form'}
              />
            ),
            filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_type'),
            filteredValue: commodity_type !== undefined ? commodity_type : null,
          },
          {
            title: 'Form',
            dataIndex: 'commodity_form_code',
            key: 'commodity_form_code',
            width: '100px',
            align: 'left',
            fixed: true,
            render: (text, row) => <div className="text-left">{row.commodity_form_code}</div>,
            sorter: (a, b) =>
              a.commodity_form_code &&
              a.commodity_form_code.localeCompare(b.commodity_form_code && b.commodity_form_code),
            filterDropdown: (props) => (
              <AutoCompleteFilter
                dataIndex={'commodity_form'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={commodity_form}
                name={'Form'}
              />
            ),
            filterIcon: (filtered) => getFilteredIcon(filtered, 'commodity_form'),
            filteredValue: commodity_form !== undefined ? commodity_form : null,
          },
          {
            title: 'Min Disc. (per 30d)',
            dataIndex: 'minimum_discount',
            key: 'minimum_discount',
            width: '130px',
            align: 'right',
            ellipsis: true,
            sorter: (a, b) => a?.minimum_discount - b?.minimum_discount,
            render: (text, row) => <div className="text-right">{row.minimum_discount}</div>,
          },
          {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            width: '150px',
            align: 'left',
            ellipsis: true,
            render: (text, row) => (
              <div
                className="text-left"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                {row.notes}
              </div>
            ),
          },
          {
            title: 'Modified By',
            dataIndex: 'modified_by',
            key: 'modified_by',
            width: '170px',
            align: 'left',
            sorter: (a, b) => a.modified_by && a.modified_by.localeCompare(b.modified_by && b.modified_by),
            render: (text, row) => (
              <div className="d-flex justify-content-start align-items-center">{row.modified_by}</div>
            ),
            filterDropdown: (props) => (
              <AutoCompleteFilter
                dataIndex={'early_payment_modified_by'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={modified_by}
                name={'Status'}
                codeBaisedFilter={true}
              />
            ),
            filterIcon: (filtered) => getFilteredIcon(filtered, 'modified_by'),
            defaultFilteredValue: modified_by !== undefined ? modified_by : null,
          },
          {
            title: 'Modified On',
            dataIndex: 'modified_datetime',
            key: 'modified_datetime',
            width: '150px',
            ellipsis: true,
            align: 'center',
            sorter: (a, b) =>
              a.modified_datetime && a.modified_datetime.localeCompare(b.modified_datetime && b.modified_datetime),
            render: (text, row) => (
              <div className="bfc-table-list-components text-left d-flex justify-content-center">
                {row.modified_datetime ? dayjs(row.modified_datetime).format('DD-MMM-YYYY') : ''}
              </div>
            ),
            filterDropdown: (props) => (
              <RangeDateSearchFilter
                dataIndex={'modified_on'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={modified_on}
                name={'Status'}
              />
            ),
            filterIcon: (filtered) => getDateIcon(filtered, 'modified_on'),
            defaultFilteredValue: modified_on !== undefined ? modified_on : null,
          },
          {
            title: 'Action',
            dataIndex: 'equipment_address',
            key: 'equipment_address',
            width: '100px',
            ellipsis: true,
            align: 'center',
            render: (text, row) => (
              <div className="bfc-table-list-components text-center">
                {CT_parent && <AntTableActions
                  showFlag={true}
                  row={row}
                  controls={[
                    {
                      name: 'Edit',
                      onClick: async () => {
                        setEdit(true);
                        setSelectedRow(row);
                        if (row.commodity_type_master_id && row.commodity_form_master_id) {
                          const params = {
                            commodity_type_id: row.commodity_type_master_id,
                            commodity_form_id: row.commodity_form_master_id,
                            price_qty_enum: 1,
                          };
                          const success = await dispatch(requirementsActions.fetchUOMAgainstCommodityTypeForm(params));
                          if (success) {
                            setFieldValue('price_uom_id', success?.[0]?.uom_id);
                            setFieldValue('uom_code', success?.[0]?.uom_code);
                          }
                        }
                        setFieldValue('minimum_discount', row?.minimum_discount);
                        setFieldValue('commodity_form_id', row?.commodity_form_master_id);
                        setFieldValue('commodity_type_code', row?.commodity_type_code);
                        setFieldValue('commodity_type_id', row?.commodity_type_master_id);
                        setFieldValue('notes', row?.notes);
                        setFieldValue('price_uom_id', row?.price_uom_id);
                        setFieldValue('uom_code', row?.uom_code);
                        setIsReRenderRequired(!isReRenderRequired);
                      },
                      controlColor: 'var(--unnamed-color-1b62ab)',
                    },
                    {
                      name: 'Delete',
                      onClick: async () => {
                        setFieldValue('early_payment_discount_config_id', row?.early_payment_discount_config_id);
                        setIsDelete(true);
                      },
                      controlColor: '#ff4d4f',
                    },
                  ]}
                />}
              </div>
            ),
          },
        ];

        return (
          <Form>
            <OverviewHeader heading={`Early Payment`} />
            <OverviewNav
              links={links}
              rightPanContent={
                <Button className="btn-sm mr-2" onClick={() => setIsAdd(true)} disabled={!CT_parent}>
                  Add
                </Button>
              }
            />
            {/* {busy && (
              <div className="commodity-wrapper">
                <div className="panel__refresh">
                  <LogoLoader />
                </div>
              </div>
            )} */}
            <div className="delivery-table bfc-body">
              <div>
                <div className="ant-table-body ant-table">
                  <Table
                    ref={tableRef}
                    dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                    columns={busy ? MINIMUM_DISCOUNT_TABLE_HEADERS.map((column) => {
                      return {
                        ...column,
                        render: function renderPlaceholder() {
                          return (
                            <Skeleton
                              active="true"
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          );
                        },
                      };
                    }) : MINIMUM_DISCOUNT_TABLE_HEADERS}
                    pagination={false}
                    onChange={handlePageChange}
                    scroll={{ y: 510 }}
                    className="ant-table"
                    size="middle"
                  />
                  <AntPagination
                    total={parseInt(totalRecords)}
                    handlePageChange={handlePageChange}
                    page={page}
                    clearFilters={clearFilters}
                    rowsPerPage={rowsPerPage}
                    setScrollToFirstRow={setScrollToFirstRow}
                  />
                  {(isAdd || isEdit) && (
                    <PopupForm
                      title={isEdit ? 'Edit Minimum Discount' : 'Add Minimum Discount'}
                      onClose={() => {
                        setEdit(false);
                        setIsAdd(false);
                        formik.resetForm();
                      }}>
                      <div className="col mb-3 p-0">
                        <span className="form-group-label">Commodity Form</span>
                        <span class="required">*</span>
                        <div className="form-group-field">
                          <Field
                            name="commodity_type_code"
                            component={CommodityTypeFormSelect}
                            placeholder="Select commodity form"
                            entity="commodityTypeForm"
                            initialDisplayText={`${selectedRow?.commodity_type_code} - ${selectedRow?.commodity_form_code}`}
                            formik={formik}
                            cb={async (newValue) => {
                              setFieldValue('commodity_type_id', newValue?.commodity_type_master_id);
                              setFieldValue('commodity_form_id', newValue?.commodity_form_master_id);
                              setFieldValue('commodity_type_code', newValue?.commodity_type_code);
                              setFieldValue('commodity_form_code', newValue?.commodity_form_code);
                              if (newValue.commodity_type_id && newValue.commodity_form_id) {
                                const params = {
                                  commodity_type_id: newValue.commodity_type_id,
                                  commodity_form_id: newValue.commodity_form_id,
                                  price_qty_enum: 1,
                                };
                                const success = await dispatch(
                                  requirementsActions.fetchUOMAgainstCommodityTypeForm(params),
                                );
                                if (success) {
                                  setFieldValue('price_uom_id', success?.[0]?.uom_id);
                                  setFieldValue('uom_code', success?.[0]?.uom_code);
                                  setIsReRenderRequired(!isReRenderRequired);
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <span className="form-group-label">Minimum Discount (per 30 days)</span>
                        <div className="form-group-field with-extention">
                          <Field
                            name="minimum_discount"
                            component={FormikMaterialTextField}
                            type="number"
                            isAdornment={true}
                            position={'both'}
                            startAdornmentContent={<RupeeIcon />}
                            endAdornmentStyles={{ background: 'none', padding: priceUomData?.length ? 0 : '20px 10px' }}
                            endAdornmentContent={
                              priceUomData?.length ? (
                                <UOMSelectDropdown
                                  disabled={priceUomData?.length < 2}
                                  select
                                  variant="outlined"
                                  size="small"
                                  value={values?.price_uom_id}
                                  onChange={(e) => {
                                    setFieldValue('price_uom_id', e.target.value);
                                    setFieldValue(
                                      'price_uom_code',
                                      priceUomData?.find((item) => item.uom_id === parseInt(e.target.value)),
                                    );
                                  }}>
                                  {priceUomData?.map((option) => (
                                    <MenuItem key={option.uom_id} value={option?.uom_id}>
                                      {option?.uom_code}
                                    </MenuItem>
                                  ))}
                                </UOMSelectDropdown>
                              ) : (
                                ''
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col mb-3 p-0">
                        <span className="form-group-label">Note</span>
                        <div className="form-group-field">
                          <TextField
                            type="text"
                            size="small"
                            fullWidth
                            variant="outlined"
                            className="material-form__field"
                            multiline
                            maxRows={5}
                            value={values?.notes}
                            onChange={(e) => {
                              e.target.value?.length <= 100 && setFieldValue('notes', e.target.value);
                            }}
                          />
                          {/* <Field name="notes" type="text" component={FormikMaterialTextField} cb={(newValue) => newValue?.length <= 10 && setFieldValue('notes', newValue)} /> */}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-content-center">
                        <Button
                          color="primary"
                          onClick={async () => {
                            const params = {
                              commodity_type_master_id: values?.commodity_type_id,
                              commodity_form_master_id: values?.commodity_form_id,
                              minimum_discount: values?.minimum_discount,
                              notes: values?.notes,
                              price_uom_id: values?.price_uom_id,
                              uom_code: values?.price_uom_code,
                              operation_enum: isEdit ? 3 : 1,
                            };
                            const success = await dispatch(
                              earlyPaymentsActions.saveAndUpdateEarlyPaymentDiscount(params),
                            );
                            if (success) {
                              setIsReRenderRequired(!isReRenderRequired);
                              setEdit(false);
                              setIsAdd(false);
                              formik.resetForm();
                            }
                          }}>
                          {isEdit ? 'Update' : 'Save'}
                        </Button>
                      </div>
                    </PopupForm>
                  )}
                  <DialogPopup
                    type="warning"
                    isOpen={isDelete}
                    setIsOpen={setIsDelete}
                    heading={'Delete Minimum Discount'}
                    message={'Are you sure you want to delete this record?'}
                    onClick={async () => {
                      const success = await dispatch(
                        earlyPaymentsActions.deleteEarlyPaymentDiscount({
                          early_payment_discount_config_id: values.early_payment_discount_config_id,
                        }),
                      );
                      setIsDelete(false);
                      success && setIsReRenderRequired(!isReRenderRequired);
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default MinimumDiscount;
