import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import utils from 'lib/utils';
import { aggregationPlanningService } from 'services';

const adapter = createEntityAdapter({});

const slice = createSlice({
  name: 'aggregationPlanning',
  initialState: adapter.getInitialState({
    busy: false,
    reportData: [],
    data: [],
  }),
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
    setReportData: (state, { payload }) => ({ ...state, reportData: payload }),
    setData: (state, { payload }) => ({ ...state, data: payload }),
  },
});

const fetchPickupActivityDetails = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await aggregationPlanningService.fetchPickupActivityDetails(params);
    dispatch(slice.actions.setData(resData));
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    dispatch(slice.actions.setData([]));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdatePickupActivity = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await aggregationPlanningService.saveAndUpdatePickupActivity(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const saveAndUpdatePickupActivityForDashboard = (params) => async (dispatch) => {
  // dispatch(slice.actions.setBusy(true));
  try {
    const resData = await aggregationPlanningService.saveAndUpdatePickupActivityForDashboard(params);
    dispatch(slice.actions.setBusy(false));
    return resData;
  } catch (err) {
    utils.displayErrors(err);
  }
  // dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchPickupActivityDetails,
  saveAndUpdatePickupActivity,
  saveAndUpdatePickupActivityForDashboard,
};

const selectors = {
  ...adapter.getSelectors((state) => state.aggregationPlanning),
};

export { actions, selectors };

export default slice.reducer;
