import _ from 'lodash';
import { NOTIFICATION_ENUM, NOTIFICATION_CATEGORY_ENUM } from 'constants/enums';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';

const notificationUtils = {
  kycLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    const storeState = globalThis.store.getState();
    const user = _.get(storeState, 'user') || {};
    let acl = utils.getACL(user);
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.KYC_APPLIED:
      case NOTIFICATION_ENUM.KYC_REJECTED:
      case NOTIFICATION_ENUM.KYC_APPROVED: {
        let notificationLink = `/account/${id}/kyc`;
        if (acl.isCompanyAdmin) {
          notificationLink = `/account/${id}/kyc-b`;
        }
        return notificationLink;
      }
      case NOTIFICATION_ENUM.KYC_ADMIN_NOTIFICATION:
      case NOTIFICATION_ENUM.KYC_TEAM_ADMIN_ASSIGN:
      case NOTIFICATION_ENUM.KYC_TEAM_ROLE_ASSIGN: {
        let notificationLink = `/account/${id}/teams?page1&back=${encodeBack}`;
        return notificationLink;
      }
    }
  },

  socialFeedLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.POST_LIKE: {
        let notificationLink = `/social/post/${id}?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.REPORT_FEED:
      case NOTIFICATION_ENUM.TAGGED_ENTITY:
      case NOTIFICATION_ENUM.FOLLOWERS_POST:
      // case NOTIFICATION_ENUM.POST_CREATED_BY_FOLLOWING: {
      //   let notificationLink = `/social/post/${id}`;
      //   return notificationLink;
      // }
      case NOTIFICATION_ENUM.FOLLOW: {
        let notificationLink = `/social/profile/${id}?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SOCIAL_CONNECT: {
        let notificationLink = `/social/profile/about/${id}?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.CREATE_COMMENT: {
        let notificationLink = `/social/post/${id}?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SHARE_POST: {
        let notificationLink = `/social/post/${id}?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },

  buySellLinks(notification_sub_category_enum, data) {
    let id = _.get(data, 'sub_category_entity_id') || 0;
    let buySellId = _.get(data, 'category_entity_id') || 0;
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.DEAL_CREATED: {
        let notificationLink = `/commodity/deals/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.OFFER_ACCEPTED: {
        let notificationLink = `/commodity/requirements/${buySellId}/bids?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.BID_ACCEPTED: {
        let notificationLink = `/commodity/offers/${buySellId}/bids?back=${encodeBack}`;
        // let notificationLink = `/commodity/deals/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.BUY_CREATED:
      case NOTIFICATION_ENUM.BUY_EDITED:
      case NOTIFICATION_ENUM.BUY_WITHDRAWN: {
        let notificationLink = `/commodity/requirements/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SELL_CREATED:
      case NOTIFICATION_ENUM.SELL_EDITED:
      case NOTIFICATION_ENUM.SELL_WITHDRAWN: {
        let notificationLink = `/commodity/offers/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.OFFER_RECIEVED:
      case NOTIFICATION_ENUM.OFFER_REJECTED:
      case NOTIFICATION_ENUM.OFFER_WITHDRAWN:
      case NOTIFICATION_ENUM.COUNTER_PROPOSAL_RECIEVED_BUY:
      case NOTIFICATION_ENUM.COUNTER_PROPOSAL_RESPONDED_BUY:
      case NOTIFICATION_ENUM.OFFER_UPDATED: {
        let notificationLink = `/commodity/requirements/${buySellId}/bids?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.BID_RECIEVED:
      case NOTIFICATION_ENUM.BID_REJECTED:
      case NOTIFICATION_ENUM.BID_WITHDRAWN:
      case NOTIFICATION_ENUM.COUNTER_PROPOSAL_RECIEVED_SELL:
      case NOTIFICATION_ENUM.COUNTER_PROPOSAL_RESPONDED_SELL:
      case NOTIFICATION_ENUM.BID_UPDATED: {
        let notificationLink = `/commodity/offers/${buySellId}/bids?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.AUTO_OFFER_HIGHMATCH_SELL: {
        let notificationLink = `/commodity/offers/${id}/auto-offer?page=1&back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.AUTO_OFFER_HIGHMATCH_BUY: {
        let notificationLink = `/commodity/requirements/${id}/bids?page=1&back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.UPDATE_FOLLOWED_BUY_SELL: {
        let notificationLink = `/commodity/offers/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },

  buySellOperationsLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.DAY_SCHEDULE:
      case NOTIFICATION_ENUM.DECADE_SCHEDULE: {
        let notificationLink = `/commodity/deals/${id}/schedule?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.AUCTION_INVITATION_EMAIL:
      case NOTIFICATION_ENUM.AUCTION_WITHDRAWN_EMAIL:
      case NOTIFICATION_ENUM.AUCTION_MESSAGE:
      case NOTIFICATION_ENUM.CHAT_MESSAGE: {
        let notificationLink = `/commodity/requirements/auction/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  tripOperationsLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.DELIVERIES_PHASE_UPDATE:
      case NOTIFICATION_ENUM.DELIVERIES_TRIP_REMINDER: {
        let notificationLink = `/deliveries/${id}/delivery-status?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.DELIVERIES_UPDATE_QS: {
        let notificationLink = `/deliveries/${id}/cargo-details?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },

  invoiceOperationsLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.INVOICE_DOCUMENT_GENERATION: {
        let notificationLink = `/deliveries/${id}/delivery-status?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.ESIGN_SELLER_INVOICES: {
        let notificationLink = `/invoices/${id}?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.RECORD_PAYMENT: {
        let notificationLink = `/payment/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.RECORD_PAYMENT_UPDATED: {
        let notificationLink = `/payment/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  auctionOperationsLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.BUY_AUCTION_INVITATION_EMAIL: {
        let notificationLink = `/commodity/requirements/auction/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SELL_AUCTION_INVITATION_EMAIL: {
        let notificationLink = `/commodity/offers/auction/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.BUY_AUCTION_STARTED: {
        let notificationLink = `/commodity/requirements/auction/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SELL_AUCTION_STARTED: {
        let notificationLink = `/commodity/offers/auction/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.MULTIPARTY_AUCTION_STARTED: {
        let notificationLink = `/commodity/markets-live/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  createPostLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.POST_CREATED_BY_FOLLOWING: {
        let notificationLink = `/social/post/${id}?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  createSubscriptionLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    // let personCompanyAffiliateEnum = _.get(data, 'person_company_affiliate_enum') || 0;
    // let personCompanyAffiliateId = _.get(data, 'categorysubscription_entity_id_entity_id') || 0;
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.SUBSCRIPTION_ENTERPRISE_PACK1_FIRST_PURCHASE: {
        let notificationLink = `/subscription-packages?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SUBSCRIPTION_POST_PAID_SUBSCRIPTION_CONFIRMED:
      case NOTIFICATION_ENUM.SUBSCRIPTION_BUSINESS_PACK:
      case NOTIFICATION_ENUM.SUBSCRIPTION_NETWORK_PLAN: {
        let notificationLink = `/subscription/mySubscription?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SUBSCRIPTION_INSUFFICIENT_BALANCE_SECOND_PARTY_TO_ACCEPT_BID_OR_OFFER: {
        let notificationLink = `/subscription-packages?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SUBSCRIPTION_LOW_PRE_PAID_BALANCE: {
        let notificationLink = `/subscription-packages?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.SUBSCRIPTION_NEW_PREPAID_PACK: {
        let notificationLink = `/subscription/mySubscription?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  createEarlyPaymentLinks(notification_sub_category_enum, id) {
    const encodeBack = utils.encodeCustomUrl('/account/notifications');
    switch (notification_sub_category_enum) {
      case NOTIFICATION_ENUM.APPLIED_EARLY_PAYMENT: {
        let notificationLink = `/commodity/deals/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
      case NOTIFICATION_ENUM.ENABLE_DISABLE_EARLY_PAYMENT_SCHEME: {
        let notificationLink = `/deliveries/${id}/overview?back=${encodeBack}`;
        return notificationLink;
      }
    }
  },
  // createIsWithinGeofenceSellsLink(notification_sub_category_enum, id) {
  //   const encodeBack = utils.encodeCustomUrl('/account/notifications');
  //   switch (notification_sub_category_enum) {
  //     case NOTIFICATION_ENUM.WITHIN_GEOFENCE: {
  //       let notificationLink = `/commodity/offers/${id}/overview?back=${encodeBack}`;
  //       return notificationLink;
  //     }
  //   }
  // },

  getNotificationLinks(data) {
    const notification_category_enum = _.get(data, 'notification_category_enum') || 0;
    const notification_sub_category_enum = _.get(data, 'notification_sub_category_enum') || 0;
    switch (notification_category_enum) {
      case NOTIFICATION_CATEGORY_ENUM.KYC: {
        let id = _.get(data, 'person_id') || 0;
        let notificationLink = notificationUtils.kycLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.SOCIAL_FEED: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.socialFeedLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.BUY_SELL: {
        let notificationLink = notificationUtils.buySellLinks(notification_sub_category_enum, data);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.BUY_SELL_OPERATIONS: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.buySellOperationsLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.DELIVERIES: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.tripOperationsLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.INVOICES: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.invoiceOperationsLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.AUCTION: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.auctionOperationsLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.CREATE_POST: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.createPostLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.SUBSCRIPTION: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.createSubscriptionLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      case NOTIFICATION_CATEGORY_ENUM.EARLY_PAYMENTS: {
        let id = _.get(data, 'sub_category_entity_id') || 0;
        let notificationLink = notificationUtils.createEarlyPaymentLinks(notification_sub_category_enum, id);
        return getUrlWithEncryptedQueryParams(notificationLink);
      }
      // case NOTIFICATION_CATEGORY_ENUM.GEOFENCE: {
      //   let id = _.get(data, 'sub_category_entity_id') || 0;
      //   let notificationLink = notificationUtils.createIsWithinGeofenceSellsLink(notification_sub_category_enum, id);
      //   return notificationLink;
      // }
    }
  },
};
export default notificationUtils;
