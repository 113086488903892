import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { actions as companyActions } from 'redux/companies';
import { actions as locationActions } from 'redux/locations';
import _ from 'lodash';
import api from 'lib/api';

export default function CompanyLocationSelect(props) {
  const {
    field,
    form: { touched, errors },
    label,
    children,
    select,
    variant,
    initialDisplayText,
    entity_id,
    id,
    company_affiliate_enum,
    disabled,
    ...rest
  } = props;

  let loading = false;
  let options = [];
  const [value, setValue] = React.useState(initialDisplayText);

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations);
  const locationData = _.get(locations, `entities`) || {};

  if (entity_id) {
    _.each(locationData, (item, id) => {
      if (item.entity_id === entity_id) {
        options.push({
          label: item.address_text || item.location_code || item.location_id || '',
          value: {
            id: parseInt(id),
            ...item,
          },
        });
      }
    });
  }
  if (!options.length) {
    loading = true;
  }

  // useEffect(() => {
  //   setValue(null);
  // }, []);

  useEffect(() => {
    if (loading) {
      let params ={
        person_id: id,
      }
      dispatch(locationActions.fetchList(params));
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      let initialAddressId = _.get(props, 'form.initialValues.company_address_id') || 0;
      const initialSelected = options.filter((o) => initialAddressId === o.value?.id);
      // console.log('\n\ninitialAddressId---------->', initialAddressId);
      // console.log('options------------>', options);
      // console.log('initialSelected------------>', initialSelected);

      setValue(initialSelected);
    }
  }, [loading]);

  // console.log(' options=======', options);

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  return (
    <div className={`mui-single-select bfc-ref-data-select-wrapper ${hasError ? 'has-error' : ''}`}>
      <Autocomplete
        id={`mui-autocomplete-${Date.now()}`}
        // multiple
        size="small"
        disableClearable
        fullWidth
        loading={loading}
        disabled={disabled || loading}
        getOptionLabel={(option) => {
          // console.log('getOptionLabel-->option---->', option);
          if (typeof option === 'string') {
            return option;
          } else if (Array.isArray(option)) {
            return _.get(option, `[0].label`) || '';
          } else {
            return option.label || '';
          }
        }} // filterOptions={(x) => x}
        // loading={true}
        options={options}
        // filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          // console.log('newValue------->', newValue);
          // setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (props.cb) {
            props.cb(newValue);
          }
        }}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...field}
            fullWidth
            variant={variant || 'outlined'}
            label={label}
            children={children}
            select={select}
            error={hasError}
            helperText={errText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
