import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import DealService, { DealMixedType } from 'services/deal';
import { financeUserService } from 'services';
import utils from 'lib/utils';

export interface DealState {
  data: Record<string, DealMixedType>;
  order: number[];
  busy: boolean;
  errors: null | [] | Object;
  errorCode: null | string | number;
  updatedAt: number;
}

const initialState: DealState = {
  data: {},
  order: [],
  busy: false,
  errors: null,
  errorCode: null,
  updatedAt: Date.now(),
};

const slice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    startFetching(state) {
      state.busy = true;
    },
    endFetching(state) {
      state.busy = false;
    },
    handleErrors(state, action) {
      state.errors = action.payload.errors;
      state.errorCode = action.payload.errorCode;
      state.busy = false;
    },
    add(state, action: PayloadAction<DealMixedType[]>) {
      const order: number[] = [];
      const data = {};
      if (action.payload) {
      }

      for (let deal of action.payload) {
        const id = deal.commodity_deal_master_id;
        if (id > 0) {
          data[id] = { ...deal };
          order.push(id);
        }
      }
      state.data = data;
      state.order = order;
      state.updatedAt = Date.now();

      state.busy = false;
    },
    update(state, action) {
      const { id, data } = action.payload;
      state.data[id] = {
        ...state.data[id],
        ...data,
        detailsUpdatedAt: Date.now(),
      };
    },
    // create(state: PlainOfferState, action) {
    //   debugger;
    //   // //@TODO: pass person_id filed value to post & handle response
    //   // api.createSocialPost(action.payload);
    // },
    // update(state: PlainOfferState, action) {
    //   const { commodity_req_offer_id, ...rest } = action.payload;
    //   state.data[commodity_req_offer_id] = { ...state.data[commodity_req_offer_id], ...rest };
    //   state.updatedAt = Date.now();
    // },
    // delete(state: PlainOfferState, action) {
    //   // debugger;
    //   const post_id = action.payload;
    //   let order = _.get(state, 'order', []) || [];
    //   // const posts = state.data.filter(p => p.post_id !== post_id)
    //   delete state['data'][post_id];
    //   let filteredOrder = order.filter((id) => id !== post_id);
    //   state['order'] = filteredOrder;
    // },
  },
});

const FetchAllCommodityDealsAgainstEntityId = (filters, page: number = 1, pageSize: number = 10, resetDealDate) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchAllCommodityDealsAgainstEntityId(filters, page, pageSize, resetDealDate);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const fetchAllDealsCt = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.fetchAllDealsCt(params);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchCommodityDealDetailsAgainstDealMasterId = (commodityDealMasterId) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchCommodityDealDetailsAgainstDealMasterId(commodityDealMasterId);
      dispatch(slice.actions.add([resData.data]));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchCommodityDealsAgainstCommodityReqOfferId = (id, filters, page = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchCommodityDealsAgainstCommodityReqOfferId(id, filters, page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchDealFulfilmentDeals = (id, filters, page = 1, pageSize: number = 10) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchDealFulfilmentDeals(id, filters, page, pageSize);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchLinkedDealByPersonId = (
  id,
  filters,
  page = 1,
  pageSize: number = 10,
  companySelectedId,
  comapanySelectedEnum,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchLinkedDealByPersonId(
        id,
        filters,
        page,
        pageSize,
        companySelectedId,
        comapanySelectedEnum,
      );
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const FetchLinkedDealByDealId = (
  id,
  filters,
  page = 1,
  pageSize: number = 10,
  companySelectedId,
  comapanySelectedEnum,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await DealService.FetchLinkedDealByDealId(
        id,
        filters,
        page,
        pageSize,
        companySelectedId,
        comapanySelectedEnum,
      );
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      dispatch(slice.actions.add([]));
    }
  };
};

const fetchDealFinanceUser = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      const resData = await financeUserService.fetchDealsList(params);
      dispatch(slice.actions.add(resData.data));
    } catch (errors) {
      // dispatch(slice.actions.handleErrors({ ...errors }));
      dispatch(slice.actions.add([]));
    }
  };
};

const RejectDeal_CT = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      return await financeUserService.RejectDeal_CT(params);
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ ...errors }));
    }
  };
};

const MarkDealAsComplete = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      return await financeUserService.MarkDealAsComplete(params);
    } catch (errors) {
      dispatch(slice.actions.handleErrors({ ...errors }));
    }
  };
};

const applyEarlyPaymentOffer = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.applyEarlyPaymentOffer(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      dispatch(slice.actions.handleErrors({ ...errors }));
    }
  };
};

const allowEarlyPaymentCT = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.allowEarlyPaymentCT(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      dispatch(slice.actions.handleErrors({ ...errors }));
    }
  };
};

const convertToBioFuelCircleTransport = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.convertToBioFuelCircleTransport(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      utils.displayErrors(errors);
    }
  };
};

const addManualDeliveries = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.addManualDeliveries(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      dispatch(slice.actions.handleErrors({ ...errors }));
    }
  };
};

const checkDealLinkingApplicability = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.checkDealLinkingApplicability(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      utils.displayErrors(errors);
      dispatch(slice.actions.endFetching());
    }
  };
};

const attachDealToIndentBuy = (params) => {
  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch(slice.actions.startFetching());
      let success = await financeUserService.attachDealToIndentBuy(params);
      if (success) {
        dispatch(slice.actions.endFetching());
      }
      return success;
    } catch (errors: any) {
      utils.displayErrors(errors);
      dispatch(slice.actions.endFetching());
    }
  };
};

export const actions = {
  ...slice.actions,
  FetchAllCommodityDealsAgainstEntityId,
  FetchCommodityDealDetailsAgainstDealMasterId,
  FetchCommodityDealsAgainstCommodityReqOfferId,
  FetchDealFulfilmentDeals,
  FetchLinkedDealByPersonId,
  FetchLinkedDealByDealId,
  fetchDealFinanceUser,
  fetchAllDealsCt,
  RejectDeal_CT,
  applyEarlyPaymentOffer,
  addManualDeliveries,
  convertToBioFuelCircleTransport,
  allowEarlyPaymentCT,
  checkDealLinkingApplicability,
  attachDealToIndentBuy,
  MarkDealAsComplete,
};

export default slice.reducer;
