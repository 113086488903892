import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  create(params = {}) {
    let bodyParams = {
      ...params,
    };

    return bodyParams;
  },
  fetchDetails(id) {
    let qsParams = {
      commodity_req_offer_response_id: id,
    };

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'commodity_req_offer_response_id') || 0,
    };
    return res;
  },

  create(resData) {
    return resData;
  },
};
