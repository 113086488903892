import _ from 'lodash';
import api from 'lib/api';
import notifications from 'lib/notifications';
import redirect from 'lib/redirect';
import utils from 'lib/utils';
import * as yup from 'yup';
import store from 'redux/store';
import { kycUserIdentityService } from 'services';

export const handleCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Individual Identity ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.create(values);
    notifications.show({
      type: 'success',
      message: 'Individual Identity Created.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleCreateStatus = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating KYC Status',
      key: 'req-form',
    });

    // console.log('values in handlers', values);
    const res = await kycUserIdentityService.createStatus(values);
    notifications.show({
      type: 'success',
      message: 'KYC Status Updated.',
      key: 'req-form',
    });

    redirect.web(`/kycapproval/individual`);

    return res;
  } catch (err) {
    console.log('handleCreate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Individual Identity ...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_kyc_id') || 0;
    const res = await kycUserIdentityService.update(id, values);
    notifications.show({
      type: 'success',
      message: 'Individual Identity details updated.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleUpdate--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Tax
export const handleTaxCreate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Tax Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.createTax(values);
    notifications.show({
      type: 'success',
      message: 'Tax Details Created.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCreateTax--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleTaxUpdate = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Tax Details ...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_tax_detail_id') || 0;
    const res = await kycUserIdentityService.updateTax(id, values);
    notifications.show({
      type: 'success',
      message: 'Tax Details updated.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleUpdateTax--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

//Bank
export const handleBankCreate = async (values, formikBag, setTimestamp, saveBankSchema) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Creating Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.createBank(values, saveBankSchema);
    notifications.show({
      type: 'success',
      message: 'Bank Details Created.',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    console.log('handleCreateTax--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleBankUpdate = async (values, formikBag, setTimestamp, saveBankSchema) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Updating Bank Details ...',
      key: 'req-form',
    });
    const id = _.get(values, 'person_bank_account_id') || 0;
    const res = await kycUserIdentityService.updateBank(id, values, saveBankSchema);
    notifications.show({
      type: 'success',
      message: 'Bank Details updated.',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    console.log('handleUpdateTax--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleEKycDocument = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Documents ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.eKycDocumentVerification(values);
    notifications.show({
      type: 'success',
      message: _.get(res, 'kyc_message') || res,
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleEKyc--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleverifyIFSC = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Bank IFSC Code ...',
      key: 'req-form',
    });
    const res = await kycUserIdentityService.verifyIFSC(values);
    notifications.show({
      type: 'success',
      message: 'Bank IFSC Code verified Successfully',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleverifyIFSC--->error---->', err);
    notifications.show({
      type: 'error',
      message: err || 'Invalid IFSC',
      key: 'req-form',
    });
  }
};

export const handleBankVerification = async (values, setTimestamp) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.verifyBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Details Verified.',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    const er = Object.values(err.errors);
    console.log('handleBankVerification--->error---->', err, er);
    const error = _.get(err, 'zoop_error') || er[0];
    notifications.show({
      type: 'error',
      message: error,
      key: 'req-form',
    });

    setTimestamp(Date.now());
  }
};

export const handleCompleteBankVerification = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.completeBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Details Verified.',
      key: 'req-form',
    });

    return res;
  } catch (err) {
    console.log('handleCompleteBankVerification--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const handleDeleteBank = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Deleting Bank Details ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.deleteBank(values);
    notifications.show({
      type: 'success',
      message: 'Bank Details Deleted.',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleDeleteBank--->error---->', err);
    utils.displayErrors(err, formikBag);
  }
};

export const verifyVPAId = async (values, setTimestamp, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Verifying VPA ...',
      key: 'req-form',
    });

    const res = await kycUserIdentityService.verifyVPAId(values);
    notifications.show({
      type: 'success',
      message: 'VPA verified successfully.',
      key: 'req-form',
    });
    setTimestamp(Date.now());
    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
    setTimestamp(Date.now());
  }
};
