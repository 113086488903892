import dayjs from 'dayjs';
import _, { update } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory, useRouteMatch } from 'react-router-dom';
// import LogoLoader from 'common/LogoLoader';
import { actions as dealInvoicesActions } from 'redux/dealInvoices';
import { fetchRelatedInvoices } from '../handlers.js';
import { actions as generateInvoiceReportActions } from 'redux/generateReportCommon';
import TableListHeader from 'common/TableListHeader';
import useQuery from 'lib/hooks/useQuery';
import { Table, Tag, Popover, Skeleton } from 'antd';
import { dealInvoicesService } from 'services';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import CommodityField from 'common/TableComponents/commodityField';
import InvoiceField from 'common/TableComponents/invoiceField';
import QuantityField from 'common/TableComponents/quantityField';
import DueDateField from 'common/TableComponents/dueDateField';
import AmountField from 'common/TableComponents/amountField';
import AutoCompleteFilter from 'common/Form/AntFilters/AutoCompleteFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import DateSearchFilter from 'common/Form/AntFilters/DateSearchFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import FilterUtils from 'lib/filterUtils';
import AntPagination from 'common/AntTablePagination';
import utils, {
  decryptionInHex,
  getDecryptedRouteParams,
  getDecryptedUrl,
  getUrlWithEncryptedQueryParams,
} from 'lib/utils';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import signatureHeaderIcon from 'assets/images/icons/signatureHeader.svg';
import scannedIcon from 'assets/images/icons/scannedIcon.svg';
import eSignIcon from 'assets/images/icons/eSignIcon.svg';
import noSignatureIcon from 'assets/images/icons/noSignatureIcon.svg';
import ClosingDateField from 'common/TableComponents/closingDateField';
import { LinkOutlined, EditOutlined } from '@ant-design/icons';
import OfferAvailedIcon from 'assets/images/icons/Offer-availed.svg';
import RelatedInvoiceIcon from 'assets/images/icons/maths.png';
import IrpIcon from 'assets/images/icons/IRP.svg';
import InvoiceApprovalPopup from '../components/InvoiceApprovalPopup';
import BulkInvoiceApprovalPopup from '../components/BulkInvoiceApprovalPopup';
import PopupForm from 'common/Form/PopupForm';
import edit_svg from 'assets/images/icons/edit-new-icon.svg';
import edit_svg_grey from 'assets/images/icons/edit-new-icon-grey.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DownloadInvoiceModal from 'common/DownloadInvoiceModal/DownloadInvoiceModal';
// import DateRangeFilterText from 'common/OverviewHeader/common/DateRangeFilterText'
import { getLinks } from 'constants/linkConfig';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import '../list/styles.scss';
import 'antd/dist/antd.css';
import notifications from 'lib/notifications';
// Omkar 4547
import FeedbackModal from 'common/FeedbackModal/FeedbackModal';
import { LoadingOutlined } from '@ant-design/icons';
import MUIAutocomplete from 'common/MUIComponents/Autocomplete';
import MUIButton from 'common/MUIComponents/Button';
import { Add } from '@material-ui/icons';
import MUIDatePicker from 'common/MUIComponents/DateTimePicker';
import MUITextField from 'common/MUIComponents/TextField';
import AddAdvancePopUp from './addAdvancePopup.jsx';
import MUIModal from 'common/MUIComponents/Modal';
import RazorPayXIconPath from 'assets/images/icons/razorPayX.svg'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: 'var(--unnamed-color-1b62ab)',
    }}
    spin
  />
);

const RazorPayXIcon = (props) => {
  return <img src={RazorPayXIconPath} alt="tractor" {...props} />;
};

function AdvancePaymentsListPage(props) {
  const { setSelectedDeliveries } = props;
  const dispatch = useDispatch();
  let { id, place } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);
  const { url } = useRouteMatch();
  const tableRef = useRef();
  const history = useHistory();
  const loc = getDecryptedUrl(window.location.href);
  // const allowedDefaultDateRangeFilter = loc.includes('control-tower/deals') || loc.includes('control-tower/buy-sell-tracker') || loc.includes('control-tower/bid-offer-tracker') || loc.includes('controltower/invoices')

  const path = location.pathname;
  let isCT = false;
  if (path.includes('control-tower') || path.includes('controltower')) {
    isCT = true;
  }

  const isMarketOrCtInvoiceScreen = loc.includes('market/advance') || loc.includes('controltower/advance');

  let {
    page,
    commodity_deal_master_id,
    delivery_id,
    customer_vendor,
    invoice_buyer,
    invoice_seller,
    load_location_code,
    delivery_location_code,
    payment_due_date,
    invoice_status_enum,
    custom_invoice_number,
    invoice_date,
    // invoice_date = (allowedDefaultDateRangeFilter) ? `${dayjs().subtract(1, 'year').format('YYYY-MM-DD')}:${dayjs().format('YYYY-MM-DD')}` : undefined,
    signature_type,
    goodsservices,
    goods,
    type_of_invoice,
    settlement_invoice_type_id,
    settlement_invoice_category_id,
    payment_made_date,
    workflow_pay_on_date,
    invoice_verification_enum_code,
    invoice_verification_enum,
    workflow_invoice_verification_enum_code,
    workflow_invoice_verification_enum,
    back,
    advance_purpose_enum,
    advance_purpose_entity_id,
    fiscal_year,
  } = useQuery();

  const userInfo = useSelector((state) => state.user);
  const isControlTowerUser = _.get(userInfo, 'profile.person.is_control_tower_user') || false;
  const typeOfIndividualEnum = _.get(userInfo, 'profile.person.type_of_individual_enum') || 0;
  const isTransporter = _.get(userInfo, 'profile.person.is_transporter') || false;
  const isCTSupport = _.get(userInfo, 'profile.is_ct_support') || false;
  const invoiceDownloadLimit = _.get(userInfo, 'profile.invoice_download_limit') || 0;
  const bulkInvoiceWorkflowStatusLimit = _.get(userInfo, 'profile.bulk_invoice_workflow_status_limit') || 0;
  const companyId = _.get(userInfo, 'profile.person.company_id') || 0;
  const context = useSelector((state) => state.context);
  const deals = useSelector((state) => state.deals);
  const isPayoutsEnabled = _.get(userInfo, 'profile.is_payouts_enabled') || false;
  const isSupplyPartner = _.get(userInfo, 'profile.is_supply_partner') || false;

  const [paymentPopupVisible, setPaymentPopupVisible] = useState(false);
  const [bulkInvoiceApprovalPopupVisible, setBulkInvoiceApprovalPopupVisible] = useState(false);
  const [showBulkInvoiceApprovalPopupButton, setShowBulkInvoiceApprovalPopupButton] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [timestamp, setTimeStamp] = useState(Date.now());
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);
  const [feedbackId, setFeedbackId] = useState(3);
  const [relatedInvoices, setRelatedInvoices] = useState([]);
  const [fetchingRelatedInvoices, setFetchingRelatedInvoices] = useState(false);
  const [isShowCrDrClicked, setIsShowCrDrClicked] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);
  const [addAdvance, setAddAdvance] = useState(false);
  const [payOn, setPayOn] = useState('');
  const [remark, setRemark] = useState('');

  const handleVisibleChange = (id, visible) => {
    setOpenPopover(visible ? id : null);
  };
  // Omkar 4547
  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(true);
  const [isFeedbackFormSubmitted, setIsFeedbackFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [pageFullyLoaded, setPageFullyLoaded] = useState(false);
  const [isReRenderRequired, setIsReRenderRequired] = useState(false);
  const [financialYear, setFinancialYear] = useState('');
  const [open, setOpen] = useState(false);
  // const [earlyPaymentDone, setEarlyPaymentDone] = useState(null);
  let isMarket = false;
  const is_zoho_connected = userInfo?.profile?.is_connected;
  const showInvoicePopup = () => {
    setPaymentPopupVisible(true);
  };

  const showBulkInvoiceApprovalPopup = () => {
    setBulkInvoiceApprovalPopupVisible(true);
  };

  const toggleAdvancePopup = () => {
    setAddAdvance(!addAdvance);
  };

  const toggle = () => {
    setPaymentPopupVisible(!paymentPopupVisible);
  };

  const bulkApprovalToggle = () => {
    setBulkInvoiceApprovalPopupVisible(!bulkInvoiceApprovalPopupVisible);
  };

  const invoices = useSelector((state) => state.dealInvoices);
  const GenerateReportAllData = useSelector((state) => state.generateReportCommon);
  const invoicesList = _.get(invoices, 'entities') || {};

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [closeArray, setCloseArray] = useState([]);
  const [isOpenDownloadInvoiceModal, setIsOpenDownloadInvoiceModal] = useState(false);
  const [isDownloadingProgress, setIsDownloadingProgress] = useState(false);
  const [links, setLinks] = useState([]);

  const csvLink = useRef();

  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};

  // const onPageLoad = () => {
  //   setTimeout(()=>{
  //     setIsPageLoaded(true)
  //     setPageFullyLoaded(true)
  //   },3000)
  // };

  // useEffect(() => {
  //   if (document.readyState === 'complete') {
  //     onPageLoad();
  //   } else {
  //     window.addEventListener('load', onPageLoad, false);
  //     return () => window.removeEventListener('load', onPageLoad);
  //   }
  // }, [pageFullyLoaded]);

  useEffect(() => {
    // Check if all selected invoices have the same verification enum, are not paid, and belongs to the buyer
    if (selectedRowKeysData.length > 1 && selectedRowKeysData.length <= bulkInvoiceWorkflowStatusLimit) {
      const firstItemWorkflowEnum = selectedRowKeysData[0]?.workflow_invoice_verification_enum;
      const allSameWorkflow = selectedRowKeysData.every(
        (item) =>
          item.workflow_invoice_verification_enum === firstItemWorkflowEnum &&
          item.is_buyer &&
          item.invoice_status_enum_code !== 'Paid',
      );
      setShowBulkInvoiceApprovalPopupButton(allSameWorkflow);
    } else {
      setShowBulkInvoiceApprovalPopupButton(false);
    }
  }, [selectedRowKeysData]);

  const invoicePaymentList = Object.values(invoicesList);
  const earlyPaymentDone = invoicePaymentList.find((obj) => obj.is_early_payment_financed === true);
  if (earlyPaymentDone) {
    console.log('Object with payment done:', earlyPaymentDone);
    // setEarlyPaymentDoneFound(true)
  } else {
    console.log('No object with payment done found.');
  }

  let MarketLinksRecord = Object.values(rolesList).find((x) => x.module_name === 'Market');
  const MarketLinks = _.get(MarketLinksRecord, `full_access`);

  let ct_createManualInvoice = Object.values(rolesList).find(
    (x) => (x.module_name === 'Settlements', x.sub_module_name === 'CreateManualInvoice'),
  );
  let ct_approveManualInvoice = Object.values(rolesList).find(
    (x) => (x.module_name === 'Settlements', x.sub_module_name === 'ApproveManualInvoice'),
  );
  const createManualInvoiceFlag =
    _.get(ct_createManualInvoice, `full_access`) || _.get(ct_createManualInvoice, `read_access`);
  const approveManualInvoiceFlag =
    _.get(ct_approveManualInvoice, `full_access`) || _.get(ct_approveManualInvoice, `read_access`);

  let backLink = '';
  let invoiceRender = 'market';
  let showAmountColor = true;
  if (loc.includes('market/invoices')) {
    invoiceRender = 'market';
    backLink = '/market/invoices';
    isMarket = true;
    showAmountColor = true;
  } else if (loc.includes('controltower/invoices')) {
    backLink = '/controltower/invoices';
    showAmountColor = false;
    isMarket = false;
  } else if (loc.includes('network/invoices')) {
    backLink = '/network/invoices';
    showAmountColor = true;
    isMarket = true;
  }
  if (loc.includes('commodity/deals') || loc.includes('network/processing-deal')) {
    invoiceRender = 'deal';
  }
  if (loc.includes('control-tower/deals')) {
    invoiceRender = 'deal';
  }

  let manualInvoiceLink = false;
  if (loc.includes('/controltower/manual-invoice')) {
    manualInvoiceLink = true;
  }
  let buyer_person_id;
  if (id && deals) {
    buyer_person_id = deals?.data?.[id]?.buyer_person_id;
  }

  useEffect(() => {
    if (fiscal_year) {
      setFinancialYear(utils.generateLastFiveYears()?.find(item => item?.value === fiscal_year))
    } else {
      setFinancialYear('')
    }
  }, [fiscal_year])

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      case 'load_location':
        delete params['load_location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('load_location_code'));
        break;
      case 'delivery_location':
        delete params['delivery_location_code'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('delivery_location_code'));
        break;
      case 'invoiceBuyer':
        delete params['invoice_buyer'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('invoice_buyer'));
        break;
      case 'invoiceSeller':
        delete params['invoice_seller'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('invoice_seller'));
        break;
      case 'invoice_date':
        delete params['invoice_date'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('invoice_date'));
        break;
      case 'signature_type_code':
        delete params['signature_type'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('signature_type'));
        break;
      case 'goods_services':
      case 'equipment_type_and_goods':
      case 'equipment_type_id':
        delete params['goods'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('goods'));
        break;
      case 'settlement_invoice_type_code':
        delete params['settlement_invoice_type_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('settlement_invoice_type_id'));
        break;
      case 'settlement_invoice_category_code':
        delete params['settlement_invoice_category_id'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('settlement_invoice_category_id'));
        break;
      case 'payment_made_date':
        delete params['payment_made_date'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('payment_made_date'));
        break;
      case 'workflow_pay_on_date':
        delete params['workflow_pay_on_date'];
        setFilters(params);
        history.push(FilterUtils.removeUrl('workflow_pay_on_date'));
        break;

      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params;
    const isAdvanceScreen = loc.includes('market/advance/payments') || loc.includes('controltower/advance/payments');
    if (loc.includes('market/invoices') || loc.includes('network/invoices') || loc.includes('controltower/invoices') || isAdvanceScreen) {
      params = {
        ...filters,
        ...(!loc.includes('network/invoices') && financialYear?.value ? { fiscal_year: financialYear?.value } : {}),
      };
    } else {
      params = {
        back: back,
      };
    }
    switch (dataIndex) {
      case 'load_location':
        params = {
          ...params,
          load_location_code: e,
        };
        break;
      case 'delivery_location':
        params = {
          ...params,
          delivery_location_code: e,
        };
        break;
      case 'invoiceBuyer':
        params = {
          ...params,
          invoice_buyer: e,
        };
        break;
      case 'invoiceSeller':
        params = {
          ...params,
          invoice_seller: e,
        };
        break;
      case 'signature_type_code':
        params = {
          ...params,
          signature_type: e,
        };
        break;
      case 'invoice_date':
        params = {
          ...params,
          invoice_date: e,
        };
        break;
      case 'goods_services':
      case 'equipment_type_and_goods':
      case 'equipment_type_id':
        params = {
          ...params,
          goods: e,
        };
        break;
      case 'settlement_invoice_type_code':
        params = {
          ...params,
          settlement_invoice_type_id: e,
        };
        break;
      case 'settlement_invoice_category_code':
        params = {
          ...params,
          settlement_invoice_category_id: e,
        };
        break;
      case 'payment_made_date':
        params = {
          ...params,
          payment_made_date: e,
        };
        break;
      case 'workflow_pay_on_date':
        params = {
          ...params,
          workflow_pay_on_date: e,
        };
        break;
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const handleFetchRelatedInvoices = async (row) => {
    const params = {
      invoice_master_id: row.invoice_master_id,
    };
    try {
      setRelatedInvoices([]);
      setIsShowCrDrClicked(row);
      setFetchingRelatedInvoices(true);
      const response = await fetchRelatedInvoices(params);
      setRelatedInvoices(response);
      setFetchingRelatedInvoices(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowLinkedDeals = async (row, deal_delivery_enum) => {
    const linkedDealDeliveryParams = {
      deal_delivery_enum,
      deal_delivery_id: row?.commodity_deal_master_id,
    };
    let linkedDealDeliveries = await dispatch(dealInvoicesActions.getLinkedDealDelivery(linkedDealDeliveryParams));
    linkedDealDeliveries = linkedDealDeliveries?.map((item) => item?.linked_deal_deliveries);
    const commodityMasterId = row.commodity_deal_master_id.toString();
    const toDealId = linkedDealDeliveries.join(',');
    const dealIdsData = commodityMasterId.concat(',', toDealId);
    let params = {
      commodity_deal_master_id: dealIdsData,
    };
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
  };

  const handleShowLinkedDeliveries = async (row, deal_delivery_enum) => {
    const linkedDealDeliveryParams = {
      deal_delivery_enum,
      deal_delivery_id: row?.delivery_id,
    };
    let linkedDealDeliveries = await dispatch(dealInvoicesActions.getLinkedDealDelivery(linkedDealDeliveryParams));
    linkedDealDeliveries = linkedDealDeliveries?.map((item) => item?.linked_deal_deliveries);
    const deliveryId = row.delivery_id.toString();
    const toDeliveryId = linkedDealDeliveries.join(',');
    const deliveryData = deliveryId.concat(',', toDeliveryId);
    let params = {
      delivery_id: deliveryData,
    };
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
  };
  const signatureContent = <p style={{ lineHeight: '10px', color: 'black' }}>Signature</p>;

  const INVOICE_TABLE_HEADERS = [
    {
      title: 'Advance no.',
      dataIndex: 'custom_invoice_number',
      width: '200px',
      align: 'center',
      fixed: true,
      sorter: (a, b) =>
        a.custom_invoice_number &&
        a.custom_invoice_number.localeCompare(b.custom_invoice_number && b.custom_invoice_number),
      // sorter: (a, b) => a.custom_invoice_number - b.custom_invoice_number,
      ellipsis: true,
      render: (text, row) => (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {/* <InvoiceField
              invoice_number={row.custom_invoice_number}
              detailsPageLink={row.detailsPageLink}
              urlFilters={row.urlFilters}
              pageNo={row.pageNo}
              invoice_number_enum={row.invoice_number_enum}
              isFinancedInvoice={row.is_seller_financed}
              isInvoiceType={true}
            /> */}
            <div className="d-flex justify-content-center align-items-center">
              {row.custom_invoice_number}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {row?.adjusted_against_invoice_master_id && (
                <MUIButton type="icon" variant="text" size="small" Icon={SubdirectoryArrowRightIcon} disableRipple />
              )}
              {row?.adjusted_against_invoice_master_id && (
                <p style={{ display: 'block' }}>
                  <Link
                    to={getUrlWithEncryptedQueryParams(
                      `/invoices/${parseInt(
                        row.adjusted_against_invoice_master_id.split('$')[1],
                      )}?back=${utils.encodeBackUrl()}`,
                    )}>
                    {row.adjusted_against_invoice_master_id.split('$')[0]}
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div>
            {/* <Popover
              placement="top"
              content={
                isShowCrDrClicked?.invoice_master_id === row.invoice_master_id ? relatedInvoices?.length > 0 ? (
                  <div style={{ minWidth: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {
                      relatedInvoices.map((invoice) => (
                        <div key={invoice?.invoice_master_id}>
                          <Link to={getUrlWithEncryptedQueryParams(`/invoices/${invoice?.invoice_master_id}`)}>
                            <MUITypography variant="body2">{invoice?.custom_invoice_number}</MUITypography>
                          </Link>
                        </div>
                      ))
                    }
                  </div>
                ) : fetchingRelatedInvoices ? <Spin indicator={antIcon} /> : (
                    <MUITypography color='black' variant="body2">No related invoices</MUITypography>
                ) : (
                  <MUITypography color='black' variant="body2">Show CR/DR</MUITypography>
                )
              }
              trigger="hover"
            >
              <Popover
                placement="top"
                trigger="click"
                visible={false}
                onVisibleChange={(visible) => handleVisibleChange(row.custom_invoice_number, visible)}
              >
                <img
                  onClick={() => handleFetchRelatedInvoices(row)}
                  src={RelatedInvoiceIcon}
                  className="ml-2 cursor-pointer"
                  style={{ width: '18px', height: '18px' }}
                />
                <MUIButton type='icon' variant='text' size='small' Icon={Visibility} onClick={() => handleFetchRelatedInvoices(row)} />
              </Popover>
            </Popover> */}
            {row?.is_early_payment_financed ? (
              <Popover
                placement="top"
                content={
                  <span>
                    {isControlTowerUser
                      ? `Seller has opted for Early Payment for this Invoice`
                      : 'You have opted for Early Payment for this Invoice'}
                  </span>
                }
                trigger="hover">
                <img
                  src={OfferAvailedIcon}
                  className={'ml-2 cursor-pointer'}
                  style={{ width: '24px', height: '24px' }}
                />
              </Popover>
            ) : (
              ''
            )}
            {row?.are_e_invoice_details_available ? (
              <Popover placement="top" content={<span>Invoice pushed to IRP</span>} trigger="hover">
                <img src={IrpIcon} className={'ml-2 cursor-pointer'} />
              </Popover>
            ) : (
              ''
            )}
          </div>
        </div>
        // </Popover>
      ),
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'custom_invoice_number'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={custom_invoice_number}
          name={'Invoice no.'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'custom_invoice_number'),
      filteredValue: custom_invoice_number !== undefined ? custom_invoice_number : null,
    },
    {
      title: 'Adv. Purpose',
      dataIndex: 'advance_purpose_enum_code',
      align: 'center',
      width: '150px',
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{row.advance_purpose_enum_code}</div>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'advance_purpose_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={advance_purpose_enum}
          name={'Adv. Purpose'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'advance_purpose_enum'),
      defaultFilteredValue: advance_purpose_enum !== undefined ? advance_purpose_enum : null,
    },
    {
      title: 'Deal Id',
      dataIndex: 'commodity_deal_master_id',
      key: 'commodity_deal_master_id',
      width: '100px',
      sorter: (a, b) => a.commodity_deal_master_id - b.commodity_deal_master_id,
      ellipsis: true,
      align: 'center',
      render: (text, row) => (
        <div className="d-flex align-items-center justify-content-center">
          <div className="grouping-tag">{row?.advance_purpose_entity_id}</div>
          {row.commodity_deal_master_id && row.to_deal_ids !== null ? (
            loc.includes('/market/invoices') || loc.includes('/controltower/invoices') ? (
              <div style={{ paddingLeft: '5px' }}>
                <LinkOutlined
                  onClick={() => handleShowLinkedDeals(row, 1)}
                  rotate={45}
                  style={{ fontSize: 'var(--fs-base__one)', color: 'var(--unnamed-color-1b62ab)' }}
                />
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
      ),
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'advance_purpose_entity_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={advance_purpose_entity_id}
          name={'Deal Id'}
          isDealDealiveryMultiselectFilter={true}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'advance_purpose_entity_id'),
      filteredValue: advance_purpose_entity_id !== undefined ? advance_purpose_entity_id : null,
    },
    {
      title: 'Buyer',
      dataIndex: 'invoice_buyer',
      width: '170px',
      align: 'left',
      render: (text, row) => (
        <>
          <Popover content={row.invoice_buyer} placement="topLeft">
            <div className="bfc-table-list-components text-left">
              <p className="multiline-ellipsis text-theme-dark">{row.invoice_buyer}</p>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'invoice_buyer'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={invoice_buyer}
          name={'Buyer'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'invoice_buyer'),
      defaultFilteredValue: invoice_buyer !== undefined ? invoice_buyer : null,
    },
    {
      title: 'Seller',
      dataIndex: 'invoice_seller',
      width: '190px',
      align: 'left',
      render: (text, row) => (
        <>
          <Popover content={row.invoice_seller} placement="topLeft">
            <div className="bfc-table-list-components text-left">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    <p className="ellipsis-text text-theme-dark">{row.invoice_seller}</p>
                  </div>
                  {row?.seller_mobile_number ? <div>({row?.seller_mobile_number})</div> : ''}
                </div>
                {row?.is_offerer_bank_verified ? (
                  <Popover content={`Bank details verified`}>
                    <CheckCircleIcon className="ml-1" style={{ color: 'var(--unnamed-color-00ae11)' }} />
                  </Popover>
                ) : row?.is_offerer_bank_verified === false ? (
                  <Popover content={`Bank details not verified`}>
                    <CancelIcon className="ml-1" style={{ color: 'var(--unnamed-color-f44336)' }} />
                  </Popover>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Popover>
        </>
      ),
      filterDropdown: (props) => (
        <AutoCompleteFilter
          dataIndex={'invoice_seller'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={invoice_seller}
          name={'Seller'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'invoice_seller'),
      defaultFilteredValue: invoice_seller !== undefined ? invoice_seller : null,
    },
    // {
    //   title: 'Quantity',
    //   dataIndex: 'total_qty',
    //   width: '90px',
    //   align: 'center',
    //   render: (text, row) => (
    //     <div className="d-flex align-items-center justify-content-center">
    //       <QuantityField qty={text} periodicity_enum_code={'Once'} qty_uom_code={row.qty_uom_code} />
    //     </div>
    //   ),
    // },
    {
      title: 'Amount',
      dataIndex: 'total_invoice_amount',
      width: '120px',
      align: 'center',
      render: (text, row) =>
        showAmountColor ? (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.total_invoice_amount} type={'noInvoice'} />
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.total_invoice_amount} />
          </div>
        ),
    },
    {
      title: 'TDS',
      dataIndex: 'total_invoice_amount',
      width: '120px',
      align: 'center',
      render: (text, row) =>
        showAmountColor ? (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.tds_amount} invoice_type={row.type_of_invoice} type={'noInvoice'} />
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.tds_amount} />
          </div>
        ),
    },
    {
      title: 'Amount Paid',
      dataIndex: 'total_invoice_amount',
      width: '120px',
      align: 'center',
      render: (text, row) =>
        showAmountColor ? (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.paid_amount} type={'noInvoice'} />
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <AmountField expected_price={row.paid_amount} />
          </div>
        ),
    },
    {
      title: 'Due Date',
      dataIndex: 'payment_due_date',
      width: '120px',
      align: 'center',
      sorter: (a, b) =>
        a.payment_due_date && a.payment_due_date.localeCompare(b.payment_due_date && b.payment_due_date),
      render: (text, row) => <DueDateField closing_on={row.payment_due_date} />,
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'payment_due_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_due_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'payment_due_date'),
      defaultFilteredValue: payment_due_date !== undefined ? payment_due_date : null,
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_made_date',
      width: '150px',
      // align: 'center',
      sorter: (a, b) =>
        a.payment_made_date && a.payment_made_date.localeCompare(b.payment_made_date && b.payment_made_date),
      render: (text, row) => <DueDateField closing_on={row.settlement_date || row.payment_made_date} />,
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'payment_made_date'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={payment_made_date}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'payment_made_date'),
      defaultFilteredValue: payment_made_date !== undefined ? payment_made_date : null,
    },
    {
      title: 'Status',
      dataIndex: 'invoice_status_enum_code',
      width: '150px',
      align: 'center',
      render: (text, row) => (
        <span>
          {text == 'Open' && (
            <Tag color={'red'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Initiated' && (
            <Tag color={'blue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Paid' && (
            <Tag color={'green'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Over Due' && (
            <Tag color={'purple'} key={text}>
              <div className="bfc-table-list-components text-center">DUE</div>
            </Tag>
          )}
          {text == 'Failed' && (
            <Tag color={'volcano'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {(text == 'Partially Paid' || text == 'PartiallyPaid') && (
            <Tag color={'orange'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Draft' && (
            <Tag color={'lime'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Sent' && (
            <Tag color={'geekblue'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Cancelled' && (
            <Tag color={'gold'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Collected' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {text == 'Void' && (
            <Tag color={'cyan'} key={text}>
              <div className="bfc-table-list-components text-center">{row.invoice_status_enum_code.toUpperCase()}</div>
            </Tag>
          )}
          {(text == 'NetOff' || text == 'Net Off' || text == 'Net off') && (
            <>
              <Tag color={'crimson'} key={text}>
                <div className="bfc-table-list-components text-center">
                  {row.invoice_status_enum_code.toUpperCase()}
                </div>
              </Tag>
            </>
          )}
        </span>
      ),
      filterDropdown: (props) => (
        <MultiSelectFilter
          dataIndex={'invoice_status_enum'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={invoice_status_enum}
          name={'Status'}
        />
      ),
      filterIcon: (filtered) => getFilteredIcon(filtered, 'invoice_status_enum'),
      defaultFilteredValue: invoice_status_enum !== undefined ? invoice_status_enum : null,
    },
    ...(!loc.includes('control-tower')
      ? [
          {
            title: 'Workflow',
            dataIndex: 'workflow_invoice_verification_enum_code',
            width: '170px',
            align: 'center',

            render: (text, row) =>
              row?.invoice_status_enum_code == 'NetOff' ||
              row?.invoice_status_enum_code == 'Net Off' ||
              row?.invoice_status_enum_code == 'Net off' ? (
                ''
              ) : (
                <span>
                  {row.workflow_invoice_verification_enum == 1 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'red'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 2 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'blue'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 3 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'green'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 4 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'gold'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 5 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'orange'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 6 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'geekblue'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img src={edit_svg} style={{ height: 20, width: 20 }} />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {row.workflow_invoice_verification_enum == 7 && (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                      <Tag color={'geekblue'} key={text}>
                        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
                      </Tag>
                      {row.invoice_status_enum === 13 ? (
                        ''
                      ) : (
                        <span
                          onClick={() => {
                            if (row.invoice_status_enum_code === 'Initiated') {
                            } else {
                              showInvoicePopup();
                              setInvoiceDetails(row);
                            }
                          }}
                          class="workflow-edit"
                          style={{
                            transform: 'translate(0px, 0px)',
                            scale: '1.2',
                            cursor: `${row.invoice_status_enum_code === 'Initiated' ? 'auto' : 'pointer'}`,
                          }}>
                          {row.is_buyer && !loc.includes('controltower') && row.invoice_status_enum_code !== 'Paid' && (
                            <img
                              src={row.invoice_status_enum_code === 'Initiated' ? edit_svg_grey : edit_svg}
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                        </span>
                      )}
                    </div>
                  )}
                  {!row.workflow_invoice_verification_enum && (
                    <div className="bfc-table-list-components text-center">{'-'}</div>
                  )}
                </span>
              ),
            filterDropdown: (props) => (
              <MultiSelectFilter
                dataIndex={'workflow_invoice_verification_enum'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={workflow_invoice_verification_enum}
                name={'Workflow'}
              />
            ),
            filterIcon: (filtered) => getFilteredIcon(filtered, 'workflow_invoice_verification_enum'),
            defaultFilteredValue:
              workflow_invoice_verification_enum !== undefined ? workflow_invoice_verification_enum : null,
          },
          {
            title: 'Amount to Pay',
            dataIndex: 'workflow_amount_to_pay',
            width: '120px',
            align: 'center',
            render: (text, row) =>
              showAmountColor ? (
                <AmountField
                  expected_price={
                    row.workflow_amount_to_pay < 0 ? row.workflow_amount_to_pay : -row.workflow_amount_to_pay
                  }
                  type={'invoice'}
                />
              ) : (
                <AmountField
                  expected_price={
                    row.workflow_amount_to_pay < 0 ? row.workflow_amount_to_pay : -row.workflow_amount_to_pay
                  }
                />
              ),
          },
          {
            title: 'Pay on',
            dataIndex: 'workflow_pay_on_date',
            width: '140px',
            align: 'center',
            sorter: (a, b) =>
              a.workflow_pay_on_date &&
              a.workflow_pay_on_date.localeCompare(b.workflow_pay_on_date && b.workflow_pay_on_date),
            render: (text, row) => <DueDateField closing_on={row.workflow_pay_on_date} />,
            filterDropdown: (props) => (
              <RangeDateSearchFilter
                dataIndex={'workflow_pay_on_date'}
                data={props}
                onFilterChange={onFilterChange}
                handleReset={handleReset}
                value={workflow_pay_on_date}
              />
            ),
            filterIcon: (filtered) => getDateIcon(filtered, 'workflow_pay_on_date'),
            defaultFilteredValue: workflow_pay_on_date !== undefined ? workflow_pay_on_date : null,
          },
        ]
      : []),
  ];

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const busy = invoices.busy;
  let tableData = busy ? [] : Object.values(invoicesList || {});
  let reportData = busy ? [] : Object.values(GenerateReportAllData.entities || {});
  const encodeBackUrl = utils.encodeBackUrl();

  // Below changes - baseUrl changes to Fix 404 Errors.
  let baseUrl = '';

  if (loc.includes('/network')) {
    baseUrl = '/network';
  } else if (loc.includes('/market')) {
    baseUrl = '/market';
  }

  tableData = tableData.map((d) => {
    return {
      ...d,
      deal_date_sorted: dayjs(d['from_date']).unix(),
      id: parseInt(d['invoice_master_id']),
      fromDate: dayjs(d.from_date).format('DD-MMM-YY'),
      toDate: dayjs(d.to_date).format('DD-MMM-YY'),
      validDate: dayjs(d.closing_On).format('DD-MMM-YY'),
      validTime: dayjs(d.closing_On).format('hh:ss a'),
      invoiceDateTime: dayjs(d['invoice_date']).unix(),
      detailsPageLink: getUrlWithEncryptedQueryParams(
        `${baseUrl}/invoices/${d['invoice_master_id']}?${
          loc.includes('controltower/invoices') ? 'isControlTower=true&' : ''
        }back=${encodeBackUrl}`,
      ),
    };
  });

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;
  tableData = _.orderBy(tableData, ['invoiceDateTime'], ['desc']);

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (back != undefined || back != null) {
      history.push(
        pathname + '?' + `back=${back}&page=1${isMarketOrCtInvoiceScreen && fiscal_year ? `&fiscal_year=${fiscal_year}` : ''}`,
      );
    } else {
      history.push(pathname + '?' + `&page=1${isMarketOrCtInvoiceScreen && fiscal_year ? `&fiscal_year=${fiscal_year}` : ''}`);
    }
  };

  const getExportReport = async () => {
    const params = {
      filters: filters,
      page_size: 10000,
      offset: 0,
    };

    let success;
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    if (isControlTowerUser && loc.includes('controltower/invoices')) {
      success = await dispatch(generateInvoiceReportActions.fetchListInvoiceReportCT(params));
    } else {
      success = await dispatch(generateInvoiceReportActions.fetchListInvoiceReport(params));
    }
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  const downloadInvoices = async () => {
    setIsDownloadingProgress(true);

    if (selectedRowKeys.length > invoiceDownloadLimit) {
      setIsOpenDownloadInvoiceModal(true);
      // notifications.show({
      //   type: 'info',
      //   message: `Please select up to ${invoiceDownloadLimit} invoices to download at a time.`
      // });
      setIsDownloadingProgress(false);
    } else {
      const params = {
        invoice_master_id: selectedRowKeys,
        recipient_id: [],
      };
      notifications.show({ type: 'info', message: 'Downloading Started...' });
      let success = await dealInvoicesService.downloadInvoices(params);
      if (success) {
        const downloadUrl = success;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.style.display = 'none';

        // Check if only one invoice is selected and prompt for a custom filename
        if (selectedRowKeys.length === 1) {
          let custom_invoice_number = invoicesList[selectedRowKeys[0]].custom_invoice_number;
          const suggestedFilename = `${custom_invoice_number}.pdf`;
          link.download = suggestedFilename;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        notifications.update({ type: 'success', message: 'Downloaded Successfully' });
        setIsDownloadingProgress(false);
      }
    }
  };

  const getExportReportMultipleInvoices = async () => {
    const params = {
      filters: {
        ...filters,
        invoice_master_id: selectedRowKeys?.toString(),
      },
      page_size: 10000,
      offset: 0,
    };

    let success;
    notifications.show({ type: 'info', message: 'Downloading Started...' });
    if (isControlTowerUser && loc.includes('controltower/invoices')) {
      success = await dispatch(generateInvoiceReportActions.fetchListInvoiceReportCT(params));
    } else {
      success = await dispatch(generateInvoiceReportActions.fetchListInvoiceReport(params));
    }
    if (success) {
      csvLink.current.link.click();
      notifications.update({ type: 'success', message: 'Downloaded Successfully' });
    }
  };

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow, page]);

  const onSelectChange = (selectedRow, selectedRows) => {
    setSelectedRowKeys(selectedRow);
    setSelectedRowKeysData(selectedRows);
  };

  console.log(selectedRowKeys, 'selectedRowKeys nikhil');
  console.log(selectedRowKeysData, 'selectedRowKeysData nikhil');

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    type: 'checkbox',
  };

  useEffect(() => {
    let filteredParams = FilterUtils.getFilters({
      commodity_deal_master_id,
      delivery_id,
      customer_vendor,
      invoice_buyer,
      invoice_seller,
      load_location_code,
      delivery_location_code,
      payment_due_date,
      invoice_status_enum,
      custom_invoice_number,
      invoice_date,
      signature_type,
      goodsservices,
      goods,
      type_of_invoice,
      settlement_invoice_type_id,
      settlement_invoice_category_id,
      payment_made_date,
      workflow_pay_on_date,
      invoice_verification_enum_code,
      invoice_verification_enum,
      workflow_invoice_verification_enum,
      workflow_invoice_verification_enum_code,
      advance_purpose_enum,
      advance_purpose_entity_id,
      fiscal_year,
    });
    setFilters(filteredParams);
    let filterParams = {
      ...filters,
      ...filteredParams,
      invoice_type_enum: '7',
    };
    const params = {
      filters: filterParams,
      page_size: rowsPerPage,
      offset: (parseInt(page) - 1) * rowsPerPage,
    };

    let newFilters = params['filters'];
    delete newFilters['back'];

    if (isControlTowerUser && loc.includes('controltower/invoices')) {
      dispatch(dealInvoicesActions.fetchListAgainstCT(params));
    } else if (invoiceRender == 'deal') {
      let dealParams = {
        ...params,
        person_id: loc.includes('control-tower/deal') && buyer_person_id && buyer_person_id,
        commodity_deal_master_id: parseInt(id),
      };
      dispatch(dealInvoicesActions.fetchList(dealParams));
    } else {
      dispatch(dealInvoicesActions.fetchListAgainstPersonID(params));
    }
  }, [
    commodity_deal_master_id,
    delivery_id,
    customer_vendor,
    invoice_buyer,
    invoice_seller,
    load_location_code,
    delivery_location_code,
    payment_due_date,
    invoice_status_enum,
    page,
    rowsPerPage,
    custom_invoice_number,
    signature_type,
    place,
    invoice_date,
    goodsservices,
    goods,
    type_of_invoice,
    settlement_invoice_type_id,
    settlement_invoice_category_id,
    payment_made_date,
    timestamp,
    workflow_pay_on_date,
    invoice_verification_enum_code,
    invoice_verification_enum,
    workflow_invoice_verification_enum,
    advance_purpose_enum,
    advance_purpose_entity_id,
    isReRenderRequired,
    fiscal_year,
  ]);

  useEffect(() => {
    const fetchedLinks = getLinks(
      isControlTowerUser,
      isTransporter,
      typeOfIndividualEnum,
      context,
      loc,
      is_zoho_connected,
    );
    setLinks(fetchedLinks);
  }, []);

  const setEmptyData = () => {
    setSelectedRowKeys([]);
    setSelectedRowKeysData([]);
    setCloseArray([]);
    setTimeStamp(Date.now());
  };
  const encodeBack = utils.encodeBackUrl();
  return (
    <div className="">
      {isOpenDownloadInvoiceModal && (
        <DownloadInvoiceModal
          isOpenDownloadInvoiceModal={isOpenDownloadInvoiceModal}
          setIsOpenDownloadInvoiceModal={setIsOpenDownloadInvoiceModal}
          selectedRowKeys={selectedRowKeys}
          companyId={companyId}
        />
      )}

      {earlyPaymentDone && earlyPaymentDone.is_early_payment_financed && (
        <FeedbackModal
          feedbackId={feedbackId}
          isOpenFeedbackModal={isOpenFeedbackModal}
          setIsOpenFeedbackModal={setIsOpenFeedbackModal}
          isFeedbackFormSubmitted={isFeedbackFormSubmitted}
          setIsFeedbackFormSubmitted={setIsFeedbackFormSubmitted}
        />
      )}

      {invoiceRender === 'market' && (
        <div className="bfc-header">
          <TableListHeader
            setEmptyData={setEmptyData}
            setFilters={setFilters}
            setRowsPerPage={setRowsPerPage}
            links={links}
            rightPanContent={
              <>
                {isMarketOrCtInvoiceScreen ? (
                  <div style={{width: 170, marginRight: 16}}>
                    <MUIAutocomplete
                      label="Financial Year"
                      data={utils.generateLastFiveYears()}
                      dataStructure={{ labelApiParam: 'label', valueApiParam: 'value' }}
                      apiParams={{}}
                      selectedCodeOrId={financialYear?.value}
                      onChange={(value, setValue) => {
                        if (value) {
                          setFinancialYear(value);
                          history.push(FilterUtils.createUrl({ ...filters, fiscal_year: value?.value }));
                        } else {
                          setValue(utils.generateLastFiveYears()?.find(item => item?.value === fiscal_year))
                          setOpen(true);
                        }
                      }}
                    />
                    <MUIModal
                      open={open}
                      setOpen={setOpen}
                      type='confirmation'
                      confirmationType='warn'
                      title='Reset Financial Year'
                      confirmationMessage='This may take a long time to fetch the data. Are you sure you wish to proceed?'
                      actionButtons={[
                        {
                        name : 'Yes', onClick: () => {
                          setFinancialYear('');
                          const filterData = { ...filters };
                          delete filterData['fiscal_year'];
                          setFilters(filterData);
                          history.push(FilterUtils.removeUrl('fiscal_year'));
                          setOpen(false);
                        }
                        },
                        {
                        name: 'No', onClick: () => {
                          setFinancialYear(utils.generateLastFiveYears()?.find(item => item?.value === fiscal_year));
                          setOpen(false);
                        }
                        }
                      ]}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="right-side__btn pr-3">
                  <Popover content="Bulk Workflow Approval Status">
                    <img
                      src={edit_svg}
                      style={{
                        height: 30,
                        width: 30,
                        cursor: showBulkInvoiceApprovalPopupButton ? 'pointer' : 'not-allowed',
                        opacity: showBulkInvoiceApprovalPopupButton ? 1 : 0.5,
                      }}
                      alt="Edit"
                      onClick={showBulkInvoiceApprovalPopupButton ? showBulkInvoiceApprovalPopup : null}
                    />
                  </Popover>
                </div>
                <MUIButton type="icon" Icon={Add} tooltipText='Create Advances' onClick={() => setAddAdvance(true)} />
                {(MarketLinks || isCTSupport) && (
                  <div className="ml-2 right-side__btn">
                    {isControlTowerUser || isSupplyPartner ? (
                      <>
                        {/* <Link
                          to={`/payouts?page=1&payment_mode=${
                            isPayoutsEnabled ? 'bulk' : 'offline'
                          }&payment_status=paid&back=${encodeBack}`}
                          >
                          RazorpayX Payouts
                        </Link> */}
                        <Link className='mr-2' to={`/payouts?page=1&payment_mode=${isPayoutsEnabled ? 'bulk' : 'offline'}&payment_status=paid&back=${encodeBack}`}>
                          <MUIButton type='icon' Icon={RazorPayXIcon} tooltipText='RazorpayX Payouts' onClick={() => {}}/>
                        </Link>
                      </>
                    ) : (
                      ''
                    )}
                    <Link
                      to={`/invoices/record-payment?back=${encodeBack}&ismarket=${isMarket}`}
                      className="btn btn-primary btn-sm">
                      Pay
                    </Link>
                  </div>
                )}
              </>
            }
          />
        </div>
      )}
      <div className="bfc-body">
        {paymentPopupVisible && (
          <div>
            <PopupForm
              isWorkFlowPopup={true}
              title={'Workflow Approval Status'}
              onClose={() => setPaymentPopupVisible(false)}>
              <InvoiceApprovalPopup
                timestamp={timestamp}
                setTimeStamp={setTimeStamp}
                paymentPopupVisible={paymentPopupVisible}
                toggle={toggle}
                setInvoiceDetails={setInvoiceDetails}
                invoiceDetails={invoiceDetails}
                dispatch={dispatch}
                page={page}
              />
            </PopupForm>
          </div>
        )}

        {bulkInvoiceApprovalPopupVisible && (
          <div>
            <PopupForm
              isWorkFlowPopup={true}
              title={'Bulk Workflow Approval Status'}
              onClose={() => {
                setBulkInvoiceApprovalPopupVisible(false);
              }}>
              <BulkInvoiceApprovalPopup
                paymentPopupVisible={paymentPopupVisible}
                isBulkWorkflowApproval={bulkInvoiceApprovalPopupVisible}
                bulkInvoiceDetails={selectedRowKeysData}
                selectedBulkInvoiceIds={selectedRowKeys}
                toggle={bulkApprovalToggle}
                setInvoiceDetails={setInvoiceDetails}
                invoiceDetails={invoiceDetails}
                dispatch={dispatch}
                page={page}
                setIsReRenderRequired={setIsReRenderRequired}
                setEmptyData={setEmptyData}
              />
            </PopupForm>
          </div>
        )}

        {addAdvance && (
          <PopupForm
            isWorkFlowPopup={true}
            title={'Advance'}
            onClose={() => {
              setAddAdvance(false);
            }}>
            <AddAdvancePopUp setTimeStamp={setTimeStamp} toggle={toggleAdvancePopup} />
          </PopupForm>
        )}

        <div className="invoice-table">
          <div className="ant-table-body ant-table">
            {invoiceRender == 'deal' ? (
              <Table
                ref={tableRef}
                dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                columns={
                  busy
                    ? INVOICE_TABLE_HEADERS.map((column) => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return <Skeleton active="true" key={column.dataIndex} title={true} paragraph={false} />;
                          },
                        };
                      })
                    : INVOICE_TABLE_HEADERS
                }
                pagination={false}
                onChange={handlePageChange}
                scroll={{ y: 550 }}
                className="ant-table"
                size="small"
              />
            ) : (
              <Table
                ref={tableRef}
                dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                columns={
                  busy
                    ? INVOICE_TABLE_HEADERS.map((column) => {
                        return {
                          ...column,
                          render: function renderPlaceholder() {
                            return <Skeleton active="true" key={column.dataIndex} title={true} paragraph={false} />;
                          },
                        };
                      })
                    : INVOICE_TABLE_HEADERS
                }
                pagination={false}
                onChange={handlePageChange}
                scroll={{ y: 550 }}
                className="ant-table"
                size="small"
                rowKey={(record) => record.invoice_master_id}
                rowCustomInvoiceNo={(record) => record.custom_invoice_number}
                rowSelection={rowSelection}
              />
            )}
            {invoiceRender == 'deal' ? (
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                rowsPerPage={rowsPerPage}
                setScrollToFirstRow={setScrollToFirstRow}
              />
            ) : (
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                reportData={reportData}
                getExportReport={getExportReport}
                getExportReportMultipleInvoices={getExportReportMultipleInvoices}
                downloadInvoices={downloadInvoices}
                csvLink={csvLink}
                type={'Invoices'}
                rowsPerPage={rowsPerPage}
                setScrollToFirstRow={setScrollToFirstRow}
                closeArray={selectedRowKeys}
                isDownloadingProgress={isDownloadingProgress}
                setIsDownloadingProgress={setIsDownloadingProgress}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancePaymentsListPage;
