import React, { useState } from 'react';
import { Select } from 'antd';
import './MultiEmailSelect.css';

const MultiEmailSelect = ({ teamMembers, onSelectChange }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const handleEmailsChange = (selectedValues) => {
    setSelectedIds(selectedValues);
    // If you need to lift the state up
    onSelectChange(selectedValues);
  };

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Select Email"
      onChange={handleEmailsChange}
      optionFilterProp="children"
      size="large"
      allowClear
      clearIcon
      showArrow
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {teamMembers.map((member) => (
        <Select.Option key={member.team_member_id} value={member.team_member_id}>
          {member.email_id}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MultiEmailSelect;

