import { createAction, createSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';
import api from 'lib/api';
import utils from 'lib/utils';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
import { kycApprovalService } from 'services';

const adapter = createEntityAdapter({
  // Assume IDs are stored in a field other than `id`
  // selectId: (deliveryScheduleDay) => _.get(deliveryScheduleDay, 'delivery_start_date_string', 0),
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => (a.name_of_farm || '').localeCompare(b.name_of_farm || ''),
});

const slice = createSlice({
  name: 'invoicesCT',
  // initialState: booksAdapter.getInitialState(),
  initialState: adapter.getInitialState({
    busy: false,
  }),
  // initialState: { ids: [], entities: {}, dirty: true },
  reducers: {
    setAll: adapter.setAll,
    addOne: adapter.addOne,
    addMany: adapter.addMany,
    updateOne: adapter.updateOne,
    updateMany: adapter.updateMany,
    upsertOne: adapter.upsertOne,
    upsertMany: adapter.upsertMany,
    removeOne: adapter.removeOne,
    removeMany: adapter.removeMany,
    removeAll: adapter.removeAll,
    setBusy(state, action) {
      let busy = action.payload;
      if (busy === false) {
        state.busy = false;
      } else {
        state.busy = true;
      }
    },
  },
});

const fetchList = (params) => async (dispatch) => {
  dispatch(slice.actions.setBusy(true));
  try {
    const resData = await kycApprovalService.fetchInvoiceListCT(params);
    dispatch(slice.actions.setAll(resData));
  } catch (err) {
    dispatch(slice.actions.setAll({}));
    utils.displayErrors(err);
  }
  dispatch(slice.actions.setBusy(false));
};

const actions = {
  ...slice.actions,
  fetchList,
};

// // Rename the exports for readability in component usage
// export const {
//   selectById: selectUserById,
//   selectIds: selectUserIds,
//   selectEntities: selectUserEntities,
//   selectAll: selectAllUsers,
//   selectTotal: selectTotalUsers
// } = usersAdapter.getSelectors(state => state.users)

const selectors = {
  ...adapter.getSelectors((state) => state.payments),
};

export { actions, selectors };

export default slice.reducer;
