import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { Formik, Field, Form, FieldArray } from 'formik';
import Bidicon from 'containers/Html/images/icon-bid.svg';
import searchLocationFrom from 'common/Form/searchLocationFrom';
import searchLocationTo from 'common/Form/searchLocationTo';
import { locationElocService } from 'services';

import MUITextField from '@material-ui/core/TextField';

import { useSelector, useDispatch } from 'react-redux';
import { actions as userActions } from 'redux/user';
import CompanySelect from 'pages/commodity/components/CompanySelect';

// import Collapse from './../components/Collapse';
import utils from 'lib/utils';
import * as handlers from './handlers';

const getInitialValues = (address = {}) => {
  let formFields = {
    entity_id: _.get(address, 'entity_id') || 0,
    transport_routes_served_id: _.get(address, 'transport_routes_served_id') || 0,
    person_company_affiliate_enum: _.get(address, 'person_company_affiliate_enum') || 0,
    from_location_code: _.get(address, 'from_district_code') || '',
    from_district_code: _.get(address, 'from_district_code') || '',
    from_state_code: _.get(address, 'from_state_code') || '',
    from_country_code: 'India',
    from_place_id: _.get(address, 'from_place_id') || '',
    to_location_code: _.get(address, 'to_district_code') || '',
    to_district_code: _.get(address, 'to_district_code') || '',
    to_state_code: _.get(address, 'to_state_code') || '',
    to_country_code: 'India',
    to_place_id: _.get(address, 'to_place_id') || '',
    status_enum: true,
  };
  return formFields;
};

const LocationForm = ({
  isEdit,
  setIsEdit,
  setOpen,
  selectedLocation,
  setSelectedLocation,
  isRerenderRequired,
  setIsRerenderRequired,
}) => {
  const locationDetails = selectedLocation || {};

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(locationDetails)}
        onSubmit={async (values, formik) => {
          values['from_country_code'] = 'India';
          values['to_country_code'] = 'India';
          let params = { ...values, operation_enum: isEdit ? 3 : 1 };
          const success = await handlers.handleCreateTransportRouteServed(params, formik);
          if (success) {
            setSelectedLocation({})
            formik.resetForm()
            setOpen(false);
            setIsEdit(false);
            setIsRerenderRequired(!isRerenderRequired);
          }
        }}>
        {(formikBag) => {
          const { values } = formikBag;
          return (
            <div>
              <div>
                <Form className="form-container">
                  <div className="form-wrapper full-width">
                    <div className="contailer-fluid">
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">Business Entity</span>
                          <div className="form-group-field">
                            <div className="form-group-field">
                              <Field
                                name="entity_id"
                                component={CompanySelect}
                                placeholder="Select Company"
                                checkKYCStatus={false}
                                initialDisplayText={values.entity_name || ''}
                                cb={(newVal) => {
                                  let type = _.get(newVal, 'value.type') || '';
                                  let entity_id = _.get(newVal, 'value.id') || 0;
                                  if (type === 'company') {
                                    formikBag.setFieldValue('person_company_affiliate_enum', 2);
                                  } else if (type === 'affiliate') {
                                    formikBag.setFieldValue('person_company_affiliate_enum', 3);
                                  }
                                  if (entity_id) {
                                    formikBag.setFieldValue('entity_id', entity_id);
                                    formikBag.setFieldValue('address_id', 0);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">From Location</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name={`from_location_code`}
                              component={searchLocationFrom}
                              cb={(locationValues) => {
                                let locationDetails = locationValues[0] || {};
                                formikBag.setFieldValue(
                                  'from_district_code',
                                  _.get(locationDetails.addressTokens, 'district') || '',
                                );
                                formikBag.setFieldValue(
                                  'from_state_code',
                                  _.get(locationDetails.addressTokens, 'state') || '',
                                );
                                formikBag.setFieldValue(
                                  'from_country_code',
                                  _.get(locationDetails.addressTokens, 'country') || '',
                                );
                                formikBag.setFieldValue('from_place_id', _.get(locationDetails, 'eLoc') || '');
                                formikBag.setFieldValue(
                                  'from_location_code',
                                  (_.get(locationDetails, 'placeName', '')
                                    ? _.get(locationDetails, 'placeName', '') + ', '
                                    : '') + _.get(locationDetails, 'placeAddress', ''),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form__form-group">
                        <div className="col">
                          <span className="form-group-label">To Location</span>
                          <span class="required">*</span>
                          <div className="form-group-field">
                            <Field
                              name={`to_location_code`}
                              component={searchLocationTo}
                              initialDisplayText={values.to_district_code || ''}
                              cb={(locationValues) => {
                                let locationDetails = locationValues[0] || {};
                                formikBag.setFieldValue(
                                  'to_district_code',
                                  _.get(locationDetails.addressTokens, 'district') || '',
                                );
                                formikBag.setFieldValue(
                                  'to_state_code',
                                  _.get(locationDetails.addressTokens, 'state') || '',
                                );
                                formikBag.setFieldValue(
                                  'to_country_code',
                                  _.get(locationDetails.addressTokens, 'country') || '',
                                );
                                formikBag.setFieldValue('to_place_id', _.get(locationDetails, 'eLoc') || '');
                                formikBag.setFieldValue(
                                  'to_location_code',
                                  (_.get(locationDetails, 'placeName', '')
                                    ? _.get(locationDetails, 'placeName', '') + ', '
                                    : '') + _.get(locationDetails, 'placeAddress', ''),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Button
                      color="primary"
                      type="button"
                      onClick={formikBag.handleSubmit}
                      className="next bfc-button-next">
                      {isEdit ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default LocationForm;
