import React, { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import { buyService } from 'services';
import history from 'lib/history';
import _ from 'lodash'
import { Link, BrowserRouter } from 'react-router-dom'
import MatchingPopUpData from './MatchingPopupData';

const MatchingPopUp = (props) => {
  const loadingDataForFirstTime = useRef(1);
  const { values } = props;
  const messageKeyRef = useRef(0);
  const oldValues = useRef(values);
  let fetchNewData = false;
  const messageKey = Math.floor(Math.random() * 1000000);
  const { publish, actual_plan_from_date, actual_plan_to_date, additional_comments, ...transformValues } = values
  const areTwoValueObjectNotEqual = !_.isEqual(oldValues.current, transformValues);

  if (areTwoValueObjectNotEqual) {
    fetchNewData = true;
  }

  if (loadingDataForFirstTime.current === 1 || fetchNewData) {
    if (!fetchNewData) {
      messageKeyRef.current = messageKey;
    }

    const config = {
      duration: 0,
      maxCount: 1,
      key: messageKey,
      content: <MatchingPopUpData data={props}
        loadingDataForFirstTime={loadingDataForFirstTime}
        oldValues={oldValues}
        messageKey={messageKey}
        messageKeyRef={messageKeyRef}
        fetchNewData={fetchNewData}
      />,

    };

    return (message.open(config));
  } else {
    return null
  }
}

export default React.memo(MatchingPopUp)

