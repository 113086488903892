import _ from 'lodash';
import utils from './../utils';
import config from '../../config';

const { baseUrl } = config;

const api = (() => {
  const getTransportRequirementDetails = async (id = 0) => {
    // try {
    //   const resData = await utils.processApiRes(transRes);
    //   return resData;
    // } catch (err) {
    //   return err;
    // }
    try {
      const backendFields = {
        Commodity_Req_Offer_Id: id,
      };

      const bodyParams = backendFields;

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams)
      const url = `${baseUrl}/BioFuelRequirementOffers/api/Requirement/FetchRequirementAgainstId_ELOC?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      delete resData['is_Followed_By_Person'];
      delete resData['isfollowedbyperson'];

      return resData;
    } catch (err) {
      console.log('getRequirementDetails->', err);

      const errors = await utils.processApiErrors(err);
      throw errors;
    }
  };

  const createTransportRequirement = async (params) => {
    // console.log('in api ---', params);
    try {
      const backendFields = {
        transport_Req_Offer_Id: 0,
        internal_Company_Id: 0,
        hire_Type_Enum: 1,
        cargo_Type_Enum: 1,
        vehicle_Type_Enum: 1,
        number_Of_Vehicles: 0,
        max_Capacity_Of_Vehicle_Type: 0,
        max_Capacity_Uom_Id: 0,
        open_Number_Of_Vehicles_To_Hire: 0, //////////////
        from_date: '2020-11-24T03:32:30.745Z',
        to_date: '2020-11-24T03:32:30.745Z',
        per_Day_Rate: 0,
        per_Km_Rate: 0,
        rate_Currency_Id: 0,
        rate_Uom_Id: 0,
        minimum_Km_Per_Day: 0,
        extra_Run_Rate_Per_Km: 0,
        copy_Rate_Per_Km_Flag: true, ////////////////////////
        operating_Circle_Id: 0,
        payment_Term_Id: 0,
        additional_Comments: 'string',
        offer_Validity_Datetime: '2020-11-24T03:32:30.745Z',
        entity_Id: 0,
        user_Type_Enum: 0,
        company_Affiliate_Enum: 0,
      };

      // console.log('back end fields -------', backendFields);
      const bodyParams = utils.getBackendParams(backendFields, params);

      // //TODO : tmp hard coding and changes remove later
      // bodyParams.periodicity_enum = parseInt(bodyParams.periodicity_enum);
      // bodyParams.commodity_Form_Id = parseInt(bodyParams.commodity_Form_Id);
      // bodyParams.commodity_Residue_Id = parseInt(bodyParams.commodity_Residue_Id);

      // bodyParams.total_qty = utils.caculateFinalQuantity(
      //   bodyParams.qty,
      //   bodyParams.periodicity_enum,
      //   bodyParams.from_date,
      //   bodyParams.to_date,
      // );

      // bodyParams.person_id = _.get(window, 'user.userid', 0);
      // // bodyParams.delivery_location_id = 1584;
      // bodyParams.address_Id = 1;
      // bodyParams.company_Affiliate_Enum = 1;
      // bodyParams.user_Type_Enum = 2;
      // ///////////////////

      const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportRequirement/SaveTransportRequiremet`;

      const res = await utils.fetch(url, {
        method: 'POST',
        body: JSON.stringify(bodyParams),
      }).ready;

      const resData = await utils.processApiRes(res);

      return resData;
    } catch (err) {
      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  const getAllTransportRequirements = async (params = {}) => {
    try {
      // TODO : get user id from window for
      // all apis

      // debugger;

      const backendFields = {
        // Entity_Id: 1, // TODO remove hardcoding
        Page_Size: 5000, // TODO remove hardcoding
        User_Type_Enum: 1, // TODO remove hardcoding
        Company_Affiliate_Enum: 1,
        Offset: 0, // TODO remove hardcoding
        Entity_Id: _.get(window, 'user.userid', 0), // TODO remove hardcoding
      };

      const bodyParams = utils.getBackendParams(backendFields, params);

      // const qs = Object.keys(bodyParams)
      //   .map((key) => `${key}=${bodyParams[key]}`)
      //   .join('&');
      const qs = utils.getQs(bodyParams)
      const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/TransportRequirement/FetchAllTransportRequirementWithinCircle?${qs}`;

      const res = await utils.fetch(url).ready;

      const resData = await utils.processApiRes(res);

      // console.log('getAllTransportRequirements-res->data', resData);

      return resData;
    } catch (err) {
      console.log('getAllTransportRequirements->', err);

      const errors = await utils.processApiErrors(err);

      throw errors;
    }
  };

  return {
    createTransportRequirement,
    getTransportRequirementDetails,
    getAllTransportRequirements,
  };
})();

export default api;
