import React from 'react';
import PropTypes from 'prop-types';
import utils from 'lib/utils';

import './styles.scss';

function QuantityField({ qty, periodicity_enum_code, qty_uom_code, isPickupOffersScreen = false }) {
  return (
    <div className="bfc-table-list-components d-flex justify-content-end">
      <div className="quantity-field" style={{ marginTop: '2px', }}>
        <p className="quantity-field-values">
          {utils.formatQty(qty)}
          <br />
          <span className="periodicity-enum-code" style={{ marginTop: '3px', }}>
            {(isPickupOffersScreen || periodicity_enum_code === 'Once')
              ? qty_uom_code
              : qty_uom_code + '/' + utils.formatperiodicity(periodicity_enum_code)}
          </span>
        </p>
      </div>
    </div>
  );
}

QuantityField.propTypes = {
  qty: PropTypes.number.isRequired,
  periodicity_enum_code: PropTypes.string.isRequired,
  qty_uom_code: PropTypes.string.isRequired,
};

export default QuantityField;
