import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from 'reactstrap';
import StarRatings from 'react-star-ratings';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import PostLoader from 'pages/socialFeed/components/Feed/PostLoader';
import { actions as ratingDetailsActions } from 'redux/ratingDetails';
import { RATING_TYPE_ENUM } from 'constants/enums';

const NestedRatingsPopup = ({
  ratingsArray,
  extendedProfilePersonId,
  userId,
  toggle,
  color,
  btn,
  editPageLink,
  title,
  message,
  colored,
  header,
  isOpen,
  rating,
  company_affiliate_enum,
  entity_id,
  isPerson,
  companyName,
  openInAnotherTab
}) => {
  const ratingDetails = useSelector((state) => state.ratingDetails);
  const details = _.get(ratingDetails, 'entities') || {};
  const participation = _.get(details, RATING_TYPE_ENUM.PARTICIPATION) || {};
  const transaction = _.get(details, RATING_TYPE_ENUM.TRANSACTION) || {};
  const quality = _.get(details, RATING_TYPE_ENUM.QUALITY) || {};
  const quantity = _.get(details, RATING_TYPE_ENUM.QUANTITY) || {};
  const payments = _.get(details, RATING_TYPE_ENUM.PAYMENTS) || {};
  const ontime = _.get(details, RATING_TYPE_ENUM.ONTIME) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    let params;
    if (isPerson) {
      params = {
        entity_id: entity_id,
      };
    } else {
      params = {
        entity_id: entity_id,
        company_affiliate_enum: company_affiliate_enum,
      };
    }
    dispatch(ratingDetailsActions.fetchDetails(params));
  }, []);

  useEffect(()=>{
    if(isOpen){
      gtag('event', 'rating_drill_down_2',
      {
        Participation : ratingsArray.filter((data) => data.rating_type === 'Participation' ).map((data)=> data.ratings ),
        Transaction : ratingsArray.filter((data) => data.rating_type === 'Transaction' ).map((data)=> data.ratings ),
        Quantity : ratingsArray.filter((data) => data.rating_type === 'Quantity' ).map((data)=> data.ratings ),
        Payments : ratingsArray.filter((data) => data.rating_type === 'Payments' ).map((data)=> data.ratings ),
        OnTime : ratingsArray.filter((data) => data.rating_type === 'OnTime' ).map((data)=> data.ratings ),
        totalRating : rating,
        viewPersonId : extendedProfilePersonId,
        userId : userId ? userId : null,
        companyName : companyName,
        otherCompanyName : ratingsArray.filter((data) => data.rating_type === 'Participation' ).map((data)=> data.compnay_code ),
      })
    }
  },[isOpen])

  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div className="bfc-post-text-editor-modal">
      <div className="bfc-follow-editor-section">
        <Button className="d-none" color={color} onClick={toggle}>
          {btn}
        </Button>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          modalClassName="ltr-support theme-light bfc-post-text-editor-modal nesting-rating-modal"
          className={`modal-dialog--${color} ${modalClass} `}>
          <Formik initialValues={{}}>
            {(formikBag) => {
              const { values } = formikBag;
              return (
                <Form className="material-form follow-modal">
                  <div className="modal__header" style={{ borderBottom: 'transparent!important' }}>
                    <p className="text-modal modal__title bfc-modalTitle">
                      Total Rating {rating}
                      <button aria-label="Close" className=" close" type="button" onClick={toggle}>
                        <span aria-hidden>×</span>
                      </button>
                    </p>
                  </div>
                  <div className="modal__body scrollbar p-0" id="style-1" style={{ display: 'inline-table!important' }}>
                    <div className="pl-30 pr-30">
                      <div className="row">
                        <div className="col-md-6" style={{ borderRight: '1px solid #d6d6d6' }}>
                          <div style={{ borderBottom: '1px solid var(--unnamed-color-ccc)' }}>
                            <div class="d-flex align-items-center">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                Participation
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={participation.rating ? parseFloat(participation.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {participation.rating ? parseFloat(participation.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(participation.rating) > 0 ? (
                              <p class="" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                There is a{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {participation?.decision_ratio ? participation?.decision_ratio : 0}% chance
                                </span>{' '}
                                this Buyer/ Seller will take a decision on their Buy or Sell. This participant has a{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {participation?.wining_ratio ? participation?.wining_ratio : 0}% probability
                                </span>{' '}
                                of submitting a winning bid or offer in response to your Sell/ Buy
                              </p>
                            ) : (
                              <p class="p-2" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                We do not have sufficient data to calculate this rating
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-10">
                          <div style={{ borderBottom: '1px solid var(--unnamed-color-ccc)' }}>
                            <div class="d-flex align-items-center">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                Quantity
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={quantity.rating ? parseFloat(quantity.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {quantity.rating ? parseFloat(quantity.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(quantity.rating) > 0 ? (
                              <p class="" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                On Average, for this participant the Actual Quantity delivered is within{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {quantity?.quantity_score ? quantity?.quantity_score : 0}%
                                </span>{' '}
                                of the Scheduled Quantity (excluding an operational tolerance of 5%)
                              </p>
                            ) : (
                              <p class="p-2" style={{ minHeight: '90px',fontSize: 'var(--fs-base__two)', }}>
                                We do not have sufficient data to calculate this rating
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6" style={{ borderRight: '1px solid #d6d6d6' }}>
                          <div style={{ borderBottom: '1px solid var(--unnamed-color-ccc)' }}>
                            <div class="d-flex align-items-center mt-10">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                Transaction
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={transaction.rating ? parseFloat(transaction.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {transaction.rating ? parseFloat(transaction.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(transaction.rating) > 0 ? (
                              <p class="" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                On average, in about{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {transaction?.fulfillment_score ? transaction?.fulfillment_score : 0} out of 10 times
                                </span>{' '}
                                , this participant will not walk away once a deal is concluded. This puts them in the{' '}
                                {transaction?.top_bottom == 'Top' ? (
                                  <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                    {transaction?.top_bottom}{' '}
                                    {transaction?.top_bottom_value
                                      ? 100 - transaction?.top_bottom_value === 0
                                        ? 1
                                        : 100 - transaction?.top_bottom_value
                                      : 0}
                                    %
                                  </span>
                                ) : (
                                  <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                    {transaction?.top_bottom}{' '}
                                    {transaction?.top_bottom_value ? transaction?.top_bottom_value : 0}%
                                  </span>
                                )}{' '}
                                of participants on the platform
                              </p>
                            ) : (
                              <p class="p-2" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                We do not have sufficient data to calculate this rating
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 mb-10">
                          <div style={{ borderBottom: '1px solid var(--unnamed-color-ccc)' }}>
                            <div class="d-flex align-items-center mt-10">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                On-Time
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={ontime.rating ? parseFloat(ontime.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {ontime.rating ? parseFloat(ontime.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(ontime.rating) > 0 ? (
                              <p class="" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {ontime?.on_time_frequency ? ontime?.on_time_frequency : 0}%
                                </span>{' '}
                                of the Deliveries from this Seller are On-Time, as per mutually agreed Scheduled Date.
                                Whenever there was a confirmed delay, on average the delivery was late by about{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {ontime?.average_delay}
                                </span>{' '}
                                days
                              </p>
                            ) : (
                              <p class="p-2" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                We do not have sufficient data to calculate this rating
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6" style={{ borderRight: '1px solid #d6d6d6' }}>
                          <div>
                            <div class="d-flex align-items-center mt-10">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                Quality
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={quality.rating ? parseFloat(quality.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {quality.rating ? parseFloat(quality.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(quality.rating) > 0 ? (
                              <p class="" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                Only{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {quality?.quality_adjustment_score ? quality?.quality_adjustment_score : 0} in 100
                                </span>{' '}
                                deliveries made by this Seller have reported quality issues. And{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {quality?.quality_rejection_score ? quality?.quality_rejection_score : 0}
                                </span>{' '}
                                of the their deliveries till date have been rejected by the Buyer. You can find more
                                insights in the Seller’s <a href={editPageLink} target={openInAnotherTab ? "_blank" : "_self"}>Quality Profile</a>
                              </p>
                            ) : (
                              <p class="p-2" style={{ minHeight: '90px', fontSize: 'var(--fs-base__two)', }}>
                                We do not have sufficient data to calculate this rating
                              </p>
                            )}
                            <hr className="d-block d-sm-none" style={{ borderColor: 'var(--unnamed-color-ccc)' }} />
                          </div>
                        </div>

                        <div className="col-md-6 mb-10">
                          <div>
                            <div class="d-flex align-items-center mt-10">
                              <div class="mr-auto" style={{ fontSize: 'var(--fs-base__three)', }}>
                                Payment
                              </div>
                              <div class="p-2">
                                <StarRatings
                                  rating={payments.rating ? parseFloat(payments.rating) : 0}
                                  starRatedColor="#ffcc33"
                                  numberOfStars={5}
                                  starDimension="14px"
                                  starSpacing="1px"
                                  name="rating"
                                  trigger="click"
                                />
                              </div>
                              <div class="p-1 mt-2" style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__four)', fontWeight: 'bold' }}>
                                {payments.rating ? parseFloat(payments.rating) : 0}
                              </div>
                            </div>
                            {parseFloat(payments.rating) > 0 ? (
                              <p class="" style={{ fontSize: 'var(--fs-base__two)', }}>
                                In{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {payments?.on_time_payment ? payments?.on_time_payment : 0} out of 100
                                </span>{' '}
                                instances, this participant pays their invoices on time.At times when payment was
                                overdue, in most cases the typical delay was between{' '}
                                <span style={{ color: 'var(--unnamed-color-1b62ab)', fontSize: 'var(--fs-base__three)', fontWeight: 'bold' }}>
                                  {payments?.one_time_payment ? payments?.one_time_payment : 0}
                                </span>{' '}
                                days.
                              </p>
                            ) : (
                              <p class="p-2" style={{ fontSize: 'var(--fs-base__two)', }}>We do not have sufficient data to calculate this rating</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </div>
    </div>
  );
};

NestedRatingsPopup.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

NestedRatingsPopup.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
  color: 'primary',
};

export default NestedRatingsPopup;
