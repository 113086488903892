import * as api from './api';

export const fetchFollowPreferences = async (params) => {
  try {
    const res = await api.fetchFollowPreferences(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchFollowPreferences_CT = async (params) => {
  try {
    const res = await api.fetchFollowPreferences_CT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPersonCompanyData = async (params) => {
  try {
    const res = await api.fetchPersonCompanyData(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const saveUpdateFollowPreference = async (params) => {
  try {
    const res = await api.saveUpdateFollowPreference(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const callFollowPreferenceApi = async (params, apiString, isQueryString) => {
  try {
    const res = await api.callFollowPreferenceApi(params, apiString, isQueryString);
    return res;
  } catch (err) {
    throw err;
  }
};
