import React from 'react';
import OtpInput from 'react-otp-input';
import { getIn } from 'formik';

const FormikOTPField = (props) => {
  const {
    field,
    form: { touched, errors },

    ...rest
  } = props;

  let errClass = !!(getIn(touched, field.name) && getIn(errors, field.name)) ? 'bfc-has-error' : '';

  return (
    <div className={`formik-otp-field ${errClass}`}>
      <OtpInput
        {...field}
        onChange={(otp) => {
          props.form.setFieldValue(field.name, otp);
        }}
        numInputs={6}
        // separator={<span>-</span>}
        isInputNum={true}
        inputStyle="otp-input"
        containerStyle="otp-input-container"
        shouldAutoFocus={true}
      />
      {!!(getIn(touched, field.name) && getIn(errors, field.name)) && (
        <span className="form__form-group-error otp-err-text">{getIn(errors, field.name)}</span>
      )}
    </div>
  );
};

export default FormikOTPField;
