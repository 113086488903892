import React, { useState } from 'react';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import { addMinutes, isPast, isToday } from 'date-fns';

const FormIkDatePickerFieldNotConfirmed = (props) => {
  // const { fieldName, placeholder, formikBag } = props;
  // const { touched, errors } = formikBag;

  const { field, form, fieldName, defaultValue, placeholder, minDate, disabled = false, minTime } = props;
  const { touched, errors } = form;

  const name = field.name || fieldName;

  const [date, setDate] = useState(props.defaultValue ? new Date(props.defaultValue) : null);

  const handleChange = (updatedDate) => {
    setDate(updatedDate);
    form.setFieldValue(name, updatedDate);
    props.setIsPickupDateChanged && props.setIsPickupDateChanged(true);
    if (moment(updatedDate).isSame(moment(), 'day')) {
      const hrs = new Date(updatedDate).getHours();
      setDate(hrs ? updatedDate : addMinutes(new Date(), 30));
      const newDate = moment(addMinutes(new Date(), 30)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      form.setFieldValue(name, hrs ? updatedDate : newDate);
      calculateMinTime(updatedDate);
    }
  };

  const hasError = touched[name] && errors[name];

  const calculateMinTime = (newDate) => {
    let isToday = moment(newDate).isSame(moment(), 'day');
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return moment(newDate).startOf('day').toDate();
  };

  const filterPassedTime = (todaysDate) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    const totalTime = isPast(new Date(date))
      ? currentDate.getTime() < selectedDate.getTime()
      : isToday(date)
      ? new Date().getTime() < new Date(todaysDate).getTime()
      : new Date().getTime();
    return totalTime;
  };

  return (
    <div className="date-picker">
      <div className={`${hasError ? 'has-error' : ''} bfc-date-time-wrapper w-100"`}>
        {/* <div className="date-picker date-picker--single"> */}
        <DatePicker
          // timeFormat="HH:mm"
          // className="form__form-group-datepicker"
          selected={date}
          selectsStart
          onChange={handleChange}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          dropDownMode="select"
          withPortal={isMobileOnly}
          placeholderText={placeholder}
          disabled={disabled}
          minDate={new Date()}
          // filterTime={filterPassedTime}
        />
        {touched[name] && errors[name] && <span className="form__form-group-error">{errors[name]}</span>}
      </div>
    </div>
  );
};

export default FormIkDatePickerFieldNotConfirmed;
