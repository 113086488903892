import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/walk-in-1.svg';

function WalkInIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default WalkInIcon;
