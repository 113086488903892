import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';

const FormikMaterialTextField = (props) => {
  // console.log('MaterialFormikTextField--->props', props)

  const {
    field,
    form: { touched, errors },
    variant,
    disabled,
    type,
    isNumberAlphabetAllow = false,
    autoCapitalize = false,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  // if (field.name === 'formData.basicInfo.name_of_farm') {
  //   console.log('\n---------FormikMaterialTextField----------');
  //   console.log('field.name-->', field.name);
  //   console.log('field-->', field);
  //   console.log('props->', props);
  //   console.log('errors->', errors);
  //   console.log('touched->', touched);
  //   console.log('hasError-->', hasError);
  //   console.log('errText-->', errText);
  // }

  return (
    <div className={`mui-formik-text-field ${hasError ? 'has-error' : ''}`}>
      <TextField
        fullWidth
        variant={variant || 'outlined'}
        size="small"
        disabled={disabled}
        error={hasError}
        helperText={errText}
        type={type}
        {...field}
        onInput={(e) => {
          e.target.value = isNumberAlphabetAllow ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, '') : e.target.value;
          e.target.value = autoCapitalize ? e.target.value.toUpperCase() : e.target.value;
        }}
      />
    </div>
  );
};

export default FormikMaterialTextField;
