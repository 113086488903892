import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, IconButton, Slide } from '@material-ui/core';
import LogoLoader from 'common/LogoLoader';
import MaintenanceIcon from 'assets/images/maintenance-icon.svg';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { actions as systemActions } from 'redux/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ServerMaintenanceModal = ({
  isOpen,
  // setOpen,
  title,
  icon,
  size = 'md',
  busy,
  children,
  transition,
  closeOnBlur,
  loaderStyle,
  isIconRequired = true,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen || false);
  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const onClose = () => {
    setOpen(false);
    dispatch(systemActions.updateServerUnderMaintenanceStatus(false));
  };

  const handelClose = () => {
    setOpen(false);
    handelOnCloseModal && handelOnCloseModal();
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      onClose();
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      className="maintenace-dialog"
      maxWidth={size}
      open={open}
      onClose={closeOnBlur && handelClose}
      {...rest}
      TransitionComponent={Transition}
    >
      <DialogContent className="dialog-content">
        <div className="server-maintenance">
          <div className="commodity-wrapper">
            {busy && (
              <div className="commodity-wrapper">
                <div className="panel__refresh" style={loaderStyle ? loaderStyle : { transform: 'translate(50%,50%)' }}>
                  <LogoLoader />
                </div>
              </div>
            )}
            <div className="commodities-popup">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img src={MaintenanceIcon} className="maintenance-icon" />
                </div>
                <div className="col-md-4 ml-3" style={{ width: '408.71px' }}>
                  <div className="server-maintenance-text">The server is busy. </div>
                  <div className="server-maintenance-subtext mt-2">
                    The server is experiencing high traffic. Please wait and try again later.
                  </div>
                  <div className="btn-section mt-30">
                    <Button className="" color="primary" onClick={() => window.location.reload(false)}>
                      Refresh
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className='row'>
              <div className='col-md-6' style={{ width: '50%' }}>
                <img
                  src={MaintenanceIcon}
                  style={{ width: '408px', height: '316px' }}
                />
              </div>
              <div className='col-md-6' style={{ width: '50%' }}>
                <p>The server is busy.</p>
                <p>we're on it & you'll be back online soon!</p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ServerMaintenanceModal;
