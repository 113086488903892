import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Col, Card, CardBody } from 'reactstrap';
import _ from 'lodash';
import { actions as notificationActions } from 'redux/notifications';
// import Loader from 'common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import profilePlaceholder from 'assets/images/placeholders/profileThumb.png';
import NotificationActivity from './NotificationActivity';
import utils from 'lib/utils';

const NotificationActivities = (props) => {
  const dispatch = useDispatch();
  const { busy, ids, entities, hasMore } = useSelector((state) => state.notifications);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true);

  const loadMore = () => {
    const loadmore = true;
    const params = {
      offset: ids.length,
      page_size: 10,
    };

    dispatch(notificationActions.fetchAll(params));
    //if last page loading infinite time then we need total_count from API to inidicate total numbers of records
    // if (parseInt(page) * 10 == total_count) {
    //   setHasMore(false);
    // }
  };

  useEffect(() => {
    let params = {
      offset: 0,
      page_size: 100,
    };
    dispatch(notificationActions.fetchAll(params));
  }, []);
  useEffect(() => {
    setHasMoreNotifications(hasMore);
  }, [hasMore]);
  return (
    <div className="profile__card  container notifications-box">
      <div className="row">
        <div className="col">
          <InfiniteScroll dataLength={ids.length} next={loadMore} hasMore={hasMoreNotifications} >
            {ids.map((id) => (
              <NotificationActivity
                busy={busy}
                children={entities[id].notification_text}
                name={entities[id].header_description}
                img={entities[id].profile_picture_thumbnail}
                date={dayjs(entities[id].notification_sent_on).format('YYYY-MM-DD HH:mm:ss')}
                data={entities[id]}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default NotificationActivities;
