import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { getIn } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import notifications from 'lib/notifications';
import CloseIcon from 'mdi-react/CloseIcon';
import MUITextField from 'common/MUIComponents/TextField';

const FormikMaterialTextField = (props) => {

  const {
    field,
    form: { touched, errors, values },
    variant,
    disabled,
    cb,
    isMarketView,
    isFarmerUser,
    ...rest
  } = props;

  const hasError = !!(getIn(touched, field.name) && getIn(errors, field.name));
  const errText = hasError ? getIn(errors, field.name) : '';

  const [tokenOAuth, setTokenOAuth] = useState('');

  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');
  // setTokenOAuth(token);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(field.value);
    if (!field.value && values?.location_code) {
      setInputValue(values?.location_code)
    }
  }, [field.value, values]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (value.length > 3) {

    }
    setInputValue(value);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://apis.mapmyindia.com/advancedmaps/api/${token}/map_sdk_plugins`;
    script.async = true;
    script.onload = () => {
      // Place search plugin

      let optional_config = {
        location: [28.61, 77.23],
        bridge: false,
        tokenizeAddress: true,
        geolocation: false,
      };

      window.MapmyIndia.search(document.getElementById('auto'), optional_config, function (data) {
        //pass data to callback function

        if (!data.hasOwnProperty('error')) {
          if (
            !data[0]?.addressTokens?.district ||
            (!data[0]?.addressTokens?.village && !data[0]?.addressTokens?.city && !data[0]?.addressTokens?.subDistrict)
          ) {
            notifications.show({
              type: 'error',
              message: 'Village or Town not found. Please search your Village/ Town again',
              key: 'req-form',
            });
            isMarketView ? setInputValue(data[0]?.placeName) : null;
            cb({});
          } else {
            isMarketView ? setInputValue(data[0]?.placeName + ", " + data[0]?.placeAddress) : null;
            cb(data);
          }
        }

        // if(data[0].addressTokens.district != ""){
        // cb(data);
        // }
      });
    };
    document.head.appendChild(script);
    // remove script after adding
    setTimeout(() => {
      document.head.removeChild(script);
    }, 100);
  }, [token]);

  return (
    <div style={{ position: isMarketView && "relative" }}>
      {isFarmerUser ? (
        <MUITextField
          size={props.size || 'medium'}
          id="auto"
          className={props?.className ? props.className : 'search-outer form-control as-input'}
          placeholder={'Search landmark / village / town'}
          label={props.label}
          value={inputValue}
          onChange={(newValue) => setInputValue(newValue)}
          disabled={disabled}
          {...rest}
        />
      ) : (<>
        <TextField
          {...rest}
          variant={variant || "outlined"}
          size="small"
          type="text"
          id="auto"
          name="auto"
          className={props?.className ? props.className : "search-outer form-control as-input"}
          placeholder={"Search landmark / village / town"}
          label={props.label}
          required=""
          spellCheck="false"
          value={inputValue}
          onChange={handleInputChange}
          disabled={disabled}
          style={{ backgroundColor: 'var(--unnamed-color-ffffff)', height: '40px' }} />
        {(isMarketView && inputValue !== "") &&
          <div style={{ position: "absolute", right: "5px", top: "7px", zIndex: 1000000, backgroundColor: "white" }}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setInputValue("");
              }}
            />
          </div>
        }
        <span style={{ color: 'var(--unnamed-color-f44336)', fontSize: 'var(--fs-base__three)', fontFamily: 'var(--unnamed-font-segoeUI)', }}>{errors.location_code}</span>
      </>
      )}
    </div>
  );
};

export default FormikMaterialTextField;
