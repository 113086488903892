import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import LogoLoader from 'common/LogoLoader';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import {  Skeleton } from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';

import AntPagination from 'common/AntTablePagination';
import TableListHeader from 'common/TableListHeader';
import useQuery from 'lib/hooks/useQuery';
import AntTableActions from 'common/TableActions';
import { actions as gpaAccountActions } from 'redux/loadingUnloading';
import FilterUtils from 'lib/filterUtils';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import MultiSelectFilter from 'common/Form/AntFilters/MultiSelectFilter';
import { getDecryptedUrl } from 'lib/utils';

const GPALog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();

  let { page, agreement, accepted_by, accepted_for, accepted_person_email_id, accepted_on } = useQuery();
  const loc = getDecryptedUrl(window.location.href);
  const user = useSelector((state) => state.user);
  const isCtUser = user?.profile?.person?.is_control_tower_user;
  const loadingUnloading = useSelector((state) => state.loadingUnloading);
  const isCtSuperOrCommercialUse = user?.profile?.person_role?.some(item => item.role_code === 'CT Superuser' || item.role_code === 'CT Commercial')

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  let links = [
    { to: '/configuration/account/user', title: 'Individual User' },
    ...(isCtUser && isCtSuperOrCommercialUse ? [{ to: '/configuration/account/all', title: 'All Users' }] : []),
  ];

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    history.push(FilterUtils.createUrl(params));
    setScrollToFirstRow(true);
  };

  const getDateIcon = (filtered, dataIndex) => {
    return (
      <CalendarOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return (
      <FilterOutlined
        style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }}
      />
    );
  };

  const showFilterAndSort = loc?.includes('/configuration/account/all') && isCtUser;

  const TABLE_HEADERS = [
    {
      title: 'Agreement',
      dataIndex: 'agreement',
      width: '100px',
      align: 'start',
      fixed: true,
      sorter: showFilterAndSort ? (a, b) => a.agreement?.localeCompare(b.agreement) : false,
      render: (text, row) => <div className="bfc-table-list-components text-left">{row?.agreement}</div>,
      filterDropdown: showFilterAndSort
        ? (props) => (
            // <TextSearchFilter
            //   dataIndex={'agreement'}
            //   data={props}
            //   onFilterChange={onFilterChange}
            //   handleReset={handleReset}
            //   value={agreement}
            //   name={'Equipment Owner'}
            // />
            <MultiSelectFilter
            dataIndex={'agreement'}
            data={props}
            onFilterChange={onFilterChange}
            handleReset={handleReset}
            value={agreement}
            name={'Agreement'}
          />
          )
        : false,
      filterIcon: (filtered) => getFilteredIcon(filtered, 'agreement'),
      filteredValue: agreement !== undefined ? agreement : null,
    },
    {
      title: 'Accepted By',
      dataIndex: 'accepted_by',
      width: '100px',
      align: 'start',
      sorter: showFilterAndSort ? (a, b) => a.accepted_by?.localeCompare(b.accepted_by) : false,
      render: (text, row) => <div className="bfc-table-list-components text-left">{row?.accepted_by}</div>,
      filterDropdown: showFilterAndSort
        ? (props) => (
            <TextSearchFilter
              dataIndex={'accepted_by'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={accepted_by}
              name={'Equipment Owner'}
              showPalceHolder={false}
            />
          )
        : false,
      filterIcon: (filtered) => getSearchIcon(filtered, 'accepted_by'),
      filteredValue: accepted_by !== undefined ? accepted_by : null,
    },
    {
      title: 'For',
      dataIndex: 'accepted_for',
      width: '100px',
      align: 'start',
      sorter: showFilterAndSort ? (a, b) => a.accepted_for?.localeCompare(b.accepted_for) : false,
      render: (text, row) => <div className="bfc-table-list-components text-left">{row?.accepted_for}</div>,
      filterDropdown: showFilterAndSort
        ? (props) => (
            <TextSearchFilter
              dataIndex={'accepted_for'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={accepted_for}
              name={'Equipment Owner'}
              showPalceHolder={false}
            />
          )
        : false,
      filterIcon: (filtered) => getSearchIcon(filtered, 'accepted_for'),
      filteredValue: accepted_for !== undefined ? accepted_for : null,
    },
    {
      title: 'Accepted On',
      dataIndex: 'accepted_on',
      width: '100px',
      align: 'center',
      // sorter: showFilterAndSort ? (a, b) => a.accepted_on - b.accepted_on : false,
      sorter: showFilterAndSort ? (a, b) => a.accepted_on?.localeCompare(b.accepted_on) : false,
      render: (text, row) => (
        <div className="bfc-table-list-components text-center">{dayjs(row?.accepted_on).format('DD-MMM-YYYY')}</div>
      ),
      filterDropdown: showFilterAndSort
        ? (props) => (
            <RangeDateSearchFilter
              dataIndex={'accepted_on'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={accepted_on}
            />
          )
        : false,
      filterIcon: (filtered) => getDateIcon(filtered, 'accepted_on'),
      filteredValue: accepted_on !== undefined ? accepted_on : null,
    },
    {
      title: 'From',
      dataIndex: 'accepted_person_email_id',
      width: '100px',
      align: 'start',
      sorter: showFilterAndSort
        ? (a, b) => a.accepted_person_email_id?.localeCompare(b.accepted_person_email_id)
        : false,
      render: (text, row) => <div className="bfc-table-list-components text-left">{row?.accepted_person_email_id}</div>,
      filterDropdown: showFilterAndSort
        ? (props) => (
            <TextSearchFilter
              dataIndex={'accepted_person_email_id'}
              data={props}
              onFilterChange={onFilterChange}
              handleReset={handleReset}
              value={accepted_person_email_id}
              name={'Equipment Owner'}
              showPalceHolder={false}
            />
          )
        : false,
      filterIcon: (filtered) => getSearchIcon(filtered, 'accepted_person_email_id'),
      filteredValue: accepted_person_email_id !== undefined ? accepted_person_email_id : null,
    },
    {
      title: '',
      width: '50px',
      align: 'center',
      render: (text, row) => (
        <AntTableActions
          row={row}
          showFlag={true}
          controls={[
            {
              name: 'Download',
              onClick: () => modifyPdf(row),
              controlColor: 'var(--unnamed-color-1890ff)',
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    let params = {
      filters:
        loc?.includes('/configuration/account/all') && isCtUser
          ? { ...filters, is_control_tower_user: `${isCtUser}` }
          : filters,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_size: rowsPerPage,
    };
    dispatch(gpaAccountActions.fetchGPAAcceptance(params));
  }, [filters, rowsPerPage, page, loc]);

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const busy = _.get(loadingUnloading, 'busy') || false;
  let tableData = busy ? [] : loadingUnloading?.gpaDetails;

  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    history.push(pathname + '?' + `page=1`);
  };

  const modifyPdf = async (rowData) => {
    const url =  `https://${window.location.hostname}/Gpa.pdf`
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    const firstPage = pages[pages.length - 1];
    firstPage.drawText(
      `Accepted by ${rowData?.accepted_by} (${rowData?.accepted_person_email_id}) for ${
        rowData?.accepted_for
      } at ${dayjs(rowData?.accepted_on).format('DD-MMM-YYYY, hh:mm:ss a')} (${
        rowData?.accepted_person_client_machine_ip
      })`,
      {
        x: 75,
        y: 10,
        size: 8,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      },
    );

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, 'Global Participation Agreement', 'application/pdf');
  }

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="commodity-deal-list-page">
      <div className="row1">
        <div className="bfc-header">
          <TableListHeader links={links} />
        </div>
      </div>
      <div className="row2 bfc-body">
        {/* {busy && (
          <div className="commodity-wrapper">
            <div className="panel__refresh">
              <LogoLoader />
            </div>
          </div>
        )} */}
        <div className="blocked-table">
          <div className="ant-table-body ant-table">
            <Table
              ref={tableRef}
              dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                columns={busy ? TABLE_HEADERS.map((column) => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <Skeleton
                          active
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                        />
                      );
                    },
                  };
              }) : TABLE_HEADERS}
              pagination={false}
              onChange={handlePageChange}
              scroll={{ y: 550 }}
              className="ant-table"
              size="small"
            />
            {loc.includes('user') ? (
              ''
            ) : (
              <AntPagination
                total={parseInt(totalRecords)}
                handlePageChange={handlePageChange}
                page={page}
                clearFilters={clearFilters}
                rowsPerPage={rowsPerPage}
                setScrollToFirstRow={setScrollToFirstRow}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GPALog;
