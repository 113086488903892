import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, ButtonToolbar, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Row, Col } from 'react-bootstrap';
import * as handlers from '../../subscription/handlers';
import { useSelector, useDispatch } from 'react-redux';
import notifications from 'lib/notifications';
import { Input, Form, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import FormikMaterialTextFieldNumber from '../../finance/components/FormikMaterialTextFieldNumber';
import RupeeIcon from 'mdi-react/RupeeIcon';
import couponIcon from '../../../assets/images/icons/coupon.svg';
import FormikMaterialTextField from '../../subscription/components/FormikMaterialTextField';
import LocationIcon from 'assets/images/icons/maps-and-flags.svg';

const DealVoucherModal = (props) => {
  const {
    isOpen,
    closeModal,
    insufficient_bal,
    details,
    setTimeStamp,
    prepaidBalance,
    location,
    address_id,
    setShowDealVoucherModal,
    isOverview
  } = props;
  let [value, setValue] = useState(0);
  const [showCoupnTextField, setShowCouponTextField] = useState(false);
  const [discountCoupon, setDiscountCoupon] = useState('');
  const user  = useSelector(state => state?.user?.profile?.person)

  useEffect(() => {
    if (isOverview) {
      setValue(insufficient_bal)
    } else {
      setValue(insufficient_bal - prepaidBalance)
    }
  }, [])

  let initialValues = {
    discount_coupon_code: '',
    balance: insufficient_bal || 0,
  }

  const handleDealVoucherPayment = async () => {
    let personCompanyAffiliateEnum
    if (details?.user_type_enum === 2 || user?.user_type_enum === 2) {
      personCompanyAffiliateEnum = 1
    } else if (details?.user_type_enum === 1 || user?.user_type_enum === 1) {
      personCompanyAffiliateEnum = details?.['company_affiliate_enum'] == 1 ? 2 : details?.['company_affiliate_enum'] === 2 && 3
    }

    let params = {
      plan_id: 1,
      number_of_users: 1,
      discount_coupon_code: discountCoupon,
      closing_balance: details?.closing_balance || 0,
      address_id: 0,
      // company_address_id: details?.ship_to_address_id || details?.address_id || address_id,
      prepaid_recharge_amount: value,
      person_company_affiliate_enum: personCompanyAffiliateEnum,
      person_company_affiliate_id: details?.['entity_id'],
    };
    let res = await handlers.handleCreate(params);

    if (res !== undefined) {
      setShowDealVoucherModal(false)
      const paymentId = _.get(res, 'bf_payment_master_response.payment_master_id') || 0;
      let razorPayParams = {
        key: _.get(res, 'prefill.pg_key') || '', // Enter the Key ID generated from the Dashboard
        amount: _.get(res, 'amount') || '', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise

        name: _.get(res, 'prefill.name') || '',

        order_id: _.get(res, 'order_id') || '', //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          notifications.show({
            type: 'success',
            message: 'Payment Successfull',
          });

          let refreshCounter = 0;
          if (refreshCounter == 0) {
            setTimeStamp(Date.now())
            refreshCounter += 1
          }
        },
        prefill: {
          name: _.get(res, 'prefill.name') || '',
        },
      };

      var rzp1 = new Razorpay(razorPayParams);
      rzp1.open();
    }
  }

  const handleApplyCoupon = async (values, formikBag) => {
    let params = {
      plan_id: 1,
      number_of_users: 1,
      discount_coupon_code: values['discount_coupon_code'],
    };
    const success = await handlers.handleApplyCoupon(params, formikBag);
    // if (success) {
    //   if (success.discount_type === 'Percentage' && success.discount_value === 100) {
    //     setIsFree(true);
    //   }
    //   setShowDiscountedValue(success);

    //   if (values.closing_balance != null && values.closing_balance > 0) {
    //     if (values.discount_type == 'TrialDays') {
    //       setWarningMessage(8);
    //       setShowRenewalModal(true);
    //       setIsTumbling(true);
    //       // setShowCancel(true);
    //     }
    //   }
    // }
  };

  if (isNaN(value)) {
    setValue(0)
  }
  let prepaidBalanceOverview;
  if (!_.isEmpty(details) && details?.prepaid_balance) {
    prepaidBalanceOverview = details?.prepaid_balance
    if (prepaidBalanceOverview == null) {
      prepaidBalanceOverview = 0
    }
  }

  let newValue = value.toLocaleString('en-US', { maximumFractionDigits: 2 });

  const amountPrice = [50, 100, 200, 500, 1000, 2000];
  const gstAmount = (value * 18) / 100;
  let gstAmountValue = value + gstAmount;
  gstAmountValue = gstAmountValue.toLocaleString('en-US', { maximumFractionDigits: 2 });

  let current_balance = prepaidBalanceOverview || prepaidBalanceOverview == 0 ? prepaidBalanceOverview : prepaidBalance;
  current_balance = current_balance.toLocaleString('en-US', { maximumFractionDigits: 2 });

  let required_balance = prepaidBalanceOverview || prepaidBalanceOverview == 0 ? (insufficient_bal + prepaidBalanceOverview) : (insufficient_bal);
  required_balance = required_balance.toLocaleString('en-US', { maximumFractionDigits: 2 });
  return (
    <div className="bfc-post-text-editor-modal">
      <div className="bfc-follow-editor-section">
        <Modal
          isOpen={isOpen}
          toggle={closeModal}
          modalClassName={`ltr-support confirm-delete-modal`}
          size="lg" style={{ maxWidth: '400px', width: '100%' }}
        >
          <Formik initialValues={initialValues}>
            {(formikBag) => {
              const { values, setFieldValue } = formikBag;
              return (
                <>
                  <div className="modal__header" style={{ boxShadow: '0px 2px 5px 2px rgba(151, 142, 142, 0.2)' }}>
                    <button aria-label="Close" className="position-relative close" style={{ left: '10px' }} type="button" onClick={closeModal}>
                      <span aria-hidden>×</span>
                    </button>
                    <p className="text-modal modal__title bfc-modalTitle mb-0 mt-0">
                      <span className="text-center mt-5" style={{ fontFamily: 'var(--unnamed-font-segoeUI)', fontSize: '19px', color: 'var(--unnamed-color-707070)' }}>
                        Add Money to your Prepaid Balance
                      </span>

                    </p>
                  </div>
                  <ModalBody>

                    <div className='mt-20'>
                      <div style={{ width: '100%', fontSize: '15px', marginBottom: '10px' }}>
                        <table style={{ width: '100%' }}>

                          <tr className="mb-15">
                            <td style={{ width: '120px', display: 'flex', alignItems: 'center' }}>
                              <img src={LocationIcon} alt="" height={'20'} style={{ width: '20px' }} />
                              <h4 className="text-center mb-0 ml-10" style={{ fontFamily: 'var(--unnamed-font-segoeUI)' }}>Location</h4>
                            </td>
                            <td style={{ width: '120px' }}>:</td>
                            <td style={{ color: 'var(--unnamed-color-707070)', textAlign: 'right' }}>{details?.address_code || details?.address_name ? details?.address_code || details?.address_name : location.charAt(0).toUpperCase() + location.slice(1)}</td>
                          </tr>
                          <tr className="mb-15">
                            <td className="text-left" style={{ width: '120px' }}>Current Balance</td>
                            <td style={{ width: '120px' }}>:</td>
                            <td style={{ color: 'red', textAlign: 'right' }}>{`₹${current_balance}`}</td>
                          </tr>
                          <tr className="mb-15">
                            <td>Amount Required</td>
                            <td>&nbsp;:&nbsp;</td>
                            <td style={{ color: 'green', textAlign: 'right' }}>{`₹${required_balance}`} </td>
                          </tr>
                        </table>
                      </div>
                      <div className='mt-15 d-flex flex-row mb-1' style={{ fontSize: 'var(--fs-base__two)', fontWeight: 'bold' }}>
                        <span>Amount to Add</span>
                      </div>
                      <Input
                        // defaultValue={`₹${newValue}`}
                        prefix={<RupeeIcon />}
                        value={value}
                        type="number"
                        min={1}
                        style={{ height: '50px', width: '100%', fontSize: 'var(--fs-base__thirty-two)', fontWeight: 900, textAlign: 'right' }}
                        onChange={(e) => {
                          e.preventDefault()
                          setValue(parseInt(e.target.value))
                        }}
                        className="inputAmount"
                      />
                      <div>
                        {isOverview ? (<span style={{ color: 'red' }}>{value < insufficient_bal ? `Minimum amount required is ₹${insufficient_bal}` : ''}</span>) : (<span style={{ color: 'red' }}>{value < insufficient_bal - prepaidBalance ? `Minimum amount required is ₹${insufficient_bal - prepaidBalance}` : ''}</span>)}
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className='d-flex w-100 mt-20'>
                            {
                              amountPrice.map((amount, index) => {
                                return (
                                  <Button className='amount-btn mt-10 mb-10 p-5' style={{ width: 'inherit' }} value={amount} onClick={(e) => {
                                    setValue(parseInt(e.target.value) + parseInt(value))
                                  }}>{`₹${amount}`}</Button>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <div className='mt-15 d-flex flex-row mb-20'>
                        {!showCoupnTextField &&
                          <Row className="content__oneline">
                            <Col md={12}>
                              <p
                                className={`point-title float-right apply-coupon`}
                                style={{ color: '#1b62ab', cursor: 'pointer' }}
                                onClick={() => setShowCouponTextField(true)}
                              >
                                Apply Coupon
                              </p>
                            </Col>
                          </Row>}
                      </div>
                      {showCoupnTextField ? (
                        <>
                          <div class="d-flex mt-20 text-center">
                            <div class="flex-shrink-1">
                              <img
                                className="trueIcons float-right mt-5"
                                src={couponIcon}
                                style={{ height: '25px' }}
                              />
                            </div>
                            <div class="w-80 d-flex justify-content-end">
                              <div className="total-price">
                                <div className="form-group-field">
                                  <Field name="discount_coupon_code" component={FormikMaterialTextField} />
                                </div>
                              </div>
                              <div className="total-price float-right">
                                <div className="form-group-field">
                                  <button
                                    type="button"
                                    className="apply-coupon-btn__blue"
                                    style={{
                                      backgroundColor: "#1b62ab",
                                      color: '#fff',
                                      height: "36px",
                                      borderWidth: '1px',
                                      borderBottomRightRadius: '4px',
                                      borderTopRightRadius: '4px'
                                    }}
                                  // onClick={() => {
                                  //   handleApplyCoupon(formikBag.values, formikBag);
                                  // }}
                                  >
                                    <small>Apply Coupon</small>
                                  </button>
                                </div>
                                <p
                                  className="float-right remove-label"
                                  style={{ color: '#ab1b1b', cursor: 'pointer' }}
                                  onClick={() => {
                                    setShowCouponTextField(false);
                                    formikBag.setFieldValue('discount_coupon_code', '');
                                  }}
                                >
                                  Remove
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className='mt-10 mb-10'>
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td className="text-left" style={{ width: '120px' }} >GST 18%</td>
                            <td style={{ width: '120px' }}>&nbsp;:&nbsp;&nbsp;</td>
                            <td style={{ textAlign: 'right' }}>{`₹${gstAmount}`}</td>
                          </tr>
                        </table>

                      </div>

                    </div>
                  </ModalBody>
                  <ModalFooter style={{ display: 'block' }}>
                    <Button style={{ borderRadius: 5, width: '100%', height: '50px', backgroundColor: value > 0 ? '#1b62ab' : '#507ca8' }} color={value > 0 ? "primary" : "#507ca8"} size="sm" onClick={handleDealVoucherPayment} disabled={value > 0 ? false : true} >
                      Proceed to pay {`₹${gstAmountValue}`}
                    </Button>
                  </ModalFooter>
                  <div className='mt-0 mb-10'>
                    <Link to={'/subscription-packages'} target="_blank" style={{ fontSize: '18px', textDecoration: 'underline' }}>Take a look at our subscriptions here !</Link>
                  </div>
                </>
              )
            }
            }
          </Formik>

        </Modal >
      </div>
    </div>

  )
}

export default DealVoucherModal;
