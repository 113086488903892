import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchPassBook = async (filters = {}, params) => {
  try {
    let bodyParams = reqTransform.fetchPassBook(filters, params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchPassBook`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    // const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPassBook(resData);
  } catch (err) {
    console.log('FetchPlanAgainstSubscriptionPlanId-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchPassBook');
    throw errors;
  }
};

export const updatePassbook = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/SystemMaintenance/UpdatePassbook?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPassBook(resData);
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'UpdatePassbook');
    throw errors;
  }
};

export const transferPassbookAmount = async (params) => {
  try {
    const qs = utils.getQs(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/SystemMaintenance/TransferPassbookAmount?${qs}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchPassBook(resData);
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'TransferPassbookAmount');
    throw errors;
  }
};
