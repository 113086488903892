import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchClosingBalance = async (params = {}) => {
  try {
    let qs = reqTransform.fetchClosingBalance(params);
    const url = `${baseUrl}/BioFuelPayments/api/Subsciption/FetchUpgradeDowngradeDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchClosingBalance(resData);
  } catch (err) {
    console.log('err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchClosingBalance');
    throw errors;
  }
};
