import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import store from 'redux/store';

export const reqTransform = {
  fetchList(params = {}) {
    const storeState = globalThis.store.getState();
    const userTypeEnum = _.get(storeState, 'kycUser.profile.person.user_type_enum');
    let qsParams = {};
    if (params['person_id'] !== _.get(window, 'user.userid', 0)) {
      qsParams = {
        company_affiliate_enum: 1,
        ...params,
        user_type_enum: userTypeEnum,
      };
    } else {
      qsParams = {
        company_affiliate_enum: 1,
        ...params,
        user_type_enum: _.get(window, 'user.profile.person.user_type_enum', 0),
      };
    }
    delete qsParams['details'];
    delete qsParams['person_id'];

    return utils.getQs(qsParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let commentsData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(commentsData, 'kyc_history_id');
  },
  fetchDetails(resData) {
    return {
      ...resData,
      id: _.get(resData, 'kyc_history_id'),
    };
  },
};
