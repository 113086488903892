import React, { useEffect, useState } from 'react';
import { Widget } from '@typeform/embed-react';
import DealService from '../../services/deal';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Button } from 'antd';
import SelectRecipientId from 'common/Form/SelectRecipientId';
import { actions as teamsActions } from 'redux/teams';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { dealInvoicesService } from 'services';
import notifications from 'lib/notifications';

const DownloadInvoiceModal = ({
  isOpenDownloadInvoiceModal,
  setIsOpenDownloadInvoiceModal,
  selectedRowKeys,
  companyId,
}) => {

  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teams);
  const user = useSelector((state) => state.user);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  useEffect(() => {
    if (companyId) {
      let params = {
        filters: {},
        offset: 0,
        page_size: 1000,
        company_id: companyId,
      };
      dispatch(teamsActions.fetchTeamMembersFromOrganization(params));
    }
  }, [companyId]);

  const handleSelectChange = (selectedIds) => {
    setSelectedRecipients(selectedIds);
  };

  const sendInvoices = async () => {
    if (selectedRecipients) {
      const params = {
        invoice_master_id: selectedRowKeys,
        recipient_id: selectedRecipients,
      };
      notifications.show({ type: 'info', message: 'Sending Invoices...' });

      try {
        let response = await dealInvoicesService.downloadInvoices(params);

        if (typeof response === 'string' && response.trim().length > 0) {
          notifications.show({ type: 'success', message: 'Invoices sent Successfully' });
        } else {
          const errorMessage = 'This service is down. Please try again later.';
          notifications.show({ type: 'warning', message: errorMessage });
        }
      } catch (error) {
        console.error("Error sending invoices:", error);
        notifications.show({ type: 'error', message: 'Failed to send invoices. Please try again later.' });
      }
    }
    setIsOpenDownloadInvoiceModal(!isOpenDownloadInvoiceModal);
  };

  const toggleModal = () => {
    setIsOpenDownloadInvoiceModal(!isOpenDownloadInvoiceModal);
  };

  return (
    <>
      <>
        <Modal isOpen={isOpenDownloadInvoiceModal} toggle={setIsOpenDownloadInvoiceModal} size="lg">
          <ModalHeader toggle={toggleModal}>Select Email</ModalHeader>
          <ModalBody>
            <div className="row form__form-group w-auto">
              <div className="col">
                <span className="form-group-label"  style={{ textAlign: 'left', display: 'block' }}>You can select multiple emails</span>
                <div className="form-group-field with-extension">
                  <SelectRecipientId
                    placeholder="Add Email"
                    teams={teams?.team}
                    teamMembers={teams?.team}
                    onSelectChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="secondary" onClick={toggleModal}>Cancel</Button>
            <Button type="primary" disabled={selectedRecipients.length === 0} onClick={sendInvoices}>Send Invoices</Button>
          </ModalFooter>
        </Modal>
      </>
    </>
  );
};

export default DownloadInvoiceModal;
