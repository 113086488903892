import React from 'react';
import { Tag } from 'antd';

function AntTableStatus(props) {
  const { text, color } = props;
  return (
    <span>
      <Tag color={color} key={text}>
        <div className="bfc-table-list-components text-center">{text.toUpperCase()}</div>
      </Tag>
    </span>
  );
}

export default AntTableStatus;
