import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchLocationEloc = async (params = {}) => {
  try {
    let qs = reqTransform.fetchLocationEloc(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/LocationSetup/GetELocCodeByLatLng?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchLocationEloc(resData);
  } catch (err) {
    console.log('err---->', err);
    const errors = await utils.processApiErrors(err, '', 'fetchLocationEloc');
    throw errors;
  }
};
