import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import * as helpers from 'services/helpers';
import { reqTransform, resTransform } from './transforms';

const { baseUrl } = config;

export const fetchLocations = async (id = 1) => {
  try {
    const qs = reqTransform.fetchLocations(id);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/LocationSetup/GetLocationDataAgainstLocationId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchLocations(resData);
  } catch (err) {
    console.log('api-posts-fetchLocations->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetLocationDataAgainstLocationId');
    throw errors;
  }
};
