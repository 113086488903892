import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  deleteSignatory(id) {
    let bodyParams = {
      authorized_signatory_id: id,
    };
    return utils.getQs(bodyParams);
  },
  invoiceConfiguration(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  deletePackaging(id) {
    let bodyParams = {
      operation_id: id,
    };
    return utils.getQs(bodyParams);
  },
  fetchAllAutorizedSignatory(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchAllVendorsList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  fetchEquipmentData(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  create(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  destroy(params) {
    let bodyParams = {
      ...params,
    };
    delete bodyParams['vendor_code'];
    return utils.getQs(bodyParams);
  },
  createSignatory(params = {}) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  createPackaging(params = {}) {
    delete params['commodity_type_code'];
    delete params['commodity_form_code'];
    // delete params['commodity_type_id'];
    // delete params['entity_id'];
    delete params['address_id'];
    delete params['commodity_type_code'];
    delete params['commodity_form_code'];

    let bodyParams = {
      ...params,
      loading_charge_value: params['loading_charge_value'],
      unloading_charge_value: params['unloading_charge_value'],
      loading_charge_uom_id: params['price_uom_id'], //Hardcode value
      unloading_charge_uom_id: params['price_uom_id'], //Hardcode value
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(configurationData, 'counterparty_id');
  },
  fetchDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'counterparty_id'),
    };
    return res;
  },
  create(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
  fetchAllVendorsList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllVendorsDetails(details);
    });
    return _.keyBy(configurationData, 'vendor_id');
  },
  fetchEquipmentData(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllVendorsDetails(details);
    });
    return _.keyBy(configurationData, 'vendor_id');
  },
  fetchAllVendorsDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'vendor_id'),
    };
    return res;
  },
  createSignatory(resData) {
    return resData;
  },
  fetchAllAutorizedSignatory(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchAllAutorizedSignatoryDetails(details);
    });
    return _.keyBy(configurationData, 'authorized_signatory_id');
  },
  fetchAllAutorizedSignatoryDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'authorized_signatory_id'),
    };
    return res;
  },
  fetchCompanyList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchCompanyDetails(details);
    });
    return _.keyBy(configurationData, 'entity_id');
  },
  fetchCompanyDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'entity_id'),
    };
    return res;
  },
  fetchUserList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchUserDetails(details);
    });
    return _.keyBy(configurationData, 'user_id');
  },
  fetchUserDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'user_id'),
    };
    return res;
  },
  deleteSignatory(resData) {
    return resData;
  },
  fetchPackagingList(resData) {
    let packagingData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchPackagingDetails(details);
    });
    return _.keyBy(packagingData, 'operation_id');
  },
  fetchPackagingDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'operation_id'),
    };
    return res;
  },
  invoiceConfiguration(resData) {
    return resData;
  },
};
