import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const KycApprovalSchema = yup.object().shape({});

export const fetchList = async (params = {}) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchListCT = async (params = {}) => {
  try {
    let res = await api.fetchListCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const revertDeliveries = async (params) => {
  try {
    let res = await api.revertDeliveries(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateUnconfirmedDeliverySchedule = async (params) => {
  try {
    let res = await api.updateUnconfirmedDeliverySchedule(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const RejectDelivery_CT = async (params, id) => {
  try {
    let res = await api.RejectDelivery_CT(params, id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchInvoiceListCT = async (params = {}) => {
  try {
    let res = await api.fetchInvoiceListCT(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const FetchPlatformLedger = async (params = {}) => {
  try {
    let res = await api.FetchPlatformLedgerDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const recordUnallocatedPayment = async (params = {}) => {
  try {
    let res = await api.recordUnallocatedPayment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteUnallocatedPayment = async (params = {}) => {
  try {
    let res = await api.deleteUnallocatedPayment(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchPlatformLedgerMetadata = async (params = {}) => {
  try {
    let res = await api.fetchPlatformLedgerMetadata(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    // await helpers.validateSchema(AffiliateSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
export const linkDealsOrDeliveries = async (params) => {
  try {
    let res = await api.linkDealsOrDeliveries(params);
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteDealsOrDeliveriesFromLink = async (params) => {
  try {
    let res = await api.deleteDealsOrDeliveriesFromLink(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getLinkedDealDelivery = async (params) => {
  try {
    let res = await api.getLinkedDealDelivery(params);
    return res;
  } catch (err) {
    throw err;
  }
};
