import _, { values } from 'lodash';
import * as yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import utils from 'lib/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormikMaterialTextFieldAcct from './FormikMaterialTextFieldAcct';
import FormikMaterialTextField from 'common/Form/FormikMaterialTextField';
import { actions as companyActions } from 'redux/companies';
import BankSelect from 'containers/Account/Profile/KYCBusiness/components/bankSelect';
import * as handlers from '../handlers';
import * as businessHandlers from 'containers/Account/Profile/KYCBusiness/handlers';
import ToggleSwitch from './toggleSwitch';
import MUITextField from 'common/MUIComponents/TextField';
import { authService } from 'services';
import MUIModal from 'common/MUIComponents/Modal';
import getColor from 'common/MUIComponents/colors';
import notifications from 'lib/notifications';
import MUIButton from 'common/MUIComponents/Button';

const bankFields = {
  account_name: '',
  account_number: '',
  re_account_number: '',
  ifsc_code: '',
  bank_account_id: 0,
  operation_enum: 1,
  is_default: true,
  is_active: true,
  bank_name: '',
  is_savings_account: false,
};
const UpiSchema = yup.object().shape({
  account_name: yup.string().required('Beneficiary Name is required').min(4, 'Minimum 4 characters are required'),
  VPA_Id: yup
  .string()
  .required('upi id is required'),

  re_VPA_Id: yup
  .string()
  .oneOf([yup.ref('VPA_Id'), ''], 'Does not match with upi id!')
  .required('Required'),
});


const UpiPopup = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const typeOfIndividualEnum = _.get(user, 'profile.person.type_of_individual_enum');
  const isProprietor = _.get(user, 'profile.person.is_proprietor');
  const { isAdmin, kycStatusKey, type, setTimestamp, company_affiliate_enum, entity_id, setShowUpiPopupForm, newBankDetails, setBankDetails, isFacilitator, name, facilitatorPersonData, setFullNameAsPerBank } = props;
  const bankData = _.get(props, 'bankData') || {};

  const companies = useSelector((state) => state.companies);
  const companyId = _.get(user, 'profile.person.company_id') || 0;
  const companyData = _.get(companies, `entities[${companyId}]`) || {};
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);
  const [vpaData, setVpaData] = useState(false);

  const initialValues = {
    ...bankFields,
    ...bankData,
    VPA_Id: bankData?.account_number,
    re_VPA_Id: bankData?.account_number,
    account_name: bankData?.account_name,
    mobile_number: bankData?.mobile_number || facilitatorPersonData?.mobile_number,
    bank_name: ''
  };

  const handleSave = async (values, formikBag) => {
    const mySchema = UpiSchema;
      let params = {
        bank_account_details_request_model: [
        {
          ...values,
          account_number: null,
          ifsc_code: null
        },
        ],
      };
      const success = await handlers.handleBankCreate(params, formikBag, setTimestamp, mySchema);
    };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={UpiSchema}
        onSubmit={async (values, formikBag) => {
        //   handleCheck(values, formikBag);
        }}>
        {(formikBag) => {
          let { values, setFieldValue } = formikBag;
          return (
            <Form className="formik-form">
              <div className="row form__form-group">
                <div className="col">
                  {/* <span className="form-group-label">UPI ID</span>
                  <span class="required">*</span> */}
                  <div className="form-group-field">
                    {/* <Field
                      name={`VPA_Id`}
                      component={FormikMaterialTextField}
                    //   disabled={isAdmin || kycStatusKey}
                      // type={'password'}
                      placeholder={
                        'UPI ID here'
                      }
                    /> */}
                    <Field
                      label='Mobile Number'
                      name={`mobile_number`}
                      value={values?.mobile_number}
                      component={MUITextField}
                      fieldType='mobile'
                      uomType='button'
                      EndIcon='Verify'
                      endIconBtnDisabled={!values?.mobile_number}
                      endIconBtnOnClick={async () => {
                        const success = await authService.getVPAAgainstMobileNumber(`91${values?.mobile_number}`);
                          if (!_.isEmpty(success) && !success?.errors) {
                            setIsRegisterPopupOpen(true);
                            setVpaData(success);
                            document?.querySelector('.Yes')?.focus()
                          } else if (!_.isEmpty(success) && !_.isEmpty(success?.errors)) {
                            notifications.show({type: 'error', message:'VPA not found. Please enter another mobile number which is linked with your current & active UPI ID!.'})
                          }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row form__form-group">
                <div className="col">
                  {/* <span className="form-group-label">Re-enter UPI ID</span>
                  <span class="required">*</span> */}
                  <div className="form-group-field">
                    {/* <Field
                      name={`re_VPA_Id`}
                      component={FormikMaterialTextField}
                      // disabled={isAdmin || kycStatusKey}
                      // type={'password'}
                      placeholder={
                        'UPI ID here'
                      }
                    /> */}
                    <Field
                      label='UPI ID'
                      name={`VPA_Id`}
                      component={MUITextField}
                      fieldType='alphanumeric'
                      value={values?.VPA_Id}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {(type !== 'business' ||
                (isProprietor && typeOfIndividualEnum == 2) ||
                (props.business_type_enum == 1 && formikBag.values['is_savings_account'] == true)) && (
                <div className="row form__form-group">
                  <div className="col">
                    {/* <span className="form-group-label">Beneficiary Name</span>
                    {type !== 'business' && <span class="required">*</span>} */}
                    <div className="form-group-field">
                      {/* <Field
                        name={`account_name`}
                        component={FormikMaterialTextField}
                        disabled={isAdmin || kycStatusKey}
                        placeholder={'Name here'}
                      /> */}
                      <Field
                        label='Beneficiary Name'
                        name={`account_name`}
                        component={MUITextField}
                        value={values?.account_name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="bfc-center">
                <MUIButton
                  // color="primary"
                  // type="submit"
                  // size="sm"
                  disabled={!values?.account_name && !values?.VPA_Id}
                  onClick={() => {
                    if(isFacilitator) {
                      const exists = newBankDetails?.findIndex(item => item.time_stamp === formikBag?.values?.time_stamp)
                      if(exists !== -1) {
                        const updated = newBankDetails?.filter((item, index) => index !== exists)
                        updated.push({...formikBag.values, account_number: formikBag.values?.VPA_Id})
                        setBankDetails(updated)
                      } else {
                        setBankDetails([...newBankDetails, {...formikBag.values, account_number: formikBag.values?.VPA_Id, time_stamp: new Date().getTime()}])
                      }
                      formikBag.setFieldValue('VPA_Id', '')
                      formikBag.setFieldValue('re_VPA_Id', '')
                      formikBag.setFieldValue('account_name', '')
                      setShowUpiPopupForm(false);
                    } else {
                      formikBag.values.eKyc = '';
                      setShowUpiPopupForm(false);
                      handleSave(formikBag.values, formikBag);
                    }
                  }}>
                  {'Save'}
                </MUIButton>
                <MUIModal
                  open={isRegisterPopupOpen}
                  setOpen={setIsRegisterPopupOpen}
                  title='Confirm VPA'
                  confirmationMessage='Is this your current & active UPI id? We will pay you on this UPI id'
                  actionButtons={[{name: 'Yes', onClick:() => {
                    notifications.show({
                      type: 'success',
                      message:
                        'Thank you for confirming your UPI id!.',
                    });
                    setFieldValue('VPA_Id', vpaData?.vpa)
                    setFieldValue('re_VPA_Id', vpaData?.vpa)
                    setFieldValue('account_name', vpaData?.name_at_bank)
                    setFullNameAsPerBank && setFullNameAsPerBank(vpaData?.name_at_bank);
                    setIsRegisterPopupOpen(false)
                  }}, {name: 'No', onClick:() => {
                    setIsRegisterPopupOpen(false)
                    notifications.show({
                      type: 'error',
                      message:
                        'Please enter another mobile number which is linked with your current & active UPI ID!.',
                    });
                    setFieldValue('mobile_number', '')
                    setFieldValue('VPA_Id', '')
                    setFieldValue('re_VPA_Id', '')
                    setFieldValue('account_name', '')
                  }}]}
                >
                  <div className='font-weight-bold' style={{color: getColor('muiPrimary')}} >{vpaData?.name_at_bank}</div>
                  <div className='font-weight-bold' style={{color: getColor('muiPrimary')}} >{vpaData?.vpa}</div>
                </MUIModal>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default UpiPopup;
