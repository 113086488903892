import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const connectProfile = async (params) => {
  try {
    let res = await api.connectProfile(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const withdrawConnect = async (params) => {
  try {
    let res = await api.withdrawConnect(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchCompanyBusinessProfile = async (params) => {
  try {
    let res = await api.fetchCompanyBusinessProfile(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchNotConnectedBusinessProfile = async (params) => {
  try {
    let res = await api.fetchNotConnectedBusinessProfile(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchConnectionRequests = async (params) => {
  try {
    let res = await api.fetchConnectionRequests(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const respondConnectRequest = async (params) => {
  try {
    let res = await api.respondConnectRequest(params);
    return res;
  } catch (err) {
    throw err;
  }
};
