import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

const { baseUrl } = config;

export const GetAllMarketTransportRate_Eloc = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/MarketTransportRate/GetAllMarketTransportRate_Eloc`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData?.map((item) => ({ ...item, id: item.market_transport_rate_id }));
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'GetAllMarketTransportRate_Eloc');
    throw errors;
  }
};

export const SaveUpdateMarketTransportRate = async (params = {}) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/MarketTransportRate/SaveUpdateMarketTransportRate`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'SaveUpdateMarketTransportRate');
    throw errors;
  }
};

export const DeleteMarketTransportRate = async (id: number) => {
  try {
    const url = `${baseUrl}/BioFuelTransportRequirementOffers/api/MarketTransportRate/DeleteMarketTransportRate?Market_Transport_Rate_Id=${id}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err: any) {
    const errors = await utils.processApiErrors(err, '', 'DeleteMarketTransportRate');
    throw errors;
  }
};
