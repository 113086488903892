import React from 'react';
import BaseIcon from './BaseIcon';

import { ReactComponent as IconPath } from 'assets/images/icons/extendedProfile/infrastructure-equipment-icon.svg';

function InfrastructureEquipmentIcon(props) {
  return <BaseIcon SVGElement={IconPath} {...props} />;
}

export default InfrastructureEquipmentIcon;
