import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';

export const EntityYupSchema = yup.object().shape({
  business_type_enum: yup.number().positive().required(),
  // company_type_enum: yup.number().positive().required(),
  nature_of_business_enum: yup.number().positive().required(),
  company_code: yup.string().required(),
  company_legal_name: yup.string().required(),
  // company_website: yup.string().required(),
});

export const fetchList = async (params) => {
  try {
    let res = await api.fetchList(params);
    return res;
  } catch (err) {
    // let errMessage = _.get(err, 'errors.company_id') || '';
    // if (errMessage === 'Invalid') {
    //   return {};
    // }
    throw err;
  }
};

export const fetchDetails = async (id) => {
  try {
    let res = await api.fetchDetails(id);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    params.company_legal_name = params.company_legal_name.trim();
    params.company_code = params.company_code.trim();
    await helpers.validateSchema(EntityYupSchema, params);
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params) => {
  try {
    params.company_legal_name = params.company_legal_name.trim();
    params.company_code = params.company_code.trim();
    await helpers.validateSchema(EntityYupSchema, params);
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const destroy = async (id) => {
  try {
    let res = await api.destroy(id);
    return res;
  } catch (err) {
    throw err;
  }
};
