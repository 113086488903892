import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';

export const reqTransform = {
  fetchList(params) {
    const bodyparams = {
      ...params,
      filters: params['filters'],
    };

    return bodyparams;
  },
  create(params = {}) {
    const bodyparams = {
      ...params,
      transporter_person_id: _.get(window, 'user.userid', 0), //526
      operation_enum: 1,
    };

    return bodyparams;
  },

  update(id, params) {
    const bodyparams = {
      ...params,
      person_id: id,
      transporter_person_id: _.get(window, 'user.userid', 0), //526
      operation_enum: 3,
    };

    return bodyparams;
  },

  destroy(id) {
    const qsParams = {
      vehicle_id: id,
    };

    return utils.getQs(qsParams);
  },
  eKycDocumentVerification(params) {
    let bodyParams = {
      verification_type_enum: 5,
      document_number: params['vehicle_registration_id'],
      document_name: params['name_on_vehicle_registration'],
      company_affiliate_enum: 0,
      expiry_date: params['expiry_date'],
      entity_id: _.get(window, 'user.userid') || 0,
    };
    return bodyParams;
  },
};

export const resTransform = {
  fetchList(resData) {
    let driverData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(driverData, 'vehicle_id');
  },
  fetchDetails(resData) {
    let data = {
      ...resData,
      id: _.get(resData, 'vehicle_id'),
    };
    return data;
  },
  create(resData) {
    return resData;
  },
  update(resData) {
    return resData;
  },
  destroy(resData) {
    return resData;
  },
  eKycVerification(resData) {
    return resData;
  },
};
