import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as handlers from './handlers';
import { actions as transportActions } from 'redux/transports';
import { PROFILE_TABS_ENUM } from 'constants/enums';
import useQuery from 'lib/hooks/useQuery';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import PopupForm from 'common/Form/PopupForm';

import { Table } from 'antd';
import AntTableActions from 'common/TableActions';
import Addlocationform from './AddLocationForm';
import OverviewNav from 'common/OverviewNav';
import AntPagination from 'common/AntTablePagination';
import {  Skeleton } from 'antd';
// import LogoLoader from 'common/LogoLoader';

const Vehiclelocations = (props) => {
  const id = props.id;
  const { back, page = 1 } = useQuery();

  const user = useSelector((state) => state.user);
  const kycUser = useSelector((state) => state.kycUser);
  const tableRef = useRef();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRerenderRequired, setIsRerenderRequired] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [filters, setFilters] = useState({});
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const transports = useSelector((state) => state.transports);
  const transportList = transports?.transportRoutes || [];
  const busy = transports.busy || false;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const filterParams = {
      filters: {
        ...filters,
      },
      page_size: rowsPerPage,
      offset: (parseInt(page) - 1) * rowsPerPage,
    };
    dispatch(transportActions.fetchListForTranportRouteServed(filterParams));
  }, [page, rowsPerPage, isRerenderRequired]);

  let tableData = transportList.length && Object.values(transportList || {});
  const totalRecords = transportList.length ? transportList?.[0]?.total_count : 0;

  const rolesAndRights = useSelector((state) => state.rolesAndRights);
  const rolesAndRightsList = _.get(rolesAndRights, 'entities') || {};
  const showTransport = Object.values(rolesAndRightsList).find(
    (x) => (x.module_id === PROFILE_TABS_ENUM.PROFILE, x.sub_module_id === PROFILE_TABS_ENUM.TRANSPORT),
  );
  const readAccess = _.get(showTransport, `read_access`);

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid) {
    isAdmin = true;
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  const acl = utils.getACL(user);
  const isCompanyUser = acl.isUserCompany;
  let nextLink = `/account/${id}/kyc`;
  let prevLink = `/account/${id}/farms`;
  if (isCompanyUser) {
    nextLink = `/account/${id}/kyc-b`;
    prevLink = `/account/${id}/teams`;
  }
  if (isAdmin) {
    const isCompany = _.get(kycUser, 'profile.person.user_type_enum');
    const isTransporter = _.get(kycUser, 'profile.person.is_transporter');
    if (isCompany == 1 || isTransporter) {
      nextLink = `/account/${id}/kyc-b?back=${back}`;
      prevLink = `/account/${id}/teams?back=${back}`;
    } else {
      nextLink = `/account/${id}/kyc?back=${back}`;
      prevLink = `/account/${id}/farms?back=${back}`;
    }
  }
  if (!isAdmin) {
    if (acl.isCompany || acl.isTransporter) {
      nextLink = `/account/${id}/kyc-b`;
      prevLink = `/account/${id}/teams`;
    }
  }

  const LOCATIONS_TABLE_HEADER = [
    {
      title: 'From',
      dataIndex: 'transport_from_location',
      width: '160px',
      align: 'center',
      fixed: true,
      ellipsis: true,
      render: (text, row) => (
        <div className="commodity-code">
          {`${row.from_district_code},${row.from_state_code},${row.from_country_code}`}
        </div>
      ),
    },
    {
      title: 'To',
      dataIndex: 'transport_to_location',
      width: '200px',
      align: 'center',
      render: (text, row) => (
        <div className="commodity-code">{`${row.to_district_code},${row.to_state_code},${row.to_country_code}`}</div>
      ),
    },
    {
      title: 'Actions',
      width: '80px',
      align: 'center',
      render: (text, row) => (
        <AntTableActions
          showFlag={true}
          isConfirmModal={true}
          isOpen={isConfirmModalOpen}
          confirmModalMsg="Are you sure you want to delete this location?"
          controls={[
            {
              name: 'Edit',
              onClick: () => handelLocationEdit(row),
              controlColor: 'var(--unnamed-color-1890ff)',
            },
            {
              name: 'Delete',
              onClick: () => handelLocationDelete(row),
              controlColor: '#ff4d4f',
            },
          ]}
        />
      ),
    },
  ];

  const handelLocationEdit = (row) => {
    setOpen(true);
    setIsEdit(true);
    setSelectedLocation(row);
  };

  const handelLocationDelete = (row) => {
    handlers.handleDeleteTransportRouteServed(row?.transport_routes_served_id);
    setIsRerenderRequired(!isRerenderRequired);
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const links = [
    { to: `/account/${id}/transport`, title: 'Vehicle' },
    { to: `/account/${id}/locations`, title: 'Route' },
  ];

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="bfc-body bfc-form profile-height-body">
      {/* {busy && (
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      )} */}
      <React.Fragment>
        <OverviewNav links={links?.map(link => ({ ...link, to: getUrlWithEncryptedQueryParams(link.to)}))} />
        <div className="form-container">
          <div className="farm-wrapper">
            <div className="farm-table">
              <div className="table-farms-wrapper">
                {!readAccess && (
                  <div className="addnew-row add-new-btn-box">
                    <Button
                      onClick={() => setOpen(true)}
                      type="button"
                      className="add-new-btn"
                      disabled={isAdmin}
                      size="sm">
                      <span className="icon">+</span>
                      <span className="text">Add Location</span>
                    </Button>
                  </div>
                )}

                <Table
                  // columns={LOCATIONS_TABLE_HEADER}
                  // dataSource={tableData}
                  ref={tableRef}
                  dataSource={!busy ? tableData : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                  columns={busy ? LOCATIONS_TABLE_HEADER.map((column) => {
                  return {
                    ...column,
                    render: function renderPlaceholder() {
                      return (
                        <Skeleton
                          active
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                        />
                      );
                    },
                  };
                  }) : LOCATIONS_TABLE_HEADER}
                  bordered
                  size="small"
                  pagination={false}
                  style={{ whiteSpace: 'pre' }}
                />
                <AntPagination
                  total={totalRecords}
                  page={parseInt(page)}
                  handlePageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  setScrollToFirstRow={setScrollToFirstRow}
                />
              </div>
            </div>

            <div className="btn-wrapper justify-content-space-between">
              <div className="">
                <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} className="link">
                  Prev
                </NavLink>
              </div>
              {!isCompanyUser && (
                <div className="">
                  <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} className="link">
                    Next
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>

        {open && (
          <PopupForm title={'Location Details'} onClose={() => setOpen(false)}>
            <Addlocationform
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setOpen={setOpen}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              isRerenderRequired={isRerenderRequired}
              setIsRerenderRequired={setIsRerenderRequired}
            />
          </PopupForm>
        )}
      </React.Fragment>
    </div>
  );
};

export default Vehiclelocations;
